import React from 'react';
import Logo from '../images/media/logo.png';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
var html;
function ReferToDoctorPrint() {
	let { printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [abc, setAbc] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [treatmentPlanResult, setTreatmentPlanResult] = useState([]);
	const [doctorDetails, setdoctorDetails] = useState([]);
	const [treatmentPlanDate, setTreatmentPlanDate] = useState('');
	const [grandTotal, setSrandTotal] = useState('');
	const [headingType, setHeadingType] = useState('Treatment Plans');
	const [isData, setIsData] = useState(false);
	const [isCompleted, setIsCompleted] = useState('0')
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);

	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);


			});

	}
	useEffect(() => {
		// window.sessionStorage.setItem('editId', '');
		setAbc(window.sessionStorage.getItem('htmlcontentreferDr'))
	}, []);

	const printTreatment = () => {
		const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
			// Hide unchecked sections before printing
		
			if (isChecked4) {
				section4.style.display = 'none';
			}
			if (isChecked5) {
				section51.style.display = 'none';
				section52.style.display = 'none';
			}
			if (isChecked7) {
				section7.style.display = 'none';
			}
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
		section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				{/* {isData === false ? <Loader /> : ''} */}
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">

					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>

											<HeaderPrint setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5}/>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
										<tbody>
											<tr style={{ fontSize: '13px' }}>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '100%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													<div className='medCertificateForm'>
														<div dangerouslySetInnerHTML={{ __html: abc }}></div>
														{/* <p>Dear Sugesh</p>
											 <p  style={{margin:"0px"}}>You have a patient referal notice</p>
											 <p>From : Mr/Mrs. Name</p>
											 <p>Note : test content</p>
											 <div style={{margin:"15px 0px"}}>
											 <p>For more details, please login to <br/> your dashboard on.</p>
											 </div>
											 <div style={{margin:"15px 0px"}}>
											 <p style={{margin:"0px"}}>Thank You</p>
											 <p>Admin</p> */}
													</div>

													{/* </div>  */}
												</td>

											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>


						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>


											<tr><td ><hr color="#142536" style={{ margin: "0px", opacity: "1", height: "2px" }} /></td></tr>

										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>
					<FooterPrint setIsChecked7={setIsChecked7} isChecked7={isChecked7}/>
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									id="printButton"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
										fontFamily: '  Verdana',
									}}
									onClick={printTreatment}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default ReferToDoctorPrint;
