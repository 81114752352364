import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Leftmenu from "../Patients/leftmenu";
import Profiledetail from "../Patients/profiledetail";
import Patientright from "../Patients/patientright";
import $ from 'jquery';
function LabOrderDentalAdd() {
  $(document).ready(function () {
    $('#labOrderAdd ').addClass("testResultAdd");

  })
  const [labData, setLabData] = useState([]);
  const [freeArticleData1, setFreeArticleData1] = useState([]);
  const [getServiceProviderId, setServiceProviderId] = useState("");
  const [id, setId] = useState("");
  const [getStyle, setStyle] = useState("none");
  const [labformsNames, setLabformsNames] = useState([]);
  const [labformsName, setService] = useState([]);
  const autoHide = () => {
    document.getElementById("locationSearch1").style.display = "none";
  };
  useEffect(() => {
    autoLabData();
  }, []);
  const autoLabData = () => {
    const apiJson = {
      keyWord: "",
    };
    callingAPI("adminReports/getLabDetails", apiJson).then((data) => {
      if (data.data.success === "1") {
        setLabData(data.data.autoLabData);
      }
    });
  };
  const orderAt = (mode, dropdownId) => {
    document.getElementById("rightId").style.display = "block";
    if (document.getElementById(mode).value.length < 1) {
      setStyle("none");
    }
    const apiJson = {
      keyWord: document.getElementById(mode).value,
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("adminReports/getLabDetails", apiJson).then((data) => {
      if (data.data.success === "1") {
        document.getElementById(dropdownId).style.display = "block";
        setFreeArticleData1(data.data.autoLabData);
        document.addEventListener("mouseup", function (e) {
          var container = document.getElementById(dropdownId);
          if (container !== null) {
            if (!container.contains(e.target)) {
              container.style.display = "none";
            }
          }
        });
      }
    });
  };
  const setFreeArticleData = (
    ServiceProviderId,
    itemName,
    mode,
    dropdownId
  ) => {
    document.getElementById(mode).value = itemName;
    setFreeArticleData1([]);
    setServiceProviderId(ServiceProviderId);
    setId(id);
    window.sessionStorage.setItem("ServiceProviderId", ServiceProviderId);
    setStyle("block");
    document.getElementById(dropdownId).style.display = "none";
    types(ServiceProviderId);
  };
  const navigationChange = () => {
    switch (document.getElementById("labFormsId1").value) {
      case "1":
        window.location.href = "/patients/labOrderDentalPrint";
        break;
      case "2":
        break;
      case "3":
        break;
      default:
        break;
    }
  };

  const navigation = () => {
    window.sessionStorage.setItem(
      "setlabFormsId",
      document.getElementById("labFormsId").value.split("-")[1]
    );
    if (
      document.getElementById("labFormsId").value.split("-")[0] ==
      "REMOVABLE PROSTHESIS"
    ) {
      setId(id);
      window.location.href = "/patients/labOrderDentalPrint";
    } else if (
      document.getElementById("labFormsId").value.split("-")[0] ==
      "METAL FREE CERAMIC PROSTHESIS"
    ) {
      window.location.href = "/patients/labOrderMetalFreeCeramic";
    } else if (
      document.getElementById("labFormsId").value.split("-")[0] ==
      "METAL CERAMIC PROSTHESIS"
    ) {
      window.location.href = "/patients/labOrderMetalCeramic";
    } else if (
      document.getElementById("labFormsId").value.split("-")[0] ==
      "ORTHO CREATION"
    ) {
      window.location.href = "/patients/labOrderOrthoCreation";
    }
  };
  const types = (ServiceProviderId) => {
    const apiJson = {
      serviceProviderId: ServiceProviderId,
    };
    callingAPI("adminReports/labFormsDetails", apiJson).then((data) => {
      if (data.data.success === "1") {
        setLabformsNames(data.data.result.labformsNames);
        setService(data.data.result.labFormsName);
        setId(data.data.result.id);
      }
    });
  };
  return (
    <div className="coverWraper">
      <Header />
      <div className="contentWraper contentWraperScroll patientPayment plInvoice">
        <Leftmenu />
        <div className="rightSection">
          <Profiledetail />
          <div className="practiceManagementWrap">
            <div className="row practiceManagementRow">
              <div className="col-lg-8 col-md-12 practiceLeftSection">
                <div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
                  <div id="divPatientContent" class="sideMenuContent">
                    <div class="treatmentPlanCover" id="treatmentPlanListDiv">
                      <div class="treatmentPlanHead">
                        <span class="treatmentPlanName">LAB ORDER &nbsp;</span>
                        <a href="/Patients/labOrderDental">
                          <span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
                            Cancel
                          </span>
                        </a>
                        <span
                          class="treatmentPlanBtn pl_blue_btn"
                          style={{ background: "transparent", padding: "0" }}
                        ></span>
                        <span
                          class="treatmentPlanBtn pl_blue_btn"
                          style={{ background: "transparent", padding: "0" }}
                        ></span>
                      </div>
                      <div
                        id="showLab"
                        class="dentalLabOrdersShowLab_PTour"
                        onClick={(e) => autoHide()}
                      >
                        <div class="newAppoinmentRightList labnameListWrap">
                          <label
                            className="formLabel"
                            style={{ fontSize: "13px" }}
                          >
                            Lab Name
                          </label>
                          <div className="appointmentFormList">
                            <input
                              type="text"
                              id="freeArticleData1"
                              name="cityName"
                              tabindex="6"
                              autocomplete="off"
                              placeholder="Lab Name"
                              maxlength="100"
                              class="ui-autocomplete-input"
                              onClick={() =>
                                orderAt("freeArticleData1", "locationSearch1")
                              }
                              style={{ height: "34px" }}
                              onChange={() =>
                                orderAt("freeArticleData1", "locationSearch1")
                              }
                            />
                            <ul
                              className="autoSearch1 autoSearch-p0 appointmentNumberSearch labOrderDentalAddAutoSearch"
                              id="locationSearch1"
                              style={{ display: "none" }}
                            >
                              {freeArticleData1.map((freeArticleDataObj, i) => (
                                <li
                                  onClick={() =>
                                    setFreeArticleData(
                                      freeArticleDataObj.ServiceProviderId,
                                      freeArticleDataObj.Lab,
                                      "freeArticleData1",
                                      "locationSearch1"
                                    )
                                  }
                                >
                                  {freeArticleDataObj.Lab}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div
                          class="practiceTimelineDivision2 practiceLabName lbOrderSelect"
                          id="formIDs"
                          style={{ width: "40%" }}
                        >
                          <div
                            class="form-group mrgTop20a testDentalLabForm_PTour"
                            id="rightId"
                            style={{ display: "none" }}
                          >
                            <label>&nbsp;</label>
                            <select
                              id="labFormsId"
                              name="labFormsId"
                              maxlength="100"
                              class="popFieldField inputField"
                              style={{ width: "100%", border: "none" }}
                              onChange={navigation}
                            >
                              <option value="0">Select Form</option>
                              {labformsNames.map((item, i) => (
                                <option
                                  id="labFormsId1 "
                                  value={item.labFormsName + "-" + item.id}
                                >
                                  {item.labFormsName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 practiceRightSection">
                <Patientright />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default LabOrderDentalAdd;
