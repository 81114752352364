import React, { useEffect, useState } from "react";
import Logo from "../images/avatarHospitalDefault.png";
import { callingAPI } from "../config";
import { useLocation } from "react-router-dom";
import HeaderPrint from '../Settings/headerPrint';
function PrescriptionDetailsPrint(props) {
  const location = useLocation();
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [testGroupData, setTestGroupData] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  const [hospitalAddress, setHospitalAddress] = useState("");
  const [hospitalHeaderLeftText, setHospitalHeaderLeftText] = useState("");
  const [hospitalHeaderRightText, setHospitalHeaderRightText] = useState("");
  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberLastName, setMemberLastName] = useState("");
  const [memberMrNo, setMemberMrNo] = useState("");
  const [memberPrNo, setMemberPrNo] = useState("");
  const [memberAge, setMemberAge] = useState("");
  const [memberPhoneNo, setMemberPhoneNo] = useState("");
  const [memberGender, setMemberGender] = useState("");
  const [hospitalImagename, sethospitalImagename] = useState("");
  const [Date, setDate] = useState(""); 
  const [title, setTitle] = useState("");
  const [doctorDisplayName, setDoctorDisplayName] = useState("");
  const [education, setEducation] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [hospitalDescription, setHospitalDescription] = useState("");
  const [hospitalEmailId, setHospitalEmailId] = useState("");
  const [secUserEmailId, setSecUserEmailId] = useState("");
  useEffect(() => {
    SelectedPrint();

    callingAPI("commonUserFunctions/headerFooterLogoPrints", {
      hospitalId: props.hospitalDataId
        ? props.hospitalDataId
        : localStorage.getItem("hospitalId"),
      doctorId: "",
    }).then((res) => {
      sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
      //setMarginTop()
    });
  }, []);
  var getS3folderHospitalLogo =
    process.env.REACT_APP_S3_URI +
    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
    process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
  var valuetosendforprint = window.sessionStorage.getItem(
    "valuetosendforprint"
  );
  const SelectedPrint = () => {
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      userDate: window.sessionStorage.getItem("visitDate"),
      memberId: window.sessionStorage.getItem("memberId"),
      hidType:
        window.sessionStorage.getItem("PrescriptionIdValue") != ""
          ? "selected"
          : "all",
      medicineIdList: window.sessionStorage.getItem("PrescriptionIdValue"),
    };
    callingAPI("printLabTestDetails/printPrescriptionDetails", PostData).then(
      (response) => {
        if (response.data.success === "1") {
          setDoctorDetails(response.data.result.doctorDetails);
          setTestGroupData(response.data.result.testGroupData);
          setHospitalAddress(
            response.data.result.hospitalDetails[0].hospitalAddress
          );
          setHospitalHeaderLeftText(
            response.data.result.hospitalDetails[0].hospitalHeaderLeftText
          );
          setHospitalHeaderRightText(
            response.data.result.hospitalDetails[0].hospitalHeaderRightText
          );
          setMemberFirstName(
            response.data.result.memberDetails[0].memberFirstName
          );
          setMemberLastName(
            response.data.result.memberDetails[0].memberLastName
          );
          setMemberMrNo(response.data.result.memberDetails[0].memberMrNo);
          setMemberPrNo(response.data.result.memberDetails[0].memberPrNo);
          setMemberAge(response.data.result.memberDetails[0].memberAge);
          setTitle(response.data.result.doctorDetails[0].title);
          setDoctorDisplayName(
            response.data.result.doctorDetails[0].doctorDisplayName
          );
          setSecUserEmailId(response.data.result.memberDetails[0].secUserEmailid);
          setEducation(response.data.result.doctorDetails[0].education);
          setSpeciality(response.data.result.doctorDetails[0].speciality);
          setMemberPhoneNo(
            response.data.result.memberDetails[0].SecuserMobileNumber
          );
          setMemberGender(response.data.result.memberDetails[0].memberGender);
          setDate(response.data.result.memberDetails[0].Date);
          setHospitalDescription(
            response.data.result.hospitalDetails[0].hospitalDescription
          );
          setHospitalEmailId(
            response.data.result.hospitalDetails[0].hospitalEmailId
          );
        }
      }
    );
  };
  const PrintThisPage = () => {
    window.print();
  };
  return (
    <div>
      <div
        align="left"
        style={{
          width: "540px",
          margin: "auto",
          fontFamily: "SourceSansPro-Regular",
        }}
      >
        <div
          class="printBtm"
          style={{ textAlign: "center", clear: "both", overflow: "hidden" }}
        >
          <p
            onClick={PrintThisPage}
            style={{
              width: "70px",
              textAlign: "center",
              paddingTop: "5px",
              lineHeight: "18px",
              mdisplay: "inline-block",
              fontWeight: "bold",
              fontSize: "14px",
              color: "white",
              background: "rebeccapurple none repeat scroll 0% 0%",
              marginTop: "2px",
              cursor: "pointer",
              borderRadius: "5px",
              marginBottom: "2px",
              height: "30px",
              fontFamily: "Verdana",
            }}
          >
            Print
          </p>
        </div>
        
        <table style={{ width: "100%", marginTop: "0px" }}>
          {/* <thead>
          <HeaderPrint />
            <tr>
              <th style={{ fontWeight: "400" }}>
                <div class="printOutHeaderSec PrescPrint ">
                  <table style={{ width: "100%" }}>
                  
                    <tbody>
                      <tr height="80px" style={{ display: "table-row" }}>
                        <td colspan="2" valign="middle" align="center">
                          <div style={{ width: "100%", float: "none" }}>
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "400",
                                fontSize: "16px",
                                clear: "both",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                {hospitalImagename != (null || "") ? (
                                  <img
                                    src={
                                      getS3folderHospitalLogo +
                                      "/" +
                                      localStorage.getItem("hospitalId") +
                                      ".png "
                                    }
                                    style={{
                                      display: "block",
                                      width: "auto",
                                      maxHeight: "65px",
                                      margin: "auto",
                                    }}
                                    align="middle"
                                  />
                                ) : (
                                  <img
                                    src={
                                      Logo
                                    }
                                    style={{
                                      display: "block",
                                      width: "auto",
                                      maxHeight: "65px",
                                      margin: "auto",
                                    }}
                                    align="middle"
                                  />
                                )}
                              </span>
                            </p>
                          </div>
                          <div
                            class="practicePrintName"
                            style={{ width: "100%", paddingTop: "5px" }}
                          >
                            <p
                              align="Left"
                              style={{
                                fontWeight: "400",
                                fontSize: "13px",
                                clear: "both",
                                width: "100%",
                                margin: "0",
                              }}
                            >
                              {doctorDisplayName}
                              <br />
                              {education}
                              <br />
                              {speciality}
                            </p>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <hr color="#142536" style={{ margin: "2px 0" }} />
                        </td>
                      </tr>
                      <tr style={{ display: "table-row" }}>
                        <td
                          colspan="1"
                          style={{ fontSize: "12px", paddingBottom: "10px" }}
                        >
                          {hospitalHeaderLeftText}
                        </td>
                        <td
                          colspan="1"
                          style={{
                            fontSize: "12px",
                            paddingBottom: "10px",
                            textAlign: "right",
                          }}
                        >
                          {hospitalHeaderRightText}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <hr color="#142536" style={{ margin: "2px 0" }} />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "80%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            paddingTop: "5px",
                          }}
                        >
                          Name : {memberFirstName} {memberLastName}
                        </td>
                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                          (<td
                            style={{
                              width: "20%",
                              fontSize: "12px",
                              padding: "0",
                              lineHeight: "15px",
                              paddingTop: "5px",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            MR No : {memberMrNo}
                          </td>) :
                          (<td
                            style={{
                              width: "20%",
                              fontSize: "12px",
                              padding: "0",
                              lineHeight: "15px",
                              paddingTop: "5px",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            PR No : {memberPrNo}
                          </td>)
                        }
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "70%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                          }}
                        >
                          Age : {memberAge}
                        </td>
                        <td
                          style={{
                            width: "30%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            textAlign: "right",
                          }}
                        >
                          Mobile No : {memberPhoneNo}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "70%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                          }}
                        >
                          Gender : {memberGender}
                        </td>

                        <td
                          style={{
                            width: "30%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          Date : {Date}{" "}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <hr color="#142536" style={{ margin: "2px 0" }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </th>
            </tr>
          </thead> */}
          {/*  ============ UPDATED HEADER PRINT BEGINS ================  */}
          <thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>

											<HeaderPrint />
										</tbody>

										<tbody>
                    <div
                            class="practicePrintName"
                            style={{ width: "100%", paddingTop: "5px" }}
                          >
                            <p
                              align="Left"
                              style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                clear: "both",
                                width: "max-content",
                                margin: "0",fontFamily:"Arial", color:"#000"
                              }}
                            >
                              {title}{doctorDisplayName}
                              <br />
                              {education}
                              <br />
                              {speciality}
                            </p>
                          </div>
                    <tr>
                <td colspan="12">
                    <hr
                        color="#142536"
                        style={{ margin: '0', opacity: '1', height: '2px' }}
                    />
                </td>
            </tr>
          
            {/*  */}

											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}

												>
													Name : {memberFirstName} {memberLastName}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Date : {Date}{" "}
												</td>
											</tr>

											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Age : {memberAge}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Email Id : {secUserEmailId}
												</td>


											</tr>
											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Sex : {memberGender}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Mobile No : {memberPhoneNo}
												</td>

											</tr>
											<tr>
											{window.sessionStorage.getItem('isPrNumEnabled') === "false" ? 
													(memberMrNo && 
													<td
														colspan="12"
														style={{
															width: '100%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														MR No : {memberMrNo}
													</td>
													) 
													:
													(memberPrNo && 
													<td 
															colspan="12"
															style={{
																width: '100%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
													>PR No : {memberPrNo}</td> 
													)
													} 
											</tr>
											{/* <tr>
												{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
													(<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														MR No : {memberDetails.memberMrNUmber}
													</td>) :
													(!memberDetails.memberPrNo == "" &&
														<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'right',
															}}
														>
															PR No : {memberDetails.memberPrNUmber}
														</td>
													)}

											</tr> */}


											<tr>
												<td colspan="12">
													<hr
														color="#142536"
														style={{ margin: '0', opacity: '1', height: '2px' }}
													/>
												</td>
											</tr>


										</tbody>

									</table>
								</div>
							</th>
						</tr>
					</thead>
          {/*  ============ UPDATED HEADER PRINT ENDS ================  */}
          <tbody>
            <tr>
              <td>
                <div class="printOutSettingContent">
                  <div
                    align="left"
                    style={{ display: "block", fontSize: "13px" }}
                  >
                    Rx
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="printOutSettingContent">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr style={{ borderBottom: "2px solid black" }}>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "60px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Medicine
                        </td>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "125px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Frequency & Dosage
                        </td>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "60px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Intake
                        </td>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "35px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Qty
                        </td>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "40px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Days
                        </td>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "60px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Start Date
                        </td>
                        <td
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            width: "70px",
                            padding: "4px 2px 4px 0",
                            boxSizing: "border-box",
                          }}
                        >
                          Instructions
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="printOutSettingContent">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {testGroupData.map((item) => (
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "60px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "initial",
                              }}
                            >
                              {item.medicineName + " " + item.medType + " " + item.strength + " " + item.medUnit}
                            </p>
                          </td>

                          <td
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "125px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "break-word",
                              }}
                            >
                              {" "}
                              {item.instructions.slice(
                                0,
                                item.instructions.lastIndexOf("-")
                              )}{" "}
                            </p>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "60px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "break-word",
                              }}
                            >
                              {item.intake}
                            </p>
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "35px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "break-word",
                              }}
                            >
                              {item.quantity === "" ? 0 : item.quantity}
                            </p>
                          </td>

                          <td
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "40px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "break-word",
                              }}
                            >
                              {item.days}
                            </p>
                          </td>

                          <td
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "60px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "break-word",
                              }}
                            >
                              {" "}
                              {item.startDate}
                            </p>
                          </td>

                          <td
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "15px",
                              width: "70px",
                              padding: "0px 2px 0px 0",
                              boxSizing: "border-box",
                              color: "#393939",
                            }}
                          >
                            <p
                              style={{
                                wordBreak: "break-all",
                                margin: "3px 0",
                                fontSize: "12px !important",
                                wordBreak: "break-word",
                              }}
                            >
                              {item.reason}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div class="printOutFooterSec">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr style={{ display: "table-row" }}>
                        <td>
                          <div
                            align="justify"
                            class="practiceFootBtm"
                            style={{ lineHeight: "15px" }}
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <hr color="#142536" style={{ margin: "2px 0" }} />
                        </td>
                      </tr>

                      <tr style={{ display: "table-row" }}>
                        <td
                          style={{
                            paddingTop: "0px",
                            fontSize: "11px",
                            color: "#000",
                          }}
                        >
                          <div style={{ float: "left" }}></div>
                          <div style={{ textAlign: "Center" }}>
                            {hospitalDescription}
                            {hospitalEmailId}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
export default PrescriptionDetailsPrint;
