import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Active from '../images/icons/active.png';
import Edit from '../images/icons/edit-bg.png';
import Inactive from '../images/icons/inactive.png';
import { Link } from 'react-router-dom';
import Youtube from '../images/icons/youtube.png';

import { callingAPI } from '../config';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { useNavigate } from 'react-router-dom';
//
function StaffList() {
	const navigate = useNavigate();
	const [isEnabled, setIsEnabled] = useState(true);
	const [staffList, setStaffList] = useState([]);
	const [isData, setIsData] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [searchValue, setsearchValue] = useState('');
	const [filterName, setFilterInput] = useState('');
	const [activePage, setActivePage] = useState(window.sessionStorage.getItem('pageNumberstaff'));
	const [pageOffset, setPageOffset] = useState(0);
	const [textfieldcolor, settextfieldcolor] = useState(true);
	const [count, setcount] = useState();
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const paginationOnPageChange = (pageNumber) => {
		window.sessionStorage.setItem('pageNumberstaff', pageNumber)
		setActivePage(pageNumber);
		var offset;
		var maxlimit;
		if (pageNumber === '1') {
			offset = '0';
			maxlimit = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			maxlimit = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) {
			setFilterInput("");
		}
		if (!booliansearchValue) { setsearchValue("") }


		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			maxlimit: maxlimit,
			offset: offset,
			search: boolianfilterName,
			inputTxt: booliansearchValue,
		};
		callingAPI('staff/staffList', PostData)
			.then((response) => {
				if (response.data.successMessage == "") {
					setStaffList([]);
					setcount(0);
				}
				if (response.data.success == "1") {
					setStaffList(response.data.result.StaffList);
					setcount(response.data.result.totalCount);
				}
			})
			.catch();
	};
	const searchValueChange = (value) => {
		setsearchValue(value);
	};
	const setactivepagenumber = (activepagestaff,data) => {

		window.sessionStorage.setItem("activepagestaff", activepagestaff)
		navigate('/clinics/staffEdit', { state:  data  })


	}
	const changeTextonTextArea = (value) => {

		settextfieldcolor(false)

		setFilterInput(value);
		setsearchValue('');
		if (value === '') {
			settextfieldcolor(true)
			setIsEnabled(true);
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			setIsEnabled(false);
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const searchValueOnChangeOnSelect = (event) => {
		setActivePage(1);
		event.preventDefault();
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('selectName').focus();
			alert("Enter" + " " + selectedText);
			document.getElementById('searchText').focus();
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(filterName);

		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: 0,
			maxlimit: process.env.REACT_APP_PAGINATION_COUNT,
			search: filterName,
			inputTxt: searchValue,
		};
		callingAPI('staff/staffList', PostData)
			.then((response) => {
				if (response.data.successMessage == "") {
					setStaffList([]);
					setcount(0);
				}
				if (response.data.success === '1') {

					setStaffList(response.data.result.StaffList);
					setcount(response.data.result.totalCount);
				}
			})
			.catch();
	};



	function StaffListForActivateandInActive() {
		setActivePage(Number(window.sessionStorage.getItem('pageNumberstaff')))

		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: window.sessionStorage.getItem('activepagestaff') == null ? 0 : window.sessionStorage.getItem('activepagestaff'),
			maxlimit: process.env.REACT_APP_PAGINATION_COUNT,
			search: filterName,
			inputTxt: searchValue,
		};

		callingAPI('staff/staffList', PostData)
			.then((response) => {

				if (response.data.success === '0') {
					setStaffList([]);
					setcount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					setDataFlag(true);
					setStaffList(response.data.result.StaffList);
					setcount(response.data.result.totalCount);
				}
			})
			.catch();
	}

	useEffect(() => {
		StaffListForActivateandInActive();

		// window.addEventListener("beforeunload", handleBeforeUnload);
		// return () => {
		// 	window.removeEventListener("beforeunload", handleBeforeUnload);
		// };
	}, []);
	const handleBeforeUnload = (e) => {
		e.preventDefault();
		window.sessionStorage.setItem('pageNumberstaff', 1)
		window.sessionStorage.setItem("activepagestaff", 0)
	}
	// const handleBeforeUnload = (e) => {
	//     e.preventDefault();
	// 	window.sessionStorage.setItem('pageNumberstaff',1)
	// 	window.sessionStorage.setItem("activepagestaff", 0)
	//   };
	const ChangeEnableDisable = (secUserId, status) => {
		var x = false;
		if (status === true) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		if (x === true) {
			let PostData = {
				enabled: status == false ? true : false,
				secUserId: secUserId,
			};

			callingAPI('staff/activateInactivate', PostData).then((response) => {
				if (response.data.success == 1) {
					StaffListForActivateandInActive();
				} else {
					setStaffList([]);
				}
			});
		}
	};

	return (
		<div className="coverWraper">
			<Header />

			<div className="contentWraper contentWraperScroll pl_staffList">
				<div class="pulseContentCoverWraper">
					<div class="container">
						<div class="content4">
							<div class="container">
								<div class="container">
									<div />

									<div class="tableHolder StaffList-MainDiv">
										<div class="left" style={{ float: 'left', width: '100%' }}>
											{/* <a
												href=""
												class="practiceGreenButton"
												style={{
													marginTop: '10px',
													textDecoration: 'none',
													border: 'none',
												}}
											>
												<img src={Youtube} />
											</a> */}
											<a
												href="/clinics/staffAdd"
												class="practiceGreenButton"
												style={{
													marginRight: '0',
													marginTop: '10px',
													marginLeft: '8px',
													textDecoration: 'none',
												}}
											>
												Add Staff
											</a>

											<a
												href="/clinics/staffSetPermission"
												class="practiceGreenButton"
												style={{ marginTop: '10px', textDecoration: 'none' }}
											>
												Set Permissions
											</a>
										</div>
										<h3>Staff List</h3>
										{count > 10 && (
											<div className="paginationSection">
												<Pagination
													activePage={activePage}
													itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
													totalItemsCount={count}
													pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
													onChange={paginationOnPageChange}
												/>
											</div>)}

										<div class="tableheadHolder tableTopHold tableTopHoldButton">
											<form>
												<select
													class="mdmField inputField"
													id="selectName"
													name="selectName"
													value={filterName}
													onChange={(e) => changeTextonTextArea(e.target.value)}
													style={{ border: '1px solid #000', borderRadius: '0px' }}
												// onChange={(e) => changeTextonTextArea(e.target.value)}
												>
													<option value="" selected="">
														Search In
													</option>
													<option value="1">ID</option>
													<option value="2">User Role</option>
													<option value="3">Email Id</option>
													<option value="4">Name</option>
												</select>
												{/* </div> */}
												<div class="selctFieldHolder">
													<input
														class="mdmField inputField"
														value={searchValue}
														onChange={(e) => searchValueChange(e.target.value)}
														id="searchText"
														name="searchText"
														placeholder=""
														type="text"
														disabled={isEnabled}
														style={{ border: '1px solid #000', borderRadius: '0px', background: textfieldcolor ? '#efeded' : "#ffffff" }}
													/>
												</div>
												<div class="selctFieldHolder pl_search">
													<input
														onClick={searchValueOnChangeOnSelect}
														type="submit"
														name="_action_staffList"
														value="Search"
														class="btn_upload btn-primary btnOrange white"
														title="Search"
														style={{ background: '#337ab7' }}
													/>
												</div>


												<div class="selctFieldHolder">
													<div className="searchCount">
														<b>Total Staff : {count}</b>
													</div>
												</div>

											</form>
										</div>
										<br />
										<br />
										{dataFlag == false ? (
											<Loader />
										) : staffList.length > 0 ? (
											<div class="col-md-12 overflow-tableOuter">
												<table class="table staffListTble ">
													<thead>
														<tr>
															<th class="tableCntWdth" colspan="2">
																Staff Id{' '}
															</th>

															<th class="tableCntWdth" colspan="3">
																Role - Category
															</th>
															<th class="tableCntWdth" colspan="3">
																Staff Name
															</th>
															<th class="tableCntWdth" colspan="3">
																Email
															</th>
															<th class="tableCntWdth" colspan="3">
																&nbsp;
															</th>
															<th class="tableCntWdth" colspan="3">
																&nbsp;
															</th>
														</tr>
													</thead>
													<tbody>
														{staffList.map((item, i) => (
															<tr>
																<td colspan="2">{item.hospitalStaffstaffId}</td>
																<td colspan="3">{item.categoryName}</td>
																<td colspan="3">{item.secUserDisplayName}</td>
																<td colspan="3">{item.secUserEmailId}</td>

																<td colspan="4">
																	<span
																		class="edit_align"
																		title="Edit"
																		style={{ textAlign: 'left!important' }}
																	>
																		<a>
																			<span
																				onClick={() => setactivepagenumber(pageOffset,{
																					hospitalStaffstaffId:
																						item.hospitalStaffstaffId,
																					secUserDisplayName:
																						item.secUserDisplayName,
																					categoryName: item.categoryName,
																					secUserEmailId:
																						item.secUserEmailId,
																					categoryId: item.categoryId,
																					ipAddress: item.ipAddress,
																					macAddress: item.macAddress,
																					password: item.password,
																				})}
																				// to={{
																				// 	pathname: '/clinics/staffEdit',
																				// 	state: {
																				// 		hospitalStaffstaffId:
																				// 			item.hospitalStaffstaffId,
																				// 		secUserDisplayName:
																				// 			item.secUserDisplayName,
																				// 		categoryName: item.categoryName,
																				// 		secUserEmailId:
																				// 			item.secUserEmailId,
																				// 		categoryId: item.categoryId,
																				// 		ipAddress: item.ipAddress,
																				// 		macAddress: item.macAddress,
																				// 		password: item.password,
																				// 	},
																				// }}
																			>
																				<img src={Edit} />
																			</span>
																		</a>
																	</span>
																</td>
																<td colspan="4">
																	<span
																		class="edit_align"
																		title="Edit"
																		style={{ textAlign: 'left!important' }}
																	>
																		{item.enabled === true ? (
																			<a
																				title="Click to Deactivate"
																				onClick={(e) =>
																					ChangeEnableDisable(
																						item.secUserId,
																						true
																					)}
																			>
																				<img src={Active} />
																			</a>
																		) : (
																			<a
																				title="Click to Activate"
																				onClick={(e) =>
																					ChangeEnableDisable(
																						item.secUserId,
																						false
																					)}
																			>
																				<img src={Inactive} />
																			</a>
																		)}
																	</span>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										) : (
											<p>
												<NoData />
											</p>
										)}

										<div class="container">
											<div class="doc pagination prctmngmtPageWrap" />
										</div>
									</div>
									{count > 10 && (
										<div className="paginationSection">

											<Pagination
												activePage={activePage}
												itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
												totalItemsCount={count}
												pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
												onChange={paginationOnPageChange}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div class="dividerSml" />
				</div>

				{/* <div className="paginationSection">
					<Pagination
						hideNavigation
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
						onChange={paginationOnPageChange}
					/>
				</div> */}
			</div>

			<Footer />
		</div>
	);
}

export default StaffList;
