import React, { useState, useEffect, Suspense } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callingAPI } from '../config';
import {
    faArFooterrowLeft,
    faHome,
    faUser,
    faWallet,
    faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
// import Visitors from './dashboardView/visitors'
let Header = React.lazy(() => import("../layout/header"))
let Footer = React.lazy(() => import("../layout/footer"))
let TodayTotalAppoimentSec = React.lazy(() => import("./dashboardView/totalAppoimentIncomeDetails"))
let TodayAppoinmentStatus = React.lazy(() => import("./dashboardView/todayAppoinmentStatus"))
let TodoListStatusView = React.lazy(() => import("../Dashboard/dashboardView/todoListStatusView"))
let PatientRecallStatusView = React.lazy(() => import("./dashboardView/patientRecallListView"))
let LaborderList = React.lazy(() => import("./dashboardView/laborderListDashboard"))
let LaborderStatus = React.lazy(() => import("./dashboardView/laborderStatus"))
let Mostprescribedmedcine = React.lazy(() => import("./dashboardView/mostprescribedmedcine"))
let MostprescribedmedcineAmount = React.lazy(() => import("./dashboardView/mostprescribedmedcineAmount"))
let VisitorStaticsApoinments = React.lazy(() => import("./dashboardView/visitorStaticsApoinments"))
let TotalIncome = React.lazy(() => import("./dashboardView/totalIncome"))
let PatientHistory = React.lazy(() => import("./dashboardView/patientHistory"))

// import { Route } from 'react-router-dom';
function DashboardView() {
    const [dataFlag, setDataFlag] = useState(false);
    const [totalTreatmentIncome, setTotalTreatmentIncome] = useState("");
    const [dueAmount, setDueAmount] = useState("")
    const [advanceAmount, setAdvanceAmount] = useState("")
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const currentYearMonth = `${currentYear}-${currentMonth}`;
    const oneYearBefore = new Date(currentDate.getFullYear() - 1, currentDate.getMonth());
    const year = oneYearBefore.getFullYear();
    const month = (oneYearBefore.getMonth() + 1).toString().padStart(2, '0');
    const yearMonth = `${year}-${month}`;
    const [xAxis, setXAxis] = useState([])
    const [totalExpense, setTotalExpense] = useState([])
    const [totalIncomes, setTotalIncomes] = useState([])

    useEffect(() => {
        const PostData = {
            hospitalId: localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/hospitalIncomeDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setDataFlag(true);
                setTotalTreatmentIncome(response.data.result.totalTreatmentIncome);
                setDueAmount(response.data.result.dueAmount);
                setAdvanceAmount(response.data.result.advanceAmount)
            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);

    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            // doctorId: role === "ROLE_DOCTOR" ? window.sessionStorage.getItem('doctorId') : "",
            // hospitalId: role === "ROLE_DOCTOR" ? "" : localStorage.getItem("hospitalId"),
            userId: window.sessionStorage.getItem("userId"),
            textInput: "",
            fromDate: yearMonth,
            toDate: currentYearMonth,
            type: "",
            hospitalId: localStorage.getItem('hospitalId'),
        };
        callingAPI('removePatientAppointment/incomeAndExpenseHistory', PostData).then((response) => {
            if (response.data.success === '1') {
                setDataFlag(true);
                setXAxis(response.data.result.incomeExpenseArray.map((obj) => obj.date))
                setTotalExpense(response.data.result.incomeExpenseArray.map((obj) => parseInt(obj.incomeAndExpenseDetails.totalExpense)))
                setTotalIncomes(response.data.result.incomeExpenseArray.map((obj) => parseInt(obj.incomeAndExpenseDetails.totalIncome)))

            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);

    return (
        <Suspense fallback={<div></div>}>
            <div className="coverWraper">
                <Header />
                <div className="contentWraper contentWraperScroll">
                    <div className='main_wrappersec main_wrapper_noleftview'>
                        <div className="subheader_topsec">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-4 col-sm-12">
                                </div>
                                <div className="col-lg-6 col-md-8 col-sm-12">
                                    <div className="row">
                                        <div className="row clearfix" style={{ paddingRight: "0" }}>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                                <a href="/totalPendingPayment" style={{ textDecoration: "none" }}>
                                                    <div className="card-topsec bg-tot_due">
                                                        <h4 className='card-topsechead'><FontAwesomeIcon icon={faWallet} />&nbsp;₹&nbsp;{dueAmount}</h4>
                                                        <span className='card-rvnutxt'>Total Pending Payments</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="total_wrapper total_wrapper-paddTop">
                            <div className="row">
                                <TodayTotalAppoimentSec />
                                <div className="row col-md-9" style={{ paddingRight: "0" }}>
                                    <div className="col-lg-6 col-md-12">
                                        <TodayAppoinmentStatus />
                                    </div>
                                    <div className="col-lg-6 col-md-12" style={{ paddingRight: "0" }}>
                                        <div>
                                            <TodoListStatusView />
                                        </div>
                                        <div>
                                            <PatientRecallStatusView />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ================================= */}
                        <div className="labOrderListWrap">
                            <div className="row">
                                <div className="col-lg-9 col-md-12">
                                    <LaborderList />
                                </div>
                                <div className="col-lg-3 col-md-12">
                                    <LaborderStatus />
                                </div>
                            </div>
                        </div>
                        {/* ================================= */}
                        <>
                            <MostprescribedmedcineAmount />
                        </>
                        <div className="visitorsstat_wrapper">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <VisitorStaticsApoinments />
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <>
                                        <Mostprescribedmedcine />
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <TotalIncome />
                            </div>
                            <div className="col-md-6">
                                <div className="patienthistry_wrpr">
                                    <div className="col-md-12">
                                        <div className="total_revnuheader">
                                            <h2 className='total_revnuheadtxt'>Income/Expense History</h2>
                                        </div>
                                        {xAxis.length > 0 ?
                                            <div>
                                                <PatientHistory key={[xAxis, totalExpense, totalIncomes]} xAxis={xAxis} totalExpense={totalExpense} totalIncomes={totalIncomes} />
                                            </div>
                                            :
                                            <div className='todayAppScroll nodataFnd nodata-found_wrpr'>
                                                <p>No Income/Expense History Found </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}
export default DashboardView;
