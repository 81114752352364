import React from 'react';

function NoAssess() {
	return (
		<div className="practiceManagementForm" style={{ border: '1px solid #dadada' }}>
			<div className="noDataFound">
				<p>No Assessments Found</p>
			</div>
		</div>
	);
}
export default NoAssess;
