

import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { callingAPI } from '../config';

import Loader from '../Common/loader';
const SimplifiedFormLabOrder = forwardRef((props, ref) => {


	const [labTestListing, setLabTestListing] = useState([]);
	const [testName, setTestName] = useState('');
	const [labOrderItems, setLabOrderItems] = useState([]);

	const [changeTo, setChangeTo] = useState('');
	const [labData, setLabData] = useState([]);
	const [specimenType, setSpecimenType] = useState([]);
	const [typeOfTest, setTypeOfTest] = useState([]);
	const [freeArticleData1, setFreeArticleData1] = useState([]);
	// const [getServiceProviderId, setServiceProviderId] = useState('');
	// const [serviceProviderId, setServiceProviderIdEdit] = useState('');
	const [labOrderTestId, setLabOrderTestId] = useState('');

	const [hidTestStatus, setHidTestStatus] = useState('');
	const [getLabOrdercomments, setLabOrdercomments] = useState('');
	const [load, setLoad] = useState(false);


	const [labDataArray, setLabDataArray] = useState({
		procedureNameSave: '',
		cmbSpecimen1: '',
		cmbtypeofTest1: '',
		procedureAmount: '',
		addNote: '',
		txtAmount1: '',
		txtTest1: '',
		txtTest2: '',
		typeName: '',
		specimenName: '',
	});

	const autoLabData = () => {
		const apiJson = {
			keyWord: '',
		};
		callingAPI('adminReports/getLabDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				setLabData(data.data.autoLabData);
				setLoad(true);
			}

		});
	};
	const typeDatas = () => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('adminReports/specimenTypeOftest', apiJson).then((data) => {
			if (data.data.success === '1') {
				setSpecimenType(data.data.result.specimenType);
				setTypeOfTest(data.data.result.typeOfTest);
				setLoad(true);
			}

		});
	};
	const orderAt = (mode, dropdownId) => {
		document.getElementById(dropdownId).style.display = 'none';
		const apiJson = {
			keyWord: document.getElementById(mode).value,
			hospitalId: localStorage.getItem("hospitalId")
		};
		callingAPI('adminReports/getLabDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				setLoad(true);
				document.getElementById(dropdownId).style.display = 'block';
				setFreeArticleData1(data.data.autoLabData);
				document.addEventListener('mouseup', function (e) {
					var container = document.getElementById(dropdownId);
					if (container !== null) {
						if (!container.contains(e.target)) {
							container.style.display = 'none';
						}
					}
				});
			}
		});
	};
	const setFreeArticleData = (ServiceProviderId, itemName, mode, dropdownId) => {
		document.getElementById(mode).value = itemName;
		setFreeArticleData1([]);
		// setServiceProviderId(ServiceProviderId);
	};
	const searchProcedure = (event) => {
		const { name, value } = event.target;
		setTestName(event.target.value);
		LabTestListing(event.target.value);
	};
	const ChangeTo = (e, i) => {
		const { name, value } = e.target;
		const values = [...labOrderItems];
		values[i] = { ...values[i], [name]: value };
		setLabOrderItems(values);


	};
	props.onLaborder(labOrderItems);

	const addNotes = (p, addNotesLink1, addNote) => {
		document.getElementById(addNote).style.display = 'block';
		document.getElementById(addNotesLink1).style.display = 'none';
	};
	const onChange = (event) => {
		const { name, value } = event.target;
		setLabDataArray((prevState) => ({ ...prevState, [name]: value }));
	};
	const autoHide = () => {
		document.getElementById('locationSearch1').style.display = 'none';
	};


	const removeTreatmentPlan = (e, p, id) => {

		let removed = [...labOrderItems];
		removed.splice(p, 1);
		setLabOrderItems(removed);

	};

	const addNewLabOrder = (labOrderObj, i) => {
		var arrayObj = {
			txtTest1: labOrderObj.Test,
			txtTest2: labOrderObj.TestId,
			cmbSpecimen1: '',
			cmbtypeofTest1: '',
			txtAmount1: labOrderObj.buy_price,
			addNote: '',
			labOrderTestId: "",

		};

		setLabOrderItems([...labOrderItems, arrayObj]);
	};

	const LabOrderSave = () => {
		if (labOrderItems.length == 0) {
			alert("Add One lab Test")

			return false;

		}
		var serviceProviderTestDetailsArray = [];
		for (var i = 0; i < labOrderItems.length; i++) {
			var obj = {
				serviceProviderTestDetailsTestSpecimen: document.getElementById('cmbSpecimen1' + i).value,
				serviceProviderTestDetailsTypeofTest: document.getElementById('typeName' + i).value,
				doctorVisitLabOrderTestComments: document.getElementById('addNote' + i).value,
				serviceProviderTestDetailsTestAmount: labOrderItems[i].txtAmount1,
				serviceProviderTestDetailsTestName: labOrderItems[i].txtTest2,
				hidlaborderTestId: labOrderItems[i].labOrderTestId,
				hidTestStatus: ''
			};
			serviceProviderTestDetailsArray.push(obj);
		}
		var flag = true;
		if (flag === true) {
			setLoad(false);
			const postData = {
				hospitalId: localStorage.getItem('hospitalId'),
				hidOrderID: '',
				doctorVisitId: '',
				memberId: window.sessionStorage.getItem('memberId'),
				userId: window.sessionStorage.getItem('userId'),
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				serviceProviderId: '',
				hidlaborderTestId: labOrderTestId.toString(),
				serviceProviderTestDetailsTestCount: '1',
				doctorVisitLabOrderComments: getLabOrdercomments,
				serviceProviderTestDetails: serviceProviderTestDetailsArray,
			};
			callingAPI('adminReports/saveLabOrder', postData)
				.then((data) => {
					if (data.data.success === '1') {
						window.location.href = '/patients/patientconsult';
						// alert(data.data.successMessage);

						//	alert('Lab Test Saved Successfully');

						// window.location.href = '/Patients/simplifiedForm';
					} else {
						alert(data.data.errorMessage);
						setLoad(true);
					}
				})
				.catch();
		}
	};
	const LabTestListing = (testName) => {
		const apiJson = {
			testName: testName === undefined ? '' : testName,
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('adminReports/searchLabTest', apiJson).then((data) => {
			if (data.data.success === '1') {
				setLoad(true);
				setLabTestListing(data.data.labTests);
			}
		});
	};

	useImperativeHandle(ref, () => ({
		showLabOrder() {
			if (labOrderItems.length == 0) {
				alert("Add One lab Test")
	
				return false;
	
			}
			var serviceProviderTestDetailsArray = [];
			for (var i = 0; i < labOrderItems.length; i++) {
				var obj = {
					serviceProviderTestDetailsTestSpecimen: document.getElementById('cmbSpecimen1' + i).value,
					serviceProviderTestDetailsTypeofTest: document.getElementById('typeName' + i).value,
					doctorVisitLabOrderTestComments: document.getElementById('addNote' + i).value,
					serviceProviderTestDetailsTestAmount: labOrderItems[i].txtAmount1,
					serviceProviderTestDetailsTestName: labOrderItems[i].txtTest2,
					hidlaborderTestId: labOrderItems[i].labOrderTestId,
					hidTestStatus: ''
				};
				serviceProviderTestDetailsArray.push(obj);
			}
			var flag = true;
			if (flag === true) {
				setLoad(false);
				const postData = {
					hospitalId: localStorage.getItem('hospitalId'),
					hidOrderID: '',
					doctorVisitId: '',
					memberId: window.sessionStorage.getItem('memberId'),
					userId: window.sessionStorage.getItem('userId'),
					doctorId: window.sessionStorage.getItem('patientDoctorId'),
					serviceProviderId: '',
					hidlaborderTestId: labOrderTestId.toString(),
					serviceProviderTestDetailsTestCount: '1',
					doctorVisitLabOrderComments: getLabOrdercomments,
					serviceProviderTestDetails: serviceProviderTestDetailsArray,
				};
				return postData
			}
				
			// saveData()
			// alert("Hello from laborder")
		},
	}))
	const saveData = () => {

		LabOrderSave();

		// this.refs.labReference.LabOrderSave()
	}


	useEffect(() => {
		// autoLabData();
		LabTestListing();
		typeDatas();
	}, []);


	return (
		<div className="coverWraper">
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap simplifiedForm">
				<div className="rightSection simplifiedLabrightSection" style={{ paddingLeft: "" }}>

					{load == false ? <Loader /> : ''}
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-8 practiceLeftSection" style={{ padding: "0px" }}>
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">

											<div class="treatmentClinicContent simplifiedAccordion" id="treatmentClinicContentId">
												{/* ====================== MEDICAL CONTENTS STARTS HERE ========================== */}

												<div class="accordion__button" style={{ marginBottom: '0px' }}  >
													<div class="clinicContentList">
														<h2>Lab Order</h2>
													</div>
													<button>-</button>
												</div>
												{/* <span
												 onClick={saveData}
											class="treatmentPlanBtn pl_blue_btn"
											id="saveid"
											style={{ background: 'red', display: 'block' }}
										>
											Save
										</span> */}
												{/* ////////////////////////// */}
												<div class="labOrderContentCover" id="divAddLabOrder">
													<div
														class="treatmentWrap labOrderTestSavedEditProceDetails_PTour"
														id="treatmentPlanSection"
													>
														<div>
															<div class="treatmentsList precscriptionTrtmntList">
																<div
																	class="practiceManagementForm"
																	style={{ padding: '0px 0px', margin: '0px' }}
																>
																	{labOrderItems.length ? (
																		<div
																			id="mainTeStListingDiv"
																			class="practiceManagementFormBlcok"
																			style={{ margin: '0px', display: 'block' }}
																		>
																			<div class="col-md-12 col-lg-12 form_registrationPatient">
																				<div
																					id="labTest"
																					class="labOrderTestEditingProceDetails_PTour"
																					style={{
																						minHeight: 'auto',
																						maxHeight: '290px',
																						overflowY: 'auto',
																					}}
																				>
																					{labOrderItems.map((labOrderObj, p) => (
																						<div
																							id="innerEduDiv1"
																							class="prescriptionTrtmntListItem"
																						>
																							<div className="plrmv">
																								<span
																									class="treatmentContentClose1"
																									title="Remove LabOrder"
																									onClick={(e) =>
																										removeTreatmentPlan(
																											e,
																											p
																										)}
																								>
																									x
																								</span>
																							</div>
																							<ul className="row">
																								<li class="col-md-4 col-lg-3">
																									<label>Test Name</label>
																									<input
																										type="text"
																										id="txtTest1"
																										readonly="readonly"
																										name="txtTest1"
																										tabindex="2"
																										autocomplete="off"
																										autofocus=""
																										placeholder="Test Name"
																										maxlength="100"
																										value={
																											labOrderObj.txtTest1
																										}
																									/>
																								</li>
																								<li class="col-md-3 col-lg-3">
																									<label>Specimen</label>
																									<select
																										class="fulWidthSelect"
																										id={
																											'cmbSpecimen1' +
																											p
																										}
																										name="cmbSpecimen1"
																										onChange={(e) =>
																											ChangeTo(e, p)}
																										value={
																											labOrderObj.cmbSpecimen1
																										}
																									>
																										<option value="">
																											Select
																										</option>
																										{specimenType.map(
																											(data, p) => (
																												<option
																													value={
																														data.specimenTypeId
																													}
																												>
																													{
																														data.specimenName
																													}
																												</option>
																											)
																										)}
																									</select>
																								</li>
																								<li class="col-md-3 col-lg-3">
																									<label>
																										Type Of Test
																									</label>
																									<select
																										class="fulWidthSelect"
																										id={
																											'typeName' +
																											p
																										}
																										name="typeName"
																										onChange={(e) =>
																											ChangeTo(e, p)}
																										value={
																											labOrderObj.typeName

																										}
																									>
																										<option value="">
																											Select
																										</option>
																										{typeOfTest.map(
																											(data, p) => (
																												<option
																													value={
																														data.typeId
																													}
																												>
																													{
																														data.typeName}
																												</option>
																											)
																										)}
																									</select>
																								</li>
																								<li class="col-md-3 col-lg-3">
																									<label>Amount</label>
																									<input
																										type="text"
																										id="txtAmount1"
																										name="txtAmount1"
																										tabindex="2"
																										autocomplete="off"
																										readonly="readonly"
																										autofocus=""
																										placeholder="Amount"
																										maxlength="100"
																										value={
																											labOrderObj.txtAmount1 ===
																												'' || labOrderObj.txtAmount1 === null ? (
																												'NA'
																											) : (
																												labOrderObj.txtAmount1 +
																												'.00'
																											)
																										}
																									/>
																								</li>
																								<li class="col-md-8 col-lg-8">
																									<div class="treatmentNote">
																										<span
																											id={
																												'addNotesLink1' +
																												p
																											}
																											name={
																												'addNotesLink1' +
																												p
																											}
																											onClick={() =>
																												addNotes(
																													p,
																													'addNotesLink1' +
																													p,
																													'addNote' +
																													p
																												)}
																										>
																											+ add notes
																										</span>
																										<input
																											style={{
																												display:
																													'none',
																											}}
																											id={
																												'addNote' +
																												p
																											}
																											name="addNote"
																											value={
																												labOrderObj.addNote
																											}
																											type="text"
																											onChange={(e) =>
																												ChangeTo(
																													e,
																													p
																												)}
																											placeholder="Add your notes"
																											autocomplete="off"
																											maxlength="254"
																										/>
																									</div>
																								</li>
																								<div class="clearfix" />
																							</ul>
																						</div>
																					))}
																					{/*  */}
																				</div>
																				<div
																					onClick={(e) => autoHide()}
																					class="precsriptionStore"
																					id="precsriptionStores"
																				>
																					<ul>
																						<li class="col-md-12 col-lg-12 PaddingGridFix12 labOrderTestEditingOrderAtDetails_PTour">
																							<label>Order At</label>
																							<input
																								type="text"
																								id="freeArticleData1"
																								name="cityName"
																								tabindex="6"
																								autocomplete="off"
																								placeholder="Lab Name"
																								maxlength="100"
																								class="ui-autocomplete-input"
																								onClick={() =>
																									orderAt(
																										'freeArticleData1',
																										'locationSearch1'
																									)}
																								style={{ height: '34px' }}
																								onKeyUp={() =>
																									orderAt(
																										'freeArticleData1',
																										'locationSearch1'
																									)}

																							/>
																							<ul
																								className="autoSearch1 autoSearch-p0"
																								id="locationSearch1"
																								style={{ display: 'block' }}
																							>
																								{freeArticleData1.map(
																									(
																										freeArticleDataObj,
																										i
																									) => (
																										<li
																											onClick={() =>
																												setFreeArticleData(
																													freeArticleDataObj.ServiceProviderId,
																													freeArticleDataObj.Lab,
																													'freeArticleData1',
																													'locationSearch1'
																												)}
																										>
																											{
																												freeArticleDataObj.Lab
																											}
																										</li>
																									)
																								)}
																							</ul>
																						</li>
																						<li class="col-md-12 col-lg-12 PaddingGridFix12 labOrderTestEditingComments_PTour">
																							<label>Comments</label>
																							<textarea
																								id="txtComments"
																								name="txtComments"
																								tabindex="14"
																								value={getLabOrdercomments}
																								onChange={(e) =>
																									setLabOrdercomments(
																										e.target.value
																									)}
																								placeholder="Comments"
																								maxlength="5000"
																								className="textareaField"
																							/>
																						</li>
																					</ul>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div
																			id="labTestingEmptyMsg"
																			class="labOrderTestEditingProceDetails_PTour"
																			style={{ display: 'block' }}
																		>
																			<div
																				id="innerEduDiv1"
																				class="prescriptionTrtmntListItem"
																			>
																				<span class="treatmentCommonTxt1 labTestMessage">
																					<span>
																						{' '}
																						Add Lab Test(s) from right, Multiple
																						Lab Test(s) can be added
																					</span>
																				</span>
																			</div>
																		</div>
																	)}
																	{/* =============== */}
																</div>
															</div>
														</div>
														{/*  */}
													</div>
												</div>

												{/* //////////////////////////////// */}
												{/* ====================== MEDICAL CONTENTS ENDS HERE ============================ */}
											</div>

										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection simplifiedFormLabOrderRightSec">
								<div className="patientright pl_pro">
									<div className="rightTagSection">
										<div className="rightTagList">
											<div className="rightTagTitle">
												Lab Test
											</div>
										</div>
										<div className="tagListingDiv" >
											<input
												type="text"
												className="inputField"
												placeholder="Search"
												name="testName"
												value={testName}
												onChange={(e) => searchProcedure(e)} />
										</div>
									</div>
									<div className="plLabOrderRightSec labOrderAddRightScroll">
										{labTestListing.map((data, j) => (
											<div className="treatmentNameList treatmentPlanList LabOrderAddLIst ">
												<ul id="tabProceduresUL">
													{data.Test !== '' ? (
														<li onClick={(e) => addNewLabOrder(data, j)}>
															<span class="elipsis" title="test">
																<b>{data.Test}</b>
															</span>
															<span class="treatmentNameListRight">
																{data.buy_price === '' ? (
																	<span
																		class="treatmentNameListAmt"
																		style={{ margin: '0px' }}
																	>
																		NA
																	</span>
																) : (
																	<span
																		class="treatmentNameListAmt"
																		style={{ margin: '0px' }}
																	>
																		&#8377; {data.buy_price}.00
																	</span>
																)}
															</span>
														</li>
													) : (
														''
													)}
												</ul>
											</div>
										))}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	);
})
export default SimplifiedFormLabOrder;
