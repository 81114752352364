import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Datepicker from 'react-datepicker';
import Light from '../images/icons/light.png';

import TabsRight from './tabsRight';
function createInvoiceSelectedMedicine() {
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment plInvoice">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead" style={{ border: 'none' }}>
												<span class="treatmentPlanName">INVOICE</span>

												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span>

												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b' }}
												>
													<a href="">Save Invoice</a>
												</span>
												<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
													<a href="/patients/treatmentPlans">Cancel</a>
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn pl_cancelbtn"
													style={{ background: 'transparent', border: 'none' }}
												>
													<a href=" " style={{ color: '#00abff' }}>
														Medicine not in Hospital
													</a>
												</span>
											</div>

											<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
												<div class="pl_treat treatmentTable planActive">
													<table style={{ border: 'none' }}>
														<thead>
															<tr>
																<th class="col-md-3">
																	<div class="dentalProName">TOWARDS</div>
																</th>
																<th class="col-md-4 " style={{ display: 'flex' }}>
																	<div class="dentalProCost">QTY </div>
																	<div class="dentalProDis">x&nbsp;COST</div>
																</th>
																<th class="col-md-1" />

																<th class="col-md-2">
																	<div class="dentalProTotal">TAX (INR)</div>
																</th>
																<th class="col-md-3  ">
																	<div
																		class="dentalProNote"
																		style={{ textAlign: 'right' }}
																	>
																		TOTAL(INR)
																	</div>
																</th>
															</tr>
														</thead>

														<tbody>
															<p className="invoiceClose">
																<span className="close_row">x</span>
															</p>

															<tr style={{ border: 'none' }}>
																<div class="treatmentTags">Procedure</div>
															</tr>
															<tr class="groupBrdr">
																<td class="col-md-3">
																	<div class="dentalProName">
																		<span title="test">
																			<b>X-Ray</b>
																		</span>
																		<p>12</p>
																	</div>
																</td>
																<td class="col-md-4">
																	<div class="treatmentPrice">
																		<div class="treatmentPriceTxt">
																			<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																				<input
																					type="number"
																					min="3"
																					name="quantity"
																					class="quantity"
																					id="txtQuantity"
																					value="3"
																				/>
																			</span>{' '}
																		</div>
																		<div class="treatmentPriceCross">x</div>
																		<div class="treatmentCostTxt">
																			<input
																				type="text"
																				autocomplete="off"
																				name="txtCost"
																				maxlength="10"
																				id="txtCost"
																				value="200"
																			/>
																		</div>
																	</div>
																</td>
																<td class="col-md-1" />
																<td class="col-md-2">
																	<div
																		class="dentalProDis"
																		style={{
																			textAlign: 'right',
																			color: '008000',
																		}}
																	>
																		0:00
																	</div>
																</td>

																<td class="col-md-3">
																	<span
																		className="totalinr"
																		style={{ color: '008000' }}
																		id="grandTotal"
																		name="grandTotal"
																	>
																		2000
																	</span>
																</td>
															</tr>

															<tr class="dentalProPlaned">
																<td>
																	<div class="treatmentNote">
																		<span>+ add notes</span>
																		<input
																			type="text"
																			autocomplete="off"
																			maxlength="254"
																			placeholder="Add your notes"
																		/>
																	</div>

																	<div class="plannedBy">
																		By<span>Dr.Sharan S</span>
																		<br />
																		On <span>12/11/2012 10:30pm</span>
																	</div>
																</td>
															</tr>

															{/*  ROOM */}
															<p className="invoiceClose">
																<span className="close_row">x</span>
															</p>
															<tr style={{ border: 'none' }}>
																<div class="treatmentTags  ">Room</div>
															</tr>
															<tr class="groupBrdr">
																<td class="col-md-3">
																	<div class="dentalProName">
																		<span title="test">
																			<b>Consultation Room (Consultation Room)</b>
																			<p>Bed #1 - Room #CR-1</p>
																		</span>
																	</div>
																</td>
																<td class="col-md-4">
																	<div class="treatmentPrice">
																		<div class="treatmentPriceTxt">
																			<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																				<input
																					type="number"
																					min="3"
																					name="quantity"
																					class="quantity"
																					id="txtQuantity"
																					value="3"
																				/>
																			</span>{' '}
																		</div>
																		<div class="treatmentPriceCross">x</div>
																		<div class="treatmentCostTxt">
																			<input
																				type="text"
																				autocomplete="off"
																				name="txtCost"
																				maxlength="10"
																				id="txtCost"
																				value="200"
																			/>
																		</div>
																	</div>
																</td>
																<td class="col-md-1" />
																<td class="col-md-2">
																	<div
																		class="dentalProDis"
																		style={{
																			textAlign: 'right',
																			color: '008000',
																		}}
																	>
																		0:00
																	</div>
																</td>

																<td class="col-md-3">
																	<span
																		className="totalinr"
																		style={{ color: '008000' }}
																		id="grandTotal"
																		name="grandTotal"
																	>
																		2000
																	</span>
																</td>
															</tr>

															<tr class="dentalProPlaned">
																<td>
																	<div class="treatmentNote">
																		<span>+ add notes</span>
																		<input
																			type="text"
																			autocomplete="off"
																			maxlength="254"
																			placeholder="Add your notes"
																		/>
																	</div>

																	<div class="plannedBy">
																		By<span>Dr.Sharan S</span>
																		<br />
																		On <span>12/11/2012 10:30pm</span>
																	</div>
																</td>
															</tr>
															<br />
															{/* ROOM */}
														</tbody>
													</table>
												</div>
											</div>
											<div className="row total_info">
												<div className="col-md-3">
													<span>
														Generate On&nbsp;<span
															style={{ color: '#1b99d5', fontSize: '10px' }}
															value=""
															id="doctoNameee"
														>
															12/12/2021 10:30pm
														</span>
													</span>
													<span
														class="treatmentPlanBtn pl_blue_btn"
														style={{
															background: '#5bb75b',
															width: '100%',
															textAlign: 'center',
														}}
													>
														<a href="">Pay Now</a>
													</span>
													<br />
												</div>
												<div className="col-md-9">
													<div className="row">
														<div className="col-md-2" style={{ padding: '0px' }}>
															<p>Total Cost INR</p>
															<p>200.00 </p>
														</div>
														<div className="col-md-1">
															{/* <p> &nbsp; </p> */}
															<p> &nbsp; +</p>
														</div>
														<div className="col-md-2" style={{ padding: '0px' }}>
															<p>Total Tax INR</p>
															<p>0.00 </p>
														</div>
														<div className="col-md-1">
															{/* <p> &nbsp; </p> */}
															<p> &nbsp; -</p>
														</div>
														<div className="col-md-3" style={{ padding: '0px' }}>
															<p>Total Discount INR</p>
															<input
																type="text"
																placeholder="0:00"
																style={{ width: '100px', float: 'right' }}
															/>
														</div>
														<div className="col-md-1">
															{/* <p> &nbsp; </p> */}
															<p> &nbsp; =</p>
														</div>
														<div className="col-md-2" style={{ padding: '0px' }}>
															<p> Grand Total INR</p>
															<p>200.00</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-4 practiceRightSection">
								<TabsRight />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default createInvoiceSelectedMedicine;
