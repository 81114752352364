import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import DoctorLeftMenu from '../Reports/DoctorLeftMenu';
function LabLeft() {
	const [toggle, setToggle] = useState(false);
	const labTest = () => {
		window.sessionStorage.setItem("activePage", 1)
		window.sessionStorage.setItem("offset", 0)
		//	window.location.href="/labLogin/labTestBill"
	};
	const labTest1 = () => {
		// window.location.reload()
		window.location.href = "/labLogin/labTestBill"
	};
	const setToggleFiles4 = (event) => {
		if (document.getElementById("qaz4").style.display === "block") {
			window.sessionStorage.setItem("qaz4", "none")
		}
		else {
			window.sessionStorage.setItem("qaz4", "block")
		}
	}
	return (
		<div className="settingMenu" style={{ paddingTop: '0px' }}>
			{window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" ?
				<div className="settingMenuList labLeftMenuList">
					<span className='LeftMenuTitle'>	{window.sessionStorage.getItem("userName")}</span>

					<ul>

						<li className="settingListLi">
							<NavLink to="/labLogin/labOrderDetails" activeClassName="active" onClick={() => {
								window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
							}} >Lab Orders</NavLink>
						</li>
						{/* <li className="settingListLi">
							<NavLink to="/labLogin/labAddCollectionDate" activeClassName="active">Add Collection Date</NavLink>
					</li> */}
						<li className="settingListLi">
							<NavLink to="/labLogin/labTestBill" activeClassName="active"><span onClick={() => labTest1()}>Lab Test Bill</span></NavLink>
						</li>
						<li className="settingListLi">
							{/* <NavLink to="/labLogin/addLabTestDetails" activeClassName="active">Add Lab Test</NavLink> */}
							<NavLink to="/labLogin/addLabTestDetails" activeClassName="active" onClick={() => {
								window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
							}} >
								<span onClick={() => labTest()}>Add Lab Test</span>
							</NavLink>
						</li>

						<li>
							<Link to="#"  >
								<span onClick={() => setToggleFiles4()}>Reports</span>

								<div className="plSubMenu" id="qaz4" style={{ display: window.sessionStorage.getItem("qaz4"), padding: '0px', paddingTop: '15px' }}>
									<div>
										<li>
											<NavLink to="/labLogin/labOrderPayment" activeClassName="active" onClick={() => {
												window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
											}} >
												<span  >Lab Order Payment</span></NavLink>
										</li>
									</div>
									<div>
										<li>
											<NavLink to="/labLogin/labTestUnavailability" activeClassName="active" onClick={() => {
												window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
											}}  >
												<span >Lab Test Unavilability</span></NavLink>
										</li>
									</div>
									<div>
										<li>
											<NavLink to="/labLogin/outsideLabOrderDetails" activeClassName="active" onClick={() => {
												window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
											}}  >
												<span  >Outside Lab Order</span></NavLink>
										</li>
									</div>


								</div>
							</Link>
						</li>
						<li className="settingListLi">
							<NavLink to="/Dashboard/changepassword" activeClassName="active" onClick={() => {
								window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
							}} >Change Password</NavLink>
						</li>
					</ul>
				</div>
				: <DoctorLeftMenu />}
		</div>
	);
}
export default LabLeft;
