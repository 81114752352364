import React from 'react';
import ClinicManagement from '../images/icons/members_receive_1.jpg';
import Website from '../images/icons/members_receive_2.jpg';
import MobileApp from '../images/icons/members_receive_3.jpg';
import Telehealth from '../images/icons/members_receive_4.jpg';
import ClinicManagementImg from '../images/icons/clinicManagement.jpg';
import AddImage from '../images/icons/addImage.jpg';
import PatientEngagement from '../images/icons/patientEngagement.jpg';

function membersReceive() {
	return (
		<div>
			<div class="contentWrap members_receive">
				<div class="container ">
					<div className="row">
						<div class="contentHead">What Members Receive</div>
						<div class="col-md-4">
							<div class="contentList">
								<ul>
									<li>
										<img src={ClinicManagement} />Clinic Management
									</li>
									<li>
										<img src={Website} />Your Own Website
									</li>
									<li>
										<img src={MobileApp} />Your Own Mobile App
									</li>
									<li>
										<img src={Telehealth} />Telehealth Technology
									</li>
								</ul>
							</div>
						</div>

						<div class="col-md-3">
							<div class="contentImg">
								<img src={ClinicManagementImg} />
								<h2>
									Clinic<br />Management
								</h2>
							</div>
						</div>
						<div class="col-md-1">
							<div class="contentImg contentPlus">
								<img src={AddImage} />
							</div>
						</div>
						<div class="col-md-3">
							<div class="contentImg">
								<img src={PatientEngagement} />
								<h2>Patient Engagement</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default membersReceive;
