import React, { useState, useEffect } from 'react';
import Editicon from '../images/icons/edit-bg.png';
import Tick from '../images/icons/tick.png';
import Close from '../images/icons/close.png';
import { Link } from 'react-router-dom';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';

function TaxDetails() {
	const navigate = useNavigate();

	const [taxDetails, setTaxDetails] = useState([]);
	const [totalCount, setTotalCount] = useState('');
	const [isData, setIsData] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	//const [pageCount, setPageCount] = useState("");
	useEffect(() => {
		//	setIsData(true)
		listing();
	}, []);
	const listing = () => {
		setActivePage(Number(window.sessionStorage.getItem("pageNumberTaxDetails")));

		//	setIsData(true)
		const apiJson = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: window.sessionStorage.getItem("offsetTaxDetails"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
		};
		callingAPI('hospitalTaxDetails/hospitalTaxDetailsList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setTaxDetails(data.data.result.hospitaltaxDetailsList);
				setTotalCount(data.data.result.totalCount);
				setIsData(true);
			} else {
				setIsData(true);
			}
		});
	};
	const taxDetailsClick = (data) => {
		// navigate('/settings/addTax', { state: data })
		navigate('/settings/addTax', { state: data })
	}
	const paginationOnChange = (pageNumber) => {
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);


		window.sessionStorage.setItem("pageNumberTaxDetails", pageNumber);
		window.sessionStorage.setItem("offsetTaxDetails", offset);
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: offset,
			max: max,
		};
		callingAPI('hospitalTaxDetails/hospitalTaxDetailsList', postdata).then((data) => {
			if (data.data.success === '1') {
				setTaxDetails(data.data.result.hospitaltaxDetailsList);
				setTotalCount(data.data.result.totalCount);
				setIsData(true);
			} else {
				setTaxDetails([]);
				setIsData(true);
			}
		});
	};
	const statusChange = (id, status, category) => {
		var x = false;
		if (status === false) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		if (x === true) {
			const satusData = {
				taxId: id,
				category: category,
				isActive: status,
			};
			callingAPI('hospitalTaxDetails/hospitalTaxDetailsDelete', satusData).then((data) => {
				if (data.data.success === '1') {
					listing();
				}
				if (data.data.success === '0') {
					alert(data.data.errorMessage);
				}
			});
		}
	};
	return (
		<div className="dashboardCover">
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle"> Tax Details</div>
				</div>
				<div className="col-md-2">
					<Link to="/settings/addTax" title="Add Tax" style={{ textDecoration: 'none', color: '#393939' }}>
						<span className="dashButtonBorder">Add Tax</span>
					</Link>
				</div>
			</div>
			{totalCount > 10 &&
				<div className="paginationSection">
					<Pagination
						// style={{ cursor: 'pointer' }}
						//hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={paginationOnChange}
					// prevPageText={"<"}
					// nextPageText={">"}
					/>
				</div>
			}
			<div className="dashboardContent">
				{isData === false ? <Loader /> : ''}
				<div className="reportWrap">
					{taxDetails.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th> Tax Name</th>
										<th> Tax Display Name </th>
										<th>Tax Value(%)</th>
										{/* <th>Apply Tax On</th> */}
										{/* <th>Category</th> */}
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{taxDetails.map((obj, i) => (
										<tr>
											<td>{obj.taxName}</td>
											<td>{obj.taxDisplayName}</td>
											<td>{parseFloat(obj.taxValue).toFixed(2)}</td>
											{/* <td>{obj.applyTaxOn} </td> */}
											{/* <td>{obj.category}</td> */}
											<td>
												{/* <a><img src={Editicon} /></a> */}

												<span
													onClick={() => taxDetailsClick({ taxId: obj.id, category: obj.category })}
												>
													<img title="Click to Edit" src={Editicon} />
												</span>

												{obj.isActive === true ? (
													<img
														src={Tick}
														style={{ cursor: 'pointer', width: '20px' }}
														title="Click to Deactivate"
														onClick={() => statusChange(obj.id, false, obj.category)}

													/>
												) : (
													<img
														src={Close}
														style={{ cursor: 'pointer', width: '20px' }}
														title="Click to Activate"
														onClick={() => statusChange(obj.id, true, obj.category)}

													/>
												)}

											</td>

										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : isData === true ? (
						<NoData />
					) : (
						''
					)}
				</div>
			</div>
			{totalCount > 10 &&
				<div className="paginationSection">
					<Pagination
						// style={{ cursor: 'pointer' }}
						//hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={paginationOnChange}
					// prevPageText={"<"}
					// nextPageText={">"}
					/>
				</div>
			}
		</div>
	);
}

export default TaxDetails;
