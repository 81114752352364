import React, { useEffect } from 'react';
import { NavLink,Link } from 'react-router-dom';
import calender from '../images/icons/calender.png';
import appointment from '../images/icons/appointment.png';
import patient from '../images/icons/patient.png';
import addpatient from '../images/icons/addpatient.png';
import doctor from '../images/icons/doctor.png';
import staff from '../images/icons/staff.png';
import treat from '../images/icons/treat.png';


function HospitalHeaderBottomLinks() {

	const showvalue = ()=>{
		window.sessionStorage.setItem('AppoinmentpageNumber',1)
		window.sessionStorage.setItem("activepagenewappoinment", 0)
		window.sessionStorage.setItem('setToDayFlag',"false")
	}
	const showlalerts = ()=>{
		window.sessionStorage.setItem('pageNumberstaff',1)
		window.sessionStorage.setItem("activepagestaff", 0)
}
const drDetailsSection = ()=>{
	window.sessionStorage.setItem("pageNumberDrDetails", 1)
			window.sessionStorage.setItem("offsetDrDetails", 0)
}
	return (
		<div>
			<span
				id="calenderHeader"
				className={'headerBottomIcon headerBottomIconNew '}
			>
				<NavLink
					to="/clinics/calendar"
				activeClassName='active'
					className={
						'headerBottomIcon headerBottomIconNew '
					}
				>
					<img src={calender} alt="" />Calendar
				</NavLink>
			</span>
			<span
				id="appointmentsHeader"

				className={
					'headerBottomIcon headerBottomIconNew '
				}
			>
				<NavLink
					to="/patients/appointment"
					activeClassName='active'
					className={
						'headerBottomIcon headerBottomIconNew '
					}
					onClick={showvalue}
				>
					<img src={appointment} alt="" />Appointments
				</NavLink>
			</span>
			<span
				id="patientsHeader"
				className={'headerBottomIcon headerBottomIconNew '}
			>
				<NavLink
					to="/patients/patientListing"
					activeClassName='active'
					className={
						'headerBottomIcon headerBottomIconNew '
					}
				>
					<img src={patient} alt="" />Patients
				</NavLink>
			</span>
			<span
				id="doctorHeader"
				className={'headerBottomIcon headerBottomIconNew ' }
			>
				{/* <NavLink
					to="/Doctor/doctorsList"
					activeClassName='active'
					className={'headerBottomIcon headerBottomIconNew ' }
				>
					<img src={doctor} alt="" />Doctors
				</NavLink> */}
				<Link onClick={drDetailsSection} to="/Doctor/doctorsList"
					activeClassName='active'
					className={'headerBottomIcon headerBottomIconNew ' }>
												<img src={doctor} alt="" />Doctors
											</Link>
			</span>
			<span
				id="staffHeader"
				className={'headerBottomIcon headerBottomIconNew ' }
			>
				<NavLink
					to="/clinics/staffList"
					activeClassName='active'
					className={'headerBottomIcon headerBottomIconNew ' }
					onClick={showlalerts}
				>
					<img src={staff} alt="" />Staff
				</NavLink>
			</span>
			<span
				id="addpatientsHeader"
				className={'headerBottomIcon headerBottomIconNew ' }
			>
				<NavLink
					to="/patients/addPatient"
					activeClassName='active'
					className={
						'headerBottomIcon headerBottomIconNew '
					}
				>
					<img src={addpatient} alt="" />Add Patient
				</NavLink>
			</span>
			<span
				id="treatmentHeader"
				className={'headerBottomIcon headerBottomIconNew ' }
			>
				<NavLink
					to="/clinics/treatmentChart"
					activeClassName='active'
					className={
						'headerBottomIcon headerBottomIconNew '
					}
				>
					<img src={treat} alt="" />Treatment Chart
				</NavLink>
			</span>
		</div>
	);
}

export default HospitalHeaderBottomLinks;
