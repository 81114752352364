import React, { useState } from 'react';
import purpleHealthLogo from '../../images/media/purpleHealthLogo.png';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useIdleTimer } from 'react-idle-timer'

function Footer() {

	const [siteId, setsiteId] = useState(window.sessionStorage.getItem('siteId'));
	$(document).ready(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('#scroll').fadeIn();
			} else {
				$('#scroll').fadeOut();
			}
		});
		$('#scroll').click(function () {
			$('html, body').animate({ scrollTop: 0 }, 100);
			return false;
		});
	});

	const handleOnIdle = (event) => {

		window.sessionStorage.removeItem("actualUserId");
		window.sessionStorage.removeItem("doctorId");
		window.sessionStorage.removeItem("userId");
		localStorage.removeItem("hospitalId");
		localStorage.removeItem("doctorAppointmentCount");
		window.sessionStorage.removeItem("userName");
		window.sessionStorage.removeItem("isLoggedIn");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("clinicsHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		window.sessionStorage.removeItem("role");
		window.location.href = "/"




	}

	const { getLastActiveTime } = useIdleTimer({
		timeout: 14400000,

		onIdle: handleOnIdle,
		debounce: 500,
	})
	return (

		<div>
			<div className="footer">
				<div className="container">
					<div className="footerContent">
						<h6>Contact</h6>
						<span>Phone : {window.sessionStorage.getItem('sitePhoneNumber')}</span>
						<span>Email : {window.sessionStorage.getItem('siteEmailId')}</span>
						<span>Support Timing : 9:30 AM - 5:30 PM(Mon-Fri)</span>
					</div>
				</div>
				<a href="#" id="scroll" style={{ display: 'none' }}>
					<FontAwesomeIcon icon={faAngleUp} className="footerfaAngleUp" />
				</a>
			</div>
		</div>

		// <div>
		// 	<div className="footer">
		// 		<div className="container">
		// 			<img src={purpleHealthLogo}/>
		// 			<p>Powered by <a href="">PurpleHealth.com</a></p>
		// 		</div>
		// 		<a href="#" id="scroll" style={{ display: 'block' }}>
		// 			<FontAwesomeIcon icon={faAngleUp} className="footerfaAngleUp" />
		// 		</a>
		// 	</div>
		// </div>
	);
}

export default Footer;
