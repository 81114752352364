import React from 'react';
import BillingHeader from '../billingLogin/layout/header';
import Footer from '../billingLogin/layout/footer';
import BillingLeft from '../billingLogin/billingLeft'



function BlankPage() {
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec">
			<BillingHeader />
			<div className="contentWraper contentWraperScroll">
				<BillingLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<p>blank page</p>

					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default BlankPage;
