import React, { useState, useEffect } from 'react';
import LabHeader from './layout/header';
import Footer from './layout/footer';
import { Route } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { callingAPI } from '../config';
import LabLeft from './labLeft'
import Datepicker from 'react-datepicker';
import labPrescription from '../images/icons/lab/labPrescription.png';
import Printer_icon from '../images/icons/printer_icon.png';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { useNavigate } from 'react-router-dom';

function LabAddCollectionDate() {
	const navigate = useNavigate();

	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [count, setCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [testGroupData, settestGroupData] = useState([]);
	const [searchValue, setsearchValue] = useState('');
	const [filterName, setFilterInput] = useState('');
	const [dataFlag, setDataFlag] = useState(false);
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	var moment = require('moment');
	const searchValueChange = (value) => {
		setsearchValue(value);
	};
	const dataPagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) {
			setFilterInput("");
		}
		if (!booliansearchValue) { setsearchValue("") }
		if (!boolianfromDate) { setFromDate("") }
		if (!booliantoDate) { setToDate("") }
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			max: max.toString(),
			offset: offset.toString(),
			inputSearch: booliansearchValue,
			selectCombo: boolianfilterName,
			fromDate: boolianfromDate ? formatDate(fromDate) : '',
			toDate: booliantoDate ? formatDate(toDate) : '',
		};
		setDataFlag(false);
		callingAPI('adminReports/labTests', PostData)
			.then((data) => {
				if (data.data.success === '0') {
					settestGroupData([]);
					setCount(0);
					setDataFlag(true);
				}
				if (data.data.success === '1') {
					settestGroupData(data.data.result.testGroupData);
					setCount(data.data.result.datacount);
					setDataFlag(true);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const changeTextonTextArea = (value) => {
		setFilterInput(value);
		setsearchValue('');
		if (value === '') {
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const searchValueOnChangeOnSelect = () => {
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('selectName').focus();
			alert(selectedText + ' required');
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(filterName);
		setboolianfromDate(fromDate);
		setbooliantoDate(toDate);
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				alert('End date must be greater than start date');
				document.getElementById("todate").focus()
				return (false);
			}
		}
		setActivePage(1);
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			offset: '0',
			max: process.env.REACT_APP_PAGINATION_COUNT,
			selectCombo: filterName,
			inputSearch: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('adminReports/labTests', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					settestGroupData([]);
					setCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					settestGroupData(response.data.result.testGroupData);
					setCount(response.data.result.datacount);
					setDataFlag(true);
				}
			})
			.catch();
	};
	const LabAddCollection = (data) => {
		navigate('/patients/testResultAdd', { state: data })
	}
	useEffect(() => {
		const PostData = {
			userId: window.sessionStorage.getItem('userId'),
			offset: pageOffset.toString(),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			selectCombo: filterName,
			inputSearch: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('adminReports/labTests', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					settestGroupData([]);
					setCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					setDataFlag(true);
					settestGroupData(response.data.result.testGroupData);
					setCount(response.data.result.datacount);
				}
			})
			.catch();
	}, []);
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec labTestBillWrapper">
			<LabHeader />
			<div className="contentWraper contentWraperScroll">
				<LabLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle">Add Collection Date</div>
								{count > 10 && (
									<div className="paginationSection">
										<Pagination
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={count}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={dataPagination}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="dashboardContent">
							<div className="reportWrap">
								<div className="dashboardSearch">
									<div className="row">
										<div className="col-md-2 dashboardSearchList">
											<select className="inputField"
												id="selectName"
												name="selectName"
												value={filterName}
												onChange={(e) => changeTextonTextArea(e.target.value)} >
												<option value="">Select</option>
												<option value="1">MR Number</option>
												<option value="2">Patient Name</option>
											</select>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<input
												type="text"
												className="inputField"
												value={searchValue}
												onChange={(e) => searchValueChange(e.target.value)}
												disabled
												id="searchText"
												name="searchText"
											/>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<Datepicker
												required
												className="Datepicker pa2 inputField"
												maxDate={new Date()}
												placeholderText="From"
												calendarClassName="rasta-stripes"
												selected={fromDate}
												dateFormat="dd-MM-yyyy"
												onChange={(date) => setFromDate(date)}
												id="fromdate"
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<Datepicker
												required
												className="Datepicker pa2 inputField"
												selected={toDate}
												dateFormat="dd-MM-yyyy"
												onChange={(date) => setToDate(date)}
												placeholderText="To"
												calendarClassName="rasta-stripes"
												id="todate"
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
										</div>
										<div className="col-md-2 dashboardSearchList"  >
											<span className="dashButtonBorder labButton" onClick={searchValueOnChangeOnSelect} style={{ padding: "8px 20px", height: "35px" }}>Search</span>
										</div>
									</div>
								</div>
								{/*  */}
								{dataFlag == false ? (
									<Loader />
								) : testGroupData.length > 0 ? (
									<div>
										{testGroupData.map((data, i) => (
											<div class="treatmentPlanList">
												<div class="treatmentPlanDate"> {moment(data.testDate).format('YYYY-MM-DD')}  </div>
												<div class="treatmentTable planActive">
													<table>
														<thead>
															<tr>
																<th class="col-md-3"><div class="dentalProName">Patient Name</div></th>
																{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																	(<th class="col-md-2"><div class="dentalProName">MR No</div></th>) :
																	(<th class="col-md-2"><div class="dentalProName">PR No</div></th>)
																}
																<th class="col-md-3"><div class="dentalProName">Receipt No</div></th>
																<th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
																<th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
															</tr>
														</thead>
														<tbody>
															<tr class="groupBrdr">
																<td class="col-md-3">
																	<div class="dentalProName paddingFive">
																		<span class="elipsis">
																			{data.displayName}
																		</span>
																	</div>
																</td>
																{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																	(<td class="col-md-2"><div class="dentalProName paddingFive">{data.mrNo} </div></td>) :
																	(<td class="col-md-2"><div class="dentalProName paddingFive">{data.prNo} </div></td>)
																}
																<td class="col-md-3"><div class="dentalProName paddingFive">RCP303 </div></td>
																<td class="col-md-2">
																	<div class="dentalProName ">
																		<span class="viewDetailLink">
																			<a href=""> View &amp; Print Bill</a>
																		</span>
																	</div>
																</td>
																<td class="col-md-3">
																	<div class="dentalProName txtCenter">
																		<span class="viewDetailLink clrBlack" style={{ display: "inline-block", marginRight: "10px" }}>
																			<nav onClick={() => LabAddCollection({
																				testmemberId: data.memberId, istestFlag: "TestResultCollection"
																			})}
																			> <img src={labPrescription} title="Add Test Result" /></nav>
																		</span>
																		<span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Test Result" style={{ display: "inline-block", verticalAlign: "middle" }}>
																			<a href='#labTestDetailedInfo'>  <img src={Printer_icon} title="Printer_icon" /></a>
																		</span>
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										))}
									</div>
								) : (
									<p>
										<NoData />
									</p>
								)}
								{count > 10 && (
									<div className="paginationSection">
										<Pagination
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={count}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={dataPagination}
										/>
									</div>
								)}
								{/* PRINNT 2nd MODAL BEGINS HERE */}
								<div id="labTestDetailedInfo" class="modalDialog">
									<div>
										<a href="#close" title="Close" class="close exportClose">X</a>
										<div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
											<div class="addDataHead">
												<span className='labTitle'>Lab Test Details</span>
											</div>
											{/* --- */}
											<div class="addDataContent" id="medicineDetails">
												<div class="patientNameDiv row">
													<span class="col-md-2 patientName patientDate">
														<span>28, Aug 2019</span>
													</span>
												</div>
												<div class="treatmentTable planActive">
													<table>
														<thead>
															<tr>
																<th class="col-md-4"><div class="dentalProCost txtLeft">Test Name (Type)</div></th>
																<th class="col-md-4"><div class="dentalProDis txtLeft">Normal Value</div></th>
																<th class="col-md-4"><div class="dentalProNote txtLeft">Patient Readings</div></th>
															</tr>
														</thead>
														<tbody>
															<tr class="groupBrdr">
																<td colspan="3" class="col-md-12">
																	<div class="dentalProName">
																		<div class="optionWrapper">
																			<input type="checkbox" id="chkSelect01" name="chkSelect01" />
																			<label for="chkSelect01"></label>
																		</div>
																		<div class="dentalProCost txtLeft" style={{ paddingTop: "0px", paddingBottom: "0", fontWeight: "600", width: "95%" }}>
																			BLOOD -SEROLOGY FOR HIV,HCV,HBsAg (Bilirubin Total) (Specimen Type:Blood)
																		</div>
																	</div>
																</td>
															</tr>
															<tr class="groupBrdr">
																<td colspan="3" class="col-md-12">
																	<div class="dentalProName">
																		<b></b>
																	</div>
																</td>
															</tr>
															<tr class="groupBrdr">
																<td class="col-md-4"><div class="dentalProDis txtLeft"> - Rapid immunochromoatography for HIVI and HIV2</div></td>
																<td class="col-md-4"><div class="dentalProDis txtLeft">
																	REACTIVE,NON REACTIVE
																</div></td>
																<td class="col-md-4">
																	<div class="dentalProNote paddingEight">
																		<span class="elipsis">
																			<span style={{ fontWeight: "bold" }}>
																				1 </span>
																		</span>
																	</div>
																</td>
															</tr>
															<tr class="groupBrdr">
																<td colspan="3" class="col-md-12">
																	<div class="dentalProName">
																		<b></b>
																	</div>
																</td>
															</tr>
															<tr class="groupBrdr">
																<td class="col-md-4"><div class="dentalProDis txtLeft"> - Rapid immunochromoatography for HbsAg</div></td>
																<td class="col-md-4"><div class="dentalProDis txtLeft">
																	positive, negative
																</div></td>
																<td class="col-md-4">
																	<div class="dentalProNote paddingEight">
																		<span class="elipsis">
																			<span style={{ fontWeight: "bold" }}>
																				1 </span>
																		</span>
																	</div>
																</td>
															</tr>
															<tr class="groupBrdr">
																<td colspan="3" class="col-md-12">
																	<div class="dentalProName">
																		<b></b>
																	</div>
																</td>
															</tr>
															<tr class="groupBrdr">
																<td class="col-md-4"><div class="dentalProDis txtLeft"> - HCV</div></td>
																<td class="col-md-4"><div class="dentalProDis txtLeft">
																	REACTIVE,NON REACTIVE
																</div></td>
																<td class="col-md-4">
																	<div class="dentalProNote paddingEight">
																		<span class="elipsis">
																			<span style={{ fontWeight: "bold" }}>
																				1 </span>
																		</span>
																	</div>
																</td>
															</tr>
															<tr class="dentalProPlaned">
																<td colspan="2"><div class="plannedBy">Noted by <span>Dr. Nagarjun Mishra Ima</span></div></td>
																<td colspan="1"><div class="estimatedPrice"></div></td>
															</tr>
														</tbody>
													</table>
												</div>
												<div class="serviceProviderVerfied">
													<span>Verified By : </span>
													<input type="text" />
													<span>Lab Technician : </span>
													<input type="text" />
													<span>Lab Technologist : </span>
													<input type="text" />
												</div>
												<div class="serviceProviderBtns">
													<input type="submit" value="Print Selected" style={{ background: "#177f40" }} />
													<input type="submit" value="Print All" id="btnPrintAll" style={{ background: "#177f40" }} />
												</div>
											</div>
											{/* --- */}
										</div>
									</div>
								</div>
								{/* PRINNT 2nd MODAL ENDS HERE */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default LabAddCollectionDate;
