import React, { useState, useEffect } from "react";
import ForgotPasswordHeader from "../layout/forgotPasswordHeader";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import { callingAPI } from "../config";
import { useParams } from "react-router-dom";
function ForgotPasswordOtp() {
  const [input, setInput] = useState(null);
  const [getUserName, setUserName] = useState("");
  const [getNewPassword, setNewPassword] = useState("");
  const [getConfirmPassword, setConfirmPassword] = useState("");
  const [state, setState] = useState({
    otpInput: "",
  });
  const validationOTP = (event) => {
    var flag = true;
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      if (event.target.id === "otpInput") {
        document.getElementById("otpInput").value = event.target.value;
        setInput(document.getElementById("otpInput").value);
      }
    } else {
      if (event.target.id === "otpInput") {
        document.getElementById("otpInput").value = input;
        alert("Only Numbers Accepted");
        flag = false;
      }
    }
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  let { siteIdOtp, userIdOtp } = useParams();
  const otpChecking = () => {
    var flag = true;
    if (document.getElementById("otpInput").value === "") {
      alert("Please Enter OTP");
      flag = false;
    }
    if (flag === true) {
      let apiJson = {
        siteId: siteIdOtp,
        userId: userIdOtp,
        otp: state.otpInput,
      };
      callingAPI("userLogin/sendOtp", apiJson)
        .then((response) => {
          if (response.data.success === "1") {
            document.getElementById("mailConfirmation").style.display = "block";
            document.getElementById("OtpConfirmation").style.display = "none";
          } else {
            alert(response.data.errorMessage);
          }
        })
        .catch();
    }
  };
  const otpSendApi = () => {
    let apiJson = {
      siteId: siteIdOtp,
      userId: userIdOtp,
      otp: "",
    };
    callingAPI("userLogin/sendOtp", apiJson)
      .then((response) => {
        if (response.data.success === "1") {
          alert("OTP has been send to your Mobile Number");
          setUserName(response.data.emailId);
        }
      })
      .catch();
  };
  useEffect(() => {
    otpSendApi();
  }, []);
  const saveNew = () => {
    var flag = true;
    if (!getNewPassword) {
      alert("Enter New Password");
      flag = false;
    } else if (!getConfirmPassword) {
      alert("Enter Confirm Password");
      flag = false;
    } else if (getNewPassword !== getConfirmPassword) {
      alert("Password not Matching");
      flag = false;
    }
    if (flag === true) {
      const apiJson = {
        siteId: siteIdOtp,
        userId: userIdOtp,
        userName: getUserName,
        newPassword: getNewPassword,
      };
      callingAPI("userLogin/forgotPassword", apiJson).then((data) => {
        if (data.data.success === "1") {
          alert("Password Changed Successfully");
          window.location.href = "/";
        }
      });
    }
  };
  return (
    <div>
      <div className="coverWraper innerCoverWraper forgotPasswordFormOtpWrap">
        <ForgotPasswordHeader></ForgotPasswordHeader>
        <div className="contentWraper contentWraperScroll homeContentWraper">
          <div className="homeContentBanner">
            <div className="container">
              <div className="row otpForgot">
                <div className="col-md-6">
                  <div
                    className="loginForm forgotPasswordForm"
                    id="OtpConfirmation"
                    style={{ display: "block" }}
                  >
                    <div className="loginList">
                      <span
                        className="enterOTPTxt"
                        style={{ textAlign: "center", cursor: "inherit" }}
                      >
                        Enter Your OTP
                      </span>
                    </div>
                    <div className="loginList">
                      <input
                        type="text"
                        className="inputField"
                        id="otpInput"
                        name="otpInput"
                        value={state.otpInput}
                        onChange={validationOTP}
                        placeholder="Enter Your OTP"
                      />
                    </div>
                    <div className="loginList" style={{ marginTop: "35px" }}>
                      <span
                        className="loginButton"
                        onClick={() => otpChecking()}
                      >
                        Process
                      </span>
                    </div>
                  </div>
                  <div
                    className="loginForm forgotPasswordForm"
                    id="mailConfirmation"
                    style={{ display: "none" }}
                  >
                    <div className="loginList">
                      <input
                        type="text"
                        className="inputField"
                        id="userName"
                        name="userName"
                        onChange={(e) => setUserName(e.target.value)}
                        value={getUserName}
                        readonly="readonly"
                      />
                    </div>
                    <div className="loginList">
                      <input
                        type="text"
                        className="inputField"
                        id="txtPassword"
                        name="txtPassword"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={getNewPassword}
                        placeholder="New Password"
                      />
                    </div>
                    <div className="loginList">
                      <input
                        type="text"
                        className="inputField"
                        value={getConfirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="txtConfirmPassword"
                        name="txtConfirmPassword"
                        placeholder="Confirm Password"
                      />
                    </div>
                    <div className="loginList">
                      <span className="loginButton" onClick={saveNew}>
                        Submit
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
export default ForgotPasswordOtp;
