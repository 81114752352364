import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config'
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';

function CasesheetDentalHistory() {
	const [dentalCaseSheetHistoryDetails, setdentalCaseSheetHistoryDetails] = useState([])
	const [pmKeyData, setPmKeyData] = useState([]);

	useEffect(() => {
		const PostData = {
			"hospitalId": localStorage.getItem("hospitalId"),
			"memberId": window.sessionStorage.getItem("memberId"),
			"doctorId": window.sessionStorage.getItem('patientDoctorId')
		}
		callingAPI('clinicalBackground/dentalCaseSheetHistory', PostData,
		).then((response) => {
			if (response.data.success === "1") {

				setdentalCaseSheetHistoryDetails(response.data.result.result)
				setPmKeyData(response.data.result.teethKeyValues);

				let keyData = response.data.result.teethKeyValues;
				for (let i = 0; i < keyData.length; i++) {
					let a = keyData[i].teeth
					for (let j = 0; j < a.length; j++) {
						if (a.length > 0) {
							document.getElementById('appendeText' + a[j].toothNo + i).innerHTML = a[j].toothKeys;
						}
						else {
							document.getElementById('teeth').style.display = 'none'
						}
					}
				}
			}
		}
		)
			.catch()
	}, []);

	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment  ">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>

												<span class="treatmentPlanBtn pl_blue_btn">
													<a href="/patients/casesheetDentalAdd">+ Add</a>
												</span>
											</div>

											<div class="treatmentPlanList1">
												<span
													class="treatmentPlanName"
													style={{
														marginLeft: '10px',
														marginTop: '10px',
														marginBottom: '10px',
														float: 'none',
													}}
												>
													<b>CASE SHEET HISTORY</b>
												</span>
												<br />
												<br />
												{dentalCaseSheetHistoryDetails.map((item, i) => (
													<div>


														<div class="treatmentPlanDate" style={{ paddingLeft: '10px' }}>
															<b>{item.complaintName}</b>
														</div>

														{item.complaintListByDate.map((data, i) => (
															<div class="treatmentComplaintWrap">
																<div class="treatmentComplaintRowWrap" style={{ width: '100%' }}>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			{data.complaintDate}
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>
																				<span class="treatmentTAgName ">
																					{data.content.split(',').map((data1) => (
																						<div>{data1}</div>
																					))}
																				</span>
																			</span>
																			{data.description ? (
																				<div>
																					<span style={{ fontWeight: 'bold' }}>
																						Description :
																					</span>
																					<span>
																						{data.description}<br />
																					</span>
																				</div>) : ('')}
																		</div>
																	</div>





																	<div class="treatmentComplaintNoted">
																		Noted by <span> {data.notedBy}</span>
																	</div>
																</div>
															</div>
														))}
													</div>
												))}
												<div>


													<div class="treatmentPlanDate" style={{ paddingLeft: '10px' }}>
														<b>Teeth Details</b>
													</div>
													{pmKeyData.map((item, i) => (
														<div>
															{item.teeth.length > 0 && (
																<div class="treatmentComplaintWrap">

																	<div class="treatmentComplaintRowWrap" style={{ width: '100%' }}>

																		<div class="treatmentComplaintRow">

																			<div class="treatmentComplaintLeft">
																				{item.createdDate}
																			</div>
																			<div class="keySelectionWrap" >
																				<div class="keySelectTop" >
																					<div class="keySelectLeft">
																						<span class="keyHead">Key :</span>
																						<ul>
																							<li>
																								<span class="keyLetter">D</span>
																								<span class="keyDes">- Decay</span>
																							</li>
																							<li>
																								<span class="keyLetter">M</span>
																								<span class="keyDes">- Missing/Extracted</span>
																							</li>
																							<li>
																								<span class="keyLetter">F</span>
																								<span class="keyDes">- Filled</span>
																							</li>
																							<li>
																								<span class="keyLetter">R</span>
																								<span class="keyDes">- Replaced</span>
																							</li>
																							<li>
																								<span class="keyLetter">X</span>
																								<span class="keyDes">- Recommended Extraction</span>
																							</li>
																							<li>
																								<span class="keyLetter">#</span>
																								<span class="keyDes">- Fractured Tooth</span>
																							</li>
																							<li>
																								<span class="keyLetter">I</span>
																								<span class="keyDes">- Impacted</span>
																							</li>
																							<li>
																								<span class="keyLetter">P</span>
																								<span class="keyDes">- Periodontal/Pockets</span>
																							</li>
																						</ul>
																					</div>
																					<div class="keySelectRight">
																						<div class="keySelectDiv">
																							<div class="keySelectDivTopOne">
																								<div class="keySelectDivTop">
																									<div class="keySelectDivTopLeft">
																										<span class="keyItem">
																											<span id={"appendeText55" + i} class="keySelector" />
																											<span>E</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText54" + i} class="keySelector" />
																											<span>D</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText53" + i} class="keySelector" />
																											<span>C</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText52" + i} class="keySelector" />
																											<span>B</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText51" + i} class="keySelector" />
																											<span>A</span>
																										</span>
																									</div>
																								</div>
																								<div class="keySelectDivBottom">
																									<div class="keySelectDivTopLeft">
																										<span class="keyItem">
																											<span>18</span>
																											<span id={"appendeText18" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>17</span>
																											<span id={"appendeText17" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>16</span>
																											<span id={"appendeText16" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>15</span>
																											<span id={"appendeText15" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>14</span>
																											<span id={"appendeText14" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>13</span>
																											<span id={"appendeText13" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>12</span>
																											<span id={"appendeText12" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>11</span>
																											<span id={"appendeText11" + i} class="keySelector" />
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>
																						<div class="keySelectDiv">
																							<div class="keySelectDivTopTwo">
																								<div class="keySelectDivTop">
																									<div class="keySelectDivTopRight">
																										<span class="keyItem">
																											<span id={"appendeText61" + i} class="keySelector" />
																											<span>A</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText62" + i} class="keySelector" />
																											<span>B</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText63" + i} class="keySelector" />
																											<span>C</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText64" + i} class="keySelector" />
																											<span>D</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText65" + i} class="keySelector" />
																											<span>E</span>
																										</span>
																									</div>
																								</div>
																								<div class="keySelectDivBottom">
																									<div class="keySelectDivTopRight">
																										<span class="keyItem">
																											<span>21</span>
																											<span id={"appendeText21" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>22</span>
																											<span id={"appendeText22" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>23</span>
																											<span id={"appendeText23" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>24</span>
																											<span id={"appendeText24" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>25</span>
																											<span id={"appendeText25" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>26</span>
																											<span id={"appendeText26" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>27</span>
																											<span id={"appendeText27" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>28</span>
																											<span id={"appendeText28" + i} class="keySelector" />
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>
																						<div class="keySelectDiv">
																							<div class="keySelectDivTopOne keyBorder">
																								<div class="keySelectDivBottom">
																									<div class="keySelectDivBottomLeft">
																										<span class="keyItem">
																											<span id={"appendeText48" + i} class="keySelector" />
																											<span>48</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText47" + i} class="keySelector" />
																											<span>47</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText46" + i} class="keySelector" />
																											<span>46</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText45" + i} class="keySelector" />
																											<span>45</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText44" + i} class="keySelector" />
																											<span>44</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText43" + i} class="keySelector" />
																											<span>43</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText42" + i} class="keySelector" />
																											<span>42</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText41" + i} class="keySelector" />
																											<span>41</span>
																										</span>
																									</div>
																								</div>
																								<div class="keySelectDivTop">
																									<div class="keySelectDivBottomLeft">
																										<span class="keyItem">
																											<span>E</span>
																											<span id={"appendeText85" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>D</span>
																											<span id={"appendeText84" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>C</span>
																											<span id={"appendeText83" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>B</span>
																											<span id={"appendeText82" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>A</span>
																											<span id={"appendeText81" + i} class="keySelector" />
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>
																						<div class="keySelectDiv">
																							<div class="keySelectDivTopTwo keyBorder">
																								<div class="keySelectDivBottom">
																									<div class="keySelectDivBottomRight">
																										<span class="keyItem">
																											<span id={"appendeText31" + i} class="keySelector" />
																											<span>31</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText32" + i} class="keySelector" />
																											<span>32</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText33" + i} class="keySelector" />
																											<span>33</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText34" + i} class="keySelector" />
																											<span>34</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText35" + i} class="keySelector" />
																											<span>35</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText36" + i} class="keySelector" />
																											<span>36</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText37" + i} class="keySelector" />
																											<span>37</span>
																										</span>
																										<span class="keyItem">
																											<span id={"appendeText38" + i} class="keySelector" />
																											<span>38</span>
																										</span>
																									</div>
																								</div>
																								<div class="keySelectDivTop">
																									<div class="keySelectDivBottomRight">
																										<span class="keyItem">
																											<span>A</span>
																											<span id={"appendeText71" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>B</span>
																											<span id={"appendeText72" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>C</span>
																											<span id={"appendeText73" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>D</span>
																											<span id={"appendeText74" + i} class="keySelector" />
																										</span>
																										<span class="keyItem">
																											<span>E</span>
																											<span id={"appendeText75" + i} class="keySelector" />
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>


																			</div>
																		</div>






																	</div>


																</div>
															)}
														</div>
													))}
												</div>

											</div>

											{/*  */}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default CasesheetDentalHistory;
