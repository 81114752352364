import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Printer_icon from '../images/icons/printer_icon.png';
import img1 from '../images/icons/treatment.png';
import Tickk from '../images/icons/tickk.png';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { Link } from 'react-router-dom';
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import CompletedTick from '../images/icons/tickdpdwn.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
function TreatmentPlans() {
	const navigate = useNavigate();
	const [treatmentPlanList, setTreatmentPlanList] = useState([]);
	const [isData, setIsData] = useState(false);
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	useEffect(() => {
		listing();
	}, []);

	const listing = () => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			memberProcedureDentalOrderId: '',
		};
		callingAPI('treatmentPlanDetails/treatmentListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setTreatmentPlanList(data.data.result);
				//setIsData(true);
			} else {
				//setIsData(true);
			}
		});
	};
	const treatmentPlanDelete = (memberProcedureDentalOrderId, divId, id) => {
		var x = window.confirm('Do you want to delete?');
		if (x) {
			//setIsData(false);
			const apiJson = {
				memberProcedureDentalOrderId: memberProcedureDentalOrderId,
			};
			callingAPI('treatmentPlanDetails/deleteTreatmentPlanDetails', apiJson).then((data) => {
				if (data.data.success === '1') {
					droplist(divId, id)
					setTreatmentPlanList("")
					listing();
				}
			});
		}
	};
	const addTreatmentPlansClick = (data) => {
		navigate('/patients/addCompletedPlans', { state:  data  })
	}
	const completedProcedures = (i, j, n, hidMemberProcedureDentalDetailsId) => {

		var treatmentArray = treatmentPlanList[i].itemArray[j].checkedValue;
		if (typeof treatmentArray === 'undefined') {
			treatmentArray = [];
		}
		var treatmentArrayIndex = treatmentArray.indexOf(hidMemberProcedureDentalDetailsId);
		if (treatmentArrayIndex >= 0) {
			treatmentArray.splice(treatmentArrayIndex, 1);
		} else {
			treatmentArray.push(hidMemberProcedureDentalDetailsId);
		}
		treatmentPlanList[i].itemArray[j].checkedValue = treatmentArray;
		treatmentPlanList[i].itemArray[j].checkedValueIds = treatmentArray.toString().replace(/[\[\]']+/g, '');
		setTreatmentPlanList([...treatmentPlanList]);
	};
	const markAsCompleted = (i, j, n) => {
		var ids = treatmentPlanList[i].itemArray[j].checkedValueIds;
		if (ids === '' || typeof ids === 'undefined') {
			alert('Select Procedure');
		} else {
			document.getElementById('treatmentCompleteTick' + j).click();
		}
	};
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		//elementDisplay.style.display = elementDisplay.style.display === 'block' ? 'none' : 'block';
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})

		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const editTreatment = (data) => {
		navigate('/patients/addTreatmentPlans', { state:  data  })
	}
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll tramntPlanDpdwnWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{/* {isData === false ? <Loader /> : ''} */}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">TREATMENT PLANS</span>

												<a href="/patients/addTreatmentPlans">
													<span class="treatmentPlanBtn pl_blue_btn">+ Add</span>
												</a>
											</div>
											{treatmentPlanList.length > 0 ? (
												treatmentPlanList.map((listObj, i) => (
													<div class="treatmentPlanList1" style={{ height: 'auto' }}>
														<div class="treatmentPlanDate">{listObj.visitDate}</div>
														<div class="treatmentTable planActive">
															<table>
																<thead>
																	<tr>
																		<th class="col-md-4">
																			<div class="dentalProName">PROCEDURE</div>
																		</th>
																		<th class="col-md-1" >
																			<div class="dentalProCost" style={{ textAlign: "center" }}>QTY</div>
																		</th>
																		<th class="col-md-1" style={{ padding: "0px" }}>
																			<div class="dentalProCost">COST</div>
																		</th>
																		<th class="col-md-1" />
																		<th class="col-md-1">
																			<div class="dentalProDis" style={{ textAlign: "right", paddingRight: '0px' }}>TAX</div>
																		</th>
																		<th class="col-md-2">
																			<div class="dentalProTotal" style={{ paddingRight: "0px" }}>TOTAL</div>
																		</th>
																		<th class="col-md-3">
																			<div class="dentalProNote">NOTES</div>
																		</th>
																	</tr>
																</thead>
																{listObj.itemArray.map((itemObj, j) => (
																	<tbody>
																		{itemObj.item.map((obj, n) => (
																			<tr class="groupBrdr">
																				<td class="col-md-3">
																					<div class="dentalProName">
																						<div class="optionWrapper">
																							{(() => {
																								if (
																									obj.isPaymentCompleted ===
																									'1'
																								) {
																									return (
																										<img
																											src={img1}
																											title="Invoice Generated"
																										/>
																									);
																								} else if (
																									obj.isCompleted ===
																									'1'
																								) {
																									return (
																										<img
																											src={Tickk}
																											title="Completed Procedure"
																										/>
																									);
																								} else {
																									return (
																										<div>
																											<input
																												type="checkbox"
																												id={
																													'chkCompleted' +
																													obj.hidMemberProcedureDentalDetailsId
																												}
																												onClick={() =>
																													completedProcedures(
																														i,
																														j,
																														n,
																														obj.hidMemberProcedureDentalDetailsId
																													)}
																											/>
																											<label
																												for={
																													'chkCompleted' +
																													obj.hidMemberProcedureDentalDetailsId
																												}
																											/>
																										</div>
																									);
																								}
																							})()}
																						</div>
																						<span
																							class="elipsis" style={{ width: "100%", maxWidth: "175px" }}
																							title={obj.procedureName}
																						>
																							<b>{obj.procedureName}</b>
																						</span>
																						<span
																							class="elipsis"
																							title={obj.procedureCode}
																							style={{ fontSize: '10px' }}
																						>
																							{obj.procedureCode}
																						</span>
																						<br />
																						{obj.hidTeethNumber !== '' &&
																							obj.hidTeethNumber !== null &&
																							obj.hidTeethNumber !==
																							'None Selected' && (
																								<span
																									class="elipsis"
																									title={
																										obj.hidTeethNumber
																									}
																									style={{
																										fontSize: '11px',
																										color: '#004d75',
																									}}
																								>
																									Teeth :{' '}
																									{obj.hidTeethNumber}
																								</span>
																							)}
																					</div>
																				</td>
																				<td
																					class="col-md-1 treatQty"
																					style={{ verticalAlign: 'middle' }}
																				>
																					{obj.quantity}
																				</td>

																				<td
																					class="col-md-1"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProCost">
																						{parseFloat(
																							obj.treatmentCost).toFixed(2)}

																					</div>
																				</td>
																				<td class="col-md-1" />
																				<td
																					class="col-md-1"
																					style={{ verticalAlign: 'middle', textAlign: "right" }}
																				>
																					<div class="dentalProDis" style={{ textAlign: "right" }}>
																						{parseInt(
																							obj.hidTaxIndividual
																						).toFixed(2)}
																					</div>
																				</td>
																				<td
																					class="col-md-2"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProTotal">
																						{parseFloat(
																							obj.netAmount
																						).toFixed(2)}
																					</div>
																				</td>
																				<td
																					class="col-md-3"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProNote">
																						<span class="elipsisNotes" title={obj.notes}>

																							{obj.notes}
																						</span>
																						{n == 0 ? (
																							<div className="pl_actions">
																								<button className="pl_print">
																									<a
																										target="_blank"
																										href={
																											'/patients/treatmentPlansPrint/' +
																											obj.hidMemberProcedureDentalOrderId +
																											'||' + localStorage.getItem('hospitalId')
																										}
																									>
																										<img
																											src={
																												Printer_icon
																											}
																										/>
																									</a>{' '}
																								</button>
																								<label class="dropdown">
																									<div class="dd-button" onClick={() => droplist("dropdown", obj.hidMemberProcedureDentalOrderId)} />

																									<input
																										type="checkbox"
																										class="dd-input"
																										id="test"
																									/>
																									{/* {(obj.isCompleted === 0 && obj.isPaymentCompleted === 0)? */}
																									<ul class="dd-menu" id={"dropdown" + obj.hidMemberProcedureDentalOrderId} >
																										{(() => {
																											if (itemObj.invoiceCompleteFlag === true) {
																												return (
																													<li onClick={() => markAsCompleted(i, j, n)}>
																														<img src={CompletedTick} style={{ width: '12px', marginRight: '5px' }} />{' '}
																														Completed
																														<nav  
																														onClick={()=>addTreatmentPlansClick({ dentalOrderId: itemObj.checkedValueIds, isCompleted: false,  })}
																														style={{ display: 'none' }}
																															id={'treatmentCompleteTick' + j}
																														>
																															</nav>
																													</li>
																												);
																											}
																										})()}
																										<li onClick={() => editTreatment({ dentalOrderId: obj.hidMemberProcedureDentalOrderId })}>
																											{/* <Link to={{ pathname: '/patients/addTreatmentPlans', state: { dentalOrderId: obj.hidMemberProcedureDentalOrderId, }, }} > */}
																												<img src={Editicon} style={{ width: '15px', marginRight: '3px' }} />{' '}
																												{itemObj.itemCount > 1 ? 'Edit All' : 'Edit'}
																											{/* </Link> */}
																										</li>
																										{(() => {
																											if (itemObj.invoiceFlag === false) {

																												return (
																													<li onClick={(e) => treatmentPlanDelete(obj.hidMemberProcedureDentalOrderId, "dropdown", obj.hidMemberProcedureDentalOrderId)}>
																														<img src={Delete} style={{ width: '15px', marginRight: '5px', }} />
																														{itemObj.itemCount > 1 ? ('Delete All') : ('Delete')}
																													</li>
																												);
																											}
																										})()}
																									</ul>
																									{/* :""} */}
																								</label>
																							</div>
																						) : (
																							''
																						)}
																					</div>
																				</td>
																			</tr>
																		))}
																		<tr class="dentalProPlaned">
																			<input
																				type="hidden"
																				name="hidTreatmentIds10"
																				id="hidTreatmentIds10"
																				value=""
																			/>
																			<td colspan="3">
																				<div class="plannedBy">
																					Planned by{' '}
																					<span>{itemObj.Plannedby}</span>
																				</div>
																			</td>
																			<td colspan="3">
																				<div class="estimatedPrice txtRight">
																					Estimated amount :{' '}
																					{parseFloat(itemObj.estimatedAmount).toFixed(2)}
																				</div>
																			</td>
																			<td colspan="1">
																				<div class="estimatedPrice">&nbsp;</div>
																			</td>
																		</tr>
																	</tbody>
																))}
															</table>
														</div>
													</div>
												))
											) :
												// isData === true ?
												(
													// <NoData />
													<span>
														<span class="treatmentCommonTxt1">
															<span>
																{' '}
																No Treatment Plan Found
															</span>
														</span>
													</span>
												)
												// : (
												// 	''
												// )
											}
										</div>

										<div id="addTreatmentPlanDiv" class="addTreatmentCover">
											<div class="loaderWrap" id="loader1" style={{ display: 'none' }}>
												<div class="loaderPurple" />
												{/* <img src="../images/dental/purpleLoader.png"> */}
											</div>
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default TreatmentPlans;
