import React from 'react';
import Logo from '../images/media/logo.png';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config'
function PatientpaymentInvoice() {
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [invoiceDate, setInvoiceDate] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [dueAmount, setDueAmount] = useState("");
	const [advanceAmount, setAdvanceAmount] = useState("");
	const [recieptList, setRecieptList] = useState({});
	const [prescriptionArray, setPrescriptionArray] = useState([]);
	const [procedureArray, setProcedureArray] = useState([]);
	const [inventoryArray, setInventoryArray] = useState([]);
	const [labArray, setLabArray] = useState([]);
	const [roomArray, setRoomArray] = useState([]);
	const [cafeteriaArray, setCafeteriaArray] = useState([]);
	const [laundryArray, setLaundryArray] = useState([]);

	useEffect(() => {
		callingAPI('commonUserFunctions/receiptPrint', {
			"receiptId": 2518
		})
			.then((res) => {

				//alert(res.data.result[0].hospitalDetails[0].hospitalAddress);
				setMemberDetails(res.data.result[0].memberDetails);
				setHospitalDetails(res.data.result[0].hospitalDetails);
				setInvoiceDate(res.data.result[0].finalArray[0].receipt_date);
				setInvoiceNumber(res.data.result[0].finalArray[0].main[0].item[0].invoiceNumber);
				setRecieptList(res.data.result[0].finalArray[0].main[0].recieptList[0])
				setPrescriptionArray(res.data.result[0].finalArray[0].main[0].item[0].prescriptionArray);
				setProcedureArray(res.data.result[0].finalArray[0].main[0].item[0].procedureArray);
				setInventoryArray(res.data.result[0].finalArray[0].main[0].item[0].inventoryArray);
				setLabArray(res.data.result[0].finalArray[0].main[0].item[0].labArray);
				setRoomArray(res.data.result[0].finalArray[0].main[0].item[0].roomArray);
				setCafeteriaArray(res.data.result[0].finalArray[0].main[0].item[0].cafeteriaArray);
				setLaundryArray(res.data.result[0].finalArray[0].main[0].item[0].laundryArray);
				setDueAmount(res.data.result[0].dueAmount);
				setAdvanceAmount(res.data.result[0].advanceAmount);
			})
	}, []);
	return (
		<div>
			<div align="center">
				<div
					align="center"
					style={{
						width: '550px',
						height: '595px',
						marginLeft: '0px',
						marginRight: '0px',
						marginTop: '0px',
						marginBottom: '0px',
					}}
				>
					<table style={{ width: '100%', marginTop: '0px' }} border="0">
						<thead>
							<tr>
								<th style={{ fontWeight: '400' }}>
									<div class="printOutHeaderSec ">
										<table style={{ width: '100%' }}>
											<tbody>
												<tr height="80px">
													<td colspan="3" valign="middle" align="center">
														<div style={{ width: '13%', float: 'left' }}>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '16px',
																	clear: 'both',
																	width: '100%;',
																}}
															>
																<span
																	style={{
																		marginTop: '-5px',
																		display: 'inline-block',
																		width: '100%',
																	}}
																>
																	<img
																		src={Logo}
																		style={{
																			display: 'block',
																			width: 'auto',
																			height: '45px ',
																		}}
																	/>
																</span>
															</p>
														</div>
														<div
															class="practicePrintName "
															style={{ width: '87%', float: 'left' }}
														>
															<p
																style={{
																	fontWeight: '600',
																	fontSize: '16px',
																	clear: 'both',
																	width: '100%',
																	marginBottom: '1px',
																	marginTop: '0',
																}}
															/>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '12px !important',
																	clear: 'both',
																	width: '100%',
																	margin: '0',
																}}
															/>
														</div>
													</td>
												</tr>

												<tr>
													<td colspan="3">
														<hr color="#142536" style={{ margin: ' 0' }} />
													</td>
												</tr>
												<tr>
													<td
														width="50%"
														colspan="2"
														align="left"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													/>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													/>
												</tr>

												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Name : {memberDetails.memberFirstName + " " + memberDetails.memberLastName}
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Date : {invoiceDate}{' '}
													</td>
												</tr>
												<tr>
													{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
														(<td
															colspan="2"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															MR No : {memberDetails.memberMrNo}
														</td>) :
														(<td
															colspan="2"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															PR No : {memberDetails.memberPrNo}
														</td>)
													}
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Phone No : {memberDetails.SecuserMobileNumber}
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Age: {memberDetails.memberAge}
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Email Id : {memberDetails.secUserEmailid}{' '}
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Sex : {memberDetails.memberGender}
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													/>
												</tr>

												<tr>
													<td colspan="3">
														<hr color="#142536" style={{ margin: '0' }} />
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr>
													<td
														style={{
															fontSize: '14px',
															paddingBottom: '10px',
															textAlign: 'left',
														}}
													>
														Payments
													</td>
													<td
														style={{
															fontSize: '14px',
															paddingBottom: '10px',
															textAlign: 'right',
														}}
													>
														Receipt Number :&nbsp; {recieptList.receiptNumber}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr
													style={{
														fontSize: '13px',
														color: '#393939',
														background: ' #eae8e8',
													}}
												>
													<td
														style={{
															boxSizing: ' border-box',
															padding: ' 5px 2px',
															width: '10%',
															textAlign: 'left',
															fontWeight: '600',

															paddingLeft: '3px',
														}}
													>
														INVOICE
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '90%',
															textAlign: 'left',
															position: 'relative',
														}}
													>
														<div
															class="procedureCode"
															style={{ fontSize: '10px !important' }}
														>
															<div
																class="procedureCodeHead"
																style={{ fontWeight: '600' }}
															>
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	TOWARDS
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Batch
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Exp
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	Qty
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Unit Price
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Cost
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Tax
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Total
																</div>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												{prescriptionArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Prescription
																	</div>
																</div>
																{prescriptionArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>
																		<div class="procedureCodeIntake">
																			<strong>HSN Code : </strong>
																			<span>{details.hsnCode}</span>
																		</div>

																		<div class="procedureCodeIntake">
																			<strong>Intake : </strong>
																			<span>{details.instructions}  ({details.inTake})</span>
																		</div>
																	</div>
																)}
															</div>

														</td>
													</tr>
												}
												{procedureArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Procedure
																	</div>
																</div>
																{procedureArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>

																	</div>
																)}
															</div>
														</td>
													</tr>
												}
												{inventoryArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Inventory
																	</div>
																</div>
																{inventoryArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>

																	</div>
																)}
															</div>

														</td>
													</tr>
												}
												{labArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Lab
																	</div>
																</div>
																{labArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>

																	</div>
																)}
															</div>

														</td>
													</tr>
												}
												{roomArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Room
																	</div>
																</div>
																{roomArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>

																	</div>
																)}
															</div>

														</td>
													</tr>
												}
												{cafeteriaArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Cafeteria
																	</div>
																</div>
																{cafeteriaArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>

																	</div>
																)}
															</div>

														</td>
													</tr>
												}
												{laundryArray.length > 0 &&
													<tr style={{ borderBottom: '1px solid #ddd' }}>
														<td
															style={{
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '10%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '10px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{invoiceNumber}
														</td>
														<td
															class="inoviceBorder"
															style={{
																verticalAlign: 'top !important',
																boxSizing: 'border-box',
																padding: '5px 2px',
																width: '90%',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>

															<div
																class="procedureCode"
																style={{
																	fontSize: '10px !important',
																	marginTop: '0',

																	paddingBottom: '2px',
																	marginBottom: '2px',
																}}
															>
																<div
																	class="procedureCodeHead"
																	style={{ fontWeight: '600', paddingBottom: '3px' }}
																>
																	<div
																		style={{
																			width: '18%',
																			display: 'inline-block',
																			textAlign: 'left',
																			verticalAlign: 'top',
																		}}
																	>
																		Laundry
																	</div>
																</div>
																{laundryArray.map((details) =>
																	<div class="procedureCodeValue">
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.itemName}
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.batchNo}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.expireDate}
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.quantity}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.unitPrice}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.cost}
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.taxAmount}
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																			}}
																		>
																			{details.totalAmount}
																		</div>

																	</div>
																)}
															</div>

														</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr>
													<td>
														<div
															class="taxValueTable"
															style={{
																width: '42%',
																marginTop: '-6px',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
																color: '#393939',
															}}
														/>

														<div
															style={{
																width: '56%',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
															}}
														>
															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Total amount (INR):&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	{recieptList.totalAmount}
																</span>
															</div>

															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Amount paid (INR):&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	{advanceAmount}
																</span>
															</div>

															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Mode of payment:&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	{recieptList.paymentMode}
																</span>
															</div>
															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Total due amount (INR):&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	{dueAmount}
																</span>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>
									<div class="printOutFooterSec">
										<table style={{ width: '100%' }}>
											<tbody>
												<tr>
													<td
														width="25%"
														colspan="2"
														align="left"
														style={{ wordBreak: 'break-all' }}
													>
														<div align="justify" style={{ fontSize: '10px' }}>
															{/* {hospitalDetails.hospitalEmailId} */}
														</div>
													</td>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '10px', wordBreak: ' break-all' }}
													>
														{/* Dr. Manu */}
													</td>
												</tr>

												<tr>
													<td colspan="3">
														<div align="justify" class="practiceFootBtm">
															{/* Working time */}
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="3" style={{ paddingTop: '10px' }} />
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div class="printBtm" style={{ width: '595px', margin: 'auto' }}>
				<p
					style={{
						width: '70px',
						textAlign: 'center',
						paddingTop: '5px',
						lineHeight: '18px',
						display: 'inline-block',
						fontWeight: 'bold',
						fontSize: '12px',
						color: 'white',
						background: 'rebeccapurple none repeat scroll 0% 0%',
						marginTop: '2px',
						cursor: 'pointer',
						borderRadius: '5px',
						marginBottom: '2px',
						height: '25px',
					}}
				>
					Print
				</p>
			</div>
		</div>
	);
}
export default PatientpaymentInvoice;
