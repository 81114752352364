// import React from 'react';
import React, { useState, useEffect } from 'react';
import Priscription from '../images/media/prescription.png';
import NoData from '../Common/noDataFound';
import NoVisit from '../Common/noPreviousVisitFound';
import NoTodayVisit from '../Common/noTodayVisitFound';
import NoMedication from '../Common/noMedicationDetailsFound';
import NoAssess from '../Common/noAssessmentsFound';
import NoTreat from '../Common/noTreatmentsFound';
import NoLaborder from '../Common/noLaborderFound';
import NoTestResult from '../Common/noTestResultFound';
import NoAllergy from '../Common/noAllergy';
import NoPresc from '../Common/noPrescription';
import Eye from '../images/icons/eye.png';
import moment from 'moment';

import { callingAPI } from '../config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import HealthBackGroundSub from './healthBackground';
var getS3folder = 'https://purplehealthfiles.s3.amazonaws.com/test/practicemanagment/PatientVisitDetails/';
function Patientright() {
	const [previousAppointmentDaysResult, setPreviousAppointmentDaysResult] = useState([]);
	const [medicineData, setMedicineData] = useState([]);
	// const [newDates, setNewDate] = useState(new Date())
	const [newDates, setNewDate] = useState(moment(new Date()).format("DD, MMM YYYY"))
	const [medicationTodayData, setMedicationTodayData] = useState([]);
	const [medicationWriteQueryData, setMedicationWriteQueryData] = useState([]);
	const [medicationTodayWriteData, setMedicationTodayWriteData] = useState([]);
	const [treatmentPlanResult, setTreatmentPlanResult] = useState([]);
	const [treatmentPlanToday, setTreatmentPlanToday] = useState([]);
	const [testData, setTestData] = useState([]);
	const [hosAdminLabHis, setHosAdminLabHis] = useState([]);
	const [testResultData, setTestResultData] = useState([]);
	const [testResultTodayData, setTestResultTodayData] = useState([]);
	const [testTodayData, setTestTodayData] = useState([]);
	const [visitData, setVisitData] = useState([]);
	const [allergyData, setAllergyData] = useState([]);
	const [prevData, setImagePrev] = useState('');
	const [assessmentData, setAssessmentData] = useState([]);
	const [assessmentTodayData, setAssessmentTodayData] = useState([]);
	const [adminlabHistoryToday, setAdminlabHistoryToday] = useState([]);
	const [popUp, setPopUp] = useState(false);
	const [todayVisit, setTodayVisit] = useState([]);
	useEffect(() => {

		const PostData = {
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('commonUserFunctions/patientMedicalHistoryNew', PostData)
			.then((data) => {
				setPreviousAppointmentDaysResult(data.data.result.previousAppointmentDaysResult);
				setMedicineData(data.data.result.medicineData);
				setMedicationWriteQueryData(data.data.result.medicationWriteQueryData);
				setTreatmentPlanResult(data.data.result.treatmentPlanResult);
				setTestData(data.data.result.testData);
				setHosAdminLabHis(data.data.result.hosAdminLabHis);
				setTestResultData(data.data.result.testResultData);
				setVisitData(data.data.result.visitData);
				setAllergyData(data.data.result.allergyData);
				setAssessmentData(data.data.result.assessmentData);
				setTodayVisit(data.data.result.todayVisit)
				setTreatmentPlanToday(data.data.result.treatmentPlanToday)
				setMedicationTodayData(data.data.result.medicationTodayData)
				setMedicationTodayWriteData(data.data.result.medicationTodayWriteData)
				setTestTodayData(data.data.result.testTodayData)
				setAdminlabHistoryToday(data.data.result.adminlabHistoryToday)
			})
			.catch();
	}, []);
	const Edit = (labReqId, labFormId, servprvid) => {

		if (labFormId === 1) {
			window.sessionStorage.setItem('labReqId', labReqId)
			window.sessionStorage.setItem('labFormId', labFormId)
			window.sessionStorage.setItem('ServiceProviderId', servprvid)
			//	window.location.href="/patients/labOrderDentalPrint"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderDentalPrint`, "_blank")
		}
		if (labFormId === 2) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			// window.location.href="/patients/labOrderMetalFreeCeramic"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderMetalFreeCeramic`, '_blank');
		}
		if (labFormId === 3) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			// window.location.href="/patients/labOrderMetalCeramic"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderMetalCeramic`, '_blank');
		}
		if (labFormId === 5) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			// window.location.href="/patients/labOrderMetalCeramic"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderOrthoCreation`, '_blank');
		}
	}
	const closePopUp = () => {
		setPopUp(false);
	};
	return (
		<div className="patientright patientrightWrapper">
			<div className="rightTagSection">
				<div className="rightTagList">
					<div className="rightTagTitle">Allergies</div>
					<div className="tagListingDiv">
						<HealthBackGroundSub type="Allergy" />
					</div>
				</div>
				<div className="rightTagList">
					<div className="rightTagTitle">Drug History</div>
					<div className="tagListingDiv">
						<HealthBackGroundSub type="DrugHistory" />
					</div>
				</div>
				<div className="rightTagList">
					<div className="rightTagTitle">Serology</div>
					<div className="tagListingDiv">
						<HealthBackGroundSub type="Serology" />
					</div>
				</div>

				<div className="rightTagList">
					<div className="rightTagTitle">Illness History</div>
					<div className="tagListingDiv">
						<HealthBackGroundSub type="IllnessHistory" />
					</div>
				</div>
				<div className="rightTagList">
					<div className="rightTagTitle">Family History</div>
					<div className="tagListingDiv">
						<HealthBackGroundSub type="FamilyHistory" />
					</div>
				</div>
				<div className="rightTagList">
					<div className="rightTagTitle">Social History</div>
					<div className="tagListingDiv">
						<HealthBackGroundSub type="SocialHistory" />
					</div>
				</div>
				<div className="rightTagList pl_accordion">
					<div className="rightTagTitle">Patient Medical History</div>
					{/* ================= */}
					<input type="checkbox" id="visitToday" />
					<label className="accrdnLabel" for="visitToday">
						Visit - Today  ({newDates})
					</label>


					<div class="content todayVisitView">
						{todayVisit?.length > 0 || treatmentPlanToday?.length > 0 || medicationTodayData?.length > 0 || medicationTodayWriteData?.length > 0 || hosAdminLabHis?.length > 0 || testTodayData?.length > 0 ? (
							<div class="content" style={{ padding: '0px' }}>
								<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
									<div class="table-responsive">


										{/*  */}

										{/*  */}
										{todayVisit?.length > 0 ? (
											// <table class="table tableFixHead">
											// 	<thead>
											// 		<tr>
											// 			<th class="col-md-2 col-lg-2 thBg">Date</th>
											// 			<th class="col-md-2 col-lg-2 thBg">Doctor</th>
											// 			<th class="col-md-2 col-lg-2 thBg">Reason</th>
											// 		</tr>
											// 		</thead>
											// 		<tbody class="tbodyBg">
											// 	{todayVisit.map((item, index) => (

											// 			<tr>
											// 				<td class="tdBg">{item.date_of_visit}</td>
											// 				<td class="tdBg">{item.doctorName}</td>
											// 				<td class="tdBg">{item.reason}</td>
											// 			</tr>

											// 	))}
											// 			</tbody>

											// </table>
											<div class="table-responsive tableFixHead">
												<table>
													<thead>
														<tr>
															<th class="col-md-2 col-lg-2 thBg">Date</th>
															<th class="col-md-2 col-lg-2 thBg">Doctor</th>
															<th class="col-md-2 col-lg-2 thBg">Reason</th>
														</tr>
													</thead>
													<tbody class="tbodyBg">
														{todayVisit.map((item, index) => (

															<tr>
																<td class="tdBg">{item.date_of_visit}</td>
																<td class="tdBg">{item.doctorName}</td>
																<td class="tdBg">{item.reason === "undefined" ? "" : item.reason}</td>
															</tr>

														))}
													</tbody>
												</table>
											</div>
										) : (
											''
										)}
									</div>
									<br />
									{treatmentPlanToday?.length > 0 ? (
										<div class="table-responsive">
											<label style={{ fontSize: "14px", fontWeight: "600" }}>
												Treatment
											</label>
											<div class="table-responsive tableFixHead">
												<table class="table">
													<thead>
														<tr>
															<th class="col-md-2 col-lg-2 thBg">Treatment Date</th>
															<th class="col-md-2 col-lg-2 thBg">Procedure</th>
															<th class="col-md-2 col-lg-2 thBg">Teeth</th>
															<th class="col-md-2 col-lg-2 thBg">Total Amount (Rs)</th>
															<th class="col-md-2 col-lg-2 thBg">Complete</th>
														</tr>
													</thead>
													<tbody class="tbodyBg">
														{treatmentPlanToday.map((item, index) => (
															<tr>
																<td class="tdBg">{item.startDate}</td>
																<td class="tdBg">
																	<label
																		style={{ fontWeight: '600!important' }}
																		class="elipsis"
																		title={item.procedure_name}
																	>
																		{item.procedure_name}
																	</label>
																	<span
																		style={{
																			width: '105px',
																			fontSize: '10px',
																			color: '#000',
																			textAlign: 'left!important',
																		}}
																		class="elipsis"
																		title={item.procedure_code}
																	>
																		{item.procedure_code}
																	</span>
																</td>
																<td class="tdBg">{item.teeth_details}</td>
																<td class="tdBg">{item.amount.toFixed(2)}</td>
																<td class="tdBg">{item.is_completed === true ? "YES" : "NO"}</td>
															</tr>
														))}
													</tbody>
													{/* <NoTreat /> */}
												</table>
											</div>
										</div>) : (
										''
									)}
									<br /><br />
									<div class="content" style={{ padding: '0px' }}>

										{medicationTodayData?.length > 0 || medicationTodayWriteData?.length > 0 ? (
											<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
												<label style={{ fontSize: "14px", fontWeight: "600" }}>
													Medication
												</label>
												<div class="table-responsive tableFixHead">
													{medicationTodayData?.length > 0 ? (
														<div class="table-responsive tableFixHead">
															<table class="table">
																<thead>
																	<tr>
																		<th class="col-md-3 col-lg-3 thBg">Medicine</th>
																		<th class="col-md-3 col-lg-3 thBg">Frequency &amp; Dosage</th>
																		<th class="col-md-1 col-lg-1 thBg">Qty</th>
																		<th class="col-md-1 col-lg-1 thBg">Days</th>
																		<th class="col-md-2 col-lg-2 thBg">Start Date</th>
																		<th class="col-md-2 col-lg-2 thBg">Instruction</th>
																	</tr>
																</thead>
																<tbody class="tbodyBg">
																	{medicationTodayData.map((item, index) => (
																		<tr>
																			<td class="tdBg">{item.medicineName}</td>
																			<td class="tdBg">{item.instructions}</td>
																			<td class="tdBg">{item.quantity}</td>
																			<td class="tdBg">{item.days}</td>
																			<td class="tdBg">{item.startDate}</td>
																			<td class="tdBg">{item.reason}</td>
																		</tr>
																	))}
																</tbody>
																{/* <NoMedication /> */}
															</table>
														</div>
													) : (
														''
													)}
												</div>

												{medicationTodayWriteData?.length > 0 ?
													<div class="table-responsive">
														{medicationWriteQueryData?.length > 0 ? (
															<div class="table-responsive tableFixHead">
																<table class="table-responsive  ">
																	<thead>
																		<tr>
																			<th class="col-md-6 thBg">Prescribed Date</th>
																			<th class="col-md-6 thBg">Prescription</th>
																		</tr>
																	</thead>

																	<tbody class="tbodyBg">
																		{medicationTodayWriteData.map((item, index) => (


																			<tr>
																				<td class="tdBg">{item.prescDate}</td>

																				<td class="tdBg">
																					<a href="#plpopup1" title="Click to View">
																						<div class="drawTumbItems">
																							{/* {item.image.map((data, j) => ( */}

																							<img
																								src={getS3folder + item.img}
																								onClick={() =>
																									setImagePrev(getS3folder + item.img)
																								}

																							/>
																							{/* ))} */}
																						</div>
																					</a>
																				</td>
																			</tr>

																		))}
																	</tbody>
																	{/* <NoPresc /> */}
																</table></div>

														) : (
															''
														)}

														<div id="plpopup1" class="ploverlay">

															{popUp == false && (
																<div class="plpopup ">
																	<h2>
																		Priscription
																		<a class="close" href='#' onClick={closePopUp}>
																			<span class="close">	× </span>
																		</a>
																	</h2>
																	<div class="plcontent">
																		<div>
																			<img src={prevData} style={{ width: '100%', height: '100%' }} />
																		</div>
																	</div>
																</div>
															)}
														</div>

													</div>
													: ""}
												{/* <NoMedication /> */}
											</div>
										) : (
											''
										)}
									</div>
									{/* {window.sessionStorage.getItem('medicineBranchId') === '4' ? ( */}
									<div class="content" style={{ padding: '0px' }}>
										<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">


											{adminlabHistoryToday?.length > 0 ? (
												<div class="table-responsive">
													<label style={{ fontSize: "14px", fontWeight: "600" }}>
														Lab Order
													</label>
													<div class="table-responsive tableFixHead">
														<table class="table">
															<thead>
																<tr>
																	<th class="col-md-2 col-lg-2 thBg">Test Date</th>
																	<th class="col-md-2 col-lg-2 thBg">Lab Name</th>
																	<th class="col-md-2 col-lg-2 thBg">Test Name</th>
																	<th class="col-md-2 col-lg-2 thBg">Status</th>
																	<th class="col-md-2 col-lg-2 thBg">LabOrder</th>
																</tr>
															</thead>
															{adminlabHistoryToday.map((item, index) => (
																<tbody class="tbodyBg">
																	<tr>
																		<td class="tdBg">{moment(item.order_date).format("DD/MM/YYYY")}</td>
																		<td class="tdBg">{item.labName}</td>
																		<td class="tdBg">{item.lab_form_display_name}</td>
																		<td class="tdBg">{item.status === 1 ? <span style={{ color: "red" }}>Pending</span> : <span style={{ color: "green" }}>Received</span>}</td>
																		<td class="tdBg">
																			<a>
																				<img
																					src={Eye}
																					target="new"
																					title="View Lab Order" style={{ cursor: "pointer" }}
																					onClick={(e) => Edit(item.labReqId, item.labFormId, item.service_provider_id)}
																				/>
																			</a>
																		</td>
																	</tr>
																</tbody>
															))}
														</table>
													</div>
												</div>
											) : (
												''
											)}

										</div>
									</div>

									{/* ):( */}
									<div class="content" style={{ padding: '0px' }}>
										<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">

											{testTodayData?.length > 0 ? (
												<div class="table-responsive">
													<label style={{ fontSize: "14px", fontWeight: "600" }}>
														Lab Order
													</label>
													<table class="table">
														<thead>
															<tr>
																<th class="col-md-2 col-lg-2 thBg">Test Date</th>
																<th class="col-md-2 col-lg-2 thBg">Test Name</th>
																<th class="col-md-2 col-lg-2 thBg">Specimen</th>
																<th class="col-md-2 col-lg-2 thBg">Test Type</th>
																<th class="col-md-2 col-lg-2 thBg">Instructions</th>
															</tr>
														</thead>
														{testTodayData.map((item, index) => (
															<tbody class="tbodyBg">
																<tr>
																	<td class="tdBg">{item.testDate}</td>
																	<td class="tdBg">{item.testName}</td>
																	<td class="tdBg">{item.specimen}</td>
																	<td class="tdBg">{item.testType}</td>
																	<td class="tdBg">{item.comments}</td>
																</tr>
															</tbody>
														))}
														{/* <NoLaborder /> */}
													</table>
												</div>) : (

												''
											)}

										</div>
									</div>
									{/* )} */}
								</div>
							</div>
						)

							: (
								<NoTodayVisit />
							)}
					</div>
					{/* ================= */}

					<input type="checkbox" id="title1" />
					<label className="accrdnLabel" for="title1">
						Previous Visit
					</label>

					<div class="content">
						<div class="content" style={{ padding: '0px' }}>
							<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
								<div class="table-responsive tableFixHead">
									{visitData?.length > 0 ? (
										<table class="table">
											<thead>
												<tr>
													<th class="col-md-2 col-lg-2 thBg">Date</th>
													<th class="col-md-2 col-lg-2 thBg">Doctor</th>
													<th class="col-md-2 col-lg-2 thBg">Reason</th>
												</tr>
											</thead>
											{visitData.map((item, index) => (
												<tbody class="tbodyBg">
													<tr>
														<td class="tdBg">{moment(item.appintmentDate).format("DD , MMM yyyy")}</td>
														<td class="tdBg">{"Dr. " + item.doctorName}</td>
														<td class="tdBg">{item.visitReason === "undefined" ? "" : item.visitReason} </td>
													</tr>
												</tbody>
											))}
										</table>
									) : (
										<NoVisit />
									)}
								</div>
							</div>
						</div>
					</div>
					<input type="checkbox" id="title2" />
					<label className="accrdnLabel" for="title2" >
						Medication
					</label>


					<div class="content" style={{ padding: '0px' }}>
						{medicineData?.length > 0 || medicationWriteQueryData?.length > 0 ? (
							<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
								<div class="table-responsive tableFixHead">
									{/* {medicineData.length > 0 ? ( */}
									<table class="table">
										<thead>
											<tr>
												<th class="col-md-3 col-lg-3 thBg">Medicine</th>
												<th class="col-md-3 col-lg-3 thBg">Frequency &amp; Dosage</th>
												<th class="col-md-1 col-lg-1 thBg">Qty</th>
												<th class="col-md-1 col-lg-1 thBg">Days</th>
												<th class="col-md-2 col-lg-2 thBg">Start Date</th>
												<th class="col-md-2 col-lg-2 thBg">Instruction</th>
											</tr>
										</thead>
										<tbody class="tbodyBg">
											{medicineData.map((item, index) => (
												<tr>
													<td class="tdBg">{item.medicineFullName}</td>
													<td class="tdBg">{item.instructions}</td>
													<td class="tdBg">{item.qty}</td>
													<td class="tdBg">{item.days}</td>
													<td class="tdBg">{item.startDate}</td>
													<td class="tdBg">{item.reason}</td>
												</tr>
											))}
										</tbody>
									</table>
									{/* ) : (
									<NoMedication />
								)} */}
								</div>

								{medicationWriteQueryData?.length > 0 ?
									<div class="table-responsive tableFixHead">
										{/* {medicationWriteQueryData.length > 0 ? ( */}

										<table class="table-responsive  ">
											<thead>
												<tr>
													<th class="col-md-6 thBg">Prescribed Date</th>
													<th class="col-md-6 thBg">Prescription</th>
												</tr>
											</thead>

											<tbody class="tbodyBg">
												{medicationWriteQueryData.map((item, index) => (


													<tr>
														<td class="tdBg">{moment(item.prescDate).format("DD, MMM YYYY")}</td>

														<td class="tdBg">
															<a href="#plpopup1" title="Click to View">
																<div class="drawTumbItems">
																	{item.image.map((data, j) => (

																		<img
																			src={getS3folder + data.imageName}
																			onClick={() =>
																				setImagePrev(getS3folder + data.imageName)
																			}

																		/>
																	))}
																</div>
															</a>
														</td>
													</tr>

												))}
											</tbody>

										</table>

										{/* ) : (
									<NoPresc />
								)} */}

										<div id="plpopup1" class="ploverlay">

											{popUp == false && (
												<div class="plpopup ">
													<h2>
														Priscription
														<a class="close" href='#' onClick={closePopUp}>
															<span class="close">	× </span>
														</a>
													</h2>
													<div class="plcontent">
														<div>
															<img src={prevData} style={{ width: '100%', height: '100%' }} />
														</div>
													</div>
												</div>
											)}
										</div>

									</div>
									: ""}

							</div>
						) : (
							<NoMedication />
						)}
					</div>
					{window.sessionStorage.getItem('medicineBranchId') !== '4' ? (
						<div>
							<input type="checkbox" id="title3" />
							<label className="accrdnLabel" for="title3">
								Diagnosis
							</label>
							<div class="content">
								<div class="content" style={{ padding: '0px' }}>
									<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
										<div class="table-responsive tableFixHead">
											{assessmentData?.length > 0 ? (
												<table class="table">
													<thead>
														<tr>
															<th class="col-md-2 col-lg-2 thBg">Date</th>
															<th class="col-md-2 col-lg-2 thBg">Diagnosis</th>
															<th class="col-md-2 col-lg-2 thBg">Disease</th>
														</tr>
													</thead>
													{assessmentData.map((item, index) => (
														<tbody class="tbodyBg">
															<tr>
																<td class="tdBg">{item.assessmentDate}</td>
																<td class="tdBg">{item.diagnosis}</td>
																<td class="tdBg">{item.disease}</td>
															</tr>
														</tbody>
													))}
												</table>
											) : (
												<NoAssess />
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<input type="checkbox" id="title4" />
					<label className="accrdnLabel" for="title4">
						Allergies
					</label>
					<div class="content">
						<div class="content" style={{ padding: '0px' }}>
							<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory allergyList">
								<div class="table-responsive tableFixHead">
									{allergyData?.length > 0 ? (
										<div>
											{/* // <table class="table">
										// 	<thead>
										// 		<tr>
										// 			<th class="col-md-2 col-lg-2 thBg">Allergies</th>
										// 		</tr>
										// 	</thead> */}
											{allergyData.map((item, index) => (
												// <tbody class="tbodyBg">
												// 	<tr>
												// 		<td class="tdBg">
												<li>{item.allergy}</li>
												// 		</td>
												// 	</tr>
												// </tbody>
											))}
										</div>

									) : (
										<NoAllergy />
									)}
								</div>
							</div>
						</div>
					</div>

					{/* {allergyData.length > 0 ?
					<div class="content">

					{allergyData.map((item, index) => (
						<div class="col-md-12 col-lg-12 mrgTop">
							<ul>
								<li>{item.allergy}</li>
							</ul>
						</div>
						))}
					</div>
					:<NoData />} */}

					<input type="checkbox" id="title5" />
					<label className="accrdnLabel" for="title5">
						Treatment
					</label>

					<div class="content" style={{ padding: '0px' }}>
						<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
							<div class="table-responsive tableFixHead">
								{treatmentPlanResult?.length > 0 ? (
									<table class="table">
										<thead>
											<tr>
												<th class="col-md-2 col-lg-2 thBg">Treatment Date</th>
												<th class="col-md-2 col-lg-2 thBg">Procedure Code</th>
												<th class="col-md-2 col-lg-2 thBg">Teeth</th>
												<th class="col-md-2 col-lg-2 thBg">Total Amount (Rs)</th>
												<th class="col-md-2 col-lg-2 thBg">Complete</th>
											</tr>
										</thead>
										<tbody class="tbodyBg">
											{treatmentPlanResult.map((item, index) => (
												<tr>
													<td class="tdBg">{item.startDate}</td>
													<td class="tdBg">
														<label
															style={{ fontWeight: '600!important' }}
															class="elipsis"
															title={item.procedure_name}
														>
															{item.procedure_name}
														</label>
														<br />
														<span
															style={{
																width: '105px',
																fontSize: '10px',
																color: '#000',
																textAlign: ' left!important',
															}}
															class="elipsis"
															title={item.procedure_code}
														>
															{item.procedure_code}
														</span>
													</td>
													<td class="tdBg">{item.teeth_details}</td>
													<td class="tdBg">{item.amount.toFixed(2)}</td>
													<td class="tdBg">{item.is_completed === true ? "YES" : "NO"}</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<NoTreat />
								)}
							</div>
						</div>
					</div>
					<input type="checkbox" id="title6" />
					<label className="accrdnLabel" for="title6">
						Lab Order
					</label>
					{/* --------------IMA----------------- */}
					{/* {window.sessionStorage.getItem('medicineBranchId') === '4' ?(  */}
					{hosAdminLabHis?.length > 0 || testData?.length > 0 ? (
						<div class="content">
							<div class="content" style={{ padding: '0px' }}>
								<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
									{hosAdminLabHis?.length > 0 ?
										<div class="table-responsive tableFixHead">


											<table class="table">
												<thead>
													<tr>
														<th class="col-md-2 col-lg-2 thBg">Test Date</th>
														<th class="col-md-2 col-lg-2 thBg">Lab Name</th>
														<th class="col-md-2 col-lg-2 thBg">Test Name</th>
														<th class="col-md-2 col-lg-2 thBg">Status</th>
														<th class="col-md-2 col-lg-2 thBg">LabOrder</th>
													</tr>
												</thead>
												{hosAdminLabHis.map((item, index) => (
													<tbody class="tbodyBg">
														<tr>
															<td class="tdBg">{moment(item.order_date).format("DD/MM/YYYY")}</td>
															<td class="tdBg">{item.labName}</td>
															<td class="tdBg">{item.lab_form_display_name}</td>
															<td class="tdBg">{item.status === 1 ? <span style={{ color: "red" }}>Pending</span> : <span style={{ color: "green" }}>Received</span>}</td>
															<td class="tdBg">
																<a>
																	<img
																		src={Eye}
																		target="new"
																		title="View Lab Order" style={{ cursor: "pointer" }}
																		onClick={(e) => Edit(item.labReqId, item.labFormId, item.service_provider_id)}
																	/>
																</a>
															</td>
														</tr>
													</tbody>
												))}
											</table>
											{/* ) : (
								<NoLaborder />
								)} */}
										</div>
										: ""}
								</div>
							</div>

							{/* ):( */}
							<div class="content" style={{ padding: '0px' }}>
								<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
									{testData?.length > 0 ?
										<div class="table-responsive tableFixHead">
											<table class="table">
												<thead>
													<tr>
														<th class="col-md-2 col-lg-2 thBg">Test Date</th>
														<th class="col-md-2 col-lg-2 thBg">Test Name</th>
														<th class="col-md-2 col-lg-2 thBg">Specimen</th>
														<th class="col-md-2 col-lg-2 thBg">Test Type</th>
														<th class="col-md-2 col-lg-2 thBg">Instructions</th>
													</tr>
												</thead>
												{testData.map((item, index) => (
													<tbody class="tbodyBg">
														<tr>
															<td class="tdBg">{item.testDate}</td>
															<td class="tdBg">{item.testName}</td>
															<td class="tdBg">{item.specimen}</td>
															<td class="tdBg">{item.testType}</td>
															<td class="tdBg">{item.comments}</td>
														</tr>
													</tbody>
												))}
											</table>

										</div>
										: ""}
								</div>
							</div>
						</div>
					) : (
						<div class="content ptMedicalHistory">
							<NoLaborder />
						</div>
					)}
					{/* )} */}
					<input type="checkbox" id="title7" />
					<label className="accrdnLabel" for="title7">
						Lab Test Result
					</label>
					<div class="content" style={{ padding: '0px' }}>
						<div class="col-md-12 col-lg-12 mrgTop ptMedicalHistory">
							<div class="table-responsive tableFixHead">
								{testResultData?.length > 0 ? (
									<table class="table">
										<thead>
											<tr>
												<th class="col-md-2 col-lg-2 thBg">Date</th>
												<th class="col-md-2 col-lg-2 thBg">Test</th>
												<th class="col-md-2 col-lg-2 thBg">Parameter</th>
												<th class="col-md-2 col-lg-2 thBg">Normal Value</th>
											</tr>
										</thead>
										{testResultData.map((item, index) => (
											<tbody class="tbodyBg">
												<tr>
													<td class="tdBg">{item.resultDate}</td>
													<td class="tdBg">{item.testName}</td>
													<td class="tdBg">{item.parameter}</td>
													<td class="tdBg">{item.normalValue}</td>
												</tr>
											</tbody>
										))}
									</table>
								) : (
									<NoTestResult />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Patientright;
