// import React, { useState, useEffect, Suspense } from 'react';
// import { useSelector } from "react-redux"
// import { callingAPI } from '../config';
// import axios from "axios";
// let Header = React.lazy(() => import("../layout/header"))
// let Footer = React.lazy(() => import("../layout/footer"))
// let HomeleftMenu = React.lazy(() => import("../Dashboard/homeleftMenu"))

// function Welcome() {
// 	const [doctorMemberDetails, setDoctorMemberDetails] = useState([]);
// 	const [hospitalHomeScreenArray, setHospitalHomeScreenArray] = useState([])
// 	const [doctorImage, setDoctorImage] = useState([]);
// 	const [doctorSpecialityDetails, setDoctorSpecialityDetails] = useState([]);
// 	const userId = useSelector(state => state.userId.value)

// 	useEffect(() => {
// 		getDoctorDetails1()
// 		let PostData = {
// 			"pmSiteId": window.sessionStorage.getItem("siteId")
// 		};
// 		callingAPI("commonUserFunctions/hospitalHomescreen", PostData
// 		).then(async (data) => {
// 			if (data.data.success === "1") {

// 				var datas = data.data.result.hospitalHomescreenArraydetails[0].dashboardContent
// 				let dashboardData = await axios.get(datas)
// 				setHospitalHomeScreenArray(dashboardData.data)
// 				//setHospitalHomeScreenArray(data.data.result.hospitalHomescreenArraydetails[0].dashboardContent)
// 			}
// 		})
// 			.catch()
// 	}, []);
// 	const getDoctorDetails1 = () => {
// 		const postData = {
// 			doctorId: window.sessionStorage.getItem("doctorId")
// 		};
// 		callingAPI('doctorProfile/getDoctorDetails', postData
// 		).then((res) => {
// 			setDoctorMemberDetails(res.data.result.memberDetails.length ? res.data.result.memberDetails[0] : [])
// 			window.sessionStorage.setItem('setDoctorImage', res.data.result.doctorImage.length > 0 ? res.data.result.doctorImage[0].doctorImageName : 'download.jpg')
// 			setDoctorImage(res.data.result.doctorImage.length > 0 ? res.data.result.doctorImage[0].doctorImageName : 'download.jpg');
// 			setDoctorSpecialityDetails(res.data.result.specialityDetails ? res.data.result.specialityDetails : [])
// 		})
// 			.catch()
// 	}
// 	return (
// 		<div>
// 			<Suspense fallback={<div></div>}>
// 				<div className="coverWraper innerCoverWraper DashboardWelcome">
// 					<Header />
// 					<div className="contentWraper contentWraperScroll">
// 						<div className="container">
// 							<div className="row">
// 								<div className="col-md-4">
// 									<HomeleftMenu doctorImg={window.sessionStorage.getItem('setDoctorImage')} doctorName={doctorMemberDetails} specialityDetails={window.sessionStorage.getItem('specializationName')}></HomeleftMenu>
// 								</div>
// 								<div className="col-md-8">
// 									{hospitalHomeScreenArray ? (
// 										<div dangerouslySetInnerHTML={{ __html: hospitalHomeScreenArray }}></div>
// 									) : <div style={{ textAlign: "center", color: "red", fontSize: "14px", padding: "15px" }}>Nothing to Display</div>}
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 					<Footer />
// 				</div>
// 			</Suspense>
// 		</div>
// 	);
// }
// export default Welcome;
import React, { useState, useEffect, Suspense } from 'react';
import { useSelector } from "react-redux";
import { callingAPI } from '../config';
import axios from "axios";

// Lazy-loaded components
const Header = React.lazy(() => import("../layout/header"));
const Footer = React.lazy(() => import("../layout/footer"));
const HomeleftMenu = React.lazy(() => import("../Dashboard/homeleftMenu"));

function Welcome() {
    const [doctorMemberDetails, setDoctorMemberDetails] = useState({});
    const [hospitalHomeScreenArray, setHospitalHomeScreenArray] = useState('');
    const [doctorImage, setDoctorImage] = useState('');
    const [doctorSpecialityDetails, setDoctorSpecialityDetails] = useState('');
    const userId = useSelector(state => state.userId.value);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const doctorDetails = await getDoctorDetails();
            setDoctorMemberDetails(doctorDetails.memberDetails);
            setDoctorImage(doctorDetails.doctorImage);
            setDoctorSpecialityDetails(doctorDetails.specialityDetails);

            const hospitalHomeScreenData = await getHospitalHomeScreenData();
            setHospitalHomeScreenArray(hospitalHomeScreenData);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error
        }
    };

    const getDoctorDetails = async () => {
        const postData = {
            doctorId: window.sessionStorage.getItem("doctorId")
        };
        const response = await callingAPI('doctorProfile/getDoctorDetails', postData);
        return response.data.result;
    };

    const getHospitalHomeScreenData = async () => {
        const postData = {
            pmSiteId: window.sessionStorage.getItem("siteId")
        };
        const response = await callingAPI('commonUserFunctions/hospitalHomescreen', postData);
        if (response.data.success === "1") {
            const dashboardContent = response.data.result.hospitalHomescreenArraydetails[0].dashboardContent;
            return dashboardContent
            // const dashboardDataResponse = await axios.get(dashboardContent);
            // return dashboardDataResponse.data;
        } else {
            throw new Error('Failed to fetch hospital home screen data');
        }
    };

    return (
        <div>
            <Suspense fallback={<div></div>}>
                <div className="coverWraper innerCoverWraper DashboardWelcome">
                    <Header />
                    <div className="contentWraper contentWraperScroll">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <HomeleftMenu doctorImg={doctorImage} doctorName={doctorMemberDetails} specialityDetails={doctorSpecialityDetails} />
                                </div>
                                <div className="col-md-8">
                                    {hospitalHomeScreenArray ? (
                                        <div dangerouslySetInnerHTML={{ __html: hospitalHomeScreenArray }}></div>
                                    ) : <div style={{ textAlign: "center", color: "red", fontSize: "14px", padding: "15px" }}>Nothing to Display</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Suspense>
        </div>
    );
}

export default Welcome;
