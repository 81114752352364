import React, { useEffect, useState } from 'react';
import Calendar from '../images/icons/img4-b.png';
import Logo from '../images/media/logo.png';
import Invoices from '../images/icons/invoice.png';
import Payment from '../images/icons/payments.png';
import Prescription from '../images/icons/prescription.png';
import Gallery from '../images/icons/gallery.png';
import deafaultLogo from '../images/avatarHospitalDefault.png';
import Files from '../images/icons/files.png';
import Vitalsigns from '../images/icons/vitalsigns.png';
import treatmentplan from '../images/icons/treatment.png';
import Moment from 'moment';
import { callingAPI } from '../config';
import CompletedPlans from '../images/icons/completedPlans.png';
function HistoryPrint(props) {
	const [printDatas, setPrinData] = useState(JSON.parse(localStorage.getItem("Printhistory")))
	const [ConsentHospitalDetails, setConsentHospitalDetails] = useState([]);
	const [HospitalLogo, setHospitalLogo] = useState("");
	const [dateOfPrint, setDateOfPrint] = useState('');
	const PrintThisPage = () => {
		window.print();
	}
	useEffect(() => {
		consentForm();
		setDateOfPrint(Moment(new Date()).format('DD-MM-YYYY'))
	}, []);
	const consentForm = () => {
		let historyParamsconsent = {
			"memberId": window.sessionStorage.getItem("memberId"),
			"doctorId": window.sessionStorage.getItem("doctorId"),
			"hospitalId": localStorage.getItem('hospitalId'),
		};
		callingAPI('medicinePrescription/patientHistoryPrintDetails', historyParamsconsent)
			.then((response) => {
				if (response.data.success === '1') {
					setConsentHospitalDetails(response.data.result);

				}
			})
			.catch();

		callingAPI('commonUserFunctions/headerFooterLogoPrints', {
			hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
			doctorId: ""
		}).then((res) => {

			setHospitalLogo(res.data.result.hospitalDetail[0].hospitalLogoImage);
			//setMarginTop()
		});
	};
	var getS3folderDental =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_DENTALFILES_S3_IMAGE_PATH;
	var getS3foldersMedicine =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;
	var getS3folder =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
	return (
		<div className='print_view historyPRINT'>
			<body>
				{printDatas.isDataExistsprint === true &&
					<div class="printBtm" style={{ textAlign: 'center', clear: 'both', overflow: 'hidden' }} id="printBtm">
						<p
							onClick={PrintThisPage}
							style={{
								width: '70px',
								textAlign: 'center',
								paddingTop: '5px',
								lineHeight: '18px',
								display: 'inline-block',
								fontFamily: 'Verdana,Geneva,sans-serif',
								fontWeight: 'bold',
								fontSize: '12px',
								color: 'white',
								background: 'rebeccapurple none repeat scroll 0% 0%',
								marginTop: '2px',
								cursor: 'pointer',
								borderRadius: '5px',
								marginBottom: '2px',
								height: '25px',
							}}
						>
							Print
						</p>
					</div>
				}
				<div align="center">
					<div
						align="center"
						style={{
							width: '540px',
							height: '595px',
							marginLeft: '0px',
							marginRight: '0px',
							marginTop: '0px',
							marginBottom: '0px',
						}}
					>
						<table style={{ width: '100%', marginTop: '0px' }} border="0">
							<tr>
								<td style={{ fontWeight: '400' }}>
									<div class="printOutHeaderSec ">
										<table style={{ width: '100%' }}>
											{ConsentHospitalDetails.map((items, i) => (
												<tbody>
													<tr height="80px">
														<td colspan="3" valign="middle" align="center">
															<div style={{ width: '100%', float: 'none' }}>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '16px',
																		clear: 'both',
																		width: '100%',
																		margin: '0px 0 3px 0px',
																	}}
																>
																	<span
																		style={{
																			marginTop: '30px',
																			display: 'inline-block',
																			width: '13%',
																		}}
																	>
																		<img
																			src={HospitalLogo != (null || "") ?

																				getS3folder + '/' + localStorage.getItem('hospitalId') + ".png" : deafaultLogo}
																			// src={Logo}
																			style={{
																				display: 'block',
																				width: '100%',
																				maxHeight: '65px',
																				margin: 'auto',
																			}}
																			align="middle"
																		/>
																	</span>
																</p>
															</div>
															<div class="practicePrintName" style={{ width: '100%' }}>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '16px',
																		clear: 'both',
																		width: '100%',
																		marginBottom: '1px',
																		marginTop: '0',
																	}}
																>
																	{items.hospitalDetails.hospitalName}
																</p>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '12px !important',
																		clear: 'both',
																		width: '100%',
																		margin: '0',
																	}}
																>
																	{items.hospitalDetails.hospitalAddress}
																</p>
															</div>
														</td>
													</tr>
													<tr>
														<td colspan="3">
															<hr color="#142536" style={{ margin: '0' }} className="hr_style" />
														</td>
													</tr>
													<tr>
														<td
															width="50%"
															colspan="2"
															align="left"
															style={{ fontSize: '12px', wordBreak: 'break-word' }}
														>
															{items.doctorDetails.title}	{items.doctorDetails.doctorDisplayName}
														</td>
														<td
															width="50%"
															align="right"
															style={{ fontSize: '12px', wordBreak: 'break-word' }}
														>
															{items.doctorDetails.address}
														</td>
													</tr>
													<tr>
														<td colspan="3">
															<hr color="#142536" style={{ margin: '0' }} className="hr_style" />
														</td>
													</tr>
													<tr>
														<td
															colspan="2"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'left',
															}}
														>
															Name : {items.memberDetails.memberFirstName} {items.memberDetails.memberLastName}
														</td>
														<td
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'right',
															}}
														>
															{/* Date : {items.memberDetails.DOB}	{' '} */}
															Date :{dateOfPrint}  {' '}
														</td>
													</tr>
													<tr>
														<td
															
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															Age : {items.memberDetails.memberAge}
														</td>
														<td    colspan="2"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'right',
															}}
														>
															Email Id : {items.memberDetails.secUserEmailid}
														</td>
													</tr>
													<tr>
														<td
															colspan="2"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															Sex : {items.memberDetails.memberGender}
														</td>
														<td
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'right',
															}}
														>
															Phone No : {items.memberDetails.SecuserMobileNumber}
														</td>
													</tr>
													<tr>
														{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
															(<td
																colspan="2"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '15px',
																	textAlign: 'left',
																}}
															>
																MR No : {items.memberDetails.memberMrNo}
															</td>) :

															(<td  colspan="2"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '15px',
																	textAlign: 'left',
																}}
															>
																PR No : {items.memberDetails.memberPrNo}
															</td>)
														}
													</tr>
													<tr>
														<td colspan="3">
															<hr className='hr_style' color="#142536" style={{ margin: '0' }} />
														</td>
													</tr>
												</tbody>
											))}
										</table>
									</div>
								</td>
							</tr>
							{printDatas.PrinDetails.map((items, i) => (
								<tbody>
									{(
										items.appointmentData.length > 0 && printDatas.appointmentsprint === true || items.caseSheetData.length > 0 && printDatas.caseSheetprint === true
										|| items.labOrderData.length > 0 && printDatas.labOrderprint === true || items.invoiceData.length > 0 && printDatas.invoicesprint === true
										|| items.paymentData.length > 0 && printDatas.paymentsprint === true || items.prescriptionData.length > 0 && printDatas.prescriptionsprint === true
										|| items.fileData.length > 0 && printDatas.filesprint === true || items.treatmentPlanData.length > 0 && printDatas.treatmentPlansprint === true
									) &&
										<tr>
											<td>
												<div id="listDate" class="timelineListDate">
													<div id='date'>
														<span class="timelineDate" >{(Moment(items.historyDate).format('DD'))}</span>
														<div class="timelineDateCvr">
															<span class="timelineMnthDate">{(Moment(items.historyDate).format('YYYY'))}</span>
															<span class="timelineDay">{(Moment(items.historyDate).format('MMMM'))}</span>
														</div>
													</div>
												</div>
											</td>
										</tr>
									}
									{(() => {
										if (items.appointmentData.length > 0 && printDatas.appointmentsprint === true) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" /> <span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img src={Calendar} /> Appointments</span>
																			</div>
																			{items.appointmentData[0].appointmentDetails.map((obj, i) => (
																				<div class="vitalSignsTable treatmentpadZero">
																					<div
																						class="appoinmentDateDetail"
																						style={{ float: 'none', width: '100%' }}
																					>
																						<div class="appoinmentLeftDiv">
																							<span class="appoinmentReason">
																								<span>Reason : </span>
																								<span > &nbsp;{obj.visitReason}</span>
																							</span>
																						</div>
																						<div class="appoinmentSatusWrap">
																							<span class="appoinmentTYpe">Type : {obj.appointmentType}</span>
																							<span class="appoinmentSTatus">
																								Status : {obj.appStatus}
																							</span>
																						</div>
																						<div class="appoinmentRightDiv">
																							<span class="appoinmentDrName">
																								{obj.doctorName}
																							</span>
																						</div>
																					</div>
																					<hr />
																				</div>
																			))}
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>)
										}
									})()}
									{(() => {
										if (
											// item.prescriptionData.length !==0
											items.caseSheetData.length > 0 && printDatas.caseSheetprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span style={{ display: 'inline-table' }}><img alt="" src={Calendar} />
																					Case Sheet</span>
																			</div>
																			<div class="treatmentPlanListWrap" id="listClinicalNotes">
																				<div class="treatmentPlanList152711">
																					<div class="treatmentPlanDate" />
																					<div class="treatmentComplaintWrap">
																						<div class="treatmentComplaintRowWrap">
																							<div class="treatmentComplaintRow">
																								<div class="treatmentComplaintLeft">
																									Chief presenting complaint
																								</div>
																								{items.caseSheetData[0].caseSheetDetails.map((obj, t) => (
																									<div class="treatmentComplaintRow">
																										<div class="treatmentComplaintLeft">
																											{obj.complaintTypeName}
																										</div>
																										<div class="treatmentComplaintRight">
																											<span class="treatmentTAgName elipsis" style={{ width: '100%', overflow: 'visible' }}>
																												{obj.tags}
																											</span>
																											<span class="treatmentTAgName elipsis">
																												{obj.sinceDate}
																											</span>
																											{/* <span class="treatmentTAgDate">
																								05,Nov 2021
																							</span> */}
																											<span
																												style={{
																													fontWeight:
																														'bold',
																												}}
																											>
																												Description :
																											</span>
																											<span>{obj.description}</span>
																										</div>
																									</div>
																								))}
																							</div>
																							{/* {item.caseSheetData[0].caseSheetDetails.map((obj, i) => (
																		<div class="treatmentComplaintNoted">
																			Noted by <span>{obj.doctor}</span>
																		</div>
																		<div class="dentalProNote" />
																		))} */}
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>
											)
										}
									})()}
									{(() => {
										if (
											// item.prescriptionData.length !==0
											items.labOrderData.length > 0 && printDatas.labOrderprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img alt="" src={Calendar} />
																					Lab Order </span>
																			</div>
																			<div class="treatmentTable prescriptionTable ">
																				<div class="treatmentPlanList2">
																					<div class="treatmentPlanDate" />
																					<div class="treatmentTable ">
																						<table style={{ display: 'table' }}>
																							<tr className='tr_head'>
																								<td>
																									<div class="dentalProName">
																										Test name
																									</div>
																								</td>
																								<td>
																									<div class="dentalProName">
																										Specimen
																									</div>
																								</td>
																								<td>
																									<div class="dentalProName">
																										Test type
																									</div>
																								</td>
																								<td>
																									<div class="dentalProName">
																										Amount
																									</div>
																								</td>
																								<td>
																									<div class="dentalProNote">
																										Notes
																									</div>
																								</td>
																							</tr>
																							<tbody>
																								{items.labOrderData.map((obj, k) => (
																									<tr class="groupBrdr">
																										<td>
																											<div class="dentalProName paddingFive">
																												<span >
																													{' '}
																													{obj.testName}
																												</span>
																											</div>
																										</td>
																										<td>
																											<div class="dentalProName paddingFive">
																												{obj.specimen}
																											</div>
																										</td>
																										<td>
																											<div class="dentalProName paddingFive" >
																												{obj.testType}
																											</div>
																										</td>
																										<td>
																											<div class="dentalProName paddingFive">
																												{obj.buy_price}
																											</div>
																										</td>
																										<td>
																											<div class="dentalProNote paddingFive">
																												<span >
																													&nbsp;
																												</span>
																												<span
																													class="dentalProIcons"
																													style={{
																														minWidth: 'auto',
																														display: 'none',
																													}}
																												>
																													<a class="dentalDrpClk dentalDrpAct">
																														<i
																															class="fa fa-caret-down"
																															aria-hidden="true"
																														/>
																													</a>
																													<div class="dentalProDrp">
																														<a href="javascript:void(0);">
																															<i
																																class="fa fa-trash"
																																aria-hidden="true"
																															/>
																															<span>
																																{obj.invoiceStatus}
																															</span>
																														</a>
																														{/* <a href="javascript:void(0);">
																															<i
																																class="fa fa-pencil"
																																aria-hidden="true"
																															/>
																															<span>Edit</span>
																														</a> */}
																														{/* <a href="javascript:void(0);">
																															<i
																																class="fa fa-trash"
																																aria-hidden="true"
																															/>
																															<span>Delete</span>
																														</a> */}
																													</div>
																												</span>
																											</div>
																										</td>
																									</tr>
																									// 						{/* <tr class="dentalProPlaned">
																									// 	<td colspan="2">
																									// 		<div class="plannedBy">
																									// 			Noted by{' '}
																									// 			<span>Dr.Sree Kala</span>
																									// 		</div>
																									// 	</td>
																									// 	<td colspan="3">
																									// 		<div class="estimatedPrice" />
																									// 	</td>
																									// </tr> */}
																								))}
																							</tbody>
																						</table>
																					</div>
																				</div>
																			</div>
																			<>
																				{items.labOrderData.find((objec) => objec.labReqId) ?
																					<div class="treatmentTable prescriptionTable ">
																						<table style={{ display: 'table' }}>
																							<thead>
																								<tr>
																									<th class="col-md-3"><div class="dentalProName">Lab name</div></th>
																									<th class="col-md-6"><div class="dentalProCost" style={{ textAlign: "left" }}>Test name</div></th>
																									{/* <th class="col-md-1"><div class="dentalProDis">View</div></th> */}
																									<th class="col-md-3"><div class="dentalProDis">Status</div></th>
																								</tr>
																							</thead>
																							<tbody>
																								{items.labOrderData.filter((objec) => objec.labReqId).map((obj, k) => (
																									<tr>
																										<td class="col-md-3"><div class="dentalProName">{obj.labName}</div></td>
																										<td class="col-md-6"><div class="dentalProCost" style={{ textAlign: "left" }}>{obj.lab_form_display_name}</div></td>

																										<th class="col-md-3">
																											{obj.status === 1 ?
																												<span style={{ color: "red" }}>Pending</span> : <span style={{ color: "green" }}>Received</span>}
																										</th>
																									</tr>
																								))}
																							</tbody>
																						</table>
																					</div> : ""}
																			</>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>

											)
										}
									})()}
									{(() => {
										if (
											// item.prescriptionData.length !==0
											items.invoiceData.length > 0 && printDatas.invoicesprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img alt="" src={Invoices} />Invoices</span>
																			</div>
																			{items.invoiceData[0].final_array.map((obj, k) => (

																				<>
																					<div class="treatmentPlanList2">
																						<div class="treatmentTable invoiceTableWrap">
																							{obj.main.map((mainobjs, m) => (
																								<table className='table_print'>

																									<tbody>

																										{/* {mainobjs.item.map((obj, k) => ( */}
																										{/* {obj.main.map((mainobjs, m) => ( */}

																										<tr className='tr_head'>
																											<td style={{ width: '120px' }}>Invoice number</td>
																											<td style={{ width: '110px' }}>Towards</td>
																											<td style={{ width: '85px' }}>Qty</td>
																											<td style={{ width: '130px' }}>Unit price</td>
																											<td style={{ width: '130px' }}>Cost</td>
																											<td style={{ width: '100px' }}>Tax</td>
																											<td style={{ width: '100px' }}>Total</td>
																										</tr>

																										<tr className='tr_body'>
																											<td className='align-top invoic_text' style={{ width: '110px', borderRight: '1px solid #afafaf' }}><img alt="" src={treatmentplan} />{mainobjs.memberProcedureInvoiceNumber}</td>
																											{/* {mainobjs.item.map((item, m) => (
								 <td className='table_subhead'><b>{item.itemType}</b></td>
								 ))} */}
																											<td colSpan={6}>

																												{mainobjs.item.map((item, m) => (
																													<>

																														<tr className='tr_body'>
																															{/* {mainobjs.item.map((item, m) => (
								 <td className='table_subhead'><b>{item.itemType}</b></td>
								 ))} */}
																															<td className='table_subhead' colSpan={6}><b>{item.itemType}</b></td>
																														</tr>
																														{item.item.map((lastitems, y) => (
																															<tr className='tr_body'>
																																<td style={{ width: '110px' }}>
																																	<p>{lastitems.itemName}</p>
																																	<p>{lastitems.itemCode}</p>
																																</td>
																																<td style={{ width: '85px' }}>
																																	{lastitems.quantity}
																																</td>
																																<td style={{ width: '130px' }}>
																																	{lastitems.unitPrice}
																																</td>
																																<td style={{ width: '130px' }}>
																																	{lastitems.cost}
																																</td>
																																<td style={{ width: '100px' }}> {lastitems.taxAmount}</td>
																																<td style={{ width: '100px' }}>
																																	{lastitems.totalAmount}
																																</td>
																															</tr>))}
																													</>
																												))}
																											</td>
																										</tr>

																										<tr className='tr_footer'>
																											<td className='align-top invoic_text' style={{ width: '110px' }}></td>
																											<td colSpan={3}>
																												<span className='text_amout'>Total Amount : {parseFloat(mainobjs.invoiceList.totalAmount).toFixed(2)}</span> <br />
																												<span className='text_amout'>Balance Amount : <span className='text-danger'>{parseFloat(mainobjs.invoiceList.balanceAmount).toFixed(2)}</span></span><br />


																											</td>
																											{/* <td colSpan={12}>
																												<span className='text_amout'>Discount Amount : {parseFloat(mainobjs.discountAmount).toFixed(2)}</span><br />
																												<span className=' text_amout'>&nbsp;</span>
																											</td> */}
																											<td colSpan={3} >
																												<span className='text_amout'>Discount Amount : {parseFloat(mainobjs.invoiceList.discountAmount).toFixed(2)}</span><br />
																												<span className='text_amout'>Paid Amount : {parseFloat(mainobjs.invoiceList.paidAmount).toFixed(2)}</span><br />

																											</td>
																										</tr>


																										{/* ))} */}
																										{/* ))} */}
																									</tbody>
																								</table>
																							))}
																							{/* ))} */}


																						</div>
																					</div>

																				</>
																			))}
																		</div>

																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>
											)
										}
									})()}
									{(() => {
										if (
											// item.prescriptionData.length !==0
											items.paymentData.length > 0 && printDatas.paymentsprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img alt="" src={Payment} />
																					Payment</span>
																			</div>
																			<div class="treatmentTable invoiceTableWrap">
																				{/* <div class="treatmentPlanList2">
																					<div class="treatmentTable invoiceTableWrap" />
																				</div> */}
																				<table style={{ display: 'table' }}>
																					{/* <tbody /> */}
																					<tr className='tr_head'>
																						<td class="col-md-4">
																							<div class="dentalProName">
																								Receipt number
																							</div>
																						</td>
																						<td colspan="4" class="col-md-8">
																							<div class="dentalProCost invoiceInnerTable">
																								<table>
																									<tr>
																										<td class="col-md-3">
																											<div class="dentalProName txtRight">
																												Amount paid
																											</div>
																										</td>
																										<td class="col-md-3">
																											<div class="dentalProDis txtLeft">
																												Invoice number
																											</div>
																										</td>
																										<td
																											class="col-md-3"
																											style={{ padding: '0' }}
																										>
																											<div class="dentalProDis ">
																												Towards
																											</div>
																										</td>
																										<td class="col-md-3">
																											<div class="dentalProTotal txtLeft">
																												Mode of payment
																											</div>
																										</td>
																									</tr>
																								</table>
																							</div>
																						</td>
																						{/* <td class="col-md-1">
																							<div
																								class="dentalProNote"
																								style={{ right: '-8px' }}
																							/>
																						</td> */}
																					</tr>
																					{items.paymentData.map((obj, k) => (
																						<>
																							{obj.paymentDetails.map((objs, k) => (
																								<tbody>
																									<tr class="groupBrdr">
																										<td
																											class="col-md-2"
																										>
																											<div class="group_amount">
																												<span className='primary_span' style={{ left: '0px', top: '0px' }}>{objs.receipt_no}</span>
																											</div>
																										</td>
																										<td class="col-md-2 group_amount">
																											<span class="txtRight" style={{ display: 'flex', justifyContent: 'flex-end' }}>
																												{objs.receipt_amount.toFixed(2)}
																											</span>
																										</td>
																										<td class="col-md-2 group_amount">
																											<span class="">
																												{objs.invoice_no}
																											</span>
																										</td>
																										<td
																											class="col-md-4 group_amount">
																											<span class="">
																												{objs.procedure_code}
																											</span>
																										</td>
																										<td class="col-md-2 group_amount">
																											<span className='primary_span' style={{ left: '0px', top: '0px' }}
																											>
																												{objs.payment_mode}
																											</span>
																										</td>
																										{/* <td class="col-md-1">
																											<div class="dentalProNote">
																												<span >&nbsp;</span>
																											</div>
																										</td> */}
																									</tr>
																								</tbody>
																							))}
																						</>
																					))}
																				</table>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>
											)
										}
									})()}
									{(() => {
										if (
											// item.prescriptionData.length !==0
											items.prescriptionData.length > 0 && printDatas.prescriptionsprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img alt="" src={Prescription} />
																					Prescriptions</span>
																			</div>
																			<div class="treatmentPlanList2">
																				<div class="treatmentTable prescriptionTable ">
																					<table style={{ display: 'table' }}>
																						<tr className='tr_head'>
																							<td>
																								<div class="dentalProName">
																									Medicine
																								</div>
																							</td>
																							<td>
																								<div class="dentalProCost" style={{ textAlign: 'left' }}>
																									Frequency &amp; Dosage
																								</div>
																							</td>
																							<td class="col-md-2">
																								<div class="dentalProCost" style={{ textAlign: 'left' }}>Intake</div>
																							</td>
																							<td class="col-md-1">
																								<div class="dentalProDis">Qty</div>
																							</td>
																							<td class="col-md-1">
																								<div class="dentalProTotal" style={{ textAlign: 'left' }}>Days</div>
																							</td>
																							<td>
																								<div class="dentalProTotal" style={{ textAlign: 'left' }}>
																									Start Date
																								</div>
																							</td>
																						</tr>
																						<tbody>
																							{items.prescriptionData[0].prescriptionDetailsData.map((obggj, k) => (
																								<tr style={{ border: '1px' }}>
																									<td>
																										<div class="dentalProName">
																											{obggj.medicineName}
																										</div>
																									</td>
																									<td>
																										<div class="dentalProCost" style={{ textAlign: 'left' }}>
																											{obggj.instructions}
																										</div>
																									</td>
																									<td>
																										<div class="dentalProCost" style={{ textAlign: 'left' }}>
																											{obggj.intake}
																										</div>
																									</td>
																									<td class="col-md-1">
																										<div class="dentalProDis">{obggj.quantity}</div>
																									</td>
																									<td class="col-md-1">
																										<div class="dentalProDis" style={{ textAlign: 'left' }}>{obggj.days}</div>
																									</td>
																									<td>
																										<div class="dentalProTotal" style={{ textAlign: 'left' }}>
																											{obggj.startDate}
																										</div>
																									</td>
																								</tr>
																							))}
																						</tbody>
																					</table>
																					<div class="treatmentPlanDate">
																						{items.prescriptionData.map((obgj, k) => (
																							<div class="treatmentComplaintNoted ">
																								Noted by <span>{obgj.doctorTitle} {obgj.displayName}</span>
																							</div>
																						))}
																						<div class="dentalSelect">
																							<div class="dentalProNote" />
																						</div>
																					</div>
																				</div>
																				<br />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>
											)
										}
									})()}
									{(() => {
										if (
											items.fileData.length > 0
										) {
											return (
												<div>
													{/* {files===true&&appointments===false&&payments===false&&invoices===false&&labOrder===false&&prescriptions===false&&completedPlans===false&&treatmentPlans===false&&caseSheet===false?(
																							<div id="listDate" class="timelineListDate">
																		 		<div id='date'>
																		 		<span class="timelineDate" >{(Moment(item.historyDate).format('DD'))}</span>
																		 		<div class="timelineDateCvr">
																		 			<span class="timelineMnthDate">{(Moment(item.historyDate).format('YYYY'))}</span>
																		 			<span class="timelineDay">{(Moment(item.historyDate).format('MMMM'))}</span>
																		 		</div>
																		 		</div>
																		 	</div>
																			):''} */}
													{printDatas.filesprint === true && items.fileData.length > 0 ? (
														<tr>
															<td>
																<div class="timelineListContent" style={{ width: "540px", float: "left" }}>
																	<div><span class="timelineArrow"></span>
																		<span class="timelineCircle"></span>
																		<div class="timelineListName">
																			<span><img alt="" src={Gallery} />
																				Files</span>
																		</div>
																		<div class="vitalSignsTable vitalSignsItemsCvr treatmentpadZero">
																			{items.fileData.map((obj, k) => (
																				<div class="vitalSignsItems ">
																					{/* <a href="#" target="_blank">
																													<img src={  obj.ImageName !== "null"?   getS3folder + '/' + localStorage.getItem('hospitalId')+ ".png"}
																														// <img alt="" src={obj.ImageName} style={{ width: "100%" }} />getS3folder
																													</a> */}
																					<img src={obj.Description === "M" ? getS3foldersMedicine + '/' + obj.ImageName : getS3folderDental + '/' + obj.ImageName} />
																				</div>
																			))}
																		</div></div></div>
															</td>
														</tr>) : null}
												</div>
											)
										}
									})()}
									{/* <tr>
									<td>
										<div class="printOutSettingContent">
											<div class="treatmentTimelineBlock">
												<div class="timelineList">
													<div class="timelineListContent">
														<span class="timelineArrow" />
														<span class="timelineCircle" />
														<div class="timelineListName">
															<img alt="" src={Vitalsigns} />
															<span>Vital Signs</span>
														</div>
														<div class="treatmentTable prescriptionTable ">
															<div class="treatmentComplaintWrap">
																<div class="treatmentComplaintRowWrap treatmentComplaintRowWrap">
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Time</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>15:06</span>
																		</div>
																	</div>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Weight (Kg)</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>55</span>
																		</div>
																	</div>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Height (cm)</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>120</span>
																		</div>
																	</div>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Blood Pressure SYS/DIA (mmHg)</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>120/170 Sitting</span>
																		</div>
																	</div>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Pulse (bpm)</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>50</span>
																		</div>
																	</div>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Resp.Rate (breaths/min)</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>12</span>
																		</div>
																	</div>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			<span>Temperature (°C)</span>
																		</div>
																		<div class="treatmentComplaintRight">
																			<span>72 Axillary (Armpit)</span>
																		</div>
																	</div>
																</div>
															</div>
															<div class="treatmentComplaintNoted">
																Noted by <span>Raja Gopal</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</td>
								</tr> */}
									{(() => {
										if (
											items.treatmentPlanData.length > 0 && printDatas.treatmentPlansprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img alt="" src={Invoices} />
																					Treatment plan</span>
																			</div>
																			<div class="treatmentTable treatmentpadZero">
																				<div class="treatmentPlanList2">
																					<div class="treatmentTable ">
																						<table style={{ display: 'table' }}>
																							<tr className='tr_head'>
																								<td>
																									<div class="dentalProName">
																										Procedure
																									</div>
																								</td>
																								<td>
																									<div class="dentalProCost">
																										Cost
																									</div>
																								</td>
																								<td>
																									<div class="dentalProCost">Tax</div>
																								</td>
																								<td>
																									<div class="dentalProTotal">
																										Total
																									</div>
																								</td>
																								<td>
																									<div class="dentalProNote">
																										Notes
																									</div>
																								</td>
																							</tr>
																							<tbody	>
																								{items.treatmentPlanData.map((obj, k) => (
																									<><>
																										{obj.treatmentPlanDetails.map((objitem, k) => (
																											<tr class="groupBrdr">
																												<td>
																													<div class="dentalProName">
																														<div class="optionWrapper">
																															<img src={treatmentplan} title="Invoice Generated" />
																															<label for="cool001"></label>
																														</div>
																														<span >{objitem.treatmentPlanName}</span>
																														<span >{objitem.procedure_code}</span>
																													</div>
																												</td>
																												<td><div class="dentalProCost">{parseFloat(objitem.net_amount) - parseFloat(objitem.total_tax)}</div></td>
																												<td><div class="dentalProDis" style={{
																													textAlign: "right", color: "inherit",
																													fontSize: "11px"
																												}}>{objitem.total_tax}</div></td>
																												<td><div class="dentalProTotal">{objitem.net_amount}</div></td>
																												<td>
																													<div class="dentalProNote">
																														<span title="">{objitem.notes}&nbsp;</span>
																													</div>
																												</td>
																											</tr>
																										))}</>
																										<tr class="dentalProPlaned" style={{ background: "#f3f3f3" }}>
																											<td colspan="3"><div class="plannedBy" style={{
																												color: "#333"
																											}}>Planned by <span>{obj.doctor_name}</span></div></td>
																											<td colspan="2"><div class="estimatedPrice txtRight">Estimated amount : {obj.total_net_amount}</div></td>
																											{/* <td colspan="1"><div class="estimatedPrice">&nbsp;</div></td> */}
																										</tr>
																									</>
																								))}
																							</tbody>
																						</table>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>
											)
										}
									})()}
									{(() => {
										if (
											items.completedPlanData.length > 0 && printDatas.completedPlansprint === true
										) {
											return (
												<>
													<tr>
														<td>
															<div class="printOutSettingContent">
																<div class="treatmentTimelineBlock">
																	<div class="timelineList">
																		<div class="timelineListContent">
																			<span class="timelineArrow" />
																			<span class="timelineCircle" />
																			<div class="timelineListName">
																				<span><img alt="" src={CompletedPlans} />
																					Completed Plans</span>
																			</div>
																			<div class="vitalSignsTable treatmentpadZero">
																				<div class="treatmentPlanList2">
																					<div class="treatmentTable prescriptionTable ">
																						<table style={{ display: 'table' }}>
																							<tr className='tr_head'>
																								<td class="col-md-3">
																									<div class="dentalProName">
																										Procedure
																									</div>
																								</td>
																								<td>
																									<div class="dentalProCost">
																										Cost
																									</div>
																								</td>
																								<td class="col-md-2">
																									<div class="dentalProDis">
																										Discount
																									</div>
																								</td>
																								<td class="col-md-1">
																									<div class="dentalProDis">Tax</div>
																								</td>
																								<td>
																									<div class="dentalProTotal">
																										Total
																									</div>
																								</td>
																								<td>
																									<div class="dentalProNote">
																										Notes
																									</div>
																								</td>
																							</tr>
																							<tbody>
																								{items.completedPlanData[0].completedPlanDetails.map((obj, k) => (
																									<>
																										<tr class="groupBrdr" id="tr1">
																											<td>
																												<div class="dentalProName">
																													<div class="optionWrapper">
																														<img
																															src={Invoices}
																															title="Invoice Generated"
																														/>
																													</div>
																													<span >
																														{obj.completedPlanName}
																													</span>
																													<span >
																														{obj.procedure_code}
																													</span>
																												</div>
																											</td>
																											<td>
																												<div class="dentalProCost">
																													{obj.total_cost}
																												</div>
																											</td>
																											<td class="col-md-1">
																												<div class="dentalProDis">{obj.discount}</div>
																											</td>
																											<td class="col-md-1">
																												<div class="dentalProDis">{obj.total_tax_amount}</div>
																											</td>
																											<td><div class="dentalProDis" style={{ textAlign: "right" }}>{obj.total_net_amount}</div></td>
																											<td><div class="dentalProDis" style={{ textAlign: "right" }}>{obj.notes}</div></td>
																											{/* <td>
																												<div class="dentalProNote">
																													<span title="">
																														&nbsp;
																													</span>
																												</div>
																											</td> */}
																										</tr>

																										<tr class="dentalProPlaned" id="trSub0">
																											<td colspan="6">
																												<div class="plannedBy">
																													Completed by{' '}
																													<span>{obj.doctor_name}</span>
																												</div>
																											</td>
																										</tr>

																										{/* <div class="treatmentPlanList2">
																											<div class="treatmentTable prescriptionTable ">
																												<table>
																													<tbody>
																														<tr class="dentalProPlaned" id="trSub0">
																															<td colspan="6">
																																<div class="plannedBy">
																																	Completed by{' '}
																																	<span>{obj.doctor_name}</span>
																																</div>
																															</td>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div> */}
																									</>
																								))}
																							</tbody>
																						</table>
																					</div>
																				</div>

																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</>
											)
										}
									})()}
								</tbody>
							))}
						</table>
					</div>
				</div>
			</body>
		</div>
	);
}
export default HistoryPrint;
