import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import img1 from '../images/icons/img1.png';
import img2 from '../images/icons/img2.png';
import logo from '../images/media/logo.png';
import img3 from '../images/icons/img3.png';
import img4 from '../images/icons/img4.png';
import img5 from '../images/icons/img5.png';
import img6 from '../images/icons/img6.png';
import img7 from '../images/icons/img7.png';
import img8 from '../images/icons/img8.png';
import img9 from '../images/icons/img9.png';
import img10 from '../images/icons/img10.png';
import img11 from '../images/icons/img11.png';
import img12 from '../images/icons/img12.png';
import img13 from '../images/icons/img13.png';
import img14 from '../images/icons/img14.png';
import img15 from '../images/icons/img15.png';
import img16 from '../images/icons/img16.png';
import img17 from '../images/icons/img17.png';
import img18 from '../images/icons/img18.png';
import img19 from '../images/icons/img19.png';
import img20 from '../images/icons/img20.png';
import img21 from '../images/icons/img21.png';
import img22 from '../images/icons/img22.png';
import img23 from '../images/icons/img23.png';
import img25 from '../images/icons/history.png';
import PoweredBy from '../images/media/powered-w.png'

import Dental from '../images/icons/dental.png';
import Medical from '../images/icons/medical.png';
import { useState } from 'react';
import $ from 'jquery';
function Leftmenu() {

	useEffect(() => {

	}, []);

	//	const [ toggleFiles, setToggleFiles ] = useState(false);

	const [showSubMenu, setShowSubMenu] = useState(false);
	const [showSubMenuLabOrder, setShowSubMenuLabOrder] = useState(false);

	const handleToggleFiles = () => {
		setShowSubMenu(!showSubMenu);
	};
	const handleToggleLabOrder = () => {
		setShowSubMenuLabOrder(!showSubMenuLabOrder);
	};

	const setToggleFiles = (event) => {
		if (document.getElementById("qaz1").style.display === "block") {
			window.sessionStorage.setItem("qaz1", "none")
		}
		else {
			window.sessionStorage.setItem("qaz1", "block")
		}

	}
	const setToggle = (event) => {

		if (document.getElementById("qaz").style.display === "block") {
			window.sessionStorage.setItem("qaz", "none")
		}
		else {
			window.sessionStorage.setItem("qaz", "block")
		}

	}
	const setToggle1 = (event) => {
		window.sessionStorage.setItem("qaz", "none");
		window.sessionStorage.setItem("qaz1", "none");
	}

	const dentalType = () => {
		window.sessionStorage.setItem("dentalactivePage", 1)
		window.sessionStorage.setItem("dentaloffset", 0)
	}


	return (
		<div
			className={
				'leftmenu ' + (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ? 'headerTopGreen' : '')
			}
			id="leftmenuRes"
		>
			<div className="logo">
				<Link to="/">
					<img
						src={
							process.env.REACT_APP_S3_URI +
							process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
							'/' +
							process.env.REACT_APP_S3_LOGO_PATH +
							'/' +
							window.sessionStorage.getItem('siteLogo')
						}
					/>
				</Link>
			</div>
			{window.sessionStorage.getItem("siteBranchId") === "4" ?
				<div className="logo-powerdbyinner">
					<a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
						<img src={PoweredBy} alt="Powered By PurpleHealth" />
					</a>
				</div>
				: ""}
			<ul>
				<li >
					<NavLink to="/patients/simplifiedForm" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img1} />
						<span>Simplified Form </span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/patients/patientconsult" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img2} />
						<span>Profile </span>
					</NavLink>
				</li>
				{/* <li>
					<Link to=" ">
						<img src={img3} />
						<span>Appointments </span>
					</Link>
				</li> */}

				<li id="appointmentAdd">
					<NavLink to="/patients/patientAppointments" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img4} />
						<span>Appointments</span>
					</NavLink>
				</li>
				{window.sessionStorage.getItem('medicineBranchId') === '4' ? (
					<li id="caseSheetAdd">
						<NavLink to="/patients/casesheetDental" activeclassname="active" onClick={() => setToggle1()}>
							<img src={img3} />
							<span>Case Sheet </span>
						</NavLink>
					</li>) :
					<li id="caseSheetAdd">
						<NavLink to="/patients/casesheetMedical" activeclassname="active" onClick={() => setToggle1()}>
							<img src={img3} />
							<span>Case Sheet </span>
						</NavLink>
					</li>
				}
				<li>
					<NavLink to="/patients/vitalSigns" activeclassname="active">
						<img src={img5} />
						<span>Vital Signs </span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/patients/vitalGraph" activeclassname="active">
						<img src={img6} />
						<span>Vital Graph </span>
					</NavLink>
				</li>
				<li id="addTreatmentPlans">
					<NavLink to="/patients/treatmentPlans" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img7} />
						<span>Treatment Plans</span>
					</NavLink>
				</li>
				<li id="addCompletedPlan">
					<NavLink to="/patients/completedPlans" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img8} />
						<span>Completed Plans</span>
					</NavLink>
				</li>
				<li id="priscriptionAdd">
					<NavLink to="/patients/patientPriscription" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img9} />
						<span>Prescriptions</span>
					</NavLink>
				</li>
				{/* <li>
					<Link to="/patients/labOrder">
						<img src={img10} />
						<span>Lab Order</span>
					</Link>
				</li> */}


				{(() => {
					if (window.sessionStorage.getItem('siteBranchId') === '4' && window.sessionStorage.getItem('role') !== 'ROLE_HOSPITALADMIN') {
						return (
							<li id="labOrderAdd">
								<NavLink to="/labOrderList" activeclassname="active" onClick={() => dentalType()}  >
									<img src={img10}></img>
									<span  >Lab Order</span>
								</NavLink>
							</li>
						);
					}
					else {
						if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' && window.sessionStorage.getItem('siteBranchId') !== '4') {
							return (
								<li id="labOrderAdd">
									<Link to="#" onClick={handleToggleLabOrder}>
										<img src={img10} />

										<span className='toggleItems'>Lab Order</span>

										<div className="plSubMenu" id="qaz" style={{ display: showSubMenuLabOrder ? "block" : "none" }}>
											<div>
												<li>
													<NavLink to="/labOrderList" activeclassname="active" onClick={() => dentalType()}>
														<img src={Dental} />
														<span>Dental</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li>
													<NavLink to="/patients/labOrder" activeclassname="active">
														<img src={Medical} />
														<span>Medical</span>
													</NavLink>
												</li>
											</div>
										</div>

									</Link>
								</li>
							)
						}
						else {
							return (
								<li id="labOrderAdd">
									<NavLink to="/labOrderList" activeclassname="active" onClick={() => dentalType()} >
										<img src={img10}></img>
										<span  >Lab Order</span>
									</NavLink>
								</li>


							);
						}
					}
				})()}



				{(() => {
					if (window.sessionStorage.getItem('siteBranchId') !== '4') {
						return (
							<li id="testResultAdd" >
								<NavLink to="/patients/testResult" activeclassname="active" onClick={() => setToggle1()}>
									<img src={img11} />
									<span>Test Result</span>
								</NavLink>
							</li>
						);
					}

				})()}



				<li id="invoiceAdd">
					<NavLink to="/patients/patientInvoices" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img12} />
						<span>Invoice</span>
					</NavLink>
				</li>
				<li id="paymentAdd">
					<NavLink to="/patients/patientPayments" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img13} />
						<span>Payment</span>
					</NavLink>
				</li>



				{(() => {
					if (window.sessionStorage.getItem('medicineBranchId') === '4' && window.sessionStorage.getItem('role') !== 'ROLE_HOSPITALADMIN') {
						return (
							<li id="filesAdd">
								<NavLink to="/patients/patientFiles" activeclassname="active"   >
									<img src={img22}></img>
									<span  >Files</span>
								</NavLink>
							</li>
						);
					}
					else {
						if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN') {
							return (
								<li id="filesAdd">
									<Link to="#" onClick={handleToggleFiles}>
										<img src={img22} />

										<span className='toggleItems'>Files</span>

										<div className="plSubMenu" id="qaz1" style={{ display: showSubMenu ? "block" : "none" }}>
											<div>
												<li>
													<NavLink to="/patients/patientFiles" activeclassname="active">
														<img src={Dental} />
														<span>Dental</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li>
													<NavLink to="/patients/patientFilesMedical" activeclassname="active">
														<img src={Medical} />
														<span>Medical</span>
													</NavLink>
												</li>
											</div>
										</div>

									</Link>
								</li>
							)
						}
						else {
							return (
								<li id="filesAdd">
									<NavLink to="/patients/patientFilesMedical" activeclassname="active"   >
										<img src={img22}></img>
										<span  >Files</span>
									</NavLink>
								</li>
							);
						}
					}
				})()}



				<li>
					<NavLink to="/patients/historyFromOtherVendor" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img25} />
						<span>History From Other Vendor</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/patients/historyInfo" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img14} />
						<span>History</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/patients/patientHealthcard" activeclassname="active" onClick={() => setToggle1()}>
						<img src={img15} />
						<span>Health Card</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/patients/medicalCertificate" activeclassname="active" >
						<img src={img18} />
						<span>Medical Certification</span>
					</NavLink>
				</li>

				<li>
					<Link to="/patients/shareData">
						<img src={img19} />
						<span>Share Data</span>
					</Link>
				</li>
				{/* <li>
					<Link to=" ">
						<img src={img16} />
						<span>Inpatient</span>
					</Link>
				</li>
				<li>
					<Link to=" ">
						<img src={img19} />
						<span>Share Data</span>
					</Link>
				</li>
				<li>
					<Link to=" ">
						<img src={img18} />
						<span>Medical Certification</span>
					</Link>
				</li>
				<li>
					<Link to=" ">
						<img src={img17} />
						<span>Initial Evaluation</span>
					</Link>
				</li> */}

				<li>
					<NavLink to="/patients/refereToaDoctor">
						<img src={img23} />
						<span>Refer To A Doctor</span>
					</NavLink>
				</li>

			</ul>


			<div>

			</div>
		</div>
	);
}

export default Leftmenu;
