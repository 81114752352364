import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import Add from '../images/icons/Add_img.png';
import Close from '../images/icons/closeImgCal.png';
import blockCalendar from '../images/icons/blockCalendar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUserMd, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import $ from 'jquery';
var moment = require('moment');
let doctor = []
const customStyles = {
	content: {
		top: '35%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '40%', marginTop: '10px'
	},
};

const BlockcalenderPopup = React.forwardRef((props, ref) => {
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [showDiv, setShowDiv] = useState(true);

	const [userId, setUserId] = useState(window.sessionStorage.getItem('userId'));
	const [hospitalId, setHospitalId] = useState(localStorage.getItem("hospitalId"));
	const [role, setRole] = useState(window.sessionStorage.getItem('role'));
	const [blockListDataget, setBlockListDataGet] = useState([]);
	const [blockListData, setBlockListData] = useState([]);
	const [blockListFlag, setBlockListFlag] = useState(false);
	const [checked, setChecked] = useState('allDay')
	const [loader, setLoader] = useState(false);
	const [timeSlot, setTimeSlot] = useState([])
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [slotStartTime, setSlotStartTime] = useState('')
	const [slotEndTime, setSlotEndTime] = useState('')
	const [docHosList, setDocHosListList] = useState([])
	const [calendarBlockDataNew, setCalendarBlockDataNew] = useState([]);
	const [doctorId, setDoctorId] = useState('')
	const [tabName, setTabName] = useState('Current')
	var hasValueSameDate = false;
	var blockedPatientAppointmentId = [];
	React.useImperativeHandle(ref, () => ({
		modalPopCall() {
			getData()
			getBlockList()
			setBlockListFlag(true)
			openModal()
		},
	}))
	useEffect(() => {
		getData()
	}, []);
	const getData = () => {
		if (role == 'ROLE_DOCTOR' || role == 'GROUP_ADMIN') {
			getHospitalList();
		}
	}
	const searchDoctor = () => {
		getDoctorList()
	}
	const doctorSelect = (doctor) => {
		setDoctorId(doctor.doctorId)
		$('#doctorNameSearch').val(doctor.doctorDisplayName + "(" + doctor.speciality + ")")
		setDocHosListList([]);
	}
	const getDoctorList = () => {
		if ($('#doctorNameSearch').val() != "" && (role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION')) {
			const postData = {
				role: role,
				userId: userId,
				hospitalId: hospitalId,
				doctorName: $('#doctorNameSearch').val(),
			};
			callingAPI('calenderListing', postData)
				.then((res) => {
					let docArray = res.data.result.map((item) => item.doctorId)
					doctor = docArray
					setDocHosListList(res.data.result);

				})
				.catch();
		} else {
			setDocHosListList([]);
			setDoctorId('')
			setBlockListFlag(false)
		}
	};
	const getHospitalList = () => {
		const postData = {
			role: 'ROLE_DOCTOR',
			userId: userId,
		};

		callingAPI('calenderListing/getHospitalDetails', postData)
			.then((res) => {


				setDocHosListList(res.data.result);
			})
			.catch();
	};
	const getBlockList = () => {
		setLoader(true)
		const postData = {
			"doctorId": (role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION') ? doctorId : userId,
			"hospitalId": hospitalId
		};
		callingAPI('clinicalBackground/blockCalendarList', postData)
			.then((res) => {
				setLoader(false)
				setBlockListDataGet(res.data.result.blockCalendarList)
				tabDataSet('Current', res.data.result.blockCalendarList)
				setBlockListFlag(true)
			})
			.catch((err) => {
				setLoader(false)
				setBlockListFlag(false)

			});
	}
	const tabChange = (name) => {
		setTabName(name)
		tabDataSet(name, blockListDataget)
	}
	const tabDataSet = (name, data) => {
		setTabName(name)
		let tabData = []
		if (name === "Current") {
			tabData = data.filter((data) => data.startTime >= new Date().setHours(0, 0, 0, 0)).sort((a, b) => a.startTime - b.startTime).map(item => item)
			setBlockListData(tabData)
		} else {
			tabData = data.filter((data) => data.startTime < new Date().setHours(0, 0, 0, 0)).sort((a, b) => b.startTime - a.startTime).map(item => item)
			setBlockListData(tabData)
		}
	}
	const createBlock = () => {
		setBlockListFlag(false)
	}
	const blockList = () => {
		getBlockList()
	}
	const openModal = () => {
		setIsOpen(true);
	}
	function closeModal() {
		setBlockListFlag(false)
		setIsOpen(false);
		setTimeSlot([])
		$(".slotData").remove()
		clearField()
	}
	const clearField = () => {
		setStartDate('')
		setEndDate('')
		$("#appointmentHosName").val(0)
		$("#reason").val('')
	}
	const slotChecked = (name) => {
		if (name == "allDay") {
			setChecked('allDay')
			clearField()
		} else {
			setChecked('timeSlot')
			clearField()

		}
	}
	const BlockCalendarSave = () => {
		$("span").attr("disabled", true);
		let reason = $("#reason").val()
		if ((role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION') && doctorId == '') {
			alert("Please Select Doctor");
			$('#doctorNameSearch').val("")
			document.getElementById("doctorNameSearch").focus()
		} else if (role == 'ROLE_DOCTOR' && $("#appointmentHosName").val() == 0) {
			alert("Hospital Name required");
		} else if (startDate == '') {
			alert("Start date required");
			document.getElementById("appoinmentDateStart").focus()
		} else if (endDate == '') {
			alert("End date required");
			document.getElementById("appoinmentDateEnd").focus()
		} else if (startDate > endDate) {
			alert("Please enter valid date");
		} else {
			setShowDiv(false)
			let dateStart = new Date(startDate).setHours(0, 0, 0, 0);
			let dateEnd = new Date(endDate).setHours(23, 59, 59, 999);
			let slot = [
				{
					"endTime": dateEnd,
					"startTime": dateStart,
					"blockedFor": reason
				}
			]
			CalendarSave(slot)
		}
	}
	const BlockSlotCalendarSave = () => {
		let slot = timeSlot
		if (slot.length > 0) {
			setShowDiv(false)
			CalendarSave(slot)
		} else {
			alert("Please Click On The Plus Icon To ADD");
			setShowDiv(true)
		}

	}
	const CalendarSave = (slot) => {
		setLoader(true)
		appointmentDataSet(slot)

	}
	const clearSlotField = () => {
		setStartDate('')
		setSlotStartTime('')
		setSlotEndTime('')
		$("#reason").val('')
	}
	const addTimeSlot = () => {
		let reason = $("#reason").val()
		let sDate = moment(startDate).format("YYYY-MM-DD")
		let slotSTime = moment(slotStartTime).format("hh:mm A")
		let slotETime = moment(slotEndTime).format("hh:mm A")
		let nDate = moment().format("YYYY-MM-DD")
		let startTime = new Date(sDate + " " + slotSTime)
		let endTime = new Date(sDate + " " + slotETime)
		let timeSlotArray = []
		if ((role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION') && doctorId == '') {
			alert("Please Select Doctor");
			$('#doctorNameSearch').val("")
			document.getElementById("doctorNameSearch").focus()

		} else if (role == 'ROLE_DOCTOR' && $("#appointmentHosName").val() == 0) {
			alert("Hospital Name required");
		} else if (startDate == '') {
			alert("Date required");
			document.getElementById("appoinmentDateTimeDuration").focus()
		} else if (slotStartTime == '') {
			alert("Start time required");
			document.getElementById("appoinmentTimeStart").focus()
		} else if (slotEndTime == '') {
			alert("End time required");
			document.getElementById("appoinmentTimeEnd").focus()
		}
		else if (slotStartTime > slotEndTime) {
			alert("End Time should be greater than Start time");
		} else {
			timeSlotArray.push(...timeSlot, {
				startTime: startTime.getTime(),
				endTime: endTime.getTime(),
				blockedFor: reason
			})
			setTimeSlot(timeSlotArray)
			clearSlotField()
		}
	}
	const blockDelete = (index) => {
		let array = timeSlot;
		if (index > -1) {
			timeSlot.splice(index, 1);
		}
		$("#slot" + index).remove()
		setTimeSlot(array)
	}
	const blockedDelete = (id) => {
		var answer = window.confirm("Do you really want to delete?")
		if (answer) {
			setLoader(true)
			const postData = {
				"timeSlotId": id
			};
			callingAPI('clinicalBackground/deleteBlock', postData)
				.then((res) => {
					getBlockList()
					alert(res.data.successMessage)
					props.blockChange()
				})
				.catch((err) => {
					setLoader(false)

				});
		}
	}

	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerFromTime = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromTimeClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToTime = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={TotimeClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};
	const FromTimeClear = () => {
		setSlotStartTime("")

	};

	const TotimeClear = () => {
		setSlotEndTime("")

	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const blockSaveApi = (slot) => {

		const postData = {
			"doctorId": (role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION') ? doctorId : userId,
			"hospitalId": hospitalId,
			"durationType": checked == "allDay" ? "All" : "Slot",
			"insertArray": slot,
			"patientAppointmentId": blockedPatientAppointmentId
		};
		callingAPI('clinicalBackground/saveBlockCalendar', postData)
			.then((res) => {
				if (res.data.success === '1') {
					setLoader(true)
					setShowDiv(true)
					alert("Dates Blocked successfully")
					setLoader(false)
					clearField()
					closeModal()
					clearSlotField()
					setTimeSlot([])
					$(".slotData").remove()
					props.blockChange()
				}
				else {
					alert(res.data.errorMessage)
					setShowDiv(true)
				}

			})
			.catch((err) => {
				setLoader(false)
				setShowDiv(true)

			});
	}

	const appointmentDataSet = (slot) => {
		var isBlocked;
		let postData = {
			"doctorId": (role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION') ? doctorId : userId,
			"patientAppointmentId": '',
			"hospitalId": hospitalId
		};
		callingAPI('calenderListing/scheduleList', postData)
			.then((res) => {
				if (res.data.success === '1') {
					setCalendarBlockDataNew(res.data.result);
					for (var d = 0; d < res.data.result.length; d++) {

						if (res.data.result[d].startTime != 0) {

							isBlocked = res.data.result[d].isBlocked
							var scheduleStartDate = new Date(res.data.result[d].startTime);
							var scheduleEndDate = new Date(res.data.result[d].endTime);
							var slotStartDate = new Date(slot[0].startTime);
							var slotEndDate = new Date(slot[0].endTime);
							if (checked === "allDay") {
								var scheduleStartDateFormat = moment(scheduleStartDate).format("DD/MM/YYYY");
								var slotStartDateFormat = moment(slotStartDate).format("DD/MM/YYYY");
							}

							else {
								var scheduleStartDateFormat = moment(scheduleStartDate).format("DD/MM/YYYY HH:mm:ss");
								var scheduleEndDateFormat = moment(scheduleEndDate).format("DD/MM/YYYY HH:mm:ss");
								var slotStartDateFormat = moment(slotStartDate).format("DD/MM/YYYY HH:mm:ss");
								var slotEndDateFormat = moment(slotEndDate).format("DD/MM/YYYY HH:mm:ss");
							}

							if (checked === "allDay") {
								if (scheduleStartDateFormat === slotStartDateFormat) {
									blockedPatientAppointmentId.push(res.data.result[d].patientAppointmentId)
									hasValueSameDate = true;

								}
							}


							else {

								if ((slotStartDateFormat <= scheduleStartDateFormat) && (scheduleEndDateFormat <= slotEndDateFormat)) {
									blockedPatientAppointmentId.push(res.data.result[d].patientAppointmentId)
									hasValueSameDate = true;
									break;
								}
							}


						}

					}
				}
				else {
					setCalendarBlockDataNew([]);
				}

				if (hasValueSameDate === true && isBlocked === false) {
					var answer = window.confirm("You have an appointment on this day.Do you want to block or not")
					if (answer) {
						blockSaveApi(slot)
					}
					else {

						setShowDiv(true)
					}
				}
				else {
					blockSaveApi(slot)
				}
			})
			.catch();

	};
	const clearDoctorListData = (e) => {
		if (document.getElementById('docidlist') !== null) {
			document.getElementById('docidlist').style.display = 'none';
		}

	};
	return (
		<>
			{loader === true ? <Loader /> : ''}
			<div className="calenderBlockList" onClick={openModal}>
				<img src={blockCalendar} className="CalendarImg" />Block Calendar
			</div>
			<div className='blockCalendarPopupWrapper'>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<div className='blockCalendarPopupSec' onClick={(e) => clearDoctorListData()}>

						<div class="addDataHead appointmentPopupClose">
							<span id="spanAddSupplier" style={{ fontSize: '16px' }}>
								Block Calendar
							</span>
							<a href="" id="btnCancel" class="close" onClick={closeModal}>
								<span class="popUpClose" style={{ position: "absolute", top: "-15px" }}>&times;</span>
							</a>
						</div>
						<div class="newAppoinmentRightList ListWrap createSect ">
							<div class="newAppoinmentButton viewAllBtnClandr">
								{blockListFlag === true ?
									<span class="borderButton createTimeslot" style={{ border: "none", color: "#428bca", paddingRight: "0px", textAlign: "right" }} onClick={() => createBlock()}>New</span>
									: (blockListFlag === false && doctorId != '') || (role == 'ROLE_DOCTOR' && blockListFlag === false) ?
										<span class="borderButton" style={{ border: "none", color: "#428bca", paddingRight: "0px", textAlign: "right" }} onClick={() => blockList()}>View All</span>
										: null
								}
							</div>
						</div>
						{(role == 'ROLE_HOSPITALADMIN' || role == 'ROLE_NURSE' || role == 'ROLE_RECEPTION') && blockListFlag == false ?
							<div className="col-md-6" style={{ float: "left" }}>
								<div className="doctorSearchFieldSec drnameCalendar" style={{ padding: "0px" }}>
									<label class="formLabel">Doctor Name
										<font color="red">
											<b>*</b>
										</font></label>
									<input
										type="text"
										className="inputField"
										placeholder="Doctor Name"
										id="doctorNameSearch"
										onChange={() => searchDoctor()}
									/>
									<FontAwesomeIcon icon={faSearch} className="doctorSearchIcon" />
									{docHosList.length > 0 &&
										<div id="docidlist" className='patientInfoList appointmentNumberSearch '>
											{docHosList.map((item, index) => (
												<div onClick={() => doctorSelect(item)}>{item.doctorDisplayName + "(" + item.speciality + ")"}</div>
											))}
										</div>
									}
								</div>
							</div>
							: role == 'ROLE_DOCTOR' && blockListFlag == false ?
								<div>
									{docHosList.length != 1 &&
										<div className="col-md-6" style={{ float: "left" }}>
											<div className="doctorSearchFieldSec drnameCalendar" style={{ padding: "0px" }}>
												<label class="formLabel">Hospital Name <font color="red">
													<b>*</b>
												</font> </label>
												<select id="appointmentHosName" className='inputField' onChange={(e) => setHospitalId(e.target.value)}>
													<option value="0">Select</option>
													{docHosList.map((val, index) => (
														<option value={val.hospitalId}>{val.hospitalName}</option>
													))}
												</select>
											</div>
										</div>
									}
								</div>
								: null
						}

						{blockListFlag === true &&

							<div className='calanderActions'>
								<button className={tabName === "Current" ? 'active' : ''} onClick={() => tabChange("Current")}>Current</button>
								<button className={tabName === "Previous" ? 'active' : ''} onClick={() => tabChange("Previous")}>Previous</button>
							</div>
						}
						{blockListFlag == false ?
							<form className='modalWrapper'>
								<div class="newAppoinmentRightList " style={{ paddingRight: "0px" }}>
									<label class="formLabel">Blocked For</label>
									<div class="appointmentFormList">
										<input type="text" class=" inputField input-design" autocomplete="off" placeholder="Blocked For" id="reason" />
									</div>
								</div>
								<div class="newAppoinmentRightList  radio" style={{ display: "block" }}>
									<input id="first" type="radio" name="numbers" value="allDay" checked={checked === 'allDay' ? true : false} onChange={() => slotChecked('allDay')} />
									<label for="first">All Day</label>
									<input id="second" type="radio" name="numbers" value="timeSlot" checked={checked === 'timeSlot' ? true : false} onChange={() => slotChecked('timeSlot')} />
									<label for="second">Time Slot</label>
								</div>
								{checked === "allDay" ?
									<div>
										<div class="newAppoinmentRightList dateSelect ">
											<label class="formLabel">Duration<sup>*</sup></label>
											<div class="appointmentFormList ">
												<Datepicker
													id="appoinmentDateStart"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
													closeCalendar="true"
													className="Datepicker pa2 inputField input-design "
													placeholderText="Start Date"
													calendarClassName="rasta-stripes"
													dateFormat="dd-MM-yyyy"
													minDate={new Date()}
													selected={startDate}
													onChange={(date) => setStartDate(date)}
													onKeyDown={e => e.preventDefault()}
													onChangeRaw={handleDateChangeRaw}
													calendarContainer={MyContainerFromDate}
												/>
											</div>
										</div>
										<div class="newAppoinmentRightList toTxt"  >
											<label class="formLabel">&nbsp;</label>
											<div class="appointmentFormList">
												<span>to</span>
											</div>
										</div>
										<div class="newAppoinmentRightList dateSelect ">
											<label class="formLabel">&nbsp;</label>
											<div class="appointmentFormList  ">
												<Datepicker
													id="appoinmentDateEnd"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
													closeCalendar="true"
													className="Datepicker pa2 inputField input-design "
													placeholderText="End Date"
													calendarClassName="rasta-stripes"
													dateFormat="dd-MM-yyyy"
													minDate={new Date()}
													selected={endDate}
													onChange={(date) => setEndDate(date)}
													onKeyDown={e => e.preventDefault()}
													onChangeRaw={handleDateChangeRaw}
													calendarContainer={MyContainerToDate}

												/>
											</div>
										</div>
									</div>
									:
									<div>
										<div class="newAppoinmentRightList dateSelect ">
											<label class="formLabel">Duration<sup>*</sup></label>
											<div class="appointmentFormList ">
												<Datepicker
													id="appoinmentDateTimeDuration"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
													closeCalendar="true"
													className="Datepicker pa2 inputField input-design "
													placeholderText="Start Date"
													calendarClassName="rasta-stripes"
													dateFormat="dd-MM-yyyy"
													minDate={new Date()}
													selected={startDate}
													onChange={(date) => setStartDate(date)}
													onKeyDown={e => e.preventDefault()}
													calendarContainer={MyContainerFromDate}
												/>
											</div>
										</div>

										<div class="newAppoinmentRightList timeSelect ">
											<label class="formLabel">&nbsp;</label>
											<div class="appointmentFormList  ">
												<Datepicker
													id="appoinmentTimeStart"
													showTimeSelect
													showTimeSelectOnly
													timeIntervals={15}
													timeCaption="Time"
													dateFormat="h:mm aa"
													className="Datepicker pa2 inputField input-design "
													placeholderText="Start Time"
													selected={slotStartTime}
													onChange={(date) => setSlotStartTime(date)}
													onKeyDown={e => e.preventDefault()}
													calendarContainer={MyContainerFromTime}
												/>
											</div>
										</div>
										<div class="newAppoinmentRightList toTxt"  >
											<label class="formLabel">&nbsp;</label>
											<div class="appointmentFormList">
												<span>to</span>
											</div>
										</div>
										<div class="newAppoinmentRightList timeSelect ">
											<label class="formLabel">&nbsp;</label>
											<div class="appointmentFormList  ">
												<Datepicker
													id="appoinmentTimeEnd"
													showTimeSelect
													showTimeSelectOnly
													timeIntervals={15}
													timeCaption="Time"
													dateFormat="h:mm aa"
													className="Datepicker pa2 inputField input-design "
													placeholderText="End Time"
													selected={slotEndTime}
													onChange={(date) => setSlotEndTime(date)}
													onKeyDown={e => e.preventDefault()}
													calendarContainer={MyContainerToTime}
												/>
											</div>
										</div>
										<div class="newAppoinmentRightList toTxt"  >
											<label class="formLabel">&nbsp;</label>
											<div class="appointmentFormList">
												<span className='calAction' onClick={() => addTimeSlot()}><img src={Add} /></span>
											</div>
										</div>
									</div>
								}
								{timeSlot.length > 0 ?
									<div className='slotInfoListAdded'>
										{timeSlot.map((item, index) => (
											<div id={"slot" + index} className="slotData">
												<div class="newAppoinmentRightList dateSelect ">
													<label class="formLabel">Duration</label>
													<div class="appointmentFormList ">
														<input type="text" class=" inputField input-design" value={moment(item.startTime).format("DD/MM/YYYY")} disabled />
													</div>
												</div>
												<div class="newAppoinmentRightList timeSelect ">
													<label class="formLabel">&nbsp;</label>
													<div class="appointmentFormList  ">
														<input type="text" class=" inputField input-design" value={moment(item.startTime).format("h:mm A")} disabled />
													</div>
												</div>
												<div class="newAppoinmentRightList toTxt"  >
													<label class="formLabel">&nbsp;</label>
													<div class="appointmentFormList">
														<span>to</span>
													</div>
												</div>
												<div class="newAppoinmentRightList timeSelect ">
													<label class="formLabel">&nbsp;</label>
													<div class="appointmentFormList  ">
														<input type="text" class=" inputField input-design" value={moment(item.endTime).format("h:mm A")} disabled />
													</div>
												</div>
												<div class="newAppoinmentRightList toTxt"  >
													<label class="formLabel">&nbsp;</label>
													<div class="appointmentFormList">
														<span className='calAction' onClick={() => blockDelete(index)}><img src={Close} /></span>
													</div>
												</div>
											</div>
										))}
									</div>
									:
									null
								}
								{checked === 'allDay' ?
									<div style={{ display: showDiv === true ? '' : 'none' }} class="newAppoinmentButton">
										<span id="addPatientSaveId" class="borderButton" onClick={() => BlockCalendarSave()}>Save</span>
									</div>
									:
									<div style={{ display: showDiv === true ? '' : 'none' }} class="newAppoinmentButton">
										<span id="addPatientSaveId" class="borderButton" onClick={() => BlockSlotCalendarSave()}>Save</span>
									</div>
								}
							</form>
							:
							<div>
								{blockListData.length > 0 ?
									<div className='timingList'>
										{blockListData.map((item) => (
											<div className='timingListItem' style={{ display: 'flex', alignItems: "center" }}>
												<div className='timeStatus'>{item.durationType == "All" ? "All Day" : "Time Slot"}</div>
												{item.durationType !== "All" &&
													<div> <label className='timeStatusLabel'>Date</label><br /> <input type="text" value={moment(item.startTime).format("DD/MM/YYYY")} ></input></div>
												}
												<div><label className='timeStatusLabel'>From </label><br /> <input value={item.durationType == "All" ? moment(item.startTime).format("DD/MM/YYYY") : moment(item.startTime).format("h:mm A")}></input></div>
												<div><label className='timeStatusLabel'>To  </label><br /> <input value={item.durationType == "All" ? moment(item.endTime).format("DD/MM/YYYY") : moment(item.endTime).format("h:mm A")}></input></div>


												{item.blokedFor !== "" ?
													<div className='reasonForBlock'><label className='timeStatusLabel' /> <br /> Reason : {item.blokedFor.replace(/\s+/g, ' ')}</div>
													:
													<div className='reasonForBlock'></div>
												}
												<div class="newAppoinmentRightList toTxt"  >
													<label class="formLabel">&nbsp;</label>
													<div class="appointmentFormList">
														<span className='calAction closeTime' onClick={() => blockedDelete(item.id)}><img src={Close} /></span>
													</div>
												</div>


											</div>

										))}
									</div>
									:
									<div className='noDataMsg' style={{ textAlign: 'center', paddingTop: '30px' }}>No data found</div>
								}
							</div>
						}
					</div>
				</Modal>
			</div>
		</>



	)
})
export default BlockcalenderPopup