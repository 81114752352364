import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import user from '../../images/icons/avatar.png';
import ardwn from '../../images/icons/ardwn.png';
import labLogo from '../../images/icons/lab/labLogo.jpg';
import Header from '../../layout/header';
import { callingAPI } from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery"
function LabHeader() {
	// const [showUserLink, setUserLink] = useState(false);
	const [result, setResult] = useState([]);
	const [dropDownCounter, setDropDownCounter] = useState({ "count": 0 });
	const revertRole = async () => {
		window.sessionStorage.setItem("homeHeader", "headerIconActive");
		window.sessionStorage.removeItem("clinicsHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		window.sessionStorage.setItem("calenderHeader", "active");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.setItem(
			"doctorId",
			window.sessionStorage.getItem("actualUserId")
		);
		window.sessionStorage.setItem("role", "ROLE_DOCTOR");
		window.sessionStorage.setItem(
			"userId",
			window.sessionStorage.getItem("actualUserId")
		);
		window.sessionStorage.setItem("userName", window.sessionStorage.getItem("actualDoctorName"))
		window.sessionStorage.setItem("userEmail", window.sessionStorage.getItem("actualDoctorMail"))
		// window.sessionStorage.setItem("loginRole", "Administrator");
		window.sessionStorage.setItem("loginRole", "Doctor");
		await calenderListingCount(window.sessionStorage.getItem("actualUserId"))
		window.sessionStorage.removeItem("actualUserId");
		window.sessionStorage.removeItem("actualDoctorName");
		window.sessionStorage.removeItem("actualDoctorMail");
		// window.location.href = "/Dashboard/welcome";
		window.location.href = "/Dashboard/dashboardView";
	};
	useEffect(() => {
		$("#notification_popup").hide();
		getNotification();
	}, []);

	const calenderListingCount = async (doctorId) => {
		return new Promise((resolve, reject) => {
			let postData = {
				"doctorId": doctorId,
				"hospitalId": ""
			}
			callingAPI("calenderListing/scheduleListCount", postData).then((data) => {
				localStorage.setItem("doctorAppointmentCount", data.data.result.totalCount);
				resolve(true)
			})
		})
	}
	//   const showUserLinks = () => {
	// 	setUserLink(!showUserLink);
	//   };
	const changeRoleDiv = (id) => {
		var elementDisplay = document.getElementById(id);
		elementDisplay.style.display = (dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			count: (dropDownCounter.count === 1) ? 0 : 1
		})

		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(id);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	};
	const f = () => {
		window.sessionStorage.removeItem("clinicsHeader");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		//window.location.href = "/Dashboard/dashboardMain";
		window.location.href = "/Dashboard/dashboardView";
	};
	const removeLoginSessions = () => {
		window.sessionStorage.removeItem("actualUserId");
		window.sessionStorage.removeItem("actualDoctorMail")
		window.sessionStorage.removeItem("actualDoctorName")
		window.sessionStorage.removeItem("role");
		window.sessionStorage.removeItem("doctorId");
		window.sessionStorage.removeItem("userEmail");
		window.sessionStorage.removeItem("userId");
		localStorage.removeItem("hospitalId");
		localStorage.removeItem("doctorAppointmentCount");
		window.sessionStorage.removeItem("userName");
		window.sessionStorage.removeItem("isLoggedIn");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("clinicsHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		window.sessionStorage.removeItem("isPrNumEnabled");
		window.location.href = "/";
	};
	const getNotification = () => {
		let PostData = {
			"functionName": ""
		}
		callingAPI('commonUserFunctions/getNotification', PostData).then((result) => {
			if (result.data.success === '1') {

				setResult(result.data.result);
			}
		})
	}
	const notificationPopup = () => {
		$("#notification_popup").show();
		getNotification();
	}
	const notificationPopupclose = () => {
		$("#notification_popup").hide();
	}
	return (
		<div>

			{window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" || window.sessionStorage.getItem("role") === "ROLE_DOCTOR" ?
				<Header /> :
				<div className="header headerScroll">
					<div className="toggleOverlay" />
					<div
						className='headerHospitalLab 00000000'>
						<div className="toggleHeader" id="toggleSetting" >
							<span />
						</div>
						<div className="logo">
							<Link to="/">
								<img
									src={
										process.env.REACT_APP_S3_URI +
										process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
										'/' +
										process.env.REACT_APP_S3_LOGO_PATH +
										'/' +
										window.sessionStorage.getItem('siteLogo')
									}
								/>
							</Link>
						</div>
						{(result.length > 0 ?
							<div className="notification_block">
								{/* video-icon */}
								<a class="notifi-icon video-icon" onClick={(e) => notificationPopup()}>
									<FontAwesomeIcon icon={faBell} />
								</a>
							</div>
							:
							<div className="notification_block">
								<a class="notifi-icon" onClick={(e) => notificationPopup()}>
									<FontAwesomeIcon icon={faBell} />
								</a>
							</div>
						)}

						<div className="headerRight">

							<div className="headerRightList">
								{window.sessionStorage.getItem("actualUserId") && (
									<span >

										<span style={{ cursor: 'default' }}>{"Logged in as "}
											<br />
											{window.sessionStorage.getItem("loginRole")}
										</span>
										<br />

										{window.sessionStorage.getItem("loginRole") !== "Hospital Lab" &&
											window.sessionStorage.getItem("loginRole") !== "Hospital Billing Admin" &&
											<a onClick={() => revertRole()} style={{ borderBottom: "1px solid #f1f1f1" }}>
												{"< Back To Doctor"}
											</a>}
									</span>
								)}
								<div />
							</div>


							<div className="headerRightList">
								<div onClick={() => changeRoleDiv("changeRoleDiv1")}>
									<span className="userClick">
										<img src={user} alt="" />
									</span>
									<span className="downCaret" />
								</div>
								<div
									className=
									'userDropDiv  userDropDivLab '
									id="changeRoleDiv1"
									style={{ display: "none" }}
								>
									<ul>
										<li className="userDropChild">
											<span class="practiceuserName">
												<span>{window.sessionStorage.getItem("userName")}</span>
												<br />
												<small>{window.sessionStorage.getItem("userEmail")}</small>
											</span>
										</li>

										<li>
											{/* <a href="#" onClick={() => dashboardMain()}>My Dashboard</a> */}
										</li>
										<li>
											<a href="#" onClick={() => removeLoginSessions()}>
												Sign Out
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					{/* <div className='headerBottom ' >
					<span class="treatmentGrandBy" className="DrNameView" style={{ position: 'absolute' }}>
							<div>
								<div className="drNameViewArrow" >
									<p style={{ margin: '0px' }}>
										Doctor Name
										<img   src={ardwn} />
										<ul
											className="autoSearch1 autoSearch-p0 appointmentNumberSearch ccc"
											id="testId"
											style={{ display: 'none' }}
										>

														<ul>
															<li  >
																<span>Doctor Name</span>
															</li>
														</ul>

										</ul>
									</p>
									<small>
										Doctor Specialisation
									</small>
								</div>
							</div>

					</span>
				</div> */}
				</div>

				//
			}
			<div className="ploverlayy" id="notification_popup" style={{ display: "none" }}>
				<div className="plpopup plpopupList plpopupNotification" style={{ width: '400px' }}>
					<h2>
						<a class="close" onClick={(e) => notificationPopupclose()}>
							&times;
						</a>
					</h2>
					<h3 className="notif_head">Notification</h3>
					<div className="plcontent">
						{result.map((data, i) => (
							<ul className="notif_ul">
								<li className="notif_li">
									<div className="notif_subhead">{data.title}</div>
									<div className="notif_descri">
										{data.description}
									</div>
								</li>
							</ul>
						))}
					</div>
				</div>
			</div>

		</div>
	);
}
export default LabHeader;