import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import TodayAppoinment from "../../images/icons/today-appointment.png"
var moment = require('moment');

function UpcomingAppoiments() {
    const [dataFlag, setDataFlag] = useState(false);
    const [upcomingAppointment, setUpcomingAppointment] = useState([])
    const [upcomingAppointmentTotal, setUpcomingAppointmentTotal] = useState([])

    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            hospitalId: localStorage.getItem("hospitalId"),
            doctorId: role === "ROLE_DOCTOR" ? window.sessionStorage.getItem('doctorId') : "",
        };
        callingAPI('commonUserFunctions/futureAppointments', PostData).then((response) => {

            if (response.data.success === '1') {
                setDataFlag(true);
                setUpcomingAppointment(response.data.result.futureAppointments)
                setUpcomingAppointmentTotal(response.data.result.totalCount)
            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    return (
        <div className="total_block upcomingApponmtmnt">
            <div className="total_subsec">
                <div className="total_sectext">
                    <div className='total_sechead'>Follow Up Appointments</div>
                    {/* <h5 className='total_seccount'>{upcomingAppointmentTotal}</h5> */}
                </div>
            </div>
            <div className="upcomingApp todayAppScroll">
                {upcomingAppointment.length > 0 ?
                    <>

                        {upcomingAppointment.map((data) => (
                            <>
                                <div class="dash_AppmntRightBlock">
                                    <div class="todyTimeSlot-appo">
                                        <h3>{moment(data.appointmentDate).format('DD MMM')}</h3>
                                        <p style={{ fontSize: "10px" }}>{moment(data.scheduledSlotStartTime).format('hh:mm a')}
                                        </p>

                                    </div>
                                    <div class="todyDetails-appomnt">
                                        <div class=" patntname-apponmnt elipsis" style={{ textTransform: "uppercase" }}> <b>{data.memberName}</b></div>
                                        <div class="dctrname-apponmnt elipsis" >{data.doctorName}</div>
                                        <div class="dctrname-apponmnt elipsis">{data.followUpNotes}</div>
                                    </div>
                                </div>
                            </>

                        ))}
                    </>
                    :
                    <div className='todayAppScroll nodataFnd nodata-found_wrpr'>
                        <p>No Follow Up Appointments Found </p>
                    </div>

                }
            </div>
        </div>
    )
}
export default UpcomingAppoiments
