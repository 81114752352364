import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Header from '../layout/header';
import Loader from '../Common/loader';
import Footer from '../layout/footer';
import axios from 'axios'
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function StaffAdd() {
	const [staffList, setStaffList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [categoryListExcludeLab, setCategoryListExcludeLab] = useState([]);

	const [staffName, setstaffName] = useState('');
	const [staffemail, setstaffemail] = useState('');
	const [staffpassword, setstaffpassword] = useState('');
	const [macAddress, setmacAddress] = useState('');
	const [ipAddress, setipAddress] = useState('');
	const [categoryId, setcategoryId] = useState('');
	const [dataFlag, setDataFlag] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	var filter = /^(.([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*))@((\[[a-z]{1,3}\.[a-z]{1,3}\.[a-z]{1,3}\.[a-z]{1,3}])|(([a-z]+\.)+[a-z]{2,}))$/;
	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
	}
	const getData = async () => {
		const res = await axios.get('https://geolocation-db.com/json/')
		// setipAddress(res.data.IPv4)
	}
	useEffect(() => {
		getData()
		document.getElementById("staffName").focus();
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			search: '',
			inputTxt: '',
		};
		callingAPI('staff/staffList', PostData).then((response) => {
			if (response.data.success === '1') {
				setDataFlag(true);
				setStaffList(response.data.StaffList);
			}
			else {
				setDataFlag(true);
				setStaffList([]);
			}
		})
			.catch();

		callingAPI('staff/categoryList').then((response) => {
			if (response.data.success === '1') {
				if (window.sessionStorage.getItem('siteBranchId') !== '4') {
					setCategoryList(response.data.result.category);
				} else {
					setCategoryList(response.data.result.category.filter((data, index) => data.name !== "Hospital Lab").map((item) => item))

				}

				//setCategoryListExcludeLab(response.data.result.category.filter((data, index) => data.name !== "Hospital Lab").map((item) => item))

			}
		})
			.catch();
	}, []);
	const saveStaffData = () => {
		let flag = true;
		if (!staffName) {
			alert('Staff Name Required');
			document.getElementById('staffName').focus();
			flag = false;
			return;
		} else if (!categoryId) {
			alert('Category Required');
			document.getElementById('categoryName').focus();
			flag = false;
			return;
		} else if (!staffemail) {
			alert('Email Required');
			document.getElementById('staffemail').focus();
			flag = false;
			return;
		}
		else if (!filter.test(staffemail)) {
			alert('Please provide a valid email address');
			document.getElementById('staffemail').focus();
			flag = false;
			return;
		} else if (!staffpassword) {
			alert('Password Required');
			document.getElementById('staffpassword').focus();
			flag = false;
			return;
		}
		setDataFlag(false);

		if (flag === true) {
			const postData = {
				staffId: '',
				name: staffName,
				password: staffpassword,
				emailId: staffemail,
				categoryId: categoryId,
				ipAddress: ipAddress,
				macAddress: "",
				// macAddress: macAddress,
				hospitalId: localStorage.getItem("hospitalId"),
				pmSiteId: window.sessionStorage.getItem('siteId'),
				userId: window.sessionStorage.getItem('userId'),
			};
			callingAPI('staff/addStaffNew', postData).then((data) => {

				if (data.data.successMessage === 'Success') {
					alert('Staff Added Successfully');
					setDataFlag(true);
					window.location.href = '/clinics/staffList';
				}
				else if (data.data.successMessage === 'Email Already Exists') {
					alert('Details not saved email id already exist');
					setDataFlag(true);
					return;
				}
				else {
					alert(data.data.errorMessage);
				}
			});
		}
	};

	return (
		<div className="coverWraper">
			<Header />

			<div className="contentWraper contentWraperScroll pl_staffList">
				<div class="pulseContentCoverWraper">
					<div class="container">
						{dataFlag === false ? <Loader /> : ''}
						<div class="content4">
							<div class="container">
								<div class="serviceInfoWrap">
									<h4
										style={{
											paddingBottom: '10px',
											paddingLeft: '8px',
											fontSize: '18px',
											fontFamily: 'OpenSans-Regular',
											fontWeight: 'normal',
										}}
									>
										Staff Details
										<br />
									</h4>
									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Staff Name *
										</label>
										<input
											type="text"
											id="staffName"
											placeholder='Name'
											value={staffName}
											onChange={(e) => { setstaffName(e.target.value); }}
											name="staffName"
										/>
										<input
											type="hidden"
											name="hospitalId"
											id="hospitalId"
											value=""
											class="formCommonField"
										/>
									</div>

									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Category{' '} *
										</label>

										<select
											class="fulWidthSelect changeMainSelect"
											id="categoryName"
											name="categoryName"
											value={categoryId}
											autofocus="autofocus"
											onChange={(e) => { setcategoryId(e.target.value); }}
										>
											<option value="0"
												style={{ fontSize: '16px' }}>Select Category</option>

											{console.log("categoryListExcludeLab.............", categoryListExcludeLab)}

											{categoryList.map((item, i) => (
												<option value={item.id} selected="selected" style={{ fontSize: '16px' }}>
													{item.name}
												</option>
											))}
										</select>
									</div>

									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Email *
										</label>
										<input
											type="text"
											id="staffemail"
											placeholder='Email'
											value={staffemail}
											onChange={(e) => { setstaffemail(e.target.value); }}
											name="staffemail"
										//  onChange={onChange}
										/>
									</div>
									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Password *
										</label>
										<input
											type={passwordType}
											id="staffpassword"
											placeholder='Password'
											value={staffpassword}
											onChange={(e) => { setstaffpassword(e.target.value); }}
											name="staffpassword"
										/>
										{/* <FontAwesomeIcon icon={faEye} onClick={togglePassword}/> */}
									</div>
									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Mac Address
										</label>
										<input
											class="smyLargstField"
											type="text"
											autofocus="autofocus"
											id="macAddress"
											value={macAddress}
											onChange={(e) => { setmacAddress(e.target.value); }}
											name="macAddress"
										/>
									</div>
									<div class="serviceListMain">
										<label for="" class="smalLbl">
											IP Address{' '}
										</label>
										<input
											class="smyLargstField"
											type="text"
											autofocus="autofocus"
											id="ipAddress"
											value={ipAddress}
											onChange={(e) => { setipAddress(e.target.value); }}
											name="ipAddress"
										/>
									</div>
									<div class="right">
										<input
											type="submit"
											value="Save"
											href="#close"
											class="practiceGreenButton"
											title="Save"
											onClick={saveStaffData}
											style={{ right: '10px', minWidth: '110px' }}
										/>
										<a href="/clinics/staffList">
											<input
												type="submit"
												value="Cancel"
												class="practiceGreyButton"
												title="Cancel"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="dividerSml" />
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default StaffAdd;
