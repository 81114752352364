import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import Priscription from '../images/icons/prescription.png';
import labOrderIcon from '../images/icons/labOrderIcon.png';
function TabsRight(props) {
	const [procedureList, setProcedureList] = useState([]);
	const [plannedProcedureList, setPlannedProcedureList] = useState([])
	const [PrescriptionsList, setPrescriptionsList] = useState([])
	const [plannedPrescriptionsList, setPlannedPrescriptionsList] = useState([])
	const [labOrder, setLabOrder] = useState([])
	const [plannedLabOrder, setPlannedLabOrder] = useState([])
	const [disposableList, setDisposableList] = useState([])
	const [procedureListBackUp, setProcedureListBackUp] = useState([]);
	const [plannedProcedureListBackUp, setPlannedProcedureListBackUp] = useState([])
	const [PrescriptionsListBackUp, setPrescriptionsListBackUp] = useState([])
	const [plannedPrescriptionsListBackUp, setPlannedPrescriptionsListBackUp] = useState([])
	const [labOrderBackUp, setLabOrderBackUp] = useState([])
	const [plannedLabOrderBackUp, setPlannedLabOrderBackUp] = useState([])
	const [disposableListBackUp, setDisposableListBackUp] = useState([])
	const [medicineBranchId, setmedicineBranchId] = useState(window.sessionStorage.getItem('medicineBranchId'));
	const [searchKey, setSearchKey] = useState("")
	const [isData, setIsData] = useState(false);
	useEffect(() => {		
		setRightMenuDataList()
	}, []);

	const setRightMenuDataList = () => {
		setProcedureList(props.rightMenuData.dataSet);
		setPlannedProcedureList(props.rightMenuData.plannedProcedure)
		setPrescriptionsList(props.rightMenuData.prescription)
		setPlannedPrescriptionsList(props.rightMenuData.plannnedPrescription)
		setLabOrder(props.rightMenuData.labOrder)
		setPlannedLabOrder(props.rightMenuData.plannnedLabOrder)
		setDisposableList(props.rightMenuData.disposableResult)
		setProcedureListBackUp(props.rightMenuData.dataSet);
		setPlannedProcedureListBackUp(props.rightMenuData.plannedProcedure)
		setPrescriptionsListBackUp(props.rightMenuData.prescription)
		setPlannedPrescriptionsListBackUp(props.rightMenuData.plannnedPrescription)
		setLabOrderBackUp(props.rightMenuData.labOrder)
		setPlannedLabOrderBackUp(props.rightMenuData.plannnedLabOrder)
		setDisposableListBackUp(props.rightMenuData.disposableResult)
		setIsData(true);	
	}
	// const PrcedureList = () => {
	// 	const apiJson = {
	// 		hospitalId: localStorage.getItem('hospitalId'),
	// 		memberId: window.sessionStorage.getItem("memberId"),
	// 		doctorId: window.sessionStorage.getItem('patientDoctorId'),
	// 		searchValue: "",
	// 		tabValue: "",
	// 		mode: "Invoice"
	// 	};		
	// 	callingAPI('treatmentPlanDetails/procedureListing', apiJson).then((data) => {			
	// 		if (data.data.success === '1') {
	// 			setProcedureList(data.data.result.dataSet);
	// 			setPlannedProcedureList(data.data.result.plannedProcedure)
	// 			setPrescriptionsList(data.data.result.prescription)
	// 			setPlannedPrescriptionsList(data.data.result.plannnedPrescription)
	// 			setLabOrder(data.data.result.labOrder)
	// 			setPlannedLabOrder(data.data.result.plannnedLabOrder)
	// 			setDisposableList(data.data.result.disposableResult)
	// 			setProcedureListBackUp(data.data.result.dataSet);
	// 			setPlannedProcedureListBackUp(data.data.result.plannedProcedure)
	// 			setPrescriptionsListBackUp(data.data.result.prescription)
	// 			setPlannedPrescriptionsListBackUp(data.data.result.plannnedPrescription)
	// 			setLabOrderBackUp(data.data.result.labOrder)
	// 			setPlannedLabOrderBackUp(data.data.result.plannnedLabOrder)
	// 			setDisposableListBackUp(data.data.result.disposableResult)
	// 			setIsData(true);
	// 		} else {
	// 			setIsData(true);
	// 		}
	// 	});
	// }
	const searchInvoice = (event) => {
		const { name, value } = event.target;
		setSearchKey(event.target.value)
		var proArray = [...procedureListBackUp]
		var proArrayPlanned = [...plannedProcedureListBackUp]
		var presArray = [...PrescriptionsListBackUp]
		var presArrayPlanned = [...plannedPrescriptionsListBackUp]
		var labArray = [...labOrderBackUp]
		var labArrayPlanned = [...plannedLabOrderBackUp]
		var disposArray = [...disposableListBackUp]
		var filteredData = []
		var filteredDataPlanned = []
		var filteredPresData = []
		var filteredPresPlanned = []
		var filteredLabData = []
		var filteredLabPlanned = []
		var filteredDisposibleData = []
		if (event.target.value !== "") {
			filteredData = proArray.filter((item) => {
				return Object.values(item.procedure_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredDataPlanned = proArrayPlanned.filter((item) => {
				return Object.values(item.procedure_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredPresData = presArray.filter((item) => {
				return Object.values(item.medicine).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredPresPlanned = presArrayPlanned.filter((item) => {
				return Object.values(item.medicine).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredLabData = labArray.filter((item) => {
				return Object.values(item.test_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredLabPlanned = labArrayPlanned.filter((item) => {
				return Object.values(item.test_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredDisposibleData = disposArray.filter((item) => {
				return Object.values(item.inventoryName).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			setProcedureList([...filteredData])
			setPlannedProcedureList([...filteredDataPlanned])
			setPrescriptionsList([...filteredPresData])
			setPlannedPrescriptionsList([...filteredPresPlanned])
			setLabOrder([...filteredLabData])
			setPlannedLabOrder([...filteredLabPlanned])
			setDisposableList([...filteredDisposibleData])
		} else {
			setProcedureList([...procedureListBackUp])
			setPlannedProcedureList([...plannedProcedureListBackUp])
			setPrescriptionsList([...PrescriptionsListBackUp])
			setPlannedPrescriptionsList([...plannedPrescriptionsListBackUp])
			setLabOrder([...labOrderBackUp])
			setPlannedLabOrder([...plannedLabOrderBackUp])
			setDisposableList([...disposableListBackUp])
		}
	}
	const procedureClick = () => {
		setSearchKey("")
		setProcedureList([...procedureListBackUp])
		setPlannedProcedureList([...plannedProcedureListBackUp])
		setPrescriptionsList([...PrescriptionsListBackUp])
		setPlannedPrescriptionsList([...plannedPrescriptionsListBackUp])
		setLabOrder([...labOrderBackUp])
		setPlannedLabOrder([...plannedLabOrderBackUp])
		setDisposableList([...disposableListBackUp])
	}
	return (
		<div className="patientright pl_pro tabsRightMain">
			<div className="rightTagSection">
				{isData === false ? <Loader /> : ''}
				<div className="rightTagList">				
					<Tabs>
						<TabList>
							<Tab><span className='procedureTab' onClick={() => procedureClick()}>Procedures </span></Tab>
							<Tab><span className='prescriptionsTab' onClick={() => procedureClick()}>Prescriptions</span></Tab>
							{medicineBranchId === '4' ? "" :
								<Tab><span className='labOrdersTab' onClick={() => procedureClick()}>Lab Orders</span></Tab>}
							<Tab><span className='disposablesTab' onClick={() => procedureClick()}>Disposables</span></Tab>
						</TabList>
						<input type="text" placeholder="Search" value={searchKey} onChange={(e) => searchInvoice(e)} className="inputField" />
						<TabPanel>
							<div className="treatmentNameList treatmentPlanList ">
								{(plannedProcedureList.length > 0 || procedureList.length > 0) ?
									<ul id="tabProceduresUL">
										{plannedProcedureList.map((plannedObj, j) => (
											<li id={"plannedProcedure" + plannedObj.member_procedure_dental_details_id} onClick={() => props.addProcedure(plannedObj, j, plannedObj.member_procedure_dental_details_id)}>
												<span class="elipsis" title={plannedObj.procedure_name}>
													<b>{plannedObj.procedure_name}</b>
												</span>
												{plannedObj.is_completed === 0 ?
													<span class="pl_planned_status">Planned</span>
													: <span class="pl_planned_status">Completed</span>} <br />
												<span class="elipsis" title={plannedObj.procedure_code} style={{ fontSize: '10px' }}>
													{plannedObj.procedure_code !== "" ? plannedObj.procedure_code : <span>&nbsp;</span>}
												</span>
												<span class="treatmentNameListRight">
													<span class="treatmentNameListAmt">₹{parseFloat(plannedObj.planned_amount).toFixed(2)}</span>
												</span>
											</li>
										))
										}
										{procedureList.map((procedureObj, i) => (
											<li id={"procedureId" + procedureObj.procedure_id} onClick={() => props.addProcedure(procedureObj, i, '')}>
												<span class="elipsis" title={procedureObj.procedure_name}>
													<b>{procedureObj.procedure_name}</b>
												</span>
												<br />
												<span class="elipsis" title={procedureObj.procedure_code} style={{ fontSize: '10px' }}>
													{procedureObj.procedure_code !== "" ? procedureObj.procedure_code : <span>&nbsp;</span>}
												</span>
												<span class="treatmentNameListRight">
													<span class="treatmentNameListAmt">₹{parseFloat(procedureObj.price).toFixed(2)}</span>
												</span>
											</li>
										))}
									</ul>
									: "No Treatment Found"}
							</div>
						</TabPanel>
						<TabPanel>
							<div className="treatmentNameList treatmentPlanList ">
								{(plannedPrescriptionsList.length > 0 || PrescriptionsList.length > 0) ?
									<ul id="tabProceduresUL">
										{plannedPrescriptionsList.map((presobj, i) => (
											<li id={"plannedPrescription" + presobj.pm_hospital_medicine_id} onClick={() => props.addPrescriptions(presobj, i, presobj.pm_hospital_medicine_id)} class="invoicePrescription">
												<span class="elipsis prescribedMedicine" title="WZ ER-tab 300 mdi">
													<img
														title="Prescribed Medicine"
														src={Priscription}
														style={{
															float: 'left',
															width: '15px',
															marginRight: '5px',
														}}
													/>
													<span>{presobj.medicine}</span>
												</span>
												<span class="treatmentNameListRight">
													<span class="invoicePresDate" title="Expiry Date">
														{presobj.expiry_date}
													</span>
													<span
														title="Prescribed Quantity"
														class="prescriptionQty"
														style={{ paddingLeft: '30px' }}
													>
														{presobj.changed_quantity}
													</span>
													<span class="treatmentNameListAmt" style={{ margin: '0' }}>
														₹ {parseFloat(presobj.unitPrice).toFixed(2)}
													</span>
												</span>
											</li>
										))}										
										{PrescriptionsList.map((obj, i) => (
											<li id={"prescriptionid" + obj.pm_hospital_medicine_id} onClick={() => props.addPrescriptions(obj, i, '')} class="invoicePrescription">
												<span class="elipsis prescribedMedicine" title="WZ ER-tab 300 mdi">
													<span>{obj.medicine}</span>
												</span>
												<span class="treatmentNameListRight">
													<span class="invoicePresDate" title="Expiry Date">
														{obj.expiry_date}
													</span>
													<span
														title="Prescribed Quantity"
														class="prescriptionQty"
														style={{ paddingLeft: '30px' }}
													>
														{obj.stockQuantity}
													</span>
													<span class="treatmentNameListAmt" style={{ margin: '0' }}>
														₹ {parseFloat(obj.unitPrice).toFixed(2)}
													</span>
												</span>
											</li>
										))}
									</ul>
									: "No Data Found"}
							</div>
						</TabPanel>
						{medicineBranchId === '4' ? "" :
							<TabPanel>
								<div className="treatmentNameList treatmentPlanList ">
									{(plannedLabOrder.length > 0 || labOrder.length > 0) ?
										<ul id="tabProceduresUL" className='labOrderUl'>
											{plannedLabOrder.map((laobj, k) => (
												<li id={"plannedLabOrder" + laobj.service_provider_test_details_id} onClick={() => props.addLabOrder(laobj, k, laobj.service_provider_test_details_id)}>
													<img
														title="Prescribed Medicine"
														src={labOrderIcon}
														style={{
															float: 'left',
															width: '15px',
															marginRight: '5px',
														}}
													/>
													<span class="elipsis" title={laobj.test_name}>
														{laobj.test_name}
													</span>
													<span class="treatmentNameListRight">
														<span class="treatmentNameListAmt" style={{ margin: '0px' }}>
															₹ {parseFloat(laobj.buy_price).toFixed(2)}
														</span>
													</span>
												</li>
											))}
											{labOrder.map((labObj, k) => (
												<li id={"labOrder" + labObj.service_provider_test_details_id} onClick={() => props.addLabOrder(labObj, k, '')}>
													<span class="elipsis" title={labObj.test_name}>
														<b>{labObj.test_name}</b>
													</span>
													<span class="treatmentNameListRight">
														<span class="treatmentNameListAmt" style={{ margin: '0px' }}>
															₹ {parseFloat(labObj.buy_price).toFixed(2)}
														</span>
													</span>
												</li>
											))}
										</ul>
										: "No Data Found"}
								</div>
							</TabPanel>}
						<TabPanel>
							<div className="treatmentNameList treatmentPlanList ">
								{disposableList.length > 0 ?
									<ul id="tabProceduresUL"  >
										{disposableList.map((disposableObj, i) => (
											<li onClick={() => props.addDisposable(disposableObj, i)} >
												<span class="elipsis" title="test">
													<b>{disposableObj.inventoryName}</b>
												</span>
												<span class="treatmentNameListRight">
													<span class="treatmentNameListAmt" style={{ margin: '0px' }}>
														₹ {parseFloat(disposableObj.unitPrice).toFixed(2)}
													</span>
												</span>
											</li>
										))}
									</ul>
									: "No Data Found"}
							</div>
						</TabPanel>
					</Tabs>					
				</div>
			</div>
		</div>
	);
}
export default TabsRight;
