import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
// import appointment from '../images/dental/icon/calender.png';
import { Link } from 'react-router-dom';
import Youtube from '../images/icons/youtube.png';
import casesheet from '../images/icons/casesheet.png';
import eye from '../images/icons/eye.png';
import payments from '../images/icons/payments.png';
import prescription from '../images/icons/prescription.png';
import treatmentplan from '../images/icons/treatment.png';
import completedPlans1 from '../images/icons/completedPlans.png';
import noImage from '../images/media/noImage.png';
import gallery from '../images/icons/gallery.png';
import invoice from '../images/icons/invoice.png';
import Calendar from '../images/icons/img4-b.png';
import Payment from '../images/icons/payments.png';
import { callingAPI } from '../config';
import useStateWithCallback from 'use-state-with-callback';
import NoData from "../Common/noDataFound";
import Moment from 'moment';
import Loader from "../Common/loader";
function HistoryInfo(props) {
	const changeData = () => {
		checkData();
	};
	const [selectClick, setSelectClick] = useState(false)
	const [showEditDiv, setShowEditDiv] = useState(true);
	const [appointments, setappointments] = useStateWithCallback(true, changeData);
	const [caseSheet, setCaseSheet] = useStateWithCallback(true, changeData);
	const [treatmentPlans, setTreatmentPlans] = useStateWithCallback(true, changeData);
	const [completedPlans, setCompletedPlans] = useStateWithCallback(true, changeData);
	const [files, setFiles] = useStateWithCallback(true, changeData);
	const [prescriptions, setPrescriptions] = useStateWithCallback(true, changeData);
	const [labOrder, setLabOrder] = useStateWithCallback(true, changeData);
	const [invoices, setinvoices] = useStateWithCallback(true, changeData);
	const [payments, setPayments] = useStateWithCallback(true, changeData);
	const [historyData, setHistoryData] = useState([])
	const [FlagData, setFlagData] = useState(false)
	const [isDataExists, setIsDataExists] = useState(true)
	const setPrintpage = () => {
		var arrayScheduleObject = {
			isDataExistsprint: isDataExists,
			appointmentsprint: appointments,
			caseSheetprint: caseSheet,
			treatmentPlansprint: treatmentPlans,
			completedPlansprint: completedPlans,
			filesprint: files,
			prescriptionsprint: prescriptions,
			labOrderprint: labOrder,
			invoicesprint: invoices,
			paymentsprint: payments,
			PrinDetails: historyData
		};
		localStorage.setItem("Printhistory", JSON.stringify(arrayScheduleObject))
		window.open(
			`${process.env.PUBLIC_URL}/Patients/historyPrint`,
			"_blank"
		);
	};
	const selectButton = () => {
		if (selectClick == true) {
			setSelectClick(!selectClick)
			setappointments(true)
			setCaseSheet(true)
			setTreatmentPlans(true)
			setCompletedPlans(true)
			setFiles(true)
			setPrescriptions(true)
			setLabOrder(true)
			setinvoices(true)
			setPayments(true)
		}
		else {
			setSelectClick(!selectClick)
			setappointments(false)
			setCaseSheet(false)
			setTreatmentPlans(false)
			setCompletedPlans(false)
			setFiles(false)
			setPrescriptions(false)
			setLabOrder(false)
			setinvoices(false)
			setPayments(false)
		}
	}
	const checkData = () => {
		setIsDataExists(false)
		historyData.map((obj) => {
			if (appointments === true) { if (obj.appointmentData.length > 0) { setIsDataExists(true) } }
			if (payments === true) { if (obj.paymentData.length > 0) { setIsDataExists(true) } }
			if (caseSheet === true) { if (obj.caseSheetData.length > 0) { setIsDataExists(true) } }
			if (treatmentPlans === true) { if (obj.treatmentPlanData.length > 0) { setIsDataExists(true) } }
			if (completedPlans === true) { if (obj.completedPlanData.length > 0) { setIsDataExists(true) } }
			if (files === true) { if (obj.fileData.length > 0) { setIsDataExists(true) } }
			if (prescriptions === true) { if (obj.prescriptionData.length > 0) { setIsDataExists(true) } }
			if (labOrder === true) { if (obj.labOrderData.length > 0) { setIsDataExists(true) } }
			if (invoices === true) { if (obj.invoiceData.length > 0) { setIsDataExists(true) } }
		})
	}
	const appointmentsClick = (event, test) => {
		setappointments(event.target.checked)
	}
	const CaseSheetClick = (event) => {
		setCaseSheet(event.target.checked)
	}
	const TreatmentPlansClick = (event) => {
		setTreatmentPlans(event.target.checked)
	}
	const CompletedPlansClick = (event) => {
		setCompletedPlans(event.target.checked)
	}
	const filesClick = (event) => {
		setFiles(event.target.checked)
	}
	const PrescriptionsClick = (event) => {
		setPrescriptions(event.target.checked)
	}
	const labOrderClick = (event) => {
		setLabOrder(event.target.checked)
	}
	const invoicesClick = (event) => {
		setinvoices(event.target.checked)
	}
	const paymentsClick = (event) => {
		setPayments(event.target.checked)
	}
	const patientHistoryListing = () => {
		const historyParams = {
			"memberId": window.sessionStorage.getItem("memberId"),
			"hospitalId": localStorage.getItem("hospitalId")
		}
		setFlagData(false)
		callingAPI('patientHistory/patientsHistory', historyParams)
			.then((response) => {
				if (response.data.success === '1') {
					setHistoryData(response.data.result.historyData)
					setFlagData(true)
				} else { setFlagData(true) }
			})
			.catch();
	}
	useEffect(() => {
		patientHistoryListing()
	}, [])
	var getS3folderDental =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_DENTALFILES_S3_IMAGE_PATH;
	var getS3foldersMedicine =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;
	const Edit = (labReqId, labFormId, servprvid) => {
		if (labFormId === 1) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			// window.location.href="/patients/labOrderDentalPrint"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderDentalPrint`, '_blank');
		}
		if (labFormId === 2) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			//window.location.href="/patients/labOrderMetalFreeCeramic"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderMetalFreeCeramic`, '_blank');
		}
		if (labFormId === 3) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			// window.location.href="/patients/labOrderMetalCeramic"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderMetalCeramic`, '_blank');
		}
		if (labFormId === 5) {
			window.sessionStorage.setItem('labReqId', labReqId);
			window.sessionStorage.setItem('labFormId', labFormId);
			window.sessionStorage.setItem('ServiceProviderId', servprvid);
			// window.location.href="/patients/labOrderMetalCeramic"
			window.open(`${process.env.PUBLIC_URL}/patients/labOrderOrthoCreation`, '_blank');
		}
	};
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll historyInfoTbles ">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection pl_addTreatSection" style={{ paddingRight: '15px' }}>
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour" style={{ background: "#ececec" }}>
									<div id="divPatientContent" class="sideMenuContent" style={{ borderTop: 'none' }}>
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead" style={{ border: 'none', padding: "13px 10px 2px" }}>
												<span class="treatmentPlanName">HISTORY</span>
												{(
													isDataExists === true
												) && (
														<span class="treatmentPlanBtn pl_blue_btn" onClick={() => setPrintpage()} style={{ background: "rgb(91, 183, 91)", padding: "4px 15px" }}>Print</span>
													)}
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}
											</div>
											<div id='patientData' class="treatmentPlanList1 pl_treatmentDiv pl_addTreatmnt">
												<div class="pl_treat treatmentTable planActive">
													<hr style={{ margin: '0px' }} />
													{/*  */}
													<div class="treatmentTimeline">
														<div class="treatmentTimelineCheck history_id_1">
															<div class="treatmentTimelineType">
																Types
																{/* <span class="timelineDeselect history_id_2" >De-select All</span> */}
																<span onClick={selectButton} class="timelineSelect history_id_2" style={{ float: "right" }}>{selectClick == true ? "Select All" : "Deselect All"}</span>
															</div>
															<ul>
																<li>
																	<input
																		type="checkbox"
																		value="appointments"
																		checked={appointments}
																		onChange={(event) => appointmentsClick(event, 'values')}
																		id="appointments"
																		name="chkType"
																	/>
																	<span class="timelineTxt">Appointments</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="clinicalNotes"
																		id="chkType"
																		name="chkType"
																		checked={caseSheet}
																		onChange={(event) => CaseSheetClick(event)}
																	/>
																	<span class="timelineTxt">Case Sheet</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="treatmentPlans"
																		checked={treatmentPlans}
																		onChange={(event) => TreatmentPlansClick(event)}
																		id="chkType"
																		name="chkType"
																	/>
																	<span class="timelineTxt">Treatment Plans</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		id="chkType"
																		name="chkType"
																		onChange={(event) => CompletedPlansClick(event)}
																		checked={completedPlans}
																	/>
																	<span class="timelineTxt">Completed Plans</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="files"
																		id="chkType"
																		name="chkType"
																		onChange={(event) => filesClick(event)}
																		checked={files}
																	/>
																	<span class="timelineTxt">Files</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="prescription"
																		checked={prescriptions}
																		onChange={(event) => PrescriptionsClick(event)}
																		id="chkType"
																		name="chkType"
																	/>
																	<span class="timelineTxt">Prescriptions</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		checked={labOrder}
																		onChange={(event) => labOrderClick(event)}
																		id="chkType"
																		name="chkType"
																	/>
																	<span class="timelineTxt">Lab Order</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		checked={invoices}
																		onChange={(event) => invoicesClick(event)}
																		id="chkType"
																		name="chkType"
																	/>
																	<span class="timelineTxt">Invoices</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="payments"
																		id="chkType"
																		name="chkType"
																		checked={payments}
																		onChange={(event) => paymentsClick(event)}
																	/>
																	<span class="timelineTxt">Payments</span>
																</li>
															</ul>
														</div>
														{FlagData == false ? (
															<div align="center">
																<Loader />
															</div>
														) : (
															<div id="innerListShow" class="history_id_3">
																<div class="treatmentTimelineBlock">
																	{(
																		isDataExists === true
																		// appointments === true  ||
																		// payments === true || caseSheet == true || treatmentPlans == true || completedPlans == true || files == true || prescriptions == true ||
																		// labOrder == true || invoices == true
																	) ?
																		<div>
																			<div class="timelineList" id='parentDiv'>
																				{historyData.map((item, i) => (
																					<div id={"dateData" + i} >
																						{
																							(appointments === true && item.appointmentData.length > 0 ||
																								payments === true && item.paymentData.length > 0 || caseSheet == true && item.caseSheetData.length > 0 || treatmentPlans == true && item.treatmentPlanData.length > 0 || completedPlans == true && item.completedPlanData.length > 0 || files == true && item.fileData.length > 0 || prescriptions == true && item.prescriptionData.length > 0 ||
																								labOrder == true && item.labOrderData.length > 0 || invoices == true && item.invoiceData.length > 0
																							) &&
																							<div id="listDate" class="timelineListDate">
																								<div id='date'>
																									<span class="timelineDate" >{(Moment(item.historyDate).format('DD'))}</span>
																									<div class="timelineDateCvr">
																										<span class="timelineMnthDate">{(Moment(item.historyDate).format('YYYY'))}</span>
																										<span class="timelineDay">{(Moment(item.historyDate).format('MMMM'))}</span>
																									</div>
																								</div>
																								{/* } */}
																							</div>
																						}
																						{/* PAYMENTS & FILES BEGINS HERE */}
																						{/* -----------APPOINTMENTS------------- */}
																						{(() => {
																							if (item.appointmentData !== undefined) {
																								return (
																									<div>
																										{appointments == true && item.appointmentData.length > 0 ? (
																											<div>
																												<div class="timelineListContent" id='appointmentss'>
																													<div className='timelineInnerSection'>
																														<span class="timelineArrow" />{' '}
																														<span class="timelineCircle" />
																														<div class="timelineListName">
																															{/* <img alt="" src={appointment} /> */}
																															<span><img src={Calendar} /> Appointments  </span>
																														</div>
																														{item.appointmentData[0].appointmentDetails.map((obj, k) => (
																															<div class="vitalSignsTable treatmentpadZero">
																																{/* {item.appointmentData.map((dataOne, index) => ( */}
																																<div>
																																	<div
																																		class="appoinmentDateDetail"
																																		style={{ float: 'none', width: '100%' }}
																																	>
																																		{/* {item.appointmentDetails.length >0 ?  */}
																																		{/* {dataOne.appointmentDetails.map(dataTwo=>(  */}
																																		<div>
																																			<div class="appoinmentLeftDiv">
																																				<span class="appoinmentReason">
																																					<span>Reason :</span>
																																					<span class="elipsis">
																																						{obj.visitReason}
																																					</span>
																																				</span>
																																			</div>
																																			<div class="appoinmentSatusWrap">
																																				<span class="appoinmentTYpe">
																																					Type : {obj.appointmentType}
																																				</span>
																																				<span class="appoinmentSTatus">
																																					Status : {obj.appStatus}
																																				</span>
																																			</div>
																																			<div class="appoinmentRightDiv">
																																				<span class="appoinmentDrName">
																																					{obj.doctorName}
																																				</span>
																																			</div>
																																		</div>
																																		{/* ))}     */}
																																	</div>
																																</div>
																																{/* ))} */}
																																<hr />
																															</div>
																														))}</div>
																												</div>
																											</div>
																										) : ""}
																									</div>
																								)
																							}
																						})()}
																						{/* -----------APPOINTMENTS------end------- */}
																						{/* Payment begins  */}
																						{(() => {
																							if (
																								item.paymentData !== undefined
																							) {
																								return (
																									<div>
																										{payments === true && item.paymentData.length > 0 ? (
																											<div class="timelineListContent" id="payments">
																												<div id={"payments"}><span class="timelineArrow"></span>
																													<span class="timelineCircle"></span>
																													<div class="timelineListName">
																														<span>
																															<img alt="" src={Payment} />
																															Payment</span>
																													</div>
																													<div class="treatmentTable invoiceTableWrap">
																														<div class="treatmentPlanList1">
																															<div class="treatmentTable invoiceTableWrap">
																															</div>
																														</div>
																														<table>
																															<tbody> </tbody>
																															<thead>
																																<tr>
																																	<th class="col-md-2"><div class="dentalProName">RECEIPT NUMBER</div></th>
																																	<th colspan="4" class="col-md-8">
																																		<div class="dentalProCost invoiceInnerTable">
																																			<table>
																																				<thead>
																																					<tr>
																																						<th class="col-md-3"><div class="dentalProName txtRight">AMOUNT PAID</div></th>
																																						<th class="col-md-3"><div class="dentalProDis txtLeft">INVOICES NUMBER</div></th>
																																						<th class="col-md-4" style={{ padding: "0" }}><div class="dentalProDis towards">TOWARDS</div></th>
																																						<th class="col-md-2"><div class="dentalProTotal txtLeft">MODE OF PAYMENT</div></th>
																																					</tr>
																																				</thead>
																																			</table>
																																		</div>
																																	</th>
																																	{/* <th class="col-md-1">
																																		<div class="dentalProNote" style={{ right: "-8px" }}>
																																		</div>
																																	</th> */}
																																</tr>
																															</thead>
																															<tbody>
																																{item.paymentData.map((obj, k) => (
																																	<tr>
																																		{obj.paymentDetails.map((objs, k) => (
																																			<tr class="groupBrdr">
																																				<td class="col-md-2" style={{ position: "relative" }}>
																																					<div class="dentalProName dentalProNameTop" >
																																						<span class="elipsis" style={{ width: '100%' }}>{objs.receipt_no}</span>
																																					</div>
																																				</td>
																																				<td colspan="4">
																																					<table style={{ border: "0" }}>
																																						<tbody>
																																							<tr style={{ border: "0" }}>
																																								<td class="col-md-3" style={{ position: "relative" }}>
																																									<div class="dentalProName dentalProNameTop textRight receipt_amt">
																																										{objs.receipt_amount.toFixed(2)}
																																									</div>
																																								</td>
																																								<td colspan="2" class="col-md-6" style={{ padding: "0" }}>
																																									<div class="dentalProCost invoiceInnerTable invoiceInnerTableBr">
																																										<tr className='InvoiceTr'>
																																											<td class="col-md-2">
																																												<div class="dentalProName txtLeft invoice_no">
																																													{objs.invoice_no}
																																												</div>
																																											</td>
																																											<td class="col-md-4" style={{ padding: "0" }}>
																																												<div class="dentalProDis " style={{ textAlign: "right", color: "inherit", fontSize: "11px" }} >
																																													{objs.procedure_code}
																																												</div></td>
																																										</tr>
																																									</div>
																																								</td>
																																								<td class="col-md-2" style={{ position: "relative" }}>
																																									<div class="dentalProName dentalProNameTop" >
																																										<span class="elipsis" style={{ color: "#000", paddingLeft: '8px' }}>{objs.payment_mode}</span>
																																									</div>
																																								</td>
																																							</tr>
																																						</tbody>
																																					</table>
																																				</td>
																																				{/* <td class="col-md-1">
																																					<div class="dentalProNote">
																																						<span class="elipsis">&nbsp;</span>
																																					</div>
																																				</td> */}
																																			</tr>
																																		))}
																																	</tr>
																																))}
																															</tbody>
																														</table>
																													</div>
																												</div>
																											</div>
																										) : ""}
																									</div>
																								)
																							}
																						})()}
																						{/* payment ends */}
																						{/* Files begins */}
																						{(() => {
																							if (
																								item.fileData !== undefined
																							) {
																								return (
																									<div>
																										{files === true && item.fileData.length > 0 ? (
																											<div class="timelineListContent" id="files" style={{ width: "100%", float: "left" }}>
																												<div><span class="timelineArrow"></span>
																													<span class="timelineCircle"></span>
																													<div class="timelineListName">
																														<span><img alt="" src={gallery} />
																															Files</span>
																													</div>
																													<div class="vitalSignsTable vitalSignsItemsCvr treatmentpadZero">
																														{item.fileData.map((obj, k) => (
																															<div class="vitalSignsItems ">
																																<img src={obj.Description === "M" ? getS3foldersMedicine + '/' + obj.ImageName : getS3folderDental + '/' + obj.ImageName} />
																																{/* <a href="#" target="_blank">
																													<img src={  obj.ImageName !== "null"?   getS3folder + '/' + localStorage.getItem('hospitalId')+ ".png"}
																														// <img alt="" src={obj.ImageName} style={{ width: "100%" }} />getS3folder
																													</a> */}
																																{/* <img src={getS3folder + '/' + obj.ImageName} style={{ width: '100%' }} /> */}
																															</div>
																														))}
																													</div>
																												</div>
																											</div>) : null}
																									</div>
																								)
																							}
																						})()}
																						{/* Files ends */}
																						{/* Lab Order  begins */}
																						{/* {item.labOrderData.map}
																					{item.labOrderData.map((obggj, k) => (fghfgdh))} */}
																						<div>
																							{(() => {
																								if (
																									item.labOrderData !== undefined
																								) {
																									return (
																										<div>
																											{labOrder == true && item.labOrderData.length > 0 ? (
																												<div id='laborderId' class="timelineListContent">
																													<div><span class="timelineArrow"></span>
																														<span class="timelineCircle"></span>
																														<div class="timelineListName">
																															<span><img alt="" src={casesheet} />
																																Lab Order</span>
																														</div>
																														{/* {item.labOrderData.find((objec) => objec.labReqId) ? */}


																														<>
																															{item.labOrderData.find((objec) => objec.labReqId) ?
																																<div class="treatmentTable prescriptionTable ">
																																	<table>
																																		<thead>
																																			<tr>
																																				<th class="col-md-3"><div class="dentalProName">Lab Name</div></th>
																																				<th class="col-md-3"><div class="dentalProCost" style={{ textAlign: "left" }}>Test Name</div></th>
																																				<th class="col-md-1"><div class="dentalProDis">View</div></th>
																																				<th class="col-md-1"><div class="dentalProDis">Status</div></th>
																																			</tr>
																																		</thead>
																																		<tbody>
																																			{item.labOrderData.filter((objec) => objec.labReqId).map((obj, k) => (
																																				<tr>
																																					<td class="col-md-3"><div class="dentalProName">{obj.labName}</div></td>
																																					<td class="col-md-3"><div class="dentalProCost" style={{ textAlign: "left" }}>{obj.lab_form_display_name}</div></td>
																																					<td class="col-md-1"><div class="dentalProDis">
																																						<label>
																																							<a
																																								onClick={(e) =>
																																									Edit(
																																										obj.labReqId,
																																										obj.labFormId,
																																										obj.service_provider_id
																																									)}
																																								target="new"
																																								title="View Lab Order"
																																							>
																																								<img src={eye} />
																																							</a>
																																						</label>
																																					</div>
																																					</td>
																																					<th class="col-md-1">
																																						{obj.status === 1 ?
																																							<span style={{ color: "red" }}>Pending</span> : <span style={{ color: "green" }}>Received</span>}
																																					</th>
																																				</tr>
																																			))}
																																		</tbody>
																																	</table>
																																</div> : ""}
																														</>
																														{/* : */}

																														{/* {item.labOrderData.find((obje) => obje.labReqId !== "" || obje.labReqId !== "null" || obje.labReqId !== null) ? */}
																														{item.labOrderData.find((objec) => objec.labOrderId) ?
																															<div class="treatmentTable prescriptionTable ">
																																<table>
																																	<thead>
																																		<tr>
																																			<th class="col-md-3"><div class="dentalProName">TEST NAME	</div></th>
																																			<th class="col-md-2"><div class="dentalProCost" style={{ textAlign: "left" }}>SPECIMEN</div></th>
																																			<th class="col-md-1"><div class="dentalProDis">TEST TYPE	</div></th>
																																			<th class="col-md-1"><div class="dentalProDis" style={{ textAlign: "right" }}>AMOUNT</div></th>
																																			<th class="col-md-2"><div class="dentalProDis">NOTES</div></th>
																																		</tr>
																																	</thead>
																																	<tbody>
																																		{item.labOrderData.map((obj, k) => (
																																			<tr>
																																				<td class="col-md-3"><div class="dentalProName">{obj.testName}	</div></td>
																																				<td class="col-md-2"><div class="dentalProCost" style={{ textAlign: "left" }}>{obj.specimen}</div></td>
																																				<td class="col-md-1"><div class="dentalProDis">{obj.testType}</div></td>
																																				<th class="col-md-1"><div class="dentalProDis" style={{ textAlign: "left", left: "24px", fontWeight: 'normal' }}>{obj.buy_price}</div></th>
																																				<th class="col-md-2"><div class="dentalProDis" style={{ textAlign: "left", fontWeight: 'normal' }}>{obj.comments}</div></th>
																																			</tr>
																																		))}
																																	</tbody>
																																	<div class="appoinmentRightDiv">
																																		<span class="appoinmentDrName">
																																			{/* {obj.doctorName} */}
																																		</span>
																																	</div>
																																</table>
																															</div>
																															: ""}
																														{/* } */}
																													</div>
																												</div>) : ""}
																										</div>
																									)
																								}
																							})()}
																						</div>
																						{/*  Lab Order ends */}
																						{/* ======================================================================= */}
																						{/* ========================================================= */}
																						{/* Prescription begins  */}
																						{(() => {
																							if (
																								// item.prescriptionData.length !==0
																								item.prescriptionData !== undefined
																							) {
																								return (
																									<div>
																										{prescriptions == true && item.prescriptionData.length > 0 ? (
																											<div class="timelineListContent" id="prescription">
																												<div><span class="timelineArrow"></span>
																													<span class="timelineCircle"></span>
																													<div class="timelineListName">
																														<span><img alt="" src={prescription} />
																															Prescriptions</span>
																													</div>
																													<div class="treatmentPlanList1">
																														<div class="treatmentTable prescriptionTable ">
																															<table>
																																<thead>
																																	<tr>
																																		<th class="col-md-3"><div class="dentalProName">Medicine</div></th>
																																		<th class="col-md-3"><div class="dentalProCost" style={{ textAlign: "left" }}>Frequency &amp; Dosage</div></th>
																																		<th class="col-md-2"><div class="dentalProCost" style={{ textAlign: "left" }}>Intake</div></th>
																																		<th class="col-md-1"><div class="dentalProDis" style={{ textAlign: "center!important" }}>Qty</div></th>
																																		<th class="col-md-1"><div class="dentalProTotal" style={{ textAlign: "left" }}>Days</div></th>
																																		<th class="col-md-2"><div class="dentalProTotal" style={{ textAlign: "left" }}>Start Date</div></th>
																																	</tr>
																																</thead>
																																<tbody>
																																	{item.prescriptionData[0].prescriptionDetailsData.map((obggj, k) => (
																																		<tr style={{ border: "1px" }}>
																																			<td class="col-md-3"><div class="dentalProName">{obggj.medicineName}</div></td>
																																			<td class="col-md-3">
																																				<div class="dentalProCost" style={{ textAlign: "left" }}>{obggj.instructions}</div>
																																			</td>
																																			<td class="col-md-2"><div class="dentalProCost" style={{ textAlign: "left" }}>{obggj.intake}</div></td>
																																			<td class="col-md-1"><div class="dentalProDis" style={{ textAlign: "center!important" }}>{obggj.quantity}</div></td>
																																			<td class="col-md-1"><div class="dentalProDis" style={{ textAlign: "left" }}>{obggj.days}</div></td>
																																			<td class="col-md-2"><div class="dentalProTotal" style={{ textAlign: "left" }}>{obggj.startDate}</div></td>
																																		</tr>))}
																																</tbody>
																															</table>
																															{item.prescriptionData.map((obgj, k) => (
																																<div class="treatmentPlanDate">

																																	<div class="treatmentComplaintNoted ">
																																		Noted by <span>{obgj.doctorTitle} {obgj.displayName}</span>
																																	</div>



																																</div>
																															))}
																														</div>
																														<br />
																													</div></div>
																											</div>
																										) : null}
																									</div>
																								)
																							}
																						})()}
																						{/* Prescreption ends */}
																						{/* Treatment plan begins*/}
																						{(() => {
																							if (
																								item.treatmentPlanData !== undefined
																							) {
																								return (
																									<div>
																										{treatmentPlans === true && item.treatmentPlanData.length > 0 ? (
																											<div class="timelineListContent" id="treatmentplan">
																												<div><span class="timelineArrow"></span>
																													<span class="timelineCircle"></span>
																													<div class="timelineListName">
																														<span><img alt="" src={casesheet} />
																															Treatment plan</span>
																													</div>
																													<div class="treatmentTable treatmentpadZero">
																														<div class="treatmentPlanList1">
																															<div class="treatmentTable ">
																																<table style={{ tableLayout: 'fixed' }}>
																																	<thead>
																																		<tr>
																																			<th class="col-md-3"><div class="dentalProName">PROCEDURE</div></th>
																																			<th class="col-md-2"><div class="dentalProCost">COST</div></th>
																																			<th class="col-md-2"><div class="dentalProDis" style={{ textAlign: "right" }}>TAX</div></th>
																																			<th class="col-md-2"><div class="dentalProTotal">TOTAL</div></th>
																																			<th class="col-md-3"><div class="dentalProNote">NOTES</div></th>
																																		</tr>
																																	</thead>
																																	<tbody	>
																																		{item.treatmentPlanData.map((obj, k) => (
																																			<>
																																				{obj.treatmentPlanDetails.map((objitem, k) => (
																																					<tr class="groupBrdr">
																																						<td class="col-md-3">
																																							<div class="dentalProName">
																																								<div class="optionWrapper">
																																									<img src={treatmentplan} title="Invoice Generated" />
																																									<label for="cool001"></label>
																																								</div>
																																								<span class="elipsis"><b>{objitem.treatmentPlanName}</b></span>
																																								<span class="elipsis">{objitem.procedure_code}</span>
																																							</div>
																																						</td>
																																						<td class="col-md-2"><div class="dentalProCost">{parseFloat(objitem.net_amount).toFixed(2) - parseFloat(objitem.total_tax).toFixed(2)}</div></td>
																																						<td class="col-md-2"><div class="dentalProDis" style={{
																																							textAlign: "right", color: "inherit",
																																							fontSize: "11px"
																																						}}>{parseFloat(objitem.total_tax).toFixed(2)}</div></td>
																																						<td class="col-md-2"><div class="dentalProTotal">{parseFloat(objitem.net_amount).toFixed(2)}</div></td>
																																						<td class="col-md-3">
																																							<div class="dentalProNote">
																																								<span class="elipsis" style={{ maxWidth: '99%', marginRight: '-6px', whiteSpace: 'normal' }} title="">{objitem.notes} </span>
																																							</div>
																																						</td>
																																					</tr>
																																				))}
																																				<tr class="dentalProPlaned" style={{ background: "#f3f3f3" }}>
																																					<td colspan="3"><div class="plannedBy" style={{
																																						color: "#333"
																																					}}>Planned by <span>{obj.doctor_name}</span></div></td>
																																					<td colspan="2"><div class="estimatedPrice txtRight">Estimated amount : {parseFloat(obj.total_net_amount).toFixed(2)}</div></td>
																																					<td colspan="1"><div class="estimatedPrice">&nbsp;</div></td>
																																				</tr>
																																			</>
																																		))}
																																	</tbody>
																																</table>
																															</div>
																														</div>
																													</div></div>
																											</div>) : null}
																									</div>
																								)
																							}
																						})()}
																						{/* Treatment lan ends */}
																						{/* Complted plan begins */}
																						{(() => {
																							if (
																								item.completedPlanData !== undefined
																							) {
																								return (
																									<div>
																										{completedPlans === true && item.completedPlanData.length > 0 ? (
																											<div class="timelineListContent" id="completedPlan">
																												<div><span class="timelineArrow"></span>
																													<span class="timelineCircle"></span>
																													<div class="timelineListName">
																														<span><img alt="" src={completedPlans1} />
																															Completed Plans</span>
																													</div>
																													{item.completedPlanData[0].completedPlanDetails.map((obj, k) => (
																														<div class="vitalSignsTable treatmentpadZero">
																															<div class="treatmentPlanList1">
																																<div class="treatmentTable prescriptionTable ">
																																	<table>
																																		<thead>
																																			<tr>
																																				<th class="col-md-3"><div class="dentalProName">PROCEDURE</div></th>
																																				<th class="col-md-2"><div class="dentalProCost" style={{ textAlign: "right" }}>COST</div></th>
																																				<th class="col-md-1"><div class="dentalProDis" style={{ textAlign: "right" }}>DISCOUNT</div></th>
																																				<th class="col-md-1"><div class="dentalProDis" style={{ textAlign: "right" }}>TAX</div></th>
																																				<th class="col-md-2"><div class="dentalProTotal" style={{ textAlign: "right" }}>TOTAL</div></th>
																																				<th class="col-md-3"><div class="dentalProNote" style={{ textAlign: "left" }}>NOTES</div></th>
																																			</tr>
																																		</thead>
																																		<tbody>
																																			<tr class="groupBrdr" id="tr0">
																																				<td class="col-md-3">
																																					<div class="dentalProName">
																																						<div class="optionWrapper">
																																							<img src={treatmentplan} title="Invoice Generated" />
																																						</div>
																																						<span class="elipsis"><b>{obj.completedPlanName}</b></span>
																																						<span class="elipsis">{obj.procedure_code}</span>
																																					</div>
																																				</td>
																																				<td class="col-md-2"><div class="dentalProCost" style={{ textAlign: "right" }}>{parseFloat(obj.amount).toFixed(2)}</div></td>
																																				<td class="col-md-1"><div class="dentalProDis" style={{
																																					textAlign: "right", color: "inherit",
																																					fontSize: "11px"
																																				}}>{parseFloat(obj.total_discount).toFixed(2)}</div></td>
																																				<td class="col-md-1"><div class="dentalProDis" style={{
																																					textAlign: "right", color: "inherit",
																																					fontSize: "11px"
																																				}}>{parseFloat(obj.total_tax_amount).toFixed(2)}</div></td>
																																				<td class="col-md-2"><div class="dentalProTotal" style={{ textAlign: "right" }}>{parseFloat(obj.net_amount).toFixed(2)}</div></td>
																																				<td class="col-md-2"><div class="dentalProTotal" style={{ textAlign: "left", maxWidth: '99%' }}>{obj.notes}</div></td>
																																				<td class="col-md-3">
																																					{/* <div class="dentalProNote" style={{ textAlign: "left" }}>
																																			<span class="elipsis" title="">&nbsp;</span>
																																		</div> */}
																																				</td>
																																			</tr>
																																			<tr class="dentalProPlaned" id="trSub0">
																																				<td colspan="6"><div class="plannedBy" style={{
																																					color: "#333"
																																				}}>Completed by <span>{obj.doctor_name}</span></div></td>
																																			</tr>
																																		</tbody>
																																	</table>
																																</div>
																															</div>
																														</div>))}</div>
																											</div>) : null}
																									</div>
																								)
																							}
																						})()}
																						{/* Complted plan ends */}
																						{/* Invoices begins  */}
																						{(() => {
																							if (
																								item.invoiceData !== undefined
																							) {
																								return (
																									<div>
																										{invoices === true && item.invoiceData.length > 0 ? (

																											<div class="timelineListContent" id="invoice">
																												<div><span class="timelineArrow"></span>
																													<span class="timelineCircle"></span>
																													<div class="timelineListName">
																														<span><img alt="" src={invoice} />Invoices</span>
																													</div>
																													{item.invoiceData.map((obj, k) => (
																														<>
																															{obj.final_array.map((obj, k) => (
																																<>
																																	<div class="treatmentPlanList1">
																																		<div class="treatmentTable invoiceTableWrap">
																																			{obj.main.map((mainobjs, m) => (
																																				<table className='table_print'>

																																					<tbody>

																																						{/* {mainobjs.item.map((obj, k) => ( */}
																																						{/* {obj.main.map((mainobjs, m) => ( */}

																																						<tr className='tr_head'>
																																							<td style={{ width: '120px' }}>INVOICE NUMBER</td>
																																							<td style={{ width: '110px' }}>TOWARDS</td>
																																							<td style={{ width: '85px' }}>QTY</td>
																																							<td style={{ width: '130px' }}>UNIT PRICE</td>
																																							<td style={{ width: '130px' }}>COST</td>
																																							<td style={{ width: '100px' }}>TAX</td>
																																							<td style={{ width: '100px' }}>TOTAL</td>
																																						</tr>

																																						<tr className='tr_body'>
																																							<td className='align-top invoic_text' style={{ width: '110px', borderRight: '1px solid #afafaf' }}><img alt="" src={treatmentplan} />&nbsp;{mainobjs.memberProcedureInvoiceNumber}</td>
																																							{/* {mainobjs.item.map((item, m) => (
																																					 <td className='table_subhead'><b>{item.itemType}</b></td>
																																					 ))} */}
																																							<td>

																																								{mainobjs.item.map((item, m) => (
																																									<>

																																										<tr className='tr_body'>
																																											{/* {mainobjs.item.map((item, m) => (
																																					 <td className='table_subhead'><b>{item.itemType}</b></td>
																																					 ))} */}
																																											<td className='table_subhead'><b>{item.itemType}</b></td>
																																										</tr>
																																										{item.item.map((lastitems, y) => (
																																											<tr className='tr_body'>
																																												<td style={{ width: '110px' }}>
																																													<p>{lastitems.itemName}</p>
																																													<p>{lastitems.itemCode}</p>
																																												</td>
																																												<td style={{ width: '85px' }}>
																																													{lastitems.quantity}
																																												</td>
																																												<td style={{ width: '130px' }}>
																																													{lastitems.unitPrice}
																																												</td>
																																												<td style={{ width: '130px' }}>
																																													{lastitems.cost}
																																												</td>
																																												<td style={{ width: '100px' }}> {lastitems.taxAmount}</td>
																																												<td style={{ width: '100px' }}>
																																													{lastitems.totalAmount}
																																												</td>
																																											</tr>))}
																																									</>
																																								))}
																																							</td>
																																						</tr>

																																						{/* {mainobjs.invoiceList.map((invoiceListmainobjs, m) => (			 */}
																																						<tr className='tr_footer'>
																																							<td className='align-top invoic_text' style={{ width: '110px' }}></td>
																																							<td  >
																																								<span className='text_amout'>Total Amount : {parseFloat(mainobjs.invoiceList.totalAmount).toFixed(2)}</span> <br />
																																								<span className='text_amout'>Balance Amount : <span className='text-danger'>{parseFloat(mainobjs.invoiceList.balanceAmount).toFixed(2)}</span></span><br />
																																							</td>
																																							<td >
																																								<span className='text_amout'>Discount Amount : {parseFloat(mainobjs.invoiceList.discountAmount).toFixed(2)}</span><br />
																																								<span className=' text_amout'>&nbsp;</span>
																																							</td>
																																							<td>
																																								<span className='text_amout'>Paid Amount : {parseFloat(mainobjs.invoiceList.paidAmount).toFixed(2)}</span><br />
																																								<span className=' text_amout'>&nbsp;</span>
																																								<span className='text-danger text_amoutn'></span>
																																							</td>
																																						</tr>
																																						{/* ))}  */}
																																						{/* ))} */}
																																						{/* ))} */}
																																					</tbody>
																																				</table>
																																			))}
																																			{/* ))} */}
																																		</div>
																																	</div>
																																</>
																															))}
																														</>
																													))}
																												</div>
																											</div>) : null}
																									</div>
																								)
																							}
																						})()}
																						{/* Invoices ends */}
																						{(() => {
																							if (
																								item.caseSheetData !== undefined
																							) {
																								return (
																									<div>
																										{caseSheet === true && item.caseSheetData.length > 0 ? (
																											<>
																												<div class="timelineListContent" id="casesheet">
																													<div><span class="timelineArrow" />
																														<span class="timelineCircle" />
																														<div class="timelineListName" style={{ marginBottom: '15px' }}>
																															<span><img alt="" src={casesheet} />
																																Case Sheet</span>
																														</div>
																														<div
																															class="treatmentPlanListWrap"
																															id="listClinicalNotes"
																														>
																															<div class="treatmentPlanList141720">
																																<div class="treatmentPlanDate" />
																																<div class="treatmentComplaintWrap">
																																	<div class="treatmentComplaintRowWrap">
																																		{item.caseSheetData.map((objs, t) => (
																																			<>
																																				{objs.caseSheetDetails.map((obj, t) => (
																																					<div class="treatmentComplaintRow">
																																						<div class="treatmentComplaintLeft">
																																							{obj.complaintTypeName}
																																						</div>
																																						<div class="treatmentComplaintRight">
																																							<span class="treatmentTAgName elipsis">
																																								{obj.tags}
																																							</span>
																																							<span class="treatmentTAgName elipsis sincedate">
																																								{obj.sinceDate}
																																							</span>
																																							{/* <span class="treatmentTAgDate">
																															05,Nov 2021
																														</span> */}
																																							<span
																																								style={{
																																									fontWeight:
																																										'bold',
																																								}}
																																							>
																																								Description :
																																							</span>
																																							<span>{obj.description}</span>
																																						</div>
																																					</div>
																																				))}
																																				<div className='treatmentComplaintLeft w-100' style={{ padding: '8px 0 15px' }}>Doctor Name : <strong>{objs.doctor}</strong></div>
																																			</>
																																		))}
																																	</div>
																																	{/* <div className='treatmentComplaintLeft'>Doctor Name :<strong>test</strong></div> */}
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																											</>
																										) : null}
																									</div>
																								)
																							}
																						})()}
																					</div>
																				))}
																				{/* payment ends */}
																			</div>
																			<div align="center" />
																		</div>
																		: <NoData />}
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default HistoryInfo;
