import React, { useState,useEffect} from 'react'
import { callingAPI } from '../config';
function Tooth(props) {
    const[masterData,setMasterData] = useState([])
    // const[value,setValue] = useState(0)
    const [valueArray, setValueArray] = useState([])
    const [disabled, setDisabled] = useState(false);
    const valueOnChange = async (e) => {
        var copyArr = [...valueArray]
        document.getElementById("appendeDiv" + props.teethNumber).style.display = "block"
        var element = document.getElementById("appendeText" + props.teethNumber)
        if(element.innerHTML!= e.target.value){  
            if(element.innerHTML.includes(e.target.value)===false){
                element.innerHTML = element.innerHTML ? element.innerHTML + "" + e.target.value : e.target.value
                copyArr = element.innerHTML.split(',');
                await props.passingToothData(copyArr, props.teethNumber);
            }
            // else{
            //     alert("Already added")
            //     return false;
            // }
        }
        // else if(element.innerHTML == e.target.value) {
        //     alert("helooooooooooooooo")
        //         element.innerHTML =  element.innerHTML?  element.innerHTML : e.target.value
        //         copyArr = element.innerHTML.split(',');
        //         await props.passingToothData(copyArr, props.teethNumber);

    }
    useEffect(() => {
        const postData = {
            "doctorId":  window.sessionStorage.getItem("patientDoctorId"),
            "memberId": window.sessionStorage.getItem("memberId"),
            "hospitalId":  localStorage.getItem("hospitalId"),
            // "date": "2021-12-29"
        }
        callingAPI('clinicalBackground/dentalCaseSheet', postData)
            .then((data) => {
                if (data.data.success === "1") {
                    setMasterData(data.data.result.pmDentalKeysData)
                }
            })
            .catch()
    }

        , []);
    return (
        <div>
            <div>
                <div className="pu_generalinfo">
              
                    <select
                        id="toothDropDown"
                        name="toothDropDown"
                        //  onclick="selectedKey(this.value)"
                        multiple="multiple"
                        onClick={valueOnChange}
                    >
               
               {masterData.map((item, i) => ( 
                        <option value={item.code}>
                           {item.code} -{item.description}
                        </option>
               ))}
                        {/* <option value="M">
                            M
                            -Missing/Extracted
                        </option>
                        <option value="F">
                            F -Filled
                        </option>
                        <option value="R">
                            R -Replaced
                        </option>
                        <option value="X">
                            X
                            -Recommended
                            Extraction
                        </option>
                        <option value="#">
                            # -Fractured
                            Tooth
                        </option>
                        <option value="I">
                            I -Impacted
                        </option>
                        <option value="P">
                            P
                            -Periodontal/Pockets
                        </option> */}
                    </select>
                    {/*  */}
                </div>
            </div>
            {/*  */}
        </div>
    );

}
export default Tooth