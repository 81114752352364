import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import referal from '../images/icons/referal.png';
import help from '../images/icons/help.png';
import dashboard from '../images/icons/dashboard.png';

function NurseHeaderLinks() {

    const myFunction = (e) => {
        if (e === "clinicsHeader") {
            window.sessionStorage.setItem('clinicsHeader', "headerIconActive");
            window.sessionStorage.removeItem('homeHeader')
            window.sessionStorage.removeItem('dashboardHeader')
            window.sessionStorage.removeItem('reportHeader')

        }


    }
    return (
        <div className="headerMenu " id="leftmenuRes">

            <span className="headerMenu ">
                <span id="clinicsHeader" className={"headerIcon "}>

                    <NavLink activeClassName='headerIconActive'
                        onClick={(e) => myFunction("clinicsHeader")}
                        to="/clinics/calendar">
                        <img src={referal} alt="" />Clinics
                    </NavLink>
                </span>
                <span id="dashboardHeader" className={'headerIcon '}>
                    <NavLink activeClassName='headerIconActive'
                        // to="/Dashboard/dashboardMain" 
                        to="/Dashboard/dashboardView"
                    >
                        <img src={dashboard} alt="" />Dashboard
                    </NavLink>
                </span>
                <span id="reportHeader" className='headerIcon' >
                    <NavLink activeClassName="headerIconActive" to="/Dashboard/help">
                        <img src={help} alt="" />Help
                    </NavLink>
                </span>
            </span>


        </div>
    )
}

export default NurseHeaderLinks
