import React, { useEffect } from 'react';
import { useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Printer_icon from '../images/icons/printer_icon.png';
import Editicon from '../images/icons/edit-bg.png';
import Loader from '../Common/loader';
import NoData from "../Common/noDataFound";
import { callingAPI } from '../config';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


var moment = require('moment');
function MedicalCertificate() {
	const navigate = useNavigate();

	const [medicalCertificateListing, setMedicalCertificateListing] = useState([]);
	const [flag, setFlag] = useState(false);
	const medicalCertificateList = () => {
		window.sessionStorage.removeItem('fromMedicalCertificateSaveAndPrint');
		setFlag(true)
		const listParams = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"memberId": window.sessionStorage.getItem('memberId'),
			"doctorId": window.sessionStorage.getItem('patientDoctorId'),
		}
		callingAPI('medicalCertificate/getMedicalCertificates', listParams)
			.then((response) => {
				if (response.data.success === '1') {

					setMedicalCertificateListing(response.data.result);
				}
				setFlag(false)
			})
			.catch();
	};
	const editCertificate = (data) => {
		navigate('/patients/medicalCertificateEdit', { state: data })
	}
	useEffect(() => {

		if (window.sessionStorage.getItem('fromMedicalCertificateSaveAndPrint') == 1) {
			window.open(
				`${process.env.PUBLIC_URL}/patients/medicalCertificatePrint/${localStorage.getItem('hospitalId')}`,
				"_blank"
			);

		}

		medicalCertificateList();


	}, []);
	return (
		<div className="coverWraper medCertificateWrap">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>

										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">Certificate</span>
												<a href="/patients/medicalCertificateEdit">
													<span class="treatmentPlanBtn pl_blue_btn">+ Add</span>
												</a>
											</div>
											<div class="treatmentPlanList" style={{ height: "auto" }}>
												{medicalCertificateListing.length > 0 ? (
													<div>
														{medicalCertificateListing.map((data) => (

															<div class="appoinmentWrap">

																<div class="appoinmentDateDiv" >
																	<span class="apnmentDate" >
																		{moment(data.createdOn).format("DD")}
																	</span>
																	<span class="apnmentDay">{moment(data.createdOn).format("MMM YYYY")}</span>
																	<span class="apnmentDay">{moment(data.createdOn).format("hh:mm A")}</span>
																</div>
																<div class="appoinmentDateDetail">
																	{data.hospitalDetails.map((item) => (
																		<div class="appoinmentLeftDiv">
																			<span class="appoinmentReason">
																				<span>Hospital : </span>
																				<span class="elipsis">
																					{item.hospitalName}
																				</span></span>
																		</div>
																	))}
																	{data.doctorDetails.map((item) => (
																		<div class="appoinmentRightDiv">
																			<span class="appoinmentHsptlName">
																				{item.title}{item.doctorDisplayName}
																			</span>
																		</div>
																	))}
																</div>
																<div className='medCertPrint'>
																	<a
																		href={`/patients/medicalCertificatePrint/${localStorage.getItem('hospitalId')}`}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<img src={Printer_icon} style={{ width: '18px' }} alt="Print Icon" />
																	</a>
																</div>
																<div class="appointmentEdit" style={{ padding: "0px" }}>
																	<span class="dentalProIcons" style={{ position: "absolute", right: "0px", top: "63px", background: "rgba(224, 224, 224, 0.66)", padding: "0px 3px" }}>
																		<nav onClick={() => editCertificate({ certificateId: data.certificateId })}
																		>
																			<img title="Edit" src={Editicon} style={{ width: "20px" }} />
																		</nav>

																	</span>
																</div>
															</div>
														))}
													</div>
												)
													: (
														<p>
															<NoData />
														</p>
													)}
											</div>


										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default MedicalCertificate;

