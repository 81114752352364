import React from 'react';
import PharmacyHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import { Route } from 'react-router-dom';
import PharmacyLeft from '../pharmacyLogin/pharmacyLeft'
import Pagination from "react-js-pagination";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
function PrescriptionAmountView() {
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper hospitalLabOrderView exportCalndrSec">
			<PharmacyHeader />
			<div className="contentWraper contentWraperScroll">
				<PharmacyLeft />
                <div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className='row'>
							<div className="breadcumbDiv">
							<Breadcrumb>
								<BreadcrumbItem>
									<Link to="">Prescription(s) Amount</Link>
								</BreadcrumbItem>
								<div class="arrow-right" style={{ marginLeft: '7px' }} />
								<BreadcrumbItem>Prescription(s) Amount Details</BreadcrumbItem>
							</Breadcrumb>
							</div>
						</div>
                        <div className="row">
                            <div className="col-md-10">
                                <div className="dashboardTitle">Prescription(s) Amount Details</div>
                            </div>
						</div>
			  <div className="dashboardContent">
							<div className="reportWrap">
								<div className="dashboardSearch">
						<div className="row dflexend">
										<div className="col-md-4 dashboardSearchList">
								<div className="searchCount" style={{margin:"0px"}}>
									Total Amount : &#8377; 100
								</div>
							</div>
						</div>
					</div>

					<div className="dashboardtable">
							<table>
								<thead>
									<tr>
												<th>Medicine Name		</th>
												<th>Instructions of taking Medicine	</th>
												<th>Date	</th>
												<th style={{textAlign:"right"}}>Amount	</th>

									</tr>
								</thead>
								<tbody>
											<tr>
												<td> DOLO (B12) tab 650 mg</td>
												<td>Every Two Hours-0-0-0-After food</td>
												<td>29, Nov 2017 - 29, Jul 2021</td>
												<td style={{textAlign:"right"}}> &#8377; 13 </td>

											</tr>
											<tr>
												<td> DOLO (B12) tab 650 mg</td>
												<td>Every Two Hours-0-0-0-After food</td>
												<td>29, Nov 2017 - 29, Jul 2021</td>
												<td style={{textAlign:"right"}}> &#8377; 13 </td>

											</tr>
                                        </tbody>
							</table>
					</div>
				</div>
					<div className="paginationSection">
						<Pagination
							itemsCountPerPage={2}
							totalItemsCount={5}
						/>
							</div>
                </div>
		    </div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PrescriptionAmountView;
