import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import { callingAPI } from "../config";
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Editicon from '../images/icons/edit-bg.png';
import Print from '../images/icons/printer_icon.png';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Delete from '../images/icons/delete_btn.png';
import { useNavigate } from 'react-router-dom';
function VitalSigns(props) {
	const navigate = useNavigate();
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	const vitalSingsPrintId = (mhrd) => {
		window.sessionStorage.setItem("mhrd", mhrd)
		window.open(
			`${process.env.PUBLIC_URL}/patients/vitalSignsPrint/${localStorage.getItem('hospitalId')}`,
			"_blank"
		);
	}

	const [listingData, setListingData] = useState([])
	useEffect(() => {
		listingApiCall()
	}, []);
	const listingApiCall = () => {
		let PostData = {
			"memberId": window.sessionStorage.getItem('memberId'),
			"hospitalId": localStorage.getItem("hospitalId"),
		}
		callingAPI("commonUserFunctions/simpleEntryFormVitalList", PostData).then((response) => {
			if (response.data.success === "1") {
				setListingData(response.data.result.vitalSignDateArr);

			}
		});
	}
	//
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})

		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none'
				}
			}
		});
	}
	// DELETE VITAL SIGNS BEGINS HERE

	const deleteInvoice = (memberHealthReportId, divId) => {

		var x = window.confirm('Do you want to Delete Vital Sign');

		if (x) {
			const postData = {
				"memberHealthReportId": memberHealthReportId,
			}
			callingAPI('patientProfile/deleteVitalSigns', postData).then((data) => {
				if (data.data.success === '1') {

					//droplist(divId, memberHealthReportId)
					alert("Deleted Successfully")
					listingApiCall()
				}
			});
		}

	}
	const editVital = (data) => {
		navigate('/patients/vitalSignsAdd', { state:  data  })
	}

	// DELETE VITAL SIGNS ENDS HERE
	// const deleteVitalSign = (memberHealthReportId, divId) => {
	// 	var x = window.confirm('Do You Want to Remove the Vital Sign?');

	// 	if (x) {
	// 		const postData = {

	// 			"memberHealthReportId": memberHealthReportId
	// 		}
	// 		callingAPI('patientProfile/deleteVitalSigns', postData).then((data) => {
	// 			if (data.data.success === '1') {
	// 				droplist(divId, memberHealthReportId)
	// 				listingApiCall()
	// 			}
	// 		});
	// 	}

	// }
	// const droplist = (divId, i) => {
	// 	var elementDisplay = document.getElementById(divId + i);
	// 	elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
	// 	setDropDownCounter({
	// 		id: divId + i,
	// 		count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
	// 	})

	// 	document.addEventListener('mouseup', function (e) {
	// 		var container = document.getElementById(divId + i);
	// 		if (container !== null) {
	// 			if (!container.contains(e.target)) {
	// 				container.style.display = 'none';
	// 			}
	// 		}
	// 	});
	// }
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">VITAL SIGNS</span>
												<a href="/patients/vitalSignsAdd">
													{/* <Link
														to={{
															pathname:
																'/patients/vitalSignsAdd',
														}}
													> */}
														<span class="treatmentPlanBtn pl_blue_btn">+ Add</span>
													{/* </Link> */}
												</a>
												{/* <a >
													<span class="treatmentPlanBtn pl_blue_btn">+ Add</span>
												</a> */}

											</div>
											{listingData.map((item) => (
												<>
													{item.VitalSignDoctorList.map((itemData, i) => (
														<div class="treatmentPlanList" style={{ height: "auto" }}>

															<div class="treatmentPlanList1"><div class="treatmentPlanDate">{itemData.VitalSignList[0].createdDate}</div>
																<div class="treatmentComplaintWrap">
																	<div class="treatmentComplaintRowWrap">
																		<div class="treatmentComplaintRow">
																			<div class="treatmentComplaintLeft">Time</div>
																			<div class="treatmentComplaintRight">
																				<span>
																					<span class="treatmentTAgName ">
																						<div>{itemData.VitalSignList[0].createdTime}</div>
																					</span>
																				</span>
																			</div>
																		</div>
																	</div>
																	{itemData.VitalSignList[0].weight !== "" ?
																		<div class="treatmentComplaintRowWrap">
																			<div class="treatmentComplaintRow">
																				<div class="treatmentComplaintLeft">Weight (Kg)</div>
																				<div class="treatmentComplaintRight">
																					<span>
																						<span class="treatmentTAgName ">
																							<div>{itemData.VitalSignList[0].weight}</div>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div> : ""}
																	{itemData.VitalSignList[0].height !== "" ?
																		<div class="treatmentComplaintRowWrap">
																			<div class="treatmentComplaintRow">
																				<div class="treatmentComplaintLeft">Height (Cm)</div>
																				<div class="treatmentComplaintRight">
																					<span>
																						<span class="treatmentTAgName ">
																							<div>{itemData.VitalSignList[0].height}</div>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div>
																		: ""}
																	{itemData.VitalSignList[0].systolic !== "" || itemData.VitalSignList[0].diastolic !== "" ?
																		<div class="treatmentComplaintRowWrap">
																			<div class="treatmentComplaintRow">
																				<div class="treatmentComplaintLeft">Blood Pressure<br />SYS/DIA (mmHg)</div>
																				<div class="treatmentComplaintRight">
																					<span>
																						<span class="treatmentTAgName ">
																							<div>{itemData.VitalSignList[0].systolic}/{itemData.VitalSignList[0].diastolic}&nbsp; {itemData.VitalSignList[0].sitOrStand}</div>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div>
																		: " "}
																	{itemData.VitalSignList[0].pulse !== "" ?
																		<div class="treatmentComplaintRowWrap">
																			<div class="treatmentComplaintRow">
																				<div class="treatmentComplaintLeft">Pulse (bpm)</div>
																				<div class="treatmentComplaintRight">
																					<span>
																						<span class="treatmentTAgName ">
																							<div>{itemData.VitalSignList[0].pulse}</div>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div>
																		: " "}
																	{itemData.VitalSignList[0].respRate !== "" ?
																		<div class="treatmentComplaintRowWrap">
																			<div class="treatmentComplaintRow">
																				<div class="treatmentComplaintLeft">Resp.Rate<br />(Breaths/Min)</div>
																				<div class="treatmentComplaintRight">
																					<span>
																						<span class="treatmentTAgName ">
																							<div>{itemData.VitalSignList[0].respRate}</div>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div> : " "}
																	{itemData.VitalSignList[0].temp !== "" ?
																		<div class="treatmentComplaintRowWrap">
																			<div class="treatmentComplaintRow">
																				<div class="treatmentComplaintLeft">Temprature (C)</div>
																				<div class="treatmentComplaintRight">
																					<span>
																						<span class="treatmentTAgName ">
																							<div>{itemData.VitalSignList[0].temp}</div>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div> : " "}
																	{itemData.VitalSignList[0].fbs !== "" || itemData.VitalSignList[0].ppbs !== "" || itemData.VitalSignList[0].rbs !== "" || itemData.VitalSignList[0].hbac !== "" || itemData.VitalSignList[0].hb !== "" || itemData.VitalSignList[0].cbc !== "" ?
																		<>
																			<div class="treatmentComplaintRowWrap">
																				<div class="treatmentComplaintRow">
																					<div class="treatmentComplaintLeft">Blood Glucose</div>
																					{itemData.VitalSignList[0].fbs !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%' }}>
																							<span class="treatmentTAgName ">
																								<div><b>FBS (Mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].fbs}</div>
																							</span>
																						</div> : ""}
																					{itemData.VitalSignList[0].ppbs !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%', borderLeft: 'none' }}>

																							<span class="treatmentTAgName ">
																								<div><b>PPBS (Mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].ppbs}</div>
																							</span>

																						</div>
																						: ""}
																					{itemData.VitalSignList[0].rbs !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%', borderLeft: 'none' }}>
																							<span class="treatmentTAgName ">
																								<div><b>RBS (Mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].rbs}</div>
																							</span>
																						</div>
																						: ""}
																				</div>
																			</div>
																			<div class="treatmentComplaintRowWrap">
																				<div class="treatmentComplaintRow">
																					{itemData.VitalSignList[0].hbac !== "" ?
																						<>
																							<div class="treatmentComplaintLeft">Hemoglobin & <br />Blood Count</div>
																							<div class="treatmentComplaintRight" style={{ width: '25%' }}>
																								<span class="treatmentTAgName ">
																									<div><b>HbA1c (%)</b></div>
																									<div>{itemData.VitalSignList[0].hbac}</div>
																								</span>
																							</div>
																						</>
																						: ""}
																					{itemData.VitalSignList[0].hb !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%', borderLeft: 'none' }}>
																							<span class="treatmentTAgName ">
																								<div><b>Hb (Mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].hb}</div>
																							</span>
																						</div> : " "}
																					{itemData.VitalSignList[0].cbc !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%', borderLeft: 'none' }}>
																							<span class="treatmentTAgName ">
																								<div><b>CBC</b></div>
																								<div>{itemData.VitalSignList[0].cbc}</div>
																							</span>
																						</div> : ""}
																				</div>
																			</div>
																		</>
																		: " "}
																	<div class="treatmentComplaintRowWrap">
																		<div class="treatmentComplaintRow">
																			{itemData.VitalSignList[0].cholestrol !== "" || itemData.VitalSignList[0].triglyceride !== "" || itemData.VitalSignList[0].hdl !== "" || itemData.VitalSignList[0].ldl !== "" ?
																				<>
																					<div class="treatmentComplaintLeft">Lipid Profile</div>
																					<div class="treatmentComplaintRight treatmentComplaintRightValue" style={{ width: '25%' }}>
																						<span class="treatmentTAgName ">
																							<div><b>Total cholestrol (Mg/dl)</b></div>
																							<div>{itemData.VitalSignList[0].cholestrol}</div>
																						</span>
																					</div>
																				</>
																				: ' '}
																			{itemData.VitalSignList[0].triglyceride !== "" ?
																				<div class="treatmentComplaintRight treatmentComplaintRightValue" style={{ width: '25%', borderLeft: 'none' }}>
																					<span class="treatmentTAgName ">
																						<div><b>Trigyceride (Mg/dl)</b></div>
																						<div>{itemData.VitalSignList[0].triglyceride}</div>
																					</span>
																				</div>
																				: " "}
																			{itemData.VitalSignList[0].ldl !== "" ?
																				<div class="treatmentComplaintRight treatmentComplaintRightValue" style={{ width: '25%', borderLeft: 'none' }}>
																					<span class="treatmentTAgName ">
																						<div><b>LDL (Mg/dl)</b></div>
																						<div>{itemData.VitalSignList[0].ldl}</div>
																					</span>
																				</div>
																				: " "}
																			{itemData.VitalSignList[0].hdl !== "" ?
																				<div class="treatmentComplaintRight" style={{ width: '75%', borderLeft: 'none', marginLeft: '25%' }}>
																					<span class="treatmentTAgName ">
																						<div><b>HDL (Mg/dl)</b></div>
																						<div>{itemData.VitalSignList[0].hdl}</div>
																					</span>
																				</div>
																				: " "}
																		</div>
																	</div>
																	{itemData.VitalSignList[0].urineAalbumin !== "" || itemData.VitalSignList[0].hb !== "" || itemData.VitalSignList[0].cbc !== "" || itemData.VitalSignList[0].lpt !== "" || itemData.VitalSignList[0].tft !== "" || itemData.VitalSignList[0].eyes !== "" ?
																		<>
																			<div class="treatmentComplaintRowWrap">
																				<div class="treatmentComplaintRow">
																					<div class="treatmentComplaintLeft">Urine Analysis</div>
																					<div class="treatmentComplaintRight" style={{ width: '25%' }}>
																						{itemData.VitalSignList[0].urineAalbumin !== "" ?
																							<span class="treatmentTAgName ">
																								<div><b>Urine Albumine (Mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].urineAalbumin}</div>
																							</span>
																							: ""}
																					</div>
																					{itemData.VitalSignList[0].hb !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%', borderLeft: 'none' }}>
																							<span class="treatmentTAgName ">
																								<div><b>Urea (mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].urea}</div>
																							</span>
																						</div>
																						: ""}
																					{itemData.VitalSignList[0].cbc !== "" ?
																						<div class="treatmentComplaintRight" style={{ width: '25%', borderLeft: 'none' }}>
																							<span class="treatmentTAgName ">
																								<div><b>Creatinine (mg/dl)</b></div>
																								<div>{itemData.VitalSignList[0].creatinine}</div>
																							</span>
																						</div>
																						: ""}
																				</div>
																			</div>
																			{itemData.VitalSignList[0].lpt !== "" ?
																				<div class="treatmentComplaintRowWrap">
																					<div class="treatmentComplaintRow">
																						<div class="treatmentComplaintLeft">LPT</div>
																						<div class="treatmentComplaintRight">
																							<span>
																								<span class="treatmentTAgName ">
																									<div>{itemData.VitalSignList[0].lpt}</div>
																								</span>
																							</span>
																						</div>
																					</div>
																				</div>
																				: ""}
																			{itemData.VitalSignList[0].tft !== "" ?
																				<div class="treatmentComplaintRowWrap">
																					<div class="treatmentComplaintRow">
																						<div class="treatmentComplaintLeft">TFT</div>
																						<div class="treatmentComplaintRight">
																							<span>
																								<span class="treatmentTAgName ">
																									<div>{itemData.VitalSignList[0].tft}</div>
																								</span>
																							</span>
																						</div>
																					</div>
																				</div>
																				: ""}
																			{itemData.VitalSignList[0].eyes !== "" ?
																				<div class="treatmentComplaintRowWrap">
																					<div class="treatmentComplaintRow">
																						<div class="treatmentComplaintLeft">Eyes</div>
																						<div class="treatmentComplaintRight">
																							<span>
																								<span class="treatmentTAgName ">
																									<div>{itemData.VitalSignList[0].eyes}</div>
																								</span>
																							</span>
																						</div>
																					</div>
																				</div>
																				: ""}
																			{itemData.VitalSignList[0].comments !== "" ?
																				<div class="treatmentComplaintRowWrap">
																					<div class="treatmentComplaintRow">
																						<div class="treatmentComplaintLeft">Comments</div>
																						<div class="treatmentComplaintRight">
																							<span>
																								<span class="treatmentTAgName ">
																									<div>{itemData.VitalSignList[0].comments}</div>
																								</span>
																							</span>
																						</div>
																					</div>
																				</div>
																				: ""}
																		</>
																		: " "}


																	<label class="dropdown vitalSignDrpDwn">
																		<div class="dd-button"
																		// onClick={() => droplist("dropdown", itemData.memberHealthReportId)}
																		/>
																		<input
																			type="checkbox"
																			class="dd-input"
																			id="test"
																		/>

																		<ul class="dd-menu"
																		// id={"dropdown" + itemData.memberHealthReportId}
																		>
																			<li  >
																				<div className="treatmentDropListOld printImg" style={{ padding: '0px' }} onClick={(e) => vitalSingsPrintId(itemData.VitalSignList[0].mhrId)}  >
																					<a>
																						<img src={Print} style={{ width: '18px' }} /> <span>Print</span>
																					</a>
																				</div>
																			</li>
																			<li onClick={()=>editVital({ memberHealthReportId: itemData.VitalSignList[0].mhrId })}>


																				{/* <Link
																					to={{
																						pathname: '/patients/vitalSignsAdd',
																						state: { memberHealthReportId: itemData.VitalSignList[0].mhrId }
																					}}> */}

																					<img src={Editicon} style={{ width: '15px', marginRight: '3px' }} /> Edit
																			{/* </Link> */}
																		</li>

																			<li onClick={() => deleteInvoice(itemData.VitalSignList[0].mhrId, "dropdown")}

																			>
																				<img src={Delete} style={{ width: '15px', marginRight: '5px', }} />Delete </li>

																		</ul>
																	</label>

																	<div class="treatmentComplaintNoted">Noted by <span>  {itemData.dname}</span></div></div></div>

														</div>
													))}
												</>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default VitalSigns;
