import React from 'react';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import InvoicePaymentList from '../Patients/invoicePaymentList';
// import Patientright from '../Patients/patientright';
// import PaymentAddRight from './Patients/paymentAddRight';
function invoicePayNow() {
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<InvoicePaymentList />
							</div>
							<div className="col-lg-4 col-md-12 practiceRightSection">
								{/* <PaymentAddRight /> */}
								<div
									class="treatmentProcedureList completedPlanProce_PTour proceduresinner_PTour testResultDetails_PTour"
									id="treatmentProcedureList"
								>
									<div class="treatmentListtop invoiceSearchProcedure_PTour paymentPending_PTour">
										<div class="treatmentListHead txtCenter">
											<span id="invoiceTxt" class="inVoiceTxtLabel">
												Select pending Invoice
											</span>
											{/* <span id="noInvoiceTxt" class="inVoiceTxtLabel">
												No pending Invoice
											</span> */}

											<span class="treatmentHeadAdvance">
												Advance (INR) : <span>12.00</span>
											</span>

											<span class="treatmentHeadDue">
												Due (INR) : <span id="dueTotal1">500.00</span>
											</span>
										</div>
									</div>
									<div class="treatmentListSide invoiceFullDetails_PTour paymentDetailsPending_PTour">
										<div class="treatmentNameList treatmentNameListHeight completedPlanSavedDetails_PTour treatmntPlanSavedDetails_PTour invoiceList_PTour">
											<ul id="treatList">
												<div class="inVoiceSideWraper paymentSideWrapper_PTour">
													<div class="inVoiceSideBlck" id="invoiceDiv53068">
														<div class="inVoiceTop">
															<div class="inVoiceSideLeft">INV39</div>
															<div class="inVoiceSideRight">25-Oct-2021</div>
														</div>

														<div class="inVoiceAmount">
															<div class="inVoiceAmountList">
																<div class="inVoiceSideLeft">Invoice Amount</div>
																<div class="inVoiceSideRight">₹ 500.00 </div>
															</div>
															<div class="inVoiceAmountList">
																<div class="inVoiceSideLeft">Paid Amount</div>

																<div class="inVoiceSideRight">₹ 0.00</div>
															</div>
															<div class="inVoiceTotalAmount">
																<div class="inVoiceSideLeft">Amount due</div>
																<div class="inVoiceSideRight">₹ 500.00 </div>
															</div>
														</div>
													</div>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default invoicePayNow;
