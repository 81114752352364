import React, { useState, useEffect } from 'react';
import Logo from '../images/media/logo.png';
import { callingAPI } from '../config';
function SimplifiedFormPrint() {
	const [simplifiedPrint, setSimplifiedPrint] = useState([])
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	// const [isData, setIsData] = useState(false);
	const [pagesizeid, setpagesizeid] = useState('');
	function styleValues() {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {

				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

				//setMarginTop()
			});

	}

	useEffect(() => {
		// styleValues()
		var a = localStorage.getItem('simplifiedPrint')
		if (a != null && a != 'null' && a != '') {
			var printData = JSON.parse(a);
			setSimplifiedPrint(printData)
			// localStorage.removeItem('simplifiedPrint')
		}
		// else {
		//     window.location.href = "/";
		// }
	})
	const printSimplifiedForm = () => {
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	return (
		<>
			{simplifiedPrint.map((hospDetails, i) => (
				<div class="caseSheetPrintWrap" style={{
					fontFamily: 'SourceSansPro-Regular', width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '0px',
					marginBottom: '0px', margin: '0 auto'
				}}>
					<div className="printWrap"  >


						<div class="caseSheetTop">
							<div class="caseLogo">
								<img src={Logo} />
							</div>
							<div class="caseNAme">
								<h2>{hospDetails.hospitalDetails[i].hospitalName}</h2>
								<span>{hospDetails.hospitalDetails[i].hospitalAddress}</span>
								<span>{hospDetails.hospitalDetails[i].hospitalPhoneNumber}</span>
							</div>
							<div class="caseDoctor">
								<p>{hospDetails.doctorDetails.doctorDisplayName} {hospDetails.doctorDetails.education}</p>
								<p>Council Reg. No.</p>
							</div>
						</div>

						<div class="patientDetails">
							<div class="patientDetailItem">
								<div class="patientNAme">
									<span class="patientNAmeLeft">PATIENT'S NAME :</span>
									<span class="patientNAmeRight">{hospDetails.memberDetails[i].memberFirstName} . {hospDetails.memberDetails[i].memberLastName}</span>
								</div>
								<div class="patientAge">
									<span class="patientAgeLeft">AGE :</span>
									<span class="patientAgeRight">{hospDetails.memberDetails[i].memberAge}</span>
								</div>
								<div class="patientSex">
									<span class="patientSexLeft">SEX :</span>
									<span class="patientSexRight">{hospDetails.memberDetails[i].memberGender}</span>
								</div>
							</div>
							<div class="patientDetailItem">
								<div class="patientAddress">
									<span class="patientAddressLeft">ADDRESS :</span>
									<span class="patientAddressRight">{hospDetails.memberDetails[i].memberAddress}</span>
								</div>
							</div>
							<div class="patientDetailItem">
								<div class="patientCase">
									<span class="patientCaseLeft">CASE RECORD NO :</span>
									<span class="patientCaseRight">{hospDetails.memberDetails[i].memberMrNo}</span>
								</div>
								<div class="patientRef">
									<span class="patientRefLeft">Ref.by :</span>
									<span class="patientRefRight" />
								</div>
								<div class="patientDate">
									<span class="patientDateLeft">DATE :</span>
									<span class="patientDateRight">{hospDetails.resultData.currentDate}</span>
								</div>
							</div>
						</div>
						{hospDetails.resultData.casesheet.length > 0 ?
							<div class="patientComplaint">
								<div class="patientComplaintItem">
									<span class="patientComplaintLabel">CHIEF COMPLAINT : </span>
									<span class="patientComplainttXT">&nbsp;{hospDetails.resultData.casesheet[0].tagCheifComp}
										<span />
									</span>
								</div>
								<div class="patientComplaintItem">
									<span class="patientHistory">ASSOCIATED COMPLAINT : </span>
									<span class="patientHistoryTxt">&nbsp;{hospDetails.resultData.casesheet[0].tagAssocComp}
										<span />
									</span>
								</div>
							</div>
							: ""}
						<div>
							<div class="specialisationTwo">
								<span class="medicalTitle">DIAGNOSIS &amp; TREATMENT PLAN/ EXPLAINED OPTIONS :</span>
								<span />
							</div>
							{hospDetails.resultData.medicineData ?
								<tbody>
									<tr>
										<td>
											<div class="printOutSettingContent">
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td align="left" style={{ width: '100%' }}>
																Rx
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>

									<tr>
										<td>
											<div class="printOutSettingContent">
												<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
													<tbody>
														<tr style={{ borderBottom: '2px solid black' }}>
															<td
																class="col-md-3"
																style={{
																	borderBottom: '2px solid black',
																	textAlign: 'left',
																	fontWeight: '500',
																	fontSize: '12px',
																	paddingBottom: '5px',
																	width: '20%',
																}}
															>
																Medicine
															</td>
															<td
																class="col-md-3"
																style={{
																	borderBottom: '2px solid black',
																	textAlign: 'left',
																	fontWeight: '500',
																	fontSize: '12px',
																	paddingBottom: '5px',
																	width: ' 25%',
																}}
															>
																Frequency & Dosage
															</td>
															<td
																class="col-md-3"
																style={{
																	borderBottom: '2px solid black',
																	textAlign: 'left',
																	fontWeight: '500',
																	fontSize: '12px',
																	paddingBottom: '5px',
																	width: ' 15%',
																}}
															>
																Intake
															</td>
															<td
																class="col-md-3"
																style={{
																	borderBottom: '2px solid black',
																	textAlign: 'left',
																	fontWeight: '500',
																	fontSize: '12px',
																	paddingBottom: '5px',
																	width: ' 8%',
																}}
															>
																Qty
															</td>
															<td
																class="col-md-3"
																style={{
																	borderBottom: '2px solid black',
																	textAlign: 'left',
																	fontWeight: '500',
																	fontSize: '12px',
																	paddingBottom: '5px',
																	width: ' 8%',
																}}
															>
																Days
															</td>
															<td
																class="col-md-3"
																style={{
																	borderBottom: '2px solid black',
																	textAlign: 'left',
																	fontWeight: '500',
																	fontSize: '12px',
																	paddingBottom: '5px',
																	width: ' 14%',
																}}
															>
																Start Date
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>

									<tr>
										<td>

											<div class="printOutSettingContent">

												{hospDetails.resultData.medicineData.map((hospDetails1, i) => (
													<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
														<tbody>
															<tr>
																<td
																	class="col-md-3"
																	style={{
																		width: '20%',
																		margin: '0',
																		color: '#616161',
																		lineHeight: '15px',
																		wordBreak: 'break-word',
																		fontSize: '11px',
																	}}
																>
																	<p
																		class="sml_matter"
																		style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																	>
																		{hospDetails1.medicineName}
																	</p>
																</td>
																<td
																	class="col-md-3"
																	style={{
																		width: ' 25%',
																		margin: '0',
																		color: '#616161',
																		lineHeight: '15px',
																		wordBreak: 'break-word',
																		fontSize: '11px',
																	}}
																>
																	<p
																		class="sml_matter"
																		style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																	>
																		{hospDetails1.frequency} - {hospDetails1.dosage}
																	</p>
																</td>
																<td
																	class="col-md-3"
																	style={{
																		width: ' 15%',
																		margin: '0',
																		color: '#616161',
																		lineHeight: '15px',
																		wordBreak: 'break-word',
																		fontSize: '11px',
																	}}
																>
																	<p
																		class="sml_matter"
																		style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																	>
																		{hospDetails1.intake}
																	</p>
																</td>
																<td
																	class="col-md-3"
																	style={{
																		width: ' 8%',
																		margin: '0',
																		color: '#616161',
																		lineHeight: '15px',
																		wordBreak: 'break-word',
																		fontSize: '11px',
																	}}
																>
																	<p
																		class="sml_matter"
																		style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																	>
																		{hospDetails1.quantity}
																	</p>
																</td>
																<td
																	class="col-md-3"
																	style={{
																		width: ' 8%',
																		margin: '0',
																		color: '#616161',
																		lineHeight: '15px',
																		wordBreak: 'break-word',
																		fontSize: '11px',
																	}}
																>
																	<p
																		class="sml_matter"
																		style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																	>
																		{hospDetails1.duration}
																	</p>
																</td>
																<td
																	class="col-md-3"
																	style={{
																		width: ' 14%',
																		margin: '0',
																		color: '#616161',
																		lineHeight: '15px',
																		wordBreak: 'break-word',
																		fontSize: '11px',
																	}}
																>
																	<p
																		class="sml_matter"
																		style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																	>
																		{hospDetails1.startDate}
																	</p>
																</td>
															</tr>
															<tr>
																<td
																	colspan="6"
																	style={{
																		textAlign: 'left',
																		fontSize: '11px',
																		lineHeight: '15px',
																		padding: ' 0px 2px 0px 0',
																		display: 'inline-block',
																		boxSizing: 'border-box',
																		color: '#393939',
																	}}
																>
																	<p
																		style={{
																			wordBreak: 'break-all',
																			margin: '3px 0',
																			fontSize: '11px!important',
																			wordBreak: ' break-word',
																		}}
																	>
																		{' '}
																		<span style={{ fontWeight: '600' }}>Instructions:{hospDetails1.instruction}</span>{' '}
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
												))}
											</div>

										</td>
									</tr>
								</tbody>

								: ""}

							<div class="specialisationConsent">
								<span class="medicalTitle">CONSENT :</span>
								<span>
									I the understand in my full sense give my consent for the above procedure explained and advised
									to me in this clinic. The doctor has explained the risk involved in this procedure and satisfied
									my queries.
								</span>
							</div>
							<div class="specialisationNames">
								<span>Name :{hospDetails.memberDetails[i].memberFirstName}</span>
								<span>Signature :</span>
							</div>
						</div>
						<div class="printBtm" style={{ textAlign: 'center' }}>
							<table style={{ width: '100%' }}>
								<tbody>
									<tr>
										<td colspan="6" style={{ textAlign: 'center' }}>
											<p
												id="printButton"
												style={{
													width: '70px',
													textAlign: 'center',
													paddingTop: '5px',
													lineHeight: '18px',
													display: 'inline-block',
													fontWeight: 'bold',
													fontSize: '14px',
													color: 'white',
													background: 'rebeccapurple none repeat scroll 0% 0%',
													marginTop: '2px',
													cursor: 'pointer',
													borderRadius: '5px',
													marginBottom: '2px',
													height: '25px',
													position: 'absolute',
													right: '0px',
													fontFamily: '  Verdana',
												}}
												onClick={printSimplifiedForm}
											>
												Print
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			))}

		</>
	);
}

export default SimplifiedFormPrint;
