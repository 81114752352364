import React, { useEffect, useRef, useState } from 'react'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicSelect from './micSelect';
const Speech = (props) => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    const lastTranscriptUpdateRef = useRef(Date.now());
    const [lastTranscript, setLastTranscript] = useState('');
    useEffect(() => {
        if (props.isClick === true) {
            document.getElementById("speechRecognition").click()

        }
        if (!listening && transcript !== "") {


            props.speechData(transcript, props.variableName)
            setLastTranscript(transcript)
        }

        const currentTime = Date.now();
        if (!listening && currentTime - lastTranscriptUpdateRef.current >= 1000) {

            SpeechRecognition.stopListening();
            //   SpeechRecognition.resetTranscript()
            setLastTranscript('');
            props.speechData(lastTranscript, props.variableName);
        }

    }, [transcript, listening])

    const speechListening = () => {
        if (props.language === "Malayalam") {
            SpeechRecognition.startListening({ language: 'ml_IN' });
        }
        else if (props.language === "English") {
            SpeechRecognition.startListening({ language: 'en-US' });
        }
        else if (props.language === "Hindi") {
            SpeechRecognition.startListening({ language: 'hi-IN' });
        }
        else if (props.language === "Tamil") {
            SpeechRecognition.startListening({ language: 'ta-IN' });
        }
    };
    return (
        <div>

            <div id="speechRecognition" onClick={speechListening} >
                {listening ?
                    <div className="speech_block">
                        <a class="notifi-icon video-icon" >
                            <FontAwesomeIcon icon={faMicrophone} />
                        </a>
                    </div>
                    :
                    <div className="speech_block initialMic ">
                        <a class="notifi-icon video-icon" >
                            <FontAwesomeIcon icon={faMicrophone} />
                        </a>
                    </div>

                }

            </div>
            <div className='speech_lang'>
                <MicSelect id={props.variableName} language={props.language} setLanguage={props.setLanguage} />
            </div>
        </div>
    )

}
export default Speech;