import React, { useEffect, useState } from 'react';
function Hospitaldetails(props) {
    const [hospitalDetailsValues, setHospitalDetailsValues] = useState([])
    useEffect(() => {
        setHospitalDetailsValues(props.hospitalDetails)
    }, [props.hospitalDetails])
    return (
        <div>
            <div className="homeProfileDetails">
                <div className="practiceManagementGrid profileForm">
                    <div className="row rowMargin">
                        <div className="col-md-12 col-lg-12 practiceManagementList">
                            <div className="dashboardtable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Country</th>
                                            <th>State</th>
                                            <th>Hospital</th>
                                        </tr>
                                    </thead>
                                    {hospitalDetailsValues.map((detailItem, index) => (
                                        <tbody>
                                            <tr>
                                                <td><input readOnly type="text" className="inputField" style={{ background: "#f1f1f1", cursor: "default" }} value={detailItem.hospitalCountry} /></td>
                                                <td><input readOnly type="text" className="inputField" style={{ background: "#f1f1f1", cursor: "default" }} value={detailItem.hospitalCity} /></td>
                                                <td><input readOnly type="text" className="inputField" style={{ background: "#f1f1f1", cursor: "default" }} value={detailItem.hospitalName} /></td>
                                            </tr>

                                        </tbody>
                                    ))}

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hospitaldetails
