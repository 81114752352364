import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { callingAPI } from '../config';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
//
function PurchaseInvoiceDetails() {
	const [activePage, setActivePage] = useState(1);
	const csvDownload = 'purchase invoice details';
	const [invoicecount, setInvoiceCount] = useState();
	const [invoiceDetails, setInvoiceDetails] = useState([]);
	const [dataFlag, setDataFlag] = useState(false);
	const [isData, setIsData] = useState(0);
	const [searchValue, setsearchValue] = useState('');
	const [filterName, setFilterInput] = useState('');
	const [totalGrossAmount, setTotalGrossAmount] = useState();
	const [totalNetAmount, setTotalNetAmount] = useState();
	const [totalTax, setTotalTax] = useState();
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");

	function csvFileDownload() {
		if (invoiceDetails.length === 0) {
			alert("No Data to Export");
		}
		else {
			const csvRow = [];
			var InvoiceList = [
				[
					'Invoice%20Number',
					'Invoice%20Date',
					'GST%20IN',
					'Supplier',
					'Address',
					'Gross%20Amount',
					'Tax%20Name',
					'Tax',
					'Quantity',
					'Total%20Tax',
					'Net%20Invoice',
				],
			];
			var name = invoiceDetails;
			let PostData = {
				hospitalId: localStorage.getItem("hospitalId"),
				offset: 0,
				max: "",
				searchValue: searchValue,
				searchId: filterName,
				fromDate: '',
				toDate: '',
			};
			callingAPI('adminReports/purchaseInvoice', PostData)
				.then((response) => {
					for (var item = 0; item < response.data.result.invoiceDetails.length; item++) {
						InvoiceList.push([
							response.data.result.invoiceDetails[item].supplierInvoiceno,
							response.data.result.invoiceDetails[item].supplierInvoiceDate.replace(/,/g, '').replace(/ /g, '%20'),
							response.data.result.invoiceDetails[item].supplierGstIn,
							response.data.result.invoiceDetails[item].supplierName.replace(/ /g, '%20'),
							response.data.result.invoiceDetails[item].supplierAddress,
							"Rs." + "" + response.data.result.invoiceDetails[item].supplierInvoiceTotalamount,
							response.data.result.invoiceDetails[item].hospitalTaxDetailsTaxname,
							response.data.result.invoiceDetails[item].supplierInvoiceTax,
							response.data.result.invoiceDetails[item].supplierInvoiceMedicineTaxDetailsQty,
							response.data.result.invoiceDetails[item].supplierInvoiceMedicineTaxDetailsTaxvalue,
							response.data.result.invoiceDetails[item].supplierInvoiceNetamount,
						]);
					}
					for (var i = 0; i < InvoiceList.length; ++i) {
						csvRow.push(InvoiceList[i].join(','));
					}
					var csvString = csvRow.join('%0A');
					var create = document.createElement('a');
					create.href = 'data:attachment/csv,' + csvString;
					create.target = '_Blank';
					create.download = csvDownload + '.csv';
					document.body.appendChild(create);
					create.click();
				})
		}
	};
	const paginationOnPageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;

		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;

		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) {
			setFilterInput("");
		}
		if (!booliansearchValue) { setsearchValue("") }


		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max.toString(),
			offset: offset.toString(),
			searchValue: booliansearchValue,
			searchId: boolianfilterName,
			fromDate: '',
			toDate: '',
		};
		setDataFlag(false);
		callingAPI('adminReports/purchaseInvoice', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					setInvoiceDetails([]);
					setInvoiceCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					setInvoiceDetails(response.data.result.invoiceDetails);
					setInvoiceCount(response.data.result.invoiceTotalCount);
					setTotalGrossAmount(response.data.result.grandTotal);
					setTotalNetAmount(response.data.result.netAmount);
					setTotalTax(response.data.result.grandInvoiceTax);
					setDataFlag(true);
				}
			})
			.catch();
	};
	const searchValueChange = (value) => {
		setsearchValue(value);
	};

	const changeTextonTextArea = (value) => {
		setFilterInput(value);
		setsearchValue('');
		if (value === '') {
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const searchValueOnChangeOnSelect = () => {
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('selectName').focus();
			alert(selectedText + ' required');
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(filterName);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: '0',
			max: process.env.REACT_APP_PAGINATION_COUNT,
			searchValue: searchValue,
			searchId: filterName,
			fromDate: '',
			toDate: '',
		};
		callingAPI('adminReports/purchaseInvoice', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					setInvoiceDetails([]);
					setInvoiceCount(0);

				}
				if (response.data.success === '1') {
					setInvoiceDetails(response.data.result.invoiceDetails);
					setInvoiceCount(response.data.result.invoiceTotalCount);
					setTotalGrossAmount(response.data.result.grandTotal);
					setTotalNetAmount(response.data.result.netAmount);
					setTotalTax(response.data.result.grandInvoiceTax);
					setIsData(1);
					setActivePage(1);
					setDataFlag(true);
				}
			})
			.catch();
	};
	useEffect(() => {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchId: filterName,
			searchValue: searchValue,
			offset: pageOffset.toString(),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			fromDate: '',
			toDate: '',
		};
		callingAPI('adminReports/purchaseInvoice', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					setInvoiceDetails([]);
					setInvoiceCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {

					setInvoiceDetails(response.data.result.invoiceDetails);
					setInvoiceCount(response.data.result.invoiceTotalCount);
					setTotalGrossAmount(response.data.result.grandTotal);
					setTotalNetAmount(response.data.result.netAmount);
					setTotalTax(response.data.result.grandInvoiceTax);
					setDataFlag(true);
				}
			})
			.catch();
	}, []);
	return (
		<div className="dashboardCover PurchaseInvoiceDetails">
			<div className="dashboardTitle">Purchase Invoice Details</div>
			{invoicecount > 10 && (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={invoicecount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
						onChange={paginationOnPageChange}
						hideDisabled
					/>
				</div>
			)}

			<div>
				<div className="dashboardContent">
					<div className="reportWrap">
						<div className="dashboardSearch">
							<div className="row">
								<div className="col-md-2 dashboardSearchList">
									<select
										className="inputField"
										id="selectName"
										name="selectName"
										value={filterName}
										onChange={(e) => changeTextonTextArea(e.target.value)}
									>
										<option value="">Search In</option>
										<option value="1">Invoice Number</option>
									</select>
								</div>
								<div className="col-md-2 dashboardSearchList">
									{filterName == "" ? (
										<input
											className="inputField"
											type="text"
											disabled
											style={{ background: "transparent" }}
											value={searchValue}
											// onChange={(e) => searchValueChange(e.target.value)}
											id="searchText"
											name="searchText"
										/>
									) : (
										<input
											className="inputField"
											type="text"
											value={searchValue}
											style={{ background: "white" }}
											onChange={(e) => searchValueChange(e.target.value)}
											id="searchText"
											name="searchText"
										/>
									)}
								</div>

								<div className="col-md-2 dashboardSearchList">
									<span onClick={searchValueOnChangeOnSelect} className="dashButtonBorder">
										Search
									</span>
								</div>
								<div className="col-md-2 dashboardSearchList">
									<span onClick={csvFileDownload} className="dashButtonBorder dashButtonBorderGrey">
										Export
									</span>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<div className="searchTxt">Total Gross Amount : {totalGrossAmount}</div>
								</div>
								<div className="col-md-3">
									<div className="searchTxt">Total Net Amount : {totalNetAmount}</div>
								</div>
								<div className="col-md-3">
									<div className="searchTxt">Total Tax : {totalTax}</div>
								</div>
								<div className="col-md-3">
									<div className="searchCount" style={{ textAlign: 'right' }}>
										Number of Invoices : {invoicecount}
									</div>
								</div>
							</div>
						</div>
						{dataFlag == false ? (
							<Loader />
						) : invoiceDetails.length > 0 ? (
							<div className="dashboardtable">
								<div class="reportTableCvr">
									<table class="table  purchaseInvoiceTble">
										<thead style={{ borderBottom: '1px solid #393939!important' }}>
											<tr>
												<th>Invoice Number</th>
												<th>
													{' '}
													<div style={{ width: '75px' }}>Invoice Date</div>
												</th>
												<th>GST IN</th>
												<th>Supplier</th>
												<th>Address</th>
												<th style={{ textAlign: 'right' }}>Gross Amount</th>
												<th class="col-md-2">Tax Details</th>
												<th style={{ textAlign: 'right' }}>Net Invoice</th>
											</tr>
										</thead>
										<tbody>
											{invoiceDetails.map((item, i) => (
												<tr style={{ borderBottom: '1px solid #757575' }}>
													<td style={{ fontSize: '13px' }}>
														<span
															style={{ fontSize: '13px', width: '85px' }}
															class="elipsis"
															title="111"
														>
															{' '}
															{item.supplierInvoiceno}{' '}
														</span>{' '}
													</td>
													<td style={{ fontSize: '13px' }}>{item.supplierInvoiceDate}</td>
													<td style={{ fontSize: '13px' }}>{item.supplierGstIn}</td>
													<td style={{ fontSize: '13px' }}> {item.supplierName}</td>
													<td>
														<span
															style={{ fontSize: '13px', width: '85px', color: '#333' }}
															class="elipsis"
															title="Trivandrum"
														>
															{item.supplierAddress}
														</span>{' '}
													</td>
													<td style={{ fontSize: '13px', textAlign: 'right' }}>
														<label style={{ fontSize: '13px' }}>
															&#8377; {item.supplierInvoiceTotalamount}
														</label>
													</td>
													<td>
														<table class="GSTtable" style={{ width: '312px' }}>
															<tbody style={{ border: 'none' }}>
																<tr>
																	<th style={{ fontWeight: "600", width: "100px" }}>Tax Name</th>
																	<th style={{ fontWeight: "600" }}>Tax </th>
																	<th style={{ fontWeight: "600" }}>Quantity</th>
																	<th style={{ textAlign: 'right', fontWeight: "600" }}>Total Tax</th>
																</tr>
																<tr>
																	<td>
																		{item.hospitalTaxDetailsTaxname === "No Tax Added" ?
																			<div style={{ fontSize: '13px', color: 'red' }}>
																				{item.hospitalTaxDetailsTaxname}
																			</div>
																			:
																			<div style={{ fontSize: '13px', color: 'Black' }}>
																				{item.hospitalTaxDetailsTaxname}
																			</div>}
																	</td>
																	{item.supplierInvoiceTax !== 0 ?
																		<td> {item.supplierInvoiceTax} %</td> :
																		<td>--</td>
																	}
																	{item.supplierInvoiceMedicineTaxDetailsQty !== 0 ?
																		<td> {item.supplierInvoiceMedicineTaxDetailsQty} </td> :
																		<td>--</td>
																	}
																	{item.supplierInvoiceMedicineTaxDetailsTaxvalue !== "" ?
																		<td style={{ textAlign: "right" }}> {item.supplierInvoiceMedicineTaxDetailsTaxvalue} </td> :
																		<td style={{ textAlign: "right" }}>--</td>
																	}
																</tr>
															</tbody>
														</table>
													</td>
													<td style={{ textAlign: 'right' }}>
														<label style={{ fontSize: '13px' }}>
															&#8377; {item.supplierInvoiceNetamount}
														</label>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								{/*  */}
							</div>
						) : (
							<p>
								<NoData />
							</p>
						)}
					</div>

					{invoicecount > 10 && (
						<div className="paginationSection">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={invoicecount}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
								onChange={paginationOnPageChange}
								hideDisabled
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default PurchaseInvoiceDetails;
