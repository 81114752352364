import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import { callingAPI } from '../config';
import Editicon from '../images/icons/edit-bg.png';
import Print from '../images/icons/printer_icon.png';
import Delete from '../images/icons/delete_btn.png';
import moment from 'moment';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';

function CasesheetDental() {
	const [dentalCaseSheetArray, setDentalCaseSheetArray] = useState([]);
	const [date, setDate] = useState([]);
	const [finalsStatus, setFinalsSatus] = useState("");
	const [redirectState, setRedirectState] = useState('');
	const [reState, setReState] = useState('');
	const [doctorVisitId, setdoctorVisitId] = useState('');
	const [load, setLoad] = useState(false);
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	const getSpecimenListingDataValues = () => {
		window.sessionStorage.setItem('editId', '');
		let postData = {
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('clinicalBackground/dentalCaseSheetlist', postData).then((response) => {
			if (response.data.success === '1') {
				setLoad(true)
				setDentalCaseSheetArray(response.data.result.dentalCaseSheetArray);
				if (response.data.result.dentalCaseSheetArray.length > 0) {
					setDate(response.data.result.dentalCaseSheetArray[0].createdOn);
					setFinalsSatus(response.data.result.dentalCaseSheetArray[0].finalStatus);
					window.sessionStorage.setItem('finalstatus', response.data.result.dentalCaseSheetArray[0].finalStatus);

					for (let i = 0; i < response.data.result.dentalCaseSheetArray.length; i++) {
						let textRedirect =
							moment(new Date(response.data.result.dentalCaseSheetArray[i].createdOn)).format('YYYY-MM-DD') ==
							moment(new Date()).format('YYYY-MM-DD');
						let drCheck = (response.data.result.dentalCaseSheetArray[i].doctorId == window.sessionStorage.getItem('patientDoctorId'))
						if (textRedirect === true && drCheck === true) {
							setRedirectState('text');
							setdoctorVisitId(response.data.result.dentalCaseSheetArray[i].doctorVisitId);
							return false;
						}
						else {
							setRedirectState('');
						}
					}

				}

			}
		})
			.catch();;
	};
	useEffect(() => {
		window.sessionStorage.setItem('editId', '');
		getSpecimenListingDataValues();
	}, []);
	const Edit = (data) => {
		// if(dentalCaseSheetArray.length == 0 ){
		// 	window.sessionStorage.setItem('historynumb', 0);
		// }else{
		// 	window.sessionStorage.setItem('historynumb', 1);
		// }
		window.location.href = '/patients/casesheetDentalAdd';
		window.sessionStorage.setItem('editId', data);
	};
	const printId = (data, medBrId, drId) => {
		window.sessionStorage.setItem('drId', drId);
		window.sessionStorage.setItem('printId', data);
		if (medBrId == 4) {
			window.open(
				`${process.env.PUBLIC_URL}/patients/casesheetDentalPrint/${window.sessionStorage.getItem('memberId') + '/' + drId + '/' + data + '/' + localStorage.getItem('hospitalId')} `,
				"_blank"
			);
		}
		else {
			window.open(
				`${process.env.PUBLIC_URL}/patients/casesheetMedicalPrint/${window.sessionStorage.getItem('memberId') + '/' + drId + '/' + data + '/' + localStorage.getItem('hospitalId')}`,
				"_blank"
			);
		}
	};
	const redirect = () => {
		// if(dentalCaseSheetArray.length == 0 ){
		// 	window.sessionStorage.setItem('historynumb', 0);
		// }else{
		// 	window.sessionStorage.setItem('historynumb', 1);
		// }
		if (redirectState === 'text') {
			window.location.href = '/patients/casesheetDentalAdd';
			window.sessionStorage.setItem('editId', doctorVisitId);
		} else {
			window.location.href = '/patients/casesheetDentalAdd';
		}
	};
	const deleteFunction = (divid, id) => {
		var x = false;
		x = window.confirm('Do you want to Delete?');
		if (x === true) {
			const postData = {
				"doctorVisitId": id,
				"userId": window.sessionStorage.getItem('userId')
			};
			callingAPI('commonUserFunctions/reviewTypdeleteClinicalNoteseView', postData).then((data) => {
				if (data.data.success === '1') {
					getSpecimenListingDataValues();
					alert("Successfully Deleted")
					drop(divid, id)
					window.location.reload()
				} else {
					alert(data.data.errorMessage);
				}
			})
				.catch();
		}
	};
	const drop = (divid, i) => {
		var elementDisplay = document.getElementById(divid + i);
		elementDisplay.style.display = (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divid + i,
			count: (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 0 : 1
		})
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divid + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{load === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>
												<span class="treatmentPlanBtn pl_blue_btn">
													{/* {moment(date).format('YYYY-MM-DD') ===
														moment(new Date()).format('YYYY-MM-DD') ? (
														<a onClick={(e) => Edit(dentalCaseSheetArray[0].doctorVisitId)}>
															+ Add
														</a>
													) : ( */}
													<a onClick={(e) => redirect()}>+ Add</a>
													{/* )} */}
												</span>
												{dentalCaseSheetArray.length > 0 ? (
													<span class="treatmentPlanBtn pl_blue_btn">
														<a href="/patients/casesheetDentalHistory">View History</a>
													</span>) : ""}
											</div>
											{dentalCaseSheetArray.length > 0 ? (
												<div>
													{dentalCaseSheetArray.map((item, i) => (
														<div class="treatmentPlanList1">
															<div class="treatmentPlanDate">{item.createdOn}</div>
															<div class="treatmentComplaintWrap">
																{item.caseSheet.map((data, j) => (
																	<div class="treatmentComplaintRowWrap">
																		<div class="treatmentComplaintRow">
																			<div class="treatmentComplaintLeft">
																				{data.title}
																			</div>
																			<div class="treatmentComplaintRight">
																				<span>
																					{data.tags ? (
																						<span class="treatmentTAgName ">
																							{data.tags.split(',').map((data1) => (
																								<div>{data1}</div>
																							))}
																						</span>) : ('')}
																					{data.sinceDate ? (
																						<span class="treatmentTAgDate">
																							{data.sinceDate}{' '}
																						</span>) : ('')}
																				</span>
																				{data.description ? (
																					<div>
																						<span style={{ fontWeight: 'bold' }}>
																							Description :
																						</span>
																						<span style={{ whiteSpace: 'pre-line' }}>
																							{data.description}
																							<br />
																						</span>
																					</div>
																				) : ('')}
																			</div>
																		</div>
																	</div>
																))}
																<div className="plcasesheet" >
																	<div class="dentalProNote" style={{ padding: '0px' }}>
																		<span class="elipsis" title="">
																			&nbsp;
																		</span>
																		<label class="dropdown">
																			<div class="dd-button" onClick={() => { drop("dropdownID", item.doctorVisitId) }} id="test" />
																			<input type="checkbox" class="dd-input" id="test" />
																			<ul class="dd-menu" id={"dropdownID" + item.doctorVisitId}>
																				{item.finalStatus == false ?
																					<div>
																						{item.doctorId == window.sessionStorage.getItem('patientDoctorId') ?
																							<a onClick={(e) => Edit(item.doctorVisitId)} style={{ textDecoration: 'none', color: 'black', }}>
																								<li>
																									<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																								</li>
																							</a> : ""}
																						<a onClick={(e) => printId(item.doctorVisitId, item.medicineBranchId, item.doctorId)}>
																							<li>
																								<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																							</li>
																						</a>
																						{item.doctorId == window.sessionStorage.getItem('patientDoctorId') ?
																							<a onClick={(e) => deleteFunction("dropdownID", item.doctorVisitId)}>
																								<li>
																									<img src={Delete} style={{ width: '15px', marginRight: '5px' }} />Delete
																								</li>
																							</a>
																							: ""}
																					</div>
																					:
																					<div>
																						<a onClick={(e) => printId(item.doctorVisitId, item.medicineBranchId, item.doctorId)}>
																							<li>
																								<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																							</li>
																						</a>
																					</div>
																				}
																			</ul>
																		</label>
																	</div>
																</div>
																<div class="treatmentComplaintNoted">
																	Noted by <span> {item.DoctorName}</span>
																</div>
															</div>
														</div>
													))}
												</div>
											) : (<div className="practiceManagementForm" style={{ border: '1px solid #dadada' }}>
												<div className="noDataFound">
													<p>No Case Sheet Found</p>
												</div>
											</div>)}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default CasesheetDental;
