import Logo from "../images/avatarHospitalDefault.png";
import { useEffect, useState } from "react";
import { callingAPI } from "../config";
import { useParams } from "react-router";
import Loader from "../Common/loader";
function PrescriptionBillPrint(props) {
  const [recieptType, setRecieptType] = useState("");
  const [memberDetails, setMemberDetails] = useState({});
  const [hospitalDetails, setHospitalDetails] = useState({});
  const [doctorDetails, setDoctorDetails] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [recieptList, setRecieptList] = useState({});
  const [prescriptionArray, setPrescriptionArray] = useState([]);
  const [procedureArray, setProcedureArray] = useState([]);
  const [inventoryArray, setInventoryArray] = useState([]);
  const [LabArray, setLabArray] = useState([]);
  const [taxPercentage, settaxPercentage] = useState([]);
  const [roomArray, setRoomArray] = useState([]);
  const [cafeteriaArray, setCafeteriaArray] = useState([]);
  const [laundryArray, setLaundryArray] = useState([]);
  const [getItem, setItem] = useState([]);
  const [load, setLoad] = useState(false);
  const [hospitalImagename, sethospitalImagename] = useState("");
  const [tax_amount, setTax_amount] = useState("");
  const [hospitalAddress, sethospitalAddress] = useState("");
  const [headertitle, setheadertitle] = useState("");
  var getS3folder =
    process.env.REACT_APP_S3_URI +
    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
    process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;

  useEffect(() => {
    setLoad(false);
    callingAPI("commonUserFunctions/headerFooterLogoPrints", {
      hospitalId: localStorage.getItem("hospitalId"),
      doctorId: "",
    }).then((res) => {
      sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
      setheadertitle(res.data.result.hospitalDetail[0].hospitalName);
      sethospitalAddress(res.data.result.hospitalDetail[0].hospitalAddress);
    });
    callingAPI("prescriptionBillReceiptPrint/prescriptionBillReceiptPrint", {
      receipt_id: window.sessionStorage.getItem("htmlDataprintid"),
    }).then((res) => {
      if (res.data.result.memberHospitalDetail.length !== 0) {
        setMemberDetails(res.data.result.memberHospitalDetail[0]);
        setHospitalDetails(res.data.result.memberHospitalDetail[0]);
      }

      setAdvanceAmount(res.data.result.advanceAmt);
      setDueAmount(res.data.result.due_amt);
      if (res.data.result.doctorDetail.length == "0") {
        setDoctorDetails("");
      } else {
        setDoctorDetails(res.data.result.doctorDetail[0].doctorDisplayName);
        setInvoiceDate(res.data.result.final_array[0].receipt_date);
      }
      if (res.data.result.final_array.length !== 0) {
        if (res.data.result.final_array[0].main[0].recieptList.length > 0) {
          setRecieptList(res.data.result.final_array[0].main[0].recieptList[0]);
          setLoad(true);
        }
      } else {
        setLoad(true);
      }

      if (
        res.data.result.receiptDetail[0].isRefund === 0 &&
        res.data.result.receiptDetail[0].isAdvance === 0
      ) {
        setRecieptType("Payment");
      } else if (res.data.result.receiptDetail[0].isRefund === 1) {
        setRecieptType("Refund");
      } else if (res.data.result.receiptDetail[0].isAdvance === 1) {
        setRecieptType("Advance");
      }
      if (res.data.result.final_array.length !== 0) {
        if (res.data.result.final_array[0].main[0].item.length > 0) {
          setPrescriptionArray(
            res.data.result.final_array[0].main[0].item[0].prescriptionArray
          );
          setTax_amount(
            res.data.result.final_array[0].main[0].item[0].prescriptionArray[0]
              .tax_amount
          );
          setProcedureArray(
            res.data.result.final_array[0].main[0].item[0].procedureArray
          );
          setInventoryArray(
            res.data.result.final_array[0].main[0].item[0].inventoryArray
          );
          setLabArray(res.data.result.final_array[0].main[0].item[0].labArray);
          setRoomArray(
            res.data.result.final_array[0].main[0].item[0].roomArray
          );
          setCafeteriaArray(
            res.data.result.final_array[0].main[0].item[0].cafeteriaArray
          );
          setLaundryArray(
            res.data.result.final_array[0].main[0].item[0].laundryArray
          );
          setItem(res.data.result.final_array[0].main[0].item);
          settaxPercentage(res.data.result.taxNameArray);
          setLoad(true);
        }
      } else {
        setLoad(true);
      }
    });
  }, [props]);

  const onPrintButtonClick = () => {
    // document.getElementById('cancel').style.display = 'none';
    document.getElementById("print").style.display = "none";
    window.print();
    // document.getElementById('cancel').style.display = 'inline-block';
    document.getElementById("print").style.display = "inline-block";
  };
  // const back = () => {
  // 	window.location.href = '/reports/refund';
  // };

  return (
    <div className="receiptPrint">
      {window.sessionStorage.getItem("patientDoctorSpecialation") ===
        "Dentist" ? (
        <div>
          {load === false ? <Loader /> : ""}
          <div align="center">
            <div
              align="center"
              style={{
                width: "540px",
                height: "595px",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              <table style={{ width: "100%", marginTop: "0px" }} border="0">
                <thead>
                  <tr>
                    <th style={{ fontWeight: "400" }}>
                      <div class="printOutHeaderSec ">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr height="80px">
                              <td colspan="4" valign="middle" align="center">
                                <div style={{ width: "13%", float: "left" }}>
                                  <p
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      clear: "both",
                                      width: "100%;",
                                    }}
                                  >
                                    <span
                                      style={{
                                        marginTop: "-5px",
                                        display: "inline-block",
                                        width: "100%",
                                      }}
                                    >
                                      {hospitalImagename != (null || "") ? (
                                        <img
                                          src={
                                            getS3folder +
                                            "/" +
                                            localStorage.getItem("hospitalId") +
                                            ".png"
                                          }
                                          style={{
                                            display: "block",
                                            width: "auto",
                                            height: "45px ",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={Logo}
                                          style={{
                                            display: "block",
                                            width: "auto",
                                            height: "45px ",
                                          }}
                                        />
                                      )}
                                    </span>
                                  </p>
                                </div>

                                <div
                                  class="practicePrintName "
                                  style={{ width: "87%", float: "left" }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      clear: "both",
                                      width: "100%",
                                      marginBottom: "1px",
                                      marginTop: "0",
                                    }}
                                  >
                                    {headertitle}
                                  </p>

                                  <p
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "12px !important",
                                      clear: "both",
                                      width: "100%",
                                      margin: "0",
                                    }}
                                  >
                                    {" "}
                                    {hospitalAddress}
                                  </p>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <hr
                                  color="#142536"
                                  style={{
                                    margin: " 0",
                                    height: "2px",
                                    opacity: "1",
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                width="50%"
                                colspan="2"
                                align="left"
                                style={{
                                  fontSize: "12px",
                                  wordBreak: "break-all",
                                }}
                              />
                              <td
                                width="50%"
                                align="right"
                                style={{
                                  fontSize: "12px",
                                  wordBreak: "break-all",
                                }}
                              />
                            </tr>

                            <tr>
                              <td
                                colspan="2"
                                style={{
                                  width: "50%",
                                  fontSize: "12px",
                                  padding: "0",
                                  lineHeight: "17px",
                                  textAlign: "left",
                                }}
                              >
                                Patient :{" "}
                                {memberDetails.memberFirstName == ""
                                  ? memberDetails.memberDisplayName
                                  : memberDetails.memberFirstName +
                                  " " +
                                  memberDetails.memberLastName}
                              </td>
                              {doctorDetails !== "" ? (
                                <td
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Doctor :{doctorDetails}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                            <tr>
                              {memberDetails !== "" ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "15px",
                                    textAlign: "left",
                                  }}
                                >
                                  Age :{" "}
                                  {memberDetails.memberAge > 0
                                    ? memberDetails.memberAge
                                    : ""}
                                </td>
                              ) : (
                                ""
                              )}
                              {invoiceDate !== "" ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Receipt Date : {invoiceDate}{" "}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                            <tr>
                          
                              {/* {memberDetails !== "" ? (
                                window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                  (<td
                                    colspan="2"
                                    style={{
                                      width: "50%",
                                      fontSize: "12px",
                                      padding: "0",
                                      lineHeight: "17px",
                                      textAlign: "left",
                                    }}
                                  >
                                    MR No : {memberDetails.mrNo}
                                  </td>) :
                                  (<td
                                    colspan="2"
                                    style={{
                                      width: "50%",
                                      fontSize: "12px",
                                      padding: "0",
                                      lineHeight: "17px",
                                      textAlign: "left",
                                    }}
                                  >
                                    PR No : {memberDetails.prNo}
                                  </td>)

                              ) : (
                                ""
                              )} */}
                               {memberDetails !== "" ? (
                                window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                  (<td
                                    colspan="2"
                                    style={{
                                      width: "50%",
                                      fontSize: "12px",
                                      padding: "0",
                                      lineHeight: "17px",
                                      textAlign: "left",
                                    }}
                                  >
                                    MR No : {memberDetails.mrNo}
                                  </td>) :
                                  (<td
                                    colspan="2"
                                    style={{
                                      width: "50%",
                                      fontSize: "12px",
                                      padding: "0",
                                      lineHeight: "17px",
                                      textAlign: "left",
                                    }}
                                  >
                                    PR No : {memberDetails.prNo}
                                  </td>)

                              ) : (
                                ""
                              )}
                              {memberDetails !== "" ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Phone No : {memberDetails.SecuserMobileNumber}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                            <tr>
                              {memberDetails !== "" ? (
                                <td
                                  colspan="1"
                                  style={{
                                    width: "40%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "left",
                                  }}
                                >
                                  Sex : {memberDetails.memberGender}
                                </td>
                              ) : (
                                ""
                              )}
                              {memberDetails !== "" ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "80%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Email Id : {memberDetails.secUserEmailid}{" "}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>

                            <tr>
                              <td colspan="4">
                                <hr
                                  color="#142536"
                                  style={{
                                    margin: "0",
                                    height: "2px",
                                    opacity: "1",
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table width="100%" cellspacing="0">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "14px",
                                  paddingBottom: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {recieptType}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  paddingBottom: "10px",
                                  textAlign: "right",
                                }}
                              >
                                Receipt Number :&nbsp;{" "}
                                {recieptList.receiptNumber}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {getItem.length > 0 ? (
                        <div class="printOutSettingContent">
                          <table width="100%" cellspacing="0">
                            <tbody>
                              {/* {data.procedureArray.map( */}
                              <tr
                                style={{
                                  fontSize: "12px",
                                  color: "#393939",
                                  background: " #eae8e8",
                                }}
                              >
                                <td
                                  style={{
                                    boxSizing: " border-box",
                                    padding: " 5px 2px",
                                    width: "14%",
                                    textAlign: "left",
                                    fontWeight: "600",
                                    fontSize: "10px",
                                    paddingLeft: "3px",
                                  }}
                                >
                                  INVOICE
                                </td>
                                <td
                                  style={{
                                    boxSizing: "border-box",
                                    padding: "5px 2px",
                                    width: "90%",
                                    textAlign: "left",
                                    position: "relative",
                                    fontSize: "10px",
                                  }}
                                >
                                  <div
                                    class="procedureCode"
                                    style={{ fontSize: "10px !important" }}
                                  >
                                    <div
                                      class="procedureCodeHead"
                                      style={{ fontWeight: "600" }}
                                    >
                                      <div
                                        style={{
                                          width: "18%",
                                          display: "inline-block",
                                          textAlign: "left",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        TOWARDS
                                      </div>
                                      <div
                                        style={{
                                          width: "15%",
                                          display: "inline-block",
                                          textAlign: "left",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Batch
                                      </div>
                                      <div
                                        style={{
                                          width: "7%",
                                          display: "inline-block",
                                          textAlign: "left",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Exp
                                      </div>
                                      <div
                                        style={{
                                          width: "7%",
                                          display: "inline-block",
                                          textAlign: "center",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Qty
                                      </div>
                                      <div
                                        style={{
                                          width: "13%",
                                          display: "inline-block",
                                          textAlign: "right",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Unit Price
                                      </div>
                                      <div
                                        style={{
                                          width: "13%",
                                          display: "inline-block",
                                          textAlign: "right",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Cost
                                      </div>
                                      <div
                                        style={{
                                          width: "8%",
                                          display: "inline-block",
                                          textAlign: "right",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Tax
                                      </div>
                                      <div
                                        style={{
                                          width: "13%",
                                          display: "inline-block",
                                          textAlign: "right",
                                          verticalAlign: "top",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Total
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {/* // )} */}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  {/* {recieptType === 'Payment' && ( */}
                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table
                          width="100%"
                          cellspacing="0"
                          className="receiptprintInnerTable"
                        >
                          {getItem.map((data, i) => (
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    boxSizing: "border-box",
                                    padding: "5px 2px",
                                    width: "14%",
                                    textAlign: "left",
                                    fontSize: "10px",
                                    color: "#393939",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {data.invoiceNumber}
                                </td>
                                <td>
                                  {data.prescriptionArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}

                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Prescription
                                            </div>
                                          </div>

                                          <div>
                                            {data.prescriptionArray.map(
                                              (details) => (
                                                <div class="procedureCodeValue">
                                                  <div
                                                    style={{
                                                      width: "18%",
                                                      display: "inline-block",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.item_name}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "15%",
                                                      display: "inline-block",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.batch
                                                      ? details.batch
                                                      : "-"}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "7%",
                                                      display: "inline-block",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.expire
                                                      ? details.expire
                                                      : "-"}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "7%",
                                                      display: "inline-block",
                                                      textAlign: "center",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.quantity}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "13%",
                                                      display: "inline-block",
                                                      textAlign: "right",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.unit_price}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "13%",
                                                      display: "inline-block",
                                                      textAlign: "right",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.cost}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "8%",
                                                      display: "inline-block",
                                                      textAlign: "right",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.tax_amount}
                                                  </div>

                                                  <div
                                                    style={{
                                                      width: "13%",
                                                      display: "inline-block",
                                                      textAlign: "right",
                                                      verticalAlign: "top",
                                                    }}
                                                  >
                                                    {details.total_amount}
                                                  </div>
                                                  {details.hsnCode != null && (
                                                    <div class="procedureCodeIntake">
                                                      <strong>
                                                        HSN Code :{" "}
                                                      </strong>
                                                      <span>
                                                        {details.hsnCode}
                                                      </span>
                                                    </div>
                                                  )}

                                                  <div class="procedureCodeIntake">
                                                    <strong>Intake : </strong>
                                                    <span>
                                                      {details.instructions} (
                                                      {details.intake})
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          {/* : "" } */}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.procedureArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>

																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Procedure
                                            </div>
                                          </div>
                                          {data.procedureArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.inventoryArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Inventory
                                            </div>
                                          </div>
                                          {data.inventoryArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.labArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          </div>
                                          {data.labArray.map((details) => (
                                            <div class="procedureCodeValue">
                                              <div
                                                style={{
                                                  width: "18%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.item_name}
                                              </div>
                                              <div
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.batch
                                                  ? details.batch
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.expire
                                                  ? details.expire
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "center",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.quantity}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.unit_price}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.cost}
                                              </div>
                                              <div
                                                style={{
                                                  width: "8%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.tax_amount}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.total_amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.roomArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Room
                                            </div>
                                          </div>
                                          {data.roomArray.map((details) => (
                                            <div class="procedureCodeValue">
                                              <div
                                                style={{
                                                  width: "18%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.item_name}
                                              </div>
                                              <div
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.batch
                                                  ? details.batch
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.expire
                                                  ? details.expire
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "center",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.quantity}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.unit_price}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.cost}
                                              </div>
                                              <div
                                                style={{
                                                  width: "8%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.tax_amount}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.total_amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )}

                                  {data.cafeteriaArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Cafeteria
                                            </div>
                                          </div>
                                          {data.cafeteriaArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.laundryArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Laundry
                                            </div>
                                          </div>
                                          {data.laundryArray.map((details) => (
                                            <div class="procedureCodeValue">
                                              <div
                                                style={{
                                                  width: "18%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.item_name}
                                              </div>
                                              <div
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.batch
                                                  ? details.batch
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.expire
                                                  ? details.expire
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "center",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.quantity}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.unit_price}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.cost}
                                              </div>
                                              <div
                                                style={{
                                                  width: "8%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.tax_amount}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.total_amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </td>
                  </tr>
                  {/*  */}
                  <tr>
                    <td>
                      <div
                        align="justify"
                        style={{ fontSize: "12px", fontFamily: "Arial" }}
                      >
                        Note : Partial refund
                      </div>
                    </td>
                  </tr>
                  {/*  */}

                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table width="100%" cellspacing="0">
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  class="taxValueTable"
                                  style={{
                                    width: "40%",
                                    marginTop: "-6px",
                                    display: "inline-block",
                                    boxSizing: "border-box",
                                    verticalAlign: "top",
                                    color: "#393939",
                                  }}
                                />

                                <div
                                  style={{
                                    width: "60%",
                                    display: "inline-block",
                                    boxSizing: "border-box",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {recieptType === "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Total amount (INR) :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {recieptList.total_amount}
                                      </span>
                                    </div>
                                  )}

                                  <div
                                    class="estimatedPrice"
                                    style={{
                                      textAlign: "right",
                                      fontSize: "13px",
                                      paddingTop: "6px",
                                      color: "#4a4a4a",
                                    }}
                                  >
                                    Amount{" "}
                                    {recieptType === "Refund"
                                      ? "refunded"
                                      : "paid"}{" "}
                                    (INR) :&nbsp;
                                    <span
                                      style={{
                                        width: "75px",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      {" "}
                                      {recieptList.amount}
                                    </span>
                                  </div>
                                  {/* {recieptType === 'Payment' && (
																		<div
																			class="estimatedPrice"
																			style={{
																				textAlign: 'right',
																				fontSize: '13px',
																				paddingTop: '6px',
																				color: '#4a4a4a',
																			}}
																		>
																			CGST (INR):&nbsp;<span
																				style={{
																					width: '75px',
																					display: 'inline-block',
																					verticalAlign: 'top',
																				}}
																			>
																				{' '}
																				{recieptList.amount}
																			</span>
																		</div>
																	)} */}

                                  {recieptType !== "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Amount from Advance (INR) :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {recieptList.amountFromAdvance}
                                      </span>
                                    </div>
                                  )}

                                  <div
                                    class="estimatedPrice"
                                    style={{
                                      textAlign: "right",
                                      fontSize: "13px",
                                      paddingTop: "6px",
                                      color: "#4a4a4a",
                                    }}
                                  >
                                    Mode of payment :&nbsp;
                                    <span
                                      style={{
                                        width: "75px",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      {" "}
                                      {recieptList.paymentMode}
                                    </span>
                                  </div>
                                  {recieptType !== "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {" "}
                                      {recieptType !== "Payment" && (
                                        <span>
                                          Towards :&nbsp;
                                          <span
                                            style={{
                                              width: "75px",
                                              display: "inline-block",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            {" "}
                                            {recieptType === "Refund"
                                              ? "Refund"
                                              : "Advance"}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  )}

                                  {recieptType === "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Total due Amount :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {parseFloat(dueAmount).toFixed(2)}
                                      </span>
                                    </div>
                                  )}

                                  {recieptType !== "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {" "}
                                      {/* {recieptType !== 'Payment' && ( */}
                                      <span>
                                        Advance amount :&nbsp;
                                        <span
                                          style={{
                                            width: "75px",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          {" "}
                                          {parseFloat(advanceAmount).toFixed(2)}
                                        </span>
                                      </span>
                                      {/* )} */}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      {/* {getItem.length > 0 ? */}
                      <div class="printOutFooterSec">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  align="justify"
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                  }}
                                >
                                  {hospitalDetails.hospitalEmail}
                                </div>
                              </td>
                              <td align="right" style={{ fontSize: "10px" }}>
                                Dr.{doctorDetails}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <div align="justify" class="practiceFootBtm">
                                  Thank you
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <div align="justify" class="practiceFootBtm">
                                  {/* Workingtime */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" style={{ paddingTop: "10px" }} />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* // : "" } */}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="printBtm" style={{ width: "595px", margin: "auto" }}>
            <p
              onClick={onPrintButtonClick}
              id="print"
              style={{
                width: "70px",
                textAlign: "center",
                paddingTop: "5px",
                lineHeight: "18px",
                display: "inline-block",
                fontWeight: "bold",
                fontSize: "14px",
                color: "white",
                background: "rebeccapurple none repeat scroll 0% 0%",
                marginTop: "2px",
                cursor: "pointer",
                borderRadius: "5px",
                marginBottom: "2px",
                height: "25px",
                position: "absolute",
                right: "-27px",
                fontFamily: "  Verdana",
                top: "110px",
              }}
            >
              Print
            </p>
            &nbsp;
            {/* <p
									id="cancel"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
									}}
									onClick={back}
								>
									Back
								</p> */}
          </div>
        </div>
      ) : (
        <div>
          {load === false ? <Loader /> : ""}
          <div align="center">
            <div
              align="center"
              style={{
                width: "540px",
                height: "595px",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              <table style={{ width: "100%", marginTop: "0px" }} border="0">
                <thead>
                  <tr>
                    <th style={{ fontWeight: "400" }}>
                      <div class="printOutHeaderSec ">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr height="80px">
                              <td colspan="4" valign="middle" align="center">
                                <div style={{ width: "13%", float: "left" }}>
                                  <p
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      clear: "both",
                                      width: "100%;",
                                    }}
                                  >
                                    <span
                                      style={{
                                        marginTop: "-5px",
                                        display: "inline-block",
                                        width: "100%",
                                      }}
                                    >
                                      {hospitalImagename != (null || "") ? (
                                        <img
                                          src={
                                            getS3folder +
                                            "/" +
                                            localStorage.getItem("hospitalId") +
                                            ".png"
                                          }
                                          style={{
                                            display: "block",
                                            width: "auto",
                                            height: "45px ",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={Logo}
                                          style={{
                                            display: "block",
                                            width: "auto",
                                            height: "45px ",
                                          }}
                                        />
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  class="practicePrintName "
                                  style={{ width: "87%", float: "left" }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      clear: "both",
                                      width: "100%",
                                      marginBottom: "1px",
                                      marginTop: "0",
                                    }}
                                  >
                                    {headertitle}
                                  </p>

                                  <p
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "12px !important",
                                      clear: "both",
                                      width: "100%",
                                      margin: "0",
                                    }}
                                  >
                                    {" "}
                                    {hospitalAddress}
                                  </p>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <hr
                                  color="#142536"
                                  style={{
                                    margin: " 0",
                                    height: "2px",
                                    opacity: "1",
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                width="50%"
                                colspan="2"
                                align="left"
                                style={{
                                  fontSize: "12px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {" "}
                                {/* {hospitalDetails.hospitalHeaderLeftText} */}
                              </td>
                              <td
                                width="50%"
                                align="right"
                                style={{
                                  fontSize: "12px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {" "}
                                {/* {hospitalDetails.hospitalHeaderRightText} */}
                                {/* 123/12, Behind SBT, Jubilee Hills, Kottayam, India */}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4">
                                <hr
                                  color="#142536"
                                  style={{
                                    margin: " 0",
                                    height: "2px",
                                    opacity: "1",
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              {memberDetails.length !== 0 ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "left",
                                  }}
                                >
                                  Patient :{" "}
                                  {memberDetails.memberFirstName == ""
                                    ? memberDetails.memberDisplayName
                                    : memberDetails.memberFirstName}{" "}
                                  {memberDetails.memberLastName}
                                </td>
                              ) : (
                                ""
                              )}

                              {doctorDetails !== "" ? (
                                <td
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Doctor :{doctorDetails}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                            <tr>
                              {memberDetails.length !== 0 ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "15px",
                                    textAlign: "left",
                                  }}
                                >
                                  Age :{" "}
                                  {memberDetails.memberAge > 0
                                    ? memberDetails.memberAge
                                    : ""}
                                </td>
                              ) : (
                                ""
                              )}
                              {invoiceDate !== "" ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Receipt Date : {invoiceDate}{" "}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                            <tr>
                              {memberDetails.length !== 0 ? (
                                window.sessionStorage.getItem('isPrNumEnabled') === false ?
                                  (<td
                                    colspan="2"
                                    style={{
                                      width: "50%",
                                      fontSize: "12px",
                                      padding: "0",
                                      lineHeight: "17px",
                                      textAlign: "left",
                                    }}
                                  >
                                    MR No : {memberDetails.mrNo}
                                  </td>)
                                  :
                                  (<td
                                    colspan="2"
                                    style={{
                                      width: "50%",
                                      fontSize: "12px",
                                      padding: "0",
                                      lineHeight: "17px",
                                      textAlign: "left",
                                    }}
                                  >
                                    PR No : {memberDetails.prNo}
                                  </td>)

                              ) : (
                                ""
                              )}
                              {memberDetails.length !== 0 ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "50%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Phone No : {memberDetails.SecuserMobileNumber}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                            <tr>
                              {memberDetails.length !== 0 ? (
                                <td
                                  colspan="1"
                                  style={{
                                    width: "40%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "left",
                                  }}
                                >
                                  Sex : {memberDetails.memberGender}
                                </td>
                              ) : (
                                ""
                              )}
                              {memberDetails.length !== 0 ? (
                                <td
                                  colspan="2"
                                  style={{
                                    width: "80%",
                                    fontSize: "12px",
                                    padding: "0",
                                    lineHeight: "17px",
                                    textAlign: "right",
                                  }}
                                >
                                  Email Id : {memberDetails.secUserEmailid}{" "}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>

                            <tr>
                              <td colspan="4">
                                <hr
                                  color="#142536"
                                  style={{
                                    margin: "0",
                                    height: "2px",
                                    opacity: "1",
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table width="100%" cellspacing="0">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "14px",
                                  paddingBottom: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {recieptType}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  paddingBottom: "10px",
                                  textAlign: "right",
                                }}
                              >
                                Receipt Number :&nbsp;{" "}
                                {recieptList.receiptNumber}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>

                  {/* {recieptType === 'Refund' && ( */}
                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table width="100%" cellspacing="0">
                          <tbody>
                            <tr
                              style={{
                                fontSize: "12px",
                                color: "#393939",
                                background: " #eae8e8",
                              }}
                            >
                              <td
                                style={{
                                  boxSizing: " border-box",
                                  padding: " 5px 2px",
                                  width: "14%",
                                  textAlign: "left",
                                  fontWeight: "600",
                                  fontSize: "10px",

                                  paddingLeft: "3px",
                                }}
                              >
                                INVOICE
                              </td>
                              <td
                                style={{
                                  boxSizing: "border-box",
                                  padding: "5px 2px",
                                  width: "90%",
                                  textAlign: "left",
                                  position: "relative",
                                  fontSize: "10px",
                                }}
                              >
                                <div
                                  class="procedureCode"
                                  style={{ fontSize: "10px !important" }}
                                >
                                  <div
                                    class="procedureCodeHead"
                                    style={{ fontWeight: "600" }}
                                  >
                                    <div
                                      style={{
                                        width: "18%",
                                        display: "inline-block",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      TOWARDS
                                    </div>
                                    <div
                                      style={{
                                        width: "15%",
                                        display: "inline-block",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Batch
                                    </div>
                                    <div
                                      style={{
                                        width: "7%",
                                        display: "inline-block",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Exp
                                    </div>
                                    <div
                                      style={{
                                        width: "7%",
                                        display: "inline-block",
                                        textAlign: "center",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Qty
                                    </div>
                                    <div
                                      style={{
                                        width: "13%",
                                        display: "inline-block",
                                        textAlign: "right",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Unit Price
                                    </div>
                                    <div
                                      style={{
                                        width: "13%",
                                        display: "inline-block",
                                        textAlign: "right",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Cost
                                    </div>
                                    <div
                                      style={{
                                        width: "8%",
                                        display: "inline-block",
                                        textAlign: "right",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Tax
                                    </div>
                                    <div
                                      style={{
                                        width: "13%",
                                        display: "inline-block",
                                        textAlign: "right",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Total
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  {/* )} */}

                  {/* {recieptType === 'Payment' && ( */}
                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table
                          width="100%"
                          cellspacing="0"
                          className="receiptprintInnerTable"
                        >
                          {getItem.map((data, i) => (
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    boxSizing: "border-box",
                                    padding: "5px 2px",
                                    width: "14%",
                                    textAlign: "left",
                                    fontSize: "10px",
                                    color: "#393939",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {data.invoiceNumber}
                                </td>
                                <td>
                                  {data.prescriptionArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}

                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Prescription
                                            </div>
                                          </div>
                                          {data.prescriptionArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                                {details.hsnCode != "null" && (
                                                  <div class="procedureCodeIntake">
                                                    <strong>HSN Code : </strong>
                                                    <span>
                                                      {details.hsnCode}
                                                    </span>
                                                  </div>
                                                )}

                                                <div class="procedureCodeIntake">
                                                  <strong>Intake : </strong>
                                                  <span>
                                                    {details.instructions} (
                                                    {details.intake})
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.procedureArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>

																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Procedure
                                            </div>
                                          </div>
                                          {data.procedureArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.inventoryArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Inventory
                                            </div>
                                          </div>
                                          {data.inventoryArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.labArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          </div>
                                          {data.labArray.map((details) => (
                                            <div class="procedureCodeValue">
                                              <div
                                                style={{
                                                  width: "18%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.item_name}
                                              </div>
                                              <div
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.batch
                                                  ? details.batch
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.expire
                                                  ? details.expire
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "center",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.quantity}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.unit_price}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.cost}
                                              </div>
                                              <div
                                                style={{
                                                  width: "8%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.tax_amount}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.total_amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.roomArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Room
                                            </div>
                                          </div>
                                          {data.roomArray.map((details) => (
                                            <div class="procedureCodeValue">
                                              <div
                                                style={{
                                                  width: "18%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.item_name}
                                              </div>
                                              <div
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.batch
                                                  ? details.batch
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.expire
                                                  ? details.expire
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "center",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.quantity}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.unit_price}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.cost}
                                              </div>
                                              <div
                                                style={{
                                                  width: "8%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.tax_amount}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.total_amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.cafeteriaArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Cafeteria
                                            </div>
                                          </div>
                                          {data.cafeteriaArray.map(
                                            (details) => (
                                              <div class="procedureCodeValue">
                                                <div
                                                  style={{
                                                    width: "18%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.item_name}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.batch
                                                    ? details.batch
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.expire
                                                    ? details.expire
                                                    : "-"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "7%",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.quantity}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.unit_price}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.cost}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "8%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.tax_amount}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "13%",
                                                    display: "inline-block",
                                                    textAlign: "right",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {details.total_amount}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {data.laundryArray.length > 0 && (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <td
																		style={{
																			boxSizing: 'border-box',
																			padding: '5px 2px',
																			width: '10%',
																			display: 'inline-block',
																			textAlign: 'left',
																			fontSize: '10px',
																			color: '#393939',
																			lineHeight: '18px',
																		}}
																	>
																		{data.invoiceNumber}
																	</td> */}
                                      <td
                                        class="inoviceBorder"
                                        style={{
                                          verticalAlign: "top !important",
                                          boxSizing: "border-box",
                                          padding: "5px 2px",
                                          width: "90%",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          color: "#393939",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <div
                                          class="procedureCode"
                                          style={{
                                            fontSize: "10px !important",
                                            marginTop: "0",

                                            paddingBottom: "2px",
                                            marginBottom: "2px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <div
                                            class="procedureCodeHead"
                                            style={{
                                              fontWeight: "600",
                                              paddingBottom: "3px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "18%",
                                                display: "inline-block",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Laundry
                                            </div>
                                          </div>
                                          {data.laundryArray.map((details) => (
                                            <div class="procedureCodeValue">
                                              <div
                                                style={{
                                                  width: "18%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.item_name}
                                              </div>
                                              <div
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.batch
                                                  ? details.batch
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.expire
                                                  ? details.expire
                                                  : "-"}
                                              </div>
                                              <div
                                                style={{
                                                  width: "7%",
                                                  display: "inline-block",
                                                  textAlign: "center",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.quantity}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.unit_price}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.cost}
                                              </div>
                                              <div
                                                style={{
                                                  width: "8%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.tax_amount}
                                              </div>
                                              <div
                                                style={{
                                                  width: "13%",
                                                  display: "inline-block",
                                                  textAlign: "right",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {details.total_amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </td>
                  </tr>
                  {/* )} */}

                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table width="100%" cellspacing="0">
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  class="taxValueTable"
                                  style={{
                                    width: "40%",
                                    marginTop: "-6px",
                                    display: "inline-block",
                                    boxSizing: "border-box",
                                    verticalAlign: "top",
                                    color: "#393939",
                                  }}
                                >
                                  {/*  */}
                                  {tax_amount > 0.0 ? (
                                    <div
                                      style={{
                                        boxSizing: "border-box",
                                        fontSize: "0px",
                                        border: "1px solid #ddd",
                                        display: "inline-block",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          display: "inline-block",
                                          textAlign: "left",
                                          verticalAlign: "top",
                                          fontSize: "11px",
                                          boxSizing: " border-box",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "40px",
                                            boxSizing: "border-box",
                                            fontWeight: "600",
                                            borderBottom: "1px solid #ddd",
                                            padding: "5px 2px 5px 4px",
                                          }}
                                        >
                                          Tax (%)
                                        </div>

                                        {prescriptionArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                        {procedureArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                        {inventoryArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                        {LabArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                        {roomArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                        {cafeteriaArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                        {laundryArray.length > 0 &&
                                          taxPercentage.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: "5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.taxPercentage}
                                            </div>
                                          ))}
                                      </div>

                                      <div
                                        style={{
                                          width: "48%",
                                          display: "inline-block",
                                          textAlign: "right",
                                          verticalAlign: "top",
                                          fontSize: "11px",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "40px",
                                            boxSizing: "border-box",
                                            fontWeight: "600",
                                            borderBottom: "1px solid #ddd",
                                            padding: "5px 2px 5px 4px",
                                          }}
                                        >
                                          Taxable Amt
                                        </div>

                                        {prescriptionArray.length > 0 &&
                                          prescriptionArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                        {procedureArray.length > 0 &&
                                          procedureArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                        {inventoryArray.length > 0 &&
                                          inventoryArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                        {LabArray.length > 0 &&
                                          LabArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                        {roomArray.length > 0 &&
                                          roomArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                        {cafeteriaArray.length > 0 &&
                                          cafeteriaArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                        {laundryArray.length > 0 &&
                                          laundryArray.map((item) => (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "top",
                                                borderBottom: "1px solid #ddd",
                                                padding: " 5px 2px 5px 4px",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              {item.cost}
                                            </div>
                                          ))}
                                      </div>
                                      {/* <div style={{ width: "23%", display: "inline-block", textAlign: "right", verticalAlign: "top", fontSize: "11px", boxSizing: "border-box" }}>
																				<div style={{ height: "40px", boxSizing: "border-box", fontWeight: "600", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px" }}></div>


																				<div style={{ textAlign: "right", verticalAlign: "top", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px", boxSizing: "border-box" }}></div>
					 															</div> */}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {/*  */}
                                </div>

                                <div
                                  style={{
                                    width: "60%",
                                    display: "inline-block",
                                    boxSizing: "border-box",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {recieptType === "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Total amount (INR) :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {recieptList.total_amount}
                                      </span>
                                    </div>
                                  )}
                                  {recieptList.discount_amount > 0 && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Total discount amount (INR) :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {recieptList.discount_amount}
                                      </span>
                                    </div>
                                  )}

                                  {recieptList.amount > 0 && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Amount{" "}
                                      {recieptType === "Refund"
                                        ? "refunded"
                                        : "paid"}{" "}
                                      (INR) :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {recieptList.amount}
                                      </span>
                                    </div>
                                  )}
                                  {/* {recieptType === 'Payment' && (
																		<div
																			class="estimatedPrice"
																			style={{
																				textAlign: 'right',
																				fontSize: '13px',
																				paddingTop: '6px',
																				color: '#4a4a4a',
																			}}
																		>
																			CGST (INR):&nbsp;<span
																				style={{
																					width: '75px',
																					display: 'inline-block',
																					verticalAlign: 'top',
																				}}
																			>
																				{' '}
																				{recieptList.amount}
																			</span>
																		</div>
																	)} */}

                                  {recieptType === "Payment" &&
                                    recieptList.amountFromAdvance > 0 && (
                                      <div
                                        class="estimatedPrice"
                                        style={{
                                          textAlign: "right",
                                          fontSize: "13px",
                                          paddingTop: "6px",
                                          color: "#4a4a4a",
                                        }}
                                      >
                                        Amount from Advance (INR) :&nbsp;
                                        <span
                                          style={{
                                            width: "75px",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          {" "}
                                          {recieptList.amountFromAdvance}
                                        </span>
                                      </div>
                                    )}

                                  <div
                                    class="estimatedPrice"
                                    style={{
                                      textAlign: "right",
                                      fontSize: "13px",
                                      paddingTop: "6px",
                                      color: "#4a4a4a",
                                    }}
                                  >
                                    Mode of payment :&nbsp;
                                    <span
                                      style={{
                                        width: "75px",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      {" "}
                                      {recieptList.paymentMode}
                                    </span>
                                  </div>
                                  {recieptType !== "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {" "}
                                      {recieptType !== "Payment" && (
                                        <span>
                                          Towards :&nbsp;
                                          <span
                                            style={{
                                              width: "75px",
                                              display: "inline-block",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            {" "}
                                            {recieptType === "Refund"
                                              ? "Refund"
                                              : "Advance"}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  )}

                                  {recieptType === "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      Total due Amount :&nbsp;
                                      <span
                                        style={{
                                          width: "75px",
                                          display: "inline-block",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {" "}
                                        {parseFloat(dueAmount).toFixed(2)}
                                      </span>
                                    </div>
                                  )}

                                  {recieptType === "Payment" && (
                                    <div
                                      class="estimatedPrice"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "13px",
                                        paddingTop: "6px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {" "}
                                      {/* {recieptType !== 'Payment' && ( */}
                                      <span>
                                        Advance amount :&nbsp;
                                        <span
                                          style={{
                                            width: "75px",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          {" "}
                                          {parseFloat(advanceAmount).toFixed(2)}
                                          {/* {advanceAmount} */}
                                        </span>
                                      </span>
                                      {/* )} */}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <div class="printOutFooterSec">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                width="25%"
                                colspan="2"
                                align="left"
                                style={{ wordBreak: "break-all" }}
                              >
                                <div
                                  align="justify"
                                  style={{ fontSize: "10px" }}
                                >
                                  {hospitalDetails.hospitalEmail}
                                </div>
                              </td>
                              <td
                                colspan="2"
                                width="50%"
                                align="right"
                                style={{
                                  fontSize: "10px",
                                  wordBreak: " break-all",
                                }}
                              >
                                Dr.{doctorDetails}
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <div align="justify" class="practiceFootBtm">
                                  {/* Workingtime */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" style={{ paddingTop: "10px" }} />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="printBtm" style={{ width: "595px", margin: "auto" }}>
            <p
              onClick={onPrintButtonClick}
              id="print"
              style={{
                width: "70px",
                textAlign: "center",
                paddingTop: "5px",
                lineHeight: "18px",
                display: "inline-block",
                fontWeight: "bold",
                fontSize: "14px",
                color: "white",
                background: "rebeccapurple none repeat scroll 0% 0%",
                marginTop: "2px",
                cursor: "pointer",
                borderRadius: "5px",
                marginBottom: "2px",
                height: "25px",
                position: "absolute",
                right: "80px",
                fontFamily: "  Verdana",
              }}
            >
              Print
            </p>
            &nbsp;
            {/* <p
									id="cancel"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
									}}
									onClick={back}
								>
									Back
								</p> */}
          </div>
        </div>
      )}
    </div>
  );
}
export default PrescriptionBillPrint;
