import React, { useEffect, useState } from 'react';
import { callingAPI, callingAPI_GET } from '../config';
function Educationaldetails(props) {
	const [graduationCategory, setGraduationCategory] = useState([]);
	const [medicalRegistrationStateValues, setStateData] = useState([]);
	const [graduationName, setGraduationName] = useState(0);
	const [graduationYear, setGraduationYear] = useState(0);
	const [graduationCollege, setGraduationCollege] = useState('');
	const [yearValues, setYearvalues] = useState([]);
	const [pgYearValues, setYearpgvalues] = useState(0);
	const [dataflag, setdataflag] = useState(false);
	const [pgGraduationName, setPGGraduationName] = useState(0);
	const [pgGraduationYear, setPGGraduationYear] = useState(0);
	const [pgGraduationCollege, setPGGraduationCollege] = useState('');
	const [medicalRegistrationState, setMedRegistrationState] = useState('');
	const [medicalRegistrationNo, setMedicalRegistrationNo] = useState('');
	const [superSpecializationType, setSuperSpecializationType] = useState(0);
	const [superSpecializationYear, setSuperSpecializationYear] = useState('');
	const [superSpecializationCollege, setSuperSpecializationCollege] = useState('');
	const [displayErrMsg1, setDisplayErrMsg1] = useState('');
	const saveEducationDetails = () => {
		setdataflag(false)
		var educationCategoryIds = [];
		var yearIds = [];
		var CollegeNames = [];
		educationCategoryIds.push(graduationName, pgGraduationName, superSpecializationType);
		yearIds.push(graduationYear, pgGraduationYear, superSpecializationYear);
		CollegeNames.push(graduationCollege, pgGraduationCollege, superSpecializationCollege);
		let flag = true;
		// if(graduationName!== 0 && pgGraduationName===0 && superSpecializationType===0){
		// 	educationCategoryIds.push(graduationName);
		// }
		// else if(graduationName!== 0 && pgGraduationName!==0 && superSpecializationType===0){
		// 	educationCategoryIds.push(graduationName, pgGraduationName);
		// }
		// else if(graduationName!== 0 && pgGraduationName===0 && superSpecializationType!==0){
		// 	educationCategoryIds.push(graduationName, superSpecializationType);
		// }
		// else if(graduationName!== 0 && pgGraduationName!==0 && superSpecializationType!==0){
		// 	educationCategoryIds.push(graduationName, pgGraduationName, superSpecializationType);
		// }
		if (graduationName == 0) {
			setdataflag(false)
			setDisplayErrMsg1('Please select Graduation Degree');
			alertlanguage()
			// window.scrollTo(0, 0)
			flag = false;
			// } else if (!graduationYear && graduationYear == 0) {
			// 	props.errFunctionCallback('Please enter Graduation Year');
			// 	flag = false;
		} else if (medicalRegistrationState == 0) {
			setdataflag(false)
			setDisplayErrMsg1('Please select Medical Reg. State');
			alertlanguage()
			// window.scrollTo(0, 0)
			flag = false;
		} else if (!medicalRegistrationNo) {
			setdataflag(false)
			setDisplayErrMsg1('Please enter Medical Reg. No.');
			alertlanguage()
			// window.scrollTo(0, 0)
			flag = false;
		} else if (flag == true) {
			setdataflag(true)
			var valuesTosend = {};
			valuesTosend.educationCategoryId = educationCategoryIds.toString();
			valuesTosend.educationYear = yearIds.toString();
			valuesTosend.educationCollege = CollegeNames.toString();
			valuesTosend.subFunctionName = 'updateEducationDetails';
			valuesTosend.doctorMedicalRegNo = medicalRegistrationNo;
			valuesTosend.doctorRegistrationState = medicalRegistrationState;
			valuesTosend.doctorId = window.sessionStorage.getItem('doctorId');

			callingAPI('doctorProfile/updateDoctorProfile', valuesTosend)
				.then((res) => {
					alert(res.data.successMessage);
					window.location.href = '/Dashboard/profile';
				})
				.catch();
		}
	};
	const alertlanguage = () => {
		setTimeout(() => {
			setDisplayErrMsg1('');
		}, 2000);

	}
	const selectedGraduationNameChange = (event) => {
		setGraduationName(event.target.value);
	};
	const selectedGraduationYearChange = (event) => {
		setGraduationYear(event.target.value);
	};
	const selectedGraduationCollegeChange = (event) => {
		setGraduationCollege(event.target.value);
	};
	const selectedPGGraduationNameChange = (event) => {
		setPGGraduationName(event.target.value);
	};
	const selectedPGGraduationYearChange = (event) => {
		setPGGraduationYear(event.target.value);
	};
	const selectedPGGraduationCollegeChange = (event) => {
		setPGGraduationCollege(event.target.value);
	};
	const selectedSuperSpecializationTypeChange = (event) => {
		setSuperSpecializationType(event.target.value);
	};
	const selectedSuperSpecializationYearChange = (event) => {
		setSuperSpecializationYear(event.target.value);
	};
	const selectedSuperSpecializationCollegeChange = (event) => {
		setSuperSpecializationCollege(event.target.value);
	};
	const selectedMedicalRegistrationStateChange = (event) => {
		setMedRegistrationState(event.target.value);
	};
	const selectedMedicalRegistrationNoChange = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setMedicalRegistrationNo(event.target.value);
		}
	};
	const getGraduationYearValues = () => {
		var ddlYears = document.getElementById('graduationYear');
		var currentYear = new Date().getFullYear();
		var year = [];
		for (var i = 1950; i <= currentYear; i++) {
			var option = document.createElement('OPTION');
			option.innerHTML = i;
			option.value = i;
			ddlYears.appendChild(option);
		}
		setYearvalues(year);
	};
	const getGraduationCategoryDropdownValues = () => {
		callingAPI('doctorProfile/getEducationCategory')
			.then((res) => {
				setGraduationCategory(res.data.result.education);
			})
			.catch();
	};
	const getStateDropdownValues = () => {
		callingAPI('doctorProfile/getState')
			.then((res) => {
				setStateData(res.data.result.state);
			})
			.catch();
	};
	const getPgYearValues = () => {
		var ddlYears = document.getElementById('pggraduationYear');
		var currentYear = new Date().getFullYear();
		var year = [];
		for (var i = 1950; i <= currentYear; i++) {
			var option = document.createElement('OPTION');
			option.innerHTML = i;
			option.value = i;
			ddlYears.appendChild(option);
		}
		setYearpgvalues(year);
	};
	const getSuperSpecializationYearValues = () => {
		var ddlYears = document.getElementById('superspecYear');
		var currentYear = new Date().getFullYear();
		for (var i = 1950; i <= currentYear; i++) {
			var option = document.createElement('OPTION');
			option.innerHTML = i;
			option.value = i;
			ddlYears.appendChild(option);
		}
	};
	useEffect(
		() => {
			getGraduationCategoryDropdownValues();
			getGraduationYearValues();
			getPgYearValues();
			getSuperSpecializationYearValues();
			getStateDropdownValues();
			alertlanguage();

			if (props.educationDetails.length) {
				setGraduationName(
					props.educationDetails && props.educationDetails[0]
						? props.educationDetails[0].educationCategoryId
						: ''
				);
				setGraduationYear(
					props.educationDetails && props.educationDetails[0] ? props.educationDetails[0].educationYear : ''
				);
				setGraduationCollege(
					props.educationDetails && props.educationDetails[0]
						? props.educationDetails[0].educationCollege
						: ''
				);
				setMedicalRegistrationNo(
					props.educationDetails && props.educationDetails[0]
						? props.educationDetails[0].doctorMedicalRegNo
						: ''
				);
				setMedRegistrationState(
					props.educationDetails && props.educationDetails[0]
						? props.educationDetails[0].doctorRegistrationState
						: ''
				);
				setPGGraduationYear(
					props.educationDetails && props.educationDetails[1] ? props.educationDetails[1].educationYear : ''
				);
				setPGGraduationCollege(
					props.educationDetails && props.educationDetails[1]
						? props.educationDetails[1].educationCollege
						: ''
				);
				setPGGraduationName(
					props.educationDetails && props.educationDetails[1]
						? props.educationDetails[1].educationCategoryId
						: ''
				);
				setSuperSpecializationCollege(
					props.educationDetails && props.educationDetails[2]
						? props.educationDetails[2].educationCollege
						: ''
				);
				setSuperSpecializationType(
					props.educationDetails && props.educationDetails[2]
						? props.educationDetails[2].educationCategoryId
						: ''
				);
				setSuperSpecializationYear(
					props.educationDetails && props.educationDetails[2] ? props.educationDetails[2].educationYear : ''
				);
			}
		},
		[props.educationDetails]
	);
	return (
		<div>
			<div className="homeProfileDetails">
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className='col-md-12'>
							<p
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'red',
									padding: '0px',
									margin: '0px', fontSize: "13px", paddingBottom: "15px"
								}}
							>
								{displayErrMsg1}
							</p>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">

							<label className="labelTextField labelMarginBottom">
								Graduation<sup>*</sup>
							</label>
							<select
								className="inputField inputFieldBottom"
								value={graduationName}
								onChange={selectedGraduationNameChange}
							>
								<option value="">Select</option>
								{graduationCategory.map((gradItem, index) => (
									<option value={gradItem.educationCategoryId}>
										{gradItem.educationCategoryDescription}
									</option>
								))}
							</select>
							<label className="labelTextField labelMarginBottom">
								Graduation Year
								{/* <sup>*</sup> */}
							</label>
							<select
								className="inputField inputFieldBottom"
								placeholder="Year"
								id="graduationYear"
								value={graduationYear}
								onChange={selectedGraduationYearChange}
							>
								<option value="0">Year</option>
							</select>
							<label className="labelTextField labelMarginBottom">
								Graduation College
								{/* <sup>*</sup> */}
							</label>
							<input
								className="inputField inputFieldBottom"
								type="text"
								value={graduationCollege}
								onChange={selectedGraduationCollegeChange}
								placeholder="College"
							/>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">
							<label className="labelTextField labelMarginBottom">Post Graduation</label>
							<select
								className="inputField inputFieldBottom"
								value={pgGraduationName}
								onChange={selectedPGGraduationNameChange}
							>
								<option value="">Select</option>
								{graduationCategory.map((gradItem, index) => (
									<option value={gradItem.educationCategoryId}>
										{gradItem.educationCategoryDescription}
									</option>
								))}
							</select>
							<label className="labelTextField labelMarginBottom">
								Post Graduation Year
								{/* <sup>*</sup> */}
							</label>
							<select
								className="inputField inputFieldBottom"
								placeholder="Year"
								id="pggraduationYear"
								value={pgGraduationYear}
								onChange={selectedPGGraduationYearChange}
							>
								<option value="0">Year</option>
							</select>
							<label className="labelTextField labelMarginBottom">
								Post Graduation College
								{/* <sup>*</sup> */}
							</label>
							<input
								className="inputField inputFieldBottom"
								type="text"
								value={pgGraduationCollege}
								onChange={selectedPGGraduationCollegeChange}
								placeholder="College"
							/>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">
							<label className="labelTextField labelMarginBottom">Medical Registration<sup>*</sup></label>
							<select
								className="inputField inputFieldBottom"
								value={medicalRegistrationState}
								onChange={selectedMedicalRegistrationStateChange}
							>
								<option value="0">Medical Reg. State</option>
								{medicalRegistrationStateValues.map((val, index) => (
									<option value={val.stateId}>{val.stateName}</option>
								))}
							</select>
							<label className="labelTextField labelMarginBottom">Medical Registration No.<sup>*</sup></label>
							<input
								type="text"
								className="inputField"
								placeholder="Medical Reg.No"
								onChange={selectedMedicalRegistrationNoChange}
								value={medicalRegistrationNo}
							/>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">
							<label className="labelTextField labelMarginBottom">Super Specialization</label>
							<select
								className="inputField inputFieldBottom"
								value={superSpecializationType}
								onChange={selectedSuperSpecializationTypeChange}
							>
								<option value="">Select</option>
								{graduationCategory.map((gradItem, index) => (
									<option value={gradItem.educationCategoryId}>
										{gradItem.educationCategoryDescription}
									</option>
								))}
							</select>
							<label className="labelTextField labelMarginBottom">Year</label>
							<select
								className="inputField inputFieldBottom"
								placeholder="Year"
								id="superspecYear"
								onChange={selectedSuperSpecializationYearChange}
								value={superSpecializationYear}
							>
								<option value="0">Year</option>
							</select>
							<label className="labelTextField labelMarginBottom">College</label>
							<input
								type="text"
								placeholder="College"
								className="inputField"
								onChange={selectedSuperSpecializationCollegeChange}
								value={superSpecializationCollege}
							/>
						</div>
						<div className="col-md-4 col-lg-4 practiceManagementList">
							<button
								disabled={dataflag}
								class="borderButton borderButtonLarge legoButton_green"
								onClick={saveEducationDetails}
								style={{ width: '100%' }}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Educationaldetails;
