import React, { useState, useEffect } from 'react';
import Edit from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import Loader from '../Common/loader';
import { callingAPI } from '../config';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function SimplifiedFormPrescriptionRight(props) {
	const [isShowLoader, setIsShowLoader] = useState(0)
	const [showDiv, setShowDiv] = useState(false);
	const [drugName, setDrugName] = useState('');
	const [drugType, setDrugType] = useState(0);
	const [drugStrength, setDrugStrength] = useState('');
	const [drugUnit, setDrugUnit] = useState(0);
	const [drugDescription, setDrugDescription] = useState('');
	const [searchDrugName, setSearchDrugName] = useState('');
	const [drugListingArr, setDrugListingArr] = useState([]);
	const [genericMedicine, setGenericMedicine] = useState([]);
	const [mostPrescribedMedicineData, setMostPrescribedMedicineData] = useState([]);
	const [templateCode, setTemplateCode] = useState('');
	const [addmedicineName, setMedicineName] = useState('');
	const [medicineID, setMedicineID] = useState('');
	const [medicineFrequency, setMedicineFrequency] = useState(0);
	const [oneTime, setOneTime] = useState('');
	const [secondTime, setSecondTime] = useState('');
	const [groupCodeId, setgroupCodeId] = useState('');
	const [thirdTime, setThirdTime] = useState('');
	const [fourthTime, setFourthTime] = useState('');
	const [intake, setIntake] = useState(0);
	const [instruction, setInstruction] = useState('');
	const [quantity, setQuantity] = useState('');
	const [duration, setDays] = useState('');
	const [errMsg, setErrmsg] = useState('');

	const [selectedMedicineArrListing, setMedicineTemplateAppendArr] = useState([{
		medicineId: "",
		frequency: "",
		frequencyId: "",
		medicineName: "",
		dosage: ["", "", "", ""],
		intake: "",
		intakeId: "",
		instruction: "",
		quantity: "",
		duration: "",
		durationId: "",
		storeId: "",
		refill: "",


	}]);
	const [chiefCaseSenstiveArray, setChiefCaseSenstiveArray] = useState([])
	const [checkBox, setcheckBox] = useState(0)
	const [timesData, setTimesData] = useState([]);
	const [medicineListingname, setMedicineListingname] = useState([]);
	const [frequencyList, setFrequencyList] = useState([]);
	const [intakeList, setIntakeList] = useState([]);
	const [drugTypeList, setDrugTypeList] = useState([]);
	const [drugUnitArr, setDrugUnitList] = useState([]);
	const [templateErrMsg, setTemplateErrMsg] = useState('');
	const [templateList, setTemplateList] = useState([]);
	const [ClickedValue, setClickedValue] = useState("");

	const showDivFunction = () => {
		setShowDiv(true);
	};
	const setMedicineDetails = (e, i) => {
		// alert("yessss")
		const re = /^[0-9.\b]+$/;
		const { name, value, checked } = e.target;
		const values = [...selectedMedicineArrListing];
		if (e.target.name == 'refill') {
			values[i] = { ...values[i], [name]: checked == true ? 'yes' : 'no' };
		}
		else if (e.target.name == "frequencyId") {
			var t = document.getElementById('frequencyId');
			var selectedTextForFrequency = t.options[t.selectedIndex].text;
			frequencyList.forEach((val, id) => {

				if (val.id === Number(e.target.value)) {
					var timesArr = [...timesData]
					timesArr[i] = Number(val.times)
					setTimesData(timesArr)
					values[i].dosage = ["", "", "", ""]

				}

			})
			values[i].frequencyId = e.target.value
			values[i].frequency = selectedTextForFrequency
		}
		else if (e.target.name == "intakeId") {
			var t1 = document.getElementById('intakeId');
			var selectedTextForIntake = t1.options[t1.selectedIndex].text;
			values[i].intakeId = e.target.value
			values[i].intake = selectedTextForIntake
		}
		else if (e.target.name == "quantity" || e.target.name == "duration") {
			if (re.test(value) || value === '') {
				values[i] = { ...values[i], [name]: value };
			}
		}
		else if (e.target.name == "medicineName") {
			values[i].medicineName = e.target.value
			values[i].medicineId = medicineID
		}
		else {
			values[i] = { ...values[i], [name]: value };
		}

		setMedicineTemplateAppendArr(values);
	};
	const setTimesCheckbox = (val, index, arrindex) => {
		const values = [...selectedMedicineArrListing];
		values[arrindex].dosage[index] = val
		setMedicineTemplateAppendArr(values);
	}
	const getMedicineIDFunction = (i, id1, id2, name, itemId) => {
		//if (i === "") { setItemName(name); }
		document.getElementById(id2 + i).style.display = 'none'
		//	var exist = disposableitemsaddedarr.some(item => name === item.itemName);
		// if (exist === true && i === "") {
		// 	alert("Already exist")
		// 	setItemName("");
		// 	setItemID("")
		// 	return;
		// }
		// if (exist === true) {
		// 	alert("Already exist")
		// 	document.getElementById("itemName" + i).value = ""
		// 	document.getElementById("itemId" + i).value = ""
		// 	return;
		// }
		const values = [...selectedMedicineArrListing];
		values[i] = { ...values[i], ["medicineName"]: name };
		values[i] = { ...values[i], ["medicineId"]: itemId };
		setMedicineTemplateAppendArr(values)
	}
	// 	const getMedicineIDFunction = (obj, id,id2,id1) => {
	// 	//	setMedicineID(obj.medicineId);
	// 		var values = [...selectedMedicineArrListing]
	// 		// values[id].medicineName = { ...values[id], ["medicineName"]: obj.medicineName };
	// 		// values[id].medicineId =  { ...values[id], ["medicineName"]: obj.medicineId };
	// 		 setMedicineTemplateAppendArr(values)
	// document.getElementById(id1).value = obj
	// document.getElementById(id2).style.display='none'
	// 		//setMedicineName(obj.medicineName);
	// 		setMedicineListingname([])
	// 		// const { name, value } = e.target;
	// 		// const values = [...disposableitemsaddedarr];
	// 		// values[idx] = { ...values[idx], [name]: value };
	// 		// setDisposableItemsAddedArr(values)
	// 	};
	const getSelectedMedicineDetails = (item) => {
		props.setingMedicalName(item, "Type");
	};
	const getMedicineTemplateDetails = (item) => {
		props.settingMedicalTemplate(item)
	}
	const getTemplateDetailsOnPopup = (item) => {
		setClickedValue("Edit")
		setgroupCodeId(item.groupCodeId)
		setTemplateCode(item.templateCode)
		let postData = {
			functionName: 'medicineTemplateDetails',
			templateId: item.groupCodeId,
		};
		callingAPI('medicinePrescription/medicineTemplateDetails', postData).then((data) => {
			if (data.data.success === '1') {
				// var copy = [...selectedMedicineArrListing]
				let timeArrayList = []
				data.data.medicineTemplateData.map((temp, i) => {
					temp.dosage = temp.dosage.split("-");
					frequencyList.forEach((val, id) => {

						if (val.id === Number(temp.frequencyId)) {
							timeArrayList[i] = Number(val.times)
							var timesArr = [...timesData]
							timesArr[i] = Number(val.times)
							data.data.medicineTemplateData[i].timesPerDay = Number(val.times)
							setTimesData(timesArr)

						}

					})
					//data.data.medicineTemplateData[i].timesArr = timeArrayList
					//setTimesData(timesArr)
				})
				setMedicineTemplateAppendArr(data.data.medicineTemplateData)
			}
		});
	}
	// athiraaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
	const saveMedicineTemplate = () => {
		setIsShowLoader(1);
		let isValidateOk = true
		if (!templateCode) {
			// setIsShowLoader(0);
			setTemplateErrMsg('Template code required');
			document.getElementById("templateCode").focus()
			isValidateOk = false;

		}
		else if (templateCode) {
			// setIsShowLoader(0);
			for (let index = 0; index < selectedMedicineArrListing.length; index++) {
				if (selectedMedicineArrListing[index].medicineName === "" || selectedMedicineArrListing[index].medicineName === "0") {
					setTemplateErrMsg("Medicine Name Required");
					isValidateOk = false;

				}
				else if (selectedMedicineArrListing[index].medicineId === "" || selectedMedicineArrListing[index].medicineId === "0") {
					setTemplateErrMsg("Medicine Not Exist");
					isValidateOk = false;



				}
				else if (selectedMedicineArrListing[index].frequencyId === "" || selectedMedicineArrListing[index].frequencyId === "0") {
					setTemplateErrMsg("Please Select Frequency & Dosage");
					isValidateOk = false;

				}
				else if (selectedMedicineArrListing[index].intakeId === "" || selectedMedicineArrListing[index].intakeId === "0") {
					setTemplateErrMsg("Please Select Intake");
					isValidateOk = false;

				}
			}
		}

		if (isValidateOk == true) {
			var finalArrayToSend = JSON.parse(JSON.stringify(selectedMedicineArrListing))
			finalArrayToSend.map((val, index) => {
				// if(val.dosage && val.dosage !==""){
				// 		val.dosage = val.dosage.join("-");
				// }
				let finalDosage = val.dosage.map(dosageCount => dosageCount.length > 0 ? dosageCount : dosageCount.replaceAll("", 0))
				val.dosage = finalDosage.toString().replaceAll(",", "-");
				return val;

			})
			// setIsShowLoader(0);
			let postData = {
				functionName: 'saveMedicineTemplate',
				userId: window.sessionStorage.getItem("userId"),
				hospitalId: localStorage.getItem("hospitalId"),
				templateCode: templateCode,
				templateId: groupCodeId ? groupCodeId : "",
				medicineData: finalArrayToSend,
			};
			callingAPI('medicinePrescription/saveMedicineTemplate', postData).then((data) => {
				if (data.data.success === '1') {
					setTemplateErrMsg('');
					alert("Medicine template added successfully")
					// setIsShowLoader(0);
					window.location.href = "/patients/patientPriscriptionAdd";

				}
			});
		}
	};
	const addTemplatePopUp = () => {

		if (ClickedValue == "Edit") {
			setTemplateCode("")
		}
		selectedMedicineArrListing.map((initialArr, index) => {
			initialArr.medicineId = ""
			initialArr.frequency = ""
			initialArr.frequencyId = ""
			initialArr.medicineName = ""
			initialArr.intakeId = ""
			initialArr.intake = ""
			initialArr.instruction = ""
			initialArr.quantity = ""
			initialArr.duration = ""
			initialArr.durationId = ""
			initialArr.refill = ""
			initialArr.storeId = ""
			initialArr.dosage = ["", "", "", ""]
			return initialArr


		})
		var timesArr = [...timesData]
		selectedMedicineArrListing.forEach((valList, index) => {

			frequencyList.forEach((dp, id) => {
				if (dp.id === Number(valList.frequencyId)) {
					timesArr[index] = Number(dp.times)
				}
			})
		})

		setTimesData(timesArr)


		// 	setMedicineTemplateAppendArr(selectedMedicineArrListing)


		// }
		setClickedValue("Add")
		// if (ClickedValue == "Add") {
		// 	setTemplateCode("")
		// }

	}
	const removeMedicineTemplate = (e, i) => {

		var copy = JSON.parse(JSON.stringify(selectedMedicineArrListing))
		if ((ClickedValue == "Edit") ? selectedMedicineArrListing.length == 1 : selectedMedicineArrListing.length == 1) {
			alert("Atleast One Medicine Required")
		} else {
			copy.splice(i, 1);
			setMedicineTemplateAppendArr(copy);

		}
	}
	const removeTemplate = (e, templateId, i) => {
		let copytemplateList = [...templateList];
		var x = false
		x = window.confirm("Do you want to remove the template?")
		if (x == true) {
			let postData = {
				functionName: "deleteMedicineTemplate",
				templateId: templateId,
			};
			callingAPI('medicinePrescription/deleteMedicineTemplate', postData).then((data) => {
				if (data.data.success === '1') {
					alert(data.data.successMessage);
					window.location.href = "/patients/patientPriscriptionAdd";


				}
			});
		}
	};
	const getMedicineNames = (i, id1, id2) => {
		let postData = {
			functionName: 'medicineList',
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			key: document.getElementById(id1).value,
			max: '10',
			offset: '0',
		};
		callingAPI('medicinePrescription/medicineList', postData).then((data) => {
			if (document.getElementById(id1).value.length > 2) {

				if (data.data.success === '1' && data.data.result[0].medicineData.length > 0) {
					document.getElementById(id2).style.display = 'block'
					setMedicineListingname(data.data.result[0].medicineData);
					//document.getElementById('medicineListDiv').style.display = 'block';
					if (data.data.result[0].medicineData.length == 0) {
						alert('No Medicine Found');
					}
				} else {
					// document.getElementById('medicineListDiv').style.display = 'none';
					setMedicineListingname([]);
				}
			}
			else {
				// document.getElementById('medicineListDiv').style.display = 'none';
				setMedicineListingname([]);
			}
		});

	};
	const searchDrugNameFunction = (event) => {


		setSearchDrugName(event.target.value);
		let postData = {
			functionName: 'medicineList',
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			key: event.target.value,
			max: '1000',
			offset: '0',
		};
		callingAPI('medicinePrescription/medicineList', postData).then((data) => {
			if (data.data.success === '1') {
				//  setDrugListingArr(data.data.result[0].medicineData);
				// setGenericMedicine(data.data.result[0].genericMedicineData)
				// setMostPrescribedMedicineData(data.data.result[0].mostPrescribedMedicineData)
				var proArray = data.data.result[0].medicineData
				var genericList = data.data.result[0].genericMedicineData
				var prescribedList = data.data.result[0].mostPrescribedMedicineData
				var filteredData = []
				var filteredData1 = []
				var filteredData2 = []

				if (event.target.value !== "") {
					filteredData = proArray.filter((item) => {
						return Object.values(item.medicineName).join('').toLowerCase().includes(event.target.value.toLowerCase())
					})
					filteredData1 = genericList.filter((item) => {
						return Object.values(item.medicineName).join('').toLowerCase().includes(event.target.value.toLowerCase())
					})
					filteredData2 = prescribedList.filter((item) => {
						return Object.values(item.medicineName).join('').toLowerCase().includes(event.target.value.toLowerCase())
					})
					setDrugListingArr([...filteredData])
					setGenericMedicine([...filteredData1])
					setMostPrescribedMedicineData([...filteredData2])
				}
				else {
					setDrugListingArr(data.data.result[0].medicineData)
				}

			}
			//  else {

			// 	document.getElementById(dropdownId).style.display = 'none';
			// 	setFreeArticleData1([]);
			// 	//setClear('')
			// }
		});
	};
	const cancelDrugDetails = () => {
		setShowDiv(false);
	};
	const saveDrugDetails = () => {
		var flag = true;
		if (!drugName) {
			setErrmsg('Drug name required');
			flag = false;
		} else if (!drugType) {
			setErrmsg('Drug type required');
			flag = false;
		} else if (!drugStrength) {
			setErrmsg('Drug Strength required');
			flag = false;
		} else if (!drugUnit) {
			setErrmsg('	Drug Unit required');
			flag = false;
		} else if (!drugDescription) {
			setErrmsg('Drug Description required');
			flag = false;
		}
		if (chiefCaseSenstiveArray.indexOf(drugName.toLowerCase()) == -1) {
			setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, drugName.toLowerCase()])
		}
		else {
			alert(drugName + " Drug Already Exist")
			return false;
		}
		if (flag == true) {
			let valuesToSend = {
				functionName: 'saveMedicine',
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				hospitalId: localStorage.getItem('hospitalId'),
				medicineName: drugName,
				medicineTypeId: drugType,
				medicineUnitId: drugUnit,
				strength: drugStrength,
				description: drugDescription,
				isGeneric: checkBox,
			};
			callingAPI('medicinePrescription/saveMedicine', valuesToSend).then((data) => {
				if (data.data.success === '1') {
					alert(data.data.successMessage);
					setErrmsg('');
					setShowDiv(false);
					setDrugName('');
					setDrugType(0);
					setDrugStrength('');
					setDrugUnit(0);
					setDrugDescription('');
				} else {
					alert(data.data.errorMessage);
				}
			});
		}
	};
	const getDrugsListing = () => {
		let postData = {
			functionName: 'medicineList',
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			key: '',
			max: '10',
			offset: '0',
		};
		callingAPI('medicinePrescription/medicineList', postData).then((data) => {
			if (data.data.success === '1') {
				props.parentCallback(data.data.result[0]);
				setDrugListingArr(data.data.result[0].medicineData);
				setGenericMedicine(data.data.result[0].genericMedicineData)
				setMostPrescribedMedicineData(data.data.result[0].mostPrescribedMedicineData)
				setFrequencyList(data.data.result[0].frequency);
				setIntakeList(data.data.result[0].intake);
				setDrugTypeList(data.data.result[0].medicineType);
				setDrugUnitList(data.data.result[0].medicineUnit);
				setTemplateList(data.data.result[0].medicineTemplateData);
				var timesArr = [...timesData]
				selectedMedicineArrListing.forEach((valList, index) => {

					data.data.result[0].frequency.forEach((dp, id) => {
						if (dp.id === Number(valList.frequencyId)) {
							timesArr[index] = Number(dp.times)
						}
					})
				})
				setTimesData(timesArr)
			}
		});
	};
	// athiraaaaaaaaaaaaaaaaaaaaaaaaa
	const addMedicineTemplate = () => {
		let flagPrescription = true;
		// if (!addmedicineName) {
		// 	alert('Select medicine Name');
		// 	document.getElementById('medicineName').focus();
		// 	flagPrescription = false;
		// }
		if (flagPrescription === true) {
			var copyPrescriptiontDetails = [...selectedMedicineArrListing];
			var newPrescriptionDetails = {
				medicineId: "",
				frequency: "",
				frequencyId: "",
				medicineName: "",
				dosage: "",
				intake: "",
				intakeId: "",
				instruction: "",
				quantity: "",
				duration: "",
				durationId: "",
				refill: "",
				storeId: "",
			};
			setMedicineTemplateAppendArr([...copyPrescriptiontDetails, newPrescriptionDetails]);
			setMedicineFrequency(0);
			setMedicineName('');
			setOneTime('');
			setSecondTime('');
			setThirdTime('');
			setFourthTime('');
			setIntake(0);
			setInstruction(0);
			setQuantity('');
			setDays('');
		}

	};
	const loadMoreDrugFunction = (item) => {
		let postData = {
			functionName: 'medicineList',
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			key: '',
			max: 10,
			offset: drugListingArr.length,
		};

		callingAPI('medicinePrescription/medicineList', postData).then((data) => {
			if (data.data.success === '1') {
				props.parentCallback(data.data.result[0]);
				setDrugListingArr(drugListingArr.concat(data.data.result[0].medicineData));
				setMostPrescribedMedicineData(mostPrescribedMedicineData.concat(data.data.result[0].genericMedicineData));
				setFrequencyList(data.data.result[0].frequency);
				setIntakeList(data.data.result[0].intake);
				setDrugTypeList(data.data.result[0].medicineType);
				setDrugUnitList(data.data.result[0].medicineUnit);
				setTemplateList(data.data.result[0].medicineTemplateData);
			}
		});
	};
	useEffect(() => {
		getDrugsListing();
		if (props.getTemplateDetails.length) {
			setMedicineTemplateAppendArr(props.getTemplateDetails)
		}
	}, [props.getTemplateDetails]);
	return (
		<div className="patientright pl_pro">
			<div className="rightTagSection">
				<div className="rightTagList">
					<div className="rightTagTitle">
						Drugs
						{/* <div class="treatmentSideBtn" style={{ display: !showDiv ? '' : 'none' , marginBottom:"10px"}}>
							<span
								class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
								id="addPro"
								onClick={showDivFunction}
							>
								+ Add
							</span>

							<a href="#add_template">
								{' '}
								<span
									class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
									id="addPro"
									onClick={addTemplatePopUp}
								>
									+ Add Template
								</span>
							</a>
						</div> */}
						<div class="treatmentSideBtn" style={{ display: showDiv == true ? '' : 'none' }}>
							<button
								class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour pl_cancell_btn"
								id="addPro"
								onClick={cancelDrugDetails}
							>
								Cancel
							</button>
							<button
								class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour pl_save_btn"
								id="addPro"
								onClick={saveDrugDetails}
							>
								Save
							</button>
						</div>
						{/* Cancel & Save Button */}
						{/* -===============  (DRUGS ADD DESIGN BEGINS HERE )  =============== */}
						<div
							class="treatmentListNew"
							id="addProNew"
							style={{ display: showDiv === true ? '' : 'none' }}
						>
							<p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{errMsg}</p>
							<div class="treatmentNewAdd treatmentNewAddBrdr precriptionDrugsFillingScreen_PTour">
								<div class="medicneNewAdd">
									<label>Drug <sup style={{ color: "red", fontSize: "12px", top: "0px" }}>*</sup></label>
									<input autoFocus
										type="text"
										id="txtDrugName"
										name="txtDrugName"
										placeholder="Drug Name"
										maxlength="100"
										value={drugName}
										onChange={(e) => setDrugName(e.target.value)}
										class="precriptionDrugsName_PTour" tabIndex={1}
									/>
									<select
										name="cmbType"
										id="cmbType"
										class="precriptionSelectDrugsType_PTour"
										value={drugType}
										onChange={(e) => setDrugType(e.target.value)}
									>
										<option value="0">Select Type</option>
										{drugTypeList.map((drug, index) => (
											<option value={drug.typeId}>{drug.typeName}</option>
										))}
									</select>
								</div>
								<div class="medicneNewAdd">
									<label>Strength</label>
									<input
										type="text"
										id="txtStrength"
										name="txtStrength"
										placeholder="Strength"
										maxlength="255"
										value={drugStrength}
										onChange={(e) => setDrugStrength(e.target.value)}
										class="precriptionDrugsStrength_PTour"
									/>
									<select
										name="cmbUnit"
										id="cmbUnit"
										class="precriptionSelectDrugsUnit_PTour"
										value={drugUnit}
										onChange={(e) => setDrugUnit(e.target.value)}
									>
										<option value="0">Select Unit</option>
										{drugUnitArr.map((drugunit, index) => (
											<option value={drugunit.unitId}>{drugunit.unitName}</option>
										))}
									</select>
								</div>

								<div class="medicneNewAdd">
									<label>Is generic</label>

									<input type="checkbox" onClick={() => setcheckBox(1)} />
								</div>

								<div class="medicneNewAddNote">
									<input
										type="text"
										placeholder="Description"
										id="txtMedicineDescription"
										maxlength="250"
										class="precriptionDrugsDesc_PTour"
										value={drugDescription}
										onChange={(e) => setDrugDescription(e.target.value)}
									/>
								</div>
							</div>
						</div>
						{/* ===============  (Drugs ADD DESIGN ENDS HERE) =============== */}
					</div>
					{/* <div className="tagListingDiv drugsSearch">
						<input
							type="text"
							className="inputField"
							placeholder="Search"
							name="searchDrugName"
							value={searchDrugName}
							onChange={searchDrugNameFunction}
						/>
						<FontAwesomeIcon icon={faSearch} className="iconSearchDrug"/>
					</div> */}


					{/*  ============== Medicine Template Begins here ============== */}
					<div class="medicineHead">Medicine Template</div>
					<div class="treatmentNameList medicineNameList prescriptionMedTemplte_PTour">
						<ul style={{ border: "none" }}>
							{templateList.map((templist, index) => (


								<li>
									<span class="elipsis" title="12" onClick={() => getMedicineTemplateDetails(templist.groupCodeId)}>
										<span>{templist.templateCode}</span>
									</span>
									<span class="addGroupIcon">
										{/* <a href="#add_template">
											<img src={Edit} title="Edit" style={{ width: '15px' }} onClick={() => getTemplateDetailsOnPopup(templist)} />
										</a> */}
										<a>
											<img src={Delete} title="Delete" style={{ width: '15px', marginLeft: '8px' }} onClick={(e) => removeTemplate(e, templist.groupCodeId, index)} />
										</a>
									</span>
								</li>
							))}
						</ul>
					</div>
					{/*  ==============  Medicine Template Ends here ============== */}
				</div>
			</div>
			<div className="tagListingDiv drugsSearch" style={{ marginLeft: "10px" }}>
				<input
					type="text"
					className="inputField"
					placeholder="Search"
					name="searchDrugName"
					value={searchDrugName}
					onChange={searchDrugNameFunction}
				/>
				<FontAwesomeIcon icon={faSearch} className="iconSearchDrug" />
			</div>
			<div class="medicineHead" id="allDrugsHead" style={{ border: 'none' }}>
				GENERIC MEDICINE
			</div>
			{genericMedicine.length ?
				<div className="treatmentNameList treatmentPlanList treatmentPlanListGenericMed " style={{ paddingBottom: '0px' }}>
					<ul class="allDrugs medicineAllListDiv">
						{genericMedicine.map((value, idx) => (
							<li>
								<span
									class="elipsis"
									id="spanMedicine1969"
									title={
										value.medicineName +
										' '
										//  +
										// value.medicineType +
										// ' ' +
										// value.strength +
										// ' ' +
										// value.medicineUnit 
										+
										'\n' +
										'Generic Name:' +
										value.genericname
									}
									onClick={() => getSelectedMedicineDetails(value)} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
								>
									{value.medicineName +
										' ' 
										// +
										// value.medicineType +
										// ' ' +
										// value.strength +
										// ' ' +
										// value.medicineUnit
										}

									<span class="treatmentNameListRight" style={{ float: "none" }}>
										<span style={{ float: "left" }}>
											{value.stockQuantity}
										</span>
										{value.unitPrice !== "" ?
											<span style={{ float: "inherit" }}>
												₹{parseFloat(value.unitPrice).toFixed(2)}

											</span> : ""}
									</span>
								</span>
							</li>
						))}
					</ul>
				</div> : ""}
			<div class="medicineHead" id="allDrugsHead" style={{ border: 'none' }}>
				MOST PRESCRIBED DRUGS
			</div>
			{mostPrescribedMedicineData.length ?
				<div className="treatmentNameList treatmentPlanList " style={{ paddingBottom: '0px', height: "auto", minHeight: "auto" }}>
					<ul class="allDrugs medicineAllListDiv">
						{mostPrescribedMedicineData.map((value, idx) => (
							<li>
								<span
									class="elipsis"
									id="spanMedicine1969"
									title={
										value.medicineName +
										' '
										//  +
										// value.medicineType +
										// ' ' +
										// value.strength +
										// ' ' +
										// value.medicineUnit
										  +
										'\n' +
										'Generic Name:' +
										value.genericname
									}
									onClick={() => getSelectedMedicineDetails(value)} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
								>
									{value.medicineName +
										' ' 
										// +
										// value.medicineType +
										// ' ' +
										// value.strength +
										// ' ' +
										// value.medicineUnit
									}
									<span class="treatmentNameListRight" style={{ float: "none" }}>
										<span style={{ float: "left" }}>
											{value.stockQuantity}
										</span>
										{value.unitPrice !== "" ?
											<span style={{ float: "inherit" }}>
												₹{parseFloat(value.unitPrice).toFixed(2)}

											</span> : ""}
									</span>
								</span>
							</li>
						))}
					</ul>
					{/* <ul class="ulLoadMore" id="ulLoadMore0">
					<li>
						<span
							id="loadMore"
							style={{ color: '#fff', textDecoration: 'none', fontWeight: '500' }}
							onClick={loadMoreDrugFunction}
						>
							Load More
						</span>
					</li>
				</ul> */}
				</div> : ""}

			<div class="medicineHead" id="allDrugsHead" style={{ border: 'none' }}>
				ALL DRUGS
			</div>

			<div className="treatmentNameList treatmentPlanList allDrugsList " style={{ paddingBottom: '0px' }}>
				<ul class="allDrugs medicineAllListDiv">
					{drugListingArr.map((value, idx) => (
						<li>
							<span
								class="elipsis"
								id="spanMedicine1969"
								title={
									value.medicineName +
									' ' 
									// +
									// value.medicineType +
									// ' ' +
									// value.strength +
									// ' ' +
									// value.medicineUnit 
									+
									'\n' +
									'Generic Name:' +
									value.genericname
								}
								onClick={() => getSelectedMedicineDetails(value)} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
							>
								{value.medicineName +
									' '
									//  +
									// value.medicineType +
									// ' ' +
									// value.strength +
									// ' ' +
									// value.medicineUnit
								}
								<span class="treatmentNameListRight" style={{ float: "none" }}>
									<span style={{ float: "left" }}>
										{value.stockQuantity}
									</span>
									{value.unitPrice !== "" ?
										<span style={{ float: "inherit" }}>
											₹{parseFloat(value.unitPrice).toFixed(2)}

										</span> : ""}
								</span>
							</span>
						</li>
					))}
				</ul>
				<ul class="ulLoadMore" id="ulLoadMore0">
					<li>
						<span
							id="loadMore"
							style={{ color: '#fff', textDecoration: 'none', fontWeight: '500' }}
							onClick={loadMoreDrugFunction}
						>
							Load More
						</span>
					</li>
				</ul>
			</div>

			{/* Popup Begins */}

			<div id="add_template" class="ploverlay">
				{isShowLoader === 1 ? <Loader /> :
					<div class="plpopup plsmspopup">
						<h2 style={{ color: '#3498db', border: 'none' }}>
							Medicine Template
							<a class="close" href="#">
								&times;
							</a>
						</h2>

						<div class="plcontent doc practiceForm">
							{/*  */}
							<p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{templateErrMsg}</p>

							<div class="treatmentWrap " id="treatmentPlanSection">
								<div class="treatmentSelect">
									<span style={{ float: 'left' }}>Template Code <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup> : &nbsp;</span>
									<input autoFocus
										class="inputField"
										type="text"
										autoComplete='off'
										id="templateCode"
										value={templateCode}
										onChange={(e) => setTemplateCode(e.target.value)}
										style={{ width: 'auto', float: 'left' }}
									/>
									<span style={{ float: 'right' }}>
										<a
											style={{ color: '#04a5bf' }}
											href="javascript:void(0);"
											onClick={addMedicineTemplate}
										>
											Add More
										</a>
									</span>
									<div class="clearfix" />
								</div>
								<div class="prescriptionContent prescriptionContentAdd precriptionAddedDrugsgroups_PTour">
									<div class="treatmentSelectBtnsType" style={{ float: 'right' }}>
										<div class="practiceManagementForm1">
											<div class="practiceManagementFormBlcok" id="typePreId">
												<div id="divJsFiles" />

												<div id="treatmentPlanSection" class="prescriptionAddedMedcne_PTour">
													<div class="treatmentsList precscriptionTrtmntList">
														<div
															class="practiceManagementForm"
															style={{
																padding: ' 0px',
																position: 'relative', margin: "0px"
															}}
														>

															{selectedMedicineArrListing.map((medicineItem, id) => (
																<div
																	class="practiceManagementFormBlcok"
																	style={{ margin: '0px' }}
																>
																	<div class="col-md-12 col-lg-12 form_registrationPatient">
																		<div
																			id="prescriptionDivMain"
																			class="prescriptionFreqMedcne_PTour"
																		>
																			<div
																				class="prescriptionTrtmntListItem"
																				id="prescriptionTrtmntListItem0"
																			>
																				<span
																					className="close_row"
																					onClick={(e) => removeMedicineTemplate(e, id)}
																				>
																					x
																				</span>
																				<ul className="row">
																					<li class="col-md-3 col-lg-3">
																						<label>Medicine <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></label>
																						<div className="col-md-12">
																							<input
																								autoComplete='off'
																								type="text"
																								name='medicineName'
																								placeholder="Medicine Name"
																								id={'medicineName' + id}
																								//	disabled={false}
																								value={medicineItem.medicineName}
																								onChange={(e) => { setMedicineDetails(e, id) }}
																								onKeyUp={() =>
																									getMedicineNames(
																										id,
																										'medicineName' + id,
																										'itemListDiv' + id
																									)}
																								style={{ width: '100%' }}
																							/>
																							<input type="hidden" id={'medicineId' + id} name="medicineId" value={medicineItem.medicineId} />
																							<ul
																								className="autoSearch autoSearch-p0 appointmentNumberSearch drugRightAutoSearch"
																								id={'itemListDiv' + id}
																								value={medicineItem.medicineName}
																								name='medicineName'
																								style={{ display: 'none' }}
																							>
																								{medicineListingname.map((item, i) => (
																									<li
																										onClick={() =>
																											getMedicineIDFunction(
																												id,
																												'medicineName',
																												'itemListDiv',
																												item.medicineName,
																												item.medicineId
																											)}
																									>
																										{item.medicineName}
																									</li>
																								))}
																							</ul>
																						</div>
																						{/* <input
																					type="text"
																					id={"medicineName"+id}
																					name="medicineName"
																					placeholder="Medicine Name"
																					tabindex="7"
																					autocomplete="off"
																					class="txtInstructions"
																					defaultValue={medicineItem.medicineName}
																					// onKeyUp={getMedicineNames}
																					onKeyUp={(e) =>
																						getMedicineNames
																							(e,"medicineListDiv"+id,"medicineName"+id)}

																					onChange={(e) =>
																						setMedicineDetails(e, id)}

																				/>


<ul
															id={"medicineListDiv"+id}
															style={{display:'none'}}

															>
																{medicineListingname.map((val, id) => (
																	<li
																	onClick={() =>
																		getMedicineIDFunction(val.medicineName, id,"medicineListDiv"+id,"medicineName"+id)}
																	>
																			{val.medicineName}
																	</li>
																))}
															</ul> */}
																						{/* {medicineListingname.map((val, id) => (
																					<div
																						id={"medicineListDiv"+id}
																						style={{display:'none'}}
																						onClick={() =>
																							getMedicineIDFunction(val, id,"medicineListDiv"+id,"medicineName"+id)}
																					>
																						{val.medicineName}
																					</div>
																				))} */}
																					</li>

																					<li class="col-md-3 col-lg-3">
																						<label>Frequency &amp; Dosage <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></label>
																						<div class="form-group">
																							<select
																								class="fulWidthSelect"
																								tabindex="2"
																								name="frequencyId"
																								id="frequencyId"
																								onChange={(e) =>
																									setMedicineDetails(e, id)}
																								value={medicineItem.frequencyId}
																							>
																								<option value="0">
																									Select
																								</option>
																								{frequencyList.map(
																									(item, id) => (
																										<option value={item.id}>
																											{item.name}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</li>
																					{/*  */}

																					<li
																						class="col-md-3 col-lg-3 liFrequency"
																						id="liFrequencyOnce0"
																					>
																						<label>&nbsp;</label>


																						<input
																						autoComplete='off'
																							type="text"
																							id={"dosage" + id}
																							name="dosage"
																							placeholder="0"
																							tabindex="3"
																							maxlength="6"
																							value={
																								medicineItem.dosage[0]
																								// medicineItem.dosage.substring(0,1)
																							}
																							style={{
																								display:
																									(timesData[id] >= 1 || medicineItem.timesPerDay >= 1)

																										? ''
																										: 'none',
																							}}
																							onChange={(e) => setTimesCheckbox(e.target.value, "0", id)}
																						// onChange={(
																						// 	e
																						// ) =>
																						// 	setMedicineDetails(
																						// 		e,
																						// 		i
																						// 	)}
																						/>
																						{/* <p>{timesData[id]}</p> */}
																						<input
																							autoComplete='off'
																							type="text"
																							id="dosage1"
																							name="txtNoon0"
																							placeholder="0"
																							tabindex="4"
																							maxlength="6"
																							value={
																								// medicineItem.dosage.substring(3,4)
																								medicineItem.dosage[1]
																							}
																							style={{
																								display:
																									(timesData[id] >= 2 || medicineItem.timesPerDay >= 2)
																										? ''
																										: 'none',
																							}}


																							onChange={(e) => setTimesCheckbox(e.target.value, "1", id)}
																						/>
																						<input
																							autoComplete='off'
																							type="text"
																							id="txtNight0"
																							name="txtNight0"
																							placeholder="0"
																							tabindex="5"
																							maxlength="6"
																							value={
																								medicineItem.dosage[2]
																							}
																							style={{
																								display:
																									(timesData[id] >= 3 || medicineItem.timesPerDay >= 3)
																										? ''
																										: 'none',
																							}}


																							onChange={(e) => setTimesCheckbox(e.target.value, "2", id)}
																						/>
																						<input
																							autoComplete='off'
																							type="text"
																							id="txtNight0"
																							name="txtNight0"
																							placeholder="0"
																							tabindex="5"
																							maxlength="6"
																							value={
																								medicineItem.dosage[3]
																							}
																							style={{
																								display:
																									(timesData[id] >= 4 || medicineItem.timesPerDay >= 4)
																										? ''
																										: 'none',
																							}}


																							onChange={(e) => setTimesCheckbox(e.target.value, "3", id)}
																						/>
																					</li>

																					{/*  */}

																					<li class="col-md-3 col-lg-3">
																						<label>Intake <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></label>
																						<div class="form-group">
																							<select
																								class="fulWidthSelect"
																								data-width="100%"
																								id="intakeId"
																								name="intakeId"
																								tabindex="6"
																								value={medicineItem.intakeId}
																								onChange={(e) =>
																									setMedicineDetails(e, id)}
																							>
																								<option selected="" value="0">
																									Select
																								</option>
																								{intakeList.map(
																									(val, index) => (
																										<option value={val.id}>
																											{val.name}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</li>
																					<li class="col-md-3 col-lg-3">
																						<label>Instructions</label>
																						<input
																							type="text"
																							id="instruction"
																							name="instruction"
																							placeholder="Instructions"
																							tabindex="7"
																							autoComplete='off'
																							class="txtInstructions"
																							value={medicineItem.instruction}
																							onChange={(e) =>
																								setMedicineDetails(e, id)}
																						/>
																					</li>
																					<li class="col-md-2 col-lg-2">
																						<label>Quantity</label>
																						<input
																							autoComplete='off'
																							type="text"
																							id="quantity"
																							name="quantity"
																							placeholder="Qty"
																							tabindex="8"
																							maxlength="8"
																							value={medicineItem.quantity}
																							onChange={(e) =>
																								setMedicineDetails(e, id)}
																						/>
																					</li>
																					<li class="col-md-2 col-lg-2">
																						<label>Days</label>
																						<input
																							autoComplete='off'
																							type="text"
																							id="duration"
																							name="duration"
																							placeholder="Days"
																							tabindex="9"
																							maxlength="9"
																							value={medicineItem.duration}
																							onChange={(e) =>
																								setMedicineDetails(e, id)}
																						/>
																					</li>

																					<div class="clearfix" />
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											</div>
										</div>

										{/*  */}
										<br />
										<div class="treatmentSelectBtnsType" style={{ float: 'right' }}>
											<input
												type="submit"
												name="_action_savePrescription"
												value="Save Template"
												id="btnInvoice"
												index="18"
												class="clinicalSave prescriptionSavePrintWBtn_PTour"
												onClick={saveMedicineTemplate}
											/>
										</div>
									</div>
									{/*  */}
								</div>

							</div>
							{/*  */}
						</div>
					</div>}
			</div>
			{/* Popup Ends */}
		</div>
	);
}

export default SimplifiedFormPrescriptionRight;
