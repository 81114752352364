import React from 'react';
import Logo from '../images/media/logo.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';


function FooterPrint(props) {
    const [printOutId, setprintOutId] = useState('');
    const [footerwidth, setfooterwidth] = useState('');
    const [leftsignature, setleftsignature] = useState();
    const [rightsignature, setrightsignature] = useState('');
    const [headerIncludeLogo, setheaderIncludeLogo] = useState('');
    const [lefttext, setlefttext] = useState('');
    const [logotype, setlogotype] = useState();
    const [righttext, setrighttext] = useState('');
    const [headertitle, setheadertitle] = useState('');
    const [marginbottom, setmarginbottom] = useState('');
    const [margintop, setmargintop] = useState('');
    const [marginleft, setmarginleft] = useState();
    const [marginright, setmarginright] = useState('');
    const [orientationId, setorientationId] = useState('');
    const [pagesizeid, setpagesizeid] = useState('');
    const [logoTypeName, setlogoTypeName] = useState('');
    const [printertype, setprintertype] = useState('');

    const [hospitalName, sethospitalName] = useState('');
    const [hospitalAddress, sethospitalAddress] = useState('');
    const [hospitalEmail, sethospitalEmail] = useState('');
    const [hospitalImagename, sethospitalImagename] = useState('');
    useEffect(() => {
        callingAPI('commonUserFunctions/headerFooterLogoPrints', {
            hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
            doctorId: ""
        }).then((res) => {
            setprintOutId(res.data.result.headerFooterDetails[0].printOutId);
            setfooterwidth(res.data.result.headerFooterDetails[0].footer_full_width_content);
            setleftsignature(res.data.result.headerFooterDetails[0].footer_left_signature);
            setrightsignature(res.data.result.headerFooterDetails[0].footer_right_signature);
            setheaderIncludeLogo(res.data.result.headerFooterDetails[0].headerIncludeLogo);
            setlefttext(res.data.result.headerFooterDetails[0].header_left_text);
            setlogotype(res.data.result.headerFooterDetails[0].header_logo_type_id);
            setrighttext(res.data.result.headerFooterDetails[0].header_right_text);
            setheadertitle(res.data.result.headerFooterDetails[0].header_title);
            setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
            setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
            setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
            setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
            setorientationId(res.data.result.headerFooterDetails[0].page_orientation_type_id);
            setprintertype(res.data.result.headerFooterDetails[0].page_printer_type_id);
            setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);
            setlogoTypeName(res.data.result.headerFooterDetails[0].logoTypeName);
            sethospitalName(res.data.result.hospitalDetail[0].hospitalName);
            sethospitalAddress(res.data.result.hospitalDetail[0].hospitalAddress);
            sethospitalEmail(res.data.result.hospitalDetail[0].hospitalEmail);
            sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
            //setMarginTop()
        });
    }, [])
    var getS3folder =
        process.env.REACT_APP_S3_URI +
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;


    return (
        <>
            <tfoot>
                <tr>
                    <td>
                        <div class="printOutFooterSec" id="section7">
                            <div className="no-print form-group cus-checkbox-wrpr" >
                                <input checked={props.isChecked7} id="isChecked7" type="checkbox" onChange={(e) => props.setIsChecked7(e.target.checked)} title="Hide the Footer while printing." />
                                <label for="isChecked7" title="Hide the Footer while printing."></label>
                            </div>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td
                                            width="25%"
                                            colspan="2"
                                            align="left"
                                            style={{ wordBreak: 'break-all' }}
                                        >
                                            <div align="justify" style={{ fontSize: '10px' }}>
                                                {leftsignature}{' '}
                                            </div>
                                        </td>
                                        <td
                                            width="50%"
                                            align="right"
                                            style={{ fontSize: '10px', wordBreak: 'break-all' }}
                                        >
                                            {rightsignature}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div align="justify" class="practiceFootBtm">
                                                {footerwidth}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style={{ paddingTop: '10px' }} />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tfoot>

        </>
    );
}

export default FooterPrint;
