import React, { useEffect, useState } from 'react';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import PatientOldHistoryListsEditpg from '../Patients/patientoldhistorysEdit';
import Patientright from '../Patients/patientright';
// import { useState } from 'react/cjs/react.development';
import { callingAPI } from '../config'
import $ from 'jquery';
function PatientoldhistoryEdit() {
  $(document).ready(function () {
    $('#appointmentAdd ').addClass("testResultAdd");

  })

  return (
    <div className="coverWraper">
      <Header />
      <div className="contentWraper contentWraperScroll">
        <Leftmenu />
        <div className="rightSection">
          <Profiledetail />
          <div className="practiceManagementWrap">
            <div className="row practiceManagementRow">
              <div className="col-lg-8 col-md-12 practiceLeftSection">
                <PatientOldHistoryListsEditpg />

              </div>
              <div className="col-lg-4 col-md-12 practiceRightSection">
                <Patientright />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PatientoldhistoryEdit;