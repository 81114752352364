import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import home from '../images/icons/home.png';
import referal from '../images/icons/referal.png';
import communication from '../images/icons/communication.png';
import dashboard from '../images/icons/dashboard.png';
import help from '../images/icons/help.png';
import report from '../images/icons/report.png';

function HospitalHeaderLinks() {
	useEffect(() => {
		// window.sessionStorage.setItem('AppoinmentpageNumber',1)
		// window.sessionStorage.setItem("activepagenewappoinment", 0)
	}, []);

	return (
		<div className="headerMenu" id="leftmenuRes">

			<span className="headerMenu ">
				<span id="homeHeader" className={'headerIcon '}>
					<NavLink activeClassName='headerIconActive' to="/hospital Admin/hospitalHome" >
						<img src={home} alt="" />Home
					</NavLink>
				</span>
				<span id="clinicsHeader" className={'headerIcon '}>
					<NavLink activeClassName='headerIconActive' to="/clinics/calendar">
						<img src={referal} alt="" />Clinics
					</NavLink>
				</span>
				<span
					id="communicationsHeader"
					className={'headerIcon '}
				>
					<NavLink activeClassName='headerIconActive' to="/communications/promotionalSMS" >
						<img src={communication} alt="" />Communications
					</NavLink>
				</span>
				<span id="dashboardHeader" className={'headerIcon '}>
					<NavLink activeClassName='headerIconActive'
						// to="/Dashboard/dashboardMain" 
						to="/Dashboard/dashboardView"
					>
						<img src={dashboard} alt="" />Dashboard
					</NavLink>
				</span>
				<span id="reportsHeader" className={'headerIcon '}>
					<NavLink activeClassName='headerIconActive' to="/reports/appointmentList" >
						{/* <NavLink activeClassName='headerIconActive' to="/reports/patientdetails" > */}
						<img src={report} alt="" />Reports
					</NavLink>
				</span>
				<span id="reportHeader" className='headerIcon' >
					<NavLink activeClassName="headerIconActive" to="/Dashboard/help">
						<img src={help} alt="" />Help
					</NavLink>
				</span>
			</span>
		</div>
	);
}
export default HospitalHeaderLinks;
