import React, { useState, useEffect } from 'react';
import Img1mm from '../images/dental/1mm.jpg';
import Img15mm from '../images/dental/1-5mm.jpg';
import Img2mm from '../images/dental/2mm.jpg';
import Img3mm from '../images/dental/3mm.jpg';
import Img4mm from '../images/dental/4mm.jpg';

import Img1mmb from '../images/dental/1mmb.jpg';
import Img15mmb from '../images/dental/1-5mmb.jpg';
import Img2mmb from '../images/dental/2mmb.jpg';
import Img3mmb from '../images/dental/3mmb.jpg';
import Img4mmb from '../images/dental/4mmb.jpg';

import T11 from '../images/dental/11.png';
import T11b from '../images/dental/11b.png';
import T12 from '../images/dental/12.png';
import T12b from '../images/dental/12b.png';
import T13 from '../images/dental/13.png';
import T13b from '../images/dental/13b.png';
import T14 from '../images/dental/14.png';
import T14b from '../images/dental/14b.png';
import T15 from '../images/dental/15.png';
import T15b from '../images/dental/15b.png';
import T16 from '../images/dental/16.png';
import T16b from '../images/dental/16b.png';
import T17 from '../images/dental/17.png';
import T17b from '../images/dental/17b.png';
import T18 from '../images/dental/18.png';
import T18b from '../images/dental/18b.png';

import T21 from '../images/dental/21.png';
import T21b from '../images/dental/21b.png';
import T22 from '../images/dental/22.png';
import T22b from '../images/dental/22b.png';
import T23 from '../images/dental/23.png';
import T23b from '../images/dental/23b.png';
import T24 from '../images/dental/24.png';
import T24b from '../images/dental/24b.png';
import T25 from '../images/dental/25.png';
import T25b from '../images/dental/25b.png';
import T26 from '../images/dental/26.png';
import T26b from '../images/dental/26b.png';
import T27 from '../images/dental/27.png';
import T27b from '../images/dental/27b.png';
import T28 from '../images/dental/28.png';
import T28b from '../images/dental/28b.png';

import T31 from '../images/dental/31.png';
import T31b from '../images/dental/31b.png';
import T32 from '../images/dental/32.png';
import T33 from '../images/dental/33.png';
import T34 from '../images/dental/34.png';
import T35 from '../images/dental/35.png';
import T36 from '../images/dental/36.png';
import T37 from '../images/dental/37.png';
import T38 from '../images/dental/38.png';

import T32b from '../images/dental/32b.png';
import T33b from '../images/dental/33b.png';
import T34b from '../images/dental/34b.png';
import T35b from '../images/dental/35b.png';
import T36b from '../images/dental/36b.png';
import T37b from '../images/dental/37b.png';
import T38b from '../images/dental/38b.png';

import T41 from '../images/dental/41.png';
import T42 from '../images/dental/42.png';
import T43 from '../images/dental/43.png';
import T44 from '../images/dental/44.png';
import T45 from '../images/dental/45.png';
import T46 from '../images/dental/46.png';
import T47 from '../images/dental/47.png';
import T48 from '../images/dental/48.png';
import T41b from '../images/dental/41b.png';
import T42b from '../images/dental/42b.png';
import T43b from '../images/dental/43b.png';
import T44b from '../images/dental/44b.png';
import T45b from '../images/dental/45b.png';
import T46b from '../images/dental/46b.png';
import T47b from '../images/dental/47b.png';
import T48b from '../images/dental/48b.png';
import { callingAPI } from '../config';
function LabDetailsView() {
	const [ removableList, setremovableList ] = useState([]);
	const [ hospitalData, setHospitalData ] = useState([]);
	const [ doctorData, setDoctorData ] = useState([]);
	const [ patientData, setPatientData ] = useState([]);
	const [ labData, setLabData ] = useState([]);
	const [ formDetailsOne, setformDetailsOne ] = useState([]);
	const [ notes, setNotes ] = useState([]);
	const [ collectionAgent, setCollectionAgent ] = useState('');
	const [ orderDate, setorderDate ] = useState('');
	const [ requireDate, setRequireDate ] = useState('');
	const [ mBleachingTray, setBleachingTray ] = useState(false);
	const [ precisionAttachment, setPrecisionAttachment ] = useState(false);
	const [ telescopicCrowns, setTelescopicCrowns ] = useState(false);
	const [ castPartial, setCastPartia ] = useState(false);
	const [ castPartialOne, setCastPartialOne ] = useState(false);
	const [ BPSWithLogo, setBPSWithLogo ] = useState(false);
	const [ BPSwithoutLogo, setBPSWithoutLogo ] = useState(false);
	const [ sunflex, setSunflex ] = useState(false);
	const [ valplast, setValplast ] = useState(false);
	const [ breFlex, setBreFlex ] = useState(false);
	const [ breCrystal, setBreCrysta ] = useState(false);
	const [ bioDentaplast, setBioDentaplast ] = useState(false);
	const [ biteBlock, setBiteBlock ] = useState(false);
	const [ lightCureSpecialTray, setLightCureSpecialTray ] = useState(false);
	const [ Impression, setImpression ] = useState(false);
	const [ Bite, setBite ] = useState(false);
	const [ articulator, setArticulator ] = useState(false);
	const [ shadeTab, setShadeTab ] = useState(false);
	const [ shadeTooth, setShadeTooth ] = useState(false);
	const [ preOpModels, setPreOpModels ] = useState(false);
	const [ modelwithTemporaryon, setModelwithTemporaryon ] = useState(false);
	const [ additionalModel, setAdditionalModel ] = useState(false);
	const [ oldWork, setOldWork ] = useState(false);
	const [ trayEnclosed, setTrayEnclosed ] = useState(false);
	const [ implantScrewDriver, setImplantScrewDriver ] = useState(false);
	const [ lightCureClosedSpecialTray, setLightCureClosedSpecialTray ] = useState(false);
	const [ lightCureOpen, setLightCureOpen ] = useState(false);
	const [ implantSpecialBite, setImplantSpecialBite ] = useState(false);
	const [ implantVerificationJig, setImplantVerificationJig ] = useState(false);
	const [ hybridDenture, setHybridDenture ] = useState(false);
	const [ baramp, setbaramp ] = useState(false);
	const [ ballAttachment, setBallAttachment ] = useState(false);
	const [ dentureMilled, setDentureMilled ] = useState(false);
	const [ frameWorkforCeramic, setFrameWorkforCeramic ] = useState(false);
	const [ playSafe, setPlaySafe ] = useState(false);
	const [ silencer, setSilencer ] = useState(false);
	const [ sportsGuardColor, setSportsGuardColor ] = useState(false);
	const [ orthoRetentionPlate, setOrthoRetentionPlate ] = useState(false);
	const [ erkolocPro, setErkolocPro ] = useState(false);
	const [ oralScreen, setOralScreen ] = useState(false);
	const [ anteriorBitePlane, setAnteriorBitePlane ] = useState(false);
	const [ michiganSplint, setMichiganSplint ] = useState(false);
	const [ upper, setUpper ] = useState(false);
	const [ lower, setlower ] = useState(false);
	const [ fullAcrylic, setFullAcrylic ] = useState(false);
	const [ temporary, setTemporary ] = useState(false);
	const [ fullAnatomy, setFullAnatomy ] = useState(false);
	const [ whiteWax, setWhiteWax ] = useState(false);
	const [ redWax, setRedWax ] = useState(false);
	const [ frameTrial, setFrameTria ] = useState(false);
	const [ teethSettingTrial, setTeethSettingTrial ] = useState(false);
	const [ CPD, setCPD ] = useState(false);
	const [ digitalPhoto, setDigitalPhoto ] = useState(false);
	const [ OpposingTeethtobeRestoredLater, setOpposingTeethtobeRestoredLater ] = useState(false);
	const [ no, setno ] = useState(false);
	const [ rush, setRush ] = useState(false);
	const [softSplint,setSoftSplint]=useState('');
	const [teethImage,setTeethImage]=useState('');
	const [ name, setName ] = useState({
		firstname: '',
		last_name: '',
		phone_number: '',
	});
	
	//print
	const printFunction = () => {
		document.getElementById('cancel').style.display = 'none';
		document.getElementById('print').style.display = 'none';
		window.print();
		document.getElementById('cancel').style.display = 'inline-block';
		document.getElementById('print').style.display = 'inline-block';
	};
	// const onChangeBleachingTray = (event) => {
	// 	setBleachingTray(event.target.checked);
	// };
	const labDetailsView = () => {
		const postData = {
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			serviceProviderId: window.sessionStorage.getItem('prvId'),
			labRequestId: window.sessionStorage.getItem('editId'),
		};
		callingAPI('commonUserFunctions/dentalFormsOne', postData)
			.then((data) => {
				if (data.data.success === '1') {
					setremovableList(data.data.result);
					setHospitalData(data.data.result.hospitalDetails);
					setDoctorData(data.data.result.doctorDetails);
					setPatientData(data.data.result.memberDetails);
					setLabData(data.data.result.labDetails);
					setformDetailsOne(data.data.result.formDetailsOne);
					setNotes(data.data.result.formDetailsOne[0].notes);
					setCollectionAgent(data.data.result.formDetailsOne[0].marketingEx);
					setorderDate(data.data.result.formDetailsOne[0].labTestRequestsOrderDate);
					setRequireDate(data.data.result.formDetailsOne[0].labTestRequestsRequiredDate);
					setName(data.data.result.doctorDetails[0]);
					setBleachingTray(data.data.result.formDetailsOne[0].BleachingTray);
					setPrecisionAttachment(data.data.result.formDetailsOne[0].Precision_Attachment);
					setTelescopicCrowns(data.data.result.formDetailsOne[0].Telescopic_Crowns_and_Over_Denturies);
					setCastPartia(data.data.result.formDetailsOne[0].Cast_Partial_Denture__Titanium);
					setCastPartialOne(data.data.result.formDetailsOne[0].Cast_Partial_Denture__Co_Cr_);
					setBPSWithLogo(data.data.result.formDetailsOne[0].Bwith_Logo);
					setBPSWithoutLogo(data.data.result.formDetailsOne[0].BPSWT_Logo);
					setSunflex(data.data.result.formDetailsOne[0].Sunflex);
					setValplast(data.data.result.formDetailsOne[0].Valplast);
					setBreFlex(data.data.result.formDetailsOne[0].Bre_Flex);
					setBreCrysta(data.data.result.formDetailsOne[0].NBre_Crystal);
					setBioDentaplast(data.data.result.formDetailsOne[0].Bio_Dentaplast);
					setBiteBlock(data.data.result.formDetailsOne[0].Bite_Block);
					setLightCureSpecialTray(data.data.result.formDetailsOne[0].Light_Cure_Special_Tray);
					setImpression(data.data.result.formDetailsOne[0].Impression);
					setBite(data.data.result.formDetailsOne[0].NBite);
					setArticulator(data.data.result.formDetailsOne[0].Articulator);
					setShadeTab(data.data.result.formDetailsOne[0].Shade_Tab);
					setShadeTooth(data.data.result.formDetailsOne[0].shadeTooth);
					setPreOpModels(data.data.result.formDetailsOne[0].Pre_Op_Models);
					setModelwithTemporaryon(data.data.result.formDetailsOne[0].Model_with_Temporary_on);
					setAdditionalModel(data.data.result.formDetailsOne[0].Additional_Model);
					setOldWork(data.data.result.formDetailsOne[0].Old_Work__adjustment_Remake);
					setTrayEnclosed(data.data.result.formDetailsOne[0].Tray_Enclosed);
					setImplantScrewDriver(data.data.result.formDetailsOne[0].implanScrew_Driver);
					setLightCureClosedSpecialTray(data.data.result.formDetailsOne[0].L_ightCureClosedSpecialTray);
					setLightCureOpen(data.data.result.formDetailsOne[0].Li_ghtCureOpenSpecialTray);
					setImplantSpecialBite(data.data.result.formDetailsOne[0].ImplantSpecialBite);
					setImplantVerificationJig(data.data.result.formDetailsOne[0].NimplantSpecificationJig);
					setHybridDenture(data.data.result.formDetailsOne[0].HybridDenture);
					setbaramp(data.data.result.formDetailsOne[0].BarAndClipOverDentur);
					setBallAttachment(data.data.result.formDetailsOne[0].BallAttachment);
					setDentureMilled(data.data.result.formDetailsOne[0].DCHybrid_Ti_DentureMilled);
					setFrameWorkforCeramic(data.data.result.formDetailsOne[0].D_CHybrid_Milled);
					setPlaySafe(data.data.result.formDetailsOne[0].PlaySafe);
					setSilencer(data.data.result.formDetailsOne[0].Silencer_SL);
					setSportsGuardColor(data.data.result.formDetailsOne[0].SportsGuardColor);
					setOrthoRetentionPlate(data.data.result.formDetailsOne[0].OrthoRetentionPlate);
					setErkolocPro(data.data.result.formDetailsOne[0].Erkoloc_Pro);
					setOralScreen(data.data.result.formDetailsOne[0].Oral_Screen);
					setAnteriorBitePlane(data.data.result.formDetailsOne[0].AnteriorBite);
					setMichiganSplint(data.data.result.formDetailsOne[0].Michigan);
					setUpper(data.data.result.formDetailsOne[0].Upper);
					setlower(data.data.result.formDetailsOne[0].Lower);
					setFullAcrylic(data.data.result.formDetailsOne[0].Acrylic_Heat);
					setTemporary(data.data.result.formDetailsOne[0].Temporary_Self);
					setFullAnatomy(data.data.result.formDetailsOne[0].Anatomy_Mockup);
					setWhiteWax(data.data.result.formDetailsOne[0].TeethSetWithWhite_Wax);
					setRedWax(data.data.result.formDetailsOne[0].RedWax);
					setFrameTria(data.data.result.formDetailsOne[0].Frame_Trial);
					setDigitalPhoto(data.data.result.formDetailsOne[0].Photo_Mail);
					setOpposingTeethtobeRestoredLater(
						data.data.result.formDetailsOne[0].OpposingTeethtobeRestoredLater
					);
					setSoftSplint(data.data.result.formDetailsOne[0].Soft_Splint);
					
					setTeethImage(data.data.result.formDetailsOne[0].Teeth_Test);

					for(let j=0;j<=data.data.result.formDetailsOne[0].Teeth_Test.split(',').length;j++)
					{
						document.getElementById('teethT'+data.data.result.formDetailsOne[0].Teeth_Test.split(',')[j]+'B').style.display = "block"
						document.getElementById('teethT'+data.data.result.formDetailsOne[0].Teeth_Test.split(',')[j]+'W').style.display = "none"
						
					}
				
					for(let i=0;i<=data.data.result.formDetailsOne[0].Soft_Splint.split(',').length;i++)
					{

						document.getElementById('teethT'+data.data.result.formDetailsOne[0].Soft_Splint.split(',')[i]+'B').style.display = "block"
						document.getElementById('teethT'+data.data.result.formDetailsOne[0].Soft_Splint.split(',')[i]+'W').style.display = "none"
						
					}
					
				}
			})
			.catch();
	};
	useEffect(() => {
		labDetailsView();
	}, []);
	
	return (
		<body style={{ margin: '0', fontSize: '11px', fontWeight: '300', color: '#000' }}>
			<form>
				<div id="parent-selector">
					<div
						class="instructionFormWrap"
						style={{
							width: '980px',
							position: 'relative',
							background: '#fff',
							margin: 'auto',
							padding: '5px 0px',
							boxSizing: 'borderBox',
							zIndex: '1',
						}}
					>
						<div
							class="formTop"
							style={{ clear: 'both', overflow: 'hidden', borderBottom: '1px solid #252525' }}
						>
							{hospitalData.map((data) => (
								<div>
									<div class="slNo" style={{ width: '15%', float: 'left', color: '#ff0000' }}>
										<span style={{ float: 'left' }}>Sl No:</span>
										<span
											style={{
												fontWeight: '800',
												fontSize: '15px',
												float: 'left',
												paddingLeft: '5px',
											}}
										/>
									</div>
									<div class="formName" style={{ width: '70%', float: 'left', textAlign: 'center' }}>
										<div
											class="title"
											style={{ fontSize: '25px', fontWeight: '600', color: '#0091d0' }}
										>
											<span style={{ color: '#ff0000', verticalAlign: 'top' }} />{' '}
											{data.hospitalName}
										</div>
										<div class="address" style={{ margin: '3px 0' }}>
											{data.hospitalCity}
										</div>
									</div>
									<div class="logo2" style={{ width: '15%', float: 'left', textAlign: 'right' }}>
										<img src={data.hospitalLogo} style={{ width: '100%' }} />
									</div>
								</div>
							))}

							<div
								class="mailPortion"
								style={{
									clear: 'both',
									overflow: 'hidden',
									fontSize: '0px',
									textAlign: 'center',
									paddingTop: '2px',
								}}
							>
								{/* transform: skew(15deg), -webkit-transform: skew(15deg), -moz-transform: skew(15deg), -ms-transform: skew(15deg), -o-transform: skew(15deg), display: inline-block,  fontSize: 10px, float: left, */}
								{/* <div
									class="mailId"
									style={{
										display: 'inline - block',
										marginRight: '5px',
										padding: '2px 7px',
										background: '#e31e24',
										color: ' #fff',
										transform: 'skew(-15deg)',
										verticalAlign: 'top',
									}}
								>
									<span
										style={{
											transform: 'skew(15deg)',
											display: 'inline-block',
											fontSize: '10px',
											float: 'left',
										}}
									>
										Doctor Name:
									</span>
									<span
										style={{
											transform: 'skew(15deg)',
											display: 'inline-block',
											fontSize: '10px',
											float: 'left',
											paddingLeft: '3px',
										}}
									>
										Dr. JOHN CHERIAN
									</span>
								</div> */}
								{doctorData.map((data) => (
									<div>
										<div
											class="mailId"
											style={{
												display: 'inline-block',
												marginRight: '5px',
												padding: ' 2px 7px',
												background: '#e31e24',
												color: ' #fff',
												transform: 'skew(-15deg)',
												verticalAlign: 'top',
											}}
										>
											<span
												style={{
													transform: 'skew(15deg)',
													display: 'inline-block',
													fontSize: '10px',
													float: 'left',
												}}
											>
												Doctor Name:
											</span>
											<span
												style={{
													transform: 'skew(15deg)',
													display: 'inline-block',
													fontSize: '10px',
													float: 'left',
													paddingLeft: '3px',
												}}
											>
												{data.doctorFirstName} {data.doctorLastName}
											</span>
										</div>
										<div
											class="mailId"
											style={{
												display: 'inline-block',
												marginRight: '5px',
												padding: '2px 7px',
												background: '#e31e24',
												color: '#fff',
												transform: 'skew(-15deg)',
												verticalAlign: 'top',
											}}
										>
											<span
												style={{
													transform: 'skew(15deg)',
													display: 'inline-block',
													fontSize: '10px',
													float: 'left',
												}}
											>
												Email Id:
											</span>
											<span
												style={{
													transform: 'skew(15deg)',
													display: 'inline-block',
													fontSize: '10px',
													float: 'left',
													paddingLeft: '3px',
												}}
											>
												{data.email}
											</span>
										</div>
										<div
											class="whatsAppNo"
											style={{
												display: 'inline-block',
												padding: '2px 7px',
												background: '#e31e24',
												color: ' #fff',
												transform: 'skew(-15deg)',
												verticalAlign: 'top',
											}}
										>
											<span
												style={{
													transform: 'skew(15deg)',
													display: 'inline-block',
													fontSize: '10px',
													float: 'left',
												}}
											>
												Phone <span style={{ fontSize: '9px', verticalAlign: 'bottom' }} /> :
											</span>
											<span
												style={{
													transform: 'skew(15deg)',
													display: ' inline-block',
													fontSize: '10px',
													float: 'left',
													paddingLeft: '3px',
												}}
											>
												{data.doctorMobileNumber}
											</span>
										</div>
									</div>
								))}
							</div>
						</div>

						<div class="instructionDetails">
							<div
								class="heading"
								style={{ textAlign: 'center', paddingTop: '10px', fontSize: '19px', color: '#0091d0' }}
							>
								INSTRUCTIONS FOR REMOVABLE PROSTHESIS
							</div>
							<div class="detailWrap" style={{ clear: 'both', overflow: 'hidden' }}>
								<div class="doctorDetail" style={{ width: '540px', float: 'left' }}>
									<span
										style={{
											fontWeight: '600',
											color: '#000',
											paddingBottom: '8px',
											display: 'block',
										}}
									>
										LAB DETAILS
									</span>
									<div
										class="doctorDes"
										style={{
											border: '1px solid #252525',
											padding: '2px',
											clear: 'both',
											overflow: 'hidden',
											height: '135px',
										}}
									>
										<div
											class="drImg"
											style={{
												width: '160px',
												float: 'left',
												height: '129px',
												border: '1px solid #252525',
												boxSizing: 'borderBox',
											}}
										>
											<span
												style={{
													textAlign: 'center',
													display: 'block',
													width: '62px',
													margin: 'auto',
													fontSize: '11px',
													paddingTop: '43px',
												}}
											>
												Barcode Sticker
											</span>
										</div>
										{labData.map((data) => (
											<div
												class="drDetail"
												style={{
													width: '374px',
													float: 'left',
													paddingLeft: '15px',
													boxSizing: 'borderBox',
												}}
											>
												<span style={{ display: 'block', fontSize: '8px' }} />
												<div style={{ paddingTop: '3px', lineHeight: '21px' }}>
													<span style={{ float: 'left', paddingRight: '3px' }}>Name : </span>
													<div style={{ wordBreak: 'breakWord', width: '358px' }}>
														{data.labName}
													</div>
												</div>

												<div style={{ paddingTop: '3px', lineHeight: '21px' }}>
													<span style={{ float: 'left', paddingRight: '3px' }}>Place : </span>
													<div style={{ wordBreak: 'breakWord', width: '358px' }}>
														{data.labName}
													</div>
												</div>
												<div style={{ paddingTop: '3px', lineHeight: '21px' }}>
													<span style={{ float: 'left', paddingRight: '3px' }}>Phone : </span>
													<div style={{ wordBreak: 'breakWord', width: '358px' }}>
														{data.labPhoneNumber}
													</div>
												</div>
												<div style={{ paddingTop: '3px', lineHeight: '21px' }}>
													<span style={{ float: 'left', paddingRight: '3px' }}>Email : </span>
													<div style={{ wordBreak: 'breakWord', width: '358px' }}>
														{data.labEmailId}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
								<div
									class="patientDetail"
									style={{ width: '425px', float: 'left', marginLeft: '15px' }}
								>
									<span
										style={{
											fontWeight: '600',
											color: '#000',
											paddingBottom: '8px',
											display: 'block',
											textAlign: 'right',
										}}
									>
										PATIENT'S DETAILS
									</span>
									<div
										class="doctorDes"
										style={{
											border: '1px solid #252525',
											padding: '2px',
											clear: 'both',
											overflow: 'hidden',
										}}
									>
										{patientData.map((data) => (
											<div
												class="drDetail"
												style={{
													width: '420px',
													float: 'left',
													paddingLeft: '15px',
													boxSizing: ' borderBox',
													height: '129px',
												}}
											>
												<span style={{ display: 'block', fontSize: '8px' }} />
												<div style={{ paddingTop: '3px', lineHeight: '21px' }}>
													<span style={{ float: 'left', paddingRight: '3px' }}>Name : </span>
													<div style={{ wordBreak: 'breakWord', width: '400px' }}>
														{data.memberFirstName} {data.memberLastName}
													</div>
												</div>
												<div
													style={{
														paddingTop: '3px',
														lineHeight: '21px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div style={{ float: 'left', width: '237px' }}>
														<span style={{ float: 'left', paddingRight: '3px' }}>
															Gender :{' '}
														</span>
														<div style={{ wordBreak: 'breakWord', width: '230px' }}>
															{data.memberGender}
														</div>
													</div>
													<div style={{ float: 'right', width: '168px' }}>
														<span style={{ float: 'left' }}>Order Date :</span>
														<div> {orderDate}</div>
													</div>
												</div>
												<div
													style={{
														paddingTop: '3px',
														lineHeight: '21px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div style={{ float: 'left', width: '222px' }}>
														<span style={{ float: 'left' }}>Age :</span>
														<div>-{data.memberAge}</div>
													</div>
													<div style={{ float: 'right', width: '183px' }}>
														<span style={{ float: 'left' }}>Required Date :</span>
														<div style={{ float: 'left' }} />
														{requireDate}
													</div>
												</div>
												<div
													style={{
														paddingTop: '3px',
														lineHeight: '21px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div
														style={{
															float: 'right',
															width: '128px',
														}}
													>
														<input
														 checked={rush}
															type="checkbox"
															// checked=""
															style={{
																margin: '4px 3px 0 0px',
																float: 'left',
															}}
															disabled="disabled"
														/>

														<div> Rush(10 % extra)</div>
													</div>
												</div>
												<div
													style={{
														paddingTop: '3px',
														lineHeight: '21px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<span
														style={{
															display: 'block',
															textAlign: 'right',
															paddingRight: '8px',
														}}
													>
														NB : *Give us more time to avail extra benefit.
													</span>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						<div class="formHalfCheck">
							<div class="formContent" style={{ clear: 'both', overflow: 'hidden' }}>
								<div
									style={{
										width: '500px',
										boxSizing: 'borderBox',
										paddingTop: '10px',
										float: 'left',
										marginRight: '5px',
										boxSizing: 'borderBox',
									}}
								>
									<div
										style={{
											width: '100%',
											textAlign: 'center',
											padding: '6px 0px',
											fontSize: '15px',
											color: '#ff0000',
											fontWeight: '600',
										}}
									>
										Removables
									</div>
									<div
										style={{
											border: '1px solid #000',
											width: '250px',
											padding: '5px',
											float: 'left',
											boxSizing: 'borderBox',
											height: '285px',
										}}
									>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												type="checkbox"
												value="1"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Precision Attachment</span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={precisionAttachment}
												type="checkbox"
												value="2"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Telescopic Crowns &amp, Over Dentures</span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={telescopicCrowns}
												type="checkbox"
												value="3"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Cast Partial Denture (Titanium)</span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={castPartial}
												type="checkbox"
												value="4"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Cast Partial Denture (Co-Cr)</span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={castPartialOne}
												type="checkbox"
												value="5"
												style={{ margin: ' 0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>BPS with Logo</span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={BPSWithLogo}
												type="checkbox"
												value="7"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>BPS without Logo</span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={BPSwithoutLogo}
												type="checkbox"
												value="8"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Sunflex</span>
										</div>
									</div>

									<div
										style={{
											border: '1px solid #000',
											width: '250px',
											padding: '5px',
											float: 'left',
											borderLeft: '0',
											height: '285px',
											boxSizing: 'borderBox',
										}}
									>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={sunflex}
												type="checkbox"
												value="9"
												name="txtService"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Valplast </span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={valplast}
												type="checkbox"
												value="10"
												style={{ margin: ' 0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Bre Flex </span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={breFlex}
												type="checkbox"
												value="11"
												name="txtService"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Bre Crystal </span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={breCrystal}
												type="checkbox"
												value="12"
												name="txtService"
												style={{ margin: ' 0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Bio Dentaplast </span>
										</div>
										{/* <div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												type="checkbox"
												value="13"
												name="txtService"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled
											/>
											<span>Acrylic Denture </span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												type="checkbox"
												value="14"
												name="txtService"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Space Maintainer </span>
										</div> */}
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={bioDentaplast}
												type="checkbox"
												value="15"
												name="txtService"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Bite Block </span>
										</div>
										<div
											style={{
												width: '100%',
												paddingBottom: '12px',
												clear: 'both',
												overflow: 'hidden',
											}}
										>
											<input
												checked={biteBlock}
												type="checkbox"
												value="16"
												name="txtService"
												style={{ margin: '0px 5px 0 0px', float: 'left' }}
												disabled="disabled"
											/>
											<span>Light Cure Special Tray </span>
										</div>
									</div>
								</div>

								<div
									style={{
										width: '470px',
										boxSizing: 'borderBox',
										paddingTop: '10px',
										float: 'left',
										marginLeft: '5px',
										boxSizing: 'borderBox',
									}}
								>
									<div style={{ width: '235px', float: 'left', boxSizing: 'borderBox' }}>
										<div
											style={{
												width: '100%',
												padding: '6px 0px',
												fontSize: '15px',
												color: '#0091d0',
												fontWeight: '600',
											}}
										>
											Enclosures
										</div>
										<div
											style={{
												border: '1px solid #000',
												padding: '5px',
												height: '285px',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={lightCureSpecialTray}
													type="checkbox"
													value="18"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Impression </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={Impression}
													type="checkbox"
													value="19"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Bite </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={Bite}
													type="checkbox"
													value="20"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Articulator </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={articulator}
													type="checkbox"
													value="21"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Shade Tab </span>
											</div>

											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={shadeTab}
													type="checkbox"
													value="66"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Shade Tooth </span>
											</div>

											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={shadeTooth}
													type="checkbox"
													value="22"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Pre-Op Models </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={preOpModels}
													type="checkbox"
													value="23"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Model-with-Temporary-on </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={modelwithTemporaryon}
													type="checkbox"
													value="24"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Additional Model </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={oldWork}
													type="checkbox"
													value="25"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Old Work (adjust/Remake) </span>
											</div>
											<div
												style={{
													width: '100%',
													paddingBottom: '12px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={trayEnclosed}
													type="checkbox"
													value="26"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Tray Enclosed </span>
											</div>
										</div>
									</div>
									<div style={{ width: '235px', float: 'left', boxSizing: 'borderBox' }}>
										<div
											style={{
												width: '100%',
												padding: '6px 0px',
												fontSize: '15px',
												color: '#0091d0',
												fontWeight: '600',
											}}
										>
											Implant
										</div>

										<div
											style={{
												border: '1px solid #000',
												padding: '5px',
												borderLeft: '0',
												height: '285px',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													width: '100%',
													paddingBottom: '8px',
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<input
													checked={implantScrewDriver}
													type="checkbox"
													value="56"
													name="txtService"
													style={{ margin: '0px 5px 0 0px', float: 'left' }}
													disabled="disabled"
												/>
												<span>Implant Screw Driver</span>
											</div>
											{formDetailsOne.map((data) => (
												<div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span style={{ float: 'left' }}>No. of Impression Replica</span>
														<div
															style={{
																wordBreak: ' breakWord',
																float: 'left',
																paddingLeft: '5px',
															}}
														/>
														: {data.Impression_Replica}
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span style={{ float: 'left' }}>No. of Impression Coping</span>
														<div
															style={{
																wordBreak: 'breakWord',
																float: 'left',
																paddingLeft: '5px',
															}}
														/>
														: {data.Imp_Coping}
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span style={{ float: 'left' }}>No. of Abutment</span>
														<div
															style={{
																wordBreak: 'breakWord',
																float: 'left',
																paddingLeft: '5px',
															}}
														/>
														: {data.Abutment1}
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span style={{ float: 'left' }}>No. of Screw</span>
														<div
															style={{
																wordBreak: 'breakWord',
																float: 'left',
																paddingLeft: '5px',
															}}
														/>
														: {data.No_of_Screw}
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span style={{ float: 'left' }}>No. of Castable Abutment</span>
														<div
															style={{
																wordBreak: 'breakWord',
																float: 'left',
																paddingLeft: '5px',
															}}
														/>
														: {data.Abutment2}
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span style={{ float: 'left' }}>No. of Temporary Abutment</span>
														<div
															style={{
																wordBreak: 'breakWord',
																float: 'left',
																paddingLeft: '2px',
															}}
														/>
														: {data.Abutment3}
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '7px',
															clear: 'both',
															overflow: 'hidden',
															lineHeight: '21px',
														}}
													>
														<span style={{ float: 'left' }}>Others</span>
														<div
															style={{
																wordBreak: 'breakWord',
																float: 'left',
																paddingLeft: '10px',
															}}
														/>
														: {data.Others}
													</div>
												</div>
											))}
										</div>
									</div>
								</div>

								<div style={{ paddingTop: '10px', boxSizing: 'borderBox' }}>
									<div style={{ boxSizing: 'borderBox', width: '720px', float: 'left' }}>
										<div
											style={{
												width: '240px',
												height: '325px',
												boxSizing: 'borderBox',
												paddingTop: '10px',
												float: 'left',
												marginRight: '15px',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													width: '100%',
													textAlign: 'center',
													padding: '6px 0px',
													fontSize: '15px',
													color: '#0091d0',
													fontWeight: '600',
												}}
											>
												Implant - Denture
											</div>
											<div
												style={{
													border: '1px solid #000',
													width: '240px',
													padding: '5px',
													float: 'left',
													boxSizing: 'borderBox',
													height: '281px',
												}}
											>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={lightCureClosedSpecialTray}
														type="checkbox"
														value="27"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Light Cure Closed Special Tray</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={lightCureOpen}
														type="checkbox"
														value="28"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Light Cure Open Special Tray</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={implantSpecialBite}
														type="checkbox"
														value="29"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Implant Special Bite</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={implantVerificationJig}
														type="checkbox"
														value="30"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Implant Verification Jig</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={hybridDenture}
														type="checkbox"
														value="31"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Hybrid Denture</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={baramp}
														type="checkbox"
														value="32"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Bar &amp, Clip Over Denture</span>
												</div>

												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={ballAttachment}
														type="checkbox"
														value="65"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Ball Attachment Over Denture</span>
												</div>

												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={dentureMilled}
														type="checkbox"
														value="33"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>DC Hybrid (Ti) Denture Milled</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={frameWorkforCeramic}
														type="checkbox"
														value="34"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>DC Hybrid (Ti) (Frame Work for Ceramic) (Milled)</span>
												</div>
											</div>
										</div>
										<div
											style={{
												width: '200px',
												height: '325px',
												boxSizing: 'borderBox',
												paddingTop: '10px',
												float: 'left',
												marginRight: '15px',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													border: '1px solid #000',
													width: '200px',
													padding: '5px',
													float: 'left',
													boxSizing: 'borderBox',
													height: '310px',
												}}
											>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={playSafe}
														type="checkbox"
														value="35"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Play Safe</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={silencer}
														type="checkbox"
														value="36"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Silencer SL</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														type="checkbox"
														// value="37"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
														checked={mBleachingTray}
													/>
													<span>Bleaching Tray</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={sportsGuardColor}
														type="checkbox"
														value="38"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Sports Guard Color</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={orthoRetentionPlate}
														type="checkbox"
														value="39"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Ortho Retention Plate</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={erkolocPro}
														type="checkbox"
														value="40"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Erkoloc Pro</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={oralScreen}
														type="checkbox"
														value="41"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Oral Screen</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={anteriorBitePlane}
														type="checkbox"
														value="42"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Anterior Bite Plane</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={michiganSplint}
														type="checkbox"
														value="43"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Michigan Splint</span>
												</div>
												<div
													style={{
														width: '80%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
														margin: '0 auto',
													}}
												>
													<input
														checked={upper}
														type="checkbox"
														value="44"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Upper</span>
												</div>
												<div
													style={{
														width: '80%',
														paddingBottom: '10px',
														clear: 'both',
														overflow: 'hidden',
														margin: '0 auto',
													}}
												>
													<input
														checked={lower}
														type="checkbox"
														value="45"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Lower</span>
												</div>
											</div>
										</div>
										<div
											style={{
												width: '230px',
												height: '325px',
												boxSizing: 'borderBox',
												paddingTop: '10px',
												float: 'left',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													border: '1px solid #000',
													width: '230px',
													padding: '5px',
													float: 'left',
													boxSizing: 'borderBox',
													height: '310px',
												}}
											>
												<div
													style={{
														borderBottom: '1px solid #000',
														padding: '5px',
														boxSizing: 'borderBox',
														margin: '0 -5px 10px',
													}}
												>
													<div
														style={{
															width: '100%',
															paddingBottom: '5px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span>Soft Splint</span>
													</div>
													{/* <div
														style={{
															width: '100%',
															paddingBottom: '2px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<img
															src={Img1mm}
															style={{
																width: '38px',
																height: 'auto',
																cursor: 'pointer',
																float: 'left',
															}}
														/>

														<img
															src={Img15mm}
															style={{
																width: "'38px'",
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
															}}
														/>

														<img
															src={Img2mm}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
													</div> */}
													<div
														style={{
															width: '100%',
															paddingBottom: '2px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<img
															id="teethT1W"
															src={Img1mm}
															style={{
																width: '38px',
																height: 'auto',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
														{/*  1mm black begins */}
														<img
															id="teethT1B"
															src={Img1mmb}
															style={{
																width: '38px',
																height: 'auto',
																display: 'none',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
														{/*  1mm black ends */}

														<img
															id="teethT105W"
															src={Img15mm}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
														{/*  1-5mm black begins */}
														<img
															id="teethT105B"
															src={Img15mmb}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
																display: 'none',
															}}
														/>
														{/*  1-5mm black ends */}

														<img													
															id="teethT2W"
															src={Img2mm}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
														{/* 2mm black begins */}
														<img													
															id="teethT2B"
															src={Img2mmb}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
																display: 'none',
															}}
														/>
														{/* 2mm black ends */}
													</div>
													<div
														style={{
															width: '100%',
															padding: '5px 0',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<span>Sports Guard Clear</span>
													</div>
													<div
														style={{
															width: '100%',
															paddingBottom: '2px',
															clear: 'both',
															overflow: 'hidden',
														}}
													>
														<img															
															id="teethT3W"
															src={Img3mm}
															style={{
																width: '38px',
																height: 'auto',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
														{/* 3mm black begins */}
														<img															
															id="teethT3B"
															src={Img3mmb}
															style={{
																width: '38px',
																height: 'auto',
																cursor: 'pointer',
																float: 'left',
																display: 'none',
															}}
														/>

														{/* 3mm black ends */}

														<img														
															id="teethT4W"
															src={Img4mm}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
															}}
														/>
														{/*  4mm black begins */}
														<img														
															id="teethT4B"
															src={Img4mmb}
															style={{
																width: '38px',
																height: 'auto',
																marginLeft: '15px',
																cursor: 'pointer',
																float: 'left',
																display: 'none',
															}}
														/>
														{/* 4mm black ends */}
													</div>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														type="checkbox"
														checked={fullAcrylic}
														value="46"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Full Acrylic/Heat Cure</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={temporary}
														type="checkbox"
														value="47"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Temporary/Self Cure</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={fullAnatomy}
														type="checkbox"
														value="48"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Full Anatomy Mockup with White Wax</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={whiteWax}
														type="checkbox"
														value="49"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Teeth Set With White Wax</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={redWax}
														type="checkbox"
														value="50"
														name="txtService"
														style={{ margin: '0px 5px 0 0px', float: 'left' }}
														disabled="disabled"
													/>
													<span>Teeth Set With Red Wax</span>
												</div>
											</div>
										</div>
										<div
											style={{
												width: '250px',
												boxSizing: 'borderBox',
												paddingTop: '10px',
												float: 'left',
												marginRight: '15px',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													width: '250px',
													border: '1px solid #000',
													padding: '5px',
													float: 'left',
													boxSizing: 'borderBox',
													height: '90px',
												}}
											>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div style={{ display: 'block' }}>
														<span style={{ float: 'left', width: '120px' }}>
															Frame Trial
														</span>

														<input
															checked={frameTrial}
															type="checkbox"
															value="51"
															name="txtService"
															style={{ float: 'left', margin: '0px 5px 0 0px' }}
															disabled="disabled"
														/>

														<span style={{ float: 'left' }}>Date</span>
													</div>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div style={{ display: 'block' }}>
														<span style={{ float: 'left', width: '120px' }}>
															Teeth Setting Trial
														</span>

														<input
															type="checkbox"
															style={{ float: 'left', margin: '0px 5px 0 0px' }}
															disabled="disabled"
														/>

														<span style={{ float: 'left' }}>Date</span>
													</div>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div style={{ display: 'block' }}>
														<span style={{ float: 'left', width: '120px' }}>
															C.P.D Design
														</span>

														<input
															type="checkbox"
															name="txtService"
															style={{ margin: ' 0px 5px 0 0px', float: 'left' }}
															disabled="disabled"
														/>
														<span style={{ float: 'left', marginRight: '15px' }}>Yes</span>

														<input
															type="checkbox"
															name="txtService"
															style={{ margin: '0px 5px 0 0px', float: 'left' }}
															disabled="disabled"
														/>
														<span style={{ float: 'left', marginRight: '15px' }}>No</span>
													</div>
												</div>
											</div>
										</div>
										<div
											style={{
												width: '210px',
												boxSizing: 'borderBox',
												paddingTop: '10px',
												float: 'left',
												marginRight: '15px',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													width: '210px',
													border: '1px solid #000',
													padding: '5px',
													float: 'left',
													boxSizing: 'borderBox',
													height: '90px',
												}}
											>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
														color: 'red',
													}}
												>
													<span>Shade</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<input
														checked={digitalPhoto}
														type="checkbox"
														value="54"
														name="txtService"
														style={{ float: 'left', margin: '0px 5px 0 0px' }}
														disabled="disabled"
													/>

													<span style={{ float: 'left' }}>Digital Photo/Mail</span>
												</div>
											</div>
										</div>
										<div
											style={{
												width: '210px',
												boxSizing: 'borderBox',
												paddingTop: '10px',
												float: 'left',
												boxSizing: 'borderBox',
											}}
										>
											<div
												style={{
													width: '210px',
													border: '1px solid #000',
													padding: '5px',
													float: 'left',
													boxSizing: 'borderBox',
													height: '90px',
												}}
											>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: "'hidden'",
														color: 'red',
														textAlign: 'center',
													}}
												>
													<span
														style={{
															fontWeight: '600',
															display: 'inline-block',
															width: '145px',
														}}
													>
														Opposing Teeth to be Restored Later
													</span>
												</div>
												<div
													style={{
														width: '100%',
														paddingBottom: '12px',
														clear: 'both',
														overflow: 'hidden',
													}}
												>
													<div style={{ float: 'left' }}>
														<input
															type="checkbox"
															value="55"
															name="txtService"
															style={{ margin: '0px 5px 0 0px', float: 'left' }}
															disabled="disabled"
														/>
														<span style={{ float: 'left' }}>Yes</span>
													</div>
													<div style={{ float: 'right' }}>
														<input
															type="checkbox"
															name="txtService"
															style={{ margin: '0px 5px 0 0px', float: 'left' }}
															disabled="disabled"
														/>
														<span style={{ float: 'left' }}>No</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										style={{
											boxSizing: 'borderBox',
											width: '260px',
											float: 'left',
											height: '430px',
											padding: '30px',
										}}
									>
										<div
											style={{
												boxSizing: 'borderBox',
												width: '200px',
												height: '385px',
												position: 'relative',
											}}
										>
											<span
												style={{
													position: 'absolute',
													top: '178px',
													left: '0px',
													fontWeight: '600',
												}}
											>
												RIGHT
											</span>
											<span
												style={{
													position: 'absolute',
													top: '179px',
													left: '168px',
													fontWeight: '600',
												}}
											>
												LEFT
											</span>
											<span
												style={{
													position: 'absolute',
													top: '38px',
													left: '76px',
													textAlign: 'center',
													fontWeight: '600',
												}}
											>
												UPPER
											</span>
											<span
												style={{
													position: 'absolute',
													top: '317px',
													left: '75px',
													textAlign: 'center',
													fontWeight: '600',
												}}
											>
												LOWER
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '136px',
													left: '-17px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '8px',
														fontWeight: '600',
													}}
												>
													18
												</span>

												<img
													src={T18}													
													id="teethT18W"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/>
												<img
													src={T18b}
													id="teethT18B"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>

												{/* <img
													src={T18b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/> */}
											</span>

											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '201px',
													left: '-21px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '8px',
														fontWeight: '600',
													}}
												>
													48
												</span>

												{/* <img
													src={T48}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/> */}
												<img
													src={T48}												
													id="teethT48W"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT48B"
													src={T48b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '106px',
													left: ' -13px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '8px',
														fontWeight: '600',
													}}
												>
													17
												</span>

												{/* <img
													src={T17}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/> */}
												<img
													src={T17}
													id="teethT17W"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/>
												<img
													src={T17b}
													id="teethT17B"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>

											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '233px',
													left: '-15px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '8px',
														fontWeight: '600',
													}}
												>
													47
												</span>

												{/* <img
													src={T47}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/> */}
												<img
													src={T47}
													id="teethT47W"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/>
												<img
													src={T47b}
													id="teethT47B"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '79px',
													left: '-8px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '7px',
														fontWeight: '600',
													}}
												>
													16
												</span>

												<img
													src={T16}
													id="teethT16W"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/>
												<img
													src={T16b}
													id="teethT16B"
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
												{/* <img
													src={T16b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/> */}
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '262px',
													left: '-10px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '8px',
														fontWeight: '600',
													}}
												>
													46
												</span>

												<img
													id="teethT46W"
													src={T46}
													style={{
														width: '32px',
														height: '32px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T46b}
													id="teethT46B"
													style={{
														width: '32px',
														height: '32px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '55px',
													left: '1px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '5px',
														fontWeight: '600',
													}}
												>
													15
												</span>

												<img
													src={T15}
													id="teethT15W"
													style={{
														width: '32px',
														height: '29px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/>
												<img
													id="teethT15B"
													src={T15b}
													style={{
														width: '32px',
														height: '29px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '289px',
													left: '3px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '11px',
														fontWeight: '600',
													}}
												>
													45
												</span>

												<img
													id="teethT45W"
													src={T45}
													style={{
														width: '27px',
														height: '27px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT45B"
													src={T45b}
													style={{
														width: '27px',
														height: '27px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '31px',
													left: '10px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '2px',
														fontWeight: '600',
													}}
												>
													14
												</span>

												<img
													id="teethT14W"
													src={T14}
													style={{
														width: '33px',
														height: '33px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT14B"
													src={T14b}
													style={{
														width: '33px',
														height: '33px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '308px',
													left: '10px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														paddingTop: '15px',
														fontWeight: '600',
													}}
												>
													44
												</span>

												<img
													id="teethT44W"
													src={T44}
													style={{
														width: '26px',
														height: '26px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T44b}
													id="teethT44B"
													style={{
														width: '26px',
														height: '26px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '14px',
													left: '23px',
													marginLeft: '21px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														left: '-16px',
														top: '-5px',
													}}
												>
													13
												</span>

												<img
													src={T13}
													id="teethT13W"
													style={{ width: '24px', height: '26px', cursor: 'pointer' }}
												/>
												<img
													src={T13b}
													id="teethT13B"
													style={{
														width: '24px',
														height: '26px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '327px',
													left: '20px',
													marginLeft: '19px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '22px',
														left: '-10px',
													}}
												>
													43
												</span>

												<img
													src={T43}
													id="teethT43W"
													style={{ width: '24px', height: '25px', cursor: 'pointer' }}
												/>
												<img
													id="teethT43B"
													src={T43b}
													style={{
														width: '24px',
														height: '25px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '4px',
													left: '40px',
													marginLeft: '21px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														left: '-10px',
														top: '-15px',
													}}
												>
													12
												</span>

												<img
													id="teethT12W"
													src={T12}
													style={{ width: '24px', height: '25px', cursor: 'pointer' }}
												/>
												<img
													id="teethT12B"
													src={T12b}
													style={{
														width: '24px',
														height: '25px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '338px',
													left: '37px',
													marginLeft: '18px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														top: '28px',
														left: '-3px',
														fontWeight: '600',
													}}
												>
													42
												</span>

												<img
													id="teethT42W"
													src={T42}
													style={{ width: '24px', height: '25px', cursor: 'pointer' }}
												/>
												<img
													id="teethT42B"
													src={T42b}
													style={{
														width: '24px',
														height: '25px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '-1px',
													left: '59px',
													marginLeft: '21px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														left: '7px',
														top: '-15px',
														fontWeight: '600',
													}}
												>
													11
												</span>

												<img
													id="teethT11W"
													src={T11}
													style={{ width: '25px', height: '25px', cursor: 'pointer' }}
												/>
												<img
													src={T11b}
													id="teethT11B"
													style={{
														width: '25px',
														height: '25px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '340px',
													left: '56px',
													marginLeft: '20px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														top: '30px',
														left: '3px',
														fontWeight: '600',
													}}
												>
													41
												</span>

												<img
													id="teethT41W"
													src={T41}
													style={{ width: '23px', height: '27px', cursor: 'pointer' }}
												/>
												<img
													src={T41b}
													id="teethT41B"
													style={{
														width: '23px',
														height: '27px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '-2px',
													left: '79px',
													marginLeft: '21px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '-15px',
														left: '10px',
														display: 'none',
													}}
												>
													21
												</span>

												<img
													src={T21}
													id="teethT21W"
													style={{ width: '25px', height: '29px', cursor: 'pointer' }}
												/>
												<img
													src={T21b}
													id="teethT21B"
													style={{
														width: '25px',
														height: '29px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '340px',
													left: '75px',
													marginLeft: '19px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														top: '30px',
														left: '10px',
														fontWeight: '600',
													}}
												>
													31
												</span>

												<img
													id="teethT31W"
													src={T31}
													style={{ width: '24px', height: '26px', cursor: 'pointer' }}
												/>
												<img
													id="teethT31B"
													src={T31b}
													style={{
														width: '24px',
														height: '26px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '4px',
													left: '99px',
													marginLeft: '19px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '-13px',
														left: '20px',
													}}
												>
													22
												</span>

												<img
													id="teethT22W"
													src={T22}
													style={{ width: '25px', height: '28px', cursor: 'pointer' }}
												/>
												<img
													id="teethT22B"
													src={T22b}
													style={{
														width: '25px',
														height: '28px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '335px',
													left: '94px',
													marginLeft: '18px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '30px',
														left: '15px',
													}}
												>
													32
												</span>

												<img
													id="teethT32W"
													src={T32}
													style={{ width: '24px', height: '25px', cursor: 'pointer' }}
												/>
												<img
													id="teethT32B"
													src={T32b}
													style={{
														width: '24px',
														height: '25px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '18px',
													left: '115px',
													marginLeft: '18px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '-8px',
														left: '29px',
													}}
												>
													23
												</span>

												<img
													id="teethT23W"
													src={T23}
													style={{ width: '26px', height: '26px', cursor: 'pointer' }}
												/>
												<img
													id="teethT23B"
													src={T23b}
													style={{
														width: '26px',
														height: '26px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '329px',
													left: '110px',
													marginLeft: '22px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '25px',
														left: '20px',
													}}
												>
													33
												</span>

												<img
													id="teethT33W"
													src={T33}
													style={{ width: '24px', height: '24px', cursor: 'pointer' }}
												/>
												<img
													id="teethT33B"
													src={T33b}
													style={{
														width: '24px',
														height: '24px',
														cursor: 'pointer',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '37px',
													left: '126px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '2px',
														left: '50px',
													}}
												>
													24
												</span>

												<img
													id="teethT24W"
													src={T24}
													style={{
														width: '29px',
														height: '29px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T24b}
													id="teethT24B"
													style={{
														width: '29px',
														height: '29px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '305px',
													left: '119px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '21px',
														left: '47px',
													}}
												>
													34
												</span>

												<img
													src={T34}
													id="teethT34W"
													style={{
														width: '27px',
														height: '27px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T34b}
													id="teethT34B"
													style={{
														width: '27px',
														height: '27px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '60px',
													left: '135px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '5px',
														left: '50px',
													}}
												>
													25
												</span>

												<img
													src={T25}
													id="teethT25W"
													style={{
														width: '29px',
														height: '29px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T25b}
													id="teethT25B"
													style={{
														width: '29px',
														height: '29px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '286px',
													left: '128px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '16px',
														left: '50px',
													}}
												>
													35
												</span>

												<img
													id="teethT35W"
													src={T35}
													style={{
														width: '27px',
														height: '27px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T35b}
													id="teethT35B"
													style={{
														width: '27px',
														height: '27px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '85px',
													left: '140px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '6px',
														left: '54px',
													}}
												>
													26
												</span>

												<img
													id="teethT26W"
													src={T26}
													style={{
														width: '31px',
														height: '31px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT26B"
													src={T26b}
													style={{
														width: '31px',
														height: '31px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '260px',
													left: '135px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '13px',
														left: '55px',
													}}
												>
													36
												</span>

												<img
													id="teethT36W"
													src={T36}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT36B"
													src={T36b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '111px',
													left: '145px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '6px',
														left: '54px',
													}}
												>
													27
												</span>

												<img
													id="teethT27W"
													src={T27}
													style={{
														width: '31px',
														height: '31px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													src={T27b}
													id="teethT27B"
													style={{
														width: '31px',
														height: '31px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '232px',
													left: '142px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '17px',
														left: '55px',
													}}
												>
													37
												</span>

												<img
													id="teethT37W"
													src={T37}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT37B"
													src={T37b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '140px',
													left: '148px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '7px',
														left: '55px',
													}}
												>
													28
												</span>

												<img
													id="teethT28W"
													src={T28}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														color: 'red',
													}}
												/>
												<img
													id="teethT28B"
													src={T28b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
											<span
												style={{
													display: 'block',
													position: 'absolute',
													top: '199px',
													left: '146px',
												}}
											>
												<span
													style={{
														position: 'absolute',
														fontWeight: '600',
														top: '20px',
														left: '57px',
													}}
												>
													38
												</span>

												<img
													id="teethT38W"
													src={T38}
													style={{
														width: '40px',
														height: '40px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/>
												<img
													id="teethT38B"
													src={T38b}
													style={{
														width: '40px',
														height: '40px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'none',
													}}
												/>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="formBottom" style={{ paddingTop: '15px' }}>
							<div
								style={{ clear: 'both', overflow: 'hidden', lineHeight: '21px', marginBottom: '15px' }}
							>
								<span style={{ float: 'left', boxSizing: 'borderBox' }}>Notes : </span>
								<div
									style={{
										wordBreak: 'breakWord',
										boxSizing: 'borderBox',
										width: '95%',
										paddingLeft: '10px',
										float: 'left',
									}}
								/>
								{notes}
							</div>
							<div style={{ clear: 'both', overflow: 'hidden', marginTop: '5px' }}>
								<div style={{ float: 'left', width: '650px' }}>
									<span style={{ float: 'left' }}>
										In case of any clarification. Please contact Dr.{' '}
									</span>
									<div>
										{name.doctorFirstName} {name.doctorLastName}
									</div>
								</div>
								<div style={{ float: 'left', width: '330px' }}>
									<span style={{ float: 'left' }}>Contact No: </span>
									<div> {name.doctorMobileNumber}</div>
								</div>
							</div>
							<div style={{ clear: 'both', overflow: 'hidden', paddingTop: '10px' }}>
								<span style={{ float: 'left' }}>Collection Agent :{collectionAgent} </span>
							</div>
							<span style={{ float: 'right', paddingTop: '5px' }}>
								Name &amp, Signature of Doctor/ Clinic Staff
							</span>
						</div>
					</div>

					<div class="teethPrintBtn" style={{ paddingTop: '20px', textAlign: 'right' }}>
						<span
							id="print"
							onClick={printFunction}
							style={{
								display: 'inline-block',
								padding: '7px 20px',
								fontSize: '14px',
								background: '#2d1152',
								border: '0',
								color: '#fff',
								fontWeight: '600',
								cursor: 'pointer',
							}}
						>
							Print
						</span>

						<a
							id="cancel"
							href="/patients/labOrderDental"
							class="saveClr buttonsmall"
							tabindex="15"
							style={{
								textDecoration: 'none',
								display: 'inline-block',
								verticalAlign: 'top',
								marginRight: '10px',
								padding: '7px 20px',
								fontSize: '14px',
								background: '#dbdbdb',
								border: '0',
								color: '#000',
								fontWeight: '600',
								cursor: 'pointer',
							}}
						>
							Cancel
						</a>
					</div>
				</div>
			</form>
		</body>
	);
}

export default LabDetailsView;
