import React, { useState, useEffect } from "react";
import Footer from "../layout/footer";
import LoginHeader from "../layout/loginHeader";
function PrivacyPolicy() {

    return (
        <div>
            <LoginHeader />
            <div className="container">
                <div className="terms_wrapper">
                    <h3 className="mainhead_ida">Privacy Policy</h3>
                    <div className="termsHead">
                        General
                    </div>
                    <br />
                    <div className="termsHead">
                        To use any of our services users must be 18+ years of age and Citizens of India.

                    </div>
                    <br />
                    <div className="termsPara">
                        We take your Privacy Seriously. In general we only ask for, view and store data that is necessary to run our apps and websites and to facilitate engagement with Health Care Providers and Users and for the smooth running of the services..

                    </div>
                    <br />
                    <div className="termsPara">
                        We do our very best to follow all laws regarding your Data Privacy and we implement industry standards and best practices for your Data Security.

                    </div>
                    <br />
                    <div className="termsHead">
                        Definitions
                    </div>
                    <br />
                    <div className="termsPara">
                        “We”, “Our”, “Us” - refers to the owner(s) of this website or mobile app and its associated clinic.

                    </div>
                    <br />
                    <div className="termsPara">
                        “You”, “Your”, “User”, “Patient”, “Client” - refer to the individual User of the mobile app or website and its associated services.

                    </div>
                    <br />
                    <div className="termsPara">
                        “Health Care Provider” or “HCP” “Provider” - refers to Doctors, Clinicians, Therapists or any other health and medical professional, expert, consultant or practitioner that may be using the service.

                    </div>
                    <br />
                    <div className="termsPara">
                        “Health Care Partner”, “Affiliate”, “Partner”, “Service Provider” - refer to associated partners that we may engage with or whose services that we use such as health care providers, clinics, hospitals, labs, pharmacies, insurance companies, medical device & equipment providers, third party technology providers or any third party service providers that we may engage with in order to provide services for the User of the apps & website.

                    </div>
                    <br />
                    <div className="termsPara">
                        “Personal Information”, “Personally Identifiable Information”, “PII” - refer to information that you provide us that identifies you like:
                    </div>
                    <br />
                    <div>
                        <ul>
                            <li className="termsPara">Name, Phone Number, Home Address, Email address. </li>
                            <li className="termsPara">Financial Information like Bank Accounts</li>
                        </ul>
                    </div>
                    <br />
                    <div className="termsPara">
                        “Personal Health Information”, “PHI” - refer to information that is provided to us in regards to users’s health like:
                    </div>
                    <br />
                    <div>
                        <li className="termsPara">Medical information: age, gender, information on your medical conditions, information on your health related issues </li>
                    </div>
                    <br />
                    <div className="termsPara">
                        “Digital Health Platform”, “Platform” - refer to the mobile app or website and the underlying technology that Users, Health Care Partners and Health Care Providers use to engage each other in terms of either accessing or providing Digital Health Services.
                    </div>
                    <br />
                    <div className="termsPara">
                        “Digital Health Services”, “Health Services”, “Services” - Refers to the key functions offered by the Digital Health Platform eg. Booking an appointment with a doctor, Telehealth consultations, Sending of Prescriptions, Creation of an Electronic Health Records and any other medical or health related service enabled by the Platform
                    </div>
                    <br />
                    <div className="termsPara">
                        “Telehealth”, “Telemedicine” - refers to remote consultation between a patient and a Health Care Provider (eg. a doctor) via phone-call, video-call, email, or text/message chat.
                    </div>
                    <br />
                    <div className="termsHead">
                        Use of Personal Data and Personal Information That Users and HCP’s Provide To Us
                    </div>
                    <br />
                    <div className="termsPara">
                        In order to use our services, we require that Users and Health Care Providers give us personal information in order to do basic things like the following::
                    </div>
                    <br />
                    <div>
                        <ul>
                            <li className="termsPara">Create and Login to an account.</li>
                            <li className="termsPara">Send SMS, Phone Calls or emails for appointment reminders, calls or chats between an user and a Health Care Provider or and any such engagement between a patient and healthcare provider that requires knowledge of contact information for either of the parties.</li>
                            <li className="termsPara">Fulfill Telemedicine regulations whereby the doctor or health care provider is required to know the name, age, gender of the patient</li>
                            <li className="termsPara">Creation of Electronic Medical & Health Records for Doctors and Health Care Providers: User’s name, gender, illnesses and medical history, lab tests and similar health related information is needed for medical and health </li>
                            <li className="termsPara">professionals to have access to in order to provide health care and for the continuity of care.</li>
                            <li className="termsPara">Purchase of Health Services by using an User’s financial information, insurance information or government ID through online methods (like online payments using credit-cards/debit-cards, bank transfers or other online payment services)</li>
                            <li className="termsPara">Payment to HCP’s bank for purchased Health Care Services such as booking a telehealth appointment by User</li>
                            <li className="termsPara">Customer Service and Support requests whereby we have to contact the person requesting help.</li>
                            <li className="termsPara">Transmission of Personal Information to associated HCP’s whom Users have requested to engage with and who use the platform’s technologies in order fulfill services such as appointments, telehealth consultations, prescriptions or any other services.</li>
                            <li className="termsPara">We may at times personalize information and content so that it is relevant to Users and HCP’s  and share those recommendations. This may include relevant articles, content, information and potential advertisements of services or products.</li>
                            <li className="termsPara"><b>Note that we do not share or sell anyone’s personal information to advertisers.</b></li>

                        </ul>
                    </div>
                    <br />
                    <div className="termsHead">
                        Data Usage, Storage & Transmission
                    </div>
                    <br />
                    <div className="termsPara">
                        Some example of what we use application and device data access for:

                    </div>
                    <br />
                    <div className="termsPara">
                        <ul>
                            <li className="termsPara"> Media Storing & Transmission
                                <ul>
                                    <li className="termsPara">Users & HCP’s saving images and files of health care records. eg. a pdf of an x-ray, photo of a prescription etc…</li>
                                    <li className="termsPara">Sending and receiving digitized media through video-chats and messages, eg. a patient sends a doctor a pdf of an xray or a HCP sends a patient a photo of a prescription</li>
                                </ul>
                            </li>
                            <li className="termsPara">Analytics
                                <ul>
                                    <li className="termsPara">Transmission of Performance analytics to see if services are working properly</li>
                                    <li className="termsPara">Transmission of Crash & error logs to note application crashes and errors</li>
                                    <li className="termsPara">Transmission of Data showing usage levels of services</li>
                                </ul>
                            </li>
                            <li className="termsPara">
                                <ul>
                                    <li className="termsPara"></li>
                                    <li className="termsPara"></li>
                                </ul>
                            </li>
                            <li className="termsPara">Contacts
                                <ul>
                                    <li className="termsPara">Facilitate audio, video, email contact between Users & HCP’s. For example a doctor may need to call a patient or a lab may need to email a report and will save and access contact information. HCP’s may transmit contact information such as a Doctor to a Lab or a Doctor to another referred Doctor</li>
                                </ul>
                            </li>
                            <li className="termsPara">Other Data
                                <ul>
                                    <li className="termsPara">The use of technical non-personal data such as device or browser information which is used in order to improve the experience and functionality of our services as well as to identify and solve any technology related problems or issues.</li>
                                    <li className="termsPara">Storage of data to simplify login process. </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div className="termsPara">
                        <b>NOTE: WE DO NOT SELL PERSONAL IDENTIFIABLE INFORMATION TO DATA BROKERS OR ADVERTISERS, PHARMA OR INSURANCE COMPANIES</b>
                    </div>
                    <br />
                    <div className="termsHead">
                        Removal of Data

                    </div>
                    <br />
                    <div className="termsPara">
                        Should you wish to delete your Personal Information from your account please contact <a href="mailto:support@purplehealth.com">support@purplehealth.com</a> with your request. We do not guarantee the removal of Personal Health Information that have been shared with a HCP. In such an instance, you will have to contact the HCP directly
                    </div>
                    <br />
                    <div className="termsPara">
                        If you use our services, you agree to this Privacy Policy that has been laid out as well as our Terms Of Service for any relevant services that you may engage with.
                    </div>
                    <br />
                    <div className="termsHead">
                        Consent
                    </div>
                    <br />
                    <div className="termsPara">
                        You agree to the above terms and conditions of our privacy and data usage policy.
                    </div>
                    <br />

                </div>
            </div>
            <Footer />
        </div>

    );
}
export default PrivacyPolicy;
