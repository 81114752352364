import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { callingAPI } from '../config';
import moment from 'moment';
import Loader from '../Common/loader';
function DashboardMain(props) {
	const [popUpDoctorList, setPopUpDoactorList] = useState(false);
	const [popaler, setpopaler] = useState([]);
	const [memberIdNew, setMemberIdNew] = useState('');
	const [appointmentIns, setdashboardappointmentIns] = useState([{}]);
	const [admitted, setdashboardadmitted] = useState([]);
	const [paymentIns, setdashboardpayments] = useState([]);
	const [paymentsadmitted, setdashboardpaymentsadmitted] = useState([]);
	const [totalPendingAmount, setTotalPendingAmount] = useState("")
	const [dataFlag, setDataFlag] = useState(false);
	const [dataFlagAppointment, setDataFlagAppointment] = useState(false);
	const [AppointmentlaborderStatusIns, setDataAppointmentlaborderStatusIns] = useState([]);
	const [AdmittedlaborderStatusIns, setDataAdmittedlaborderStatusIns] = useState([]);
	//const [laborderStatusInsappoinment, setDataAdmittedlaborderStatusIns] = useState([]);
	const DashBoardAdmitted = () => {
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			date: moment().format('YYYY-MM-DD'),
			doctorId: window.sessionStorage.getItem('doctorId'),
			userId: window.sessionStorage.getItem('userId'),
		};
		callingAPI('dashboard/showDashBoardMethodAdmit', PostData).then((response) => {
			if (response.data.success === '1') {
				setDataFlag(true);
				setdashboardadmitted(response.data.result.appointmentIns);
				setdashboardpaymentsadmitted(response.data.result.paymentIns);
				setDataAppointmentlaborderStatusIns(response.data.result.orderIns);
				setDataAdmittedlaborderStatusIns(response.data.result.orderIns);
				setDataAdmittedlaborderStatusIns(response.data.result.orderIns);
			} else if (response.data.success === '0') {
				setDataFlagAppointment(false);
				setdashboardadmitted([]);
				setdashboardpaymentsadmitted([]);
				setDataAppointmentlaborderStatusIns([]);
				setDataAdmittedlaborderStatusIns([]);
			}
		});
	};
	const DashBoardAppointed = () => {
		var consentType = ""
		var consentTypeInput = ""
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') { consentType = window.sessionStorage.getItem('doctorId') }
		if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN') { consentTypeInput = localStorage.getItem("hospitalId") }
		let PostData = {
			hospitalId: consentTypeInput,
			date: moment().format('YYYY-MM-DD'),
			doctorId: consentType,
		};
		callingAPI('dashboard/dashboard', PostData).then((response) => {
			if (response.data.success === '1') {
				setDataFlagAppointment(true);
				setdashboardappointmentIns(response.data.result.appointmentIns);
				setdashboardpayments(response.data.result.paymentIns);
				setTotalPendingAmount(response.data.result.totalPendingAmount);
				setDataAppointmentlaborderStatusIns(response.data.result.orderIns);
				setDataAdmittedlaborderStatusIns(response.data.result.orderIns);
			} else if (response.data.success === '0') {
				setDataFlagAppointment(false);
				setdashboardappointmentIns([]);
				setdashboardpayments([]);
				setDataAppointmentlaborderStatusIns([]);
				setDataAdmittedlaborderStatusIns([]);
			}
		});
	};
	useEffect(() => {
		DashBoardAdmitted();
		DashBoardAppointed();
	}, []);
	const popups = (memberId, hospitId) => {
		popup(memberId, hospitId)
		// window.sessionStorage.setItem('memberId', memberId);
		// window.sessionStorage.setItem('patientDoctorId', window.sessionStorage.getItem('userId'));
		// localStorage.setItem('hospitalId', localStorage.getItem('hospitalId'));
		// window.sessionStorage.setItem('patientDoctorName', window.sessionStorage.getItem('userName'));
		// window.location.href = '/patients/patientconsult';
	};
	const popup = (memberId, hospitId) => {
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') {
			window.sessionStorage.setItem('memberId', memberId);
			window.sessionStorage.setItem('patientDoctorId', window.sessionStorage.getItem('userId'));
			localStorage.setItem('hospitalId', hospitId);
			window.sessionStorage.setItem('patientDoctorName', window.sessionStorage.getItem('userName'));
			// window.sessionStorage.setItem('patientDoctorSpecialation', popaler[index].specialization);
			window.location.href = '/patients/patientPayments';
		} else {
			setMemberIdNew(memberId);
			// setFlag(false);
			let PostData = {
				hospitalId: localStorage.getItem('hospitalId'),
				selectCombo: '',
				inputSearch: '',
			};
			callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
				if (response.data.success === '1') {
					// setFlag(true);
					setpopaler(response.data.result.doctorsListArray);
					setPopUpDoactorList(true);
				}
				else {
					alert("no doctors found")
				}
			});
		}
	};
	const closePopUp = () => {
		setPopUpDoactorList(false);
	};
	const hospitalpageChange = (index) => {
		if (index !== '') {
			window.sessionStorage.setItem('memberId', memberIdNew);
			window.sessionStorage.setItem('doctorId', popaler[index].doctorId);
			window.sessionStorage.setItem('medicineBranchId', popaler[index].medicineBranchId);
			window.sessionStorage.setItem('patientDoctorId', popaler[index].doctorId);
			localStorage.setItem('hospitalId', popaler[index].hospitalId);
			window.sessionStorage.setItem('patientDoctorName', popaler[index].doctorName);
			window.sessionStorage.setItem('patientDoctorSpecialation', popaler[index].specialization);
			window.location.href = '/patients/patientPayments';
		}
	};
	return (
		<div>
			<div className="coverWraper innerCoverWraper dashbrdMain">
				<Header />
				<div className="contentWraper contentWraperScroll">
					<div className="coverDiv">
						<Tabs>
							<TabList>
								<Tab>
									{' '}
									<div className="tabHead">Outpatient</div>
								</Tab>
								{/* <Tab>
									{' '}
									<div className="tabHead">Inpatient</div>
								</Tab> */}
							</TabList>
							<div>
								<TabPanel>
									<div class="tab-content">
										<div id="appointmentSpan" class="tab-pane   active  ">
											{dataFlagAppointment == false && (
												<div align="center">
													<Loader />
												</div>
											)}
											<div class="dashboardWrap row">
												<div class="col-lg-6 col-md-12">
													{(() => {
														if (appointmentIns.length > 0 && dataFlagAppointment == true) {
															return (
																<div class="dashboardLeft">

																	<div class="dashHead">Appointments</div>
																	<div>
																		<div class="appoinmentWrapCvr"
																		>
																			{appointmentIns.map((appointmentInslist, i) => (

																				<div class="appoinmentWrap  appoinmentWrapMainDiv" onClick={(e) => popups(appointmentInslist.emrPatientAppointmentMemberId, appointmentInslist.hospitalId)}>

																					<div

																						class="appoinmentDateDiv"
																						style={{
																							height: 'auto',
																							display: 'flex',
																						}}
																					>
																						<span class="apnmentDay apnmentDayTime">
																							{moment(
																								appointmentInslist.scheduledTimeSlotStartTime
																							).format(' hh:mm A ')}
																						</span>
																					</div>
																					<div class="appoinmentDateDetail">
																						<div class="dashBoardTopDetail">
																							<span class="dashBoardPatient elipsis">
																								{
																									appointmentInslist.secUserDisplayName
																								}
																							</span>
																							<span class="dashBoardHosName elipsis">
																								{
																									appointmentInslist.hospitalName
																								}
																							</span>
																						</div>
																						<div class="appoinmentLeftDiv">
																							{
																								appointmentInslist.emrPatientAppointmentVisitReason == "" ?
																									''
																									:
																									<span class="appoinmentReason">
																										<span>Reason :</span>
																										<span
																											title={
																												appointmentInslist.emrPatientAppointmentVisitReason
																											}
																											class="elipsis"
																											style={{
																												fontWeight:
																													'normal',
																											}}
																										>
																											{
																												appointmentInslist.emrPatientAppointmentVisitReason
																											}
																										</span>
																									</span>}
																						</div>
																						<div class="dashBoardContentDes">
																							<span class="dashBoardContent elipsis ">
																								MR No :&nbsp;
																								<span
																									class="elipsis"
																									title={
																										appointmentInslist.memberNo
																									}
																									style={{
																										fontWeight:
																											'normal',
																									}}
																								>
																									{
																										appointmentInslist.memberNo
																									}
																								</span>
																							</span>

																							<div class="dashBoardContentDes">
																								<span class="dashBoardContent elipsis ">
																									PR No :&nbsp;
																									<span
																										class="elipsis"
																										title={
																											appointmentInslist.prNumber
																										}
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										{appointmentInslist.prNumber != "" ?

																											appointmentInslist.prNumber : ""}

																									</span>
																								</span>
																							</div>

																							{
																								appointmentInslist.secUserEmailId == "" ?
																									''
																									:
																									<span class="dashBoardContent emailIdDashBoardContent  elipsis" title={
																										appointmentInslist.secUserEmailId
																									}>
																										<span class="elipsis" title="" style={{ fontWeight: 'normal' }} />
																										Email Id :&nbsp;<span style={{ fontWeight: 'normal' }}>{appointmentInslist.secUserEmailId}</span>
																									</span>
																							}
																							<span class="dashBoardContent mobNumDashBoardContent elipsis ">
																								Mobile No :&nbsp;
																								<span
																									class="elipsis"
																									title={
																										appointmentInslist.secUserMobileNumber
																									}
																									style={{
																										fontWeight:
																											'normal',
																									}}
																								>
																									{
																										appointmentInslist.secUserMobileNumber
																									}
																								</span>
																							</span>
																						</div>
																						<div class="appoinmentSatusWrap">
																							<span class="appoinmentSTatus">
																								Status :&nbsp;{appointmentInslist.emrPatientAppointmentStatusappStatus}
																							</span>
																							{
																								appointmentInslist.emrPatientAppointmentType == "" ?
																									''
																									:
																									<span class="appoinmentTYpe">
																										Type :{' '}
																										{appointmentInslist.emrPatientAppointmentType}
																									</span>
																							}
																							{/* <span class="appoinmentTYpe">
																							Lab Order :
																							<font
																								style={{
																									color:
																										'red',
																								}}
																							>
																							</font>
																						</span> */}
																							{
																								appointmentInslist.balanceAmount == 0 ||
																									appointmentInslist.balanceAmount == "" ?
																									''
																									:
																									<span class="appoinmentTYpe">
																										Payment :
																										<font
																											style={{
																												color:
																													'red',
																											}}
																										>
																											&nbsp;₹ {Math.trunc(appointmentInslist.balanceAmount * 100) / 100} Balance
																										</font>
																									</span>
																							}
																						</div>
																					</div>
																				</div>
																			))}
																		</div>

																	</div>
																</div>
															);
														} else if (
															appointmentIns.length == 0 &&
															dataFlagAppointment == true
														) {
															return (
																<div align="center">
																	<font style={{ color: 'green' }}>
																		No Appointments Today{' '}
																	</font>
																</div>
															);
														}
													})()}
												</div>
												{/* <div class="col-lg-6 col-md-12">
													<div class="dashboardBoxRight">
														{(() => {
															if (AppointmentlaborderStatusIns.length > 0 && dataFlagAppointment == true) {
																return (
																	<div class="dashboardBoxTop">
																		<div class="dashHead">Lab Order</div>
																		<div>
																				<table>
																					<thead>
																						<tr>
																							<th class="col-md-3">
																								<div class="dashBrdPatient">
																								Date
																								</div>
																							</th>
																							<th class="col-md-3">
																								<div class="dashBrdPatient">

																								Patient
																								</div>
																							</th>
																							<th class="col-md-3">
																								<div class="dashBrdTest">
																										Test Name
																								</div>
																							</th>
																							<th class="col-md-2">
																								<div class="dashBrdSpecimen">
																								Lab Name
																								</div>
																							</th>
																							<th class="col-md-1">
																								<div class="dashBrdSpecimen">
																								View
																								</div>
																							</th>
																							<th class="col-md-1">
																								<div class="dashBrdSpecimen">
																								Status
																								</div>
																							</th>
																						</tr>
																					</thead> */}
												{/* {AppointmentlaborderStatusIns.map((AppointmentlaborderStatusInslist, i) => (
																					<tbody>
																						<tr>
																							<td class="col-md-3">
																								<div class="dashBrdPatient">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										{moment(
																							AppointmentlaborderStatusInslist.labTestRequestsDate
																						).format('DD-MMMM-YYYY')}
																							</span>
																								</div>
																							</td>
																							<td class="col-md-3">
																								<div class="dashBrdPatient">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										{
																											AppointmentlaborderStatusInslist.memberName
																										}
																									</span>
																								</div>
																							</td>
																							<td class="col-md-3">
																								<div class="dashBrdTest">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									> */}
												{/* <font
																											style={{
																												color:
																													'red',
																											}}
																										> */}
												{/* {
																												AppointmentlaborderStatusInslist.lab_form_display_name
																											} */}
												{/* </font> */}
												{/* </span>
																								</div>
																							</td>
																							<td class="col-md-2">
																								<div class="dashBrdSpecimen">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										{
																											AppointmentlaborderStatusInslist.labName
																										}
																									</span> */}
												{/* </div>
																							</td>
																							<td class="col-md-1">
																								<div class="dashBrdSpecimen">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										<font
																											style={{
																												color:
																													'red',
																											}}
																										> */}
												{/* {
																												AppointmentlaborderStatusInslist.status
																											}
																										</font>
																									</span>
																								</div>
																							</td>
																							<td class="col-md-1">
																								<div class="dashBrdSpecimen">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									> */}
												{/* {
												AppointmentlaborderStatusInslist.status =AdmittedlaborderStatusrecieved.labOrderStatusId
												AdmittedlaborderStatusrecieved.labOrderStatusName} ? (
													'class-red'
												) : (
													''
												)
											} */}
												{/* </span>
																								</div>
																							</td>
																						</tr>
																					</tbody>
																					))}
																				</table>

																		</div>
																	</div>
																);
															}
														})()
														}
													</div>
												</div> */}
												<div class="col-lg-6 col-md-12">
													<div class="dashboardBoxRight">
														{(() => {
															if (paymentIns.length > 0 && dataFlagAppointment == true) {
																return (
																	<div class="dashboardBoxTop">
																		<div class="dashHead">Payments</div>
																		<div>
																			<table>
																				<thead className='dashboardMainThead'>
																					<tr>
																						<th class="col-md-3">
																							<div class="dashBrdPatient">
																								Patient Name
																							</div>
																						</th>
																						<th class="col-md-3">
																							<div class="dashBrdPatient">
																								Invoice No
																							</div>
																						</th>
																						<th class="col-md-3">
																							<div class="dashBrdTest">
																								Total Amount
																							</div>
																						</th>
																						<th class="col-md-2">
																							<div class="dashBrdSpecimen">
																								Pending Amount
																							</div>
																						</th>
																						<th class="col-md-1">
																							<div class="dashBrdSpecimen">
																								Status
																							</div>
																						</th>
																					</tr>
																				</thead>

																				{paymentIns.map((paymentInslist, i) => (
																					<tbody>
																						<tr>
																							<td class="col-md-3">
																								<div class="dashBrdPatient">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										{
																											paymentInslist.memberDispalyName
																										}
																									</span>
																								</div>
																							</td>
																							<td class="col-md-3">
																								<div class="dashBrdPatient">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										{
																											paymentInslist.memberProcedureInvoiceNo
																										}
																									</span>
																								</div>
																							</td>
																							<td class="col-md-3">
																								<div class="dashBrdTest">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										<font

																										>

																											{
																												parseFloat(paymentInslist.memberProcedureInvoiceTotalNetAmount).toFixed(2) === "" ? "0.00" : parseFloat(paymentInslist.memberProcedureInvoiceTotalNetAmount).toFixed(2)
																											}
																										</font>
																									</span>
																								</div>
																							</td>
																							<td class="col-md-2">
																								<div class="dashBrdSpecimen">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal', color: 'red'
																										}}
																									>
																										{

																											parseFloat(paymentInslist.total_balance_amount).toFixed(2) === "" ? "0.00" : parseFloat(paymentInslist.total_balance_amount).toFixed(2)
																										}
																									</span>
																								</div>
																							</td>
																							<td class="col-md-1">
																								<div class="dashBrdSpecimen">
																									<span
																										class="elipsis"
																										style={{
																											fontWeight:
																												'normal',
																										}}
																									>
																										<font
																											style={{
																												color:
																													'red',
																											}}
																										>
																											{
																												paymentInslist.invoiceSatus
																											}
																										</font>
																									</span>
																								</div>
																							</td>
																						</tr>
																					</tbody>
																				))}
																			</table>
																		</div>
																	</div>
																);
															}

														})()
														}

														<div>
															<span
																class="elipsis"
																style={{
																	fontWeight:
																		'normal', color: 'red'
																}}
															>
																Total Pending Amount : &#8377; {totalPendingAmount}</span>
														</div>

													</div>
												</div>

											</div>
										</div>
									</div>
								</TabPanel>
							</div>
						</Tabs>
					</div>
				</div>
				<Footer />
				<div>
					{popUpDoctorList == true && (
						<div>
							{popaler.length > 1 ?
								<div className="ploverlayy">

									<div className="plpopup plpopupList selectDr " style={{ width: '400px' }}>
										<h2>
											<a class="close" href="#" onClick={closePopUp}>
												&times;
											</a>
										</h2>

										<div className="plcontent">
											<label>
												Select Doctor<span className="mandatory">*</span>
											</label>
											<div className="addDataContent" style={{ padding: '4px 0 0' }}>
												<div className="addDataDiv" id="styled-select">
													{popaler.length > 1 ?
														<select
															id="default"
															style={{ width: '100%' }}
															onChange={(e) => hospitalpageChange(e.target.value)}
														>
															<option value="">Doctor Name</option>
															{popaler.map((Popuplist, i) => (
																<option value={i}>
																	{Popuplist.doctorName}({Popuplist.specialization})
																</option>
															))}
														</select>
														:
														<select
															id="default"
															style={{ width: '100%' }}
															onChange={(e) => hospitalpageChange(e.target.value)}
														>
															{popaler.map((Popuplist, i) => (
																<option value={i}>
																	{Popuplist.doctorName}({Popuplist.specialization})
																</option>
															))}
														</select>}

												</div>
											</div>
										</div>

									</div>

								</div>
								: hospitalpageChange(0)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
export default DashboardMain;
