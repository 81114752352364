import React from 'react'
import pulseLoader from '../images/icons/pulseLoader.png';
function Loader() {
    return (
        <div class="fullpage-loader">
					<div class="fullpage-loader__logo">
						<div class="loaderWrap" id="loader" style={{ display: 'block' }}>
							<div class="loaderPurple" />
							<img src={pulseLoader} />
						</div>
					</div>
				</div>
    )
}
export default Loader