import React,{useState} from 'react';

function ComposeSms() {
	const [count, setCount] = useState(0);
	return (
		<div class="sendSmsWrap">
			<div class="sendSmsCountTop">
				<div class="sendSmsCountDiv">
					Remaining SMS
					<span>9994</span>
				</div>
				<div class="sendSmsCountDiv">
					Today's submission
					<span>0</span>
				</div>
				<div class="sendSmsCountDiv">
					Expiry Date
					<span>09 Sep 2022</span>
				</div>
				<div class="sendSmsCountDiv">
					Sms Sender Id
					<span>PURPLE</span>
				</div>
			</div>

			<form>
				<div class="sendSmsCvrDiv">
					{/* <div class="smsNotifyError" style={{ display: 'none' }}>
						Promotional Sms Date Exceeded Please Contact Administrator
					</div> */}
					<div class="smsHeading">PROMOTIONAL SMS</div>
					<div class="smsNotify">Please note that promotional sms can be send from 9 AM to 8 PM only.</div>
					<div class="sendSmsContentDiv">
						<div class="sendSmsLeftSec">
							<ul class="smsLeftTab">
								<li class="tabActive" id="leftOption1">
									<span>1</span>
								</li>
								<li class="tabActive" id="leftOption2">
									<span>2</span>
								</li>
							</ul>
						</div>
						{/* <div class="sendSmsRightSec" id="numberComposeDiv">
							<div class="smsTypeDiv">
								<div class="smsTypeSectionTop">aubciuabiuabufiuabfiab</div>
							</div>
						</div> */}
						<div class="sendSmsRightSec" id="smsComposeDiv">
							<div class="smsTypeSectionDiv">
								<div class="smsTypeSectionBlock" id="manualModId">
									<div class="smsBody">
										<div class="scheduleSmsDivHead">COMPOSE Sms </div>
										<textarea name="ComposeSms" id="ComposeSms" maxlength="150"
										 onChange={e => setCount(e.target.value.length)}
										/>
										<label>
											<span id="smsLength">{count}</span>/150
										</label>

										<div class="smsButtonDiv">
											<button
												type="Submit"
												id="smsSendId"
												class="commonButton"
												style={{ border: 'none', color: '#fff' }}
											>
												Send Sms
											</button>
											<a
												href=""
												class="commonButton"
												style={{ border: 'none', color: '#fff', textDecoration: 'none' }}
											>
												Cancel
											</a>
										</div>
									</div>

									<div id="scheduleSmsDiv" class="scheduleSmsDiv">
										<div class="scheduleSmsDivHead">Schedule SMS</div>
										<div class="scheduleSMSRow" />
										<div class="scheduleSMSRow">
											<label>Schedule Date</label>

											<input type="date" name="scheduleDate" id="scheduleDate" />
											<select name="scheduleTime" id="scheduleTime">
												<option value="9AM">09 AM</option>
												<option value="2PM">02 PM</option>
												<option value="5PM">05 PM</option>
												<option value="8PM">08 PM</option>
											</select>
										</div>
										<div class="smsButtonDiv" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ComposeSms;
