import React from 'react';
// import Header from "../layout/header"
import Footer from "../layout/footer"
import { Link } from 'react-router-dom'
import teethbanner from "../images/media/teethbanner.jpg"
import img1 from "../images/media/1.png"
import img2 from "../images/media/2.png"
import img3 from "../images/media/3.png"
import qleft from "../images/icons/q-left.png"

function forgotPassword() {
    return (
        <div>
            <div className="coverWraper innerCoverWraper">
                {/* <Header></Header> */}
                <div className="contentWraper contentWraperScroll homeContentWraper">
                    <div className="homeContentBanner">
                        <div className="container">
                            <div className="contentHead">
                                Welcome to IDA Kerala Assist
                                <span>Digital Clinic Management System</span>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="pulseLeftImg">
                                        <img src={teethbanner} alt="teethbanner" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="loginForm">
                                        <div className="loginList">
                                            <label>Mobile Number / Email ID</label>
                                            <input type="text" className="inputField" />
                                        </div>
                                        <div className="loginList">
                                            <label>Password</label>
                                            <input type="text" className="inputField" />
                                        </div>
                                        <div className="loginList txtRight">
                                            {/* <span className="forgotLink">Login</span> */}
                                            <span className="forgotLink"> <Link to="Authentication/login" style={{ textDecoration: "none" }}>Login</Link> </span>
                                        </div>
                                        <div className="loginList">
                                            <Link to="/Dashboard/welcome" className="loginButton">Submit</Link>
                                            <span className="loginButton commonTrialButton">Try For Free<span>(14 Day Trial)</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="doctorHomeSection">
                        <div className="container">
                            <div className="contentHead">What Dentists are saying</div>
                            <div className="doctorHomeDiv">
                                <div class="iconTabList">
                                    <div class="iconTab">
                                        <div class="iconQuotes"><img src={qleft} alt="" /></div>
                                        <img alt="" src="https://s3.amazonaws.com/purplehealthfiles/production/pulse/innerHtmlImages/anwar.jpg" />
                                        <h4>Dr Anwar Sha</h4>
                                        <h6>Sha's Dental Care</h6>
                                        <h6>Karunagapally, Kerala</h6>
                                        <p>IDA KSB Assist is outstanding practice management software that is fully featured and easy to use. I also got my own branded website for my clinic. There is no other software like this on the market and all this at an amazing low cost.</p>
                                    </div>
                                </div>
                                <div class="iconTabList">
                                    <div class="iconTab">
                                        <div class="iconQuotes"><img src={qleft} alt="" /></div>
                                        <img alt="" src="https://s3.amazonaws.com/purplehealthfiles/production/pulse/innerHtmlImages/nehas.jpg" />
                                        <h4>Dr Nehas H</h4>
                                        <h6>Smyle Artz Dental Speciality Clinic</h6>
                                        <h6>Kollam</h6>
                                        <p>This software is excellent and helpful to anyone who wishes to update or modernize their practice.</p>
                                    </div>
                                </div>
                                <div class="iconTabList">
                                    <div class="iconTab">
                                        <div class="iconQuotes"><img src={qleft} alt="" /></div>
                                        <img alt="" src="https://s3.amazonaws.com/purplehealthfiles/production/pulse/innerHtmlImages/salish.jpg" />
                                        <h4>Dr Salish JS</h4>
                                        <h6>Kizhakumbhagam, Chithara</h6>
                                        <h6>Kollam</h6>
                                        <p>I am using this software from past 3 months, its very much user friendly and easy. This took my practise to next level. Thank you IDA KSB for this wonderful initiative.</p>
                                    </div>
                                </div>
                                <div class="iconTabList">
                                    <div class="iconTab iconTabLong">
                                        <div class="iconQuotes"><img src={qleft} alt="" /></div>
                                        <img alt="" src="https://s3.amazonaws.com/purplehealthfiles/production/pulse/innerHtmlImages/sameer.jpg" />
                                        <h4>Dr.Sameer Thavalengal</h4>
                                        <h6>Dental Surgeon & Oral Implantologist</h6>
                                        <h6>Perinthalmanna</h6>
                                        <p>I am using IDA ASSIST online clinical software since last 6months, I find this software more dentists friendly & ease to use. Moreover being part of IDA, we can assured that our patients details are well safeguarded & trusted. I recommend this software which is economical with package including a clinic website & personal app. They have excellent supportive & dedicated team ready to help & thier response is fast to our queries. Getting used to online dentistry with IDA Assist. Thanks Purple health team & expecting improvements in app & website.</p>
                                    </div>
                                </div>
                                <div class="iconTabList">
                                    <div class="iconTab iconTabLong">
                                        <div class="iconQuotes"><img src={qleft} alt="" /></div>
                                        <img alt="" src="https://s3.amazonaws.com/purplehealthfiles/production/pulse/innerHtmlImages/muralikrishna.jpg" />
                                        <h4>Dr Muralikrishna</h4>
                                        <h6>Visakh Speciality Dental Clinic</h6>
                                        <h6>Perumbavoor</h6>
                                        <p>I'm Dr Muralikrishna, I have been using dental practice management software from IDA KSB ASSIST from 6 months. It is a must have software for all dental clinics to digitally maintain the patient records. The executive are always very responsive in case you need their help. I strongly recommend this software to all practicing dentist.</p>
                                    </div>
                                </div>
                                <div class="iconTabList">
                                    <div class="iconTab iconTabLong">
                                        <div class="iconQuotes"><img src={qleft} alt="" /></div>
                                        <img alt="" src="https://s3.amazonaws.com/purplehealthfiles/production/pulse/innerHtmlImages/doc6.png" />
                                        <h4>Dr. Anjo Jimmy</h4>
                                        <h6>Dr. Anjo's Dental Clinic</h6>
                                        <h6>Thrissur</h6>
                                        <p>For Dentists looking to digitize their practice, I recommend IDA KSB Assist as it is very powerful software with all the necessary features and provides great value.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="superchargeDiv">
                        <div className="containerNew">
                            <div className="headerText">Supercharge Your Practice</div>
                            <div className="topBlocks">
                                <h4>Practice Management</h4>
                                <h6>Full Featured & Robust</h6>
                                <div className="imageDiv"><img src={img1} alt="" /></div>
                                <div className="buttonDiv">
                                    <span className="topButton">TRY FOR FREE</span>
                                </div>
                            </div>
                            <div className="topBlocks">
                                <h4>Your Own Branded Website</h4>
                                <h6>Be Online & Be Unique</h6>
                                <div className="imageDiv"><img src={img2} alt="" /></div>
                                <div className="buttonDiv">
                                    <span className="topButton">SIGN UP NOW</span>
                                </div>
                            </div>
                            <div className="topBlocks">
                                <h4>Your Own Branded Mobile App</h4>
                                <h6>Includes Telehealth Technology</h6>
                                <div className="imageDiv"><img src={img3} alt="" /></div>
                                <div className="buttonDiv">
                                    <span className="topButton">SIGN UP NOW</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>

        </div>
    )
}

export default forgotPassword
