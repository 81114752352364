import React from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../images/icons/icon1.png';
import icon2 from '../images/icons/icon2.png';
import icon3 from '../images/icons/icon3.png';

function homeleftMenu() {

	return (
		<div>
			<div className="homeLeftmenu">
				<ul className="dashMenu">
					<li>
						{/* "/calender" */}
						<Link to="/clinics/calendar" >
							<img src={icon1} alt="" />Practice Management
						</Link>
					</li>
					<li>
						{/* "/Dashboard/changepassword" */}
						<Link to="/Dashboard/changepassword" >
							<img src={icon2} alt="" />Change Password
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default homeleftMenu;
