import React from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';


function HeaderPrint(props) {
    const [printOutId, setprintOutId] = useState('');

    const [headerIncludeLogo, setheaderIncludeLogo] = useState('');
    const [lefttext, setlefttext] = useState('');
    const [logotype, setlogotype] = useState();
    const [righttext, setrighttext] = useState('');
    const [headertitle, setheadertitle] = useState('');
    const [marginbottom, setmarginbottom] = useState('');
    const [margintop, setmargintop] = useState('');
    const [marginleft, setmarginleft] = useState();
    const [marginright, setmarginright] = useState('');
    const [orientationId, setorientationId] = useState('');
    const [pagesizeid, setpagesizeid] = useState('');
    const [logoTypeName, setlogoTypeName] = useState('');
    const [printertype, setprintertype] = useState('');

    const [hospitalName, sethospitalName] = useState('');
    const [hospitalAddress, sethospitalAddress] = useState('');
    const [hospitalEmail, sethospitalEmail] = useState('');
    const [hospitalImagename, sethospitalImagename] = useState('');
    useEffect(() => {
        callingAPI('commonUserFunctions/headerFooterLogoPrints', {
            hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
            doctorId: ""
        }).then((res) => {

            setprintOutId(res.data.result.headerFooterDetails[0].printOutId);
            setheaderIncludeLogo(res.data.result.headerFooterDetails[0].headerIncludeLogo);
            setlefttext(res.data.result.headerFooterDetails[0].header_left_text);
            setlogotype(res.data.result.headerFooterDetails[0].header_logo_type_id);
            setrighttext(res.data.result.headerFooterDetails[0].header_right_text);
            setheadertitle(res.data.result.headerFooterDetails[0].header_title);
            setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
            setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
            setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
            setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
            setorientationId(res.data.result.headerFooterDetails[0].page_orientation_type_id);
            setprintertype(res.data.result.headerFooterDetails[0].page_printer_type_id);
            setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);
            setlogoTypeName(res.data.result.headerFooterDetails[0].logoTypeName);

            sethospitalName(res.data.result.hospitalDetail[0].hospitalName);
            sethospitalAddress(res.data.result.hospitalDetail[0].hospitalAddress);
            sethospitalEmail(res.data.result.hospitalDetail[0].hospitalEmail);
            sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
            //setMarginTop()
        });
    }, [])
    var getS3folder =
        process.env.REACT_APP_S3_BUCKET_URL +
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;

    if (pagesizeid == '1') {
        var a4sizewidth = '595px'
        var a4sizeheight = '842px'
    }
    if (pagesizeid == '2') {
        var a5sizewidth = '420px'
        var a5sizeheight = '595px'
    }
    if (pagesizeid == '3') {
        var b4sizewidth = '780px'
        var b4sizeheight = '288px'
    }
    if (pagesizeid == '4') {
        var b5sizewidth = '540px'
        var b5sizeheight = '414px'
    }
    if (pagesizeid == '5') {
        var lettersizewidth = '792px'
        var lettersizeheight = '612px'
    }
    if (pagesizeid == '6') {
        var prescriptionsizewidth = '540px'
        var prescriptionsizeheight = '794px'
    }
    else {
        var a4sizewidth = '595px'
        var a4sizeheight = '842px'
    }
    return (
        <>






            <tr height="80px">
                <td colspan="12" valign="middle" align="center">
                    <div className="no-print form-group cus-checkbox-wrpr" style={{ textAlign: "left" }}>
                        <input checked={props.isChecked4} id='isChecked4' type="checkbox" onChange={(e) => props.setIsChecked4(e.target.checked)} title="Hide the logo while printing." />
                        <label for="isChecked4" title="Hide the logo while printing."></label>
                    </div>
                    <div style={{ width: '30%', float: logoTypeName == 'Square' ? 'none' : 'left' }} id='section4'>
                        {/* style={{ width: '13%', float: 'left' }} */}
                        <p
                            style={{
                                fontWeight: '400',
                                fontSize: '16px',
                                clear: 'both',
                                width: '100%',
                            }}
                        >
                            {hospitalImagename != (null || "") ?
                                <span
                                    style={{
                                        marginTop: '30px',
                                        display: 'inline-block',
                                        width: '100%',
                                    }}
                                >

                                    {(headerIncludeLogo == 1 && logoTypeName == 'Wide') &&

                                        <img

                                            src={
                                                getS3folder + '/' + props.hospitalDataId + ".png"

                                            }

                                            style={{
                                                float: 'left',
                                                width: '100%',
                                                height: '45px', objectFit: 'contain'
                                            }}
                                        />}
                                    {(headerIncludeLogo == 1 && logoTypeName == 'Square') &&

                                        <img
                                            src={
                                                getS3folder + '/' + props.hospitalDataId + ".png"}
                                            style={{


                                                float: 'middle',
                                                width: '100%',
                                                height: '65px', objectFit: 'contain'
                                            }}
                                        />}
                                    {
                                        (headerIncludeLogo == 1 && logoTypeName == 'Narrow') &&
                                        <img
                                            src={
                                                getS3folder + '/' + props.hospitalDataId + ".png"}
                                            style={{


                                                float: 'left',
                                                width: 'auto',
                                                height: '43px',
                                            }}
                                        />}
                                </span> : <img src={Logo} className="printLogoIimg" />}
                        </p>
                    </div>

                    <div className="no-print form-group cus-checkbox-wrpr" style={{ textAlign: "left" }}>
                        <input checked={props.isChecked5} id='isChecked5' type="checkbox" onChange={(e) => props.setIsChecked5(e.target.checked)} title="Hide the header details while printing." />
                        <label for="isChecked5" title="Hide the header details while printing."></label>
                    </div>
                    <div class="practicePrintName" style={{ marginTop: logoTypeName !== 'Square' && '30px', width: logoTypeName == 'Square' ? '100%' : '70%' }} id='section5.1'>
                        <p
                            style={{
                                fontWeight: '700',
                                fontSize: '16px',
                                clear: 'both',
                                width: '100%',
                                marginBottom: '1px',
                                marginTop: '0',
                            }}
                        >
                            {headertitle}
                        </p>
                        <p
                            style={{
                                fontWeight: '400',
                                fontSize: '12px!important',
                                clear: 'both',
                                width: '100%',
                                margin: '0',
                            }}
                        >
                            {hospitalAddress}
                        </p>
                    </div>

                </td>
            </tr>
            {lefttext == "" || righttext == "" &&
                <tr>
                    <td colspan="12">
                        <hr
                            color="#142536"
                            style={{ margin: '0', opacity: '1', height: '2px' }}
                        />
                    </td>
                </tr>

            }

            {/* HEADER COMMON  */}
            <tr id='section5.2'>
                <td
                    width="50%"
                    colspan="6"
                    align="left"
                    style={{ fontSize: '12px', wordBreak: 'break-word' }}
                >{lefttext}</td>

                <td
                    width="50%"
                    align="right" colspan="6"
                    style={{ fontSize: '12px', wordBreak: 'break-word' }}
                >{righttext}</td>
            </tr>
            {/*  */}


            <tr>
                <td colspan="12">
                    <hr
                        color="#142536"
                        style={{ margin: '0', opacity: '1', height: '2px' }}
                    />
                </td>
            </tr>









        </>
    );
}

export default HeaderPrint;
