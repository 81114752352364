import React, { useEffect, useState } from "react";
import PharmacyHeader from "../labLogin/layout/header";
import Footer from "../labLogin/layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "../pharmacyLogin/pharmacyLeft";
import Pagination from "react-js-pagination";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { callingAPI } from "../config";
function OutsidePharmacyOrderDetailsView() {
  const [labDetailedViewData, setlabDetailedViewData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState("");
  const [pageOffset, setPageOffset] = useState(0);
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [filterName, setFilterInput] = useState("");
  const paginationView = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) {
      setFilterInput("");
    }
    const labDetails = {
      userId: window.sessionStorage.getItem("userId"),
      doctorVisitId: window.sessionStorage.getItem("outsidedoctorVisitId"),
      orderId: window.sessionStorage.getItem("outsideorderId"),
      hospitalId: localStorage.getItem("hospitalId"),
      max: 10,
      offset: pageOffset,
    };
    callingAPI(
      "viewOutSidePharmacyOrderDetails/viewOutSidePharmacyOrderDetails",
      labDetails
    )
      .then((response) => {
        if (response.data.success === "1") {
          setlabDetailedViewData(response.data.result.outSideOrderDetails);
        }
      })
      .catch();
  };
  const labDetailsView = () => {
    const labDetails = {
      userId: window.sessionStorage.getItem("userId"),
      doctorVisitId: window.sessionStorage.getItem("outsidedoctorVisitId"),
      orderId: window.sessionStorage.getItem("outsideorderId"),
      hospitalId: localStorage.getItem("hospitalId"),
      max: 10,
      offset: pageOffset,
    };
    callingAPI(
      "viewOutSidePharmacyOrderDetails/viewOutSidePharmacyOrderDetails",
      labDetails
    )
      .then((response) => {
        if (response.data.success === "1") {
          setlabDetailedViewData(response.data.result.outSideOrderDetails);
          setTotalCount(response.data.result.outSideOrderDetailsCount);
        }
      })
      .catch();
  };
  useEffect(
    () => {
      labDetailsView();
    },
    [],
    []
  );
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <div className="row">
        <div className="breadcumbDiv">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="outsidePharmacyOrderDetails">
                Outside Pharmacy Order
              </Link>
            </BreadcrumbItem>
            <div class="arrow-right" style={{ marginLeft: "7px" }} />
            <BreadcrumbItem>Outside Pharmacy Order Details</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle">Outside Pharmacy Order Details</div>
        </div>
      </div>
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardtable">
            <table>
              <thead>
                <tr>
                  <th>Medicine Name </th>
                  <th>Frequency And Dosage </th>
                  <th>Instructions </th>
                  <th>Comments</th>
                  <th>Quantity</th>
                  <th>Days</th>
                  <th>Start Date</th>
                </tr>
              </thead>
              {labDetailedViewData.map((data) => (
                <tbody>
                  <tr>
                    <td> {data.medicineName}</td>
                    <td>{data.freqDosage}</td>
                    <td>{data.instructions}</td>
                    <td> {data.dvmComments} </td>
                    <td> {data.quantity} </td>
                    {data.days == 0 ? <td>{""}</td> : <td>{data.days}</td>}

                    <td> {data.startDate} </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
        {totalCount > 10 ? (
          <div className="paginationSection">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={totalCount}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={paginationView}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default OutsidePharmacyOrderDetailsView;
