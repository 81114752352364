import React from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';


function FooterDoctorPrescriptionPrint(props) {
    const [printOutId, setprintOutId] = useState('');

    const [footeralign, setfooteralign] = useState('');
    const [footerfullwidthcontent, setfooterfullwidthcontent] = useState('');
    const [header_align, setheader_align] = useState('');
    const [headerIncludeLogo, setheaderIncludeLogo] = useState('');
    const [header_logo_type_id, setheader_logo_type_id] = useState('');
    const [header_title, setheader_title] = useState('');
    const [page_size_id, setpage_size_id] = useState('');
    const [custum_height, setcustum_height] = useState();
    const [custum_width, setcustum_width] = useState('');
    const [showFooter, setshowFooter] = useState('');
    const [showHeader, setshowHeader] = useState('');
    const [showRx, setshowRx] = useState('');
    const [header_left_text, setheader_left_text] = useState('');
    const [header_right_text, setheader_right_text] = useState(0);
    const [logoTypeName, setlogoTypeName] = useState('');


    const [hospitalAddress, sethospitalAddress] = useState('');
    const [hospitalName, sethospitalName] = useState('');
    const [hospitalEmail, sethospitalEmail] = useState('');

    const [logo_image, setlogo_image] = useState('');
    const [doctorName, setdoctorName] = useState('');
    const [specializationName, setspecializationName] = useState('');
    const [education, seteducation] = useState([]);
    useEffect(() => {
        callingAPI('commonUserFunctions/headerFooterLogoPrints', {
            hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
            doctorId: props.doctorDataId ? props.doctorDataId : sessionStorage.getItem('patientDoctorId'),
        }).then((res) => {
            if (res.data.success === '1') {
                setprintOutId(res.data.result.headerFooterDetails[0].header_left_text);
                setheaderIncludeLogo(res.data.result.headerFooterDetails[0].headerIncludeLogo);
                setfooteralign(res.data.result.headerFooterDetails[0].footer_align);
                setfooterfullwidthcontent(res.data.result.headerFooterDetails[0].footer_full_width_content);
                setheader_align(res.data.result.headerFooterDetails[0].header_align);
                setheader_logo_type_id(res.data.result.headerFooterDetails[0].header_logo_type_id);
                setheader_title(res.data.result.headerFooterDetails[0].header_title);
                setpage_size_id(res.data.result.headerFooterDetails[0].page_size_id);
                setcustum_height(res.data.result.headerFooterDetails[0].custum_height);
                setcustum_width(res.data.result.headerFooterDetails[0].custum_width);
                setshowFooter(res.data.result.headerFooterDetails[0].showFooter);
                setshowHeader(res.data.result.headerFooterDetails[0].showHeader);
                setshowRx(res.data.result.headerFooterDetails[0].showRx);
                setheader_left_text(res.data.result.headerFooterDetails[0].header_left_text);
                setheader_right_text(res.data.result.headerFooterDetails[0].header_right_text);
                setlogoTypeName(res.data.result.headerFooterDetails[0].logoTypeName);

                sethospitalAddress(res.data.result.hospitalDetail[0].hospitalAddress);
                sethospitalEmail(res.data.result.hospitalDetail[0].hospitalEmail);
                sethospitalName(res.data.result.hospitalDetail[0].hospitalName);

                setlogo_image(res.data.result.hospitalDetail[0].hospitalLogoImage);




                if (res.data.result.doctorDetail.length > 0) {
                    setdoctorName(res.data.result.doctorDetail[0].doctorName);
                    setspecializationName(res.data.result.doctorDetail[0].specializationName);
                }



                seteducation(res.data.result.doctorEducationDetail);
                //setMarginTop()
            }
            // else {
            //     alert(res.data.errorMessage);
            // }
        })
            .catch();
    }, [])

    var getS3folder =
        process.env.REACT_APP_S3_URI +
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;


    return (
        <div align="center" className='patientPrescriptionWrapper'>

            <tfoot>
                <tr>
                    <td>
                        <div class="printOutFooterSec" id="section7">
                            <div className="no-print form-group cus-checkbox-wrpr" style={{textAlign:"left"}}>
                                <input checked={props.isChecked7} id="isChecked7" type="checkbox" onChange={(e) => props.setIsChecked7(e.target.checked)} title="Hide the Footer while printing." />
                                <label for="isChecked7" title="Hide the Footer while printing."></label>
                            </div>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr style={{ display: 'table-row' }}>
                                        {showFooter == true &&
                                            footeralign == 'Center' && (
                                                <td>
                                                    <div
                                                        align="Center"
                                                        class="practiceFootBtm"
                                                        style={{ lineHeight: '15px', textAlign: 'Center', }}
                                                    >
                                                        {footerfullwidthcontent}
                                                    </div>
                                                </td>)}
                                        {showFooter == true &&
                                            footeralign == 'Left' && (
                                                <td>
                                                    <div
                                                        align="Left"
                                                        class="practiceFootBtm"
                                                        style={{ lineHeight: '15px', textAlign: 'Left', }}
                                                    >
                                                        {footerfullwidthcontent}
                                                    </div>
                                                </td>)}
                                        {showFooter == true &&
                                            footeralign == 'Right' && (
                                                <td>
                                                    <div
                                                        align="Right"
                                                        class="practiceFootBtm"
                                                        style={{ lineHeight: '15px', textAlign: 'Right', }}
                                                    >
                                                        {footerfullwidthcontent}
                                                    </div>
                                                </td>)}
                                    </tr>
                                    <tr>
                                        <td>
                                            <hr color="#142536" style={{ margin: '2px 0' }} />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </div>
    );
}

export default FooterDoctorPrescriptionPrint;
