import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Priscription from '../images/media/prescription.png';
import Print from '../images/icons/printer_icon.png';
import Delete from '../images/icons/delete_btn.png';
function CasesheetMedicalHistory() {
	const [medicalCaseSheetHistoryDetails, setMedicalCaseSheetHistoryDetails] = useState([]);
	const [imageDetails, setImageDetails] = useState([]);
	const [imageArrs, setImageArrs] = useState('');

	useEffect(() => {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
		};
		callingAPI('clinicalBackground/dentalCaseSheetHistory', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setMedicalCaseSheetHistoryDetails(response.data.result.result);
					setImageDetails(response.data.result.imageNames);
					setImageArrs(
						process.env.REACT_APP_S3_BUCKET_URL +
						process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
						process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH
					);
				}
			})
			.catch();
	}, []);
	const imageClick = (data) => {
		document.getElementById('priscriptionThumbnailPopup').src = data;
	};
	const printId = (data) => {
		// window.location.href = '/patients/casesheetMedicalPrint';
		window.sessionStorage.setItem('printId', data);
		window.open(
			`${process.env.PUBLIC_URL}/patients/casesheetMedicalPrint`,
			"_blank"
		);
	};
	const deletedFunction = (id) => {
		const postData = {
			doctorVisitId: id,
		};
		callingAPI('commonUserFunctions/deletePmmemberComplaintsWrite', postData)
			.then((data) => {
				if (data.data.success === '1') {
					alert('Successfully Deleted');
					window.location.href = '/patients/casesheetMedicalHistory';
				} else {
					alert(data.data.errorMessage);
				}
			})
			.catch();
	};
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment  ">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>

												<span class="treatmentPlanBtn pl_blue_btn">
													<a href="/patients/casesheetMedicalAdd">+ Add</a>
												</span>
											</div>
											{/* ==========   IMAGE BEGINS ========= */}
											{(imageDetails.length > 0 && imageDetails[0].imageName) ? (
												<div>
													{imageDetails.map((item, i) => (
														<div class="treatmentPlanList1" style={{ height: 'auto' }}>
															<div class="treatmentTable planActive">
																<div class="treatmentPlanDate">{item.createdDate}</div>
																<table className="treatmentComplaintWrap">
																	<tbody>
																		<tr class="groupBrdr">
																			<td colspan="11">
																				<div class="treatmentTable prescriptionTable">
																					<div
																						class="drawTumbVisit"
																						style={{ width: '100%' }}
																					>
																						<a>
																							<div
																								class="drawTumbItemVisit thumbPopup"
																								id="drawTumbItemVisit0"
																							>
																								{item.imageName
																									.split(',')
																									.map((itemImg, i) => (
																										<div class="popup-link">
																											<a
																												href="#popup1"
																												onClick={() => {
																													imageClick(
																														imageArrs +
																														itemImg
																													);
																												}}
																											>
																												<img
																													className="priscriptionThumbnail"
																													src={
																														imageArrs +
																														itemImg
																													}
																												/>
																											</a>
																										</div>
																									))}
																								{/* <div class="popup-link">
																						<a href="#popup1">
																							<img
																								className="priscriptionThumbnail"
																								src={Priscription}
																							/>
																						</a>
																					</div> */}
																							</div>
																						</a>
																					</div>
																				</div>
																			</td>

																			<td colspan="1">
																				<div
																					class="dentalProNote caseSheetMdclDpdwn caseSheetMdclHistoryDpdwn  "
																					style={{ padding: '0px' }}
																				>
																					<span class="elipsis" title="">
																						&nbsp;
																					</span>

																					<label class="dropdown">
																						<div class="dd-button" />

																						<input
																							type="checkbox"
																							class="dd-input"
																							id="test"
																						/>

																						<ul class="dd-menu">
																							<a
																								onClick={(e) =>
																									printId(item.doctorVisitId)}
																								style={{
																									textDecoration: 'none',
																									color: 'black',
																								}}
																							>
																								<li>
																									<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																								</li>
																							</a>
																							<a
																								onClick={(e) =>
																									deletedFunction(item.doctorVisitId)}
																								style={{
																									textDecoration: 'none',
																									color: 'black',
																								}}
																							>
																								<li>
																									<img src={Delete} style={{ width: '15px', marginRight: '5px' }} />Delete
																								</li>
																							</a>
																						</ul>
																					</label>
																				</div>
																			</td>
																		</tr>

																		<tr class="dentalProPlaned">
																			<input
																				type="hidden"
																				name="hidTreatmentIds10"
																				id="hidTreatmentIds10"
																				value=""
																			/>
																			<td colspan="12">
																				<div class="plannedBy">
																					{' '}
																					<span> Prescribed By {item.doctorName}</span>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													))}
												</div>) : (
												''
											)}

											{/* ================   IMAGE ENDS HERE  ================ */}

											<div class="treatmentPlanList1">
												<span
													class="treatmentPlanName"
													style={{
														marginLeft: '10px',
														marginTop: '10px',
														marginBottom: '10px',
														float: 'none',
													}}
												>
													<b>CASE SHEET HISTORY</b>
												</span>
												<br />
												<br />

												{medicalCaseSheetHistoryDetails.map((item, i) => (
													<div>
														<div class="treatmentPlanDate" style={{ paddingLeft: '10px' }}>
															<b>{item.complaintName}</b>
														</div>

														{item.complaintListByDate.map((data, i) => (

															<div class="treatmentComplaintWrap">
																<div
																	class="treatmentComplaintRowWrap"
																	style={{ width: '100%' }}
																>
																	<div class="treatmentComplaintRow">
																		<div class="treatmentComplaintLeft">
																			{data.complaintDate}
																		</div>

																		<div class="treatmentComplaintRight">
																			<span>
																				<span class="treatmentTAgName ">
																					{data.content}
																				</span>
																			</span>
																			{data.description ? (
																				<div>
																					<span
																						style={{ fontWeight: 'bold' }}
																					>
																						Description :
																					</span>
																					<span>
																						{data.description}
																						<br />
																					</span>
																				</div>
																			) : (
																				''
																			)}
																		</div>
																	</div>

																	<div class="row">
																		<div class="col-md-6">
																			Noted by{' '}
																			<span style={{ fontWeight: '600', color: '#000' }}>{data.notedBy}</span>
																		</div>
																		{data.reason ? (
																			<div class="col-md-6 ellipsisText">
																				<b>Reason:</b>{' '}
																				<span
																					style={{
																						fontWeight: 'normal',
																						fontSize: '10px',
																					}}
																				>
																					{data.reason}
																				</span>
																			</div>
																		) : (
																			''
																		)}
																	</div>
																</div>
															</div>
														))}
													</div>
												))}

											</div>

											{/*  */}
											<div id="popup1" class="popup-container">
												<div class="popup-content">
													<a href="#" class="close">
														&times;
													</a>
													<h3>Case Sheet</h3>
													<img className="priscriptionThumbnailPopup" id="priscriptionThumbnailPopup" src={Priscription} />
												</div>
											</div>

											{/*  */}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default CasesheetMedicalHistory;
