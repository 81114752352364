import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';

function CasesheetSettings() {

	const [caseSheetSettings, setCaseSheetSettings] = useState('');
	useEffect(() => {

		let postData = {
			"doctorId": window.sessionStorage.getItem("doctorId"),
		};

		callingAPI('commonUserFunctions/casesheetSettingsList', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setCaseSheetSettings(response.data.result[0].mode)

					if (response.data.result[0].mode === "type") {
						document.getElementById('type').checked = true;
					}
					else {
						document.getElementById('write').checked = true;
					}

				}
			})
			.catch();
	}, []);
	const saveNew = () => {
		const apiJson = {
			"doctorId": window.sessionStorage.getItem("doctorId"),
			"role": "ROLE_DOCTOR",
			"radiobutton": caseSheetSettings,
		};
		callingAPI('commonUserFunctions/saveCasesheetSettings', apiJson).then((data) => {
			if (data.data.success === '1') {
				alert('Saved Successfully');
				window.location.reload();
			}
		}
		)
	}
	return (
		<div className="dashboardCover SettingsConsent">
			<div className="dashboardContentCenter">
				<div className="dashboardTitle">Case Sheet Settings</div>
				<div className="dashboardContent">
					<div class="radioAreaType prescriptionradioBtn_PTour" id="radioareaid">
						<span class="precriptionDetails_PTour" style={{ float: 'left' }}>
							<input
								type="radio"
								name="radiobtn"
								value="type"
								id="type"
								class="precriptionTypeDetails_PTour" style={{ float: 'left', marginTop: '5px', marginRight: '5px' }}
								onChange={(e) => setCaseSheetSettings(e.target.value)}
							/>
							<label class="radiolabel">Type&nbsp;&nbsp;</label>
							<input
								type="radio"
								name="radiobtn"
								value="write" id="write"
								class="precriptionTypeDetails_PTour" style={{ marginTop: '5px', marginRight: '5px' }}
								onChange={(e) => setCaseSheetSettings(e.target.value)}
							/>
							<label class="radiolabel">Write&nbsp;&nbsp;</label>
						</span>
						<span class="dashButtonBorder searchcaseSettings" onClick={saveNew}>Save</span>
					</div>
				</div>
			</div>
		</div>
	);
}
export default CasesheetSettings;
