import React from "react";
import Logo from "../images/media/logo.png";
import { useEffect, useState } from "react";
import { callingAPI } from "../config";
import Loader from "../Common/loader";
import moment from "moment";
import HeaderPrint from "../Settings/headerPrint";
import FooterPrint from "../Settings/footerPrint";
import { useParams } from 'react-router-dom';
function LabOrderPrint() {
  let { orderTestId, hospId } = useParams();
  const [marginbottom, setmarginbottom] = useState("");
  const [margintop, setmargintop] = useState("");
  const [marginleft, setmarginleft] = useState();
  const [marginright, setmarginright] = useState("");
  const [pagesizeid, setpagesizeid] = useState("");
  const [memberDetails, setMemberDetails] = useState({});
  const [hospitalDetails, setHospitalDetails] = useState({});
  const [labOrderData, setLabOrderData] = useState([]);
  const [orderDate, setOrderDate] = useState("");
  const [doctorDisplayName, setDoctorDisplayName] = useState("");
  const [load, setLoad] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);
  function styleValues() {
    const PostData = {
      hospitalId: hospId ? hospId : localStorage.getItem('hospitalId'),
      doctorId: "",
    };

    callingAPI("commonUserFunctions/headerFooterLogoPrints", PostData).then(
      (res) => {
        setmarginbottom(
          res.data.result.headerFooterDetails[0].page_margin_bottom
        );
        setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
        setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
        setmarginright(
          res.data.result.headerFooterDetails[0].page_margin_right
        );

        setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

        //setMarginTop()
      }
    );
  }
  useEffect(() => {
    styleValues();
    callingAPI("commonUserFunctions/printSubLabOrderMedical", {
      orderTestId: orderTestId
      // labDate: moment(window.sessionStorage.getItem("getlabDate")).format(
      //   "YYYY-MM-DD"
      // ),
      // orderId: window.sessionStorage.getItem("getorderId"),
      // doctorVisitId: window.sessionStorage.getItem("getdoctorVisitId"),
      // orderTestId: window.sessionStorage.getItem("singlePrintId"),
    }).then((res) => {
      if (res.data.success !== 0) {
        setMemberDetails(res.data.result[0].memberDetails[0]);
        setHospitalDetails(res.data.result[0].hospitalDetails[0]);
        if (res.data.result[0].labOrderData.length > 0) {
          setLabOrderData(res.data.result[0].labOrderData);
          setOrderDate(res.data.result[0].orderDate);
          setDoctorDisplayName(
            res.data.result[0].doctorDetails.doctorDisplayName
          );
        }
        setLoad(true);
      }
    });
  }, []);
  const printPage = () => {
    const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
			// Hide unchecked sections before printing
		
			if (isChecked4) {
				section4.style.display = 'none';
			}
			if (isChecked5) {
				section51.style.display = 'none';
				section52.style.display = 'none';
			}
			if (isChecked7) {
				section7.style.display = 'none';
			}
    document.getElementById("cancel").style.display = "none";
    document.getElementById("print").style.display = "none";
    window.print();
    document.getElementById("cancel").style.display = "inline-block";
    document.getElementById("print").style.display = "inline-block";
    section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';
  };
  const back = () => {
    window.location.href = "/patients/labOrder";
  };
  if (pagesizeid == "1") {
    var a4sizewidth = "540px";
    var a4sizeheight = "842px";
  }
  if (pagesizeid == "2") {
    var a5sizewidth = "420px";
    var a5sizeheight = "595px";
  }
  if (pagesizeid == "3") {
    var b4sizewidth = "780px";
    var b4sizeheight = "288px";
  }
  if (pagesizeid == "4") {
    var b5sizewidth = "540px";
    var b5sizeheight = "414px";
  }
  if (pagesizeid == "5") {
    var lettersizewidth = "792px";
    var lettersizeheight = "612px";
  }
  if (pagesizeid == "6") {
    var prescriptionsizewidth = "540px";
    var prescriptionsizeheight = "794px";
  } else {
    var a4sizewidth = "540px";
    var a4sizeheight = "842px";
  }
  const printSingle = () => {
    callingAPI("commonUserFunctions/printSubLabOrderMedical", {
      labDate: moment(window.sessionStorage.getItem("getlabDate")).format(
        "YYYY-MM-DD"
      ),
      orderId: window.sessionStorage.getItem("getorderId"),
      doctorVisitId: window.sessionStorage.getItem("getdoctorVisitId"),
      orderTestId: window.sessionStorage.getItem("singlePrintId"),
      doctorVisitLabOrderTestId: window.sessionStorage.getItem("addPrint"),
    }).then((res) => {
      if (res.data.success !== 0) {
        setMemberDetails(res.data.result[0].memberDetails[0]);
        setHospitalDetails(res.data.result[0].hospitalDetails[0]);
        if (res.data.result[0].labOrderData.length > 0) {
          setLabOrderData(res.data.result[0].labOrderData);
          setOrderDate(res.data.result[0].orderDate);
          setDoctorDisplayName(
            res.data.result[0].doctorDetails.doctorDisplayName
          );
        }
        setLoad(true);
      }
    });
  };
  return (
    <div align="center">
      <div
        align="center"
        style={{
          width:
            a4sizewidth ||
            a5sizewidth ||
            b4sizewidth ||
            b5sizewidth ||
            lettersizewidth ||
            prescriptionsizewidth,
          height:
            a4sizeheight ||
            a5sizeheight ||
            b4sizeheight ||
            b5sizeheight ||
            lettersizeheight ||
            prescriptionsizeheight,
          marginLeft: "0px",
          marginright: "0px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <table
          style={{
            width: "100%",
            marginLeft: marginleft + "px",
            marginRight: marginright + "px",
            marginTop: margintop + "px",
            marginBottom: marginbottom + "px",
          }}
          border="0"
        >
          <thead>
            <tr>
              <th style={{ fontWeight: "400" }}>
                <div class="printOutHeaderSec ">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <HeaderPrint hospitalDataId={hospId} setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5}/>
                      <tr>
                        <td
                          width="50%"
                          colspan="6"
                          align="left"
                          style={{ fontSize: "12px", wordBreak: "break-all" }}
                        >
                          {doctorDisplayName}
                        </td>
                        <td
                          colspan="6"
                          width="50%"
                          align="right"
                          style={{ fontSize: "12px", wordBreak: "break-all" }}
                        >
                          {hospitalDetails.hospitalCity}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="12">
                          <hr
                            color="#142536"
                            style={{
                              margin: " 0",
                              height: "2px",
                              opacity: "1",
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="8"
                          style={{
                            width: "50%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "17px",
                            textAlign: "left",
                          }}
                        >
                          Name : {memberDetails.memberFirstName}{" "}
                          {memberDetails.memberLastName}
                        </td>
                        <td
                          colspan="4"
                          style={{
                            width: "50%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "17px",
                            textAlign: "right",
                          }}
                        >
                          Date : {orderDate}{" "}
                        </td>
                      </tr>

                      <tr>
                        <td
                          colspan="3"
                          style={{
                            width: "40%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            textAlign: "left",
                          }}
                        >
                          Age : {memberDetails.memberAge}
                        </td>
                        <td
                          colspan="9"
                          style={{
                            width: "80%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            textAlign: "right",
                          }}
                        >
                          Email Id : {memberDetails.secUserEmailid}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="6"
                          style={{
                            width: "50%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            textAlign: "left",
                          }}
                        >
                          Sex : {memberDetails.memberGender}
                        </td>
                        <td
                          colspan="6"
                          style={{
                            width: "50%",
                            fontSize: "12px",
                            padding: "0",
                            lineHeight: "15px",
                            textAlign: "right",
                          }}
                        >
                          Phone No : {memberDetails.SecuserMobileNumber}
                        </td>
                      </tr>
                      <tr>
                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                          (<td
                            colspan="8"
                            style={{
                              width: "50%",
                              fontSize: "12px",
                              padding: "0",
                              lineHeight: "15px",
                              textAlign: "left",
                            }}
                          >
                            MR No : {memberDetails.memberMrNo}
                          </td>)
                          :
                          (!memberDetails.memberPrNo == "" && (
                            <td
                              colspan="4"
                              style={{
                                width: "50%",
                                fontSize: "12px",
                                padding: "0",
                                lineHeight: "15px",
                                textAlign: "left",
                              }}
                            >
                              PR No : {memberDetails.memberPrNo}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td colspan="12">
                          <hr
                            color="#142536"
                            style={{
                              margin: " 0",
                              height: "2px",
                              opacity: "1",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="printOutSettingContent">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td align="left" style={{ width: "100%" }} />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="printOutSettingContent">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr style={{ borderBottom: "2px solid black" }}>
                        <td
                          class="col-md-3"
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "13px",
                            paddingBottom: "5px",
                            width: "25%",
                          }}
                        >
                          Test Name
                        </td>
                        <td
                          class="col-md-3"
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "13px",
                            paddingBottom: "5px",
                            width: " 25%",
                          }}
                        >
                          Specimen
                        </td>
                        <td
                          class="col-md-3"
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "13px",
                            paddingBottom: "5px",
                            width: " 25%",
                          }}
                        >
                          Test Type
                        </td>
                        <td
                          class="col-md-3"
                          style={{
                            borderBottom: "2px solid black",
                            textAlign: "left",
                            fontWeight: "500",
                            fontSize: "13px",
                            paddingBottom: "5px",
                            width: "25%",
                          }}
                        >
                          Instructions
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            {labOrderData.length > 0 ? (
              <div>
                {labOrderData.map((item, i) => (
                  <tr>
                    <td>
                      <div class="printOutSettingContent">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                class="col-md-3"
                                style={{
                                  width: "25%",
                                  margin: "0",
                                  color: "#616161",
                                  lineHeight: "15px",
                                  wordBreak: "break-word",
                                }}
                              >
                                <p
                                  class="sml_matter"
                                  style={{
                                    margin: " 3px 0",
                                    fontSize: "13px!important",
                                    color: "#616161",
                                  }}
                                >
                                  {item.testName}
                                </p>
                              </td>
                              <td
                                class="col-md-3"
                                style={{
                                  width: " 25%",
                                  margin: "0",
                                  color: "#616161",
                                  lineHeight: "15px",
                                  wordBreak: "break-word",
                                }}
                              >
                                <p
                                  class="sml_matter"
                                  style={{
                                    margin: " 3px 0",
                                    fontSize: "13px!important",
                                    color: "#616161",
                                  }}
                                >
                                  {item.specimen}
                                </p>
                              </td>
                              <td
                                class="col-md-3"
                                style={{
                                  width: " 25%",
                                  margin: "0",
                                  color: "#616161",
                                  lineHeight: "15px",
                                  wordBreak: "break-word",
                                }}
                              >
                                <p
                                  class="sml_matter"
                                  style={{
                                    margin: " 3px 0",
                                    fontSize: "13px!important",
                                    color: "#616161",
                                  }}
                                >
                                  {item.testType}
                                </p>
                              </td>
                              <td
                                class="col-md-3"
                                style={{
                                  width: " 25%",
                                  margin: "0",
                                  color: "#616161",
                                  lineHeight: "15px",
                                  wordBreak: "break-word",
                                }}
                              >
                                <p
                                  class="sml_matter"
                                  style={{
                                    margin: " 3px 0",
                                    fontSize: "13px!important",
                                    color: "#616161",
                                  }}
                                >
                                  {item.instructions}
                                </p>
                              </td>
                            </tr>
                            {item.comments !== "" ? (
                              <tr>
                                <td
                                  class="col-md-3"
                                  style={{
                                    width: " 25%",
                                    margin: "0",
                                    color: "#616161",
                                    lineHeight: "15px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <label
                                    style={{
                                      textAlign: "left",
                                      fontWeight: "500",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Comments
                                  </label>
                                  <p
                                    class="sml_matter"
                                    style={{
                                      margin: " 3px 0",
                                      fontSize: "13px!important",
                                      color: "#616161",
                                    }}
                                  >
                                    {item.comments}
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                ))}
              </div>
            ) : (
              ""
            )}
          </tbody>
          <FooterPrint hospitalDataId={hospId}  setIsChecked7={setIsChecked7} isChecked7={isChecked7}/>
        </table>
      </div>
      <div class="printBtm" style={{ textAlign: "center" }}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td colspan="6" style={{ textAlign: "center" }}>
                <p
                  onClick={printPage}
                  id="print"
                  style={{
                    width: "70px",
                    textAlign: "center",
                    paddingTop: "5px",
                    lineHeight: "15px",
                    display: "inline-block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "white",
                    background: "rebeccapurple none repeat scroll 0% 0%",
                    marginTop: "2px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginBottom: "2px",
                    height: "25px",
                    position: "absolute",
                    right: "80px",
                    fontFamily: "  Verdana",
                  }}
                >
                  Print
                </p>
                &nbsp;
                <p
                  id="cancel"
                  style={{
                    width: "70px",
                    textAlign: "center",
                    paddingTop: "5px",
                    lineHeight: "15px",
                    display: "inline-block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "white",
                    background: "rebeccapurple none repeat scroll 0% 0%",
                    marginTop: "2px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginBottom: "2px",
                    height: "25px",
                    position: "absolute",
                    right: "0px",
                    fontFamily: "  Verdana",
                  }}
                  onClick={back}
                >
                  Back
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        {load === false ? <Loader /> : ""}
      </div>
    </div>
  );
}
export default LabOrderPrint;
