import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { Route } from 'react-router-dom';
import Settingleft from '../Settings/settingleft';
import Healthcard from './healthcard';
import CasesheetSettings from './casesheetSettings';
import SimplifiedFormSettings from './simplifiedFormSettings';
import Consentform from './consentform';
import HospitalExpenses from './hospitalExpenses';
import HospitalInventory from './hospitalInventory';
import LabTestDetails from './labTestDetails';
import LabTestParameters from './labTestParameters';
import PharmacySupplier from './pharmacySupplier';
import PharmacyManufacture from './pharmacyManufacture';
import TaxDetails from './taxDetails';
import TreatmentProcedure from './treatmentProcedure';
import AutoIdGeneration from './autoIdGeneration';
import AddSpecimenType from './addSpecimenType';
import LabTestType from './labTestType';
import MedicineStockUpdate from './medicineStockUpdate';
import PurchaseLists from './purchaseLists';
import DoctorPrescription from './doctorPrescription';
import Emails from './emails';
import Printouts from './printouts';
import AddProcedure from './addProcedure';
import AddHospitalExpenses from './addHospitalExpenses';
import EditHospitalExpenses from './editHospitalExpenses';
import AddMedicine from './addMedicine';
import EditMedicine from './editMedicine';
// import ConsentFormInvoice from './consentFormInvoice';
import AddInventory from './addInventory';
import EditInventory from './editInventory';
import EditPurchaseDetails from './editPurchaseDetails';
import AddPurchase from './addPurchase';
import AddItemDetails from './addItemDetails';
import AddTax from './addTax';
import NewBatchInventory from './newBatchInventory';
import NewBatchMedicine from './newBatchMedicine';
import NewBatchPurchase from './newBatchPurchase';
// import PracticeDetails from './practiceDetails';
import PharmacyHeader from '../pharmacyLogin/layout/header';
import { Outlet } from 'react-router-dom';
import PharmacyLeft from '../pharmacyLogin/pharmacyLeft';
import ToDoList from './toDoList';
import AddLab from './addLab'
import AddLabWork from './addLabWork'
import AddWorkType from './addWorkType'

function settings() {
	return (
		<div className="coverWraper innerCoverWraper">
			{window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY" ? <PharmacyHeader /> : <Header />}
			<div className="contentWraper contentWraperScroll">
				{window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY" ? <PharmacyLeft /> : <Settingleft />}
				<div className="settingsRight">
				<Outlet/>
					{/* <Route path="/settings/healthcard" component={Healthcard} />
					<Route path="/settings/simplifiedFormSettings" component={SimplifiedFormSettings} />
					<Route path="/settings/casesheetSettings" component={CasesheetSettings} /> */}
{/*
					<Route path="/settings/consentform" component={Consentform} />
					<Route path="/settings/hospitalExpenses" component={HospitalExpenses} /> */}
					{/* <Route path="/settings/hospitalInventory" component={HospitalInventory} /> */}
					{/* <Route path="/settings/labTestDetails" component={LabTestDetails} />
					<Route path="/settings/labTestParameters" component={LabTestParameters} /> */}
					{/* <Route path="/settings/pharmacySupplier" component={PharmacySupplier} /> */}
					{/* <Route path="/settings/pharmacyManufacture" component={PharmacyManufacture} /> */}
					{/* <Route path="/settings/taxDetails" component={TaxDetails} />
					<Route path="/settings/treatmentProcedure" component={TreatmentProcedure} />
					<Route path="/settings/autoIdGeneration" component={AutoIdGeneration} />
					<Route path="/settings/addSpecimenType" component={AddSpecimenType} />
					<Route path="/settings/labTestType" component={LabTestType} /> */}
					{/* <Route path="/settings/medicineStockUpdate" component={MedicineStockUpdate} /> */}
					{/* <Route path="/settings/purchaseLists" component={PurchaseLists} /> */}
					{/* <Route path="/settings/doctorPrescription" component={DoctorPrescription} />
					<Route path="/settings/emails" component={Emails} />
					<Route path="/settings/toDoList" component={ToDoList} />
					<Route path="/settings/addLab" component={AddLab} />
					<Route path="/settings/addLabWork" component={AddLabWork} />
					<Route path="/settings/addWorkType" component={AddWorkType} /> */}

					{/* <Route path="/settings/printouts" component={Printouts} />
					<Route path="/settings/addProcedure" component={AddProcedure} />
					<Route path="/settings/addHospitalExpenses" component={AddHospitalExpenses} />
					<Route path="/settings/editHospitalExpenses" component={EditHospitalExpenses} />
					<Route path="/settings/newBatchInventory" component={NewBatchInventory} />
					<Route path="/settings/addMedicine" component={AddMedicine} />
					<Route path="/settings/editMedicine" component={EditMedicine} />
					<Route path="/settings/newBatchMedicine" component={NewBatchMedicine} />
					<Route path="/settings/newBatchPurchase" component={NewBatchPurchase} /> */}


					{/* <Route path="/settings/consentFormInvoice" component={ConsentFormInvoice} /> */}
					{/* <Route path="/settings/addInventory" component={AddInventory} />
					<Route path="/settings/editInventory" component={EditInventory} />
					<Route path="/settings/editPurchaseDetails" component={EditPurchaseDetails} />
					<Route path="/settings/addPurchase" component={AddPurchase} />
					<Route path="/settings/addItemDetails" component={AddItemDetails} />
					<Route path="/settings/addTax" component={AddTax} /> */}
					{/* <Route path="/settings/practiceDetails" element={<PracticeDetails/>} /> */}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default settings;
