import LabHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import { Route } from 'react-router-dom';
import LabLeft from '../labLogin/labLeft'
// import Pagination from "react-js-pagination";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
function OutsideLabOrderDetailsView(props) {
	const location = useLocation();

	const [labOrderDetailedView, setLabOrderDetailedView] = useState([]);
	const [load, setLoad] = useState(false);
	useEffect(() => {
		const PostData = {
			labOrderId: location.state.data
		};
		callingAPI('clinicalBackground/OutsideLabDetails', PostData)
			.then((data) => {
				setLabOrderDetailedView(data.data.result.labOrderDetailedView);
				setLoad(true)
			})
			.catch();
	}, [props]);
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper hospitalLabOrderView exportCalndrSec">
			<LabHeader />
			<div className="contentWraper contentWraperScroll">
				<LabLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className='row'>
							<div className="breadcumbDiv">
								<Breadcrumb>
									<BreadcrumbItem>
										<Link to="/labLogin/outsideLabOrderDetails">Outside Lab Order</Link>
									</BreadcrumbItem>
									<div class="arrow-right" style={{ marginLeft: '7px' }} />
									<BreadcrumbItem>Outside Lab Order Details</BreadcrumbItem>
								</Breadcrumb>
							</div>
						</div>
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle">Outside Lab Order Details</div>
							</div>
						</div>
						{/* <div className="paginationSection">
						<Pagination
							itemsCountPerPage={2}
							totalItemsCount={5}
						/>
					</div> */}
						<div className="dashboardContent" style={{ background: "transparent" }}>
							{load === false ? <Loader /> : ''}
							<div className="reportWrap">
								<div className="dashboardtable">
									<table>
										<thead>
											<tr>
												<th>Test Name </th>
												<th>Specimen</th>
												<th>Test Type</th>
												<th>Note</th>
											</tr>
										</thead>
										<tbody>
											{labOrderDetailedView.map((data, i) => (
												<tr>
													<td>{data.testname}</td>
													<td>{data.spename}</td>
													<td>{data.testtype}</td>
													<td>{data.note}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							{/* <div className="paginationSection">
						<Pagination
							itemsCountPerPage={2}
							totalItemsCount={5}
						/>
					</div> */}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default OutsideLabOrderDetailsView;
