import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import img1 from '../images/icons/treatment.png';
import Tickk from '../images/icons/tickk.png';
import Printer_icon from '../images/icons/printer_icon.png';
import Editicon from '../images/icons/edit-bg.png';
import { callingAPI } from '../config';
import { Link } from 'react-router-dom';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
function CompletedPlans() {
	const navigate = useNavigate();
	const [completedPlan, setCompletedPlan] = useState([]);
	const [completedPlanArray, setCompletedPlanArray] = useState([]);
	const [memberProcedureInvoiceId, setmemberProcedureInvoiceId] = useState("");
	const [isData, setIsData] = useState(false);
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	const [checkedIds, setCheckedIds] = useState([])
	const [procedureCheckIds, setprocedureCheckIds] = useState([])
	useEffect(() => {
		listing();
	}, []);
	const listing = () => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			memberProcedureDentalDetailsId: '',
			isCompleted: true,
		};
		callingAPI('treatmentPlanDetails/completedTreatmentListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setCompletedPlan(data.data.result);
				setIsData(true);
			} else {
				setIsData(true);
			}
		});
	};
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const createInvoice = (a, j, i, hidMemberProcedureDentalDetailsId) => {
		// if (document.getElementById('treatmentPlan' + hidMemberProcedureDentalDetailsId).checked === true) {
		// 	document.getElementById("saveInvoiceBtn").classList.remove("test")
		// 	document.getElementById("createInvoiceBtn").classList.remove("test")
		// } else {
		// 	document.getElementById("saveInvoiceBtn").classList.add("test")
		// 	document.getElementById("createInvoiceBtn").classList.add("test")
		// }
		var completedArray = [...procedureCheckIds];
		if (typeof completedArray === 'undefined') {
			completedArray = [];
		}
		var treatmentArrayIndex = completedArray.indexOf(hidMemberProcedureDentalDetailsId);
		if (treatmentArrayIndex >= 0) {
			completedArray.splice(treatmentArrayIndex, 1);
		} else {
			completedArray.push(hidMemberProcedureDentalDetailsId);
		}
		setprocedureCheckIds(completedArray)
		if (completedArray.length > 0) {
			document.getElementById("saveInvoiceBtn").classList.remove("test")
			document.getElementById("createInvoiceBtn").classList.remove("test")
		} else {
			document.getElementById("saveInvoiceBtn").classList.add("test")
			document.getElementById("createInvoiceBtn").classList.add("test")
		}

	}
	const createInvoiceForSelected = () => {

		document.getElementById('treatmentCompleteTick').click();
	}

	const convertDate = (date) => {
		var formatedDate = ""
		if (date !== "") {
			var split = date.split(" ")
			var dateSplit = split[0].split("/")
			formatedDate = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0] + " " + split[1] + " " + split[2]

		}
		return formatedDate
	}

	const getTaxDetails = async (id, amount) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				"procedureId": id,
				"amount": amount
			};
			callingAPI('commonUserFunctions/getTaxDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					var a = {
						taxDetails: data.data.result.taxDetails,
						taxAmountTotal: data.data.result.taxAmountTotal
					}
					resolve(a);
				} else {
					resolve([]);
				}
			});
		});
	};

	const saveInvoiceForSelected = async (procedureCheckIds) => {
		setIsData(false);

		var plannedProArray = [...completedPlan]
		var filteredProcedureData = []
		for (var l = 0; l < procedureCheckIds.length; l++) {
			for (var i = 0; i < plannedProArray.length; i++) {
				for (var j = 0; j < plannedProArray[i].itemArray.length; j++) {
					for (var k = 0; k < plannedProArray[i].itemArray[j].item.length; k++) {
						var a = plannedProArray[i].itemArray[j].item[k].hidMemberProcedureDentalDetailsId
						if (procedureCheckIds[l] === a) {
							filteredProcedureData.push(plannedProArray[i].itemArray[j].item[k])
						}
					}
				}
			}
		}


		var proArray = [];
		var taxDetails = []
		for (var k = 0; k < filteredProcedureData.length; k++) {
			if (filteredProcedureData[k].hidTaxIndividual > 0) {
				var taxDetailsObj = await getTaxDetails(filteredProcedureData[k].hidProcedureId, ((filteredProcedureData[k].quantity) * filteredProcedureData[k].netAmount));
				taxDetails = taxDetailsObj.taxDetails;
			}
			//var time = new Date(convertDate(filteredProcedureData.dateOn));
			var date2 = moment(filteredProcedureData[k].dateOn).format('yyyy-MM-DD h:mm A');
			var milliStart = moment(date2).valueOf();
			var milliEnd = moment(date2).add(30, 'm').format('yyyy-MM-DD h:mm A');
			var objArray = {
				hidProcedureId: filteredProcedureData[k].hidProcedureId,
				hidDoctorId: window.sessionStorage.getItem('patientDoctorId'),
				hidTaxIndividual: filteredProcedureData[k].hidTaxIndividual,
				txtTreatmentCost: filteredProcedureData[k].treatmentCost,
				hidDiscountIndividual: '0.00',
				hidGrandIndividual: (filteredProcedureData[k].quantity * filteredProcedureData[k].treatmentCost) + filteredProcedureData[k].hidTaxIndividual,
				txtNotes: filteredProcedureData[k].notes,
				spinner: filteredProcedureData[k].quantity,
				hidTeethNumber: filteredProcedureData[k].hidTeethNumber,
				disType: 'percentage',
				txtDisAmt: '0.00',
				scheduledtimeSlotStartTime: milliStart,
				scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
				chkMultipleCost: filteredProcedureData[k].chkMultipleCost,
				appDate: date2,
				dateOn: new Date(date2),
				hidMemberProcedureDentalDetailsId: filteredProcedureData[k].hidMemberProcedureDentalDetailsId,
				procedureName: filteredProcedureData[k].procedureName,
				procedureCode: filteredProcedureData[k].procedureCode,
				showTeeth: filteredProcedureData[k].showTeeth,
				hidDoctorId: filteredProcedureData[k].completedBy,
				doctorName: filteredProcedureData[k].completedByDoctorName,
				fullMouth: 0,
				taxDetails: taxDetails
			};
			proArray.push(objArray);
		}
		//setCompletedPlanArray(proArray)

		var date = moment(new Date()).format('yyyy-MM-DD hh:mm A');
		var date2 = moment(new Date()).format('yyyy-MM-DD HH:mm:ss');
		var milliStart = moment(date).valueOf();
		var milliEnd = moment(date).add(30, 'm').format('yyyy-MM-DD h:mm A');
		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			hidMemberId: window.sessionStorage.getItem('memberId'),
			prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,

			hidDoctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			scheduledtimeSlotStartTime: milliStart,
			scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
			txtFinalDiscount: "0",
			hidAdvcAmtSave: '0',
			hidAdvcAmt: '0',
			hidPayAfterAdvnc: "0",
			hidPromoId: '',
			hidPromoAmt: '',
			hidPromoPerc: '',
			promoReason: '',
			txtAmount: "",
			generatedOn: date2,
			currentDateTime: date,
			hidInvoiceId: "",
			hidRemoveIdsPrescription: '',
			paymentType: '1',
			txtDoctorName: '',
			txtPatientName: '',
			txtPatientAge: '',
			hidFromPage: '',
			hidTreatmentDetails: proArray,
		};

		callingAPI('commonUserFunctions/saveInvoicePayNow', postData).then((data) => {
			setIsData(true);
			if (data.data.success === '1') {
				alert('Invoice Saved Successfully');
				window.sessionStorage.setItem('memberProcedureInvoiceId', data.data.result.memberProcedureInvoiceId)
				console.log("....",data.data.result.memberProcedureInvoiceId);
				window.location.href = "/patients/addPayments";

			} else {
				alert(data.data.errorMessage);
			}

		});
	}
	const IncompletePlan = (hidMemberProcedureDentalDetailsId) => {
		var x = window.confirm('Do you want to Incomplete?');
		if (x) {
			const apiJson = {
				"userId": window.sessionStorage.getItem('userId'),
				"memberProcedureDentalDetailsId": hidMemberProcedureDentalDetailsId
			};
			callingAPI('calenderListing/removeCompletedPlan', apiJson).then((data) => {
				if (data.data.success === '1') {
					listing();
					window.location.reload()
					setIsData(true);
				} else {
					setIsData(true);
				}
			});
		} else {
			document.getElementById("dropdown" + hidMemberProcedureDentalDetailsId).style.display = "none"
		}
	}
	const editCompletePlan = (data) => {
		navigate('/patients/addCompletedPlans', { state:  data  })
	}
	const invoiceCeateTreatment = (data) => {
		navigate('/patients/patientInvoiceAdd', { state: data });
	}
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										{isData === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">COMPLETED PLANS</span>

												<a href="/patients/addCompletedPlans">
													<span class="treatmentPlanBtn pl_blue_btn">+ Add</span>
												</a>
												<span class="treatmentPlanBtn pl_blue_btn test" id="createInvoiceBtn" onClick={() => createInvoiceForSelected()}>
													<span style={{ display: 'none' }}
														id={'treatmentCompleteTick'} onClick={()=>invoiceCeateTreatment({ procedurecheckedIds: procedureCheckIds, hospitalId: localStorage.getItem('hospitalId'), memberId: window.sessionStorage.getItem('memberId'), isFlag: "" })}
													></span>
													Create invoice for selected treatment
												</span>
												<span class="treatmentPlanBtn pl_blue_btn test" id="saveInvoiceBtn" onClick={() => saveInvoiceForSelected(procedureCheckIds)}>
													<Link style={{ display: 'none' }}
														id={'treatmentCreateTick'} to={{ pathname: '/patients/addPayments' }}
													></Link>
													Save Invoice & Create Payment
												</span>
											</div>
											{completedPlan.length > 0 ? (
												completedPlan.map((copletedObj, a) => (
													<div
														class="treatmentPlanList1"
														style={{ height: 'auto', paddingLeft: '10px' }}
													>
														<div class="treatmentPlanDate">{copletedObj.visitDate}</div>
														<div class="treatmentTable planActive">
															<table>
																<thead>
																	<tr>
																		<th class="col-md-3">
																			<div class="dentalProName">PROCEDURE</div>
																		</th>
																		<th class="col-md-1" >
																			<div class="dentalProCost" style={{ textAlign: "center" }}>QTY</div>
																		</th>
																		{/* <th class="col-md-1" style={{ textAlign: "right" }}>
																			<div class="dentalProCost" style={{ paddingRight: "0px" }}>QTY</div>
																		</th> */}
																		<th class="col-md-2" style={{ paddingRight: "0px" }}>
																			<div class="dentalProCost"  >COST</div>
																		</th>
																		{/* <th class="col-md-1" /> */}
																		<th class="col-md-1" style={{ textAlign: "right", paddingRight: "0px" }}>
																			<div class="dentalProDis" style={{ textAlign: "right" }}>TAX</div>
																		</th>
																		<th class="col-md-2" style={{ padding: "0px" }}>
																			<div class="dentalProTotal" style={{ textAlign: "right" }}>TOTAL</div>
																		</th>
																		<th class="col-md-3">
																			<div class="dentalProNote" style={{ paddingLeft: "0px" }}>NOTES</div>
																		</th>
																	</tr>
																</thead>
																{copletedObj.itemArray.map((itemObj, j) =>
																	itemObj.item.map((obj, i) => (
																		<tbody style={{ borderBottom: '1px solid #cecece' }}>
																			<tr class="groupBrdr">
																				<td class="col-md-3">
																					<div class="dentalProName">
																						<div class="optionWrapper">
																							{obj.isPaymentCompleted ===
																								'1' ? (
																								<img src={img1} title="Invoice Generated" />
																							) : (
																								<input
																									type="checkbox"
																									id={
																										'treatmentPlan' +
																										obj.hidMemberProcedureDentalDetailsId
																									}
																									onClick={() => createInvoice(a, j, i, obj.hidMemberProcedureDentalDetailsId)}
																								/>
																							)}

																							<label
																								for={
																									'treatmentPlan' +
																									obj.hidMemberProcedureDentalDetailsId
																								}
																							/>
																						</div>
																						<span
																							class="elipsis" style={{ width: "100%", maxWidth: "190px" }}
																							title={obj.procedureName}
																						>
																							<b>{obj.procedureName}</b>
																						</span>
																						<span
																							class="elipsis"
																							title={obj.procedureCode}
																							style={{ fontSize: '10px', width: "100%", maxWidth: "190px" }}
																						>
																							{obj.procedureCode}
																						</span>
																						<br />
																						{obj.hidTeethNumber !== '' &&
																							obj.hidTeethNumber !== null &&
																							obj.hidTeethNumber !==
																							'None Selected' && (
																								<span
																									class="elipsis"
																									title={
																										obj.hidTeethNumber
																									}
																									style={{
																										fontSize: '11px',
																										color: '#004d75',
																									}}
																								>
																									Teeth :{' '}
																									{obj.hidTeethNumber}
																								</span>
																							)}
																					</div>
																				</td>
																				{/* <td
																					class="col-md-1"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProCost">
																							{ obj.quantity}
																					</div>
																				</td> */}
																				<td
																					class="col-md-1 treatQty"
																					style={{ verticalAlign: 'middle' }}
																				>
																					{obj.quantity}
																				</td>
																				<td
																					class="col-md-2"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProCost">
																						{parseFloat(
																							obj.treatmentCost
																						).toFixed(2)}
																					</div>
																				</td>
																				{/* <td class="col-md-1" /> */}
																				<td
																					class="col-md-1"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProDis" style={{ textAlign: "right" }}>
																						{parseFloat(
																							obj.hidTaxIndividual
																						).toFixed(2)}
																					</div>
																				</td>
																				<td
																					class="col-md-2"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProTotal" style={{ paddingLeft: "5px", textAlign: "right" }}>
																						{parseFloat(
																							obj.netAmount
																						).toFixed(2)}
																					</div>
																				</td>
																				<td
																					class="col-md-3"
																					style={{ verticalAlign: 'middle' }}
																				>
																					<div class="dentalProNote compltdPlanDpdwn">
																						<span
																							class="elipsisNotes"
																							title={obj.notes}
																							style={{ width: "140px" }}
																						>
																							{obj.notes}
																						</span>
																						{/*  */}
																						<button className="pl_print" >
																							<a
																								target={"_blank"}
																								href={
																									'/patients/treatmentPlansPrint/' +
																									obj.hidMemberProcedureDentalOrderId +
																									'|' +
																									obj.hidMemberProcedureDentalDetailsId + '|' + localStorage.getItem('hospitalId')
																								}
																							>
																								<img
																									src={Printer_icon}
																								/>
																							</a>{' '}
																						</button>
																						{/*  */}
																						<label class="dropdown">
																							<div class="dd-button" onClick={() => droplist("dropdown", obj.hidMemberProcedureDentalDetailsId)} />

																							<input
																								type="checkbox"
																								class="dd-input"
																								id="test"
																							/>

																							<ul class="dd-menu" id={"dropdown" + obj.hidMemberProcedureDentalDetailsId} >
																								<li onClick={()=>editCompletePlan({
																												dentalOrderId:
																													obj.hidMemberProcedureDentalDetailsId,
																												isCompleted: true,
																											})}>
																									{/* <Link
																										to={{
																											pathname:
																												'/patients/addCompletedPlans',
																											state: {
																												dentalOrderId:
																													obj.hidMemberProcedureDentalDetailsId,
																												isCompleted: true,
																											},
																										}}
																									> */}
																										<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																									{/* </Link> */}
																								</li>
																								{obj.isPaymentCompleted === 0 ?
																									<li onClick={(e) => IncompletePlan(obj.hidMemberProcedureDentalDetailsId)}>
																										<FontAwesomeIcon icon={faArrowDown} style={{ width: '15px', marginRight: '3px' }} />  Incomplete
																									</li>
																									: ""
																								}
																							</ul>
																						</label>
																					</div>
																				</td>
																			</tr>

																			<tr class="dentalProPlaned">
																				<input
																					type="hidden"
																					name="hidTreatmentIds00"
																					id="hidTreatmentIds00"
																					value=""
																				/>
																				<td colspan="10">
																					<div class="plannedBy">
																						Completed by{' '}
																						<span>{obj.completedByDoctorName}</span>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	))
																)}
															</table>
														</div>
													</div>
												))
											) : isData === true ? (
												//<NoData />
												<span>
													<span class="treatmentCommonTxt1">
														<span>
															{' '}
															No Completed Plan Found
														</span>
													</span>
												</span>
											) : (
												''
											)}
										</div>

										<div id="addTreatmentPlanDiv" class="addTreatmentCover">
											<div class="loaderWrap" id="loader1" style={{ display: 'none' }}>
												<div class="loaderPurple" />
												{/* <img src="../images/dental/purpleLoader.png"> */}
											</div>
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CompletedPlans;
