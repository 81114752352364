import React, { useState, useEffect } from 'react';
import LabHeader from '../labLogin/layout/header';
// import Footer from '../labLogin/layout/footer';
import Footer from '../layout/footer';
import { Route, Link } from 'react-router-dom';
import LabLeft from '../labLogin/labLeft'
import Datepicker, { CalendarContainer } from 'react-datepicker';
import labPrescription from '../images/icons/lab/labPrescription.png';
import Printer_icon from '../images/icons/printer_icon.png';
import labEye from '../images/icons/lab/labEye.png';
import labEdit from '../images/icons/lab/labEdit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { callingAPI } from '../config';
import NoData from '../Common/noDataFound';
import moment from 'moment';
import Loader from '../Common/loader';
import Pagination from 'react-js-pagination';
import BillingLeft from '../billingLogin/billingLeft';
import BillingHeader from '../billingLogin/layout/header';
import { useNavigate } from 'react-router-dom';

function LabOrderDetails() {
    const navigate = useNavigate();

    const [PopUpodataFlag, setPopUpodataFlag] = useState(false);
    const [PopUpodataFlag1, setPopUpodataFlag1] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [person, setPerson] = useState([]);
    const [doctorIdTest, setdoctorIdTest] = useState('');
    const [doctorvisitIdTest, setdoctorvisitIdTest] = useState('');
    const [memeberIdTest, setmemeberIdTest] = useState('');
    const [txtlabTechnician, settxtlabTechnician] = useState('');
    const [txtlabTechnologist, settxtlabTechnologist] = useState('');
    const [txtVerifiedBy, settxtVerifiedBy] = useState('');
    const [getalltestId, setgetalltestId] = useState('');
    const [testResultIdvalue, settestResultIdvalue] = useState([]);
    const [labOrderDetailsList, setLabOrderDetailsListData] = useState([]);
    const [labOrderDetailsView, setLabOrderDetailsListView] = useState([]);
    const [labTestDetailedInfo, setLabTestDetailedInfo] = useState([]);
    const [labmemberInfo, setLabmemberInfo] = useState([]);
    const [printLabTestId, setprintLabTestId] = useState();
    const [memberIdvalue, setmemberIdvalue] = useState('');
    const [testDatevalue, settestDatevalue] = useState('');
    const [patientName, setPatientName] = useState('');
    const [mrNumber, setMrNumber] = useState('');
    const [detailTestDate, setDetailTestDate] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState('');
    const [pageOffset, setPageOffset] = useState("0");
    const [dataFlag, setDataFlag] = useState(false);
    var date = new Date();
    var requiredDate = moment(date).format('DD-MM-YYYY');
    var toDay = requiredDate.split('-');
    toDay = new Date(toDay[2], toDay[1] - 1, toDay[0]);
    const [labFormDetailsPrintIdarray, setLabFormDetailsPrintIdarray] = useState([])
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        var strOffset = '' + offset;
        setActivePage(pageNumber);
        setPageOffset(strOffset);
        let PostData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            max: max,
            offset: strOffset,
            searchId: document.getElementById('selectName').value,
            searchValue: document.getElementById('searchText').value,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
        };
        callingAPI('calenderListing/labOrderDetails', PostData).then((data) => {
            if (data.data.success === '1') {
                setLabOrderDetailsListData(data.data.result.testGroupData);
                setCount(data.data.result.totalCount);
            }
        })
            .catch();
    };
    const changeText = () => {
        if (document.getElementById('selectName').value !== '') {
            document.getElementById('searchText').style.backgroundColor = 'WHITE'
            document.getElementById('searchText').disabled = false;
            document.getElementById('searchText').focus();
        } else {
            document.getElementById('searchText').style.backgroundColor = 'transparent'
            document.getElementById('searchText').disabled = true;
        }
        document.getElementById('searchText').value = '';
    };
    const searchValueChange = () => {
        if (startDate && endDate) {
            if (startDate > endDate) {
                alert('End date must be greater than start date');
                document.getElementById("todate").focus()
                return (false);
            }
        }
        if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
            var t = document.getElementById('selectName');
            var selectedText = t.options[t.selectedIndex].text;
            document.getElementById('searchText').focus();
            alert('Enter ' + selectedText);
            return false;
        }
        setActivePage(1);
        let PostData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            max: process.env.REACT_APP_PAGINATION_COUNT,
            offset: "0",
            searchId: document.getElementById('selectName').value,
            searchValue: document.getElementById('searchText').value,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
        };
        callingAPI('calenderListing/labOrderDetails', PostData).then((data) => {
            if (data.data.success === '0') {
                setLabOrderDetailsListData(data.data.result.testGroupData);
                setCount(data.data.result.totalCount);
            }
            if (data.data.success === '1') {
                setLabOrderDetailsListData(data.data.result.testGroupData);
                setCount(data.data.result.totalCount);
                setPageOffset("0");
            }
        })
            .catch();
    };
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    useEffect(() => {
        window.sessionStorage.removeItem("testresultmemebrid")
        changeText();
        labOrderDetailsListing();
        if (window.sessionStorage.getItem("htmlData") !== null) {
            window.open(`${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${window.sessionStorage.getItem("htmlData")}`, "_blank")
        }
        window.sessionStorage.removeItem("htmlData")
    }, [testResultIdvalue]);
    const labOrderDetailsListing = () => {
        const PostData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            max: process.env.REACT_APP_PAGINATION_COUNT,
            offset: pageOffset,
            searchId: document.getElementById('selectName').value,
            searchValue: document.getElementById('searchText').value,
        };
        callingAPI('calenderListing/labOrderDetails', PostData).then((data) => {
            if (data.data.success === '1') {
                setLabOrderDetailsListData(data.data.result.testGroupData);
                setCount(data.data.result.totalCount);
                setDataFlag(true);
            }
            else {
                setDataFlag(true);
                setLabOrderDetailsListData([]);
            }
        });
    };
    const labTestDetailedListInfo = (memberId, testDate, labOrderTestId) => {
        const PostData =
        {
            hospitalId: localStorage.getItem("hospitalId"),
            labOrderTestId: labOrderTestId,
            memberId: memberId,
            testDate: testDate,
        };
        setPopUpodataFlag1(false);
        callingAPI('calenderListing/labTestReadingDetails', PostData).then((data) => {
            if (data.data.success === '1') {
                setPopUpodataFlag1(true);
                setgetalltestId(labOrderTestId)
                setLabTestDetailedInfo(data.data.result.testGroupData);
                setdoctorIdTest(data.data.result.testGroupData[0].doctorId)
                setdoctorvisitIdTest(data.data.result.testGroupData[0].doctorVisitId)
                setmemeberIdTest(memberId)
                setLabmemberInfo(data.data.result.memberDetails);
            }
            else {
                setPopUpodataFlag1(true);
            }
        });
    };
    const labTestDetailsListView = (memberId, testDate) => {
        const PostData = {
            userId: window.sessionStorage.getItem('userId'),
            memberId: memberId,
            testDate: testDate,
        };
        setPopUpodataFlag(false);
        callingAPI('calenderListing/labOrderTestDetails', PostData).then((data) => {
            if (data.data.success === '1') {
                setPopUpodataFlag(true);
                setLabOrderDetailsListView(data.data.result.testGroupData);
                settestDatevalue(testDate)
                setmemberIdvalue(memberId)
            }
            else {
                setPopUpodataFlag1(true);
            }
        });
    };
    const printClick1 = (i, j, k, labDetailsTestId) => {
        const value = labDetailsTestId;
        setPerson((item) =>
            person.includes(value)
                ? item.filter((data) => data !== value)
                : [...item, labDetailsTestId]
        );
    };
    const editLabOrder = (data) => {
        navigate('/patients/patientInvoiceAdd', { state: data })
    }
    const LabOrderClick = (data) => {
        navigate('/patients/testResultAdd', { state: data })
    }
    const labprintSelected = () => {
        if (person.length == "0") {
            alert("Atleast one test required")
        }
        else {
            window.sessionStorage.setItem("testDatevalueprint", testDatevalue)
            window.sessionStorage.setItem("personId", person.toString().replace(/[\[\]']+/g, ''))
            window.sessionStorage.setItem("memberIdvalue", memberIdvalue)
            window.open("labTestPrint");
        }
    }
    const labprintAll = () => {
        window.sessionStorage.setItem("testDatevalueprint", testDatevalue)
        window.sessionStorage.setItem("personId", "")
        window.sessionStorage.setItem("memberIdvalue", memberIdvalue)
        window.open("labTestPrint");
    }
    const txtVerifiedBychange = (event) => {
        settxtVerifiedBy(event.target.value);
    };
    const txtlabTechnicianchange = (event) => {
        settxtlabTechnician(event.target.value);
    };
    const txtlabTechnologistonchange = (event) => {
        settxtlabTechnologist(event.target.value);
    };
    const PrintTestresult = (a, b, testResultId, event) => {
        const value = testResultId;
        settestResultIdvalue((item) =>
            testResultIdvalue.includes(value)
                ? item.filter((data) => data !== value)
                : [...item, testResultId]
        );
    }
    const testresultprintSelected = () => {
        if (testResultIdvalue.length == "0") {
            alert("Atleast one test required")
        }
        else {
            window.sessionStorage.setItem("doctorIdTest", doctorIdTest)
            window.sessionStorage.setItem("doctorvisitIdTest", doctorvisitIdTest)
            window.sessionStorage.setItem("memeberIdTest", memeberIdTest)
            window.sessionStorage.setItem("txtVerifiedBy", txtVerifiedBy)
            window.sessionStorage.setItem("txtlabTechnician", txtlabTechnician)
            window.sessionStorage.setItem("txtlabTechnologist", txtlabTechnologist)
            window.sessionStorage.setItem("laborderTestIdValue", testResultIdvalue.toString().replace(/[\[\]']+/g, ''))
            window.open("testResultPrint");
        }
    }
    const testresultprintAll = () => {
        // if(person.length == "0"){
        //     alert("Atleast one test required")
        // }
        // else{
        window.sessionStorage.setItem("doctorIdTest", doctorIdTest)
        window.sessionStorage.setItem("doctorvisitIdTest", doctorvisitIdTest)
        window.sessionStorage.setItem("memeberIdTest", memeberIdTest)
        window.sessionStorage.setItem("txtVerifiedBy", txtVerifiedBy)
        window.sessionStorage.setItem("txtlabTechnician", txtlabTechnician)
        window.sessionStorage.setItem("txtlabTechnologist", txtlabTechnologist)
        window.sessionStorage.setItem("laborderTestIdValue", getalltestId)
        window.open("testResultPrint");
        // }
    }
    const FromDateClear = () => {
        setStartDate("")
    };
    const MyContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
                    </span>
                </div>
            </CalendarContainer>
        );
    };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    const ToDateClear = () => {
        setEndDate("")
    };
    const sessionSetting = (memberId, hospitalId, doctorId) => {
        window.sessionStorage.setItem("memberId", memberId)
        localStorage.setItem('hospitalId', hospitalId)
        window.sessionStorage.setItem("doctorId", doctorId)
    }
    const MyContainerTo = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
                        {/* <a  className="closeCalander">Close</a> */}
                    </span>
                </div>
            </CalendarContainer>
        );
    };
    return (
        <div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec">
            {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? <BillingHeader /> : <LabHeader />}
            <div className="contentWraper contentWraperScroll">
                {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? <BillingLeft /> : <LabLeft />}
                <div className="settingsRight">
                    <div className="dashboardCover pharmacyManufactureWrap">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="dashboardTitle">Lab Order Details</div>
                            </div>
                            <div className="col-md-2">
                                <a style={{ textDecoration: 'none' }}>
                                    <span
                                        className="dashButtonBorder labButton" style={{ borderRadius: "0px" }}
                                    >
                                        <nav onClick={() => editLabOrder({ hospitalId: localStorage.getItem('hospitalId'), memberId: "", isFlag: "Add Lab" })}
                                            style={{ color: '#fff' }}> Buy Lab Order</nav>
                                    </span>
                                </a>
                            </div>
                        </div>
                        {count > 10 && (
                            <div className="paginationSection">
                                <Pagination
                                    hideNavigation
                                    activePage={activePage}
                                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                                    onChange={handlePageChange}
                                />
                            </div>
                        )}
                        <div className="dashboardContent">
                            <div className="reportWrap">
                                <div className="dashboardSearch">
                                    <div className="row">
                                        <div className="col-md-2 dashboardSearchList">
                                            <select
                                                autocomplete="off"
                                                className="input-design inputField"
                                                id="selectName"
                                                name="selectName"
                                                onChange={changeText}
                                            >
                                                <option value="">Select</option>
                                                {window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<option value="1">MR Number</option>)}
                                                <option value="2">Patient Name</option>
                                                {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<option value="3">PR Number</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <input
                                                type="text"
                                                className="inputField"
                                                placeholder='Enter Value'
                                                autocomplete="off"
                                                id="searchText"
                                                name="searchText"
                                                style={{ background: "transparent" }} />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <Datepicker
                                                autoComplete='off'
                                                required
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className="Datepicker pa2 inputField  "
                                                placeholderText="From Date"
                                                calendarClassName="rasta-stripes"
                                                selected={startDate}
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => setStartDate(date)}
                                                id="fromdate"
                                                calendarContainer={MyContainer}
                                                onChangeRaw={handleDateChangeRaw}
                                            />{' '}
                                            <label
                                                className="icon iconCalendar calendar plcalendar"
                                                style={{ fontSize: '16px' }}
                                                htmlFor="fromdate"
                                            >
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </label>
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <Datepicker
                                                autoComplete='off'
                                                required
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className="Datepicker pa2 inputField"
                                                // maxDate={new Date()}
                                                placeholderText="To Date"
                                                calendarClassName="rasta-stripes"
                                                selected={endDate}
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => setEndDate(date)}
                                                id="todate"
                                                calendarContainer={MyContainerTo}
                                                onChangeRaw={handleDateChangeRaw}
                                            />{' '}
                                            <label
                                                className="icon iconCalendar calendar plcalendar"
                                                style={{ fontSize: '16px' }}
                                                htmlFor="todate"
                                            >
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </label>
                                        </div>
                                        <div className="col-md-2 dashboardSearchList"  >
                                            <span
                                                className="dashButtonBorder labButton"
                                                onClick={searchValueChange}
                                                style={{ padding: "8px 20px", height: "35px" }}>Search</span>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}
                                {dataFlag == false ? (
                                    <Loader />
                                ) : labOrderDetailsList.length > 0 ? (
                                    <div>
                                        {labOrderDetailsList.map((listObj, i) => (
                                            <div class="treatmentPlanList">
                                                <div class="treatmentPlanDate">{listObj.testDate}</div>
                                                <div class="treatmentTable planActive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th class="col-md-3"><div class="dentalProName">Patient Name</div></th>
                                                                {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                    (<th class="col-md-2"><div class="dentalProName">MR No</div></th>) :
                                                                    (<th class="col-md-2"><div class="dentalProName">PR No</div></th>)
                                                                }
                                                                <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                                <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                                <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                                <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                                <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listObj.labDetails.map((itemObj, j) => (
                                                                <tr class="groupBrdr">
                                                                    <td class="col-md-3">
                                                                        <div class="dentalProName paddingFive">
                                                                            <span class="elipsis">{itemObj.displayName} </span>
                                                                        </div>
                                                                    </td>
                                                                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                        (<td class="col-md-2"><div class="dentalProName paddingFive">{itemObj.mrNo} </div></td>) :
                                                                        (<td class="col-md-2"><div class="dentalProName paddingFive">{itemObj.prNo} </div></td>)
                                                                    }
                                                                    <td class="col-md-1"><div class="dentalProName txtCenter">
                                                                        <span class="viewDetailLink" onClick={() => sessionSetting(itemObj.memberId, itemObj.hospitalId, itemObj.doctorId)}>
                                                                            {/* <a href="/patients/patientInvoiceAdd" title="View Details"><img src={labEye} /> */}
                                                                            <nav onClick={() => editLabOrder({ labViewIds: itemObj.labOrderTestId, memberId: itemObj.memberId, hospitalId: itemObj.hospitalId, isFlag: "Lab" })}
                                                                            >  <img src={labEye} title="Click to View" /></nav>
                                                                            {/* </a> */}
                                                                        </span></div>
                                                                    </td>
                                                                    <td class="col-md-1"><div class="dentalProName txtCenter"
                                                                        onClick={() => labTestDetailsListView(itemObj.memberId, itemObj.testDate)}>
                                                                        <span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Lab Test">
                                                                            <a href="#labTestDetails" > <img src={Printer_icon} /></a>
                                                                        </span></div>
                                                                    </td>
                                                                    <td class="col-md-2"><div
                                                                        class="dentalProName txtCenter"><span class="viewDetailLink">
                                                                            {/* <a href=" " class="clrBlack" title="Add Test Result"> */}
                                                                            <nav onClick={() => LabOrderClick({
                                                                                testmemberId: itemObj.memberId, istestFlag: "TestResultLBD"
                                                                            })}>
                                                                                <img src={labPrescription} class="clrBlack" title="Add Test Result" /></nav>
                                                                            {/* <img src={labPrescription} /></a>  */}
                                                                        </span></div>
                                                                    </td>
                                                                    <td class="col-md-2"><div class="dentalProName txtCenter"><span class="viewDetailLink">
                                                                        {/* <a href=" " class="clrBlack" title="Edit Test Result"> */}
                                                                        <nav onClick={() => LabOrderClick({
                                                                            testmemberId: itemObj.memberId, istestFlag: "TestResultLBD1"
                                                                        })}
                                                                        > <img src={labEdit} class="clrBlack" title="Edit Test Result" /></nav>
                                                                        {/* <img src={labEdit} /> */}
                                                                        {/* </a> */}
                                                                    </span></div>
                                                                    </td>
                                                                    <td class="col-md-1"><div class="dentalProName txtCenter"
                                                                        onClick={() => labTestDetailedListInfo(itemObj.memberId, itemObj.testDate, itemObj.labOrderTestId)}
                                                                    ><span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Test Result">
                                                                            <a href="#labTestDetailedInfo"><img src={Printer_icon} /></a>
                                                                        </span></div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>
                                        <NoData />
                                    </p>
                                )}
                                {/* PRINNT 1st MODAL BEGINS HERE */}
                                <div id="labTestDetails" class="modalDialog">
                                    <div>
                                        <a href="#close" title="Close" class="close exportClose">X</a>
                                        <div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
                                            <div class="addDataHead">
                                                <span className='labTitle'>Lab Test Details</span>
                                            </div>
                                            {/* --- */}
                                            {PopUpodataFlag == false ? (
                                                <Loader />
                                            ) : labOrderDetailsView.length > 0 ? (
                                                <div>
                                                    {labOrderDetailsView.map((labDataitem, q) => (
                                                        <div class="addDataContent" id="medicineDetails">
                                                            <div class="patientNameDiv row">
                                                                <span class="col-md-2 patientName patientDate">
                                                                    <label>Date :</label>
                                                                    <span>{labDataitem.testDate}</span>
                                                                </span>
                                                                <span class="col-md-5 patientName ">
                                                                    <label>Patient Name : {labDataitem.patientName}</label>
                                                                    <span></span>
                                                                </span>
                                                                <span class="col-md-3 patientName">
                                                                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                        <label>MR No : {labDataitem.mrNo} </label>
                                                                        :
                                                                        <label>PR No : {labDataitem.prNo} </label>

                                                                    }
                                                                </span>

                                                            </div>
                                                            <div>
                                                                {labDataitem.labData.map((labDataDetailView, w) => (
                                                                    <div class="treatmentTable planActive">
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th class="col-md-3"><div class="dentalProName">Test Name</div></th>
                                                                                    <th class="col-md-3"><div class="dentalProCost txtLeft">Specimen</div></th>
                                                                                    <th class="col-md-2"><div class="dentalProDis txtLeft">Test Type</div></th>
                                                                                    <th class="col-md-2"><div class="dentalProDis txtLeft">Amount</div></th>
                                                                                    <th class="col-md-2"><div class="dentalProTotal txtLeft">Notes</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {labDataDetailView.labDetails.map((labDetails, e) => (
                                                                                    <tr>
                                                                                        <td class="col-md-3">
                                                                                            <div class="dentalProName">
                                                                                                <div class="optionWrapper">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={
                                                                                                            'chkSelect' +
                                                                                                            labDetails.testId}
                                                                                                        onClick={() =>
                                                                                                            printClick1(
                                                                                                                q,
                                                                                                                w,
                                                                                                                e,
                                                                                                                labDetails.testId
                                                                                                            )}
                                                                                                    />
                                                                                                    <label
                                                                                                        for={
                                                                                                            'chkSelect' +
                                                                                                            labDetails.testId
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <span class="elipsis" title="BLOOD -SEROLOGY FOR HIV,HCV,HBsAg ">{labDetails.testName} </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="col-md-3"><div class="dentalProCost txtLeft">{labDetails.specimen}</div></td>
                                                                                        <td class="col-md-2"><div class="dentalProDis txtLeft">{labDetails.testType}</div></td>
                                                                                        <td class="col-md-2"><div class="dentalProDis txtLeft">
                                                                                            {labDetails.buyPrice}
                                                                                        </div></td>
                                                                                        <td class="col-md-2"><div class="dentalProTotal txtLeft" title="">  {labDetails.comments} </div></td>
                                                                                    </tr>
                                                                                ))}
                                                                                <tr class="dentalProPlaned">
                                                                                    <td colspan="3"><div class="plannedBy">Prescribed by <span>{labDataDetailView.prescribedBy}</span></div></td>
                                                                                    <td colspan="2"></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div class="serviceProviderBtns">
                                                                {/* <Link
                                                                to={{
                                                                state:{
                                                                    printLabTestIdvalue:person.toString().replace(/[\[\]']+/g, ''),
                                                                    testDatevalueprint:testDatevalue,
                                                                    memberIdvalueprint:memberIdvalue
                                                                }
                                                                }}
                                                                > */}
                                                                <input type="submit" value="Print Selected" onClick={labprintSelected} style={{ background: "#0096d6" }} />
                                                                {/* </Link> */}
                                                                {/* <Link
                                                                to={{
                                                                    pathname: 'labTestPrint',
                                                                state:{
                                                                    printLabTestIdvalue:"",
                                                                    testDatevalueprint:testDatevalue,
                                                                    memberIdvalueprint:memberIdvalue
                                                                }
                                                                }}
                                                                > */}
                                                                <input type="submit" value="Print All" id="btnPrintAll" onClick={labprintAll} style={{ background: "#0096d6" }} />
                                                                {/* </Link> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div class="noDataFoundCommon treatmentCommonTxtWrap">
                                                    <span class="treatmentCommonTxt">
                                                        <span class="addNewRcdTxt">No Test Result Found </span>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* PRINNT 1st MODAL ENDS HERE */}
                                {/* PRINNT 2nd MODAL BEGINS HERE */}
                                <div id="labTestDetailedInfo" class="modalDialog">
                                    <div>
                                        <a href="#close" title="Close" class="close exportClose">X</a>
                                        <div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
                                            <div class="addDataHead">
                                                <span className='labTitle'>Lab Test Details</span>
                                            </div>
                                            {/* --- */}
                                            {PopUpodataFlag1 == false ? (
                                                <Loader />
                                            ) : labTestDetailedInfo.length > 0 ? (
                                                <div>
                                                    {labTestDetailedInfo.map((itemDetail, a) => (
                                                        <div class="addDataContent" id="medicineDetails">
                                                            {/* <div class="patientNameDiv row">
                                                                <span class="col-md-2 patientName patientDate">
                                                                    <span>{itemDetail.createdOn}</span>
                                                                </span>
                                                            </div> */}
                                                            {/*  */}
                                                            {labmemberInfo.map((info, k) => (
                                                                <div class="patientNameDiv row">
                                                                    <span class="col-md-2 patientName patientDate">
                                                                        <label>Date :</label>
                                                                        <span>{itemDetail.createdOn}</span>
                                                                    </span>
                                                                    <span class="col-md-5 patientName ">
                                                                        <label>Patient Name : {info.memberFirstName}</label>
                                                                        <span></span>
                                                                    </span>
                                                                    <span class="col-md-3 patientName">
                                                                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                            <label>MR No : {info.memberMrNo} </label>
                                                                            :
                                                                            <label>PR No : {info.memberPrNo} </label>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            ))}


                                                            {/*  */}
                                                            <div>
                                                                {itemDetail.labData.map((items, b) => (
                                                                    <div class="treatmentTable planActive">
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th class="col-md-4"><div class="dentalProCost txtLeft">Test Name (Type)</div></th>
                                                                                    <th class="col-md-4"><div class="dentalProDis txtLeft">Normal Value</div></th>
                                                                                    <th class="col-md-4"><div class="dentalProNote txtLeft">Patient Readings</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr class="groupBrdr">
                                                                                    <td colspan="3" class="col-md-12">
                                                                                        <div class="dentalProName">
                                                                                            <div class="optionWrapper">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={
                                                                                                        'chkSelectValue' +
                                                                                                        items.testId}
                                                                                                    onClick={() =>
                                                                                                        PrintTestresult(
                                                                                                            a,
                                                                                                            b,
                                                                                                            items.testId
                                                                                                        )}
                                                                                                />
                                                                                                <label
                                                                                                    for={
                                                                                                        'chkSelectValue' +
                                                                                                        items.testId
                                                                                                    }
                                                                                                />
                                                                                                <label for="chkSelect01"></label>
                                                                                            </div>
                                                                                            <div class="dentalProCost txtLeft" style={{ paddingTop: "0px", paddingBottom: "0", fontWeight: "600", width: "95%" }}>
                                                                                                {items.testName}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                {items.labDetails.map((labiteminfo, l) => (
                                                                                    <>
                                                                                        <tr class="groupBrdr">
                                                                                            <td class="col-md-4"><div class="dentalProDis txtLeft" style={{ paddingLeft: "5px" }}> - {labiteminfo.testName}</div></td>
                                                                                            <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                                                {labiteminfo.normalValue}
                                                                                            </div></td>
                                                                                            <td class="col-md-4">
                                                                                                <div class="dentalProNote paddingEight">
                                                                                                    <span class="elipsis">
                                                                                                        <span style={{ fontWeight: "bold" }}>
                                                                                                            {labiteminfo.patientReading} </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr class="groupBrdr">
                                                                                            <td colspan="3" class="col-md-12">
                                                                                                <div class="dentalProName">
                                                                                                    <b></b>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))}
                                                                                <tr class="dentalProPlaned">
                                                                                    <td colspan="3"><div class="plannedBy">Noted by <span> {items.notedBy}</span></div></td>
                                                                                    <td colspan="2"></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div class="serviceProviderVerfied">
                                                                <span>Verified By : </span>
                                                                <input type="text" onChange={txtVerifiedBychange} />
                                                                <span>Lab Technician : </span>
                                                                <input type="text" onChange={txtlabTechnicianchange} />
                                                                <span>Lab Technologist : </span>
                                                                <input type="text" onChange={txtlabTechnologistonchange} />
                                                            </div>
                                                            <div class="serviceProviderBtns">
                                                                <input type="submit" value="Print Selected" onClick={testresultprintSelected} style={{ background: "#177f40" }} />
                                                                <input type="submit" value="Print All" id="btnPrintAll" onClick={testresultprintAll} style={{ background: "#177f40" }} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div class="noDataFoundCommon treatmentCommonTxtWrap">
                                                    <span class="treatmentCommonTxt">
                                                        <span class="addNewRcdTxt">No Test Result Found </span>
                                                    </span>
                                                </div>
                                            )}
                                            {/* ======= NO DATA FOUND ENDS ======= */}
                                            {/* --- */}
                                        </div>
                                    </div>
                                </div>
                                {/* PRINNT 2nd MODAL ENDS HERE */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        hideNavigation
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <Footer />
        </div>
    );
}
export default LabOrderDetails;
