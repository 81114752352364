import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import { callingAPI } from '../config'
import img from '../images/icons/chart.jpg';
import { Link } from 'react-router-dom';
function PromotionalSMS() {
	const [activePage, setActivePage] = useState(1);
	const [totalDataCount, setTotalDataCount] = useState()
	const[promotionalSms,setPromotionalSms] = useState([])

	// const handlePageChange = (pageNumber) => {
	// 	setActivePage(pageNumber)
	// 	var offset;
	// 	var max;
	// 	if (pageNumber === "1") {
	// 	  offset = "0";
	// 	  max = process.env.REACT_APP_PAGINATION_COUNT;
	// 	  setActivePage(pageNumber)
	// 	}
	// 	else {
	// 	  offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT
	// 	  max = process.env.REACT_APP_PAGINATION_COUNT;
	// 	  setActivePage(pageNumber)
	// 	}
	// 	let PostData = {
	// 		"userId": window.sessionStorage.getItem("userId"),
	// 		"hospitalId": localStorage.getItem("hospitalId"),
	// 		"offset" : offset,
	// 		"max" : max,
	
	// 	}
	// 	console.log(PostData)
	// 	callingAPI('promotionalSms/practiceCommun', PostData
	// 	).then((response) => {
	// 	  if (response.data.success === "1") {
	// 		setPromotionalSms(response.data.result.practiceSmsCommunicationData)
	// 		setTotalDataCount(response.data.result.totalDataCount)
	
	// 	  }
	// 	}
	// 	)
	// 	  .catch((err) => console.log(err, "err"))
	//   }
	useEffect(() => {
		const PostData = {
			"userId": window.sessionStorage.getItem("userId"),
			"hospitalId": localStorage.getItem("hospitalId"),
			"offset" : "0",
		  	"max": 100,
		
		}
		callingAPI('promotionalSms/practiceCommun', PostData,
		).then((response) => {
		  if (response.data.success === "1") {
			setPromotionalSms(response.data.result.practiceSmsCommunicationData)
			setTotalDataCount(response.data.result.totalDataCount)
	
		  }
		}
		)
		  .catch((err) => console.log(err, "err"))
	  }, []);
	return (
		<div className="dashboardCover">
			<div className="dashboardTitle">
				SMS Communication
				<Link to="/communications/prepareSms" className="practicementBtn">
					Send SMS
				</Link>
				
			</div>
			
			<div className="dashboardTitle">
				count: {totalDataCount}</div>
			
			<div className="dashboardContent">
	
				{/* <div className="paginationSection">
					<Pagination
			          hideNavigation
					  activePage={activePage}
					  itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
					  totalItemsCount={totalDataCount}
					  pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
					  onChange={handlePageChange}
					/>
				</div> */}
				
				<div className="reportWrap">
					<div className="dashboardtable">
						<table>
							<thead>
								<tr>
									<th>Message</th>
									<th>Delivery Date & Time</th>
									<th>Track Logs</th>
								</tr>
							</thead>
							<tbody>
								{promotionalSms.map((item,i)=>(
								<tr>
									<td>
										<div className="dashboardTableText elipsis">
											{item.practiceSmsCommunicationMessage}
										</div>
									</td>
									<td>{item.practiceSmsCommunicationCreatedOn}</td>
									<td>
										<span className="chartImg">
											<img src={img} />
										</span>
									</td>
								</tr>
				))}
							</tbody>
						</table>
					</div>
				</div>
				{/* <div className="paginationSection">
					<Pagination
		              hideNavigation
					  activePage={activePage}
					  itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
					  totalItemsCount={totalDataCount}
					  pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
					  onChange={handlePageChange}
					/>
				</div> */}
			</div>
		</div>
	);
}

export default PromotionalSMS;
