import React from 'react';
import Logo from '../images/media/logo.png';
function testResultPrintAll() {
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: '500px',
					height: '595px',
					marginLeft: '0px',
					marginRight: '0px',
					marginTop: '0px',
					marginBottom: '0px',
				}}
			>
				<table style={{ width: '100%', marginTop: '0px' }} border="0">
					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr height="80px">
												<td colspan="3" valign="middle" align="center">
													<div style={{ width: '100%', float: 'none' }}>
														<p
															style={{
																fontWeight: '400',
																fontSize: '16px',
																clear: 'both',
																width: '100%',
																margin: '0px 0 3px 0px',
															}}
														>
															<span
																style={{
																	marginTop: '-5px',
																	display: 'inline - block',
																	width: '100%',
																}}
															>
																<img
																	src={Logo}
																	style={{
																		display: 'block',
																		width: 'auto',
																		maxHeight: '65px',
																		margin: 'auto',
																	}}
																	align="middle"
																/>
															</span>
														</p>
													</div>
													<div class="practicePrintName" style={{ width: '100%' }}>
														<p
															style={{
																fontWeight: '400',
																fontSize: '16px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
															}}
														>
															Mishras Clinic
														</p>
														<p
															style={{
																fontWeight: '400',
																fontSize: '12px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
															}}
														>
															1-24-129/1, Laxmi nagar, Loth kunta, Alwal
														</p>
														<p
															style={{
																fontWeight: '400',
																fontSize: '12px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
															}}
														>
															Endodontic
														</p>
													</div>
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>
											<tr>
												<td
													width="50%"
													colspan="2"
													align="left"
													style={{ fontSize: '12px', wordBreak: 'break-all' }}
												>
													Dr Nagarjun Mishra, MBBS, DM
												</td>
												<td
													width="50%"
													align="right"
													style={{ fontSize: '12px', wordBreak: 'break-all' }}
												/>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>

											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Name : ABHAY SAJU.S
												</td>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Date : 28, Aug 2019
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													MR No : 22
												</td>

												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Phone No : 1212121212
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Sex : Male
												</td>
												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Email Id : john@gmail.com
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
										<tbody>
											<tr style={{ borderBottom: '2px solid black' }}>
												<td
													class="col-md-3"
													style={{
														borderBottom: '2px solid black',
														textAlign: 'left',
														fontWeight: '500',
														fontSize: '12px',
														paddingBottom: '5px',
														width: '35%',
													}}
												>
													Test Name (Type)
												</td>
												<td
													class="col-md-3"
													style={{
														borderBottom: '2px solid black',
														textAlign: 'left',
														fontWeight: '500',
														fontSize: '12px',
														paddingBottom: '5px',
														width: ' 20%',
													}}
												>
													Patient Reading
												</td>
												<td
													class="col-md-3"
													style={{
														borderBottom: '2px solid black',
														textAlign: 'left',
														fontWeight: '500',
														fontSize: '12px',
														paddingBottom: '5px',
														width: ' 15%',
													}}
												>
													Normal Value
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						<tr>
							<td>
								<div class="printOutSettingContent">
									<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
										<tbody>
											<tr>
												<td
													class="col-md-3"
													style={{
														width: '35%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														- Rapid immunochromoatography for HIVI and HIV2
													</p>
												</td>
												<td
													class="col-md-3"
													style={{
														width: ' 20%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														1
													</p>
												</td>
												<td
													class="col-md-3"
													style={{
														width: ' 15%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														Reactive,Non Reactive
													</p>
												</td>
											</tr>

											<tr>
												<td
													class="col-md-3"
													style={{
														width: '35%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														- Rapid immunochromoatography for HIVI and HIV2
													</p>
												</td>
												<td
													class="col-md-3"
													style={{
														width: ' 20%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														1
													</p>
												</td>
												<td
													class="col-md-3"
													style={{
														width: ' 15%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														Reactive,Non Reactive
													</p>
												</td>
											</tr>
											<tr>
												<td
													class="col-md-3"
													style={{
														width: '35%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														- Rapid immunochromoatography for HIVI and HIV2
													</p>
												</td>
												<td
													class="col-md-3"
													style={{
														width: ' 20%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														1
													</p>
												</td>
												<td
													class="col-md-3"
													style={{
														width: ' 15%',
														margin: '0',
														color: '#616161',
														lineHeight: '15px',
														wordBreak: 'break-word',
														fontSize: '11px',
													}}
												>
													<p
														class="sml_matter"
														style={{ margin: ' 3px 0', fontSize: '11px!important' }}
													>
														Reactive,Non Reactive
													</p>
												</td>
											</tr>
											<tr>
												<td
													colspan="6"
													style={{
														textAlign: 'left',
														fontSize: '11px',
														lineHeight: '15px',
														padding: ' 0px 2px 0px 0',
														display: 'inline-block',
														boxSizing: 'border-box',
														color: '#393939',
													}}
												>
													<p
														style={{
															wordBreak: 'break-all',
															margin: '3px 0',
															fontSize: '11px!important',
															wordBreak: ' break-word',
														}}
													>
														{' '}
														<span style={{ fontWeight: '600' }}>Instructions:</span>{' '}
													</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent" style={{ paddingTop: '80px' }}>
									<table style={{ width: '100%' }} cellspacing="0">
										<tbody>
											<tr>
												<td>
													<div
														style={{
															padding: '0 0',
															textAlign: 'center',
															display: 'block',
															float: 'left',
															width: '33.33%',
														}}
													>
														<span />
													</div>
													<div
														style={{
															padding: '0 0',
															textAlign: 'center',
															display: 'block',
															float: 'left',
															width: '33.33%',
														}}
													>
														<span />
													</div>
													<div
														style={{
															padding: '0 0',
															textAlign: 'center',
															display: 'block',
															float: 'left',
															width: '33.33%',
														}}
													>
														<span />
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<div
														style={{
															padding: '0 0',
															textAlign: 'center',
															display: 'block',
															float: 'left',
															width: '33.33%',
														}}
													>
														<span>Lab Technician</span>
													</div>
													<div
														style={{
															padding: '0 0',
															textAlign: 'center',
															display: 'block',
															float: 'left',
															width: '33.33%',
														}}
													>
														<span>Lab Technologist</span>
													</div>
													<div
														style={{
															padding: '0 0',
															textAlign: 'center',
															display: 'block',
															float: 'left',
															width: '33.33%',
														}}
													>
														<span>Verified By</span>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>

					<tfoot>
						<tr>
							<td>
								<div class="printOutFooterSec">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr>
												<td
													width="25%"
													colspan="2"
													align="left"
													style={{ wordBreak: 'break-all' }}
												>
													<div align="justify" style={{ fontSize: '10px' }}>
														{' '}
													</div>
												</td>
												<td
													width="50%"
													align="right"
													style={{ fontSize: '10px', wordBreak: 'break-all' }}
												>
													{' '}
													nagarjunmishra@yahoo.co.in
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<div align="justify" class="practiceFootBtm">
														Thank you
													</div>
												</td>
											</tr>
											<tr>
												<td colspan="3" style={{ paddingTop: '10px' }} />
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
									}}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default testResultPrintAll;
