import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import img1 from '../images/dental/32-teeth/1.png';
import img2 from '../images/dental/32-teeth/2.png';
import img3 from '../images/dental/32-teeth/3.png';
import img4 from '../images/dental/32-teeth/4.png';
import img5 from '../images/dental/32-teeth/5.png';
import img6 from '../images/dental/32-teeth/6.png';
import img7 from '../images/dental/32-teeth/7.png';
import img8 from '../images/dental/32-teeth/8.png';
import img9 from '../images/dental/32-teeth/9.png';
import img10 from '../images/dental/32-teeth/10.png';
import img11 from '../images/dental/32-teeth/11.png';
import img12 from '../images/dental/32-teeth/12.png';
import img13 from '../images/dental/32-teeth/13.png';
import img14 from '../images/dental/32-teeth/14.png';
import img15 from '../images/dental/32-teeth/15.png';
import img16 from '../images/dental/32-teeth/16.png';
import img1btm from '../images/dental/32-teeth/1-bottom.png';
import img2btm from '../images/dental/32-teeth/2-bottom.png';
import img3btm from '../images/dental/32-teeth/3-bottom.png';
import img4btm from '../images/dental/32-teeth/4-bottom.png';
import img5btm from '../images/dental/32-teeth/5-bottom.png';
import img6btm from '../images/dental/32-teeth/6-bottom.png';
import img7btm from '../images/dental/32-teeth/7-bottom.png';
import img8btm from '../images/dental/32-teeth/8-bottom.png';
import img9btm from '../images/dental/32-teeth/9-bottom.png';
import img10btm from '../images/dental/32-teeth/10-bottom.png';
import img11btm from '../images/dental/32-teeth/11-bottom.png';
import img12btm from '../images/dental/32-teeth/12-bottom.png';
import img13btm from '../images/dental/32-teeth/13-bottom.png';
import img14btm from '../images/dental/32-teeth/14-bottom.png';
import img15btm from '../images/dental/32-teeth/15-bottom.png';
import img16btm from '../images/dental/32-teeth/16-bottom.png';

import img_1 from '../images/dental/20-teeth/1.png';
import img_2 from '../images/dental/20-teeth/2.png';
import img_3 from '../images/dental/20-teeth/3.png';
import img_4 from '../images/dental/20-teeth/4.png';
import img_5 from '../images/dental/20-teeth/5.png';
import img_6 from '../images/dental/20-teeth/6.png';
import img_7 from '../images/dental/20-teeth/7.png';
import img_8 from '../images/dental/20-teeth/8.png';
import img_9 from '../images/dental/20-teeth/9.png';
import img_10 from '../images/dental/20-teeth/10.png';
import img_1btm from '../images/dental/20-teeth/1-bottom.png';
import img_2btm from '../images/dental/20-teeth/2-bottom.png';
import img_3btm from '../images/dental/20-teeth/3-bottom.png';
import img_4btm from '../images/dental/20-teeth/4-bottom.png';
import img_5btm from '../images/dental/20-teeth/5-bottom.png';
import img_6btm from '../images/dental/20-teeth/6-bottom.png';
import img_7btm from '../images/dental/20-teeth/7-bottom.png';
import img_8btm from '../images/dental/20-teeth/8-bottom.png';
import img_9btm from '../images/dental/20-teeth/9-bottom.png';
import img_10btm from '../images/dental/20-teeth/10-bottom.png';
import Datepicker from 'react-datepicker';
import Tooth from '../Patients/tooth';
import { callingAPI } from '../config';
import moment from 'moment';
import Loader from '../Common/loader';
import { WindowScrollController } from '@fullcalendar/react';
import $ from 'jquery';
import Speech from '../AudioToText/speechRecognize'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UseMic from '../AudioToText/useMic'
const { detect } = require('detect-browser');
const browserDetect = detect();
const browser = browserDetect.name


function CasesheetDentalAdd(props) {

	$(document).ready(function () {
		$('#caseSheetAdd ').addClass("testResultAdd");

	})
	const userAgent = navigator.userAgent;
	const [isChief, setChief] = useState(false);
	const [isAssociated, setAssociated] = useState(false);
	const [isKnownCase, setKnownCase] = useState(false);
	const [isObservation, setObservation] = useState(false);
	const [istxtRadiograph, setIstxtRadiograph] = useState(false);
	const [isRemark, setRemark] = useState(false);
	const [isDiagnosis, setIsDiagnosis] = useState(false);
	const [isTreatment, setTreatment] = useState(false);
	const [isSpecialist, setSpecialist] = useState(false);
	const [doctorList, setDoctorList] = useState([]);
	const [getDrName, setDrName] = useState(window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ? window.sessionStorage.getItem('patientDoctorId') : '');
	const [styles, setStyle] = useState({ display: 'none' });
	const [fullArray, setFullArray] = useState([]);
	const [associatedArray, setAssociatedArray] = useState([]);
	const [knownCaseArray, setKnownCaseArray] = useState([]);
	const [chiefDescription, setChiefDescription] = useState('');
	const [knownDescription, setKnownDescription] = useState('');
	const [associatedDescription, setAssociatedDescription] = useState('');
	const [getValue, setGetValue] = useState([]);
	const [clearValue, setClear] = useState('');
	const [associateClearValue, setAssociateClear] = useState('');
	const [knownClearValue, setKnownClear] = useState('');
	const [sinceDate1, setSinceDate1] = useState(new Date());
	const [sinceDate2, setSinceDate2] = useState(new Date());
	const [sinceDate3, setSinceDate3] = useState(new Date());
	const [sinceDate4, setSinceDate4] = useState(new Date());
	const [appointmentDate, setAppointmentDate] = useState('');
	const [toggle, setToggle] = useState(false);
	const [getId, setId] = useState([]);
	const [teethArrayData, setToothArrayData] = useState([]);
	const [txtOcclu, setTxtOcclu] = useState('');
	const [txtLymph, setTxtLymph] = useState('');
	const [txtTMJ, setTxtTMJ] = useState('');
	const [txtBuccal, setTxtBuccal] = useState('');
	const [txtVesti, setTxtVesti] = useState('');
	const [txtTongue, setTxtTongue] = useState('');
	const [txtFloor, setTxtFloor] = useState('');
	const [txtPalate, setTxtPalate] = useState('');
	const [txtPharynx, setTxtPharynx] = useState('');
	const [txtAlBone, setTxtAlBone] = useState('');
	const [txtPerio, setTxtPerio] = useState('');
	const [txtGingiva, setTxtGingiva] = useState('');
	const [txtRadiograph, setTxtRadiograph] = useState('');
	const [txtComments, setTxtComments] = useState('');
	const [txtObservations, setTxtObservations] = useState('');
	const [txtSpecialist, setTxtSpecialist] = useState('');
	const [txtDiagnosis, setTxtDiagnosis] = useState('');
	const [txtTreatmentDone, setTxtTreatmentDone] = useState('');
	const [selectedRadioButton1, setSelectedRadioButton1] = useState('');
	const [selectedRadioButton2, setSelectedRadioButton2] = useState('');
	const [selectedRadioButton3, setSelectedRadioButton3] = useState('');
	const [followUpClick, setFollowUpClick] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [futureDrVisitId, setFutureDrVisitId] = useState('')
	const [appointmentNextData, setAppointmentNextData] = useState('');
	const [freeArticleData1, setFreeArticleData1] = useState([]);
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState('');
	const [convertedFutureDate, setConvertedFutureDate] = useState('');
	const [dentalCaseSheetEditData, setDentalCaseSheetEditData] = useState([]);
	const [disable, setDisable] = useState(false);
	const [dataFlag, setDataFlag] = useState(false);
	const [cmbStatus, setCmbStatus] = useState('3');
	const [editArray, setEditArray] = useState([]);
	const [futureAppointmentDate, setFutureAppointmentDate] = useState([]);
	const [pmId, setpmId] = useState('');
	const [chiefCaseSenstiveArray, setChiefCaseSenstiveArray] = useState([])
	const [associatedCaseSenstiveArray, setAssociatedCaseSenstiveArray] = useState([])
	const [knownCaseSenstiveArray, setKnownCaseSenstiveArray] = useState([])
	const [finalsStatus, setFinalsSatus] = useState("");
	const [chiefReason, setChiefReason] = useState('');
	const [associatedReason, setAssociatedReason] = useState('');
	const [knownReason, setKnownReason] = useState('');
	const [recallClick, setRecallClick] = useState(false);
	const [recallToggle, setRecallToggle] = useState(false);
	const [comments, setComments] = useState('');
	const [recallDate, setRecallDate] = useState('');
	const [language, setLanguage] = useState('English');

	const orderAt = (id, mode, dropdownId) => {
		document.getElementById(mode).value = document.getElementById(mode).value.split('').length == 1 ? document.getElementById(mode).value.replace(/\s/, '') : document.getElementById(mode).value;
		setTimeout(function () {
			const apiJson = {
				complaintTypeId: id,
				txtInput: document.getElementById('clearinputname' + id).value,
			};
			callingAPI('clinicalBackground/dentalCaseSheetTags', apiJson).then((data) => {
				if (document.getElementById(mode).value.split('').length > 1) {
					if (data.data.success === '1' && data.data.result.tagName.length > -1) {
						setFreeArticleData1(data.data.result.tagName);
						document.getElementById(dropdownId).style.display = 'block';
						setStyle(document.getElementById(mode).value)
						document.addEventListener('mouseup', function (e) {
							var container = document.getElementById("locationSearch" + id);
							if (container !== null) {
								if (!container.contains(e.target)) {
									container.style.display = 'none';
								}
							}
						});
					} else {
						document.getElementById(dropdownId).style.display = 'none';
						setFreeArticleData1([]);
					}
				} else {
					setFreeArticleData1([]);
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		}, 200);

	};
	const doctorClickFunction1 = (val) => {
		setDrName(val)
	};
	const doctorClickFunction = (val) => {
		const postData = {
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('commonUserFunctions/doctorList', postData).then((data) => {
			if (data.data.success === '1') {
				setDoctorList(data.data.result.doctorList);
			} else {
				alert(data.data.errorMessage);
			}
		})
			.catch();
	};
	const associatedMouseLeave = (i) => {
		document.getElementById('associate' + i).style.display = 'none';
	};
	const associatedMouseEnter = (i) => {
		document.getElementById('associate' + i).style.display = 'block';
		document.getElementById('associate' + i).style.display = 'inline';
	};
	const setChiefTagData = (itemName, mode, dropdownId) => {
		if (chiefCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setFullArray([...fullArray, itemName])
			setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, itemName.toLowerCase()])
			setClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already Added")
			return false;
		}
	};
	const setAssociatedTagData = (itemName, mode, dropdownId) => {
		if (associatedCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setAssociatedArray([...associatedArray, itemName]);
			setAssociatedCaseSenstiveArray([...associatedCaseSenstiveArray, itemName.toLowerCase()])
			setAssociateClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already Added")
			return false;
		}
	};
	const setKnownTagData = (itemName, mode, dropdownId) => {
		if (knownCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setKnownCaseArray([...knownCaseArray, itemName]);
			setKnownCaseSenstiveArray([...knownCaseSenstiveArray, itemName.toLowerCase()])
			setKnownClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already Added")
			return false;
		}
	};
	const editRecall = () => {
		const postData = {
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId')
		}
		callingAPI('patientProfile/getPatientRecallDetails', postData)
			.then((data) => {

				if (data.data.success === '1') {
					setRecallDate(data.data.result[0].recallDateAndTime)
					setComments(data.data.result[0].comments)
					setRecallToggle(true);
				}
			})
	}
	useEffect(() => {

		if (window.sessionStorage.getItem('editId') === '') {
			document.getElementById('saveid').style.display = 'block';
			document.getElementById('updateid').style.display = 'none';
			document.getElementById('lowsaveid').style.display = 'block';
			document.getElementById('lowupdateid').style.display = 'none';
			document.getElementById('updateid').disable = true;
			setDentalCaseSheetEditData([]);
			doctorClickFunction();
			setDataFlag(true);
		} else {
			document.getElementById('saveid').style.display = 'none';
			document.getElementById('lowsaveid').style.display = 'none';
			document.getElementById('updateid').style.display = 'block';
			document.getElementById('lowupdateid').style.display = 'block';
			doctorClickFunction();
			const postData = {
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				memberId: window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				doctorVisitId: window.sessionStorage.getItem('editId'),
				date: ""
			};

			callingAPI('clinicalBackground/dentalCaseSheet', postData).then((data) => {

				if (data.data.success === '1') {
					editRecall();
					setDataFlag(true);
					setFinalsSatus(window.sessionStorage.getItem('finalstatus'))
					setpmId(data.data.result.PmIdData);
					var date1 = data.data.result.dentalCaseSheetEditData.ChiefpresentingcomplaintsinceDate
						? data.data.result.dentalCaseSheetEditData.ChiefpresentingcomplaintsinceDate
							.toString()
							.split('/')
						: [];
					var date2 = data.data.result.dentalCaseSheetEditData.AssociatedcomplaintssinceDate
						? data.data.result.dentalCaseSheetEditData.AssociatedcomplaintssinceDate
							.toString()
							.split('/')
						: [];
					var date3 = data.data.result.dentalCaseSheetEditData.KnownCaseofsinceDate
						? data.data.result.dentalCaseSheetEditData.KnownCaseofsinceDate.toString().split('/')
						: [];
					var date4 = data.data.result.dentalCaseSheetEditData.KnownCaseofsinceDate
						? data.data.result.dentalCaseSheetEditData.KnownCaseofsinceDate.toString().split('/')
						: [];
					let chiefTags = data.data.result.dentalCaseSheetEditData.Chiefpresentingcomplainttag
						? data.data.result.dentalCaseSheetEditData.Chiefpresentingcomplainttag.toString().split(',')
						: [];
					setFullArray(chiefTags);
					let associateTags = data.data.result.dentalCaseSheetEditData.Associatedcomplaintstag
						? data.data.result.dentalCaseSheetEditData.Associatedcomplaintstag.toString().split(',')
						: [];
					setAssociatedArray(associateTags);
					let knownTags = data.data.result.dentalCaseSheetEditData.KnownCaseoftag
						? data.data.result.dentalCaseSheetEditData.KnownCaseoftag.toString().split(',')
						: [];
					setKnownCaseArray(knownTags);
					date1.length > 0
						? setSinceDate1(new Date(date1[2], date1[1] - 1, date1[0]))
						: setSinceDate1(new Date());
					date2.length > 0
						? setSinceDate2(new Date(date2[2], date2[1] - 1, date2[0]))
						: setSinceDate2(new Date());
					date3.length > 0
						? setSinceDate3(new Date(date3[2], date3[1] - 1, date3[0]))
						: setSinceDate3(new Date());
					date4.length > 0
						? setSinceDate4(new Date(date3[2], date3[1] - 1, date3[0]))
						: setSinceDate4(new Date());
					setEditArray(data.data.result.dentalCaseSheetEditData);
					if (data.data.result.dentalCaseSheetEditData.Chiefpresentingcomplaintdescription !== undefined) {
						setChiefDescription(
							data.data.result.dentalCaseSheetEditData.Chiefpresentingcomplaintdescription
						);
					}
					if (data.data.result.dentalCaseSheetEditData.Associatedcomplaintsdescription !== undefined) {
						setAssociatedDescription(
							data.data.result.dentalCaseSheetEditData.Associatedcomplaintsdescription
						);
					}
					if (data.data.result.dentalCaseSheetEditData.KnownCaseofdescription !== undefined) {
						setKnownDescription(data.data.result.dentalCaseSheetEditData.KnownCaseofdescription);
					}
					setTxtOcclu(data.data.result.dentalCaseSheetEditData.OcclusionEvaluationdescription);
					setTxtLymph(data.data.result.dentalCaseSheetEditData.Lymphnodesdescription);
					setTxtTMJ(data.data.result.dentalCaseSheetEditData.TMJdescription);
					setTxtBuccal(data.data.result.dentalCaseSheetEditData.Buccalmucosadescription);
					setTxtVesti(data.data.result.dentalCaseSheetEditData.Vestibulesdescription);
					setTxtFloor(data.data.result.dentalCaseSheetEditData.FloorofMouthdescription);
					setTxtTongue(data.data.result.dentalCaseSheetEditData.Tonguedescription);
					setTxtPalate(data.data.result.dentalCaseSheetEditData.Palatedescription);
					setTxtPharynx(data.data.result.dentalCaseSheetEditData.Pharynxdescription);
					setTxtAlBone(data.data.result.dentalCaseSheetEditData.Alveolarbonedescription);
					setTxtPerio(data.data.result.dentalCaseSheetEditData.Periopocketsdescription);
					setTxtGingiva(data.data.result.dentalCaseSheetEditData.Gingivadescription);
					if (data.data.result.dentalCaseSheetEditData.Radiographsexistingadviseddescription !== undefined) {
						setTxtRadiograph(data.data.result.dentalCaseSheetEditData.Radiographsexistingadviseddescription);
					}
					if (data.data.result.dentalCaseSheetEditData.Commentsdescription !== undefined) {
						setTxtComments(data.data.result.dentalCaseSheetEditData.Commentsdescription);
					}
					if (data.data.result.dentalCaseSheetEditData.Observationsdescription !== undefined) {
						setTxtObservations(data.data.result.dentalCaseSheetEditData.Observationsdescription);
					}
					if (data.data.result.dentalCaseSheetEditData.SpecialistOpinionifanydescription !== undefined) {
						setTxtSpecialist(data.data.result.dentalCaseSheetEditData.SpecialistOpinionifanydescription);
					}
					if (data.data.result.dentalCaseSheetEditData.DiagnosisTreatmentPlanExplainedOptionsdescription !== undefined) {
						setTxtDiagnosis(data.data.result.dentalCaseSheetEditData.DiagnosisTreatmentPlanExplainedOptionsdescription);
					}
					if (data.data.result.dentalCaseSheetEditData.TreatmentDonedescription !== undefined) {
						setTxtTreatmentDone(data.data.result.dentalCaseSheetEditData.TreatmentDonedescription);
					}
					setSelectedRadioButton1(data.data.result.dentalCaseSheetEditData.OralHygienedescription);
					setSelectedRadioButton2(data.data.result.dentalCaseSheetEditData.Calculusdescription);
					setSelectedRadioButton3(data.data.result.dentalCaseSheetEditData.GingivalBleedingdescription);
					setCmbStatus(data.data.result.AppointmentStatus);
					setDentalCaseSheetEditData(data.data.result.dentalCaseSheetEditData);
					var currentState = [];
					for (let index = 0; index < data.data.result.pmKeyData.length; index++) {
						document.getElementById(
							'appendeText' + data.data.result.pmKeyData[index].toothNo
						).innerHTML =
							data.data.result.pmKeyData[index].toothKeys;
						document.getElementById(
							'appendeDiv' + data.data.result.pmKeyData[index].toothNo
						).style.display =
							'block';
						if (currentState)
							currentState.push({
								teethId: data.data.result.pmKeyData[index].toothNo,
								teethkeys: data.data.result.pmKeyData[index].toothKeys.toString(),
							});
					}
					setToothArrayData(currentState);
					setFutureAppointmentDate(data.data.result.futureAppoinmentDateData);
					// let dateSetter = data.data.result.clinicalNotesDateData[0].aptDate.split(' ')
					// var eu_date = dateSetter[0];
					// var parts = eu_date.split('/');
					// var convertedDate = parts[2]+'-'+parts[1]+'-'+parts[0]+' '+dateSetter[1]+' '+dateSetter[2];
					if (data.data.result.futureAppoinmentDateData.length > 0) {
						var date = data.data.result.futureAppoinmentDateData[0].appointDate;
						var d = new Date(date);
						var convertedDate = moment(d).format('YYYY-MM-DD hh:mm A');
						var nextappointmentdate = moment(convertedDate).format('DD-MM-YYYY hh:mm A')
						setSelectedAppointmentDate(convertedDate)
						document.getElementById('weee').style.visibility = 'hidden'
						setFutureDrVisitId(data.data.result.futureAppoinmentDateData[0].doctorVisitId)
					}
					else {
						setSelectedAppointmentDate('')
					}
					setConvertedFutureDate(nextappointmentdate)

				}
			})
				.catch();
		}
	}, []);
	const teethDelete = (teethNo) => {
		let teethArray = teethArrayData;
		var index = teethArray.findIndex((x) => x.teethId == teethNo);
		teethArray.splice(index, 1);
		setToothArrayData(teethArray);
		document.getElementById('appendeText' + teethNo).innerHTML = '';
		document.getElementById('theethKeyClose' + teethNo).style.display = 'none';
	};
	const deleteValue = (e, i) => {
		let newArray = [...fullArray];
		newArray.splice(i, 1);
		setFullArray(newArray);
		setChiefCaseSenstiveArray(newArray)


	};
	const associatedDelete = (e, i) => {
		let associateArray = [...associatedArray];
		associateArray.splice(i, 1);
		setAssociatedArray(associateArray);
		setAssociatedCaseSenstiveArray(associateArray)
	};
	const knownCaseDelete = (e, i) => {
		let knownArray = [...knownCaseArray];
		knownArray.splice(i, 1);
		setKnownCaseArray(knownArray);
		setKnownCaseSenstiveArray(knownArray)
	};
	const passingToothDataArr = (toothVal, teethNo) => {
		var currentState = teethArrayData;
		var index = 0;
		if (currentState.length > 0) {
			index = currentState.findIndex((x) => x.teethId == teethNo);
			if (index < 0) {
				currentState.push({
					teethId: teethNo,
					teethkeys: toothVal.toString(),
				});
				document.getElementById('theethKeyClose' + teethNo).style.display = 'block';
			} else {
				currentState[index].teethkeys = toothVal.toString();
			}
		} else {
			currentState.push({
				teethId: teethNo,
				teethkeys: toothVal.toString(),
			});
			document.getElementById('theethKeyClose' + teethNo).style.display = 'block';
		}
		setToothArrayData(currentState);
	};
	const setValue = (e) => {
		const filteredData1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData1.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setClear('');
				setFullArray([...fullArray, e.target.value]);
				setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const setAssociateValue = (e) => {
		const filteredData = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setAssociateClear('');
				setAssociatedArray([...associatedArray, e.target.value]);
				setAssociatedCaseSenstiveArray([...associatedCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const setKnownValue = (e) => {
		const filteredData2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData2.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setKnownClear('');
				setKnownCaseArray([...knownCaseArray, e.target.value]);
				setKnownCaseSenstiveArray([...knownCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
		// if(knownCaseSenstiveArray.indexOf(e.target.value.toLowerCase()) == -1){
		// if (e.key == 'Enter') {
		// 	setGetValue(e.target.value);
		// 	setKnownClear('');
		// 	setKnownCaseArray([...knownCaseArray, e.target.value]);
		// 	setKnownCaseSenstiveArray([...knownCaseSenstiveArray, e.target.value.toLowerCase()])
		// }}
		// else{
		// 	alert("Already added")
		// 	return false;
		// }
	};
	const deleteInputValue = (event) => {
		setClear(event.target.value);
	};
	const deleteAssociateValue = (event) => {
		setAssociateClear(event.target.value);
	};
	const deleteKnownValue = (event) => {
		setKnownClear(event.target.value);
	};
	const setToothSection = (id, divId) => {
		var toothDataPopup = document.getElementsByClassName('toothDataPopup');
		for (let index = 0; index < toothDataPopup.length; index++) {
			const element = toothDataPopup[index];
			element.style.display = 'none';
		}
		var elementDisplay = document.getElementById(divId + id);
		elementDisplay.style.display = elementDisplay.style.display === 'block' ? 'none' : 'block';
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + id);
			if (!container.contains(e.target)) {
				container.style.display = 'none';
			}
		});
	};

	const selectedDropdownValue = (val) => {
		setId(val);
	};

	const recallClickSave = () => {
		let postData = {
			patientReacallId: "",
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			userId: window.sessionStorage.getItem('userId'),
			recallDateAndTime: recallDate ? Date.parse(moment(recallDate).format('YYYY-MM-DD hh:mm A')).toString() : "",
			status: "Pending",
			comments: comments
		}
		callingAPI('patientProfile/savePatientRecallDetails', postData).then((data) => {

		})
	}

	const saveClickFunction = (e, type) => {
		if (clearValue) {
			const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered1.includes(clearValue.toLowerCase()) == false) {
				fullArray.push(clearValue)
			}
		}
		if (associateClearValue) {
			const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered.includes(associateClearValue.toLowerCase()) == false) {
				associatedArray.push(associateClearValue)
			}
		}
		if (knownClearValue) {
			const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
				knownCaseArray.push(knownClearValue)
			}
		}
		// if (fullArray.length == 0) {
		// 	alert("Please enter chief presenting complaint")
		// 	document.getElementById("clearinputname1").focus()
		// 	return false;
		// }
		// if (!recallDate) {
		// 	alert("Please Enter Recall date")
		// 	return false
		// }
		setDataFlag(false);
		if (disable) {
			return;
		}
		setDisable(true);

		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
			prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,

			tagCheifComp: fullArray ? fullArray.toString() : '',
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			hidFinalize: type == 'Save' ? 'false' : 'true',
			txtCreatedDate: moment(new Date()).format('YYYY-MM-DD hh:mm'),
			needFollowUp: followUpClick,
			txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
			scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
			scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
			txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
			scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
			scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
			appointmentNextData: "",
			txtFollowNote: appointmentNextData,
			txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
			txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
			txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
			//txtCasesheetDate: sinceDate4 ? moment(sinceDate4).format('YYYY-MM-DD') : '',
			cheifDesc: chiefDescription,
			txtAssociate: associatedDescription,
			tagKnowCase: knownCaseArray.toString(),
			txtKnown: knownDescription,
			txtOcclu: txtOcclu,
			txtLymph: txtLymph,
			txtTMJ: txtTMJ,
			txtBuccal: txtBuccal ? txtBuccal : '',
			txtVesti: txtVesti,
			txtTongue: txtTongue,
			txtFloor: txtFloor,
			txtPalate: txtPalate,
			txtPharynx: txtPharynx,
			txtAlBone: txtAlBone,
			txtPerio: txtPerio,
			txtGingiva: txtGingiva,
			txtRadio1: selectedRadioButton1,
			txtRadio2: selectedRadioButton2,
			txtRadio3: selectedRadioButton3,
			txtRadiograph: txtRadiograph,
			txtComments: txtComments,
			txtSpecialist: txtSpecialist,
			txtDiagnosis: txtDiagnosis.replace(/(?:\r\n|\r|\n)/g, "<br>"),
			txtTreatmentDone: txtTreatmentDone,
			txtObservations: txtObservations,
			tagAssocComp: associatedArray.toString(),
			teethDetails: teethArrayData,
			cmbStatus: document.getElementById('cmbStatus').value,
		};

		callingAPI('commonUserFunctions/saveCaseSheetDental', postData).then((data) => {
			if (data.data.success === '1') {
				if (comments !== "" || recallDate !== "") {
					recallClickSave();
				}
				setDataFlag(true);
				window.location.href = '/Patients/casesheetDental';
				alert('Case Sheet Saved Successfully');
			} else if (data.data.success === '0') {
				setDataFlag(true);
				alert(data.data.errorMessage);
				// window.location.href = '/Patients/casesheetDentalAdd';
			}
			setDisable(false);
		})
			.catch((err) => {
				setDisable(false);
			});
	};
	const updateClickFunction = (e, type) => {
		if (clearValue) {
			const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered1.includes(clearValue.toLowerCase()) == false) {
				fullArray.push(clearValue)
			}
		}
		if (associateClearValue) {
			const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered.includes(associateClearValue.toLowerCase()) == false) {
				associatedArray.push(associateClearValue)
			}
		}
		if (knownClearValue) {
			const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
				knownCaseArray.push(knownClearValue)
			}
		}
		// if (fullArray.length == 0) {
		// 	alert("Please enter chief presenting complaint")
		// 	document.getElementById("clearinputname1").focus()
		// 	return false;
		// }
		// if (!recallDate) {
		// 	alert("Please enter recall date")
		// 	return false
		// }
		setDataFlag(false);
		if (disable) {
			return;
		}
		setDisable(true);
		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			tagCheifComp: fullArray.toString(),
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			hidDoctorVisitId: window.sessionStorage.getItem('editId'),
			hidFinalize: window.sessionStorage.getItem('finalstatus') == 'false' && type == 'Save' ? 'false' : 'true',
			hidUpdatePmcId: pmId ? pmId[0].pmcId : '',
			txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
			txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
			txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
			//txtCasesheetDate: sinceDate4 ? moment(sinceDate4).format('YYYY-MM-DD') : '',
			cheifDesc: chiefDescription ? chiefDescription : '',
			txtAssociate: associatedDescription ? associatedDescription : '',
			tagKnowCase: knownCaseArray.toString(),
			isChanged: isChanged,
			needFollowUp: followUpClick,
			txtAppoimenttDate: appointmentDate
				? moment(appointmentDate).format('YYYY-MM-DD hh:mm A')
				: "",

			scheduledtimeSlotStartTime: appointmentDate
				? Date.parse(appointmentDate).toString()
				: "",
			// (selectedAppointmentDate !== "" ? (Date.parse(selectedAppointmentDate).toString()) : ""),

			scheduledtimeSlotEndTime: appointmentDate
				? (Date.parse(appointmentDate) + 15 * 60 * 1000).toString()
				: "",
			//  (selectedAppointmentDate !== "" ? (Date.parse(moment(selectedAppointmentDate)) + 15 * 60 * 1000).toString() : ""),
			hidAptDocVisit: futureDrVisitId,
			reasonChangeChiefTag: chiefReason,
			reasonChangeAssociatedTag: associatedReason,
			reasonChangeKnownCaseTag: knownReason,
			txtFollowNote: appointmentNextData,
			txtKnown: knownDescription ? knownDescription : '',
			txtOcclu: txtOcclu ? txtOcclu : '',
			txtLymph: txtLymph ? txtLymph : '',
			txtTMJ: txtTMJ ? txtTMJ : '',
			txtBuccal: txtBuccal ? txtBuccal : '',
			txtVesti: txtVesti ? txtVesti : '',
			txtTongue: txtTongue ? txtTongue : '',
			txtFloor: txtFloor ? txtFloor : '',
			txtPalate: txtPalate ? txtPalate : '',
			txtPharynx: txtPharynx ? txtPharynx : '',
			txtAlBone: txtAlBone ? txtAlBone : '',
			txtPerio: txtPerio ? txtPerio : '',
			txtGingiva: txtGingiva ? txtGingiva : '',
			txtRadio1: selectedRadioButton1 ? selectedRadioButton1 : '',
			txtRadio2: selectedRadioButton2 ? selectedRadioButton2 : '',
			txtRadio3: selectedRadioButton3 ? selectedRadioButton3 : '',
			txtRadiograph: txtRadiograph ? txtRadiograph : '',
			txtComments: txtComments ? txtComments : '',
			txtSpecialist: txtSpecialist ? txtSpecialist : '',
			txtObservations: txtObservations ? txtObservations : '',
			txtDiagnosis: txtDiagnosis ? txtDiagnosis : '',
			txtTreatmentDone: txtTreatmentDone ? txtTreatmentDone : '',
			tagAssocComp: associatedArray.toString(),
			teethDetails: teethArrayData,
			cmbStatus: document.getElementById('cmbStatus').value,
		};
		callingAPI('commonUserFunctions/updateCaseSheetDental', postData).then((data) => {
			if (data.data.success === '1') {
				if (comments !== "" || recallDate !== "") {
					recallClickSave();
				}
				setDataFlag(true);
				window.location.href = '/Patients/casesheetDental';
				alert('Case Sheet Updated Successfully');
				window.sessionStorage.removeItem("editId");
				window.sessionStorage.removeItem('fromSimplifiedForm')
			} else {
				setDataFlag(true);
				alert(data.data.errorMessage);
			}
			setDisable(false);
		})
			.catch((err) => {
				setDisable(false);
			});
	};

	const finalizeClick = (e) => {
		if (window.sessionStorage.getItem('editId') === '') {
			saveClickFunction(e, 'Finalize');
		} else {
			updateClickFunction(e, 'Finalize');
		}
	};
	const chiefLeave = (i) => {
		document.getElementById('chief' + i).style.display = 'none';
	};
	const chiefEnter = (i) => {
		document.getElementById('chief' + i).style.display = 'block';
		document.getElementById('chief' + i).style.display = 'inline';
	};
	const knownLeave = (i) => {
		document.getElementById('known' + i).style.display = 'none';
	};
	const knownEnter = (i) => {
		document.getElementById('known' + i).style.display = 'block';
		document.getElementById('known' + i).style.display = 'inline';
	};
	const cancel = () => {
		window.sessionStorage.removeItem('fromSimplifiedForm')
		window.location.href = '/patients/casesheetDental';
	}
	const speechData = (data, variableName) => {

		if (variableName === 'chiefDescription') {
			setChief(false);
			if (data !== "") {
				setChiefDescription(chiefDescription.concat(' ' + data))
			}
		}
		else if (variableName === 'associatedDescription') {
			setAssociated(false);
			if (data !== "") {
				setAssociatedDescription(associatedDescription.concat(' ' + data));
			}
		}
		else if (variableName === 'knownDescription') {
			setKnownCase(false);
			if (data !== "") {
				setKnownDescription(knownDescription.concat(' ' + data));
			}
		}
		else if (variableName === 'observations') {
			setObservation(false);
			if (data !== "") {
				setTxtObservations(txtObservations.concat(' ' + data));
			}
		}
		else if (variableName === 'istxtRadiograph') {
			setIstxtRadiograph(false);
			if (data !== "") {
				setTxtRadiograph(txtRadiograph.concat(' ' + data));
			}
		}
		else if (variableName === 'remarks') {
			setRemark(false);
			if (data !== "") {
				setTxtComments(txtComments.concat(' ' + data));
			}
		}
		else if (variableName === 'txtDiagnosis') {
			setIsDiagnosis(false);
			if (data !== "") {
				setTxtDiagnosis(txtDiagnosis.concat(' ' + data));
			}
		}
		else if (variableName === 'txtTreatmentDone') {
			setTreatment(false);
			if (data !== "") {
				setTxtTreatmentDone(txtTreatmentDone.concat(' ' + data));
			}
		}
		else if (variableName === 'txtSpecialist') {
			setSpecialist(false);
			if (data !== "") {
				setTxtSpecialist(txtSpecialist.concat(' ' + data));
			}
		}
	}
	return (
		<div className="coverWraper" >
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap caseshtMedAdd">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{dataFlag === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" >
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>
												{/* {window.sessionStorage.getItem('historynumb') == 1 ?
												<span class="treatmentPlanBtn pl_blue_btn"><a href="/patients/casesheetDentalHistory">View History</a></span>:""} */}
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}

												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b' }}
													onClick={(e) => {
														finalizeClick(e);
														// saveClickFunction(e, 'Finalize');
														// updateClickFunction(e, 'Finalize')
													}}
												>
													Finalize
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													id="saveid"
													disabled={disable}
													style={{ background: '#5bb75b', display: 'block' }}
													onClick={(e) => { saveClickFunction(e, 'Save'); }}>
													Save
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													id="updateid"
													disabled={disable}
													style={{ background: '#5bb75b', display: 'none' }}
													onClick={(e) => { updateClickFunction(e, 'Save'); }}>
													Update
												</span>
												<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
													<a onClick={(e) => cancel()}>Cancel</a>
												</span>


												{/* <div className="text-center">
													<span className="" style={{ fontSize: "12px" }}>Generated On</span>
													<div class="clinicalDate datepicker-casesheet">

														<Datepicker
															required
															className="Datepicker pa2 inputField"
															placeholderText="From"
															maxDate={new Date()}
															calendarClassName="rasta-stripes"
															selected={sinceDate4}
															value={sinceDate4}
															dateFormat="dd/MM/yyyy"
															onChange={(date) => setSinceDate4(date)}
														/>
													</div>
												</div> */}

											</div>
											<div class="treatmentClinicContent" id="treatmentClinicContentId" >
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour" >
													<div class="clinicalLabel">Chief presenting complaint <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></div>
													<div class="clinicalField" >
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt" id="chiefTags">
																(Add Tag(s) & Press Enter Key.)<br />
																{fullArray.map((index, i) => (
																	<span>
																		<li
																			style={{
																				listStyle: 'none',
																				fontSize: '12px',
																			}}
																			onMouseEnter={(e) => chiefEnter(index)}
																			onMouseLeave={(e) => chiefLeave(index)}
																		>
																			{index}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'chief' + index}
																				onClick={(e) => deleteValue(e, i)}
																			>
																				{' '}
																				x{' '}
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<span
																class="twitter-typeahead"
																style={{
																	position: 'relative',
																	display: 'inline - block',
																}}
															>
																<input
																	type="text"
																	onKeyPress={setValue}
																	value={clearValue}
																	onChange={deleteInputValue}
																	id="clearinputname1"
																	className="inputField"
																	autoComplete="off"
																	onKeyUp={() =>
																		orderAt(
																			'1',
																			'clearinputname1',
																			'locationSearch1'
																		)}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																	id="locationSearch1"
																	style={{ display: 'none' }}>
																	{freeArticleData1.map((freeArticleDataObj, i) => (
																		<li
																			onClick={(e) =>
																				setChiefTagData(
																					freeArticleDataObj.tagName,
																					'clearinputname1',
																					'locationSearch1'
																				)}
																		>
																			{freeArticleDataObj.tagName}
																		</li>
																	))}
																</ul>
															</span>{' '}
															<div class="clinicalDesField position-relative">
																{browser === "chrome" ?
																	<div>
																		{isChief === false ?
																			<span className='speechBlock_sectn speechBlock_Change'>
																				<UseMic micClick={setChief} />
																			</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change' id={chiefDescription}>
																				<Speech key={language} isClick={isChief} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"chiefDescription"} />
																			</span>
																		}
																	</div> : ""}
																<span class="clinicalDesTxt">Description</span>

																<textarea
																	id="chiefDes"
																	maxlength="255"
																	// value={chiefDescription}
																	value={chiefDescription + (isChief === true ? ' Listening...' : '')}
																	onChange={(e) => setChiefDescription(e.target.value)}
																	placeholder="Description"
																	style={{ resize: 'none' }}
																/>
															</div>
															{window.sessionStorage.getItem('fromSimplifiedForm') == 1 ?
																<div class="clinicalDesField">
																	<span class="clinicalDesTxt">
																		Reason For Change
																	</span>
																	<input
																		type="text"
																		value={chiefReason}
																		onChange={(e) => setChiefReason(e.target.value)}

																		className="inputField"
																		placeholder="Reason For Change"
																		style={{ width: '100%' }}
																	/>
																</div> : ""}
														</div>
													</div>
													<div class="clinicalDate">
														<label>Since Date</label>
														<Datepicker
															required
															className="Datepicker pa2 inputField"
															placeholderText="From"
															maxDate={new Date()}
															calendarClassName="rasta-stripes"
															selected={sinceDate1}
															value={sinceDate1}
															dateFormat="dd/MM/yyyy"
															onChange={(date) => setSinceDate1(date)}
														/>
													</div>

												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
													<div class="clinicalLabel">
														Associated <br />complaints
													</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt">
																(Add Tag(s) & Press Enter Key.)
																{associatedArray.map((index, i) => (
																	<span>
																		<li
																			style={{
																				listStyle: 'none',
																				fontSize: '12px',
																			}}
																			onMouseEnter={(e) => associatedMouseEnter(index)}
																			onMouseLeave={(e) => associatedMouseLeave(index)}
																		>
																			{index}{' '}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'associate' + index}
																				onClick={(e) => associatedDelete(e, i)}
																			>
																				{' '}
																				x{' '}
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<span
																class="twitter-typeahead"
																style={{
																	position: 'relative',
																	display: 'inline - block',
																}}
															>
																<input
																	type="text"
																	onKeyPress={setAssociateValue}
																	value={associateClearValue}
																	id="clearinputname2"
																	onChange={deleteAssociateValue}
																	className="inputField"
																	autoComplete="off"
																	onKeyUp={() => orderAt('2', 'clearinputname2', 'locationSearch2')}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																	id="locationSearch2"
																	style={{ display: 'none' }}
																>
																	{freeArticleData1.map((freeArticleDataObj, i) => (
																		<li
																			onClick={(e) => setAssociatedTagData(freeArticleDataObj.tagName, 'clearinputname2', 'locationSearch2')}
																		>
																			{freeArticleDataObj.tagName}
																		</li>
																	))}
																</ul>
															</span>{' '}
															<div class="clinicalDesField position-relative">
																{browser === "chrome" ?
																	<div>
																		{isAssociated === false ?
																			<span className='speechBlock_sectn speechBlock_Change'>
																				<UseMic micClick={setAssociated} />
																			</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change' id={"associatedDescription"}>
																				<Speech key={language} isClick={isAssociated} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"associatedDescription"} id={"associatedDescription"} />
																			</span>
																		}
																	</div> : ""}
																<span class="clinicalDesTxt">Description</span>

																<textarea
																	id="associatedDes"
																	// value={associatedDescription}
																	value={associatedDescription + (isAssociated === true ? ' Listening...' : '')}
																	maxlength="255"
																	onChange={(e) => setAssociatedDescription(e.target.value)}
																	placeholder="Description"
																	style={{ resize: 'none' }}
																/>
															</div>
															{window.sessionStorage.getItem('fromSimplifiedForm') == 1 ?
																<div class="clinicalDesField">
																	<span class="clinicalDesTxt">
																		Reason For Change
																	</span>
																	<input
																		type="text"
																		value={associatedReason}
																		onChange={(e) =>
																			setAssociatedReason(e.target.value)}

																		className="inputField"
																		placeholder="Reason For Change"
																		style={{ width: '100%' }}
																	/>
																</div> : ""}
														</div>
													</div>
													<div class="clinicalDate">
														<label>Since Date</label>
														<Datepicker
															required
															className="Datepicker pa2 inputField"
															placeholderText="From"
															maxDate={new Date()}
															calendarClassName="rasta-stripes"
															selected={sinceDate2}
															value={sinceDate2}
															dateFormat="dd/MM/yyyy"
															onChange={(date) => setSinceDate2(date)}
														/>
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
													<div class="clinicalLabel">Known Case of</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt">
																(Add Tag(s) & Press Enter Key.)
																{knownCaseArray.map((index, i) => (
																	<span>
																		<li
																			style={{ listStyle: 'none', fontSize: '12px', }}
																			onMouseEnter={(e) => knownEnter(index)}
																			onMouseLeave={(e) => knownLeave(index)}
																		>
																			{index}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'known' + index}
																				onClick={(e) => knownCaseDelete(e, i)}
																			>
																				<b> x </b>
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<span
																class="twitter-typeahead"
																style={{
																	position: 'relative',
																	display: 'inline - block',
																}}
															>
																<input
																	type="text"
																	onKeyPress={setKnownValue}
																	value={knownClearValue}
																	onChange={deleteKnownValue}
																	id="clearinputname3"
																	className="inputField"
																	autoComplete="off"
																	onKeyUp={() => orderAt('3', 'clearinputname3', 'locationSearch3')}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																	id="locationSearch3"
																	style={{ display: 'none' }}
																>
																	{freeArticleData1.map((freeArticleDataObj, i) => (
																		<li
																			onClick={() => setKnownTagData(freeArticleDataObj.tagName, 'clearinputname3', 'locationSearch3')}
																		>
																			{freeArticleDataObj.tagName}
																		</li>
																	))}
																</ul>
															</span>{' '}
															<div class="clinicalDesField position-relative">
																{browser === "chrome" ?
																	<div>
																		{isKnownCase === false ?
																			<span className='speechBlock_sectn speechBlock_Change'>
																				<UseMic micClick={setKnownCase} />
																			</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																				<Speech key={language} isClick={isKnownCase} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"knownDescription"} />
																			</span>
																		}
																	</div> : ""}
																<span class="clinicalDesTxt">Description</span>
																<textarea
																	id="knownDes"
																	// value={knownDescription}
																	value={knownDescription + (isKnownCase === true ? ' Listening...' : '')}
																	maxlength="255"
																	onChange={(e) => setKnownDescription(e.target.value)}
																	placeholder="Description"
																	style={{ resize: 'none' }}
																/>
															</div>
															{window.sessionStorage.getItem('fromSimplifiedForm') == 1 ?
																<div class="clinicalDesField">
																	<span class="clinicalDesTxt">
																		Reason For Change
																	</span>
																	<input
																		type="text"
																		value={knownReason}
																		onChange={(e) => setKnownReason(e.target.value)}

																		className="inputField"
																		placeholder="Reason For Change"
																		style={{ width: '100%' }}
																	/>
																</div> : ""}
														</div>
													</div>
													<div class="clinicalDate">
														<label>Since Date</label>
														<Datepicker
															required
															className="Datepicker pa2 inputField"
															placeholderText="From"
															maxDate={new Date()}
															calendarClassName="rasta-stripes"
															selected={sinceDate3}
															value={sinceDate3}
															dateFormat="dd/MM/yyyy"
															onChange={(date) => setSinceDate3(date)}
														/>
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetKey_PTour">
													<div
														class="keyLeft dentalCaseSheetKeyLeft_PTour"
													>
														<span class="keyHead">Key :</span>
														<ul>
															<li>
																<span class="keyLetter">D</span>
																<span class="keyDes">- Decay</span>
															</li>

															<li>
																<span class="keyLetter">M</span>
																<span class="keyDes">- Missing/Extracted</span>
															</li>

															<li>
																<span class="keyLetter">F</span>
																<span class="keyDes">- Filled</span>
															</li>

															<li>
																<span class="keyLetter">R</span>
																<span class="keyDes">- Replaced</span>
															</li>

															<li>
																<span class="keyLetter">X</span>
																<span class="keyDes">- Recommended Extraction</span>
															</li>

															<li>
																<span class="keyLetter">#</span>
																<span class="keyDes">- Fractured Tooth</span>
															</li>

															<li>
																<span class="keyLetter">I</span>
																<span class="keyDes">- Impacted</span>
															</li>

															<li>
																<span class="keyLetter">P</span>
																<span class="keyDes">- Periodontal/Pockets</span>
															</li>
														</ul>
													</div>
													{/*  */}
													<div class="keyRight dentalCaseSheetKeyRight_PTour">
														<div class="teethWrap">
															<div class="teethSec">
																<div class="teethContent">
																	<div class="teethList">
																		<span>18</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv18"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText18" onClick={(e) => setToothSection(18, 'teethDiv')}></div>
																				<span
																					id="theethKeyClose18"
																					class="theethKeyClose"
																					onClick={(e) => teethDelete(18)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img1}
																				onClick={(e) => setToothSection(18, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv18"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="18"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>17</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv17"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText17" onClick={() => setToothSection(17, 'teethDiv')}></div>
																				<span
																					id="theethKeyClose17"
																					class="theethKeyClose"
																					onClick={() => teethDelete(17)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img2}
																				onClick={() => setToothSection(17, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv17"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="17"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>16</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv16"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText16" onClick={() => setToothSection(16, 'teethDiv')} />
																				<span
																					id="theethKeyClose16"
																					class="theethKeyClose"
																					onClick={() => teethDelete(16)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img3}
																				onClick={() =>
																					setToothSection(16, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv16"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="16"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>15</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv15"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText15" onClick={() => setToothSection(15, 'teethDiv')} />
																				<span
																					id="theethKeyClose15"
																					class="theethKeyClose"
																					onClick={() => teethDelete(15)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img4}
																				onClick={() => setToothSection(15, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv15"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="15"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>14</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv14"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText14" onClick={() => setToothSection(14, 'teethDiv')} />
																				<span
																					id="theethKeyClose14"
																					class="theethKeyClose"
																					onClick={() => teethDelete(14)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img5}
																				onClick={() => setToothSection(14, 'teethDiv')} />
																			<div
																				className="toothDataPopup"
																				id="teethDiv14"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="14"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>13</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv13"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText13" onClick={() => setToothSection(13, 'teethDiv')} />
																				<span
																					id="theethKeyClose13"
																					class="theethKeyClose"
																					onClick={() => teethDelete(13)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img6}
																				onClick={() => setToothSection(13, 'teethDiv')} />
																			<div
																				className="toothDataPopup"
																				id="teethDiv13"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="13"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>12</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv12"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText12" onClick={() => setToothSection(12, 'teethDiv')} />
																				<span
																					id="theethKeyClose12"
																					class="theethKeyClose"
																					onClick={() => teethDelete(12)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img7}
																				onClick={() => setToothSection(12, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv12"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="12"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>11</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv11"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText11" onClick={() => setToothSection(11, 'teethDiv')} />
																				<span
																					id="theethKeyClose11"
																					class="theethKeyClose"
																					onClick={() => teethDelete(11)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img8}
																				onClick={() => setToothSection(11, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv11"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="11"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>21</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv21"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText21" onClick={() => setToothSection(21, 'teethDiv')} />
																				<span
																					id="theethKeyClose21"
																					class="theethKeyClose"
																					onClick={() => teethDelete(21)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img9}
																				onClick={() => setToothSection(21, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv21"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="21"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>22</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv22"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText22" onClick={() => setToothSection(22, 'teethDiv')} />
																				<span
																					id="theethKeyClose22"
																					class="theethKeyClose"
																					onClick={() => teethDelete(22)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img10}
																				onClick={() => setToothSection(22, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv22"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="22"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>23</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv23"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText23" onClick={() => setToothSection(23, 'teethDiv')} />
																				<span
																					id="theethKeyClose23"
																					class="theethKeyClose"
																					onClick={() => teethDelete(23)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img11}
																				onClick={() => setToothSection(23, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv23"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="23"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>24</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv24"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText24" onClick={() => setToothSection(24, 'teethDiv')} />
																				<span
																					id="theethKeyClose24"
																					class="theethKeyClose"
																					onClick={() => teethDelete(24)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img12}
																				onClick={() => setToothSection(24, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv24"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="24"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>25</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv25"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText25" onClick={() => setToothSection(25, 'teethDiv')} />
																				<span
																					id="theethKeyClose25"
																					class="theethKeyClose"
																					onClick={() => teethDelete(25)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img13}
																				onClick={() => setToothSection(25, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv25"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="25"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>26</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv26"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText26" onClick={() => setToothSection(26, 'teethDiv')} />
																				<span
																					id="theethKeyClose26"
																					class="theethKeyClose"
																					onClick={() => teethDelete(26)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img14}
																				onClick={() => setToothSection(26, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv26"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="26"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>27</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv27"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText27" onClick={() => setToothSection(27, 'teethDiv')} />
																				<span
																					id="theethKeyClose27"
																					class="theethKeyClose"
																					onClick={() => teethDelete(27)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img15}
																				onClick={() => setToothSection(27, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv27"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="27"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>28</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv28"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText28" onClick={() => setToothSection(28, 'teethDiv')} />
																				<span
																					id="theethKeyClose28"
																					class="theethKeyClose"
																					onClick={() => teethDelete(28)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img16}
																				onClick={() => setToothSection(28, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv28"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="28"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="teethContent">
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv48"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText48" onClick={() => setToothSection(48, 'teethDiv')} />
																				<span
																					id="theethKeyClose48"
																					class="theethKeyClose"
																					onClick={() => teethDelete(48)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img1btm}
																				onClick={() => setToothSection(48, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv48"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="48"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>48</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv47"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText47" onClick={() => setToothSection(47, 'teethDiv')} />
																				<span
																					id="theethKeyClose47"
																					class="theethKeyClose"
																					onClick={() => teethDelete(47)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img2btm}
																				onClick={() => setToothSection(47, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv47"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="47"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>47</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv46"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText46" onClick={() => setToothSection(46, 'teethDiv')} />
																				<span
																					id="theethKeyClose46"
																					class="theethKeyClose"
																					onClick={() => teethDelete(46)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img3btm}
																				onClick={() => setToothSection(46, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv46"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="46"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>46</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv45"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText45" onClick={() => setToothSection(45, 'teethDiv')} />
																				<span
																					id="theethKeyClose45"
																					class="theethKeyClose"
																					onClick={() => teethDelete(45)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img4btm}
																				onClick={() => setToothSection(45, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv45"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="45"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>45</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv44"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText44" onClick={() => setToothSection(44, 'teethDiv')} />
																				<span
																					id="theethKeyClose44"
																					class="theethKeyClose"
																					onClick={() => teethDelete(44)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img5btm}
																				onClick={() => setToothSection(44, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv44"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="44"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>44</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv43"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText43" onClick={() => setToothSection(43, 'teethDiv')} />
																				<span
																					id="theethKeyClose43"
																					class="theethKeyClose"
																					onClick={() => teethDelete(43)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img6btm}
																				onClick={() => setToothSection(43, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv43"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="43"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>43</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv42"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText42" onClick={() => setToothSection(42, 'teethDiv')} />
																				<span
																					id="theethKeyClose42"
																					class="theethKeyClose"
																					onClick={() => teethDelete(42)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img7btm}
																				onClick={() => setToothSection(42, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv42"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="42"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>42</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv41"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText41" onClick={() => setToothSection(41, 'teethDiv')} />
																				<span
																					id="theethKeyClose41"
																					class="theethKeyClose"
																					onClick={() => teethDelete(41)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img8btm}
																				onClick={() => setToothSection(41, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv41"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="41"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>41</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv31"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText31" onClick={() => setToothSection(31, 'teethDiv')} />
																				<span
																					id="theethKeyClose31"
																					class="theethKeyClose"
																					onClick={() => teethDelete(31)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img9btm}
																				onClick={() => setToothSection(31, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv31"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="31"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>31</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv32"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText32" onClick={() => setToothSection(32, 'teethDiv')} />
																				<span
																					id="theethKeyClose32"
																					class="theethKeyClose"
																					onClick={() => teethDelete(32)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img10btm}
																				onClick={() => setToothSection(32, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv32"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="32"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>32</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv33"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText33" onClick={() => setToothSection(33, 'teethDiv')} />
																				<span
																					id="theethKeyClose33"
																					class="theethKeyClose"
																					onClick={() => teethDelete(33)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img11btm}
																				onClick={() => setToothSection(33, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv33"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="33"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>33</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv34"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText34" onClick={() => setToothSection(34, 'teethDiv')} />
																				<span
																					id="theethKeyClose34"
																					class="theethKeyClose"
																					onClick={() => teethDelete(34)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img12btm}
																				onClick={() => setToothSection(34, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv34"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="34"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>34</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv35"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText35" onClick={() => setToothSection(35, 'teethDiv')} />
																				<span
																					id="theethKeyClose35"
																					class="theethKeyClose"
																					onClick={() => teethDelete(35)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img13btm}
																				onClick={() => setToothSection(35, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv35"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="35"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>35</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv36"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText36" onClick={() => setToothSection(36, 'teethDiv')} />
																				<span
																					id="theethKeyClose36"
																					class="theethKeyClose"
																					onClick={() => teethDelete(36)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img14btm}
																				onClick={() => setToothSection(36, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv36"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="36"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>36</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv37"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText37" onClick={() => setToothSection(37, 'teethDiv')} />
																				<span
																					id="theethKeyClose37"
																					class="theethKeyClose"
																					onClick={() => teethDelete(37)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img15btm}
																				onClick={() => setToothSection(37, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv37"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="37"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>37</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv38"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText38" onClick={() => setToothSection(38, 'teethDiv')} />
																				<span
																					id="theethKeyClose38"
																					class="theethKeyClose"
																					onClick={() => teethDelete(38)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img16btm}
																				onClick={() => setToothSection(38, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv38"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="38"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>38</span>
																	</div>
																</div>
															</div>
															<br />

															<div class="teethSec" style={{ display: 'block' }}>
																<div class="teethContent">
																	<div class="teethList ">
																		<span>55</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv55"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText55" onClick={() => setToothSection(55, 'teethDiv')} />
																				<span
																					id="theethKeyClose55"
																					class="theethKeyClose"
																					onClick={() => teethDelete(55)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_1}
																				onClick={() => setToothSection(55, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv55"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="55"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList  ">
																		<span>54</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv54"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText54" onClick={() => setToothSection(54, 'teethDiv')} />
																				<span
																					id="theethKeyClose54"
																					class="theethKeyClose"
																					onClick={() => teethDelete(54)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_2}
																				onClick={() => setToothSection(54, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv54"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="54"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>53</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv53"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText53" onClick={() => setToothSection(53, 'teethDiv')} />
																				<span
																					id="theethKeyClose53"
																					class="theethKeyClose"
																					onClick={() => teethDelete(53)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_3}
																				onClick={() => setToothSection(53, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv53"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="53"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>52</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv52"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText52" onClick={() => setToothSection(52, 'teethDiv')} />
																				<span
																					id="theethKeyClose52"
																					class="theethKeyClose"
																					onClick={() => teethDelete(52)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_4}
																				onClick={() => setToothSection(52, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv52"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="52"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>51</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv51"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText51" onClick={() => setToothSection(51, 'teethDiv')} />
																				<span
																					id="theethKeyClose51"
																					class="theethKeyClose"
																					onClick={() => teethDelete(51)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_5}
																				onClick={() => setToothSection(51, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv51"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="51"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>61</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv61"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText61" onClick={() => setToothSection(61, 'teethDiv')} />
																				<span
																					id="theethKeyClose61"
																					class="theethKeyClose"
																					onClick={() => teethDelete(61)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_6}
																				onClick={() => setToothSection(61, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv61"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="61"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>62</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv62"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText62" onClick={() => setToothSection(62, 'teethDiv')} />
																				<span
																					id="theethKeyClose62"
																					class="theethKeyClose"
																					onClick={() => teethDelete(62)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_7}
																				onClick={() => setToothSection(62, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv62"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="62"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>63</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv63"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText63" onClick={() => setToothSection(63, 'teethDiv')} />
																				<span
																					id="theethKeyClose63"
																					class="theethKeyClose"
																					onClick={() => teethDelete(63)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_8}
																				onClick={() => setToothSection(63, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv63"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="63"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>64</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv64"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText64" onClick={() => setToothSection(64, 'teethDiv')} />
																				<span
																					id="theethKeyClose64"
																					class="theethKeyClose"
																					onClick={() => teethDelete(64)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_9}
																				onClick={() => setToothSection(64, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv64"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="64"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div class="teethList">
																		<span>65</span>
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv65"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText65" onClick={() => setToothSection(65, 'teethDiv')} />
																				<span
																					id="theethKeyClose65"
																					class="theethKeyClose"
																					onClick={() => teethDelete(65)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_10}
																				onClick={() => setToothSection(65, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv65"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="65"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="teethContent">
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv85"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText85" onClick={() => setToothSection(85, 'teethDiv')} />
																				<span
																					id="theethKeyClose85"
																					class="theethKeyClose"
																					onClick={() => teethDelete(85)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_1btm}
																				onClick={() => setToothSection(85, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv85"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="85"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>85</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv84"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText84" onClick={() => setToothSection(84, 'teethDiv')} />
																				<span
																					id="theethKeyClose84"
																					class="theethKeyClose"
																					onClick={() => teethDelete(84)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_2btm}
																				onClick={() => setToothSection(84, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv84"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="84"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>84</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv83"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText83" onClick={() => setToothSection(83, 'teethDiv')} />
																				<span
																					id="theethKeyClose83"
																					class="theethKeyClose"
																					onClick={() => teethDelete(83)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_3btm}
																				onClick={() => setToothSection(83, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv83"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="83"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>83</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv82"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText82" onClick={() => setToothSection(82, 'teethDiv')} />
																				<span
																					id="theethKeyClose82"
																					class="theethKeyClose"
																					onClick={() => teethDelete(82)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_4btm}
																				onClick={() => setToothSection(82, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv82"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="82"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>82</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv81"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText81" onClick={() => setToothSection(81, 'teethDiv')} />
																				<span
																					id="theethKeyClose81"
																					class="theethKeyClose"
																					onClick={() => teethDelete(81)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_5btm}
																				onClick={() => setToothSection(81, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv81"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="81"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>81</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv71"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText71" onClick={() => setToothSection(71, 'teethDiv')} />
																				<span
																					id="theethKeyClose71"
																					class="theethKeyClose"
																					onClick={() => teethDelete(71)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_6btm}
																				onClick={() => setToothSection(71, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv71"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="71"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>71</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv72"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText72" onClick={() => setToothSection(72, 'teethDiv')} />
																				<span
																					id="theethKeyClose72"
																					class="theethKeyClose"
																					onClick={() => teethDelete(72)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_7btm}
																				onClick={() => setToothSection(72, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv72"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="72"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>72</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv73"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText73" onClick={() => setToothSection(73, 'teethDiv')} />
																				<span
																					id="theethKeyClose73"
																					class="theethKeyClose"
																					onClick={() => teethDelete(73)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_8btm}
																				onClick={() => setToothSection(73, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv73"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="73"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>73</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv74"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText74" onClick={() => setToothSection(74, 'teethDiv')} />
																				<span
																					id="theethKeyClose74"
																					class="theethKeyClose"
																					onClick={() => teethDelete(74)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_9btm}
																				onClick={() => setToothSection(74, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv74"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="74"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>74</span>
																	</div>
																	<div class="teethList">
																		<div class="teethImg">
																			<span
																				class="teethKey"
																				id="appendeDiv75"
																				style={{ display: 'none' }}
																			>
																				<div id="appendeText75" onClick={() => setToothSection(75, 'teethDiv')} />
																				<span
																					id="theethKeyClose75"
																					class="theethKeyClose"
																					onClick={() => teethDelete(75)}
																				>
																					x
																				</span>
																			</span>
																			<img
																				src={img_10btm}
																				onClick={() => setToothSection(75, 'teethDiv')}
																			/>
																			<div
																				className="toothDataPopup"
																				id="teethDiv75"
																				style={{ display: 'none' }}
																			>
																				<Tooth
																					parentCallback={
																						selectedDropdownValue
																					}
																					teethNumber="75"
																					passingToothData={
																						passingToothDataArr
																					}
																				/>
																			</div>
																		</div>
																		<span>75</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/*  */}

													<div class="clinicContentList">
														<div class="caseSheetItemList dentalCaseSheetOcclusnEvaln_PTour">
															<label>Occlusion Evaluation</label>
															<input
																value={txtOcclu}
																type="text"
																id="txtOcclu"
																name="txtOcclu"
																maxlength="255"
																onChange={(e) => setTxtOcclu(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetLymphNode_PTour">
															<label>Lymph nodes</label>
															<input
																type="text"
																id="txtLymph"
																name="txtLymph"
																value={txtLymph}
																maxlength="255"
																onChange={(e) => setTxtLymph(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetTMJ_PTour">
															<label>TMJ</label>
															<input
																type="text"
																id="txtTMJ"
																name="txtTMJ"
																value={txtTMJ}
																maxlength="255"
																onChange={(e) => setTxtTMJ(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetBuccalmucosa_PTour">
															<label>Buccal mucosa</label>
															<input
																type="text"
																id="txtBuccal"
																name="txtBuccal"
																value={txtBuccal}
																maxlength="255"
																onChange={(e) => setTxtBuccal(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetVestibules_PTour">
															<label>Vestibules</label>
															<input
																type="text"
																id="txtVesti"
																name="txtVesti"
																value={txtVesti}
																maxlength="255"
																onChange={(e) => setTxtVesti(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetTongue_PTour">
															<label>Tongue</label>
															<input
																type="text"
																id="txtTongue"
																name="txtTongue"
																value={txtTongue}
																maxlength="255"
																onChange={(e) => setTxtTongue(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetFloorofMouth_PTour">
															<label>Floor of Mouth</label>
															<input
																type="text"
																id="txtFloor"
																name="txtFloor"
																value={txtFloor}
																maxlength="255"
																onChange={(e) => setTxtFloor(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetPalate_PTour">
															<label>Palate</label>
															<input
																type="text"
																id="txtPalate"
																name="txtPalate"
																value={txtPalate}
																maxlength="255"
																onChange={(e) => setTxtPalate(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetPharynx_PTour">
															<label>Pharynx</label>
															<input
																type="text"
																id="txtPharynx"
																name="txtPharynx"
																value={txtPharynx}
																maxlength="255"
																onChange={(e) => setTxtPharynx(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetAlveolarbone_PTour">
															<label>Alveolar bone</label>
															<input
																type="text"
																id="txtAlBone"
																name="txtAlBone"
																value={txtAlBone}
																maxlength="255"
																onChange={(e) => setTxtAlBone(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetPeriopockets_PTour">
															<label>Perio pockets</label>
															<input
																type="text"
																id="txtPerio"
																name="txtPerio"
																value={txtPerio}
																maxlength="255"
																onChange={(e) => setTxtPerio(e.target.value)}
															/>
														</div>
														<div class="caseSheetItemList dentalCaseSheetGingiva_PTour">
															<label>Gingiva</label>
															<input
																type="text"
																id="txtGingiva"
																name="txtGingiva"
																value={txtGingiva}
																maxlength="255"
																onChange={(e) => setTxtGingiva(e.target.value)}
															/>
														</div>

														<div class="caseSheetItemList dentalCaseSheetOralHygiene_PTour">
															<label>Oral Hygiene</label>
															<div class="caeSheetRadioWrap">
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio1"
																		name="txtRadio1"
																		checked={selectedRadioButton1 === 'Excellent'}
																		value="Excellent"
																		onClick={(e) => { setSelectedRadioButton1('Excellent'); }}
																	/>
																	<span>Excellent</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio1"
																		name="txtRadio1"
																		checked={selectedRadioButton1 === 'Good'}
																		value="Good"
																		onClick={(e) => { setSelectedRadioButton1('Good'); }}
																	/>
																	<span>Good</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio1"
																		name="txtRadio1"
																		checked={selectedRadioButton1 === 'Fair'}
																		value="Fair"
																		onClick={(e) => { setSelectedRadioButton1('Fair'); }}
																	/>
																	<span>Fair</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio1"
																		name="txtRadio1"
																		checked={selectedRadioButton1 === 'Poor'}
																		value="Poor"
																		onClick={(e) => { setSelectedRadioButton1('Poor'); }}
																	/>
																	<span>Poor</span>
																</div>
															</div>
														</div>
														<div class="caseSheetItemList dentalCaseSheetCalculus_PTour">
															<label>Calculus</label>
															<div class="caeSheetRadioWrap">
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio2"
																		name="txtRadio2"
																		checked={selectedRadioButton2 === 'None'}
																		value="None"
																		onClick={(e) => { setSelectedRadioButton2('None'); }}
																	/>
																	<span>None</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio2"
																		name="txtRadio2"
																		checked={selectedRadioButton2 === 'Little'}
																		value="Little"
																		onClick={(e) => { setSelectedRadioButton2('Little'); }}
																	/>
																	<span>Little</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio2"
																		name="txtRadio2"
																		checked={selectedRadioButton2 === 'Moderate'}
																		value="Moderate"
																		onClick={(e) => { setSelectedRadioButton2('Moderate'); }}
																	/>
																	<span>Moderate</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio2"
																		name="txtRadio2"
																		checked={selectedRadioButton2 === 'Heavy'}
																		value="Heavy"
																		onClick={(e) => { setSelectedRadioButton2('Heavy'); }}
																	/>
																	<span>Heavy</span>
																</div>
															</div>
														</div>
														<div class="caseSheetItemList dentalCaseSheetGingivalBleeding_PTour">
															<label>Gingival Bleeding</label>
															<div class="caeSheetRadioWrap">
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio3"
																		name="txtRadio3"
																		checked={selectedRadioButton3 === 'Localized'}
																		value="Localized"
																		onClick={(e) => { setSelectedRadioButton3('Localized'); }}
																	/>
																	<span>Localized</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio3"
																		name="txtRadio3"
																		checked={selectedRadioButton3 === 'General'}
																		value="General"
																		onClick={(e) => { setSelectedRadioButton3('General'); }}
																	/>
																	<span>General</span>
																</div>
																<div class="caeSheetRadio">
																	<input
																		type="radio"
																		id="txtRadio3"
																		name="txtRadio3"
																		checked={selectedRadioButton3 === 'None'}
																		value="None"
																		onClick={(e) => { setSelectedRadioButton3('None'); }}
																	/>
																	<span>None</span>
																</div>
															</div>
														</div>
													</div>
													<div class="clinicContentList">
														<div class="caseSheetRadioText dentalCaseSheetRadiographs_PTour position-relative" style={{ width: "95%" }}>
															<label>Radiographs existing/ advised</label>
															<textarea
																id="txtRadiograph"
																// value={txtRadiograph}
																value={txtRadiograph + (istxtRadiograph === true ? ' Listening...' : '')}
																onChange={(e) => setTxtRadiograph(e.target.value)}
																name="txtRadiograph"
															/>
															{browser === "chrome" ?
																<div>
																	{istxtRadiograph === false ?
																		<span className='speechBlock_sectn speechBlock_Change'>
																			<UseMic micClick={setIstxtRadiograph} />
																		</span>
																		:
																		<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																			<Speech key={language} isClick={istxtRadiograph} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"istxtRadiograph"} />
																		</span>
																	}
																</div> : ""}
														</div>
														<div class="caseSheetRadioText dentalCaseSheetComments_PTour position-relative" style={{ width: "95%" }}>
															<label>Comments</label>
															<textarea
																id="txtComments"
																// value={txtComments}
																value={txtComments + (isRemark === true ? ' Listening...' : '')}
																onChange={(e) => setTxtComments(e.target.value)}
																name="txtComments"
															/>
															{browser === "chrome" ?
																<div>
																	{isRemark === false ?
																		<span className='speechBlock_sectn speechBlock_Change'>
																			<UseMic micClick={setRemark} />
																		</span>
																		:
																		<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																			<Speech key={language} isClick={isRemark} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"remarks"} />
																		</span>
																	}
																</div> : ""}
														</div>
														<div class="caseSheetRadioText dentalCaseSheetComments_PTour position-relative" style={{ width: "95%" }}>
															<label>Observation</label>
															<textarea
																id="txtObservations"
																// value={txtObservations}
																value={txtObservations + (isObservation === true ? ' Listening...' : '')}
																onChange={(e) => setTxtObservations(e.target.value)}
																name="txtObservations"
															/>
															{browser === "chrome" ?
																<div>
																	{isObservation === false ?
																		<span className='speechBlock_sectn speechBlock_Change'>
																			<UseMic micClick={setObservation} />
																		</span>
																		:
																		<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																			<Speech key={language} isClick={isObservation} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"observations"} />
																		</span>
																	}</div> : ""}
														</div>
													</div>
													<div class="clinicContentList">
														<div class="caseSheetRadioText dentalCaseSheetRadiographs_PTour position-relative" style={{ width: "95%" }}>
															<label>Specialist Opinion if any</label>
															<textarea
																id="txtRadiograph"
																// value={txtSpecialist}
																value={txtSpecialist + (isSpecialist === true ? ' Listening...' : '')}
																onChange={(e) => setTxtSpecialist(e.target.value)}
																name="txtRadiograph"
															/>
															{browser === "chrome" ?
																<div>
																	{isSpecialist === false ?
																		<span className='speechBlock_sectn speechBlock_Change'>
																			<UseMic micClick={setSpecialist} />
																		</span>
																		:
																		<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																			<Speech key={language} isClick={isSpecialist} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtSpecialist"} />
																		</span>
																	}</div> : ""}
														</div>
														<div class="caseSheetRadioText dentalCaseSheetComments_PTour position-relative" style={{ width: "95%" }}>
															<label>Diagnosis & Treatment Plan/Explained Options</label>
															<textarea
																id="txtComments"
																// value={txtDiagnosis}
																value={txtDiagnosis + (isDiagnosis === true ? ' Listening...' : '')}
																onChange={(e) => setTxtDiagnosis(e.target.value)}
																name="txtComments"
															/>
															{browser === "chrome" ?
																<div>
																	{isDiagnosis === false ?
																		<span className='speechBlock_sectn speechBlock_Change'>
																			<UseMic micClick={setIsDiagnosis} />
																		</span>
																		:
																		<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																			<Speech key={language} isClick={isDiagnosis} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtDiagnosis"} />
																		</span>
																	}</div> : ""}
														</div>
														<div class="caseSheetRadioText dentalCaseSheetComments_PTour position-relative" style={{ width: "95%" }}>
															<label>Treatment Done</label>
															<textarea
																id="txtTreatmentDone"
																// value={txtTreatmentDone}
																value={txtTreatmentDone + (isTreatment === true ? ' Listening...' : '')}
																onChange={(e) => setTxtTreatmentDone(e.target.value)}
																name="txtTreatmentDone"
															/>
															{browser === "chrome" ?
																<div>
																	{isTreatment === false ?
																		<span className='speechBlock_sectn speechBlock_Change'>
																			<UseMic micClick={setTreatment} />
																		</span>
																		:
																		<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																			<Speech key={language} isClick={isTreatment} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtTreatmentDone"} />
																		</span>
																	}</div> : ""}
														</div>
													</div>
													<div class="clinicContentList dentalCaseSheetAppontStatus_PTour">
														<div class="clinicArrived">
															<label class="clinicalLabel">Appointment Status</label>
															<div class="clinicalField">
																<select
																	name="cmbStatus"
																	id="cmbStatus"
																	value={cmbStatus}
																	onChange={(e) => setCmbStatus(e.target.value)}
																>
																	<option value="1">OPEN</option>
																	<option value="2">CONFIRM</option>
																	<option value="3" selected="selected">
																		ARRIVED
																	</option>
																	<option value="4">DOCTOR CHANGED</option>
																	<option value="8">ENGAGED</option>
																	<option value="5">ADMITTED</option>
																	<option value="7">DISCHARGE</option>
																	<option value="6">COMPLETED</option>
																	<option value="9">CANCELLED</option>
																	<option value="10">CALL COMPLETED-DOCTOR</option>
																</select>
															</div>
														</div>
													</div>
													{/* ======== NEW UPDATE BEGINS HERE ============ */}

													<div>
														{futureAppointmentDate.map((item, i) => (
															<div class="clinicFollowUp">
																<span
																	class="followUpTxtDate"
																	id="followUpTxtDateLabel"
																>
																	Next Appointment On {convertedFutureDate}
																</span>
																<span
																	class="followUpTxt"
																	onClick={() => setToggle(!toggle)}

																>
																	<span onClick={() => setIsChanged(true)}>
																		Change
																	</span>
																</span>
																<br />
																<span class="followUpTxtDate">
																	Doctor : {item.doctor_name}
																</span>
																<br />
																{item.followUpNotes ? (
																	<span class="followUpTxtDate">
																		Bring For Next Appointment : {item.followUpNotes}
																	</span>
																) : ('')}
																{/* <div class="clinicFollowUp caseSheetNeedFollowUp_PTour caseSheetWNeedFollowUp_PTour">

																		{toggle && (
																			<div
																				id="needFollowDiv"
																				style={{ display: 'block' }}
																			>
																				<div class="clinicContentList">
																					<div class="clinicalLabel">
																						Appointment Date &amp; Time
																					</div>
																					<div class="clinicalField">
																						<Datepicker
																							required
																							// id="appoinmentDateTime"
																							className="Datepicker pa2 inputField"
																							placeholderText={
																								item.aptDate
																							}
																							dateFormat="dd-MM-y h:mm aa"
																							calendarClassName="rasta-stripes"
																							showTimeSelect
																							selected={updatedAppointmentDate}
																							onChange={(date) =>
																								setUpdatedAppointmentDate(
																									date
																								)}
																						/>
																					</div>
																				</div>

																				<div class="clinicContentList">
																					<div class="clinicalLabel">
																						Bring For Next Appointment
																					</div>
																					<div class="clinicalField">
																						<textarea
																							name="txtFollowNote"
																							id="txtFollowNote"
																							maxlength="100"
																							value={updatedFollowNote}
																							onChange={(e) =>
																								setUpdatedFollowNote(
																									e.target.value
																								)}
																							style={{ resize: 'none' }}
																						/>
																					</div>
																				</div>
																			</div>
																		)}
																	</div> */}
															</div>
														))}
													</div>

													<div class="clinicFollowUp caseSheetNeedFollowUp_PTour caseSheetWNeedFollowUp_PTour">
														<span
															id="weee"
															class="followUpTxt needfollowup-inline"
															onClick={() => setToggle(!toggle)} style={{ visibility: "visible" }}
														>
															<div onClick={() => setFollowUpClick(true)}
																className='followup-button'
															>
																Add Appointment Follow Up
															</div>
														</span>
														<span class="followUpTxt recall-inline" id="weee" onClick={() => setRecallToggle(!recallToggle)} style={{ visibility: "visible" }}>
															<div style={{ paddingLeft: '15px' }} onClick={() =>
																setRecallClick(true)}
																className='recall-button'

															>
																Add Patient Recall
															</div>
														</span>

														{toggle && (
															<div id="needFollowDiv" style={{ display: 'block' }}>
																<div class="clinicContentList">
																	<div class="clinicalLabel">
																		Appointment Date &amp; Time
																	</div>
																	<div class="clinicalField">
																		<Datepicker
																			required
																			className="Datepicker pa2 inputField"
																			placeholderText="Date  Time"
																			dateFormat="dd-MM-y h:mm aa"
																			calendarClassName="rasta-stripes"
																			showTimeSelect
																			minDate={new Date()}
																			selected={appointmentDate}
																			onChange={(date) => setAppointmentDate(date)}
																		/>
																	</div>
																</div>
																{window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ?
																	<div class="clinicContentList">
																		<div class="clinicalLabel">By</div>
																		<div class="clinicalField">
																			<select
																				name="cmbDoctor"
																				id="cmbDoctor"
																				value={getDrName}
																				onChange={(e) => doctorClickFunction1(e.target.value)}
																			>
																				<option >
																					Select Doctor
																				</option>
																				{doctorList.map((index, i) => (
																					<option value={index.doctor_id}>
																						{index.doctor_name}
																					</option>
																				))}
																			</select>
																		</div>
																	</div> : ""}

																<div class="clinicContentList">
																	<div class="clinicalLabel">
																		Bring For Next Appointment
																	</div>
																	<div class="clinicalField">
																		<textarea
																			name="txtFollowNote"
																			id="txtFollowNote"
																			maxlength="100"
																			value={appointmentNextData}
																			onChange={(e) => setAppointmentNextData(e.target.value)}
																			style={{ resize: 'none' }}
																		/>
																	</div>
																</div>
															</div>
														)}
														{recallToggle && (
															<div id="needFollowDiv" style={{ display: 'block' }}>
																<div class="clinicContentList">
																	<div class="clinicalLabel">
																		Recall Date &amp; Time
																		{/* <span className='starimp'>*</span> */}
																	</div>
																	<div class="clinicalField">
																		<Datepicker
																			required
																			className="Datepicker pa2 inputField"
																			placeholderText="Date  Time"
																			dateFormat="dd-MM-y h:mm aa"
																			calendarClassName="rasta-stripes"
																			showTimeSelect
																			minDate={new Date()}
																			selected={recallDate}
																			onChange={(date) => setRecallDate(date)}
																		/>
																	</div>
																</div>

																<div class="clinicContentList">
																	<div class="clinicalLabel">
																		Comments
																	</div>
																	<div class="clinicalField">
																		<textarea
																			name="txtFollowNote"
																			id="txtFollowNote"
																			maxlength="100"
																			value={comments}
																			onChange={(e) => setComments(e.target.value)}
																			style={{ resize: 'none' }}
																		/>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
												<div
													class="treatmentSelect treatmentPlanHead"
													id="bottomDiv"
													style={{ border: 'none', float: 'right' }}
												>
													<div class="treatmentSelectBtns caseSheetMAdd">
														<a
															type="button"
															onClick={(e) => cancel()}
															class="treatmentPlanBtn pl_blue_btn pl_cancelbtn"
														>
															Cancel
														</a>
														<input
															type="submit"
															value="Save"
															id="lowsaveid"
															disabled={disable}
															onClick={(e) => { saveClickFunction(e, 'Save'); }}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
														<input
															type="submit"
															value="Update"
															id="lowupdateid"
															disabled={disable}
															style={{ background: '#5bb75b', display: 'none' }}
															onClick={(e) => { updateClickFunction(e, 'Save'); }}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
														{/* <input
															type="submit"
															value="Save"
															disabled={disable}
															onClick={saveClickFunction}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/> */}
														<input
															type="submit"
															value="Finalize"
															onClick={(e) => { finalizeClick(e); }}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default CasesheetDentalAdd;
