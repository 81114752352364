import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/media/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { callingAPI } from '../config';
import ReactToPrint from 'react-to-print';
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
function PatientTestResultPrint() {
	const location = useLocation();
	const [printData, setPrintData] = useState([])
	const [memberData, setMemberData] = useState([])
	const [doctorData, setDoctorData] = useState([])
	const [printArray, setPrintArray] = useState([])
	const [printId, setPrintId] = useState(window.sessionStorage.getItem("labOrderPrint"))
	const [hospitalId, setHospitalId] = useState(localStorage.getItem("hospitalId"));
	// const [memberId, setMemberId] = useState(window.sessionStorage.getItem('memberId'));
	const [memberId, setMemberId] = useState(window.sessionStorage.getItem('testresultmemebrid'));

	const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('doctorId'));
	const componentRef = useRef();
	useEffect(() => {
		printDataget()
	}, [])
	const printDataget = () => {
		const PostData = {
			"doctorVisitLabOrderTestId": printId,
			"memberId": memberId,
			"hospitalId": hospitalId,
			// "doctorId": doctorId,
			// As per Sivabro instruction doctorId as empty
			"doctorId": " ",
		};
		callingAPI('patientsListing/testResultPrintAll', PostData)
			.then((res) => {
				if (res.data.success === "0") {
					// alert(res.data.errorMessage)
					setMemberData([]);
				} else if (res.data.success === "1") {
					setMemberData(res.data.result.memberData[0])
					setPrintArray(res.data.result.testResultEditData[0])
					setDoctorData(res.data.result.doctorDetails[0])
					setPrintData(res.data.result.testResultEditData[0].labData);
				}
			})
			.catch();
	};



	return (
		<div className="patientTestResultPrint">
			<div align="center" style={{ fontFamily: "SourceSansPro-Regular" }}>

				<div align="center" style={{ width: "540px", height: "595px", margin: "0px auto" }} ref={componentRef}>
					<table style={{ width: "100%", marginTop: "0px" }} border="0">
						<thead>
							<tr>
								<th style={{ fontWeight: "400" }}>
									<div class="printOutHeaderSec PrescPrint ">
										<table style={{ width: "100%" }}>
											<tbody>
												<tr height="80px">
													<td colspan="3" valign="middle" align="center" style={{ verticalAlign: "middle" }}>
														<div style={{ width: "40%", margin: "0 auto", textAlign: "center" }}>
															<p style={{ fontWeight: "400", fontSize: "16px", clear: "both", width: "100%" }}>
																<span style={{ display: "inline-block", width: "100%" }}>
																	<img src={getS3folder + "/" + memberData.hospitalLogoImage} style={{ display: "block", width: "auto", height: "45px", margin: "0 auto" }} /></span>
															</p>
														</div>
														<div class="practicePrintName " style={{ width: "100%", float: "left" }}>
															<p style={{ fontWeight: "600", fontSize: "16px", clear: "both", width: "100%", marginBottom: "1px", marginTop: "0" }}>
															</p>
															<p style={{ fontWeight: "400", fontSize: "12px !important", clear: "both", width: "100%", margin: "0" }}>{memberData.hospitalName}</p>
															<p style={{ fontWeight: "400", fontSize: "12px !important", clear: "both", width: "100%", margin: "0" }}>{memberData.hospitalAddress}</p>
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr>
												<tr>
													<td colspan="3">
														{doctorData.doctorDisplayName}, {doctorData.speciality}, {doctorData.address}, {doctorData.city}
													</td>
												</tr>
												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "left" }}>Name : {memberData.displayName}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "right" }}>Date : {printArray.createdOn}</td>
												</tr>

												<tr>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Age : {memberData.memberAge}
													</td>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Email Id : {memberData.secUserEmailid}</td>

												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Sex : {memberData.memberGender}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Mobile No : {memberData.SecuserMobileNumber}</td>
												</tr>
												<tr>
													{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
														(<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>MR No : {memberData.mrNo}</td>) :
														(!memberData.prNo == "" && <td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>PR No : {memberData.prNo}</td>)}
												</tr>

												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr>

											</tbody>
										</table>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table style={{ width: "100%" }} cellspacing="0">
											<tbody>
												<tr>
													<td>
														<div class="teethContentHead">
															<div class="teethHeadItem testNameHead">Test Name (Type)</div>
															<div class="teethHeadItem patientReadingHead">Patient Reading</div>
															<div class="teethHeadItem normalValueHead">Normal Value</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							{printData.map((item) => {
								return (
									<tr>
										<td>
											<div class="printOutSettingContent">
												<table style={{ width: "100%" }} cellspacing="0">
													<tbody>
														<tr>
															<td>
																<div class="testPrintWrap">
																	<div class="teethContentDesRow teethContentTestNAme">
																		{item.department !== "" &&
																			<p style={{ marginBottom: "0px" }}>Department : <b>{item.department}</b></p>
																		}
																		<div class="teethContentDesItem teethContentDesDiv" style={{ width: "100%" }}><span style={{
																			fontFamily: "Arial",
																			fontWeight: 600
																		}}><u> {item.testName}</u>
																		</span>
																		</div>
																	</div>
																	{item.labDetails.map((data) => {
																		return (
																			<div class="teethContentDesRow">
																				<div style={{ padding: "4px 2px", fontSize: "12px" }}><b> </b></div>

																				<div class="teethContentDesItem testNameHead"><span>{data.parameter}</span></div>
																				<div class="teethContentDesItem patientReadingHead"><span>
																					<span style={{ color: "green", fontWeight: "bold" }}>{data.patientReadingValue} </span>
																				</span></div>
																				<div class="teethContentDesItem normalValueHead">
																					<div class="teethDesItemUnit">
																						<span>{data.normalValue}</span>
																					</div>

																				</div>
																			</div>
																		)
																	})}

																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								)
							})}

							<tr>
								<td>
									<div class="printOutSettingContent" style={{ paddingTop: "80px" }}>
										<table style={{ width: "100%" }} cellspacing="0">
											<tbody>

												<tr>
													<td>
														<div style={{ padding: '0 0', textAlign: "center", display: "block", float: "left", width: "33.33%", fontSize: "13px" }}>
															<span>Lab Technician</span>
														</div>
														<div style={{ padding: "0 0", textAlign: "center", display: "block", float: "left", width: "33.33%", fontSize: "13px" }}>
															<span>Lab Technologist</span>
														</div>
														<div style={{ padding: "0 0", textAlign: "center", display: "block", float: "left", width: "33.33%", fontSize: "13px" }}>
															<span>Verified By</span>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>

						</tbody>
						<tfoot>
							<tr>
								<td>
									<div class="printOutFooterSec">
										<table style={{ width: "100%" }}>
											<tbody>
												<tr>
													<td width="25%" colspan="2" align="left" style={{ wordBreak: " break-all" }}>
														<div align="justify" style={{ fontSize: "10px" }}> </div></td>
													<td width="50%" align="right" style={{ fontSize: "10px", wordBreak: "break-all" }}> {doctorData.doctorEmail}</td>
												</tr>

												<tr>
													<td colspan="3"><div align="justify" class="practiceFootBtm">Working time</div></td>
												</tr>
												<tr>
													<td colspan="3" style={{ paddingTop: "10px" }}>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<ReactToPrint
					trigger={() =>
						<div class="printBtm" style={{ textAlign: "center", clear: "both", overflow: "hidden" }}>
							<p style={{ width: "70px", textAlign: "center", paddingTop: "5px", lineHeight: "18px", display: "inline-block", fontWeight: "bold", fontSize: "14px", color: "white", background: "rebeccapurple none repeat scroll 0% 0%", marginTop: "2px", cursor: "pointer", borderRadius: "5px", marginBottom: "2px", height: "30px", fontFamily: "Verdana" }}>Print</p>
						</div>
					}
					content={() => componentRef.current}
				/>
			</div>
		</div>
	);
}
export default PatientTestResultPrint