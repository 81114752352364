import React, { useEffect, useState } from 'react';
import NoDataFound from '../Common/noPaymentFound';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import img1 from '../images/icons/treatment.png';
import Loader from '../Common/loader';
import Printer_icon from '../images/icons/printer_icon.png';
import Sms from '../images/icons/chat.png';
import { Link } from 'react-router-dom';
import { callingAPI } from '../config';
import sendEmail from '../images/icons/sendEmail.png';
import $ from 'jquery';
function PatientPayments() {
	$(document).ready(function () {
		$('#paymentAdd').addClass("testResultAdd");

	})
	const [getPaymentinvoiceReceiptListing, setPaymentinvoiceReceiptListing] = useState([]);
	const [getAdvanceAmount, setAdvanceAmount] = useState('');
	const [getDueAmount, setDueAmount] = useState('');
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [noData, setNoData] = useState(false);
	const [Dataflag, setFlag2] = useState(false);
	useEffect(() => {
		listing();
	}, []);

	const listing = () => {
		const apiJson = {
			hospitalId: localStorage.getItem("hospitalId"),
			memberId: window.sessionStorage.getItem('memberId'),
			userId: window.sessionStorage.getItem('userId'),
		};
		callingAPI('payment/invoiceReceiptListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setPaymentinvoiceReceiptListing(data.data.result[0].finalArray);
				setAdvanceAmount(data.data.result[0].advanceAmount);
				setDueAmount(data.data.result[0].dueAmount);
				setFlag2(true);
				//setLoaderFlag(true)
			}
		});
	};
	const setReceiptRecptno = (htmlData) => {
		if (htmlData.recieptList.isTowards == 1) {
			var typePay = "Payment"
			var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/${typePay + '/' + htmlData.recieptList.memberProcedurePaymentReceiptId + '|' + window.sessionStorage.getItem('patientDoctorId') + '|' + localStorage.getItem('hospitalId')}`

			window.open(a, "_blank")

		}
		else if (htmlData.recieptList.isTowards == 0 && htmlData.recieptList.memberProcedurePaymentReceiptIsRefund == 1) {
			var typeRefund = "Refund"
			//window.sessionStorage.setItem("htmlData", htmlData.recieptList.memberProcedurePaymentReceiptId)
			window.open(`${process.env.PUBLIC_URL}/patients/RecieptPrint/${typeRefund + '/' + htmlData.recieptList.memberProcedurePaymentReceiptId + '|' + window.sessionStorage.getItem('patientDoctorId') + '|' + localStorage.getItem('hospitalId')}`, "_blank")

		}

		else {
			var typeAdvance = "Advance"
			//window.sessionStorage.setItem("htmlData", htmlData.recieptList.memberProcedurePaymentReceiptId)
			window.open(`${process.env.PUBLIC_URL}/patients/RecieptPrint/${typeAdvance + '/' + htmlData.recieptList.memberProcedurePaymentReceiptId + '|' + window.sessionStorage.getItem('patientDoctorId') + '|' + localStorage.getItem('hospitalId')}`, "_blank")

		}
	}

	const sendSms = (memberProcedureInvoiceId, mode) => {
		var typeOfPayment;
		if (mode.recieptList.isTowards == 1) {
			typeOfPayment = "Payments"
		}
		else if (mode.recieptList.isTowards == 0 && mode.recieptList.memberProcedurePaymentReceiptIsRefund == 1) {
			typeOfPayment = "Refund"
		}
		else {
			typeOfPayment = "Advance"
		}
		if (window.sessionStorage.getItem("PATIENTMOB") === "") {
			alert("Please Add a Valid MobileNumber")
		}
		else {
			const postData = {

				"userId": window.sessionStorage.getItem('userId'),
				"invoiceId": memberProcedureInvoiceId,
				"mode": typeOfPayment,
				"hospitalId": localStorage.getItem('hospitalId'),
				"siteId": window.sessionStorage.getItem("siteId")
			}
			callingAPI('commonUserFunctions/sendTreatmentPlanSMSToUser', postData).then((data) => {
				if (data.data.success === '1') {
					if (data.data.result !== "") {
						const PostData = {
							functionName: "createAppointmentMail",
							siteId: window.sessionStorage.getItem("siteId"),
							smsObj: {
								mobileNumber: data.data.result.smsObj.mobileNumber,
								message: data.data.result.smsObj.message,
								userId: data.data.result.smsObj.userId,
								siteId: data.data.result.smsObj.siteId,
								hospitalId: data.data.result.smsObj.hospitalId,
								smsType: data.data.result.smsObj.smsType,
								smsTemplateId: data.data.result.smsObj.smsTemplateId
							},
						};
						callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
							if (response.data.success === "0") {
								alert(response.data.errorMessage)
								// alert("success---0000")
								// var popup = document.getElementById("myPopupSmsError1"+memberProcedureInvoiceId);

								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)
							} else if (response.data.success === "1") {
								alert("SMS sent Successfully")
								//  alert("success---111")
								// var popup = document.getElementById("myPopupSms1"+memberProcedureInvoiceId);

								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// alert("888")
								// 	setTimeout(() => popup.classList.remove("show"), 3000)
								//window.location.reload()
							}
						})
							.catch();
					}

				} else {
					// alert("Invalid Mobile number")
					alert(data.data.errorMessage)
				}
			});
		}

	}
	const sendMail = (memberProcedureInvoiceId, mode) => {
		var typeOfPay;
		if (mode.recieptList.isTowards == 1) {
			typeOfPay = "Payments"
		}
		else if (mode.recieptList.isTowards == 0 && mode.recieptList.memberProcedurePaymentReceiptIsRefund == 1) {
			typeOfPay = "Refund"
		}
		else {
			typeOfPay = "Advance"
		}
		if (window.sessionStorage.getItem("PATIENTEMAIL") === "") {
			alert("Please Add a Valid EmailId")
		}

		else {
			const postData = {

				"userId": window.sessionStorage.getItem('userId'),
				"invoiceId": memberProcedureInvoiceId,
				"mode": typeOfPay,
				"hospitalId": localStorage.getItem('hospitalId'),
				"siteId": window.sessionStorage.getItem("siteId")
			}
			callingAPI('commonUserFunctions/sendTreatmentPlanMailToUser', postData).then((data) => {
				if (data.data.success === '1') {
					if (data.data.result !== "") {
						const PostData = {
							jsonMailOptions: {
								to: data.data.result.jsonMailOptions.to,
								AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
								SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
								subject: data.data.result.jsonMailOptions.subject,
								html: data.data.result.jsonMailOptions.html,
								bccEmailRequired: data.data.result.jsonMailOptions.bccEmailRequired,
								ccEmailRequired: data.data.result.jsonMailOptions.ccEmailRequired
							},
						};
						callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
							if (response.data.success === "0") {
								//alert("Something went wrong")
								// var popup = document.getElementById("myPopupError");
								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)
							}
							else if (response.data.success === "1") {
								alert("Email sent Successfully")
								// var popup = document.getElementById("myPopup");
								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)

								//window.location.reload()
							}
						})
							.catch();
					} else {
						//alert("Invalid Email id")
						alert("Something went wrong")
					}
				}
			});
		}




	}
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					{/* {loaderFlag === false ? <Loader /> : ''} */}
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">

							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{/* {loaderFlag === true ? */}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">

												<span class="treatmentPlanName">
													PAYMENTS{' '}
													{getAdvanceAmount !== "0.00" ?
														<span className="treatmentHeadDue">
															Advance (INR) : <span style={{ color: '#008000' }}>{getAdvanceAmount}</span>
														</span>
														: ""}
													{getDueAmount !== "0.00" ?
														<span className="treatmentHeadDue">
															Due (INR) : <span style={{ color: '#f00' }}>{getDueAmount}</span>
														</span>
														: ""}
												</span>


												<span class="treatmentPlanBtn pl_blue_btn">
													<a href="/patients/addPayments">+ Add</a>
												</span>
												<span class="treatmentPlanBtn pl_blue_btn">
													<a href="/patients/addPaymentsRefund">Refund</a>
												</span>
											</div>
											{getPaymentinvoiceReceiptListing.length > 0 ?
												<div>
													{getPaymentinvoiceReceiptListing.map((listObj, i) => (

														<div class="treatmentPlanList1" style={{ height: 'auto' }}>
															{listObj.main.length > 0 ?
																<div class="treatmentPlanDate">
																	{listObj.memberProcedurePaymentReceiptDate}
																</div>
																: ''}


															{/* ===========  UPDATED TABLE DESIGN BEGINS HERE ==================== */}
															<div class="treatmentTable planActive invoiceInnerTableBr">
																{listObj.main.map((itemObj, j) => (

																	<table>
																		<thead>
																			<tr style={{ borderBottom: '1px solid #dedede' }}>
																				<th class="col-md-2">
																					<div class="dentalProName">RECEIPT NUMBER</div>
																				</th>
																				<th colspan="4" class="col-md-8">
																					<div class="dentalProCost invoiceInnerTable">
																						<table>
																							<thead>
																								<tr style={{ border: 'none' }}>
																									<th class="col-md-2">
																										<div class="dentalProName txtRight">
																											AMOUNT PAID
																										</div>
																									</th>
																									<th class="col-md-2">
																										<div class="dentalProDis txtLeft">
																											INVOICES NUMBER
																										</div>
																									</th>
																									<th
																										class="col-md-4"
																										style={{
																											padding: '0',
																											textAlign: 'right',
																										}}
																									>
																										<div
																											class="dentalProDis "
																											style={{
																												textAlign: 'right',
																											}}
																										>
																											TOWARDS
																										</div>
																									</th>
																									<th class="col-md-2">
																										<div class="dentalProTotal txtLeft">
																											MODE OF PAYMENT
																										</div>
																									</th>
																								</tr>
																							</thead>
																						</table>
																					</div>
																				</th>
																				<th class="col-md-1">
																					<div
																						class="dentalProNote"
																						style={{ right: '0px' }}
																					>
																						<div class="dentalProNote">
																							<span class="elipsis" title="">
																								&nbsp;
																							</span>
																							<button className="pl_print">
																								<Link to="#"
																									onClick={() => setReceiptRecptno(itemObj)}
																								>
																									<img src={Printer_icon} />
																								</Link>
																							</button>
																							<label class="dropdown">
																								<div class="dd-button" />

																								<input
																									type="checkbox"
																									class="dd-input"
																									id="test"
																								/>

																								<ul class="dd-menu">
																									<li onClick={() => sendSms(itemObj.recieptList.memberProcedurePaymentReceiptId, itemObj)}>
																										<img src={Sms} style={{ width: '15px', marginRight: '5px' }} />Send
																										SMS
																										<span class="popuptext" id={"myPopupSms1" + itemObj.recieptList.memberProcedurePaymentReceiptId}>SMS sent Successfully</span>
																										<span class="popuptext" style={{ display: 'none' }} id={"myPopupSmsError1" + itemObj.recieptList.memberProcedurePaymentReceiptId}>Something went wrong</span>
																									</li>
																									<li onClick={() => sendMail(itemObj.recieptList.memberProcedurePaymentReceiptId, itemObj)}>
																										<img src={sendEmail} style={{ width: '15px', marginRight: '5px' }} />Send
																										Email
																										<span class="popuptext" id="myPopupError">Something went wrong</span>
																										<span class="popuptext" id="myPopup">Email sent Successfully</span>
																									</li>
																								</ul>
																							</label>
																						</div>
																					</div>
																				</th>
																			</tr>
																		</thead>

																		<tbody>
																			<tr class="groupBrdr">
																				<td
																					class="col-md-2"
																					style={{ position: 'relative' }}
																				>
																					<div class="dentalProName dentalProNameTop" style={{ padding: '7px 3px' }}>
																						<span class="elipsis">{
																							itemObj.memberProcedurePaymentReceiptNumber
																						}</span>
																					</div>
																				</td>
																				<td colspan="4">
																					<table style={{ border: '0' }}>
																						<tbody>
																							<tr
																								style={{
																									border: '0',
																									verticalAlign: 'top',
																								}}
																							>
																								<td class="col-md-2">
																									<div class="dentalProName dentalProNameTop txtRight " style={{ color: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "#f00" : "#000", marginTop: '6px' }}>
																										{itemObj.recieptList.finalAmount}
																									</div>
																								</td>
																								<td
																									colspan="3"
																									class="col-md-6"
																									style={{ padding: '0' }}
																								>



																									<div class="dentalProCost invoiceInnerTable invoiceInnerTableBr">
																										{/* <p style={{ color: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "#f00" : "#000", display: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "block" : "none" }}>
																												Refund
																											</p> */}
																										<p style={{ display: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 0 ? "block" : "none", padding: '3px 5px' }}>
																											Advance
																										</p>
																										{(itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund) == 1 ?
																											itemObj.item.length > 0 ?
																												itemObj.item.map((itemObj1, k) => (
																													<table>
																														<tbody>
																															<tr>
																																<td style={{ width: '100px', float: 'left' }} >
																																	<div class="dentalProName txtLeft" style={{ padding: '7px 3px' }}>
																																		{itemObj1.memberProcedureInvoiceNumber}
																																	</div>
																																</td>
																																<td

																																	style={{
																																		padding:
																																			'0', width: '234px', float: 'left', textAlign: "right!important"
																																	}}
																																>
																																	<p style={{ color: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "#f00" : "#000", display: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "block" : "none", textAlign: "right" }}>
																																		Refund
																																	</p>
																																</td>
																															</tr>
																														</tbody>
																													</table>
																												))
																												:
																												<p style={{ color: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "#f00" : "#000", display: itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1 ? "block" : "none", textAlign: "right" }}>
																													Refund
																												</p>
																											:

																											itemObj.item.map((itemObj1, k) => (
																												<table>

																													<tbody>

																														<tr>
																															<td style={{ width: '100px', float: 'left' }} >
																																{/* class="col-md-2" */}
																																<div class="dentalProName txtLeft" style={{ padding: '7px 3px' }}>
																																	{itemObj1.memberProcedureInvoiceNumber}
																																</div>
																															</td>

																															<td

																																style={{
																																	padding:
																																		'0', width: '237px', float: 'left'
																																}}
																															>
																																{/* class="col-md-4" */}

																																{/* {(() => {
																																	if (itemObj.recieptList.isTowards == 1) {
																																		return ( */}

																																<div
																																	class="dentalProDis treatmentTxtPayment zzzzzzzzzzzzzzzzzzz"
																																	style={{
																																		textAlign:
																																			'right!important', padding: '7px 3px', paddingBottom: '0px'
																																	}}
																																>

																																	{(() => {
																																		if (itemObj.item.length == 0) {
																																			return (
																																				<p>

																																				</p>
																																			)
																																		}
																																		else {
																																			return (
																																				<>
																																					{itemObj1.procedureCode !== "" ?
																																						<p style={{ paddingBottom: '3px', marginBottom: '3px' }}>
																																							<span style={{ fontSize: '11px' }} ><b>{itemObj1.procedureCode !== "" ? "Procedure - " : ''}</b>{itemObj1.procedureCode !== "" ? itemObj1.procedureCode.replaceAll('()', '') : ''}</span><br />
																																						</p>
																																						: ""}
																																					{itemObj1.prescriptionName !== "" ?
																																						<p style={{ paddingBottom: '3px', marginBottom: '3px' }}>
																																							<span style={{ fontSize: '11px' }}><b>{itemObj1.prescriptionName !== "" ? "Prescription - " : ''}</b>{itemObj1.prescriptionName !== "" ? itemObj1.prescriptionName : ''}</span><br />
																																						</p>
																																						: ""}
																																					{itemObj1.labName !== "" ?
																																						<p style={{ paddingBottom: '3px', marginBottom: '3px' }}>
																																							<span style={{ fontSize: '11px' }}><b>{itemObj1.labName !== "" ? "Lab - " : ''}</b>{itemObj1.labName !== "" ? itemObj1.labName : ''}</span><br />
																																						</p>
																																						: ""}
																																					{itemObj1.inventoryName !== "" ?
																																						<p style={{ paddingBottom: '3px', marginBottom: '3px' }}>
																																							<span style={{ fontSize: '11px' }}><b>{itemObj1.inventoryName !== "" ? "Disposables - " : ''}</b>{itemObj1.inventoryName !== "" ? itemObj1.inventoryName : ''}</span><br />
																																						</p>
																																						: ""}


																																				</>


																																			)
																																		}
																																	})()}
																																</div>

																																{/* )
																																	}
																																else if (itemObj.recieptList.isTowards == 0 && itemObj.recieptList.memberProcedurePaymentReceiptIsRefund == 1) {
																																	return (
																																		<div>
																																			<p style={{colur : "#f00"}}>Refund</p>
																																			</div>

																																	)
																																  }
																																   else {
																																		return (
																																			<div>Advance</div>

																																		)
																																	}
																																})()} */}


																															</td>
																														</tr>

																														<tr></tr>


																													</tbody>
																													{/* )
																													} */}
																												</table>
																											))
																										}
																									</div>

																								</td>
																								<td
																									class="col-md-2"
																									style={{ position: 'relative' }}
																								>
																									<div class="dentalProName dentalProNameTop" style={{ padding: '3px 0px' }}>
																										<span
																											class="elipsis"
																											style={{
																												color: '#000',
																											}}
																										>
																											{itemObj.recieptList.invoicePaymentMode}
																										</span>
																									</div>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</td>
																				<td class="col-md-1" />
																			</tr>

																			<tr
																				class="groupBrdr groupBg"
																				style={{ borderTop: '1px solid #D8D8D8' }}
																			>
																				<td colspan="6">
																					<div class="dentalProName">
																						<span class="paymentBy">
																							<b>Billed by :</b>{itemObj.recieptList.secUserDisplayName}
																						</span>
																					</div>
																				</td>
																			</tr>
																		</tbody>

																	</table>
																))}
															</div>
															{/* ============ UPDATED TABLE DESIGN ENDS HERE ====================  */}

														</div>
													))}
												</div>
												:
												// <NoDataFound />  
												// }
												getPaymentinvoiceReceiptListing == 0 && Dataflag == true && <NoDataFound />}

										</div>
										{/* : ""} */}
										{/* {getPaymentinvoiceReceiptListing == 0 && Dataflag == true && <NoDataFound />} */}

									</div>
								</div>
								{/*  */}
							</div>

							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default PatientPayments;
