import React, { useState } from 'react';
import purpleHealthLogo from '../images/media/purpleHealthLogo.png';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useIdleTimer } from 'react-idle-timer'
function Footer() {
	const [siteId, setsiteId] = useState(window.sessionStorage.getItem('siteId'));
	$(document).ready(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('#scroll').fadeIn();
			} else {
				$('#scroll').fadeOut();
			}
		});
		$('#scroll').click(function () {
			$('html, body').animate({ scrollTop: 0 }, 5);
			return false;
		});
	});


	const handleOnIdle = (event) => {
		window.sessionStorage.removeItem("actualUserId");
		window.sessionStorage.removeItem("doctorId");
		window.sessionStorage.removeItem("userId");
		localStorage.removeItem("hospitalId");
		localStorage.removeItem("doctorAppointmentCount");
		window.sessionStorage.removeItem("userName");
		window.sessionStorage.removeItem("isLoggedIn");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("clinicsHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		window.sessionStorage.removeItem("role");
		window.location.href = "/"



	}
	const [logoPath, setlogoPath] = useState(process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + "/" + process.env.REACT_APP_S3_LOGO_PATH + "/");
	const { getLastActiveTime } = useIdleTimer({
		timeout: 14400000,
		// timeout:10000,
		onIdle: handleOnIdle,
		debounce: 500,
	})
	return (
		(siteId !== null && siteId !== "null" && siteId !== "") ?
			<div>
				<div className="footer footer-padd">
					<div className="container">

						{window.sessionStorage.getItem("siteBranchId") === "4" ?
							<>
								<div className="footerida_wraper">
									<div className="pulseLogo">
										<img src={logoPath + window.sessionStorage.getItem('siteLogo')} class="  imageResponsive" />
									</div>
									<div className="footerContent text-center footerContent_padd">
										<h6>Contact</h6>
										<span>Phone : {window.sessionStorage.getItem('sitePhoneNumber')}</span>
										<span>Email : {window.sessionStorage.getItem('siteEmailId')}</span>
										<span>Support Timing : 9:30 AM - 5:30 PM(Mon-Fri)</span>
									</div>
									<div className="">
										<span class="pulseLogo">
											<a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
												<img src={purpleHealthLogo} class="footerpurple_logoimg  imageResponsive" />
											</a>
										</span>
									</div>
								</div>

							</>
							:
							<div className="footerContent">
								<h6>Contact</h6>
								<span>Phone : {window.sessionStorage.getItem('sitePhoneNumber')}</span>
								<span>Email : {window.sessionStorage.getItem('siteEmailId')}</span>
								<span>Support Timing : 9:30 AM - 5:30 PM(Mon-Fri)</span>
							</div>
						}
					</div>
					{window.sessionStorage.getItem("siteBranchId") === "4" ?
						<div className="footer_subwraper">
							<div className="container footer_subdflex">
								<span className='footer_subtext'><a href="/aboutus" className='footersubtextLink'>About</a></span>
								<span className='footer_subtext'><a href="/termsAndConditions" className='footersubtextLink'>Terms & Conditions</a></span>
								<div className='footer_subtext'><a href="/privacyPolicy" className='footersubtextLink'>Privacy Policy</a></div>
							</div>
						</div>
						: ""}
					<a href="#" id="scroll" style={{ display: 'none' }}>
						<FontAwesomeIcon icon={faAngleUp} className="footerfaAngleUp" />
					</a>
				</div>
			</div>
			:
			<div>
				<div className="footer">
					<div className="container">
						<img src={purpleHealthLogo} />
						<p>Powered by <a href="">PurpleHealth.com</a></p>
					</div>
					<a href="#" id="scroll" style={{ display: 'none' }}>
						<FontAwesomeIcon icon={faAngleUp} className="footerfaAngleUp" />
					</a>
				</div>
			</div>
	);
}

export default Footer;
