import React, { useState, useEffect } from 'react';
import Favicon from 'react-favicon';
import PoweredBy from '../images/media/powered-w.png'
function LoginHeader() {
	const [siteId, setsiteId] = useState(window.sessionStorage.getItem('siteId'));
	const [logoPath, setlogoPath] = useState(process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + "/" + process.env.REACT_APP_S3_LOGO_PATH + "/");
	const [showUserLink, setUserLink] = useState(false);
	const removeLoginSessions = () => {
		window.sessionStorage.removeItem('actualUserId');
		window.sessionStorage.removeItem('role');
		window.sessionStorage.removeItem('doctorId');
		window.sessionStorage.removeItem('userId');
		window.sessionStorage.removeItem('hospitalId');
		window.sessionStorage.removeItem('userName');
		window.sessionStorage.removeItem('isLoggedIn');
		window.sessionStorage.removeItem("isPrNumEnabled");
		window.location.href = '/';
	};
	const showUserLinks = () => {
		setUserLink(!showUserLink);
	};


	return (
		<>
			<div className="pulseHeader">
				{(siteId !== null && siteId !== "null" && siteId !== "") ?
					<Favicon url={logoPath + window.sessionStorage.getItem('siteFavIcon')} />
					:
					<Favicon url={logoPath + "fav_icon_pulse.ico"} />
				}
				<div class="container logo-wrapper">
					<span className='position-relative'>
						<span className="pulseLogo">
							<a href="/">
								{(siteId !== null && siteId !== "null" && siteId !== "") ?
									<img src={logoPath + window.sessionStorage.getItem('siteLogo')} class="  imageResponsive" />
									:
									<img src={logoPath + "purpleLogo.png"} class="  imageResponsive" />
								}
							</a>

						</span>
						{window.sessionStorage.getItem("siteBranchId") === "4" ?
							<div className="logo-powerdby">
								<a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
									<img src={PoweredBy} alt="Powered By PurpleHealth" />
								</a>
							</div>
							: ""}
					</span>
					{window.sessionStorage.getItem("siteBranchId") === "4" ?
						<span class="pulseLogo pulseLogoright">
							<a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
								<img src={logoPath + "purpleLogo.png"} class="  imageResponsive" />
							</a>
						</span>
						: ""}
					{window.sessionStorage.getItem('isLoggedIn') === "true" &&
						<div class="userCoverWrap">
							<span class="userClicks" onClick={() => showUserLinks()}>
								Hi 	{window.sessionStorage.getItem('userName')}<span class="downCaret" />
							</span>
							<div class="userSubDetail" style={{ display: showUserLink === false ? 'none' : 'block' }}>
								{/* <a href="/Dashboard/profile">My Profile</a> */}
								{(window.sessionStorage.getItem('role') != "ROLE_RECEPTION" &&
									window.sessionStorage.getItem('role') != "ROLE_NURSE") &&
									// <a href="/Dashboard/dashboardMain">My Dashboard</a>
									<a href="/Dashboard/dashboardView">My Dashboard</a>
								}
								<a href="#" onClick={() => removeLoginSessions()}>Sign Out</a>
							</div>

						</div>
					}
				</div>
			</div>
		</>
	)
}

export default LoginHeader
