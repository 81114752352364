import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
function GraphComponents(props) {
    useEffect(() => {

    }, [])


    const series = [
        {
            name: props.name,
            data: props.xArray
        }
    ];
    const options = {
        chart: {
            id: 'line-chart',
            height: 500,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: props.name,
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: props.yArray,
            type: 'text'
        }
    };

    return (
        <div className="treatmentProfileCover">
            <div className="treatmentPlanContent">

                <Chart
                    options={options}
                    series={series}
                    type="line"
                    width="600"
                />

            </div>
        </div>
    );
}
export default GraphComponents;
