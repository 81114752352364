import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import Home from '../images/icons/homeicon-healthcard.png';
import Calls from '../images/icons/calls.png';
import Phoneicon from '../images/icons/phoneicon.png';
import QRCode from 'qrcode.react';
import Web from '../images/icons/web.png';
import Webmail from '../images/icons/webmail.png';
import TopArrow from '../images/icons/topArrow.png';
import DownArrow from '../images/icons/downArrow.png';
import healthcard_user from '../images/media/healthcard_user.png';
import Print1 from '../images/media/print1.png';
import Print2 from '../images/media/print2.png';
import { callingAPI, callingAPI_GET } from '../config';
import Logo from '../images/avatarHospitalDefault.png';
import girl from '../images/media/noimageGirl.png';
import boy from '../images/media/noimageBoy.png';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import html2canvas from 'html2canvas';
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
function PatientHealthcard(props) {

	const [healthCardList, sethealthCardList] = useState([]);
	const [getMrNo, setMrNo] = useState(true);
	const [Mrnumber, setMrnumber] = useState(true);
	const [getPrno, setPrno] = useState('0');
	const [Prnumber, setPrnumber] = useState(true);
	const [isAddress, setIsAddress] = useState(false);
	const [noBackground, setnoBackground] = useState(false);
	const [firstname, setfirstname] = useState(false);
	const [lastname, setlastname] = useState(false);
	const [defaultstyle, setdefaultstyle] = useState(false);

	const [gendertrue, setgendertrue] = useState(false);
	const [isMobileNumber, setisMobileNumber] = useState(true);
	const [isGetDob, setisGetDob] = useState(true);
	const [PhoneNumber, setPhoneNumber] = useState(false);
	const [isEmail, setisEmail] = useState(true);
	const [islogo, setislogo] = useState(true);
	const [isBarcode, setBarcode] = useState(false);
	const [backSide, setbackSide] = useState(false);
	const [isPatientImage, setIsPatientImage] = useState(true);
	const [hospitalLogo, setHospitalLogo] = useState('');
	const [patientImage, setPatientImage] = useState('');
	const [instructionOne, setInstructionOne] = useState('');
	const [instructionTwo, setInstructionTwo] = useState('');
	const [instructionThree, setInstructionThree] = useState('');
	const [instructionFour, setInstructionFour] = useState('');
	const [isShowParentDoctor, setisShowParentDoctor] = useState(true)
	const [healthCardFormatTitleText, sethealthCardFormatTitleText] = useState("")
	const [patientDob, setPatientDob] = useState("");
  	const [age, setAge] = useState(null);
	const [load, setLoad] = useState(true)
	const [hospitalImagename, sethospitalImagename] = useState('');
	var QRCodeValue = firstname + " " + lastname + '\n' + Mrnumber + '\n' + isMobileNumber;

	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;

	var getS3folderHospitalLogo =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE
		+ process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;

	const printReceipt = () => {
		var printContents = document.getElementById('healthCardPrint').innerHTML;
		var originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
		window.location.reload();

	};

	const calculateAge = (dateOfBirth) => {
		const parts = dateOfBirth.split("/");
		const day = parseInt(parts[0], 10);
		const month = parseInt(parts[1] - 1, 10);
		const year = parseInt(parts[2], 10);
	  
		const dob = new Date(year, month, day);
		const currentDate = new Date();
	  
		const yearsDiff = currentDate.getFullYear() - dob.getFullYear();
		const monthsDiff = currentDate.getMonth() - dob.getMonth();
		const daysDiff = currentDate.getDate() - dob.getDate();
		if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
		  return yearsDiff - 1; 
		} else {
		  return yearsDiff; 
		}
	  }
	  
	
	const HealthCard = () => {
		setLoad(false)
		let PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			serviceProviderId: '',
			memberId: window.sessionStorage.getItem('memberId'),
			// 		   "hospitalId": "9373",
			// "serviceProviderId": "",
			// "memberId": "363745"
		};

		callingAPI('patientHealthCard', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setLoad(true)
					sethealthCardFormatTitleText(response.data.patientHealthCardList[0].healthCardFormatTitleText)
					setgendertrue(response.data.patientHealthCardList[0].healthCardFormatIsGender);
					setdefaultstyle(response.data.patientHealthCardList[0].healthCardFormatDefaultStyle);
					setnoBackground(response.data.patientHealthCardList[0].healthCardFormatHasBackground);
					setbackSide(response.data.patientHealthCardList[0].healthCardFormatBackSide);
					setIsAddress(response.data.patientHealthCardList[0].healthCardFormatIsAddress);
					setMrNo(response.data.patientHealthCardList[0].healthCardFormatIsMrNo);
					setPrno(response.data.patientHealthCardList[0].healthCardFormatIsPrNo);
					setPrnumber(response.data.patientHealthCardList[0].prNo);
					setisGetDob(response.data.patientHealthCardList[0].healthCardFormatIsDob);
					setisEmail(response.data.patientHealthCardList[0].healthCardFormatIsEmailId);
					setPhoneNumber(response.data.patientHealthCardList[0].healthCardFormatIsMobileNumber);
					setMrnumber(response.data.patientHealthCardList[0].mrNo);
					setBarcode(response.data.patientHealthCardList[0].healthCardFormatBarCode)
					setfirstname(response.data.patientHealthCardList[0].memberFirstName);
					setlastname(response.data.patientHealthCardList[0].memberLastName);
					setisMobileNumber(response.data.patientHealthCardList[0].secUserMobileNumber);

					sethealthCardList(response.data.patientHealthCardList);
					setislogo(response.data.patientHealthCardList[0].healthCardFormatClinicLogo);
					setisShowParentDoctor(response.data.patientHealthCardList[0].healthCardFormatShowParentDoctor)

					setPatientDob(response.data.patientHealthCardList[0].memberDateOfBirth)
					//setHospitalLogo(getS3folderHospitalLogo + '/' + response.data.patientHealthCardList[0].hospitalImagesLogoImage);
					// if (response.data.patientHealthCardList[0].hospitalImagesLogoImage !== '' || response.data.patientHealthCardList[0].hospitalImagesLogoImage !== null || response.data.patientHealthCardList[0].hospitalImagesLogoImage !== 'null') {
					// 	setHospitalLogo(
					// 		(getS3folderHospitalLogo + '/' + response.data.patientHealthCardList[0].hospitalImagesLogoImage)
					// 	);
					// } else {
					// 	setHospitalLogo(Logo);
					// }

					if (response.data.patientHealthCardList[0].memberImageName !== '' || response.data.patientHealthCardList[0].memberImageName !== null || response.data.patientHealthCardList[0].memberImageName !== "null") {
						setPatientImage(getS3folder + '/' + response.data.patientHealthCardList[0].memberImageName);
					} else {
						setPatientImage(boy);
					}


					var allInstructions = response.data.patientHealthCardList[0].healthCardFormatInstructionText;
					var instructions = allInstructions.split('@@');
					setInstructionOne(instructions[0]);
					setInstructionTwo(instructions[1]);
					setInstructionThree(instructions[2]);
					setInstructionFour(instructions[3]);
					if (response.data.patientHealthCardList[0].memberImageName !== '') {
						setPatientImage(getS3folder + '/' + response.data.patientHealthCardList[0].memberImageName);
					} else {
						if (response.data.patientHealthCardList[0].memberGender === 'Male') {
							setPatientImage(boy);
						} else {
							setPatientImage(girl);
						}
					}
					setIsPatientImage(response.data.patientHealthCardList[0].healthCardFormatPatientPhoto);
				}

				else if (response.data.success === '0') {
					setLoad(true)
					document.getElementById("getflag").style.display = "none"
					document.getElementById("setFlag").style.display = "block"
				}
				// else{
				// 	document.getElementById("getflag").style.display="block"
				// 	document.getElementById("setFlag").style.display="none"
				// }
			})
			.catch();
	};
	useEffect(() => {
		HealthCard();

		callingAPI('commonUserFunctions/headerFooterLogoPrints', {
			hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
			doctorId: ""
		}).then((res) => {

			sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
			//setMarginTop()
		});


	}, []);

	useEffect(() => {
		
		if (patientDob) {
			const calculatedAge = calculateAge(patientDob);
			setAge(calculatedAge);
		  }

	}, [patientDob]);
	return (
		<div className="coverWraper" id="HealthCardWrapper">
			<div id="HealthCardHeader">
				<Header />
			</div>
			<div className="contentWraper contentWraperScroll patientHealthcrd ptntHlthCrd">
				<div id="HealthCardLeftMenu">
					<Leftmenu />
				</div>
				<div className="rightSection">
					<div id="HealthCardProfiledetail">
						<Profiledetail />
					</div>
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection pl_addTreatSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div
												class="treatmentPlanHead"
												id="healthCardHead"
												style={{ border: 'none', borderBottom: "1px solid #cbc5d4", marginBottom: "15px" }}
											>
												<span class="treatmentPlanName" style={{ paddingLeft: '15px' }}>HEALTH CARD</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													{/* <img src={Youtube} /> */}
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													{/* <img src={Light} style={{ width: '25px' }} /> */}
												</span>
												{load == false ? <Loader /> : ''}
											</div>
											{(gendertrue == true || isGetDob == true || PhoneNumber == true || isEmail == true) && healthCardList != '' ? (
												<div id="getflag"
													class="treatmentPlanList1 pl_treatmentDiv pl_addTreatmnt"
													style={{ background: '#ececec' }}
												>
													<div class="pl_treat treatmentTable planActive" id="healthCardPrint" style={{position: 'relative',minHeight: '208px', overflow:'hidden',
													
												}}
													>
														{/* <hr id="hr" style={{ margin: '0px' }} /> */}
														{/*  */}
														<div class="" id="cardPrint" style={{ border: 'none',minHeight: '208px',position: 'absolute',top:'0px',
																left:'10px' 
															}}>
															<div id="printable-div" class="registrationCard  card_id_1 patientRegistrationCard" style={{
																width: '320px',
																height: 'auto',
																fontSize: '10px',
																lineHeight: '14px',
																margin: '0',
																background: '#fff',
																position: 'relative',
																
																// overflow:'hidden',
																// marginTop: '15px',
																//marginLeft: '15px',
																fontFamily: 'SourceSansPro-Regular',
																
																// display:"flex",
																// justifyContent:"start",
																// alignItems:"start",
																// alignContent:"start",
																// textAlign:"left"
															}}>
																{noBackground == true && defaultstyle &&
																	<div
																		class="registrationCardTop"
																		style={{
																			opacity: '1', height: '19px', background: "#eb1e25", color: "#fff", textAlign: "center",
																			padding: "3px 0", marginBottom: "10px", fontSize: "10px"
																		}}
																	>
																		<span>{healthCardFormatTitleText}</span>
																	</div>}
																{noBackground == true && !defaultstyle &&
																	<div
																		class="registrationCardTop"
																		style={{
																			opacity: '1', height: '53px', background: "#eb1e25", color: "#fff", textAlign: "center",
																			padding: "3px 0", marginBottom: "10px", fontSize: "10px"
																		}}
																	>
																		<span>{healthCardFormatTitleText}</span>
																	</div>}
																{healthCardList.map((data) => (
																	<div>
																		<div class="registrationCardContent" style={{ paddingBottom: "5px", paddingTop: "10px", width: "100%", float: "left", position: "relative"
																		// ,minHeight: "190px" 
																		}}>
																			<div
																				class="cardDetailLeft"
																				style={{
																					float: 'left', display: "inline-block",
																					width: "79%",
																					paddingTop: "66px"
																					// paddingTop: "20px"
																				}}
																			>

																				{window.sessionStorage.getItem('isPrNumEnabled') === "true" &&
																					(getPrno == '1' && data.prNo != "" ? (
																						<div class="cardDetailList " style={{ fontSize: "10px" }}>
																							<label style={{
																								margin: "0",
																								width: "27%",
																								float: "left",
																								fontFamily: "OpenSans-Bold",
																								fontSize: "13px",
																								lineHeight: "15px",
																								fontWeight: "600"
																							}}><b>PR No</b></label>
																							<span class="cardDetailListTxt" style={{
																								width: "73%",
																								float: "left"
																							}}>
																								<span style={{
																									float: "left",
																									width: "2%",
																									fontSize: "14px",
																									textTransform: "uppercase",
																									lineHeight: "15px"
																								}}>:</span>
																								<span class="cardDetailListTxtCont" style={{
																									fontSize: "14px",
																									width: "95%",
																									paddingLeft: "6px",
																									wordBreak: "break-word",
																									fontWeight: "600",
																									fontFamily: "OpenSans-Bold",
																									lineHeight:"15px"
																								}}>
																									<b>{data.prNo}</b>
																								</span>
																							</span>
																						</div>
																					) : (
																						<label></label>
																					))
																				}
																				{window.sessionStorage.getItem('isPrNumEnabled') === "false" &&
																					(getMrNo == true && data.mrNo != "" ? (
																						<div class="cardDetailList " style={{ fontSize: "10px" }}>
																							<label style={{
																								margin: "0",
																								width: "27%",
																								float: "left",
																								fontFamily: "OpenSans-Bold",
																								fontSize: "13px",
																								lineHeight: "15px",
																								fontWeight: "600"
																							}}><b>MR No</b></label>
																							<span class="cardDetailListTxt" style={{
																								width: "73%",
																								float: "left"
																							}}>
																								<span style={{
																									float: "left",
																									width: "2%",
																									fontSize: "14x",
																									textTransform: "uppercase",
																									lineHeight: "15px"
																								}}>:</span>
																								<span class="cardDetailListTxtCont" style={{
																									fontSize: "14px",
																									width: "95%",
																									paddingLeft: "6px",
																									wordBreak: "break-word",
																									fontWeight: "600",
																									fontFamily: "OpenSans-Bold",
																									lineHeight:"15px"
																								}}>
																									<b>{data.mrNo}</b>
																								</span>
																							</span>
																						</div>
																					) : (
																						<label></label>
																					))
																				}
																				<div class="cardDetailList" style={{ fontSize: "10px" }}>
																					<label style={{
																						margin: "0",
																						width: "27%",
																						float: "left",
																						fontFamily: "OpenSans-Bold",
																						fontSize: "13px",
																						lineHeight: "14px",
																						fontWeight: "600"
																					}}><b>Name</b> </label>
																					<span class="cardDetailListTxt" style={{
																						width: "73%",
																						float: "left"
																					}}>
																						<span style={{
																							float: "left",
																							width: "2%",
																							fontSize: "14px",
																							textTransform: "uppercase",
																							lineHeight: "14px"
																						}}> :</span>
																						<span class="cardDetailListTxtCont" style={{
																							fontSize: "12px",
																							width: "95%",
																							paddingLeft: "6px",
																							wordBreak: "break-word",
																							fontWeight: "600",
																							fontFamily: "OpenSans-Bold",
																							lineHeight:"15px"
																						}}>
																							<b>{data.memberFirstName}{' '}</b>
																							<b>{data.memberLastName}</b>
																						</span>
																					</span>
																				</div>
																				{isGetDob == true && data.memberDateOfBirth != "" ? (
																					<div class="cardDetailList " style={{ fontSize: "10px" }}>
																						<label style={{
																							margin: "0",
																							width: "27%",
																							float: "left",
																							fontFamily: "OpenSans-Bold",
																							fontSize: "13px",
																							lineHeight: "15px",
																							paddingTop:"3px",
																							fontWeight: "600"
																						}}>Sex/Age</label>
																						<span class="cardDetailListTxt" style={{
																							width: "73%",
																							float: "left"
																						}}>
																							<span style={{
																								float: "left",
																								width: "2%",
																								fontSize: "14px",
																								textTransform: "uppercase",
																								lineHeight: "15px",
																								paddingTop:"3px",
																							}}>:</span>
																							<span class="cardDetailListTxtCont" style={{
																								fontSize: "13px",
																								width: "95%",
																								paddingLeft: "6px",
																								wordBreak: "break-word",
																								fontWeight: "600",
																								fontFamily: "OpenSans-Bold",
																								lineHeight:"15px"
																							}}>
																								{/* {data.memberDateOfBirth}{' '} */}
																								
																								{/* &nbsp;&nbsp; */}

																								{gendertrue == true && data.memberGender != "" ? (
																									<span class="cardDetailListTxtCont1" style={{ width: "auto", float: "revert" }}>
																										{(data.memberGender === "male"||data.memberGender === "Male"||data.memberGender === "MALE"||data.memberGender ==="M") ? "M" : "F"}

																									</span>) : ""}/{age}Y

																							</span>
																						</span>
																					</div>
																				) : (
																					<label />
																				)}
																				{isGetDob == false && gendertrue == true ? (
																					<div class="cardDetailList " style={{ fontSize: "10px" }}>
																						<label style={{
																							margin: "0",
																							width: "27%",
																							float: "left",
																							fontFamily: "OpenSans-Bold",
																							fontSize: "13px",
																							lineHeight: "15px",
																							fontWeight: "600"
																						}}>Gender</label>
																						<span class="cardDetailListTxt" style={{
																							width: "73%",
																							float: "left"
																						}}>
																							<span style={{
																								float: "left",
																								width: "2%",
																								fontSize: "14px",
																								textTransform: "uppercase",
																								lineHeight: "15px"
																							}}>:</span>
																							<span class="cardDetailListTxtCont" style={{
																								fontSize: "13px",
																								width: "95%",
																								paddingLeft: "6px",
																								wordBreak: "break-word",
																								fontWeight: "600",
																								fontFamily: "OpenSans-Bold",
																								lineHeight:"15px"
																							}}>
																								{data.memberGender}{' '}
																								&nbsp;&nbsp;
																							</span>
																						</span>
																					</div>
																				) : <label></label>}
																				{isShowParentDoctor == true && data.parentdoctorname != "" ? (

																					<div class="cardDetailList " style={{ fontSize: "10px" }}>
																						<label style={{
																							margin: "0",
																							width: "27%",
																							float: "left",
																							fontFamily: "OpenSans-Bold",
																							fontSize: "13px",
																							lineHeight: "15px",
																							fontWeight: "600"
																						}}>Doctor</label>
																						<span class="cardDetailListTxt" style={{
																							width: "73%",
																							float: "left"
																						}}>
																							<span style={{
																								float: "left",
																								width: "2%",
																								fontSize: "14px",
																								textTransform: "uppercase",
																								lineHeight: "15px"
																							}}>:</span>
																							<span class="cardDetailListTxtCont" style={{
																								fontSize: "13px",
																								width: "95%",
																								paddingLeft: "6px",
																								wordBreak: "break-word",
																								fontWeight: "600",
																								fontFamily: "OpenSans-Bold",
																								lineHeight:"15px"
																							}}>
																								{data.parentdoctorname}{' '}
																								&nbsp;&nbsp;
																							</span>
																						</span>
																					</div>
																				) : (
																					<label></label>
																				)}
																				{isEmail == true && data.secUserEmailId != "" ? (
																					<div class="cardDetailList " style={{ fontSize: "10px" }}>
																						<label style={{
																							margin: "0",
																							width: "27%",
																							float: "left",
																							fontFamily: "OpenSans-Bold",
																							fontSize: "13px",
																							lineHeight: "15px",
																							fontWeight: "600"
																						}}>Email</label>
																						<span class="cardDetailListTxt" style={{
																							width: "73%",
																							float: "left"
																						}}>
																							<span style={{
																								float: "left",
																								width: "2%",
																								fontSize: "14px",
																								textTransform: "uppercase",
																								lineHeight: "15px"
																							}}>:</span>
																							<span class="cardDetailListTxtCont" style={{
																								fontSize: "13px",
																								width: "95%",
																								paddingLeft: "6px",
																								wordBreak: "break-word",
																								fontWeight: "600",
																								fontFamily: "OpenSans-Bold",
																								lineHeight:"15px"
																							}} >
																								{data.secUserEmailId}{' '}

																							</span>
																						</span>
																					</div>
																				) : (
																					<label></label>
																				)}
																				{PhoneNumber == true && data.secUserMobileNumber != "" ?
																					<div class="cardDetailList " style={{ fontSize: "10px" }}>
																						<label style={{
																							margin: "0",
																							width: "27%",
																							float: "left",
																							fontFamily: "OpenSans-Bold",
																							fontSize: "13px",
																							lineHeight: "15px",
																							fontWeight: "600"
																						}}>Mobile</label>
																						<span class="cardDetailListTxt" style={{
																							width: "73%",
																							float: "left"
																						}}>
																							<span style={{
																								float: "left",
																								width: "2%",
																								fontSize: "14px",
																								textTransform: "uppercase",
																								lineHeight: "15px"
																							}}>:</span>
																							<span class="cardDetailListTxtCont" style={{
																								fontSize: "13px",
																								width: "95%",
																								paddingLeft: "6px",
																								wordBreak: "break-word",
																								fontWeight: "600",
																								fontFamily: "OpenSans-Bold",
																								lineHeight:"15px"
																							}}>
																								{data.secUserMobileNumber}
																							</span>
																						</span>
																					</div> : <label></label>}
																				{isAddress == true && data.memberAddress != "" ? (
																					<div class="cardDetailList " style={{ fontSize: "10px" }}>
																						<label style={{
																							margin: "0",
																							width: "27%",
																							float: "left",
																							fontFamily: "OpenSans-Bold",
																							fontSize: "13px",
																							lineHeight: "15px",
																							fontWeight: "600"
																						}}>Address</label>
																						<span class="cardDetailListTxt" style={{
																							width: "73%",
																							float: "left"
																						}}>
																							<span style={{
																								float: "left",
																								width: "2%",
																								fontSize: "14px",
																								textTransform: "uppercase",
																								lineHeight: "15px"
																							}}>:</span>
																							<span class="cardDetailListTxtCont" style={{
																								paddingLeft: "6px", fontSize: "11px", lineHeight: "10.5px", paddingTop: "4px",
																								// fontSize: "16px",
																								width: "95%",
																								// paddingLeft: "6px",
																								wordBreak: "break-word",
																								fontWeight: "600",
																								fontFamily: "OpenSans-Bold",
																								textTransform:'capitalize'
																							}}>
																								{data.memberAddress}
																							</span>
																						</span>
																					</div>
																				) : (
																					<label></label>
																				)}
																			</div>
																			<div class="cardDetailRight" style={{
																				width: "60px",
																				float: "right",
																				padding: "5px 5px 5px 5px",
																				height: "155px",
																				height: "auto",
																			}}>

																				{islogo === true ? (
																					<div
																						class="cardDetailLogo"
																						style={{
																							float: 'left',
																							textAlign: "right",
																							// float: "right",
																							overflow: "hidden",
																							marginBottom: "5px",
																							position: "absolute",
																							right: "5px",
																							width: "40px",
																							top: "2px"
																						}}
																					>
																						{' '}
																						{hospitalImagename != (null || "") ?
																							<img

																								src={getS3folderHospitalLogo + '/' + localStorage.getItem("hospitalId") + ".png "}
																								style={{
																									float: 'right',
																									display: 'block',
																									width: '100%',
																								}}
																							/> :
																							<img
																								// src={hospitalLogo}
																								src={Logo}
																								style={{
																									float: 'right',
																									display: 'block',
																									width: '100%',
																								}}
																							/>}
																					</div>
																				) : (
																					<label></label>
																				)}

																				{isPatientImage == true ? (
																					<div class="cardDetailImg"
																						style={{
																							textAlign: "right",
																							float: "right",
																							overflow: "hidden",
																							marginBottom: "5px",
																							objectFit: "contain",
																							height: "40px",
																							width: "40px",
																							objectPosition: "center",
																							display: "flex",
																							alignAtems: "center",
																							position: "absolute",
																							top: "45px",
																							right: "5px",
																						}}
																					>
																						<img
																							src={patientImage}
																							style={{
																								display: 'block',
																								width: '100%',
																							}}
																						/>
																					</div>
																				) : (
																					<label>

																					</label>
																				)}
																				{isBarcode == true ? (
																					<div
																						class="cardDetailLogo cardDetailbarCode"
																						style={{
																							float: 'left',
																							// float: 'left' ,
																							textAlign: "right",
																							// float: "right",
																							overflow: "hidden",
																							marginBottom: "5px",
																							position: "absolute",
																							right: "5px",
																							width: "40px",
																							// top: "2px"
																							bottom: "-10px",
																							top: "unset",
																						}}
																					>
																						<div>
																							<QRCode
																								value={QRCodeValue}
																								style={{
																									// marginRight: '12px',
																									height: '23px',
																									width: '28px',
																									float: 'right',
																									display: 'block',
																									marginTop: '48px'
																								}}
																							/>
																						</div>
																					</div>
																				) : (
																					<label></label>
																				)}
																			</div>
																		</div>
																		{noBackground == true &&
																			<div class="registrationCardBtm" style={{ padding: "0 0px" }}>
																				<div class="registrationCardAddr"
																					style={{
																						color: "#fff",
																						padding: "0px",
																						margin: "20px 0 0",
																						float: "left",
																						width: "100%",
																						lineHeight: "10px",
																						background: "#008f73",
																						borderBottom: "2px solid #a7cb37",
																						clear: "both",
																						overflow: "hidden",
																						height: "26px",
																						fontSize: "10px",
																					}}>
																					<span class="rgstrnAddrsIcon"
																						style={{
																							float: "left",
																							textAlign: "center",
																							color: "#fff",
																							background: "#02795b",
																							fontSize: "12px",
																							clear: "both",
																							overflow: "hidden",
																							height: "auto",
																							color: "#fff",
																							padding: "7px",
																							lineHeight: "0px",
																							width: "20px",
																							marginRight: "5px"
																						}}
																					>
																						{/* <i class="fa fa-home" aria-hidden="true"></i> */}
																						<img src={Home} style={{ width: "10px" }} />
																					</span>
																					{data.hospitalAddress != "" ? (
																						<span class="rgstrnAddrs"
																							style={{
																								fontSize: " 7px",
																								color: "#fff",
																								float: "left",
																								padding: "3px 0 2px 5px",
																								padding: "1px 0 2px 5px",
																								width: "90%",
																								lineHeight: "9px"
																							}}>
																							{data.hospitalName}  {data.hospitalAddress}
																						</span>
																					) : ""}

																				</div>
																				{defaultstyle == true &&
																					<div class="registrationCardContact" style={{
																						clear: "both",
																						overflow: "hidden",
																						padding: '2px 8px',
																						fontSize: "9px",
																						background: '#01609a',
																					}}>
																						{data.hospitalPhone && (
																							<div class="cardContactList"
																								style={{
																									width: "50%",
																									float: "left",
																									color: "#fff",
																									marginBottom: "2px",
																								}}
																							>
																								<img src={Calls} style={{
																									width: "10px",
																									marginRight: "5px",
																								}} />
																								{data.hospitalPhone}
																							</div>
																						)}
																						{data.hospitalEmailId && (
																							<div class="cardContactList"
																								style={{
																									width: "50%",
																									float: "left",
																									color: "#fff",
																									marginBottom: "2px",
																								}}>
																								<img src={Webmail} style={{
																									width: "10px",
																									marginRight: "5px",
																								}} />
																								{data.hospitalEmailId}
																							</div>
																						)}
																						{data.hospitalAdminMobileNumber && (
																							<div class="cardContactList"
																								style={{
																									width: "50%",
																									float: "left",
																									color: "#fff",
																									marginBottom: "2px",
																								}}>
																								<img src={Phoneicon} style={{
																									width: "10px",
																									marginRight: "5px",
																								}} />
																								{data.hospitalAdminMobileNumber}
																							</div>
																						)}
																						{data.hospitalWebsiteUrl && (
																							<div class="cardContactList"
																								style={{
																									width: "50%",
																									float: "left",
																									color: "#fff",
																									marginBottom: "2px",
																								}}>
																								<img src={Web} style={{
																									width: "10px",
																									marginRight: "5px",
																								}} />
																								{data.hospitalWebsiteUrl}
																							</div>
																						)}
																					</div>}
																			</div>}
																		{backSide == true &&
																			<div className="instructionHealthCard" style={{ padding: "20px 30px" }}>
																				<span class="cardInstrnTitle"
																					style={{
																						display: "block",
																						textAlign: "center",
																						fontWeight: "600",
																						textTransform: "uppercase",
																						marginBottom: "10px",
																						fontSize: "10px"
																					}}
																				>Instruction</span>
																				<div className="IstrSec" style={{ paddingBottom: "40px" }}>
																					<ul style={{ padding: '0px' }}>
																						{instructionOne != '' ? (<li>{instructionOne}</li>) : ""}
																						{instructionTwo != '' ? (<li>{instructionTwo}</li>) : ""}
																						{instructionThree != '' ? (<li>{instructionThree}</li>) : ""}
																						{instructionFour != '' ? (<li>{instructionFour}</li>) : ""}
																						{/* <li>{instructionTwo}</li>
																				<li>{instructionThree}</li>
																				<li>{instructionFour}</li> */}
																					</ul>
																				</div>
																				<div class="signatureDivSec"
																					style={{
																						fontWeight: "600",
																						position: "absolute",
																						bottom: "10px",
																						right: "15px"
																					}}>
																					Authorised Signatory
																				</div>
																			</div>}
																	</div>
																))}
															</div>
														</div>


																		

														{/*  */}
													</div>

													<div class="CardPrint" id="cardPrintButton">
														<a
															// href="/patients/patientHealthcard"
															class="card_id_3"
															// target="#"
															onClick={printReceipt}
														>
															Print
														</a>
													</div>

													<div className="printSettings">
															<div className="text-danger" style={{fontSize: "18px",
																paddingTop: "14px",
																paddingLeft: "12px",
																fontWeight:"500"
															}}>Print Settings</div>
															<div className="" style={{display:"flex", justifyContent:"space-between",padding: "15px 100px"}}>
															<div className="">
																<img src={Print1} alt="Print Options" className='img-fluid' style={{height:"230px", width:"300px"}} />
															</div>
															<div className="">
																<img src={Print2} alt="Print Options" className='img-fluid' style={{height:"230px", width:"300px"}}/>
															</div>
															</div>
														</div>		
													
												</div>
											) :
												<div id="setFlag" className='registrationCardWrapNodataFound'>
													<span >
														<p style={{ marginBottom: "10px" }}>Health Card Settings not done for Hospital. <br />Please contact your Hospital Administrator.</p>
														<p style={{ marginBottom: "10px" }}><b>(Or)</b></p>
														<p>Do Health Card Settings your own.</p>
														<ol>
															<li>  Login as Hospital Admin.</li>
															<li>Click on the 'Settings' icon at the top right.</li>
															<li>Click 'Health Card' from Left Menu.</li>
															<li>Click on the required check box. (Selected attributes will be displayed on the Patient Health Card).</li>
															<li> Click Save.</li>
															<li>Log out and Login as doctor / Change role to Doctor.</li>
															<li>Check the Patient Health Card.</li>
														</ol>
													</span>
												</div>
											}
											{/* {healthCardList==''?(
											<div id="setFlag"  className='registrationCardWrapNodataFound'>
													<span >
														<p style={{marginBottom:"10px"}}>Health Card Settings not done for Hospital. <br/>Please contact your Hospital Administrator.</p>
													<p style={{marginBottom:"10px"}}><b>(Or)</b></p>
													<p>Do Health Card Settings your own.</p>
													<ol>
														<li>  Login as Hospital Admin.</li>
														<li>Click on the 'Settings' icon at the top right.</li>
														<li>Click 'Health Card' from Left Menu.</li>
														<li>Click on the required check box. (Selected attributes will be displayed on the Patient Health Card).</li>
														<li> Click Save.</li>
														<li>Log out and Login as doctor / Change role to Doctor.</li>
														<li>Check the Patient Health Card.</li>
													</ol>
													</span>
											</div>
	                                       ):""} */}
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection" id="HealthCardPatientright">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="healthCardFooter">
				<Footer />
			</div>
		</div >
	);
}
export default PatientHealthcard;
