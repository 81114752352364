import React, { useEffect } from 'react'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MicSelect = (props) => {
    useEffect(() => {
        if (props.language === "Malayalam") {
            document.getElementById("radio1" + props.id).checked = true;
        }
        else if (props.language === "English") {
            document.getElementById("radio2" + props.id).checked = true;
        }
        else if (props.language === "Tamil") {
            document.getElementById("radio3" + props.id).checked = true;
        }
        else if (props.language === "Hindi") {
            document.getElementById("radio4" + props.id).checked = true;
        }
    }, [])

    return (
        <>
            <div className="">
               
                <div className='d-flex speech-labl'>
                    <input
                        onChange={(e) => props.setLanguage(e.target.value)}
                        type="radio"
                        value="English"
                        id={"radio2" + props.id}
                        name={"languageType" + props.id}
                        className='mr-5 mt-0 speechinputSelect cursor-pointer'
                    />
                    <label for="radio2" />
                    English
                </div>
                <div className='d-flex speech-labl'>
                    <input
                        type="radio"
                        onChange={(e) => props.setLanguage(e.target.value)}
                        value="Hindi"
                        id={"radio4" + props.id}
                        name={"languageType" + props.id}
                        className='mr-5 mt-0 cursor-pointer'
                    />
                    <label for="radio1" />
                    Hindi
                </div>
            </div>

            <div className="">
            <div className='d-flex speech-labl'>
                    <input
                        type="radio"
                        onChange={(e) => props.setLanguage(e.target.value)}
                        value="Malayalam"
                        id={"radio1" + props.id}
                        name={"languageType" + props.id}
                        className='mr-5 mt-0 cursor-pointer'
                    />
                    <label for="radio1" />
                    Malayalam
                </div>
                <div className='d-flex speech-labl'>
                    <input
                        type="radio"
                        onChange={(e) => props.setLanguage(e.target.value)}
                        value="Tamil"
                        id={"radio3" + props.id}
                        name={"languageType" + props.id}
                        className='mr-5 mt-0 cursor-pointer'
                    />
                    <label for="radio1" />
                    Tamil
                </div>
              
            </div>
        </>
    )
}
export default MicSelect;