import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Profile from '../Patients/profile';
import Patientright from '../Patients/patientright';

// import { Route } from 'react-router-dom';
function patientconsult() {
	return (
		
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<Profile />
								{/* <Route path="/patients/profile" component={Profile} /> */}
							</div>
							<div className="col-lg-4 col-md-12 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default patientconsult;
