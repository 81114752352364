import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { callingAPI } from "../config";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
var moment = require('moment');

function AppointmentList() {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [appointmentListData, setAppointmentListData] = useState([])
  const downloadName = "appointmentListData";
  const [statusData, setStatusData] = useState("");
  const [filterName, setFilterName] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [startime, setStartime] = useState("");
  const [count, setCount] = useState("")
  const [dataFlag, setDataFlag] = useState(false)
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('patientDoctorId'));
  const [doctorName, setDoctor] = useState(window.sessionStorage.getItem('patientDoctorName'));
  const [doctorList, setDoctorList] = useState([]);
  const [getSelectDoctor, setSelectDoctor] = useState(
    window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
      ? window.sessionStorage.getItem('userName')
      : ''
  );

  const getAppointmentList = () => {
    let PostData = {
      doctorName: getSelectDoctor,
      hospitalId: localStorage.getItem('hospitalId'),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
      cmbSelect: filterName,
      cmbStatus: statusData,
      txtInput: searchValue,
      fromDate: "",
      toDate: ""
    };
    callingAPI("patientProfile/appointmentList", PostData).then((response) => {

      if (response.data.success === "1") {
        setAppointmentListData(response.data.result.appointmentDetails);
        setStartime(response.data.result.startime)
        setCount(response.data.result.count);
        setDataFlag(true);
      }
      else { setDataFlag(true); }
    });

  }

  useEffect(() => {
    getAppointmentList();

  }, []);

  //pagegination function
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }

    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      doctorName: getSelectDoctor,
      hospitalId: localStorage.getItem('hospitalId'),
      max: max,
      offset: offset,
      cmbSelect: filterName,
      cmbStatus: statusData,
      txtInput: searchValue,
      fromDate: fromDate ? formatDate(fromDate) : '',
      toDate: toDate ? formatDate(toDate) : ''
    };

    callingAPI("patientProfile/appointmentList", PostData).then((response) => {
      if (response.data.success === "1") {
        setAppointmentListData(response.data.result.appointmentDetails);
        setCount(response.data.result.count);
      }
    });
  };
  const doctorDropdown = () => {
    const apiJson = {
      hospitalId: localStorage.getItem("hospitalId"),
      selectCombo: "",
      inputSearch: document.getElementById('searchTextDr').value,
      max: 100,
      offset: 0,
    };
    document.getElementById("doctorListDiv").style.display = "block";
    callingAPI("commonUserFunctions/doctorsList", apiJson).then((data) => {
      if (document.getElementById('searchTextDr').value.length > 2) {
        callingAPI('commonUserFunctions/doctorsList', apiJson).then((data) => {
          if (data.data.success === '1') {
            setDoctorList(data.data.result.doctorsListArray);
          }
        });
      }
      else {
        document.getElementById('doctorListDiv').style.display = 'none'
        setDoctorList([])
        // alert("no doctors found")
      }
    });
  };

  const setDoctorName = (doctorObj, i) => {
    setSelectDoctor(doctorObj.doctorName)
    setDoctor(doctorObj.doctorName);
    setDoctorId(doctorObj.doctorId);
    document.getElementById('doctorListDiv').style.display = 'none';
  };

  const onBlurItemCategory = (e) => {
    if (!["react-datepicker__month-select", "react-datepicker__year-select"].includes(String(e.target.className))) {
      document.getElementById('doctorListDiv').style.display = 'none';
      setDoctorList([]);
    }
  };
  function formatDate(date) {
    var date = new Date(date),
      month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  //list function
  function patientListDownload() {
    if (appointmentListData.length === 0) {
      alert('No Data to Export');
    }
    else {
      const csvRow = [];
      var appointmentDetailsInfo = [
        [
          "Doctor%20Name",
          "Patient%20Name",
          "Appointment%20Date%20And%20Time",
          "Status",
        ],
      ];
      var name = appointmentDetailsInfo;
      let PostData = {
        doctorName: getSelectDoctor,
        hospitalId: localStorage.getItem('hospitalId'),
        max: "",
        offset: 0,
        cmbSelect: filterName,
        cmbStatus: statusData,
        txtInput: searchValue,
        fromDate: fromDate ? formatDate(fromDate) : '',
        toDate: toDate ? formatDate(toDate) : ''
      };
      callingAPI('patientProfile/appointmentList', PostData).then((response) => {
        // if (response.data.success === '0') {
        //   alert("No Data to Export")
        //   }
        if (response.data.success === '1') {
          for (var item = 0; item < response.data.result.appointmentDetails.length; item++) {
            let date = moment(response.data.result.appointmentDetails[item].appintmentDate).format(' DD MMM YYYY ')
            let time = moment(response.data.result.appointmentDetails[item].startime).format(' hh:mm a ')
            let dateTime = (date + "" + time)
            appointmentDetailsInfo.push([
              response.data.result.appointmentDetails[item].doctorname.replace(/ /g, '%20'),
              response.data.result.appointmentDetails[item].pateintname.replace(/,/g, '%20').replace(/ /g, '%20'),
              dateTime.replace(/ /g, '%20'),
              response.data.result.appointmentDetails[item].STATUS,

            ]);
          }
        }
        for (var i = 0; i < appointmentDetailsInfo.length; ++i) {
          csvRow.push(appointmentDetailsInfo[i].join(","));
        }
        var csvString = csvRow.join("%0A");
        var creat = document.createElement("a");
        creat.href = "data:attachment/csv," + csvString;
        creat.target = "_Blank";
        creat.download = downloadName + ".csv";
        document.body.appendChild(creat);
        creat.click();
      })
    }
  }

  //searchfunction
  const searchFunction = () => {
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("To date should be greater than or equal to from date");
        document.getElementById("todate").focus()
        return (false);
      }
    }
    if (filterName !== "" && searchValue === "") {
      var t = document.getElementById("selectFilterName");
      var selectedText = t.options[t.selectedIndex].text;
      document.getElementById("searchText").focus();
      alert("Enter" + " " + selectedText);
      return false;
    }
    let PostData = {
      doctorName: getSelectDoctor,
      hospitalId: localStorage.getItem('hospitalId'),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: 0,
      cmbSelect: filterName,
      cmbStatus: statusData,
      txtInput: searchValue,
      fromDate: fromDate ? formatDate(fromDate) : '',
      toDate: toDate ? formatDate(toDate) : '',
    };
    callingAPI('patientProfile/appointmentList', PostData)
      .then((response) => {
        if (response.data.success === '0') {
          setAppointmentListData([]);
          setCount(response.data.result.count);
          setActivePage(0)
        }
        if (response.data.success === '1') {
          setAppointmentListData(response.data.result.appointmentDetails);
          setActivePage(1)
          setCount(response.data.result.count);
          setDataFlag(true);
        }
      })
      .catch();

  };

  const changeStatus = (event) => {
    setStatusData(event.target.value);
  };
  //filter function
  const filterByPatient = (value) => {
    setsearchValue(value);
  };
  //change text
  const changefilterName = (value) => {
    setFilterName(value);
    setsearchValue("");
    if (value === "") {
      setsearchValue("");
      document.getElementById("searchText").disabled = true;
      document.getElementById("searchText").style.background = "transparent";
    } else {
      document.getElementById("searchText").disabled = false;
      document.getElementById("searchText").focus();
      document.getElementById("searchText").style.background = "white";
    }
  };
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchFunction();
    }
  };
  const FromDateClear = () => {
    setFromDate("")

  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>

        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  const ToDateClear = () => {
    setToDate("")
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander ToDateClearOpen">
            {children}
            <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <div className="dashboardTitle">Appointment List</div>
      {dataFlag == false ? (
        <div align="center">
          <Loader />
        </div>
      ) : (
        <div>
          {dataFlag === true && (
            <div className="dashboardContent">
              {count > 10 && (
                <div className="paginationSection">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                    totalItemsCount={count}
                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                    onChange={handlePageChange}
                    //hideNavigation
                    hideDisabled
                  />
                </div>
              )}
              <div className="reportWrap">
                <div className="dashboardSearch" onClick={onBlurItemCategory}>
                  <div className="row">
                    <div className="col-md-2 dashboardSearchList">
                      <input
                        type="text"
                        value={getSelectDoctor}
                        id="searchTextDr"
                        name="searchTextDr"
                        autoComplete="off"
                        class="ui-autocomplete-input inputField"
                        placeholder="Select Doctor"
                        onKeyUp={doctorDropdown}
                        onChange={(e) => setSelectDoctor(e.target.value)}
                      />
                      <div>
                        <ul
                          className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
                          id="doctorListDiv"
                          style={{ display: 'none' }}
                        >
                          {doctorList.map((doctorObj, i) => (
                            <ul>
                              <li onClick={(e) => setDoctorName(doctorObj, i)}>
                                <span id={'doctorNameLabel' + i}>
                                  {doctorObj.doctorName}
                                </span>
                              </li>
                            </ul>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      <select
                        className="inputField"
                        id="statusData"
                        name="statusData"
                        value={statusData}
                        onChange={changeStatus}
                        onKeyDown={onKeyDown}
                      >
                        <option value="">Status</option>
                        <option value="2">Confirm</option>
                        <option value="3">Arrived</option>
                        <option value="5">Admitted</option>
                        <option value="6">Completed</option>
                        <option value="7">Discharge</option>
                        <option value="8">Engaged</option>
                        <option value="9">Cancelled</option>
                        <option value="10">CALL COMPLETED-DOCTOR</option>
                      </select>
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      <select
                        className="inputField"
                        id="selectFilterName"
                        name="selectFilterName"
                        value={filterName}
                        onChange={(e) => changefilterName(e.target.value)}
                      >
                        <option value="">Search In</option>
                        <option value="1">Patient Name</option>

                      </select>
                    </div>
                    <div className="col-md-2 dashboardSearchList">

                      <input
                        type="text"
                        className="inputField"
                        id="searchText"
                        style={{ background: "transparent" }}
                        name="searchText"
                        value={searchValue}
                        onKeyDown={onKeyDown}
                        onChange={(e) => filterByPatient(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      {
                        <div>
                          <DatePicker
                            className="inputField"
                            autoComplete='off'
                            selected={fromDate}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="From"
                            id="fromdate"
                            onKeyDown={onKeyDown}
                            showMonthDropdown
                            showYearDropdown
                            readonly="readonly"
                            dropdownMode="select"
                            calendarContainer={MyContainer}
                            onChangeRaw={handleDateChangeRaw}
                            required
                            maxDate={new Date()}
                            onChange={(date) => setFromDate(date)}
                          />
                          <label
                            className="icon iconCalendar calendar plReportsCalendar"
                            style={{ fontSize: "16px" }}
                            htmlFor="fromdate"
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </label>
                        </div>
                      }
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      {
                        <div>
                          <DatePicker
                            className="inputField"
                            selected={toDate}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="To Date"
                            maxDate={new Date()}
                            id="todate"
                            showMonthDropdown
                            autoComplete='off'
                            showYearDropdown
                            dropdownMode="select"
                            calendarContainer={MyContainerTo}
                            onChangeRaw={handleDateChangeRaw}
                            onChange={(date) => setToDate(date)}
                            onKeyDown={onKeyDown}
                          />
                          <label
                            className="icon iconCalendar calendar plReportsCalendar"
                            style={{ fontSize: "16px" }}
                            htmlFor="todate"
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </label>
                        </div>
                      }
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      <span
                        className="dashButtonBorder"
                        onClick={searchFunction}
                        onKeyDown={onKeyDown}
                      >
                        Search
                      </span>
                    </div>

                    <div className="col-md-2 dashboardSearchList">
                      {/* {!appointmentListData.length ? (
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey 1"
                          onClick={noSearchData}
                        >
                          Export
                        </button>
                      ) : (
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          onClick={patientListDownload}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey 2"
                        >
                          Export
                        </button>
                       )}   */}
                      <button
                        type="button"
                        style={{ width: "100%" }}
                        onClick={patientListDownload}
                        id="export"
                        className="dashButtonBorder dashButtonBorderGrey 2"
                      >
                        Export
                      </button>
                    </div>


                  </div>
                  <div className="searchCount">Total Appointment : {count}</div>
                </div>
                <div className="dashboardtable">
                  <table>
                    {appointmentListData.length !== 0 && (
                      <thead>
                        <tr>
                          <th>Doctor Name</th>
                          <th >Patient Name</th>
                          <th>Appointment Date And Time</th>
                          <th >Status</th>

                        </tr>
                      </thead>
                    )}

                    <tbody>
                      {
                        appointmentListData.map((list, i) => (
                          <tr value={list.appointmentId} id={pageOffset}>
                            <td>{list.doctorname}</td>
                            <td>{list.pateintname}</td>
                            <td>
                              {moment(list.startime).format(' DD MMM YYYY ')},
                              {moment(list.startime).format(' hh:mm a ')}
                            </td>
                            <td>{list.STATUS}</td>
                          </tr>
                        ))}

                    </tbody>
                  </table>
                </div>
              </div>

              {count > 10 && (
                <div className="paginationSection">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                    totalItemsCount={count}
                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                    onChange={handlePageChange}
                    //hideNavigation
                    hideDisabled
                  />
                </div>
              )}
            </div>
          )}
          {appointmentListData.length == 0 && dataFlag == true && <NoData />}
        </div>
      )}
    </div>
  );
}
export default AppointmentList;
