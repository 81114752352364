import React from 'react'
import { Link, NavLink, Route } from "react-router-dom";
function communicationLeft() {
  return (
    <div className="settingMenu">
      <div className="settingLeftTitle">Communication</div>
      <div className="settingMenuList">
        <ul>
          <li className="settingListLi">
            <NavLink to="/communications/promotionalSMS" activeClassName='active'> Promotional SMS</NavLink>
          </li>
          <li className="settingListLi">
            <NavLink to="/communications/smsEmail" activeClassName='active'>SMS & Emails</NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default communicationLeft
