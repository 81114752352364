import React, { useEffect, useState } from "react";
import { callingAPI } from "../config";
import moment from "moment";
import { Send } from "@material-ui/icons";
import Loader from "../Common/loader";
function LabOrderOrthoCreation() {
  const [LabDetails, setLabDetails] = useState([]);
  const [labFormDetailsIdarray, setLabFormDetailsIdarray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [newDates, setNewDate] = useState(new Date());
  const [flag, setflag] = useState(false);
  const [Place, setPlace] = useState("");
  const [Address, setAddress] = useState("");
  const [age, setAge] = useState("");
  const [Appliance, setAppliance] = useState("");
  const [billing, setBilling] = useState("");
  const [clinic, setClinic] = useState("");
  const [due, setDue] = useState("");
  const [instruction, setInstruction] = useState("");
  const [Modification, setModification] = useState("");
  const [Patient, setPatient] = useState("");
  const [doctorDetails, setdoctorDetails] = useState("");
  const [Send, setSend] = useState("");
  const [saveflag, setSaveflag] = useState(false);
  var currentDate = new Date();
  const toDate = currentDate;
  var orderedDate = moment(newDates).format("YYYY-MM-DD");
  var date = moment(newDates).format("YYYY-MM-DD");
  var time = moment(currentDate).format("hh:mm A");
  var framedTrialDate = "" + date + "" + " " + time + "";
  var convertedDate = Date.parse(framedTrialDate);
  const printPage = () => {
    document.getElementById("cancel").style.display = "none";
    document.getElementById("print").style.display = "none";
    document.getElementById("send").style.display = "none";
    window.print();
    document.getElementById("cancel").style.display = "inline-block";
    document.getElementById("print").style.display = "inline-block";

    document.getElementById("send").style.display =
      flag == true ? "none" : "inline-block";
  };
  const checkedBox = (event, checkedId) => {
    if (event.target.checked == true) {
      labFormDetailsIdarray.push(checkedId);
      setLabFormDetailsIdarray(labFormDetailsIdarray);
    } else {
      const index = labFormDetailsIdarray.indexOf(checkedId);
      if (index > -1) {
        labFormDetailsIdarray.splice(index, 1);
        setLabFormDetailsIdarray(labFormDetailsIdarray);
      }
    }
  };
  const cancelButton = () => {
    window.top.close();
  };
  const orthoList = () => {
    setLoader(false);
    const orthoParams = {
      memberId: window.sessionStorage.getItem("memberId"),
      hospitalId: localStorage.getItem("hospitalId"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      serviceProviderLabFormId: window.sessionStorage.getItem("setlabFormsId"),
      userId: window.sessionStorage.getItem("userId"),
    };
    callingAPI("adminReports/dentalOrtho", orthoParams)
      .then((response) => {
        if (response.data.success === "1") {
          setLoader(true);
          setLabDetails(response.data.result.LabDetails);
        }
      })
      .catch();
  };
  const saveAndSendOutside = (serviceProviderId) => {
    const postData = {
      siteId: window.sessionStorage.getItem("siteId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendMail", postData).then(
      (data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: "",
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired: "",
              ccEmailRequired: "",
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                alert("Something went wrong");
              } else if (response.data.success === "1") {

                // window.location.href = "/Patients/labOrderDental";
              }
            })
            .catch();
        }
      }
    );
  };
  const saveOrtho = () => {
    setLoader(false);
    const saveParams = {
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      hospitalId: localStorage.getItem("hospitalId"),
      memberId: window.sessionStorage.getItem("memberId"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      userId: window.sessionStorage.getItem("userId"),
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      labTestRequestDetailsLabFormsId: "5",
      labTestRequestOrderNo: "12112",
      labTestRequestRequiredDate: moment(newDates).format("YYYY-MM-DD"),
      labTestRequestOrderDate: moment(newDates).format("YYYY-MM-DD"),
      savePrint: true,
      labFormDetailsId: labFormDetailsIdarray.toString(),
      drs: document.getElementById("drs").value,
      Clinic: document.getElementById("Clinic").value,
      Place: document.getElementById("Place").value,
      Patient: document.getElementById("Patient").value,
      Age: document.getElementById("Age").value,
      Appliance: document.getElementById("Appliance").value,
      Due: document.getElementById("Due").value,
      Billing: document.getElementById("Billing").value,
      Address: document.getElementById("Address").value,
      Modification: document.getElementById("Modification").value,
      Instruction: document.getElementById("Instruction").value,
      Send: document.getElementById("Send").value,
      appointmentDate: moment(currentDate).format("YYYY-MM-DD hh:mm A"),
      scheduledtimeSlotStartTime: Date.parse(currentDate),
      scheduledtimeSlotEndTime: Date.parse(currentDate) + 15 * 60 * 1000,
    };
    callingAPI("adminReports/saveFormOrtho", saveParams)
      .then((response) => {
        setLoader(true);
        if (response.data.success === "1") {

          saveAndSendOutside()
          alert("Lab Test save and send successfully");
          // window.location.href = "/Patients/labOrderDental";
        }
      })
      .catch();
  };
  const editingId = (event) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
    } else {
      setLoader(true);
      setflag(true);
      document.getElementById("send").style.display = "none";
      const postData = {
        doctorId: window.sessionStorage.getItem("patientDoctorId"),
        memberId: window.sessionStorage.getItem("memberId"),
        hospitalId: localStorage.getItem("hospitalId"),
        serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
        labRequestId: window.sessionStorage.getItem("labReqId"),
      };
      callingAPI("commonUserFunctions/listFormOrtho", postData)
        .then((data) => {
          if (data.data.success === "1") {
            setPlace(data.data.result.Place);
            setAddress(data.data.result.Address);
            setAge(data.data.result.Age);
            setAppliance(data.data.result.Appliance);
            setBilling(data.data.result.Billing);
            setClinic(data.data.result.Clinic);
            setDue(data.data.result.Due);
            setInstruction(data.data.result.Instruction);
            setModification(data.data.result.Modification);
            setPatient(data.data.result.Patient);
            setPlace(data.data.result.Place);
            setdoctorDetails(data.data.result.drs);
            setSend(data.data.result.Send);
            var labId = data.data.result.labFormDetailsId.toString().split(",");
            labId.map((labformId) => {
              if (labformId) {
                const checkbox = document.getElementById("teethTC" + labformId);
                checkbox.checked = "true";
                checkbox.disabled = "true";
              }
            });
          }
        })
        .catch();
    }
  };
  useEffect(
    () => {
      editingId();
      orthoList();
    },
    [],
    []
  );
  return (
    <div>
      <div style={{ width: "595px", margin: "0 auto" }}>
        <form>
          <div
            class="instructionFormWrap"
            style={{
              width: "660px",
              position: "relative",
              background: "#fff",
              Zindex: "1",
              margin: "auto",
              padding: "5px 0px",
              boxSizing: "border-box",
            }}
          >
            {loader == false ? <Loader /> : ""}
            {LabDetails.map((data) => (
              <div
                style={{ textAlign: "center", borderBottom: "1px solid #000" }}
              >
                <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                  {" "}
                  {data.name}
                </p>
                <p>
                  {data.address} , Phone - {data.phone}
                </p>
                <p />
              </div>
            ))}
            <div class="formSection">
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Dr</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        disabled
                        value={doctorDetails}
                        type="text"
                        id="drs"
                        name="drs"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Dr</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        type="text"
                        id="drs"
                        name="drs"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Clinic</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        disabled
                        value={clinic}
                        type="text"
                        id="Clinic"
                        name="Clinic"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Clinic</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        type="text"
                        id="Clinic"
                        name="Clinic"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Place</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        disabled
                        value={Place}
                        type="text"
                        id="Place"
                        name="Place"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Place</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        type="text"
                        id="Place"
                        name="Place"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Patient</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <div class="mediumField">
                      <span class="contentTxt">
                        <input
                          disabled
                          value={Patient}
                          type="text"
                          id="Patient"
                          name="Patient"
                          class="inputfield"
                        />
                      </span>
                    </div>
                    <div class="smallLabelTxt">Age/Sex</div>
                    <div class="smallField">
                      <span class="semiColan">:</span>
                      <span class="contentTxt">
                        <input
                          disabled
                          value={age}
                          type="text"
                          id="Age"
                          name="Age"
                          class="inputfield"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Patient</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <div class="mediumField">
                      <span class="contentTxt">
                        <input
                          type="text"
                          id="Patient"
                          name="Patient"
                          class="inputfield"
                        />
                      </span>
                    </div>
                    <div class="smallLabelTxt">Age/Sex</div>
                    <div class="smallField">
                      <span class="semiColan">:</span>
                      <span class="contentTxt">
                        <input
                          type="text"
                          id="Age"
                          name="Age"
                          class="inputfield"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Appliance</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        disabled
                        value={Appliance}
                        type="text"
                        id="Appliance"
                        name="Appliance"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Appliance</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        type="text"
                        id="Appliance"
                        name="Appliance"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Due Date</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <div class="upper">
                      <span class="contentTxt">
                        <input
                          disabled
                          value={due}
                          type="text"
                          id="Due"
                          name="Due"
                          class="inputfield"
                        />
                      </span>
                    </div>
                    <div class="labelTxt">Upper/Lower</div>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Due Date</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <div class="upper">
                      <span class="contentTxt">
                        <input
                          type="text"
                          id="Due"
                          name="Due"
                          class="inputfield"
                        />
                      </span>
                    </div>
                    <div class="labelTxt">Upper/Lower</div>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Billing Name</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        disabled
                        value={billing}
                        type="text"
                        id="Billing"
                        name="Billing"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Billing Name</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <span class="contentTxt">
                      <input
                        type="text"
                        id="Billing"
                        name="Billing"
                        class="inputfield"
                      />
                    </span>
                  </div>
                </div>
              )}
              {flag == true ? (
                <div class="listDiv">
                  <div class="labelTxt">Address</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <div class="addressField">
                      <span class="contentTxt">
                        <input
                          value={Address}
                          type="text"
                          id="Address"
                          name="Address"
                          class="inputfield"
                        />
                      </span>
                    </div>
                    <div class="extraDiv">Most Urgent 25% Extra</div>
                  </div>
                </div>
              ) : (
                <div class="listDiv">
                  <div class="labelTxt">Address</div>
                  <div class="contentDiv">
                    <span class="semiColan">:</span>
                    <div class="addressField">
                      <span class="contentTxt">
                        <input
                          type="text"
                          id="Address"
                          name="Address"
                          class="inputfield"
                        />
                      </span>
                    </div>
                    <div class="extraDiv">Most Urgent 25% Extra</div>
                  </div>
                </div>
              )}
            </div>
            <div class="applying">Appliance Style</div>
            <div class="checkFieldDiv">
              <div class="ckeckList">
                Removable
                <input
                  id="teethTC1"
                  type="checkbox"
                  name="txtService"
                  disabled={flag}
                  onChange={(event) => checkedBox(event, "1")}
                  value="297"
                />
              </div>
              <div class="ckeckList">
                Bondable
                <input
                  disabled={flag}
                  id="teethTC2"
                  onChange={(event) => checkedBox(event, "2")}
                  type="checkbox"
                  name="txtService"
                  value="298"
                />
              </div>
              <div class="ckeckList">
                Coloured
                <input
                  disabled={flag}
                  id="teethTC3"
                  onChange={(event) => checkedBox(event, "3")}
                  type="checkbox"
                  name="txtService"
                  value="299"
                />
              </div>
              <div class="ckeckList">
                Fixed
                <input
                  disabled={flag}
                  id="teethTC4"
                  onChange={(event) => checkedBox(event, "4")}
                  type="checkbox"
                  name="txtService"
                  value="300"
                />
              </div>
              <div class="ckeckList">
                Bandable
                <input
                  disabled={flag}
                  id="teethTC5"
                  onChange={(event) => checkedBox(event, "5")}
                  type="checkbox"
                  name="txtService"
                  value="301"
                />
              </div>
              <div class="ckeckList">
                Ligatable
                <input
                  disabled={flag}
                  id="teethTC6"
                  onChange={(event) => checkedBox(event, "6")}
                  type="checkbox"
                  name="txtService"
                  value="302"
                />
              </div>
              <div class="ckeckList ckeckListLarge">
                Pressure Moulded
                <input
                  disabled={flag}
                  id="teethTC7"
                  onChange={(event) => checkedBox(event, "7")}
                  type="checkbox"
                  name="txtService"
                  value="303"
                />
              </div>
            </div>
            <div class="applying">Colour Scheme</div>
            <div class="colourScheme">
              <div class="colourList">
                Red{" "}
                <input
                  disabled={flag}
                  id="teethTC8"
                  onChange={(event) => checkedBox(event, "8")}
                  type="checkbox"
                  name="txtService"
                  value="312"
                />
              </div>
              <div class="colourList">
                Blue{" "}
                <input
                  disabled={flag}
                  id="teethTC9"
                  onChange={(event) => checkedBox(event, "9")}
                  type="checkbox"
                  name="txtService"
                  value="313"
                />
              </div>
              <div class="colourList">
                Green{" "}
                <input
                  disabled={flag}
                  id="teethTC10"
                  onChange={(event) => checkedBox(event, "10")}
                  type="checkbox"
                  name="txtService"
                  value="314"
                />
              </div>
              <div class="colourList">
                Yellow{" "}
                <input
                  disabled={flag}
                  id="teethTC11"
                  onChange={(event) => checkedBox(event, "11")}
                  type="checkbox"
                  name="txtService"
                  value="315"
                />
              </div>
              <div class="colourList">
                Orange{" "}
                <input
                  disabled={flag}
                  id="teethTC12"
                  onChange={(event) => checkedBox(event, "12")}
                  type="checkbox"
                  name="txtService"
                  value="316"
                />
              </div>
              <div class="colourList borderRight">
                {" "}
                Purple
                <input
                  disabled={flag}
                  id="teethTC13"
                  onChange={(event) => checkedBox(event, "13")}
                  type="checkbox"
                  name="txtService"
                  value="317"
                />
              </div>
              <div class="colourList borderBottom">
                Black
                <input
                  disabled={flag}
                  id="teethTC14"
                  onChange={(event) => checkedBox(event, "14")}
                  type="checkbox"
                  name="txtService"
                  value="318"
                />
              </div>
              <div class="colourList borderBottom">
                Violet
                <input
                  disabled={flag}
                  id="teethTC15"
                  onChange={(event) => checkedBox(event, "15")}
                  type="checkbox"
                  name="txtService"
                  value="319"
                />
              </div>
              <div class="colourList borderBottom">
                Fl Green
                <input
                  disabled={flag}
                  id="teethTC16"
                  onChange={(event) => checkedBox(event, "16")}
                  type="checkbox"
                  name="txtService"
                  value="320"
                />
              </div>
              <div class="colourList borderBottom">
                Fl Yellow
                <input
                  disabled={flag}
                  id="teethTC17"
                  onChange={(event) => checkedBox(event, "17")}
                  type="checkbox"
                  name="txtService"
                  value="321"
                />
              </div>
              <div class="colourList borderBottom">
                Red
                <input
                  disabled={flag}
                  id="teethTC18"
                  onChange={(event) => checkedBox(event, "18")}
                  type="checkbox"
                  name="txtService"
                  value="322"
                />
              </div>
              <div class="colourList borderBottom borderRight">
                Other
                <input
                  disabled={flag}
                  id="teethTC19"
                  onChange={(event) => checkedBox(event, "19")}
                  type="checkbox"
                  name="txtService"
                  value="323"
                />
              </div>
            </div>
            <div class="checkFieldDiv">
              <div class="ckeckListGrid">
                D.D/Cheque
                <input
                  disabled={flag}
                  id="teethTC20"
                  onChange={(event) => checkedBox(event, "20")}
                  type="checkbox"
                  name="txtService"
                  value="304"
                />
              </div>
              <div class="ckeckListGrid">
                Screw
                <input
                  disabled={flag}
                  id="teethTC21"
                  onChange={(event) => checkedBox(event, "21")}
                  type="checkbox"
                  name="txtService"
                  value="305"
                />
              </div>
              <div class="ckeckListGrid">
                Bite
                <input
                  disabled={flag}
                  id="teethTC22"
                  onChange={(event) => checkedBox(event, "22")}
                  type="checkbox"
                  name="txtService"
                  value="306"
                />
              </div>
              <div class="ckeckListGrid">
                Others
                <input
                  disabled={flag}
                  id="teethTC23"
                  onChange={(event) => checkedBox(event, "23")}
                  type="checkbox"
                  name="txtService"
                  value="307"
                />
              </div>
              <div class="ckeckListGrid">
                Bands
                <input
                  disabled={flag}
                  id="teethTC24"
                  onChange={(event) => checkedBox(event, "24")}
                  type="checkbox"
                  name="txtService"
                  value="308"
                />
              </div>
              <div class="ckeckListGrid">
                Wires
                <input
                  disabled={flag}
                  id="teethTC25"
                  onChange={(event) => checkedBox(event, "25")}
                  type="checkbox"
                  name="txtService"
                  value="309"
                />
              </div>
              <div class="ckeckListGrid">
                Teeth
                <input
                  disabled={flag}
                  id="teethTC26"
                  onChange={(event) => checkedBox(event, "26")}
                  type="checkbox"
                  name="txtService"
                  value="310"
                />
              </div>
              <div class="ckeckListGrid">
                Face bow
                <input
                  disabled={flag}
                  id="teethTC27"
                  onChange={(event) => checkedBox(event, "27")}
                  type="checkbox"
                  name="txtService"
                  value="311"
                />
              </div>
            </div>
            {flag == true ? (
              <div class="listDiv">
                <div class="labelTxt">Modification</div>
                <div class="contentDiv">
                  <span class="semiColan">:</span>
                  <span class="contentTxt">
                    <input
                      disabled
                      value={Modification}
                      type="text"
                      id="Modification"
                      name="Modification"
                      class="inputfield"
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div class="listDiv">
                <div class="labelTxt">Modification</div>
                <div class="contentDiv">
                  <span class="semiColan">:</span>
                  <span class="contentTxt">
                    <input
                      type="text"
                      id="Modification"
                      name="Modification"
                      class="inputfield"
                    />
                  </span>
                </div>
              </div>
            )}
            {flag == true ? (
              <div class="listDiv">
                <div class="labelTxt">Instruction</div>
                <div class="contentDiv">
                  <span class="semiColan">:</span>
                  <span class="contentTxt">
                    <input
                      disabled
                      value={instruction}
                      type="text"
                      id="Instruction"
                      name="Instruction"
                      class="inputfield"
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div class="listDiv">
                <div class="labelTxt">Instruction</div>
                <div class="contentDiv">
                  <span class="semiColan">:</span>
                  <span class="contentTxt">
                    <input
                      type="text"
                      id="Instruction"
                      name="Instruction"
                      class="inputfield"
                    />
                  </span>
                </div>
              </div>
            )}
            {flag == true ? (
              <div class="listDiv">
                <div class="labelTxt">Send through</div>
                <div class="contentDiv">
                  <span class="semiColan">:</span>
                  <span class="contentTxt">
                    <input
                      disabled
                      value={Send}
                      type="text"
                      id="Send"
                      name="Send"
                      class="inputfield"
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div class="listDiv">
                <div class="labelTxt">Send through</div>
                <div class="contentDiv">
                  <span class="semiColan">:</span>
                  <span class="contentTxt">
                    <input
                      type="text"
                      id="Send"
                      name="Send"
                      class="inputfield"
                    />
                  </span>
                </div>
              </div>
            )}
            <div class="signatureField">Signature of Dr.</div>
            <div
              class="formBottomBtn"
              style={{
                paddingTop: "15px",
                textAlign: "right",
                paddingBottom: "15px",
              }}
            >
              <span
                id="print"
                onClick={printPage}
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#2d1152",
                  border: "0",
                  color: "#fff",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Print
              </span>

              <span
                class="saveClr buttonsmall"
                id="send"
                tabindex="15"
                onClick={(e) => {
                  saveOrtho(e);
                  orthoList(e);
                }}
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#dbdbdb",
                  border: "0",
                  color: "#000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Send
              </span>
              <a
                onClick={cancelButton}
                href="/Patients/labOrderDental"
                class="saveClr buttonsmall"
                id="cancel"
                tabindex="15"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#dbdbdb",
                  textDecoration: "none",
                  border: "0",
                  color: "#000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Cancel
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default LabOrderOrthoCreation;
