import {createSlice} from "@reduxjs/toolkit"

export const userInfo = createSlice({
    name:"loginUserId",
    initialState:{
        value:null
    },
    reducers:{
        change_userId:(state,action) => {
            state.value = action.payload.userId
        }
    }
})

export const {change_userId} = userInfo.actions

export default userInfo.reducer