import React, { useEffect, useState, Suspense } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import search from '../images/icons/search.png';
import reload from '../images/icons/reload.png';
import "react-datepicker/dist/react-datepicker.css";
import PhoneImage from '../images/icons/phoneImage.png';
import ClockImage from '../images/icons/clockImage.png';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Pagination from 'react-js-pagination';
import moment from 'moment';
import WebcamCapture from './webcam';
import Editicon from '../images/icons/edit-bg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Datepicker from 'react-datepicker';
import { faEdit, faClock, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { callingAPI } from '../config';
import AWS, { GlobalAccelerator } from 'aws-sdk';
import noImage from '../images/media/noImage.png';
let Header = React.lazy(() => import("../layout/header"))
let Footer = React.lazy(() => import("../layout/footer"))
let NoAppointmentData = React.lazy(() => import("../Common/noAppointmentData"))
var s3;
var fileNameUpload;
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;

function Appointment() {
	const [startDate, setStartDate] = useState(
		setHours(setMinutes(new Date(), 0), 9)
	);
	const [isSaving, setIsSaving] = useState(false);
	const [pageOffset, setPageOffset] = useState(window.sessionStorage.getItem('activepagenewappoinment') == null ? 0 : window.sessionStorage.getItem('activepagenewappoinment'));
	const [activePage, setActivePage] = useState(window.sessionStorage.getItem('AppoinmentpageNumber') == null ? 1 : window.sessionStorage.getItem('AppoinmentpageNumber'));
	const [getToDayFlag, setToDayFlag] = useState(false);
	const [StatusDate, setStatusDate] = useState(new Date());
	const [getChangeStatus, setChangeStatus] = useState(0);
	const [ChangeStatusHospitralName, setChangeStatusHospitralName] = useState('');
	const [GettingDocList, setGettingDocList] = useState([]);
	const [ChangestausSelectDoctor, setstausSelectDoctor] = useState('');
	const [getComments, setComments] = useState('');
	const [getDisharge, setgetDisharge] = useState('');
	const [getSave, setSave] = useState(false);
	const [getHospitalListByDoctorApp, setHospitalListByDoctorApp] = useState([]);
	const [getLastMrNo, setLastMrNo] = useState('');
	const [getPatientAppointmentType, setPatientAppointmentType] = useState('Walk In');
	const [getPatientFullName, setPatientFullName] = useState('');
	const [getDoctorName, setDoctorName] = useState([]);
	const [getNumberOfDoctors, setNumberOfDoctors] = useState([]);
	const [Dataflag, setFlag2] = useState(false);
	const [getMrFlag, setMrFlag] = useState(true);
	const [capturedData, setData] = useState({});
	const [freeArticleData12345, setFreeArticleData12345] = useState([]);
	const [getdoctorvisitId, setdoctorvisitId] = useState('');
	const [getPatientAppointmentId, setPatientAppointmentId] = useState('');
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [getOldMr, setOldMr] = useState('');
	const [newDate, setDate] = useState(new Date());
	const [state, setState] = useState({ imageName: '', mobileNumber: '', ageId: '' });
	const [file, setFile] = useState('');
	const [getHospitalNameChangeApp, hospitalNameChangeApp] = useState(localStorage.getItem("hospitalId")
	);
	const [getHospitalNameChangeAppNew, hospitalNameChangeAppNew] = useState('');
	const [imageName, setImageName] = useState('');
	const [imagePreview, setImagePreview] = useState(noImage);
	const [isData] = useState(0);
	const [isData1, setIsData1] = useState(false);
	const [getIsRescheduled, setIsRescheduled] = useState(false);
	const [getMrNoSearch, setMrNoSearch] = useState('');
	const [getPrNoSearch, setPrNoSearch] = useState('');
	const [image, setImage] = useState('');
	const [base64, setBase64] = useState('');
	const [display, setDisplay] = useState(false);
	const [imagePath, setImagePath] = useState('');
	const [areaCode, setAreaCode] = useState('+91');
	const [mobileNumber, setMobileNumber] = useState('');
	const [mrNo, setMrNo] = useState('');
	const [patientFirstName, setPatientFirstName] = useState('');
	const [patientLastName, setPatientLastName] = useState('');
	const [patientGender, setPatientGender] = useState('');
	const [emailId, setEmailId] = useState('');
	const [patientBloodGroup, setPatientBloodGroup] = useState(window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? '3' : '');
	const [zip, setZip] = useState('Consultation');
	const [mrNumberClick, setMrNumberClick] = useState("");
	const [web, setWeb] = useState("")
	const [prNoAutoComplete, setPrNoAutoComplete] = useState('');
	const [singleDoctorId, setSingleDoctorId] = useState([]);
	const [prNo, setPrNo] = useState('');
	const [getHidDocId, setHidDocId] = useState(
		window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
	);
	const [getSelectDoctor, setSelectDoctor] = useState(
		window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
			? window.sessionStorage.getItem('userName') +
			'(' +
			window.sessionStorage.getItem('specializationName') +
			')'
			: ''
	);
	const [getSelectDropdownStatus, setSelectDropdownStatus] = useState('0');
	const [getSelectDropdownDoctor, setSelectDropdownDoctor] = useState('');
	const [getStatus, setStatus] = useState([]);
	const [picture, setPicture] = useState(null);
	const [imgData, setImgData] = useState(null);
	const [getDoctor, setDoctor] = useState([]);
	const [popUpDoctorList1, setPopUpDoactorList1] = useState(false);
	const [freeArticleData123, setFreeArticleData123] = useState([]);
	const [getTimeImage, setTimeImage] = useState('');
	const [freeArticleData123456, setFreeArticleData123456] = useState([]);
	const [getAppointmentDataListing, setAppointmentDataListing] = useState([]);
	const [getCount, setCount] = useState('');
	const [freeArticleData1234567, setFreeArticleData1234567] = useState([]);
	const [getHidisNewUser, setHidisNewUser] = useState('newuser');
	const [getFlagClear, setFlagClear] = useState(false);
	const [getMemberId, setMemberId] = useState('');
	const [getHosId, setHosId] = useState('');
	const [showAllAppointmentButton, setShowAllAppointmentButton] = useState(false);
	const [smsFlag, setSmsFlag] = useState(0);
	const [dob, setFromDate1] = useState('');
	const [getAgeMonth, setAgeMonth] = useState("Year");
	const [getPage, setPage] = useState(false);

	async function fetchMyAPIApp() {
		const apiJson = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('patientsListing/getHospitalListByDoctor', apiJson).then((data) => {
			if (data.data.success === '1') {
				setHospitalListByDoctorApp(data.data.result);
				if (data.data.result.length === 1) {
					setHosId(data.data.result[0].hospitalId)
					hospitalNameChangeApp(data.data.result[0].hospitalId);
					getNewMr(data.data.result[0].hospitalId);
				} else {
					if (window.sessionStorage.getItem('role') !== 'ROLE_DOCTOR') {
						getNewMr(localStorage.getItem("hospitalId"));
					}
					else {
						getNewMr(data.data.result[0].hospitalId);
					}
					hospitalNameChangeApp(data.data.result[0].hospitalId);
				}
			}
		});
	}
	const isDate = (date) => {
		setDate(date)
		if (Date.parse(moment(new Date).format('YYYY-MM-DD')) < Date.parse(moment(date).format('YYYY-MM-DD'))) {
			setPatientBloodGroup('2');
		}
		else if (Date.parse(moment(new Date).format('YYYY-MM-DD')) == Date.parse(moment(date).format('YYYY-MM-DD'))) {
			setPatientBloodGroup('3');
		}
	};
	const addAppointment = () => {
		document.getElementById('newUsertextId').style.display = 'block';
		document.getElementById('radio1').checked = false;
		document.getElementById('radio2').checked = false;
		document.getElementById('savePopUpId').style.display = 'block';
		var popupModal = document.getElementById("savePopUpId");
		popupModal.addEventListener("keypress", function (event) {
			if (event.key === "Enter") {
				event.preventDefault();
				document.getElementById("savePopUpId").style.display = 'block';
			}
		});

		getStatusNew();
		setSave(false);
		setImagePreview(noImage);
		hospitalNameChangeApp(
			window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN'
				? localStorage.getItem("hospitalId")
				: getHospitalListByDoctorApp.length === 1 ? localStorage.getItem("hospitalId") : getHospitalNameChangeApp
		);

		setOldMr('')
		setTimeImage('')
		setDate(new Date());
		setAreaCode('+91');
		setState({
			mobileNumber: '',
		});
		setHidisNewUser('newuser');
		setPatientBloodGroup('3');
		setZip('Consultation');
		setIsRescheduled(false);
		setdoctorvisitId('');
		setPatientAppointmentId('');
		setMrNo(getLastMrNo);
		setHidDocId(
			window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" && getNumberOfDoctors.length === 1 ? singleDoctorId : ""
		);
		setSelectDoctor(
			window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
				? window.sessionStorage.getItem('userName') +
				'(' +
				window.sessionStorage.getItem('specializationName') +
				')'
				: window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" && getNumberOfDoctors.length === 1 ? getDoctorName : ""
		);

	};
	const cityAutocomplete23456 = (mode, dropdownId) => {
		var flag = true;
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChangeApp === '') {
			alert('Please Select Hospital');
			flag = false;
		}
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
			setMrNo('')
		} else {
			setMrNo(getLastMrNo)
		}
		document.getElementById('locationSearch1234567').style.display = 'none';
		document.getElementById('locationSearch12345').style.display = 'none';
		setMrFlag(true);
		if (getMrFlag === true) {
			setMrFlag(false);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrNoId').style.display = 'block';
				document.getElementById('mrNo').style.display = 'none';
				document.getElementById('mrBorderId').style.display = 'none';
				document.getElementById('editiconId').style.display = 'block';
			}
			document.getElementById('newUsertextId').style.display = 'block';

		}
		if (getFlagClear === true) {
			document.getElementById('mobileNumber').value = '';
			setState({
				mobileNumber: '',
			});
			setAreaCode('+91');
			setPatientLastName('');
			setEmailId('');
			setImagePreview(noImage);
			setMrNo(getLastMrNo);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'none';
			}
			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			setDate(new Date());
			setTimeImage('');
			setFlagClear(false);
			setHidisNewUser('newuser');
			setPrNoAutoComplete('')
		}
		const apiJson = {
			hospitalId: getHospitalNameChangeApp,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData123456(data.data.result);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			setFreeArticleData123456([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		setFreeArticleData1234567([]);
		setFreeArticleData12345([]);
	};
	const cityAutocomplete234567 = (mode, dropdownId) => {
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
		}
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch12345').style.display = 'none';
		setMrFlag(true);
		if (getMrFlag === true) {
			setMrFlag(false);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrNoId').style.display = 'block';
				document.getElementById('mrNo').style.display = 'none';
				document.getElementById('mrBorderId').style.display = 'none';
				document.getElementById('editiconId').style.display = 'block';
			}
			document.getElementById('newUsertextId').style.display = 'block';

		}
		if (getFlagClear === true) {
			document.getElementById('mobileNumber').value = '';
			setState({
				mobileNumber: '',
			});
			setAreaCode('+91');
			setPatientFirstName('');
			setEmailId('');
			setImagePreview(noImage);
			setMrNo(getLastMrNo);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'none';
			}
			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			setDate(new Date());
			setTimeImage('');
			setFlagClear(false);
			setHidisNewUser('newuser');
			setPrNoAutoComplete('')
		}
		const apiJson = {
			hospitalId: getHospitalNameChangeApp,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData1234567(data.data.result);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			setFreeArticleData1234567([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		setFreeArticleData123456([]);
		setFreeArticleData12345([]);
	};
	const setFreeArticleData234567 = (data, id2) => {
		setState({
			mobileNumber: data.secUserMobileNumber,
		});
		setMrNo(data.mrNo);
		setOldMr(data.mrNo)
		setPrNoAutoComplete(data.prNo)
		setPatientFirstName(data.memberFirstName);
		setAreaCode(data.secUserAreaCode);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setMemberId(data.memberId);
		setPatientGender(data.memberGender);
		if (data.memberGender === 'Male' || data.memberGender === 'male') {
			document.getElementById('radio1').checked = true;
		}
		if (data.memberGender === 'Female' || data.memberGender === 'female') {
			document.getElementById('radio2').checked = true;
		}
		setDate(new Date());
		setTimeImage(data.memberImageName);
		setHidisNewUser('');
		if (data.memberImageName !== '' && data.memberImageName !== 'null' && data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(noImage);
		}
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'none';
		}
		document.getElementById('newUsertextId').style.display = 'none';

		setFreeArticleData1234567([]);
		document.getElementById(id2).style.display = 'none';
		setFlagClear(true);
	};
	const setFreeArticleData23456 = (data, id2) => {
		setState({
			mobileNumber: data.secUserMobileNumber,
		});
		setMrNo(data.mrNo);
		setOldMr(data.mrNo)
		setPrNoAutoComplete(data.prNo)
		setPatientFirstName(data.memberFirstName);
		setAreaCode(data.secUserAreaCode);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setPatientGender(data.memberGender);
		setMemberId(data.memberId);
		if (data.memberGender === 'Male' || data.memberGender === 'male') {
			document.getElementById('radio1').checked = true;
		}
		if (data.memberGender === 'Female' || data.memberGender === 'female') {
			document.getElementById('radio2').checked = true;
		}
		setDate(new Date());
		setTimeImage(data.memberImageName);
		setHidisNewUser('');
		if (data.memberImageName !== '' && data.memberImageName !== 'null' && data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(noImage);
		}
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'none';
		}
		document.getElementById('newUsertextId').style.display = 'none';

		setFreeArticleData123456([]);
		document.getElementById(id2).style.display = 'none';
		setFlagClear(true);
	};
	const cityAutocomplete2345 = (mode, dropdownId) => {
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
			setMrNo('')
		} else {
			setMrNo(getLastMrNo)
		}
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';
		const apiJson = {
			hospitalId: getHospitalNameChangeApp,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData12345(data.data.result);
				} else {
					setFreeArticleData12345([]);
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		}
		else {
			setFreeArticleData12345([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	const setFreeArticleData2345 = (data, id2) => {
		document.getElementById('mobileNumber').value = data.secUserMobileNumber;
		setState({
			mobileNumber: data.secUserMobileNumber,
		});
		setMrNo(data.mrNo);
		setPrNoAutoComplete(data.prNo)
		setOldMr(data.mrNo)
		setPatientFirstName(data.memberFirstName);
		setAreaCode(data.secUserAreaCode);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setMemberId(data.memberId);
		setPatientGender(data.memberGender);
		if (data.memberGender === 'Male' || data.memberGender === 'male') {
			document.getElementById('radio1').checked = true;
		}
		if (data.memberGender === 'Female' || data.memberGender === 'female') {
			document.getElementById('radio2').checked = true;
		}
		setDate(new Date());
		setTimeImage(data.memberImageName);
		setHidisNewUser('');
		if (data.memberImageName !== '' && data.memberImageName !== 'null' && data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(noImage);
		}
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'none';
		}
		document.getElementById('newUsertextId').style.display = 'none';
		setFreeArticleData123456([]);
		document.getElementById(id2).style.display = 'none';
		setFlagClear(true);
	};
	const EditiconShow = () => {
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('editiconId').style.display = 'none';
			document.getElementById('mrNo').style.display = 'block';
			document.getElementById('mrNoId').style.display = 'none';
			document.getElementById('mrBorderId').style.display = 'none';
		}
	};
	const handlePageChange = (pageNumber) => {

		window.sessionStorage.setItem('AppoinmentpageNumber', pageNumber)
		setActivePage(pageNumber);
		var max;
		var offset;
		var max;
		if (pageNumber === "1") {
			offset = "0";
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset =
				parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		window.sessionStorage.setItem('activepagenewappoinment', offset)
		var d = new Date();
		d.setUTCHours(0, 0, 0, 0);
		let PostData = {
			userRole: window.sessionStorage.getItem('role'),
			userId: window.sessionStorage.getItem('userId'),
			selectDropdownStatus: getToDayFlag == true ? "0" : getSelectDropdownStatus,
			selectDropdownHospital: getHospitalNameChangeAppNew,
			searchPatientName: document.getElementById('searchPatientName').value,
			searchPatientMobile: document.getElementById('searchPatientMobile').value,
			searchByMrNo: getMrNoSearch,
			searchByPrNo: getPrNoSearch,
			selectDropdownDoctor: getSelectDropdownDoctor,
			searchByMemberTransId: '',
			orderBy: getToDayFlag == true ? "asc" : '',
			searchByCardNo: '',
			toDate: getToDayFlag == true ? +d + 86400000 - 1 - 19800000 : toDate === '' ? '' : Date.parse(toDate),
			fromDate: getToDayFlag == true ? +d - 19800 + 1 - 19800000 : fromDate === '' ? '' : Date.parse(fromDate),
			selectDropdownAppointmentType: document.getElementById('cmbAppType').value,
			offset: offset,
			max: max,
		};
		callingAPI('patientAppointment/patientAppointmentListing', PostData).then((data) => {
			if (data.data.success === '1') {
				setAppointmentDataListing(data.data.result.appointmentData);
				setCount(data.data.result.appointmentCountData);
			}
		})
			.catch();
	};
	const getIsPrNoEnableFlag = () => {
		const PostData = {
			"hospitalId": localStorage.getItem("hospitalId"),

		};
		callingAPI('commonUserFunctions/getIsPrNoEnableFlag', PostData).then((response) => {
			if (response.data.success === '1') {
				window.sessionStorage.setItem("isPrNumEnabled", response.data.result[0].is_prno_enabled);
			}
			else {
				alert(response.data.errorMessage)
			}
		})
			.catch();
	}
	useEffect(() => {

		const apiJson = {
			hospitalId: localStorage.getItem("hospitalId"),
			userId: window.sessionStorage.getItem('userId'),
			searchKeyWord: "",
		};
		callingAPI('patientAppointment/doctorAutoComplteByName', apiJson).then((data) => {
			if (data.data.success === '1') {
				setNumberOfDoctors(data.data.result)
				setDoctorName(data.data.result[0].doctorName)
				setSingleDoctorId(data.data.result[0].doctorId)
			}
		});
		getNewMr(localStorage.getItem("hospitalId"));
		window.sessionStorage.setItem("qaz", "none");
		window.sessionStorage.setItem("qaz1", "none");
		getIsPrNoEnableFlag();

		getStatusNew();
		doctorList();
		if (window.sessionStorage.getItem('setToDayFlag') === "true") {
			todayAppointment()
		} else {
			listing();
		}

		fetchMyAPIApp();
		// window.addEventListener("beforeunload", handleBeforeUnload);
		// return () => {
		// 	window.removeEventListener("beforeunload", handleBeforeUnload);
		// };
	}, []);
	const handleBeforeUnload = (e) => {
		e.preventDefault();
		window.sessionStorage.setItem('AppoinmentpageNumber', 1)
		window.sessionStorage.setItem('activepagenewappoinment', 0)
	};
	const getNewMr = (data1) => {
		const apiJson = {
			"hospitalId": data1,
			"memberId": "0"
		};
		setIsData1(false)
		callingAPI('calenderListing/autoCompleteGetMrNo', apiJson).then((data) => {

			if (data.data.success === '1') {
				if (data.data.result[0].isMrNoExist === false) {
					setLastMrNo(data.data.result[0].mrNo)
					setIsData1(true)
				}
			}
		});
	};
	const doctorList = () => {
		const apiJson = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('patientsListing/getDoctorsClick', apiJson).then((data) => {
			if (data.data.success === '1') {
				setDoctor(data.data.result);
			} else {
			}
		});
	};
	const handleChange = (event) => {
		if (event.target.id === 'mobileNumber') {
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'none';
			}
			setMrFlag(true);
			if (getMrFlag === true) {
				var flag1 = true;
				if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChangeApp === '') {
					alert('Please Select Hospital');
					setMrNo('');
					if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
						document.getElementById('mrBorderId').style.display = 'block';
					}
					flag1 = false;
				} else {
					setMrFlag(false);
					if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
						document.getElementById('mrNoId').style.display = 'block';
						document.getElementById('mrNo').style.display = 'none';
						document.getElementById('editiconId').style.display = 'block';
					}
					document.getElementById('newUsertextId').style.display = 'block';

				}
			}
			if (getFlagClear === true) {
				setPatientFirstName('');
				setAreaCode('+91');
				setImagePreview(noImage);
				setPatientLastName('');
				setEmailId('');
				setMrNo(getLastMrNo);
				if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
					document.getElementById('mrBorderId').style.display = 'none';
				}
				document.getElementById('radio1').checked = false;
				document.getElementById('radio2').checked = false;
				setDate(new Date());
				setTimeImage('');
				setPrNoAutoComplete('')
				setFlagClear(false);
				setHidisNewUser('newuser');
				setPrNoAutoComplete('')
			}
			setFreeArticleData1234567([]);
			setFreeArticleData123456([]);
		}
		var flag = true;
		const re = /^[0-9\b]+$/;
		if (re.test(event.target.value) || event.target.value === '') {
			if (event.target.id === 'mobileNumber') {
				document.getElementById('mobileNumber').value = event.target.value;
				setMobileNumber(document.getElementById('mobileNumber').value);
			}
		} else {
			if (event.target.id === 'mobileNumber') {
				document.getElementById('mobileNumber').value = mobileNumber;
				alert('Only Numbers Accepted');
				flag = false;
			}
		}
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};
	const onCaptureHandler = (captureData) => {
		setData(captureData);
	};
	const abcA = (a) => {
		if (a === "true") {
			setState({
				isData: 0,
			});
		}
		setWeb(a)
	};

	const closePopUp = () => {
		document.getElementById('savePopUpId').style.display = 'none';
		setFile('');
		setImageName('');
		setImagePreview('');
		setImage('');
		setBase64('');
		setPrNoAutoComplete("")
		setDisplay(false);
		setImagePath('');
		setAreaCode('');
		setMobileNumber('');
		setState({
			mobileNumber: '',
		});
		setPrNo('');
		setMrNo('');
		setPatientFirstName('');
		setPatientLastName('');
		setPatientGender('');
		setEmailId('');
		setPatientBloodGroup('');
		setZip('');
		setMemberId('');
	};
	const closePopUpEdit = () => {
		document.getElementById('savePopUpIdNew').style.display = 'none';
		setFile('');
		setImageName('');
		setImagePreview('');
		setImage('');
		setBase64('');
		setDisplay(false);
		setImagePath('');
		setAreaCode('');
		setMobileNumber('');
		setState({
			mobileNumber: '',
		});
		setPrNo('')
		setMrNo('');
		setPatientFirstName('');
		setPatientLastName('');
		setPatientGender('');
		setEmailId('');
		setPatientBloodGroup('');
		setZip('');
		setMemberId('');
	};
	const closePopUp1 = () => {
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			var a = document.getElementById('mrNo').value = ""
			setMrNo(a);
		}
		setFile('');
		setImageName('');
		setImagePreview('');
		setImage('');
		setBase64('');
		setDisplay(false);
		setImagePath('');
		setAreaCode('');
		setMobileNumber('');
		setState({
			mobileNumber: '',
		});
		setPatientFirstName('');
		setPatientLastName('');
		setPatientGender('');
		setEmailId('');
		setPatientBloodGroup('');
		setZip('');
		setMemberId('');
		setPopUpDoactorList1(false);
	};

	const todayAppointment = () => {
		setShowAllAppointmentButton(true)
		setActivePage(Number(window.sessionStorage.getItem('AppoinmentpageNumber')))
		window.sessionStorage.setItem('setToDayFlag', "true")
		doctorList();
		if (window.sessionStorage.getItem('todaysAppoinmentPageLoad') !== "true") {
			window.sessionStorage.setItem('activepagenewappoinment', 0)
			window.sessionStorage.setItem('AppoinmentpageNumber', 1)
		}
		setToDayFlag(true)
		var d = new Date();
		d.setUTCHours(0, 0, 0, 0);
		const apiJson = {
			userRole: window.sessionStorage.getItem('role'),
			userId: window.sessionStorage.getItem('userId'),
			selectDropdownStatus: '0',
			selectDropdownHospital: '',
			searchPatientName: '',
			searchPatientMobile: '',
			searchByMrNo: '',
			searchByPrNo: '',
			selectDropdownDoctor: '',
			searchByMemberTransId: '',
			searchByCardNo: '',
			toDate: +d + 86400000 - 1 - 19800000,
			fromDate: +d - 19800 + 1 - 19800000,
			orderBy: 'asc',
			selectDropdownAppointmentType: '',
			offset: window.sessionStorage.getItem('activepagenewappoinment') == null ? 0 : 0,
			max: 10,
		};
		callingAPI('patientAppointment/patientAppointmentListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setAppointmentDataListing(data.data.result.appointmentData);
				setCount(data.data.result.appointmentCountData);
				getNewMr(localStorage.getItem("hospitalId"));
				setPage(true)
				setFlag2(true);
				setIsData1(true);
			} else {
				setPage(true)
				setIsData1(true);
			}
		});
	}
	const listing = () => {
		setShowAllAppointmentButton(false)
		setToDayFlag(false)
		setActivePage(Number(window.sessionStorage.getItem('AppoinmentpageNumber')))
		const apiJson = {
			userRole: window.sessionStorage.getItem('role'),
			userId: window.sessionStorage.getItem('userId'),
			selectDropdownStatus: getSelectDropdownStatus,
			selectDropdownHospital: '',
			searchPatientName: '',
			searchPatientMobile: '',
			searchByMrNo: '',
			searchByPrNo: '',
			selectDropdownDoctor: '',
			searchByMemberTransId: '',
			searchByCardNo: '',
			toDate: '',
			fromDate: '',
			orderBy: '',
			selectDropdownAppointmentType: '',
			offset: window.sessionStorage.getItem('activepagenewappoinment') == null ? 0 : window.sessionStorage.getItem('activepagenewappoinment'),
			max: 10,
		};
		setPage(false)
		setIsData1(false);
		callingAPI('patientAppointment/patientAppointmentListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setAppointmentDataListing(data.data.result.appointmentData);
				setCount(data.data.result.appointmentCountData);
				setPage(true)
				setFlag2(true);
				setIsData1(true);
				if (document.getElementById('searchPatientName') !== null) {
					document.getElementById('searchPatientName').value = ""
					document.getElementById('searchPatientMobile').value = ""
				}
			} else {
				setPage(true)
				setIsData1(true);
			}
		});
	};

	const addAppointment1 = (id, memberId, typeId) => {
		setPatientAppointmentType(typeId)
		setPopUpDoactorList1(true);
		setPatientAppointmentId(id);
		setIsData1(false);
		const apiJson = {
			memberId: memberId,
			hospitalId: localStorage.getItem("hospitalId"),
			patientAppointmentId: id,
		};
		callingAPI('patientAppointment/editAppointmentDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				setMemberId(memberId);
				setIsRescheduled(true);
				setAreaCode(data.data.result.userdetailsData[0].secUserAreaCode);
				setMobileNumber(data.data.result.userdetailsData[0].secuserMobileNumber);
				setState({
					mobileNumber: data.data.result.userdetailsData[0].secuserMobileNumber,
				});
				setMrNo(data.data.result.mrNo);
				setPatientFirstName(data.data.result.userdetailsData[0].memberFirstName);
				setPatientLastName(data.data.result.userdetailsData[0].memberLastName);
				setPatientFullName(data.data.result.userdetailsData[0].memberFirstName + " " + data.data.result.userdetailsData[0].memberLastName);
				setEmailId(data.data.result.userdetailsData[0].secUserEmailid);
				setZip(data.data.result.doctorData[0].patientAppointmentReasonForVisit);
				setTimeImage(data.data.result.userdetailsData[0].memberImageName);
				setImageName(data.data.result.userdetailsData[0].memberImageName);
				setSelectDoctor(data.data.result.doctorData[0].doctorId);
				setImage(data.data.result.userdetailsData[0].memberImageName);
				setImagePath(getS3folder + '/' + data.data.result.userdetailsData[0].memberImageName);
				setdoctorvisitId(data.data.result.doctorData[0].doctorvisitId);
				setSelectDoctor(
					window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
						? window.sessionStorage.getItem('userName') +
						'(' +
						window.sessionStorage.getItem('specializationName') +
						')'
						: data.data.result.doctorData[0].doctorId
				);
				if (data.data.result.doctorData.length !== 0) {
					setHidDocId(
						window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
							? window.sessionStorage.getItem('userId')
							: data.data.result.doctorData[0].doctorId
					);
				}
				setPatientGender(data.data.result.userdetailsData[0].memberGender);
				setPatientBloodGroup(data.data.result.statusData[0].appointmentStatusId);
				let fromDateSplit = data.data.result.doctorData[0].patientAppointmentDateTime.split(' ');
				let a = fromDateSplit[0].split('/');
				let appoinmentDateTime =
					a[2] + '-' + a[1] + '-' + a[0] + ' ' + fromDateSplit[1] + ' ' + fromDateSplit[2];
				hospitalNameChangeApp(localStorage.getItem("hospitalId"));
				setDate(new Date(data.data.result.doctorData[0].scheduledSlotStartTime));
				setIsData1(true);
			} else {
			}
		});
	};
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const handleImageUploadOnChange = (e) => {
		setFile(e.target.files[0]);
		setImage("")
		setImageName(e.target.files[0].name);
		setState({
			imageName: e.target.files[0].name,
			// mobileNumber: mobileNumber
		});
		setImagePreview(URL.createObjectURL(e.target.files[0]));
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('uploadId').style.display = 'none';
		document.getElementById('takeSnapshotId1').style.display = 'none';
		document.getElementById('uploadId1').style.display = 'none';
	};
	const fileUpload = () => {
		if (Object.keys(capturedData).length === 0) {
			var S3BucketMainFolder =
				process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
			var fullFilePath = S3BucketMainFolder + '/' + imageName;
			s3.upload(
				{
					Key: fullFilePath,
					Body: file,
					ACL: 'public-read',
				},
				(err, data) => {
					if (err) {
						alert('There was an error uploading your photo: ' + err.message);
						return null;
					} else {
					}
				}
			);
		} else {
			s3.putObject(capturedData, (err, data) => {
				if (err) {
					alert('There was an error uploading your photo: ' + err.message);
				} else {
				}
			});
		}
	};

	const setFreeArticleData23 = (dropdownId) => {
		setSelectDoctor(dropdownId);
		setHidDocId(dropdownId);
		setFreeArticleData123([]);
	};

	const getStatusNew = () => {
		callingAPI('patientAppointment/patientStatusDropdown', '').then((data) => {
			if (data.data.success === '1') {
				setStatus(data.data.result);
			} else {
			}
		});
	};
	const addPatientSave = async () => {
		if (document.getElementById("saveId") !== null) {
			document.getElementById("saveId").style.display = 'none'
		}
		document.getElementById("cancelId").style.display = 'none'
		setMrNumberClick("true")
		if (file != '' || image != '') {
			await fileUpload();
		}
		await addPatientSaveNew();
	};
	const addPatientSaveNew = () => {
		setMrNumberClick("true")
		setIsData1(false);
		var isMrNoEdit = false
		if (mrNo === getOldMr) {
			isMrNoEdit = false
		}
		else {
			isMrNoEdit = true
		}
		const apiJson1 = {
			"hospitalId": getHospitalNameChangeApp,
			"mrNo": mrNo,
			"memberId": getMemberId,
			"Newuser": getHidisNewUser === "newuser" ? "1" : "0"
		};
		callingAPI('addPatient/checkMrNo', apiJson1).then((data) => {
			if (data.data.success === '1') {
				var dataNew = Date.parse(document.getElementById('appoinmentDateTime').value);
				var flag = true;
				var characters = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var email = document.getElementById('emailId').value;
				var splitemail = email.split(/\r|\r\n|\n/);
				var emailcount = splitemail.length;
				if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChangeApp === '') {
					setIsData1(true);
					alert('Please Select Hospital');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					flag = false;
				} else if (document.getElementById('mobileNumber').value === '') {
					setIsData1(true);
					alert('Please Fill Mobile Number');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					document.getElementById('mobileNumber').focus();
					flag = false;
				} else if (document.getElementById('mobileNumber').value.length < 10) {
					setIsData1(true);
					alert('Mobile Number must be 10 Digits');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					document.getElementById('mobileNumber').focus();
					flag = false;
				} else if (patientFirstName === '') {
					setIsData1(true);
					alert('Please Fill First Name  ');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					document.getElementById('patientFirstName').focus();
					flag = false;
				} else if (patientGender === '') {
					setIsData1(true);
					alert('Gender Required');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					flag = false;
				}
				else if (getHidDocId === '' || getSelectDoctor === '') {
					setIsData1(true);
					alert('Please Select Doctor Name ');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					flag = false;
				}
				else if (mrNo === '') {
					setIsData1(true);
					alert('MR Number Required');
					setMrNumberClick("true")
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					flag = false;
				} else if (
					characters.test(splitemail[emailcount - 1]) === false &&
					document.getElementById('emailId').value !== ''
				) {
					setIsData1(true);
					alert('Please Enter Valid Email Id');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					document.getElementById('emailId').focus();
					flag = false;
				} else if (newDate === null || newDate === "Invalid date") {
					setIsData1(true);
					alert('Please Select Appointment Date and Time field');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					document.getElementById("appoinmentDateTime").focus()
					flag = false;
				} else if (zip === '') {
					setIsData1(true);
					alert('Please Fill Reason For Visit');
					document.getElementById("saveId").style.display = 'inline-block'
					document.getElementById("cancelId").style.display = 'inline-block'
					document.getElementById('zip').focus();
					flag = false;
				}
				if (flag === true) {
					//alert(data.data.successMessage)
					//alert("Appointment Saved Successfully")
					if (document.getElementById("saveId") !== null) {
						document.getElementById("saveId").style.display = 'inline-block'
					}
					document.getElementById("cancelId").style.display = 'inline-block'
					setIsData1(false);
					document.getElementById('savePopUpId').style.display = 'none';
					const apiJson = {
						hidisNewUser: getHidisNewUser,
						memberId: getHidisNewUser == "newuser" ? "" : getMemberId,
						siteId: window.sessionStorage.getItem('siteId'),
						patientAppointmentDateTime: moment(newDate).format('yyyy-MM-DD hh:mm A'), //document.getElementById('appoinmentDateTime').value,
						memberFirstName: patientFirstName,
						memberLastName: patientLastName,
						memberEmailId: emailId,
						secUserAreaCode: areaCode,
						memberMobileNumber: document.getElementById('mobileNumber').value,
						memberGender: patientGender,
						memberImageName: image ? image : imageName ? imageName : getTimeImage,
						patientAppointmentId: getPatientAppointmentId,
						doctorVisitId: getdoctorvisitId,
						userId: window.sessionStorage.getItem('userId'),
						patientAppointmentType: getPatientAppointmentType,
						reScheduledAppointment: [],
						doctorId: getHidDocId,
						hospitalId: getHospitalNameChangeApp,
						firstReason: zip,
						secondReason: '',
						patientAppointmentStartTime: newDate != '' ? Date.parse(newDate) : dataNew,
						patientAppointmentEndTime: newDate != '' ? Date.parse(newDate) + 1800000 : dataNew + 1800000, //Date.parse(newDate)+1800000,
						doctorVisitReasonForVisitByPatient: zip,
						doctorVisitReasonByDoctor: '',
						doctorVisitDischargeSummary: '',
						doctorVisitDetails: '',
						mrNo: mrNo,
						patientAppointmentStatusId: patientBloodGroup,
						patientAppointmentLastVisitedDate: '',
						patientAppointmentLastVisitedReason: '',
						patientAppointmentTokenNo: '',
						isRescheduled: getIsRescheduled,
						isMrNoEdit: isMrNoEdit,
						memberIsSmsAlert: 'false',
						memberIsEmailAlert: 'false',
						fromCalender: ""
					};
					callingAPI('clinicalBackground/saveOrUpdateAppointments', apiJson).then((data) => {
						setMrNumberClick("true")
						setIsData1(true);
						setIsData1(true);
						if (data.data.success === '1') {
							setIsData1(true);
							setSmsFlag(data.data.result.smsFlag)
							let statusIdForSMS = patientBloodGroup
							sendSmsEmail(moment(newDate).format('yyyy-MM-DD hh:mm A'), data.data.result.newPatientAppointmentId)
							document.getElementById('savePopUpId').style.display = 'none';
							document.getElementById('savePopUpIdNew').style.display = 'none';
							setFile('');
							setImageName('');
							setImagePreview('');
							setImage('');
							setBase64('');
							setDisplay(false);
							setImagePath('');
							setAreaCode('');
							setMobileNumber('');
							setState({
								mobileNumber: '',
							});
							setPrNo('');
							setMrNo('');
							setPatientFirstName('');
							setPatientLastName('');
							setPatientGender('');
							setEmailId('');
							setPatientBloodGroup('');
							setZip('');
							setMemberId('');
							setIsRescheduled(false);
							alert(data.data.successMessage)
							setTimeout(() => {
								listing();
								window.location.href = "/patients/appointment";
							}, 2000);


						} else {
							setMrNumberClick("false")
							document.getElementById('savePopUpId').style.display = 'block';
							alert(data.data.errorMessage)
							document.getElementById("saveId").style.display = 'inline-block'
							document.getElementById("cancelId").style.display = 'inline-block'
						}
					});
				}
			} else {
				setMrNumberClick("false")
				alert(data.data.errorMessage);
				setIsData1(true);
				setMrNo('')
				setPrNo('');
				document.getElementById('mrNo').focus()
			}
		});
	};

	const check = (value) => {
		if (mrNumberClick === "false") {
			const apiJson1 = {
				"hospitalId": getHospitalNameChangeApp,
				"mrNo": value,
				"memberId": getMemberId,
				"Newuser": getHidisNewUser === "newuser" ? "1" : "0"

			};
			callingAPI('addPatient/checkMrNo', apiJson1).then((data) => {
				if (data.data.success === '0') {
					alert(data.data.errorMessage);
					setIsData1(true);
					setMrNo('')
					document.getElementById('mrNo').focus()

				}
			});
		}
	}
	const reloadSearchBar = () => {
		window.sessionStorage.setItem('setToDayFlag', "false")
		window.sessionStorage.setItem('activepagenewappoinment', 0)
		window.sessionStorage.setItem('AppoinmentpageNumber', 1)
		window.location.href = '/patients/appointment';
		document.getElementById('searchPatientName').value = '';
		document.getElementById('searchPatientMobile').value = '';
		setSelectDropdownStatus('');
		document.getElementById('cmbAppType').value = '';
		setFromDate('');
		setToDate('');
		setMrNoSearch('');
		setPrNoSearch('');
		hospitalNameChangeAppNew('');
		setSelectDropdownDoctor('');
	};
	const searchList = () => {
		setToDayFlag(false)
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				alert('End date must be greater than start date');
				document.getElementById('todate').focus();
				return false;
			}
		}
		const apiJson = {
			userRole: window.sessionStorage.getItem('role'),
			userId: window.sessionStorage.getItem('userId'),
			selectDropdownStatus: getSelectDropdownStatus,
			selectDropdownHospital: getHospitalNameChangeAppNew,
			searchPatientName: document.getElementById('searchPatientName').value,
			searchPatientMobile: document.getElementById('searchPatientMobile').value,
			searchByMrNo: getMrNoSearch,
			searchByPrNo: getPrNoSearch,
			selectDropdownDoctor: getSelectDropdownDoctor,
			searchByMemberTransId: '',
			searchByCardNo: '',
			orderBy: '',
			toDate: toDate === null || toDate === "" ? '' : Date.parse(toDate) + 86400000 - 1 - 19800000,
			fromDate: fromDate === null || fromDate === "" ? '' : Date.parse(moment(fromDate).format('YYYY-MM-DD hh:mm A')),
			selectDropdownAppointmentType: document.getElementById('cmbAppType').value,
			offset: 0,
			max: 10,
		};
		callingAPI('patientAppointment/patientAppointmentListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setAppointmentDataListing(data.data.result.appointmentData);
				setCount(data.data.result.appointmentCountData);
				setActivePage(1);
			} else {
			}
		});
	};
	const filterPassedTime = (time) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const editAppointment = (id, memberId, patientAppointmentType, data, docId, docName) => {
		document.getElementById('savePopUpIdNew').style.display = 'block';
		setPatientAppointmentId(id);
		setAreaCode(data.secUserAreaCode);
		setPatientAppointmentType(patientAppointmentType)
		setMobileNumber(data.secuserMobileNumber);
		document.getElementById('mobileNumber').value = data.secuserMobileNumber;
		setState({
			mobileNumber: data.secuserMobileNumber
		});
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
		}
		setMrNo(data.mrNo);
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrBorderId').style.display = 'none';
		}
		setPatientFirstName(data.memberFirstName);
		document.getElementById('newUsertextId').style.display = 'none';
		setMemberId(memberId);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setZip(data.patientAppointmentReasonForVisit);
		setImageName(data.memberImageName);
		if (data.memberImageName !== '' && data.memberImageName !== 'null' && data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(noImage);
		}
		setdoctorvisitId(data.doctorVisitId);
		if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION") {
			setSelectDoctor(docId)
			setHidDocId(docId)
		}
		setPatientGender(data.memberGender);
		setPrNo(data.prNo)
		setPatientGender(data.memberGender);
		setPatientBloodGroup(data.appointmentStatusId);
		setDate(new Date(data.scheduledSlotStartTime));
		setHidisNewUser('');
		setIsRescheduled(true);
		hospitalNameChangeApp(data.hospitalId);
		setSave(true);

	};
	const add = () => {
		setState({
			isData: 1,
		});
		document.getElementById('imageThumb1').style.display = 'none';
		document.getElementById('imageThumb2').style.display = 'none';
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('uploadId').style.display = 'none';
		document.getElementById('uploadId1').style.display = 'none';
		document.getElementById('takeSnapshotId1').style.display = 'none';
	};
	const webCamImageFunction = (image, base64) => {
		setImageName('')
		setImage(image)
		setImagePreview(base64);
		setState({
			isData: 0,
		});
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('uploadId').style.display = 'none';
		document.getElementById('uploadId1').style.display = 'none';
		document.getElementById('takeSnapshotId1').style.display = 'none';
	};

	const changeImage = () => {
		setDisplay(true);
		document.getElementById('uploadId').style.display = 'block';
		document.getElementById('takeSnapshotId').style.display = 'block';
		document.getElementById('uploadId1').style.display = 'block';
		document.getElementById('takeSnapshotId1').style.display = 'block';
	};
	const addPatientSave1 = () => {
		setIsData1(false);
		document.getElementById('shortpopup').style.display = 'none';
		var dataNew = Date.parse(document.getElementById('appoinmentDateTime').value);
		const apiJson = {
			hidisNewUser: '',
			memberId: getMemberId,
			siteId: window.sessionStorage.getItem('siteId'),
			patientAppointmentDateTime: '',
			memberFirstName: patientFirstName,
			memberLastName: patientLastName,
			memberEmailId: emailId,
			secUserAreaCode: areaCode,
			memberMobileNumber: mobileNumber,
			memberGender: patientGender,
			memberImageName: image ? image : imageName ? imageName : getTimeImage,
			patientAppointmentId: getPatientAppointmentId,
			doctorVisitId: getdoctorvisitId,
			userId: window.sessionStorage.getItem('userId'),
			patientAppointmentType: getPatientAppointmentType,
			reScheduledAppointment: [
				{
					reScheduleDateTime: moment(newDate).format('yyyy-MM-DD hh:mm A'),
					reScheduleStartTime: newDate != '' ? Date.parse(newDate) : dataNew,
					reScheduleEndTime: newDate != '' ? Date.parse(newDate) + 900000 : dataNew + 900000,
				},
			],
			doctorId: getHidDocId,
			hospitalId: getHospitalNameChangeApp,
			firstReason: zip,
			secondReason: '',
			patientAppointmentStartTime: '',
			patientAppointmentEndTime: '',
			doctorVisitReasonForVisitByPatient: zip,
			doctorVisitReasonByDoctor: '',
			doctorVisitDischargeSummary: '',
			doctorVisitDetails: '',
			mrNo: mrNo,
			patientAppointmentStatusId: patientBloodGroup,
			patientAppointmentLastVisitedDate: '',
			patientAppointmentLastVisitedReason: '',
			patientAppointmentTokenNo: '',
			isRescheduled: getIsRescheduled,
			isMrNoEdit: false,
			memberIsSmsAlert: 'false',
			memberIsEmailAlert: 'false',
			fromCalender: ""
		};
		callingAPI('clinicalBackground/saveOrUpdateAppointments', apiJson).then((data) => {
			if (data.data.success === '1') {
				setSmsFlag(data.data.result.smsFlag)
				let statusIdForSMS = patientBloodGroup
				sendSmsEmail(moment(newDate).format('yyyy-MM-DD hh:mm A'), data.data.result.newPatientAppointmentId)
				document.getElementById('shortpopup').style.display = 'none';
				setIsData1(true);
				if (file != '' || image != '') {
					fileUpload();
				}
				alert('Appointment Date and Time Changed Successfully');
				setPopUpDoactorList1(false);
				setTimeout(() => {

					listing();
				}, 2000);

				setFile('');
				setImageName('');
				setImagePreview('');
				setImage('');
				setBase64('');
				setDisplay(false);
				setImagePath('');
				setAreaCode('');
				setMobileNumber('');
				setState({
					mobileNumber: '',
				});
				setMrNo('');
				setPatientFirstName('');
				setPatientLastName('');
				setPatientGender('');
				setEmailId('');
				setPatientBloodGroup('');
				setZip('');
				setMemberId('');
				setIsRescheduled(false);
				setTimeImage('');
			} else {
				setIsData1(true);
				alert(data.data.errorMessage)
			}
		});

	};

	const sendSmsEmail = (appointmentDate, newPatientAppointmentId) => {
		const postData = {
			"hospitalId": getHospitalNameChangeApp,
			"patientAppointmentId": getPatientAppointmentId,
			"siteId": window.sessionStorage.getItem('siteId'),
			"changedAppointmentDateTime": appointmentDate,
			"hidisNewUser": getHidisNewUser == "newuser" ? 'newuser' : '',
			"smsFlag": smsFlag,
			"memberEmailId": emailId,
			"memberMobileNumber": document.getElementById('mobileNumber').value === "" ? mobileNumber : document.getElementById('mobileNumber').value,
			"newPatientAppointmentId": newPatientAppointmentId,
			"changeDate": moment(appointmentDate).format('YYYY-MM-DD'),
			"changeTime": moment(appointmentDate).format('hh : mm A'),
			"isRescheduled": getPatientAppointmentId == "" ? false : true,
			"patientAppointmentType": ""
		};
		callingAPI('commonUserFunctions/saveOrUpdateAppointmentEmailAndSms', postData).then((data) => {
			if (data.data.success === '1') {
			}
		})
			.catch();
	};
	const popup = (object) => {
		window.sessionStorage.setItem('todaysAppoinmentPageLoad', 'true')
		window.sessionStorage.setItem('AppoinmentpageNumber', activePage)
		window.sessionStorage.setItem("activepagenewappoinment", pageOffset)
		window.sessionStorage.setItem('memberId', object.memberId);
		window.sessionStorage.setItem('patientDoctorId', object.doctorId);
		localStorage.setItem('hospitalId', object.hospitalId);
		window.sessionStorage.setItem('patientDoctorName', object.doctorName);
		window.location.href = '/patients/patientconsult';
	};
	const hospitalNameChangeApp1 = (data) => {
		getNewMr(data);
		hospitalNameChangeApp(data);
		setFlagClear(true);
		if (data !== getHospitalNameChangeApp) {
			setLastMrNo('')
			setFile('');
			setImageName('');
			setImagePreview(noImage);
			setImage('');
			setBase64('');
			setDisplay(false);
			setImagePath('');
			setAreaCode('+91');
			setMobileNumber('');
			setMrNo('');
			setPatientFirstName('');
			setPatientLastName('');
			setPatientGender('');
			setEmailId('');
			setPatientBloodGroup('3');
			setZip('Consultation');
			setMemberId('');
			setIsRescheduled(false);
			//	fetchMyAPIApp();
			setState({
				mobileNumber: '',
			});
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'block';
			}
			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			getStatusNew();
			setDate(new Date());
			setHidisNewUser('newuser');
			setdoctorvisitId('');
			setPatientAppointmentId('');
			setHidDocId(
				window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
			);
			setSelectDoctor(
				window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
					? window.sessionStorage.getItem('userName') +
					'(' +
					window.sessionStorage.getItem('specializationName') +
					')'
					: ''
			);
		}
	};
	const clearData = (e) => {
		document.getElementById('locationSearch12345').style.display = 'none';
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';
	};
	const filter = (e) => {
		var x = document.getElementById("filterId");
		if (x.style.display === "none") {
			x.style.display = "block";
		} else {
			x.style.display = "none";
		}
	};
	//status code begin here
	const changeStatus = (id, data) => {
		setstausSelectDoctor("")
		setChangeStatus(0)
		setComments("")
		setgetDisharge("")
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById("status" + id);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
		document.getElementById("status" + id).style.display = 'block'
		setStatusDate(new Date(data.scheduledSlotStartTime))
	}
	const SaveingStatusChange = (data) => {
		if (!isSaving) {
			setIsSaving(true);
			setIsData1(false);
			var postdata = {
				"userId": window.sessionStorage.getItem('userId'),
				"appointmentId": data.patientAppointmentId,
				"statusId": getChangeStatus !== 0 ? getChangeStatus : data.appointmentStatusId.toString(),
				"comments": getComments,
				"status": document.getElementById("getChangeStatus").options[document.getElementById("getChangeStatus").selectedIndex].text !== "Select Status" ? document.getElementById("getChangeStatus").options[document.getElementById("getChangeStatus").selectedIndex].text : data.patientAppointmentStatus,
				"doctorId": getChangeStatus == 4 ? data.doctorId : "",
				"hospitalId": getChangeStatus == 4 ? data.hospitalId : "",
				"startTime": getChangeStatus == 2 ? (StatusDate === '' ? '' : Date.parse(StatusDate)) : "",
				"endTime": getChangeStatus == 2 ? (StatusDate === '' ? '' : Date.parse(StatusDate) + 18000) : "",
				"dischargeNote": getChangeStatus == 7 ? getDisharge : "",
				"appointmentDateTime": getChangeStatus == 2 ? (StatusDate === '' ? '' : moment(StatusDate).format('yyyy-MM-DD HH:mm:ss')) : "",
			}
			callingAPI('commonUserFunctions/appointmentStatusChange', postdata).then((res) => {
				setIsData1(true);
				setIsSaving(false);
				if (res.data.success === '1') {
					alert("Status saved successfully")
					setChangeStatus(0)
					listing()
					setComments("")
					setgetDisharge("")
					setStatusDate("")
					document.getElementById("status" + data.patientAppointmentId).style.display = 'none'
				}
			});
		}
	};
	const SaveingStatusChangeCancel = (data) => {
		setChangeStatus(0)
		setComments("")
		setgetDisharge("")
		setStatusDate("")
		document.getElementById("status" + data.patientAppointmentId).style.display = 'none'
	}
	const getDisposableItemNames = (value, locationSearch123) => {
		if (!ChangeStatusHospitralName) {
			alert("Hospital Name Required")
		}
		let postData = {
			hospitalId: ChangeStatusHospitralName,
			userId: window.sessionStorage.getItem('userId'),
			searchKeyWord: value,
		};
		callingAPI('patientAppointment/doctorAutoComplteByName', postData).then((data) => {
			if (data.data.success === '1') {
				setGettingDocList(data.data.result)
				setChangeStatusHospitralName("")
			}
		});
	}
	const stausSelectDoctorDropDownSelect = (SeletedDocName, SelectedDocId, i) => {
		setstausSelectDoctor(SeletedDocName)
		document.getElementById("medicineLists" + i).style.display = 'none'
	}
	const OnstatusClickChangeStatus = (value) => {
		setChangeStatus(value)
		setComments("")
		setgetDisharge("")
	}
	const BackFromTodaysAppointment = () => {
		window.sessionStorage.setItem('activepagenewappoinment', 0)
		window.sessionStorage.setItem('AppoinmentpageNumber', 1)
		listing();
		setToDayFlag(false);
	}
	const enterKey = (e) => {
		if (e.key === 'Enter') {
			searchList();
		}
	}
	const spanColour = (e) => {
		if (e.target.id === 'dobGreen') {
			document.getElementById('dobDashId').style.display = 'block';
			document.getElementById('ageDashId').style.display = 'none';
			document.getElementById('ageGreen').classList.remove('tabActive');
			document.getElementById('dobGreen').classList.add('tabActive');
			if (document.getElementById('ageId').value !== '') {
			}
			else {
			}
		} else {
			document.getElementById('dobDashId').style.display = 'none';
			document.getElementById('ageDashId').style.display = 'block';
			document.getElementById('dobGreen').classList.remove('tabActive');
			document.getElementById('ageGreen').classList.add('tabActive');
			if (document.getElementById('dobId').value === '') {
				var age = document.getElementById('ageId').value * 31556926000; //in Millis
				var dateOfBirth = new Date(new Date().getTime() - age);
			}
			else {

			}
		}
	};
	const ageChange = (date) => {
		if (date !== null && date !== "null") {
			setFromDate1(date);
			var today = new Date();
			var birthDate = date;
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			setState({
				ageId: age,
			});
		}
		else {
			setFromDate1('');
			setState({
				ageId: '',
			});
		}
	};
	const handleChange1 = (event) => {
		if (event.target.id === "ageId") {
			if (getAgeMonth === "Year") {
				if (document.getElementById("ageId").value < 1 && document.getElementById("ageId").value !== "") {
					alert("Please enter value greater than zero")
					event.target.value = ""
				} else {
					var age = document.getElementById('ageId').value * 31556926000; //in Millis
					var dateOfBirth = new Date(new Date().getTime() - age);
					if ((0 == dateOfBirth.getFullYear() % 4) && (0 != dateOfBirth.getFullYear() % 100) || (0 == dateOfBirth.getFullYear() % 400)) {
						setFromDate1(dateOfBirth);
					}
					else {
						setFromDate1(dateOfBirth);
					}
					setState({
						ageId: event.target.value,
					});
				}
			}
			else if (getAgeMonth === "Month") {
				if (document.getElementById("ageId").value < 1 && document.getElementById("ageId").value !== "") {
					alert("Please enter value greater than zero")
					event.target.value = ""
				}
				else if (document.getElementById("ageId").value <= 12) {

					var month = document.getElementById('ageId').value * 2.628e+9; //in Millis
					var dateOfBirth = new Date(new Date().getTime() - month);
					setFromDate1(dateOfBirth);
					setState({
						ageId: event.target.value,
					});
				}
				else {
					alert("Please enter value less than twelve")
					event.target.value = ""
				}

			}
			else if (getAgeMonth === "Day") {
				if (document.getElementById("ageId").value < 1 && document.getElementById("ageId").value !== "") {
					alert("Please enter value greater than zero")
					event.target.value = ""
				} else {
					var month = document.getElementById('ageId').value * 8.64e+7; //in Millis
					var dateOfBirth = new Date(new Date().getTime() - month);
					setFromDate1(dateOfBirth);
				}
			}
		}

		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};
	const ageMonth = (e) => {
		setAgeMonth(e.target.value)
		setState({
			ageId: "",
		});
	};
	return (
		<Suspense fallback={<div></div>}>
			<div className="coverWraper">
				<Header />
				<div className="contentWraper contentWraperScroll Appointmentjsx">
					<div className="patientWrapper">
						<div className="patientTopSection">
							<div className="breadcumbDiv">
								<Breadcrumb>
									<BreadcrumbItem>
										<Link to="/patients/appointment" tabIndex="-1">Practice Management</Link>
									</BreadcrumbItem>
									{getToDayFlag == false ?
										<React.Fragment>
											<div class="arrow-right" style={{ marginLeft: '7px' }} />
											<BreadcrumbItem> Patient Appointment</BreadcrumbItem>
										</React.Fragment>
										:
										<React.Fragment>
											<div class="arrow-right" style={{ marginLeft: '7px' }} />
											<BreadcrumbItem onClick={BackFromTodaysAppointment}><Link to="/patients/appointment" tabIndex="-1">Patient Appointment</Link></BreadcrumbItem>
											<div class="arrow-right" style={{ marginLeft: '7px' }} />
											<BreadcrumbItem>Today's Appointment</BreadcrumbItem>
										</React.Fragment>
									}
								</Breadcrumb>
								<div className="breadcumbDivRight">
									{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ?
										"" :
										<Link to="/patients/patientListing" className="allPatientLink" tabIndex="0">
											All Patients
										</Link>
									}
									<FontAwesomeIcon icon={faFilter} title="Search Filter" onClick={(e) => filter()} />
								</div>
							</div>
							<div className="appInfoWrap" id="filterId">
								<div className="col-md-12">
									<div className="searchSection row plAppInfo">
										<div className="col-md-4">
											<input
												type="text"
												onKeyDown={enterKey}
												//tabIndex="1"
												className="inputField"
												id="searchPatientName"
												name="searchPatientName"
												placeholder="Patient Name" tabIndex="1" autofocus="autofocus"
											/>
										</div>
										<div className="col-md-4">
											<input
												onKeyPress={(event) => {
													if (!/[0-9]/.test(event.key)) {
														event.preventDefault();
													}
												}}
												maxLength="10"
												type="text"
												onKeyDown={enterKey}
												tabIndex="2"
												className="inputField"
												id="searchPatientMobile"
												name="searchPatientMobile"
												placeholder="Patient Mobile Number"
											/>
										</div>
										{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
											<div
												class="newAppoinmentRightList"
												style={{
													display:
														getHospitalListByDoctorApp.length === 1 ? 'none' : 'inline-block',
												}}
											>
												<div className="appointmentFormList">
													<select style={{ background: "#f2f2f2" }}
														name="hospitalNameIdAppNew"
														className="formInput"
														tabIndex="4"
														id="hospitalNameIdAppNew"
														value={getHospitalNameChangeAppNew}
														onChange={(e) => hospitalNameChangeAppNew(e.target.value)}
													>
														{getHospitalListByDoctorApp.length !== 1 ? (
															<option value="">Select Hospital</option>
														) : (
															''
														)}
														{getHospitalListByDoctorApp.map((data, i) => (
															<option value={data.hospitalId}>{data.hospitalName}</option>
														))}
													</select>
												</div>
											</div>
										) : (
											''
										)}
										{window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (
											<div className="col-md-4">
												<input
													type="text"
													className="inputField"
													tabIndex="5"
													id="searchByMrNo"
													onKeyDown={enterKey}
													name="searchByMrNo"
													placeholder="Patient MR Number"
													value={getMrNoSearch}
													onChange={(e) => setMrNoSearch(e.target.value)}
												/>
											</div>


										) : (
											<div className="col-md-4">
												<input
													type="text"
													className="inputField"
													tabIndex="5"
													id="searchByPrNo"
													onKeyDown={enterKey}
													name="searchByPrNo"
													placeholder="Patient PR Number"
													value={getPrNoSearch}
													onChange={(e) => setPrNoSearch(e.target.value)}
												/>
											</div>
										)}

									</div>
								</div>
								<div className="col-md-12">
									<div className="searchSection row plAppInfo">
										{window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem('role') === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
											<div className="col-md-2">
												<select
													style={{ background: "#f2f2f2" }}
													className="inputField"
													id="selectDropdownDoctor"
													onKeyDown={enterKey}
													tabIndex="6"
													name="selectDropdownDoctor"
													value={getSelectDropdownDoctor}
													onChange={(e) => setSelectDropdownDoctor(e.target.value)}
												>
													<option value="">Doctor Name</option>
													{getDoctor.map((data, k) => (
														<option value={data.doctorId}>{data.doctorName}</option>
													))}
												</select>
											</div>
										) : (
											''
										)}
										<div className="col-md-2">
											<select
												className="inputField"
												style={{ background: "#f2f2f2" }}
												id="selectDropdownStatus"
												tabIndex="7"
												onKeyDown={enterKey}
												name="selectDropdownStatus"
												value={getSelectDropdownStatus}
												onChange={(e) => setSelectDropdownStatus(e.target.value)}
											>
												<option value="0">All Status</option>
												{getStatus.map((data, k) => (
													<option value={data.patientAppointmentStatusId}>
														{data.patientAppointmentStatus}
													</option>
												))}
											</select>
										</div>
										<div className="col-md-2">
											<select id="cmbAppType" name="cmbAppType" class="inputField" tabIndex="8" onKeyDown={enterKey}
												style={{ background: "#f2f2f2" }}>
												<option value="">Appointment Type</option>
												<option value="Walk In">Walk In</option>
												<option value="In Person">In Person</option>
												<option value="Video">Video Chat</option>
												<option value="Call A Doctor">Audio Chat</option>
											</select>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<Datepicker
												onKeyDown={enterKey}
												required
												tabIndex="9"
												className="Datepicker pa2 inputField"
												placeholderText="From Date"
												autoComplete="off"
												calendarClassName="rasta-stripes"
												selected={fromDate}
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												dateFormat="dd/MM/yyyy"
												onChange={(date) => setFromDate(date)}
											/>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<Datepicker
												onKeyDown={enterKey}
												selected={toDate}
												className="Datepicker pa2 inputField"
												placeholderText="To Date"
												tabIndex="10"
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												autoComplete="off"
												dropdownMode="select"
												calendarClassName="rasta-stripes"
												dateFormat="dd/MM/yyyy"
												id="todate"
												onChange={(date) => setToDate(date)}
											/>
										</div>
										<div className="col-md-2">
											<span className="commonButton" title="Search" onClick={searchList}>
												<img alt='' src={search} tabIndex="11" />
											</span>
											<span className="commonButton" title="Reset" onClick={reloadSearchBar}>
												<img alt='' src={reload} tabIndex="12" />
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="appointment">
								<div className="titleSection">
									<h4>Patient Appointment(s)</h4>
									<button className="borderButton" title="Add Appointment" onClick={() => addAppointment()} tabIndex="13">
										Add Appointment <span className="addAppoPlus">+</span>{' '}
									</button>
								</div>

								<div className="gridListSection" id="pageId">
									<span class="borderButton todaysAppointment" onClick={() => todayAppointment()} title="Today's Appointments" style={{ fontFamily: "Montserrat-SemiBold" }} tabIndex="14" >Today's Appointments  </span>
									{showAllAppointmentButton == true &&
										<span class="borderButton todaysAppointment" onClick={() => listing()} title="All Appointments" style={{ fontFamily: "Montserrat-SemiBold" }} tabIndex="14" >All Appointments </span>
									}
									{getAppointmentDataListing ? (
										<div>
											<div className="paginationSection">
												<Pagination
													//hideNavigation
													hideDisabled
													activePage={activePage}
													itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
													totalItemsCount={getCount}
													pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
													onChange={handlePageChange}
												/>
											</div>
											{getAppointmentDataListing.map((data, i) => (
												<span className='appointmentGridList'>
													<div className="gridList" tabIndex="15">
														<div className="gridImage">
															{data.patientAppointmentDate === '' ? (
																''
															) : (
																<div className="dateSection">
																	<span>{data.patientAppointmentDate.split('/')[0]}</span>{' '}
																	{data.patientAppointmentDateTime.split('-')[1]}{' '}
																	{data.patientAppointmentDate.split(' ')[0].split('/')[2]}
																</div>
															)}
														</div>
														<div className="gridContent">
															<div className="gridTop" onClick={() => popup(data)}>
																<span className="gridPatientName" style={{ color: '#3498db' }}>{data.secUserDisplayName}</span>
																<span className="gridDoctorName" style={{ display: 'none' }}>
																	{' '}
																</span>
															</div>
															<div className="gridTop">
																<span className="gridPatientName" style={{ cursor: "default" }} >
																	{data.doctorName}
																</span>
															</div>
															{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
																<div className="gridTop hospitalName">
																	<span className="gridPatientName" style={{ color: '#000', cursor: "default" }} >
																		{data.hospitalName}
																	</span>
																</div>
															) : (
																''
															)}
															<div className="gridIconsList">
																<div className="gridIconItem greenColor">
																	<img alt='' src={ClockImage} style={{ marginLeft: '-3px' }} />

																	{moment(data.scheduledSlotStartTime).format('h:mm A')}
																	&nbsp;<FontAwesomeIcon icon={faEdit} title="Change Appointment Date and Time" tabIndex="16"
																		onClick={() =>
																			addAppointment1(
																				data.patientAppointmentId,
																				data.memberId, data.patientAppointmentType
																			)}
																		style={{ cursor: 'pointer', color: '#3498db', fontSize: "14px" }} />
																</div>
																<div className="gridIconItem">
																	<img alt='' src={PhoneImage} />
																	{data.secuserMobileNumber}
																</div>
																{window.sessionStorage.getItem('isPrNumEnabled') === "false" &&
																	<div title={data.mrNo} className="gridIconItem" style={{ width: "auto", display: "block-inline" }}>MR No : {data.mrNo}</div>
																}
															</div>
															<div className="gridIconsList">
																{data.patientAppointmentType !== '' ? (
																	<div className="gridIconItem">
																		Type : {data.patientAppointmentType}
																	</div>
																) : (
																	''
																)}
																<div className="gridIconItem brownColor" style={{ cursor: "pointer" }} onClick={() =>
																	changeStatus(data.patientAppointmentId, data)}>
																	Status : {data.patientAppointmentStatus}
																</div>
																{window.sessionStorage.getItem('isPrNumEnabled') === "true" &&
																	(data.prNo !== '' &&
																		<div className="gridIconItem" style={{ width: "auto", display: "block-inline" }} title={data.prNo}>PR No : {data.prNo}</div>
																	)}
															</div>
														</div>
														<br />
														<div
															title="Edit Appointment"
															className="gridEdit"
															onClick={() =>
																editAppointment(data.patientAppointmentId, data.memberId, data.patientAppointmentType, data, data.doctorId, data.doctorName)}
															style={{ color: '#3498db' }}>
															<FontAwesomeIcon icon={faEdit} /> Edit Appointment
														</div>
														<div id="statusChangeDiv" className="statusChangeDiv">
															<h5>Change Status</h5>
															<div className="row">
																<div className="col-md-6">
																	<label className="labelText">
																		Current Status :<span className="statusSpan">
																			CANCELLED---------------------------------
																		</span>
																	</label>
																</div>
																<div className="clearfix" />
																<div className="col-md-6">
																	<label className="labelText">
																		Change Status To-----------------------------{' '}
																	</label>
																	<select className="inputField">
																		<option>Select Status</option>
																		<option>Open</option>
																		<option>Engaged</option>
																	</select>
																</div>
																<div className="clearfix" />
																<div className="col-md-12">
																	<label className="labelText">Discharge Note</label>
																	<textarea className="textareaField" />
																</div>
																<div className="clearfix" />
																<div className="col-md-6">
																	<label className="labelText">Appointment Date and Time</label>
																	<input type="text" className="inputField" />
																</div>
																<div className="clearfix" />
																<div className="col-md-6">
																	<label className="labelText">MR Number *</label>
																	<input type="text" className="inputField" />
																</div>
																<div className="clearfix" />
																<div className="col-md-12">
																	<label className="labelText">Discharge Note</label>
																	<textarea className="textareaField" />
																</div>
																<div className="col-md-12">
																	<label className="labelText">Comments</label>
																	<textarea className="textareaField" />
																</div>
																<div className="col-md-12 buttonRightSection">
																	<span className="borderButton">Save</span>
																	<span className="borderButton borderCancelButton">Cancel</span>
																</div>
															</div>
														</div>
													</div>
													{/* ////////////////////////////////////////////////////////////////////////////// */}
													{/* Change status begins here  */}
													<div className='changeStatusWrap' id={"status" + data.patientAppointmentId} style={{ display: 'none' }}>
														<h5>Change Status</h5>
														<p>Current Status :  {data.patientAppointmentStatus}</p>
														<p>Change Status To :  </p>

														<select className="formInput selectOptionAppointmnt"
															value={getChangeStatus}
															onChange={(e) =>
																OnstatusClickChangeStatus(
																	e.target.value
																)}

															id="getChangeStatus"
															name="getChangeStatus"
														>
															<option value="0">Select Status</option>
															{getStatus.filter((item) => item.patientAppointmentStatusId !== data.appointmentStatusId).map((data, k) => (
																<option
																	value={data.patientAppointmentStatusId}>
																	{data.patientAppointmentStatus}
																</option>
															))}
														</select>
														{getChangeStatus == 4 ?
															<div>
																<span >
																	<p>Hospital Name :  </p>
																	<select className="formInput selectOptionAppointmnt"
																		value={ChangeStatusHospitralName}
																		onChange={(e) =>
																			setChangeStatusHospitralName(
																				e.target.value
																			)}
																		id="ChangeStatusHospitralName"
																		name="ChangeStatusHospitralName"
																	>
																		<option value="0">Hospital Name </option>
																		{getHospitalListByDoctorApp.map((data, i) => (
																			<option value={data.hospitalId}>{data.hospitalName}</option>
																		))}
																	</select>
																	<p>Current Doctor Name :{data.doctorName} </p>
																	<div class="col-md-4"> <label>New Doctor Name </label>
																		<input maxlength="10" type="text" tabindex="2" value={ChangestausSelectDoctor} class="inputField" id="searchPatientMobile" name="searchPatientMobile" placeholder="New Doctor Name" onChange={(e) =>
																			setstausSelectDoctor(
																				e.target.value
																			)}
																			onKeyUp={(e) =>
																				getDisposableItemNames(e.target.value, "medicineList"
																				)} />
																		{/*  */}
																		<ul class="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch" id={"medicineLists" + i} style={{ display: "block" }}>
																			{GettingDocList.map((GettingDocListObj, idx) => (
																				<li onClick={(e) =>
																					stausSelectDoctorDropDownSelect(GettingDocListObj.doctorName, GettingDocListObj.doctorId, i

																					)}> {GettingDocListObj.doctorName} </li>
																			))}
																		</ul>
																		{/*  */}
																	</div>
																</span>
															</div>
															:
															""
														}
														{/* Appointment Date and Time */}
														{getChangeStatus == 2 ?
															<div>
																<div class="col-md-6 col-lg-6">
																	<label>Appointment Date and Time</label>
																	<Datepicker
																		required
																		id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		minDate={new Date()}
																		//value={new Date()}
																		placeholderText="Date  Time"
																		dateFormat="dd/MM/yyyy h:mm aa"
																		calendarClassName="rasta-stripes"
																		selected={StatusDate}
																		showTimeSelect
																		// showTimeInput
																		onChange={(date) => setStatusDate(date)}
																	/>
																</div>
																{/*  */}
															</div>
															:
															""
														}
														{getChangeStatus == 7 ?
															<div>
																<p>Disharge Note</p>
																<textarea value={getDisharge}
																	onChange={(e) =>
																		setgetDisharge(
																			e.target.value
																		)} className="  textAreaAppointment" name="Disharges" id="Disharge"></textarea>
															</div>
															:
															""
														}
														<p>Comments</p>
														<textarea value={getComments}
															onChange={(e) =>
																setComments(
																	e.target.value
																)} className="  textAreaAppointment" name="getComments" id="getComments"></textarea>
														<div class="newAppoinmentButton" style={{ paddingRight: "0px" }}>
															<button class="borderButton" disabled={isSaving} onClick={(e) =>
																SaveingStatusChange(
																	data
																)}>Save </button>
															<span class="borderButton borderCancelButton" onClick={(e) =>
																SaveingStatusChangeCancel(
																	data
																)}>Cancel</span>
														</div>
													</div>
													{/* Change status ends here  */}
												</span>
											))}
											<div className="paginationSection" tabIndex="0">
												<Pagination
													// hideNavigation
													hideDisabled
													activePage={activePage}
													itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
													totalItemsCount={getCount}
													pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
													onChange={handlePageChange}
												/>
											</div>
										</div>
									) :
										<NoAppointmentData />


									}
								</div>

								{getAppointmentDataListing == 0 && Dataflag == true && <NoAppointmentData />}
							</div>
						</div>
					</div>
					<div>
						{/* {popUpDoctorList == true && ( */}
						<div className="ploverlayy" id="savePopUpId" style={{ display: 'none' }} onClick={(e) => clearData()}>
							<div className="plpopup plpopupList plAppointmenPopup " id="formId">
								<div class="addDataHead">
									{getSave === false ? (
										<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
											Patient Appointment
										</span>
									) : (
										<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
											Edit Appointment
										</span>
									)}
									<a id="btnCancel" title="Close" class="close" onClick={closePopUp}>
										<span class="popUpClose"> &times;</span>
									</a>
								</div>
								<div className="popBack" id="popup1">
									<div className="popOverlay" />
									<div className="row">
										<div className="customizationPop">
											<div className="customizationPopContentSec">
												<input type="hidden" id="txtHiddenCategoryId" />
												<div
													className="contentWraper contentWraperScroll whiteBg"
													style={{ minHeight: 'auto' }}
												>
													<div className="container">
														<div className="innerContent plAppContent">
															{/* <div className="titleHeading">Add Patient</div> */}
															<div className="addpatientContent">
																<div className="row">
																	<div className="col-md-2">
																		<div
																			className="profileImg"
																			style={{ width: 'auto' }}
																		>
																			<div className="webCamSection">
																				<div
																					className="webcamOpen1"
																					id="results1" style={{ display: (base64 || imagePath || image) ? 'block' : 'none' }}
																				>
																					<img
																						src={
																							base64 || imagePath || image
																						}
																						alt=''
																						id="imageThumb1"
																						className="webOpenImage1"
																						onClick={changeImage}
																					/>
																					<span
																						className="changeImage"
																						onClick={changeImage}
																					>
																						{' '}
																					</span>
																				</div>
																				<div
																					className="webcamOpen"
																					id="results"
																				>
																					<img
																						src={imagePreview}
																						alt=''
																						id="imageThumb"
																						className="webOpenImage"
																						title="Upload Images"
																						onClick={changeImage}
																					/>
																					<span
																						className="changeImage"
																						style={{
																							display: display
																								? ''
																								: 'block',
																						}}
																						onClick={changeImage}
																					>
																						{' '}
																					</span>
																				</div>
																				<div
																					className="webcamDiv"
																					style={{
																						display: display ? '' : 'none',
																					}}
																				>
																					<div
																						className="myCamera"
																						id="my_camera"
																					/>
																					<div
																						className="webCamButton"
																						id="imageDivId"
																					>
																						{state.isData === 1 ? (
																							<WebcamCapture
																								onCapture={
																									onCaptureHandler
																								}
																								webCamImage={
																									webCamImageFunction
																								}
																								web={abcA}

																							/>
																						) : (
																							''
																						)}
																						<span
																							className="borderButton borderButtonSmall"
																							onClick={add}
																							style={{ display: 'none' }}
																							id="takeSnapshotId"
																						>
																							Take Snapshot
																						</span>
																						<span
																							style={{ display: 'none' }}
																							id="uploadId"
																							className="uploadButton uploadButtonSmall"
																						>
																							<input
																								type="file"
																								id="file1"
																								onChange={(e) =>
																									handleImageUploadOnChange(
																										e
																									)}
																							/>
																							<label for="file1">
																								Upload
																							</label>
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-10">
																		<div className="appointmentForm">
																			{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
																				<div
																					class="newAppoinmentRightList"
																					style={{
																						display:
																							getHospitalListByDoctorApp.length ===
																								1
																								? 'none'
																								: 'inline-block',
																					}}
																				>
																					<label className="formLabel">
																						Hospital Name<sup>*</sup>
																					</label>
																					<div className="appointmentFormList">
																						<select
																							name="hospitalNameIdApp"
																							className="formInput"
																							id="hospitalNameIdApp"
																							//onChange={hospitalNameChhospitalNameIdange}
																							value={
																								getHospitalNameChangeApp
																							}
																							onChange={(e) =>
																								hospitalNameChangeApp1(
																									e.target.value
																								)}
																						>

																							{getHospitalListByDoctorApp.map(
																								(data, i) => (
																									<option
																										value={
																											data.hospitalId
																										}
																									>
																										{
																											data.hospitalName
																										}
																									</option>
																								)
																							)}
																						</select>
																					</div>
																				</div>
																			) : (
																				''
																			)}
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Mobile No<sup>*</sup>
																				</label>
																				<div className="appointmentFormList">
																					<span class="mobileCountrySelect">
																						<input
																							type="text"
																							value={areaCode}
																							id="areaCode"
																							name="areaCode"
																							className="formInput"
																							placeholder="Mobile Number"
																							onChange={(e) =>
																								setAreaCode(
																									e.target.value
																								)}
																						/>
																					</span>
																					<span class="mobileNumberField">
																						<input
																							type="text"
																							autoComplete="off"
																							value={state.mobileNumber}
																							onKeyPress={(event) => {
																								if (
																									!/[0-9]/.test(
																										event.key
																									)
																								) {
																									event.preventDefault();
																								}
																							}}
																							id="mobileNumber"
																							name="mobileNumber"
																							placeholder="Mobile Number"
																							maxlength="10"
																							className="formInput"
																							onChange={handleChange}
																							onKeyUp={() =>
																								cityAutocomplete2345(
																									'mobileNumber',
																									'locationSearch12345'
																								)} autofocus="autofocus"
																						/>
																						<ul
																							className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																							id="locationSearch12345"
																							style={{ display: 'none' }}
																						>
																							{freeArticleData12345.map(
																								(
																									freeArticleDataObj,
																									i
																								) => (
																									<li
																										onClick={() =>
																											setFreeArticleData2345(
																												freeArticleDataObj,
																												'locationSearch12345'
																											)}
																									>
																										{
																											freeArticleDataObj.memberDetailsWithMrNo
																										}
																									</li>
																								)
																							)}
																						</ul>
																					</span>
																				</div>
																			</div>

																			{window.sessionStorage.getItem('isPrNumEnabled') === "true" && (
																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						PR Number
																					</label>
																					<div className="appointmentFormList" >
																						<input
																							// style={{ display: 'none' }}
																							type="text"
																							className="formInput"
																							value={prNoAutoComplete}
																							id="prNoAutoComplete"
																							maxlength="50"
																							name="prNoAutoComplete"
																							readOnly
																							// onBlur={(e) =>
																							// 	check(e.target.value)}
																							placeholder="PR Number"
																						/>
																					</div>
																				</div>)}
																			{window.sessionStorage.getItem('isPrNumEnabled') === "false" &&
																				(<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						MR Number *
																					</label>
																					<div className="appointmentFormList" title={mrNo}>
																						<input
																							style={{ display: 'none' }}
																							type="text"
																							className="formInput"
																							value={mrNo}
																							id="mrNo"
																							maxlength="50"
																							name="mrNo"
																							onBlur={(e) =>
																								check(e.target.value)}
																							placeholder="MR Number"
																							onChange={(e) =>
																								setMrNo(e.target.value)} tabIndex="41"
																						/>

																						<input
																							style={{
																								display: 'block',
																								border: 'none',
																							}}
																							className="formInput"
																							placeholder="MR Number"
																							id="mrBorderId"
																						/>
																						<span
																							class="mrNumberEdit"
																							id="mrNoId"
																							style={{ display: 'none' }}
																						>
																							{mrNo}
																							<button type='button' className='btn_mrno'>
																								<img
																									id="editiconId"
																									alt=''
																									style={{ display: 'none' }}
																									src={Editicon}
																									onClick={(e) =>
																										EditiconShow()}
																								/>
																							</button>
																						</span>
																					</div>
																				</div>)
																			}
																			{window.sessionStorage.getItem('role') ===
																				'ROLE_DOCTOR' ? (
																				<div
																					class="newAppoinmentRightList plEmptyLabel "
																					style={{
																						display:
																							getHospitalListByDoctorApp.length ===
																								1
																								? 'inline-block'
																								: 'none',
																					}}
																				>
																					<label className="formLabel">
																						&nbsp;
																					</label>
																				</div>
																			) : (
																				''
																			)}
																			{window.sessionStorage.getItem('role') ===
																				'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																				<div class="newAppoinmentRightList plEmptyLabel ">
																					<label className="formLabel">
																						&nbsp;
																					</label>
																				</div>
																			) : (
																				''
																			)}
																			<br />
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					First Name<sup>*</sup>{' '}
																					<span
																						className="blue-text"
																						id="newUsertextId"
																						style={{
																							color: 'green',
																							display: 'block',
																						}}
																					>
																						<b>New User </b>
																					</span>
																				</label>
																				<div className="appointmentFormList">
																					<input
																						type="text"
																						value={patientFirstName}
																						id="patientFirstName"
																						name="patientFirstName"
																						placeholder="First Name"
																						onChange={(e) =>
																							setPatientFirstName(
																								e.target.value.toUpperCase()
																							)}
																						className="input-design formInput"
																						autoComplete="off"
																						onKeyUp={() =>
																							cityAutocomplete23456(
																								'patientFirstName',
																								'locationSearch123456'
																							)}
																					/>
																					<ul
																						className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																						id="locationSearch123456"
																						style={{ display: 'none' }}
																					>
																						{freeArticleData123456.map(
																							(freeArticleDataObj, i) => (
																								<li
																									onClick={() =>
																										setFreeArticleData23456(
																											freeArticleDataObj,
																											'locationSearch123456'
																										)}
																								>
																									{
																										freeArticleDataObj.memberDetailsWithMrNo
																									}
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Last Name
																				</label>
																				<div className="appointmentFormList">
																					<input
																						type="text"
																						// className="formInput"
																						value={patientLastName}
																						id="patientLastName"
																						name="patientLastName"
																						placeholder="Last Name"
																						onChange={(e) =>
																							setPatientLastName(
																								e.target.value.toUpperCase()
																							)}
																						className="input-design formInput"
																						autoComplete="off"
																						onKeyUp={() =>
																							cityAutocomplete234567(
																								'patientLastName',
																								'locationSearch1234567'
																							)}
																					/>
																					<ul
																						className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																						id="locationSearch1234567"
																						style={{ display: 'none' }}
																					>
																						{freeArticleData1234567.map(
																							(freeArticleDataObj, i) => (
																								<li
																									onClick={() =>
																										setFreeArticleData234567(
																											freeArticleDataObj,
																											'locationSearch1234567'
																										)}
																								>
																									{
																										freeArticleDataObj.memberDetailsWithMrNo
																									}
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Email Id
																				</label>
																				<div className="appointmentFormList">
																					<input
																						type="text"
																						value={emailId}
																						id="emailId"
																						name="emailId"
																						className="formInput"
																						placeholder="Email Id"
																						onChange={(e) =>
																							setEmailId(e.target.value)}
																					/>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Gender<sup>*</sup>
																				</label>
																				<div className="appointmentFormList1">
																					<span className="radioSection1">
																						<input
																							name="patientLastName"
																							type="radio"
																							onChange={(e) =>
																								setPatientGender(
																									e.target.value
																								)}
																							value="Male"
																							id="radio1"
																						/>
																						<label for="radio1" />Male
																					</span>
																					<span className="radioSection1">
																						<input
																							name="patientLastName"
																							onChange={(e) =>
																								setPatientGender(
																									e.target.value
																								)}
																							type="radio"
																							value="Female"
																							id="radio2"
																						/>
																						<label for="radio2" />Female
																					</span>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Doctor Name<sup>*</sup>
																				</label>
																				<div className="appointmentFormList">
																					{window.sessionStorage.getItem(
																						'role'
																					) === 'ROLE_DOCTOR' ? (
																						<input
																							type="text"
																							value={getSelectDoctor}

																							id="freeArticleData123"
																							name="cityName"
																							placeholder="Doctor Name"
																							className="formInput"
																							autoComplete="off"
																							readOnly
																						/>
																					) : (
																						''
																					)}
																					{window.sessionStorage.getItem(
																						'role'
																					) === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem(
																						'role'
																					) === 'ROLE_NURSE' || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																						<>
																							{getDoctor.length > 1 ?
																								<select
																									style={{ background: "#f2f2f2" }}
																									className="inputField"
																									id="selectDropdownDoctor"
																									onKeyDown={enterKey}
																									tabIndex="6"
																									name="selectDropdownDoctor"
																									value={getSelectDoctor}
																									onChange={(e) => setFreeArticleData23(e.target.value)}
																								// onKeyUp={() =>
																								// 	 		cityAutocomplete23(
																								// 	 			'freeArticleData123',
																								// 	 			'locationSearch123'
																								// 	 		)} 
																								>
																									<option value="">Doctor Name</option>
																									{getDoctor.map((data, k) => (
																										<option value={data.doctorId}>{data.doctorName}</option>
																									))}
																								</select>
																								:
																								<select
																									style={{ background: "#f2f2f2" }}
																									className="inputField"
																									id="selectDropdownDoctor"
																									onKeyDown={enterKey}
																									tabIndex="6"
																									name="selectDropdownDoctor"
																									value={getSelectDoctor}
																									onChange={(e) => setFreeArticleData23(e.target.value)}
																								// onKeyUp={() =>
																								// 	 		cityAutocomplete23(
																								// 	 			'freeArticleData123',
																								// 	 			'locationSearch123'
																								// 	 		)} 
																								>
																									{/* <option value="">Doctor Name</option> */}
																									{getDoctor.map((data, k) => (
																										<option value={data.doctorId}>{data.doctorName}</option>
																									))}
																								</select>}
																						</>

																					) : (
																						''
																					)}

																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Appointment Date and Time<sup>*</sup>
																				</label>
																				<div className="appointmentFormList customDatePicker">
																					<Datepicker
																						required
																						id="appoinmentDateTime"
																						className="Datepicker pa2 inputField appmntDateTime"
																						minDate={new Date()}
																						selected={newDate}
																						filterTime={filterPassedTime}
																						//value='dfgfdg'
																						placeholderText="Date  Time"
																						dateFormat="dd/MM/yyyy h:mm aa"
																						timeFormat="h:mm aa"
																						calendarClassName="rasta-stripes"
																						dropdownMode="scroll"
																						autoComplete="off"
																						maxDate='+5D'
																						//	value={newDate}
																						showTimeSelect
																						onChange={(date) =>
																							isDate(date)}
																					/>
																				</div>
																			</div>
																			<input
																				type="hidden"
																				id="hidDocId"
																				value={getHidDocId}
																			/>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Status
																				</label>
																				<div className="appointmentFormList">
																					<select
																						className="formInput"
																						// value={patientBloodGroup}
																						value={patientBloodGroup}
																						onChange={(e) =>
																							setPatientBloodGroup(
																								e.target.value
																							)}
																						id="patientBloodGroup"
																						name="patientBloodGroup"
																					>
																						{/* <option value="">Select</option> */}
																						<option value="1">OPEN</option>
																						<option value="2">CONFIRM</option>
																						<option value="3">ARRIVED</option>
																						<option value="4">DOCTOR CHANGED</option>
																						<option value="5">ADMITTED</option>
																						{Date.parse(moment(newDate).format('YYYY-MM-DD')) > Date.parse(moment(new Date()).format('YYYY-MM-DD')) ? "" : <option value="6">COMPLETED</option>}

																						{/* <option value="7">DISCHARGE</option> */}
																						{/* <option value="8">ENGAGED</option> */}
																						<option value="7">CANCELLED</option>
																						{/* <option value="10">CALL COMPLETED-DOCTOR</option> */}

																					</select>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					Reason For Visit<sup>*</sup>
																				</label>
																				<div className="appointmentFormList">

																					<textarea
																						maxlength="50"
																						value={zip}
																						id="zip"
																						name="zip"
																						onChange={(e) =>
																							setZip(e.target.value)}

																						className="formInput"
																						placeholder="Reason For Visit"
																						style={{ height: 'auto' }}
																					>
																						Consultation
																					</textarea>
																				</div>
																			</div>
																			<div class="newAppoinmentButton">
																				{getSave === false ? (
																					<button type='button'
																						id="saveId"
																						title="Save"
																						className="borderButton"
																						onClick={() => addPatientSave()}
																					>
																						Save
																					</button>
																				) : (
																					<button type='button'
																						title="Update"
																						className="borderButton"
																						onClick={() => addPatientSave()}
																					>
																						Update
																					</button>
																				)}
																				<button type='button'
																					id="cancelId"
																					title="Cancel"
																					className="borderButton borderCancelButton"
																					onClick={closePopUp}
																				>
																					Cancel
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* // )} */}
					</div>
					<div>
						<div className="ploverlayy" id="savePopUpIdNew" style={{ display: 'none' }} onClick={(e) => clearData()}>
							<div className="plpopup plpopupList plAppointmenPopup " id="formId">

								<div class="addDataHead">
									{getSave === false ? (
										<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
											Patient Appointment
										</span>
									) : (
										<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
											Edit Appointment
										</span>
									)}
									<a id="btnCancel" title="Close" class="close" onClick={closePopUpEdit}>
										<span class="popUpClose"> &times;</span>
									</a>
								</div>
								<div className="popBack" id="popup1">
									<div className="popOverlay" />
									<div className="row">
										<div className="customizationPop">
											<div className="customizationPopContentSec">
												<input type="hidden" id="txtHiddenCategoryId" />
												<div
													className="contentWraper contentWraperScroll whiteBg"
													style={{ minHeight: 'auto' }}
												>
													<div className="container">
														<div className="innerContent plAppContent">
															{/* <div className="titleHeading">Add Patient</div> */}
															<div className="addpatientContent">
																<div className="row">
																	<div className="col-md-2">
																		<div
																			className="profileImg"
																			style={{ width: 'auto' }}
																		>
																			<div className="webCamSection">
																				<div
																					className="webcamOpen1"
																					id="results1" style={{ display: (base64 || imagePath || image) ? 'block' : 'none' }}
																				>
																					<img
																						src={
																							base64 || imagePath || image
																						}
																						id="imageThumb2"
																						alt=''
																						className="webOpenImage1"
																						onClick={changeImage}
																					/>
																					<span
																						className="changeImage"
																						onClick={changeImage}
																					>
																						{' '}
																					</span>
																				</div>
																				<div
																					className="webcamOpen"
																					id="results"
																				>
																					<img
																						src={imagePreview}
																						id="imageThumb"
																						alt=''
																						className="webOpenImage"
																						title="Upload Images"
																						onClick={changeImage}
																					/>
																					<span
																						className="changeImage"
																						style={{
																							display: display
																								? ''
																								: 'block',
																						}}
																						onClick={changeImage}
																					>
																						{' '}
																					</span>
																				</div>
																				<div
																					className="webcamDiv"
																					style={{
																						display: display ? '' : 'none',
																					}}
																				>
																					<div
																						className="myCamera"
																						id="my_camera"
																					/>
																					<div
																						className="webCamButton"
																						id="imageDivId"
																					>
																						{state.isData === 1 ? (
																							<WebcamCapture
																								onCapture={
																									onCaptureHandler
																								}
																								webCamImage={
																									webCamImageFunction
																								}
																								web={abcA}
																							/>
																						) : (
																							''
																						)}
																						<span
																							className="borderButton borderButtonSmall"
																							onClick={add}
																							style={{ display: 'none' }}
																							id="takeSnapshotId1"
																						>
																							Take Snapshot
																						</span>
																						<span
																							style={{ display: 'none' }}
																							id="uploadId1"
																							className="uploadButton uploadButtonSmall"
																						>
																							<input
																								type="file"
																								id="file1"
																								onChange={(e) =>
																									handleImageUploadOnChange(
																										e
																									)}
																							/>
																							<label for="file1">
																								Upload
																							</label>
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-10">
																		<div className="appointmentForm">
																			{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
																				<div
																					class="newAppoinmentRightList"
																					style={{
																						display:
																							getHospitalListByDoctorApp.length ===
																								1
																								? 'none'
																								: 'inline-block', width: '100%'
																					}}
																				>
																					<label className="formLabel">
																						<b>Hospital Name </b>
																					</label>
																					<div className="appointmentFormList">
																						<select
																							disabled
																							name="hospitalNameIdApp"
																							className="formInput"
																							id="hospitalNameIdApp"

																							value={
																								getHospitalNameChangeApp
																							}
																							onChange={(e) =>
																								hospitalNameChangeApp1(
																									e.target.value
																								)} style={{ width: "33.33%" }}
																						>

																							{getHospitalListByDoctorApp.map(
																								(data, i) => (
																									<option
																										value={
																											data.hospitalId
																										}
																									>
																										{
																											data.hospitalName
																										}
																									</option>
																								)
																							)}
																						</select>
																					</div>
																				</div>
																			) : (
																				''
																			)}
																			<div className='EditAppontmntWraper'>
																				<div className='MRPRNUM'>
																					{window.sessionStorage.getItem('isPrNumEnabled') === "false" &&
																						<div class="newAppoinmentRightList">
																							<label className="formLabel" style={{ color: "rgb(52, 152, 219)" }}>
																								<b>MR Number </b>
																							</label>
																							<div className="appointmentFormList" title={mrNo}>
																								<label
																									style={{ display: 'block' }}
																									id="mrNo"
																									maxlength="50"
																									name="mrNo"
																								// onBlur={(e) =>
																								//     check(e.target.value)}
																								// placeholder="MR Number"
																								>{mrNo} </label>


																							</div>
																						</div>
																					}
																					{window.sessionStorage.getItem('isPrNumEnabled') === "true" &&
																						(prNo !== '' ?
																							<div class="newAppoinmentRightList" id="prNo1">
																								<label className="formLabel" style={{ color: "rgb(52, 152, 219)" }}>
																									<b>PR Number</b>
																								</label>
																								<div className="appointmentFormList" title={prNo}>
																									<label

																										id="prNo"
																										maxlength="50"
																										name="prNo"
																										placeholder="PR Number"
																									// onChange={(e) =>
																									// setMrNo(e.target.value)} tabIndex="41"
																									>{prNo}</label>

																								</div>
																							</div> : '')}
																					{window.sessionStorage.getItem('role') ===
																						'ROLE_DOCTOR' ? (
																						<div
																							class="newAppoinmentRightList plEmptyLabel "
																							style={{
																								display:
																									getHospitalListByDoctorApp.length === 1
																										? 'inline-block'
																										: 'none',
																							}}
																						>
																							<label className="formLabel">
																								&nbsp;
																							</label>
																						</div>
																					) : (
																						''
																					)}
																					{window.sessionStorage.getItem('role') ===
																						'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																						<div class="newAppoinmentRightList plEmptyLabel ">
																							<label className="formLabel">
																								&nbsp;
																							</label>
																						</div>
																					) : (
																						''
																					)}
																				</div>

																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>First Name</b> {' '}

																					</label>
																					<div className="appointmentFormList">
																						<label
																							// type="text"
																							// value={patientFirstName}
																							id="patientFirstName"
																							name="patientFirstName"
																							placeholder="First Name"
																							onChange={(e) =>
																								setPatientFirstName(
																									e.target.value.toUpperCase()
																								)}
																							// className="input-design formInput"
																							autoComplete="off"
																							onKeyUp={() =>
																								cityAutocomplete23456(
																									'patientFirstName',
																									'locationSearch123456'
																								)}
																						>{patientFirstName} </label>
																						<ul
																							className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																							id="locationSearch123456"
																							style={{ display: 'none' }}
																						>
																							{freeArticleData123456.map(
																								(freeArticleDataObj, i) => (
																									<li
																										onClick={() =>
																											setFreeArticleData23456(
																												freeArticleDataObj,
																												'locationSearch123456'
																											)}
																									>
																										{
																											freeArticleDataObj.memberDetailsWithMrNo
																										}
																									</li>
																								)
																							)}
																						</ul>
																					</div>
																				</div>
																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>Last Name</b>
																					</label>
																					<div className="appointmentFormList">
																						<label
																							// type="text"
																							id="patientLastName"
																							name="patientLastName"
																							placeholder="Last Name"
																							onChange={(e) =>
																								setPatientLastName(
																									e.target.value.toUpperCase()
																								)}
																							// className="input-design formInput"
																							autoComplete="off"
																							onKeyUp={() =>
																								cityAutocomplete234567(
																									'patientLastName',
																									'locationSearch1234567'
																								)}
																						> {patientLastName}</label>
																						<ul
																							className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																							id="locationSearch1234567"
																							style={{ display: 'none' }}
																						>
																							{freeArticleData1234567.map(
																								(freeArticleDataObj, i) => (
																									<li
																										onClick={() =>
																											setFreeArticleData234567(
																												freeArticleDataObj,
																												'locationSearch1234567'
																											)}
																									>
																										{
																											freeArticleDataObj.memberDetailsWithMrNo
																										}
																									</li>
																								)
																							)}
																						</ul>
																					</div>
																				</div>

																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>Gender</b>
																					</label>
																					<div className="appointmentFormList1">
																						<span className="radioSection1">

																							<label for="radio11">{patientGender}</label>
																						</span>

																					</div>
																				</div>
																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>Mobile Number</b>
																					</label>
																					<div className="appointmentFormList">

																						<span class="mobileNumberField">
																							<label
																								// type="text"
																								autoComplete="off"
																								id="mobileNumber"
																								name="mobileNumber"
																								placeholder="Mobile Number"
																								maxlength="10"
																								// className="formInput"
																								// onChange={handleChange}
																								// onKeyUp={() =>
																								// 	cityAutocomplete2345(
																								// 		'mobileNumber',
																								// 		'locationSearch12345'
																								// 	)}
																								autofocus="autofocus"
																							>{areaCode}-{mobileNumber}</label>
																							<ul
																								className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																								id="locationSearch12345"
																								style={{ display: 'none' }}
																							>
																								{freeArticleData12345.map(
																									(
																										freeArticleDataObj,
																										i
																									) => (
																										<li
																											onClick={() =>
																												setFreeArticleData2345(
																													freeArticleDataObj,
																													'locationSearch12345'
																												)}
																										>
																											{
																												freeArticleDataObj.memberDetailsWithMrNo
																											}
																										</li>
																									)
																								)}
																							</ul>
																						</span>
																					</div>
																				</div>
																				<div class="newAppoinmentRightList" style={{ margin: '0px' }}>
																					<label className="formLabel">
																						<b>Email Id</b>
																					</label>
																					<div className="appointmentFormList">
																						<label
																							// type="text"
																							// value={emailId}
																							id="emailId"
																							name="emailId"
																							// className="formInput"
																							placeholder="Email Id"
																							onChange={(e) =>
																								setEmailId(e.target.value)}
																						>{emailId}</label>
																					</div>
																				</div>
																			</div>
																			<div>
																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>Doctor Name</b>
																					</label>
																					<div className="appointmentFormList">
																						{window.sessionStorage.getItem(
																							'role'
																						) === 'ROLE_DOCTOR' ? (
																							<input
																								type="text"
																								value={getSelectDoctor}

																								id="freeArticleData123"
																								name="cityName"
																								placeholder="Doctor Name"
																								className="formInput"
																								autoComplete="off"
																								readOnly
																							/>
																						) : (
																							''
																						)}
																						{window.sessionStorage.getItem(
																							'role'
																						) === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																							<>
																								{getDoctor.length > 1 ?
																									<select
																										style={{ background: "#f2f2f2" }}
																										className="inputField"
																										id="selectDropdownDoctor"
																										onKeyDown={enterKey}
																										tabIndex="6"
																										name="selectDropdownDoctor"
																										value={getSelectDoctor}
																										onChange={(e) => setFreeArticleData23(e.target.value)}
																									// onKeyUp={() =>
																									// 	 		cityAutocomplete23(
																									// 	 			'freeArticleData123',
																									// 	 			'locationSearch123'
																									// 	 		)} 
																									>
																										<option value="">Doctor Name</option>
																										{getDoctor.map((data, k) => (
																											<option value={data.doctorId}>{data.doctorName}</option>
																										))}
																									</select>
																									:
																									<select
																										style={{ background: "#f2f2f2" }}
																										className="inputField"
																										id="selectDropdownDoctor"
																										onKeyDown={enterKey}
																										tabIndex="6"
																										name="selectDropdownDoctor"
																										value={getSelectDoctor}
																										onChange={(e) => setFreeArticleData23(e.target.value)}
																									// onKeyUp={() =>
																									// 	 		cityAutocomplete23(
																									// 	 			'freeArticleData123',
																									// 	 			'locationSearch123'
																									// 	 		)} 
																									>
																										{/* <option value="">Doctor Name</option> */}
																										{getDoctor.map((data, k) => (
																											<option value={data.doctorId}>{data.doctorName}</option>
																										))}
																									</select>}
																							</>

																						) : (
																							''
																						)}

																					</div>
																				</div>
																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>Appointment Date and Time </b>
																					</label>
																					<div className="appointmentFormList customDatePicker">
																						<Datepicker
																							required
																							id="appoinmentDateTime"
																							className="Datepicker pa2 inputField appmntDateTime"
																							minDate={new Date()}
																							selected={newDate}
																							filterTime={filterPassedTime}

																							placeholderText="Date  Time"
																							dateFormat="dd/MM/yyyy h:mm aa"
																							timeFormat="h:mm aa"
																							calendarClassName="rasta-stripes"
																							dropdownMode="scroll"
																							autoComplete="off"
																							maxDate='+5D'

																							showTimeSelect
																							onChange={(date) => isDate(date)}
																						// onChange={(date) =>
																						// 	setDate(date)}
																						/>
																					</div>
																				</div>
																				<input
																					type="hidden"
																					id="hidDocId"
																					value={getHidDocId}
																				/>
																				<div class="newAppoinmentRightList">
																					<label className="formLabel">
																						<b>Status</b>
																					</label>
																					<div className="appointmentFormList">
																						<select
																							className="formInput"
																							value={patientBloodGroup}
																							onChange={(e) =>
																								setPatientBloodGroup(
																									e.target.value
																								)}
																							id="patientBloodGroup"
																							name="patientBloodGroup"
																						>

																							<option value="1">OPEN</option>
																							<option value="2">CONFIRM</option>
																							<option value="3">ARRIVED</option>
																							<option value="4">DOCTOR CHANGED</option>
																							<option value="5">ADMITTED</option>
																							{Date.parse(moment(newDate).format('YYYY-MM-DD')) > Date.parse(moment(new Date()).format('YYYY-MM-DD')) ? "" : <option value="6">COMPLETED</option>}
																							<option value="7">DISCHARGE</option>
																							<option value="8">ENGAGED</option>
																							<option value="9">CANCELLED</option>
																							<option value="10">CALL COMPLETED-DOCTOR</option>

																						</select>
																					</div>
																				</div>

																				<div class="newAppoinmentRightList" style={{ width: '100%' }}>
																					<label className="formLabel">
																						<b>Reason For Visit </b>
																					</label>
																					<div className="appointmentFormList">

																						<textarea
																							maxlength="50"
																							value={zip}
																							id="zip"
																							name="zip"
																							onChange={(e) =>
																								setZip(e.target.value)}

																							className="formInput"
																							placeholder="Reason For Visit"
																							style={{ height: 'auto' }}
																						>
																							Consultation
																						</textarea>
																					</div>
																				</div>
																			</div>
																			<div class="form-group">
																				<div class="col-sm-12">
																					{getHidisNewUser == "newuser" && (
																						<div class="calendarDateOfBirth" style={{ width: '100%' }}>
																							<label className="formLabel" style={{ margin: '0 0 2px' }}>
																								<span
																									class="addPatientTab "
																									id="dobGreen"
																									onClick={(e) => spanColour(e)} style={{ fontFamily: "OpenSans-Regular", fontSize: '11px' }}
																								>
																									DOB
																								</span> / <span
																									onClick={(e) => spanColour(e)}
																									class="addPatientTab tabActive"
																									id="ageGreen"
																									autoComplete="off"
																								>
																									Age*
																								</span>{' '}

																							</label>

																							<div
																								className="appointmentFormList" autoComplete="off"
																								style={{ display: 'none' }}
																								id="dobDashId"
																							>
																								<Datepicker
																									id="dobId"
																									className="Datepicker pa2 inputField formInput"
																									placeholderText="Date of Birth"
																									calendarClassName="rasta-stripes"
																									selected={dob}
																									value={dob}
																									maxDate={new Date()}
																									dateFormat="dd/MM/yyyy"
																									peekNextMonth
																									showMonthDropdown
																									showYearDropdown
																									autoComplete="off"
																									dropdownMode="select"
																									onChange={(date) => ageChange(date)}
																								/>
																							</div>

																							<div className="appointmentFormList" id="ageDashId">
																								<div className='yearOfBirthInfo'>

																									<span className="practiceContentText" style={{ width: "50%" }}>
																										<select
																											onChange={ageMonth}
																											value={getAgeMonth}
																											name="agemonth"
																											id="agemonth"
																											className="inputField formInput" style={{ height: "20px", marginTop: "0px", position: 'relative', top: '-3px' }}
																										>
																											<option value="Year">Year</option>
																											<option value="Month">Month</option>
																											<option value="Day">Day</option>


																										</select>
																									</span>
																								</div>
																								<input
																									onChange={handleChange1}
																									value={state.ageId}
																									type="text"
																									className="formInput form-control"
																									id="ageId"
																									maxlength="3"
																									name="ageId"
																									onKeyPress={(event) => {
																										if (!/[0-9]/.test(event.key)) {
																											event.preventDefault();
																										}
																									}}
																									placeholder={getAgeMonth}
																								/>
																							</div>
																						</div>)}
																				</div>
																			</div>
																			<div class="newAppoinmentButton" style={{ padding: "15px 10px" }}>
																				{getSave === false ? (
																					<button type='button'
																						id="saveId"
																						title="Save"
																						className="borderButton"
																						onClick={() => addPatientSave()}
																					>
																						Save
																					</button>
																				) : (
																					<button type='button'
																						title="Update"
																						className="borderButton"
																						onClick={() => addPatientSave()}
																					>
																						Update
																					</button>
																				)}
																				<button type='button'
																					title="Cancel"
																					className="borderButton borderCancelButton"
																					onClick={closePopUpEdit}
																				>
																					Cancel
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						{popUpDoctorList1 == true && (
							<div className="ploverlayy" id="shortpopup">
								<div className="plpopup plpopupList  ">
									<div class="addDataHead appointmentPopupClose">
										<span id="spanAddSupplier" style={{ fontSize: '16px' }}>
											Change Appointment Date and Time
										</span>
										<a href="#" id="btnCancel" class="close" onClick={closePopUp1}>
											<span class="popUpClose">&times;</span>
										</a>
									</div>
									<div className="popBack" id="popup1">
										<div className="popOverlay" />
										<div className="row">
											<div className="customizationPop">
												<div className="customizationPopContentSec">
													<input type="hidden" id="txtHiddenCategoryId" />
													<div
														className="contentWraper contentWraperScroll whiteBg"
														style={{ minHeight: 'auto' }}
													>
														{/* <div className="container"> */}
														<div className="innerContent plAppContent">
															{/* <div className="titleHeading">Add Patient</div> */}
															<div className="addpatientContent">
																<div className="row">
																	<div className="col-md-12">
																		<div className="row appointmentForm">
																			<div class="col-md-6">
																				<label className="formLabel">
																					Patient Name
																				</label>
																				<div className="appointmentFormList">
																					<input
																						readOnly
																						type="text"
																						className="  inputField"
																						//value={getPatientFullName}
																						id="patientFirstName"
																						name="patientFirstName"
																						placeholder={getPatientFullName}

																					/>
																				</div>
																			</div>
																			<div class="col-md-6">
																				<label className="formLabel">
																					Appointment Date and Time
																				</label>
																				<div className="appointmentFormList customDatePicker">
																					<Datepicker
																						required
																						id="appoinmentDateTime"
																						className="Datepicker pa2 inputField"
																						minDate={new Date()}
																						//value={new Date()}
																						filterTime={filterPassedTime}
																						placeholderText="Date  Time"
																						dateFormat="dd/MM/yyyy h:mm aa"
																						calendarClassName="rasta-stripes"
																						selected={newDate}
																						showTimeSelect
																						// showTimeInput
																						onChange={(date) => setDate(date)}
																					/>
																				</div>
																			</div>
																			<input
																				type="hidden"
																				id="hidDocId"
																				value={getHidDocId}
																			/>
																			<div class="newAppoinmentButton">
																				<button
																					className="borderButton"
																					onClick={() => addPatientSave1()}
																				>
																					Change
																				</button>
																				<button
																					className="borderButton borderCancelButton"
																					onClick={closePopUp1}
																				>
																					Cancel
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{/* </div> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		</Suspense>
	);
}
export default Appointment;
