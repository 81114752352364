import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import AWS from 'aws-sdk';
import QRCode from 'qrcode.react';
import healthcard_user from '../images/media/healthcard_user.png';
import Home from '../images/icons/homeicon-healthcard.png';
import Calls from '../images/icons/calls.png';
import Phoneicon from '../images/icons/phoneicon.png';
import Web from '../images/icons/web.png';
import Webmail from '../images/icons/webmail.png';
import Youtube from '../images/icons/youtube.png';
import Logo from '../images/media/logo.png';
import Loader from "../Common/loader";
import { callingAPI } from '../config';
import { WindowScrollController } from '@fullcalendar/react';
var s3Path =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
var s3;
function PracticeDetails() {
	const [ClinicName, setClinicName] = useState("");
	const [ClinicEmail, setClinicEmail] = useState("");
	const [ClinicAddress, setClinicAddress] = useState("");
	const [ClinicCountry, setClinicCountry] = useState("");
	const [ClinicState, setClinicState] = useState("");
	const [ClinicCity, setClinicCity] = useState("");
	const [ClinicPinCode, setClinicPinCode] = useState("");
	const [ClinicPhone, setClinicPhone] = useState("");
	const [ClinicPrimaryEmailIds, setClinicPrimaryEmailIds] = useState("");
	const [ClinicDeactivateDate, setClinicDeactivateDate] = useState("");
	const [ClinicCategory, setClinicCategory] = useState("");
	const [ClinicCredential, setClinicCredential] = useState("");
	const [ClinicType, setClinicType] = useState("");
	const [ClinicClassification, setClinicClassification] = useState([]);
	const [ClinicPayment, setClinicPayment] = useState("");
	const [ClinicWorktimeFilter, setClinicWorktimeFilter] = useState("");
	const [ClinicDisplayName, setClinicDisplayName] = useState("");
	const [ClinicUserName, setClinicUserName] = useState("");
	const [ClinicWebsiteAddress, setClinicWebsiteAddress] = useState("");
	const [ClinicDescription, setClinicDescription] = useState("");
	const [ClinicShowHospitalMedicinesOnly, setClinicShowHospitalMedicinesOnly] = useState("");
	const [file, setFile] = useState('');
	const [imagePreview, setImagePreview] = useState(null);
	const [imageName, setImageName] = useState(null);
	//////////////////////////////////////////////////////////
	const [ClinicCategoryDropDown, setClinicCategoryDropDown] = useState([]);
	const [ClinicCredentialDropDown, setClinicCredentialDropDown] = useState([]);
	const [ClinicTypeDropDown, setClinicTypeDropDown] = useState([]);
	const [ClinicClassificationDropDown, setClinicClassificationDropDown] = useState([]);
	const [ClinicPaymentDropDown, setClinicPaymentDropDown] = useState([]);
	const [ClinicWorktimeFilterDropDown, setClinicWorktimeFilterDropDown] = useState([]);
	const [ClinicCountryDropDown, setClinicCountryDropDown] = useState([]);
	const [ClinicStateDropDown, setClinicStateDropDown] = useState([]);
	const [ClinicCityDropDown, setClinicCityDropDown] = useState([]);
	const [FlagData, setFlagData] = useState(false)

	useEffect(() => {
		PracticeListFunction();

	}, []);

	const PracticeListFunction = () => {
		let historyParamsconsent = {
			"userId": window.sessionStorage.getItem('userId'),
			"roleName": window.sessionStorage.getItem("role")

		};
		setFlagData(false)
		callingAPI('commonUserFunctions/practiceDetails', historyParamsconsent)
			.then((response) => {
				if (response.data.success === '1') {
					setFlagData(true)
					if (response.data.result.hospital.length > 0) {
						getAllCountryData(response.data.result.hospital[0].country_id, 'state');
						getAllCountryData(response.data.result.hospital[0].state_id, 'city', response.data.result.hospital[0].country_id);
						setClinicName(response.data.result.hospital[0].name)
						setClinicEmail(response.data.result.hospital[0].email_id)
						setClinicAddress(response.data.result.hospital[0].address)
						setClinicCountry(response.data.result.hospital[0].country_id)
						setClinicState(response.data.result.hospital[0].state_id)
						setClinicCity(response.data.result.hospital[0].city)
						setClinicPinCode(response.data.result.hospital[0].zip)
						setClinicPhone(response.data.result.hospital[0].phone)
						setClinicDeactivateDate(response.data.result.hospital[0].deactive_date)
						setClinicCategory(response.data.result.hospital[0].category_id)
						setClinicCredential(response.data.result.hospital[0].credential_id)
						setClinicType(response.data.result.hospital[0].type_id)
						setClinicWorktimeFilter(response.data.result.hospital[0].time_filter)
						setClinicDisplayName(response.data.result.hospital[0].name)
						setClinicUserName(response.data.result.hospitalAdmin[0].secUserName)
						setClinicWebsiteAddress(response.data.result.hospital[0].website_url)
						setClinicDescription(response.data.result.hospital[0].description)
						setClinicShowHospitalMedicinesOnly(response.data.result.hospital[0].show_hospital_medicines_only)
						setImageName(response.data.result.hospital[0].logo_image)
					}
					if (response.data.result.hospitalDetail.length > 0) {
						setClinicPrimaryEmailIds(response.data.result.hospitalDetail[0].primary_email_ids)
					}
					if (response.data.result.hospitalClassificationDetails.length > 0) {
						setClinicClassification(response.data.result.hospitalClassificationDetails[0].classificationId)
					}
					if (response.data.result.hospitalPaymentDetails.length > 0) {
						setClinicPayment(response.data.result.hospitalPaymentDetails[0].paymentTypeId)
					}

					ApiCallingDropDown()
				}
				else { setFlagData(true) }
			})
			.catch();

	};
	const StateCountrychange = (value) => {
		var e = document.getElementById("countryId");
		var strUser = e.value;
		getAllCountryData(value.split('-')[0], 'state');
		setClinicCountry(value);
	};
	const StateDatachange = (value) => {
		getAllCountryData(value, 'city', ClinicCountry);
		setClinicState(value);

	};
	const onCityDataChange = (value) => {
		setClinicCity(value);
	};
	const getAllCountryData = (value, type, countryID) => {
		let countryId = '';
		let stateId = '';
		let cityId = '';
		if (type === 'country') {
			countryId = '';
		} else if (type === 'state') {
			countryId = value;
		} else if (type === 'city') {
			stateId = value;
			countryId = countryID;
		}
		// else if (type === 'location') {
		// 	stateId = ClinicState.split('-')[0];
		// 	countryId = ClinicCountry.split('-')[0];
		// 	cityId = value;
		// }
		let PostData = {
			country: countryId,
			state: stateId,
			city: cityId,
		};
		callingAPI('doctorProfile/getCountryBased', PostData).then((response) => {
			if (response.data.success === '1') {
				setClinicCountryDropDown(response.data.result.country);
				if (countryId !== '') {
					setClinicStateDropDown(response.data.result.state);
				}
				if (countryId && stateId !== '') {
					setClinicCityDropDown(response.data.result.city);
				}

			}
		});
	};
	const ApiCallingDropDown = () => {
		// getAllCountryData()
		callingAPI('commonUserFunctions/typeDropDown').then((res) => {
			setClinicTypeDropDown(res.data.result.typeResult);
		})
		callingAPI('commonUserFunctions/categoryDropDown').then((res) => {
			setClinicCategoryDropDown(res.data.result.CategoryResult);
		})
		callingAPI('commonUserFunctions/credentialDropDown').then((res) => {
			setClinicCredentialDropDown(res.data.result.credentialResult);
		})
		callingAPI('commonUserFunctions/classificationDropDown').then((res) => {
			setClinicClassificationDropDown(res.data.result.classificationResult);
		})
		callingAPI('commonUserFunctions/paymentTypeDropDown').then((res) => {
			setClinicPaymentDropDown(res.data.result.paymentTypeResult);
		})
		callingAPI('commonUserFunctions/timefilterDropDown').then((res) => {
			setClinicWorktimeFilterDropDown(res.data.result.timefilterResult);
		});
	};
	const setClinicClassifications = (e) => {
		const selectedClassifications = [];
		let selectedOption = e.target.selectedOptions;
		for (let i = 0; i < selectedOption.length; i++) {
			selectedClassifications.push(selectedOption.item(i).value);
			// setClinicClassification((selectedOption.item(i).value))
		}
		setClinicClassification(selectedClassifications);
	};
	const setClinicWorktimeFilters = (e) => {
		const WorktimeFilters = [];
		let selectedOption = e.target.selectedOptions;
		for (let i = 0; i < selectedOption.length; i++) {
			WorktimeFilters.push(selectedOption.item(i).value);
			// setClinicWorktimeFilter((selectedOption.item(i).value));
		}
		setClinicWorktimeFilter(WorktimeFilters);
	};
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const handleImageUploadOnChange = (e) => {
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name.replace(/ /g, '-'));
		setImagePreview(URL.createObjectURL(e.target.files[0]));
	};
	////imageSave
	const imageS3Save = (e) => {
		// setFlagData(true)
		var S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH + '/';
		var fullFilePath = S3BucketMainFolder + localStorage.getItem("hospitalId") + ".png";
		s3.upload(
			{
				Key: fullFilePath,
				Body: file,
				ACL: 'public-read',
			},
			(err, data) => {
				if (err) {
					alert('There was an error uploading your photo: ' + err.message);
					// return null;
				} else {
					setFlagData(true)
					alert('Practice Details Updated Successfully');
					window.location.reload();
				}
			}
		);
	};
	const Cancel = () => {
		window.location.reload();

	}

	const Upadate = () => {
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;

		var ValidationFlag = true
		if (!ClinicName) {
			alert("Clinic Name Requried")
			ValidationFlag = false;
			window.document.getElementById('name').focus();
		}
		else if (!ClinicEmail) {
			ValidationFlag = false
			alert("Email Requried")
			window.document.getElementById('emailId').focus();
		}
		else if (!ClinicEmail.match(email)) {
			ValidationFlag = false
			alert('Enter a Valid Email ID');
			window.document.getElementById('emailId').focus();
		}
		else if (!ClinicAddress) {
			ValidationFlag = false
			alert("Adress Requried")
			window.document.getElementById('address').focus();
		}
		else if (!ClinicCountry) {
			ValidationFlag = false
			alert("Country Requried")
			window.document.getElementById('countryId').focus();
		}
		else if (!ClinicState) {
			ValidationFlag = false
			alert("State Requried")
			window.document.getElementById('stateId').focus();
		}
		else if (!ClinicCity) {
			ValidationFlag = false
			alert("City Requried")
			window.document.getElementById('cityId').focus();
		}
		else if (!ClinicPinCode) {
			ValidationFlag = false
			alert("Pincode Requried")
			window.document.getElementById('zip').focus();
		}
		else if (!ClinicPhone) {
			ValidationFlag = false
			alert("Phone Number Requried")
			window.document.getElementById('phone').focus();
		}
		else if (!ClinicPhone.match(phNum)) {
			ValidationFlag = false
			alert("Enter a Valid Contact Number")
			window.document.getElementById('phone').focus();
			// return false
		}
		// else if (!ClinicPrimaryEmailIds) {
		// 	ValidationFlag = false
		// 	alert("Primary EmailId Requried")
		// 	window.document.getElementById('primaryEmailIds').focus();
		// }
		// else if (ClinicPrimaryEmailIds.match(email)) {
		// 	ValidationFlag = false
		// 	alert('Enter a Valid Email ID');
		// 	window.document.getElementById('primaryEmailIds').focus();
		// }

		// else if (!ClinicDeactivateDate) {
		// 	ValidationFlag = false
		// 	alert("DeactiveDate Requried")
		// 	window.document.getElementById('cityid').focus();
		// }
		else if (!ClinicCategory) {
			ValidationFlag = false
			alert("Category Requried")
			window.document.getElementById('category').focus();
		}
		else if (!ClinicCredential) {
			ValidationFlag = false
			alert("Credential  Requried")
			window.document.getElementById('credential').focus();
		}
		else if (!ClinicType) {
			ValidationFlag = false
			alert("Type Requried")
			window.document.getElementById('hospitalType').focus();
		}
		else if (!ClinicClassification) {
			ValidationFlag = false
			alert("Classification Requried")
			window.document.getElementById('classification').focus();
		}
		else if (!ClinicPayment) {
			ValidationFlag = false
			alert("Payment Type Requried")
			window.document.getElementById('paymentType').focus();
		}
		else if (!ClinicWorktimeFilter) {
			ValidationFlag = false
			alert("WorkTime Filter Requried")
			window.document.getElementById('classification').focus();
		}
		else if (!ClinicDisplayName) {
			ValidationFlag = false
			alert("Display Name Requried")
			window.document.getElementById('displayName').focus();
		} else if (!ClinicUserName) {
			ValidationFlag = false
			alert("UserName Requried")
			window.document.getElementById('username').focus();
		}
		else if (!ClinicDescription) {
			ValidationFlag = false
			alert("Description Requried")
			window.document.getElementById('description').focus();
		}
		if (ValidationFlag === true) {
			let PostData = {
				"hospitalId": localStorage.getItem("hospitalId"),
				"categoryId": ClinicCategory,
				"cityName": ClinicCity,
				"countryId": ClinicCountry,
				"credentialId": ClinicCredential,
				"deActivateDate": moment(ClinicDeactivateDate).format('yyyy-mm-DD'),
				"description": ClinicDescription,
				"emailId": ClinicEmail,
				"phone": ClinicPhone,
				"stateId": ClinicState,
				"timeFilterId": ClinicWorktimeFilter.toString(),
				"typeId": ClinicType.toString(),
				"websiteUrl": ClinicWebsiteAddress,
				"zip": ClinicPinCode,
				"address": ClinicAddress,
				"logoImage": imageName,
				"name": ClinicName,
				"showHospitalMedicinesOnly": ClinicShowHospitalMedicinesOnly,
				"primaryEmailId": ClinicPrimaryEmailIds,
				"classificationId": ClinicClassification.toString(),
				"paymentTypeId": ClinicPayment.toString(),
				"userName": ClinicUserName,
				"displayName": ClinicDisplayName

			}
			setFlagData(false)
			callingAPI('commonUserFunctions/practiceHospitalUpdate', PostData).then((response) => {
				if (response.data.success === '1') {
					if (file !== "") { imageS3Save(); }
					else {
						setFlagData(true)
						alert('Practice Details Updated Successfully');
						window.location.reload();
					}
					// imageS3Save();
					// setFlagData(true)
					// if (file !== '' || imageName !== '') {


					// 
					// }

					// window.location.reload();

				}
				else {
					alert(response.data.errorMessage);
					setFlagData(true)
				}
			})
		}
	};
	return (
		<div className="dashboardCover settingsHealthCard practiceDetailsWraper">
			<div className="pl_youtube">
				{/* <img src={Youtube} /> */}
			</div>
			<div className="dashboardTitle">Practice Details</div>
			<div className="dashboardContent">
				<div class="practiceManagementForm">
					<div class="col-md-12 col-lg-12">
						{/* <div class="col-md-6 col-lg-6">
							<h3 class="practiceHead">Settings- <span>Practice Details</span></h3>
						</div> */}
						{/* <div class="col-md-6 col-lg-6 videoHelpCvrDiv">




							<div class="helpVideoWrap" id="resizeDiv">
								<div class="helpVideoClose" title="Close"  >x</div>
								<div class="videoSection">
									<video id="videoDemo" controls="">

									</video>
								</div>
							</div>
						</div> */}

						{FlagData == false &&
							<div align="center">
								<Loader />
							</div>}

						<div class="serviceInfoWrap">
							<div class="serviceListMain">
								<label for="" class="smalLbl">

									Clinic Name


									<span class="mandatory">*</span></label>
								<input onChange={(e) => setClinicName(e.target.value)} name="ClinicName" placeholder="Hospital Name" id="name" class="smyLargstField" type="text" value={ClinicName} maxlength="100" autofocus="autofocus" />

							</div>
							<div class="serviceListMain" style={{ display: 'flex', justifyContent: 'flex-end' }}>

								<div class="profileImgRight">
									<div class="profileImg" style={{ width: '100%' }}>

										{/* <div class="webCamImg">
												<img
												 src={imagePreview ? imagePreview : s3Path + '/' + imageName}
												  id="imagesLoad" width="120px" height="90px" />
												<div class="profileImgBtn" style={{ marginBottom: "15px" }}>
													<a id="uploadLogo" style={{ color: '#337ab7' }} onClick={(e) => handleImageUploadOnChange(e)}  >Change Logo</a>
												</div>
											</div> */}
										{/* <div class="btnFileUpload practiceGreenButton" id="uploadDiv"  >
												<label for="uploadpc">Upload</label>
												<input type="file" id="uploadpc" 
												
												/>
											</div> */}
										<div class="serviceListMain">
											<label for="Image" class="smalLbl">
												Image(jpeg,png)
											</label>
											<div
												class="fileUpload_upload btn_upload btn-primary"
												style={{ float: 'left' }}
											>
												<span>Upload</span>
												<input
													type="file"
													id="file1"
													accept="image/png, image/jpeg"
													name="file1"
													onChange={(e) => handleImageUploadOnChange(e)}
													class="upload_upload"
												/>
											</div>
											{imageName !== null && (
												<div class="bannerTumb mrg_top" id="divThumb" title="">
													<img
														id="imageThumbdemo"
														src={imagePreview ? imagePreview : s3Path + '/' + localStorage.getItem("hospitalId") + ".png"}
														style={{
															width: '35px',
															height: '35px',
															float: 'left',
															marginLeft: '20px',
															objectFit: 'contain',
														}}
													/>
												</div>
											)}
										</div>

									</div>
								</div>
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Email<span class="mandatory">*</span></label>
								<input
									onChange={(e) => setClinicEmail(e.target.value)}
									name="ClinicEmail" placeholder="Email" id="emailId" class="smyLargstField" type="text" value={ClinicEmail} />
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Address<span class="mandatory">*</span></label>
								<input name="ClinicAddress"
									onChange={(e) => setClinicAddress(e.target.value)}
									placeholder="Address" id="address" class="smyLargstField" type="text" value={ClinicAddress} maxlength="250" />
							</div>

							<div class="serviceListMain">
								<label for="" class="smalLbl">Country<span class="mandatory">*</span></label>
								<select class="smyLargstField" name="country.id" id="countryId" value={ClinicCountry}
									//   onChange={(e) => setClinicCountry(e.target.value)}
									onChange={(e) => StateCountrychange(e.target.value)}
								>
									{ClinicCountryDropDown.map((ClinicCountryDropDownlist) => (
										<option value={ClinicCountryDropDownlist.countryId}>
											{ClinicCountryDropDownlist.countryName}
										</option>
									))}
								</select>
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">State<span class="mandatory">*</span></label>
								<select class="smyLargstField" name="state.id" id="stateId" value={ClinicState}
									//  onChange={(e) => setClinicState(e.target.value)} 
									onChange={(e) => StateDatachange(e.target.value)}
								>
									{ClinicStateDropDown.map((ClinicStateDropDownlist) => (
										<option value={ClinicStateDropDownlist.stateId}>
											{ClinicStateDropDownlist.stateName}
										</option>
									))}

								</select>

							</div>
							<div class="serviceListMain">

								<label for="" class="smalLbl">City<span class="mandatory">*</span></label>
								<select class="smyLargstField" name="city" id="cityId" value={ClinicCity}
									//  onChange={(e) => setClinicCity(e.target.value)}
									onChange={(e) => onCityDataChange(e.target.value)}
								>
									{ClinicCityDropDown.map((ClinicCityDropDownlist) => (
										<option value={ClinicCityDropDownlist.cityName}>
											{ClinicCityDropDownlist.cityName}
										</option>
									))}

								</select>

							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Pin Code<span class="mandatory">*</span></label>
								<input
									onChange={(e) => setClinicPinCode(e.target.value)}
									name="ClinicPinCode" placeholder="Pin Code" id="zip" class="smyLargstField" type="text" value={ClinicPinCode} maxlength="20" />
							</div>

							<div class="serviceListMain">
								<label for="" class="smalLbl">Phone<span class="mandatory">*</span></label>
								<input
									onChange={(e) => setClinicPhone(e.target.value)}
									name="ClinicPhone" placeholder="Phone" id="phone" class="smyLargstField" type="text" value={ClinicPhone} maxlength="50" />
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Primary Email Ids<span class="mandatory">*</span></label>
								<input
									onChange={(e) => setClinicPrimaryEmailIds(e.target.value)}
									name="ClinicPrimaryEmailIds" placeholder="Primary Email Ids" id="primaryEmailIds"
									// readOnly 
									class="smyLargstField"
									value={ClinicPrimaryEmailIds} type="text" />
							</div>

							<div class="serviceListMain">
								<label for="" class="smalLbl">Deactivate Date<span class="mandatory">*</span></label>
								<input
									onChange={(e) => setClinicDeactivateDate(e.target.value)}
									name="ClinicDeactivateDate" placeholder="Deactivate Date" id="deactiveDate" class="smyLargstFieldBg" type="text" value={moment(ClinicDeactivateDate).format('DD/MM/yyyy')} readonly="readonly" />
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Category<span class="mandatory">*</span></label>
								<select class="smyLargstField" id="category" value={ClinicCategory}
									onChange={(e) => setClinicCategory(e.target.value)}
								><option value="">Select</option>
									{ClinicCategoryDropDown.map((ClinicCategoryDropDownlist) => (
										<option value={ClinicCategoryDropDownlist.id}>
											{ClinicCategoryDropDownlist.name}
										</option>
									))}
								</select>
							</div>

							<div class="serviceListMain">
								<label for="" class="smalLbl">Credential<span class="mandatory">*</span></label>
								<select class="smyLargstField" id="credential" value={ClinicCredential}
									onChange={(e) => setClinicCredential(e.target.value)}
								><option value="">Select</option>
									{ClinicCredentialDropDown.map((ClinicCredentialDropDownlist) => (
										<option value={ClinicCredentialDropDownlist.id}>
											{ClinicCredentialDropDownlist.credential}
										</option>
									))}

								</select>
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Type<span class="mandatory">*</span></label>
								<select class="smyLargstField" id="hospitalType" value={ClinicType}
									onChange={(e) => setClinicType(e.target.value)}
								><option value="">Select</option>
									{ClinicTypeDropDown.map((ClinicTypeDropDownlist) => (
										<option value={ClinicTypeDropDownlist.id}>
											{ClinicTypeDropDownlist.type}
										</option>
									))}
								</select>

							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Classification<span class="mandatory">*</span></label>
								<select
									onChange={(e) => setClinicClassifications(e)}
									class="smyLargstFieldMulti" name="ClinicClassification" value={ClinicClassification.toString()} id="classification" multiple={true} >
									<option value="">Select</option>
									{ClinicClassificationDropDown.map((ClinicClassificationDropDownlist) => (

										<option value={ClinicClassificationDropDownlist.id}>
											{ClinicClassificationDropDownlist.classification}
										</option>
									))}

								</select>
							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Payment<span class="mandatory">*</span></label>
								<select
									onChange={(e) => setClinicPayment(e.target.value)}
									class="smyLargstFieldMulti" value={ClinicPayment} name="ClinicPayment" id="paymentType"  >
									<option value="">Select</option>
									{ClinicPaymentDropDown.map((ClinicPaymentDropDownlist) => (
										<option value={ClinicPaymentDropDownlist.id}>
											{ClinicPaymentDropDownlist.paymentType}
										</option>
									))}

								</select>
							</div>
							<div class="serviceListMain">
								<label for="First Name" class="smalLbl">Worktime Filter<span class="mandatory">*</span></label>
								{/* <label for="" class="smalLbl">Classification<span class="mandatory">*</span></label> */}
								<select
									onChange={(e) => setClinicWorktimeFilters(e)}
									class="smyLargstFieldMulti" name="ClinicClassification" value={ClinicWorktimeFilter.toString()} id="classification" multiple={true} >
									<option value="">Select</option>
									{ClinicWorktimeFilterDropDown.map((ClinicWorktimeFilterDropDownlist) => (
										<option value={ClinicWorktimeFilterDropDownlist.id}>
											{ClinicWorktimeFilterDropDownlist.timefilterName}
										</option>
									))}

								</select>
								{/* <select value={ClinicWorktimeFilter}
									 onChange={(e) => setClinicWorktimeFilter(e.target.value)}  
									id="timeFilterId" class="smyLargstFieldMulti" name="timeFilter"  >
										<option value="">Select</option>
										{ClinicWorktimeFilterDropDown.map((ClinicWorktimeFilterDropDownlist) => (
															<option value={ClinicWorktimeFilterDropDownlist.id}>
																{ClinicWorktimeFilterDropDownlist.timefilterName}
															</option>
														))}
									</select> */}

							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Display Name<span class="mandatory">*</span></label>
								<input
									onChange={(e) => setClinicDisplayName(e.target.value)}
									name="ClinicDisplayName" placeholder="" id="displayName" class="smyLargstField" type="text" value={ClinicDisplayName} />

							</div>

							<div class="serviceListMain">
								<label for="" class="smalLbl">User Name<span class="mandatory">*</span></label>
								<input
									//onChange={(e) => setClinicUserName(e.target.value)}
									readOnly
									name="ClinicUserName" placeholder="Email Id Needed" id="username" class="smyLargstField" type="text" value={ClinicUserName} />

							</div>
							<div class="serviceListMain">
								<label for="" class="smalLbl">Website Address</label>
								<input
									onChange={(e) => setClinicWebsiteAddress(e.target.value)}
									name="ClinicWebsiteAddress" id="webAddress" class="smyLargstField" type="text" value={ClinicWebsiteAddress} />
							</div>
							<div class="serviceListMain">
								<label for="Description" class="mcu">Description<span class="mandatory">*</span></label>
								<div class="clearfix"></div>

								<textarea
									onChange={(e) => setClinicDescription(e.target.value)}
									name="ClinicDescription" rows="20" cols="20" id="description" value={ClinicDescription} placeholder="Description" class="mgalrgTxtareaNew" maxlength="5000" style={{ resize: 'none' }}></textarea>

							</div>
							<div class="serviceListMain">
								<label for="Hospital Medicines Only" class="mcu">Show Hospital Medicines Only</label>

								<input
									onChange={(e) => setClinicShowHospitalMedicinesOnly(e.target.checked)}
									checked={ClinicShowHospitalMedicinesOnly}
									type="checkbox" name="ClinicShowHospitalMedicinesOnly" id="chkHospMed" />

							</div>
							<div class="right rightBtn" style={{ padding: "3px" }}>
								<input type="button" value="Update" class="practiceGreenButton" onClick={(e) => Upadate()} title="Update" id="saveform" style={{ color: '#000', fontWeight: '600' }} />


								<button class="practiceGreyButton" onClick={(e) => Cancel()} title="Cancel">Cancel</button>

							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
	);
}
export default PracticeDetails;
