import { faFireExtinguisher } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';
function HealthBackGroundSub(props) {
	const [clearValue, setClear] = useState('');
	const [style, setStyle] = useState({ display: 'none' });
	const [clinicalBackground, setClinicalBaground] = useState([]);
	const [pushArray, setPushArray] = useState([])
	const saveInputValue = (event) => {
		let flaginput = true;
		if (event.key === 'Enter') {
			for (var i = 0; i < clinicalBackground.length; i++) {
				var item = clinicalBackground[i].name
				if (clearValue === item) {
					setClear("")
					flaginput = false
				}
			}
		}
		if (clearValue === '' && event.key === 'Enter') {
			flaginput = false;
			setClear('');
		}

		if (flaginput === true && event.key === 'Enter' && clearValue.trim() !== '') {
			var obj = {
				"Id": "",
				"name": clearValue
			}
			clinicalBackground.push(obj)
			setClear("")
			let saveData = {
				history: clearValue,
				type: `save${props.type}`,
				memberId: window.sessionStorage.getItem('memberId'),
				userId: window.sessionStorage.getItem('userId'),
				hospitalId: localStorage.getItem('hospitalId'),
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
			};
			callingAPI('clinicalBackground/saveRemoveClinicalBackground', saveData)
				.then((response) => {
					if (response.data.success === '1') {
						clinicalList();
						setClear('');
					}
				})
				.catch();
		}
	};
	const deleteValue =(Idvalue)=>{
		let deleteData = {
			Id: Idvalue,
			history: 'removeHistory',
			type: `remove${props.type}`,
			memberId: window.sessionStorage.getItem('memberId'),
			userId: window.sessionStorage.getItem('userId'),
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
		};
		callingAPI('clinicalBackground/saveRemoveClinicalBackground', deleteData)
			.then((response) => {
				if (response.data.success === '1') {
					clinicalList();
				}
			})
			.catch();
	};
	const clearInput = (e) => {
		setClear(e.target.value);
	};
	const clinicalList = () => {
		let clinicalData = {
			patientId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			type: props.type,
		};
		callingAPI('clinicalBackground/clinicalBackgroundList', clinicalData)
			.then((response) => {
				if (response.data.success === '1') {
					setClinicalBaground(response.data.result.clinicalBackgroundList);
				}
			})
			.catch();
	};
	const qaz1 = (i) => {
		document.getElementById('qwe' + i).style.visibility = 'hidden';
	};
	const qaz = (i) => {
		document.getElementById('qwe' + i).style.visibility = 'visibile';
		document.getElementById('qwe' + i).style.display = 'inline';
	};
	useEffect(() => {
		clinicalList();
	}, []);
	return (
		<div>
			<div id="appendNotifyDefault">
				<div id="updateNotifyDiv6" class="rightTagTxt rightTagTxtgreen">
					<label class="">
						{clinicalBackground.map((data, i) => (
							<li className = "healthbackground"
								style={{ listStyleType: 'none' }}
								onMouseEnter={(e) => qaz(data.Id)}
								onMouseLeave={(e) => qaz1(data.Id)}
							>

								{data.name}
								<span
									style={{ visibility: 'hidden', marginLeft: '5px', color: 'red' }}
									id={'qwe' + data.Id}
									onClick={(e)=>deleteValue(data.Id)}
								>
									x
								</span>{' '}
							</li>
						))}
					</label>
				</div>
			</div>
			<div>
				<input
					onKeyUp={saveInputValue}
					value={clearValue}
					onChange={clearInput}
					type="text"
					className="inputField"
					placeholder="Click To Add"
				/>
			</div>
		</div>
	);
}
export default HealthBackGroundSub;
