import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Active from '../images/icons/active.png';
import { Link } from 'react-router-dom';
import Inactive from '../images/icons/inactive.png';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
function AddToGroup(props) {
	const [drop, setDrop] = useState([]);
	const [finalArray, setFinalArray] = useState([]);
	const [hospitalID, sethospitalID] = useState(0);
	const [load, setLoad] = useState(false);
	const addValue = () => {
		var flag = true;
		var copyDataArr = [...finalArray];
		copyDataArr.map((val, idx) => {
			if (val.hospitalPatientGroupId === Number(hospitalID)) {
				alert('Group Already Added');
				document.getElementById('hospitalGroup').value = "0"
				flag = false;
			}
		});
		if (Number(hospitalID) === 0) {
			alert('Please Select Groups');
			flag = false;
		}
		if (flag == true) {
			let postData = {
				hospitalPatientGroupId: hospitalID,
				memberId: window.sessionStorage.getItem('memberId'),
			};
			callingAPI('patientProfile/addPatientToHospitalGroup', postData)
				.then((data) => {
					if (data.data.success === '1') {
						alert('Group Added Successfully');

						getListValues();

					}
				})
				.catch();
		}
	};
	const dropDownValue = (event) => {
		sethospitalID(event.target.value);
	};
	const dropdownvalueChange = () => {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('patientProfile/addGroup', PostData)
			.then((data) => {
				if (data.data.success == 1) {
					setDrop(data.data.result);
					setLoad(true);
					document.getElementById('hospitalGroup').value = "0"
				}
			})
			.catch();
	};
	const cancelUpdate = () => {
		window.location.href = '/patients/patientconsult';
	};
	const activateStatus = (id, status) => {
		const postData = {
			isActive: status,
			memberHospitalPatientGroupId: id,
		};
		callingAPI('patientProfile/activateMember', postData)
			.then((data) => {
				if (data.data.success === '1') {
					getListValues();
				}
			})
			.catch();
	};
	const getListValues = () => {
		const PostData = {
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('patientProfile/listPatientGroups', PostData)
			.then((data) => {
				setFinalArray(data.data.result.hospitalPatientGroupData);
				setLoad(true);
				document.getElementById('hospitalGroup').value = "0"
			})
			.catch();
	};
	useEffect(() => {
		dropdownvalueChange();
		getListValues();
		document.getElementById('hospitalGroup').value = "0"
	}, []);
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div>
									{load === false ? <Loader /> : ''}
									<div className="treatmentProfile">
										<div className="treatmentProfileCover">
											<div className="treatmentPlanHead">
												<span className="treatmentPlanName">Patient Profile </span>
												<div id="treatmentPlanBtnEdit" className="treatmentPlanBtn">
													<Link to="/patients/addToGroup">
														<span className="practicementBtn">Add to Group</span>
													</Link>
													<Link to="/patients/patientconsult/Edit">
														<span className="practicementBtn">Edit Patient Profile</span>
													</Link>
												</div>
											</div>
											<div id="patientProfileId" className="treatmentPlanContent">
												<div
													class="treatmentProfileCover memberProfileDiv_PTour"
													id="patientGroup"
													style={{
														background: '#fff',
														padding: '30px 15px',
													}}
												>
													<div class="patientGroupDiv" id="showGroups">
														<div class="patientGroupTop">
															<label>Active Groups : &nbsp;</label>
															<span>
																<select
																	name="hospitalGroup"
																	class="dentalFilesSelectType_PTour"
																	id="hospitalGroup"
																	value={hospitalID}
																	onChange={dropDownValue}
																>
																	<option value="0">Select Group</option>
																	{drop.map((data) => (
																		<option value={data.hospitalPatientGroupId}>
																			{data.hospitalPatientGroupName}
																		</option>
																	))}
																</select>
																<span class="GroupAddButton">
																	<input
																		type="button"
																		value="Add"
																		class="commonButton"
																		onClick={addValue}
																	/>
																	<input
																		type="button"
																		value="Cancel"
																		onClick={cancelUpdate}
																		class="commonButtonCancel"
																	/>
																</span>
															</span>
														</div>
														<br />
														<div id="patientsInGroups" class="patientGroupSec">
															{finalArray.length > 0 &&
																finalArray.map((data, idx) => (
																	<div class="patientGroupItem">
																		<span class="patientGroupName">
																			{data.hospitalPatientGroupName}
																		</span>
																		<span class="patientClose">
																			{data.isActive === 0 ? (
																				<img
																					src={Inactive}
																					title="Click to Activate"
																					alt=""
																					onClick={() => {
																						activateStatus(
																							data.memberHospitalPatientGroupId,
																							true
																						);
																					}}
																				/>
																			) : (
																				<img
																					src={Active}
																					alt=""
																					title="Click to Deactivate"
																					className="mr-2  conf-cursorpointer"
																					width="20"
																					onClick={() => {
																						activateStatus(
																							data.memberHospitalPatientGroupId,
																							false
																						);
																					}}
																				/>
																			)}
																		</span>
																	</div>
																))}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-12 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default AddToGroup;
