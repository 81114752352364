import React, { useState, useEffect } from 'react'
import img1 from '../images/dental/32-teeth/1.png';
import img2 from '../images/dental/32-teeth/2.png';
import img3 from '../images/dental/32-teeth/3.png';
import img4 from '../images/dental/32-teeth/4.png';
import img5 from '../images/dental/32-teeth/5.png';
import img6 from '../images/dental/32-teeth/6.png';
import img7 from '../images/dental/32-teeth/7.png';
import img8 from '../images/dental/32-teeth/8.png';
import img9 from '../images/dental/32-teeth/9.png';
import img10 from '../images/dental/32-teeth/10.png';
import img11 from '../images/dental/32-teeth/11.png';
import img12 from '../images/dental/32-teeth/12.png';
import img13 from '../images/dental/32-teeth/13.png';
import img14 from '../images/dental/32-teeth/14.png';
import img15 from '../images/dental/32-teeth/15.png';
import img16 from '../images/dental/32-teeth/16.png';
import img1btm from '../images/dental/32-teeth/1-bottom.png';
import img2btm from '../images/dental/32-teeth/2-bottom.png';
import img3btm from '../images/dental/32-teeth/3-bottom.png';
import img4btm from '../images/dental/32-teeth/4-bottom.png';
import img5btm from '../images/dental/32-teeth/5-bottom.png';
import img6btm from '../images/dental/32-teeth/6-bottom.png';
import img7btm from '../images/dental/32-teeth/7-bottom.png';
import img8btm from '../images/dental/32-teeth/8-bottom.png';
import img9btm from '../images/dental/32-teeth/9-bottom.png';
import img10btm from '../images/dental/32-teeth/10-bottom.png';
import img11btm from '../images/dental/32-teeth/11-bottom.png';
import img12btm from '../images/dental/32-teeth/12-bottom.png';
import img13btm from '../images/dental/32-teeth/13-bottom.png';
import img14btm from '../images/dental/32-teeth/14-bottom.png';
import img15btm from '../images/dental/32-teeth/15-bottom.png';
import img16btm from '../images/dental/32-teeth/16-bottom.png';
import img_1 from '../images/dental/20-teeth/1.png';
import img_2 from '../images/dental/20-teeth/2.png';
import img_3 from '../images/dental/20-teeth/3.png';
import img_4 from '../images/dental/20-teeth/4.png';
import img_5 from '../images/dental/20-teeth/5.png';
import img_6 from '../images/dental/20-teeth/6.png';
import img_7 from '../images/dental/20-teeth/7.png';
import img_8 from '../images/dental/20-teeth/8.png';
import img_9 from '../images/dental/20-teeth/9.png';
import img_10 from '../images/dental/20-teeth/10.png';
import img_1btm from '../images/dental/20-teeth/1-bottom.png';
import img_2btm from '../images/dental/20-teeth/2-bottom.png';
import img_3btm from '../images/dental/20-teeth/3-bottom.png';
import img_4btm from '../images/dental/20-teeth/4-bottom.png';
import img_5btm from '../images/dental/20-teeth/5-bottom.png';
import img_6btm from '../images/dental/20-teeth/6-bottom.png';
import img_7btm from '../images/dental/20-teeth/7-bottom.png';
import img_8btm from '../images/dental/20-teeth/8-bottom.png';
import img_9btm from '../images/dental/20-teeth/9-bottom.png';
import img_10btm from '../images/dental/20-teeth/10-bottom.png';
function TeethSection(props) {
    const [showPediatric, setshowPediatricTeeth] = useState(false)
    const [teethArray, setTeethArray] = useState([])

    useEffect(() => {

        if (props.teethArrayPass.length > 0) {
            editTeethNumber()
            var array = [...teethArray]
            props.teethArrayPass.map((data, i) => {
                var obj = {
                    teethNumber: data,
                }
                array.push(obj)
                setTeethArray(array)
            })
        }
    }, []);
    const editTeethNumber = () => {

        if (props.teethArrayPass.length > 1) {
            props.teethArrayPass.map((data, i) => {
                var element = document.getElementsByClassName("teethImg" + data);
                var elementCheck = element[0].classList
                if (elementCheck.length <= 3) {
                    element[0].classList.add("teethActive");
                    var teethArraySelect = [...teethArray]
                    var obj = {
                        teethNumber: data,
                    }
                    teethArraySelect.push(obj)
                    setTeethArray(teethArraySelect)
                }
            })
        }
    }

    const setTeethNumber = (teethNumber,) => {
        var element = document.getElementsByClassName("teethImg" + teethNumber);
        var elementCheck = element[0].classList
        if (elementCheck.length <= 3) {
            element[0].classList.add("teethActive");
            var teethArraySelect = [...teethArray]
            var obj = {
                teethNumber: teethNumber,
            }
            teethArraySelect.push(obj)
            setTeethArray(teethArraySelect)
            props.getTeethArray(teethArraySelect)
        }
        else {
            element[0].classList.remove("teethActive");
            var teethArrayDeSelect = [...teethArray]
            var teethArrayDeSelectObj = teethArrayDeSelect.filter((data) => data.teethNumber !== teethNumber);
            setTeethArray(teethArrayDeSelectObj)
            props.getTeethArray(teethArrayDeSelectObj)
        }

    }
    const showPediatricTeeth = (i) => {
        setshowPediatricTeeth(!showPediatric)
    }

    return (
        <div
            class="treatmentTeethWrap1"
            id={'showTeethDiv' + props.index}
            style={{
                display:
                    'block'
            }}
        >
            <div class="teethWrap">
                <div class="teethSec">
                    <div class="teethContent">
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(18)}
                        >
                            <span>18</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg18"}>
                                <img src={img1} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(17)}
                        >
                            <span>17</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg17"}>
                                <img src={img2} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(16)}
                        >
                            <span>16</span>
                            <div
                                class={"teethImg teethImg" + props.index + " teethImg16"}
                                id="teethImg16308801114379"
                            >
                                <img src={img3} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(15)}
                        >
                            <span>15</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg15"}>
                                <img src={img4} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(14)}
                        >
                            <span>14</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg14"}>
                                <img src={img5} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(13)}
                        >
                            <span>13</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg13"}>
                                <img src={img6} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(12)}
                        >
                            <span>12</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg12"}>
                                <img src={img7} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(11)}
                        >
                            <span>11</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg11"}>
                                <img src={img8} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(21)}
                        >
                            <span>21</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg21"}>
                                <img src={img9} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(22)}
                        >
                            <span>22</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg22"}>
                                <img src={img10} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(23)}
                        >
                            <span>23</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg23"}>
                                <img src={img11} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(24)}
                        >
                            <span>24</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg24"}>
                                <img src={img12} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(25)}
                        >
                            <span>25</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg25"}>
                                <img src={img13} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(26)}
                        >
                            <span>26</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg26"}>
                                <img src={img14} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(27)}
                        >
                            <span>27</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg27"}>
                                <img src={img15} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(28)}
                        >
                            <span>28</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg28"}>
                                <img src={img16} />
                            </div>
                        </div>
                    </div>
                    <div class="teethContent">
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(48)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg48"}>
                                <img src={img1btm} />
                            </div>
                            <span>48</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(47)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg47"}>
                                <img src={img2btm} />
                            </div>
                            <span>47</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(46)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg46"}>
                                <img src={img3btm} />
                            </div>
                            <span>46</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(45)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg45"}>
                                <img src={img4btm} />
                            </div>
                            <span>45</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(44)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg44"}>
                                <img src={img5btm} />
                            </div>
                            <span>44</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(43)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg43"}>
                                <img src={img6btm} />
                            </div>
                            <span>43</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(42)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg42"}>
                                <img src={img7btm} />
                            </div>
                            <span>42</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(41)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg41"}>
                                <img src={img8btm} />
                            </div>
                            <span>41</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(31)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg31"}>
                                <img src={img9btm} />
                            </div>
                            <span>31</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(32)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg32"}>
                                <img src={img10btm} />
                            </div>
                            <span>32</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(33)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg33"}>
                                <img src={img11btm} />
                            </div>
                            <span>33</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(34)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg34"}>
                                <img src={img12btm} />
                            </div>
                            <span>34</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(35)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg35"}>
                                <img src={img13btm} />
                            </div>
                            <span>35</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(36)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg36"}>
                                <img src={img14btm} />
                            </div>
                            <span>36</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(37)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg37"}>
                                <img src={img15btm} />
                            </div>
                            <span>37</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(38)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg38"}>
                                <img src={img16btm} />
                            </div>
                            <span>38</span>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    class="childBtn"
                    style={{ textAlign: 'center' }}
                >
                    <span
                        class="childTeethShow"
                        style={{ color: '#00abff' }}
                        id={"paediatricTeethDiv" + props.index}
                        onClick={() =>
                            showPediatricTeeth(props.index)}
                    >
                        Show Paediatric Teeth
                    </span>
                </div>
                <br />
                <div
                    class="teethSec"
                    id={'pediatricTeethDiv' + props.index}
                    style={{ display: showPediatric === false ? 'none' : "block" }}
                >
                    <div class="teethContent">
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(55)}
                        >
                            <span>55</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg55"}>
                                <img src={img_1} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(54)}
                        >
                            <span>54</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg54"}>
                                <img src={img_2} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(53)}
                        >
                            <span>53</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg53"}>
                                <img src={img_3} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(52)}
                        >
                            <span>52</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg52"}>
                                <img src={img_4} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(51)}
                        >
                            <span>51</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg51"}>
                                <img src={img_5} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(61)}
                        >
                            <span>61</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg61"}>
                                <img src={img_6} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(62)}
                        >
                            <span>62</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg62"}>
                                <img src={img_7} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(63)}
                        >
                            <span>63</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg63"}>
                                <img src={img_8} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(64)}
                        >
                            <span>64</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg64"}>
                                <img src={img_9} />
                            </div>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(65)}
                        >
                            <span>65</span>
                            <div class={"teethImg teethImg" + props.index + " teethImg65"}>
                                <img src={img_10} />
                            </div>
                        </div>
                    </div>
                    <div class="teethContent">
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(85)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg85"}>
                                <img src={img_1btm} />
                            </div>
                            <span>85</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(84)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg84"}>
                                <img src={img_2btm} />
                            </div>
                            <span>84</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(83)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg83"}>
                                <img src={img_3btm} />
                            </div>
                            <span>83</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(82)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg82"}>
                                <img src={img_4btm} />
                            </div>
                            <span>82</span>
                        </div>
                        <div class="teethList">
                            <div
                                class={"teethImg teethImg" + props.index + " teethImg81"}
                                onClick={() =>
                                    setTeethNumber(81)}
                            >
                                <img src={img_5btm} />
                            </div>
                            <span>81</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(71)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg71"}>
                                <img src={img_6btm} />
                            </div>
                            <span>71</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(72)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg72"}>
                                <img src={img_7btm} />
                            </div>
                            <span>72</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(73)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg73"}>
                                <img src={img_8btm} />
                            </div>
                            <span>73</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(74)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg74"}>
                                <img src={img_9btm} />
                            </div>
                            <span>74</span>
                        </div>
                        <div
                            class="teethList"
                            onClick={() =>
                                setTeethNumber(75)}
                        >
                            <div class={"teethImg teethImg" + props.index + " teethImg75"}>
                                <img src={img_10btm} />
                            </div>
                            <span>75</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TeethSection