import React, { Component, useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import HelpDesk from '../images/icons/helpDesk.png';
function ExportCalendar() {

    const exportClick = () => {
        // Create Base64 Object

    }

    return (
        <div>
            <div class="pulseContentCoverWraper exportCalndrSec">
                <Header />
                <form >
                    <div class="body" id="calendar">
                        <div class="calenderGoogleForm">
                            <div class="calenderGooglehead">
                                Export to Google Calendar
                            </div>
                            <div class="calenderGoogleList">
                                <label> Hospital</label>
                                <select name="cmbHospital" id="cmbHospital"  >
                                    <option >IDA Test clinic </option>
                                </select>
                            </div>
                            <div class="calenderGoogleList">
                                <div class="checkbox checkbox-warning">
                                    <input id="datatype" name="datatype" type="checkbox" />
                                    <label for="datatype">Only new data</label>
                                </div>
                            </div>
                            <div class="calenderGoogleBtn">
                                <input type="button" name="btnGenerateCal" id="btnGenerateCal" value="Export"
                                    onClick={exportClick}
                                    class="checkerButton" />
                            </div>
                            <div id="copyBtn" style={{ display: "block" }} >
                                <label>Copy the link below&nbsp;</label>
                                {/*  */}
                                <a href="#exportModal"><span style={{ fontSize: "20px" }}>
                                    <img src={HelpDesk} />
                                </span></a>
                                <div id="exportModal" class="modalDialog">
                                    <div>
                                        <a href="#close" title="Close" class="close exportClose">X</a>
                                        <div class="modal-header" style={{ display: "block" }}>
                                            <h3 class="modal-title practiceDarkClr">
                                                <div  > Steps to add to google calendar</div>
                                            </h3>
                                            <span style={{ display: "block", padding: "15px", paddingLeft: "0px" }}>
                                                <ul>
                                                    <li>1. Copy the link</li>
                                                    <li>2. Open  Google calendar &gt; other calendar &gt; Add by Url </li>
                                                    <li>3. Paste the copied link  </li>
                                                    <li>4. Click 'Add Calendar'  </li>
                                                </ul>
                                                The appointment(s) get imported !!!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}
                                <div id="generatedlink">http://www.idaksbassist.com/calendar/getDrcalendar?token=1646047648597:MzAzNjA2&amp;hospitalids=11437:&amp;datatype=newdata</div>
                                <div id="outlooklink"><a class="pr-blue" ng-href="webcal://www.idaksbassist.com/calendar/getDrcalendar?token=1646047648597:MzAzNjA2&amp;hospitalids=11437:&amp;datatype=newdata" href="webcal://www.idaksbassist.com/calendar/getDrcalendar?token=1646047648597:MzAzNjA2&amp;hospitalids=11437:&amp;datatype=newdata">Outlook/ICal Link</a></div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}
export default ExportCalendar