import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callingAPI } from '../config';
import {
    faAngleRight,
    faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import NoLabOrderList from '../Common/noLabOrderList';
import Pagination from 'react-js-pagination';
import LabOrderSave from './labOrder';

function LabOrderList() {
    const [clcikFlagActive, setClickFlagActive] = useState(false)
    const [clcikFlagSent, setClickFlagSent] = useState(false)
    const [clcikFlagProduction, setClickFlagProduction] = useState(false)
    const [clcikFlagTransit, setClickFlagTransit] = useState(false)
    const [clcikFlagRecieved, setClickFlagRecieved] = useState(false)
    const [clcikFlagOverdue, setClickFlagOverdue] = useState(false)
    const [clcikFlagToday, setClickFlagToday] = useState(false)
    const [clcikFlagTomorrow, setClickFlagTomorrow] = useState(false)
    const [initialRender, setInitialRender] = useState(true);
    const [inputValue, setInputValue] = useState("")
    const [listArray, setListArray] = useState([])
    const [statusListArray, setStatusListArray] = useState([])
    const [pageCount, setPageCount] = useState('');
    const [activePage, setActivePage] = useState(parseInt(window.sessionStorage.getItem('dentalactivePage')));
    const [componentActivate, setComponentActivate] = useState(false);
    const [editClick, setEditClick] = useState([]);

    useEffect(() => {
        labTrackingList();
        statusList();
    }, []);
    const labTrackingList = () => {
        const apiJson = {
            "labOrderId": "",
            "memberId": window.sessionStorage.getItem('memberId'),
            "hospitalId": localStorage.getItem('hospitalId'),
            "doctorId": window.sessionStorage.getItem('doctorId'),
            "searchInput": "",
            "cmbStatus": "",
            "offset": window.sessionStorage.getItem("dentaloffset"),
            "max": "10",
            "dueDateSelect": "",
        }
        callingAPI("commonUserFunctions/getDentalLabOrderDetails", apiJson).then((data) => {

            if (data.data.success === "1") {
                setListArray(data.data.result)
                setPageCount(data.data.totalCount)
            }
        })
    }
    const statusList = () => {
        const apiJson = {
            "hospitalId": localStorage.getItem('hospitalId'),
        }
        callingAPI("commonUserFunctions/getDentalLabMasterDetails", apiJson).then((data) => {

            if (data.data.success === "1") {
                setStatusListArray(data.data.result.dentalLabStatusDetails)
            }
        })
    }
    const paginationOnChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        var statusCheckArray = [clcikFlagActive === true ? "1" : "", clcikFlagSent === true ? "2" : "", clcikFlagProduction === true ? "3" : "", clcikFlagTransit === true ? "4" : "", clcikFlagRecieved === true ? "5" : "", clcikFlagOverdue === true ? "6" : ""]
        var statusCheckArrayresult = statusCheckArray.filter(function (value) {
            return value && value !== "";
        }).join(",");
        var dueCheckArray = [clcikFlagToday === true ? "today" : "", clcikFlagTomorrow === true ? "tomarrow" : ""]
        var dueCheckArrayresult = dueCheckArray.filter(function (value) {
            return value && value !== "";
        }).join(",");
        window.sessionStorage.setItem("dentalactivePage", pageNumber)
        window.sessionStorage.setItem('dentaloffset', offset)
        const apiJson = {
            "labOrderId": "",
            "memberId": window.sessionStorage.getItem('memberId'),
            "hospitalId": localStorage.getItem('hospitalId'),
            "doctorId": window.sessionStorage.getItem('doctorId'),
            "searchInput": inputValue,
            "cmbStatus": statusCheckArrayresult,
            "offset": offset,
            "max": max,
            "dueDateSelect": dueCheckArrayresult,
        }
        callingAPI("commonUserFunctions/getDentalLabOrderDetails", apiJson).then((data) => {

            if (data.data.success === '1') {
                setListArray(data.data.result)
                setPageCount(data.data.totalCount)
            }
            else {
                setListArray(data.data.result)
            }
        });
    };
    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            searchLabOrderList();
        }
    }, [inputValue, clcikFlagActive, clcikFlagSent, clcikFlagProduction, clcikFlagTransit, clcikFlagRecieved, clcikFlagOverdue, clcikFlagToday, clcikFlagTomorrow]);
    const inputValueOnchange = (value) => {
        setInputValue(value)
    }
    const addNewOrder = (mode, data) => {
        console.log("🚀 ~ addNewOrder ~ data:", data)
        setComponentActivate(!componentActivate)
        if (mode === "edit") {
            setEditClick(data)
        }
        else {
            setEditClick([])
        }

    }
    const searchLabOrderList = () => {
        var statusCheckArray = [clcikFlagActive === true ? "1" : "", clcikFlagSent === true ? "2" : "", clcikFlagProduction === true ? "3" : "", clcikFlagTransit === true ? "4" : "", clcikFlagRecieved === true ? "5" : "", clcikFlagOverdue === true ? "6" : ""]
        var statusCheckArrayresult = statusCheckArray.filter(function (value) {
            return value && value !== "";
        }).join(",");
        var dueCheckArray = [clcikFlagToday === true ? "today" : "", clcikFlagTomorrow === true ? "tomarrow" : ""]
        var dueCheckArrayresult = dueCheckArray.filter(function (value) {
            return value && value !== "";
        }).join(",");
        const apiJson = {
            "labOrderId": "",
            "memberId": window.sessionStorage.getItem('memberId'),
            "hospitalId": localStorage.getItem('hospitalId'),
            "doctorId": window.sessionStorage.getItem('doctorId'),
            "searchInput": inputValue,
            "cmbStatus": statusCheckArrayresult,
            "offset": "",
            "max": "",
            "dueDateSelect": dueCheckArrayresult,
        }
        callingAPI("commonUserFunctions/getDentalLabOrderDetails", apiJson).then((data) => {

            if (data.data.success === "1") {
                setListArray(data.data.result)
                setPageCount(data.data.totalCount)
            }
        })
    }
    const active = (mode) => {
        switch (mode) {
            case 'ACTIVE':
                setClickFlagActive(!clcikFlagActive)
                break;
            case 'SENT':
                setClickFlagSent(!clcikFlagSent)
                break;
            case 'PRODUCTION':
                setClickFlagProduction(!clcikFlagProduction)
                break;
            case 'TRANSIT':
                setClickFlagTransit(!clcikFlagTransit)
                break;
            case 'RECIEVED':
                setClickFlagRecieved(!clcikFlagRecieved)
                break;
            case 'OVERDUE':
                setClickFlagOverdue(!clcikFlagOverdue)
                break;
            case 'TODAY':
                setClickFlagToday(!clcikFlagToday)
                break;
            case 'TOMORROW':
                setClickFlagTomorrow(!clcikFlagTomorrow)
                break;
            default:
                break;
        }
    }
    const statusChange = (data, value) => {
        const apiJson = {
            "dentalLabOrderId": data.id,
            "dentalLabStatusId": value
        }
        callingAPI("commonUserFunctions/changeStatusDentalLabOrder", apiJson).then((data) => {

            if (data.data.success === "1") {
                labTrackingList()
            }
        })
    }
    return (
        <div className="coverWraper labOrderListWrap">
            <Header />
            <div className="contentWraper contentWraperScroll">
                <Leftmenu />
                {componentActivate === false ?
                    <div className="rightSection laborder-wraper">
                        <Profiledetail />
                        <div className="lab-orderheadsectn">
                            <div className='text-mainHead'>LAB TRACKING</div>
                            <div><span className="pl_blue_btn" onClick={(e) => addNewOrder("add")}>+Add New Order</span></div>
                        </div>
                        {/* {listArray && listArray.length > 0 ? */}
                        <div className="list-tablesec">
                            <table className='table'>
                                <thead>
                                    <tr className='search-section'>
                                        <th className="" colspan="2">
                                            <input type="text" className='inputField input-width' id='autoCompleteValue' placeholder='Search order by Job No./Work Name/Doctor' onChange={(e) => inputValueOnchange(e.target.value)} />
                                        </th>
                                        <th className="text-right" colspan="4">
                                            <label htmlFor="" className='label-text'>Status <span style={{ fontWeight: "900", fontSize: "14px", color: "#000" }} >:</span></label>
                                            <span className='statusbar-btns' id='active' onClick={(e) => active("ACTIVE")} style={{ backgroundColor: (clcikFlagActive === true ? '#000' : '#999494') }}>ACTIVE</span>
                                            <span className='statusbar-btns' id='sent' onClick={(e) => active("SENT")} style={{ backgroundColor: (clcikFlagSent === true ? '#000' : '#999494') }}>SENT</span>
                                            <span className='statusbar-btns' id='production' onClick={(e) => active("PRODUCTION")} style={{ backgroundColor: (clcikFlagProduction === true ? '#000' : '#999494') }}>IN PRODUCTION</span>
                                            <span className='statusbar-btns' id='transit' onClick={(e) => active("TRANSIT")} style={{ backgroundColor: (clcikFlagTransit === true ? '#000' : '#999494') }}>IN TRANSIT</span>
                                            <span className='statusbar-btns' id='recieved' onClick={(e) => active("RECIEVED")} style={{ backgroundColor: (clcikFlagRecieved === true ? '#000' : '#999494') }}>RECEIVED</span>
                                            <span className='statusbar-btns' id='overdue' onClick={(e) => active("OVERDUE")} style={{ backgroundColor: (clcikFlagOverdue === true ? '#000' : '#999494') }}>OVERDUE</span>
                                        </th>
                                        <th className="text-right" colspan="2">
                                            <label htmlFor="" className='label-text'>Due <span style={{ fontWeight: "900", fontSize: "14px", color: "#000" }} >:</span></label>
                                            <span className='statusbar-btns' id='today' onClick={(e) => active("TODAY")} style={{ backgroundColor: (clcikFlagToday === true ? '#000' : '#999494') }}>TODAY</span>
                                            <span className='statusbar-btns' id='tomorrow' onClick={(e) => active("TOMORROW")} style={{ backgroundColor: (clcikFlagTomorrow === true ? '#000' : '#999494') }}>TOMORROW</span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>JOB NO.</th>
                                        {/* <th>PATIENT INFO</th> */}
                                        <th>DOCTOR</th>
                                        <th>LAB</th>
                                        <th>WORK NAME</th>
                                        <th>WORK TYPE</th>
                                        <th>DUE</th>
                                        <th className='text-right'>STATUS</th>
                                        <th className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                {console.log("🚀 ~ listArray:", listArray)}
                                {listArray && listArray.length > 0 ?

                                    <tbody>
                                        {listArray && listArray.map((list, i) =>
                                            <tr>
                                                <td className='td-textNo'>{list.id}</td>
                                                {/* <td className='td-textName'>{list.patientfirstname + " " + list.patientlastname}</td> */}
                                                <td>{list.doctorName}</td>
                                                <td><span className='icon-phone'><FontAwesomeIcon icon={faPhoneAlt} /></span>{list.dentalLabName}</td>
                                                <td>{list.dentalLabWorkName}</td>
                                                <td>{list.dentalLabWorkTypeName}</td>
                                                <td>{moment(list.dueDate).format("DD/MMM/YYYY")}</td>
                                                <td className='text-right'>
                                                    <select value={list.dentalLabStatusId} name="" id="" className='select_status' onChange={(e) => statusChange(list, e.target.value)}>
                                                        {statusListArray.map((obj, i) => (
                                                            <option value={obj.id}>{obj.name}</option>))}
                                                    </select>
                                                </td>
                                                <td className='text-right'><span className='lbListAction' onClick={(e) => addNewOrder("edit", list)}>
                                                    {/* <FontAwesomeIcon icon={faAngleRight} /> */}
                                                    Edit
                                                </span></td>
                                            </tr>
                                        )}
                                    </tbody>

                                    :
                                    <div className='lbOrderNodata'>
                                        <p><NoLabOrderList /></p>
                                    </div>
                                }
                            </table>
                        </div>

                        {pageCount > 10 && (
                            <div className="paginationSection">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                                    totalItemsCount={pageCount}
                                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                                    onChange={paginationOnChange}
                                />
                            </div>
                        )}
                    </div> :
                    <LabOrderSave editClick={editClick} addNewOrder={addNewOrder} labTrackingList={labTrackingList} />}
            </div>
            <Footer />
        </div>
    );
                                        
}
export default LabOrderList;
