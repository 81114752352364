import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';

function SearchFilterPromotionalSms() {
	const [groupName, setGroupName] = useState([]);
	const [allPatientGroupData, setallPatientGroupData] = useState([]);
	const [filteredPatientGroupData, setfilteredPatientGroupData] = useState(allPatientGroupData);
	useEffect(() => {
		const groupNameData = {

			"hospitalId": localStorage.getItem("hospitalId"),
			"offset": "0",
			"max": "5"
		}
		callingAPI('promotionalSms/getGroupSms', groupNameData,
		).then((response) => {
			if (response.data.success === "1") {
				setGroupName(response.data.result.hospitalPatientGroupData)
				setallPatientGroupData(response.data.result.hospitalPatientGroupData);
				setfilteredPatientGroupData(response.data.result.hospitalPatientGroupData);
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	}, []);
	const handleSearchforFilteredData = (event) => {
		let value = event.target.value.toLowerCase();
		let result = [];

		result = allPatientGroupData.filter((data) => {
			return data.hospitalPatientGroupName.search(value) !== -1;
		});
		setfilteredPatientGroupData(result);
	}
	return (
		<div className="App">
			<div class="smsTypeSectionBlock" id="groupModId">
				<div class="smsTypeSectionTable">
					<div class="tableSearchDiv">
						<a href="/communications/patientGroup" class="pl_add_group">
							Add Group
						</a>

						<span>Search :</span>
						<input
							type="text"
							id="searchGroup"
							name="searchGroup"
							placeholder="Search Group Name"
							onChange={(event) => handleSearchforFilteredData(event)}
						/>
					</div>
					<div id="groupTable">
						<table class="table-bordered ">
							<thead>
								<tr>
									<th width="5%">
										<div class="tableContentSec">Select</div>
									</th>

									<th width="75%">
										<div class="tableContentSec">Name</div>
									</th>
									<th width="20%">
										<div class="tableContentSec">Size</div>
									</th>
								</tr>
							</thead>


							{filteredPatientGroupData.map((item, index) => {
								return (
									<tbody>

										<tr>
											<td>
												<div class="tableContentSec">
													<input type="checkbox" />
												</div>
											</td>

											<td>
												<div class="tableContentSec">{item.hospitalPatientGroupName}</div>
											</td>

											<td>
												<div class="tableContentSec">4</div>
											</td>
										</tr>
									</tbody>
								)

							})}





						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
export default SearchFilterPromotionalSms;















