import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config'
import eye from "../images/icons/eye.png"
import Mobile from '../images/icons/mobilebg.png';
function SmsEmail() {
	const [activePage, setActivePage] = useState(1);
	const [communicationcount, setCommunicationCount] = useState()
	const [communication, setCommunication] = useState([]);
	const [savesms, setSaveSms] = useState()
	const [saveemail, setSaveEmail] = useState()
	const [savesmsemailpopup, setSaveSmsEmailPopup] = useState()
	const [EmailTextField, setEmailTextField] = useState("")
	const [SmsTextField, SetSmsTextField] = useState("")
	const [pageOffset, setPageOffset] = useState(0);
	const [SmsEmailId, SetSmsEmailId] = useState("")
	const [smsEmailMessageTypeId, SetsmsEmailMessageTypeId] = useState("")
	const [SmsEmailmessageName, SetSmsEmailmessageName] = useState("")
	const [SmsEmailmessageSubject, SetSmsEmailmessageSubject] = useState("")

	const emailtext = (a) => {
		setEmailTextField(a)
	}
	const smstext = (a) => {
		SetSmsTextField(a)
	}
	const pmMessageContentDetailsId = (a) => {
		SetSmsEmailId(a)
	}
	const emailSmsMessageTypeId = (a) => {
		SetsmsEmailMessageTypeId(a)
	}
	const messageName = (a) => {
		SetSmsEmailmessageName(a)
	}
	const messageSubject = (a) => {
		SetSmsEmailmessageSubject(a)
	}
	const SmsCheckboxOnChange = (SmsEmailId, hasSms, smsEmailMessageTypeId) => {
		const SmsData = {
			"userId": window.sessionStorage.getItem("userId"),
			"pmMessageContentDetailsId": SmsEmailId,
			"smsEmailMessageTypeId": smsEmailMessageTypeId,
			"pmMessageContentDetailsIsSms": hasSms,
		}
		callingAPI('smsAndEmail/saveSmsDetails', SmsData
		).then((response) => {

			if (response.data.success === "1") {
				setSaveSms((response.data.successMessage))
				smsEmailCommunicationListing()
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	};
	const EmailCheckboxOnChange = (SmsEmailId, hasEmail, smsEmailMessageTypeId) => {
		const EmailData = {
			"userId": window.sessionStorage.getItem("userId"),
			"pmMessageContentDetailsId": SmsEmailId,
			"smsEmailMessageTypeId": smsEmailMessageTypeId,
			"pmMessageContentDetailsIsMail": hasEmail,
		}
		callingAPI('smsAndEmail/saveEmailDetails', EmailData
		).then((response) => {

			if (response.data.success === "1") {
				setSaveEmail(response.data.successMessage)
				smsEmailCommunicationListing()
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	};
	const SmsEmailPopupSave = () => {
		const SmsEmailPopupData = {
			"userId": window.sessionStorage.getItem("userId"),
			"pmMessageContentDetailsId": SmsEmailId,
			"smsEmailMessageTypeId": smsEmailMessageTypeId,
			"hasEmail": false,
			"hasSms": false,
			"messageName": SmsEmailmessageName,
			"messageSubject": SmsEmailmessageSubject,
			"messageText": EmailTextField,
			"messageTextParams": "~patient~,~date~,~time~,~doctor~,~clinic~",
			"smsText": SmsTextField,
			"smsTextParams": "~patient~,~date~,~time~,~doctor~,~clinic~",
		}
		callingAPI('smsAndEmail/saveSmsPopupDetails', SmsEmailPopupData
		)
			.then((response) => {
				if (response.data.success === "1") {
					setSaveSmsEmailPopup(response.data.successMessage)
				}

			}
			)
			.catch((err) => console.log(err, "err"))

	};

	const smsEmailCommunicationListing = () => {

		const PostData = {
			"userId": window.sessionStorage.getItem("userId"),
			offset: pageOffset.toString(),
			max: 100,
			"groupHospitalID": ""
		}
		callingAPI('smsAndEmail/communicationForm', PostData

		).then((response) => {
			if (response.data.success === "1") {
				setCommunication(response.data.result.smsEmailCommunicationData)
				setCommunicationCount(response.data.result.smsCount)
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	}
	useEffect(() => {
		smsEmailCommunicationListing()
	}, []);

	const SavesmswhenCheckBoxchecked = (e, id, smsCheckboxOnChange) => {
		const checked = e.target.checked;
		SmsCheckboxOnChange(id, checked, smsCheckboxOnChange)
	};
	const SaveemailwhenCheckBoxchecked = (e, id, emailCheckboxOnChange) => {
		const checked = e.target.checked;
		EmailCheckboxOnChange(id, checked, emailCheckboxOnChange)
	};
	return (
		<div className="dashboardCover">
			<div className="dashboardTitle">
				SMS & Email Communication Settings
				<div class="smsNotify">Remaining Transactional SMS count : {communicationcount}</div>
				<div class="smsNotifyError" style={{ display: 'none' }}>
					You have not subscribed for SMS plan, Please contact our customer support.{' '}
				</div>
			</div>


			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardtable">
						<table>
							<thead>
								<tr>
									<th>Name</th>
									<th>SMS/Email To</th>
									<th>SMS</th>
									<th>Email</th>
									<th>SMS/Email Content</th>
								</tr>
							</thead>
							<tbody id="smsEmailMessageTypeName">
								{communication.map((item, i) => (
									<tr>
										<td>{item.smsEmailMessageTypeName}</td>
										<td>{item.smsEmailTo}</td>
										<td>
											<span class="checkSection">

												<input type="checkbox" id={"checkbox1" + i} defaultChecked={item.pmMessageContentDetailsIsSms === 1 ? true : false}
													onChange={(e) => { SavesmswhenCheckBoxchecked(e, item.pmMessageContentDetailsId, item.smsEmailMessageTypeId) }} />
												<label for={"checkbox1" + i}></label>


											</span>SMS
										</td>
										<td>
											<span class="checkSection"  >

												<input type="checkbox" id={"checkbox2" + i} defaultChecked={item.pmMessageContentDetailsIsMail === 1 ? true : false}
													onChange={(e) => { SaveemailwhenCheckBoxchecked(e, item.pmMessageContentDetailsId, item.smsEmailMessageTypeId) }} />
												<label for={"checkbox2" + i}></label>
											</span>Email
										</td>
										<td>

											<span className="chartImg" onClick={(e) => {
												emailtext(item.pmMessageContentDetailsMessageTextNew == "" ? item.smsEmailMessageTypeMessageText : item.pmMessageContentDetailsMessageTextNew);
												smstext(item.pmMessageContentDetailsSmsTextNew == "" ? item.smsEmailMessageTypeSmsText : item.pmMessageContentDetailsSmsTextNew);
												pmMessageContentDetailsId(item.pmMessageContentDetailsId);
												emailSmsMessageTypeId(item.smsEmailMessageTypeId);
												messageName(item.smsEmailMessageTypeName);
												messageSubject(item.smsEmailMessageTypeMessagesubject)

											}}>
												<a href="#sms_viewmore">
													<img src={eye} />
												</a>
											</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{/* Popup Begins */}

						<div id="sms_viewmore" class="ploverlay">
							<div class="plpopup plsmspopup">
								<h2 style={{ color: '#3498db' }}>
									Preview - Confirmation{' '}
									<a class="close" href="#">
										&times;
									</a>
								</h2>

								<div class="plcontent doc practiceForm">
									{/*  */}

									<div class="tabset">
										<input type="radio" name="tabset" id="tab1" aria-controls="sms" checked />
										<label for="tab1">SMS</label>

										<input type="radio" name="tabset" id="tab2" aria-controls="email" />
										<label for="tab2">EMAIL</label>

										<div class="tab-panels">
											<section id="sms" class="tab-panel">
												<div className="col-md-12">
													<div className="row">
														<div class="clearfix" id="sms" style={{ display: 'flex' }}>
															<div
																class="pl_sms_left"
																style={{
																	background: 'rgba(47, 119, 199, 0.35)',
																	minheight: '507px',
																	padding: '10px',
																}}
															>
																<li class="no-gutter" style={{ listStyle: 'none' }}>
																	<div class="clearfix">
																		<label style={{ marginBottom: '15px' }}>
																			<strong>
																				Include the following in SMS
																			</strong>
																		</label>
																		<div class="procedureCatList">
																			<div
																				class="procedureCatType"
																				style={{
																					width: 'auto',
																					paddingRight: '5px',
																				}}
																			>
																				<input type="checkbox" checked />
																			</div>
																			<div
																				class="procedureCatName"
																				style={{ width: 'auto' }}
																			>
																				<label id="lblShowTeeth">
																					Patient Name
																				</label>
																			</div>
																		</div>
																		<div class="procedureCatList">
																			<div
																				class="procedureCatType"
																				style={{
																					width: 'auto',
																					paddingRight: '5px',
																				}}
																			>
																				<input type="checkbox" checked />
																			</div>
																			<div
																				class="procedureCatName"
																				style={{ width: 'auto' }}
																			>
																				<label id="lblShowTeeth">
																					Clininc Name
																				</label>
																			</div>
																		</div>
																		<div class="procedureCatList">
																			<div
																				class="procedureCatType"
																				style={{
																					width: 'auto',
																					paddingRight: '5px',
																				}}
																			>
																				<input type="checkbox" checked />
																			</div>
																			<div
																				class="procedureCatName"
																				style={{ width: 'auto' }}
																			>
																				<label id="lblShowTeeth">
																					Appointment category
																				</label>
																			</div>
																		</div>
																		<div class="procedureCatList">
																			<div
																				class="procedureCatType"
																				style={{
																					width: 'auto',
																					paddingRight: '5px',
																				}}
																			>
																				<input type="checkbox" checked />
																			</div>
																			<div
																				class="procedureCatName"
																				style={{ width: 'auto' }}
																			>
																				<label id="lblShowTeeth">
																					Contact number
																				</label>
																			</div>
																		</div>
																		<div class="procedureCatList">
																			<div
																				class="procedureCatType"
																				style={{
																					width: 'auto',
																					paddingRight: '5px',
																				}}
																			>
																				<input type="checkbox" checked />
																			</div>
																			<div
																				class="procedureCatName"
																				style={{ width: 'auto' }}
																			>
																				<label id="lblShowTeeth">
																					Link to online appointment
																				</label>
																			</div>
																		</div>
																		<div class="clearfix" />
																	</div>
																</li>
															</div>
															<div
																class="pl_sms_right popMobViewBlck"
																style={{
																	background: '#2f77c7',
																	margin: 'auto',
																	textAlign: 'center',
																}}
															>
																<img
																	src={Mobile}
																	class="popMobViewBlckImage"
																	style={{ minWidth: '400px', maxWidth: '400px' }}
																/>

																<div class="popMobView" id="addSmsContentDiv">

																	<textarea
																		name="txtSmsArea"
																		id="txtSmsArea"
																		value={SmsTextField}
																	/>

																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section id="email" class="tab-panel">
												<span class="checkSection">
													<div className="pl_email_txt" >
														{EmailTextField}
													</div>
												</span>
											</section>
											<div
												class="selctFieldHolder pl_communicationss"
												style={{
													clear: 'both',
													overflow: 'hidden',
												}}
											>
												<a href=" " class="practiceGreyButton">
													Reset
												</a>
												<a href=" " class="practiceGreyButton" style={{ marginRight: '20px' }}>
													Cancel
												</a>
												<input
													onClick={SmsEmailPopupSave}
													type="submit"
													value="Save"
													class="practiceGreyButton practiceGreenButton"
													style={{ marginRight: '5px' }}
												/>
											</div>
										</div>
									</div>
									{/*  */}
								</div>
							</div>
						</div>
						{/* Popup Ends */}
					</div>
				</div>
			</div>
		</div>
	);
}
export default SmsEmail
