import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Datepicker from 'react-datepicker';
import TabsRight from './tabsRight';
import $ from 'jquery';
import moment from 'moment';
import Loader from '../Common/loader';
import InvoiceTeethSession from './invoiceTeethSession';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Priscription from '../images/icons/prescription.png';
import labOrderIcon from '../images/icons/labOrderIcon.png';
import { callingAPI, callingPostAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';
import useStateWithCallback from 'use-state-with-callback';
import { parse } from '@fortawesome/fontawesome-svg-core';
import { logDOM } from '@testing-library/react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
var proIds = ''
var labOrderIds = ''
var prescriptionidss = ''
function PatientInvoiceAdd() {

	$(document).ready(function () {
		$('#invoiceAdd').addClass("testResultAdd");

	})
	var procedureDataarray = []
	var prescriptionDataArray = []
	var labOrderDataArray = []
	const changeAmount = () => {
		costAndQuantityCalculation();
	};
	const location = useLocation();
	const [isFlag, setisFlag] = useState(typeof location.state !== "undefined" ? (typeof location.state.isFlag !== "undefined" ? location.state.isFlag : "") : "")
	const [procedureViewIds, setprocedureViewIds] = useState(typeof location.state !== "undefined" ? location.state.procedureViewIds : "")
	const [hospitalId, sethospitalId] = useState(typeof location.state !== "undefined" ? (typeof location.state.hospitalId !== "undefined" ? location.state.hospitalId : localStorage.getItem('hospitalId')) : localStorage.getItem('hospitalId'))
	const [memberIds, setmemberIds] = useState(typeof location.state !== "undefined" ? (typeof location.state.memberId !== "undefined" ? location.state.memberId : window.sessionStorage.getItem('memberId')) : window.sessionStorage.getItem('memberId'))
	const [labViewIds, setlabViewIds] = useState(typeof location.state !== "undefined" ? location.state.labViewIds : "")
	const [procedureList, setProcedureList] = useState([]);
	const [plannedProcedureList, setPlannedProcedureList] = useState([])
	const [PrescriptionsList, setPrescriptionsList] = useState([])
	// const [plannedPrescriptionsList, setPlannedPrescriptionsList] = useState([])
	const [labOrder, setLabOrder] = useState([])
	const [plannedLabOrder, setPlannedLabOrder] = useState([])
	const [disposableList, setDisposableList] = useState([])
	const [procedureListBackUp, setProcedureListBackUp] = useState([]);
	const [plannedProcedureListBackUp, setPlannedProcedureListBackUp] = useState([])
	const [PrescriptionsListBackUp, setPrescriptionsListBackUp] = useState([])
	const [plannedPrescriptionsListBackUp, setPlannedPrescriptionsListBackUp] = useState([])
	const [labOrderBackUp, setLabOrderBackUp] = useState([])
	const [plannedLabOrderBackUp, setPlannedLabOrderBackUp] = useState([])
	const [disposableListBackUp, setDisposableListBackUp] = useState([])
	const [medicineBranchId, setmedicineBranchId] = useState(window.sessionStorage.getItem('medicineBranchId'));
	const [searchKey, setSearchKey] = useState("")
	const [isData, setIsData] = useState(false);


	const [rightMenuData, setrightMenuData] = useState([])
	const [rightMenuDataChecking, setrightMenuDataChecking] = useState([])
	const [procedureArray, setProcedureArray] = useStateWithCallback([], changeAmount);
	const [prescriptionArray, setPrescriptionArray] = useStateWithCallback([], changeAmount);
	const [labOrderArray, setLabOrderArray] = useStateWithCallback([], changeAmount);
	const [disposableArray, setDisposableArray] = useStateWithCallback([], changeAmount);
	const [patientPrescriptionList, setPatientPrescriptionList] = useState([])
	const [plannedPrescriptionsList, setPlannedPrescriptionsList] = useState([])
	const [procedureChkIds, setProcedureChkIds] = useState([]);
	const [prescriptionChkIds, setPrescriptionChkIds] = useState([]);
	const [labOrderChkIds, setLabOrderChkIds] = useState([]);
	const [generatedOn, setGeneratedOn] = useState('');
	const [notInHospitalIds, setNotInHospital] = useState([]);
	const [notInHospitalLabIds, setNotInHospitalLab] = useState([]);
	const [totalCost, setTotalCost] = useState(0);
	const [totalTax, setTotalTax] = useState(0.00);
	const [grandTotal, setGrandTotal] = useState(0.00);
	const [totalDiscount, setTotalDiscount] = useState("");
	const [payNowTotal, setPayNowTotal] = useState(0.00);
	const [dueAfterPayment, setDueAfterPayment] = useState(0.00);
	const [advanceAfterPayment, setAdvanceAfterPayment] = useState(0.00);
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	// const [hidAdvcAmtSave, sethidAdvcAmtSave] = useState(0.00);
	const [oldAdvanceAmt, setoldAdvanceAmt] = useState(window.sessionStorage.getItem('advanceAmt'));
	const [specimenType, setSpecimenType] = useState([]);
	const [typeOfTest, setTypeOfTest] = useState([]);
	const [frequencyDetails, setFrequencyDetails] = useState([]);
	const [intakeDetails, setIntakeDetails] = useState([]);
	const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('patientDoctorId'));
	const [doctorName, setDoctor] = useState(window.sessionStorage.getItem('patientDoctorName'));
	const [doctorList, setDoctorList] = useState([]);
	const [errMsg, setErrMsg] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [doctorVisitMedicineIds, setDoctorVisitMedicineIds] = useState('');
	const [memberProcedureInvoiceId, setMemberProcedureInvoiceId] = useState('');

	const [isLoader, setIsLoader] = useState(false);
	const [printIds, setPrintIds] = useState({ prescriptionDate: '', medicineId: '', doctorVisitId: '' });
	const [prescriptionData, setPrescriptionData] = useState([])
	const [newResultsetData, setNewResultsetData] = useState([]);
	const [patientLabList, setpatientLabList] = useState([]);
	const [newPreArray, setNewPresArray] = useState([]);
	const [notInHospitalMedicine, setNotInHospitalMedicine] = useState([]);

	const [addClass, setaddClass] = useState('react-tabs__tab--selected');
	const [addClasslab, setaddClasslab] = useState('');
	const [addClasspres, setaddClasspres] = useState('');
	const [addClassdis, setaddClassdis] = useState('');
	const [newProIds, setNewProIds] = useState([]);
	const [receiptId, setreceiptId] = useState('')
	// useEffect(async () => {

	// 	window.sessionStorage.setItem('prescriptionArrayOld', JSON.stringify([]))
	// 	if (typeof location.state !== "undefined") {
	// 		if (procedureViewIds !== "" && procedureViewIds !== undefined) {
	// 			var a = procedureViewIds.split(",")
	// 			var newProIdss = []
	// 			for (var i = 0; i < a.length; i++) {
	// 				newProIdss.push(a[i])
	// 			}
	// 			proIds = newProIdss;
	// 			procedureClick()
	// 		}
	// 		else
	// 			if (location.state.labViewIds !== null && location.state.labViewIds !== undefined) {
	// 				var a = location.state.labViewIds.split(",")
	// 				var newLabIdss = []
	// 				for (var i = 0; i < a.length; i++) {
	// 					newLabIdss.push(a[i])
	// 				}
	// 				labOrderIds = newLabIdss
	// 				labOrdersClick()
	// 			} else
	// 				if (location.state.prescriptionViewIds !== null && location.state.prescriptionViewIds !== undefined) {
	// 					var a = location.state.prescriptionViewIds.split(",")
	// 					var newprescIdss = []
	// 					for (var i = 0; i < a.length; i++) {
	// 						newprescIdss.push(a[i])
	// 					}
	// 					prescriptionidss = newprescIdss
	// 					prescriptionClick()
	// 				} else if (isFlag === "Add Lab") {
	// 					labOrdersClick()
	// 					document.getElementById("profileMainDiv").style.display = 'none'
	// 				} else if (isFlag === "Add Prescription") {
	// 					prescriptionClick()
	// 					document.getElementById("profileMainDiv").style.display = 'none'
	// 				} else if (window.sessionStorage.getItem("isFlag") === "" || window.sessionStorage.getItem("isFlag") === null) {
	// 					procedureClick()
	// 				} else {
	// 					procedureClick()

	// 				}
	// 	}

	// 	await PrcedureList()
	// 	await listing()
	// 	setaddClasslab('')
	// 	setaddClasspres('')
	// 	setaddClassdis('')
	// }, []);
	useEffect(() => {
		const fetchData = async () => {
			window.sessionStorage.setItem('prescriptionArrayOld', JSON.stringify([]));
	
			if (typeof location.state !== 'undefined') {
				if (procedureViewIds !== '' && procedureViewIds !== undefined) {
					let a = procedureViewIds.split(',');
					let newProIdss = [];
					for (let i = 0; i < a.length; i++) {
						newProIdss.push(a[i]);
					}
					proIds = newProIdss;
					procedureClick();
				} else if (location.state.labViewIds !== null && location.state.labViewIds !== undefined) {
					let a = location.state.labViewIds.split(',');
					let newLabIdss = [];
					for (let i = 0; i < a.length; i++) {
						newLabIdss.push(a[i]);
					}
					labOrderIds = newLabIdss;
					labOrdersClick();
				} else if (location.state.prescriptionViewIds !== null && location.state.prescriptionViewIds !== undefined) {
					let a = location.state.prescriptionViewIds.split(',');
					let newprescIdss = [];
					for (let i = 0; i < a.length; i++) {
						newprescIdss.push(a[i]);
					}
					prescriptionidss = newprescIdss;
					prescriptionClick();
				} else if (isFlag === 'Add Lab') {
					labOrdersClick();
					document.getElementById('profileMainDiv').style.display = 'none';
				} else if (isFlag === 'Add Prescription') {
					prescriptionClick();
					document.getElementById('profileMainDiv').style.display = 'none';
				} else if (window.sessionStorage.getItem('isFlag') === '' || window.sessionStorage.getItem('isFlag') === null) {
					procedureClick();
				} else {
					procedureClick();
				}
			}
	
			await PrcedureList();
			await listing();
			setaddClasslab('');
			setaddClasspres('');
			setaddClassdis('');
		};
	
		fetchData();
	}, []);
	
	const PrcedureList = async () => {
		return new Promise((resolve, reject) => {
			setIsLoader(true)
			const apiJson = {
				hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
				memberId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : memberIds !== "" ? memberIds : window.sessionStorage.getItem("memberId")),
				doctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('patientDoctorId') !== null ? window.sessionStorage.getItem('patientDoctorId') : window.sessionStorage.getItem('doctorId')),
				hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
				searchValue: "",
				tabValue: "",
				mode: "Invoice",
				medicineBranchId: window.sessionStorage.getItem("siteBranchId") === "4" ? (window.sessionStorage.getItem('medicineBranchId')) : "",
				fromPage: (isFlag === "Lab" || isFlag === "Add Lab" || isFlag === "Prescription" || isFlag === "Add Prescription" || isFlag === "Procedure") ? "1" : "0"
			};
			callingAPI('treatmentPlanDetails/procedureListing', apiJson).then((data) => {

				if (data.data.success === '1') {
					setProcedureList(data.data.result.dataSet);
					setPlannedProcedureList(data.data.result.plannedProcedure)
					setPrescriptionsList(data.data.result.prescription)
					setPlannedPrescriptionsList(data.data.result.plannnedPrescription)
					setLabOrder(data.data.result.labOrder)
					setPlannedLabOrder(data.data.result.plannnedLabOrder)
					setDisposableList(data.data.result.disposableResult)
					setProcedureListBackUp(data.data.result.dataSet);
					setPlannedProcedureListBackUp(data.data.result.plannedProcedure)
					setPrescriptionsListBackUp(data.data.result.prescription)
					setPlannedPrescriptionsListBackUp(data.data.result.plannnedPrescription)
					setLabOrderBackUp(data.data.result.labOrder)
					setPlannedLabOrderBackUp(data.data.result.plannnedLabOrder)
					setDisposableListBackUp(data.data.result.disposableResult)
					setIsData(true);

					window.sessionStorage.setItem('prescriptionData', JSON.stringify(data.data.result.plannnedPrescription))
					window.sessionStorage.setItem('nonPrescriptionData', JSON.stringify(data.data.result.prescription))

					window.sessionStorage.setItem('labData', JSON.stringify(data.data.result.plannnedLabOrder))
					window.sessionStorage.setItem('nonLabData', JSON.stringify(data.data.result.labOrder))

					setrightMenuData(data.data.result)
					setrightMenuDataChecking(data.data.result.dataSet)
					setIsLoader(false)

					resolve(true);

				}
			});
		});
	}

	const searchInvoice = (event) => {

		const { name, value } = event.target;
		setSearchKey(event.target.value)
		var proArray = [...procedureListBackUp]
		var proArrayPlanned = [...plannedProcedureListBackUp]
		var presArray = [...PrescriptionsListBackUp]
		var presArrayPlanned = [...plannedPrescriptionsListBackUp]
		var labArray = [...labOrderBackUp]
		var labArrayPlanned = [...plannedLabOrderBackUp]
		var disposArray = [...disposableListBackUp]
		var filteredData = []
		var filteredDataPlanned = []
		var filteredPresData = []
		var filteredPresPlanned = []
		var filteredLabData = []
		var filteredLabPlanned = []
		var filteredDisposibleData = []
		if (event.target.value !== "") {
			filteredData = proArray.filter((item) => {
				const procedureName = Object.values(item?.procedure_name).join('').toLowerCase();
				const procedureCode = Object.values(item?.procedure_code).join('').toLowerCase();
				const branchName = Object.values(item?.pmTreatmentsBranchName ? item?.pmTreatmentsBranchName : "").join('').toLowerCase();
				const searchValue = value.toLowerCase();
				return procedureName.includes(searchValue) || procedureCode.includes(searchValue) || branchName.includes(searchValue);
			})
			filteredDataPlanned = proArrayPlanned.filter((item) => {
				return Object.values(item.procedure_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredPresData = presArray.filter((item) => {
				return Object.values(item.medicine).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredPresPlanned = presArrayPlanned.filter((item) => {
				return Object.values(item.medicine).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredLabData = labArray.filter((item) => {
				return Object.values(item.test_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredLabPlanned = labArrayPlanned.filter((item) => {
				return Object.values(item.test_name).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			filteredDisposibleData = disposArray.filter((item) => {
				return Object.values(item.inventoryName).join('').toLowerCase().includes(event.target.value.toLowerCase())
			})
			setProcedureList([...filteredData])
			setPlannedProcedureList([...filteredDataPlanned])
			setPrescriptionsList([...filteredPresData])
			setPlannedPrescriptionsList([...filteredPresPlanned])
			setLabOrder([...filteredLabData])
			setPlannedLabOrder([...filteredLabPlanned])
			setDisposableList([...filteredDisposibleData])

			setTimeout(() => {
				let prescriptionArrayData = [...prescriptionArray]
				for (let index = 0; index < prescriptionArrayData.length; index++) {
					const element = prescriptionArrayData[index].hidPmHospitalMedicineId;
					if (document.getElementById('hidNonPlannedPrescriptionQty' + element) !== null) {
						var prescriptionQty = document.getElementById('hidNonPlannedPrescriptionQty' + element).value
						document.getElementById('nonPlannedPrescriptionQty' + element).innerHTML = (parseInt(prescriptionQty) - parseInt(prescriptionArrayData[index].spinner))
					}
				}
			}, 1000);


		} else {
			setProcedureList([...procedureListBackUp])
			setPlannedProcedureList([...plannedProcedureListBackUp])
			setPrescriptionsList([...PrescriptionsListBackUp])
			setPlannedPrescriptionsList([...plannedPrescriptionsListBackUp])
			setLabOrder([...labOrderBackUp])
			setPlannedLabOrder([...plannedLabOrderBackUp])
			setDisposableList([...disposableListBackUp])
		}
	}
	const procedureClick = () => {
		if (document.getElementById("ProcedureId") !== null) {
			document.getElementById("ProcedureId").style.display = 'block'
		}
		if (document.getElementById("PriscriptionId") !== null) {
			document.getElementById("PriscriptionId").style.display = 'none'
		}
		if (document.getElementById("LabOrderId") !== null) {
			document.getElementById("LabOrderId").style.display = 'none'
		}
		if (document.getElementById("Disposable") !== null) {
			document.getElementById("Disposable").style.display = 'none'
		}

		setaddClass('react-tabs__tab--selected')
		setaddClasslab('')
		setaddClasspres('')
		setaddClassdis('')

	}
	const labOrdersClick = () => {

		if (document.getElementById("ProcedureId") !== null) {
			document.getElementById("ProcedureId").style.display = 'none'
		}
		if (document.getElementById("PriscriptionId") !== null) {
			document.getElementById("PriscriptionId").style.display = 'none'
		}
		if (document.getElementById("LabOrderId") !== null) {
			document.getElementById("LabOrderId").style.display = 'block'
		}
		if (document.getElementById("Disposable") !== null) {
			document.getElementById("Disposable").style.display = 'none'
		}


		setaddClass('')
		setaddClasslab('react-tabs__tab--selected')
		setaddClasspres('')
		setaddClassdis('')
	}
	const disposablesClick = () => {

		if (document.getElementById("ProcedureId") !== null) {
			document.getElementById("ProcedureId").style.display = 'none'
		}
		if (document.getElementById("PriscriptionId") !== null) {
			document.getElementById("PriscriptionId").style.display = 'none'
		}
		if (document.getElementById("LabOrderId") !== null) {
			document.getElementById("LabOrderId").style.display = 'none'
		}
		if (document.getElementById("Disposable") !== null) {
			document.getElementById("Disposable").style.display = 'block'
		}
		setaddClass('')
		setaddClasslab('')
		setaddClasspres('')
		setaddClassdis('react-tabs__tab--selected')

	}
	const prescriptionClick = () => {

		if (document.getElementById("ProcedureId") !== null) {
			document.getElementById("ProcedureId").style.display = 'none'
		}
		if (document.getElementById("PriscriptionId") !== null) {
			document.getElementById("PriscriptionId").style.display = 'block'
		}
		if (document.getElementById("LabOrderId") !== null) {
			document.getElementById("LabOrderId").style.display = 'none'
		}
		if (document.getElementById("Disposable") !== null) {
			document.getElementById("Disposable").style.display = 'none'
		}
		setaddClass('')
		setaddClasslab('')
		setaddClasspres('react-tabs__tab--selected')
		setaddClassdis('')

	}
	const medicineListing = async () => {

		return new Promise((resolve, reject) => {
			let preObj = []
			const apiJson = {
				functionName: 'prescriptionList',
				scheduledTimeSlotId: '',
				memberId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : memberIds !== "" ? memberIds : window.sessionStorage.getItem('memberId')),
				doctorId: "",
				hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
				visitId: '',
			};
			callingAPI('medicinePrescription/prescriptionList', apiJson).then((data) => {
				if (data.data.success === '1') {
					data.data.result.prescriptionMedicineData.map((obj) => {
						obj.prescriptionData.map((obj1) => {
							obj1.prescriptionDetails.map((obj2) => {
								preObj.push(obj2)
								setPrintIds({ prescriptionDate: moment(obj.visit_date).format('YYYY-MM-DD'), medicineId: obj2.medicineId, doctorVisitId: obj1.visitId })
							})
						})
					})
					window.sessionStorage.setItem('allPrescriptionData', JSON.stringify(preObj))
					setPrescriptionData(data.data.result.prescriptionMedicineData)
					resolve(true);
				}
			});
		})
	}
	const labListing = () => {

		return new Promise((resolve, reject) => {
			let labObjArray = []
			const apiJson = {

				userId: window.sessionStorage.getItem('userId'),
				moduleName: 'Lab Order',
				memberId: memberIds !== "" ? memberIds : window.sessionStorage.getItem('memberId'),
				hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem("hospitalId"),
				labOrderTestId: '',
			};
			callingAPI('adminReports/labOrderPayment', apiJson).then((data) => {

				if (data.data.success === '1') {
					//setPrescriptionData(data.data.result.prescriptionMedicineData)
					setNewResultsetData(data.data.testData)

					data.data.testData.map((obj) => {
						obj.itemArray.map((labObj) => {
							labObj.data.map((dataObj) => {
								labObjArray.push(dataObj)
							})
						})
					})
					window.sessionStorage.setItem('alllabData', JSON.stringify(labObjArray))
					resolve(true);
				}
			});
		})
	}
	const listing = async () => {
		console.log("memberProcedureInvoiceId.....", memberProcedureInvoiceId)
		console.log("generatedOnDate.....", location.state.generatedOnDate)
		if (location.state.memberProcedureInvoiceId) {
			setGeneratedOn(new Date(location.state.generatedOnDate));
		} else {
			setGeneratedOn(new Date());
		}

		if (typeof location.state !== 'undefined' || window.sessionStorage.getItem('prescriptionid') !== null || window.sessionStorage.getItem('labOrderId') !== null || proIds !== null || labOrderIds !== null || prescriptionidss !== null) {
			var memberProcedureInvoiceIdNew = null, procedurecheckedIdsNew = null, prescriptionIdsNew = null, labOrderInvoiceIdsNew = null;


			if (typeof location.state !== 'undefined') {
				const { memberProcedureInvoiceId, procedurecheckedIds, prescriptionIds, labOrderInvoiceIds } = location.state;
				memberProcedureInvoiceIdNew = (typeof memberProcedureInvoiceId === "undefined" ? null : memberProcedureInvoiceId)
				procedurecheckedIdsNew = (typeof procedurecheckedIds === "undefined" ? (proIds !== "undefined" ? proIds : null) : procedurecheckedIds)
				prescriptionIdsNew = (typeof prescriptionIds === "undefined" ? (prescriptionidss !== "undefined" ? prescriptionidss : null) : prescriptionIds)
				labOrderInvoiceIdsNew = (typeof labOrderInvoiceIds === "undefined" ? (labOrderIds !== "undefined" ? labOrderIds : null) : labOrderInvoiceIds)
			}
			setProcedureChkIds((procedurecheckedIdsNew === null ? [] : procedurecheckedIdsNew))
			var presId = []
			var laborderIds = []
			if (typeof location.state !== 'undefined') {
				presId = prescriptionIdsNew
				laborderIds = labOrderInvoiceIdsNew
			} else {
				var ids = []
				if (window.sessionStorage.getItem('prescriptionid')) {
					ids = window.sessionStorage.getItem('prescriptionid').split(",")
				}
				var labIds = []
				if (window.sessionStorage.getItem('labOrderId')) {
					labIds = window.sessionStorage.getItem('labOrderId').split(",")
				}
				for (var i = 0; i < ids.length; i++) {
					presId.push(Number(ids[i]))
				}
				for (var i = 0; i < labIds.length; i++) {
					laborderIds.push(Number(labIds[i]))
				}

			}
			prescriptionIdsNew = presId
			labOrderInvoiceIdsNew = laborderIds
			setPrescriptionChkIds(presId)
			setLabOrderChkIds(laborderIds)
			window.sessionStorage.removeItem('prescriptionid')
			window.sessionStorage.removeItem('labOrderId')
			if (prescriptionIdsNew !== null || window.sessionStorage.getItem('prescriptionid') !== null) {

				await medicineListing()
				//prescriptionClick()
				var prescriptionArrayPlanned = JSON.parse(window.sessionStorage.getItem('prescriptionData'))
				var nonPrescriptionData = JSON.parse(window.sessionStorage.getItem('nonPrescriptionData'))
				var allPrescriptionData = JSON.parse(window.sessionStorage.getItem('allPrescriptionData'))
				var notInHospital = []
				var notInHospitalNew = [...notInHospitalMedicine]
				for (var i = 0; i < prescriptionIdsNew.length; i++) {
					var filteredPrescriptionData = []
					filteredPrescriptionData = prescriptionArrayPlanned.filter(function (el) {
						return (el.doctor_visit_medicine_id == prescriptionIdsNew[i])
					});
					if (filteredPrescriptionData[0] !== undefined) {


						let stockQty = filteredPrescriptionData[0].stockQuantity
						let changedQty = filteredPrescriptionData[0].changed_quantity
						let balanceQty = 0
						if (parseInt(changedQty) > parseInt(stockQty)) {
							filteredPrescriptionData[0].changed_quantity = stockQty
							balanceQty = parseInt(changedQty) - parseInt(stockQty)
						}
						await addPrescriptions(filteredPrescriptionData[0], filteredPrescriptionData[0].doctor_visit_medicine_id, filteredPrescriptionData[0].pm_hospital_medicine_id, '1')
						if (parseInt(balanceQty) > 0) {
							var allmedicine = JSON.parse(window.sessionStorage.getItem('nonPrescriptionData'))
							for (let index = 0; index < allmedicine.length; index++) {
								if (filteredPrescriptionData[0].medicine_id.toString() === allmedicine[index].medicine_id.toString() && filteredPrescriptionData[0].pm_hospital_medicine_id.toString() !== allmedicine[index].pm_hospital_medicine_id.toString()) {
									if (parseInt(balanceQty) > parseInt(allmedicine[index].stockQuantity)) {
										filteredPrescriptionData[0].changed_quantity = allmedicine[index].stockQuantity
										filteredPrescriptionData[0].stockQuantity = allmedicine[index].stockQuantity
										filteredPrescriptionData[0].pm_hospital_medicine_id = allmedicine[index].pm_hospital_medicine_id
										filteredPrescriptionData[0].unitPrice = allmedicine[index].unitPrice
										addPrescriptions(filteredPrescriptionData[0], filteredPrescriptionData[0].doctor_visit_medicine_id, allmedicine[index].pm_hospital_medicine_id, '1')
										balanceQty = parseInt(balanceQty) - parseInt(allmedicine[index].stockQuantity)
									}
									else {
										filteredPrescriptionData[0].changed_quantity = balanceQty
										filteredPrescriptionData[0].stockQuantity = balanceQty
										filteredPrescriptionData[0].pm_hospital_medicine_id = allmedicine[index].pm_hospital_medicine_id
										filteredPrescriptionData[0].unitPrice = allmedicine[index].unitPrice
										addPrescriptions(filteredPrescriptionData[0], filteredPrescriptionData[0].doctor_visit_medicine_id, allmedicine[index].pm_hospital_medicine_id, '1')
										balanceQty = 0
										break;
									}
								}
							}
						}
						if (balanceQty > 0) {
							var notInHospitalNew = [...notInHospitalMedicine]
							var allPrescriptionData = JSON.parse(window.sessionStorage.getItem('allPrescriptionData'))
							let notMedicine = allPrescriptionData.filter((objnot) =>
								objnot.doctorVisitMedicineId.toString() == filteredPrescriptionData[0].doctor_visit_medicine_id
							)
							notMedicine[0].quantity = balanceQty
							notInHospitalNew.push(notMedicine[0])
							setNotInHospitalMedicine([...notInHospitalNew])
							document.getElementById("medicineNotHospital").style.display = 'block'
						}

					} else {
						document.getElementById("medicineNotHospital").style.display = 'block'
						let notMedicine = allPrescriptionData.filter((obj) =>
							obj.doctorVisitMedicineId.toString() == prescriptionIdsNew[i].toString()
						)
						notInHospitalNew.push(notMedicine[0])
						setNotInHospitalMedicine([...notInHospitalNew])
					}
				}

			}
			console.log('labOrderInvoiceIdsNew....lab***...', labOrderInvoiceIdsNew);
			console.log('labOrderInvoiceIdsNew.sessionStorage...lab***...', window.sessionStorage.getItem('labOrderId'));

			if ((labOrderInvoiceIdsNew !== null && labOrderInvoiceIdsNew !== "null" && labOrderInvoiceIdsNew !== "") || (window.sessionStorage.getItem('labOrderId') !== null && window.sessionStorage.getItem('labOrderId') !== "null" && window.sessionStorage.getItem('labOrderId') !== "")) {
				labListing()
				var plannnedLabOrderArray = JSON.parse(window.sessionStorage.getItem('labData'))
				var nonLabData = JSON.parse(window.sessionStorage.getItem('nonLabData'))
				var alllabData = JSON.parse(window.sessionStorage.getItem('alllabData'))
				console.log("alllabData....", alllabData);
				console.log("nonLabData....", nonLabData);
				// const apiJson = {
				// 	hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
				// 	memberId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : memberIds !== "" ? memberIds : window.sessionStorage.getItem("memberId")),
				// 	doctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('patientDoctorId') !== null ? window.sessionStorage.getItem('patientDoctorId') : window.sessionStorage.getItem('doctorId')),
				// 	hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
				// 	searchValue: "",
				// 	tabValue: "",
				// 	mode: "Invoice",
				// 	medicineBranchId: window.sessionStorage.getItem('medicineBranchId'),
				// 	fromPage: (isFlag === "Lab" || isFlag === "Add Lab" || isFlag === "Prescription" || isFlag === "Add Prescription" || isFlag === "Procedure") ? "1" : "0"
				// };
				// callingAPI('treatmentPlanDetails/procedureListing', apiJson).then(async (data) => {
				// 	console.log("procedureListing.......",data);
				// 	if (data.data.success === '1') {
				//var plannnedLabOrderArray = data.data.result.plannnedLabOrder
				console.log("plannnedLabOrderArray.......", plannnedLabOrderArray);
				var labnotInHospital = []
				console.log("labOrderInvoiceIdsNew.......", labOrderInvoiceIdsNew);
				for (var i = 0; i < labOrderInvoiceIdsNew.length; i++) {
					var filteredProcedureData = []
					filteredProcedureData = plannnedLabOrderArray.filter(function (el) {
						console.log("elelelelel.......", el);
						return (el.doctor_visit_lab_order_test_id == labOrderInvoiceIdsNew[i])

					});
					console.log("filteredProcedureData.....", filteredProcedureData);
					if (filteredProcedureData[0] !== undefined) {
						addLabOrder(filteredProcedureData[0], "", labOrderInvoiceIdsNew[i], '1')
					} else {
						document.getElementById("labTestNotHospital").style.display = 'block'
						let notLab = alllabData.filter((obj) =>
							obj.labOrderTestId.toString() == labOrderInvoiceIdsNew[i].toString()
						)
						labnotInHospital.push(notLab[0])
						console.log("notLab.....", notLab);
						console.log("labnotInHospital.....", labnotInHospital);
					}
				}
				setNotInHospitalLab(labnotInHospital)
				// 	} else {
				// 		setIsLoader(true);
				// 	}
				// });

			}
			if (procedurecheckedIdsNew !== null && procedurecheckedIdsNew !== "") {
				const apiJson = {
					hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
					memberId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : memberIds !== "" ? memberIds : window.sessionStorage.getItem("memberId")),
					doctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('patientDoctorId') !== null ? window.sessionStorage.getItem('patientDoctorId') : window.sessionStorage.getItem('doctorId')),
					hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
					searchValue: "",
					tabValue: "",
					mode: "Invoice",
					medicineBranchId: window.sessionStorage.getItem("siteBranchId") === "4" ? (window.sessionStorage.getItem('medicineBranchId')) : "",
					fromPage: (isFlag === "Lab" || isFlag === "Add Lab" || isFlag === "Prescription" || isFlag === "Add Prescription" || isFlag === "Procedure") ? "1" : "0"
				};
				callingAPI('treatmentPlanDetails/procedureListing', apiJson).then(async (data) => {

					if (data.data.success === '1') {
						var procedureArrayPlanned = data.data.result.plannedProcedure
						for (var i = 0; i < procedurecheckedIdsNew.length; i++) {
							var filteredProcedureData = []
							filteredProcedureData = procedureArrayPlanned.filter(function (el) {
								return (el.member_procedure_dental_details_id == procedurecheckedIdsNew[i])
							});
							if (typeof filteredProcedureData[0] !== "undefined") {
								addProcedure(filteredProcedureData[0], "", procedurecheckedIdsNew[i], '1')
							}

						}
					} else {
						setIsLoader(true);

					}
				});

			}



			if (memberProcedureInvoiceIdNew !== null && memberProcedureInvoiceIdNew !== "") {
				const postdata = {
					hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
					memberId: memberIds !== "" ? memberIds : window.sessionStorage.getItem('memberId'),
					userId: window.sessionStorage.getItem('userId'),
					memberProcedureInvoiceId: memberProcedureInvoiceIdNew,
				};
				callingAPI('payment/invoiceList', postdata).then(async (data) => {
					if (data.data.success === '1') {
						var array = data.data.result[0].finalArray[0].main[0].item;
						var invoiceId = data.data.result[0].finalArray[0].main[0].invoiceList.memberProcedureInvoiceId;
						setMemberProcedureInvoiceId(invoiceId);
						var totalCostValue = '';
						var totalGrand = '';
						var totDiscount = '';
						var totalTaxAmt = ''
						for (var i = 0; array.length > i; i++) {
							if (array[i].itemType === 'Procedure') {
								var proArray = [];
								var taxDetails = []
								for (var j = 0; array[i].item.length > j; j++) {
									if (array[i].item[j].taxAmount > "0.00") {
										var taxDetailsObj = await getTaxDetails(array[i].item[j].hidProcedureId, ((array[i].item[j].quantity) * array[i].item[j].unitPrice));
										taxDetails = taxDetailsObj.taxDetails;
									}
									var time = new Date(convertDate(array[i].item[j].appDate));
									var date2 = moment(time).format('yyyy-MM-DD h:mm A');
									var milliStart = moment(date2).valueOf();
									var milliEnd = moment(date2).add(30, 'm').format('yyyy-MM-DD h:mm A');
									let fm = 0;
									let mc = array[i].item[j].chkMultipleCost;
									if (array[i].item[j].teethDetails === 'Full Mouth') {
										fm = 1;
										mc = 0;
									}
									var pro = {
										hidProcedureId: array[i].item[j].hidProcedureId,
										hidDoctorId: window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId'),
										hidTaxIndividual: array[i].item[j].taxAmount,
										txtTreatmentCost: array[i].item[j].unitPrice,
										hidDiscountIndividual: array[i].item[j].discount,
										hidGrandIndividual: parseFloat(array[i].item[j].totalAmount).toFixed(2),
										txtNotes: array[i].item[j].txtNotes !== "undefined" ? array[i].item[j].txtNotes : "",
										spinner: array[i].item[j].quantity,
										hidTeethNumber: (array[i].item[j].teethDetails === "null" ? "" : array[i].item[j].teethDetails),
										disType: 'percentage',
										scheduledtimeSlotStartTime: milliStart,
										scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
										txtDisAmt: array[i].item[j].discount,
										chkMultipleCost: mc,
										appDate: date2,
										dateOn: new Date(time),
										hidMemberProcedureDentalDetailsId: array[i].item[j].memberProcedureDentalDetailsId,
										procedureName: array[i].item[j].itemName,
										procedureCode: array[i].item[j].itemCode,
										showTeeth: array[i].item[j].showTeeth,
										hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[j].completedByDoctorId),
										doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[j].completedByDoctorName),
										fullMouth: fm,
										taxDetails: (taxDetails.length > 0 ? taxDetails : [])
									};
									proArray.push(pro);
								}
								setProcedureArray([...proArray]);
							} else if (array[i].itemType === 'Disposables') {
								var disposArray = [];
								for (var k = 0; array[i].item.length > k; k++) {
									var taxDetails = []
									if (array[i].item[k].taxAmount > 0) {
										var taxDetailsObj = await getPrescriptionTaxDetails(array[i].item[k].hidDisposableId, ((array[i].item[k].quantity) * array[i].item[k].unitPrice));
										taxDetails = taxDetailsObj.taxDetails;
									}
									var time = new Date(convertDate(array[i].item[k].appDate));
									var date2 = moment(array[i].item[k].appDate).format('yyyy-MM-DD h:mm A');
									var milliStart = moment(date2).valueOf();
									var milliEnd = moment(date2).add(30, 'm').format('yyyy-MM-DD h:mm A');
									var diposObj = {
										hidDisposableId: array[i].item[k].hidDisposableId,
										hidDisposableDoctorVisitMedicineId:
											array[i].item[k].hidDisposableDoctorVisitMedicineId,
										hidDisposablePmHospitalMedicineId:
											array[i].item[k].hidDisposablePmHospitalMedicineId,
										hidTaxIndividual: array[i].item[k].taxAmount,
										txtTreatmentCost: array[i].item[k].unitPrice,
										hidDiscountIndividual: array[i].item[k].discount,
										hidGrandIndividual: parseFloat(array[i].item[k].totalAmount).toFixed(2),
										txtNotes: array[i].item[k].txtNotes !== "undefined" ? array[i].item[k].txtNotes : "",
										spinner: array[i].item[k].quantity,
										hidDoctorId: window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId'),
										scheduledtimeSlotStartTime: milliStart,
										scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
										disposableName: array[i].item[k].itemName,
										appDate: date2,
										dateOn: time,
										hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[k].completedByDoctorId),
										doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[k].completedByDoctorName),
										taxDetails: (taxDetails.length > 0 ? taxDetails : [])
									};
									disposArray.push(diposObj);
								}
								setDisposableArray([...disposArray]);
							} else if (array[i].itemType === 'Lab Order') {
								console.log("array[i].hidDoctorVisitLabOrderTest.......", array[i]);
								typeDatas();

								var labArray = []
								for (var l = 0; array[i].item.length > l; l++) {
									if (array[i].item[l].hidDoctorVisitLabOrderTest !== "") {
										document.getElementById('plannedLabOrder' + array[i].item[l].hidDoctorVisitLabOrderTest).style.display = 'none';
									}
									var taxDetails = []
									if (array[i].item[l].taxAmount > 0.00) {
										var taxDetailsObj = await getLabTaxDetails(array[i].item[l].hidServiceProviderTestId, ((array[i].item[l].quantity) * array[i].item[l].unitPrice));
										taxDetails = taxDetailsObj.taxDetails
									}
									var date1 = new Date(convertDate(array[i].item[l].appDate));
									var date2 = moment(date1).format('yyyy-MM-DD h:mm A');
									var milliStart = moment(date2).valueOf();
									var milliEnd = moment(date2).add(30, 'm').format('yyyy-MM-DD h:mm A');
									var labObj = {
										hidServiceProviderTestId: array[i].item[l].hidServiceProviderTestId,
										hidServiceProviderTestDetailsId: array[i].item[l].hidServiceProviderTestDetailsId,
										cmbSpecimen: array[i].item[l].cmbSpecimen,
										cmbtypeofTest: array[i].item[l].cmbtypeofTest,
										spinner: array[i].item[l].quantity,
										hidTaxIndividual: array[i].item[l].taxAmount,
										txtTreatmentCost: array[i].item[l].unitPrice,
										hidDiscountIndividual: array[i].item[l].discount,
										hidGrandIndividual: parseFloat(array[i].item[l].totalAmount).toFixed(2),
										txtNotes: array[i].item[l].txtNotes !== "undefined" ? array[i].item[l].txtNotes : "",
										hidDoctorVisitLabOrderTest: array[i].item[l].hidDoctorVisitLabOrderTest,
										hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
										scheduledtimeSlotStartTime: milliStart,
										scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
										testName: array[i].item[l].itemName,
										appDate: date2,
										dateOn: new Date(date1),
										hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[l].completedByDoctorId),
										doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[l].completedByDoctorName),
										taxDetails: (taxDetails.length > 0 ? taxDetails : [])
									};
									labArray.push(labObj)
								}
								setLabOrderArray([...labArray])
							} else if (array[i].itemType === 'Prescription') {
								var frequencyDetailsArray = frequencyDetails
								if (frequencyDetailsArray.length === 0) {
									frequencyDetailsArray = await frequencyAndIntakeDetails()
								}
								var prescripArray = []
								for (var m = 0; array[i].item.length > m; m++) {
									var taxDetails = [];
									if (array[i].item[m].taxAmount > 0) {
										var taxDetailsObj = await getPrescriptionTaxDetails(array[i].item[m].hidPmHospitalMedicineId, ((array[i].item[m].quantity) * array[i].item[m].unitPrice));
										taxDetails = taxDetailsObj.taxDetails;
									}
									var date1 = moment(new Date(convertDate(array[i].item[m].appDate))).format('yyyy-MM-DD h:mm A');
									var date2 = moment(array[i].item[m].appDate).format('yyyy-MM-DD h:mm A')
									var milliStart = moment(date2).valueOf();
									var milliEnd = moment(date2).add(30, 'm').format('yyyy-MM-DD h:mm A');
									var dateOnly = moment(array[i].item[m].dtStartdate).format('yyyy-MM-DD ');
									let newTimes = []
									if (array[i].item[m].cmbFrequency !== "0") {
										newTimes = frequencyDetailsArray.filter((item) => {
											return (item.name === array[i].item[m].cmbFrequency)
										})
									}
									var newTimesArray = []
									if (newTimes.length > 0) {
										if (newTimes[0].times > 0) {
											for (var n = 0; newTimes[0].times > n; n++) {
												var a = { val: "" }
												newTimesArray.push(a)
											}
										}
										if (newTimes[0].times === '3') {
											newTimesArray[0].val = array[i].item[m].txtMorning
											newTimesArray[1].val = array[i].item[m].txtNoon
											newTimesArray[2].val = array[i].item[m].txtNight
										} else if (newTimes[0].times === '1') {
											newTimesArray[0].val = array[i].item[m].txtMorning
										} else if (newTimes[0].times === '4') {
											newTimesArray[0].val = array[i].item[m].txtMorning
											newTimesArray[1].val = array[i].item[m].txtNoon
											newTimesArray[2].val = array[i].item[m].txtNight
											newTimesArray[3].val = array[i].item[m].txtFrequencyMore
										}
									}
									var presObj = {
										hidPmHospitalMedicineId: array[i].item[m].hidPmHospitalMedicineId,
										hidTaxIndividual: array[i].item[m].taxAmount,
										txtTreatmentCost: array[i].item[m].unitPrice,
										hidDiscountIndividual: array[i].item[m].discount,
										hidGrandIndividual: parseFloat(parseFloat(array[i].item[m].totalAmount) + parseFloat(array[i].item[m].taxAmount)).toFixed(2),
										txtNotes: array[i].item[m].txtNotes !== "undefined" ? array[i].item[m].txtNotes : "",
										cmbFrequency: array[i].item[m].cmbFrequency,
										txtFrequencyMore: array[i].item[m].txtFrequencyMore,
										txtMorning: array[i].item[m].txtMorning,
										txtNoon: array[i].item[m].txtNoon,
										txtNight: array[i].item[m].txtNight,
										cmbTime: array[i].item[m].cmbTime,
										txtDays: array[i].item[m].txtDays,
										scheduledtimeSlotStartTime: milliStart,
										scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
										dtStartdate: new Date(dateOnly),
										checkboxRefill: array[i].item[m].checkboxRefill,
										spinner: array[i].item[m].quantity,
										hidDoctorVisitMedicineId: array[i].item[m].hidDoctorVisitMedicineId,
										hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
										medicineName: array[i].item[m].itemName,
										appDate: date2,
										dateOn: new Date(date1),
										hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[m].completedByDoctorId),
										doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : array[i].item[m].completedByDoctorName),
										timesArray: newTimesArray,
										taxDetails: taxDetails
									};
									prescripArray.push(presObj)
								}
								setPrescriptionArray([...prescripArray])
							}
							totalCostValue =
								+totalCost + +data.data.result[0].finalArray[0].main[0].invoiceList.totalAmount;
							totDiscount =
								+totalDiscount + +data.data.result[0].finalArray[0].main[0].invoiceList.discountAmount;
							totalTaxAmt =
								+totalTax + +data.data.result[0].finalArray[0].main[0].invoiceList.totalTaxAmount
							totalGrand =
								+grandTotal +
								+data.data.result[0].finalArray[0].main[0].invoiceList.totalAmount +
								-+totDiscount;
							var grand = +totalGrand + +totalTaxAmt
							setTotalCost(totalCostValue);
							setGrandTotal(grand);
							setTotalTax(totalTaxAmt)
							setTotalDiscount(totDiscount);
						}
					}
				});
			}

		}
	}
	const convertDate = (date) => {
		var formatedDate = ""
		if (date !== "") {
			var split = date.split(" ")
			var dateSplit = split[0].split("/")
			formatedDate = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0] + " " + split[1] + " " + split[2]
		}
		return formatedDate
	}
	const getTaxDetails = async (id, amount) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				"procedureId": id,
				"amount": amount
			};
			callingAPI('commonUserFunctions/getTaxDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					var a = {
						taxDetails: data.data.result.taxDetails,
						taxAmountTotal: data.data.result.taxAmountTotal
					}
					resolve(a);
				} else {
					resolve([]);
				}
			});
		});
	};
	const getTaxDetailsWithoutAsync = (id, amount, index, currentQty, currentCost, taxDetails) => {


		const values = [...procedureArray];
		let taxamount = 0
		let taxName = ""
		let taxAmountData = 0
		let taxAmountTot = 0
		var taxDetail = []
		for (var j = 0; j < taxDetails.length; j++) {

			taxamount = (parseFloat(amount) / 100) * (parseFloat(taxDetails[j].taxPercentage))
			taxName = taxDetails[j].taxName
			taxAmountData = parseFloat(taxamount)
			taxAmountTot = parseFloat(taxAmountTot) + parseFloat(taxamount)
			let taxData = {
				taxName: taxName,
				taxAmountData: taxAmountData,
				taxPercentage: taxDetails[j].taxPercentage
			}
			taxDetail.push(taxData)
		}
		values[index] = {
			...values[index],
			hidGrandIndividual: parseFloat(amount) + parseFloat(taxAmountTot),
			hidTaxIndividual: taxAmountTot,
			taxDetails: taxDetail,
			spinner: currentQty,
			txtTreatmentCost: currentCost !== "" ? currentCost : "0",

		};
		setProcedureArray([...values]);


	};

	const getPrescriptionTaxDetailsWithoutAsync = (id, amount, index, currentQty, currentCost, mode, taxDetails) => {


		var values = []
		if (mode === "Prescription") {
			values = [...prescriptionArray];
		} else if (mode === "disposible") {
			values = [...disposableArray];
		}
		let taxamount = 0
		let taxName = ""
		let taxAmountData = 0
		let taxAmountTot = 0
		var taxDetail = []
		for (var j = 0; j < taxDetails.length; j++) {

			taxamount = (parseFloat(amount / 100)) * (parseFloat(taxDetails[j].taxPercentage))
			taxName = taxDetails[j].taxName
			taxAmountData = parseFloat(taxamount)
			taxAmountTot = parseFloat(taxAmountTot) + parseFloat(taxamount)
			let taxData = {
				taxName: taxName,
				taxAmountData: taxAmountData,
				taxPercentage: taxDetails[j].taxPercentage
			}
			taxDetail.push(taxData)

		}
		values[index] = {
			...values[index],
			hidGrandIndividual: parseFloat(amount) + parseFloat(taxAmountTot),
			hidTaxIndividual: taxAmountTot,
			taxDetails: taxDetail,
			spinner: currentQty,
			txtTreatmentCost: currentCost !== "" ? currentCost : "0",

		};
		if (mode === "Prescription") {
			setPrescriptionArray([...values]);
		} else if (mode === "disposible") {
			setDisposableArray([...values]);
		}

	}
	const getPrescriptionTaxDetails = async (id, amount) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				"pmHospitalMedicineId": id,
				"amount": amount
			};
			callingAPI('commonUserFunctions/getPrescriptionTaxDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					var a = {
						taxDetails: data.data.result.taxDetails,
						taxAmountTotal: data.data.result.taxAmountTotal
					}
					resolve(a);
				} else {
					resolve([]);
				}
			});
		});
	}
	const getLabTaxDetails = async (id, amount) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				"serviceProviderTestId": id,
				"amount": amount
			};
			callingAPI('commonUserFunctions/getLabOrderTaxDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					var a = {
						taxDetails: data.data.result.taxDetails,
						taxAmountTotal: data.data.result.taxAmountTotal
					}
					resolve(a);
				} else {
					resolve([]);
				}
			});
		});
	}
	const getLabTaxDetailsWithOutAsync = (id, amount, index, currentQty, currentCost, taxDetails) => {


		const values = [...labOrderArray];
		let taxamount = 0
		let taxName = ""
		let taxAmountData = 0
		let taxAmountTot = 0
		var taxDetail = []
		for (var j = 0; j < taxDetails.length; j++) {

			taxamount = (parseFloat(amount) / 100) * (parseFloat(taxDetails[j].taxPercentage))
			taxName = taxDetails[j].taxName
			taxAmountData = parseFloat(taxamount)
			taxAmountTot = parseFloat(taxAmountTot) + parseFloat(taxamount)
			let taxData = {
				taxName: taxName,
				taxAmountData: taxAmountData,
				taxPercentage: taxDetails[j].taxPercentage
			}
			taxDetail.push(taxData)

		}
		values[index] = {
			...values[index],
			hidGrandIndividual: parseFloat(amount) + parseFloat(taxAmountTot),
			hidTaxIndividual: taxAmountTot,
			taxDetails: taxDetail,
			spinner: currentQty,
			txtTreatmentCost: currentCost !== "" ? currentCost : "0",

		};
		setLabOrderArray([...values]);

	}
	const MedicineNotInHospitalView = () => {

		if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALPHARMACY' || window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB') {
			setIsOpen(true);
			$("#pharmacyModal").addClass(' pharmacyModal')
		}
	}
	const printLab = () => {
		var printWindow = window.open('', '', 'height=1000,width=1000');
		printWindow.document.write('<html><head><title>Lab Order Details</title>');

		var divContents = document.getElementById("labDetails").innerHTML;

		printWindow.document.write('<html>');
		printWindow.document.write('<body > ');
		printWindow.document.write('<h2 style="font-family: sans-serif; font-size:18px;" >Lab Order Details</h2>');
		printWindow.document.write(divContents);
		printWindow.document.write('</body></html>');
		printWindow.document.close();
		printWindow.print();


	}

	const showTaxDetails = (i, divId) => {
		document.getElementById(divId + i).style.display =
			document.getElementById(divId + i).style.display === 'block' ? 'none' : 'block';
		droplist(divId, i)
	}

	const addPrescriptionsBefore = async (obj, doctor_visit_medicine_id, id, isAthorModules) => {

		if (doctor_visit_medicine_id !== "") {
			let stockQty = obj.stockQuantity
			let changedQty = obj.changed_quantity
			let balanceQty = 0
			if (parseInt(changedQty) > parseInt(stockQty)) {
				obj.changed_quantity = stockQty
				balanceQty = parseInt(changedQty) - parseInt(stockQty)
			}
			await addPrescriptions(obj, doctor_visit_medicine_id, id, isAthorModules)
			if (parseInt(balanceQty) > 0) {
				var allmedicine = JSON.parse(window.sessionStorage.getItem('nonPrescriptionData'))
				for (let index = 0; index < allmedicine.length; index++) {
					if (obj.medicine_id.toString() === allmedicine[index].medicine_id.toString() && obj.pm_hospital_medicine_id.toString() !== allmedicine[index].pm_hospital_medicine_id.toString()) {
						if (parseInt(balanceQty) > parseInt(allmedicine[index].stockQuantity)) {
							obj.changed_quantity = allmedicine[index].stockQuantity
							obj.stockQuantity = allmedicine[index].stockQuantity
							obj.pm_hospital_medicine_id = allmedicine[index].pm_hospital_medicine_id
							obj.unitPrice = allmedicine[index].unitPrice
							addPrescriptions(obj, doctor_visit_medicine_id, allmedicine[index].pm_hospital_medicine_id, isAthorModules)
							balanceQty = parseInt(balanceQty) - parseInt(allmedicine[index].stockQuantity)
						}
						else {
							obj.changed_quantity = balanceQty
							obj.stockQuantity = balanceQty
							obj.pm_hospital_medicine_id = allmedicine[index].pm_hospital_medicine_id
							obj.unitPrice = allmedicine[index].unitPrice
							addPrescriptions(obj, doctor_visit_medicine_id, allmedicine[index].pm_hospital_medicine_id, isAthorModules)
							balanceQty = 0
							break;
						}
					}
				}
			}
			if (balanceQty > 0) {
				var notInHospitalNew = [...notInHospitalMedicine]
				var allPrescriptionData = JSON.parse(window.sessionStorage.getItem('allPrescriptionData'))
				let notMedicine = allPrescriptionData.filter((objnot) =>
					objnot.doctorVisitMedicineId.toString() == doctor_visit_medicine_id
				)
				notMedicine[0].quantity = balanceQty
				notInHospitalNew.push(notMedicine[0])
				setNotInHospitalMedicine([...notInHospitalNew])
				document.getElementById("medicineNotHospital").style.display = 'block'
			}
		}
		else {
			addPrescriptions(obj, doctor_visit_medicine_id, id, isAthorModules)
		}
	}
	const addProcedure = async (obj, i, id, isAthorModules) => {

		var procedureArrayOld = [...procedureArray]
		var taxDetails = await getTaxDetails(obj.procedure_id, ((obj.planned_quantity ? obj.planned_quantity : 1) * (obj.planned_amount !== "" && obj.planned_amount !== undefined ? obj.planned_amount : obj.price)));

		if (id !== '') {
			document.getElementById('plannedProcedure' + id).style.display = 'none';
		}
		var taxTotal = taxDetails.taxAmountTotal;
		var taxTot = taxTotal + totalTax
		var totalCostValue = +totalCost + +obj.price;
		var totalGrand = +grandTotal + ((obj.planned_quantity ? obj.planned_quantity : "1") * (obj.planned_amount !== "" && obj.planned_amount !== undefined ? obj.planned_amount : obj.price)) + (taxTotal !== 0.00 ? taxTotal : 0.00);

		var date1 = moment(new Date()).format('yyyy-MM-DD h:mm A');
		var milliStart = moment(date1).valueOf();
		var milliEnd = moment(date1).add(30, 'm').format('yyyy-MM-DD h:mm A');
		setTotalTax(taxTot);
		var objArray = {
			hidProcedureId: obj.procedure_id,
			hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? window.sessionStorage.getItem('userId') : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),

			hidTaxIndividual: (taxTotal !== 0.00 ? taxTotal : 0.00),
			txtTreatmentCost: (obj.planned_amount !== "" && obj.planned_amount !== undefined) ? (parseFloat(obj.planned_amount).toFixed(2) !== "" ? parseFloat(obj.planned_amount).toFixed(2) : "0") : (parseFloat(obj.price).toFixed(2) !== "" ? parseFloat(obj.price).toFixed(2) : "0"),
			hidDiscountIndividual: '0.00',
			hidGrandIndividual: ((obj.planned_quantity ? obj.planned_quantity : "1") * (obj.planned_amount !== "" && obj.planned_amount !== undefined ? obj.planned_amount : obj.price)) + (taxTotal !== 0.00 ? taxTotal : 0.00),
			txtNotes: obj.notes ? obj.notes : "",
			spinner: (obj.planned_quantity ? obj.planned_quantity : "1"),
			hidTeethNumber: obj.teethDetails ? obj.teethDetails : 'None Selected',
			disType: 'percentage',
			txtDisAmt: '0.00',
			scheduledtimeSlotStartTime: milliStart,
			scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
			chkMultipleCost: obj.multiplyCostWithTeeth,
			appDate: date1,
			dateOn: new Date(date1),
			hidMemberProcedureDentalDetailsId: obj.member_procedure_dental_details_id,
			procedureName: obj.procedure_name,
			procedureCode: obj.procedure_code,
			showTeeth: obj.showTeeth,
			hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? window.sessionStorage.getItem('userId') : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
			doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : obj.doctorName ? obj.doctorName : doctorName),
			fullMouth: 0,
			taxDetails: taxDetails.taxDetails
		};
		if (isAthorModules === "1") {
			procedureDataarray.push(objArray)
			setProcedureArray([...procedureDataarray]);
		}
		else {
			procedureArrayOld.push(objArray)
			setProcedureArray([...procedureArrayOld]);
		}

		setTotalCost(totalCostValue);
		setGrandTotal(totalGrand);

	};
	const addPrescriptions = async (obj, doctor_visit_medicine_id, id, isAthorModules) => {

		var prescriptionArrayOld = JSON.parse(window.sessionStorage.getItem('prescriptionArrayOld'))
		prescriptionArrayOld.map((obj1) => {
			obj1.dateOn = new Date(obj1.dateOn)
			obj1.dtStartdate = new Date(obj1.dtStartdate)
			return obj1
		})

		let isData = true;
		if (id !== "") {
			if (typeof obj.changed_quantity === "undefined") {
				var prescriptionQty = document.getElementById('hidNonPlannedPrescriptionQty' + id).value
				document.getElementById('nonPlannedPrescriptionQty' + id).innerHTML = (parseInt(prescriptionQty) - 1)
			}
			else {
				document.getElementById('plannedPrescription' + doctor_visit_medicine_id).style.display = 'none';
				if (document.getElementById('hidNonPlannedPrescriptionQty' + id) !== null) {
					var prescriptionQty = document.getElementById('hidNonPlannedPrescriptionQty' + id).value
					document.getElementById('nonPlannedPrescriptionQty' + id).innerHTML = (parseInt(prescriptionQty) - parseInt(obj.changed_quantity === 0 ? 1 : obj.changed_quantity))

				}
				else {
					isData = false;
					var notInHospitalNew = [...notInHospitalMedicine]
					var allPrescriptionData = JSON.parse(window.sessionStorage.getItem('allPrescriptionData'))
					let notMedicine = allPrescriptionData.filter((obj1) =>
						obj1.doctorVisitMedicineId.toString() == obj.doctor_visit_medicine_id.toString()
					)
					notInHospitalNew.push(notMedicine[0])
					setNotInHospitalMedicine([...notInHospitalNew])
					document.getElementById("medicineNotHospital").style.display = 'block'
				}

			}
		}
		if (isData === true) {
			var instructions = ""
			var cmbFrequency = "0"
			var frequencyDetailsArray = frequencyDetails
			if (frequencyDetailsArray.length === 0) {
				frequencyDetailsArray = await frequencyAndIntakeDetails()
			}
			let newTimes = []
			if (obj.cmbFrequency !== "0") {
				newTimes = frequencyDetailsArray.filter((item) => {
					return (item.id === obj.cmbFrequency)
				})
			}
			var newTimesArray = []
			var cmbTime = "0"

			if (newTimes.length > 0) {
				if (newTimes[0].times > 0) {
					for (var n = 0; newTimes[0].times > n; n++) {
						var a = { val: "" }
						newTimesArray.push(a)
					}
				}

				if (typeof obj.instructions !== "undefined") {
					instructions = obj.instructions.split("-")
					if (instructions.length === 5) {
						if (newTimes[0].times === "1") {
							cmbFrequency = instructions[0]
							newTimesArray[0].val = instructions[1]
							cmbTime = instructions[4]
						} else if (newTimes[0].times === "3" || newTimes[0].times === "4") {
							cmbFrequency = instructions[0]
							newTimesArray[0].val = instructions[1]
							newTimesArray[1].val = instructions[2]
							newTimesArray[2].val = instructions[3]
							cmbTime = instructions[4]
						}
					} else if (instructions.length === 6) {
						if (newTimes[0].times === "4") {
							cmbFrequency = instructions[0]
							newTimesArray[0].val = instructions[1]
							newTimesArray[1].val = instructions[2]
							newTimesArray[2].val = instructions[3]
							if (instructions[4] !== "") {
								newTimesArray[3].val = instructions[4]
							} else {
								newTimesArray[3].val = "0"
							}

							cmbTime = instructions[5]
						}
					} else if (instructions.length === 3) {
						cmbFrequency = instructions[0]
						newTimesArray[0].val = instructions[1]
						cmbTime = instructions[2]
					}

				}
			}
			await frequencyAndIntakeDetails()
			var taxDetails = await getPrescriptionTaxDetails(obj.pm_hospital_medicine_id, ((obj.changed_quantity ? obj.changed_quantity : 1) * obj.unitPrice));

			var taxTotal = taxDetails.taxAmountTotal;
			var taxTot = taxTotal + totalTax
			var totalCostValue = +totalCost + +obj.unitPrice;
			var totalGrand = +grandTotal + ((obj.changed_quantity ? obj.changed_quantity : 1) * obj.unitPrice) + (taxTotal !== 0.00 ? taxTotal : 0.00);

			var date1 = moment(new Date()).format('yyyy-MM-DD h:mm A');
			var milliStart = moment(date1).valueOf();
			var milliEnd = moment(date1).add(30, 'm').format('yyyy-MM-DD h:mm A');
			var dateOnly = moment(new Date()).format('yyyy-MM-DD');
			if (obj.startDate) {
				var dateSplit = obj.startDate.split('/')
				var startDate = moment(new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0])).format('yyyy-MM-DD');
			}
			setTotalTax(taxTot);
			var prescriptionObj = {
				hidPmHospitalMedicineId: obj.pm_hospital_medicine_id,
				hidTaxIndividual: (taxTotal !== 0.00 ? taxTotal : 0.00),
				txtTreatmentCost: parseFloat(obj.unitPrice).toFixed(2) !== "" ? parseFloat(obj.unitPrice).toFixed(2) : "0",
				hidDiscountIndividual: '0',
				hidGrandIndividual: parseFloat(((obj.changed_quantity ? obj.changed_quantity : 1) * parseFloat(obj.unitPrice).toFixed(2)) + parseFloat(taxTotal !== 0.00 ? parseFloat(taxTotal).toFixed(2) : 0.00)).toFixed(2),
				txtNotes: obj.notes !== null ? obj.notes : "",
				cmbFrequency: cmbFrequency,//(instructions.length > 0 ?instructions[0] : "0")
				txtFrequencyMore: newTimesArray.length > 3 ? newTimesArray[3].val : "",
				txtMorning: newTimesArray.length > 0 ? newTimesArray[0].val : "0",
				txtNoon: newTimesArray.length > 1 ? newTimesArray[1].val : "0",
				txtNight: newTimesArray.length > 2 ? newTimesArray[2].val : "0",
				cmbTime: cmbTime,
				txtDays: obj.txtDays ? obj.txtDays : '0',
				dtStartdate: obj.startDate ? new Date(startDate) : new Date(dateOnly),
				scheduledtimeSlotStartTime: milliStart,
				scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
				checkboxRefill: (obj.refill ? (obj.refill === 0 ? false : true) : false),
				spinner: obj.changed_quantity ? obj.changed_quantity : '1',
				hidDoctorVisitMedicineId: obj.doctor_visit_medicine_id ? obj.doctor_visit_medicine_id : "",
				hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? window.sessionStorage.getItem('userId') : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
				medicineName: obj.medicine,
				appDate: date1,
				dateOn: new Date(),
				//hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : doctorId),
				doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : doctorName),
				timesArray: newTimesArray.length > 0 ? newTimesArray : [],
				taxDetails: taxDetails.taxDetails
			};
			if (isAthorModules === "1") {
				console.log("prescriptionObj.....", prescriptionObj);
				prescriptionDataArray.push(prescriptionObj)
				window.sessionStorage.setItem('prescriptionArrayOld', JSON.stringify(prescriptionDataArray))
				setPrescriptionArray([...prescriptionDataArray]);
			}
			else {
				console.log("prescriptionObj..222222..", prescriptionObj);
				prescriptionArrayOld.push(prescriptionObj)
				window.sessionStorage.setItem('prescriptionArrayOld', JSON.stringify(prescriptionArrayOld))
				setPrescriptionArray([...prescriptionArrayOld]);
			}
			setTotalCost(totalCostValue);
			setGrandTotal(totalGrand);
		}
	};
	const addLabOrder = async (obj, i, id, isAthorModules) => {

		console.log("id.........", id);
		typeDatas();
		if (id != "") {
			document.getElementById('plannedLabOrder' + id).style.display = 'none';
		}
		// document.getElementById('plannedLabOrder' + doctor_visit_medicine_id).style.display = 'none';
		var labOrderrrayOld = [...labOrderArray]
		var taxDetails = await getLabTaxDetails(obj.service_provider_test_id, obj.buy_price);
		var taxTotal = taxDetails.taxAmountTotal;
		var taxTot = taxTotal + totalTax
		var totalCostValue = +totalCost + +obj.buy_price;
		var totalGrand = (+grandTotal + +obj.buy_price) + (taxTotal !== 0.00 ? taxTotal : 0.00);
		var date1 = moment(new Date()).format('yyyy-MM-DD h:mm A');
		var milliStart = moment(date1).valueOf();
		var milliEnd = moment(date1).add(30, 'm').format('yyyy-MM-DD h:mm A');
		setTotalTax(taxTot);

		var labObj = {
			hidServiceProviderTestId: obj.service_provider_test_id,
			hidServiceProviderTestDetailsId: obj.service_provider_test_details_id,
			cmbSpecimen: obj.cmbSpecimenId ? obj.cmbSpecimenId : '',
			cmbtypeofTest: obj.cmbTypeOfTest ? obj.cmbTypeOfTest : '',
			spinner: '1',
			hidTaxIndividual: (taxTotal !== 0.00 ? taxTotal : 0.00),
			txtTreatmentCost: parseFloat(obj.buy_price).toFixed(2) !== "" ? parseFloat(obj.buy_price).toFixed(2) : "0",
			hidDiscountIndividual: '0',
			hidGrandIndividual: obj.buy_price + (taxTotal !== 0.00 ? taxTotal : 0.00),
			txtNotes: obj.notes ? obj.notes : "",
			scheduledtimeSlotStartTime: milliStart,
			scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
			hidDoctorVisitLabOrderTest: obj.doctor_visit_lab_order_test_id ? obj.doctor_visit_lab_order_test_id : "",
			hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
			testName: obj.test_name,
			appDate: date1,
			dateOn: new Date(date1),
			hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : obj.doctorId ? obj.doctorId : doctorId),
			doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : obj.doctorName ? obj.doctorName : doctorName),
			taxDetails: taxDetails.taxDetails
		};

		if (isAthorModules === "1") {
			labOrderDataArray.push(labObj)
			setLabOrderArray([...labOrderDataArray]);
		}
		else {
			labOrderrrayOld.push(labObj)
			setLabOrderArray([...labOrderrrayOld]);
		}
		setTotalCost(totalCostValue);
		setGrandTotal(totalGrand);
	};
	const addDisposable = async (obj, i) => {

		var taxDetails = await getPrescriptionTaxDetails(obj.pmHospitalMedicineId, (obj.unitPrice));
		var taxTotal = taxDetails.taxAmountTotal;
		var taxTot = taxTotal + totalTax
		var totalCostValue = +totalCost + +obj.unitPrice;
		var totalGrand = (+grandTotal + +obj.unitPrice) + (taxTotal !== 0.00 ? taxTotal : 0.00);
		var date1 = moment(new Date()).format('yyyy-MM-DD h:mm A');
		//	var date2 = moment(new Date()).format('yyyy-MM-DD HH:mm:ss');
		var milliStart = moment(date1).valueOf();
		var milliEnd = moment(date1).add(30, 'm').format('yyyy-MM-DD h:mm A');
		setTotalTax(taxTot);
		var diposObj = {
			hidDisposableId: obj.hospitalInventoryMasterId,
			hidDisposableDoctorVisitMedicineId: '',
			hidDisposablePmHospitalMedicineId: obj.pmHospitalMedicineId,
			hidTaxIndividual: (taxTotal !== 0.00 ? taxTotal : 0.00),
			txtTreatmentCost: parseFloat(obj.unitPrice).toFixed(2) !== "" ? parseFloat(obj.unitPrice).toFixed(2) : "0",
			hidDiscountIndividual: '0',
			hidGrandIndividual: obj.unitPrice + (taxTotal !== 0.00 ? taxTotal : 0.00),
			scheduledtimeSlotStartTime: milliStart,
			scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
			txtNotes: '',
			spinner: '1',
			// hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
			hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? window.sessionStorage.getItem('userId') : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),

			disposableName: obj.inventoryName,
			appDate: date1,
			dateOn: new Date(),
			// hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : doctorId),
			hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? window.sessionStorage.getItem('userId') : doctorId),

			doctorName: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : doctorName),
			taxDetails: taxDetails.taxDetails
		};
		setDisposableArray([...disposableArray, diposObj]);
		setTotalCost(totalCostValue);
		setGrandTotal(totalGrand);
	};
	const setTeethInfo = (data, index) => {
		if (data === 'Multiply Cost') {
			if (procedureArray[index].chkMultipleCost === 1) {
				procedureArray[index].spinner = 1
				procedureArray[index].hidGrandIndividual = procedureArray[index].txtTreatmentCost
			}
			else {
				procedureArray[index].spinner = procedureArray[index].hidTeethNumber.toString().split('|').length
				procedureArray[index].hidGrandIndividual = procedureArray[index].spinner * procedureArray[index].txtTreatmentCost
			}
			procedureArray[index].fullMouth = 0;
			procedureArray[index].chkMultipleCost = procedureArray[index].chkMultipleCost === 1 ? 0 : 1;
		} else if (data === 'Full Mouth' || data === 'None Selected') {
			if (data === 'Full Mouth') {
				if (procedureArray[index].fullMouth === 1) {
					var element = document.getElementsByClassName('teethImg' + index);
					for (var i = 0; i < element.length; i++) {
						if (procedureArray[index].fullMouth === 0) {
							element[i].classList.add('teethActive');
						} else {
							element[i].classList.remove('teethActive');
						}
					}
				}
				procedureArray[index].hidTeethNumber =
					procedureArray[index].fullMouth === 1 ? 'None Selected' : 'Full Mouth';
				procedureArray[index].fullMouth = procedureArray[index].fullMouth === 1 ? 0 : 1;
				procedureArray[index].chkMultipleCost = 0;
				procedureArray[index].spinner = 1;
				procedureArray[index].hidGrandIndividual = procedureArray[index].txtTreatmentCost

			} else {
				procedureArray[index].hidTeethNumber = (data === "null" ? "" : data);
			}
		} else {
			if (procedureArray[index].chkMultipleCost === 1) {
				procedureArray[index].spinner = data.toString().split('|').length;
				procedureArray[index].hidGrandIndividual =
					data.toString().split('|').length * procedureArray[index].txtTreatmentCost;
			}
			procedureArray[index].hidTeethNumber = (data === "null" ? "" : data);
		}
		setProcedureArray([...procedureArray]);
		getTaxDetailsWithoutAsync(procedureArray[index].hidProcedureId, (procedureArray[index].spinner * procedureArray[index].txtTreatmentCost), index, procedureArray[index].spinner, procedureArray[index].txtTreatmentCost, procedureArray[index].taxDetails)

	};
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})

		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const addNotes = (i, mode) => {
		document.getElementById('txtNotes' + mode + i).style.display = 'block';
		document.getElementById('addNoteLabel' + mode + i).style.display = 'none';
	};

	const setDate = (date, i, mode) => {
		if (mode === "Procedure") {
			procedureArray[i].appDate = moment(new Date(date)).format('yyyy-MM-DD h:mm A');
			procedureArray[i].dateOn = new Date(date);
			setProcedureArray([...procedureArray]);
		} else if (mode === "Prescription") {
			prescriptionArray[i].appDate = moment(new Date(date)).format('yyyy-MM-DD h:mm A');
			prescriptionArray[i].dateOn = new Date(date);
			setPrescriptionArray([...prescriptionArray]);
		} else if (mode === "Lab") {
			labOrderArray[i].appDate = moment(new Date(date)).format('yyyy-MM-DD h:mm A');
			labOrderArray[i].dateOn = new Date(date);
			setLabOrderArray([...labOrderArray]);
		} else if (mode === "Dispos") {
			disposableArray[i].appDate = moment(new Date(date)).format('yyyy-MM-DD h:mm A');
			disposableArray[i].dateOn = new Date(date);
			setDisposableArray([...disposableArray]);
		}
	};

	const setStartDate = (date, i) => {
		prescriptionArray[i].dtStartdate = date
		setPrescriptionArray([...prescriptionArray]);
	}
	const onChangeAddNotes = (event, i) => {
		procedureArray[i].txtNotes = event.target.value
		setProcedureArray([...procedureArray]);
	};

	const onChangeAddNotesDispos = (event, i) => {
		const { name, value } = event.target;
		const values = [...disposableArray];
		values[i] = { ...values[i], [name]: value };
		setDisposableArray(values);
	};
	const onChangeAddNotesLab = (event, i) => {
		const { name, value } = event.target;
		const values = [...labOrderArray];
		values[i] = { ...values[i], [name]: value };
		setLabOrderArray(values);
	};
	const onChangeAddNotesPrescription = (event, i) => {
		const { name, value } = event.target;
		const values = [...prescriptionArray];
		values[i] = { ...values[i], [name]: value };
		setPrescriptionArray(values);
	};

	const showTeethListing = (i, mode) => {
		if (mode !== '1') {
			document.getElementById("showTeethDiv" + i).style.display = (document.getElementById("showTeethDiv" + i).style.display === "block" ? "none" : "block")
		}
		//document.getElementById('showTeethDiv' + i).style.display = 'block';
		document.getElementById('showTeeth' + i).style.display = 'none';
		document.getElementById('txtTeeth' + i).style.display = 'block';
	};


	const onChangeLab = (e, i) => {
		const { name, value } = e.target;
		const values = [...labOrderArray];
		values[i] = { ...values[i], [name]: value };
		setLabOrderArray(values);
	};
	const typeDatas = () => {
		const apiJson = {
			hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
		};
		callingAPI('adminReports/specimenTypeOftest', apiJson).then((data) => {
			if (data.data.success === '1') {
				setSpecimenType(data.data.result.specimenType);
				setTypeOfTest(data.data.result.typeOfTest);
			}
		});
	};
	const frequencyAndIntakeDetails = async () => {
		return new Promise((resolve, reject) => {
			callingAPI('treatmentPlanDetails/frequencyIntake', "").then((data) => {
				console.log('data...frequencyIntake**...', data);
				if (data.data.success === '1') {
					setFrequencyDetails(data.data.result.frequency)
					setIntakeDetails(data.data.result.intake)
					resolve(data.data.result.frequency)

				} else {
					resolve(true)
				}
			});
		});
	};

	const removeProcedure = (i, obj, id) => {
		let removed = [...procedureArray];
		removed.splice(i, 1);
		setProcedureArray(removed);
		if (id) {
			if (document.getElementById(id) !== null) {
				document.getElementById(id).style.display = 'block';
			}
		}
		var taxTot = +totalTax + -obj.hidTaxIndividual;
		var cost = +totalCost + -obj.txtTreatmentCost;
		var grand = +grandTotal + -obj.hidGrandIndividual;
		setTotalCost(cost);
		setTotalTax(taxTot);
		setGrandTotal(grand);
		setErrMsg("Successfully removed your Treatment plan")
		removeMessage()
	};
	const removePrescription = (i, obj, id) => {


		let removed = [...prescriptionArray];
		removed.splice(i, 1);
		window.sessionStorage.setItem('prescriptionArrayOld', JSON.stringify(removed))
		setPrescriptionArray(removed);
		if (id) {
			if (document.getElementById(id) !== null) {
				document.getElementById(id).style.display = 'block';

			}
		}
		var taxTot = +totalTax + -obj.hidTaxIndividual;
		var cost = +totalCost + -obj.unitPrice;
		var grand = +grandTotal + -obj.unitPrice;
		setTotalCost(cost);
		setGrandTotal(grand);
		setTotalTax(taxTot);
		setErrMsg("Successfully removed your Medicine")
		if (document.getElementById("hidPlannedPrescriptionQty" + obj.hidPmHospitalMedicineId) != null) {
			if (document.getElementById("hidNonPlannedPrescriptionQty" + obj.hidPmHospitalMedicineId) !== null) {
				document.getElementById('nonPlannedPrescriptionQty' + obj.hidPmHospitalMedicineId).innerHTML = document.getElementById("hidNonPlannedPrescriptionQty" + obj.hidPmHospitalMedicineId).value
			}
		}
		else {
			if (document.getElementById("hidNonPlannedPrescriptionQty" + obj.hidPmHospitalMedicineId) !== null) {
				document.getElementById('nonPlannedPrescriptionQty' + obj.hidPmHospitalMedicineId).innerHTML = document.getElementById("hidNonPlannedPrescriptionQty" + obj.hidPmHospitalMedicineId).value
			}
		}
		removeMessage()

	};
	const removeLabOrder = (i, obj, id) => {
		console.log("obj.....", obj);
		let removed = [...labOrderArray];
		removed.splice(i, 1);
		setLabOrderArray(removed);
		if (id) {
			if (document.getElementById(id) !== null) {
				document.getElementById(id).style.display = 'block';
			}
			//document.getElementById('plannedLabOrder' + id).style.display = 'none';
		}
		var taxTot = +totalTax + -obj.hidTaxIndividual;
		var cost = +totalCost + -obj.txtTreatmentCost;
		var grand = +grandTotal + -obj.hidGrandIndividual;
		setTotalCost(cost);
		setGrandTotal(grand);
		setTotalTax(taxTot);
		setErrMsg("Successfully removed your Lab Test")
		removeMessage()
	};
	const removeDisposables = (i, obj) => {
		let removed = [...disposableArray];
		removed.splice(i, 1);
		setDisposableArray(removed);
		var taxTot = +totalTax + -obj.hidTaxIndividual;
		var cost = +totalCost + -obj.txtTreatmentCost;
		var grand = +grandTotal + -obj.hidGrandIndividual;
		setTotalCost(cost);
		setGrandTotal(grand);
		setTotalTax(taxTot);
		setErrMsg("Successfully removed your disposable item")
		removeMessage()
	};
	const onChangeQuantity = async (event, j, type, array) => {
		let flag = true;
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		const { name, value } = event.target;
		var values = [...array]
		var id = ""
		if (array === procedureArray) {
			id = values[j].hidProcedureId
		} else if (array === prescriptionArray) {
			id = values[j].hidPmHospitalMedicineId
		} else if (array === labOrderArray) {
			id = values[j].hidServiceProviderTestId
		} else if (array === disposableArray) {
			id = values[j].hidDisposableId
		}
		if (type === "Prescription" && name === 'spinner') {
			if (event.target.value === '' || re.test(event.target.value)) {
				if (document.getElementById('nonPlannedPrescriptionQty' + id) != null) {
					var prescriptionQty = document.getElementById('hidNonPlannedPrescriptionQty' + id).value
					let preQty = (parseInt(prescriptionQty) - value)
					if (parseInt(preQty) >= 0) {
						document.getElementById('nonPlannedPrescriptionQty' + id).innerHTML = preQty
					}
					else {
						setErrMsg("Sorry only " + prescriptionQty + " stock available")
						removeMessage()
						flag = false;
					}
				}
				// else {
				// 	alert('Only Numbers Accepted')
				// }
			}
		}
		if (flag === true) {
			// const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
			let currentQty = 0;
			let currentCost = 0;
			if (event.target.value === '' || event.target.value == '.' || event.target.value == '.0' || re.test(event.target.value)) {
				console.log("event.target.value......", event.target.value);
				if (name === 'spinner') {

					currentQty = parseInt((value == "" || value == 0) ? 1 : value);
					currentCost = values[j].txtTreatmentCost !== "" ? values[j].txtTreatmentCost : "0";
					//}
				} else {
					currentQty = values[j].spinner;
					currentCost = (value === "." ? "0." : value);
				}
				values[j] = {
					...values[j],
					[name]: value,
					hidGrandIndividual: currentCost * currentQty,
					spinner: currentQty,
					txtTreatmentCost: currentCost !== "" ? currentCost : "0",
				};
				console.log("values[j].txtTreatmentCost)......", values[j].txtTreatmentCost);
			} else {
				if (name === 'spinner') {
					currentQty = 1;
					currentCost = values[j].txtTreatmentCost !== "" ? values[j].txtTreatmentCost : "0";
				} else {
					alert('Only Numbers Accepted')
					currentQty = values[j].spinner;
					currentCost = values[j].txtTreatmentCost !== "" ? values[j].txtTreatmentCost : "0";
				}
				values[j] = {
					...values[j],
					[name]: value,
					hidGrandIndividual: currentCost * currentQty,
					spinner: currentQty,
					txtTreatmentCost: currentCost !== "" ? currentCost : "0",
				};
				console.log("values[j].txtTreatmentCost;....22222..", values[j].txtTreatmentCost);
			}
			if (type === "Procedure") {
				setProcedureArray(values);
				getTaxDetailsWithoutAsync(values[j].hidProcedureId, (currentQty * currentCost), j, currentQty, currentCost, values[j].taxDetails)
			} else if (type === "Prescription") {
				setPrescriptionArray(values);
				getPrescriptionTaxDetailsWithoutAsync(values[j].hidPmHospitalMedicineId, (currentQty * currentCost), j, currentQty, currentCost, "Prescription", values[j].taxDetails)
			} else if (type === "LabOrder") {
				setLabOrderArray(values);
				getLabTaxDetailsWithOutAsync(values[j].hidServiceProviderTestId, (currentQty * currentCost), j, currentQty, currentCost, values[j].taxDetails)
			} else if (type === "disposible") {
				setDisposableArray(values);
				getPrescriptionTaxDetailsWithoutAsync(id, (currentQty * currentCost), j, currentQty, currentCost, "disposible", values[j].taxDetails)
			}
		}
	};

	const costAndQuantityCalculation = (e) => {

		var totalCost1 = 0;
		var totalTax1 = 0.00
		var grand = 0.00
		var discount = 0
		for (var i = 0; i < procedureArray.length; i++) {
			var quantity = procedureArray[i].spinner;
			var cost = procedureArray[i].txtTreatmentCost;
			var tax = procedureArray[i].hidTaxIndividual
			setTotalTax(tax)
			totalCost1 = +totalCost1 + + quantity * cost;
			totalTax1 = +totalTax1 + + tax
			grand = (+totalCost1 + + totalTax1);
			if (
				procedureArray[i].showTeeth === 1 &&
				procedureArray[i].hidTeethNumber !== '' &&
				procedureArray[i].hidTeethNumber !== 'None Selected' &&
				procedureArray[i].hidTeethNumber !== 'Full Mouth'
			) {
				showTeethListing(i, '1');
				var teethData = procedureArray[i].hidTeethNumber.toString().split('|');
				for (let index = 0; index < teethData.length; index++) {
					var element = document.getElementsByClassName('teethImg' + teethData[index] + i);
					element[0].classList.add('teethActive');
				}
			} else if (procedureArray[i].hidTeethNumber === 'Full Mouth') {
				showTeethListing(i, '1');
				var element = document.getElementsByClassName('teethImg' + i);
				for (let index = 0; index < element.length; index++) {
					element[index].classList.add('teethActive');
				}
			}
			if (
				procedureArray[i].txtNotes !== '' &&
				procedureArray[i].txtNotes !== null &&
				procedureArray[i].txtNotes !== 'null'
			) {
				document.getElementById('txtNotesPro' + i).style.display = 'block';
				document.getElementById('addNoteLabelPro' + i).style.display = 'none';
			}
		}
		for (var i = 0; i < prescriptionArray.length; i++) {
			var quantity = prescriptionArray[i].spinner;
			var cost = prescriptionArray[i].txtTreatmentCost;
			var tax = prescriptionArray[i].hidTaxIndividual

			totalCost1 = +totalCost1 + + quantity * cost;
			totalTax1 = +totalTax1 + + tax
			grand = (+totalCost1 + + totalTax1);

			if (
				prescriptionArray[i].txtNotes !== '' &&
				prescriptionArray[i].txtNotes !== null &&
				prescriptionArray[i].txtNotes !== 'null'
			) {
				document.getElementById('txtNotesPres' + i).style.display = 'block';
				document.getElementById('addNoteLabelPres' + i).style.display = 'none';
			}
		}
		for (var i = 0; i < disposableArray.length; i++) {
			var quantity = disposableArray[i].spinner;
			var cost = disposableArray[i].txtTreatmentCost;
			var tax = disposableArray[i].hidTaxIndividual
			//var discountAmt = totalDiscount
			totalCost1 = +totalCost1 + + quantity * cost;
			totalTax1 = +totalTax1 + + tax
			grand = (+totalCost1 + + totalTax1);
			if (
				disposableArray[i].txtNotes !== '' &&
				disposableArray[i].txtNotes !== null &&
				disposableArray[i].txtNotes !== 'null'
			) {
				document.getElementById('txtNotesDispo' + i).style.display = 'block';
				document.getElementById('addNoteLabelDispo' + i).style.display = 'none';
			}
		}
		for (var i = 0; i < labOrderArray.length; i++) {
			var quantity = labOrderArray[i].spinner;
			var cost = labOrderArray[i].txtTreatmentCost;
			var tax = labOrderArray[i].hidTaxIndividual

			totalCost1 = +totalCost1 + + quantity * cost;
			totalTax1 = +totalTax1 + + tax
			//discount = (totalCost1 + totalTax1) - discountAmt
			grand = (+totalCost1 + + totalTax1);
			if (
				labOrderArray[i].txtNotes !== '' &&
				labOrderArray[i].txtNotes !== null &&
				labOrderArray[i].txtNotes !== 'null'
			) {
				document.getElementById('txtNotesLab' + i).style.display = 'block';
				document.getElementById('addNoteLabelLab' + i).style.display = 'none';
			}
		}
		if (totalDiscount > totalCost) {
			var discount = 0.00
			var total = totalCost
			setTotalDiscount(discount)

			setGrandTotal(total);
		} else {
			var grand = (totalCost + totalTax1) - totalDiscount
			setGrandTotal(grand);

		}
		setTotalCost(totalCost1);
		setTotalTax(totalTax1);
		setTotalDiscount(totalDiscount)

	};
	const prescriptionOnChange = (event, id) => {
		const { name, value } = event.target;
		const values = [...prescriptionArray];
		values[id] = { ...values[id], [name]: value };

		if (event.target.name === "cmbFrequency") {
			if (event.target.value !== "0") {
				var timesArray = []
				timesArray = frequencyDetails.filter((item) => {
					return (item.name === event.target.value)
				})
				if (timesArray.length > 0) {
					if (timesArray[0].times > 0) {
						var times = timesArray[0].times
						var newArray = []
						for (var i = 0; times > i; i++) {
							var a = { val: "" }
							newArray.push(a)
						}
					}
				}
				values[id].timesArray = newArray
			}
		}
		setPrescriptionArray([...values]);

	};
	const timesOnChange = (e, k, j) => {
		const { name, value } = e.target;
		const values = [...prescriptionArray];
		values[k].timesArray[j] = { ...values[k].timesArray[j], [name]: value };
		setPrescriptionArray(values);
		if (prescriptionArray[k].timesArray.length > 0) {
			if (prescriptionArray[k].timesArray.length === 3) {
				prescriptionArray[k].txtMorning = prescriptionArray[k].timesArray[0].val
				prescriptionArray[k].txtNoon = prescriptionArray[k].timesArray[1].val
				prescriptionArray[k].txtNight = prescriptionArray[k].timesArray[2].val
			} else if (prescriptionArray[k].timesArray.length === 4) {
				prescriptionArray[k].txtMorning = prescriptionArray[k].timesArray[0].val
				prescriptionArray[k].txtNoon = prescriptionArray[k].timesArray[1].val
				prescriptionArray[k].txtNight = prescriptionArray[k].timesArray[2].val
				prescriptionArray[k].txtFrequencyMore = prescriptionArray[k].timesArray[3].val
			} else if (prescriptionArray[k].timesArray.length === 1) {
				prescriptionArray[k].txtMorning = prescriptionArray[k].timesArray[0].val
			}
		}
	}
	const prescriptionCheckOnChange = (event, i) => {
		const { name, checked } = event.target;
		const values = [...prescriptionArray];
		values[i] = { ...values[i], checkboxRefill: checked };
		setPrescriptionArray([...values]);
	};
	const doctorDropdown = (i, mode) => {
		const apiJson = {
			hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
			selectCombo: '',
			inputSearch: '',
			max: 100,
			offset: 0,
		};
		document.getElementById('doctorListDiv' + mode + i).style.display = 'block';
		callingAPI('commonUserFunctions/doctorsList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setDoctorList(data.data.result.doctorsListArray);
			}
			else {
				alert("no doctors found")
			}
		});
	};
	const payNowEnable = async () => {
		if (isFlag !== "") {
			let PostData = {
				"hospitalId": hospitalId,
				"memberId": memberIds
			}
			await callingAPI('payment/advanceAmountDetails', PostData).then((response) => {
				setoldAdvanceAmt(response.data.result.advanceAmt)
			});
		}
		let isValidateOk = true;
		if (prescriptionArray.length > 0) {
			setIsLoader(false);
			for (let index = 0; index < prescriptionArray.length; index++) {
				if (
					prescriptionArray[index].cmbFrequency === '' ||
					prescriptionArray[index].cmbFrequency === '0'
				) {
					// setErrMsg('Please Select Frequency & Dosage');
					alert("Please Select Frequency & Dosage")
					isValidateOk = false;
					removeMessage()
					break;
				} else if (
					prescriptionArray[index].cmbTime === '' ||
					prescriptionArray[index].cmbTime === '0'
				) {
					// setErrMsg('Please Select Intake');
					alert("Please Select Intake")
					isValidateOk = false;
					removeMessage()
					break;
				}

			}
		}

		if (isValidateOk === true) {
			document.getElementById("mainDiv1").classList.add("treatmentSectionDisable");
			document.getElementById("mainDiv2").classList.add("treatmentSectionDisable");
			document.getElementById("mainDiv3").classList.add("treatmentSectionDisable");
			document.getElementById("paymentDiv").style.display = "block"
			setPayNowTotal(parseFloat(grandTotal - oldAdvanceAmt).toFixed(2))
			if (oldAdvanceAmt > 0) {
				if (oldAdvanceAmt > grandTotal) {
					setPayNowTotal("0.00")
					setDueAfterPayment("0.00")
					setAdvanceAfterPayment(oldAdvanceAmt - grandTotal)
				} else {
					setPayNowTotal(parseFloat(grandTotal - oldAdvanceAmt).toFixed(2))
					setDueAfterPayment("0.00")
					setAdvanceAfterPayment(oldAdvanceAmt - grandTotal)
				}
			} else {
				setPayNowTotal(parseFloat(grandTotal).toFixed(2))
				setDueAfterPayment("0.00")
				setAdvanceAfterPayment(oldAdvanceAmt - grandTotal)
			}
		}
	}
	const settingDueAfterPay = (val) => {
		var dueAmount = "0.00"
		var advanceAmt = "0.00"
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (val === '' || re.test(val)) {
			setPayNowTotal(val)
			if (oldAdvanceAmt > 0 && grandTotal < oldAdvanceAmt) {
				advanceAmt = (parseFloat(oldAdvanceAmt) - parseFloat(grandTotal)) + parseFloat(val)
			}
			if (val >= grandTotal) {
				advanceAmt = parseFloat(val) - (parseFloat(grandTotal) - parseFloat(oldAdvanceAmt))
			} else {
				dueAmount = (parseFloat(grandTotal) - parseFloat(oldAdvanceAmt)) - (parseFloat(val))
				advanceAmt = (parseFloat(oldAdvanceAmt) + parseFloat(val)) - parseFloat(grandTotal)
			}
		} else {

			alert('Only Numbers Accepted')
		}
		setAdvanceAfterPayment(advanceAmt)
		setDueAfterPayment(dueAmount)
	}
	const paynowCancel = () => {
		document.getElementById("mainDiv1").classList.remove("treatmentSectionDisable");
		document.getElementById("mainDiv2").classList.remove("treatmentSectionDisable");
		document.getElementById("mainDiv3").classList.remove("treatmentSectionDisable");
		document.getElementById("paymentDiv").style.display = "none"
		setAdvanceAfterPayment("0.00")
		setDueAfterPayment("0.00")
	}
	const setDoctorName = (doctorObj, index, mode) => {
		if (mode === "Procedure") {
			procedureArray[index].hidDoctorId = doctorObj.doctorId;
			procedureArray[index].doctorName = doctorObj.doctorName;
			setProcedureArray([...procedureArray]);
		} else if (mode === "Prescription") {
			prescriptionArray[index].hidDoctorId = doctorObj.doctorId;
			prescriptionArray[index].doctorName = doctorObj.doctorName;
			setPrescriptionArray([...prescriptionArray]);
		} else if (mode === "LabOrder") {
			labOrderArray[index].hidDoctorId = doctorObj.doctorId;
			labOrderArray[index].doctorName = doctorObj.doctorName;
			setLabOrderArray([...labOrderArray]);
		} else if (mode === "Disposable") {
			disposableArray[index].hidDoctorId = doctorObj.doctorId;
			disposableArray[index].doctorName = doctorObj.doctorName;
			setDisposableArray([...disposableArray]);
		}
		document.getElementById('doctorListDiv' + mode + index).style.display = 'none';
	};

	const settingDiscount = (e) => {
		var discount = e.target.value;
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (e.target.value === '' || re.test(e.target.value)) {
			setTotalDiscount(e.target.value);
			if (e.target.value > Number(totalCost)) {
				alert("Discount amount should be less than or equal to grand total")
				discount = ""
			}
			var grand = (totalCost - parseFloat(discount)) + totalTax;
			setTotalDiscount(discount)
			setGrandTotal((grand));
		} else {
			document.getElementById("totalDiscount").value = ''
			var grand = +(totalCost) + +totalTax;
			setTotalDiscount(0);
			alert("Only Number accepted")
			setGrandTotal(grand);
		}
	};


	const removeMessage = () => {
		setTimeout(() => {
			setErrMsg("")
		}, 2000);
	};
	const removeMessagePay = () => {
		setTimeout(() => {
			setErrorMsg("")
		}, 2000);
	};
	const printClick = () => {
		window.sessionStorage.setItem('setMedicineData', JSON.stringify(notInHospitalMedicine));
		window.sessionStorage.setItem('prescriptionidsNotInHospital', JSON.stringify(printIds));
		window.open(
			`${process.env.PUBLIC_URL}/patients/invoiceNotInHospitalPrint`,
			"_blank"
		);
	}
	const payNowSave = (memberProcedureInvoiceId, flag, doctorNameNew, patientNameNew, age) => {
		setIsLoader(true);
		//var advanceAmt = window.sessionStorage.getItem('advanceAmt')
		var hidPayAfterAdvnc = 0.00
		var hidAdvcAmtSave = 0.00
		if (oldAdvanceAmt > 0) {
			if (oldAdvanceAmt > grandTotal) {
				hidAdvcAmtSave = (oldAdvanceAmt - grandTotal) + parseFloat(payNowTotal)
				hidPayAfterAdvnc = grandTotal

			} else {
				if (payNowTotal > grandTotal) {
					hidAdvcAmtSave = payNowTotal + -(+grandTotal + - oldAdvanceAmt)
				} else {
					hidAdvcAmtSave = 0.00
				}
				hidPayAfterAdvnc = oldAdvanceAmt
			}
		} else {
			var newAdvance = payNowTotal - grandTotal
			if (newAdvance > 0) {
				hidAdvcAmtSave = newAdvance
			} else {
				hidAdvcAmtSave = 0.00
			}
		}
		const apiJson = {
			"hospitalId": hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
			"memberId": ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : memberIds !== "" ? memberIds : window.sessionStorage.getItem('memberId')),
			"userId": window.sessionStorage.getItem('userId'),
			"memberProcedureInvoiceId": memberProcedureInvoiceId,
			"txtAmount": payNowTotal,
			"hidAdvcAmtSave": hidAdvcAmtSave,
			"hidPayAfterAdvnc": hidPayAfterAdvnc,
			"hidNetAmt": grandTotal,
			"hidPromoId": "",
			"hidPromoPerc": "",
			"hidPromoAmt": "",
			"promoReason": "",
			"txtDoctorName": doctorNameNew,
			"txtPatientName": patientNameNew,
			"txtPatientAge": age,
			"hidAdvcAmt": oldAdvanceAmt,
			"invoicePaymentMode": document.getElementById('paymentTypeId').value
		};
		callingAPI('payment/savePendingInvoice', apiJson).then((data) => {
			if (data.data.success === '1') {
				alert('Invoice Saved and Paid Successfully');
				if (flag === "1") {
					window.sessionStorage.setItem("htmlData", data.data.result.receiptId)
					setreceiptId(data.data.result.receiptId)
				}


				if (isFlag === "Lab" || isFlag === "Add Lab") {
					window.location.href = "/labLogin/labOrderDetails";
				} else if (isFlag === "Prescription" || isFlag === "Add Prescription") {
					window.location.href = "/pharmacyLogin/prescriptionDetails";
				} else if (isFlag === "Procedure") {
					window.location.href = "/billingLogin/procedurePayment";
				} else {
					window.location.href = "/patients/patientInvoices";
				}

			}
		});
	}

	const invoiceSave = (mode, flag) => {

		var doctorNameNew = ""
		var patientNameNew = ""
		var age = ""
		if (document.getElementById("txtDoctorName") != null) {
			doctorNameNew = document.getElementById("txtDoctorName").value
		}
		if (document.getElementById("txtPatientName") != null) {
			patientNameNew = document.getElementById("txtPatientName").value
		}
		if (document.getElementById("txtAge") != null) {
			age = document.getElementById("txtAge").value
		}

		setIsLoader(true);
		let isValidateOk = true;
		if (prescriptionArray.length > 0) {
			setIsLoader(false);
			for (let index = 0; index < prescriptionArray.length; index++) {
				if (
					prescriptionArray[index].cmbFrequency === '' ||
					prescriptionArray[index].cmbFrequency === '0'
				) {
					// setErrMsg('Please Select Frequency & Dosage');
					alert("Please Select Frequency & Dosage")
					isValidateOk = false;
					removeMessage()
					break;
				} else if (
					prescriptionArray[index].cmbTime === '' ||
					prescriptionArray[index].cmbTime === '0'
				) {
					// setErrMsg('Please Select Intake');
					alert("Please Select Intake")
					isValidateOk = false;
					removeMessage()
					break;
				}

			}
		}
		if (mode === "paynow") {
			if (oldAdvanceAmt == 0 && payNowTotal == 0 && grandTotal > 0) {
				setIsLoader(false);
				setErrorMsg('Amount required')
				//removeMessagePay()
				isValidateOk = false;
			}
			// isValidateOk = false;
		}

		if (isValidateOk === true) {

			setIsLoader(true);
			if (mode === "paynow") {
				document.getElementById("payNowSaveBtn").style.display = 'none'
			}
			var finalArray = [];

			finalArray = [...procedureArray]
			Array.prototype.push.apply(finalArray, disposableArray);
			Array.prototype.push.apply(finalArray, labOrderArray);
			Array.prototype.push.apply(finalArray, prescriptionArray);

			var date = moment(generatedOn).format('yyyy-MM-DD hh:mm A');
			var date2 = moment(generatedOn).format('yyyy-MM-DD HH:mm:ss');
			var milliStart = moment(date).valueOf();
			var milliEnd = moment(date).add(30, 'm').format('yyyy-MM-DD h:mm A');

			const postData = {
				userId: window.sessionStorage.getItem('userId'),
				prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
				hidMemberId: ((isFlag === "Add Lab" || isFlag === "Add Prescription") ? "" : memberIds !== "" ? memberIds : window.sessionStorage.getItem('memberId')),
				hidDoctorId: ((isFlag === "Add Lab" || isFlag === "Add Prescription" || isFlag === "Procedure") ? finalArray[0].hidDoctorId : window.sessionStorage.getItem('doctorId') !== null ? window.sessionStorage.getItem('doctorId') : window.sessionStorage.getItem('patientDoctorId')),
				hospitalId: hospitalId !== "" ? hospitalId : localStorage.getItem('hospitalId'),
				hasDiscount: false,
				balanceAmount: mode === "paynow" ? advanceAfterPayment : oldAdvanceAmt,
				scheduledtimeSlotStartTime: milliStart,
				scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
				txtFinalDiscount: totalDiscount,
				hidAdvcAmtSave: '0',
				hidAdvcAmt: '0',
				hidPayAfterAdvnc: "0",
				hidPromoId: '',
				hidPromoAmt: '',
				hidPromoPerc: '',
				promoReason: '',
				txtAmount: "",
				generatedOn: date2,
				currentDateTime: date,
				hidInvoiceId: memberProcedureInvoiceId,
				hidRemoveIdsPrescription: '',
				paymentType: '1',
				txtDoctorName: doctorNameNew,
				txtPatientName: patientNameNew,
				txtPatientAge: age,
				hidFromPage: (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALPHARMACY' ? "pharmacy" : (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB') ? "lab" : ""),
				hidTreatmentDetails: finalArray,
				isOutside: isFlag === "Add Prescription" ? true : false,
			};
			callingAPI('commonUserFunctions/saveInvoicePayNow', postData).then((data) => {
				if (data.data.success === '1') {

					if (mode === "paynow") {
						payNowSave(data.data.result.memberProcedureInvoiceId, flag, doctorNameNew, patientNameNew, age)
					} else {
						alert('Invoice Saved Successfully');
						window.location.href = "/patients/patientInvoices";
						if (isFlag === "Lab" || isFlag === "Add Lab") {
							window.location.href = "/labLogin/labOrderDetails";
						} else if (isFlag === "Prescription" || isFlag === "Add Prescription") {
							window.location.href = "/pharmacyLogin/prescriptionDetails";
						} else if (isFlag === "Procedure") {
							window.location.href = "/billingLogin/procedurePayment";
						} else {
							window.location.href = "/patients/patientInvoices";
						}
					}

				} else {
					alert(data.data.errorMessage);
					setIsLoader(false);
				}
			});
		}
	};


	const customStyles = {
		content: {
			top: '23%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)', width: '70%'
		},
	};


	let subtitle;
	const [modalIsOpen, setIsOpen] = React.useState(false);

	function openModal() {
		setIsOpen(true);
	}

	// function afterOpenModal() {

	// 	subtitle.style.color = '#f00';
	// }

	function closeModal() {
		setIsOpen(false);
	}
	return (
		<div className="coverWraper">

			<Header />
			{/* <div className={"contentWraper contentWraperScroll ptIN ptInAddPopup add_treatment_plan ptInvoiiceAddWrap InvoiceAddSection exportCalndrSec" + window.sessionStorage.getItem('role') === 'ROLE_HOSPITALPHARMACY' ? " pharmacyModal" :""}   > */}
			<div className="contentWraper contentWraperScroll ptIN ptInAddPopup add_treatment_plan ptInvoiiceAddWrap InvoiceAddSection exportCalndrSec" id="pharmacyModal">

				{isFlag !== "" ?
					"" : <Leftmenu />}

				{/* <div className="rightSection"> */}
				<div className={"rightSection" + (window.sessionStorage.getItem('role') === 'ROLE_BILLINGADMIN' ||
					window.sessionStorage.getItem('role') === 'ROLE_HOSPITALPHARMACY' ||
					window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB' || window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB'
					? ' billingLeftMenu' : '')}>

					{typeof location.state !== "undefined" &&
						(location.state.memberId !== null && typeof location.state.memberId != "undefined") ?
						<Profiledetail memberId={location.state.memberId} />
						:
						<Profiledetail />
					}

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow" >
							<div className="col-lg-8 col-md-12 practiceLeftSection pl_addTreatSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour" id="mainDiv1">
									<div id="divPatientContent" class="sideMenuContent">
										{isLoader === true ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead" style={{ border: 'none', padding: "13px 10px 2px" }}>
												{(isFlag !== "Add Lab" && isFlag !== "Lab" && isFlag !== "Prescription" && isFlag !== "Add Prescription") ?
													<span class="treatmentPlanName">INVOICE</span>
													: <span class="treatmentPlanName">BILLING</span>}


												<label
													id="lblMessage"
													style={{ color: 'red', textAlign: "center", fontSize: "13px" }}
												>	{errMsg}
												</label>
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}
												{
													((procedureArray.length > 0 ||
														prescriptionArray.length > 0 ||
														labOrderArray.length > 0 ||
														disposableArray.length > 0) &&
														(isFlag === "")) ? (
														<span
															class="treatmentPlanBtn pl_blue_btn" id="invoiceSaveBtn"
															style={{ background: '#5bb75b' }}
															onClick={() => invoiceSave("save")}
														>
															Save Invoice
														</span>
													) : (
														''
													)}
												{(procedureChkIds !== undefined && procedureChkIds !== null && procedureChkIds.length > 0 && isFlag !== "Procedure") ?
													<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
														<a href="/patients/completedPlans">Cancel</a>
													</span>
													: (labOrderChkIds !== undefined && labOrderChkIds !== null && labOrderChkIds.length > 0 && isFlag !== "Lab") ?
														<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
															<a href="/patients/labOrder">Cancel</a>
														</span>
														: (prescriptionChkIds !== undefined && prescriptionChkIds !== null && prescriptionChkIds.length > 0 && isFlag !== "Prescription") ?
															<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
																<a href="/patients/patientPriscription">Cancel</a>
															</span>
															: (isFlag === "Procedure") ?
																<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
																	<a href="/billingLogin/procedurePayment">Cancel</a>
																</span>
																: (isFlag === "Lab" || isFlag === "Add Lab") ?
																	<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
																		<a href="/labLogin/labOrderDetails">Cancel</a>
																	</span>
																	: (isFlag === "Prescription" || isFlag === "Add Prescription") ?
																		<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
																			<a href="/pharmacyLogin/prescriptionDetails">Cancel</a>
																		</span>
																		:
																		<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
																			<a href="/patients/patientInvoices">Cancel</a>
																		</span>
												}
												{/* <span id="medicineNotHospital" onClick={() => MedicineNotInHospitalView()} style={{ display: 'none', background: "transparent", borderBottom: "1px solid #00abff" }} class="treatmentPlanBtn   ">
													<a href="#labTestDetails" style={{ color: "#00abff", fontSize: "12px" }}> Medicine not in Hospital</a>&nbsp;
												</span>

												<span id="labTestNotHospital" onClick={() => MedicineNotInHospitalView()} style={{ display: 'none', background: "transparent", borderBottom: "1px solid #00abff" }} class="treatmentPlanBtn   ">
													<a href="#labTestDetails" style={{ color: "#00abff", fontSize: "12px" }}> Lab Test not in Hospital</a>&nbsp;
												</span> */}

												<span id="medicineNotHospital" onClick={() => MedicineNotInHospitalView()} style={{ display: 'none', background: "transparent", borderBottom: "1px solid #00abff" }} class="treatmentPlanBtn   ">
													<a onClick={openModal} style={{ color: "#00abff", fontSize: "12px", cursor: 'pointer' }}> Medicine not in Hospital</a>&nbsp;
												</span>

												<span id="labTestNotHospital" onClick={() => MedicineNotInHospitalView()} style={{ display: 'none', background: "transparent", borderBottom: "1px solid #00abff" }} class="treatmentPlanBtn   ">
													<a onClick={openModal} style={{ color: "#00abff", fontSize: "12px", cursor: 'pointer' }}> Lab Test not in Hospital</a>&nbsp;
												</span>

											</div>
											<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
												<div class="pl_treat treatmentTable planActive">
													<table style={{ border: 'none' }}>
														<thead>
															{/* <tr className="addTreatmentTr"> */}
															<tr>
																<th class="col-md-5">
																	<div class="dentalProName">TOWARDS</div>
																</th>
																<th class="col-md-4 " style={{ display: 'flex' }}>
																	<div class="dentalProCost">QTY </div>
																	<div class="dentalProDis">x&nbsp;&nbsp;&nbsp;&nbsp;COST</div>
																</th>
																<th class="col-md-1" />

																<th class="col-md-4">
																	<div class="dentalProTotal" style={{ paddingRight: "17px" }}>TAX (INR)</div>
																</th>
																<th class="col-md-2  ">
																	<div
																		class="dentalProNote"
																		style={{ textAlign: 'right', paddingRight: "15px" }}
																	>
																		TOTAL(INR)
																	</div>
																</th>
															</tr>
														</thead>
														{procedureArray.map((procedureObj, i) => (
															<tbody className='procedureTbody' style={{ width: '100%', display: 'block' }}>
																{/* <span
																	className="close_row"
																	onClick={(e) => removeProcedure(i, procedureObj,"plannedProcedure"+procedureObj.hidProcedureId )}
																>
																	x
																</span> */}
																<tr style={{ border: 'none' }}>
																	<div class="treatmentTags procedureTreatmentTags">Procedure</div>
																	<td colSpan={7} className="position-relative"><span
																		className="close_row close_span"
																		onClick={(e) => removeProcedure(i, procedureObj, "plannedProcedure" + procedureObj.hidMemberProcedureDentalDetailsId)}
																	>
																		<span title='Remove'>x</span>
																	</span></td>
																</tr>
																<tr class="groupBrdr">
																	<td class="col-md-3">
																		<div class="dentalProName invoiceTowardsName" style={{ margin: "0px", paddingTop: "0px" }}>
																			<span title={procedureObj.procedureName} className='invoiceTretmentName'>
																				<b>{procedureObj.procedureName}</b>
																			</span>
																			<span
																				class="elipsis"
																				title=""
																				style={{ fontSize: '13px' }}
																			>
																				{procedureObj.procedureCode}
																			</span>
																		</div>
																	</td>
																	<td class="col-md-3">
																		<div class="treatmentPrice">
																			<div class="treatmentPriceTxt">
																				<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																					<input
																						type="number"
																						min="1"
																						step="1"
																						name="spinner"
																						class="quantity"
																						id={'spinner' + i}
																						pattern="[0-9]" onkeypress={(event) => { return !(event.charCode == 46) }}
																						onChange={(e) =>
																							onChangeQuantity(e, i, "Procedure", procedureArray)}
																						onKeyUp={(e) =>
																							costAndQuantityCalculation(e)}

																						value={procedureObj.spinner}
																					/>
																				</span>{' '}
																			</div>
																			<div class="treatmentPriceCross">x</div>
																			<div class="treatmentCostTxt">
																				<input
																					type="text"
																					autocomplete="off"
																					name="txtTreatmentCost"
																					maxlength="10"
																					id={'txtTreatmentCost' + i}
																					onChange={(e) =>
																						onChangeQuantity(e, i, "Procedure", procedureArray)}
																					value={
																						procedureObj.txtTreatmentCost}
																				/>
																			</div>
																		</div>

																		<div
																			className="treatmentTeethShow"
																			style={{
																				display:
																					procedureObj.showTeeth === 0
																						? 'none'
																						: 'block',
																			}}
																		>
																			<span
																				id={'showTeeth' + i}
																				onClick={() => showTeethListing(i)}
																			>
																				Show Teeth
																			</span>
																			<span
																				onClick={() => showTeethListing(i)}
																				id={'txtTeeth' + i}
																				style={{ display: 'none' }}
																			>
																				Teeth : {procedureObj.hidTeethNumber}
																			</span>
																		</div>
																	</td>


																	<td class="col-md-2 pull-right">
																		{' '}
																		<a
																			href="/patients/consentFormInvoice"
																			style={{
																				textAlign: 'right',
																				width: '100%',
																				float: 'left',
																				color: '#00abff',
																			}}
																		/>
																	</td>
																	<td class="col-md-2">
																		<div
																			class="dentalProDis"
																			style={{
																				textAlign: 'right',
																				color: '#008000', paddingTop: "0"
																			}}
																		>
																			{parseFloat(
																				procedureObj.hidTaxIndividual
																			).toFixed(2)}
																		</div>
																		{procedureObj.taxDetails.length >
																			0 && (
																				<div className='inventryOuter addCompltedPlaninventryOuter' style={{ textAlign: "left" }}>
																					<span
																						onClick={() => showTaxDetails(i, "taxListDiv")}
																					>
																						<a style={{ color: "#00abff", cursor: "pointer", fontSize: "12px" }}>Tax Details</a>
																					</span>
																					<ul
																						class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																						id={"taxListDiv" + i}
																						style={{ display: 'none' }}
																					>
																						{procedureObj.taxDetails.map(
																							(taxObj, inv) => (
																								<li>
																									<span className="inventoryInfoWrap">
																										<span>{taxObj.taxName}{' '}</span>
																										<span>{parseFloat(taxObj.taxAmountData).toFixed(2)}</span>
																									</span>
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			)}

																	</td>
																	<td class="col-md-2">
																		<span
																			className="totalinr totalinrAmountView"
																			style={{ color: '#008000', paddingRight: "15px", paddingTop: "0px" }}
																			id={'hidGrandIndividual' + i}
																			name="hidGrandIndividual"
																		>
																			{parseFloat(
																				procedureObj.hidGrandIndividual
																				//(procedureObj.txtTreatmentCost * procedureObj.spinner)
																			).toFixed(2)}

																		</span>
																	</td>
																</tr>
																<tr class="dentalProPlaned">
																	<input
																		type="hidden"
																		name="hidTreatmentIds00"
																		id="hidTreatmentIds00"
																		value=""
																	/>
																	<td colspan="3">
																		<div class="plannedBy " style={{ paddingTop: "0px", paddingBottom: "5px" }}>
																			<span
																				id={'addNoteLabelPro' + i}
																				onClick={(e) => addNotes(i, "Pro")}
																			>
																				+ add notes
																			</span>
																			<input
																				style={{ display: 'none' }}
																				id={'txtNotesPro' + i}
																				type="text"
																				name="txtNotes"
																				value={procedureObj.txtNotes}
																				placeholder="Add your notes"
																				onChange={(e) => onChangeAddNotes(e, i)}
																			/>
																		</div>
																		<div className="row total_info" style={{ paddingTop: "0px" }}>
																			<div className="col-md-8">
																				{/* ========== Dropdown begins here  =============== */}

																				<span
																					class="treatmentGrandBy"
																					style={{ position: 'absolute' }}
																					onClick={() => droplist("doctorListDivProcedure", i)}
																				>
																					<span>By&nbsp;</span>
																					<span
																						class="treatmentByName elipsis" style={{
																							// width: "80%",
																							display: "inline-block",
																							verticalAlign: "bottom"
																						}}
																						onClick={() => doctorDropdown(i, "Procedure")}
																					>
																						{procedureObj.doctorName}
																					</span>

																					<div
																						class="treatmentDrNameList pl_drList"
																						id={'doctorListDivProcedure' + i}
																						style={{ display: 'none' }}
																					>
																						<ul  >
																							{doctorList.map(
																								(doctorObj, j) => (
																									<li
																										onClick={(e) =>
																											setDoctorName(
																												doctorObj,
																												i, "Procedure"
																											)}
																									>
																										<span>
																											{doctorObj.doctorName}
																										</span>
																									</li>
																								)
																							)}
																						</ul>

																					</div>
																				</span>

																				<br />

																				<span style={{ float: 'left' }}>
																					On&nbsp;<span style={{ color: '#1b99d5' }} />
																					<Datepicker
																						required
																						id={'dateAndTimeProcedure' + i}
																						className="Datepicker pa2"
																						//	minDate={new Date()}
																						dateFormat="dd/MM/yyyy h:mm aa"
																						placeholderText=""
																						calendarClassName="rasta-stripes"
																						selected={procedureObj.dateOn}
																						showTimeSelect
																						onChange={(date) =>
																							setDate(date, i, "Procedure")}
																						showMonthDropdown    // Add month dropdown
																						showYearDropdown     // Add year dropdown
																						dropdownMode="select"
																					/>
																				</span>
																			</div>
																		</div>
																	</td>
																</tr>
																<InvoiceTeethSession
																	setTeethInfo={setTeethInfo}
																	index={i}
																	teethObject={procedureObj}
																/>
															</tbody>
														))}
														{procedureArray.length === 0 &&
															prescriptionArray.length === 0 &&
															labOrderArray.length === 0 &&
															disposableArray.length === 0 ? (
															<span className="ptInvoiceAddMsg">

																<span class="treatmentCommonTxt1">
																	<span> Add items from right by clicking on it</span>

																</span>
															</span>
														) : (
															''
														)}

														{/*  */}
														{prescriptionArray.map((presObj, k) => (
															<tbody className='MedicineTbody' style={{ width: '100%', display: 'block' }}>

																<tr style={{ border: 'none' }}>
																	<div class="treatmentTags">Medicine</div>
																	<td colSpan={7} className="position-relative"><span
																		class="close_row close_span"
																		onClick={(e) => removePrescription(k, presObj, "plannedPrescription" + presObj.hidDoctorVisitMedicineId)}
																	>
																		<span title='Remove'>x</span>
																	</span></td>
																</tr>
																<tr class="groupBrdr">
																	<td class="col-md-3">
																		<div class="dentalProName invoiceTowardsName">
																			<span title={presObj.medicineName} className='invoiceTretmentName'>
																				<b>{presObj.medicineName}</b>
																			</span>

																		</div>
																	</td>
																	<td class="col-md-3">
																		<div class="treatmentPrice">
																			<div class="treatmentPriceTxt">
																				<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																					<input
																						type="number"
																						min="1"
																						name="spinner"
																						class="quantity"
																						step="1"
																						oninput="event.target.value = event.target.value.replace(/[^1-9]*/g,'');"
																						id={'quantity' + k}
																						onChange={(e) =>
																							onChangeQuantity(
																								e,
																								k, "Prescription", prescriptionArray
																							)}
																						onKeyUp={(e) =>
																							costAndQuantityCalculation(e)}
																						value={presObj.spinner}
																					/>
																				</span>{' '}
																			</div>
																			<div class="treatmentPriceCross">x</div>
																			<div class="treatmentCostTxt">
																				<input
																					type="text"
																					autocomplete="off"
																					name="txtTreatmentCost"
																					maxlength="10"
																					id={'treatmentCost' + k}
																					onChange={(e) =>
																						onChangeQuantity(
																							e,
																							k, "Prescription", prescriptionArray
																						)}
																					value={presObj.txtTreatmentCost}
																				/>
																			</div>
																		</div>
																	</td>
																	<td class="col-md-2 pull-right">
																		{' '}
																		<a
																			href="/patients/consentFormInvoice"
																			style={{
																				textAlign: 'right',
																				width: '100%',
																				float: 'left',
																				color: 'rgb(0, 171, 255)',
																			}}
																		/>
																	</td>
																	<td class="col-md-2">
																		<div
																			class="dentalProDis"
																			style={{ textAlign: 'right' }}
																		>
																			{parseFloat(presObj.hidTaxIndividual).toFixed(2)}
																		</div>
																		{presObj.taxDetails.length >
																			0 && (
																				<div className='inventryOuter addCompltedPlaninventryOuter' style={{ textAlign: "left" }}>
																					<span
																						onClick={() => showTaxDetails(k, "taxListDivPres")}
																					>
																						<a style={{ color: "#00abff", cursor: "pointer", fontSize: "12px" }}>Tax Details</a>
																					</span>
																					<ul
																						class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																						id={"taxListDivPres" + k}
																						style={{ display: 'none' }}
																					>
																						{presObj.taxDetails.map(
																							(taxObj, inv) => (
																								<li>
																									<span className="inventoryInfoWrap">
																										<span>{taxObj.taxName}{' '}</span>
																										<span>{parseFloat(taxObj.taxAmountData).toFixed(2)}</span>
																									</span>
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			)}

																	</td>
																	<td class="col-md-2">
																		<span
																			class="totalinr totalinrAmountView"
																			id={'grandTotalPres' + k}
																			name="grandTotalPres"
																		>
																			{parseFloat(presObj.hidGrandIndividual).toFixed(2)}
																		</span>
																	</td>
																</tr>
																<span className="row dentalProPlaned1 secondRow" style={{ border: 'none' }}>
																	<span className="col-md-4 col-lg-4">
																		<div className="plannedBy " style={{ paddingTop: "0px", paddingBottom: "0px" }}>
																			<span
																				id={'addNoteLabelPres' + k}
																				onClick={(e) => addNotes(k, "Pres")}
																			>
																				+ add notes
																			</span>
																			<input
																				style={{ display: 'none' }}
																				id={'txtNotesPres' + k}
																				type="text"
																				placeholder="Add your notes"
																				name="txtNotes"
																				value={presObj.txtNotes}
																				onChange={(e) =>
																					onChangeAddNotesPrescription(e, k)}
																			/>
																		</div>
																		<div class="row total_info" style={{ paddingTop: "0px" }}>
																			<div class="col-md-12">
																				<span
																					class="treatmentGrandBy"
																					style={{ position: 'absolute' }}
																					onClick={() => droplist("doctorListDivPrescription", k)}
																				>
																					<span>By&nbsp;</span>
																					<span class="treatmentByName elipsis" style={{
																						// width: "80%",
																						display: "inline-block",
																						verticalAlign: "bottom"
																					}} onClick={() => doctorDropdown(k, "Prescription")}>
																						{presObj.doctorName}

																					</span>
																					<div
																						class="treatmentDrNameList pl_drList"
																						id={"doctorListDivPrescription" + k}
																						style={{ display: 'none' }}
																					>
																						<ul >
																							{doctorList.map(
																								(doctorObj, j) => (
																									<li
																										onClick={(e) =>
																											setDoctorName(
																												doctorObj,
																												k, "Prescription"
																											)}
																									>
																										<span>
																											{doctorObj.doctorName}
																										</span>
																									</li>
																								)
																							)}
																						</ul>
																					</div>
																				</span>
																				<br />
																				<span style={{ float: 'left' }}>
																					On&nbsp;{' '}
																					<span style={{ color: 'rgb(27, 153, 213)' }} />
																					<div class="react-datepicker-wrapper">
																						<div class="react-datepicker__input-container">
																							<Datepicker
																								className="Datepicker pa2"
																								//minDate={new Date()}
																								dateFormat="dd/MM/yyyy h:mm aa"
																								placeholderText=""
																								id={"dateAndTimePrescription" + k}
																								calendarClassName="rasta-stripes"
																								selected={
																									presObj.dateOn
																								}
																								showTimeSelect
																								onChange={(date) =>
																									setDate(date, k, "Prescription")}
																								showMonthDropdown    // Add month dropdown
																								showYearDropdown     // Add year dropdown
																								dropdownMode="select"
																							/>
																						</div>
																					</div>
																				</span>
																			</div>
																		</div>
																	</span>
																	<span class="col-md-3 col-lg-3" style={{ paddingLeft: "5px" }}>
																		<label>Frequency &amp; Dosage</label>
																		<select
																			class="fulWidthSelect frequencyUsage"
																			tabindex="2"
																			name="cmbFrequency"
																			id={'frequencyId' + k}
																			value={presObj.cmbFrequency}
																			onChange={(e) => prescriptionOnChange(e, k)}
																		// onKeyDown={(e)=>settingFrequency(e,k)}
																		>
																			<option value="0">Select</option>
																			{frequencyDetails.map((obj, i) => (
																				<option value={obj.name}>{obj.name}</option>
																			))}
																		</select>
																	</span>

																	<span class="col-md-3 col-lg-3 liFrequency" >
																		<label>&nbsp; </label>
																		{presObj.timesArray.length > 0 ?
																			presObj.timesArray.map((obj, j) => (
																				<input
																					type="text"
																					placeholder="0"
																					id={"times" + k + j}
																					name="val"
																					value={obj.val}
																					maxlength="6"
																					autoComplete='off'
																					onChange={(e) => timesOnChange(e, k, j)} />
																			))
																			: ""}
																	</span>
																	{/* <td
																		className="dosageInput"
																		// style={{ display: 'none' }}
																	>
																		<div id = {"liFrequencyNo"+k}><label>&nbsp;</label> </div>
																		<div id = {"liFrequencyMore"+k} style={{ display: 'none' }}>
																		<input type="text" placeholder="0" id={"txtMorning"+k} name="txtMorning" value={presObj.txtMorning}  maxlength="6" onChange={(e) => prescriptionOnChange(e, k)}/>
																		<input type="text" placeholder="0" id={"txtNoon"+k} name="txtNoon" value={presObj.txtNoon}   maxlength="6" onChange={(e) => prescriptionOnChange(e, k)}/>
																		<input type="text" placeholder="0" id={"txtNight"+k} name="txtNight" value={presObj.txtNight}   maxlength="6" onChange={(e) => prescriptionOnChange(e, k)}/>
																		</div>
																		<div id={"liFrequencyOnce"+k} style={{ display: 'none' }}>
																		<input type="text" placeholder="0" id={"txtFrequencyMore"+k} name="txtFrequencyMore" value={presObj.txtFrequencyMore} tabindex="3" maxlength="6" onChange={(e) => prescriptionOnChange(e, k)}/>
																		</div>
																	</td> */}
																	<span className='col-md-2 col-lg-2' style={{ marginLeft: "-15px" }}>
																		<label>Intake</label>
																		<select
																			class="fulWidthSelect frequencyUsage"
																			tabindex="2"
																			name="cmbTime"
																			id={'cmbTime' + k}
																			value={presObj.cmbTime}
																			onChange={(e) => prescriptionOnChange(e, k)}
																		>
																			<option value="0">Select</option>
																			{intakeDetails.map((inObj, l) => (
																				<option value={inObj.name}>{inObj.name}</option>
																			))}
																		</select>
																	</span>
																</span>
																<span class="row dentalProPlaned thirdRow">
																	<span className='col-md-4 col-lg-11'>
																		<div class="row total_info">
																			<div class="col-md-12">&nbsp;</div>
																		</div>
																	</span>
																	<span class="col-md-3 col-lg-6" style={{ paddingLeft: "5px" }}>
																		<label>Days</label>
																		<input
																			autoComplete='off'
																			type="text"
																			id={'txtDays' + k}
																			name="txtDays"
																			value={presObj.txtDays}
																			className="inputField"
																			placeholder="Days"
																			onChange={(e) => prescriptionOnChange(e, k)}
																		/>
																	</span>
																	<span className='ptInvoiceAddDtpkr col-md-3 col-lg-7' colSpan={2}>
																		<label>Start Date</label>
																		<Datepicker
																			className="plstartDate  "
																			// minDate={new Date()}
																			dateFormat="dd/MM/yyyy"
																			placeholderText=""
																			calendarClassName="rasta-stripes"
																			selected={presObj.dtStartdate}
																			onChange={(date) => setStartDate(date, k)}
																		/>
																	</span>
																	<span style={{ paddingLeft: '10px' }} class="col-md-1 col-lg-4">
																		<label>Refill</label>
																		<input
																			type="checkbox"
																			id={'checkboxRefill' + k}
																			name="checkboxRefill"
																			checked={presObj.checkboxRefill}
																			onChange={(e) =>
																				prescriptionCheckOnChange(e, k)}
																		/>
																	</span>
																</span>
															</tbody>
														))}
														{labOrderArray.map((labObj, l) => (
															<tbody className='LabTestTbody' style={{ width: '100%', display: 'block' }}>
																{/* <span
																	className="close_row"
																	onClick={(e) => removeLabOrder(l, labObj,"plannedLabOrder"+labObj.hidServiceProviderTestDetailsId)}
																>
																	x
																</span> */}
																<tr style={{ border: 'none' }}>
																	<div class="treatmentTags laborderTreatmentTags">Lab Test</div>
																	<td colSpan={7} className="position-relative"><span
																		className="close_row close_span"
																		onClick={(e) => removeLabOrder(l, labObj, "plannedLabOrder" + labObj.hidDoctorVisitLabOrderTest)}
																	>
																		<span title='Remove'>x</span>
																	</span></td>
																</tr>
																<tr class="groupBrdr">
																	<td class="col-md-3">
																		<div class="dentalProName invoiceTowardsName">
																			<span title={labObj.testName} className='invoiceTretmentName'>
																				<b>{labObj.testName}</b>
																			</span>
																			{/* <span
																				class="elipsis"
																				title=""
																				style={{ fontSize: '10px' }}
																			>
																				{labObj.procedureCode}
																			</span> */}
																		</div>
																	</td>
																	<td class="col-md-3">
																		<div class="treatmentPrice">
																			<div class="treatmentPriceTxt">
																				<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																					<input
																						type="number"
																						min="1"
																						name="spinner"
																						class="quantity"
																						id={'spinner' + l}
																						onChange={(e) =>
																							onChangeQuantity(e, l, "LabOrder", labOrderArray)}
																						onKeyUp={(e) =>
																							costAndQuantityCalculation(e)}
																						value={labObj.spinner}
																					/>
																				</span>{' '}
																			</div>
																			<div class="treatmentPriceCross">x</div>
																			<div class="treatmentCostTxt">
																				<input
																					type="text"
																					autoComplete='off'
																					name="txtTreatmentCost"
																					maxlength="10"
																					id={'txtTreatmentCost' + l}
																					onChange={(e) =>
																						onChangeQuantity(e, l, "LabOrder", labOrderArray)}
																					value={labObj.txtTreatmentCost}
																				/>
																			</div>
																		</div>
																	</td>
																	<td class="col-md-2 pull-right">
																		{' '}
																		<a
																			href="/patients/consentFormInvoice"
																			style={{
																				textAlign: 'right',
																				width: '100%',
																				float: 'left',
																				color: '#00abff',
																			}}
																		/>
																	</td>
																	<td class="col-md-2">
																		<div
																			class="dentalProDis"
																			style={{
																				textAlign: 'right',
																				color: '#008000',
																			}}
																		>
																			{parseFloat(
																				labObj.hidTaxIndividual
																			).toFixed(2)}
																		</div>
																		{labObj.taxDetails.length >
																			0 && (
																				<div className='inventryOuter addCompltedPlaninventryOuter' style={{ textAlign: "left" }}>
																					<span
																						onClick={() => showTaxDetails(l, "taxListDivLab")}
																					>
																						<a style={{ color: "#00abff", cursor: "pointer", fontSize: "12px" }}>Tax Details</a>
																					</span>
																					<ul
																						class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																						id={"taxListDivLab" + l}
																						style={{ display: 'none' }}
																					>
																						{labObj.taxDetails.map(
																							(taxObj, inv) => (
																								<li>
																									<span className="inventoryInfoWrap">
																										<span>{taxObj.taxName}{' '}</span>
																										<span>{parseFloat(taxObj.taxAmountData).toFixed(2)}</span>
																									</span>
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			)}
																	</td>
																	<td class="col-md-2">
																		<span
																			className="totalinr totalinrAmountView"
																			style={{ color: '#008000' }}
																			id={'grandTotalLab' + l}
																			name={'grandTotalLab' + l}
																		>
																			{parseFloat(
																				labObj.hidGrandIndividual
																			).toFixed(2)}
																		</span>
																	</td>
																</tr>
																<span class=" row dentalProPlaned labTestBottom">
																	<input
																		type="hidden"
																		name="hidTreatmentIds00"
																		id="hidTreatmentIds00"
																		value=""
																	/>
																	<span class="col-md-4">
																		<div class="plannedBy " style={{ paddingTop: "0px", paddingBottom: "0px" }}>
																			<span
																				id={'addNoteLabelLab' + l}
																				onClick={(e) => addNotes(l, "Lab")}
																			>
																				+ add notes
																			</span>
																			<input
																				style={{ display: 'none' }}
																				id={'txtNotesLab' + l}
																				type="text"
																				name="txtNotes"
																				value={labObj.txtNotes}
																				placeholder="Add your notes"
																				onChange={(e) =>
																					onChangeAddNotesLab(e, l)}
																			/>
																		</div>
																		<div className="row total_info" style={{ paddingTop: "0px" }}>
																			<div className="col-md-12">
																				{/* ========== Dropdown begins here  =============== */}
																				{(isFlag !== "Add Lab" && isFlag !== "Add Prescription") &&
																					<span
																						class="treatmentGrandBy"
																						style={{ position: 'absolute' }}
																						onClick={() => droplist("doctorListDivLabOrder", l)}
																					>
																						<span>By&nbsp;</span>
																						<span class="treatmentByName elipsis" style={{
																							// width: "80%",
																							display: "inline-block",
																							verticalAlign: "bottom"
																						}} onClick={() => doctorDropdown(l, "LabOrder")}>
																							{labObj.doctorName}
																						</span>
																						<div
																							class="treatmentDrNameList pl_drList"
																							id={'doctorListDivLabOrder' + l}
																							style={{ display: 'none' }}
																						>
																							<ul >
																								{doctorList.map(
																									(doctorObj, j) => (
																										<li
																											onClick={(e) =>
																												setDoctorName(
																													doctorObj,
																													l, "LabOrder"
																												)}
																										>
																											<span>
																												{doctorObj.doctorName}
																											</span>
																										</li>
																									)
																								)}
																							</ul>
																						</div>
																					</span>
																				}
																				<br />

																				<span style={{ float: 'left' }}>
																					On&nbsp;<span style={{ color: '#1b99d5' }} />
																					<Datepicker
																						required
																						id={'dateAndTimeLab' + l}
																						className="Datepicker pa2  "
																						//minDate={new Date()}
																						dateFormat="dd/MM/yyyy h:mm aa"
																						placeholderText=""
																						calendarClassName="rasta-stripes"
																						selected={labObj.dateOn}
																						showTimeSelect
																						onChange={(date) =>
																							setDate(date, l, "Lab")}
																						showMonthDropdown    // Add month dropdown
																						showYearDropdown     // Add year dropdown
																						dropdownMode="select"
																					/>
																				</span>
																			</div>
																		</div>
																	</span>
																	<span class="col-md-4" style={{ paddingLeft: "5px" }}>
																		<label>Specimen</label>
																		<select
																			class="fulWidthSelect frequencyUsage"
																			tabindex="2"
																			name="cmbSpecimen"
																			id={'cmbSpecimen' + l}
																			onChange={(e) => onChangeLab(e, l)}
																			value={labObj.cmbSpecimen}
																		>
																			<option value="">Select</option>
																			{specimenType.map((data, p) => (
																				<option value={data.specimenTypeId}>
																					{data.specimenName}
																				</option>
																			))}
																		</select>
																	</span>
																	<span class="col-md-4" style={{ marginLeft: "-15px" }}>
																		<label>Type Of Test</label>
																		<select
																			class="fulWidthSelect frequencyUsage"
																			tabindex="2"
																			name="cmbtypeofTest"
																			id={'cmbtypeofTest' + l}
																			value={labObj.cmbtypeofTest}
																			onChange={(e) => onChangeLab(e, l)}
																		>
																			<option value="">Select</option>
																			{typeOfTest.map((data, p) => (
																				<option value={data.typeId}>
																					{data.typeName}
																				</option>
																			))}
																			{/* <option value="Before Food">Before Food</option>
																			<option value="After Food">After Food</option>
																			<option value="Anytime">Anytime</option> */}
																		</select>
																	</span>
																</span>
															</tbody>
														))}
														{/*  */}
														{disposableArray.map((disposibleObj, l) => (
															<tbody className='disposableTbody' style={{ width: '100%', display: 'block' }}>
																{/* <span
																	className="close_row"
																	onClick={(e) => removeDisposables(l, disposibleObj)}
																>
																	x
																</span> */}
																<tr style={{ border: 'none' }}>
																	<div class="treatmentTags disposableTreatmentTags">Disposable</div>
																	<td colSpan={7} className="position-relative"><span
																		className="close_row"
																		onClick={(e) => removeDisposables(l, disposibleObj)}
																	>
																		<span title='Remove'>x</span>
																	</span></td>
																</tr>
																<tr class="groupBrdr">
																	<td class="col-md-3">
																		<div class="dentalProName invoiceTowardsName">
																			<span title={disposibleObj.disposableName} className='invoiceTretmentName'>
																				<b>{disposibleObj.disposableName}</b>
																			</span>
																		</div>
																	</td>
																	<td class="col-md-3">
																		<div class="treatmentPrice">
																			<div class="treatmentPriceTxt">
																				<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																					<input
																						type="number"
																						min="1"
																						name="spinner"
																						class="quantity"
																						id={'quantity' + l}
																						onChange={(e) =>
																							onChangeQuantity(e, l, "disposible", disposableArray)}
																						onKeyUp={(e) =>
																							costAndQuantityCalculation(e)}
																						value={disposibleObj.spinner}
																					/>
																				</span>{' '}
																			</div>
																			<div class="treatmentPriceCross">x</div>
																			<div class="treatmentCostTxt">
																				<input
																					type="text"
																					autocomplete="off"
																					name="txtTreatmentCost"
																					maxlength="10"
																					id={'price' + l}
																					onChange={(e) =>
																						onChangeQuantity(e, l, "disposible", disposableArray)}
																					value={
																						disposibleObj.txtTreatmentCost
																					}
																				/>
																			</div>
																		</div>
																	</td>
																	<td class="col-md-2 pull-right">
																		{' '}
																		<a
																			href="/patients/consentFormInvoice"
																			style={{
																				textAlign: 'right',
																				width: '100%',
																				float: 'left',
																				color: '#00abff',
																			}}
																		/>
																	</td>
																	<td class="col-md-2">
																		<div
																			class="dentalProDis"
																			style={{
																				textAlign: 'right',
																				color: '#008000',
																			}}
																		>
																			{parseFloat(disposibleObj.hidTaxIndividual).toFixed(2)}
																		</div>
																		{disposibleObj.taxDetails.length >
																			0 && (
																				<div className='inventryOuter addCompltedPlaninventryOuter' style={{ textAlign: "left" }}>
																					<span
																						onClick={() => showTaxDetails(l, "taxListDivLab")}
																					>
																						<a style={{ color: "#00abff", cursor: "pointer", fontSize: "12px" }}>Tax Details</a>
																					</span>
																					<ul
																						class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																						id={"taxListDivLab" + l}
																						style={{ display: 'none' }}
																					>
																						{disposibleObj.taxDetails.map(
																							(taxObj, inv) => (
																								<li>
																									<span className="inventoryInfoWrap">
																										<span>{taxObj.taxName}{' '}</span>
																										<span>{parseFloat(taxObj.taxAmountData).toFixed(2)}</span>
																									</span>
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			)}
																	</td>
																	<td class="col-md-2">
																		<span
																			className="totalinr totalinrAmountView"
																			style={{ color: '#008000' }}
																			id={'grandTotalDisposable' + l}
																			name={'grandTotal'}
																		>
																			{parseFloat(disposibleObj.hidGrandIndividual
																			).toFixed(2)}
																		</span>
																	</td>
																</tr>
																<tr class="dentalProPlaned">
																	<input
																		type="hidden"
																		name="hidTreatmentIds00"
																		id="hidTreatmentIds00"
																		value=""
																	/>
																	<td colspan="3">
																		<div class="plannedBy " style={{ paddingTop: "0px", paddingBottom: "5px" }}>
																			<span
																				id={'addNoteLabelDispo' + l}
																				onClick={(e) => addNotes(l, "Dispo")}
																			>
																				+ add notes
																			</span>
																			<input
																				style={{ display: 'none' }}
																				id={'txtNotesDispo' + l}
																				type="text"
																				placeholder="Add your notes"
																				name="txtNotes"
																				value={disposibleObj.txtNotes}
																				onChange={(e) =>
																					onChangeAddNotesDispos(e, l)}
																			/>
																		</div>
																		<div className="row total_info" style={{ paddingTop: "0px" }}>
																			<div className="col-md-8">
																				{/* ========== Dropdown begins here  =============== */}
																				{(isFlag !== "Add Lab" && isFlag !== "Add Prescription") &&
																					<span
																						class="treatmentGrandBy"
																						style={{ position: 'absolute' }}
																						onClick={() => droplist("doctorListDivDisposable", l)}
																					>
																						<span>By&nbsp;</span>
																						<span class="treatmentByName elipsis" style={{
																							// width: "80%",
																							display: "inline-block",
																							verticalAlign: "bottom"
																						}} onClick={() => doctorDropdown(l, "Disposable")}>
																							{disposibleObj.doctorName}
																						</span>
																						<div
																							class="treatmentDrNameList pl_drList"
																							id={'doctorListDivDisposable' + l}
																							style={{ display: 'none' }}
																						>
																							<ul >
																								{doctorList.map(
																									(doctorObj, j) => (
																										<li
																											onClick={(e) =>
																												setDoctorName(
																													doctorObj,
																													l, "Disposable"
																												)}
																										>
																											<span>
																												{doctorObj.doctorName}
																											</span>
																										</li>
																									)
																								)}
																							</ul>
																						</div>
																					</span>
																				}
																				<br />
																				<span style={{ float: 'left' }}>
																					On&nbsp;<span style={{ color: '#1b99d5' }} />
																					<Datepicker
																						required
																						id={'dateAndTimeDispos' + l}
																						className="Datepicker pa2  "
																						//minDate={new Date()}
																						dateFormat="dd/MM/yyyy h:mm aa"
																						placeholderText=""
																						calendarClassName="rasta-stripes"
																						selected={disposibleObj.dateOn}
																						showTimeSelect
																						onChange={(date) =>
																							setDate(date, l, "Dispos")}
																						showMonthDropdown    // Add month dropdown
																						showYearDropdown     // Add year dropdown
																						dropdownMode="select"
																					/>
																				</span>
																			</div>
																		</div>
																	</td>
																</tr>
															</tbody>
														))}
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								{procedureArray.length > 0 ||
									prescriptionArray.length > 0 ||
									labOrderArray.length > 0 ||
									disposableArray.length > 0 ? (
									<div className="row total_info generatedInfo" id="mainDiv2">
										<div className="col-md-4">
											{/* ========== Dropdown begins here  =============== */}
											<span style={{ color: '#585858', fontSize: "13px" }}>
												Generated On
												<Datepicker
													required
													id="completedDateTime2"
													className="Datepicker pa2"
													// minDate={new Date()}
													dateFormat="dd/MM/yyyy h:mm aa"
													placeholderText=""
													calendarClassName="rasta-stripes"
													selected={generatedOn}
													showTimeSelect
													onChange={(date) => setGeneratedOn(date)} style={{ fontSize: "13px" }}
													showMonthDropdown    // Add month dropdown
													showYearDropdown     // Add year dropdown
													dropdownMode="select"
												/>
											</span>
											<br />
											{parseFloat(grandTotal) > 0 &&
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', width: '220px', textAlign: 'center', float: "left", margin: "0px", fontSize: "13px", textShadow: "none" }}
													onClick={() => payNowEnable()}
												>
													Pay Now
												</span>}
										</div>
										<div className="col-md-8 plbottomBlock">
											<div className="row">
												<div className="col-md-2" style={{ padding: "0px" }}>
													<p>Total Cost INR</p>
													<p>{parseFloat(totalCost).toFixed(2)}</p>
												</div>
												<div className="col-md-1 ptInvoiiceAddPlus" style={{ padding: "0px" }}>

													<p>+</p>
												</div>
												<div className="col-md-2" style={{ padding: "0px" }}>
													<p>Total Tax INR</p>
													<p>{parseFloat(totalTax).toFixed(2)} </p>
												</div>
												<div className="col-md-1 ptInvoiiceAddPlus" style={{ padding: "0px" }}>
													<p>-</p>
												</div>
												<div className="col-md-3" style={{ padding: "0px" }}>
													<p>Total Discount INR</p>
													<div class="treatmentCostTxt totalDiscountINRInput">
														<input
															type="text"
															id="totalDiscount"
															value={totalDiscount}
															//onChange={(e) => setTotalDiscount(e.target.value)}
															onChange={(e) => settingDiscount(e)}
															placeholder="Discount"
														/>{' '}
													</div>
												</div>
												<div className="col-md-1 ptInvoiiceAddPlus" style={{ padding: "0px" }}>
													{/* <p> &nbsp; </p> */}
													<p> &nbsp; =</p>
												</div>
												<div className="col-md-2" style={{ padding: "0px" }}>
													<p> Grand Total INR</p>
													<p className='totalGreen'>{parseFloat(grandTotal).toFixed(2)}</p>
												</div>
											</div>
										</div>
									</div>
								) : (
									''
								)}
								{/* TOTAL PAYABLE INFO BEGINS HERE */}
								<div class="paymentSec payableInfo" id="paymentDiv" style={{ display: 'none' }}>
									<div class="paymentSecTop invoicePaymentSec_PTour">
										<span class="ContentLabel">TOTAL PAYABLE : INR<span style={{ color: "#f00" }}  >{parseFloat(grandTotal).toFixed(2)}</span></span>
										&nbsp; &nbsp;{oldAdvanceAmt !== "0.00" && <span class="ContentLabel">AVAILABLE ADVANCE: INR <span   >{parseFloat(oldAdvanceAmt).toFixed(2)}</span></span>}
									</div>
									<div class="paymentSecContent invoicePaymntSecContent_PTour">
										{oldAdvanceAmt !== "0.00" &&
											<div class="paymentSecLst">
												<label>ADVANCE AMOUNT APPLIED  : INR {grandTotal > oldAdvanceAmt ? parseFloat(oldAdvanceAmt).toFixed(2) : parseFloat(grandTotal).toFixed(2)}</label>
											</div>
										}
										<div class="paymentSecLst">
											<label>PAY NOW  : </label>
											<span>
												<input type="text" autocomplete="off" value={(payNowTotal)} maxlength="10" style={{ height: "25px" }} onChange={(e) => settingDueAfterPay(e.target.value)} />
												<select style={{ height: "25px" }} id="paymentTypeId" >
													<option value="1">Cash</option>
													<option value="2">Cheque</option>
													<option value="3">Card</option>
													<option value="4">UPI</option>
												</select>
												<label
													id="lblMessage"
													style={{ color: 'red', textAlign: "left", fontSize: "13px" }}
												>	{errorMsg}
												</label>
											</span><br></br>
											{(isFlag === "Add Lab" || isFlag === "Add Prescription") &&
												<div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
													<label>Doctor Name :  </label>
													<input type="text" id="txtDoctorName" name="txtDoctorName" className='form-control' autocomplete="off"
														style={{ width: "22%", float: 'left', borderRadius: "0px", marginRight: "20px", marginBottom: "0" }} />

													<label>Patient Name : </label>
													<input type="text" id="txtPatientName" name="txtPatientName" className='form-control' autocomplete="off"
														style={{ width: "22%", float: 'left', borderRadius: "0px", marginRight: "20px", marginBottom: "0" }} />

													<label>Age :  </label>
													<input type="text" id="txtAge" name="txtAge" className='form-control' autocomplete="off"
														style={{ width: "22%", float: 'left', borderRadius: "0px", marginRight: "20px", marginBottom: "0" }} />

												</div>
											}
										</div>
									</div>
									<div class="paymentSecBtm invoicePaymntSecBtm_PTour">
										{dueAfterPayment >= "0.00" ?
											<span class="ContentBtmLabel" id="dueAfterPayment">DUE AFTER PAYMENT : INR  &nbsp;&nbsp;<span style={{ color: "#f00" }}  >{parseFloat(dueAfterPayment).toFixed(2)}</span></span>
											: ""}
										{advanceAfterPayment > "0.00" ?
											<span class="ContentBtmLabel" id="advanceAfterPayment">ADVANCE AFTER PAYMENT : INR  &nbsp;&nbsp;<span style={{ color: "#f00" }}  >{parseFloat(advanceAfterPayment).toFixed(2)}</span></span>
											: ""}
										<div class="paymentSecBtnBtm">
											<span class="clinicalCancel invoiceSavePaymntCancelBtn_PTour" onClick={() => paynowCancel()}>Cancel</span>
											<input type="submit" name="_action_saveInvoice" id="payNowSaveBtn" value={isFlag === "" || isFlag === "Procedure" ? "Save Invoice & Payment" : "Cash Received"} class="clinicalSave invoiceSavePaymntBtn_PTour" onClick={() => invoiceSave("paynow")} />
											{(isFlag !== "" && isFlag !== "Procedure") &&
												<input type="submit" name="_action_saveInvoice" id="cashReicevedBtn" value={"Cash Received & Print Receipt"} class="clinicalSave invoiceSavePaymntBtn_PTour" onClick={() => invoiceSave("paynow", "1")} />}
										</div>
									</div>
								</div>
								{/* TOTAL PAYABLE INFO ENDS HERE */}
							</div>



							{/* *************************************Right section starts here ******************************************************** */}






							{/* <div className="col-md-4 practiceRightSection" id="mainDiv3">
								{rightMenuDataChecking.length > 0 &&
									<TabsRight
										rightMenuData={rightMenuData}
										addProcedure={addProcedure}
										addPrescriptions={addPrescriptions}
										addLabOrder={addLabOrder}
										addDisposable={addDisposable}
									/>
								}
							</div> */}

							<div className="col-md-4 practiceRightSection" id="mainDiv3">
								<div className="patientright pl_pro tabsRightMain">
									<div className="rightTagSection">
										{isData === false ? <Loader /> : ''}
										<div className="rightTagList">
											<span style={{ padding: '8px 10px', float: 'left' }}>
												{(isFlag === "Procedure" || isFlag === "") &&
													<li className={'react-tabs__tab' + " " + addClass} >
														<span className='procedureTab react-tabs__tab' id="procedureTabClick"
															onClick={() => procedureClick()}
														>Procedures </span>
													</li>
												}

												{(isFlag === "Prescription" || isFlag === "" || isFlag === "Add Prescription") ?
													<li className={'react-tabs__tab' + " " + addClasspres}>
														<span className='prescriptionsTab react-tabs__tab' id="prescriptionTabClick"
															onClick={() => prescriptionClick()}
														>Prescriptions</span>
													</li>
													: ""
												}
												{(((medicineBranchId !== '4' || window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB') && (window.sessionStorage.getItem("siteBranchId") !== "4")) && (isFlag === "Lab" || isFlag === "" || isFlag === "Add Lab")) &&
													<li className={'react-tabs__tab' + " " + addClasslab}>
														<span className='labOrdersTab react-tabs__tab' id="labOrderTabClick"
															onClick={() => labOrdersClick()}
														>Lab Orders</span>
													</li>
												}

												{(isFlag === "Prescription" || isFlag === "Lab" || isFlag === "" || isFlag === "Add Lab" || isFlag === "Add Prescription") &&
													<li className={'react-tabs__tab' + " " + addClassdis}>
														<span className='disposablesTab react-tabs__tab' id="disposableTabClick"
															onClick={() => disposablesClick()}
														>Disposables</span>
													</li>
												}
											</span>
											<hr className='invAddRighthr' />

											<input type="text" placeholder="Search" value={searchKey} onChange={(e) => searchInvoice(e)} className="inputField" />
											<div id="ProcedureId">
												{/* {( isFlag ==="Procedure" || isFlag === "") && */}
												<div className="treatmentNameList treatmentPlanList ">

													{(plannedProcedureList.length > 0 || procedureList.length > 0) ?
														<ul id="tabProceduresUL">
															{plannedProcedureList.map((plannedObj, j) => (
																<li id={"plannedProcedure" + plannedObj.member_procedure_dental_details_id} onClick={() => addProcedure(plannedObj, j, plannedObj.member_procedure_dental_details_id)}>
																	<span class="elipsis" title={plannedObj.procedure_name}>
																		<b>{plannedObj.procedure_name}</b>
																	</span>
																	{plannedObj.is_completed === 0 ?
																		<span class="pl_planned_status">Planned</span>
																		: <span class="pl_planned_status">Completed</span>} <br />
																	<span class="elipsis" title={plannedObj.procedure_code} style={{ fontSize: '10px' }}>
																		{plannedObj.procedure_code !== "" ? plannedObj.procedure_code : <span>&nbsp;</span>}
																	</span>
																	<span class="treatmentNameListRight">
																		<span class="treatmentNameListAmt">₹{parseFloat(plannedObj.planned_amount).toFixed(2)}</span>
																	</span>
																</li>
															))
															}
															{/* {console.log("procedureObj", procedureList)} */}
															{procedureList.map((procedureObj, i) => (

																<li id={"procedureId" + procedureObj.procedure_id} onClick={() => addProcedure(procedureObj, i, '')}>
																	<span class="elipsis" title={procedureObj.procedure_name}>
																		<b>{procedureObj.procedure_name}</b>
																	</span>
																	<br />
																	{window.sessionStorage.getItem("siteBranchId") === "4" &&
																		<span
																			class="elipsis"
																			title={procedureObj.pmTreatmentsBranchName}
																			style={{ fontSize: '10px' }}
																		>
																			{procedureObj.pmTreatmentsBranchName == "" ? <span>&nbsp;</span> : procedureObj.pmTreatmentsBranchName}
																		</span>
																	}
																	<br />
																	<span class="elipsis" title={procedureObj.procedure_code} style={{ fontSize: '10px' }}>
																		{procedureObj.procedure_code !== "" ? procedureObj.procedure_code : <span>&nbsp;</span>}
																	</span>

																	<span class="treatmentNameListRight">
																		<span class="treatmentNameListAmt">₹{parseFloat(procedureObj.price).toFixed(2)}</span>
																	</span>
																</li>
															))}
														</ul>
														: "No Treatment Found"}
												</div>
												{/* } */}
											</div>
											<div id='PriscriptionId'>
												{/* {( isFlag ==="Prescription" ||  isFlag === "") && */}
												<div className="treatmentNameList treatmentPlanList ">

													{(plannedPrescriptionsList.length > 0 || PrescriptionsList.length > 0) ?
														<ul id="tabProceduresUL">
															{plannedPrescriptionsList.map((presobj, i) => (
																<li id={"plannedPrescription" + presobj.doctor_visit_medicine_id} onClick={() => addPrescriptionsBefore(presobj, presobj.doctor_visit_medicine_id, presobj.pm_hospital_medicine_id)} class="invoicePrescription">
																	<span class="elipsis prescribedMedicine" title={presobj.medicine}>
																		<img
																			title="Prescribed Medicine"
																			src={Priscription}
																			style={{
																				float: 'left',
																				width: '15px',
																				marginRight: '5px',
																			}}
																		/>
																		<span>{presobj.medicine + " " + presobj.medType + " " + presobj.medUnit}</span>
																	</span>
																	<span class="treatmentNameListRight">
																		<span class="invoicePresDate" title="Expiry Date">
																			{presobj.expiry_date}
																		</span>
																		<span
																			title="Prescribed Quantity"
																			class="prescriptionQty"
																			style={{ paddingLeft: '30px' }}
																			id={"plannedPrescriptionQty" + presobj.doctor_visit_medicine_id}
																		>
																			{presobj.changed_quantity === 0 ? '' : presobj.changed_quantity}
																		</span>
																		<input type="hidden" id={"hidPlannedPrescriptionQty" + presobj.doctor_visit_medicine_id} value={presobj.changed_quantity} />
																		<span class="treatmentNameListAmt" style={{ margin: '0' }}>
																			₹ {parseFloat(presobj.unitPrice).toFixed(2)}
																		</span>
																	</span>
																</li>
															))}
															{PrescriptionsList.map((obj, i) => (
																<li id={"prescriptionid" + obj.pm_hospital_medicine_id} onClick={() => addPrescriptionsBefore(obj, "", obj.pm_hospital_medicine_id)} class="invoicePrescription">
																	<span class="elipsis prescribedMedicine" title={obj.medicine}>
																		<span>{obj.medicine}</span>
																	</span>
																	<span class="treatmentNameListRight">
																		<span class="invoicePresDate" title="Expiry Date">
																			{obj.expiry_date}
																		</span>
																		<span
																			title="Available Quantity"
																			class="prescriptionQty"
																			style={{ paddingLeft: '30px' }}
																			id={"nonPlannedPrescriptionQty" + obj.pm_hospital_medicine_id}
																		>
																			{obj.stockQuantity}
																		</span>
																		<input type="hidden" id={"hidNonPlannedPrescriptionQty" + obj.pm_hospital_medicine_id} value={obj.stockQuantity} />
																		<span class="treatmentNameListAmt" style={{ margin: '0' }}>
																			₹ {parseFloat(obj.unitPrice).toFixed(2)}
																		</span>
																	</span>
																</li>
															))}
														</ul>
														: "No Medicine Found"}
												</div>
												{/* } */}
											</div>
											{/* {(medicineBranchId === '4' || (isFlag ==="Lab" || isFlag === "")) && */}
											<div id="LabOrderId">
												<div className="treatmentNameList treatmentPlanList ">
													{(plannedLabOrder.length > 0 || labOrder.length > 0) ?
														<ul id="tabProceduresUL" className='labOrderUl'>
															{plannedLabOrder.map((laobj, k) => (
																<li id={"plannedLabOrder" + laobj.doctor_visit_lab_order_test_id} onClick={() => addLabOrder(laobj, k, laobj.doctor_visit_lab_order_test_id)}>
																	<img
																		title="Prescribed Test"
																		src={labOrderIcon}
																		style={{
																			float: 'left',
																			width: '15px',
																			marginRight: '5px',
																		}}
																	/>
																	<span class="elipsis" title={laobj.test_name}>
																		{laobj.test_name}
																	</span>
																	<span class="treatmentNameListRight">
																		<span class="treatmentNameListAmt" style={{ margin: '0px' }}>
																			₹ {parseFloat(laobj.buy_price).toFixed(2)}
																		</span>
																	</span>
																</li>
															))}
															{labOrder.map((labObj, k) => (
																<li id={"labOrder" + labObj.service_provider_test_details_id} onClick={() => addLabOrder(labObj, k, '')}>
																	<span class="elipsis" title={labObj.test_name}>
																		<b>{labObj.test_name}</b>
																	</span>
																	<span class="treatmentNameListRight">
																		<span class="treatmentNameListAmt" style={{ margin: '0px' }}>
																			₹ {parseFloat(labObj.buy_price).toFixed(2)}
																		</span>
																	</span>
																</li>
															))}
														</ul>
														: "No Lab order Found"}
												</div>
											</div>
											{/* } */}
											<div id="Disposable">
												<div className="treatmentNameList treatmentPlanList ">
													{/* {(isFlag ==="Prescription" || isFlag ==="Lab" || isFlag === "")&& */}
													{disposableList.length > 0 ?
														<ul id="tabProceduresUL"  >
															{disposableList.map((disposableObj, i) => (
																<li onClick={() => addDisposable(disposableObj, i)} >
																	<span class="elipsis" title="test">
																		<b>{disposableObj.inventoryName}</b>
																	</span>
																	<span class="treatmentNameListRight">
																		<span class="treatmentNameListAmt" style={{ margin: '0px' }}>
																			₹ {parseFloat(disposableObj.unitPrice).toFixed(2)}
																		</span>
																	</span>
																</li>
															))}															</ul>
														: "No disposables Found"}
												</div>
											</div>
											{/* </Tabs> */}
										</div>
									</div>
								</div>
							</div>


							{/* *************************************Right section ends here ******************************************************** */}



							<Modal
								isOpen={modalIsOpen}
								// onAfterOpen={afterOpenModal}
								onRequestClose={closeModal}
								style={customStyles}
								contentLabel="Example Modal"
							>
								{isLoader === true ? <Loader /> : ''}
								<div>
									{/* <a href="#close" title="Close" class="close exportClose">X</a> */}
									<button className='popupCloseReact' onClick={closeModal}>X</button>
									<div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
										{notInHospitalMedicine.length > 0 ?
											<div class="addDataHead">
												<span className='labTitle'>Prescription Details</span>
											</div>
											: <div class="addDataHead">
												<span className='labTitle'>Lab Order Details</span>
											</div>}


										<div>
											{notInHospitalMedicine.length > 0 ?
												<div class="addDataContent" id="medicineDetails">

													<div>
														<div class="treatmentTable planActive">
															<table>
																<thead>
																	<tr>
																		<th class="col-md-3"><div class="dentalProName">Medicine</div></th>
																		<th class="col-md-4"><div class="dentalProCost txtLeft" style={{ width: "100%" }}>Frequency & Dosage</div></th>
																		<th class="col-md-2"><div class="dentalProDis txtLeft" style={{ paddingLeft: "10px" }}>Intake</div></th>
																		<th class="col-md-2"><div class="dentalProDis txtLeft">Qty</div></th>
																		<th class="col-md-2"><div class="dentalProTotal txtLeft">Days</div></th>
																		<th class="col-md-2"><div class="dentalProTotal txtLeft">Start Date
																		</div></th>
																	</tr>
																</thead>
																<tbody>
																	{notInHospitalMedicine.map((prescriptionDetails, e) => (
																		<tr>
																			<td class="col-md-3">
																				<div class="dentalProName">
																					<span class="elipsis" title="calpol">{prescriptionDetails.medicineName} </span>
																				</div>
																			</td>
																			<td class="col-md-4"><div class="dentalProCost txtLeft" style={{ width: "100%" }}>{prescriptionDetails.frequency + " " + prescriptionDetails.dosage}</div></td>
																			<td class="col-md-2" ><div class="dentalProDis txtLeft" style={{ paddingLeft: "10px" }}>{prescriptionDetails.intake}</div></td>
																			<td class="col-md-2"><div class="dentalProDis txtLeft">

																				{prescriptionDetails.quantity}
																			</div></td>
																			<td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> {prescriptionDetails.duration}</div></td>
																			<td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> {prescriptionDetails.startDate}</div></td>
																		</tr>
																	))}
																</tbody>
															</table>
														</div>

													</div>

													<div class="serviceProviderBtns invoiceAddPrintbtn" id="printBtnPatientInvoiceAdd" onClick={printClick}>Print


													</div>
												</div>
												: ""}
											{notInHospitalLabIds.length > 0 ?
												<div  >

													<div class="addDataContent" id="labDetails" style={{
														padding: "30px 0 0", overflowX: "hidden"
													}}>
														<div class="treatmentTable planActive">
															<table className='patientInvoiceAddTable' style={{ border: "1px solid #cbc5d4", width: "100%", borderSpacing: "0px" }}>
																<thead>
																	<tr style={{ border: "1px solid #cbc5d4" }}>
																		<th class="col-md-3" style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px", padding: "5px", borderBottom: "1px solid #ccc" }}><div class="dentalProName" >LAB TEST</div></th>
																		<th class="col-md-3" style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px", padding: "5px", borderBottom: "1px solid #ccc" }}><div class="dentalProCost txtLeft" style={{ width: "100%" }}>SPECIMEN</div></th>
																		<th class="col-md-4" style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px", padding: "5px", borderBottom: "1px solid #ccc" }}><div class="dentalProDis txtLeft">TEST TYPE</div></th>

																	</tr>
																</thead>
																<tbody>
																	{notInHospitalLabIds.map((prescriptionDetails, e) => (
																		<tr style={{ border: "1px solid #cbc5d4" }}>
																			<td class="col-md-3" style={{ borderLeft: "2px solid #3498db" }}  >
																				<div class="dentalProName" style={{ padding: "5px 3px" }}>
																					<span class="elipsis" title="calpol">{prescriptionDetails.testName} </span>
																				</div>
																			</td>
																			<td class="col-md-3"  ><div class="dentalProCost txtLeft " style={{ width: "100%", padding: "5px 3px" }}>{prescriptionDetails.specimen}</div></td>
																			<td class="col-md-4"   ><div class="dentalProDis txtLeft" style={{ padding: "5px 3px" }}>{prescriptionDetails.testType}</div></td>

																		</tr>


																	))}
																</tbody>
															</table>
														</div>

													</div>

													<div class="serviceProviderBtns invoiceAddPrintbtn" onClick={printLab}>
														<Link to={{

														}}>
															Print</Link>



													</div>
												</div>
												: ""}



										</div>


									</div>
								</div>
							</Modal>






							{/* <div id="labTestDetails" class="modalDialog" >
								{isLoader === true ? <Loader /> : ''}
								<div>
									<a href="#close" title="Close" class="close exportClose">X</a>
									<div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
										{notInHospitalMedicine.length > 0 ?
											<div class="addDataHead">
												<span className='labTitle'>Prescription Details</span>
											</div>
											: <div class="addDataHead">
												<span className='labTitle'>Lab Order Details</span>
											</div>}


										<div>
											{notInHospitalMedicine.length > 0 ?
												<div class="addDataContent" id="medicineDetails">

													<div>
														<div class="treatmentTable planActive">
															<table>
																<thead>
																	<tr>
																		<th class="col-md-3"><div class="dentalProName">Medicine</div></th>
																		<th class="col-md-4"><div class="dentalProCost txtLeft" style={{ width: "100%" }}>Frequency & Dosage</div></th>
																		<th class="col-md-2"><div class="dentalProDis txtLeft" style={{ paddingLeft: "10px" }}>Intake</div></th>
																		<th class="col-md-2"><div class="dentalProDis txtLeft">Qty</div></th>
																		<th class="col-md-2"><div class="dentalProTotal txtLeft">Days</div></th>
																		<th class="col-md-2"><div class="dentalProTotal txtLeft">Start Date
																		</div></th>
																	</tr>
																</thead>
																<tbody>
																	{notInHospitalMedicine.map((prescriptionDetails, e) => (
																		<tr>
																			<td class="col-md-3">
																				<div class="dentalProName">
																					<span class="elipsis" title="calpol">{prescriptionDetails.medicineName} </span>
																				</div>
																			</td>
																			<td class="col-md-4"><div class="dentalProCost txtLeft" style={{ width: "100%" }}>{prescriptionDetails.frequency + prescriptionDetails.dosage}</div></td>
																			<td class="col-md-2" ><div class="dentalProDis txtLeft" style={{ paddingLeft: "10px" }}>{prescriptionDetails.intake}</div></td>
																			<td class="col-md-2"><div class="dentalProDis txtLeft">

																				{prescriptionDetails.quantity}
																			</div></td>
																			<td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> {prescriptionDetails.duration}</div></td>
																			<td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> {prescriptionDetails.startDate}</div></td>
																		</tr>
																	))}
																</tbody>
															</table>
														</div>

													</div>

													<div class="serviceProviderBtns invoiceAddPrintbtn" id="printBtnPatientInvoiceAdd" onClick={printClick}>Print


													</div>
												</div>
												: ""}
											{notInHospitalLabIds.length > 0 ?
												<div  >

													<div class="addDataContent" id="labDetails" style={{
														padding: "30px 0 0", overflowX: "hidden"
													}}>
														<div class="treatmentTable planActive">
															<table className='patientInvoiceAddTable' style={{ border: "1px solid #cbc5d4", width: "100%", borderSpacing: "0px" }}>
																<thead>
																	<tr style={{ border: "1px solid #cbc5d4" }}>
																		<th class="col-md-3" style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px", padding: "5px", borderBottom: "1px solid #ccc" }}><div class="dentalProName" >LAB TEST</div></th>
																		<th class="col-md-3" style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px", padding: "5px", borderBottom: "1px solid #ccc" }}><div class="dentalProCost txtLeft" style={{ width: "100%" }}>SPECIMEN</div></th>
																		<th class="col-md-4" style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px", padding: "5px", borderBottom: "1px solid #ccc" }}><div class="dentalProDis txtLeft">TEST TYPE</div></th>

																	</tr>
																</thead>
																<tbody>
																	{notInHospitalLabIds.map((prescriptionDetails, e) => (
																		<tr style={{ border: "1px solid #cbc5d4" }}>
																			<td class="col-md-3" style={{ borderLeft: "2px solid #3498db" }}  >
																				<div class="dentalProName" style={{ padding: "5px 3px" }}>
																					<span class="elipsis" title="calpol">{prescriptionDetails.testName} </span>
																				</div>
																			</td>
																			<td class="col-md-3"  ><div class="dentalProCost txtLeft " style={{ width: "100%", padding: "5px 3px" }}>{prescriptionDetails.specimen}</div></td>
																			<td class="col-md-4"   ><div class="dentalProDis txtLeft" style={{ padding: "5px 3px" }}>{prescriptionDetails.testType}</div></td>

																		</tr>


																	))}
																</tbody>
															</table>
														</div>

													</div>

													<div class="serviceProviderBtns invoiceAddPrintbtn" onClick={printLab}>
														<Link to={{

														}}>
															Print</Link>



													</div>
												</div>
												: ""}



										</div>


									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default PatientInvoiceAdd;
