import React, { useEffect, useState } from "react";
import { callingAPI } from "../config";
import Img1mm from "../images/dental/1mm.jpg";
import Img15mm from "../images/dental/1-5mm.jpg";
import Img2mm from "../images/dental/2mm.jpg";
import Img3mm from "../images/dental/3mm.jpg";
import Img4mm from "../images/dental/4mm.jpg";
import Img1mmb from "../images/dental/1mmb.jpg";
import Img15mmb from "../images/dental/1-5mmb.jpg";
import Img2mmb from "../images/dental/2mmb.jpg";
import Img3mmb from "../images/dental/3mmb.jpg";
import Img4mmb from "../images/dental/4mmb.jpg";
import T11 from "../images/dental/11.png";
import T11b from "../images/dental/11b.png";
import T12 from "../images/dental/12.png";
import T12b from "../images/dental/12b.png";
import T13 from "../images/dental/13.png";
import T13b from "../images/dental/13b.png";
import T14 from "../images/dental/14.png";
import T14b from "../images/dental/14b.png";
import T15 from "../images/dental/15.png";
import T15b from "../images/dental/15b.png";
import T16 from "../images/dental/16.png";
import T16b from "../images/dental/16b.png";
import T17 from "../images/dental/17.png";
import T17b from "../images/dental/17b.png";
import T18 from "../images/dental/18.png";
import T18b from "../images/dental/18b.png";
import T21 from "../images/dental/21.png";
import T21b from "../images/dental/21b.png";
import T22 from "../images/dental/22.png";
import T22b from "../images/dental/22b.png";
import T23 from "../images/dental/23.png";
import T23b from "../images/dental/23b.png";
import T24 from "../images/dental/24.png";
import T24b from "../images/dental/24b.png";
import T25 from "../images/dental/25.png";
import T25b from "../images/dental/25b.png";
import T26 from "../images/dental/26.png";
import T26b from "../images/dental/26b.png";
import T27 from "../images/dental/27.png";
import T27b from "../images/dental/27b.png";
import T28 from "../images/dental/28.png";
import T28b from "../images/dental/28b.png";
import T31 from "../images/dental/31.png";
import T31b from "../images/dental/31b.png";
import T32 from "../images/dental/32.png";
import T33 from "../images/dental/33.png";
import T34 from "../images/dental/34.png";
import T35 from "../images/dental/35.png";
import T36 from "../images/dental/36.png";
import T37 from "../images/dental/37.png";
import T38 from "../images/dental/38.png";
import T32b from "../images/dental/32b.png";
import T33b from "../images/dental/33b.png";
import T34b from "../images/dental/34b.png";
import T35b from "../images/dental/35b.png";
import T36b from "../images/dental/36b.png";
import T37b from "../images/dental/37b.png";
import T38b from "../images/dental/38b.png";
import T41 from "../images/dental/41.png";
import T42 from "../images/dental/42.png";
import T43 from "../images/dental/43.png";
import T44 from "../images/dental/44.png";
import T45 from "../images/dental/45.png";
import T46 from "../images/dental/46.png";
import T47 from "../images/dental/47.png";
import T48 from "../images/dental/48.png";
import Logo from "../images/media/logo.png";
import T41b from "../images/dental/41b.png";
import T42b from "../images/dental/42b.png";
import T43b from "../images/dental/43b.png";
import T44b from "../images/dental/44b.png";
import T45b from "../images/dental/45b.png";
import T46b from "../images/dental/46b.png";
import T47b from "../images/dental/47b.png";
import T48b from "../images/dental/48b.png";
import Loader from "../Common/loader";
import Datepicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment";
function LabOrderDentalPrint() {
  const [state, setState] = useState({
    labTestRequestRequiredDate: "",
    labTestRequestOrderDate: "",
    labTestFrameDate: "",
    labTestTeethSettingDate: "",
  });
  const [isData, setIsData] = useState(false);
  const [flag, setflag] = useState(false);
  const [saveflag, setSaveflag] = useState(false);
  const [orderDate, setOrderDate] = useState(new Date());
  const [logoImage, setLogo] = useState([]);
  const [requiredDate, setRequiredDate] = useState("");
  const [frameTrialDate, setFrameTrialDate] = useState("");
  const [teethSettingTrial, setTeethSettingTrial] = useState("");
  const [hospitalData, setHospitalData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [labDetails, setLabdetails] = useState([]);
  const [patientAge, setPatientAge] = useState([]);
  const [SoftNum, setSoftNum] = useState([]);
  const [teethArray, setTeethArray] = useState([]);
  const [labFormDetailsIdarray, setLabFormDetailsIdarray] = useState([]);
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [hospitalPhone, setHospitalPhone] = useState("");
  const [softSplint, setSoftSplint] = useState("");
  const [teethImage, setTeethImage] = useState("");
  const [labFormDetailsId, setlabFormDetailsId] = useState("");
  const [getServiceProviderId, setServiceProviderId] = useState("");
  const [txImplant1, setTxImplant1] = useState("");
  const [txImplant2, setTxImplant2] = useState("");
  const [txImplant3, setTxImplant3] = useState("");
  const [txImplant4, setTxImplant4] = useState("");
  const [txImplant5, setTxImplant5] = useState("");
  const [txImplant6, setTxImplant6] = useState("");
  const [txImplant7, setTxImplant7] = useState("");
  const [doctorData, setDoctorData] = useState({
    firstName: "",
    lastName: "",
  });
  const [labName, setLabName] = useState("");
  const [labAddress, setLabAddress] = useState("");
  const [labTestRequestDetails, setLabTestRequestDetails] = useState("");
  const [dataSet, setDataSet] = useState("");
  const [doctorMobile, setdoctorMobile] = useState("");
  const [notes, setNotes] = useState("");
  const [marketingEx, setMarketingEx] = useState("");
  const [rushState, setrushState] = useState(false);
  const [newDates, setNewDate] = useState(new Date());
  var currentDate = new Date();
  const toDate = currentDate;
  var orderedDate = moment(orderDate).format("YYYY-MM-DD");
  var reqDate = requiredDate;
  var teethSettingTrialDate = moment(state.labTestTeethSettingDate).format(
    "DD-MM-YYYY"
  );
  var date = moment(orderDate).format("YYYY-MM-DD");
  var time = moment(currentDate).format("hh:mm A");
  const outputTrialDate = moment(state.labTestFrameDate).format("DD-MM-YYYY");
  var framedTrialDate = "" + date + "" + " " + time + "";
  var convertedDate = Date.parse(framedTrialDate);
  var convertedCurrentDate = Date.parse(currentDate);
  const ordereDateChange = (date) => {
    if (date !== "") {
      setOrderDate(date);
    } else {
      setOrderDate(toDate);
    }
  };
  const requiredDateChange = (date) => {
    if (date !== "") {
      setRequiredDate(date);
    } else {
      setRequiredDate("");
    }
  };
  const frameDateChange = (date) => {
    if (date === "") {
      setFrameTrialDate(date);
    } else {
      setFrameTrialDate(date);
    }
  };
  const teethSettingDateChange = (date) => {
    setTeethSettingTrial(date);
  };
  const onChangeRush = (event) => {
    setrushState(event.target.checked);
  };
  var getS3folder =
    process.env.REACT_APP_S3_URI +
    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
    process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
  const changeColor = (id, color, teeth) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
      if (color === "W") {
        document.getElementById("teethT" + id + "W").style.display = "none";
        document.getElementById("teethT" + id + "B").style.display = "block";
      } else if (color === "B") {
        document.getElementById("teethT" + id + "W").style.display = "block";
        document.getElementById("teethT" + id + "B").style.display = "none";
      }
      if (teeth === "longTeeth") {
        const index = teethArray.indexOf(id.toString());
        if (index > -1) {
          teethArray.splice(index, 1);
          setTeethArray(teethArray);
        } else {
          teethArray.push(id);
          setTeethArray(teethArray);
        }
      } else {
        const index1 = SoftNum.indexOf(id.toString());
        if (index1 > -1) {
          SoftNum.splice(index1, 1);
          setSoftNum(SoftNum);
        } else {
          SoftNum.push(id);
          setSoftNum(SoftNum);
        }
      }
    } else {
      if (color === "W") {
        document.getElementById("teethT" + id + "W").style.display = "block";
        document.getElementById("teethT" + id + "B").style.display = "none";
      } else if (color === "B") {
        document.getElementById("teethT" + id + "W").style.display = "none";
        document.getElementById("teethT" + id + "B").style.display = "block";
      }
    }
  };
  const printPage = () => {
    document.getElementById("cancel").style.display = "none";
    document.getElementById("print").style.display = "none";
    document.getElementById("savePrint").style.display = "none";
    document.getElementById("send").style.display = "none";
    window.print();
    document.getElementById("cancel").style.display = "inline-block";
    document.getElementById("print").style.display = "inline-block";
    document.getElementById("savePrint").style.display = "inline-block";
    document.getElementById("send").style.display = "inline-block";
    document.getElementById("send").style.display =
      flag == true ? "none" : "inline-block";
    document.getElementById("savePrint").style.display =
      flag == true ? "none" : "inline-block";
  };
  const checkedBox = (event, checkedId) => {
    if (event.target.checked == true) {
      labFormDetailsIdarray.push(checkedId);
      setLabFormDetailsIdarray(labFormDetailsIdarray);
    } else {
      const index = labFormDetailsIdarray.indexOf(checkedId);
      if (index > -1) {
        labFormDetailsIdarray.splice(index, 1);
        setLabFormDetailsIdarray(labFormDetailsIdarray);
      }
    }
  };

  const saveAndSendOutside = (serviceProviderId) => {
    const postData = {
      siteId: window.sessionStorage.getItem("siteId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendMail", postData).then(
      (data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: "",
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired: "",
              ccEmailRequired: "",
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                alert("Something went wrong");
              } else if (response.data.success === "1") {
                window.location.href = "/Patients/labOrderDental";
              }
            })
            .catch();
        }
      }
    );
  };
  const saveFunction = () => {
    setIsData(false);
    if (saveflag) {
      return;
    }
    setSaveflag(true);
    var rushValue = "off";
    if (rushState === true) {
      rushValue = "on";
    }
    let saveParams = {
      rush: rushValue,
      SettingDate:
        teethSettingTrial === ""
          ? ""
          : moment(teethSettingTrial).format("YYYY-MM-DD"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      hidSoftNum: SoftNum.toString(),
      hidTeethNumber: teethArray.toString(),
      appoinmentDate:
        orderDate !== ""
          ? framedTrialDate
          : moment(currentDate).format("YYYY-MM-DD hh:mm A"),
      hospitalId: localStorage.getItem("hospitalId"),
      labFormDetailsId: labFormDetailsIdarray.toString(),
      labFormsId: "1",
      labTestReqId: "",
      labTestRequestOrderNo: dataSet,
      labTestRequestOrderDate:
        orderDate === "" ? "" : moment(orderDate).format("YYYY-MM-DD"),
      labTestRequestRequiredDate:
        reqDate === "" ? "" : moment(reqDate).format("YYYY-MM-DD"),
      marketingEx: document.getElementById("marketingEx").value,
      memberId: window.sessionStorage.getItem("memberId"),
      notes: document.getElementById("notes").value,
      savePrint: true,
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      trialId:
        frameTrialDate === ""
          ? ""
          : moment(frameTrialDate).format("YYYY-MM-DD HH:mm:ss"),
      txImplant1: document.getElementById("text1").value,
      txImplant2: document.getElementById("text2").value,
      txImplant3: document.getElementById("text3").value,
      txImplant4: document.getElementById("text4").value,
      txImplant5: document.getElementById("text5").value,
      txImplant6: document.getElementById("text6").value,
      txImplant7: document.getElementById("text7").value,
      userId: window.sessionStorage.getItem("userId"),
      appoinmentDate:
        orderDate !== ""
          ? framedTrialDate
          : moment(currentDate).format("YYYY-MM-DD hh:mm A"),
      scheduledtimeSlotStartTime:
        orderDate !== "" ? convertedDate : Date.parse(currentDate),
      scheduledtimeSlotEndTime:
        orderDate !== ""
          ? convertedDate + 15 * 60 * 1000
          : Date.parse(currentDate) + 15 * 60 * 1000,
    };
    callingAPI("adminReports/saveForm", saveParams)
      .then((response) => {
        setIsData(true);
        if (response.data.success === "1") {
          saveAndSendOutside()
          alert("Lab Test save and send successfully");
          window.location.href = "/Patients/labOrderDental";
        } else {
          alert(response.data.errorMessage);
        }
        setSaveflag(false);
      })
      .catch((err) => {
        setSaveflag(false);
      });
  };
  const listForm = () => {
    let listParams = {
      hospitalId: localStorage.getItem("hospitalId"),
      memberId: window.sessionStorage.getItem("memberId"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      userId: window.sessionStorage.getItem("userId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      labFormsId: "1",
      labTestRequestOrderNo: dataSet,
      labTestRequestsRequiredDate: reqDate,
      labTestRequestsOrderDate: orderedDate,
      savePrint: true,
      labFormDetailsId: labFormDetailsIdarray.toString(),
    };
    callingAPI("adminReports/dentalFormNew", listParams)
      .then((response) => {
        if (response.data.success === "1") {
          setHospitalData(response.data.result.hospital);
          setMemberData(response.data.result.patient);
          setLabdetails(response.data.result.serviceProviderDetails);
          if (response.data.result.doctor.length > 0) {
            setDoctorData({
              firstName: response.data.result.doctor[0].first_name,
              lastName: response.data.result.doctor[0].last_name,
            });
          }

          if (response.data.result.doctor.length > 0) {
            setdoctorMobile(response.data.result.doctor[0].mobileNumber);
          }
          if (response.data.result.serviceProviderDetails.length > 0) {
            setLabName(response.data.result.serviceProviderDetails[0].name);
            setLabAddress(response.data.result.serviceProviderDetails[0].address);
          }
          if (response.data.result.hospital.length > 0) {
            setHospitalEmail(response.data.result.hospital[0].email_id);
            setHospitalPhone(response.data.result.hospital[0].phone);
          }
          if (response.data.result.logoImage.length > 0) {
            setLogo(response.data.result.logoImage[0].logoImage);
          }
          if (response.data.result.patientAge.length > 0) {
            setPatientAge(response.data.result.patientAge[0]._year);
          }

        }
      })
      .catch();
  };
  const editingId = (event) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
      setIsData(true);
    } else {
      setIsData(false);
      setflag(true);
      document.getElementById("cancel").style.display = "block";
      document.getElementById("print").style.display = "block";
      document.getElementById("savePrint").style.display = "none";
      document.getElementById("send").style.display = "none";
      const postData = {
        doctorId: window.sessionStorage.getItem("patientDoctorId"),
        memberId: window.sessionStorage.getItem("memberId"),
        hospitalId: localStorage.getItem("hospitalId"),
        serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
        labRequestId: window.sessionStorage.getItem("labReqId"),
      };
      callingAPI("commonUserFunctions/dentalFormsOne", postData)
        .then((data) => {
          setIsData(true);
          if (data.data.success === "1") {
            if (data.data.result.labTestRequestDetails.length > 0) {
              setLabTestRequestDetails(
                data.data.result.labTestRequestDetails[0]
                  .labTestRequestOrderNumber
              );
            }

            setTxImplant1(data.data.result.txImplant1);
            setTxImplant2(data.data.result.txImplant2);
            setTxImplant3(data.data.result.txImplant3);
            setTxImplant4(data.data.result.txImplant4);
            setTxImplant5(data.data.result.txImplant5);
            setTxImplant6(data.data.result.txImplant6);
            setTxImplant7(data.data.result.txImplant7);
            setNotes(data.data.result.notes);
            setMarketingEx(data.data.result.marketingEx);
            setState({
              labTestRequestRequiredDate:
                data.data.result.labTestRequestRequiredDate,
              labTestRequestOrderDate: data.data.result.labTestRequestOrderDate,
              labTestFrameDate: data.data.result.trailId,
              labTestTeethSettingDate: data.data.result.settingDate,
            });
            setlabFormDetailsId(data.data.result.labFormDetailsId);
            var labFormData = data.data.result.labFormDetailsId
              .toString()
              .split(",");

            labFormData.map((labformId) => {
              if (labformId) {
                const checkbox = document.getElementById("teethTC" + labformId);
                checkbox.checked = "true";
                checkbox.disabled = "true";
              }
            });
            setSoftSplint(data.data.result.softNumber);
            var softSplitData = data.data.result.softNumber.split(",");
            softSplitData.map((item) => {
              if (item) {
                document.getElementById("teethT" + item + "B").style.display =
                  "block";
                document.getElementById("teethT" + item + "W").style.display =
                  "none";
              }
            });
            setTeethImage(data.data.result.teethNumber);
            var teethData = data.data.result.teethNumber.split(",");
            teethData.map((itemteethData) => {
              if (itemteethData) {
                document.getElementById(
                  "teethT" + itemteethData + "B"
                ).style.display = "block";
                document.getElementById(
                  "teethT" + itemteethData + "W"
                ).style.display = "none";
              }
            });
            if (data.data.result.rush === 1) {
              const rushCheckbox = document.getElementById("teethTC0");
              rushCheckbox.checked = "true";
              rushCheckbox.disabled = "true";
            }
          }
        })
        .catch();
    }
  };
  useEffect(() => {
    setDataSet(Math.floor(1000 + Math.random() * 9000));
    editingId();
    listForm();
  }, []);
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  return (
    <body
      style={{
        margin: "0",
        fontSize: "13px",
        fontWeight: "300",
        color: "#000",
        fontFamily: "sans-serif",
      }}
      className="lbOrderPrintMain"
    >
      <div id="parent-selector">
        <div
          class="instructionFormWrap"
          style={{
            width: "980px",
            position: "relative",
            background: "#fff",
            margin: "auto",
            padding: "5px 0px",
            boxSizing: "borderBox",
            zIndex: "1",
          }}
        >
          {isData === false ? <Loader /> : ""}
          {hospitalData.map((data) => (
            <div>
              <div
                class="formTop"
                style={{ clear: "both", overflow: "hidden" }}
              >
                {flag == true ? (
                  <div
                    class="slNo"
                    style={{ width: "15%", float: "left", color: "#ff0000" }}
                  >
                    <span style={{ float: "left" }}>
                      Sl No:{labTestRequestDetails}
                    </span>
                    <span
                      style={{
                        fontWeight: "800",
                        fontSize: "15px",
                        float: "left",
                        paddingLeft: "5px",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    class="slNo"
                    style={{ width: "15%", float: "left", color: "#ff0000" }}
                  >
                    <span style={{ float: "left" }}>Sl No:{dataSet}</span>
                    <span
                      style={{
                        fontWeight: "800",
                        fontSize: "15px",
                        float: "left",
                        paddingLeft: "5px",
                      }}
                    />
                  </div>
                )}
                <div
                  class="formName"
                  style={{ width: "70%", float: "left", textAlign: "center" }}
                >
                  <div
                    class="title"
                    style={{
                      fontSize: "25px",
                      fontWeight: "600",
                      color: "#0091d0",
                    }}
                  >
                    <span style={{ color: "#ff0000", verticalAlign: "top" }} />
                    {labName}
                  </div>
                  <div class="address" style={{ margin: "3px 0" }}>
                    . - {labAddress}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div
            class="mailPortion"
            style={{
              clear: "both",
              overflow: "hidden",
              fontSize: "0px",
              textAlign: "center",
              paddingTop: "2px",
              borderBottom: "1px solid #252525",
            }}
          >
            <div>
              <div
                class="mailId"
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                  padding: " 2px 7px",
                  background: "#e31e24",
                  color: " #fff",
                  transform: "skew(-15deg)",
                  verticalAlign: "top",
                }}
              >
                <span
                  style={{
                    transform: "skew(15deg)",
                    display: "inline-block",
                    fontSize: "10px",
                    float: "left",
                  }}
                >
                  Doctor Name:
                </span>
                <span
                  style={{
                    transform: "skew(15deg)",
                    display: "inline-block",
                    fontSize: "10px",
                    float: "left",
                    paddingLeft: "3px",
                  }}
                >
                  Dr. {doctorData.firstName} {doctorData.lastName}
                </span>
              </div>
              <div
                class="mailId"
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                  padding: "2px 7px",
                  background: "#e31e24",
                  color: "#fff",
                  transform: "skew(-15deg)",
                  verticalAlign: "top",
                }}
              >
                <span
                  style={{
                    transform: "skew(15deg)",
                    display: "inline-block",
                    fontSize: "10px",
                    float: "left",
                  }}
                >
                  Email Id:
                </span>
                <span
                  style={{
                    transform: "skew(15deg)",
                    display: "inline-block",
                    fontSize: "10px",
                    float: "left",
                    paddingLeft: "3px",
                  }}
                >
                  {hospitalEmail}
                </span>
              </div>
              <div
                class="whatsAppNo"
                style={{
                  display: "inline-block",
                  padding: "2px 7px",
                  background: "#e31e24",
                  color: " #fff",
                  transform: "skew(-15deg)",
                  verticalAlign: "top",
                }}
              >
                <span
                  style={{
                    transform: "skew(15deg)",
                    display: "inline-block",
                    fontSize: "10px",
                    float: "left",
                  }}
                >
                  Phone{" "}
                  <span style={{ fontSize: "9px", verticalAlign: "bottom" }} />{" "}
                  :
                </span>
                <span
                  style={{
                    transform: "skew(15deg)",
                    display: " inline-block",
                    fontSize: "10px",
                    float: "left",
                    paddingLeft: "3px",
                  }}
                >
                  {hospitalPhone}
                </span>
              </div>
            </div>
          </div>
          <div class="instructionDetails">
            <div
              class="heading"
              style={{
                textAlign: "center",
                paddingTop: "10px",
                fontSize: "19px",
                color: "#0091d0",
              }}
            >
              INSTRUCTIONS FOR REMOVABLE PROSTHESIS
            </div>
            <div
              class="detailWrap"
              style={{ clear: "both", overflow: "hidden" }}
            >
              <div
                class="doctorDetail"
                style={{ width: "540px", float: "left" }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    color: "#000",
                    paddingBottom: "8px",
                    display: "block",
                  }}
                >
                  LAB DETAILS
                </span>
                {labDetails.map((data) => (
                  <div
                    class="doctorDes"
                    style={{
                      border: "1px solid #252525",
                      padding: "1px",
                      clear: "both",
                      overflow: "hidden",
                      height: "156px",
                    }}
                  >
                    <div
                      class="drImg"
                      style={{
                        width: "160px",
                        float: "left",
                        height: "150px",
                        border: "1px solid #252525",
                        boxSizing: "borderBox",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "center",
                          display: "block",
                          width: "62px",
                          margin: "auto",
                          fontSize: "11px",
                          paddingTop: "55px",
                        }}
                      >
                        Barcode Sticker
                      </span>
                    </div>
                    <div
                      class="drDetail"
                      style={{
                        width: "374px",
                        float: "left",
                        paddingLeft: "15px",
                        boxSizing: "borderBox",
                      }}
                    >
                      { }
                      <span style={{ display: "block", fontSize: "8px" }} />
                      <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                        <span style={{ float: "left", paddingRight: "3px" }}>
                          Name :{" "}
                        </span>
                        <div style={{ wordBreak: "breakWord", width: "358px" }}>
                          {data.name}
                        </div>
                      </div>

                      <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                        <span style={{ float: "left", paddingRight: "3px" }}>
                          Place :{" "}
                        </span>
                        <div style={{ wordBreak: "breakWord", width: "358px" }}>
                          {data.address}
                        </div>
                      </div>
                      <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                        <span style={{ float: "left", paddingRight: "3px" }}>
                          Phone :{" "}
                        </span>
                        <div style={{ wordBreak: "breakWord", width: "358px" }}>
                          {data.phone}
                        </div>
                      </div>
                      <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                        <span style={{ float: "left", paddingRight: "3px" }}>
                          Email :{" "}
                        </span>
                        <div style={{ wordBreak: "breakWord", width: "358px" }}>
                          {data.emailId}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                class="patientDetail"
                style={{ width: "425px", float: "left", marginLeft: "15px" }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    color: "#000",
                    paddingBottom: "8px",
                    display: "block",
                    textAlign: "right",
                  }}
                >
                  PATIENT'S DETAILS
                </span>
                {memberData.map((data) => (
                  <div
                    class="doctorDes"
                    style={{
                      border: "1px solid #252525",
                      padding: "1px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      class="drDetail"
                      style={{
                        width: "420px",
                        float: "left",
                        paddingLeft: "15px",
                        boxSizing: " borderBox",
                        height: "150px",
                      }}
                    >
                      <span style={{ display: "block", fontSize: "8px" }} />
                      <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                        <span style={{ float: "left", paddingRight: "3px" }}>
                          Name :{" "}
                        </span>
                        <div style={{ wordBreak: "breakWord", width: "400px" }}>
                          {data.first_name} {data.last_name}
                        </div>
                      </div>
                      <div
                        style={{
                          paddingTop: "3px",
                          lineHeight: "21px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ float: "left", width: "160px" }}>
                          <span style={{ float: "left", paddingRight: "3px" }}>
                            Gender :{" "}
                          </span>
                          <div
                            style={{ wordBreak: "breakWord", width: "230px" }}
                          >
                            {data.gender}
                          </div>
                        </div>
                        <div style={{ float: "right" }}>
                          <span style={{ float: "left" }}>Order Date :</span>
                          <div style={{ float: "left", width: "80px" }}>
                            {flag == true ? (
                              <Datepicker
                                autoComplete="off"
                                required
                                className="Datepicker pa2 LborderdentalPrint  "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                disabled
                                value={state.labTestRequestOrderDate}
                                id="fromdate"
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                              />
                            ) : (
                              <Datepicker
                                autoComplete="off"
                                required
                                //
                                value={toDate}
                                className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm   "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                selected={orderDate}
                                onChange={(date) => {
                                  ordereDateChange(date);
                                }}
                                id="fromdate"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingTop: "3px",
                          lineHeight: "21px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ float: "left", width: "130px" }}>
                          <span style={{ float: "left" }}>Age : </span>
                          &nbsp;{patientAge}
                        </div>
                        <div style={{ float: "right" }}>
                          <span style={{ float: "left" }}>Required Date :</span>
                          <div style={{ float: "left", width: "80px" }}>
                            {flag == true ? (
                              <Datepicker
                                autoComplete="off"
                                required
                                className="Datepicker pa2 LborderdentalPrint  "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                id="fromdate"
                                disabled
                                value={
                                  state.labTestRequestRequiredDate ===
                                    "00-00-0000"
                                    ? ""
                                    : state.labTestRequestRequiredDate
                                }
                                style={{ color: "#000" }}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                              />
                            ) : (
                              <Datepicker
                                autoComplete="off"
                                required
                                className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                id="fromdate"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={requiredDate}
                                onChange={(date) => {
                                  requiredDateChange(date);
                                }}
                                style={{ color: "#000" }}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingTop: "3px",
                          lineHeight: "21px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            float: "right",
                            width: "128px",
                          }}
                        >
                          <input
                            value={rushState}
                            onChange={onChangeRush}
                            type="checkbox"
                            name="rush"
                            id="teethTC0"
                            disabled={flag}
                            style={{
                              margin: "4px 3px 0 0px",
                              float: "left",
                            }}
                          />

                          <div> Rush(10 % extra)</div>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingTop: "3px",
                          lineHeight: "21px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <span
                          style={{
                            display: "block",
                            textAlign: "right",
                            paddingRight: "8px",
                          }}
                        >
                          NB : *Give us more time to avail extra benefit.
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div class="formHalfCheck">
            <div
              class="formContent"
              style={{ clear: "both", overflow: "hidden" }}
            >
              <div
                style={{
                  width: "500px",
                  boxSizing: "borderBox",
                  paddingTop: "10px",
                  float: "left",
                  marginRight: "5px",
                  boxSizing: "borderBox",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    padding: "6px 0px",
                    fontSize: "15px",
                    color: "#ff0000",
                    fontWeight: "600",
                  }}
                >
                  Removables
                </div>
                <div
                  style={{
                    border: "1px solid #000",
                    width: "250px",
                    padding: "5px",
                    float: "left",
                    boxSizing: "borderBox",
                    height: "285px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="txtService"
                      disabled={flag}
                      onChange={(event) => checkedBox(event, "1")}
                      value="1"
                      id="teethTC1"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Precision Attachment</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "2")}
                      value="2"
                      disabled={flag}
                      id="teethTC2"
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Telescopic Crowns , Over Dentures </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "3")}
                      value="3"
                      id="teethTC3"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Cast Partial Denture (Titanium)</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "4")}
                      value="4"
                      id="teethTC4"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Cast Partial Denture (Co-Cr)</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "5")}
                      value="5"
                      id="teethTC5"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: " 0px 5px 0 0px", float: "left" }}
                    />
                    <span>BPS with Logo</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "7")}
                      value="7"
                      id="teethTC7"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>BPS without Logo</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "8")}
                      value="8"
                      id="teethTC8"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Sunflex</span>
                  </div>
                </div>

                <div
                  style={{
                    border: "1px solid #000",
                    width: "250px",
                    padding: "5px",
                    float: "left",
                    borderLeft: "0",
                    height: "285px",
                    boxSizing: "borderBox",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "9")}
                      value="9"
                      id="teethTC9"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Valplast </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "10")}
                      value="10"
                      id="teethTC10"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: " 0px 5px 0 0px", float: "left" }}
                    />
                    <span>Bre Flex </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "11")}
                      value="11"
                      id="teethTC11"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Bre Crystal </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "12")}
                      value="12"
                      id="teethTC12"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: " 0px 5px 0 0px", float: "left" }}
                    />
                    <span>Bio Dentaplast </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "81")}
                      value="81"
                      disabled={flag}
                      id="teethTC81"
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Acrylic Denture </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "82")}
                      value="82"
                      disabled={flag}
                      id="teethTC82"
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Space Maintainer </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "15")}
                      value="15"
                      id="teethTC15"
                      disabled={flag}
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Bite Block </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "8px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={(event) => checkedBox(event, "16")}
                      value="16"
                      disabled={flag}
                      id="teethTC16"
                      name="txtService"
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Light Cure Special Tray </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "470px",
                  boxSizing: "borderBox",
                  paddingTop: "10px",
                  float: "left",
                  marginLeft: "5px",
                  boxSizing: "borderBox",
                }}
              >
                <div
                  style={{
                    width: "235px",
                    float: "left",
                    boxSizing: "borderBox",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "6px 0px",
                      fontSize: "15px",
                      color: "#0091d0",
                      fontWeight: "600",
                    }}
                  >
                    Enclosures
                  </div>
                  <div
                    style={{
                      border: "1px solid #000",
                      padding: "5px",
                      height: "285px",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "18")}
                        value="18"
                        disabled={flag}
                        id="teethTC18"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Impression </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "19")}
                        value="19"
                        disabled={flag}
                        id="teethTC19"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Bite </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "20")}
                        value="20"
                        disabled={flag}
                        id="teethTC20"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Articulator </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "21")}
                        value="21"
                        disabled={flag}
                        id="teethTC21"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Shade Tab </span>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "66")}
                        value="66"
                        disabled={flag}
                        id="teethTC66"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Shade Tooth </span>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "22")}
                        value="22"
                        disabled={flag}
                        id="teethTC22"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Pre-Op Models </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "23")}
                        value="23"
                        disabled={flag}
                        id="teethTC23"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Model-with-Temporary-on </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "24")}
                        value="24"
                        disabled={flag}
                        id="teethTC24"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Additional Model </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "25")}
                        value="25"
                        disabled={flag}
                        id="teethTC25"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Old Work (adjust/Remake) </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) => checkedBox(event, "26")}
                        value="26"
                        disabled={flag}
                        id="teethTC26"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Tray Enclosed </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "235px",
                    float: "left",
                    boxSizing: "borderBox",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "6px 0px",
                      fontSize: "15px",
                      color: "#0091d0",
                      fontWeight: "600",
                    }}
                  >
                    Implant
                  </div>

                  <div
                    style={{
                      border: "1px solid #000",
                      padding: "5px",
                      borderLeft: "0",
                      height: "285px",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "8px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="checkbox"
                        disabled={flag}
                        onChange={(event) => checkedBox(event, "56")}
                        value="56"
                        id="teethTC56"
                        name="txtService"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Implant Screw Driver</span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>
                        No. of Impression Replica
                      </span>

                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        {flag == true ? (
                          <input
                            type="text1"
                            maxlength="2"
                            id="text1"
                            disabled
                            value={txImplant1}
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              value: "Fc",
                              height: "21px",
                              display: txImplant1 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <input
                            type="text"
                            maxlength="2"
                            id="text1"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              value: "Fc",
                              height: "21px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>
                        No. of Impression Coping
                      </span>
                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        {flag == true ? (
                          <input
                            id="text22"
                            disabled
                            value={txImplant2}
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                              display: txImplant2 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <input
                            id="text2"
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>No. of Abutment</span>
                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        {flag == true ? (
                          <input
                            id="text33"
                            disabled
                            value={txImplant3}
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                              display: txImplant3 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <input
                            id="text3"
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>No. of Screw</span>
                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        {flag == true ? (
                          <input
                            id="text44"
                            disabled
                            value={txImplant4}
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                              display: txImplant4 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <input
                            id="text4"
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>
                        No. of Castable Abutment
                      </span>
                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        {flag == true ? (
                          <input
                            id="text55"
                            disabled
                            value={txImplant5}
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                              display: txImplant5 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <input
                            id="text5"
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>
                        No. of Temporary Abutment
                      </span>
                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        {flag == true ? (
                          <input
                            id="text66"
                            disabled
                            value={txImplant6}
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                              display: txImplant6 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <input
                            id="text6"
                            type="text"
                            maxlength="2"
                            style={{
                              width: "50px",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              height: "21px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                        lineHeight: "21px",
                      }}
                    >
                      <span style={{ float: "left" }}>Others</span>
                      <div
                        style={{
                          wordBreak: " breakWord",
                          float: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {flag == true ? (
                          <textarea
                            id="text77"
                            disabled
                            value={txImplant7}
                            cols="20"
                            maxlength="250"
                            style={{
                              width: "170px",
                              height: "80px",
                              resize: "none",
                              border: "1px solid #000",
                              padding: "2px 5px",
                              display: txImplant7 !== "" ? "block" : "none",
                            }}
                          />
                        ) : (
                          <textarea
                            id="text7"
                            cols="20"
                            maxlength="250"
                            style={{
                              width: "170px",
                              height: "80px",
                              resize: "none",
                              border: "1px solid #000",
                              padding: "2px 5px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "10px", boxSizing: "borderBox" }}>
                <div
                  style={{
                    boxSizing: "borderBox",
                    width: "720px",
                    float: "left",
                  }}
                >
                  <div
                    style={{
                      width: "240px",
                      height: "325px",
                      boxSizing: "borderBox",
                      paddingTop: "10px",
                      float: "left",
                      marginRight: "15px",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "6px 0px",
                        fontSize: "15px",
                        color: "#0091d0",
                        fontWeight: "600",
                      }}
                    >
                      Implant - Denture
                    </div>
                    <div
                      style={{
                        border: "1px solid #000",
                        width: "240px",
                        padding: "5px",
                        float: "left",
                        boxSizing: "borderBox",
                        height: "281px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "27")}
                          value="27"
                          disabled={flag}
                          id="teethTC27"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Light Cure Closed Special Tray</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "28")}
                          value="28"
                          disabled={flag}
                          id="teethTC28"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Light Cure Open Special Tray</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "29")}
                          value="29"
                          disabled={flag}
                          id="teethTC29"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Implant Special Bite</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "30")}
                          value="30"
                          disabled={flag}
                          id="teethTC30"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Implant Verification Jig</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "31")}
                          value="31"
                          disabled={flag}
                          id="teethTC31"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Hybrid Denture</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "32")}
                          value="32"
                          disabled={flag}
                          id="teethTC32"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Bar & Clip Over Denture</span>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "65")}
                          value="65"
                          disabled={flag}
                          id="teethTC65"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Ball Attachment Over Denture</span>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "33")}
                          value="33"
                          disabled={flag}
                          id="teethTC33"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>DC Hybrid (Ti) Denture Milled</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "34")}
                          value="34"
                          disabled={flag}
                          id="teethTC34"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>
                          DC Hybrid (Ti) (Frame Work for Ceramic) (Milled)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "200px",
                      height: "325px",
                      boxSizing: "borderBox",
                      paddingTop: "10px",
                      float: "left",
                      marginRight: "15px",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #000",
                        width: "200px",
                        padding: "5px",
                        float: "left",
                        boxSizing: "borderBox",
                        height: "310px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "35")}
                          value="35"
                          disabled={flag}
                          id="teethTC35"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Play Safe</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "36")}
                          value="36"
                          disabled={flag}
                          id="teethTC36"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Silencer SL</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "37")}
                          value="37"
                          disabled={flag}
                          id="teethTC37"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Bleaching Tray</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "38")}
                          value="38"
                          disabled={flag}
                          id="teethTC38"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Sports Guard Color</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "39")}
                          value="39"
                          disabled={flag}
                          id="teethTC39"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Ortho Retention Plate</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "40")}
                          value="40"
                          disabled={flag}
                          id="teethTC40"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Erkoloc Pro</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "41")}
                          value="41"
                          disabled={flag}
                          id="teethTC41"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Oral Screen</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "42")}
                          value="42"
                          disabled={flag}
                          id="teethTC42"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Anterior Bite Plane</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "43")}
                          value="43"
                          disabled={flag}
                          id="teethTC43"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Michigan Splint</span>
                      </div>
                      <div
                        style={{
                          width: "80%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                          margin: "0 auto",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "44")}
                          value="44"
                          disabled={flag}
                          id="teethTC44"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Upper</span>
                      </div>
                      <div
                        style={{
                          width: "80%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                          margin: "0 auto",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "45")}
                          value="45"
                          disabled={flag}
                          id="teethTC45"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Lower</span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "230px",
                      height: "325px",
                      boxSizing: "borderBox",
                      paddingTop: "10px",
                      float: "left",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #000",
                        width: "230px",
                        padding: "5px",
                        float: "left",
                        boxSizing: "borderBox",
                        height: "310px",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #000",
                          padding: "5px",
                          boxSizing: "borderBox",
                          margin: "0 -5px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: "8px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <span>Soft Splint</span>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: "2px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            onClick={(e) => changeColor("1", "W", "")}
                            id="teethT1W"
                            src={Img1mm}
                            style={{
                              width: "38px",
                              height: "auto",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                          {/*  1mm black begins */}

                          <img
                            onClick={(e) => changeColor("1", "B", "")}
                            id="teethT1B"
                            src={Img1mmb}
                            style={{
                              width: "38px",
                              height: "auto",
                              display: "none",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                          {/*  1mm black ends */}

                          <img
                            onClick={(e) => changeColor("105", "W", "")}
                            id="teethT105W"
                            src={Img15mm}
                            style={{
                              width: "38px",
                              height: "auto",
                              marginLeft: "15px",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                          {/*  1-5mm black begins */}
                          <img
                            onClick={(e) => changeColor("105", "B", "")}
                            id="teethT105B"
                            src={Img15mmb}
                            style={{
                              width: "38px",
                              height: "auto",
                              marginLeft: "15px",
                              cursor: "pointer",
                              float: "left",
                              display: "none",
                            }}
                          />
                          {/*  1-5mm black ends */}

                          <img
                            onClick={(e) => changeColor("2", "W", "")}
                            id="teethT2W"
                            src={Img2mm}
                            style={{
                              width: "38px",
                              height: "auto",
                              marginLeft: "15px",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                          {/* 2mm black begins */}
                          <img
                            onClick={(e) => changeColor("2", "B", "")}
                            id="teethT2B"
                            src={Img2mmb}
                            style={{
                              width: "38px",
                              height: "auto",
                              marginLeft: "15px",
                              cursor: "pointer",
                              float: "left",
                              display: "none",
                            }}
                          />
                          {/* 2mm black ends */}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            padding: "5px 0",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <span>Sports Guard Clear</span>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: "2px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            onClick={(e) => changeColor("3", "W", "")}
                            id="teethT3W"
                            src={Img3mm}
                            style={{
                              width: "38px",
                              height: "auto",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                          {/* 3mm black begins */}
                          <img
                            onClick={(e) => changeColor("3", "B", "")}
                            id="teethT3B"
                            src={Img3mmb}
                            style={{
                              width: "38px",
                              height: "auto",
                              cursor: "pointer",
                              float: "left",
                              display: "none",
                            }}
                          />

                          {/* 3mm black ends */}

                          <img
                            onClick={(e) => changeColor("4", "W", "")}
                            id="teethT4W"
                            src={Img4mm}
                            style={{
                              width: "38px",
                              height: "auto",
                              marginLeft: "15px",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                          {/*  4mm black begins */}
                          <img
                            onClick={(e) => changeColor("4", "B", "")}
                            id="teethT4B"
                            src={Img4mmb}
                            style={{
                              width: "38px",
                              height: "auto",
                              marginLeft: "15px",
                              cursor: "pointer",
                              float: "left",
                              display: "none",
                            }}
                          />
                          {/* 4mm black ends */}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "46")}
                          value="46"
                          disabled={flag}
                          id="teethTC46"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Full Acrylic/Heat Cure</span>
                      </div>
                      <div
                        style={{
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "47")}
                          value="47"
                          disabled={flag}
                          id="teethTC47"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Temporary/Self Cure</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "48")}
                          value="48"
                          disabled={flag}
                          id="teethTC48"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Full Anatomy Mockup with White Wax</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          value="49"
                          id="teethTC49"
                          disabled={flag}
                          onChange={(event) => checkedBox(event, "49")}
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Teeth Set With White Wax</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "50")}
                          value="50"
                          disabled={flag}
                          id="teethTC50"
                          name="txtService"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Teeth Set With Red Wax</span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "250px",
                      boxSizing: "borderBox",
                      paddingTop: "10px",
                      float: "left",
                      marginRight: "15px",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        border: "1px solid #000",
                        padding: "5px",
                        float: "left",
                        boxSizing: "borderBox",
                        height: "90px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "6px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <span style={{ float: "left", width: "110px" }}>
                            Frame Trial
                          </span>

                          <input
                            type="checkbox"
                            onChange={(event) => checkedBox(event, "51")}
                            value="51"
                            disabled={flag}
                            id="teethTC51"
                            name="txtService"
                            style={{ float: "left", margin: "0px 5px 0 0px" }}
                          />

                          <span
                            style={{
                              float: "left",
                              width: "100px",
                              fontSize: "11.5px",
                            }}
                          >
                            {flag == true ? (
                              <Datepicker
                                autoComplete="off"
                                required
                                className="Datepicker pa2 LborderdentalPrint   "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                id="fromdate"
                                disabled
                                value={
                                  state.labTestFrameDate === ""
                                    ? ""
                                    : outputTrialDate
                                }
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                              />
                            ) : (
                              <Datepicker
                                required
                                className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                id="fromdate"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={frameTrialDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                                onChange={(date) => {
                                  frameDateChange(date);
                                }}
                                autoComplete="off"
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "6px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <span style={{ float: "left", width: "110px" }}>
                            Teeth Setting Trial
                          </span>

                          <input
                            type="checkbox"
                            onChange={(event) => checkedBox(event, "80")}
                            name="txtService"
                            id="teethTC80"
                            disabled={flag}
                            style={{ float: "left", margin: "0px 5px 0 0px" }}
                          />

                          <div
                            style={{
                              float: "left",
                              width: "100px",
                              fontSize: "11.5px",
                            }}
                          >
                            {flag == true ? (
                              <Datepicker
                                autoComplete="off"
                                required
                                className="Datepicker pa2 LborderdentalPrint  "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                id="fromdate"
                                disabled
                                value={
                                  state.labTestTeethSettingDate === ""
                                    ? ""
                                    : teethSettingTrialDate
                                }
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                              />
                            ) : (
                              <Datepicker
                                autoComplete="off"
                                required
                                className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                id="fromdate"
                                peekNextMonth
                                // type="number"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={teethSettingTrial}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={handleDateChangeRaw}
                                onChange={(date) => {
                                  teethSettingDateChange(date);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "6px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <span style={{ float: "left", width: "110px" }}>
                            C.P.D Design
                          </span>

                          <input
                            type="checkbox"
                            onChange={(event) => checkedBox(event, "53")}
                            value="53"
                            disabled={flag}
                            id="teethTC53"
                            name="txtService"
                            style={{ margin: " 0px 5px 0 0px", float: "left" }}
                          />
                          <span style={{ float: "left", marginRight: "15px" }}>
                            Yes
                          </span>

                          <input
                            type="checkbox"
                            onChange={(event) => checkedBox(event, "67")}
                            value="67"
                            disabled={flag}
                            id="teethTC67"
                            name="txtService"
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span style={{ float: "left", marginRight: "15px" }}>
                            No
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "210px",
                      boxSizing: "borderBox",
                      paddingTop: "10px",
                      float: "left",
                      marginRight: "15px",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        width: "210px",
                        border: "1px solid #000",
                        padding: "5px",
                        float: "left",
                        boxSizing: "borderBox",
                        height: "90px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                          color: "red",
                        }}
                      >
                        <span>Shade</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event) => checkedBox(event, "54")}
                          value="54"
                          disabled={flag}
                          id="teethTC54"
                          name="txtService"
                          style={{ float: "left", margin: "0px 5px 0 0px" }}
                        />

                        <span style={{ float: "left" }}>
                          Digital Photo/Mail
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "210px",
                      boxSizing: "borderBox",
                      paddingTop: "10px",
                      float: "left",
                      boxSizing: "borderBox",
                    }}
                  >
                    <div
                      style={{
                        width: "210px",
                        border: "1px solid #000",
                        padding: "5px",
                        float: "left",
                        boxSizing: "borderBox",
                        height: "90px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "'hidden'",
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            display: "inline-block",
                            width: "145px",
                          }}
                        >
                          Opposing Teeth to be Restored Later
                        </span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "8px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ float: "left" }}>
                          <input
                            type="checkbox"
                            disabled={flag}
                            onChange={(event) => checkedBox(event, "55")}
                            value="55"
                            id="teethTC55"
                            name="txtService"
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span style={{ float: "left" }}>Yes</span>
                        </div>
                        <div style={{ float: "right" }}>
                          <input
                            type="checkbox"
                            name="txtService"
                            valu="68"
                            id="teethTC68"
                            disabled={flag}
                            onChange={(event) => checkedBox(event, "68")}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span style={{ float: "left" }}>No</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    boxSizing: "borderBox",
                    width: "260px",
                    float: "left",
                    height: "430px",
                    padding: "30px",
                  }}
                >
                  <div
                    style={{
                      boxSizing: "borderBox",
                      width: "200px",
                      height: "385px",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "178px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      RIGHT
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        top: "179px",
                        left: "168px",
                        fontWeight: "600",
                      }}
                    >
                      LEFT
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        top: "38px",
                        left: "76px",
                        textAlign: "center",
                        fontWeight: "600",
                      }}
                    >
                      UPPER
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        top: "317px",
                        left: "75px",
                        textAlign: "center",
                        fontWeight: "600",
                      }}
                    >
                      LOWER
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "136px",
                        left: "-17px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "8px",
                          fontWeight: "600",
                        }}
                      >
                        18
                      </span>

                      <img
                        src={T18}
                        onClick={(e) => changeColor("18", "W", "longTeeth")}
                        id="teethT18W"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "block",
                        }}
                      />
                      <img
                        src={T18b}
                        onClick={(e) => changeColor("18", "B", "longTeeth")}
                        id="teethT18B"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />

                      {/* <img
													src={T18b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
														display: 'block',
													}}
												/> */}
                    </span>

                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "201px",
                        left: "-21px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "8px",
                          fontWeight: "600",
                        }}
                      >
                        48
                      </span>
                      <img
                        src={T48}
                        onClick={(e) => changeColor("48", "W", "longTeeth")}
                        id="teethT48W"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("48", "B", "longTeeth")}
                        id="teethT48B"
                        src={T48b}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "106px",
                        left: " -13px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "8px",
                          fontWeight: "600",
                        }}
                      >
                        17
                      </span>
                      <img
                        src={T17}
                        onClick={(e) => changeColor("17", "W", "longTeeth")}
                        id="teethT17W"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "block",
                        }}
                      />
                      <img
                        src={T17b}
                        onClick={(e) => changeColor("17", "B", "longTeeth")}
                        id="teethT17B"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>

                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "233px",
                        left: "-15px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "8px",
                          fontWeight: "600",
                        }}
                      >
                        47
                      </span>
                      <img
                        src={T47}
                        onClick={(e) => changeColor("47", "W", "longTeeth")}
                        id="teethT47W"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "block",
                        }}
                      />
                      <img
                        src={T47b}
                        onClick={(e) => changeColor("47", "B", "longTeeth")}
                        id="teethT47B"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "79px",
                        left: "-8px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "7px",
                          fontWeight: "600",
                        }}
                      >
                        16
                      </span>

                      <img
                        src={T16}
                        onClick={(e) => changeColor("16", "W", "longTeeth")}
                        id="teethT16W"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "block",
                        }}
                      />
                      <img
                        src={T16b}
                        onClick={(e) => changeColor("16", "B", "longTeeth")}
                        id="teethT16B"
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                      {/* <img
													src={T16b}
													style={{
														width: '35px',
														height: '35px',
														cursor: 'pointer',
														marginLeft: '18px',
													}}
												/> */}
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "262px",
                        left: "-10px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "8px",
                          fontWeight: "600",
                        }}
                      >
                        46
                      </span>

                      <img
                        onClick={(e) => changeColor("46", "W", "longTeeth")}
                        id="teethT46W"
                        src={T46}
                        style={{
                          width: "32px",
                          height: "32px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("46", "B", "longTeeth")}
                        src={T46b}
                        id="teethT46B"
                        style={{
                          width: "32px",
                          height: "32px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "55px",
                        left: "1px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "5px",
                          fontWeight: "600",
                        }}
                      >
                        15
                      </span>

                      <img
                        src={T15}
                        onClick={(e) => changeColor("15", "W", "longTeeth")}
                        id="teethT15W"
                        style={{
                          width: "32px",
                          height: "29px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "block",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("15", "B", "longTeeth")}
                        id="teethT15B"
                        src={T15b}
                        style={{
                          width: "32px",
                          height: "29px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "289px",
                        left: "3px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "11px",
                          fontWeight: "600",
                        }}
                      >
                        45
                      </span>

                      <img
                        onClick={(e) => changeColor("45", "W", "longTeeth")}
                        id="teethT45W"
                        src={T45}
                        style={{
                          width: "27px",
                          height: "27px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("45", "B", "longTeeth")}
                        id="teethT45B"
                        src={T45b}
                        style={{
                          width: "27px",
                          height: "27px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "31px",
                        left: "10px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "2px",
                          fontWeight: "600",
                        }}
                      >
                        14
                      </span>

                      <img
                        onClick={(e) => changeColor("14", "W", "longTeeth")}
                        id="teethT14W"
                        src={T14}
                        style={{
                          width: "33px",
                          height: "33px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("14", "B", "longTeeth")}
                        id="teethT14B"
                        src={T14b}
                        style={{
                          width: "33px",
                          height: "33px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "308px",
                        left: "10px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          paddingTop: "15px",
                          fontWeight: "600",
                        }}
                      >
                        44
                      </span>

                      <img
                        onClick={(e) => changeColor("44", "W", "longTeeth")}
                        id="teethT44W"
                        src={T44}
                        style={{
                          width: "26px",
                          height: "26px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        src={T44b}
                        onClick={(e) => changeColor("44", "B", "longTeeth")}
                        id="teethT44B"
                        style={{
                          width: "26px",
                          height: "26px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "14px",
                        left: "23px",
                        marginLeft: "21px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          left: "-16px",
                          top: "-5px",
                        }}
                      >
                        13
                      </span>

                      <img
                        src={T13}
                        onClick={(e) => changeColor("13", "W", "longTeeth")}
                        id="teethT13W"
                        style={{
                          width: "24px",
                          height: "26px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src={T13b}
                        onClick={(e) => changeColor("13", "B", "longTeeth")}
                        id="teethT13B"
                        style={{
                          width: "24px",
                          height: "26px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "327px",
                        left: "20px",
                        marginLeft: "19px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "22px",
                          left: "-10px",
                        }}
                      >
                        43
                      </span>

                      <img
                        src={T43}
                        onClick={(e) => changeColor("43", "W", "longTeeth")}
                        id="teethT43W"
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("43", "B", "longTeeth")}
                        id="teethT43B"
                        src={T43b}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "4px",
                        left: "40px",
                        marginLeft: "21px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          left: "-10px",
                          top: "-15px",
                        }}
                      >
                        12
                      </span>

                      <img
                        onClick={(e) => changeColor("12", "W", "longTeeth")}
                        id="teethT12W"
                        src={T12}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("12", "B", "longTeeth")}
                        id="teethT12B"
                        src={T12b}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "338px",
                        left: "37px",
                        marginLeft: "18px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "28px",
                          left: "-3px",
                          fontWeight: "600",
                        }}
                      >
                        42
                      </span>

                      <img
                        onClick={(e) => changeColor("42", "W", "longTeeth")}
                        id="teethT42W"
                        src={T42}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("42", "B", "longTeeth")}
                        id="teethT42B"
                        src={T42b}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "-1px",
                        left: "59px",
                        marginLeft: "21px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "7px",
                          top: "-15px",
                          fontWeight: "600",
                        }}
                      >
                        11
                      </span>

                      <img
                        onClick={(e) => changeColor("11", "W", "longTeeth")}
                        id="teethT11W"
                        src={T11}
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src={T11b}
                        onClick={(e) => changeColor("11", "B", "longTeeth")}
                        id="teethT11B"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "340px",
                        left: "56px",
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "30px",
                          left: "3px",
                          fontWeight: "600",
                        }}
                      >
                        41
                      </span>

                      <img
                        onClick={(e) => changeColor("41", "W", "longTeeth")}
                        id="teethT41W"
                        src={T41}
                        style={{
                          width: "23px",
                          height: "27px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src={T41b}
                        onClick={(e) => changeColor("41", "B", "longTeeth")}
                        id="teethT41B"
                        style={{
                          width: "23px",
                          height: "27px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "-2px",
                        left: "79px",
                        marginLeft: "21px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "-15px",
                          left: "10px",
                        }}
                      >
                        21
                      </span>

                      <img
                        src={T21}
                        onClick={(e) => changeColor("21", "W", "longTeeth")}
                        id="teethT21W"
                        style={{
                          width: "25px",
                          height: "29px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src={T21b}
                        onClick={(e) => changeColor("21", "B", "longTeeth")}
                        id="teethT21B"
                        style={{
                          width: "25px",
                          height: "29px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "340px",
                        left: "75px",
                        marginLeft: "19px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "30px",
                          left: "10px",
                          fontWeight: "600",
                        }}
                      >
                        31
                      </span>

                      <img
                        onClick={(e) => changeColor("31", "W", "longTeeth")}
                        id="teethT31W"
                        src={T31}
                        style={{
                          width: "24px",
                          height: "26px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("31", "B", "longTeeth")}
                        id="teethT31B"
                        src={T31b}
                        style={{
                          width: "24px",
                          height: "26px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "4px",
                        left: "99px",
                        marginLeft: "19px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "-13px",
                          left: "20px",
                        }}
                      >
                        22
                      </span>

                      <img
                        onClick={(e) => changeColor("22", "W", "longTeeth")}
                        id="teethT22W"
                        src={T22}
                        style={{
                          width: "25px",
                          height: "28px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("22", "B", "longTeeth")}
                        id="teethT22B"
                        src={T22b}
                        style={{
                          width: "25px",
                          height: "28px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "335px",
                        left: "94px",
                        marginLeft: "18px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "30px",
                          left: "15px",
                        }}
                      >
                        32
                      </span>

                      <img
                        onClick={(e) => changeColor("32", "W", "longTeeth")}
                        id="teethT32W"
                        src={T32}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("32", "B", "longTeeth")}
                        id="teethT32B"
                        src={T32b}
                        style={{
                          width: "24px",
                          height: "25px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "18px",
                        left: "115px",
                        marginLeft: "18px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "-8px",
                          left: "29px",
                        }}
                      >
                        23
                      </span>

                      <img
                        onClick={(e) => changeColor("23", "W", "longTeeth")}
                        id="teethT23W"
                        src={T23}
                        style={{
                          width: "26px",
                          height: "26px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("23", "B", "longTeeth")}
                        id="teethT23B"
                        src={T23b}
                        style={{
                          width: "26px",
                          height: "26px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "329px",
                        left: "110px",
                        marginLeft: "22px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "25px",
                          left: "20px",
                        }}
                      >
                        33
                      </span>

                      <img
                        onClick={(e) => changeColor("33", "W", "longTeeth")}
                        id="teethT33W"
                        src={T33}
                        style={{
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("33", "B", "longTeeth")}
                        id="teethT33B"
                        src={T33b}
                        style={{
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "37px",
                        left: "126px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "2px",
                          left: "50px",
                        }}
                      >
                        24
                      </span>

                      <img
                        onClick={(e) => changeColor("24", "W", "longTeeth")}
                        id="teethT24W"
                        src={T24}
                        style={{
                          width: "29px",
                          height: "29px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        src={T24b}
                        onClick={(e) => changeColor("24", "B", "longTeeth")}
                        id="teethT24B"
                        style={{
                          width: "29px",
                          height: "29px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "308px",
                        left: "122px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "21px",
                          left: "47px",
                        }}
                      >
                        34
                      </span>

                      <img
                        src={T34}
                        onClick={(e) => changeColor("34", "W", "longTeeth")}
                        id="teethT34W"
                        style={{
                          width: "27px",
                          height: "27px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        src={T34b}
                        onClick={(e) => changeColor("34", "B", "longTeeth")}
                        id="teethT34B"
                        style={{
                          width: "27px",
                          height: "27px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "60px",
                        left: "135px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "5px",
                          left: "50px",
                        }}
                      >
                        25
                      </span>

                      <img
                        src={T25}
                        onClick={(e) => changeColor("25", "W", "longTeeth")}
                        id="teethT25W"
                        style={{
                          width: "29px",
                          height: "29px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        src={T25b}
                        onClick={(e) => changeColor("25", "B", "longTeeth")}
                        id="teethT25B"
                        style={{
                          width: "29px",
                          height: "29px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "287px",
                        left: "135px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "16px",
                          left: "50px",
                        }}
                      >
                        35
                      </span>

                      <img
                        onClick={(e) => changeColor("35", "W", "longTeeth")}
                        id="teethT35W"
                        src={T35}
                        style={{
                          width: "27px",
                          height: "27px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        src={T35b}
                        onClick={(e) => changeColor("35", "B", "longTeeth")}
                        id="teethT35B"
                        style={{
                          width: "27px",
                          height: "27px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "85px",
                        left: "140px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "6px",
                          left: "54px",
                        }}
                      >
                        26
                      </span>

                      <img
                        onClick={(e) => changeColor("26", "W", "longTeeth")}
                        id="teethT26W"
                        src={T26}
                        style={{
                          width: "31px",
                          height: "31px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("26", "B", "longTeeth")}
                        id="teethT26B"
                        src={T26b}
                        style={{
                          width: "31px",
                          height: "31px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "260px",
                        left: "135px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "13px",
                          left: "55px",
                        }}
                      >
                        36
                      </span>

                      <img
                        onClick={(e) => changeColor("36", "W", "longTeeth")}
                        id="teethT36W"
                        src={T36}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("36", "B", "longTeeth")}
                        id="teethT36B"
                        src={T36b}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "111px",
                        left: "145px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "6px",
                          left: "54px",
                        }}
                      >
                        27
                      </span>

                      <img
                        onClick={(e) => changeColor("27", "W", "longTeeth")}
                        id="teethT27W"
                        src={T27}
                        style={{
                          width: "31px",
                          height: "31px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        src={T27b}
                        onClick={(e) => changeColor("27", "B", "longTeeth")}
                        id="teethT27B"
                        style={{
                          width: "31px",
                          height: "31px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "232px",
                        left: "142px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "17px",
                          left: "55px",
                        }}
                      >
                        37
                      </span>

                      <img
                        onClick={(e) => changeColor("37", "W", "longTeeth")}
                        id="teethT37W"
                        src={T37}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("37", "B", "longTeeth")}
                        id="teethT37B"
                        src={T37b}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "140px",
                        left: "148px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "7px",
                          left: "55px",
                        }}
                      >
                        28
                      </span>

                      <img
                        onClick={(e) => changeColor("28", "W", "longTeeth")}
                        id="teethT28W"
                        src={T28}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          color: "red",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("28", "B", "longTeeth")}
                        id="teethT28B"
                        src={T28b}
                        style={{
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "199px",
                        left: "146px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "600",
                          top: "20px",
                          left: "57px",
                        }}
                      >
                        38
                      </span>

                      <img
                        onClick={(e) => changeColor("38", "W", "longTeeth")}
                        id="teethT38W"
                        src={T38}
                        style={{
                          width: "40px",
                          height: "40px",
                          cursor: "pointer",
                          marginLeft: "18px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("38", "B", "longTeeth")}
                        id="teethT38B"
                        src={T38b}
                        style={{
                          width: "40px",
                          height: "40px",
                          cursor: "pointer",
                          marginLeft: "18px",
                          display: "none",
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="formBottom" style={{ paddingTop: "15px" }}>
            <div
              style={{
                clear: "both",
                overflow: "hidden",
                lineHeight: "21px",
                marginBottom: "15px",
              }}
            >
              <span style={{ float: "left", boxSizing: "borderBox" }}>
                Notes :{" "}
              </span>

              <div
                style={{
                  wordBreak: "breakWord",
                  boxSizing: "borderBox",
                  width: "95%",
                  paddingLeft: "10px",
                  float: "left",
                }}
              >
                {flag == true ? (
                  <textarea
                    type="text"
                    disabled
                    value={notes}
                    maxlength="250"
                    id="notes"
                    name="notes"
                    style={{
                      width: "100%",
                      height: "80px",
                      resize: "none",
                      border: "1px solid #000",
                      padding: "2px 5px",
                    }}
                  />
                ) : (
                  <textarea
                    type="text"
                    maxlength="250"
                    id="notes"
                    name="notes"
                    style={{
                      width: "100%",
                      height: "80px",
                      resize: "none",
                      border: "1px solid #000",
                      padding: "2px 5px",
                    }}
                  />
                )}
              </div>
            </div>

            <div
              style={{ clear: "both", overflow: "hidden", marginTop: "5px" }}
            >
              <div style={{ float: "left", width: "650px" }}>
                <span style={{ float: "left" }}>
                  In case of any clarification. Please contact Dr.{" "}
                </span>
                <div>
                  {doctorData.firstName} {doctorData.lastName}
                </div>
              </div>
              <div style={{ float: "left", width: "330px" }}>
                <span style={{ float: "left" }}>Contact No : &nbsp;</span>
                <div> {doctorMobile}</div>
              </div>
            </div>

            <div
              style={{ clear: "both", overflow: "hidden", paddingTop: "10px" }}
            >
              <span style={{ float: "left" }}>Collection Agent : </span>
              <div
                style={{
                  wordBreak: "break-word",
                  boxSizing: "border-box",
                  width: "170px",
                  paddingTop: "5px",
                  marginLeft: "15px",
                  float: "left",
                }}
              >
                {flag == true ? (
                  <input
                    type="text"
                    id="marketingEx"
                    disabled
                    value={marketingEx}
                    name="marketingEx"
                    maxlength="20"
                    style={{
                      border: "1px solid #000",
                      padding: " 4px 5px",
                      width: "100%",
                      clear: "both",
                      float: "none",
                      boxSizing: "border-box",
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    id="marketingEx"
                    name="marketingEx"
                    maxlength="20"
                    style={{
                      border: "1px solid #000",
                      padding: " 4px 5px",
                      width: "100%",
                      clear: "both",
                      float: "none",
                      boxSizing: "border-box",
                    }}
                  />
                )}
              </div>
            </div>
            <span style={{ float: "right", paddingTop: "5px" }}>
              Name & Signature of Doctor/ Clinic Staff
            </span>
          </div>

          <div
            class="formBottomBtn"
            style={{
              paddingTop: "40px",
              textAlign: "right",
              paddingBottom: "15px",
              display: "inline-flex",
              width: "100%",
              flexFlow: "row",
              justifyContent: "flex-end",
            }}
          >
            <span
              onClick={printPage}
              id="print"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginRight: "10px",
                padding: "7px 20px",
                fontSize: "14px",
                background: "#2d1152",
                border: "0",
                color: "#fff",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Print
            </span>

            <span
              id="savePrint"
              disabled={saveflag}
              onClick={(e) => {
                saveFunction(e);
                printPage(e);
              }}
              class="saveClr buttonsmall"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginRight: "10px",
                padding: "7px 20px",
                fontSize: "14px",
                background: "#dbdbdb",
                border: "0",
                color: "#393939",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Save&amp;Print
            </span>

            <span
              id="send"
              disabled={saveflag}
              onClick={(e) => {
                saveFunction(e);
                listForm(e);
              }}
              class="saveClr buttonsmall"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginRight: "10px",
                padding: "7px 20px",
                fontSize: "14px",
                background: "#dbdbdb",
                border: "0",
                color: "#393939",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Send
            </span>

            <a
              href="/Patients/labOrderDentalAdd"
              id="cancel"
              class="saveClr buttonsmall"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginRight: "10px",
                padding: "7px 20px",
                fontSize: "14px",
                background: "#dbdbdb",
                border: "0",
                color: "#393939",
                fontWeight: "600",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
      {/* </form> */}
    </body>
  );
}

export default LabOrderDentalPrint;
