import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import doctorHome from '../images/media/doctorHome.jpg';
import Clinic from '../images/icons/clinic.png';
import hearts from '../images/icons/hearts.png';
import { callingAPI } from '../config';

function HealthInfoDetails(props) {
	const [healthInfoDetails, setHealthInfoDetails] = useState([])
	const [commentsArray, setCommentsArray] = useState([])
	const [commentedValue, setCommentedValue] = useState('');
	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH;

	var getS3healthInfo =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_HEALTHINFO_PROFILE_S3_IMAGE_PATH;
	useEffect(() => {
		let postData = {
			"userId": window.sessionStorage.getItem("userId"),
			"healthInfoId": props && props.location && props.location.state ? props.location.state.data : ""
		}
		callingAPI("commonUserFunctions/healthInfoDetails", postData)
			.then((data) => {
				if (data.data.success === "1") {
					setHealthInfoDetails(data.data.result.healthInfoResult)
					setCommentsArray(data.data.result.commentsArray)
				}
			})
			.catch()
	}, [])
	const comments = (event) => {
		setCommentedValue(event.target.value)
	}
	const commentOnClick = () => {
		const postdata = {
			"comments": commentedValue,
			"healthInfoId": props.location.state.data,
			"userId": window.sessionStorage.getItem("userId"),
		}
		callingAPI('commonUserFunctions/addComments', postdata)
			.then((data) => {
				if (data.data.success === "1") {
					// setHealthInfoDetails(data.data.result.healthInfoResult)
					// setCommentsArray(data.data.result.commentsArray)
					alert("Submitted Successfully")
					window.location.href = "/Dashboard/healthInfoDetails"
				}
			})
			.catch()
	}
	return (
		<div>
			<div className="coverWraper innerCoverWraper">
				<Header />
				<div className="contentWraper contentWraperScroll">
					<div className="container">
						<div class="row">
							<div class="col-md-12">
								<div class="doc blogDetailPage">
									{healthInfoDetails.map((index, i) => (
										<div class="row">
											<div class="col-md-12 col-lg-12" />
											<div class="clearfix" />
											<div class="col-md-12">
												<div class="backPage">
													<a href="/Dashboard/welcome">Back to Previous Page</a>
												</div>
											</div>
											<div class="col-md-12 col-lg-12">
												<div class="blogSampleHead">
													<div class="blogVistAvtarBlock">
														<a href="javascript:void(0);" style={{ cursor: 'default' }}>
															{index.doctorImage != "" &&
																<img class="avtarImg139496" src={getS3folder + "/" + index.doctorImage} />}

														</a>
													</div>
													<div class="blogVisitAvatarInfo">
														<span class="avtarname">
															<a href="javascript:void(0);" style={{ cursor: 'default' }}>
																{index.healthIfoTitle}
															</a>
														</span>
														{/* <span
														title="Following"
														class="smlFollow"
														style={{ cursor: 'default' }}
													>
														+ Following
													</span> */}
														<span class="datetime">
															<a href="javascript:void(0);" style={{ cursor: 'default' }}>
																{index.postedDate}
															</a>
														</span>
													</div>
													<div class="blogSampleBtn" />
												</div>
												<div class="blogDetailHead">
													{index.healthIfoTitle}
												</div>
												<div class="clearfix" />
												<div class="blogDetailpara">
													<p />
													<p>
														{index.shortDescription}
													</p>
													<p>
														<div dangerouslySetInnerHTML={{ __html: index.description }}></div>
													</p>
													{index.profileImage != "" &&
														<img src={getS3healthInfo + "/" + index.profileImage} />}

												</div>
												<div class="batchIcons2">
													<span class="luvIcon2">
														<div class="iconTwo" style={{ display: 'inline-block' }}>
															<img title="Liked" style={{ cursor: 'default' }} src={hearts} />
														</div>
														<sup style={{ fontSize: '13px', color: "#333" }}>&nbsp;{index.userLiked}</sup>
													</span>
												</div>
												<div class="defaulttags">
													<a href="/Dashboard/welcome">{index.healthIfoTitle}</a>
												</div>
												<div class="divider" />
												<div class="clearfix" />
												<div>
													<div class="commentBox">
														<div class="commentheadBlock">Comments</div>
														<textarea
															placeholder="Type your comments here"
															name="commentContent"
															cols="40"
															rows="5"
															tabindex="5"
															maxlength="250"
															class="commentTextarea"
															id="commentContent"
															value={commentedValue}
															onChange={comments} style={{ resize: "none" }}
														/>
														<input
															type="submit"
															class="commentSubmit"
															tabindex="15"
															id="comment"
															value="Submit"
															name="submitcomment"
															onClick={commentOnClick}
														/>
													</div>
												</div>
												<div class="clearfix" />
												{commentsArray.map((item, i) => (
													<div class="commentBox">
														<div class="commentheadBlock">Recent Comments</div>
														<div
															class="commentheadBlock"
															style={{
																fontSize: '0.89em',
																textDecoration: 'none',
																color: '#616161',
															}}
														>
															{commentsArray.length} Comment
														</div>
														<div class="commentHeadingBlock">{item.comments}</div>
													</div>
												))}</div>
											<div class="col-md-2 col-lg-2" />
										</div>
									))}</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default HealthInfoDetails;
