import React from 'react';
import Logo from '../images/media/logo.png';
import Priscription from '../images/media/prescription.png';
function PriscriptionPrint() {
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: '500px',
					height: '595px',
					marginLeft: '0px',
					marginRight: '0px',
					marginTop: '0px',
					marginBottom: '0px',
				}}
			>
				<table style={{ width: '100%', marginTop: '0px' }} border="0">
					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr height="80px">
												<td colspan="3" valign="middle" align="center">
													<div style={{ width: '100%', float: 'none' }}>
														<p
															style={{
																fontWeight: '400',
																fontSize: '16px',
																clear: 'both',
																width: '100%',
																margin: '0px 0 3px 0px',
															}}
														>
															<span
																style={{
																	marginTop: '-5px',
																	display: 'inline - block',
																	width: '100%',
																}}
															>
																<img
																	src={Logo}
																	style={{
																		display: 'block',
																		width: 'auto',
																		maxHeight: '65px',
																		margin: 'auto',
																	}}
																	align="middle"
																/>
															</span>
														</p>
													</div>
													<div class="practicePrintName" style={{ width: '100%' }}>
														<p
															style={{
																fontWeight: '400',
																fontSize: '12px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
																textAlign: 'right',
															}}
														>
															Mishras Clinic
														</p>
														<p
															style={{
																fontWeight: '400',
																fontSize: '12px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
																textAlign: 'right',
															}}
														>
															DGO
														</p>
														<p
															style={{
																fontWeight: '400',
																fontSize: '12px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
																textAlign: 'right',
															}}
														>
															Endodontic
														</p>
													</div>
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>
											<tr>
												<td
													width="50%"
													colspan="2"
													align="left"
													style={{ fontSize: '12px', wordBreak: 'break-all' }}
												>
													Dr Nagarjun Mishra, MBBS, DM
												</td>
												<td
													width="50%"
													align="right"
													style={{ fontSize: '12px', wordBreak: 'break-all' }}
												/>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>

											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Name : ABHAY SAJU.S
												</td>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													MR No : 22
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Age:
												</td>

												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Mobile No : 1212121212
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Sex : Male
												</td>
												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Date : 17,Nov 2021{' '}
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr>
												<td align="left" style={{ width: '100%' }}>
													Rx
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						<tr
							style={{
								display: 'inline-block',
								width: '20%',
								verticalAlign: 'top',
								textAlign: 'left',
								border: '1px solid #ccc',
								marginRight: '10px',
								marginBottom: '10px',
								marginLeft: '8px',
							}}
						>
							<td style={{ verticalAlign: 'top!important' }}>
								<img id="prescImgId0" src={Priscription} width="80" height="80" class="img-fluid" />
							</td>
						</tr>
					</tbody>

					<tfoot>
						<tr>
							<td>
								<div class="printOutFooterSec">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr style={{ display: 'table-row' }}>
												<td>
													<div
														align="justify"
														class="practiceFootBtm"
														style={{ lineHeight: '15px' }}
													>
														IDA test clinic
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<hr color="#142536" style={{ margin: '2px 0' }} />
												</td>
											</tr>

											<tr style={{ display: 'table-row' }}>
												<td style={{ paddingTop: '0px', fontSize: '11px' }}>
													<div style={{ float: 'left' }} />
													<div style={{ textAlign: 'Center' }}>
														IDA Test clinic, .<br />
														testclinic@ida.com
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
									}}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default PriscriptionPrint;
