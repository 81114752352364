import React, { useEffect, useState } from 'react';
import BillingHeader from '../billingLogin/layout/header';
import Footer from './layout/footer';
import { Route } from 'react-router-dom';
import BillingLeft from '../billingLogin/billingLeft';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { callingAPI } from '../config';
import labPrescription from '../images/icons/lab/labPrescription.png';
import Printer_icon from '../images/icons/printer_icon.png';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { useNavigate } from 'react-router-dom';

function ProcedurePayment() {
	const navigate = useNavigate();

	const [procedureListing, setProcedureListing] = useState([]);
	const [searchType, setSearchType] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [totalCount, setTotalCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	const [isEnabled, setIsEnabled] = useState(true);
	const [procedureids, setProcedureids] = useState([]);
	const [dataFlag, setDataFlag] = useState(false);
	useEffect(() => {

		let postData = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"cmbAppPatient": "",
			"txtPatientName": "",
			"fromDate": "",
			"toDate": "",
			"max": 10,
			"offset": 0
		};
		callingAPI('clinicalBackground/procedurePayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setProcedureListing(response.data.result.procedureData);
					setTotalCount(response.data.result.totalCount[0].totalCount);
					// setTotalSellingPrice(response.data.result.totalpricevalue);
					// setTotalPurchaseUnitPrice(response.data.result.totalpurchaseunitprice);
					// setTotalPurchasePrice(response.data.result.totalpurchaseprice);
					// setTotalCount(response.data.result.totalCount);
					setDataFlag(true);
				}
				else {
					setProcedureListing([]);
					setTotalCount(0)
					setDataFlag(true);
				}
			})
			.catch();
	}, []);
	const searchTypeOnChange = (event) => {
		setSearchValue('');
		if (event.target.value == 0) {
			setIsEnabled(true);
			setSearchType(event.target.value);
			setSearchValue('');
		} else {
			setSearchType(event.target.value);
			setIsEnabled(false);
		}
	};
	const searchValueFunction = (event) => {
		setSearchValue(event.target.value);
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchClickFunction = () => {

		if (startDate && endDate) {
			if (startDate > endDate) {
				alert('End date must be greater than start date');
				document.getElementById("endDate").focus();
				return false;
			}
		}
		if (searchType !== "" && searchValue === "") {
			var t = document.getElementById("selectFilterName");
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById("searchText").focus();
			alert("Enter" + " " + selectedText);
			return false;
		}
		// if (searchType !== 0 && searchValue.split('').length === 0) {
		// 	if (searchType === '1') {
		// 		alert('Enter Medicine Name');
		// 		return false;
		// 	}
		// }
		// // if(dropDown !== 0 && dropDown === 1){
		// // 	alert('Egfhjkl');
		// // 	return false;
		// // }
		// // else {
		// // 	alert("podaaaaaaaaaaa")
		// // 	return false;
		// // }

		// 	if (dropDown === '1'&& document.getElementById("fromdate").value==="" ) {
		// 		alert("Enter stock update from date")
		// 		return false;
		// 	}else if(dropDown === '1' && document.getElementById("todate").value===""){
		// 		alert("Enter stock update to date")
		// 		return false;
		// 	}

		// 	if (dropDown === '2' && document.getElementById("fromdate").value==="" ) {
		// 		alert("Enter expiry from date")
		// 		return false;
		// 	}else if(dropDown === '2' && document.getElementById("todate").value===""){
		// 		alert("Enter expiry to date")
		// 		return false;
		// 	}

		setActivePage(1);
		setbooliansearchValue(searchValue);
		setboolianfilterName(searchType);
		setboolianfromDate(startDate);
		setbooliantoDate(endDate);
		let postData = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"cmbAppPatient": searchType,
			"txtPatientName": searchValue,
			"fromDate": startDate ? formatDate(startDate) : '',
			"toDate": endDate ? formatDate(endDate) : '',
			"max": 10,
			"offset": 0
		};
		callingAPI('clinicalBackground/procedurePayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setProcedureListing(response.data.result.procedureData);
					setTotalCount(response.data.result.totalCount[0].totalCount);
					// setTotalSellingPrice(response.data.result.totalpricevalue);
					// setTotalPurchaseUnitPrice(response.data.result.totalpurchaseunitprice);
					// setTotalPurchasePrice(response.data.result.totalpurchaseprice);
					// setTotalCount(response.data.result.totalCount);
					// setDataFlag(true);
				}
			})
			.catch();
	};
	const procedureInvoiceAdd = (data) => {
		navigate('/patients/patientInvoiceAdd', { state: data })
	}
	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) { setSearchType("") }
		if (!booliansearchValue) { setSearchValue("") }
		if (!boolianfromDate) { setStartDate("") }
		if (!booliantoDate) { setEndDate("") }
		let postData = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"cmbAppPatient": boolianfilterName,
			"txtPatientName": booliansearchValue,
			"fromDate": boolianfromDate ? formatDate(startDate) : '',
			"toDate": booliantoDate ? formatDate(endDate) : '',
			"max": max,
			"offset": offset
		};
		callingAPI('clinicalBackground/procedurePayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setProcedureListing(response.data.result.procedureData);
					setTotalCount(response.data.result.totalCount[0].totalCount);
					// setTotalSellingPrice(response.data.result.totalpricevalue);
					// setTotalPurchaseUnitPrice(response.data.result.totalpurchaseunitprice);
					// setTotalPurchasePrice(response.data.result.totalpurchaseprice);
					// setTotalCount(response.data.result.totalCount);
					// setDataFlag(true);
				}
			})
			.catch();
	};
	const FromDateClear = () => {
		setStartDate("")
	};

	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setEndDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const procedureIdsss = (ids) => {
		setProcedureids(ids)
	}
	return (
		// <div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec labTestBillWrapper">
		// 	<BillingHeader />
		// 	<div className="contentWraper contentWraperScroll">
		// 		<BillingLeft />
		//         <div className="settingsRight">
		<div className="dashboardCover pharmacyManufactureWrap">
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Procedure Payment</div>
				</div>

			</div>
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField"
									id="selectFilterName"
									name="selectName"
									value={searchType}
									onChange={searchTypeOnChange}
								>

									<option value="">Select</option>
									<option value="Patient Name">Patient Name</option>
									{window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<option value="MR Number">MR Number</option>)}
									{window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<option value="PR Number">PR Number</option>)}

								</select>
							</div>
							{searchType == "" ?
								<div className="col-md-2 dashboardSearchList">
									<input type="text" className="inputField" placeholder='Enter Value'
										value={searchValue}
										disabled={isEnabled}
										// onChange={searchValueFunction}
										style={{ background: "transparent" }} />
								</div>
								: <div className="col-md-2 dashboardSearchList">
									<input type="text" id="searchText" className="inputField" placeholder='Enter Value'
										value={searchValue}
										disabled={isEnabled}
										onChange={searchValueFunction} style={{ background: "white" }} />
								</div>}
							<div className="col-md-2 dashboardSearchList">
								<Datepicker
									autocomplete="off"
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									required
									selected={startDate}
									onChange={(date) => setStartDate(date)}
									closeCalendar="true"
									className="Datepicker pa2 inputField"
									placeholderText="From Date"
									calendarClassName="rasta-stripes"
									dateFormat="dd-MM-yyyy"
									calendarContainer={MyContainer}
									onChangeRaw={handleDateChangeRaw}

								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<Datepicker
									autocomplete="off"
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									required
									selected={endDate}
									id="endDate"
									onChange={(date) => setEndDate(date)}
									closeCalendar="true"
									className="Datepicker pa2 inputField"
									placeholderText="To Date"
									calendarClassName="rasta-stripes"
									dateFormat="dd-MM-yyyy"
									calendarContainer={MyContainerTo}
									onChangeRaw={handleDateChangeRaw}

								/>
							</div>
							<div className="col-md-2 dashboardSearchList"  >
								<span className="dashButtonBorder labButton"
									onClick={searchClickFunction}
									style={{ padding: "8px 20px", height: "35px" }}>Search</span>
							</div>
						</div>
					</div>
					{/*  */}
					{dataFlag == false ? (
						<Loader />
					) :
						procedureListing.length > 0 ? (
							<>
								{procedureListing.map((data, i) => (
									<div class="treatmentPlanList">
										<div class="treatmentPlanDate"> {data.visit_date} </div>
										<div class="treatmentTable planActive">
											<table>
												<thead>
													<tr>
														<th class="col-md-3"><div class="dentalProName">Patient Name</div></th>
														{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
															(<th class="col-md-2"><div class="dentalProName">MR No</div></th>) :
															(<th class="col-md-2"><div class="dentalProName">PR No</div></th>)
														}
														<th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
														<th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
													</tr>
												</thead>
												<tbody>
													{data.procedurePaymentData.map((item, i) => (
														<tr class="groupBrdr">
															<td class="col-md-5">
																<div class="dentalProName paddingFive">

																	<span class="elipsis">

																		{item.displayName}

																	</span>
																</div>
															</td>
															{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																(<td class="col-md-4"><div class="dentalProName paddingFive">{item.mrNo} </div></td>) :
																(<td class="col-md-4"><div class="dentalProName paddingFive">{item.prNo} </div></td>)
															}

															<td class="col-md-2">
																<div class="dentalProName ">
																	<span class="viewDetailLink">
																		<nav onClick={() => procedureInvoiceAdd({ procedureViewIds: item.visitId, memberId: item.memberId, hospitalId: item.hospitalId, isFlag: "Procedure" })}
																		> View Details</nav>
																		{/* <a href=""> View Details</a> */}
																	</span>
																</div>
															</td>

														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								))}
							</>
						) : (
							<p>
								<NoData />
							</p>
						)}
					{totalCount > 10 && (
						<div className="paginationSection">
							<Pagination
								// itemsCountPerPage={2}
								// totalItemsCount={5}
								hideNavigation
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={totalCount}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={handlePagination}
							/>
						</div>
					)}
				</div>
			</div>
		</div>



	);
}

export default ProcedurePayment;
