import Editicon from "../images/icons/edit-bg.png"
import Close from "../images/icons/close.png"
import LabHeader from './layout/header';
import Footer from './layout/footer';
import Tick from "../images/icons/active.png"
import LabLeft from './labLeft'
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import NoData from '../Common/noDataFound';
import List from '../images/icons/list.png';
import { callingAPI } from '../config';
function LabTestParametersLab() {
	const [patameters, setParameters] = useState([])
	const [parameterCount, setParameterCount] = useState('')
	const [popUpDoctorList, setPopUpDoactorList] = useState(false);
	const [getheadingId, setHeadingId] = useState('');
	const [getunitId, setUnitId] = useState('');
	const [gethidparameter, sethidparameter] = useState('');
	const [gettxtheading, settxtheading] = useState('');
	const [gettxtnormalmax, settxtnormalmax] = useState('');
	const [getcmbCategory1, setcmbCategory1] = useState("1");
	const [getunit, setunit] = useState('');
	const [gettxtParameter, settxtParameter] = useState('');
	const [gettxtParameterSearch, settxtParameterSearch] = useState('');
	const [load, setLoad] = useState(false);
	const [gettxtnormal, settxtnormal] = useState([]);
	const [addValue, setAddValue] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [showEditDiv, setShowEditDiv] = useState(false);
	const [filterName, setFilterInput] = useState("0");
	const [getOffset, setOffset] = useState('');
	const [dispaly, setDisplay] = useState(false);
	const [medicineUnits, setMedicineUnits] = useState([]);
	// const [medUnit, setMedUnit] = useState('');
	var phoneno = /^[0-9.]+$/;
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		}
		setOffset(offset)
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtParameter": "",
			offset: offset,
			max: max,
			testDetailsId: window.sessionStorage.getItem('test_id'),
			// "testDetailsId":window.sessionStorage.getItem('test_id')
		};
		callingAPI('parameterList/parameterList', PostData).then((data) => {
			if (data.data.success === '1') {
				// alert("succes.....")
				setParameters(data.data.result.parameters);
				setParameterCount(data.data.result.parameterCount);
			} else {
				listing([]);
				// setErrors(data.data.errorMessage)
				//alert('error');
			}
		});
	};
	const handleKeypress = (event) => {
		//it triggers by pressing the enter key
		//
		if (event.key === 'Enter') {
			//    alert("ssssssssssssssssss")
			cmbSearch();
		}
	};
	const cmbSearch = () => {
		const PostData = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtParameter": document.getElementById('gettxtParameterSearch').value,
			offset: '0',
			max: 10,
			// "testDetailsId":window.sessionStorage.getItem('test_id')
			testDetailsId: window.sessionStorage.getItem('test_id')
		}
		callingAPI('parameterList/parameterList', PostData).then((data) => {
			if (data.data.success === '1') {
				// alert("succes.....")
				setParameters(data.data.result.parameters);
				setParameterCount(data.data.result.parameterCount);
				setActivePage(1)
			} else {
				setParameters([]);
				// setErrors(data.data.errorMessage)
				//alert('error');
			}
		});
	};
	useEffect(() => {
		listing();
		getMedicineUnitsTypes();
	}, []);
	const listing = () => {
		setLoad(true)
		setFilterInput("0")
		//	document.getElementById('popup1').style.display = 'none';
		const apiJson = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtParameter": "",
			"offset": getOffset,
			"max": 10,
			// "testDetailsId":window.sessionStorage.getItem('test_id')
			testDetailsId: window.sessionStorage.getItem('test_id')
		};
		callingAPI('parameterList/parameterList', apiJson).then((data) => {
			if (data.data.success === '1') {
				// alert("succes.....")
				setParameters(data.data.result.parameters);
				setParameterCount(data.data.result.parameterCount);
			} else {
				// setErrors(data.data.errorMessage)
				//alert('error');
			}
		});
	};
	const closePopUp = () => {
		setPopUpDoactorList(false);
		settxtParameter('');
	};
	const category1PopOpen = () => {
		setShowEditDiv(true);
		setPopUpDoactorList(true);
		sethidparameter('');
		setHeadingId('')
		setUnitId('')
		settxtheading('');
		settxtParameter('');
		setunit('');
		settxtnormal('');
		setcmbCategory1('');
		settxtnormalmax('');
	};
	const editLab = (parameterId, heading, parameter, unitName, normalvalue, normaltype, max, unitId, headingId) => {
		// alert(normaltype);
		var phoneno = /^[0-9]+$/;
		var phoneno1 = /^[0-9a-zA-Z]+$/;
		setShowEditDiv(false);
		setPopUpDoactorList(true);
		// document.getElementById('addParameter').style.display = 'block';
		sethidparameter(parameterId);
		setHeadingId(headingId)
		setUnitId(unitId)
		settxtheading(heading);
		settxtParameter(parameter);
		setunit(unitName);
		settxtnormal(normalvalue);
		setcmbCategory1(normaltype === ">" ? "3" : normaltype === "<" ? "2" : "1");
		settxtnormalmax(max);
		if (phoneno.test(normalvalue)) {
			// event.preventDefault();
			// alert("Only numbers accepted")
			// alert("11111111111")
			setDisplay(true)
		}
		else if (phoneno1.test(normalvalue)) {
			// alert("2222222222222")
			setDisplay(false)
		}
	};
	const activate = (parameterId, status) => {
		//  alert("dddddd")
		var x = 0;
		if (status == 1) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		//alert(x);
		if (x == 1) {
			const postData = {
				parameterId: parameterId.toString(),
				currentStatus: status == 1 ? 1 : 0,
			};
			callingAPI('activateDeActivateParameter/activateDeActivateParameter', postData).then((data) => {
				if (data.data.success === '1') {
					listing();
				}
			});
		}
	};
	const labTestSave = () => {
		setLoad(false)
		var flag = true;
		//	serviceProviderTestDetailsTestName/serviceProviderTestBuyPrice/serviceProviderTestDescription
		if (gettxtParameter === '') {
			setLoad(true)
			document.getElementById('gettxtParameter').focus();
			alert('Parameter Required');
			flag = false;
		}
		if (dispaly === true && getcmbCategory1 === "1") {
			var a = parseFloat(document.getElementById("gettxtnormal").value)
			var b = parseFloat(document.getElementById("gettxtnormalmax").value)
			if (a >= b) {
				setLoad(true)
				// document.getElementById('gettxtParameter').focus();
				alert('Normal value max should not be less than Normal value Min');
				flag = false;
			}
		}
		if (flag === true) {
			const apiJson = {
				userId: window.sessionStorage.getItem("userId"),
				roleName: window.sessionStorage.getItem("role"),
				// "testDetailsId": window.sessionStorage.getItem('test_id'),
				testDetailsId: window.sessionStorage.getItem('test_id'),
				"txtHeading": gettxtheading,
				"txtParameter": gettxtParameter,
				"txtUnit": getunit,
				"txtNormalValue": gettxtnormal,
				"cmbCategory": getcmbCategory1,
				"txtNormalMax": gettxtnormalmax
			};
			callingAPI('saveParameter/saveParameter', apiJson).then((data) => {
				if (data.data.success === '1') {
					// alert(data.data.successMessage);
					alert("Parameter Added Successfully")
					setPopUpDoactorList(false);
					listing();
				} else {
					// setErrors(data.data.errorMessage)
					alert(data.data.errorMessage);
					setPopUpDoactorList(true);
					listing();
				}
			});
		}
	};
	const labTestSave1 = () => {
		setLoad(false)
		var flag = true;
		//	serviceProviderTestDetailsTestName/serviceProviderTestBuyPrice/serviceProviderTestDescription
		if (gettxtParameter === '') {
			setLoad(true)
			document.getElementById('gettxtParameter').focus();
			alert('Parameter Required');
			flag = false;
		}
		if (dispaly === true) {
			var a = parseFloat(document.getElementById("gettxtnormal").value)
			var b = parseFloat(document.getElementById("gettxtnormalmax").value)
			if (a >= b) {
				setLoad(true)
				// document.getElementById('gettxtParameter').focus();
				alert('Normal value max should not be less than Normal value Min');
				flag = false;
			}
			else if (document.getElementById("getcmbCategory1").value === "1" && document.getElementById("gettxtnormalmax").value === "") {
				setLoad(true)
				document.getElementById('getcmbCategory1').focus();
				alert('Please Enter Normal Value Max');
				flag = false;
			}
		}
		if (flag === true) {
			const apiJson = {
				userId: window.sessionStorage.getItem("userId"),
				roleName: window.sessionStorage.getItem("role"),
				// "testDetailsId": window.sessionStorage.getItem('test_id'),
				testDetailsId: window.sessionStorage.getItem('test_id'),
				"txtHeading": gettxtheading,
				"txtParameter": gettxtParameter,
				"txtUnit": getunit,
				"txtNormalValue": gettxtnormal,
				"cmbCategory": getcmbCategory1,
				"txtNormalMax": gettxtnormalmax,
				"hidParameterId": gethidparameter,
				"hidUnitId": getunitId,
				"hidHeadingId": getheadingId
			};
			callingAPI('updateParameter/updateParameter', apiJson).then((data) => {
				if (data.data.success === '1') {
					// alert(data.data.successMessage);
					alert("Parameter Updated Successfully")
					setPopUpDoactorList(false);
					listing();
				} else {
					// setErrors(data.data.errorMessage)
					alert(data.data.errorMessage);
					setPopUpDoactorList(false);
					listing();
				}
			});
		}
	};
	const changeText = (value) => {
		setFilterInput(value);
		if (value === "0") {
			settxtParameterSearch('')
		}
		// if (value === '') {
		// 	setTxtInput('');
		// 	document.getElementById('txtInput').disabled = true;
		// } else {
		// 	document.getElementById('txtInput').disabled = false;
		// 	document.getElementById('txtInput').focus();
		// }
	};
	const onChangeMedUnit = (event) => {
		setunit(event.target.value);
	};
	const getMedicineUnitsTypes = () => {
		callingAPI('inventoryStockUpdate/getMedicineDetails')
			.then((data) => {
				setMedicineUnits(data.data.result.medicineUnit);
				// setMedicineTypes(data.data.result.medicineType);
			})
			.catch();
	};
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec addLabTest">
			<LabHeader />
			<div className="contentWraper contentWraperScroll">
				<LabLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className="row">
							<div className="col-md-10">
								{/* <div className="dashboardTitle">Lab Test Parameters</div> */}
								<div className="breadcrumbDoc">
									<div className="breadcrumbDocSect" style={{ borderBottom: 'none' }}>
										<ul>
											<li>
												<a href="/labLogin/addLabTestDetails" class="blueLink">
													Lab Test Details
												</a>
											</li>{' '}
											<div class="arrow-right" />
											<li>Parameter </li>
										</ul>
										<p className="parameterTestName">
											Parameters for Test Name :{' '}
											<span style={{ color: '#22a2fd', fontWeight: '600' }}>
												{window.sessionStorage.getItem('testName')}
											</span>
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<a href="#addParameter"> <span className="dashButtonBorder AddBtn" title="add" onClick={category1PopOpen} >
									Add +
								</span></a>
							</div>
						</div>
						<div className="dashboardCover labTestDtls">
							<div className="dashboardContent">
								{/*  */}
								{parameterCount > 10 ? (
									<div className="paginationSection">
										<Pagination
											// hideNavigation
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={parameterCount}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={handlePageChange}
										/>
									</div>
								) : (
									''
								)}
								{/*  */}
								<div className="reportWrap">
									<div className="dashboardSearch">
										<div className="row">
											<div className="col-md-2 dashboardSearchList">
												<select className="inputField" id="cmbSelect" onChange={(e) => changeText(e.target.value)}>
													<option value="0">Search In</option>
													<option value="1">Parameter</option>
												</select>
											</div>
											<div className="col-md-2 dashboardSearchList">
												<input
													type="text"
													className="formInputField inputField"
													id="gettxtParameter"
													name="gettxtParameter"
													value={gettxtParameter}
													placeholder="Enter Parameter"
													onKeyPress={handleKeypress}
													onChange={(e) =>
														settxtParameter(e.target.value)}
													disabled={filterName === "0" ? true : false}
													style={{ background: filterName === "0" ? "transparent" : "white" }}
												/>
											</div>
											<div className="col-md-2 dashboardSearchList">
												<span className="dashButtonBorder" title="Search" onClick={cmbSearch}  >
													Search
												</span>
											</div>
											<div className="col-md-6 dashboardSearchList">
												<div className="searchCount">  <b>Total : {parameterCount ? parameterCount : "0"}</b>  </div>
											</div>
										</div>
									</div>
									{patameters.length > 0 ? (
										<div className="dashboardtable">
											<table>
												<thead>
													<tr>
														<th>Heading</th>
														<th>Parameter</th>
														<th>Unit</th>
														<th>Type</th>
														<th>Min</th>
														<th> Max</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{patameters.map((data, i) => (
														<tr>
															<td>{data.heading}</td>
															<td>{data.parameter}</td>
															<td>{data.unitName}</td>
															<td>{data.normaltype}</td>
															<td>{data.normalvalue}</td>
															<td>{data.max}</td>
															<td>
																<a href="#addParameter">
																	<img
																		src={Editicon}
																		title="Edit"
																		onClick={(e) =>
																			editLab(
																				data.parameterId,
																				data.heading,
																				data.parameter,
																				data.unitName,
																				data.normalvalue,
																				data.normaltype,
																				data.max,
																				data.unitId,
																				data.headingId
																			)}
																	/>
																</a>
																{/* <a><img src={Tick} /></a> */}
																{data.isActive == 1 ? (
																	<img
																		src={Tick}
																		title="Click to Deactivate"
																		className="mr-2  conf-cursorpointer"
																		alt=""
																		onClick={() => {
																			activate(data.parameterId, 1);
																		}}
																		style={{ cursor: 'pointer' }}
																	/>
																) : (
																	<img
																		src={Close}
																		alt=""
																		title="Click to Activate"
																		className="mr-2  conf-cursorpointer"
																		onClick={() => {
																			activate(data.parameterId, 0);
																		}}
																	/>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									) : (
										<NoData />
									)}
								</div>
								{/*  */}
								{parameterCount > 10 ? (
									<div className="paginationSection">
										<Pagination
											// hideNavigation
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={parameterCount}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={handlePageChange}
										/>
									</div>
								) : (
									''
								)}
								{/*  */}
							</div>
							<div>
							</div>
						</div>
						{/* PRINNT 1st MODAL BEGINS HERE */}
						{popUpDoctorList == true && (
							<div id="addParameter" class="modalDialog">
								<div className='AddLabtestMdl'>
									<a href="#close" title="Close" class="close exportClose" onClick={closePopUp}>X</a>
									<div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
										<div class="addDataHead" style={{ marginBottom: "10px" }}>
											<span style={{ display: showEditDiv ? '' : 'none' }} className='labTitle'>Add Parameter</span>
											<span style={{ display: !showEditDiv ? '' : 'none' }} className='labTitle'>Edit Parameter</span>
										</div>
										{/* --- */}
										<div className="popBack" id="popup1">
											<div className="popOverlay" />
											<div className="row">
												<div className="customizationPop">
													<div className="customizationPopContentSec">
														<input type="hidden" id="txtHiddenCategoryId" />
														<div className="row">
															<div className="col-md-12 col-12">
																<div className="row">
																	<div className="col-md-4">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<label className="labelField">
																					Heading
																				</label>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-8">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<div className="formInputSection">
																					<input
																						type="text"
																						className="formInputField inputField"
																						id="gettxtheading"
																						name="gettxtheading"
																						value={gettxtheading}
																						placeholder="Enter Heading"
																						onChange={(e) => settxtheading(e.target.value)}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12 col-12">
																<div className="row">
																	<div className="col-md-4">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<label className="labelField">
																					Parameter <sup />
																				</label>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-8">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<div className="formInputSection">
																					<input
																						type="text"
																						className="formInputField inputField"
																						id="gettxtParameter"
																						name="gettxtParameter"
																						value={gettxtParameter}
																						placeholder="Enter Parameter"
																						onChange={(e) =>
																							settxtParameter(e.target.value)}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12 col-12">
																<div className="row">
																	<div className="col-md-4">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<label className="labelField">
																					Unit
																				</label>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-8">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<div className="formInputSection">
																					{/* <input
																		type="text"
																		className="formInputField inputField"
																		id="getunit"
																		name="getunit"
																		value={getunit}
																		placeholder="Enter Unit"
																		onChange={(e) => setunit(e.target.value)}
																	/> */}
																					<select
																						autocomplete="off"
																						class="fulWidthSelect formInputField inputField"
																						name="cmbMedicineUnit"
																						id="cmbMedicineUnit"
																						value={getunit}
																						onChange={onChangeMedUnit}
																					>
																						<option value="0">Select Unit</option>
																						{medicineUnits.map((item, idix) => (
																							<option value={item.medicineUnitName}>{item.medicineUnitName}</option>
																						))}
																					</select>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12 col-12">
																<div className="row">
																	<div className="col-md-4">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<label className="labelField">
																					Normal Value
																				</label>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-8">
																		<div className="form-group">
																			<div className="addStoreSectionList">
																				<div className="formInputSection">
																					<input
																						type="text"
																						className="formInputField inputField"
																						id="gettxtnormal"
																						name="gettxtnormal"
																						value={gettxtnormal}
																						placeholder="Enter Normal Value"
																						// onChange={(e) =>categoryTypeChange}
																						onChange={(e) => settxtnormal(e.target.value)}
																						onKeyUp={(event) => {
																							if (phoneno.test(event.target.value)) {
																								setDisplay(true)
																							}
																							else {
																								setDisplay(false)
																								setcmbCategory1("1");
																								settxtnormalmax('');
																							}
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																	{dispaly === true ? (
																		<div className="addStoreSectionList">
																			<div className="row">
																				<div className="col-md-4">
																					<label className="labelField">
																						Normal Value Type
																					</label>
																				</div>
																				<div className="col-md-8">
																					<div className="formInputSection">
																						{/* <input type="text" className="formInputField" id="getcmbCategory1" name="getcmbCategory1" value={getcmbCategory1} placeholder="Link Name" onChange={(e) => setcmbCategory1(e.target.value)}/> */}
																						<select
																							class="mdmField inputField"
																							id="getcmbCategory1"
																							name="getcmbCategory1"
																							value={getcmbCategory1}
																							placeholder="Select Normal Value Type"
																							onChange={(e) =>
																								setcmbCategory1(e.target.value)}
																						>
																							<option value="1">
																								Range{' '}
																							</option>
																							<option value="2">&lt;</option>
																							<option value="3">&gt;</option>
																						</select>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : ("")}
																	{dispaly === true ? (
																		<div>
																			{getcmbCategory1 === "1" ? (
																				<div className="addStoreSectionList">
																					<div className="row">
																						<div className="col-md-4">
																							<label className="labelField">
																								Normal Value Max
																							</label>
																						</div>
																						<div className="col-md-8">
																							<div className="formInputSection">
																								<input
																									type="text"
																									className="formInputField inputField"
																									id="gettxtnormalmax"
																									name="gettxtnormalmax"
																									value={gettxtnormalmax}
																									placeholder="Enter Normal Value Max"
																									onChange={(e) =>
																										settxtnormalmax(e.target.value)}
																								/>
																							</div>
																						</div>
																					</div>
																				</div>
																			) : ('')}
																		</div>
																	) : ('')}
																	<div />
																</div>
															</div>
															<div class="summaryBtnDiv">
																<button
																	className="practiceGreenButton"
																	id="addCategory1Save"
																	title="Save"
																	style={{ display: showEditDiv ? '' : 'none' }}
																	onClick={() => labTestSave()}
																>
																	Save
																</button>
																<button
																	className="practiceGreenButton"
																	id="addCategory1Save"
																	title="Save"
																	style={{ display: !showEditDiv ? '' : 'none' }}
																	onClick={() => labTestSave1()}
																>
																	Update
																</button>
																<span
																	className="customisBtn"
																	id="addLinkUpdate"
																	style={{ display: 'none' }}
																>
																	Update
																</span>
																<span
																	onClick={closePopUp}
																	className="customisBtn practiceGreenButton"
																	style={{ borderColor: '#b22222' }}
																	title="Cancel"
																	id="addCategory1Cancel"
																>
																	Cancel
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{/* PRINNT 1st MODAL ENDS HERE */}
					</div>
				</div>
			</div>
			{/* {parameterCount > 10 ? ( */}
			{/* <div className="paginationSection">
				<Pagination
												// hideNavigation
												activePage={activePage}
												itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
												totalItemsCount={parameterCount}
												pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
												onChange={handlePageChange}
											/>
                </div> */}
			{/* // ) 
				// : (
				// 	"rrrrrrrrrrrrrr"
				// )} */}
			<Footer />
		</div>
	)
}
export default LabTestParametersLab;
