import React from 'react';
import Editbg from '../images/icons/edit-bg.png';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
function invoicePaymentList() {
	return (
		<div>
			<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
				<div id="divPatientContent" class="sideMenuContent">
					<div class="treatmentPlanCover" id="treatmentPlanListDiv">
						<div class="treatmentPlanHead" style={{ border: 'none' }}>
							<span class="treatmentPlanName">INVOICE PAYMENT</span>
							<span
								class="treatmentPlanBtn pl_blue_btn"
								style={{ background: 'transparent', padding: '0' }}
							>
								<img src={Youtube} />
							</span>
							<span
								class="treatmentPlanBtn pl_blue_btn"
								style={{ background: 'transparent', padding: '0' }}
							>
								<img src={Light} style={{ width: '25px' }} />
							</span>

							<span class="treatmentPlanBtn pl_blue_btn" style={{ background: '#5bb75b' }}>
								<a href="">Pay Now</a>
							</span>
							<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
								<a href="">Cancel</a>
							</span>
						</div>

						<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
							<div class="pl_treat treatmentTable planActive">
								<table style={{ border: 'none' }}>
									<thead>
										<tr>
											<th class="col-md-2">
												<div class="dentalProName">INVOICE NO</div>
											</th>

											<th class="col-md-2">
												<div class="dentalProName">TOWARDS</div>
											</th>
											<th class="col-md-3  ">
												<div class="dentalProNote" style={{ textAlign: 'right' }}>
													TOTAL AMOUNT (INR)
												</div>
											</th>
											<th class="col-md-2  ">
												<div class="dentalProNote" style={{ textAlign: 'right' }}>
													PAID (INR)
												</div>
											</th>
											<th class="col-md-3  ">
												<div class="dentalProNote" style={{ textAlign: 'right' }}>
													BALANCE (INR)
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<span className="close_row">x</span>
										<br />
										<tr class="groupBrdr">
											<td class="col-md-2">
												<div class="dentalProName">
													<a href="" style={{ color: '#00abff', textDecoration: 'none' }}>
														INV39
													</a>
												</div>
											</td>
											<td class="col-md-2">
												<span>Procedure - SCALING (001)</span>
											</td>

											<td class="col-md-2 ">
												<div
													class="dentalProDis"
													style={{
														textAlign: 'right',
														color: '008000',
													}}
												>
													0.00
												</div>
											</td>

											<td class="col-md-3">
												<span className="totalinr" style={{ color: '008000' }}>
													200.00
												</span>
											</td>
											<td class="col-md-3">
												<span className="totalinr" style={{ color: '008000' }}>
													200.00
												</span>
											</td>
										</tr>
									</tbody>
								</table>
								{/*  */}
								{/*  ====================  */}
								<div
									class="paymentSec paymentSecDiv paymentPaymntSec_PTour"
									style={{ display: 'block' }}
								>
									<div class="paymentSecTop paymentSecTop_PTour">
										<span class="ContentLabel">
											TOTAL PAYABLE: INR<span style={{ color: '#f00' }}>500.00</span>
										</span>
									</div>
									<br />

									<div class="paymentSecContent paymentSecContent_PTour">
										<div class="paymentSecLst">
											<label>PAY NOW : </label>
											<span>
												<input
													type="text"
													value="0.00"
													autocomplete="off"
													maxlength="10"
													style={{ height: '30px' }}
												/>
												<select
													style={{
														height: '30px',
														width: 'auto',
														border: '1px solid #9a9a9a',
													}}
												>
													<option value="1">Cash</option>
													<option value="2">Cheque</option>
													<option value="3">Card</option>
													<option value="4">UPI</option>
												</select>
											</span>
										</div>
									</div>

									<div class="paymentSecBtm paymentSecBtm_PTour">
										<span class="ContentBtmLabel">
											DUE AFTER PAYMENT : INR <span style={{ color: '#f00' }}>0.00</span>
										</span>
										<span class="ContentBtmLabel txtGreenColor" style={{ display: 'none' }}>
											ADVANCE AFTER PAYMENT : INR
											<span>0.00</span>
										</span>
									</div>
								</div>
								{/*  */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*  */}
		</div>
	);
}

export default invoicePaymentList;
