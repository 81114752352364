import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import HomeleftMenu from '../Dashboard/homeleftMenu';
import Memberdetails from './memberdetails';
import Educationaldetails from './educationaldetails';
import Language from './language';
import Hospitaldetails from './hospitaldetails';
import Professionaldetails from './professionaldetails';
import Experiencedetails from './experiencedetails';
import Achievements from './achievements';
import Doctorimage from './doctorimage';
import Specialitydetails from './specialitydetails';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-tabs/style/react-tabs.css';
import { callingAPI } from '../config';
function Profile(props) {
	const [doctorMemberDetails, setDoctorMemberDetails] = useState([]);
	const [doctorProfessionalDetails, setDoctorProfessionalDetails] = useState([]);
	const [doctorEducationDetails, setDoctorEducationDetails] = useState([]);
	const [doctorAchievements, setDoctorAchievements] = useState([]);
	const [doctorHospitalDetails, setDoctorHospitalDetails] = useState([]);
	const [doctorLanguages, setDoctorLanguages] = useState([]);
	const [doctorSpecialityDetails, setDoctorSpecialityDetails] = useState([]);
	const [doctorSpecialityDetails1, setDoctorSpecialityDetails1] = useState([]);
	const [doctorImage, setDoctorImage] = useState([]);
	const [doctorExpertise, setDoctorExpertise] = useState([]);
	const [displayErrMsg, setDisplayErrMsg] = useState('');
	const [isData, setIsData] = useState(false);
	const errFunction = (errmsg) => {
		setDisplayErrMsg(errmsg);
	};
	const getDoctorDetails = () => {
		const postData = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('doctorProfile/getDoctorDetails', postData)
			.then((res) => {
				setDoctorMemberDetails(res.data.result.memberDetails.length>0 ? res.data.result.memberDetails[0] : []);
				setDoctorProfessionalDetails(
					res.data.result.professionalDetails ? res.data.result.professionalDetails[0] : []
				);
				setDoctorEducationDetails(res.data.result.educationDetails);
				setDoctorAchievements(res.data.result.achievements);
				setDoctorHospitalDetails(res.data.result.hospitalDetails);
				setDoctorLanguages(res.data.result.languages);

				if (res.data.result.specialityDetails !== undefined) {
					var speciality = [];
					for (var i = 0; i < res.data.result.specialityDetails.length; i++) {
						if (res.data.result.specialityDetails[i].speciality) {
							speciality.push(res.data.result.specialityDetails[i].speciality);

						}
					}
				}
				if (speciality !== undefined) {

					setDoctorSpecialityDetails(speciality.join(','));
				}
				setDoctorSpecialityDetails1(res.data.result.specialityDetails)

				window.sessionStorage.setItem('setDoctorImage', res.data.result.doctorImage.length > 0? res.data.result.doctorImage[0].doctorImageName : 'download.jpg')
				setDoctorImage(res.data.result.doctorImage ? res.data.result.doctorImage[0] : 'download.jpg');
				setDoctorExpertise(res.data.result.expertise);
				setIsData(true)
			})
			.catch();
	};
	useEffect(() => {
		getDoctorDetails();

	}, []);
	const scrollTopDiv1 = () => {
		$('html, body').animate({
			scrollTop: $("#MemberDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv2 = () => {
		$('html, body').animate({
			scrollTop: $("#EducationalDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv3 = () => {
		$('html, body').animate({
			scrollTop: $("#LanguagesKnownDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv4 = () => {
		$('html, body').animate({
			scrollTop: $("#HospitalDetailsDiv").offset().top
		}, 500);
	};

	const scrollTopDiv5 = () => {
		$('html, body').animate({
			scrollTop: $("#ProfessionalDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv6 = () => {
		$('html, body').animate({
			scrollTop: $("#SpecialityDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv7 = () => {
		$('html, body').animate({
			scrollTop: $("#ExperienceDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv8 = () => {
		$('html, body').animate({
			scrollTop: $("#AchievementsDetailsDiv").offset().top
		}, 500);
	};
	const scrollTopDiv9 = () => {
		$('html, body').animate({
			scrollTop: $("#DoctorImageDetailsDiv").offset().top
		}, 500);
	};
	return (
		<div>
			<div className="coverWraper innerCoverWraper">
				<Header />
				<div className="contentWraper contentWraperScroll">
					<div className="container">
						<div className="row">
							<div className="col-md-4">
								<HomeleftMenu doctorImg={doctorImage} doctorName={doctorMemberDetails} specialityDetails={doctorSpecialityDetails1} />
							</div>
							<div className="col-md-8">
								<div className="landingBodyWrap dashboardProfile">
									<p
										style={{
											display: 'flex',
											justifyContent: 'center',
											color: 'red',
											padding: '0px',
											margin: '0px',
										}}
									>
										{displayErrMsg}
									</p>
									<div class="landingHead">Personal Details</div>
									<div className="landingProfile">
										<Accordion allowZeroExpanded className='profileAccordion'>
											<AccordionItem onClick={scrollTopDiv1} id='MemberDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Member Details{' '}
														<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} />
															<FontAwesomeIcon icon={faMinus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													{isData === true &&
														<Memberdetails
															memberDetails={doctorMemberDetails}
															errFunctionCallback={errFunction}
														/>
													}

												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv2} id='EducationalDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Educational Details<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Educationaldetails
														educationDetails={doctorEducationDetails}
														errFunctionCallback={errFunction}
													/>
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv3} id='LanguagesKnownDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Languages Known<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Language
														languages={doctorLanguages}
														errFunctionCallback={errFunction}
													/>
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv4} id='HospitalDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Your Hospital Details<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Hospitaldetails hospitalDetails={doctorHospitalDetails} />
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv5} id="ProfessionalDetailsDiv">
												<AccordionItemHeading>
													<AccordionItemButton>
														Professional Details<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Professionaldetails
														professionalDetails={doctorProfessionalDetails}
														errFunctionCallback={errFunction}
													/>
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv6} id='SpecialityDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Speciality Details<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Specialitydetails
														memberDetails={doctorMemberDetails}
														specialityDetails={doctorSpecialityDetails1}
													/>
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv7} id='ExperienceDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Experience Details<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Experiencedetails
														expertise={doctorExpertise}
														errFunctionCallback={errFunction}
													/>
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv8} id='AchievementsDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Achievements<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Achievements
														achievements={doctorAchievements}
														errFunctionCallback={errFunction}
													/>
												</AccordionItemPanel>
											</AccordionItem>
											<AccordionItem onClick={scrollTopDiv9} id='DoctorImageDetailsDiv'>
												<AccordionItemHeading>
													<AccordionItemButton>
														Doctor Image<span
															style={{
																float: 'right',
																color: '#393939',
															}}
														>
															{/* <FontAwesomeIcon icon={faPlus} /> */}
														</span>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<Doctorimage doctorImage={doctorImage} />
												</AccordionItemPanel>
											</AccordionItem>
										</Accordion>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default Profile;
