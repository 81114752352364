import React from 'react';
// import { useSelector } from "react-redux"
import Header from '../layout/header';
import Footer from '../layout/footer';
import HomeleftMenu from '../Dashboard/homeleftMenu';
import Loader from '../Common/loader';
import Youtube from '../images/icons/youtube.png';
// import Clinic from '../images/icons/clinic.png';
// import hearts from '../images/icons/hearts.png';
// import { Link } from 'react-router-dom';
// import { callingAPI } from '../config';
function Help() {

	return (
		<div>
			<div className="coverWraper innerCoverWraper DashboardWelcome helpWrapper">
				<Header />
				<div className="contentWraper contentWraperScroll">
					{/* {load === false ? <Loader /> : ''} */}
					<div className="container">
						<div className="row">
							<div class="FAQBodyWrap">
								<div class="landingHead">Practice Management FAQ</div>
								<div class="FAQWrap">
									<div class="faqQuestionDiv">
										<h4>I want to be able to quickly enter information during consultation. I don’t want to click on each link and enter information.</h4>
										<p>We have an option called Simplified Form, you can choose which all fields you wish to enter during the consultation. You can set this up in <b>Settings-&gt;Simplified Form.</b> From here you can select the details you wish to see and Save it. When you open the patient record, from Left menu select
											Simplified Form. If you wish to enter any additional information for some patients you can select from the additional options from the Left menu.</p>
										{/* <span class="clickSeeVideo">Click to see the video</span> */}
										{/* <span class="videoHelp" id="videoHelp1">
					<a>
						<img src={Youtube} alt="No Image" id="divImage1"   title="Video Demo"/>
					</a>
				</span> */}
									</div>
									<div class="faqQuestionDiv">
										<h4>How can I add a new appointment on the calendar?</h4>
										<p>In the <b>Calendar</b> section click on the desired date and time. In the pop-up, fill in the details of the patient and click the <b>Save</b> button. If you are entering the mobile number of a patient who has visited the clinic previously, their details will get automatically filled. If you are entering a new mobile number
											that patient will get newly added.</p>
										{/* <span class="clickSeeVideo">Click to see the video</span>
				<span class="videoHelp" id="videoHelp2">
					<a><img src={Youtube} alt="No Image" id="divImage1"   title="Video Demo"/></a>
				</span> */}
									</div>
									<div class="faqQuestionDiv">
										<h4>How can a Doctor’s calendar be blocked?</h4>
										<p>Click on the <b>Block Calendar </b>icon on the left hand side of the Calendar and Save the needed details here. Appointments cannot be booked for the doctor in the blocked dates, or the block needs to be removed.	</p>
									</div>
									<div class="faqQuestionDiv">
										<h4>How can I see the list of all my patients?</h4>
										<p>Click on the <b>Patients </b>icon. Here you can see the list of all your patients alphabetically. You can search for patients by Name.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to see all of my clinic appointments till date.</h4>
										<p>You can go to the icon <b>Appointments</b>. You can see the list of all your appointments, you can filter based on patients details, by doctor name, status, or select a particular date range also.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How do I add a new patient without booking an appointment?</h4>
										<p>Click on the <b>Add Patient</b>	icon, enter the patient information and <b>Save</b>.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I need to update the profile information (address, aadhar number etc) of one of my patients. How can I do that?</h4>
										<p>Click the <b>Patients</b>icon, search for the patient name and click it. Then click on Edit Patient Profile button. Make necessary changes and save it.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to write the case sheet for my Dental patient. How can I do that.</h4>
										<p>We have Dental specific case sheets. You can select the Patient record and Click Case Sheet. Enter the needed details here.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to upload the medical images, photos of my Dental patients. How can I do that?</h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select Health Files and upload the images. You can upload Orthodontic Images, Before and After Images and any other images.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to send a order to the lab for my Dental Patients. How can I do that? </h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select <b>Lab Order</b></p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to upload the medical images, photos of my medical patients. How can I do that?</h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select Health Files and upload the images.You have the option to add notes to the Xray images.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to select a particular Treatment plan for a patient, how can I do that?</h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select Treatment Plans, you can add a particular Treatment plan from the right menu. You can add a new treatment plan if it is not available in the list.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to prescribe medicines for my patients. How can I do that?</h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select 	<b>Prescription.</b> You can add a new prescription by clicking <b>Add</b>. Select the medicine from the right side. If the clinic is having its own pharmacy the medicines price and remaining stock availability will be shown.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to note down the vital signs of my patients.</h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select Vital Signs. Note down the vital details and save it. You can see the graph based on vital signs on the right hand side.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How can I see the entire details of a particular patient in one place?</h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. From left menu select <b>	History</b></p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to see the BMI Chart, Blood pressure Graph of a particular patient. </h4>
										<p>Click the <b>Patients</b> icon, search for the patient name and click it. On the right hand side you can see the BMI Chart, Blood Pressure charts based on previous visits of that patient.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to see the appointments, pending payments, lab orders for today in one place. </h4>
										<p>You can click on the <b>Dashboard</b> icon to see this.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How secure is my data?</h4>
										<p>We store all the information in a secure environment on Amazon Web Services, on virtual private cloud. Your data is secure. </p>
									</div>
									<h2 style={{ borderBottom: 'none' }}>Settings FAQ:</h2>
									<div class="faqQuestionDiv">
										<h4>How can the MR number/ Patient ID numbers be generated automatically?</h4>
										<p>Login as the Admin, Go to <b>Settings</b>, and from left menu select the <b>Auto ID Generation</b>. You can set the format for Patient ID/MR number here and save it. When you are adding new patients the Patient ID/MR number will get automatically generated based on the settings given.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How can I set up a Health Card format for all of my patients?</h4>
										<p>Login as Admin, Go to <b>Settings</b>, and from left menu select  <b>Health Card	</b>. You can customize and save the health card. When you go to any patient record you can select Health Card (from left menu) and Print it. </p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I need to Print a Health Card for a particular patient.</h4>
										<p>You need to first Login as Admin, Go to <b>Settings</b>, and from left menu select <b>Health Card</b> and set the format. You can customize and save the health card. See above question.</p>
										<p>When you go to any patient record you can select Health Card (from left menu) and Print it. </p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How can I export my patients data?</h4>
										<p>Login as an <b>Admin </b>, Go to <b>Settings</b> at the top and from left menu click <b>Export Data</b>. Select the data you would like to export and click on <b>Export </b> button.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I want to upload Treatment/Procedures to my clinic.</h4>
										<p>Go to <b>Settings</b>, and from left menu select <b>Procedure Catalogue</b>. Click <b>Add Procedure</b> to add procedures. Create a file with procedure name | Procedure Code | Price. Note that these must be Pipe delimited (the vertical line) and save the file in .csv format. If you have a file saved with procedures, save that in csv format (ensure that the information is Pipe delimited) and use the <b>UploadCSV </b> option.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How can I customize the printouts for invoices, receipts, lab orders, pharmacy etc?</h4>
										<p>Login as <b>Admin </b>, Go to  <b>Settings</b>, and from left menu select <b>Print Outs</b>. Make the necessary changes here and save it. When taking printouts, this saved format will be used.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>How can the doctor prescription pad be customized while printing prescriptions?	</h4>
										<p>Login as <b>Admin</b>, Go to <b>Settings</b>, and from left menu select <b>Doctor Prescription</b>. Make the necessary changes here and save it. When taking printout of the doctor prescriptions, this saved format will be used.</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to send SMS communications to my patients, how can I do this?</h4>
										<p>Once you inform us that you would like to send SMS communications to patients, we will switch the feature on for you. You can then login as <b>Admin </b> and go to the 	<b>Communications</b> icon at the top.</p>
										<p>Click on <b>SMS &amp; Emails</b>, there you can select whether you want sms/email notifications to be sent to the doctor or patient based on the value given in Role.  </p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to send Promotional SMS messages to my patients, how can I do this?	</h4>
										<p>Once you inform us that you would like to send Promotional SMS messages to patients, we would switch the feature on for you. You can then login as <b>Admin </b> and go to the <b>Communications</b>	 icon.</p>
										<p>Click on <b>Promotional	SMS</b>, and click <b>Send SMS</b>, fill in the values and click <b> Save</b> . You can create Groups to which you wish the Promotional SMS by going to <b>Settings-&gt; Hospital Patient Group</b></p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to send newsletters by post to my patients. Is there a way to print out the patient details for this purpose?</h4>
										<p>Yes, you can go to <b>Settings, Label Printing.</b> Select  the needed details and <b>Print</b> </p>

									</div>
									<div class="faqQuestionDiv">
										<h4>My clinic name and some other details have changed. Can this be updated?</h4>
										<p>You can go to <b>Settings,Practice Details.</b>Make the necessary changes here</p>

									</div>
									<div class="faqQuestionDiv">
										<h4>I would like to see the Week view in Calendar by default. How can I do this?</h4>
										<p>You can set your preference to see Day View, Week View or Month View by logging in as the Doctor, and goto <b>Settings, Calendar Settings</b></p>

									</div>
									{/* <div class="helpVideoWrap ui-resizable" id="resizeDiv">
				<div class="helpVideoClose" title="Close"  >x</div>
				<div class="videoSection">
					<video id="videoDemo" controls="">
						  <source src="">
					</video>
				</div>
				<div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
			</div> */}
								</div>
							</div>

						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default Help;
