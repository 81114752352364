import React, { useEffect, useState } from 'react';
import { callingAPI, callingAPI_GET } from '../config';
function Specialitydetails(props) {
	const [specialityDetails, setSpecialityDetails] = useState([]);
	const [specialityName, setSpecialityName] = useState('');
	const [dataflag, setdataflag] = useState(false);
	const [primarySpecialCheck, setprimarySpecialCheck] = useState(false);
	const getSpecialityDetails = () => {
		var postData = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('doctorProfile/getSpecialization', postData)
			.then((res) => {
				var specialization = [];
				for (var s = 0; s < props.specialityDetails.length; s++) {
					var obj = {
						specialty: props.specialityDetails[s].speciality
					}
					specialization.push(obj)
					let dataCheck = props.specialityDetails.filter((data, index) => data.isPrimary === true).map((item) => item)
				window.sessionStorage.setItem("specializationId",dataCheck[0].specializationId);
					// Array.prototype.map.call(props.specialityDetails, function(item) { return item.specializationId; }).join(", ")
			window.sessionStorage.setItem("specializationName",dataCheck[0].speciality);
				}
				//  Array.prototype.map.call(props.specialityDetails, function(item) { return item.speciality; }).join(", ")
				var result = [];
				var getCheckedIds;
				result = res.data.result.speciality.map((val, idx) => {
					val.isChecked = false;
					val.isPrimary = true
					return val;
				});
				if (props.specialityDetails) {

					props.specialityDetails.map((savedObject, savedIndex) => {
						result.filter((defaultObject) => {
							if (defaultObject.specializationId === savedObject.specializationId) {
								defaultObject.isChecked = true;
							}
							else if (savedObject.isPrimary === true) {
								defaultObject.isPrimary = false;
							}

							return defaultObject;
						});
					});
					setSpecialityDetails(result);
				} else {
					setSpecialityDetails(result);
				}
				for (var i = 0; i < props.specialityDetails.length; i++) {
					document.getElementById("chkInsurance" + props.specialityDetails[i].specializationId).checked = true
					if (props.specialityDetails[i].isPrimary === true) {
						document.getElementById("primarySpecialId" + props.specialityDetails[i].specializationId).checked = true
					}
				}
			})
			.catch();
	};
	const specialityChange = (e, item, index) => {
		const newData = [...specialityDetails];
		newData[index].isChecked = !item.isChecked;
		setSpecialityDetails(newData);
	};
	const saveSpecialityDetails = () => {
		setdataflag(false)
		var specialisationsSelect = [];
		for (var s = 0; s < specialityDetails.length; s++) {
			if (document.getElementById('chkInsurance' + specialityDetails[s].specializationId).checked === true) {
				var a = {
					SpecialityId: specialityDetails[s].specializationId.toString(),
					primarySpecial:
						document.getElementById('primarySpecialId' + specialityDetails[s].specializationId).checked === true
							? 'true'
							: 'false',
				};
				specialisationsSelect.push(a);
			}
		}

		var specialisationsSelect1 = [];
		for (var i = 0; i < specialisationsSelect.length; i++) {
			if (specialisationsSelect[i].primarySpecial === 'true') {
				specialisationsSelect1.push(i);
			}
		}
		if (specialisationsSelect.length == 0) {
			setdataflag(false)
			alert(' Choose a Specialization ');
			return false
		} else if (specialisationsSelect1.length == 0) {
			setdataflag(false)
			alert('Choose at least One  Primary Specialization');
			return false
		} else if (specialisationsSelect1.length > 1) {
			setdataflag(false)
			alert('Choose Only One Primary Specialization Field');
			return false
		}
		setdataflag(true)
		// var specialisationsSelect = specialityDetails.reduce(
		// 	(a, o) => (o.isChecked && a.push(o.specializationId), a),
		// 	[]
		// );

		// var specializationSpecialityName = specialityDetails.reduce((a, o) => 
		// (o.isChecked && a.push(o.specializationSpeciality), a),[]
		// );
		// localStorage.setItem('specializationSpecialityName', JSON.stringify(specializationSpecialityName));
		const postData = {
			doctorSpecializationIds: specialisationsSelect,
			subFunctionName: 'updateSpecialityDetails',
			doctorId: window.sessionStorage.getItem('doctorId'),
		};

		callingAPI('doctorProfile/updateDoctorProfile', postData)
			.then((res) => {
				alert(res.data.successMessage);
				window.location.href = '/Dashboard/profile';
			})
			.catch();
	};
	useEffect(
		() => {

			getSpecialityDetails();
			// if (props.specialityDetails.length) {
			// 	setSpecialityDetails(props.specialityDetails);
			// }
			if (props.memberDetails) {
				setSpecialityName(props.memberDetails.medicineBranchName);
			}

		},
		[props.specialityDetails]
	);
	const primarySpecial = (id, value) => {
		if (value === true) {
			setprimarySpecialCheck(true);
		}
		if (
			document.getElementById('primarySpecialId' + id).checked === true &&
			document.getElementById('chkInsurance' + id).checked === false
		) {
			alert('Please add corresponding Specialisation');
			document.getElementById('primarySpecialId' + id).checked = false;
		}
	};
	return (
		<div>
			<div className="homeProfileDetails">
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className="col-md-12 col-lg-12 practiceManagementList">
							<div className="homeSpecialityContent">
								<div className="homeSpecialityTitle">Medicine Branch : {specialityName}</div>
								<div className="homeSpecialitycheck">
									{specialityDetails.map((option, idx) => (
										<div className="homeSpecialityList">
											<span class="checkSection">
												<input
													// type="checkbox"
													// value={option.speciality}
													// id={option.specializationId}
													// checked={option.isChecked}
													// onChange={(e) => specialityChange(e, option, idx)}
													//defaultChecked={option.isChecked}
													type="checkbox"
													name={
														'chkInsurance' + option.specializationId
													}
													id={'chkInsurance' + option.specializationId}

												/>
												<label for={'chkInsurance' + option.specializationId} />
											</span>
											<li style={{ listStyle: "none" }}>{option.specializationSpeciality}</li>
											<span>
												<input
													type="checkbox"
													class="radiobtnindex3 spl0"
													name="primarySpecial56"
													id={
														'primarySpecialId' +
														option.specializationId
													}
													//		defaultChecked={option.isPrimary}
													// checked={option.isPrimary}
													// defaultValue={option.isPrimary}
													onChange={(e) =>
														primarySpecial(
															option.specializationId,
															e.target.checked
														)}
												/>
												Primary Specialization
											</span>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="col-md-4 col-lg-4 practiceManagementList">
							{specialityDetails.length > 0 ? (
								<button
									disabled={dataflag}
									class="borderButton borderButtonLarge legoButton_green"
									onClick={saveSpecialityDetails}
									style={{ width: '100%' }}
								>
									Save
								</button>
							) : ("")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Specialitydetails;
