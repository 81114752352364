import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import Calendar from '../images/icons/img4-b.png';
import Casesheet from '../images/icons/casesheet.png';
import Prescription from '../images/icons/prescription.png';
import CompletedPlans from '../images/icons/completedPlans.png';
import Payment from '../images/icons/payments.png';
import Invoice from '../images/icons/invoice.png';
import Datepicker from 'react-datepicker';
import Tickk from '../images/icons/tickk.png';
import Treatment from '../images/icons/treatment.png';
import Eye from '../images/icons/eye.png';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';
function History() {
	const [referAdoctor, setReferAdoctor] = useState([])
	const [appoiments123, setAppoiments123] = useState(false);
	const [appoimentsArray, setAppoimentsArray] = useState([]);
	const [click, setClick] = useState(true);
	const [referDoctor, setReferDoctor] = useState(false);
	const [caseSheetArray, setCaseSheetArray] = useState([]);
	const [caseSheetArray123, setCaseSheetArray123] = useState(false);
	const [vitalSigns, setVitalSigns] = useState(false);
	const [treatmentPlan123, setTreatmentPlan123] = useState(false);
	const [treatmentPlanArray, setTreatmentPlanArray] = useState([]);
	const [completedPlan123, setCompletedPlan123] = useState(false);
	const [completedPlanArray, setCompletedPlanArray] = useState([]);
	const [files, setFiles] = useState(false);
	const [prescriptionsArray, setPrescriptionsArray] = useState([]);
	const [prescriptions123, setPrescriptions123] = useState(false);
	const [labOrder123, setLabOrder123] = useState(false);
	const [labOrderArray, setLabOrderArray] = useState([]);
	const [invoice123, setInvoice123] = useState(false);
	const [invoiceArray, setInvoiceArray] = useState([]);
	const [payments123, setPayments123] = useState(false);
	const [paymentsArray, setPaymentsArray] = useState([]);
	const [testResult, setTestResult] = useState(false);
	const [admit, setAdmit] = useState(false);
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [dischargeNote, setDischargeNote] = useState(false);
	useEffect(() => {
		//	patientDataListing();
	}, []);

	const Appoiments123 = (event) => {
		setAppoiments123(event.target.checked)
	}
	// const ReferDoctor = (event) => {
	// 	setReferDoctor(event.target.checked)
	// }
	const CaseSheetArray123 = (event) => {
		setCaseSheetArray123(event.target.checked)
	}
	// const VitalSigns = (event) => {
	// 	setVitalSigns(event.target.checked)
	// }
	const TreatmentPlan123 = (event) => {
		setTreatmentPlan123(event.target.checked)
	}
	const CompletedPlan123 = (event) => {
		setCompletedPlan123(event.target.checked)
	}
	const Files = (event) => {
		setFiles(event.target.checked)
	}
	const Prescriptions123 = (event) => {
		setPrescriptions123(event.target.checked)
	}
	const LabOrder123 = (event) => {
		setLabOrder123(event.target.checked)
	}
	const Invoice123 = (event) => {
		setInvoice123(event.target.checked)
	}
	const Payments123 = (event) => {
		setPayments123(event.target.checked)
	}
	const TestResult = (event) => {
		setTestResult(event.target.checked)
	}
	// const Admit = (event) => {
	// 	setAdmit(event.target.checked)
	// }
	// const DischargeNote = (event) => {
	// 	setDischargeNote(event.target.checked)
	// }
	function selectFunction() {
		if (click === true) {
			setClick(!click)
			setAppoiments123(true)
			// setReferDoctor(true)
			setCaseSheetArray123(true)
			// setVitalSigns(true)
			setTreatmentPlan123(true)
			setCompletedPlan123(true)
			setFiles(true)
			setPrescriptions123(true)
			setLabOrder123(true)
			setInvoice123(true)
			setPayments123(true)
			setTestResult(true)
			// setAdmit(true)
			// setDischargeNote(true)
		}
		if (click === false) {
			setClick(!click)
			setAppoiments123(false)
			// setReferDoctor(false)
			setCaseSheetArray123(false)
			// setVitalSigns(false)
			setTreatmentPlan123(false)
			setCompletedPlan123(false)
			setFiles(false)
			setPrescriptions123(false)
			setLabOrder123(false)
			setPayments123(false)
			setInvoice123(false)
			setTestResult(false)
			// setAdmit(false)
			// setDischargeNote(false)
		}
	}
	const patientDataListing = () => {
		const apiJson = {
			memberId: window.sessionStorage.getItem("memberId"),
			"fromDate": moment(fromDate).format('yyyy-MM-DD'),
			"toDate": moment(toDate).format('yyyy-MM-DD'),
			hospitalId: localStorage.getItem('hospitalId'),
			"category": ["caseSheet", "appoiments",
				"treatmentPlan", "completedPlans", "prescriptions",
				"invoice", "payments", "labOrder", "testResult", "files"]
		}
		callingAPI('patientHistory/patientsHistory', apiJson).then((data) => {
			if (data.data.success === 1) {

				// setVitalSigns(data.data.result.vitalSigns)
				// setCaseSheetArray(data.data.result.caseSheetArray);
				// setAppoimentsArray(data.data.result.appoiments)				
				// setTreatmentPlanArray(data.data.result.treatmentPlan)
				// setCompletedPlanArray(data.data.result.completedPlan)
				// setFiles(data.data.result.files)
				// setPrescriptionsArray(data.data.result.prescriptions)
				// setLabOrderArray(data.data.result.labOrder)
				// setInvoiceArray(data.data.result.invoice)
				// setPaymentsArray(data.data.result.payments)
				// setTestResult(data.data.result.testResult)
				// setReferDoctor(data.data.result.referDoctor)
				// setAdmit(data.data.result.admit)
			}
		}
		)
	}
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll  pl_history ">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection pl_addTreatSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanList1Div">
											<div class="treatmentPlanHead" style={{ border: 'none' }}>
												<span class="treatmentPlanName">HISTORY</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span>
											</div>
											<div class="treatmentPlanList11 pl_treatmentDiv pl_addTreatmnt">
												<div class="pl_treat treatmentTable planActive">
													<hr style={{ margin: '0px' }} />
													{/*  */}
													<div class="treatmentTimeline">
														<div class="treatmentTimelineCheck history_id_1">
															<div class="treatmentTimelineType">
																Types
																{/* <span class="timelineDeselect history_id_2" style={{ float: 'right' }}  >Select All</span> */}
																<span
																	class="timelineSelect history_id_2"
																	style={{ float: 'right' }} onClick={() => selectFunction()}>
																	{click ? 'Select All' : 'Deselect All'}
																</span>
															</div>
															<div className="col-md-2 dashboardSearchList">
																<Datepicker
																	required
																	className="Datepicker pa2 inputField"
																	placeholderText="From"
																	calendarClassName="rasta-stripes"
																	selected={fromDate}
																	dateFormat="dd/MM/yyyy"
																	onChange={(date) => setFromDate(date)}
																/>
															</div>
															<div className="col-md-2 dashboardSearchList">
																<Datepicker
																	selected={toDate}
																	className="Datepicker pa2 inputField"
																	placeholderText="To"
																	calendarClassName="rasta-stripes"
																	dateFormat="dd/MM/yyyy"
																	onChange={(date) => setToDate(date)}
																/>
															</div>
															<ul>
																<li>
																	<input
																		type="checkbox"
																		value="appoiments"
																		id="appoiments"
																		name="appoiments"
																		checked={appoiments123}
																		onChange={(e) => { Appoiments123(e) }}
																		onClick={(e) => { patientDataListing(e) }}

																	/>
																	<span class="timelineTxt">Appointments</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="clinicalNotes"
																		checked={caseSheetArray123}
																		id="caseSheetArray"
																		name="caseSheetArray"
																		onChange={CaseSheetArray123} />
																	<span class="timelineTxt">Case Sheet</span>
																</li>

																{/* <li>
																	<input type="checkbox"
																		value="vitalSigns"
																		checked={vitalSigns}
																		id="vitalSigns"
																		name="vitalSigns"
																		onChange={VitalSigns} />
																	<span class="timelineTxt">Vital Signs</span>
																</li> */}
																<li>
																	<input
																		type="checkbox"
																		value="treatmentPlans"
																		checked={treatmentPlan123}
																		id="treatmentPlans"
																		name="treatmentPlans"
																		onChange={TreatmentPlan123}
																	/>
																	<span class="timelineTxt">Treatment Plans</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="completedPlan"
																		checked={completedPlan123}
																		id="completedPlan"
																		name="completedPlan"
																		onChange={CompletedPlan123}
																	/>
																	<span class="timelineTxt">Completed Plans</span>
																</li>
																<li>
																	<input type="checkbox"
																		value="files"
																		checked={files}
																		id="files"
																		name="files"
																		onChange={Files} />
																	<span class="timelineTxt">Files</span>
																</li>
																<li>
																	<input
																		type="checkbox"
																		value="prescriptions"
																		checked={prescriptions123}
																		id="prescriptions"
																		name="prescriptions"
																		onChange={Prescriptions123}
																	/>
																	<span class="timelineTxt">Prescriptions</span>
																</li>
																<li>
																	<input type="checkbox"
																		value="labOrder"
																		checked={labOrder123}
																		id="labOrder"
																		name="labOrder"
																		onChange={LabOrder123}
																	/>
																	<span class="timelineTxt">Lab Order</span>
																</li>
																<li>
																	<input type="checkbox"
																		value="invoice"
																		checked={invoice123}
																		id="invoice"
																		name="invoice"
																		onChange={Invoice123} />
																	<span class="timelineTxt">Invoices</span>
																</li>
																<li>
																	<input type="checkbox"
																		value="payments"
																		checked={payments123}
																		id="payments"
																		name="payments"
																		onChange={Payments123} />
																	<span class="timelineTxt">Payments</span>
																</li>
																<li>
																	<input type="checkbox"
																		value="testResult"
																		checked={testResult}
																		id="testResult"
																		name="testResult"
																		onChange={TestResult} />
																	<span class="timelineTxt">Test Result</span>
																</li>
																{/* <li>
																	<input
																		type="checkbox"
																		value="referDoctor"
																		id="referDoctor"
																		name="referDoctor"
																		checked={referDoctor}
																		onChange={ReferDoctor}
																	/>
																	<span class="timelineTxt">Refer A Doctor</span>
																</li> */}
																{/* <li>
																	<input type="checkbox"
																		value="admit"
																		checked={admit}
																		id="admit"
																		name="admit"
																		onChange={Admit}
																	/>
																	<span class="timelineTxt">Admit</span>
																</li> */}
																{/* <li>
																	<input
																		type="checkbox"
																		value="dischargeNote"
																		checked={dischargeNote}
																		id="dischargeNote"
																		name="dischargeNote"
																		onChange={DischargeNote}
																	/>
																	<span class="timelineTxt">Discharge Note</span>
																</li> */}
															</ul>
														</div>

														<div id="innerListShow" class="history_id_3">
															<div class="treatmentTimelineBlock">
																<div class="timelineList">
																	<div class="timelineListDate">
																		<span class="timelineDate"></span>
																		<div class="timelineDateCvr">
																			<span class="timelineMnthDate">     </span>{' '}
																			<span class="timelineDay"></span>
																		</div>
																	</div>

																	{appoiments123 == true && appoimentsArray.map((data, i) => (

																		<div class="timelineListContent">
																			<span class="timelineArrow" />{' '}

																			<span class="timelineCircle" />

																			<div class="timelineListName">
																				<img alt="" src={Calendar} />
																				<span>APPOINTMENTS</span>
																			</div>
																			<div class="vitalSignsTable treatmentpadZero">
																				<div
																					class="appoinmentDateDetail"
																					style={{ float: 'none', width: '100%' }}
																				>
																					<div class="appoinmentLeftDiv">
																						<span class="appoinmentReason">
																							<span>Reason:
																								{data.visitReason}
																							</span>
																							<span class="elipsis">

																								{data.appintmentDate}
																							</span>
																						</span>
																					</div>
																					<div class="appoinmentSatusWrap">
																						<span class="appoinmentTYpe">
																							Type :
																							{data.appointmentType}
																						</span>
																						<span class="appoinmentSTatus">
																							Status :
																							{data.appStatus}
																						</span>
																					</div>
																					<div class="appoinmentRightDiv">
																						<span class="appoinmentDrName">
																							{data.doctorName}
																						</span>
																					</div>
																				</div>
																				<hr />

																			</div>

																		</div>
																	))}

																	{/* {caseSheetArray123 == true && caseSheetArray.map((data,i) => ( */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img src={Casesheet} />
																			<span>Case Sheet</span>
																		</div>
																		<div
																			class="treatmentPlanList1Wrap"
																			id="listClinicalNotes"
																		>
																			<div class="treatmentPlanList1141720">
																				<div class="treatmentPlanDate" />
																				<div class="treatmentComplaintWrap">
																					<div class="treatmentComplaintRowWrap">
																						<div class="treatmentComplaintRow">
																							<div class="treatmentComplaintLeft">
																								Chief presenting
																								--------------complaint---------------------------
																							</div>
																							<div class="treatmentComplaintRight">
																								<span>
																									<span class="treatmentTAgName elipsis">
																										{/* {data.createdOn} */}
																									</span>

																									<span class="treatmentTAgDate">
																										{/* {data.orderDate} */}
																									</span>
																								</span>
																								<span
																									style={{
																										fontWeight:
																											'bold',
																									}}
																								>
																									----------Description :-----------------
																								</span>
																								<span>test</span>
																							</div>
																						</div>
																						<div class="treatmentComplaintNoted">
																							Noted by{' '}
																							{/* <span>{data.doctorName}</span> */}
																						</div>
																						<div class="dentalProNote" />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	{/* ))} */}
																	{/* {prescriptions123 == true && prescriptionsArray.map((data,i) => ( */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img src={Prescription} />
																			<span>Prescriptions</span>
																		</div>
																		<div class="treatmentPlanList1">
																			<div class="treatmentTable prescriptionTable ">
																				<table>
																					<thead>
																						<tr>
																							<th class="col-md-3">
																								<div class="dentalProName">
																									Medicine---------------------
																								</div>
																							</th>
																							<th class="col-md-3">
																								<div class="dentalProCost">
																									Frequency &amp;
																									Dosage-----------------
																								</div>
																							</th>
																							<th class="col-md-1">
																								<div class="dentalProCost">
																									Intake-------------------
																								</div>
																							</th>
																							<th class="col-md-1">
																								<div class="dentalProDis">
																									Qty--------------------
																								</div>
																							</th>
																							<th class="col-md-1">
																								<div class="dentalProTotal1">
																									Days--------------------
																								</div>
																							</th>
																							<th class="col-md-2">
																								<div class="dentalProTotal">
																									{/* {data.cdate} */}
																								</div>
																							</th>
																						</tr>
																					</thead>
																				</table>
																				<div class="treatmentPlanDate">
																					<div class="treatmentComplaintNoted ">
																						Noted by{' '}
																						{/* <span>{data.name}</span> */}
																					</div>
																					<div class="dentalSelect">
																						<div class="dentalProNote" />
																					</div>
																				</div>
																			</div>
																			<br />
																		</div>
																	</div>
																	{/* ))}   */}
																</div>

																<div class="timelineList">
																	<div class="timelineListDate">
																		<span class="timelineDate"></span>
																		<div class="timelineDateCvr">
																			<span class="timelineMnthDate">{"data.visit_date"}</span>{' '}
																			<span class="timelineDay">November</span>
																		</div>
																	</div>
																	{/* {treatmentPlan123 == true && treatmentPlanArray.map((data,i) => (																 */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img src={Casesheet} />
																			<span>Treatment plan</span>
																		</div>
																		<div class="treatmentTable treatmentpadZero">
																			<div class="treatmentPlanList1">
																				<div class="treatmentTable ">
																					<table>
																						<thead>
																							<tr>
																								<th class="col-md-3">
																									<div class="dentalProName">
																										PROCEDURE
																									</div>
																								</th>
																								<th class="col-md-2">
																									<div class="dentalProCost">
																										COST
																									</div>
																								</th>
																								<th class="col-md-2">
																									<div class="dentalProCost">
																										TAX
																									</div>
																								</th>
																								<th class="col-md-2">
																									<div class="dentalProTotal">
																										TOTAL
																									</div>
																								</th>
																								<th class="col-md-3">
																									<div class="dentalProNote">
																										NOTES
																									</div>
																								</th>
																							</tr>
																						</thead>
																						<tbody>
																							<tr class="groupBrdr">
																								<td class="col-md-3">
																									<div class="dentalProName">
																										<div class="optionWrapper">
																											<img
																												src={
																													Tickk
																												}
																											/>
																											<label for="cool000" />
																										</div>
																										<span class="elipsis">
																											{/* {data.procedure_code} */}
																										</span>
																									</div>
																								</td>
																								<td class="col-md-2">
																									<div class="dentalProCost">
																										{/* {data.total_cost} */}
																									</div>
																								</td>
																								<td class="col-md-2">
																									<div class="dentalProDis">
																										{/* {data.total_tax_amount} */}
																									</div>
																								</td>
																								<td class="col-md-2">
																									<div class="dentalProTotal">
																										{/* {data.total_net_amount} */}
																									</div>
																								</td>
																								<td class="col-md-3">
																									<div class="dentalProNote">
																										<span
																											class="elipsis"
																											title=""
																										>
																											&nbsp;
																										</span>
																									</div>
																								</td>
																							</tr>
																							<tr class="dentalProPlaned">
																								<td colspan="3">
																									<div class="plannedBy">
																										Planned by{' '}
																										<span>---------Dr. Sugesh IDA-------------------</span>
																									</div>
																								</td>
																								<td colspan="2">
																									<div class="estimatedPrice txtRight">
																										Estimated amount
																										{/* : {data.net_amount} */}
																									</div>
																								</td>
																								<td colspan="1">
																									<div class="estimatedPrice">
																									</div>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																	{/* ))} */}
																	{/* {completedPlan123 == true && completedPlanArray.map((data,i) => ( */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img src={CompletedPlans} />
																			<span>COMPLETED PLANS</span>
																		</div>
																		<div class="vitalSignsTable treatmentpadZero">
																			<div class="treatmentPlanList1">
																				<div class="treatmentTable prescriptionTable ">
																					<table>
																						<thead>
																							<tr>
																								<th class="col-md-3">
																									<div class="dentalProName">
																										PROCEDURE
																									</div>
																								</th>
																								<th class="col-md-2">
																									<div class="dentalProCost">
																										COST
																									</div>
																								</th>
																								<th class="col-md-1">
																									<div class="dentalProDis">
																										DISCOUNT
																									</div>
																								</th>
																								<th class="col-md-1">
																									<div class="dentalProCost">
																										TAX
																									</div>
																								</th>
																								<th class="col-md-2">
																									<div class="dentalProTotal">
																										TOTAL
																									</div>
																								</th>
																								<th class="col-md-3">
																									<div class="dentalProNote">
																										NOTES
																									</div>
																								</th>
																							</tr>
																						</thead>
																						<tbody>
																							<tr
																								class="groupBrdr"
																								id="tr0"
																							>
																								<td class="col-md-3">
																									<div class="dentalProName">
																										<span class="elipsis">
																											{/* {data.completedPlanArray} */}
																										</span>
																									</div>
																								</td>
																								<td class="col-md-2">
																									<div class="dentalProCost">
																										{/* {data.total_cost} */}
																									</div>
																								</td>
																								<td class="col-md-1">
																									<div class="dentalProDis">
																										{/* {data.total_discount} */}
																									</div>
																								</td>
																								<td class="col-md-1">
																									<div class="dentalProDis">
																										{/* {data.total_tax_amount} */}
																									</div>
																								</td>
																								<td class="col-md-2">
																									<div class="dentalProTotal">
																										{/* {data.datatotal_net_amount} */}
																									</div>
																								</td>
																								<td class="col-md-3">
																									<div class="dentalProNote">
																										<span
																											class="elipsis"
																											title=""
																										>
																											&nbsp;
																										</span>
																									</div>
																								</td>
																							</tr>
																							<tr
																								class="dentalProPlaned"
																								id="trSub0"
																							>
																								<td colspan="6">
																									<div class="plannedBy">
																										Completed by{' '}
																										{/* <span> {data.doctor_name} </span> */}
																									</div>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																	{/* ))} */}
																</div>


																<div class="timelineList">
																	<div class="timelineListDate">
																		<span class="timelineDate">26</span>
																		<div class="timelineDateCvr">
																			<span class="timelineMnthDate">2021</span>{' '}
																			<span class="timelineDay">October</span>
																		</div>
																	</div>
																	{/* {labOrder123 == true && labOrderArray.map((data,i) => ( */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img src={Casesheet} />
																			<span>Lab Order</span>
																		</div>
																		<div class="treatmentTable prescriptionTable ">
																			<table>
																				<thead>
																					<tr>
																						<th class="col-md-1">
																							<div class="dentalProName">
																								Lab Name
																							</div>
																						</th>
																						<th class="col-md-3">
																							<div class="dentalProCost">
																								Test Name
																							</div>
																						</th>
																						<th class="col-md-1">
																							<div class="dentalProDis">
																								View
																							</div>
																						</th>
																						<th class="col-md-1">
																							<div class="dentalProDis">
																								Status
																							</div>
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					<tr>
																						<td class="col-md-3">
																							<div class="dentalProName">
																								Dentcare Dental Lab Pvt.
																								Ltd.------------------------------------
																							</div>
																						</td>
																						<td class="col-md-3">
																							<div class="dentalProCost">
																								{/* {data.testName} */}
																							</div>
																						</td>
																						<td class="col-md-1">
																							<div class="dentalProDis">
																								<label>
																									<a
																										href="/patients/labDetailsView"
																										target="_blank"
																										class="edit_align"
																									>
																										<img
																											src={Eye}
																										/>
																									</a>
																								</label>
																							</div>
																						</td>
																						<th class="col-md-1">
																							<span
																								style={{
																									color: '#008000',
																									padding: '10px 6px',
																								}}
																							>
																								{/* {data.invoiceStatus} */}
																							</span>
																						</th>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																	</div>
																	{/* ))}																 */}
																	{/* {invoice123 == true && invoiceArray.map((data,i) => ( */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img alt="" src={Invoice} />
																			<span>INVOICES</span>
																		</div>
																		<div class="treatmentPlanList1 pl_invoice">
																			<div class="treatmentTable invoiceTableWrap">
																				<table>
																					<thead>
																						<tr>
																							<th class="col-md-2">
																								<div class="dentalProName">
																									INVOICE NUMBER
																								</div>
																							</th>
																							<th
																								colspan="4"
																								class="col-md-10"
																								style={{ padding: '0' }}
																							>
																								<div class="dentalProCost invoiceInnerTable">
																									<table>
																										<thead>
																											<tr>
																												<th class="col-md-4">
																													<div class="dentalProName">
																														TOWARDS-----------------------
																													</div>
																												</th>
																												<th class="col-md-1">
																													<div class="dentalProDis">
																														QTY
																													</div>
																												</th>
																												<th class="col-md-2">
																													<div class="dentalProDis">
																														UNIT
																														PRICE
																													</div>
																												</th>
																												<th class="col-md-2">
																													<div class="dentalProCost">
																														COST
																													</div>
																												</th>
																												<th class="col-md-1">
																													<div class="dentalProCost">
																														TAX
																													</div>
																												</th>
																												<th class="col-md-2">
																													<div class="dentalProTotal">
																														TOTAL
																													</div>
																												</th>
																											</tr>
																										</thead>
																									</table>
																								</div>
																							</th>
																						</tr>
																					</thead>
																					<tbody>
																						<tr class="groupBrdr">
																							<td
																								class="col-md-2"
																								style={{
																									position:
																										'relative',
																								}}
																							>
																								<div class="dentalProName dentalProNameTop">
																									<span class="elipsis">
																										{/* {data.invoice_no} */}
																									</span>
																								</div>
																							</td>
																							<td colspan="4">
																								<div class="dentalProCost invoiceInnerTable invoiceInnerTableBr">
																									<table>
																										<tbody>
																											<tr class="procedureInvoiceDiv">
																												<td colspan="6">
																													<div class="invoiceDivHeading">
																														------------
																													</div>
																												</td>
																											</tr>
																											<tr class="procedureInvoiceDiv">
																												<td class="col-md-4">
																													<div class="dentalProName">
																														<p
																														>
																															<b
																																class="elipsis"
																																title="SCALING"
																															>
																																{/* {data.quantity} */}
																															</b>
																														</p>
																														<p
																															class="elipsis"
																															title="001"
																														>
																															{/* {data.cost} */}
																														</p>
																													</div>
																												</td>
																												<td class="col-md-1">
																													<div class="dentalProDis">
																														{/* {data.total_tax} */}
																													</div>
																												</td>
																												<td class="col-md-2">
																													<div class="dentalProDis">
																														{/* {data.total_net_amount} */}
																													</div>
																												</td>
																											</tr>
																										</tbody>
																									</table>
																								</div>
																							</td>
																						</tr>
																						<tr class="groupBrdr groupBg">
																							<td>
																								<div
																									class="dentalProName"
																									id="discountSection0"
																								/>
																							</td>
																							<td colspan="4">
																								<div class="dentalInvoiceAmount">
																									<div class="inVoiceTotal">
																										<span>
																											Total Amount
																											:
																										</span>
																										<span id="totalAmtLbl0">
																											{/* {data.total_net_amount} */}
																										</span>
																									</div>
																									<div class="inVoiceTotal">
																										<span>
																											Discount
																											Amount :
																										</span>
																										<span id="disAmtLbl0">
																											{/* {data.total_discount} */}
																										</span>
																									</div>
																									<div class="inVoicePaid">
																										<span>
																											Paid Amount
																											:
																										</span>
																										<span id="paidAmtLbl0">
																											{/* {data.member_procedure_invoice_payment} */}
																										</span>
																									</div>
																									<div class="inVoiceBal">
																										<span>
																											Balance
																											Amount :
																										</span>
																										<span id="balAmt0">
																											{/* {data.total_balance_amount} */}
																										</span>
																									</div>
																								</div>
																							</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																		</div>
																	</div>
																	{/* // ))} */}
																</div>
																<div class="timelineList">
																	<div class="timelineListDate">
																		<span class="timelineDate">20</span>
																		<div class="timelineDateCvr">
																			<span class="timelineMnthDate">2021</span>{' '}
																			<span class="timelineDay">October</span>
																		</div>
																	</div>
																	{/* {payments123 == true && paymentsArray.map((data,i) => ( */}
																	<div class="timelineListContent">
																		<span class="timelineArrow" />
																		<span class="timelineCircle" />
																		<div class="timelineListName">
																			<img src={Payment} />
																			<span>Payment</span>
																		</div>
																		<div class="treatmentTable invoiceTableWrap">
																			<div class="treatmentPlanList1">
																				<div class="treatmentTable invoiceTableWrap" />
																			</div>
																			<table>
																				<tbody />
																				<thead>
																					<tr>
																						<th class="col-md-2">
																							<div class="dentalProName">
																								RECEIPT NUMBER
																							</div>
																						</th>
																						<th
																							colspan="4"
																							class="col-md-8"
																						>
																							<div class="dentalProCost invoiceInnerTable">
																								<table>
																									<thead>
																										<tr>
																											<th class="col-md-2">
																												<div class="dentalProName txtRight">
																													AMOUNT
																													PAID
																												</div>
																											</th>
																											<th class="col-md-2">
																												<div class="dentalProDis txtLeft">
																													INVOICES
																													NUMBER
																												</div>
																											</th>
																											<th
																												class="col-md-4"
																												style={{
																													padding:
																														'0',
																												}}
																											>
																												<div class="dentalProCost ">
																													TOWARDS
																												</div>
																											</th>
																											<th class="col-md-2">
																												<div class="dentalProTotal txtLeft">
																													MODE
																													OF
																													PAYMENT
																												</div>
																											</th>
																										</tr>
																									</thead>
																								</table>
																							</div>
																						</th>
																						<th class="col-md-1">
																							<div
																								class="dentalProNote"
																								style={{
																									right: ' -8px ',
																								}}
																							/>
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					<tr class="groupBrdr">
																						<td
																							class="col-md-2"
																							style={{
																								position: 'relative',
																							}}
																						>
																							<div class="dentalProName dentalProNameTop">
																								<span class="elipsis">
																									{/* {data.receipt_no} */}
																								</span>
																							</div>
																						</td>
																						<td colspan="4">
																							<table
																								style={{ border: '0' }}
																							>
																								<tbody>
																									<tr
																										style={{
																											border: '0',
																										}}
																									>
																										<td
																											class="col-md-2"
																											style={{
																												position:
																													'relative',
																											}}
																										>
																											<div class="dentalProName dentalProNameTop txtRight">
																												{/* {data.receipt_amount} */}
																											</div>
																										</td>
																										<td
																											colspan="2"
																											class="col-md-6"
																											style={{
																												padding:
																													'0',
																											}}
																										>
																											<div class="dentalProCost invoiceInnerTable invoiceInnerTableBr">
																												<table>
																													<tbody
																													>
																														<tr
																														>
																															<td class="col-md-2">
																																<div class="dentalProName txtLeft">
																																	{/* {data.invoice_no} */}
																																</div>
																															</td>
																															<td
																																class="col-md-4"
																																style={{
																																	padding:
																																		'0',
																																}}
																															>
																																<div class="dentalProDis">
																																	<div class="refundAmountSpan">
																																		------------------------------------Refund----------------
																																	</div>
																																</div>
																															</td>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</td>
																										<td
																											class="col-md-2"
																											style={{
																												position:
																													'relative',
																											}}
																										>
																											<div class="dentalProName dentalProNameTop">
																												<span
																													class="elipsis"
																													style={{
																														color:
																															'#000',
																													}}
																												>
																													{/* {data.payment_mode} */}
																												</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</td>
																						<td class="col-md-1">
																							<div class="dentalProNote">
																								<span class="elipsis">
																									&nbsp;
																								</span>
																							</div>
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																	</div>
																	{/* ))} */}
																</div>



															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default History;
