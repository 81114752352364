// import React from 'react'
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import pulseLoader from '../images/icons/pulseLoader.png';
import { callingAPI } from "../config";
function LoginLoader() {
  let { siteId, token } = useParams();
  const [pmSiteId, setSiteId] = useState(
    typeof siteId === "undefined" ? "" : (siteId.toString().indexOf('GROUP') != -1 ? siteId.toString().split("-")[1] : siteId)
  );
  const [tokenData, setTokenData] = useState(
    typeof token === "undefined" ? "" : token
  );
  useEffect(() => {


    if (token) {
      decreptURL()
    }
  }, [])
  const decreptURL = () => {

    const apiJson = {
      encryptedData: tokenData
    };
    callingAPI("commonUserFunctions/decryptPulseUrl", apiJson).then((data) => {

      if (data.data.success === "1") {
        window.sessionStorage.setItem(
          "siteId",
          siteId
        );
        window.sessionStorage.setItem("redirectURL", data.data.result[0].siteUrl);
        window.sessionStorage.setItem("userName", data.data.result[0].userTitle + data.data.result[0].userName);
        window.sessionStorage.setItem("role", data.data.result[0].role);
        window.sessionStorage.setItem("userId", data.data.result[0].userId);
        window.sessionStorage.setItem("userEmail", data.data.result[0].emailId);
        localStorage.setItem('hospitalId', data.data.result[0].hospitalId);
        window.sessionStorage.setItem("isLoggedIn", "true");
        window.sessionStorage.setItem("loginHospitalIds", data.data.result[0].hospitalId);
        if (data.data.result[0].role === "ROLE_DOCTOR") {
          window.sessionStorage.setItem(
            "isOwner",
            data.data.result[0].isOwner
          );
          window.sessionStorage.setItem(
            "medicineBranchId",
            data.data.result[0].medicineBranchId
          );
          window.sessionStorage.setItem(
            "specializationId",
            data.data.result[0].specializationId
          );
          window.sessionStorage.setItem(
            "specializationName",
            data.data.result[0].specializationName
          );
          window.sessionStorage.setItem(
            "doctorId",
            data.data.result[0].userId
          );
          window.sessionStorage.setItem(
            "userName",
            data.data.result[0].userTitle + data.data.result[0].userName
          );
          window.sessionStorage.setItem("userEmail", data.data.result[0].emailId);
          window.sessionStorage.setItem("homeHeader", "headerIconActive");
          window.sessionStorage.setItem("calenderHeader", "active");
          window.location.href = "/Dashboard/welcome";
        }
      }
    })
  }
  return (
    <div class="fullpage-loader">
      <div class="fullpage-loader__logo">
        <div class="loaderWrap" id="loader" style={{ display: 'block' }}>
          <div class="loaderPurple" />
          <img src={pulseLoader} />
        </div>
      </div>
    </div>
  )
}
export default LoginLoader