import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { callingAPI } from '../config';
function Experiencedetails(props) {
	const [experienceDetailsArrValues, setExperienceDetailsArrValues] = useState([]);
	const [selectedExperienceDescription, setSelectedExperienceDescription] = useState('');
	const [displayErrMsg1, setDisplayErrMsg1] = useState('');
	const [dataflag, setdataflag] = useState(false);
	const [displayErrMsg2, setDisplayErrMsg2] = useState('');
	const [display1, setDisplay1] = useState(false);
	const selectedExperienceDescriptionChange = (e) => {
		setSelectedExperienceDescription(e.target.value);
	};
	const addExperienceDescriptionArr = (e) => {
		setDisplay1(true)
		let copyExperienceDetailsArrvalues = JSON.parse(JSON.stringify(experienceDetailsArrValues));
		if (!selectedExperienceDescription) {
			setDisplayErrMsg1('Please enter expertise')
			alertlanguage()
		} else {
			setDisplayErrMsg1('');
			if (copyExperienceDetailsArrvalues.indexOf(selectedExperienceDescription.toLowerCase()) == -1) {
				copyExperienceDetailsArrvalues.push(selectedExperienceDescription);
				setExperienceDetailsArrValues(copyExperienceDetailsArrvalues);
				setSelectedExperienceDescription('');
			}
			else {
				setDisplayErrMsg2('Experience Already Exist')
				alertlanguage()
			}
		}
	};
	const alertlanguage = () => {
		setTimeout(() => {
			setDisplayErrMsg1('');
			setDisplayErrMsg2('')
		}, 2000);

	}
	const saveExperienceDetails = () => {
		setdataflag(true)
		let postData = {
			expertiseDescription: experienceDetailsArrValues.toString(),
			subFunctionName: 'updateExperienceDetails',
			doctorId: window.sessionStorage.getItem('doctorId'),
		};

		callingAPI('doctorProfile/updateDoctorProfile', postData)
			.then((res) => {
				alert(res.data.successMessage);
				window.location.href = '/Dashboard/profile';
			})
			.catch();
	};
	const removeExperienceItem = (e, i) => {
		let copyExperienceArr = [...experienceDetailsArrValues];
		copyExperienceArr.splice(i, 1);
		setExperienceDetailsArrValues(copyExperienceArr);
		setDisplay1(true)

	};
	useEffect(
		() => {

			if (props.expertise.length) setExperienceDetailsArrValues(props.expertise);
			alertlanguage()
		},
		[props.expertise]
	);
	return (
		<div>
			<div className="homeProfileDetails languageProfileDiv">
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className='col-md-12'>
							<p
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'red',
									padding: '0px',
									margin: '0px', fontSize: "13px", paddingBottom: "15px"
								}}
							>
								{displayErrMsg1}
								{displayErrMsg2}
							</p>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">

							<input
								type="text"
								value={selectedExperienceDescription}
								placeholder="Experience Description"
								name="experiencedesc"
								onChange={selectedExperienceDescriptionChange}
								className="inputField"
							/>
							<span className="languageIcons">
								<FontAwesomeIcon
									title="Add"
									icon={faPlus}
									onClick={(e) => addExperienceDescriptionArr(e)}
								/>
							</span>
						</div>
						{experienceDetailsArrValues.length > 0 ? (

							<div className="col-md-12 col-lg-12 practiceManagementList">
								<div className="addOnSection">
									<div className="addOnSectionTitle">Experience In</div>

									<div className="addOnContent">
										{experienceDetailsArrValues.map((exp, index) => (
											<div className="addOnContentList">
												<input type="text" value={exp} className="inputField" style={{ background: "transparent" }} />
												<span className="languageIcons">
													<FontAwesomeIcon
														title="Delete"
														icon={faTrashAlt}
														onClick={(e) => removeExperienceItem(e, index)}
													/>
												</span>
											</div>
										))}
									</div>

								</div>
							</div>
						) : []}
					</div>
					<div className="col-md-4">
						<button
							disabled={dataflag}
							class="borderButton borderButtonLarge  legoButton_green "
							onClick={saveExperienceDetails}
							style={{ width: '100%', display: display1 ? '' : 'none' }}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Experiencedetails;
