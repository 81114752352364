import React from 'react'
import { NavLink } from 'react-router-dom';
// import BillingLeft from '../billingLogin/billingLeft';
// import PharmacyLeft from '../pharmacyLogin/pharmacyLeft';

import HospitalLeftMenu from '../Reports/HospitalLeftMenu';
function DoctorLeftMenu() {
    const paginationOfOutsidePharmacyOder = () => {
        window.sessionStorage.setItem('OutsidePharmacyOderpageNumber', 1)
        window.sessionStorage.setItem("OutsidePharmacyOderactivepagenew", 0)
    }
    return (
        <div className="settingMenuList">
            {window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" || window.sessionStorage.getItem("role") === "ROLE_DOCTOR" ?
                <>
                    <div className="settingLeftTitle">Reports</div>
                    <ul>
                        <li>
                            <NavLink to="/reports/patientdetails" activeClassName="active" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>Patient Details </NavLink>
                        </li>
                        {/* {(() => {

                            if (window.sessionStorage.getItem('siteBranchId') !== '4') {
                                return ( */}
                        <li>
                            <NavLink to="/pharmacyLogin/outsidePharmacyOrderDetails" activeClassName="active" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}><span onClick={paginationOfOutsidePharmacyOder}>Outside Pharmacy Order</span></NavLink>
                        </li>
                        {/* );
                            }

                        })()} */}
                        {(() => {

                            if (window.sessionStorage.getItem('siteBranchId') !== '4') {
                                return (
                                    <li>
                                        <NavLink to="/labLogin/outsideLabOrderDetails" activeClassName="active" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>Outside Lab Order</NavLink>
                                    </li>
                                );
                            }

                        })()}
                        <li>
                            <NavLink to="/reports/doctorReference" activeClassName="active" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>Doctor Reference</NavLink>
                        </li>
                        <li>
                            <NavLink to="/reports/medicineByDoctor" activeClassName="active" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>Medicine By Doctor</NavLink>
                        </li>

                    </ul></>
                : <HospitalLeftMenu />}
        </div >

    )
}

export default DoctorLeftMenu
