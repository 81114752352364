import React, { useState, useEffect } from 'react';
import Logo from '../images/media/logo.png';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import HeaderPrint from '../Settings/headerPrint';
import Loader from '../Common/loader';
import FooterPrint from '../Settings/footerPrint';

function CasesheetMedicalPrint() {
	let { membId, doctorId, printId, urlHospId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [isData, setIsData] = useState(false);
	const [pagesizeid, setpagesizeid] = useState('');
	const [hospitalDetails, setHospitalDetails] = useState([]);

	const [doctorDetails, setDoctorDetails] = useState([]);
	const [memberDetails, setMemberDetails] = useState([]);
	const [caseSheetPrintData, setCaseSheetPrintData] = useState([]);
	const [date, setDate] = useState('');
	const [systemReview, setSystemReview] = useState([]);
	const [image, setImage] = useState([]);
	const [imageArrs, setImageArrs] = useState('');
	const [logoPath, setlogoPath] = useState(process.env.REACT_APP_S3_BUCKET_URL + '/' + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + '/' + process.env.REACT_APP_S3_LOGO_PATH + '/');
	const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);

	function styleValues() {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {

				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

				//setMarginTop()
			});

	}
	useEffect(() => {
		styleValues()
		const PostData = {
			doctorId: doctorId,
			memberId: membId,
			hospitalId: localStorage.getItem('hospitalId'),
			doctorVisitId: printId,
		};
		callingAPI('commonUserFunctions/printMedicalCaseSheet', PostData).then((response) => {
			if (response.data.success === '1') {
				setHospitalDetails(response.data.result.hospitalDetails);
				setDoctorDetails(response.data.result.doctorDetails);
				setMemberDetails(response.data.result.memberDetails);
				setCaseSheetPrintData(response.data.result.CaseSheetPrintData);
				if (response.data.result.CaseSheetPrintData.length > 0) {
					setDate(response.data.result.CaseSheetPrintData[0].createdOn)
				}
				else if (response.data.result.clinclNoteWrPrntData) {
					setDate(response.data.result.clinclNoteWrPrntData[0].createdOn)
				}

				setSystemReview(response.data.result.clinicalNoteReviewGroupListData)
				setImage(response.data.result.clinclNoteWrPrntData)
				setImageArrs(
					process.env.REACT_APP_S3_BUCKET_URL +
					process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
					process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH
				);
				setIsData(true);
			}
		})
			.catch();
	}, []);
	const printCaseSheetMedical = () => {
		const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
			// Hide unchecked sections before printing
			
			if (isChecked4) {
				section4.style.display = 'none';
			}
			if (isChecked5) {
				section51.style.display = 'none';
				section52.style.display = 'none';
			}
			if (isChecked7) {
				section7.style.display = 'none';
			}
			
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	
		section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	return (
		<div>
			<div className='casesheetMedicalPrintSec casesheetMedPrintWrapr' align="center" style={{
				fontFamily: 'SourceSansPro-Regular', width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
				height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
				marginLeft: '0px',
				marginright: '0px',
				marginTop: '0px',
				marginBottom: '0px', margin: '0 auto'
			}}>
				<div
					align="center"
				// style={{
				// 	width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
				// 	height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
				// 	marginLeft: '0px',
				// 	marginright: '0px',
				// 	marginTop: '0px',
				// 	marginBottom: '0px',
				// }}
				>
					{isData === false ? <Loader /> : ''}
					<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">
						<thead>
							<tr>
								<th style={{ fontWeight: '400', color: '#000', fontFamily: 'SourceSansPro-Regular' }}>
									<div class="printOutHeaderSec ">
										<table style={{ width: '100%' }}>
											<tbody>

												<HeaderPrint hospitalDataId={urlHospId} setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5}/>

												{doctorDetails.map((item, i) => (
													<tr>
														<td
															width="50%"
															colspan="6"
															align="left"
															style={{ fontSize: '12px', wordBreak: 'break-all' }}
														>
															{item.title} {item.doctorDisplayName}, {item.education},{item.speciality}
														</td>
														<td colspan="6"
															width="50%"
															align="right"
															style={{ fontSize: '12px', wordBreak: ' break-all' }}
														>
															{item.address}
														</td>
													</tr>
												))}
												<tr>
													<td colspan="12">
														<hr
															color="#142536"
															style={{ margin: '0', opacity: '1', height: '2px' }}
														/>
													</td>
												</tr>

												<tr>
													{memberDetails.map((data, i) => (
														<td
															colspan="8"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'left',
															}}
														>
															Name : {data.memberFirstName} .{data.memberLastName}
														</td>
													))}
													{/* {caseSheetPrintData.map((data, i) => ( */}
													<td colspan="4"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Date : {date}{' '}
													</td>
													{/* ))} */}
												</tr>

												{memberDetails.map((data, i) => (
													<tr>

														<td
															colspan="3"
															style={{
																width: '40%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '12px',
																textAlign: 'left',
															}}
														>
															Age : {data.memberAge}
														</td>


														<td
															colspan="9"
															style={{
																width: '80%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'right',
															}}
														>
															Email Id : {data.secUserEmailid}
														</td>

													</tr>
												))}
												{memberDetails.map((data, i) => (
													<tr>

														<td
															colspan="3"
															style={{
																width: ' 50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															Sex : {data.memberGender}
														</td>


														<td colspan="9"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'right',
															}}
														>
															Phone No : {data.SecuserMobileNumber}
														</td>

													</tr>
												))}
												{memberDetails.map((data, i) => (
													<tr>
														{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
															(<td
																colspan="8"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '15px',
																	textAlign: 'left',
																}}
															>
																CASE RECORD NO : {data.memberMrNo}
															</td>
															) :

															(!data.memberPrNo == "" &&
																<td colspan="4"
																	style={{
																		width: '50%',
																		fontSize: '12px',
																		padding: '0',
																		lineHeight: '15px',
																		textAlign: 'left',
																	}}
																>
																	CASE RECORD NO : {data.memberPrNo}
																</td>
															)}


													</tr>))}
												<tr>
													<td colspan="12">
														<hr
															color="#142536"
															style={{ margin: '0', opacity: '1', height: '2px' }}
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</th>
							</tr>
						</thead>
						{image.length > 0 ? (
							<tbody>
								{image.map((data, i) => (

									<tr colspan="12">
										<td>
											<div class="printOutSettingContent">
												<div class="treatmentComplaintWrap">
													<div class="treatmentComplaintRowWrap" style={{ margin: "0 auto" }}>
														<div class="treatmentComplaintRow">
															<img src={imageArrs + data.image} style={{ width: "100%" }} />
															{/* <div class="treatmentComplaintLeft">
																{data.complaintTypeName}
															</div>
															<div class="treatmentComplaintRight">
																<span>
																	{' '}
																	<span
																		class="treatmentTAgName"
																		style={{ float: 'left' }}
																	>
																		{data.tags}
																	</span>
																	<span style={{ float: 'left' }}>
																		<b>Description</b> : {data.description}
																		{''}
																	</span>
																	<span
																		class="treatmentTAgDate"
																		style={{ float: 'right' }}
																	>
																		{data.sinceDate}
																	</span>
																</span>
															</div> */}
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						) : (
							<tbody>
								{caseSheetPrintData.map((data, i) => (
									<>
										<tr colspan="12">
											<td>
												<div class="printOutSettingContent">
													<div class="treatmentComplaintWrap">
														<div class="treatmentComplaintRowWrap">
															<div class="treatmentComplaintRow">
																<div class="treatmentComplaintLeft">
																	{data.complaintTypeName}
																</div>
																<div class="treatmentComplaintRight">
																	<span>
																		{' '}
																		<span
																			class="treatmentTAgName"
																			style={{ float: 'left' }}
																		>
																			{data.tags}
																		</span>
																		{data.description ? (
																			<div style={{ display: "initial" }}>
																				<span style={{ float: 'left', width: '80%' }}>
																					<b>Description</b> : {data.description}
																					{''}
																				</span>
																			</div>) : ('')}
																		<span
																			class="treatmentTAgDate"
																			style={{ float: 'right' }}
																		>
																			{data.sinceDate}
																		</span>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</>

								))}

								<tr colspan="12">

									<td>
										<div class="printOutSettingContent">
											<div class="treatmentComplaintWrap">
												<div class="treatmentComplaintRowWrap">

													<div class="treatmentComplaintRow" id="systemReviewId" >
														{systemReview.length > 0 &&
															<div class="treatmentComplaintLeft">
																System Review
															</div>}


														<div class="treatmentComplaintRight">
															{systemReview.map((data, i) => (
																<span>
																	<span class="treatmentTAgName treatmentTAgSystem  " style={{ float: 'left', width: "100%" }}>
																		<b style={{ fontSize: "12px" }}>{data.name}</b>&nbsp; {data.symptom_name} &nbsp;
																		{data.description !== "" && <b style={{ fontSize: "12px" }}>DESCRIPTION&nbsp; :</b>} {data.description}</span>
																</span>
															))}

														</div>

													</div>
												</div>
											</div>

										</div>
									</td>

								</tr>
								<tr colspan="12">
									{doctorDetails.map((data, i) => (
										<td>
											<div class="printOutSettingContent">
												<div class="treatmentComplaintWrap">
													<div class="treatmentComplaintRowWrap">
														<div class="treatmentComplaintNoted">
															Noted by <span>{data.title} {data.doctorDisplayName}</span>
														</div>
													</div>
												</div>
											</div>
										</td>))}
								</tr>
							</tbody>
						)}
						<FooterPrint setIsChecked7={setIsChecked7} isChecked7={isChecked7}/>
					</table>
				</div>
			</div>

			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									id="printButton"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '14px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
										fontFamily: '  Verdana',
									}}
									onClick={printCaseSheetMedical}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CasesheetMedicalPrint;
