import React, { useEffect, useState } from "react";
import { callingAPI } from "../config";
import { Link } from "react-router-dom";
import moment from "moment";
import Large from "../images/icons/large.jpg";
import LargeB from "../images/icons/largeB.jpg";
import Medium from "../images/icons/medium.jpg";
import MediumB from "../images/icons/mediumB.jpg";
import D from "../images/icons/d.jpg";
import DB from "../images/icons/dB.jpg";
import P1 from "../images/icons/p1.jpg";
import P2 from "../images/icons/p2.jpg";
import P3 from "../images/icons/p3.jpg";
import P4 from "../images/icons/p4.jpg";
import P5 from "../images/icons/p5.jpg";
import Shade1a from "../images/icons/1a.png";
import Shade1aB from "../images/icons/1aB.png";
import Shade1b from "../images/icons/1b.png";
import Shade1bB from "../images/icons/1bB.png";
import Shade1c from "../images/icons/1c.png";
import Shade1cB from "../images/icons/1cB.png";
import Shade1d from "../images/icons/1d.png";
import Shade1dB from "../images/icons/1dB.png";
import Shade1e from "../images/icons/1e.png";
import Shade1eB from "../images/icons/1eB.png";
import Shade1f from "../images/icons/1f.png";
import Shade1fB from "../images/icons/1fB.png";
import Shade2a from "../images/icons/2a.png";
import Shade2aB from "../images/icons/2aB.png";
import Shade2b from "../images/icons/2b.png";
import Shade2bB from "../images/icons/2bB.png";
import Shade2c from "../images/icons/2c.png";
import Shade2cB from "../images/icons/2cB.png";
import Shade2d from "../images/icons/2d.png";
import Shade2dB from "../images/icons/2dB.png";
import Shade2e from "../images/icons/2e.png";
import Shade2eB from "../images/icons/2eB.png";
import Shade2f from "../images/icons/2f.png";
import Shade2fB from "../images/icons/2fB.png";
import Shade3a from "../images/icons/3a.png";
import Shade3aB from "../images/icons/3aB.png";
import Shade3b from "../images/icons/3b.png";
import Shade3bB from "../images/icons/3bB.png";
import Shade3c from "../images/icons/3c.png";
import Shade3cB from "../images/icons/3cB.png";
import Shade3d from "../images/icons/3d.png";
import Shade3dB from "../images/icons/3dB.png";
import Shade3e from "../images/icons/3e.png";
import Shade3eB from "../images/icons/3eB.png";
import Shade3f from "../images/icons/3f.png";
import Shade3fB from "../images/icons/3fB.png";
import Shade4a from "../images/icons/4a.png";
import Shade4aB from "../images/icons/4aB.png";
import Shade4b from "../images/icons/4b.png";
import Shade4bB from "../images/icons/4bB.png";
import Shade4c from "../images/icons/4c.png";
import Shade4cB from "../images/icons/4cB.png";
import Shade4d from "../images/icons/4d.png";
import Shade4dB from "../images/icons/4dB.png";
import Shade4e from "../images/icons/4e.png";
import Shade4eB from "../images/icons/4eB.png";
import Shade4f from "../images/icons/4f.png";
import Shade4fB from "../images/icons/4fB.png";
import Shade5a from "../images/icons/5a.png";
import Shade5aB from "../images/icons/5aB.png";
import Shade5b from "../images/icons/5b.png";
import Shade5bB from "../images/icons/5bB.png";
import Shade5c from "../images/icons/5c.png";
import Shade5cB from "../images/icons/5cB.png";
import Shade5d from "../images/icons/5d.png";
import Shade5dB from "../images/icons/5dB.png";
import Shade5e from "../images/icons/5e.png";
import Shade5eB from "../images/icons/5eB.png";
import Shade5f from "../images/icons/5f.png";
import Shade5fB from "../images/icons/5fB.png";
import Shade6a from "../images/icons/6a.png";
import Shade6aB from "../images/icons/6aB.png";
import Shade6b from "../images/icons/6b.png";
import Shade6bB from "../images/icons/6bB.png";
import Shade6c from "../images/icons/6c.png";
import Shade6cB from "../images/icons/6cB.png";
import Shade6d from "../images/icons/6d.png";
import Shade6dB from "../images/icons/6dB.png";
import Shade6e from "../images/icons/6e.png";
import Shade6eB from "../images/icons/6eB.png";
import Shade6f from "../images/icons/6f.png";
import Shade6fB from "../images/icons/6fB.png";
import T11 from "../images/dental/11.png";
import T11b from "../images/dental/11b.png";
import T12 from "../images/dental/12.png";
import T12b from "../images/dental/12b.png";
import T13 from "../images/dental/13.png";
import T13b from "../images/dental/13b.png";
import T14 from "../images/dental/14.png";
import T14b from "../images/dental/14b.png";
import T15 from "../images/dental/15.png";
import T15b from "../images/dental/15b.png";
import T16 from "../images/dental/16.png";
import T16b from "../images/dental/16b.png";
import T17 from "../images/dental/17.png";
import T17b from "../images/dental/17b.png";
import T18 from "../images/dental/18.png";
import T18b from "../images/dental/18b.png";
import T21 from "../images/dental/21.png";
import T21b from "../images/dental/21b.png";
import T22 from "../images/dental/22.png";
import T22b from "../images/dental/22b.png";
import T23 from "../images/dental/23.png";
import T23b from "../images/dental/23b.png";
import T24 from "../images/dental/24.png";
import T24b from "../images/dental/24b.png";
import T25 from "../images/dental/25.png";
import T25b from "../images/dental/25b.png";
import T26 from "../images/dental/26.png";
import T26b from "../images/dental/26b.png";
import T27 from "../images/dental/27.png";
import T27b from "../images/dental/27b.png";
import T28 from "../images/dental/28.png";
import T28b from "../images/dental/28b.png";
import T31 from "../images/dental/31.png";
import T31b from "../images/dental/31b.png";
import T32 from "../images/dental/32.png";
import T33 from "../images/dental/33.png";
import T34 from "../images/dental/34.png";
import T35 from "../images/dental/35.png";
import T36 from "../images/dental/36.png";
import T37 from "../images/dental/37.png";
import T38 from "../images/dental/38.png";
import T32b from "../images/dental/32b.png";
import T33b from "../images/dental/33b.png";
import T34b from "../images/dental/34b.png";
import T35b from "../images/dental/35b.png";
import T36b from "../images/dental/36b.png";
import T37b from "../images/dental/37b.png";
import T38b from "../images/dental/38b.png";
import T41 from "../images/dental/41.png";
import T42 from "../images/dental/42.png";
import T43 from "../images/dental/43.png";
import T44 from "../images/dental/44.png";
import T45 from "../images/dental/45.png";
import T46 from "../images/dental/46.png";
import T47 from "../images/dental/47.png";
import T48 from "../images/dental/48.png";
import Logo from "../images/media/logo.png";
import T41b from "../images/dental/41b.png";
import T42b from "../images/dental/42b.png";
import T43b from "../images/dental/43b.png";
import T44b from "../images/dental/44b.png";
import T45b from "../images/dental/45b.png";
import T46b from "../images/dental/46b.png";
import T47b from "../images/dental/47b.png";
import T48b from "../images/dental/48b.png";
import Datepicker from "react-datepicker";
import Loader from "../Common/loader";
function LabOrderMetalFreeCeramic() {
  const [state, setState] = useState({
    labTestRequestRequiredDate: "",
    labTestRequestOrderDate: "",
    labTestotherDate: "",
  });
  const [flag, setflag] = useState(false);
  const [saveflag, setSaveflag] = useState(false);
  const [isData, setIsData] = useState(false);
  const [orderDate, setOrderDate] = useState("");
  const [otherDate, setotherDate] = useState("");
  const [logoImage, setLogo] = useState();
  const [requiredDate, setRequiredDate] = useState(null);
  const [hospitalData, setHospitalData] = useState([]);
  const [doctorData, setDoctorData] = useState({
    firstName: "",
    lastName: "",
  });
  const [labName, setLabName] = useState("");
  const [address, setLabAddress] = useState("");
  const [doctorMobile, setdoctorMobile] = useState("");
  const [memberData, setMemberData] = useState([]);
  const [labDetails, setLabdetails] = useState([]);
  const [patientAge, setPatientAge] = useState([]);
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [hospitalPhone, setHospitalPhone] = useState("");
  var orderedDate = orderDate;
  var reqDate = requiredDate;
  var ortheredDate = moment(otherDate).format("YYYY-MM-DD");
  let currentDate = new Date();
  const toDate = moment(currentDate).format("DD-MM-YYYY");
  const [teethNumber, setTeethNumber] = useState("");
  const [teethNumberOne, setTeethNumberOne] = useState("");
  const [teethNumberTwo, setTeethNumberTwo] = useState("");
  const [labFormDetailsId, setlabFormDetailsId] = useState("");
  const [stbShade, setstbShade] = useState("");
  const [mainShade, setmainShade] = useState("");
  const [Cervical, setCervical] = useState("");
  const [Middle, setMiddle] = useState("");
  const [Incisal, setIncisal] = useState("");
  const [notes, setnotes] = useState("");
  const [marketingEx, setmarketingEx] = useState("");
  const [labTestRequestDetails, setLabTestRequestDetails] = useState("");
  const [longTeethArray, setLongTeethArray] = useState([]);
  const [squareTeethArray, setSquareTeethArray] = useState([]);
  const [shortTeethArray, setShortTeethArray] = useState([]);
  const [labFormDetailsIdarray, setLabFormDetailsIdarray] = useState([]);
  const [rushState, setrushState] = useState(false);
  const [dataSet, setDataSet] = useState("");
  const [other, setOther] = useState("");
  const formSplitOther = state.labTestotherDate.toString();
  const otherDay = moment(formSplitOther).format("DD-MM-YYYY");
  var date = moment(orderDate).format("YYYY-MM-DD");
  var time = moment(currentDate).format("hh:mm A");
  const outputTrialDate = moment(state.labTestFrameDate).format("DD-MM-YYYY");
  var framedTrialDate = "" + date + "" + " " + time + "";
  var convertedDate = Date.parse(framedTrialDate);
  const ordereDateChange = (date) => {
    if (date !== "") {
      setOrderDate(date);
    } else {
      setOrderDate(toDate);
    }
  };
  const requiredDateChange = (date) => {
    if (date !== "") {
      setRequiredDate(date);
    } else {
      setRequiredDate("");
    }
  };
  const otherDateChange = (date) => {
    if (date !== "") {
      setotherDate(date);
    } else {
      setotherDate("");
    }
  };
  const onChangeRush = (event) => {
    setrushState(event.target.checked);
  };
  var getS3folder =
    process.env.REACT_APP_S3_URI +
    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
    process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
  const checkedBox = (event, checkedId) => {
    if (event.target.checked == true) {
      labFormDetailsIdarray.push(checkedId);
      setLabFormDetailsIdarray(labFormDetailsIdarray);
    } else {
      const index = labFormDetailsIdarray.indexOf(checkedId);
      if (index > -1) {
        labFormDetailsIdarray.splice(index, 1);
        setLabFormDetailsIdarray(labFormDetailsIdarray);
      }
    }
  };
  const printPage = () => {
    document.getElementById("cancel").style.display = "none";
    document.getElementById("print").style.display = "none";
    document.getElementById("savePrint").style.display = "none";
    document.getElementById("send").style.display = "none";
    window.print();
    document.getElementById("cancel").style.display = "inline-block";
    document.getElementById("print").style.display = "inline-block";
    document.getElementById("savePrint").style.display = "inline-block";
    document.getElementById("send").style.display = "inline-block";
    document.getElementById("send").style.display =
      flag == true ? "none" : "inline-block";
    document.getElementById("savePrint").style.display =
      flag == true ? "none" : "inline-block";
  };
  const changeColor = (id, color, teeth) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
      if (color === "W") {
        document.getElementById("teethT" + id + "W").style.display = "none";
        document.getElementById("teethT" + id + "B").style.display = "block";
      } else {
        document.getElementById("teethT" + id + "W").style.display = "block";
        document.getElementById("teethT" + id + "B").style.display = "none";
      }
      if (teeth === "longteeth") {
        const index = longTeethArray.indexOf(id.toString());
        if (index > -1) {
          longTeethArray.splice(index, 1);
          setLongTeethArray(longTeethArray);
        } else {
          longTeethArray.push(id);
          setLongTeethArray(longTeethArray);
        }
      } else if (teeth === "") {
        const index = squareTeethArray.indexOf(id.toString());
        if (index > -1) {
          squareTeethArray.splice(index, 1);
          setSquareTeethArray(squareTeethArray);
        } else {
          squareTeethArray.push(id);
          setSquareTeethArray(squareTeethArray);
        }
      } else {
        const index = shortTeethArray.indexOf(id.toString());
        if (index > -1) {
          shortTeethArray.splice(index, 1);
          setShortTeethArray(shortTeethArray);
        } else {
          shortTeethArray.push(id);
          setShortTeethArray(shortTeethArray);
        }
      }
    } else {
      if (color === "W") {
        document.getElementById("teethT" + id + "W").style.display = "block";
        document.getElementById("teethT" + id + "B").style.display = "none";
      } else if (color === "B") {
        document.getElementById("teethT" + id + "W").style.display = "none";
        document.getElementById("teethT" + id + "B").style.display = "block";
      }
    }
  };
  const editingId = (event) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
      setIsData(true);
    } else {
      setIsData(false);
      setflag(true);
      document.getElementById("cancel").style.display = "block";
      document.getElementById("print").style.display = "block";
      document.getElementById("savePrint").style.display = "none";
      document.getElementById("send").style.display = "none";
      const postData = {
        doctorId: window.sessionStorage.getItem("patientDoctorId"),
        memberId: window.sessionStorage.getItem("memberId"),
        hospitalId: localStorage.getItem("hospitalId"),
        serviceProviderId: window.sessionStorage.getItem("servprvid"),
        labRequestId: window.sessionStorage.getItem("labReqId"),
      };
      callingAPI("commonUserFunctions/dentalFormsTwo", postData)
        .then((data) => {
          setIsData(true);
          if (data.data.success === "1") {
            setLabTestRequestDetails(
              data.data.result.labTestRequestDetails[0]
                .labTestRequestOrderNumber
            );
            setstbShade(data.data.result.stbShade);
            setmainShade(data.data.result.mainShade);
            setCervical(data.data.result.Cervical);
            setMiddle(data.data.result.Middle);
            setIncisal(data.data.result.Incisal);
            setnotes(data.data.result.notes);
            setmarketingEx(data.data.result.marketingEx);
            setState({
              labTestRequestRequiredDate:
                data.data.result.labTestRequestRequiredDate,
              labTestRequestOrderDate: data.data.result.labTestRequestOrderDate,
              labTestotherDate: data.data.result.otherDate,
            });
            var teethData = data.data.result.teethNumber.split(",");
            teethData.map((itemteethData) => {
              if (itemteethData) {
                document.getElementById(
                  "teethT" + itemteethData + "B"
                ).style.display = "block";
                document.getElementById(
                  "teethT" + itemteethData + "W"
                ).style.display = "none";
              }
            });
            var teethData1 = data.data.result.teethNumber1.split(",");
            teethData1.map((itemteethData1) => {
              if (itemteethData1) {
                document.getElementById(
                  "teethT" + itemteethData1 + "B"
                ).style.display = "block";
                document.getElementById(
                  "teethT" + itemteethData1 + "W"
                ).style.display = "none";
              }
            });
            var teethData2 = data.data.result.teethNumber2.split(",");
            teethData2.map((itemteethData2) => {
              if (itemteethData2) {
                document.getElementById(
                  "teethT" + itemteethData2 + "B"
                ).style.display = "block";
                document.getElementById(
                  "teethT" + itemteethData2 + "W"
                ).style.display = "none";
              }
            });
            if (data.data.result.rush === 1) {
              const rushCheckbox = document.getElementById("teethTC0");
              rushCheckbox.checked = "true";
              rushCheckbox.disabled = "true";
            }
            setlabFormDetailsId(data.data.result.labFormDetailsId);
            var labFormData = data.data.result.labFormDetailsId
              .toString()
              .split(",");
            labFormData.map((labformId) => {
              if (labformId) {
                const checkbox = document.getElementById("teethTC" + labformId);
                checkbox.checked = "true";
                checkbox.disabled = "true";
              }
            });
          }
        })
        .catch();
    }
  };
  const cancelButton = () => {
    window.top.close();
  };
  const saveAndSendOutside = (serviceProviderId) => {
    const postData = {
      siteId: window.sessionStorage.getItem("siteId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendMail", postData).then(
      (data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: "",
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired: "",
              ccEmailRequired: "",
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                alert("Something went wrong");
              } else if (response.data.success === "1") {
                window.location.href = "/Patients/labOrderDental";
              }
            })
            .catch();
        }
      }
    );
  };
  const labOrderSave = () => {
    setIsData(false);
    if (saveflag) {
      return;
    }
    setSaveflag(true);
    var rushValue = "off";
    if (rushState === true) {
      rushValue = "on";
    }
    const laborderSaveParams = {
      rush: rushValue,
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      labFormsId: "2",
      labTestRequestOrderNo: dataSet,
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      Cervical: document.getElementById("Cervical").value,
      Incisal: document.getElementById("Incisal").value,
      Middle: document.getElementById("Middle").value,
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      hidTeethNumber: longTeethArray.toString(),
      hidTeethNumbers1: squareTeethArray.toString(),
      hidTeethNumbers2: shortTeethArray.toString(),
      hospitalId: localStorage.getItem("hospitalId"),
      labFormDetailsId: labFormDetailsIdarray.toString(),
      labTestRequestOrderDate: moment(orderedDate).format("YYYY-MM-DD"),
      labTestRequestRequiredDate:
        reqDate === "" ? "" : moment(reqDate).format("YYYY-MM-DD"),
      mainShade: document.getElementById("mainShade").value,
      marketingEx: document.getElementById("marketingEx").value,
      memberId: window.sessionStorage.getItem("memberId"),
      notes: document.getElementById("notes").value,
      otherDate: otherDate === "" ? "" : moment(otherDate).format("YYYY-MM-DD"),
      savePrint: true,
      stbShade: document.getElementById("stbShade").value,
      userId: window.sessionStorage.getItem("userId"),
      appointmentDate:
        orderDate !== ""
          ? framedTrialDate
          : moment(currentDate).format("YYYY-MM-DD hh:mm A"),
      scheduledtimeSlotStartTime:
        orderDate !== "" ? convertedDate : Date.parse(currentDate),
      scheduledtimeSlotEndTime:
        orderDate !== ""
          ? convertedDate + 15 * 60 * 1000
          : Date.parse(currentDate) + 15 * 60 * 1000,
    };
    callingAPI("adminReports/saveFormSecond", laborderSaveParams)
      .then((response) => {
        setIsData(true);
        if (response.data.success === "1") {
          saveAndSendOutside()
          alert("Lab Test save and send successfully");
          window.location.href = "/Patients/labOrderDental";
        } else {
          alert(response.data.errorMessage);
        }
        setSaveflag(false);
      })
      .catch((err) => {
        setSaveflag(false);
      });
  };
  const labOrderListing = () => {
    const labOrderParams = {
      hospitalId: localStorage.getItem("hospitalId"),
      memberId: window.sessionStorage.getItem("memberId"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      userId: window.sessionStorage.getItem("userId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      labFormsId: "2",
      labTestRequestOrderNo: dataSet,
      labTestRequestRequiredDate: reqDate,
      labTestRequestOrderDate: orderedDate,
      savePrint: true,
      labFormDetailsId: labFormDetailsIdarray.toString(),
    };
    callingAPI("adminReports/dentalFormNewSecond", labOrderParams)
      .then((response) => {
        if (response.data.success === "1") {
          setHospitalData(response.data.result.hospital);
          setHospitalEmail(response.data.result.hospital[0].emailId);
          setHospitalPhone(response.data.result.hospital[0].phone);
          setLogo(response.data.result.logoImage[0].logo_image);
          setDoctorData({
            firstName: response.data.result.doctor[0].first_name,
            lastName: response.data.result.doctor[0].last_name,
          });
          setMemberData(response.data.result.patient);
          setLabName(response.data.result.serviceProviderDetails[0].name);
          setLabAddress(response.data.result.serviceProviderDetails[0].address);
          setLabdetails(response.data.result.serviceProviderDetails);
          setdoctorMobile(response.data.result.doctor[0].mobileNumber);
          setPatientAge(response.data.result.patientAge[0]._year);
          if (response.data.result.logoImage[0].logoImage !== "") {
            setLogo(
              getS3folder + "/" + response.data.result.logoImage[0].logoImage
            );
          } else {
            setLogo(Logo);
          }
        }
      })
      .catch();
  };
  useEffect(() => {
    labOrderListing();
    setDataSet(Math.floor(1000 + Math.random() * 9000));
    var fromDateSplit = toDate.split("-");
    setOrderDate(
      new Date(
        fromDateSplit[2] + "," + fromDateSplit[1] + "," + fromDateSplit[0]
      )
    );
    editingId();
  }, []);
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  return (
    <div className="metalFreeCeramicPdfWrap">
      <body
        style={{
          margin: "0",
          fontSize: "13px",
          fontFamily: "sans-serif",
          fontWeight: "300",
          color: "#000",
          backgrounda: "#fff",
        }}
      >
        <div class="instructionFormWrap1">
          {isData === false ? <Loader /> : ""}
          <div
            class="instructionFormWrap"
            style={{
              width: "980px",
              position: "relative",
              background: "#fff",
              zIndex: "1",
              margin: "auto",
              padding: "5px 0px",
              boxSizing: "border-box",
            }}
          >
            <div
              class="formTop"
              style={{
                clear: "both",
                overflow: "hidden",
                borderBottom: "1px solid #252525",
              }}
            >
              {flag == true ? (
                <div
                  class="slNo"
                  style={{ width: "15%", float: "left", color: "#ff0000" }}
                >
                  <span style={{ float: "left" }}>Sl No: </span>
                  <span
                    style={{
                      fontWeight: "800",
                      width: "70%",
                      fontSize: "15px",
                      float: "left",
                      paddingLeft: "5px",
                    }}
                  >
                    <input
                      type="text"
                      name="siNo"
                      style={{ border: "0", color: "#ff0000" }}
                      readonly="readonly"
                      value={labTestRequestDetails}
                    />{" "}
                  </span>
                </div>
              ) : (
                <div
                  class="slNo"
                  style={{ width: "15%", float: "left", color: "#ff0000" }}
                >
                  <span style={{ float: "left" }}>Sl No: </span>
                  <span
                    style={{
                      fontWeight: "800",
                      width: "70%",
                      fontSize: "15px",
                      float: "left",
                      paddingLeft: "5px",
                    }}
                  >
                    <input
                      type="text"
                      name="siNo"
                      style={{ border: "0", color: "#ff0000" }}
                      readonly="readonly"
                      value={dataSet}
                    />{" "}
                  </span>
                </div>
              )}
              {hospitalData.map((data) => (
                <div
                  class="formName"
                  style={{ width: "70%", float: "left", textAlign: "center" }}
                >
                  <div
                    class="title"
                    style={{
                      fontSize: "25px",
                      fontWeight: "600",
                      color: "#0091d0",
                    }}
                  >
                    <span style={{ color: "#ff0000", verticalAlign: "top" }} />
                    {labName}
                  </div>
                  <div class="address" style={{ margin: "3px 0" }}>
                    . - {address}
                  </div>
                </div>
              ))}
              <div
                class="mailPortion"
                style={{
                  clear: "both",
                  overflow: "hidden",
                  fontSize: "0px",
                  textAlign: "center",
                  paddingTop: "2px",
                }}
              >
                <div
                  class="mailId"
                  style={{
                    display: "inline-block",
                    marginRight: "5px",
                    padding: "2px 7px",
                    background: "#e31e24",
                    color: "#fff",
                    transform: "skew(-15deg)",
                    verticalAlign: "top",
                  }}
                >
                  <span
                    style={{
                      transform: "skew(15deg)",
                      display: "inline-block",
                      fontSize: "10px",
                      float: "left",
                    }}
                  >
                    Doctor Name :
                  </span>
                  <span
                    style={{
                      transform: "skew(15deg)",
                      display: "inline-block",
                      fontSize: "10px",
                      float: "left",
                      paddingLeft: "3px",
                    }}
                  >
                    Dr. {doctorData.firstName} {doctorData.lastName}
                  </span>
                </div>
                <div
                  class="mailId"
                  style={{
                    display: "inline-block",
                    marginRight: "5px",
                    padding: "2px 7px",
                    background: "#e31e24",
                    color: "#fff",
                    transform: "skew(-15deg)",
                    verticalAlign: "top",
                  }}
                >
                  <span
                    style={{
                      transform: "skew(15deg)",
                      display: "inline-block",
                      fontSize: "10px",
                      float: "left",
                    }}
                  >
                    Email Id:
                  </span>
                  <span
                    style={{
                      transform: "skew(15deg)",
                      display: "inline-block",
                      fontSize: "10px",
                      float: "left",
                      paddingLeft: "3px",
                    }}
                  >
                    {hospitalEmail}
                  </span>
                </div>
                <div
                  class="whatsAppNo"
                  style={{
                    display: "inline-block",
                    padding: "2px 7px",
                    background: "#e31e24",
                    color: "#fff",
                    transform: "skew(-15deg)",
                    verticalAlign: "top",
                  }}
                >
                  <span
                    style={{
                      transform: "skew(15deg)",
                      display: "inline-block",
                      fontSize: "10px",
                      float: "left",
                    }}
                  >
                    Phone{" "}
                    <span
                      style={{ fontSize: "9px", verticalAlign: "bottom" }}
                    />{" "}
                    :
                  </span>
                  <span
                    style={{
                      transform: "skew(15deg)",
                      display: "inline-block",
                      fontSize: "10px",
                      float: "left",
                      paddingLeft: "3px",
                    }}
                  >
                    {hospitalPhone}
                  </span>
                </div>
              </div>
            </div>

            <div class="instructionDetails">
              <div
                class="heading"
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  fontSize: "19px",
                  color: "#0091d0",
                }}
              >
                INSTRUCTIONS FOR METAL FREE CERAMIC PROSTHESIS
              </div>
              <div
                class="detailWrap"
                style={{ clear: "both", overflow: "hidden" }}
              >
                <div
                  class="doctorDetail"
                  style={{ width: "540px", float: "left" }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "#000",
                      paddingBottom: "8px",
                      display: "block",
                    }}
                  >
                    LAB DETAILS
                  </span>
                  <div
                    class="doctorDes"
                    style={{
                      border: "1px solid #252525",
                      padding: "2px",
                      clear: "both",
                      overflow: "hidden",
                      height: "136px",
                    }}
                  >
                    <div
                      class="drImg"
                      style={{
                        width: "159px",
                        float: "left",
                        height: "129px",
                        border: "1px solid #252525",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "center",
                          display: "block",
                          width: "62px",
                          margin: "auto",
                          fontSize: "11px",
                          paddingTop: "43px",
                        }}
                      >
                        Barcode Sticker
                      </span>
                    </div>
                    {labDetails.map((data) => (
                      <div
                        class="drDetail"
                        style={{
                          width: "374px",
                          float: "left",
                          paddingLeft: "15px",
                          boxSizing: "border-box",
                        }}
                      >
                        <span style={{ display: "block", fontSize: "8px" }} />

                        <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                          <span style={{ float: "left", paddingRight: "3px" }}>
                            Name :{" "}
                          </span>
                          <div
                            style={{ wordBreak: "break-word", width: "358px" }}
                          >
                            {data.name}
                          </div>
                        </div>

                        <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                          <span style={{ float: "left", paddingRight: "3px" }}>
                            Place :{" "}
                          </span>
                          <div
                            style={{ wordBreak: "break-word", width: "358px" }}
                          >
                            {data.address}
                          </div>
                        </div>
                        <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                          <span style={{ float: "left", paddingRight: "3px" }}>
                            Phone :{" "}
                          </span>
                          <div
                            style={{ wordBreak: "break-word", width: "358px" }}
                          >
                            {data.phone}
                          </div>
                        </div>
                        <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                          <span style={{ float: "left", paddingRight: "3px" }}>
                            Email :{" "}
                          </span>
                          <div
                            style={{ wordBreak: "break-word", width: "358px" }}
                          >
                            {data.emailId}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  class="patientDetail"
                  style={{ width: "425px", float: "left", marginLeft: "15px" }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "#000",
                      paddingBottom: "8px",
                      display: "block",
                      textAlign: "right",
                    }}
                  >
                    PATIENT'S DETAILS
                  </span>

                  <div
                    class="doctorDes"
                    style={{
                      border: "1px solid #252525",
                      padding: "2px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    {memberData.map((data) => (
                      <div
                        class="drDetail"
                        style={{
                          width: "420px",
                          float: "left",
                          paddingLeft: "15px",
                          boxSizing: "border-box",
                          height: "129px",
                        }}
                      >
                        <span style={{ display: "block", fontSize: "8px" }} />
                        <div style={{ paddingTop: "3px", lineHeight: "21px" }}>
                          <span style={{ float: "left", paddingRight: "3px" }}>
                            Name :{" "}
                          </span>
                          <div
                            style={{ wordBreak: "break-word", width: "400px" }}
                          >
                            {data.first_name} {data.last_name}
                          </div>
                        </div>
                        <div
                          style={{
                            paddingTop: "3px",
                            lineHeight: "21px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <div style={{ float: "left", width: "237px" }}>
                            <span
                              style={{ float: "left", paddingRight: "3px" }}
                            >
                              Gender :{" "}
                            </span>
                            <div
                              style={{
                                wordBreak: "break-word",
                                width: "230px",
                              }}
                            >
                              {data.gender}
                            </div>
                            {/* {data.gender} */}
                          </div>
                          <div style={{ float: "right", width: "168px" }}>
                            <span style={{ float: "left" }}>Order Date :</span>
                            <div style={{ float: "left", width: "95px" }}>
                              {flag == true ? (
                                <Datepicker
                                  autoComplete="off"
                                  required
                                  className="Datepicker pa2 LborderdentalPrint  "
                                  calendarClassName="rasta-stripes"
                                  dateFormat="dd-MM-yyyy"
                                  disabled
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChangeRaw={handleDateChangeRaw}
                                  value={state.labTestRequestOrderDate}
                                  id="fromdate"
                                />
                              ) : (
                                <Datepicker
                                  autoComplete="off"
                                  required
                                  className="Datepicker pa2 LborderdentalPrint  "
                                  calendarClassName="rasta-stripes"
                                  dateFormat="dd-MM-yyyy"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChangeRaw={handleDateChangeRaw}
                                  selected={orderDate}
                                  onChange={(date) => {
                                    ordereDateChange(date);
                                  }}
                                  id="fromdate"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            paddingTop: "3px",
                            lineHeight: "21px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <div style={{ float: "left", width: "222px" }}>
                            <span style={{ float: "left" }}>Age : </span>
                            <div> &nbsp;{patientAge}</div>
                          </div>

                          <div style={{ float: "right", width: "183px" }}>
                            <span style={{ float: "left" }}>
                              Required Date :
                            </span>
                            <div style={{ float: "left", width: "90px" }}>
                              {flag == true ? (
                                <Datepicker
                                  autoComplete="off"
                                  required
                                  className="Datepicker pa2 LborderdentalPrint  "
                                  calendarClassName="rasta-stripes"
                                  dateFormat="dd-MM-yyyy"
                                  id="fromdate"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChangeRaw={handleDateChangeRaw}
                                  disabled
                                  // state.labTestRequestRequiredDate==="00-00-0000"?"":
                                  value={
                                    state.labTestRequestRequiredDate ===
                                    "00-00-0000"
                                      ? ""
                                      : state.labTestRequestRequiredDate
                                  }
                                />
                              ) : (
                                <Datepicker
                                  autoComplete="off"
                                  required
                                  className="Datepicker pa2 LborderdentalPrint  "
                                  calendarClassName="rasta-stripes"
                                  dateFormat="dd-MM-yyyy"
                                  id="fromdate"
                                  peekNextMonth
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChangeRaw={handleDateChangeRaw}
                                  showMonthDropdown
                                  // value={requiredDate}
                                  showYearDropdown
                                  dropdownMode="select"
                                  selected={requiredDate}
                                  onChange={(date) => {
                                    requiredDateChange(date);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            paddingTop: "3px",
                            lineHeight: "21px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <div style={{ float: "right", width: "128px" }}>
                            <input
                              value={rushState}
                              onChange={onChangeRush}
                              type="checkbox"
                              name="rush"
                              id="teethTC0"
                              disabled={flag}
                              style={{ margin: "4px 3px 0 0px", float: "left" }}
                            />
                            <div>Rush (10% extra)</div>
                          </div>
                        </div>
                        <div
                          style={{
                            paddingTop: "3px",
                            lineHeight: "21px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <span
                            style={{
                              display: "block",
                              textAlign: "right",
                              paddingRight: "8px",
                            }}
                          >
                            NB : *Give us more time to avail extra benefit.
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div class="formHalfCheck">
              <div
                class="formContent"
                style={{ clear: "both", overflow: "hidden" }}
              >
                <div style={{ float: "left", width: "650px" }}>
                  <div style={{ width: "370px", float: "left" }}>
                    <div
                      className="metalFreePdf"
                      style={{
                        width: "370px",
                        float: "left",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ float: "left", width: "180px" }}>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            padding: "6px 0px",
                            fontSize: "15px",
                            color: "#0091d0",
                            fontWeight: "600",
                          }}
                        >
                          Dentcare Zirconia
                        </div>
                        <div
                          style={{
                            border: "1px solid #000",
                            width: "180px",
                            padding: "5px",
                            boxSizing: "border-box",
                            fontSize: "13px",
                            fontFamily: "sans-serif",
                            fontWeight: "300",
                            color: "#000",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "71")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC71"
                              value="71"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Premium </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "72")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC72"
                              value="72"
                              disabled={flag}
                              style={{
                                margin: " 0px 5px 0 0px",
                                float: "left",
                              }}
                            />
                            <span>Premium Full Contour</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "73")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC73"
                              disabled={flag}
                              value="73"
                              style={{
                                margin: " 0px 5px 0 0px",
                                float: "left",
                              }}
                            />
                            <span>Classic</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "74")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC74"
                              value="74"
                              disabled={flag}
                              style={{
                                margin: " 0px 5px 0 0px",
                                float: "left",
                              }}
                            />
                            <span>Classic </span> Full Contour
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "75")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC75"
                              value="75"
                              disabled={flag}
                              style={{
                                margin: " 0px 5px 0 0px",
                                float: "left",
                              }}
                            />
                            <span>Basic</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "76")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC76"
                              value="76"
                              disabled={flag}
                              style={{
                                margin: " 0px 5px 0 0px",
                                float: "left",
                              }}
                            />
                            <span>Basic Full Contour</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "77")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC77"
                              value="77"
                              disabled={flag}
                              style={{
                                margin: " 0px 5px 0 0px",
                                float: "left",
                              }}
                            />
                            <span>Monolithic</span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "180px",
                            boxSizing: "border-box",
                            marginRight: "15px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              padding: "6px 0px",
                              fontSize: "15px",
                              color: "#0091d0",
                              fontWeight: "600",
                            }}
                          >
                            Dentcare Luminers
                          </div>
                          <div
                            style={{
                              border: "1px solid #000",
                              width: "180px",
                              padding: "5px",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "78")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC78"
                                value="78"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Partial Surface</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "79")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC79"
                                value="79"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Full Surface </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "180px",
                            textAlign: "center",
                            padding: "5px 0px",
                            fontSize: "15px",
                            color: "#0091d0",
                            fontWeight: "600",
                          }}
                        >
                          Ivoclar Vivadent
                        </div>
                        <div
                          style={{
                            border: "1px solid #000",
                            width: "180px",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "80")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC80"
                              value="80"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Zenostar</span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          float: "left",
                          width: "170px",
                          padding: "0 10px 10px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "170px",
                            boxSizing: "border-box",
                            marginRight: "15px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              padding: "6px 0px",
                              fontSize: "15px",
                              color: "#0091d0",
                              fontWeight: "600",
                            }}
                          >
                            Cercon (Dentsply)
                          </div>
                          <div
                            style={{
                              border: "1px solid #000",
                              width: "170px",
                              padding: "10px",
                              boxSizing: "border-box",
                              height: "100px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "81")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC81"
                                value="81"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Cercon HT</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "82")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC82"
                                value="82"
                                disabled={flag}
                                style={{
                                  margin: " 0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>HT Full Contour</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "83")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC83"
                                value="83"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Cercon</span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "170px",
                            textAlign: "center",
                            padding: "6px 0px",
                            fontSize: "15px",
                            color: "#0091d0",
                            fontWeight: "600",
                          }}
                        >
                          Lava 3M ESPE
                        </div>
                        <div
                          style={{
                            width: "170px",
                            boxSizing: "border-box",
                            paddingTop: "10px",
                            marginRight: "15px",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #000",
                              width: "170px",
                              padding: "5px",
                              boxSizing: "border-box",
                              fontSize: "13px",
                              fontFamily: "sans-serif",
                              fontWeight: "300",
                              color: "#000",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "84")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC84"
                                value="84"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span> Ultimate </span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "85")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC85"
                                value="85"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Premium</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "86")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC86"
                                value="86"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Premium Full Contour</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "87")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC87"
                                value="87"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>3M Translucent</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "88")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC88"
                                value="88"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>Classic</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "89")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC89"
                                value="89"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "sans-serif",
                                  fontWeight: "300",
                                  color: "#000",
                                }}
                              >
                                Classic Full Contour{" "}
                              </span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "90")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC90"
                                value="90"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>3M Essential</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "91")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC91"
                                value="91"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>3M Essential Full Contour</span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "12px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                onChange={(event) => checkedBox(event, "92")}
                                type="checkbox"
                                name="txtDental"
                                id="teethTC92"
                                value="92"
                                disabled={flag}
                                style={{
                                  margin: "0px 5px 0 0px",
                                  float: "left",
                                }}
                              />
                              <span>3M Monolith</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="pdfViewMetalFree"
                      style={{
                        width: "370px",
                        float: "left",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "180px",
                          float: "left",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #000",
                            width: "180px",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#0091d0",
                                fontWeight: "bold",
                              }}
                            >
                              Implant{" "}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "93")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC93"
                              value="93"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Cement Retained</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "94")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC94"
                              value="94"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Screw Retained</span>
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #000",
                            width: "180px",
                            padding: "5px",
                            boxSizing: " border-box",
                            borderTop: "0",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                              }}
                            >
                              Trial{" "}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "95")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC95"
                              value="95"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Frame</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "96")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC96"
                              value="96"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Bisque (Body)</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "97")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC97"
                              value="97"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Scanned Image</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <span style={{ display: "flex" }}>
                              <span>Date</span>
                              {flag == true ? (
                                <Datepicker
                                  autoComplete="off"
                                  required
                                  className="Datepicker pa2 LborderdentalPrint  "
                                  calendarClassName="rasta-stripes"
                                  dateFormat="dd-MM-yyyy"
                                  id="fromdate"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChangeRaw={handleDateChangeRaw}
                                  disabled
                                  value={
                                    otherDay === "Invalid date" ? "" : otherDay
                                  }
                                  style={{ width: "60% !important" }}
                                />
                              ) : (
                                <Datepicker
                                  autoComplete="off"
                                  required
                                  //value={otherDate}
                                  className="Datepicker pa2 LborderdentalPrint  "
                                  calendarClassName="rasta-stripes"
                                  dateFormat="dd-MM-yyyy"
                                  selected={otherDate}
                                  onChange={(date) => {
                                    otherDateChange(date);
                                  }}
                                  id="fromdate"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChangeRaw={handleDateChangeRaw}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  style={{ width: "60% !important" }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "180px",
                          float: "left",
                          boxSizing: "border-box",
                          float: "left",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #000",
                            width: "180px",
                            padding: "5px",
                            boxSizing: "border-box",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#0091d0",
                                fontWeight: "bold",
                              }}
                            >
                              Proximal Contact{" "}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "50%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>Broad</span>
                            <input
                              onChange={(event) => checkedBox(event, "98")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC98"
                              value="98"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "50%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>Point</span>
                            <input
                              onChange={(event) => checkedBox(event, "99")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC99"
                              value="99"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #000",
                            borderTop: "0",
                            width: "180px",
                            padding: "5px",
                            boxSizing: "border-box",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#0091d0",
                                fontWeight: "bold",
                              }}
                            >
                              Texture
                            </span>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>Low</span>
                            <input
                              onChange={(event) => checkedBox(event, "100")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC100"
                              value="100"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "40%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>Medium</span>
                            <input
                              onChange={(event) => checkedBox(event, "101")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC101"
                              value="101"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "30%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>High</span>
                            <input
                              onChange={(event) => checkedBox(event, "102")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC102"
                              value="102"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #000",
                            borderTop: "0",
                            width: "180px",
                            padding: "5px",
                            boxSizing: "border-box",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#0091d0",
                                fontWeight: "bold",
                              }}
                            >
                              Glaze
                            </span>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>Low</span>
                            <input
                              onChange={(event) => checkedBox(event, "103")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC103"
                              value="103"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "40%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>Medium</span>
                            <input
                              onChange={(event) => checkedBox(event, "104")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC104"
                              value="104"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "30%",
                              paddingBottom: "12px",
                              float: "left",
                            }}
                          >
                            <span>High</span>
                            <input
                              onChange={(event) => checkedBox(event, "105")}
                              type="checkbox"
                              name="txtDental"
                              id="teethTC105"
                              value="105"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ float: "left", width: "280px" }}>
                    <div
                      style={{
                        width: "240px",
                        boxSizing: "border-box",
                        paddingTop: "10px",
                        marginRight: "15px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "6px 0px",
                          fontSize: "15px",
                          color: "#0091d0",
                          fontWeight: "600",
                        }}
                      >
                        Procera
                      </div>
                      <div
                        style={{
                          border: "1px solid #000",
                          padding: "5px",
                          boxSizing: "border-box",
                          height: "55px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: "6px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "106")}
                            type="checkbox"
                            name="txtDental"
                            id="teethTC106"
                            value="106"
                            disabled={flag}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Alumina</span>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: "6px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "107")}
                            type="checkbox"
                            name="txtDental"
                            id="teethTC107"
                            value="107"
                            disabled={flag}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Zirconia</span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        border: "0px solid #000",
                        width: "240px",
                        padding: "5px",
                        boxSizing: "border-box",
                        height: "64px",
                        marginTop: "0px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "108")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC108"
                          value="108"
                          disabled={flag}
                          style={{ margin: "2px 5px 0 0px", float: "left" }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            color: "#0091d0",
                            fontWeight: "600",
                            float: "left",
                            marginRight: "15px",
                          }}
                        >
                          {" "}
                          e.Max press
                        </span>
                        <input
                          onChange={(event) => checkedBox(event, "109")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC109"
                          value="109"
                          disabled={flag}
                          style={{ margin: "2px 5px 0 0px", float: "left" }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            color: " #0091d0",
                            fontWeight: "600",
                            float: "left",
                          }}
                        >
                          CAD
                        </span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "110")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC110"
                          value="110"
                          disabled={flag}
                          style={{ margin: "2px 5px 0 0px", float: "left" }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            color: "#0091d0",
                            fontWeight: "600",
                            float: "left",
                            marginRight: "15px",
                          }}
                        >
                          IPS Empress
                        </span>
                        <input
                          onChange={(event) => checkedBox(event, "111")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC111"
                          value="111"
                          disabled={flag}
                          style={{ margin: "2px 5px 0 0px", float: "left" }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            color: " #0091d0",
                            fontWeight: "600",
                            float: "left",
                          }}
                        >
                          CAD
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        border: "1px solid #000",
                        width: "240px",
                        padding: "5px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "112")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC112"
                          value="112"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Crown Layering</span>
                      </div>
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "113")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC113"
                          value="113"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Crown Staining</span>
                      </div>
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "115")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC115"
                          value="115"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Veneer Layering</span>
                      </div>
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "114")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC114"
                          value="114"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Veneer Staining</span>
                      </div>
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "116")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC116"
                          value="116"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Cosmopost</span>
                      </div>
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "117")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC117"
                          value="117"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Inlay</span>
                      </div>
                      <div
                        className="pdfView"
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "118")}
                          type="checkbox"
                          name="txtDental"
                          id="teethTC118"
                          value="118"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Onlay </span>
                      </div>
                    </div>

                    <div
                      className="metalFreePdfTeeth"
                      style={{
                        boxSizing: "border-box",
                        width: "260px",
                        float: "left",
                        height: "430px",
                        padding: "30px",
                      }}
                    >
                      <div
                        style={{
                          boxSizing: "border-box",
                          width: "200px",
                          height: "385px",
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "178px",
                            left: "0px",
                            fontWeight: "600",
                          }}
                        >
                          RIGHT
                        </span>
                        <span
                          style={{
                            position: "absolute",
                            top: "179px",
                            left: "168px",
                            fontWeight: "600",
                          }}
                        >
                          LEFT
                        </span>
                        <span
                          style={{
                            position: "absolute",
                            top: "38px",
                            left: "76px",
                            textAlign: "center",
                            fontWeight: "600",
                          }}
                        >
                          UPPER
                        </span>
                        <span
                          style={{
                            position: "absolute",
                            top: "317px",
                            left: "75px",
                            textAlign: "center",
                            fontWeight: "600",
                          }}
                        >
                          LOWER
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "136px",
                            left: "-17px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "8px",
                              fontWeight: "600",
                            }}
                          >
                            18
                          </span>

                          <img
                            onClick={(e) => changeColor("18", "W", "longteeth")}
                            id="teethT18W"
                            src={T18}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("18", "B", "longteeth")}
                            id="teethT18B"
                            src={T18b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "201px",
                            left: "-21px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "8px",
                              fontWeight: "600",
                            }}
                          >
                            48
                          </span>

                          <img
                            onClick={(e) => changeColor("48", "W", "longteeth")}
                            id="teethT48W"
                            src={T48}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("48", "B", "longteeth")}
                            id="teethT48B"
                            src={T48b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "106px",
                            left: "-13px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "8px",
                              fontWeight: "600",
                            }}
                          >
                            17
                          </span>

                          <img
                            onClick={(e) => changeColor("17", "W", "longteeth")}
                            id="teethT17W"
                            src={T17}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("17", "B", "longteeth")}
                            id="teethT17B"
                            src={T17b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "233px",
                            left: "-15px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "8px",
                              fontWeight: "600",
                            }}
                          >
                            47
                          </span>

                          <img
                            onClick={(e) => changeColor("47", "W", "longteeth")}
                            id="teethT47W"
                            src={T47}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("47", "B", "longteeth")}
                            id="teethT47B"
                            src={T47b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "79px",
                            left: "-8px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "7px",
                              fontWeight: "600",
                            }}
                          >
                            16
                          </span>

                          <img
                            src={T16}
                            onClick={(e) => changeColor("16", "W", "longteeth")}
                            id="teethT16W"
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("16", "B", "longteeth")}
                            id="teethT16B"
                            src={T16b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "262px",
                            left: "-10px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "8px",
                              fontWeight: "600",
                            }}
                          >
                            46
                          </span>

                          <img
                            onClick={(e) => changeColor("46", "W", "longteeth")}
                            id="teethT46W"
                            src={T46}
                            style={{
                              width: "32px",
                              height: "32px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("46", "B", "longteeth")}
                            id="teethT46B"
                            src={T46b}
                            style={{
                              width: "32px",
                              height: "32px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "55px",
                            left: "1px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            15
                          </span>

                          <img
                            onClick={(e) => changeColor("15", "W", "longteeth")}
                            id="teethT15W"
                            src={T15}
                            style={{
                              width: "32px",
                              height: "29px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("15", "B", "longteeth")}
                            id="teethT15B"
                            src={T15b}
                            style={{
                              width: "32px",
                              height: "29px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "289px",
                            left: "3px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "11px",
                              fontWeight: "600",
                            }}
                          >
                            45
                          </span>

                          <img
                            onClick={(e) => changeColor("45", "W", "longteeth")}
                            id="teethT45W"
                            src={T45}
                            style={{
                              width: "27px",
                              height: "27px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("45", "B", "longteeth")}
                            id="teethT45B"
                            src={T45b}
                            style={{
                              width: "27px",
                              height: "27px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "31px",
                            left: "10px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "2px",
                              fontWeight: "600",
                            }}
                          >
                            14
                          </span>

                          <img
                            onClick={(e) => changeColor("14", "W", "longteeth")}
                            id="teethT14W"
                            src={T14}
                            style={{
                              width: "33px",
                              height: "33px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("14", "B", "longteeth")}
                            id="teethT14B"
                            src={T14b}
                            style={{
                              width: "33px",
                              height: "33px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "308px",
                            left: "10px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              paddingTop: "15px",
                              fontWeight: "600",
                            }}
                          >
                            44
                          </span>

                          <img
                            onClick={(e) => changeColor("44", "W", "longteeth")}
                            id="teethT44W"
                            src={T44}
                            style={{
                              width: "26px",
                              height: "26px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("44", "B", "longteeth")}
                            id="teethT44B"
                            src={T44b}
                            style={{
                              width: "26px",
                              height: "26px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "14px",
                            left: "23px",
                            marginLeft: "21px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              left: "-16px",
                              top: "-5px",
                            }}
                          >
                            13
                          </span>

                          <img
                            onClick={(e) => changeColor("13", "W", "longteeth")}
                            id="teethT13W"
                            src={T13}
                            style={{
                              width: "24px",
                              height: "26px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("13", "B", "longteeth")}
                            id="teethT13B"
                            src={T13b}
                            style={{
                              width: "24px",
                              height: "26px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "327px",
                            left: "20px",
                            marginLeft: "19px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "22px",
                              left: "-10px",
                            }}
                          >
                            43
                          </span>

                          <img
                            onClick={(e) => changeColor("43", "W", "longteeth")}
                            id="teethT43W"
                            src={T43}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("43", "B", "longteeth")}
                            id="teethT43B"
                            src={T43b}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "4px",
                            left: "40px",
                            marginLeft: "21px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              left: "-10px",
                              top: "-15px",
                            }}
                          >
                            12
                          </span>

                          <img
                            onClick={(e) => changeColor("12", "W", "longteeth")}
                            id="teethT12W"
                            src={T12}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("12", "B", "longteeth")}
                            id="teethT12B"
                            src={T12b}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "338px",
                            left: "37px",
                            marginLeft: "18px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "28px",
                              left: "-3px",
                              fontWeight: "600",
                            }}
                          >
                            42
                          </span>

                          <img
                            onClick={(e) => changeColor("42", "W", "longteeth")}
                            id="teethT42W"
                            src={T42}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("42", "B", "longteeth")}
                            id="teethT42B"
                            src={T42b}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "-1px",
                            left: "59px",
                            marginLeft: "21px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "7px",
                              top: "-15px",
                              fontWeight: "600",
                            }}
                          >
                            11
                          </span>

                          <img
                            onClick={(e) => changeColor("11", "W", "longteeth")}
                            id="teethT11W"
                            src={T11}
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("11", "B", "longteeth")}
                            id="teethT11B"
                            src={T11b}
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "340px",
                            left: "56px",
                            marginLeft: "20px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "30px",
                              left: "3px",
                              fontWeight: "600",
                            }}
                          >
                            41
                          </span>

                          <img
                            onClick={(e) => changeColor("41", "W", "longteeth")}
                            id="teethT41W"
                            src={T41}
                            style={{
                              width: "23px",
                              height: "27px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("41", "B", "longteeth")}
                            id="teethT41B"
                            src={T41b}
                            style={{
                              width: "23px",
                              height: "27px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "-2px",
                            left: "79px",
                            marginLeft: "21px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "-15px",
                              left: "10px",
                            }}
                          >
                            21
                          </span>

                          <img
                            onClick={(e) => changeColor("21", "W", "longteeth")}
                            id="teethT21W"
                            src={T21}
                            style={{
                              width: "25px",
                              height: "29px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("21", "B", "longteeth")}
                            id="teethT21B"
                            src={T21b}
                            style={{
                              width: "25px",
                              height: "29px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "340px",
                            left: "75px",
                            marginLeft: "19px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "30px",
                              left: "10px",
                              fontWeight: "600",
                            }}
                          >
                            31
                          </span>

                          <img
                            onClick={(e) => changeColor("31", "W", "longteeth")}
                            id="teethT31W"
                            src={T31}
                            style={{
                              width: "24px",
                              height: "26px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("31", "B", "longteeth")}
                            id="teethT31B"
                            src={T31b}
                            style={{
                              width: "24px",
                              height: "26px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "4px",
                            left: "99px",
                            marginLeft: "19px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "-13px",
                              left: "20px",
                            }}
                          >
                            22
                          </span>

                          <img
                            onClick={(e) => changeColor("22", "W", "longteeth")}
                            id="teethT22W"
                            src={T22}
                            style={{
                              width: "25px",
                              height: "28px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("22", "B", "longteeth")}
                            id="teethT22B"
                            src={T22b}
                            style={{
                              width: "25px",
                              height: "28px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "335px",
                            left: "94px",
                            marginLeft: "18px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "30px",
                              left: "15px",
                            }}
                          >
                            32
                          </span>

                          <img
                            onClick={(e) => changeColor("32", "W", "longteeth")}
                            id="teethT32W"
                            src={T32}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("32", "B", "longteeth")}
                            id="teethT32B"
                            src={T32b}
                            style={{
                              width: "24px",
                              height: "25px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "18px",
                            left: "115px",
                            marginLeft: "18px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "-8px",
                              left: "29px",
                            }}
                          >
                            23
                          </span>

                          <img
                            onClick={(e) => changeColor("23", "W", "longteeth")}
                            id="teethT23W"
                            src={T23}
                            style={{
                              width: "26px",
                              height: "26px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("23", "B", "longteeth")}
                            id="teethT23B"
                            src={T23b}
                            style={{
                              width: "26px",
                              height: "26px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "329px",
                            left: "110px",
                            marginLeft: "22px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "25px",
                              left: "20px",
                            }}
                          >
                            33
                          </span>

                          <img
                            onClick={(e) => changeColor("33", "W", "longteeth")}
                            id="teethT33W"
                            src={T33}
                            style={{
                              width: "24px",
                              height: "24px",
                              cursor: "pointer",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("33", "B", "longteeth")}
                            id="teethT33B"
                            src={T33b}
                            style={{
                              width: "24px",
                              height: "24px",
                              cursor: "pointer",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "37px",
                            left: "126px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "2px",
                              left: "50px",
                            }}
                          >
                            24
                          </span>

                          <img
                            onClick={(e) => changeColor("24", "W", "longteeth")}
                            id="teethT24W"
                            src={T24}
                            style={{
                              width: "29px",
                              height: "29px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("24", "B", "longteeth")}
                            id="teethT24B"
                            src={T24b}
                            style={{
                              width: "29px",
                              height: "29px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "305px",
                            left: "119px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "21px",
                              left: "47px",
                            }}
                          >
                            34
                          </span>

                          <img
                            onClick={(e) => changeColor("34", "W", "longteeth")}
                            id="teethT34W"
                            src={T34}
                            style={{
                              width: "27px",
                              height: "27px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("34", "B", "longteeth")}
                            id="teethT34B"
                            src={T34b}
                            style={{
                              width: "27px",
                              height: "27px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "60px",
                            left: "135px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "5px",
                              left: "50px",
                            }}
                          >
                            25
                          </span>

                          <img
                            onClick={(e) => changeColor("25", "W", "longteeth")}
                            id="teethT25W"
                            src={T25}
                            style={{
                              width: "29px",
                              height: "29px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("25", "B", "longteeth")}
                            id="teethT25B"
                            src={T25b}
                            style={{
                              width: "29px",
                              height: "29px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "286px",
                            left: "128px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "16px",
                              left: "50px",
                            }}
                          >
                            35
                          </span>

                          <img
                            onClick={(e) => changeColor("35", "W", "longteeth")}
                            id="teethT35W"
                            src={T35}
                            style={{
                              width: "27px",
                              height: "27px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("35", "B", "longteeth")}
                            id="teethT35B"
                            src={T35b}
                            style={{
                              width: "27px",
                              height: "27px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "85px",
                            left: "140px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "6px",
                              left: "54px",
                            }}
                          >
                            26
                          </span>

                          <img
                            onClick={(e) => changeColor("26", "W", "longteeth")}
                            id="teethT26W"
                            src={T26}
                            style={{
                              width: "31px",
                              height: "31px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("26", "B", "longteeth")}
                            id="teethT26B"
                            src={T26b}
                            style={{
                              width: "31px",
                              height: "31px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "260px",
                            left: "135px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "13px",
                              left: "55px",
                            }}
                          >
                            36
                          </span>

                          <img
                            onClick={(e) => changeColor("36", "W", "longteeth")}
                            id="teethT36W"
                            src={T36}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("36", "B", "longteeth")}
                            id="teethT36B"
                            src={T36b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "111px",
                            left: "145px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "6px",
                              left: "54px",
                            }}
                          >
                            27
                          </span>

                          <img
                            onClick={(e) => changeColor("27", "W", "longteeth")}
                            id="teethT27W"
                            src={T27}
                            style={{
                              width: "31px",
                              height: "31px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("27", "B", "longteeth")}
                            id="teethT27B"
                            src={T27b}
                            style={{
                              width: "31px",
                              height: "31px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "232px",
                            left: "142px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "17px",
                              left: "55px",
                            }}
                          >
                            37
                          </span>

                          <img
                            onClick={(e) => changeColor("37", "W", "longteeth")}
                            id="teethT37W"
                            src={T37}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("37", "B", "longteeth")}
                            id="teethT37B"
                            src={T37b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "140px",
                            left: "148px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "7px",
                              left: "55px",
                            }}
                          >
                            28
                          </span>

                          <img
                            onClick={(e) => changeColor("28", "W", "longteeth")}
                            id="teethT28W"
                            src={T28}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("28", "B", "longteeth")}
                            id="teethT28B"
                            src={T28b}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "199px",
                            left: "146px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              fontWeight: "600",
                              top: "20px",
                              left: "57px",
                            }}
                          >
                            38
                          </span>

                          <img
                            onClick={(e) => changeColor("38", "W", "longteeth")}
                            id="teethT38W"
                            src={T38}
                            style={{
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                              marginLeft: "18px",
                            }}
                          />

                          <img
                            onClick={(e) => changeColor("38", "B", "longteeth")}
                            id="teethT38B"
                            src={T38b}
                            style={{
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                              marginLeft: "18px",
                              display: "none",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "330px", float: "left" }}>
                  <div
                    style={{
                      width: "308px",
                      border: "1px solid #000",
                      float: "left",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "25%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <span>Discoloration</span>
                    </div>
                    <div
                      style={{
                        width: "25%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <img
                        onClick={(e) => changeColor("90", "W", "")}
                        id="teethT90W"
                        src={Large}
                        style={{
                          marginLeft: "21px",
                          display: "block",
                          float: "left",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("90", "B", "")}
                        id="teethT90B"
                        src={LargeB}
                        style={{
                          marginLeft: "21px",
                          display: "none",
                          float: "left",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "25%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <img
                        onClick={(e) => changeColor("91", "W", "")}
                        id="teethT91W"
                        src={Medium}
                        style={{
                          marginLeft: "21px",
                          display: "block",
                          float: "left",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("91", "B", "")}
                        id="teethT91B"
                        src={MediumB}
                        style={{
                          marginLeft: "21px",
                          display: "none",
                          float: "left",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "25%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <img
                        onClick={(e) => changeColor("92", "W", "")}
                        id="teethT92W"
                        src={D}
                        style={{
                          marginLeft: "21px",
                          display: "block",
                          float: "left",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("92", "B", "")}
                        id="teethT92B"
                        src={DB}
                        style={{
                          marginLeft: "21px",
                          display: "none",
                          float: "left",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "7px",
                        clear: "both",
                        overflow: "hidden",
                      }}
                    >
                      <span style={{ float: "left" }}>Stump Shade</span>
                      {flag == true ? (
                        <input
                          type="text"
                          style={{ marginLeft: "6px" }}
                          maxlength="20"
                          id="stbShade"
                          disabled
                          value={stbShade}
                          name="stbShade"
                        />
                      ) : (
                        <input
                          type="text"
                          style={{ marginLeft: "6px" }}
                          maxlength="20"
                          id="stbShade"
                          name="stbShade"
                        />
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <div style={{ float: "left", paddingRight: "20px" }}>
                        Gingiva Colour
                      </div>
                      <input
                        onChange={(event) => checkedBox(event, "119")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC119"
                        value="119"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div style={{ float: "left", paddingRight: "20px" }}>
                        Yes
                      </div>
                      <input
                        onChange={(event) => checkedBox(event, "120")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC120"
                        disabled={flag}
                        value="120"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div style={{ float: "left" }}>No</div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "121")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC121"
                        value="121"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div style={{ float: "left", paddingRight: "20px" }}>
                        Individual
                      </div>
                      <input
                        onChange={(event) => checkedBox(event, "122")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC122"
                        value="122"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div style={{ float: "left" }}>Splinted (joined)</div>
                    </div>
                  </div>
                </div>
                <div
                  className="enclosurePdf"
                  style={{
                    width: "308px",
                    border: "1px solid #000",
                    float: "left",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ color: "#FF0000", float: "left" }}>Shade</div>
                    {flag == true ? (
                      <input
                        type="text"
                        style={{ marginLeft: "10px" }}
                        maxlength="20"
                        id="mainShade"
                        disabled
                        value={mainShade}
                        name="mainShade"
                      />
                    ) : (
                      <input
                        type="text"
                        style={{ marginLeft: "10px" }}
                        maxlength="20"
                        id="mainShade"
                        name="mainShade"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    {flag == true ? (
                      <input
                        type="text"
                        maxlength="2"
                        id="Cervical"
                        value={Cervical}
                        disabled
                        name="Cervical"
                        style={{ marginLeft: "5px", width: "30px" }}
                      />
                    ) : (
                      <input
                        type="text"
                        maxlength="2"
                        id="Cervical"
                        name="Cervical"
                        style={{ marginLeft: "5px", width: "30px" }}
                      />
                    )}
                    <div style={{ float: "left" }}>Cervical</div>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left" }}>Middle</div>
                    {flag == true ? (
                      <input
                        type="text"
                        id="Middle"
                        value={Middle}
                        disabled
                        maxlength="2"
                        name="Middle"
                        style={{ marginLeft: "10px", width: "30px" }}
                      />
                    ) : (
                      <input
                        type="text"
                        id="Middle"
                        maxlength="2"
                        name="Middle"
                        style={{ marginLeft: "10px", width: "30px" }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left" }}>Incisal</div>
                    {flag == true ? (
                      <input
                        type="text"
                        id="Incisal"
                        value={Incisal}
                        disabled
                        maxlength="2"
                        name="Incisal"
                        style={{ marginLeft: "10px", width: "30px" }}
                      />
                    ) : (
                      <input
                        type="text"
                        id="Incisal"
                        maxlength="2"
                        name="Incisal"
                        style={{ marginLeft: "10px", width: "30px" }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Digital photo/E-mail/Whatsapp
                    </div>
                    <input
                      onChange={(event) => checkedBox(event, "123")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC123"
                      value="123"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      clear: "both",
                      height: "73px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <span>
                      <img
                        onClick={(e) => changeColor("51", "W", "shortteeth")}
                        id="teethT51W"
                        src={Shade1a}
                        style={{ position: "absolute", left: "4px", top: "0" }}
                      />
                      <img
                        onClick={(e) => changeColor("51", "B", "shortteeth")}
                        id="teethT51B"
                        src={Shade1aB}
                        style={{
                          position: "absolute",
                          left: "4px",
                          top: "0",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("52", "W", "shortteeth")}
                        id="teethT52W"
                        src={Shade1b}
                        style={{ position: "absolute", left: "25px", top: "0" }}
                      />
                      <img
                        onClick={(e) => changeColor("52", "B", "shortteeth")}
                        id="teethT52B"
                        src={Shade1bB}
                        style={{
                          position: "absolute",
                          left: "25px",
                          top: "0",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("53", "W", "shortteeth")}
                        id="teethT53W"
                        src={Shade1c}
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "24px",
                          width: "24px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("53", "B", "shortteeth")}
                        id="teethT53B"
                        src={Shade1cB}
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "24px",
                          width: "24px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("54", "W", "shortteeth")}
                        id="teethT54W"
                        src={Shade1d}
                        style={{
                          position: "absolute",
                          left: "24px",
                          top: "24px",
                          width: "22px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("54", "B", "shortteeth")}
                        id="teethT54B"
                        src={Shade1dB}
                        style={{
                          position: "absolute",
                          left: "24px",
                          top: "24px",
                          width: "22px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("55", "W", "shortteeth")}
                        id="teethT55W"
                        src={Shade1e}
                        style={{
                          position: "absolute",
                          left: "-2px",
                          top: "44px",
                          width: "26px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("55", "B", "shortteeth")}
                        id="teethT55B"
                        src={Shade1eB}
                        style={{
                          position: "absolute",
                          left: "-2px",
                          top: "44px",
                          display: "none",
                          width: "26px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("56", "W", "shortteeth")}
                        id="teethT56W"
                        src={Shade1f}
                        style={{
                          position: "absolute",
                          left: "24px",
                          top: "43px",
                          width: "20px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("56", "B", "shortteeth")}
                        id="teethT56B"
                        src={Shade1fB}
                        style={{
                          position: "absolute",
                          left: "24px",
                          top: "43px",
                          width: "20px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("57", "W", "shortteeth")}
                        id="teethT57W"
                        src={Shade2a}
                        style={{
                          position: "absolute",
                          left: "44px",
                          top: "4px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("57", "B", "shortteeth")}
                        id="teethT57B"
                        src={Shade2aB}
                        style={{
                          position: "absolute",
                          left: "44px",
                          top: "4px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("58", "W", "shortteeth")}
                        id="teethT58W"
                        src={Shade2b}
                        style={{
                          position: "absolute",
                          left: "67px",
                          top: "5px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("58", "B", "shortteeth")}
                        id="teethT58B"
                        src={Shade2bB}
                        style={{
                          position: "absolute",
                          left: "67px",
                          top: "5px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("59", "W", "shortteeth")}
                        id="teethT59W"
                        src={Shade2c}
                        style={{
                          position: "absolute",
                          left: "42px",
                          top: "26px",
                          width: "25px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("59", "B", "shortteeth")}
                        id="teethT59B"
                        src={Shade2cB}
                        style={{
                          position: "absolute",
                          left: "43px",
                          top: "27px",
                          display: "none",
                          width: "25px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("60", "W", "shortteeth")}
                        id="teethT60W"
                        src={Shade2d}
                        style={{
                          position: "absolute",
                          left: "66px",
                          top: "27px",
                          width: "22px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("60", "B", "shortteeth")}
                        id="teethT60B"
                        src={Shade2dB}
                        style={{
                          position: "absolute",
                          left: "66px",
                          top: "27px",
                          width: "22px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("61", "W", "shortteeth")}
                        id="teethT61W"
                        src={Shade2e}
                        style={{
                          position: "absolute",
                          left: "40px",
                          top: "46px",
                          width: "26px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("61", "B", "shortteeth")}
                        id="teethT61B"
                        src={Shade2eB}
                        style={{
                          position: "absolute",
                          left: "40px",
                          top: "46px",
                          display: "none",
                          width: "26px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("62", "W", "shortteeth")}
                        id="teethT62W"
                        src={Shade2f}
                        style={{
                          position: "absolute",
                          left: "58px",
                          top: "46px",
                          width: "26px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("62", "B", "shortteeth")}
                        id="teethT62B"
                        src={Shade2fB}
                        style={{
                          position: "absolute",
                          left: "58px",
                          top: "46px",
                          display: "none",
                          width: "26px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("63", "W", "shortteeth")}
                        id="teethT63W"
                        src={Shade3a}
                        style={{
                          position: "absolute",
                          left: "88px",
                          top: "0px",
                          width: "20px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("63", "B", "shortteeth")}
                        id="teethT63B"
                        src={Shade3aB}
                        style={{
                          position: "absolute",
                          left: "88px",
                          top: "0px",
                          display: "none",
                          width: "20px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("64", "W", "shortteeth")}
                        id="teethT64W"
                        src={Shade3b}
                        style={{
                          position: "absolute",
                          left: "107px",
                          top: "0px",
                          height: "22px",
                        }}
                      />
                      <img
                        src={Shade3bB}
                        onClick={(e) => changeColor("64", "B", "shortteeth")}
                        id="teethT64B"
                        style={{
                          position: "absolute",
                          left: "107px",
                          top: "0px",
                          height: "22px",
                          display: "none",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("65", "W", "shortteeth")}
                        id="teethT65W"
                        src={Shade3c}
                        style={{
                          position: "absolute",
                          left: "84px",
                          top: "21px",
                          width: "24px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("65", "B", "shortteeth")}
                        id="teethT65B"
                        src={Shade3cB}
                        style={{
                          position: "absolute",
                          left: "86px",
                          top: "21px",
                          display: "none",
                          width: "24px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("66", "W", "shortteeth")}
                        id="teethT66W"
                        src={Shade3d}
                        style={{
                          position: "absolute",
                          left: "106px",
                          top: "21px",
                          width: "21px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("66", "B", "shortteeth")}
                        id="teethT66B"
                        src={Shade3dB}
                        style={{
                          position: "absolute",
                          left: "104px",
                          top: "21px",
                          display: "none",
                          width: "21px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("67", "W", "shortteeth")}
                        id="teethT67W"
                        src={Shade3e}
                        style={{
                          position: "absolute",
                          left: "82px",
                          top: "42px",
                          width: "26px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("67", "B", "shortteeth")}
                        id="teethT67B"
                        src={Shade3eB}
                        style={{
                          position: "absolute",
                          left: "82px",
                          top: "42px",
                          display: "none",
                          width: "26px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("69", "W", "shortteeth")}
                        id="teethT69W"
                        src={Shade3f}
                        style={{
                          position: "absolute",
                          left: "106px",
                          top: "42px",
                          width: "26px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("69", "B", "shortteeth")}
                        id="teethT69B"
                        src={Shade3fB}
                        style={{
                          position: "absolute",
                          left: "104px",
                          top: "42px",
                          display: "none",
                          width: "26px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("70", "W", "shortteeth")}
                        id="teethT70W"
                        src={Shade4a}
                        style={{
                          position: "absolute",
                          left: "129px",
                          top: "-3px",
                          width: "20px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("70", "B", "shortteeth")}
                        id="teethT70B"
                        src={Shade4aB}
                        style={{
                          position: "absolute",
                          left: "129px",
                          top: "0px",
                          display: "none",
                          width: "20px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("71", "W", "shortteeth")}
                        id="teethT71W"
                        src={Shade4b}
                        style={{
                          position: "absolute",
                          left: "148px",
                          top: " -3px",
                          width: "20px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("71", "B", "shortteeth")}
                        id="teethT71B"
                        src={Shade4bB}
                        style={{
                          position: "absolute",
                          left: "148px",
                          top: "-3px",
                          display: "none",
                          width: "20px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("72", "W", "shortteeth")}
                        id="teethT72W"
                        src={Shade4c}
                        style={{
                          position: "absolute",
                          left: "126px",
                          top: "26px",
                          width: "23px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("72", "B", "shortteeth")}
                        id="teethT72B"
                        src={Shade4cB}
                        style={{
                          position: "absolute",
                          left: "129px",
                          top: "26px",
                          display: "none",
                          width: "23px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("73", "W", "shortteeth")}
                        id="teethT73W"
                        src={Shade4d}
                        style={{
                          position: "absolute",
                          left: "148px",
                          top: "24px",
                          width: "23px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("73", "B", "shortteeth")}
                        id="teethT73B"
                        src={Shade4dB}
                        style={{
                          position: "absolute",
                          left: "148px",
                          top: "24px",
                          display: "none",
                          width: "23px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("74", "W", "shortteeth")}
                        id="teethT74W"
                        src={Shade4e}
                        style={{
                          position: "absolute",
                          left: "123px",
                          top: "45px",
                          width: "28px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("74", "B", "shortteeth")}
                        id="teethT74B"
                        src={Shade4eB}
                        style={{
                          position: "absolute",
                          left: "129px",
                          top: "45px",
                          display: "none",
                          width: "28px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("75", "W", "shortteeth")}
                        id="teethT75W"
                        src={Shade4f}
                        style={{
                          position: "absolute",
                          left: "149px",
                          top: "44px",
                          width: "29px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("75", "B", "shortteeth")}
                        id="teethT75B"
                        src={Shade4fB}
                        style={{
                          position: "absolute",
                          left: "149px",
                          top: "45px",
                          display: "none",
                          width: "29px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("76", "W", "shortteeth")}
                        id="teethT76W"
                        src={Shade5a}
                        style={{
                          position: "absolute",
                          right: "100px",
                          top: "7px",
                          width: "16px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("76", "B", "shortteeth")}
                        id="teethT76B"
                        src={Shade5aB}
                        style={{
                          position: "absolute",
                          right: "100px",
                          top: "7px",
                          display: "none",
                          width: "16px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("77", "W", "shortteeth")}
                        id="teethT77W"
                        src={Shade5b}
                        style={{
                          position: "absolute",
                          right: "85px",
                          top: "5px",
                          width: "17px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("77", "B", "shortteeth")}
                        id="teethT77B"
                        src={Shade5bB}
                        style={{
                          position: "absolute",
                          right: "85px",
                          top: "7px",
                          display: "none",
                          width: "17px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("78", "W", "shortteeth")}
                        id="teethT78W"
                        src={Shade5c}
                        style={{
                          position: "absolute",
                          right: "97px",
                          top: "27px",
                          width: "19px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("78", "B", "shortteeth")}
                        id="teethT78B"
                        src={Shade5cB}
                        style={{
                          position: "absolute",
                          right: "97px",
                          top: "27px",
                          display: "none",
                          width: "19px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("79", "W", "shortteeth")}
                        id="teethT79W"
                        src={Shade5d}
                        style={{
                          position: "absolute",
                          right: "80px",
                          top: "26px",
                          width: "19px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("79", "B", "shortteeth")}
                        id="teethT79B"
                        src={Shade5dB}
                        style={{
                          position: "absolute",
                          right: "80px",
                          top: "26px",
                          display: "none",
                          width: "19px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("80", "W", "shortteeth")}
                        id="teethT80W"
                        src={Shade5e}
                        style={{
                          position: "absolute",
                          right: "98px",
                          top: "43px",
                          width: "24px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("80", "B", "shortteeth")}
                        id="teethT80B"
                        src={Shade5eB}
                        style={{
                          position: "absolute",
                          right: "98px",
                          top: "43px",
                          display: "none",
                          width: "24px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("81", "W", "shortteeth")}
                        id="teethT81W"
                        src={Shade5f}
                        style={{
                          position: "absolute",
                          right: "76px",
                          top: "43px",
                          width: "24px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("81", "B", "shortteeth")}
                        id="teethT81B"
                        src={Shade5fB}
                        style={{
                          position: "absolute",
                          right: "76px",
                          top: "43px",
                          display: "none",
                          width: "26px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("82", "W", "shortteeth")}
                        id="teethT82W"
                        src={Shade6a}
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "2px",
                          width: "25px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("82", "B", "shortteeth")}
                        id="teethT82B"
                        src={Shade6aB}
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "2px",
                          display: "none",
                          width: "25px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("83", "W", "shortteeth")}
                        id="teethT83W"
                        src={Shade6b}
                        style={{
                          position: "absolute",
                          right: "44px",
                          top: "2px",
                          width: "17px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("83", "B", "shortteeth")}
                        id="teethT83B"
                        src={Shade6bB}
                        style={{
                          position: "absolute",
                          right: "44px",
                          top: "2px",
                          display: "none",
                          width: "17px",
                        }}
                      />
                    </span>
                    <span>
                      {" "}
                      <img
                        onClick={(e) => changeColor("84", "W", "shortteeth")}
                        id="teethT84W"
                        src={Shade6c}
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "23px",
                          width: "19px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("84", "B", "shortteeth")}
                        id="teethT84B"
                        src={Shade6cB}
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "23px",
                          display: "none",
                          width: "19px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("85", "W", "shortteeth")}
                        id="teethT85W"
                        src={Shade6d}
                        style={{
                          position: "absolute",
                          right: "41px",
                          top: "22px",
                          width: "20px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("85", "B", "shortteeth")}
                        id="teethT85B"
                        src={Shade6dB}
                        style={{
                          position: "absolute",
                          right: "41px",
                          top: "22px",
                          display: "none",
                          width: "20px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("86", "W", "shortteeth")}
                        id="teethT86W"
                        src={Shade6e}
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "41px",
                          width: "19px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("86", "B", "shortteeth")}
                        id="teethT86B"
                        src={Shade6eB}
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "41px",
                          display: "none",
                          width: "19px",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        onClick={(e) => changeColor("87", "W", "shortteeth")}
                        id="teethT87W"
                        src={Shade6f}
                        style={{
                          position: "absolute",
                          right: "41px",
                          top: "41px",
                          width: "20px",
                        }}
                      />
                      <img
                        onClick={(e) => changeColor("87", "B", "shortteeth")}
                        id="teethT87B"
                        src={Shade6fB}
                        style={{
                          position: "absolute",
                          right: "41px",
                          top: "40px",
                          display: "none",
                          width: "20px",
                        }}
                      />
                    </span>{" "}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ color: "#FF0000", float: "left" }}>
                      Translucency
                    </div>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Regular
                    </div>
                    <input
                      onChange={(event) => checkedBox(event, "124")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC124"
                      value="124"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Low
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "125")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC125"
                      value="125"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      High
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "126")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC126"
                      value="126"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ color: "#FF0000", float: "left" }}>Value</div>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Regular
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "127")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC127"
                      value="127"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Low
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "128")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC128"
                      value="128"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      High
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "129")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC129"
                      value="129"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      clear: "both",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ color: "#FF0000", float: "left" }}>
                      Occlusal Staining
                    </div>
                  </div>
                  <div
                    style={{
                      width: "20%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      No
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "130")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC130"
                      value="130"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Light
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "131")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC131"
                      value="131"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "30%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Medium
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "132")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC132"
                      value="132"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Dark
                    </div>{" "}
                    <input
                      onChange={(event) => checkedBox(event, "133")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC133"
                      value="133"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      width: "308px",
                      textAlign: "center",
                      padding: "10px 0px 0px",
                      fontSize: "15px",
                      color: "red",
                      fontWeight: "600",
                      float: "left",
                    }}
                  >
                    Enclosures
                  </div>
                  <div
                    style={{
                      width: "308px",
                      border: "1px solid #000",
                      float: "left",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "134")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC134"
                        value="134"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Impression</span>
                    </div>
                    <div
                      style={{
                        width: "30%",
                        paddingBottom: "12px",
                        float: "left",
                        paddingLeft: "19px",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "135")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC135"
                        value="135"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Bite</span>
                    </div>
                    <div
                      style={{
                        width: "30%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "136")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC136"
                        value="136"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Articulator</span>
                    </div>

                    <div
                      style={{
                        width: "50%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "137")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC137"
                        value="137"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Shade Tab</span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "138")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC138"
                        value="138"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Shade Tooth</span>
                    </div>

                    <div
                      style={{
                        width: "50%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "139")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC139"
                        value="139"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Pre-Op Models</span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "140")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC140"
                        value="140"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Additional Model</span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "141")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC141"
                        value="141"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Model-With-Temporary-on</span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "142")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC142"
                        value="142"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Old Work(Adjust / Remake)</span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "12px",
                        float: "left",
                      }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "143")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC143"
                        value="143"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <span>Tray Enclosed</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "980px", float: "left" }}>
                <div
                  style={{
                    width: "308px",
                    border: "1px solid #000",
                    float: "left",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "308px",
                      textAlign: "center",
                      padding: "0px 0px 10px",
                      fontSize: "15px",
                      color: "red",
                      fontWeight: "600",
                      float: "left",
                    }}
                  >
                    If no occlusal clearance :
                  </div>
                  <div
                    style={{
                      width: "50%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <input
                      onChange={(event) => checkedBox(event, "144")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC144"
                      value="144"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Frame Island</span>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <input
                      onChange={(event) => checkedBox(event, "145")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC145"
                      value="145"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Adjust Opposing</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <input
                      onChange={(event) => checkedBox(event, "146")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC146"
                      value="146"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <span>Reduction coping(chargeable)</span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "12px",
                      float: "left",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        paddingRight: "20px",
                        color: "red",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      RCT Done
                    </div>
                    <input
                      onChange={(event) => checkedBox(event, "147")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC147"
                      value="147"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Yes
                    </div>
                    <input
                      onChange={(event) => checkedBox(event, "148")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC148"
                      value="148"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      No
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "308px",
                    border: "1px solid #000",
                    float: "left",
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "308px",
                      textAlign: "center",
                      padding: "0px 0px 10px",
                      fontSize: "15px",
                      color: "red",
                      fontWeight: "600",
                      float: "left",
                    }}
                  >
                    Opposing teeth to be restored later
                  </div>
                  <div
                    style={{
                      width: " 81%",
                      paddingBottom: "12px",
                      margin: "0 auto",
                    }}
                  >
                    <input
                      onChange={(event) => checkedBox(event, "149")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC149"
                      value="149"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 0px", float: "left" }}
                    />
                    <div style={{ float: "left", paddingRight: "20px" }}>
                      Yes
                    </div>
                    <input
                      onChange={(event) => checkedBox(event, "150")}
                      type="checkbox"
                      name="txtDental"
                      id="teethTC150"
                      value="150"
                      disabled={flag}
                      style={{ margin: "0px 5px 0 125px", float: "left" }}
                    />
                    <div style={{ float: "left", paddingRight: "10px" }}>
                      No
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "258px",
                    border: "1px solid #000",
                    float: "left",
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "258px",
                      textAlign: "center",
                      padding: "0px 0px 10px",
                      fontSize: "15px",
                      color: "red",
                      fontWeight: "600",
                      float: "left",
                    }}
                  >
                    Pontic Design (please tick one)
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      float: "left",
                      width: "258px",
                      borderTop: "0",
                    }}
                  >
                    <div
                      style={{ width: "20%", float: "left", paddingTop: "5px" }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "151")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC151"
                        value="151"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div
                        style={{
                          width: "22px",
                          float: "left",
                          paddingRight: "5px",
                        }}
                      >
                        <img
                          src={P1}
                          style={{ width: "100%", height: "40px" }}
                        />
                      </div>
                      <div style={{ width: "100%", float: "left" }}>
                        Sanitary
                      </div>
                    </div>
                    <div
                      style={{ width: "20%", float: "left", paddingTop: "5px" }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "152")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC152"
                        value="152"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div
                        style={{
                          width: "20px",
                          float: "left",
                          paddingRight: "5px",
                        }}
                      >
                        <img src={P2} style={{ width: "100%" }} />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          textAlign: "center",
                        }}
                      >
                        Full Ridge
                      </div>
                    </div>
                    <div
                      style={{ width: "20%", float: "left", paddingTop: "5px" }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "153")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC153"
                        disabled={flag}
                        value="153"
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div
                        style={{
                          width: "20px",
                          float: "left",
                          paddingRight: "5px",
                        }}
                      >
                        <img src={P3} style={{ width: "100%" }} />
                      </div>
                      <div style={{ width: "100%", float: "left" }}>
                        Modified
                      </div>
                    </div>
                    <div
                      style={{ width: "20%", float: "left", paddingTop: "5px" }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "154")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC154"
                        value="154"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div
                        style={{
                          width: "20px",
                          float: "left",
                          paddingRight: "5px",
                        }}
                      >
                        <img src={P4} style={{ width: "100%" }} />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          textAlign: "center",
                        }}
                      >
                        Bullet
                      </div>
                    </div>
                    <div
                      style={{ width: "20%", float: "left", paddingTop: "5px" }}
                    >
                      <input
                        onChange={(event) => checkedBox(event, "155")}
                        type="checkbox"
                        name="txtDental"
                        id="teethTC155"
                        value="155"
                        disabled={flag}
                        style={{ margin: "0px 5px 0 0px", float: "left" }}
                      />
                      <div
                        style={{
                          width: "20px",
                          float: "left",
                          paddingRight: "5px",
                        }}
                      >
                        <img src={P5} style={{ width: "100%" }} />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          textAlign: "center",
                        }}
                      >
                        Ovate
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="formBottom" style={{ paddingTop: "5px" }}>
              <div
                style={{
                  clear: "both",
                  overflow: "hidden",
                  lineHeight: "21px",
                  marginBottom: "15px",
                }}
              >
                <span style={{ float: "left", boxSizing: "border-box" }}>
                  Notes :{" "}
                </span>
                <div
                  style={{
                    wordBreak: "break-word",
                    boxSizing: "border-box",
                    width: "95%",
                    paddingLeft: "10px",
                    float: "left",
                  }}
                >
                  {flag == true ? (
                    <textarea
                      type="text"
                      maxlength="250"
                      id="notes"
                      value={notes}
                      disabled
                      name="notes"
                      style={{
                        width: "100%",
                        height: "60px",
                        resize: "none",
                        border: "1px solid #000",
                        padding: "2px 5px",
                        marginTop: "8px",
                      }}
                    />
                  ) : (
                    <textarea
                      type="text"
                      maxlength="250"
                      id="notes"
                      name="notes"
                      style={{
                        width: "100%",
                        height: "60px",
                        resize: "none",
                        border: "1px solid #000",
                        padding: "2px 5px",
                        marginTop: "8px",
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{ clear: "both", overflow: "hidden", marginTop: "5px" }}
              >
                <div style={{ float: "left", width: "650px" }}>
                  <span style={{ float: "left" }}>
                    In case of any clarification. Please contact Dr.{" "}
                  </span>
                  <div>
                    {doctorData.firstName} {doctorData.lastName}
                  </div>
                </div>
                <div style={{ float: "left", width: "330px" }}>
                  <span style={{ float: "left" }}>Contact No : </span>
                  <div>&nbsp;{doctorMobile}</div>
                </div>
              </div>
              <div
                style={{
                  clear: "both",
                  overflow: "hidden",
                  paddingTop: "10px",
                }}
              >
                <span style={{ float: "left", paddingTop: "9px" }}>
                  Collection Agent
                </span>
                <div
                  style={{
                    wordBreak: "break-word",
                    boxSizing: "border-box",
                    width: "170px",
                    paddingTop: "10px",
                    marginLeft: "15px",
                    float: "left",
                  }}
                >
                  {flag == true ? (
                    <input
                      type="text"
                      maxlength="20"
                      name="marketingEx"
                      id="marketingEx"
                      value={marketingEx}
                      disabled
                      style={{
                        border: "1px solid #000",
                        padding: "4px 5px",
                        width: "100%",
                        clear: "both",
                        float: "none",
                        boxSizing: "border-box",
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      maxlength="20"
                      name="marketingEx"
                      id="marketingEx"
                      style={{
                        border: "1px solid #000",
                        padding: "4px 5px",
                        width: "100%",
                        clear: "both",
                        float: "none",
                        boxSizing: "border-box",
                      }}
                    />
                  )}
                </div>
                <span style={{ float: "right", paddingTop: "5px" }}>
                  Name & Signature of Doctor/ Clinic Staff
                </span>
              </div>
            </div>
            <div
              class="formBottomBtn"
              style={{
                paddingTop: "15px",
                textAlign: "right",
                paddingBottom: "15px",
                display: "inline-flex",
                width: "100%",
                flexFlow: "row",
                justifyContent: "flex-end",
              }}
            >
              <span
                onClick={printPage}
                id="print"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#2d1152",
                  border: "0",
                  color: "#fff",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Print
              </span>
              <span
                id="savePrint"
                disabled={saveflag}
                onClick={(e) => {
                  labOrderSave(e);
                  printPage(e);
                }}
                class="saveClr buttonsmall"
                tabindex="15"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#dbdbdb",
                  border: "0",
                  color: "#000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Save & Print
              </span>

              <span
                disabled={saveflag}
                onClick={(e) => {
                  labOrderSave(e);
                  labOrderListing(e);
                }}
                id="send"
                class="saveClr buttonsmall"
                tabindex="15"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#dbdbdb",
                  border: "0",
                  color: " #000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Send
              </span>

              <a
                href="/Patients/labOrderDentalAdd"
                onClick={cancelButton}
                id="cancel"
                class="saveClr buttonsmall"
                tabindex="15"
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "10px",
                  padding: "7px 20px",
                  fontSize: "14px",
                  background: "#dbdbdb",
                  border: "0",
                  color: "#000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
export default LabOrderMetalFreeCeramic;
