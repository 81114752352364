import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { callingAPI } from '../config';
import Logo from '../images/avatarHospitalDefault.png';
import Loader from '../Common/loader';
// var s3Path =
// 	process.env.REACT_APP_S3_URI +
// 	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH+
// 	'/' +"1641193031310_hospital_logo.jpg"
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
function ConsentFormInvoiceSettings(props) {
	const location = useLocation();
	const [city, setCity] = useState('');
	const [hospitalImagename, sethospitalImagename] = useState('');
	const [memberName, setprintPreviewmemberName] = useState();
	const [ageyear, setprintPreviewageyear] = useState();
	const [memberMobileNumber, setprintPreviewmemberMobileNumber] = useState();
	const [memberEmailId, setprintPreviewmemberEmailId] = useState();
	const [gender, setgender] = useState();
	const [hospitalEmailId, sethospitalEmailId] = useState();
	const [printPreview, setprintview] = useState([]);
	const [hospitalLogo, sethospitalhospitalLogo] = useState();
	const [hospitalLocation, sethospitalhospitalLocation] = useState();
	const [hospitalPh, sethospitalhospitalPh] = useState();
	const [hospitalName, sethospitalhospitalName] = useState();
	const [hospitaladdress, sethospitalhospitaladdress] = useState();
	useEffect(() => {
		if (location.state && Object.keys(location.state).length !== 0) {
			const { DescriptionValue } = location.state;
		}
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('consentForm/printPreview', PostData).then((response) => {
			if (response.data.success === '1') {
				setgender(response.data.result.printPreview[0].gender);
				sethospitalEmailId(response.data.result.printPreview[0].hospitalEmailId);
				setCity(response.data.result.printPreview[0].city);
				setprintPreviewmemberName(response.data.result.printPreview[0].memberName);
				setprintPreviewageyear(response.data.result.printPreview[0].ageyear);
				setprintPreviewmemberMobileNumber(response.data.result.printPreview[0].memberMobileNumber);
				// setprintPreviewmemberEmailId(response.data.result.printPreview[0].memberEmailId);
				setprintPreviewmemberEmailId(response.data.result.printPreview[0].memberEmailId);
				sethospitalhospitalLocation(response.data.result.printPreview[0].city);
				sethospitalhospitalName(response.data.result.printPreview[0].hospitalName);
				setprintview(response.data.result.printPreview[0]);
				sethospitalhospitalLogo(response.data.result.printPreview[0].hospitalLogo);
				sethospitalhospitaladdress(response.data.result.printPreview[0].address);
			}
		});
		callingAPI('commonUserFunctions/headerFooterLogoPrints', {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		}).then((res) => {
			sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
			//setMarginTop()
		});
	}, []);
	const printPage = () => {
		// printDisplay.style.display="none"
		document.getElementById('cancel').style.display = 'none';
		document.getElementById('print').style.display = 'none';
		// document.getElementById("savePrint").style.display="none"
		// document.getElementById("send").style.display="none"
		window.print();
		document.getElementById('cancel').style.display = 'inline-block';
		document.getElementById('print').style.display = 'inline-block';
		// document.getElementById("savePrint").style.display="inline-block"
		// document.getElementById("send").style.display="inline-block"
	};
	const cancelButton = () => {
		var a = ''
		window.sessionStorage.setItem('concentFormType', a)
		window.sessionStorage.setItem('descriptionLocalLanguage', a)
		window.sessionStorage.setItem('hospitalConsentFormDescription', a)
		window.location.href = '/settings/consentform';
	}
	return (
		<div>
			<div align="center">
				<div
					align="center"
					style={{
						width: '540px',
						height: '595px',
						marginLeft: '0px',
						marginRight: '0px',
						marginTop: '0px',
						marginBottom: '0px',
						fontFamily: 'SourceSansPro-Regular',
					}}
				>
					<table style={{ width: '100%', marginTop: '0px' }} border="0">
						<thead>
							<tr>
								<th style={{ fontWeight: '400' }}>
									<div class="printOutHeaderSec ">
										<table style={{ width: '100%' }}>
											<tbody>
												<tr height="80px">
													<td colspan="3" valign="middle" align="center">
														<div style={{ width: '100%', float: 'none' }}>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '14px',
																	clear: 'both',
																	width: '100%',
																	margin: '0px 0 3px 0px',
																}}
															>

																<span
																	style={{
																		marginTop: '-5px',
																		display: 'inline-block',
																		// width: '100%',
																	}}
																>
																	{hospitalImagename != (null || "") ?
																		<img
																			src={getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"}
																			style={{
																				display: 'block',
																				width: 'auto',
																				maxHeight: '65px',
																				margin: 'auto',
																			}}
																			align="middle"
																		/> : <img src={Logo} />}
																</span>

															</p>
														</div>
														<div class="practicePrintName" style={{ width: '100%' }}>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '14px',
																	clear: 'both',
																	width: '100%',
																	marginBottom: '1px',
																	marginTop: '0',
																}}
															>
																{hospitalName}
															</p>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '12px !important',
																	clear: 'both',
																	width: '100%',
																	margin: '0',
																}}
															>
																{hospitaladdress}
															</p>
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="3">
														<hr
															color="#142536"
															style={{ margin: ' 0', opacity: '1', height: '2px' }}
														/>
													</td>
												</tr>
												<tr>
													<td
														width="50%"
														colspan="2"
														align="left"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													>
														{hospitalLocation}
													</td>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													>
														{hospitalPh}
													</td>
												</tr>
												<tr>
													<td colspan="3">
														<hr
															color="#142536"
															style={{ margin: ' 0', opacity: '1', height: '2px' }}
														/>
													</td>
												</tr>
												<tr>
													<td
														width="50%"
														colspan="2"
														align="left"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													/>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													/>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Name : {memberName}
														{/* Name :ARUN S */}
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Date : 01/11/2021
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														MR No : 202003050048
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														{/* Phone No : 6546879798 */}
														Phone No : {memberMobileNumber}
													</td>
												</tr>
												<tr>
													<td
														colspan="1"
														style={{
															width: '40%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Age : {ageyear}
														{/* Age:31 */}
													</td>
													<td
														colspan="2"
														style={{
															width: '80%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														{/* Email Id :arun@ads.com */}
														Email Id : {memberEmailId}
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Sex : {gender}
														{/* Sex :Male */}
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													/>
												</tr>
												<tr>
													<td colspan="3">
														<hr
															color="#142536"
															style={{ margin: '0', opacity: '1', height: '2px' }}
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr >
													{/* <td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '30%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '10px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>

													</td> */}
													{/* <td
														class="inoviceBorder"
														style={{
															verticalAlign: 'top !important',
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '100%',
															textAlign: 'left',
															fontSize: '13px',
															color: 'rgb(0 0 0)',
															lineHeight: '18px',
														}}
													>
														<div
															class="procedureCode"
															style={{
																fontSize: '10px !important',
																marginTop: '0',

																paddingBottom: '2px',
																marginBottom: '2px',
															}}
														>
															<div
																class="procedureCodeHead"
																style={{ paddingBottom: '3px' }}
															>
																<div
																	style={{
																		width: '100%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	{props.location.state.Type}
																</div>
															</div>
														</div>
													</td> */}
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr>
													{/* <td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '30%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '10px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														  DESCRIPTION (English)
													</td> */}
													<td
														class="inoviceBorder"
														style={{
															verticalAlign: 'top !important',
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '100%',
															textAlign: 'left',
															fontSize: '13px',
															color: 'rgb(0 0 0)',
															lineHeight: '18px',
														}}
													>
														<div
															class="procedureCode"
															style={{
																fontSize: '10px !important',
																marginTop: '0',

																paddingBottom: '2px',
																marginBottom: '2px',
															}}
														>
															<div
																class="procedureCodeHead"
																style={{ paddingBottom: '3px' }}
															>
																<div
																	style={{
																		width: '100%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	{location.state.DescriptionValue}
																</div>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr  >
													{/* <td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '30%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '10px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														DESCRIPTION (Local)
													</td> */}
													<td
														class="inoviceBorder"
														style={{
															verticalAlign: 'top !important',
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '100%',
															textAlign: 'left',
															fontSize: '13px',
															color: 'rgb(0 0 0)',
															lineHeight: '18px',
														}}
													>
														<div
															class="procedureCode"
															style={{
																fontSize: '10px !important',
																marginTop: '0',

																paddingBottom: '2px',
																marginBottom: '2px',
															}}
														>
															<div
																class="procedureCodeHead"
																style={{ paddingBottom: '3px' }}
															>
																<div
																	style={{
																		width: '100%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	{location.state.LocalLanguageValue}
																</div>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>
									<div class="printOutFooterSec">
										<table style={{ width: '100%' }}>
											<tbody>
												<tr>
													<td
														width="25%"
														colspan="2"
														align="left"
														style={{ wordBreak: 'break-all' }}
													>
														<div align="justify" style={{ fontSize: '10px' }}>
															{/* {hospitalEmailId} */}
															{/* TestHospital@test.com */}
														</div>
													</td>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '10px', wordBreak: ' break-all' }}
													>
														{/* not given from api*/}
														{/* Dr. Manu */}
													</td>
												</tr>
												<tr>
													<td colspan="3">
														<div align="justify" class="practiceFootBtm">
															{/* Working time */}
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="3" style={{ paddingTop: '10px' }} />
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			{/* <div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									onClick={printPage}
									id="print"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '15px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '80px',
										fontFamily: '  Verdana',
									}}
								>
									Print
								</p>&nbsp;
								<p
					style={{
						width: '70px',
						textAlign: 'center',
						paddingTop: '5px',
						lineHeight: '18px',
						display: 'inline-block',
						fontWeight: 'bold',
						fontSize: '12px',
						color: 'white',
						background: 'rebeccapurple none repeat scroll 0% 0%',
						marginTop: '2px',
						cursor: 'pointer',
						borderRadius: '5px',
						marginBottom: '2px',
						height: '25px',
					}}
				>
					<Link to="/settings/consentform" style={{ color: '#fff', textDecoration: 'none' }}>
						Cancel
					</Link>
				</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div> */}
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								{/* <p
									onClick={printPage}
									id="print"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '15px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '80px',
										fontFamily: '  Verdana',
									}}
								>
									Print
								</p>&nbsp; */}
								<p
									id="cancel"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '15px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										fontFamily: '  Verdana',
										right: '0px',
									}}
									onClick={cancelButton}
								>
									Cancel
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}
export default ConsentFormInvoiceSettings;
