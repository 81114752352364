import React from 'react';
import DoctorLeftMenu from './DoctorLeftMenu';
import HospitalLeftMenu from './HospitalLeftMenu';
function reportmenu() {
	return (
		<div className="settingMenu" >
			{/* {window.sessionStorage.getItem("role")==="ROLE_DOCTOR" &&
				<>
					<DoctorLeftMenu />
				</>
				} */}
			{window.sessionStorage.getItem("role") === "ROLE_DOCTOR" && <DoctorLeftMenu />}
			{window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" && <HospitalLeftMenu />}
		</div>
	);
}

export default reportmenu;
