
import React from 'react';
import SearchFilterPromotionalSms from './SearchFilterPromotionalSms'
function PrepareSms() {
	return (
		<div class="sendSmsWrap">
			<div class="sendSmsCountTop">
				<div class="sendSmsCountDiv">
					Remaining SMS
					<span>9994</span>
					<input type="hidden" id="promotionalSmsCount" value="9994" />
				</div>
				<div class="sendSmsCountDiv">
					Today's submission
					<span>0</span>
				</div>
				<div class="sendSmsCountDiv">
					Expiry Date
					<span>09 Sep 2022</span>
				</div>
				<div class="sendSmsCountDiv">
					Sms Sender Id
					<span>PURPLE</span>
				</div>
			</div>
			<form>
				<input type="hidden" value="manual" />
				<div class="sendSmsCvrDiv">
					<div class="smsHeading">PROMOTIONAL SMS</div>
					<div class="smsNotify">Please note that promotional sms can be send from 9 AM to 8 PM only.</div>
					<div class="sendSmsContentDiv">
						<div class="sendSmsLeftSec">
							<ul class="smsLeftTab">
								<li class="tabActive" id="leftOption1">
									<span>1</span>
								</li>
								<li id="leftOption2">
									<span>2</span>
								</li>
							</ul>
						</div>
						<div class="sendSmsRightSec" id="numberComposeDiv">
							<div class="smsTypeDiv">
								<div class="smsTypeSectionTop">
									<div class="tabset">
										<input type="radio" name="tabset" id="tab1" aria-controls="Manual" checked />
										<label for="tab1">
											<input type="radio" name="promotionalsms" />Manual
										</label>

										<input type="radio" name="tabset" id="tab2" aria-controls="Upload" />
										<label for="tab2">
											<input type="radio" name="promotionalsms" />Upload
										</label>
										<input type="radio" name="tabset" id="tab3" aria-controls="Group" />
										<label for="tab3">
											<input type="radio" name="promotionalsms" />Group
										</label>
										<div class="tab-panels">
											<section id="Manual" class="tab-panel">
												<div class="smsTypeSectionDiv">
													<div class="smsTypeSectionBlock" id="manualModId">
														<div class="smsSubHeading">
															Phone Numbers{' '}
															<span style={{ fontSize: '80%' }}>
																(Enter the phone number and press enter key to continue)
															</span>
														</div>
														<div class="bootstrap-tagsinput">
															<span class="tag label label-info">
																09567984539
																<span data-role="remove" />
															</span>
															<span class="tag label label-info">
																999888777123
																<span data-role="remove" />
															</span>
															<input type="text" placeholder="" size="11" />
														</div>
														<input
															type="text"
															data-role="tagsinput"
															height="300px"
															id="manualNumbers"
															name="manualNumbers"
															style={{ display: 'none' }}
														/>
													</div>
												</div>

												{/*  */}
												<div class="smsButtonDiv">
													<span class="treatmentPlanBtn pl_blue_btn">
														<a href="/communications/composeSms">Next</a>
													</span>
												</div>
											</section>
											<section id="Upload" class="tab-panel">
												<div class="smsTypeSectionBlock" id="uploadModId">
													<div class="fileUpload_upload btn_upload btnOrange">
														<span class="pointer">Upload CSV</span>
														<input
															type="file"
															name="uploadFileMain"
															id="uploadFileMain"
															class="upload_upload btnOrange"
															title="Only Allow comma separated Numbers (eg. 90000000,40000000,50000000)"
														/>
													</div>
													<div class="smsTypeSectionDiv">
														<div class="smsTypeSectionBlock" id="manualModId">
															<div class="smsSubHeading">
																Phone Numbers{' '}
																<span style={{ fontSize: '80%' }}>
																	Phone Numbers (Add multiple mobile numbers with
																	comma seperation)
																</span>
															</div>
															<div class="bootstrap-tagsinput">
																<span class="tag label label-info">
																	09567984539
																	<span data-role="remove" />
																</span>
																<span class="tag label label-info">
																	999888777123
																	<span data-role="remove" />
																</span>
																<input type="text" placeholder="" size="11" />
															</div>
															<input
																type="text"
																data-role="tagsinput"
																height="300px"
																id="manualNumbers"
																name="manualNumbers"
																style={{ display: 'none' }}
															/>
														</div>
													</div>
												</div>
												<div class="smsButtonDiv">
													<span class="treatmentPlanBtn pl_blue_btn">
														<a href="/communications/composeSms">Next</a>
													</span>
												</div>
											</section>
											
											<section id="Group" class="tab-panel">
										
												<SearchFilterPromotionalSms/>
												<div class="smsButtonDiv">
													<span class="treatmentPlanBtn pl_blue_btn">
														<a href="/communications/composeSms">Next</a>
													</span>
												</div>
											</section>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default PrepareSms;
