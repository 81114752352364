import React from 'react';

import Header from '../layout/header';
import Footer from '../layout/footer'; 
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import PatientOldHistorylist from '../Patients/patientoldhistoryList';
import Patientright from '../Patients/patientright';

function PatientOldHistory() {
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientAppointmentWrapper">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<PatientOldHistorylist />
							</div>
							<div className="col-lg-4 col-md-12 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PatientOldHistory;
