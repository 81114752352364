import React from 'react';
import BillingHeader from '../billingLogin/layout/header';
import Footer from '../billingLogin/layout/footer';
import { Route } from 'react-router-dom';
import BillingLeft from '../billingLogin/billingLeft'
import Datepicker from 'react-datepicker';
import labPrescription from '../images/icons/lab/labPrescription.png';
import Printer_icon from '../images/icons/printer_icon.png';
import labEye from '../images/icons/lab/labEye.png';
import labEdit from '../images/icons/lab/labEdit.png';


function LabOrderDetails() {
    return (
        <div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec">
            <BillingHeader />
            <div className="contentWraper contentWraperScroll">
                <BillingLeft />
                <div className="settingsRight">
                    <div className="dashboardCover pharmacyManufactureWrap">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="dashboardTitle">Lab Order Details</div>
                            </div>
                            <div className="col-md-2">
                                <a style={{ textDecoration: 'none' }}>
                                    <span
                                        className="dashButtonBorder labButton" style={{ borderRadius: "0px" }}
                                    >
                                        Buy Lab Order
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="dashboardContent">
                            <div className="reportWrap">
                                <div className="dashboardSearch">
                                    <div className="row">
                                        <div className="col-md-2 dashboardSearchList">
                                            <select className="inputField"  >
                                                <option value="">Select</option>
                                                <option value="1">Patient Name</option>
                                                <option value="2">MR Number</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <input type="text" className="inputField" placeholder='Enter Value' style={{ background: "transparent" }} />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <Datepicker
                                                autocomplete="off"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                required
                                                closeCalendar="true"
                                                className="Datepicker pa2 inputField"
                                                placeholderText="From Date"
                                                calendarClassName="rasta-stripes"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <Datepicker
                                                autocomplete="off"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                required
                                                closeCalendar="true"
                                                className="Datepicker pa2 inputField"
                                                placeholderText="To Date"
                                                calendarClassName="rasta-stripes"
                                                dateFormat="dd-MM-yyyy"

                                            />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList"  >
                                            <span className="dashButtonBorder labButton" style={{ padding: "8px 20px", height: "35px" }}>Search</span>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}
                                <div class="treatmentPlanList">
                                    <div class="treatmentPlanDate"> 28, Aug 2019   </div>
                                    <div class="treatmentTable planActive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th class="col-md-3"><div class="dentalProName">Patient Name</div></th>
                                                    <th class="col-md-2"><div class="dentalProName">MR No</div></th>
                                                    <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                    <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                    <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                    <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                    <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="groupBrdr">
                                                    <td class="col-md-3">
                                                        <div class="dentalProName paddingFive">
                                                            <span class="elipsis">ABHAY SAJU.S </span>
                                                        </div>
                                                    </td>
                                                    <td class="col-md-2"><div class="dentalProName paddingFive">22 </div></td>
                                                    <td class="col-md-1"><div class="dentalProName txtCenter">
                                                        <span class="viewDetailLink">
                                                            <a href=" " title="View Details">
                                                                <img src={labEye} />
                                                            </a>
                                                        </span></div>
                                                    </td>
                                                    <td class="col-md-1"><div class="dentalProName txtCenter">
                                                        <span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Lab Test">
                                                            <a href="#labTestDetails"> <img src={Printer_icon} /></a>
                                                        </span></div>
                                                    </td>
                                                    <td class="col-md-2"><div class="dentalProName txtCenter"><span class="viewDetailLink">
                                                        <a href=" " class="clrBlack" title="Add Test Result">
                                                            <img src={labPrescription} /></a> </span></div>
                                                    </td>
                                                    <td class="col-md-2"><div class="dentalProName txtCenter"><span class="viewDetailLink">
                                                        <a href=" " class="clrBlack" title="Edit Test Result">
                                                            <img src={labEdit} />
                                                        </a>
                                                    </span></div>
                                                    </td>
                                                    <td class="col-md-1"><div class="dentalProName txtCenter"><span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Test Result">
                                                        <a href="#labTestDetailedInfo"><img src={Printer_icon} /></a>
                                                    </span></div>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* PRINNT 1st MODAL BEGINS HERE */}
                                <div id="labTestDetails" class="modalDialog">
                                    <div>
                                        <a href="#close" title="Close" class="close exportClose">X</a>
                                        <div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
                                            <div class="addDataHead">
                                                <span className='labTitle'>Lab Test Details</span>
                                            </div>
                                            {/* --- */}
                                            <div class="addDataContent" id="medicineDetails">
                                                <div class="patientNameDiv row">
                                                    <span class="col-md-2 patientName patientDate">
                                                        <label>Date :</label>
                                                        <span>28,Aug 2019</span>
                                                    </span>
                                                    <span class="col-md-5 patientName ">
                                                        <label>Patient Name : ABHAY SAJU.S</label>
                                                        <span></span>
                                                    </span>
                                                    <span class="col-md-3 patientName">
                                                        <label>MR No :</label>
                                                        <span>22</span>
                                                    </span>
                                                </div>
                                                <div class="treatmentTable planActive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th class="col-md-3"><div class="dentalProName">Test Name</div></th>
                                                                <th class="col-md-3"><div class="dentalProCost txtLeft">Specimen</div></th>
                                                                <th class="col-md-2"><div class="dentalProDis txtLeft">Test Type</div></th>
                                                                <th class="col-md-2"><div class="dentalProDis txtLeft">Amount</div></th>
                                                                <th class="col-md-2"><div class="dentalProTotal txtLeft">Notes</div></th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="col-md-3">
                                                                    <div class="dentalProName">
                                                                        <div class="optionWrapper">
                                                                            <input type="checkbox" id="chkSelect0" name="chkSelect0" />
                                                                            <label for="chkSelect0"></label>
                                                                        </div>

                                                                        <span class="elipsis" title="ABC ">ABC </span>
                                                                    </div>
                                                                </td>

                                                                <td class="col-md-3"><div class="dentalProCost txtLeft">Blood</div></td>
                                                                <td class="col-md-2"><div class="dentalProDis txtLeft">Bilirubin Direct</div></td>
                                                                <td class="col-md-2"><div class="dentalProDis txtLeft">

                                                                    200.00

                                                                </div></td>
                                                                <td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> </div></td>


                                                            </tr>
                                                            <tr class="dentalProPlaned">
                                                                <td colspan="3"><div class="plannedBy">Prescribed by <span>Dr.Nagarjun Mishra Ima</span></div></td>
                                                                <td colspan="2"></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="col-md-3">
                                                                    <div class="dentalProName">
                                                                        <div class="optionWrapper">
                                                                            <input type="checkbox" id="chkSelect1" name="chkSelect1" />
                                                                            <label for="chkSelect1"></label>
                                                                        </div>

                                                                        <span class="elipsis" title="BLOOD -SEROLOGY FOR HIV,HCV,HBsAg ">BLOOD -SEROLOGY FOR HIV,HCV,HBsAg </span>
                                                                    </div>
                                                                </td>

                                                                <td class="col-md-3"><div class="dentalProCost txtLeft">Blood</div></td>
                                                                <td class="col-md-2"><div class="dentalProDis txtLeft">LDL</div></td>
                                                                <td class="col-md-2"><div class="dentalProDis txtLeft">

                                                                    10000.00

                                                                </div></td>
                                                                <td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> </div></td>


                                                            </tr>
                                                            <tr class="dentalProPlaned">
                                                                <td colspan="3"><div class="plannedBy">Prescribed by <span>Dr.Nagarjun Mishra Ima</span></div></td>
                                                                <td colspan="2"></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="col-md-3">
                                                                    <div class="dentalProName">
                                                                        <div class="optionWrapper">
                                                                            <input type="checkbox" id="chkSelect2" name="chkSelect2" />
                                                                            <label for="chkSelect2"></label>
                                                                        </div>

                                                                        <span class="elipsis" title="BLOOD -SEROLOGY FOR HIV,HCV,HBsAg ">BLOOD -SEROLOGY FOR HIV,HCV,HBsAg </span>
                                                                    </div>
                                                                </td>

                                                                <td class="col-md-3"><div class="dentalProCost txtLeft">Blood</div></td>
                                                                <td class="col-md-2"><div class="dentalProDis txtLeft">Bilirubin Total</div></td>
                                                                <td class="col-md-2"><div class="dentalProDis txtLeft">

                                                                    10000.00

                                                                </div></td>
                                                                <td class="col-md-2"><div class="dentalProTotal txtLeft" title=""> </div></td>
                                                            </tr>
                                                            <tr class="dentalProPlaned">
                                                                <td colspan="3"><div class="plannedBy">Prescribed by <span>Dr.Nagarjun Mishra Ima</span></div></td>
                                                                <td colspan="2"></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="serviceProviderBtns">
                                                    <input type="submit" value="Print Selected" style={{ background: "#0096d6" }} />
                                                    <input type="submit" value="Print All" id="btnPrintAll" style={{ background: "#0096d6" }} />

                                                </div>

                                            </div>
                                            {/* --- */}

                                        </div>
                                    </div>
                                </div>
                                {/* PRINNT 1st MODAL ENDS HERE */}
                                {/* PRINNT 2nd MODAL BEGINS HERE */}
                                <div id="labTestDetailedInfo" class="modalDialog">
                                    <div>
                                        <a href="#close" title="Close" class="close exportClose">X</a>
                                        <div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
                                            <div class="addDataHead">
                                                <span className='labTitle'>Lab Test Details</span>
                                            </div>
                                            {/* --- */}
                                            <div class="addDataContent" id="medicineDetails">
                                                <div class="patientNameDiv row">
                                                    <span class="col-md-2 patientName patientDate">

                                                        <span>28, Aug 2019</span>
                                                    </span>
                                                </div>
                                                <div class="treatmentTable planActive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th class="col-md-4"><div class="dentalProCost txtLeft">Test Name (Type)</div></th>
                                                                <th class="col-md-4"><div class="dentalProDis txtLeft">Normal Value</div></th>
                                                                <th class="col-md-4"><div class="dentalProNote txtLeft">Patient Readings</div></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="groupBrdr">
                                                                <td colspan="3" class="col-md-12">
                                                                    <div class="dentalProName">
                                                                        <div class="optionWrapper">
                                                                            <input type="checkbox" id="chkSelect01" name="chkSelect01" />
                                                                            <label for="chkSelect01"></label>
                                                                        </div>
                                                                        <div class="dentalProCost txtLeft" style={{ paddingTop: "0px", paddingBottom: "0", fontWeight: "600", width: "95%" }}>
                                                                            BLOOD -SEROLOGY FOR HIV,HCV,HBsAg (Bilirubin Total) (Specimen Type:Blood)
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="groupBrdr">
                                                                <td colspan="3" class="col-md-12">
                                                                    <div class="dentalProName">
                                                                        <b></b>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="groupBrdr">
                                                                <td class="col-md-4"><div class="dentalProDis txtLeft"> - Rapid immunochromoatography for HIVI and HIV2</div></td>
                                                                <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                    REACTIVE,NON REACTIVE
                                                                </div></td>
                                                                <td class="col-md-4">
                                                                    <div class="dentalProNote paddingEight">
                                                                        <span class="elipsis">
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                1 </span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="groupBrdr">
                                                                <td colspan="3" class="col-md-12">
                                                                    <div class="dentalProName">

                                                                        <b></b>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="groupBrdr">
                                                                <td class="col-md-4"><div class="dentalProDis txtLeft"> - Rapid immunochromoatography for HbsAg</div></td>
                                                                <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                    positive, negative
                                                                </div></td>
                                                                <td class="col-md-4">
                                                                    <div class="dentalProNote paddingEight">
                                                                        <span class="elipsis">
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                1 </span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="groupBrdr">
                                                                <td colspan="3" class="col-md-12">
                                                                    <div class="dentalProName">
                                                                        <b></b>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="groupBrdr">
                                                                <td class="col-md-4"><div class="dentalProDis txtLeft"> - HCV</div></td>
                                                                <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                    REACTIVE,NON REACTIVE
                                                                </div></td>
                                                                <td class="col-md-4">
                                                                    <div class="dentalProNote paddingEight">
                                                                        <span class="elipsis">
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                1 </span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="dentalProPlaned">
                                                                <td colspan="2"><div class="plannedBy">Noted by <span>Dr. Nagarjun Mishra Ima</span></div></td>
                                                                <td colspan="1"><div class="estimatedPrice"></div></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="serviceProviderVerfied">
                                                    <span>Verified By : </span>
                                                    <input type="text" />
                                                    <span>Lab Technician : </span>
                                                    <input type="text" />
                                                    <span>Lab Technologist : </span>
                                                    <input type="text" />
                                                </div>
                                                <div class="serviceProviderBtns">
                                                    <input type="submit" value="Print Selected" style={{ background: "#177f40" }} />
                                                    <input type="submit" value="Print All" id="btnPrintAll" style={{ background: "#177f40" }} />

                                                </div>

                                            </div>
                                            {/* --- */}

                                        </div>
                                    </div>
                                </div>
                                {/* PRINNT 2nd MODAL ENDS HERE */}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LabOrderDetails;
