import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import User from '../images/media/noimageGirl.png';
import { Link, useLocation } from 'react-router-dom'
var getS3folder = process.env.REACT_APP_S3_URI + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH
function PatientGroup() {
	const [groupData, setGroupData] = useState({ version: "", hospitalPatientGroupDescription: "", specializationId: "", doctorId: "", hospitalPatientGroupName: "", type: "" })
	const [DoctorDropDownNames, setDoctorDropDownNames] = useState([])
	const [EditedPatientGroupData, setEditedPatientGroupData] = useState([])
	const [SavedPatientGroupData, setSavedPatientGroupData] = useState([])
	const [SpecialityListFromDoctor, setSpecialityListFromDoctor] = useState([]);
	const [DoctorIdFromRespone, setDoctorIdFromRespone] = useState()
	const [TotalCountOfPatient, setTotalCountOfPatient] = useState()
	const [TotalCountOfPatientAddedInGroup, setTotalCountOfPatientAddedInGroup] = useState()
	const [addOrremoveGroup, setAddorRemoveGroupFromCheckbox] = useState([])
	const [enableEdit, setEnableEdit] = useState(false);
	const [hospitalPatientGroupId, setHospitalPatientGroupId] = useState();
	const location = useLocation()
	const onChange = (event) => {
		const { name, value } = event.target;
		setGroupData(prevState => ({ ...prevState, [name]: value }));
	}
	useEffect(() => {
		DoctorDropdownList()
		if (typeof location.state !== "undefined" && location.state != null) {
			const { hospitalPatientGroupDescription, doctorId, specializationId, hospitalPatientGroupName, hospitalPatientGroupId, enableEdit } = location.state;
			setEnableEdit(enableEdit);
			setDoctorIdFromRespone(doctorId)
			setHospitalPatientGroupId(hospitalPatientGroupId)
			setGroupData({
				hospitalPatientGroupDescription: hospitalPatientGroupDescription,
				hospitalPatientGroupName: hospitalPatientGroupName,
				hospitalPatientGroupId: hospitalPatientGroupId,
			})
			let PostData = {
				"userId": window.sessionStorage.getItem("userId"),
				"hospitalId": localStorage.getItem("hospitalId"),
				"specializationId": specializationchange(specializationId),
				"doctorId": document.getElementById("doctor").value = DoctorIdFromRespone,
				"hospitalPatientGroupId": hospitalPatientGroupId
			};
			callingAPI('promotionalSms/getspecializationPatients', PostData).then((response) => {
				if (response.data.success === '1') {
					setTotalCountOfPatientAddedInGroup(response.data.result.totalCount)
					let EditedPatientGroupListArray = []
					for (let i = 0; i < response.data.result.memberList.length; i++) {
						var PatientGroupDataOfObjects = {
							memberId: response.data.result.memberList[i].memberId,
							memberAddress: response.data.result.memberList[i].memberAddress,
							memberImageName: response.data.result.memberList[i].memberImageName,
							memberFullName: response.data.result.memberList[i].memberFullName,
							secUserMobileNumber: response.data.result.memberList[i].secUserMobileNumber,
							isActive: true,
							color: "white"
						}
						EditedPatientGroupListArray.push(PatientGroupDataOfObjects)
					}
					setEditedPatientGroupData(EditedPatientGroupListArray)
				}
			});
			SpecializationPatientsFromDoctor("Edit")
		}
		else {
			AllSpecializedPatients()
			SpecializationPatientsFromDoctor("")
		}
	}, []);
	const AddOrRemovePatientCheckBoxChecking = (e, id, addorremovepatient) => {
		if (!enableEdit) {
			return;
		}
		const checked = e.target.checked;
		AddOrRemovePatientFunction(id, checked, addorremovepatient)
	};
	const AddOrRemovePatientFunction = (e, checked, addorremovepatient) => {
		const PostData = {
			"hospitalPatientGroupId": hospitalPatientGroupId,
			"memberId": addorremovepatient.memberId,
			"isChecked": checked ? true : false
		}
		callingAPI('promotionalSms/addOrRemovePatientFromGroup', PostData,
		).then((response) => {
			if (response.data.success === "1") {
				alert(response.data.successMessage)
				setAddorRemoveGroupFromCheckbox(response.data.successMessage)
				
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	}
	const AllSpecializedPatients = () => {
		const postdata = {
			"userId": window.sessionStorage.getItem("userId"),
			"hospitalId": localStorage.getItem("hospitalId")
		}
		callingAPI('promotionalSms/getspecializationPatientsAll', postdata,
		).then((response) => {
			if (response.data.success === "1") {
				setTotalCountOfPatient(response.data.result.totalCount)
				response.data.result.memberList.map((val, id) => {
					val.color = "#94d297"
					val.isActive = true
					return val;
				})
				setSavedPatientGroupData(response.data.result.memberList)
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	}
	const savePatientGroupDetails = () => {
		var specialisationsSelect = [];
		for (var s = 0; s < SavedPatientGroupData.length; s++) {
			if (document.getElementById('chkName308801' + SavedPatientGroupData[s].memberId).checked === true) {
				var a = SavedPatientGroupData[s].memberId
				specialisationsSelect.push(a)
			}
		}
		let flag = true;
		if (!groupData.hospitalPatientGroupName) {
			alert("group Name Required")
			document.getElementById("hospitalPatientGroupName").focus()
			flag = false
		} else if (!groupData.hospitalPatientGroupDescription) {
			alert("groupDescription Required")
			document.getElementById("hospitalPatientGroupDescription").focus()
			flag = false
		}
		if (flag === true) {
			const postData = {
				"userId": window.sessionStorage.getItem("userId"),
				"hospitalId": localStorage.getItem("hospitalId"),
				"doctorId": document.getElementById("doctor").value,
				"specializationId": document.getElementById("speciality").value,
				"hospitalPatientGroupDescription": groupData.hospitalPatientGroupDescription,
				"hospitalPatientGroupName": groupData.hospitalPatientGroupName,
				"listedMemberIds": specialisationsSelect.toString()
			};
			callingAPI("promotionalSms/savePatientGroup", postData).then((data) => {
				if (data.data.success === "1") {
					alert("Group Added Successfully")
					window.location.href = "/communications/hospitalPatientGroup";
				} else {
					alert(data.data.errorMessage)
				}
			});
		}
	}
	const SpecializationPatientsFromDoctor = (mode) => {
		let flag = false;
		if (mode === "Edit") {
			flag = true
		}
		const PostData = {
			"userId": window.sessionStorage.getItem("userId"),
			"hospitalId": localStorage.getItem("hospitalId"),
			"specializationId": "",
			"doctorId": document.getElementById("doctor").value,
		}
		callingAPI('promotionalSms/getspecializationPatients', PostData,
		).then((response) => {
			if (response.data.success === "1") {
				response.data.result.memberList.map((val, id) => {
					val.color = "#94d297"
					val.isActive = flag
					val.isEdited = enableEdit
					return val;
				})
				setTotalCountOfPatient(response.data.result.totalCount)
				const PatientGrouplist = response.data.result.memberList;
				var ArrayForRemoveDuplicationInPatientGroupListData = [];
				let DuplicatedPatientGroupListFound = false;
				PatientGrouplist.map((item) => {
					DuplicatedPatientGroupListFound = false;
					EditedPatientGroupData.map((newItem) => {
						if (item.memberId === newItem.memberId) {
							DuplicatedPatientGroupListFound = true;
						}
					})
					if (!DuplicatedPatientGroupListFound) {
						ArrayForRemoveDuplicationInPatientGroupListData.push(item);
					}
				})
				setSavedPatientGroupData(ArrayForRemoveDuplicationInPatientGroupListData);
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	}
	const DoctorDropdownList = () => {
		
		const doctordropdownData = {
		
			"hospitalId": localStorage.getItem("hospitalId"),
		}
		callingAPI('patientsListing/getDoctorsClick', doctordropdownData,
		).then((response) => {
			if (response.data.success === "1") {
				setDoctorDropDownNames(response.data.result)
			}
		}
		)
			.catch((err) => console.log(err, "err"))
	}
	const specializationchange = (id) => {
		setSpecialityListFromDoctor([])
		const specialityData = {
			"userId": window.sessionStorage.getItem("userId"),
			"hospitalId": localStorage.getItem("hospitalId"),
			"doctorId": document.getElementById("doctor").value
		}
		callingAPI('promotionalSms/getspecializationByDoctor', specialityData,
		).then((response) => {

			if (response.data.success === "1") {
				setSpecialityListFromDoctor(response.data.result)
				document.getElementById("speciality").value = id
			}
		}
		)
			.catch((err) => console.log(err, "err"))

	};
	return (
		<div class="contentSectionCover">
			<div class="mainTitle">Patient Group</div>
			<div class="groupSectionDiv sms-comm-group">
				<div class="formCoverDiv">
					<div class="formItemDiv">
						<div class="formItemHalfDiv">
							<label class="formCommonTxt">Group Name*</label>
							<span class="formFieldSec">
								<span class="colonSpan">:</span>
								<input type="text" name="hospitalPatientGroupName" id="hospitalPatientGroupName" value="" class="formCommonField"
									autofocus="autofocus" value={groupData.hospitalPatientGroupName} onChange={onChange} />
								<input type="hidden" name="groupId" id="groupId" value="" class="formCommonField" />
								<input
									type="hidden"
									name="caseType"
									id="caseType"
									value="doctor"
									class="formCommonField"
								/>
							</span>
						</div>
						<div class="formItemHalfDiv">
							<label class="formCommonTxt">Description*</label>
							<span class="formFieldSec">
								<span class="colonSpan">:</span>
								<input
									type="text"
									name="hospitalPatientGroupDescription"
									value=""
									id="hospitalPatientGroupDescription"
									class="formCommonField"
									autofocus="autofocus"
									value={groupData.hospitalPatientGroupDescription} onChange={onChange}
								/>
							</span>
						</div>
					</div>
					<div class="formItemDiv" />
					<div class="formItemDiv">
						<div class="formItemHalfDiv">
							<label class="formCommonTxt">Doctor</label>
							<span class="formFieldSec">
								<span class="colonSpan">:</span>
								<select tabindex="7" id="doctor" name="doctor" class="formCommonField" value={DoctorIdFromRespone} onChange={(event) => {
									(specializationchange(event)); (SpecializationPatientsFromDoctor(event))
								}} >
									<option value="" >-- Select Doctor -- </option>
									{DoctorDropDownNames.map((item, i) => (
										<option onChange={onChange} value={item.doctorId} >{item.doctorName} </option>
									))}

								</select>
							</span>
						</div>
						<div class="formItemHalfDiv">
							<label class="formCommonTxt">Speciality</label>
							<span class="formFieldSec">
								<span class="colonSpan">:</span>
								<select class="formCommonField" name="speciality" id="speciality" onChange={(event) => { (SpecializationPatientsFromDoctor(event)) }}>
									<option value="" > - - Select Speciality- -</option>
									{SpecialityListFromDoctor.map((item, i) =>
										<option onChange={onChange}   value={item.specializationId} >{item.specializationName}</option>
									)}
								</select> 
							</span>
						</div>
					</div>
				</div>
				<div class="groupPatientCvr">
					<div class="patientDetailDivSec">
						<div class="commonSubTitle">
							Patient Group List
							<span>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patient:<span id="newP">{TotalCountOfPatient}</span>
							</span>
							<span>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient added in the group:<span id="groupP">{TotalCountOfPatientAddedInGroup}</span>
							</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<Link to="/communications/hospitalPatientGroup"
								class="clinicalSave memberProfileSaveBtn_PTour"
								style={{ display: ' inline-block', float: 'right', marginLeft: '10px' }}
							>
								Cancel
							</Link>
							<span id="saveButton">
								<input
									onClick={savePatientGroupDetails}
									type="submit"
									name="_action_savePatientGroup"
									value="Save"
									class="clinicalSave memberProfileSaveBtn_PTour"
									style={{ display: 'inline - block', float: 'right' }}
								/>
							</span>
						</div>
						<div class="patientDetailWraper" id="patientList" style={{ clear: 'both', paddingTop: '5px' }}>

							{EditedPatientGroupData.length > 0 && EditedPatientGroupData.map((item, i) => (

								<div class="patientDetailDiv" id="newMember308801" style={{ backgroundColor: item.color }}>
									<div class="imageLeftDiv"  >
										<img src={item.memberImageName != "" ? getS3folder + "/" + item.memberImageName : User} />
									</div>
									<div class="detailRightDiv" >
										<div class="grpPatientName">{item.memberFullName}</div>
										<div class="grpPatientDOB">Mobile No. {item.secUserMobileNumber}</div>
										<div class="grpPatientAddrs elipsis">Address: {item.memberAddress} </div>
										{item.isActive}
									</div>
									<div class="optionWrapper">
										<input
											type="checkbox"
											id={"chkName308801" + item.memberId}
											value={item.memberId}
											name="memberIdn"
											defaultChecked={item.isActive}
										/>
										<label for={"chkName308801" + item.memberId} />
									</div>
								</div>
							))}
							{SavedPatientGroupData.length > 0 && SavedPatientGroupData.map((item, i) => (
								<div class="patientDetailDiv" id="newMember308801" style={{ backgroundColor: item.color }}>
									<div class="imageLeftDiv"  >
										<img src={item.memberImageName != "" ? getS3folder + "/" + item.memberImageName : User} />
									</div>
									<div class="detailRightDiv" >
										<div class="grpPatientName">{item.memberFullName}</div>
										<div class="grpPatientDOB">Mobile No. {item.secUserMobileNumber}</div>
										<div class="grpPatientAddrs elipsis">Address: {item.memberAddress} </div>
										{item.isActive}
									</div>
									<div class="optionWrapper">
										<input
											type="checkbox"
											id={"chkName308801" + item.memberId}
											value={item.memberId}
											name="memberIdn"
											defaultChecked={item.isActive}
											onChange={(e) => { AddOrRemovePatientCheckBoxChecking(e, "", item) }}
										/>
										<label for={"chkName308801" + item.memberId} />
									</div>
								</div>
							))}
								</div>
							<div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PatientGroup;
