
import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';

import Datepicker from 'react-datepicker';
import moment from 'moment';
import AWS from 'aws-sdk';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import SimplifiedFormPrescriptionRight from '../Patients/simplifiedFormPrescriptionRight';
// var s3;
// AWS.config.update({
// 	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
// 	secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
// 	region: 'us-east-1',
// });
// var bucketParams = {
// 	Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
// };
// s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
const SimplifiedFormPrescription = forwardRef((props, ref) => {


	const [medicineFrequencyDropdown, setmedicineFrequencyDropdown] = useState([]);
	const [medicineIntakeDropdown, setMedicineIntakeDropdown] = useState([]);
	var [selectedMedicineArrListing, setSelectedMedicineArrayListing] = useState([]);
	const [storeAuto, setStoreAuto] = useState([]);
	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [refillCheck, setRefillCheck] = useState('');
	const [selectedCheckbox, setSelectedCheckbox] = useState('Type');

	const [frequencyinvalid, setAllvalid] = useState(true);
	const [intakevalid, setAllvalid1] = useState(true);

	const [timesData, setTimesData] = useState([]);

	var [templateClickShow, settemplateClickShow] = useState(false);
	// const [errMsg, setErrMsg] = useState('');
	const [isShowLoader, setIsShowLoader] = useState(0)
	const [load, setLoad] = useState(false);
	const [isDrawn, setIsDrawn] = useState(false);
	const [getTemplateDetailsArray, setGetTemplateDetailsAray] = useState([
		{
			medicineId: '',
			frequency: '',
			frequencyId: '',
			medicineName: '',
			dosage: ['', '', '', ''],
			intake: '',
			intakeId: '',
			instruction: '',
			quantity: '',
			duration: '',
			durationId: '',
			refill: '',
			startDate: '',
			//storeId: '',
			comments: ''
		},
	]);
	const dropdownValuesSettingFunction = (val) => {
		setmedicineFrequencyDropdown(val.frequency);
		var timesArr = [...timesData];
		// var medArray = []
		selectedMedicineArrListing.forEach((valList, index) => {

			val.frequency.forEach((dp, id) => {
				if (dp.id === Number(valList.frequencyId)) {
					valList.timesPerDay = Number(dp.times)
					timesArr[index] = Number(dp.times);
				}
			});

		});

		//	setTimesData(timesArr);
		setMedicineIntakeDropdown(val.intake);
	};

	const setTimesCheckbox = (val, index, arrindex) => {
		const re = /^[0-9.\b]+$/;
		if (val === '' || re.test(val)) {
			const values = [...selectedMedicineArrListing];
			values[arrindex].dosage[index] = val;
			setSelectedMedicineArrayListing(values);
		} else {
			alert('Only Numbers Accepted');
		}

	};
	const selectedDateFunction = (date, i) => {
		setSelectedStartDate(date);
		const values = [...selectedMedicineArrListing];
		values[i].startDate = date;
		setSelectedMedicineArrayListing(values);
	};
	// const setStoreClick = (name, id, i) => {
	// 	setStoreId(id)
	// 	setRefillCheck(id)
	// 	const values = [...selectedMedicineArrListing];
	// 	selectedMedicineArrListing.forEach((val, index) => {
	// 		val.storeId = id;
	// 	})
	// 	setSelectedMedicineArrayListing(values);
	// 	document.getElementById('txtStore').value = name;
	// 	document.getElementById('hiddenStoreId').value = id;
	// 	setStoreAuto([]);
	// 	document.getElementById('storeList').style.display = 'none';
	// };
	const setMedicineDetails = (e, i) => {
		const re = /^[0-9.\b]+$/;
		const { name, value, checked } = e.target;
		const values = [...selectedMedicineArrListing];
		if (e.target.name === 'refill') {
			values[i] = { ...values[i], [name]: checked };
		} else if (e.target.name == 'frequencyId') {
			var t = document.getElementById('frequencyId' + i);

			var selectedTextForFrequency = t.options[t.selectedIndex].text;
			medicineFrequencyDropdown.forEach((val, id) => {
				if (val.id === Number(e.target.value)) {
					// var timesArr = [...timesData];
					// timesArr[i] = Number(val.times);
					//setTimesData(timesArr);
					values[i].timesPerDay = Number(val.times)
					values[i].dosage = ['', '', ''];
				}
			});
			values[i].frequencyId = value;
			values[i].frequency = selectedTextForFrequency;
		} else if (e.target.name == 'intakeId') {
			var t1 = document.getElementById('intakeId' + i);
			var selectedTextForIntake = t1.options[t1.selectedIndex].text;
			values[i].intakeId = e.target.value;
			values[i].intake = selectedTextForIntake;
		} else if (e.target.name == 'quantity' || e.target.name == 'duration') {
			if (re.test(value) || value === '') {
				values[i] = { ...values[i], [name]: value };
			}
			else {
				alert('Only Numbers Accepted');
			}
		}
		// else if (e.target.name == 'storeId') {
		// 	selectedMedicineArrListing.forEach((val, id) => {
		// 		val.storeId = document.getElementById('hiddenStoreId').value = id
		// 	})
		// }
		else {
			values[i] = { ...values[i], [name]: value };
		}
		setSelectedMedicineArrayListing(values);
	};
	props.onPrescription(selectedMedicineArrListing);
	const savePrescription = () => {
		let isValidateOk = true;
		for (let index = 0; index < selectedMedicineArrListing.length; index++) {
			if (
				selectedMedicineArrListing[index].frequencyId === '' ||
				selectedMedicineArrListing[index].frequencyId === '0'
			) {
				alert('Please Select Frequency & Dosage');
				isValidateOk = false;
				return;
			} else if (
				selectedMedicineArrListing[index].intakeId === '' ||
				selectedMedicineArrListing[index].intakeId === '0'
			) {
				alert('Please Select Intake');
				isValidateOk = false;
				return;
			}
		}
		if (isValidateOk === true) {
			if (selectedMedicineArrListing.length == 0) {
				alert('Add One Drugs');
				return
			}
			var finalArrayToSend = JSON.parse(JSON.stringify(selectedMedicineArrListing));
			finalArrayToSend.map((val, index) => {
				val.refill = val.refill == true ? 'yes' : 'no';
				// val.storeId = refillCheck
				// val.comments = document.getElementById("txtComments").value ? document.getElementById("txtComments").value : ""
				val.startDate = val.startDate
					? moment(val.startDate).format('DD/MM/YYYY')
					: moment(selectedStartDate).format('DD/MM/YYYY');
				let finalDosage = val.dosage.map(
					(dosageCount) => (dosageCount.length > 0 ? dosageCount : dosageCount.replaceAll('', 0))
				);
				val.dosage = finalDosage.toString().replaceAll(',', '-');
				return val;
			});
			setLoad(true)
			let postDta = {
				functionName: 'saveAppointmentPrescription',
				// scheduledTimeSlotId: "14157",
				prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
				userId: window.sessionStorage.getItem('memberId'),
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				hospitalId: localStorage.getItem('hospitalId'),
				type: 'write',
				serviceProviderId: "",
				currentDate: moment(new Date()).format('YYYY-MM-DD'),
				currentDateAndTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
				medicineData: finalArrayToSend,
				medicineImageData: [],
				scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
				scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
			};
			callingAPI('medicinePrescription/saveAppointmentPrescription', postDta).then((data) => {
				if (data.data.success === '1') {
					window.location.href = '/patients/patientconsult';
					//alert('Medicine saved Successfully');
					//setLoad(false)
					//  window.location.href = '/Patients/simplifiedForm';
				} else {
					setLoad(false)
					alert(data.data.errorMessage);
				}
			});
		}
	};
	const settingMedicineTemplateFunction = (groupCodeId, divshow) => {
		settemplateClickShow(true);
		let postData = {
			functionName: 'medicineTemplateDetails',
			templateId: groupCodeId,
		};
		callingAPI('medicinePrescription/medicineTemplateDetails', postData).then((data) => {
			if (data.data.success === '1') {
				var copyArr = [...selectedMedicineArrListing];
				let timeArrayList = []
				data.data.medicineTemplateData.map((temp, i) => {
					copyArr.push(temp)
					temp.startDate = moment(selectedStartDate).format('DD-MMM-YYYY');
					temp.dosage = temp.dosage1.split('-');
					medicineFrequencyDropdown.forEach((val, id) => {
						if (val.id === Number(temp.frequencyId)) {
							//timeArrayList[i]= Number(val.times)
							// var timesArr = [...timesData];
							// timesArr[i] = Number(val.times);
							temp.timesPerDay = Number(val.times)
							//	setTimesData(timesArr);
						}
					});
					return temp;
				});

				//copyArr.push(data.data.medicineTemplateData);
				setSelectedMedicineArrayListing([...copyArr]);
			}
		});
	};

	const settingMedicalNameFunction = (obj, divshow) => {
		var copymedicineArr = [...selectedMedicineArrListing];
		var appendArr = {
			doctorVisitMedicineId: '',
			medicineName: obj.medicineName,
			medicineId: obj.medicineId,
			frequency: '',
			frequencyId: '',
			dosage: ['', '', '', ''],
			intake: '',
			intakeId: '',
			instruction: '',
			quantity: '',
			duration: '',
			durationId: '',
			startDate: moment().format('DD-MMM-YYYY'),
			refill: '',
			//storeId: '',

		};
		copymedicineArr.push(appendArr);
		// appendArr.push(a)
		setSelectedMedicineArrayListing(copymedicineArr);
	};
	const removePrescription = (i) => {
		let removed = [...selectedMedicineArrListing];
		if (removed[i].doctorVisitMedicineId) {
			var x = false;
			x = window.confirm('Do you want to remove the medicine?');
			if (x === true) {
				const postData = {
					userId: window.sessionStorage.getItem('userId'),
					doctorVisitMedicineId: removed[i].doctorVisitMedicineId

				};
				callingAPI('medicinePrescription/deactivatePrescriptionDetails', postData)
					.then((data) => {
						if (data.data.success === '1') {
							removed.splice(i, 1);
							setSelectedMedicineArrayListing(removed);
						}
					})
					.catch((err) => { });
			}
		} else {
			removed.splice(i, 1);
			setSelectedMedicineArrayListing(removed);
		}
	};
	const onChangeValue = (e) => {
		setSelectedCheckbox(e.target.value);
	};



	useImperativeHandle(ref, () => ({
		showPrescription() {
			let isValidateOk = true;
			for (let index = 0; index < selectedMedicineArrListing.length; index++) {
				if (
					selectedMedicineArrListing[index].frequencyId === '' ||
					selectedMedicineArrListing[index].frequencyId === '0'
				) {
					alert('Please Select Frequency & Dosage');
					isValidateOk = false;
					return;
				} else if (
					selectedMedicineArrListing[index].intakeId === '' ||
					selectedMedicineArrListing[index].intakeId === '0'
				) {
					alert('Please Select Intake');
					isValidateOk = false;
					return;
				}
			}
			if (isValidateOk === true) {
				if (selectedMedicineArrListing.length == 0) {
					alert('Add One Drugs');
					return
				}
				var finalArrayToSend = JSON.parse(JSON.stringify(selectedMedicineArrListing));
				finalArrayToSend.map((val, index) => {
					val.refill = val.refill == true ? 'yes' : 'no';
					// val.storeId = refillCheck
					// val.comments = document.getElementById("txtComments").value ? document.getElementById("txtComments").value : ""
					val.startDate = val.startDate
						? moment(val.startDate).format('DD/MM/YYYY')
						: moment(selectedStartDate).format('DD/MM/YYYY');
					let finalDosage = val.dosage.map(
						(dosageCount) => (dosageCount.length > 0 ? dosageCount : dosageCount.replaceAll('', 0))
					);
					val.dosage = finalDosage.toString().replaceAll(',', '-');
					return val;
				});
				setLoad(true)
				let postDta = {
					functionName: 'saveAppointmentPrescription',
					// scheduledTimeSlotId: "14157",
					userId: window.sessionStorage.getItem('memberId'),
					doctorId: window.sessionStorage.getItem('patientDoctorId'),
					hospitalId: localStorage.getItem('hospitalId'),
					type: 'write',
					serviceProviderId: "",
					currentDate: moment(new Date()).format('YYYY-MM-DD'),
					currentDateAndTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
					medicineData: finalArrayToSend,
					medicineImageData: [],
					scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
					scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
				};
				return postDta
			}
			// saveData()
			// alert("Hello from prescription")
		},
	}))
	const saveData = () => {

		savePrescription();

		// this.refs.labReference.LabOrderSave()
	}
	useEffect(() => {
		//	getPatientPrescriptionListDetails();

	}, []);


	return (
		<div className="coverWraper">
			{load == true ? <Loader /> : ''}
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap simplifiedForm" style={{ minHeight: "auto" }}>
				<div className="rightSection simplifiedLabrightSection" >

					<div className="practiceManagementWrap">

						<div className="row practiceManagementRow " style={{ marginBottom: "0px" }}>
							<div className="col-lg-8 col-md-8 practiceLeftSection" style={{ padding: "0px" }}>
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"  >
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">

											<div class="treatmentClinicContent simplifiedAccordion" id="treatmentClinicContentId">
												{/* ====================== MEDICAL CONTENTS STARTS HERE ========================== */}

												<div class="accordion__button" id="PrescriptionContentDiv" >
													<div class="clinicContentList">
														<h2>Prescription</h2>
													</div>
													<button >-</button>
												</div>
												{/* <span onClick={saveData}
													class="treatmentPlanBtn pl_blue_btn"
													id="saveid"
													style={{ background: 'red', display: 'block' }}
												>
													Save
												</span> */}

												<div class="prescriptionContent prescriptionContentAdd precriptionAddedDrugsgroups_PTour">
													<div class="practiceManagementForm1">
														<div class="practiceManagementFormBlcok" id="typePreId">
															<div id="divJsFiles" />

															<div
																id="treatmentPlanSection"
																class="prescriptionAddedMedcne_PTour"
															>
																<div class="treatmentsList precscriptionTrtmntList" style={{ background: "#fafafa" }}>
																	<div
																		class="practiceManagementForm"
																		style={{
																			padding: ' 0px',
																			position: 'relative',
																		}}
																	>
																		<div
																			class="practiceManagementFormBlcok"
																			style={{ margin: '0px' }}
																		>
																			<div class="col-md-12 col-lg-12 form_registrationPatient">
																				{/* <div
															style={{
																color: 'red',
																display: 'flex',
																justifyContent: 'center',
															}}
														>
															{errMsg}
														</div> */}
																				{/*  ====================================== */}
																				<div
																					class="treatmentDivSecWrap prescriptionAddDiv_PTour"
																					id="prescriptionNoDataDiv"
																					style={{
																						display:
																							selectedCheckbox ==
																								'Write' ||
																								selectedMedicineArrListing.length
																								? 'none'
																								: '', height: "731px"
																					}}
																				>
																					<span
																						class="treatmentCommonTxt"
																						id="treatmentCommonTxt"
																					>
																						<span>
																							Add a medicine from the
																							right. Multiple medicine(s)
																							can be added
																						</span>
																					</span>
																				</div>

																				<div
																					id="prescriptionDivMain"
																					class="prescriptionFreqMedcne_PTour prescriptionDivMainHeight"
																					style={{
																						display:
																							selectedCheckbox ==
																								'Type'
																								? ''
																								: 'none',
																					}}
																				>
																					<p
																						style={{
																							display: frequencyinvalid
																								? 'none'
																								: '',
																						}}
																					>
																						{frequencyinvalid &&
																							!intakevalid ? (
																							''
																						) : (
																							'Selcect Frquncy'
																						)}
																					</p>
																					{frequencyinvalid &&
																						intakevalid ? (
																						''
																					) : (
																						'Select Intake id'
																					)}
																					{selectedMedicineArrListing.map(
																						(object, i) => (
																							<div
																								class="prescriptionTrtmntListItem"
																								id="prescriptionTrtmntListItem0"
																							>
																								<span
																									className="close_row"
																									onClick={(e) =>
																										removePrescription(
																											i
																										)}
																								>
																									x
																								</span>
																								<ul className="row">
																									<li class="col-md-3 col-lg-3">
																										<label
																											id="lblMedicineName0"
																											class="lblMedicineName elipsis"
																											title=" CINCLOX-S inj  vial"
																										>
																											{' '}
																											<b>
																												{
																													object.medicineName
																												}
																											</b>
																										</label>
																									</li>

																									<li class="col-md-3 col-lg-3">
																										<label>
																											Frequency
																											&amp;
																											Dosage <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup>
																										</label>
																										<div class="form-group">
																											<select style={{ background: "#ececec", border: "none" }}
																												class="fulWidthSelect"
																												tabindex="2"
																												name="frequencyId"
																												id={
																													'frequencyId' +
																													i
																												}
																												value={
																													object.frequencyId
																												}
																												// onChange={(e) => {
																												// 	setMedicineFrequency(e.target.value)
																												// }}
																												onChange={(
																													e
																												) =>
																													setMedicineDetails(
																														e,
																														i
																													)}
																											>
																												<option value="0">
																													Select
																												</option>
																												{medicineFrequencyDropdown.map(
																													(
																														val,
																														id
																													) => (
																														<option
																															value={
																																val.id
																															}
																														>
																															{
																																val.name
																															}
																														</option>
																													)
																												)}
																											</select>
																										</div>
																									</li>
																									{/*  */}
																									<li
																										class="col-md-3 col-lg-3 liFrequency"
																										id="liFrequencyOnce0"
																									>
																										<label>
																											&nbsp;
																										</label>
																										<input
																											type="text"
																											id="dosage"
																											name="dosage"
																											placeholder="0"
																											tabindex="3"
																											maxlength="6"
																											value={
																												object
																													.dosage[0]
																												// object.dosage.substring(0,1)
																											}
																											style={{
																												display:
																													(object.timesPerDay >= 1)
																														? ''
																														: 'none',
																											}}
																											onChange={(
																												e
																											) =>
																												setTimesCheckbox(
																													e
																														.target
																														.value,
																													'0',
																													i
																												)}
																										// onChange={(
																										// 	e
																										// ) =>
																										// 	setMedicineDetails(
																										// 		e,
																										// 		i
																										// 	)}
																										/>
																										<input
																											type="text"
																											id="dosage1"
																											name="txtNoon0"
																											placeholder="0"
																											tabindex="4"
																											maxlength="6"
																											value={
																												// object.dosage.substring(3,4)
																												object
																													.dosage[1]
																											}
																											style={{
																												display:
																													(object.timesPerDay >= 2)
																														? ''
																														: 'none',
																											}}
																											onChange={(
																												e
																											) =>
																												setTimesCheckbox(
																													e
																														.target
																														.value,
																													'1',
																													i
																												)}
																										/>
																										<input
																											type="text"
																											id="txtNight0"
																											name="txtNight0"
																											placeholder="0"
																											tabindex="5"
																											maxlength="6"
																											value={
																												object
																													.dosage[2]
																											}
																											style={{
																												display:
																													(object.timesPerDay >= 3)
																														? ''
																														: 'none',
																											}}
																											onChange={(
																												e
																											) =>
																												setTimesCheckbox(
																													e
																														.target
																														.value,
																													'2',
																													i
																												)}
																										/>
																										<input
																											type="text"
																											id="txtNight0"
																											name="txtNight0"
																											placeholder="0"
																											tabindex="5"
																											maxlength="6"
																											value={
																												object
																													.dosage[3]
																											}
																											style={{
																												display:
																													(object.timesPerDay >= 4)
																														? ''
																														: 'none',
																											}}
																											onChange={(
																												e
																											) =>
																												setTimesCheckbox(
																													e
																														.target
																														.value,
																													'3',
																													i
																												)}
																										/>
																									</li>
																									{/*  */}

																									<li class="col-md-3 col-lg-3">
																										<label>Intake <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></label>
																										<div class="form-group">
																											<select style={{ background: "#ececec", border: "none" }}
																												class="fulWidthSelect"
																												data-width="100%"
																												id={
																													'intakeId' +
																													i
																												}
																												name="intakeId"
																												tabindex="6"
																												value={
																													object.intakeId
																												}
																												onChange={(
																													e
																												) =>
																													setMedicineDetails(
																														e,
																														i
																													)}

																											// onChange={(e) => setIntake(e.target.value)}
																											>
																												<option
																													selected=""
																													value="0"
																												>
																													Select
																												</option>
																												{medicineIntakeDropdown.map(
																													(
																														val,
																														id
																													) => (
																														<option
																															value={
																																val.id
																															}
																														>
																															{
																																val.name
																															}
																														</option>
																													)
																												)}
																											</select>
																										</div>
																									</li>

																									<li class="col-md-3 col-lg-3">
																										<label>
																											Instructions
																										</label>
																										<input
																											type="text"
																											id="instruction"
																											name="instruction"
																											placeholder="Instructions"
																											tabindex="7"
																											autocomplete="off"
																											class="txtInstructions"
																											value={
																												object.instruction
																											}
																											onChange={(
																												e
																											) =>
																												setMedicineDetails(
																													e,
																													i
																												)}

																										// onChange={(e) => setInstruction(e.target.value)}
																										/>
																									</li>
																									<li class="col-md-2 col-lg-2">
																										<label>
																											Quantity
																										</label>
																										<input
																											type="text"
																											id="quantity"
																											name="quantity"
																											placeholder="Qty"
																											tabindex="8"
																											maxlength="8"
																											value={
																												object.quantity
																											}
																											onChange={(
																												e
																											) =>
																												setMedicineDetails(
																													e,
																													i
																												)}

																										// onChange={(e) => setQuantity(e.target.value)}
																										/>
																									</li>
																									<li class="col-md-2 col-lg-2">
																										<label>
																											Days
																										</label>
																										<input
																											type="text"
																											id="duration"
																											name="duration"
																											placeholder="Days"
																											tabindex="9"
																											maxlength="9"
																											value={
																												object.duration
																											}
																											// onChange={(e) => setDays(e.target.value)}
																											onChange={(
																												e
																											) =>
																												setMedicineDetails(
																													e,
																													i
																												)}
																										/>
																									</li>
																									<li class="col-md-3 col-lg-3">
																										<label>
																											Start
																											Date
																										</label>
																										<Datepicker
																											required
																											id="startdate"
																											name="startdate"
																											className="Datepicker pa2 inputField"
																											// placeholderText="15/11/2021"
																											calendarClassName="rasta-stripes"
																											dateFormat="dd-MM-yyyy"
																											// value={}
																											selected={
																												object.startDate ? (
																													new Date(
																														object.startDate
																													)
																												) : (
																													new Date()
																												)
																											}
																											peekNextMonth
																											showMonthDropdown
																											showYearDropdown
																											minDate={
																												new Date()
																											}
																											dropdownMode="select"
																											onChange={(
																												date
																											) =>
																												selectedDateFunction(
																													date,
																													i
																												)}
																										/>
																									</li>
																									<li class="col-md-1 col-lg-1">
																										<label>
																											Refill
																										</label>
																										<input
																											type="checkbox"
																											id={
																												'refill' +
																												i
																											}
																											tabindex="11"
																											onChange={(
																												e
																											) =>
																												setMedicineDetails(
																													e,
																													i
																												)}
																											name={
																												'refill'
																											}
																											checked={
																												object.refill
																											}
																											placeholder="Patient Name"
																											style={{
																												width:
																													'15px',
																												marginTop:
																													'10px',
																											}}
																										/>
																									</li>

																									<div class="clearfix" />
																								</ul>
																							</div>
																						)
																					)}
																					{/* {selectedMedicineArrListing.length >
																						0 ? (
																						<div
																							class="precsriptionStore"
																							id="medicineCommonSection"
																						>
																							<ul>
																								<li class="col-md-12 col-lg-12 PaddingGridFix12">
																									<label>
																										Select Store
																									</label>
																									<input
																										type="text"
																										id="txtStore"
																										name="txtStore"
																										class="prescriptionSelectStore_PTour ui-autocomplete-input"
																										// data-position="top"
																										tabindex="13"
																										placeholder="Select Store"
																										autocomplete="off"
																										onClick={() =>
																											getStoreNameAutoComplete('txtStore','storeList')}
																											style={{ height: '34px' }}
																											onKeyUp={() =>
																												getStoreNameAutoComplete('txtStore','storeList')}
																									/>
																									<ul
																										className="autoSearch autoSearch-p0"
																										id="storeList"
																										style={{
																											display:
																												'none',
																										}}
																									>
																										{storeAuto.map(
																											(
																												item,
																												i
																											) => (
																												<li
																													onClick={() =>
																														setStoreClick(
																															item.StoreName,
																															item.SPID,'txtStore','storeList'
																														)}
																												>
																													{
																														item.StoreName
																													}
																												</li>
																											)
																										)}
																									</ul>
																									<input
																										type="hidden"
																										id="hiddenStoreId"
																									/>
																								</li>
																								<li class="col-md-12 col-lg-12 PaddingGridFix12 prescriptionComments_PTour">
																									<label>
																										Comments
																									</label>
																									<textarea
																										id="txtComments"
																										name="txtComments"
																										tabindex="14"
																										placeholder="Comments"
																										maxlength="5000"
																										className="textareaField"
																									/>
																								</li>
																							</ul>
																							<div class="treatmentComplaintRowWrap treatmentPrescriptionRowWrap" />
																						</div>
																					) : null} */}
																				</div>

																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* ====================== MEDICAL CONTENTS ENDS HERE ============================ */}
											</div>

										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection   simplifiedFormPrescriptionRightSec">
								<SimplifiedFormPrescriptionRight

									parentCallback={dropdownValuesSettingFunction}
									setingMedicalName={settingMedicalNameFunction}
									settingMedicalTemplate={settingMedicineTemplateFunction}
									getTemplateDetails={getTemplateDetailsArray}
								/>

							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	);
})
export default SimplifiedFormPrescription;
