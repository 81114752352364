import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import MultiGraph from "../Patients/GraphComponents/multiGraph";
import GraphComponents from './GraphComponents/graphComponents';
function VitalGraph() {
	const [result, setResult] = useState([])
	const [creatinineX, setCreatinineX] = useState([])
	const [creatinineY, setCreatinineY] = useState([])
	const [ureaX, setUreaX] = useState([])
	const [ureaY, setUreaY] = useState([])
	const [urine_albuminX, setUrine_albuminX] = useState([])
	const [urine_albuminY, setUrine_albuminY] = useState([])
	const [CholestrolX, setCholestrolX] = useState([])
	const [CholestrolY, setCholestrolY] = useState([])
	const [TriglycerideX, setTriglycerideX] = useState([])
	const [TriglycerideY, setTriglycerideY] = useState([])
	const [LDLX, setLDLX] = useState([])
	const [LDLY, setLDLY] = useState([])
	const [HDLX, setHDLX] = useState([])
	const [HDLY, setHDLY] = useState([])
	const [hbSetX, setHbSetX] = useState([])
	const [hbSetY, setHbSetY] = useState([])
	const [hbacSetX, setHbacSetX] = useState([])
	const [hbacSetY, setHbacSetY] = useState([])
	const [cbcSetX, setCbcSetX] = useState([])
	const [cbcSetY, setCbcSetY] = useState([])
	const [rbsSetX, setRbsSetX] = useState([])
	const [rbsSetY, setRbsSetY] = useState([])
	const [PPBSSetX, setPPBSSetX] = useState([])
	const [PPBSSetY, setPPBSSetY] = useState([])
	const [fbsSetX, setFbsSetX] = useState([])
	const [fbsSetY, setFbsSetY] = useState([])
	const [weightSetX, setWeightSetX] = useState([])
	const [weightSetY, setWeightSetY] = useState([])
	const [tempSetX, setTempSetX] = useState([])
	const [tempSetY, setTempSetY] = useState([])
	const [pulseSetX, setPulseSetX] = useState([])
	const [pulseSetY, setPulseSetY] = useState([])
	// const [data, setData] = useState([])
	// const [data12, setData12] = useState([])
	const [wholeSet, setWholeSet] = useState([])
	const [wholeSetDate, setWholeSetDate] = useState([])
	const [highAndLowBPSet, setHighAndLowBPSet] = useState([])
	const chartList = () => {
		let PostData =
		{
			"doctorId": window.sessionStorage.getItem('doctorId'),
			"patientId": window.sessionStorage.getItem('memberId'),
			"hospitalId": localStorage.getItem('hospitalId'),
		}
		callingAPI('/commonUserFunctions/vitalGraphList', PostData).then((response) => {
			if (response.data.success === "1") {
				setResult(response.data.result)
				setCreatinineX(response.data.result.creatinineX)
				setCreatinineY(response.data.result.creatinineY)
				setUreaX(response.data.result.ureaX)
				setUreaY(response.data.result.ureaY)
				setUrine_albuminX(response.data.result.urine_albuminX)
				setUrine_albuminY(response.data.result.urine_albuminY)
				setCholestrolX(response.data.result.CholestrolX);
				setCholestrolY(response.data.result.CholestrolY);
				setTriglycerideX(response.data.result.TriglycerideX);
				setTriglycerideY(response.data.result.TriglycerideY);
				setLDLX(response.data.result.LDLX);
				setLDLY(response.data.result.LDLY);
				setHDLX(response.data.result.HDLX);
				setHDLY(response.data.result.HDLY);
				setHbSetX(response.data.result.hbSetX);
				setHbSetY(response.data.result.hbSetY);
				setHbacSetX(response.data.result.hbacSetX);
				setHbacSetY(response.data.result.hbacSetY);
				setCbcSetX(response.data.result.cbcSetX);
				setCbcSetY(response.data.result.cbcSetY);
				setRbsSetX(response.data.result.rbsSetX);
				setRbsSetY(response.data.result.rbsSetY);
				setPPBSSetX(response.data.result.PPBSSetX);
				setPPBSSetY(response.data.result.PPBSSetY);
				setFbsSetX(response.data.result.fbsSetX);
				setFbsSetY(response.data.result.fbsSetY);
				setWeightSetX(response.data.result.weightSetX);
				setWeightSetY(response.data.result.weightSetY);
				setTempSetX(response.data.result.tempSetX);
				setTempSetY(response.data.result.tempSetY);
				setPulseSetX(response.data.result.pulseSetX);
				setPulseSetY(response.data.result.pulseSetY);
				setWholeSet(response.data.result.wholeSet);
				// setData12(response.data.result.wholeSet[1].data);
				setWholeSetDate(response.data.result.wholeSetDate);
				setHighAndLowBPSet(response.data.result.highAndLowBPSet);
			}
			else {
				setResult([]);
			}
		});
	}
	useEffect(() => {
		chartList();
	}, [])
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">VITAL SIGNS GRAPH</span>
											</div>
											<div class="treatmentPlanList" style={{ height: "auto" }}>
												<div className="sharedata">
													<div className="treatmentProfileCover">
														<div className="treatmentPlanContent">
															{(creatinineX.length > 0) && (creatinineY.length > 0) ?
																<GraphComponents xArray={creatinineX} yArray={creatinineY} name={"Creatinine"} />
																: ""}
															{ureaX.length > 0 && ureaY.length > 0 ?
																<GraphComponents xArray={ureaX} yArray={ureaY} name={"Urea"} />
																: ""}
															{urine_albuminX.length > 0 && urine_albuminY.length > 0 ?
																<GraphComponents urine_albuminArrX={urine_albuminX} urine_albuminArrY={urine_albuminY} name={"Urine Albumin"} />
																: ""}

															{CholestrolX.length > 0 && CholestrolY.length > 0 ?
																<GraphComponents xArray={CholestrolX} yArray={CholestrolY} name={"Cholestrol"} />
																: ""}
															{TriglycerideX.length > 0 && TriglycerideY.length > 0 ?
																<GraphComponents TriglycerideArrX={TriglycerideX} TriglycerideArrY={TriglycerideY} name={"Triglyceride"} />
																: ""}
															{LDLX.length > 0 && LDLY.length > 0 ?
																<GraphComponents xArray={LDLX} yArray={LDLY} name={"LDL"} />
																: ""}
															{HDLX.length > 0 && HDLY.length > 0 ?
																<GraphComponents xArray={HDLX} yArray={HDLY} name={"HDL"} />
																: ""}
															{hbSetX.length > 0 && hbSetY.length > 0 ?
																<GraphComponents xArray={hbSetX} yArray={hbSetY} name={"Hb"} />
																: ""}
															{hbacSetX.length > 0 && hbacSetY.length > 0 ?
																<GraphComponents xArray={hbacSetX} yArray={hbacSetY} name={"Hb Ac(%)"} />
																: ""}
															{cbcSetX.length > 0 && cbcSetY.length > 0 ?
																<GraphComponents xArray={cbcSetX} yArray={cbcSetY} name={"CBC"} />
																: ""}
															{rbsSetX.length > 0 && rbsSetY.length > 0 ?
																<GraphComponents xArray={rbsSetX} yArray={rbsSetY} name={"RBS"} />
																: ""}
															{PPBSSetX.length > 0 && PPBSSetY.length > 0 ?
																<GraphComponents xArray={PPBSSetX} yArray={PPBSSetY} name={"PPBS"} />
																: ""}
															{fbsSetX.length > 0 && fbsSetY.length > 0 ?
																<GraphComponents xArray={fbsSetX} yArray={fbsSetY} name={"FBS"} />
																: ""}
															{weightSetX.length > 0 && weightSetY.length > 0 ?
																<GraphComponents xArray={weightSetX} yArray={weightSetY} name={"Weight"} />
																: ""}
															{tempSetX.length > 0 && tempSetY.length > 0 ?
																<GraphComponents xArray={tempSetX} yArray={tempSetY} name={"Temperature"} />
																: ""}
															{pulseSetX.length > 0 && pulseSetY.length > 0 ?
																<GraphComponents xArray={pulseSetX} yArray={pulseSetY} name={"Pulse"} />
																: ""}
															{wholeSet.length > 0 && wholeSetDate.length > 0 ?
																<MultiGraph xArray={wholeSet} yArray={wholeSetDate} name={"Systolic and Diastolic"} highBp={highAndLowBPSet[0]} lowBp={highAndLowBPSet[1]} />
																: ""}
														</div>
													</div>
												</div>
												{/*  */}
											</div>
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div >
	);
}
export default VitalGraph;
