import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import { callingAPI } from '../config';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import Pagination from 'react-js-pagination';
import DatePicker, { CalendarContainer } from "react-datepicker";

function TotalPendingPayment() {
	const downloadName = "totalPendingPayment";
	const [dataFlag, setDataFlag] = useState(false);
	const [pendingPaymentDueData, setpendingPaymentDueData] = useState([]);
	const [count, setCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [popUpDoctorList, setPopUpDoctorList] = useState(false);
	const [doctorList, setDoctorList] = useState([]);
	const [memberIdNew, setMemberIdNew] = useState('');
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [urlLink, setUrl] = useState("");

	const handleKeypress = (event) => {
		if (event.key === 'Enter') {
			paymentReportSearch();
		}
	};
	const searchInChange = (event) => {
		document.getElementById("searchInBy").value = ""
		if (event === "") {
			document.getElementById("searchInBy").value = ""
			document.getElementById("searchInBy").disabled = true;
			document.getElementById("searchInBy").style.background = "transparent";
		} else {
			document.getElementById("searchInBy").disabled = false;
			document.getElementById("searchInBy").focus();
			document.getElementById("searchInBy").style.background = "white";
		}
	}
	const popup = (memberId, url) => {

		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') {
			window.sessionStorage.setItem('memberId', memberId);
			window.sessionStorage.setItem('patientDoctorId', window.sessionStorage.getItem('userId'));
			localStorage.setItem('hospitalId', localStorage.getItem('hospitalId'));
			window.sessionStorage.setItem('patientDoctorName', window.sessionStorage.getItem('userName'));
			window.location.href = url;

		} else {
			setUrl(url)
			setMemberIdNew(memberId);

			let PostData = {
				hospitalId: localStorage.getItem('hospitalId'),
				selectCombo: '',
				inputSearch: '',
			};
			callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
				if (response.data.success === '1') {

					setDoctorList(response.data.result.doctorsListArray);
					setPopUpDoctorList(true);
				}
				else {
					alert("no doctors found")
				}

			});
		}
	};
	const closePopUp = () => {
		setPopUpDoctorList(false);
	};
	const hospitalpageChange = (index) => {
		if (index !== '') {
			window.sessionStorage.setItem('memberId', memberIdNew);
			window.sessionStorage.setItem('doctorId', doctorList[index].doctorId);
			window.sessionStorage.setItem('medicineBranchId', doctorList[index].medicineBranchId);
			window.sessionStorage.setItem('patientDoctorId', doctorList[index].doctorId);
			localStorage.setItem('hospitalId', doctorList[index].hospitalId);
			window.sessionStorage.setItem('patientDoctorName', doctorList[index].doctorName);
			window.sessionStorage.setItem('patientDoctorSpecialation', doctorList[index].specialization);
			window.location.href = urlLink;
		}
	};

	const handlePageChange = (pageNumber) => {
		//	setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		const PostData = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"fromDate": fromDate ? formatDate(fromDate) : '',
			"toDate": toDate ? formatDate(toDate) : '',
			"sortType": document.getElementById("sortId").value,
			"cmbSelect": document.getElementById("searchIn").value,
			"txtInput": document.getElementById("searchInBy").value,
			"offset": offset,
			"max": max
		};

		callingAPI('medicinePrescription/pendingPaymentDue', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setpendingPaymentDueData(data.data.result.pendingpaymentdetails);

					setCount(data.data.result.count);
					setDataFlag(true);
				}
				else {
					setpendingPaymentDueData([]);
					setCount("");
					setDataFlag(true);

				}
			})
			.catch();

	};

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}

	const exportPendingPayment = () => {
		if (pendingPaymentDueData.length === 0) {
			alert('No Data to Export');
		}
		else {
			const csvRow = [];
			var pendingPaymentDueDataInfo = [
				[
					"Patient%20Name",
					"Age",
					"MR%20Number",
					"Mobile%20Number",
					"Email",
					"Total%20Due%20Amount",
				],
			];
			var name = pendingPaymentDueDataInfo;
			let PostData = {
				"hospitalId": localStorage.getItem('hospitalId'),
				"fromDate": fromDate ? formatDate(fromDate) : '',
				"toDate": toDate ? formatDate(toDate) : '',
				"sortType": document.getElementById("sortId").value,
				"cmbSelect": document.getElementById("searchIn").value,
				"txtInput": document.getElementById("searchInBy").value,
				"max": "",
				"offset": 0,
			};
			callingAPI('medicinePrescription/pendingPaymentDue', PostData).then((data) => {

				if (data.data.success === '1') {
					for (var item = 0; item < data.data.result.pendingpaymentdetails.length; item++) {

						pendingPaymentDueDataInfo.push([
							data.data.result.pendingpaymentdetails[item].memberName.replace(/ /g, '%20'),
							data.data.result.pendingpaymentdetails[item].memberAge,
							data.data.result.pendingpaymentdetails[item].memberMrNo,
							data.data.result.pendingpaymentdetails[item].mobileNumber,
							data.data.result.pendingpaymentdetails[item].emailId,
							"Rs." + "" + data.data.result.pendingpaymentdetails[item].memberProedureInvoiceTotalBalanceAmount.replace(/,/g, ''), ,
						]);

					}
				}
				for (var i = 0; i < pendingPaymentDueDataInfo.length; ++i) {
					csvRow.push(pendingPaymentDueDataInfo[i].join(","));
				}
				var csvString = csvRow.join("%0A");
				var creat = document.createElement("a");
				creat.href = "data:attachment/csv," + csvString;
				creat.target = "_Blank";
				creat.download = downloadName + ".csv";
				document.body.appendChild(creat);
				creat.click();
			})
		}
	}

	const paymentReportSearch = () => {
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				alert('End date must be greater than start date');
				document.getElementById("todate").focus()
				return (false);
			}
		}
		if (document.getElementById("searchIn").value !== "" && document.getElementById("searchInBy").value === "") {
			var t = document.getElementById("searchIn");
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById("searchInBy").focus();
			alert("Enter" + " " + selectedText);
			return false;
		}
		const PostData = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"fromDate": fromDate ? formatDate(fromDate) : '',
			"toDate": toDate ? formatDate(toDate) : '',
			"sortType": document.getElementById("sortId").value,
			"cmbSelect": document.getElementById("searchIn").value,
			"txtInput": document.getElementById("searchInBy").value,
			"max": process.env.REACT_APP_PAGINATION_COUNT,
			"offset": 0,
		};
		callingAPI('medicinePrescription/pendingPaymentDue', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setpendingPaymentDueData(data.data.result.pendingpaymentdetails);
					setCount(data.data.result.count);
					setDataFlag(true);
				}
				else {
					setpendingPaymentDueData([]);
					setCount("");
					setDataFlag(true);

				}
			})
			.catch();
	}


	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setFromDate("")

	};

	const ToDateClear = () => {
		setToDate("")

	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	useEffect(() => {
		listing()
	}, []);
	const listing = () => {
		const PostData = {
			"hospitalId": localStorage.getItem('hospitalId'),
			"fromDate": "",
			"toDate": "",
			"sortType": "",
			"cmbSelect": "",
			"txtInput": "",
			"max": process.env.REACT_APP_PAGINATION_COUNT,
			"offset": pageOffset,
		};

		callingAPI('medicinePrescription/pendingPaymentDue', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setpendingPaymentDueData(data.data.result.pendingpaymentdetails);
					setCount(data.data.result.count);
					setDataFlag(true);
				}
				else {
					setpendingPaymentDueData([]);
					setCount("");
					setDataFlag(true);
				}
			})
			.catch();
	}
	const handleClear = () => {
		document.getElementById("sortId").value = ""
		document.getElementById("searchIn").value = ""
		document.getElementById("searchInBy").value = ""
		setToDate("")
		setFromDate("")
		listing()
	}

	return (

		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll" style={{ backgroundColor: "#fff" }}>
				<Leftmenu />
				<div className='main_wrappersec'>
					<div className="dashboardCover pharmacyManufactureWrap pharmacyManuf pulseDashboard ">
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle"> Total Pending Payment Report</div>
							</div>
							<div className="dashboardSearch">
								<div className="row">

									<div className="col-md-2 dashboardSearchList">
										<select className="inputField" id='searchIn' onChange={(e) => searchInChange(e)} >
											<option value="">Search In</option>
											<option value="1">Patient Name</option>
											{/* <option value="2">Invoice No.</option> */}
											<option value="3">Mobile Number</option>
											<option value="4">Email Id</option>
											<option value="5">MR Number</option>
										</select>
									</div>
									<div className="col-md-2 dashboardSearchList">
										<input className="inputField"
											style={{ background: "transparent" }}
											disabled
											id='searchInBy' onKeyPress={handleKeypress}></input>
									</div>
									<div className="col-md-2 dashboardSearchList">
										<select className="inputField" id='sortId'>
											<option value="">Sort By</option>
											<option value="highToLow">High to Low</option>
											<option value="lowToHigh">Low to High</option>
										</select>
									</div>
									<div className="col-md-1 dashboardSearchList">
										<DatePicker
											className="inputField"
											placeholderText="From Date"
											maxDate={new Date()}
											calendarClassName="rasta-stripes"
											selected={fromDate}
											value={fromDate}
											dateFormat="dd/MM/yyyy"
											onChange={(date) => setFromDate(date)}
											id="fromdate"
											showMonthDropdown
											showYearDropdown
											autoComplete='off'
											dropdownMode="select"
											onChangeRaw={handleDateChangeRaw}
											calendarContainer={MyContainerFromDate}
										/>
									</div>
									<div className="col-md-1 dashboardSearchList">
										<DatePicker
											className="inputField"
											placeholderText="To Date"
											maxDate={new Date()}
											calendarClassName="rasta-stripes"
											selected={toDate}
											value={toDate}
											dateFormat="dd/MM/yyyy"
											onChange={(date) => setToDate(date)}
											id="todate"
											showMonthDropdown
											showYearDropdown
											autoComplete='off'
											dropdownMode="select"
											onChangeRaw={handleDateChangeRaw}
											calendarContainer={MyContainerToDate}
										/>
									</div>


									<div className="col-md-1 dashboardSearchList" >
										<span className="dashButtonBorder" onClick={(e) => paymentReportSearch()}>Search</span>
									</div>
									<div className="col-md-1 dashboardSearchList" >
										<span className="dashButtonBorder" onClick={(e) => exportPendingPayment()}>Export</span>
									</div>
									<div className="col-md-1 dashboardSearchList" >
										<span className="dashButtonBorder" onClick={(e) => handleClear()}>Clear</span>
									</div>
								</div>
							</div>
						</div>
						{count > 10 && (
							<div className="paginationSection">
								<Pagination
									// hideNavigation
									hideDisabled
									activePage={activePage}
									itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
									totalItemsCount={count}
									pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
									onChange={handlePageChange}
								/>
							</div>
						)}
						<div>
							{popUpDoctorList == true && (
								<div>
									{doctorList.length > 1 ?
										<div className="ploverlayy">

											<div className="plpopup plpopupList selectDr " style={{ width: '400px' }}>
												<h2>
													<a class="close" href="#" onClick={closePopUp}>
														&times;
													</a>
												</h2>

												<div className="plcontent">
													<label>
														Select Doctor<span className="mandatory">*</span>
													</label>
													<div className="addDataContent" style={{ padding: '4px 0 0' }}>
														<div className="addDataDiv" id="styled-select">
															{doctorList.length > 1 ?
																<select
																	id="default"
																	style={{ width: '100%' }}
																	onChange={(e) => hospitalpageChange(e.target.value)}
																>
																	<option value="">Doctor Name</option>
																	{doctorList.map((Popuplist, i) => (
																		<option value={i}>
																			{Popuplist.doctorName}

																		</option>
																	))}
																</select>
																:
																<select
																	id="default"
																	style={{ width: '100%' }}
																	onChange={(e) => hospitalpageChange(e.target.value)}
																>
																	{doctorList.map((Popuplist, i) => (
																		<option value={i}>
																			{Popuplist.doctorName}
																		</option>
																	))}
																</select>}

														</div>
													</div>
												</div>

											</div>

										</div>
										: hospitalpageChange(0)}
								</div>
							)}
						</div>

						{dataFlag == false ? (
							<Loader />
						) : pendingPaymentDueData.length > 0 ? (
							<div className="dashboardContent">
								<div className="reportWrap">

									<div className="dashboardtable">
										<table>
											<thead>
												<tr>
													<th>Patient Name</th>
													<th>Age</th>
													<th>MR No</th>
													{/* <th>
														{window.sessionStorage.getItem('isPrNumEnabled') === "false" ? "MR No " : "PR No"}
													</th> */}
													<th>Mobile Number </th>
													<th>Email Id</th>
													<th>Total Due Amount</th>
													<th>Go To</th>

												</tr>
											</thead>
											<tbody>
												{pendingPaymentDueData.map((item, index) => (
													<tr>
														<td>{item.memberName}</td>
														<td>{item.memberAge}</td>
														<td>{item.memberMrNo}</td>
														{/* <td>{window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (item.memberMrNo) : (item.memberPrNo)}</td> */}
														<td>{item.mobileNumber}</td>
														<td>{item.emailId}</td>
														<td>{item.memberProedureInvoiceTotalBalanceAmount}</td>
														<td>
															<span className='gotoButtons' style={{ backgroundColor: "#007bfad1" }} onClick={(e) => popup(item.memberId, '/patients/patientconsult')}>Profile</span>
															<span className='gotoButtons' style={{ backgroundColor: "#2cbb41" }} onClick={(e) => popup(item.memberId, '/patients/patientInvoices')}>Invoice</span>

														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>

							</div>
						) : (
							<p>
								<NoData />
							</p>
						)}
					</div>
					{count > 10 && (
						<div className="paginationSection">
							<Pagination
								// hideNavigation
								hideDisabled
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={count}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={handlePageChange}
							/>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default TotalPendingPayment;


