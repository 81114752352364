import React, { useEffect } from 'react'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const UseMic = (props) => {
    useEffect(() => {

    }, [])

    return (
        <div>
        <FontAwesomeIcon title='Use Microphone' icon={faMicrophone}  className='micIcon' onClick={() => props.micClick(true)} />
        </div>
    )
}
export default UseMic;