import React from 'react';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config'
import HeaderDoctorPrescriptionPrint from '../Settings/headerDoctorPrescriptionPrint';
import FooterDoctorPrescriptionPrint from '../Settings/footerDoctorPrescriptionPrint'
import moment from 'moment';
function PriscriptionPrint(props) {
    const [memberDetails, setMemberDetails] = useState({});
    const [medicineData, setMedicineData] = useState([]);
    const [marginbottom, setmarginbottom] = useState('');
    const [margintop, setmargintop] = useState('');
    const [marginleft, setmarginleft] = useState();
    const [marginright, setmarginright] = useState('');
    const [rx, setRx] = useState('');
    const [pagesizeid, setpagesizeid] = useState('');
    const [custum_width, setcustum_width] = useState('');
    const [custum_height, setcustum_height] = useState();
    const [date, setDate] = useState("");
    const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
    const [isChecked7, setIsChecked7] = useState(false);
    useEffect(() => {
        var date1 = moment(new Date()).format('DD-MM-yyyy');
        setDate(date1)
        styleValues()
        setMedicineData(JSON.parse(window.sessionStorage.getItem('setMedicineData')))
        const printId = JSON.parse(window.sessionStorage.getItem('prescriptionidsNotInHospital'))
        const postData = {
            prescriptionDate: printId.prescriptionDate === "" ? "" : printId.prescriptionDate,
            medicineId: printId.medicineId,
            doctorVisitId: printId.doctorVisitId,
        };
        callingAPI('commonUserFunctions/printPrescription', postData).then((res) => {
            if (res.data.success === "1") {
                setMemberDetails(res.data.result[0].memberDetails[0]);
            }
        })
        window.sessionStorage.removeItem('prescriptionidsNotInHospital')
    }, []);

    function styleValues(hospitalId) {
        const PostData = {
            hospitalId: localStorage.getItem('hospitalId'),
            doctorId: props.doctorDataId ? props.doctorDataId : sessionStorage.getItem('doctorId'),
        };
        callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
            .then((res) => {
                setRx(res.data.result.headerFooterDetails[0].showRx)
                setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
                setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
                setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
                setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
                setcustum_width(res.data.result.headerFooterDetails[0].custum_width);
                setcustum_height(res.data.result.headerFooterDetails[0].custum_height);
                setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);
            });

    }

    const printPrescription = () => {
        const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
        // Hide unchecked sections before printing
		
			if (isChecked4) {
				section4.style.display = 'none';
			}
			if (isChecked5) {
				section51.style.display = 'none';
				section52.style.display = 'none';
			}
			if (isChecked7) {
				section7.style.display = 'none';
			}
        document.getElementById('printButton').style.display = 'none';
        window.print();
        document.getElementById('printButton').style.display = 'block';
        section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';

    };
    if (pagesizeid == '1') {
        var a4sizewidth = '540px'
        var a4sizeheight = '842px'
    }
    if (pagesizeid == '2') {
        var a5sizewidth = '420px'
        var a5sizeheight = '595px'
    }
    if (pagesizeid == '3') {
        var b4sizewidth = '780px'
        var b4sizeheight = '288px'
    }
    if (pagesizeid == '4') {
        var b5sizewidth = '540px'
        var b5sizeheight = '414px'
    }
    if (pagesizeid == '5') {
        var lettersizewidth = '792px'
        var lettersizeheight = '612px'
    }
    if (pagesizeid == '6') {
        var prescriptionsizewidth = '540px'
        var prescriptionsizeheight = '794px'
    }
    else {
        var a4sizewidth = '540px'
        var a4sizeheight = '842px'
    }
    return (
        <div align="center" className='patientPrescriptionWrapper'>
            <div>
                <div
                    align="center"
                    style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth || custum_width + 'px',
                        height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight || custum_height + 'px',
                    }}
                >
                    <table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }}
                        border="0">
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '400' }}>
                                    <div class="printOutHeaderSec ">
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <HeaderDoctorPrescriptionPrint setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5}/>
                                                <tr>
                                                    <td
                                                        colspan="6"
                                                        style={{
                                                            width: '50%',
                                                            fontSize: '12px',
                                                            padding: '0',
                                                            lineHeight: '17px',
                                                            textAlign: 'left',
                                                        }}

                                                    >
                                                        Name :
                                                        &nbsp;{memberDetails.memberFirstName} {memberDetails.memberLastName}
                                                    </td>
                                                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                        (<td
                                                            colspan="6"
                                                            style={{
                                                                width: '50%',
                                                                fontSize: '12px',
                                                                padding: '0',
                                                                lineHeight: '15px',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            MR No :
                                                            &nbsp;{memberDetails.memberMrNo}
                                                        </td>) :
                                                        (<td
                                                            colspan="6"
                                                            style={{
                                                                width: '50%',
                                                                fontSize: '12px',
                                                                padding: '0',
                                                                lineHeight: '15px',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            PR No :
                                                            &nbsp;{memberDetails.memberPrNo}
                                                        </td>)

                                                    }
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="6"
                                                        style={{
                                                            width: '50%',
                                                            fontSize: '12px',
                                                            padding: '0',
                                                            lineHeight: '15px',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        Age :
                                                        &nbsp;{memberDetails.memberAge}
                                                    </td>
                                                    <td colspan="6"
                                                        style={{
                                                            width: '50%',
                                                            fontSize: '12px',
                                                            padding: '0',
                                                            lineHeight: '15px',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        Mobile No :
                                                        &nbsp;{memberDetails.SecuserMobileNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="6"
                                                        style={{
                                                            width: '50%',
                                                            fontSize: '12px',
                                                            padding: '0',
                                                            lineHeight: '15px',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        Sex :
                                                        &nbsp;{memberDetails.memberGender}
                                                    </td>
                                                    <td colspan="6"
                                                        style={{
                                                            width: '50%',
                                                            fontSize: '12px',
                                                            padding: '0',
                                                            lineHeight: '17px',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        Date : {date}&nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="12">
                                                        <hr color="#142536" style={{ margin: '0' }} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <div>
                            <tbody className='PrescriptionPrintTable'>
                                <tr>
                                    <td>
                                        <div class="printOutSettingContent">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" style={{ width: '100%' }}>
                                                            Rx
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                <tr >
                                    <td>
                                        <div class="printOutSettingContent">
                                            <table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
                                                <tbody>
                                                    <tr style={{ borderBottom: '2px solid black' }}>
                                                        <td
                                                            class="col-md-3"
                                                            style={{
                                                                borderBottom: '2px solid black',
                                                                textAlign: 'left',
                                                                fontWeight: '500',
                                                                fontSize: '12px',
                                                                paddingBottom: '5px',
                                                                width: '20%',
                                                            }}
                                                        >
                                                            Medicine
                                                        </td>
                                                        <td
                                                            class="col-md-3"
                                                            style={{
                                                                borderBottom: '2px solid black',
                                                                textAlign: 'left',
                                                                fontWeight: '500',
                                                                fontSize: '12px',
                                                                paddingBottom: '5px',
                                                                width: ' 25%',
                                                            }}
                                                        >
                                                            Frequency & Dosage
                                                        </td>
                                                        <td
                                                            class="col-md-3"
                                                            style={{
                                                                borderBottom: '2px solid black',
                                                                textAlign: 'left',
                                                                fontWeight: '500',
                                                                fontSize: '12px',
                                                                paddingBottom: '5px',
                                                                width: ' 15%',
                                                            }}
                                                        >
                                                            Intake
                                                        </td>
                                                        <td
                                                            class="col-md-3"
                                                            style={{
                                                                borderBottom: '2px solid black',
                                                                textAlign: 'left',
                                                                fontWeight: '500',
                                                                fontSize: '12px',
                                                                paddingBottom: '5px',
                                                                width: ' 8%',
                                                            }}
                                                        >
                                                            Qty
                                                        </td>
                                                        <td
                                                            class="col-md-3"
                                                            style={{
                                                                borderBottom: '2px solid black',
                                                                textAlign: 'left',
                                                                fontWeight: '500',
                                                                fontSize: '12px',
                                                                paddingBottom: '5px',
                                                                width: ' 8%',
                                                            }}
                                                        >
                                                            Days
                                                        </td>
                                                        <td
                                                            class="col-md-3"
                                                            style={{
                                                                borderBottom: '2px solid black',
                                                                textAlign: 'left',
                                                                fontWeight: '500',
                                                                fontSize: '12px',
                                                                paddingBottom: '5px',
                                                                width: ' 14%',
                                                            }}
                                                        >
                                                            Start Date
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                <tr >
                                    <td>
                                        <div class="printOutSettingContent">
                                            <table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
                                                <tbody>
                                                    {medicineData.map((medicine) => (
                                                        <tr>
                                                            <td
                                                                class="col-md-3"
                                                                style={{
                                                                    width: '20%',
                                                                    margin: '0',
                                                                    color: '#616161',
                                                                    lineHeight: '15px',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '11px',
                                                                }}
                                                            >
                                                                <p
                                                                    class="sml_matter"
                                                                    style={{ margin: ' 3px 0', fontSize: '11px!important' }}
                                                                >
                                                                    {medicine.medicineName}
                                                                </p>
                                                            </td>
                                                            <td
                                                                class="col-md-3"
                                                                style={{
                                                                    width: ' 25%',
                                                                    margin: '0',
                                                                    color: '#616161',
                                                                    lineHeight: '15px',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '11px',
                                                                }}
                                                            >
                                                                <p
                                                                    class="sml_matter"
                                                                    style={{ margin: ' 3px 0', fontSize: '11px!important' }}
                                                                >

                                                                    <div>{medicine.frequency + " - " + medicine.dosage}</div>

                                                                </p>
                                                            </td>
                                                            <td
                                                                class="col-md-3"
                                                                style={{
                                                                    width: ' 15%',
                                                                    margin: '0',
                                                                    color: '#616161',
                                                                    lineHeight: '15px',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '11px',
                                                                }}
                                                            >
                                                                <p
                                                                    class="sml_matter"
                                                                    style={{ margin: ' 3px 0', fontSize: '11px!important' }}
                                                                >
                                                                    {medicine.intake}
                                                                </p>
                                                            </td>
                                                            <td
                                                                class="col-md-3"
                                                                style={{
                                                                    width: ' 8%',
                                                                    margin: '0',
                                                                    color: '#616161',
                                                                    lineHeight: '15px',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '11px',
                                                                }}
                                                            >
                                                                <p
                                                                    class="sml_matter"
                                                                    style={{ margin: ' 3px 0', fontSize: '11px!important' }}
                                                                >
                                                                    {medicine.quantity}
                                                                </p>
                                                            </td>
                                                            <td
                                                                class="col-md-3"
                                                                style={{
                                                                    width: ' 8%',
                                                                    margin: '0',
                                                                    color: '#616161',
                                                                    lineHeight: '15px',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '11px',
                                                                }}
                                                            >
                                                                <p
                                                                    class="sml_matter"
                                                                    style={{ margin: ' 3px 0', fontSize: '11px!important' }}
                                                                >
                                                                    {medicine.duration}
                                                                </p>
                                                            </td>
                                                            <td
                                                                class="col-md-3"
                                                                style={{
                                                                    width: ' 14%',
                                                                    margin: '0',
                                                                    color: '#616161',
                                                                    lineHeight: '15px',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '11px',
                                                                }}
                                                            >
                                                                <p
                                                                    class="sml_matter"
                                                                    style={{ margin: ' 3px 0', fontSize: '11px!important' }}
                                                                >
                                                                    {medicine.startDate}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td
                                                            colspan="6"
                                                            style={{
                                                                textAlign: 'left',
                                                                fontSize: '11px',
                                                                lineHeight: '15px',
                                                                padding: ' 0px 2px 0px 0',
                                                                display: 'inline-block',
                                                                boxSizing: 'border-box',
                                                                color: '#393939',
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    wordBreak: 'break-all',
                                                                    margin: '3px 0',
                                                                    fontSize: '11px!important',
                                                                    wordBreak: ' break-word',
                                                                }}
                                                            >
                                                                {' '}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </div>
                        <tfoot>
                            <tr>
                                <td>
                                    <div class="printOutSettingContent">
                                        <table width="100%" cellspacing="0">
                                            <FooterDoctorPrescriptionPrint setIsChecked7={setIsChecked7} isChecked7={isChecked7}/>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="printBtm" style={{ textAlign: 'center' }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td colspan="6" style={{ textAlign: 'center' }}>
                                    <p
                                        id="printButton"
                                        style={{
                                            width: '70px',
                                            textAlign: 'center',
                                            paddingTop: '5px',
                                            lineHeight: '18px',
                                            display: 'inline-block',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            color: 'white',
                                            background: 'rebeccapurple none repeat scroll 0% 0%',
                                            marginTop: '2px',
                                            cursor: 'pointer',
                                            borderRadius: '5px',
                                            marginBottom: '2px',
                                            height: '30px',
                                            position: "absolute",
                                            right: "0px", fontFamily: "Verdana",
                                        }}
                                        onClick={printPrescription}
                                    >
                                        Print
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
export default PriscriptionPrint;
