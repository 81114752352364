import React, { useEffect, useState } from 'react';
import Logo from '../images/media/logo.png';
import deafaultLogo from '../images/avatarHospitalDefault.png';
import Loader from '../Common/loader';
import { callingAPI, callingAPI_GET } from '../config';
// import { useState } from 'react/cjs/react.development';
import { useParams } from 'react-router-dom';
function ConsentFormInvoice() {
	const [consentHospital, setConsentHospital] = useState([]);
	const [consentPatient, setconsentPatient] = useState([]);
	const [consentDoctor, setconsentDoctor] = useState([]);
	const [hospitalConsentFormDescription, setproceduredescription] = useState('');
	const [consentHospitalEmail, setConsentHospitalEmail] = useState("")
	const [procedurelocaldescription, setprocedurelocaldescription] = useState('');
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [isData, setIsData] = useState(false);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH +
		'/'
	);
	let { hospitalId, memberId, doctorId } = useParams();
	const printReceipt = () => {
		document.getElementById('reset').style.display = 'none';
		document.getElementById('print').style.display = 'none';
		window.print();
		document.getElementById('reset').style.display = 'inline-block';
		document.getElementById('print').style.display = 'inline-block';
	};
	const consentForm = () => {
		let consent = {
			patientId: memberId,
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: doctorId,
		};
		callingAPI('commonUserFunctions/consentForm', consent)
			.then((response) => {
				if (response.data.success === '1') {
					setConsentHospital(response.data.result.hospitalDetails);
					setConsentHospitalEmail(response.data.result.hospitalDetails[0].EmailId);
					setHospitalLogo(response.data.result.hospitalDetails[0].hospitalImage);
					setconsentPatient(response.data.result.patientDetails);
					setconsentDoctor(response.data.result.doctorDetails);
				}
			})
			.catch();
	};
	const consentFormDescription = () => {
		let PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			hospitalConsentType: 'PROCEDURE',
		};
		callingAPI('consentForm/consentFormDetails', PostData).then((response) => {
			
			if (response.data.success === '1') {
				setproceduredescription(response.data.result.data[0].hospitalConsentFormDescription);
				setprocedurelocaldescription(response.data.result.data[0].descriptionLocalLanguage);
				setIsData(true);
			} else {
				setproceduredescription("I, have received information about the proposed treatment. I have discussed my " +
					" treatment with Dr. _______________ and have been given an opportunity to ask questions and have them fully answered. I understand the nature of the recommended treatment, alternate treatment options, the risks of the recommended treatment, and the risks of refusing treatment. I wish to proceed with the recommended treatment. ");
				setprocedurelocaldescription('');
				setIsData(true);
			}
		});
	}
	useEffect(() => {
		consentForm();
		consentFormDescription();
	}, []);
	var getS3folder =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
	return (
		<div class="instructionFormWrap" id="cardPrint" style={{
			width: '980px', margin: 'auto', padding: '5px 0px',
			boxSizing: 'border-box', fontFamily: 'sans-serif', fontWeight: '300', color: ' #000', fontSize: '17px',
		}}		>


			{isData === false ? <Loader /> : ''}
			<div style={{ textAlign: 'center', fontSize: '22px', margin: '20px 0 35px' }}>CONSENT FORM</div>
			<div class="formTop" style={{ clear: 'both', overflow: 'hidden', borderBottom: '1px solid #252525' }}>
				<div class="slNo" style={{ width: '15%', float: 'left', color: '#ff0000' }}>
					<span style={{ float: 'left', paddingTop: '5px' }} />
					<span style={{ fontWeight: '800', fontSize: '15px', float: 'left', paddingLeft: '5px' }} />
				</div>
				{consentHospital.map((data) => (
					<div class="formName" style={{ width: ' 70%', float: 'left', textAlign: 'center' }}>
						<div class="title" style={{ fontSize: '25px', fontWeight: '600', color: '#0091d0' }}>
							<span style={{ color: '#ff0000', verticalAlign: 'top' }} /> {data.hospitalName}
						</div>
						{data.address && (
							<div class="address" style={{ margin: '3px 0', fontSize: '15px' }}>
								{data.address}
							</div>
						)}
					</div>
				))}
				<div
					class="logo"
					style={{
						width: '15%',
						float: 'left',
						textAlign: 'right',
						position: 'relative',
						top: 'auto',
						left: 'auto',
					}}
				>
					<img src={HospitalLogo !== "null" ? getS3folder + '/' + localStorage.getItem('hospitalId') + ".png" : deafaultLogo} style={{ width: '100%' }} />
				</div>
				{consentDoctor.map((data) => (
					<div
						class="mailPortion"
						style={{
							clear: 'both',
							overflow: 'hidden',
							fontSize: '0px',
							textAlign: 'center',
							paddingTop: '2px',
						}}
					>
						<div
							class="mailId"
							style={{
								display: 'inline-block',
								marginRight: '5px',
								padding: '2px 7px',
								background: '#e31e24',
								color: '#fff',
								transform: 'skew(-15deg)',
								verticalAlign: 'top',
							}}
						>
							<span style={{ transform: 'skew(15deg)', fontSize: '15px', float: 'left' }}>
								Doctor Name :
							</span>
							<span
								style={{
									transform: 'skew(15deg)',
									display: 'inline-block',
									fontSize: '15px',
									float: 'left',
									paddingLeft: '3px',
								}}
							>
								{data.doctorName}
							</span>
						</div>
						{consentHospitalEmail && (
							<div
								class="mailId"
								style={{
									display: 'inline-block',
									marginRight: '5px',
									padding: '2px 7px',
									background: '#e31e24',
									color: '#fff',
									transform: 'skew(-15deg)',
									verticalAlign: 'top',
								}}
							>
								<span
									style={{
										transform: 'skew(15deg)',
										display: 'inline-block',
										fontSize: '15px',
										float: 'left',
									}}
								>
									Email Id :
								</span>
								<span
									style={{
										transform: 'skew(15deg)',
										display: 'inline - block',
										fontSize: '15px',
										float: 'left',
										paddingLeft: '3px',
									}}
								>
									{consentHospitalEmail}
								</span>
							</div>
						)}
						{consentHospital[0].phoneNumber && (
							<div
								class="whatsAppNo"
								style={{
									display: 'inline-block',
									padding: '2px 7px',
									background: '#e31e24',
									color: '#fff',
									transform: 'skew(-15deg)',
									verticalAlign: 'top',
								}}
							>
								<span
									style={{
										transform: 'skew(15deg)',
										display: 'inline-block',
										fontSize: '15px',
										float: 'left',
									}}
								>
									Phone <span style={{ fontSize: '9px', verticalAlign: 'bottom' }} /> :
								</span>
								<span
									style={{
										transform: 'skew(15deg)',
										display: 'inline-block',
										fontSize: '15px',
										float: 'left',
										paddingLeft: '3px',
									}}
								>
									{consentHospital[0].phoneNumber}
								</span>
							</div>
						)}
					</div>
				))}
			</div>
			{consentPatient.map((data) => (
				<div>
					<div
						style={{
							clear: 'both',
							overflow: 'hidden',
							lineHeight: '25px',
							paddingTop: '30px',
							marginBottom: '10px',
						}}
					>
						<span style={{ float: 'left', paddingRight: '3px' }}>Patient’s Name :</span>
						<div style={{ wordBreak: 'break-word' }}>{data.patientName}</div>
					</div>
					{data.dateOfBirth && (
						<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '25px', marginBottom: '10px' }}>
							<span style={{ float: 'left', paddingRight: '3px' }}>Date of Birth :</span>
							<div style={{ wordBreak: 'break-word' }}>{data.dateOfBirth}</div>
						</div>
					)}
					{data.gender && (
						<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '25px', marginBottom: '10px' }}>
							<span style={{ float: 'left', paddingRight: '3px' }}>Sex :</span>
							<div style={{ wordBreak: 'break-word' }}>{data.gender}</div>
						</div>
					)}
					{/* {consentPatient[0].address && ( */}
					<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '25px', marginBottom: '10px' }}>
						<span style={{ float: 'left', paddingRight: '3px' }}>Address :</span>
						<div style={{ wordBreak: 'break-word' }}>{consentPatient[0].address}</div>
					</div>
					{/* )} */}
					{data.phoneNumber && (
						<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '25px', marginBottom: '10px' }}>
							<span style={{ float: 'left', paddingRight: '3px' }}>Phone no :</span>
							<div style={{ wordBreak: 'break-word' }}>{data.phoneNumber}</div>
						</div>
					)}
				</div>
			))}
			<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '21px', marginBottom: '15px' }}>
				<div style={{ wordBreak: 'break-word', boxSizing: 'border-box', width: '100%', float: 'left' }}>
					<textarea
						class="textAreaBox"
						type="text"
						name="notes"
						value={hospitalConsentFormDescription}
						style={{
							width: '100%',
							boxSizing: 'border-box',
							minHeight: '250px',
							resize: 'none',
							border: '1px solid #d3d3d3',
							padding: '10px',
							lineHeight: '26px',
							fontFamily: 'monospace',
							fontSize: '18px',
							textIndent: '225px',
						}}
						onChange={(e) => setproceduredescription(e.target.value)}
					>
						{/* I, have received information about the proposed treatment. I have discussed my treatment with
						Dr. ________________ and have been given an opportunity to ask questions and have them fully
						answered. I understand the nature of the recommended treatment, alternate treatment options, the
						risks of the recommended treatment, and the risks of refusing treatment. I wish to proceed with
						the recommended treatment - */}

					</textarea>
					{procedurelocaldescription !== null || procedurelocaldescription !== "" ?
						<textarea
							class="textAreaBox"
							type="text"
							name="notes"
							value={procedurelocaldescription}
							style={{
								width: '100%',
								boxSizing: 'border-box',
								minHeight: '250px',
								resize: 'none',
								border: '1px solid #d3d3d3',
								padding: '10px',
								lineHeight: '26px',
								fontFamily: 'monospace',
								fontSize: '18px',
								textIndent: '180px',
							}}
							onChange={(e) => setprocedurelocaldescription(e.target.value)}
						>

						</textarea>
						: ""}
				</div>
			</div>
			<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '25px', marginBottom: '10px' }}>
				<div style={{ float: 'left', width: '70%' }}>
					<span style={{ float: 'left', paddingRight: '3px' }}>Signature of the Patient</span>
					<div style={{ wordBreak: 'break-word' }}>
						..................................................................................
					</div>
				</div>
			</div>
			<div style={{ clear: 'both', overflow: 'hidden', lineHeight: '25px', marginBottom: '10px' }}>
				<div style={{ float: 'left', width: '70%' }}>
					<span style={{ float: 'left', paddingRight: '3px' }}>Signature of the Parent or Guardian</span>
					<div style={{ wordBreak: ' break-word' }}>
						.........................................................................
					</div>
				</div>
				<div style={{ float: 'left', width: '30%' }}>
					<span style={{ float: 'left', paddingRight: '3px' }}>Date & Time :</span>
					<div style={{ wordBreak: ' break-word' }}>................................</div>
				</div>
			</div>
			<div class="teethPrintBtn" style={{ paddingTop: '20px', textAlign: 'right' }}>
				<a
					href=""
					value="1"
					id="reset"
					className="hide"
					style={{
						textDecoration: 'none',
						color: '#000',
						display: 'inline-block',
						padding: '7px 20px',
						fontSize: '14px',
						border: '1px solid #000000',
						fontWeight: '600',
						cursor: 'pointer',
					}}
				>
					Reset
				</a>&nbsp;&nbsp;
				<span
					id="print"
					className="hide"
					value="1"
					onClick={printReceipt}
					style={{
						display: 'inline-block',
						padding: ' 7px 20px',
						fontSize: '14px',
						border: '1px solid #000000',
						fontWeight: '600',
						cursor: 'pointer',
					}}
				>
					Print
				</span>
			</div>
		</div>
	);
}
export default ConsentFormInvoice;
