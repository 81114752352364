import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import $ from "jquery";
function ShareData() {
	const [hsopitalList, setHsopitalList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [hasConsent, setHasConsent] = useState(false);
	const [result, setResult] = useState([]);
	const [isActive, setisActive] = useState(false)
	const [caseSheet, setCasesheet] = useState(false);

	const [selectOptValue, setSelectOptValue] = useState();
	useEffect(() => {
		groupList();
	}, []);
	const CaseSheet = (event) => {
		setCasesheet(event.target.checked)
	}
	const HasConsent = (event) => {
		setHasConsent(event)
	}
	const shareDataHospitalList = (groupId) => {
		const apiJson = {
			"hospitalGroupId": groupId,
			"memberId": window.sessionStorage.getItem('memberId'),
			"sourceHospitalId": localStorage.getItem('hospitalId'),
		}
		callingAPI('clinicalBackground/shareDataShowHospitalsList', apiJson).then((response) => {
			if (response.data.success === "1") {
				setHsopitalList(response.data.result.hsopitalList)
				setCategoryList(response.data.result.categoryList)
			}
		})
	}
	const groupList = () => {
		const apiJson = {
			"hospitalId": localStorage.getItem('hospitalId')
		}
		callingAPI('clinicalBackground/sharedDataList', apiJson).then((response) => {
			if (response.data.success === "1") {
				setResult(response.data.result)
			}
		})
	}
	const shareDataSave = () => {
		var ValidationFlag = true
		if (!(isActive)) {
			ValidationFlag = false
			alert("Please select hospital")
			return false
		}

		// else if (!categoryList) {
		// 	alert("Please select Category")
		// 	ValidationFlag = false;
		// }
		else
			if (!hasConsent) {
				alert("Confirm to sign consent form")
				ValidationFlag = false;
				window.document.getElementById('hasConsent').focus();

			}

		var hsopitalListArray = [];
		for (let i = 0; i < hsopitalList.length; i++) {
			var Obj = {
				"name": hsopitalList[i].name,
				"id": hsopitalList[i].id,
				"isActive": hsopitalList[i].isActive === true ? true : false,
				"hospitalId": hsopitalList[i].hospitalId,
				"hasConsent": hasConsent,
				"hospitalGroupId": hsopitalList[i].hospitalGroupId
			}
			hsopitalListArray.push(Obj)
		}
		var categoryListArray = [];
		for (let y = 0; y < categoryList.length; y++) {
			var Obj1 = {
				"name": categoryList[y].name,
				"id": categoryList[y].id,
				"isActive": categoryList[y].isActive === true ? true : false,
				"hospitalGroupId": categoryList[y].hospitalGroupId
			}
			categoryListArray.push(Obj1)
		}
		if (ValidationFlag === true) {
			let apiJson1 = {
				"hospitalGroupId": "1",
				"memberId": window.sessionStorage.getItem('memberId'),
				"SourceHospitalId": localStorage.getItem('hospitalId'),
				"hsopitalList": hsopitalListArray,
				"categoryList": categoryListArray
			}
			callingAPI('clinicalBackground/saveShareData', apiJson1).then((data) => {
				if (data.data.success === '1') {
					alert("Shared data Successfully");
				}
			})
		}
	}
	const selectedGroup = (e) => {
		shareDataHospitalList(e);
		$(".shareHospitalButtons").css("display", "block")
		$(".shareHistoryType").css("display", "block")

		setSelectOptValue(e);
		if (e == 0) {
			$(".shareHospitalButtons").css("display", "none")
			$(".shareHistoryType").css("display", "none")


		}

	}
	const onChangeHospitalList = (event, i, active) => {
		setisActive(event.target.checked)
		// if (event.target.checked == true) {
		// 	$(".checkSection1").css("display", "block");
		// }
		// else {
		// 	$(".checkSection1").css("display", "none");
		// }
		const { name, value } = event.target;
		const values = [...hsopitalList];
		values[i] = { ...values[i], isActive: event.target.checked };
		setHsopitalList(values);
	}
	const onChangeCategoryList = (event, i) => {
		const { name, value } = event.target;
		const values = [...categoryList];
		values[i] = { ...values[i], isActive: event.target.checked };
		setCategoryList(values);
	}
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">SHARE DATA</span>
											</div>
											<div class="treatmentPlanList" style={{ height: "auto" }}>
												<div className="sharedata">
													<div className="treatmentProfileCover">
														<div className="treatmentPlanContent">
															<div className="profileCover">
																<div class="shareHospitalGroup">
																	Share with Hospital Group
																	<select className="inputField" id="selectedGroup" onChange={(event) => selectedGroup(event.target.value)} value={selectOptValue}>
																		<option value="0"  > Select Group </option>
																		{result.map((item, idx) => (
																			<option value={item.id}>{item.groupName}</option>
																		))}
																	</select>
																</div>
															</div>
															<div className='pul_shareWraper' >
																<div class="hospitalGroupName">
																	<ul className='pul_shareData treatmentTimelineCheck'>
																		{hsopitalList.map((item, i) => {
																			return (
																				<li>
																					<span className="pul_hospData">
																						<input
																							type="checkbox"
																							name={item.isActive}
																							id={item.hospitalId}
																							defaultChecked={item.isActive}
																							onChange={(e) => onChangeHospitalList(e, i, item.isActive)}
																						/>
																						<label style={{ paddingLeft: '8px' }} for={item.hospitalId}>{item.name}</label>
																					</span>
																				</li>
																			);
																		})}
																	</ul>
																	{/*  */}
																</div>
																<div className="treatmentTimelineCheck shareHistoryType">
																	<ul>
																		{categoryList.map((data, i) => {
																			return (
																				<li>
																					<span className="checkSection1">
																						<input type="checkbox"
																							id={data.id}
																							name={data.isActive}
																							checked={data.isActive}
																							onChange={(e) => onChangeCategoryList(e, i)}
																						/>
																						<label >{data.name}</label>
																					</span>
																				</li>
																			)
																		})}
																	</ul>
																	<div class="shareHospitalButtons" style={{ display: "none" }} >
																		{/*  */}
																		<ul>
																			<li>
																				<span className="checkSection">
																					<input type="checkbox" id="hasConsent"
																						onChange={(event) => HasConsent(event.target.checked)} checked={hasConsent} /><label for="hasConsent"></label>
																				</span>
																				<span className="timelineTxt"
																				>Consent form accepted by patient</span>
																			</li>
																		</ul>
																		<div className='pul_shareAction'>
																			<a href="/patients/shareDataConsentForm" style={{ textDecoration: "none" }} className="printButton">Consent Form</a>
																			{/* display: 'none', */}
																			<span class="dataButton" onClick={(e) => shareDataSave(e)} >Share Data</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/*  */}
										</div>
									</div>
								</div>
							</div>
							{/*  */}
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default ShareData;