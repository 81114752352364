import React, { useEffect } from 'react';
import Editbg from '../images/icons/lab/labEdit.png';
import { callingAPI, callingAPI_GET } from '../config';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { useNavigate } from 'react-router-dom';
function PatientAppointmentslist() {
	const navigate = useNavigate();
	const [hospitalListing, setHospitalListing] = useState([]);
	const [flag, setFlag] = useState(false);
	const appointmentList = () => {
		setFlag(true)
		const listParams = {
			"memberId": window.sessionStorage.getItem('memberId'),
			"doctorId": "",
			"hospitalId": localStorage.getItem('hospitalId'),
			"appointmentId": ""
		}
		callingAPI('patientProfile/getPatientAppointment', listParams)
			.then((response) => {
				if (response.data.success === '1') {
					setHospitalListing(response.data.patientAppointment);
				}
				setFlag(false)
			})
			.catch();
	};
	useEffect(() => {
		appointmentList();
	}, []);
	const patientsAppointmentEdits = (data) => {
		navigate('/patients/patientAppointmentEdit', { state:  data  })
	}
	return (
		<div>
			<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
				<div id="divPatientContent" class="sideMenuContent">
					<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
						<div class="treatmentPlanHead">
							<span class="treatmentPlanName">APPOINTMENTS</span>

							<a href="/patients/patientAppointmentEdit">
								<span title='Add Appointment' class="treatmentPlanBtn pl_blue_btn">+ Add</span>
							</a>
						</div>
						{flag === true ? (
							<Loader />
						) : hospitalListing.length > 0 ? (
							<div class="treatmentPlanList" style={{ height: 'auto' }}>
								<div>
									{hospitalListing.map((data) => (

										<div class="appoinmentWrap" id="rows0">
											{data.appintmentDateAndTime && (

												<div class="appoinmentDateDiv">

													<span class="apnmentDate" id="appDay139704">
														{data.appintmentDateAndTime.split('-')[0]}
													</span>
													<span class="apnmentDay" id="monthyeartime139704">
														{data.appintmentDateAndTime.split('-')[1]}{' '}
														{data.appintmentDateAndTime.split('-')[2]}
														{data.appintmentDateAndTime.split('-')[3]}
													</span>
												</div>)}
											<div class="appoinmentDateDetail">
												<div class="appoinmentLeftDiv">
													<span class="appoinmentReason">
														<span>Reason :</span>
														<span class="elipsis">{data.visitReason}</span>
													</span>
												</div>
												<div class="appoinmentSatusWrap">
													<span class="appoinmentTYpe">Type : {data.appointmentType}</span>
													<span class="appoinmentSTatus">
														Status : {data.appStatus}
													</span>
												</div>
												{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ?
													<div class="appoinmentRightDiv">
														<span class="appoinmentHsptlName" >{data.hospitalName}</span>
													</div>
													:
													<div class="appoinmentRightDiv">
														<span class="appoinmentHsptlName" >{data.doctorName}</span>
													</div>
												}

											</div>
											<div class="appointmentEdit" style={{ padding: '0px' }}>
												<span
													style={{
														position: 'absolute',
														right: '0px',
														top: '63px',
														background: 'rgb(224 224 224 / 66%)',
														padding: '0 3px',
														border: 'border: 1px solid #ddd;',
													}}
													class="dentalProIcons"
													onClick={()=>patientsAppointmentEdits(data)}
												>
													{/* <Link
														to={{
															pathname:
																'/patients/patientAppointmentEdit',
															state: {
																data
															},
														}}
													> */}
														<img title='Edit Appointment' src={Editbg} style={{ width: '20px' }} />{' '}
														Edit
													{/* </Link> */}
												</span>
											</div>
										</div>
									))}
								</div>
							</div>
						) : (
							<p>
								<NoData />
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
export default PatientAppointmentslist;
