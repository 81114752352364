import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
function MedicineByDoctor() {
  const [fromDate, setfromtDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [patientList, setpatientListData] = useState([]);
  const downloadName = "MedicineByDoctor";
  const [count, setCount] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [filterName, setFilterInput] = useState("");
  const [dataFlag, setDataFlag] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  const [medicineResult, setMedicineResult123] = useState([])
  const searchValueFunction = (event) => {
    setsearchValue(event.target.value);
    // getPrecriptionMedicineAutoComplete()
  };
  //function
  const getpatientsList = () => {
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
      textInput: searchValue,
    };
    callingAPI("clinicalBackground/medicineByDoctor", PostData).then((response) => {
      if (response.data.success === "1") {
        setpatientListData(response.data.result.MedicineDetails);
        setCount(response.data.result.count);
        setDataFlag(true);
      } else { setDataFlag(true); }
    });
  };
  useEffect(() => {
    getpatientsList();
  }, []);
  //searchfunction
  const searchFunction = () => {
    var searchFlag = true
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("To date should be greater than or equal to from date");
        document.getElementById("todate").focus()
        // return (false);
        searchFlag = false;
      }
    }
    if (searchValue === "" && fromDate === "" && toDate === "") {
      alert("Enter Medicine Name");
      window.location.reload()
      searchFlag = false;
    }
    else if (searchFlag === true) {
      setbooliansearchValue(searchValue);
      setboolianfilterName(filterName);
      setboolianfromDate(fromDate);
      setbooliantoDate(toDate);
      let PostData = {
        userId: window.sessionStorage.getItem("userId"),
        role: window.sessionStorage.getItem("role"),
        max: 10,
        offset: 0,
        // cmbSelect: filterName,
        textInput: searchValue,
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
        hospitalId: localStorage.getItem("hospitalId"),
      };
      // setDataFlag(false);
      callingAPI("clinicalBackground/medicineByDoctor", PostData).then((response) => {
        if (response.data.success === "1") {
          setpatientListData(response.data.result.MedicineDetails);
          setCount(response.data.result.count);
          setDataFlag(true);
          setActivePage(1);
        } else if (response.data.success === "0") {
          setpatientListData([]);
          setActivePage(0);
        }
      });
    };
  }
  //Date functions
  function formatDate(date) {
    var dates = new Date(date),
      month = "" + (dates.getMonth() + 1),
      day = "" + dates.getDate(),
      year = dates.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  //list function
  function patientListDownload() {
    if (patientList.length === 0) {
      alert('No Data to Export');
    }
    else {
      const csvRow = [];
      var MedicineDetails = [
        [
          "Medicine%20Name",
          "%20Date",
          "	Quantity%20",
          "Amount%20",
        ],
      ];
      var name = MedicineDetails;
      let PostData = {
        userId: window.sessionStorage.getItem("userId"),
        role: window.sessionStorage.getItem("role"),
        max: '',
        offset: 0,
        // cmbSelect: filterName,
        textInput: searchValue,
        hospitalId: localStorage.getItem("hospitalId"),
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
      };
      callingAPI("clinicalBackground/medicineByDoctor", PostData).then((response) => {
        // if (response.data.success === '0') {
        //   alert("No Data to Export")
        //   }
        if (response.data.success === '1') {
          for (var item = 0; item < response.data.result.MedicineDetails.length; item++) {
            MedicineDetails.push([
              response.data.result.MedicineDetails[item].MedName.replace(/ /g, '%20'),
              response.data.result.MedicineDetails[item].VisitDate.replace(/[^a-zA-Z0-9]/g, "-"),
              response.data.result.MedicineDetails[item].Totqty,
              response.data.result.MedicineDetails[item].TotAmt !== "" ? response.data.result.MedicineDetails[item].TotAmt : 0,

            ]);
          }
        }
        for (var i = 0; i < MedicineDetails.length; ++i) {
          csvRow.push(MedicineDetails[i].join(","));
        }
        var csvString = csvRow.join("%0A");
        var creat = document.createElement("a");
        creat.href = "data:attachment/csv," + csvString;
        creat.target = "_Blank";
        creat.download = downloadName + ".csv";
        document.body.appendChild(creat);
        creat.click();
      })
    }
  }

  //pagegination function
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) { setFilterInput("") }
    if (!booliansearchValue) { setsearchValue("") }
    if (!boolianfromDate) { setfromtDate("") }
    if (!booliantoDate) { settoDate("") }
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: max,
      offset: offset,
      // cmbSelect: boolianfilterName,
      textInput: booliansearchValue,
      fromDate: boolianfromDate ? formatDate(fromDate) : "",
      toDate: booliantoDate ? formatDate(toDate) : "",
      hospitalId: localStorage.getItem("hospitalId"),
    };

    callingAPI("clinicalBackground/medicineByDoctor", PostData).then((response) => {
      if (response.data.success === "1") {
        setpatientListData(response.data.result.MedicineDetails);
        setCount(response.data.result.count);
      }
    });
  };
  //filter function

  //change text

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      // alert("ssssssssss")
      searchFunction();
    }
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const FromDateClear = () => {
    setfromtDate("")
    //document.getElementById("dashboardCover").click()
    // document.getElementById("fromdate").style.display ='none'
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>

        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  const ToDateClear = () => {
    settoDate("")
    // document.getElementById("fromdate").style.display ='none'
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  const getPrecriptionMedicineAutoComplete = () => {

    const postData = {
      userId: window.sessionStorage.getItem("userId"),
      textInput: document.getElementById("searchText").value
    };
    if (document.getElementById('searchText').value.length > 0) {
      callingAPI('clinicalBackground/autoMedicineByDoctor', postData)
        .then((data) => {
          if (data.data.success === '1') {
            document.getElementById('prescriptionMedicineList').style.display = 'block';
            setMedicineResult123(data.data.result.MedicineDetails);
          } else {
            document.getElementById('prescriptionMedicineList').style.display = 'none';
            setMedicineResult123([]);
          }
        });
    }
    else {
      document.getElementById('prescriptionMedicineList').style.display = 'none';
      setMedicineResult123([]);
    }
  }
  const medicineNameClick = (name, id) => {
    setsearchValue(name)
    // setIdSearch(id)
    setMedicineResult123([]);
    document.getElementById('prescriptionMedicineList').style.display = 'none';
  }
  // const onBlurItemCategory = () => {
  //   document.getElementById('prescriptionMedicineList').style.display = 'none';
  //   setMedicineResult123([]);
  // };
  document.addEventListener('mouseup', function (e) {
    var container = document.getElementById("prescriptionMedicineList");
    if (container !== null) {
      if (!container.contains(e.target)) {
        container.style.display = 'none';
      }
    }
  });
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <div className="dashboardTitle">Medicine By Doctor</div>
      {dataFlag == false ? (
        <div align="center">
          <Loader />
        </div>
      ) : ("")}
      <div>

        <div className="dashboardContent">

          <div className="paginationSection">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={count}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
              onChange={handlePageChange}
              //hideNavigation
              hideDisabled
            />
          </div>

          <div className="reportWrap">
            <div className="dashboardSearch">
              <div className="row">
                <div className="col-md-2 dashboardSearchList">
                  <input
                    type="text"
                    className="inputField"
                    id="searchText"
                    autoComplete="off"
                    value={searchValue}
                    onChange={searchValueFunction}
                    onKeyUp={() =>
                      getPrecriptionMedicineAutoComplete(
                        '',
                        'medicineId',
                        'itemListDiv'
                      )}
                    name="searchText" placeholder='Medicine Name'
                  />
                  <ul
                    className="autoSearch autoSearch-p0 appointmentNumberSearch"
                    id="prescriptionMedicineList"
                    style={{ display: 'none' }}
                  >
                    {medicineResult.map((item, i) => (
                      <li
                        onClick={() => medicineNameClick(item.MedName, item.id)}
                      >
                        {item.MedName}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-2 dashboardSearchList">

                  <div>
                    <DatePicker
                      className="inputField"
                      autoComplete='off'
                      selected={fromDate}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="From"
                      maxDate={new Date()}
                      onChange={(date) => setfromtDate(date)}
                      onKeyDown={onKeyDown}
                      id="fromdate"
                      showMonthDropdown
                      showYearDropdown
                      readonly="readonly"
                      dropdownMode="select"
                      calendarContainer={MyContainer}
                      onChangeRaw={handleDateChangeRaw}
                    />
                    <label
                      className="icon iconCalendar calendar plReportsCalendar"
                      style={{ fontSize: "16px" }}
                      htmlFor="fromdate"
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </label>
                  </div>

                </div>
                <div className="col-md-2 dashboardSearchList">
                  {
                    <div>
                      <DatePicker
                        className="inputField"
                        selected={toDate}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="To"
                        maxDate={new Date()}
                        onChange={(date) => settoDate(date)}
                        onKeyDown={onKeyDown}
                        id="todate"
                        showMonthDropdown
                        autoComplete='off'
                        showYearDropdown
                        dropdownMode="select"
                        calendarContainer={MyContainerTo}
                        onChangeRaw={handleDateChangeRaw}
                      />
                      <label
                        className="icon iconCalendar calendar plReportsCalendar"
                        style={{ fontSize: "16px" }}
                        htmlFor="todate"
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </label>
                    </div>
                  }
                </div>
                <div className="col-md-2 dashboardSearchList">
                  <span
                    className="dashButtonBorder"
                    onClick={searchFunction}
                    onKeyDown={onKeyDown}
                  >
                    Search
                  </span>
                </div>
                {/* {!patientList.length ? (
                  <div className="col-md-2 dashboardSearchList">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      id="export"
                      className="dashButtonBorder dashButtonBorderGrey 1"
                      onClick={noSearchData}
                    >
                      Export
                    </button>
                  </div>
                ) : (
                  <div className="col-md-2 dashboardSearchList">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      onClick={patientListDownload}
                      id="export"
                      className="dashButtonBorder dashButtonBorderGrey 2"
                    >
                      Export
                    </button>
                  </div>
                 )}  */}
                <div className="col-md-2 dashboardSearchList">
                  <button
                    type="button"
                    style={{ width: "100%" }}
                    onClick={patientListDownload}
                    id="export"
                    className="dashButtonBorder dashButtonBorderGrey 2"
                  >
                    Export
                  </button>
                </div>

              </div>
              <div className="searchCount">Total Medicine Count : {count}</div>
            </div>
            <div className="dashboardtable">
              {patientList.length > 0 ?
                <table>

                  <thead>
                    <tr>
                      <th>Medicine Name</th>
                      <th > Date</th>
                      <th>Quantity</th>
                      <th style={{ textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientList.map((items, i) => (
                      <tr>
                        <td>{items.MedName}</td>
                        <td>{items.VisitDate}</td>
                        <td>{items.Totqty}</td>
                        <td style={{ textAlign: 'right' }}>&#8377;{items.TotAmt !== "" ? items.TotAmt : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                : <NoData />}
            </div>
          </div>

          <div className="paginationSection">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={count}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
              onChange={handlePageChange}
              //hideNavigation
              hideDisabled
            />
          </div>

        </div>

        {/* {patientList.length == 0 && dataFlag == true && <NoData />} */}
      </div>

    </div>
  );
}
export default MedicineByDoctor;
