import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import calender from '../images/icons/calender.png';
import appointment from '../images/icons/appointment.png';
import patient from '../images/icons/patient.png';
import addpatient from '../images/icons/addpatient.png';
function DoctorHeaderBottomLinks() {
	const showvalue = ()=>{
		window.sessionStorage.setItem('AppoinmentpageNumber',1)
		window.sessionStorage.setItem("activepagenewappoinment", 0)
		window.sessionStorage.setItem('setToDayFlag',"false")
	}
	return (
		<div>
			<span
				id="calenderHeader"
				activeClassName="active"
				className={'headerBottomIcon headerBottomIconNew ' + "active"}
			>
				<NavLink
					to="/clinics/calendar"
					activeClassName="active"
					className={
						'headerBottomIcon headerBottomIconNew '
					}
				>
					<img src={calender} alt="" />Calendar
				</NavLink>
			</span>

			<span
				id="appointmentsHeader"
				className={
					'headerBottomIcon headerBottomIconNew ' + "active"
				}
			>
				<NavLink
					to="/patients/appointment"
					activeClassName="active"
					className={
						'headerBottomIcon headerBottomIconNew '
					}
					onClick={showvalue}
				>
					<img src={appointment} alt="" />Appointments
				</NavLink>
			</span>

			<span
				id="patientsHeader"
				className={'headerBottomIcon headerBottomIconNew ' + "active"}
			>
				<NavLink
					to="/patients/patientListing"
					activeClassName="active"
					className={
						'headerBottomIcon headerBottomIconNew '
					}

				>
					<img src={patient} alt="" />Patients
				</NavLink>
			</span>
			<span
				id="addpatientsHeader"
				className={'headerBottomIcon headerBottomIconNew ' + "active"}
			>
				<NavLink
					to="/patients/addPatient"
					activeClassName="active"
					className={
						'headerBottomIcon headerBottomIconNew '
					}
				>
					<img src={addpatient} alt="" />Add Patient
				</NavLink>
			</span>
		</div>
	);
}

export default DoctorHeaderBottomLinks;
