import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
function MultiGraph(props) {
  useEffect(() => {

  }, [])


  const seriesMulti = props.xArray


  const optionsMulti = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Systolic And Diastolic',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: props.yArray,
      title: {
        // text: 'props.name'
      }
    },
    yaxis: {
      title: {
        // text: 'props.name'
      },
      min: props.lowBp,
      max: props.highBp
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }

  return (
    <div className="treatmentProfileCover">
      <div className="treatmentPlanContent">

        <Chart
          options={optionsMulti}
          series={seriesMulti}
          type="line"
          width="600"
        />

      </div>
    </div>
  );
}
export default MultiGraph;