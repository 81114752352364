import React from 'react';
import Logo from '../images/media/logo.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderDoctorPrescriptionPrint from '../Settings/headerDoctorPrescriptionPrint';
import FooterDoctorPrescriptionPrint from '../Settings/footerDoctorPrescriptionPrint'
import FooterPrint from '../Settings/footerPrint';
import Loader from '../Common/loader';
import { callingAPI } from '../config'
function PriscriptionPrint() {
	// let { receiptId ,doctorId} = useParams();
	let { printId, hospId, doctorId, tp, tw } = useParams();
	// let { hospId } = useParams();
	// let { doctorId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [rx, setRx] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');
	const [custum_width, setcustum_width] = useState('');
	const [custum_height, setcustum_height] = useState();
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [doctorDetails, setDoctorDetails] = useState({});
	const [medicineData, setMedicineData] = useState([]);
	const [imageData, setImageData] = useState([]);
	const [priscriptionDate, setPriscriptionDate] = useState("");
	const [priscriptionInstruction, setPriscriptionInstruction] = useState("");
	const [flag, setFlag] = useState(0);
	const [comment, setComments] = useState([]);
	const [headingType, setHeadingType] = useState('PRESCRIPTIONS');
	// const [isChecked1, setIsChecked1] = useState(false);
	const [isChecked2, setIsChecked2] = useState(false);
	// const [isChecked3, setIsChecked3] = useState(false);
	const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	// const [isChecked6, setIsChecked6] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);
	// const [isChecked8, setIsChecked8] = useState(false);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		'/' +
		process.env.REACT_APP_S3_LOGO_PATH +
		'/'
	);
	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: hospId,
			doctorId: doctorId
		};
		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData).then((res) => {
			setRx(res.data.result.headerFooterDetails[0].showRx)
			setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
			setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
			setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
			setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
			setcustum_width(res.data.result.headerFooterDetails[0].custum_width);
			setcustum_height(res.data.result.headerFooterDetails[0].custum_height);
			setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

			//setMarginTop()
		});

	}
	useEffect(() => {
		styleValues()
		// if (window.sessionStorage.getItem('printType') == "print") {
		// }
		// else {

		// }
		const postData = {
			// prescriptionDate: window.sessionStorage.getItem('visitDate'),
			//  medicineId: "",
			doctorVisitMedicineId: window.sessionStorage.getItem('drVisitIdOfMedicine') == null ? "" : window.sessionStorage.getItem('drVisitIdOfMedicine'),
			doctorVisitId: printId,
		};
		callingAPI('commonUserFunctions/printPrescription', postData).then((res) => {
			if (res.data.success === "1") {
				setFlag(1);
				res.data.result[0].medicineData.map((temp, index) => {
					setComments(temp.comments)
				});
				setMemberDetails(res.data.result[0].memberDetails[0]);
				setHospitalDetails(res.data.result[0].hospitalDetails[0]);
				setPriscriptionDate(res.data.result[0].medicineDate || res.data.result[0].medicineImageDate)
				setMedicineData(res.data.result[0].medicineData);
				setDoctorDetails(res.data.result[0].doctorDetails);
				setPriscriptionInstruction(res.data.result[0].medicalPrescriptionComment)
				setImageData(res.data.result[0].medicineImageData)
				window.sessionStorage.removeItem("doctorVisitIdOfPrint");

				// var abc = []
				// for (let i = 0; i < res.data.result[0].medicineData.length; i++) {
				// 	var v = [];
				// 	if (res.data.result[0].medicineData[i].instructions != "" && res.data.result[0].medicineData[i].instructions != null) {
				// 		var a = res.data.result[0].medicineData[i].instructions.split('-')
				// 		if (a.length == 3) {
				// 			v.push(a[0] + " - " + a[1])
				// 			var obj = a[0] + " - " + a[1]
				// 			//	setInstructions(a[0] + " - " + a[1])
				// 		}
				// 		else if (a.length == 5) {
				// 			v.push(a[0] + " - " + a[1] + " - " + a[2] + " - " + a[3])
				// 			var obj = a[0] + " - " + a[1] + " - " + a[2] + " - " + a[3]
				// 			//	setInstructions(a[0] + " - " + a[1] + " - " + a[2] + " - " + a[3])
				// 		}
				// 		else if (a.length == 6) {
				// 			v.push(a[0] + " - " + a[1] + " - " + a[2] + " - " + a[3] + " - " + a[4])
				// 			var obj = a[0] + " - " + a[1] + " - " + a[2] + " - " + a[3] + " - " + a[4]
				// 			//	setInstructions(a[0] + " - " + a[1] + " - " + a[2] + " - " + a[3] + " - " + a[4])
				// 		}
				// 		abc.push(obj)
				// 	}
				// 	setArray(instructions)

				// }

			}
		})
	}, []);
	const printPrescription = () => {
		// const section1 = document.getElementById('section1');
		const section21 = document.getElementById('section2.1');
		const section22 = document.getElementById('section2.2');
		const section23 = document.getElementById('section2.3');
		const section24 = document.getElementById('section2.4');
		const section25 = document.getElementById('section2.5');
		// const section3 = document.getElementById('section3');
		const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		// const section53 = document.getElementById('section5.3');
		// const section54 = document.getElementById('section5.4');
		const section7 = document.getElementById('section7');
		// const section8 = document.getElementById('section8');

		// Hide unchecked sections before printing
		// if (isChecked1) {
		// 	section1.style.display = 'none';
		// }
		if (isChecked2) {
			section21.style.display = 'none';
			section22.style.display = 'none';
			section23.style.display = 'none';
			section24.style.display = 'none';
			section25.style.display = 'none';
		}
		// if (isChecked3) {
		// 	section3.style.display = 'none';
		// }
		if (isChecked4) {
			section4.style.display = 'none';
		}
		if (isChecked5) {
			section51.style.display = 'none';
			section52.style.display = 'none';
			// section53.style.display = 'none';
			// section54.style.display = 'none';
		}
		if (isChecked7) {
			section7.style.display = 'none';
		}
		// if (isChecked8) {
		// 	section8.style.display = 'none';
		// }

		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
		// section1.style.display = '';
		section21.style.display = '';
		section22.style.display = '';
		section23.style.display = '';
		section24.style.display = '';
		section25.style.display = '';
		// section3.style.display = '';
		section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		// section53.style.display = '';
		// section54.style.display = '';
		section7.style.display = '';
		// section8.style.display = '';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (

		<div align="center" className='patientPrescriptionWrapper printResponsive 11111111111111111111' >
			{/* {flag == 0 ? (
				<Loader />
			) : */}
			<div>
				<div
					align="center"
					style={{
						// width: '595px',
						// height: '595px',
						marginLeft: '0px',
						marginRight: '0px',
						marginTop: '0px',
						marginBottom: '0px',
						width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth || custum_width + 'px',
						height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight || custum_height + 'px',
						// marginLeft: '0px',
						// marginright: '0px',
						// marginTop: '0px',
						// marginBottom: '0px',
					}} className="printResponsiveWraper">
					<table
						// style={{ width: '100%', marginTop: '0px' }}
						style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }}
						border="0">
						<thead>
							<tr>
								<th style={{ fontWeight: '400' }}>
									<div class="printOutHeaderSec ">
										<table style={{ width: '100%' }}>
											<tbody>
												{/* <tr height="80px">
														<td colspan="12" valign="middle" align="center">
															<div style={{ width: '100%', float: 'none' }}>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '16px',
																		clear: 'both',
																		width: '100%',
																		margin: '0px 0 3px 0px',
																	}}
																>
																	<span
																		style={{
																			marginTop: '-5px',
																			display: 'inline - block',
																			width: '100%',
																		}}
																	>
																		<img
																			src={logoPath + window.sessionStorage.getItem('siteLogo')}
																			style={{
																				display: 'block',
																				width: 'auto',
																				maxHeight: '65px',
																				margin: 'auto',
																			}}
																			align="middle"
																		/>
																	</span>
																</p>
															</div>
															<div class="practicePrintName" style={{ width: '100%' }}>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '12px',
																		clear: 'both',
																		width: '100%',
																		marginBottom: '1px',
																		marginTop: '0',
																		textAlign: 'right',
																	}}
																>
																	{hospitalDetails.hospitalName}
																</p>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '12px',
																		clear: 'both',
																		width: '100%',
																		marginBottom: '1px',
																		marginTop: '0',
																		textAlign: 'right',
																	}}
																>
																	{hospitalDetails.hospitalAddress}
																</p>
																<p
																	style={{
																		fontWeight: '400',
																		fontSize: '12px',
																		clear: 'both',
																		width: '100%',
																		marginBottom: '1px',
																		marginTop: '0',
																		textAlign: 'right',
																	}}
																>
																	{hospitalDetails.hospitalDescription}
																</p>
															</div>
														</td>
													</tr> */}
												<HeaderDoctorPrescriptionPrint doctorDataId={doctorId} hospitalDataId={hospId} setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5} />
												{/*
													<tr>
														<td colspan="12">
															<hr color="#142536" style={{ margin: '0' }} />
														</td>
													</tr>
													<tr>
														<td
															width="50%"
															colspan="8"
															align="left"
															style={{ fontSize: '12px', wordBreak: 'break-all' }}
														>
															{doctorDetails.title} {doctorDetails.doctorDisplayName},{doctorDetails.speciality},{doctorDetails.education}
														</td>
														<td
															width="50%"
															align="right" colspan="4"
															style={{ fontSize: '12px', wordBreak: 'break-all' }}
														/>
													</tr>

													<tr>
														<td colspan="12">
															<hr color="#142536" style={{ margin: '0' }} />
														</td>
													</tr> */}
												<div className="no-print form-group cus-checkbox-wrpr" >
													<input checked={isChecked2} id='isChecked2' type="checkbox" onChange={(e) => setIsChecked2(e.target.checked)} title="Hide the patient's details while printing." />
													<label for="isChecked2" title="Hide the patient's details while printing."></label>
												</div>

												<tr id='section2.1'>
													<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}

													>
														Name : {memberDetails.memberFirstName} {memberDetails.memberLastName}
													</td>
													<td colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Date : {priscriptionDate}{' '}
													</td>
												</tr>

												<tr id='section2.2'>
													<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														Age : {memberDetails.memberAge}
													</td>
													<td colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'right',
														}}
													>
														Email Id : {memberDetails.secUserEmailid}
													</td>


												</tr>
												<tr id='section2.3'>
													<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														Sex : {memberDetails.memberGender}
													</td>
													<td colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'right',
														}}
													>
														Mobile No : {memberDetails.SecuserMobileNumber}
													</td>

												</tr>
												<tr id='section2.4'>
													{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
														(<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															MR No : {memberDetails.memberMrNo}
														</td>) :
														(!memberDetails.memberPrNo == "" &&
															<td
																colspan="6"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '15px',
																	textAlign: 'left',
																}}
															>
																PR No : {memberDetails.memberPrNo}
															</td>
														)}
												</tr>

												<tr id='section2.5'>
													<td colspan="12">
														<hr color="#142536" style={{ margin: '0' }} />
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</th>
							</tr>
						</thead>
						{medicineData.length > 0 && tw == "t" ? (

							<div>
								<tbody className='PrescriptionPrintTable'>
									{/* <tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr style={{ borderBottom: '2px solid black' }}>
												<td style={{ fontSize: '14px', paddingBottom: '10px' }}>
													{headingType}
												</td>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'right',
													}}
												>
													{' '}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr> */}
									{rx === 1 ? (
										<tr>
											<td>
												<div class="printOutSettingContent">
													<table style={{ width: '100%' }}>
														<tbody>
															<tr>
																<td align="left" style={{ width: '100%' }}>
																	Rx
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</td>
										</tr>
									) : ('')}

									<tr >
										<td>
											<div class="printOutSettingContent">
												<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
													<thead>
														<tr style={{ fontSize: '13px', background: '#eae8e8', display: 'flex', width: '540px' }}>
															<th style={{ width: '120px' }}

															>
																Medicine
															</th>
															<th style={{ width: "175px" }}

															>
																Frequency & Dosage
															</th>
															<th style={{ width: '80px' }}

															>
																Intake
															</th>
															<th style={{ width: '45px' }}

															>
																Qty
															</th>
															<th style={{ width: '80px' }}

															>
																Days
															</th>
															<th style={{ width: '90px' }}

															>
																Start Date
															</th>
														</tr>
													</thead>
													<tbody>

														{medicineData.map((medicine) => (
															<>
																<tr style={{ width: '540px', display: 'flex' }}>
																	<td style={{ width: '120px' }}
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.medicineName + " " + medicine.medType + " " + medicine.strength + " " + medicine.medUnit}
																		</p>
																	</td>

																	<td style={{ width: '175px' }}
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{(() => {
																				if (medicine.instructions.split('-').length == 3) {
																					return (
																						<div>{medicine.instructions.split('-')[0] + " - " + medicine.instructions.split('-')[1]}</div>
																					)
																				} else if (medicine.instructions.split('-').length == 5 || medicine.instructions.split('-').length == 7) {
																					return (
																						<div>{medicine.instructions.split('-')[0] + " - " + medicine.instructions.split('-')[1] + " - " + medicine.instructions.split('-')[2] + " - " + medicine.instructions.split('-')[3]}</div>
																					)
																				} else if (medicine.instructions.split('-').length == 6) {
																					return (
																						<div>{medicine.instructions.split('-')[0] + " - " + medicine.instructions.split('-')[1] + " - " + medicine.instructions.split('-')[2] + " - " + medicine.instructions.split('-')[3] + " - " + medicine.instructions.split('-')[4]}</div>
																					)
																				}
																			})()}
																		</p>
																	</td>
																	<td style={{ width: '80px' }}
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.intake}
																		</p>
																	</td>
																	<td style={{ width: '45px' }}
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.quantity}
																		</p>
																	</td>

																	<td style={{ width: '80px' }}>
																		{medicine.days != 0 ?
																			<p
																				class="sml_matter"
																				style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																			>
																				{medicine.days}
																			</p> : ""}
																	</td>
																	<td style={{ width: '90px' }}
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.startDate}
																		</p>
																	</td>

																</tr>
																{/* <tr style={{ borderBottom: "1px solid #ddd" }}>
																	<td

																		style={{
																			textAlign: 'left',
																			fontSize: '11px',
																			lineHeight: '15px',
																			padding: ' 0px 2px 0px 0',
																			display: 'inline-block',
																			boxSizing: 'border-box',
																			color: '#393939',
																		}}
																	>
																		<p
																			style={{
																				wordBreak: 'break-all',
																				margin: '3px 0',
																				fontSize: '11px!important',
																				wordBreak: ' break-word',
																			}}
																		>
																			{' '}
																			<span style={{ fontWeight: '600' }}>Instructions :  </span>{' '}
																		</p>
																	</td>
																	<td

																		style={{
																			textAlign: 'left',
																			fontSize: '11px',
																			lineHeight: '15px',
																			padding: ' 0px 2px 0px 0',
																			display: 'inline-block',
																			boxSizing: 'border-box',
																			color: '#393939',
																		}}
																	>
																		<p
																			style={{
																				wordBreak: 'break-all',
																				margin: '3px 0',
																				fontSize: '11px!important',
																				wordBreak: ' break-word',
																			}}
																		>
																			{' '}
																			<span style={{ fontWeight: '600' }}>  {medicine.reason}</span>{' '}
																		</p>
																	</td>
																</tr> */}
																{medicine.reason !== '' ?

																	<p style={{
																		width: "595px", wordBreak: 'break-all',
																		margin: '3px 0',
																		fontSize: '11px!important',
																		wordBreak: ' break-word', fontSize: "11px"
																	}}><b>Instructions</b> : {medicine.reason}</p>
																	: ""}

															</>



														))}
														<div class="printOutSettingContent">
															<table style={{ width: "100%" }}>
																<tbody>
																	<tr>

																		<div class="tablePrintRaw1">
																			{comment !== '' ?

																				<div class="treatmentComplaintRow1" style={{ float: "left", display: "inline-flex", width: "595px" }}>
																					<div class="treatmentComplaintLeft1" style={{ fontSize: "12px" }}>
																						Comments
																					</div>
																					<div class="treatmentComplaintRight1">
																						<span style={{ fontSize: "12px" }}> {comment}</span>
																						<br />
																					</div>
																				</div> : ''}



																		</div>

																	</tr>
																</tbody>
															</table>
														</div>

													</tbody>
												</table>
											</div>
										</td>
									</tr>

									<tr >
										<td>
											<div class="printOutSettingContent">
												<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
													<tbody>
														{/* {medicineData.map((medicine) => (
																<tr>
																	<td
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.medicineName}
																		</p>
																	</td>

																	<td
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{(() => {
																				if (medicine.instructions.split('-').length == 3) {
																					return (
																						<div>{medicine.instructions.split('-')[0] + " - " + medicine.instructions.split('-')[1]}</div>
																					)
																				} else if (medicine.instructions.split('-').length == 5 || medicine.instructions.split('-').length == 7) {
																					return (
																						<div>{medicine.instructions.split('-')[0] + " - " + medicine.instructions.split('-')[1] + " - " + medicine.instructions.split('-')[2] + " - " + medicine.instructions.split('-')[3]}</div>
																					)
																				} else if (medicine.instructions.split('-').length == 6) {
																					return (
																						<div>{medicine.instructions.split('-')[0] + " - " + medicine.instructions.split('-')[1] + " - " + medicine.instructions.split('-')[2] + " - " + medicine.instructions.split('-')[3] + " - " + medicine.instructions.split('-')[4]}</div>
																					)
																				}
																			})()}
																		</p>
																	</td>
																	<td
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.intake}
																		</p>
																	</td>
																	<td
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.quantity}
																		</p>
																	</td>
																	{medicine.days != 0 ?
																	<td>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.days}
																		</p>
																	</td>:""}
																	<td
																	>
																		<p
																			class="sml_matter"
																			style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																		>
																			{medicine.startDate}
																		</p>
																	</td>
																</tr>
															))} */}
														{/* ============================================================================================================================= */}

														{/* <tr>
																<td
																	colspan="6"
																	style={{
																		textAlign: 'left',
																		fontSize: '11px',
																		lineHeight: '15px',
																		padding: ' 0px 2px 0px 0',
																		display: 'inline-block',
																		boxSizing: 'border-box',
																		color: '#393939',
																	}}
																>
																	<p
																		style={{
																			wordBreak: 'break-all',
																			margin: '3px 0',
																			fontSize: '11px!important',
																			wordBreak: ' break-word',
																		}}
																	>
																		{' '}
																		<span style={{ fontWeight: '600' }}>Instructions : {priscriptionInstruction}</span>{' '}
																	</p>
																</td>
															</tr> */}

														{/*  instrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr*/}
														{tp == "p" ?
															null
															: <React.Fragment>
																<tr>
																	<td colspan="12">
																		<div class="printOutSettingContent">
																			<table style={{ width: "100%" }}>
																				<tbody>
																					<tr><td><hr style={{ margin: "0" }} /></td></tr>
																				</tbody>
																			</table>
																		</div>
																	</td>
																</tr>
																{medicineData.map((medicine, i) => (
																	<React.Fragment>
																		<tr id="medicineDetailSection0">
																			<td colspan="12">
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }}>
																						<tbody>
																							<tr>
																								<td>
																									<div class="medicineDetailName">{medicine.medicineName}</div>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>

																		<tr>
																			<td colspan="12">
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }}>
																						<tbody>
																							<tr>
																								<td>
																									{medicine.interactions !== '' ?
																										<div class="medicineDetailItems">
																											<span>Interactions</span>
																											<p>{medicine.interactions}</p>
																										</div> : ''}
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td colspan="12">
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }}>
																						<tbody>
																							<tr>
																								<td>
																									{medicine.drugReactions !== '' ?
																										<div class="medicineDetailItems">
																											<span>Drug Reactions</span>
																											<p>{medicine.drugReactions}</p>
																										</div> : ""}
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td colspan="12">
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }}>
																						<tbody>
																							<tr>
																								<td>
																									{medicine.majorSideEffects !== '' ?
																										<div class="medicineDetailItems">
																											<span>Major Side Effects</span>
																											<p>{medicine.majorSideEffects}</p>
																										</div> : ""}
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>

																		<tr>
																			<td colspan="12">
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }}>
																						<tbody>
																							<tr>
																								<td>
																									{medicine.majorSideEffects !== '' ?
																										<div class="medicineDetailItems">
																											<span>Indication And Dosage</span>
																											<p>{medicine.indicationAndDosage}</p>
																										</div> : ""}
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td colspan="12">
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }}>
																						<tbody>
																							<tr>
																								<td>
																									{medicine.majorSideEffects !== '' ?
																										<div class="medicineDetailItems">
																											<span>Precautions</span>
																											<p>{medicine.precautions}</p>
																										</div> : ""}
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>
																	</React.Fragment>
																))}
															</React.Fragment>}

														{/*  */}
													</tbody>
												</table>
											</div>
										</td>
									</tr>

								</tbody>
							</div>) : (

							<tbody className='PrescriptionPrintImgs'>
								<tr>
									<td>
										<div class="printOutSettingContent">
											<table style={{ width: '100%' }}>
												<tbody>
													{rx === 1 ? (
														<tr>
															<td align="left" style={{ width: '100%' }}>
																Rx
															</td>
														</tr>
													) : ("")}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
								{imageData.map((item, i) => (

									<tr className='priscriptionPrintTr'>
										<td>
											<img src={item.prescriptionImageName} />
										</td>
									</tr>
								))}
							</tbody>)}
						{/* Images Print Section ends here */}
						<tfoot>
							{/* <tr>
									<td>
										<div class="printOutFooterSec">
											<table style={{ width: '100%' }}>
												<tbody>
													<tr style={{ display: 'table-row' }}>
														<td>
															<div
																align="justify"
																class="practiceFootBtm"
																style={{ lineHeight: '15px' }}
															>
																{hospitalDetails.hospitalName}
															</div>
														</td>
													</tr>
													<tr>
														<td>
															<hr color="#142536" style={{ margin: '2px 0' }} />
														</td>
													</tr>

													<tr style={{ display: 'table-row' }}>
														<td style={{ paddingTop: '0px', fontSize: '11px' }}>
															<div style={{ float: 'left' }} />
															<div style={{ textAlign: 'Center' }}>
																{hospitalDetails.hospitalName}<br />
																{hospitalDetails.hospitalEmailId}
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr> */}
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											{/* <tbody>
																<tr class="dentalProPlaned">
																	<td
																		style={{ paddingTop: '15px', fontSize: '10px' }}
																	>
																		The Final treatment charges may vary depending
																		on any unseen treatments included or
																		modification in treatment plan.
																	</td>
																</tr>
															</tbody> */}
											<FooterDoctorPrescriptionPrint doctorDataId={doctorId} hospitalDataId={hospId} setIsChecked7={setIsChecked7} isChecked7={isChecked7}/>
										</table>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div class="printBtm" style={{ textAlign: 'center' }}>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td colspan="6" style={{ textAlign: 'center' }}>
									<p
										id="printButton"
										style={{
											width: '70px',
											textAlign: 'center',
											paddingTop: '5px',
											lineHeight: '18px',
											display: 'inline-block',
											fontWeight: 'bold',
											fontSize: '14px',
											color: 'white',
											background: 'rebeccapurple none repeat scroll 0% 0%',
											marginTop: '2px',
											cursor: 'pointer',
											borderRadius: '5px',
											marginBottom: '2px',
											height: '30px',
											position: "absolute",
											right: "0px", fontFamily: "Verdana",
										}}
										onClick={printPrescription}
									>
										Print
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			{/* } */}
		</div>
	);
}

export default PriscriptionPrint;
