import React, { useState, useRef } from 'react';
import Webcam from "react-webcam";
import AWS from "aws-sdk";
import { logRoles } from '@testing-library/dom';
import Profile from './profile';
var s3;
const WebcamComponent = () => <Webcam />;
const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};
const WebcamCaptureDental = (props) => {
    const [fileName, setFileName] = useState('')
    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc)
           // document.getElementById('saveCamButton').style.display = 'block' ;
            // document.getElementById ('').style.display = 'none' ;
        });
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        region: "us-east-1"
    });
    var bucketParams = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    const handleExport = () => {
        
        const base64 = image;
        let fileName = "patient" + Date.parse(new Date()) + ".png";
        let S3BucketMainFolder = process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_DENTALFILES_S3_IMAGE_PATH
        let fullFilePath = S3BucketMainFolder + "/" + fileName;
        props.webCamImage(fileName, base64)
        const buf = Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')
        var data = {
            Key: fullFilePath,
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: 'image/jpeg'
        };
        props.onCapture(data);
    }
    return (
        <div  className="webcam-container">
            <div className="webcam-img">
                {image == '' ? <Webcam
                    audio={false}
                    height={150}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={150}
                    videoConstraints={videoConstraints}
                /> : <img src={image} />}
            </div>
            <div>
                {image != '' ?
                    <button onClick={(e) => {
                        e.preventDefault();
                        setImage('')
                    }}
                        className="webcam-btn">
                        Take Another</button> :
                    <button onClick={(e) => {
                        e.preventDefault();
                        capture();
                    }}
                        className="borderButton borderButtonSmall">Take Snapshot</button>
                }
                <button id='saveCamButton'
                //  style={{ display: 'none' }}
                    className="borderButton borderButtonSmall" onClick={handleExport}>Save Photo</button>
            </div>
        </div>
    );
};
export default WebcamCaptureDental