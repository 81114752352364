import React from 'react';
import PharmacyHeader from './layout/header';
import Footer from './layout/footer';
import { Route } from 'react-router-dom';
import PharmacyLeft from './pharmacyLeft'
import Datepicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
function PharmacyStockDetails() {
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper hospitalLabOrder exportCalndrSec hospitalLabWraper hosPharmacy">
			<PharmacyHeader />
			<div className="contentWraper contentWraperScroll">
				<PharmacyLeft />
                <div className="settingsRight">
                    <div className="dashboardCover pharmacyManufactureWrap">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="dashboardTitle"> Pharmacy Stock Details</div>
                            </div>
						</div>
						<div className="paginationSection">
						<Pagination
							itemsCountPerPage={2}
							totalItemsCount={5}
						/>
					</div>
			   <div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									id="selectName"
									name="selectName"
								>
									<option value="0">Search In</option>
									<option value="1">Patient Name</option>
									<option value="2">Invoice Number</option>
									<option value="3">Reciept Number</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									type="text"
									className="inputField"
									id="searchText"
									name="searchText" style={{background:"transparent"}}
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										className="Datepicker pa2 inputField"
										placeholderText="From"
										calendarClassName="rasta-stripes"
										dateFormat="dd-MM-yyyy"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										className="Datepicker pa2 inputField"
										placeholderText="To"
										calendarClassName="rasta-stripes"
										dateFormat="dd-MM-yyyy"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span  className="dashButtonBorder" style={{border:"1px solid gainsboro"}}>
									Search
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span   className="dashButtonBorder dashButtonBorderGrey" style={{border:"1px solid gainsboro"}}>
									Export
								</span>
							</div>
						</div>
							<div className="row  ">
								<div className='col-md-5'>
                                        <div className="searchTxt">
									        Total Selling Price : &#8377; 3,570,876.20
								        </div>
								</div>
								<div className='col-md-4'>
                                        <div className="searchTxt">
									        Total Selling Unit Price : &#8377; 3,570,876.20
								        </div>
                                 </div>

							<div className="col-md-3">
								<div className="searchCount" style={{margin:"0px"}}>
									Total Medicines  : 123
								</div>
							</div>
						</div>
					</div>
					<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th>Medicine Name	</th>
										<th>Total Available Quantity </th>
										<th>Batch Number</th>
                                    	<th>Available Quantity</th>
                                        <th>Expiry Date</th>
                                        <th style={{ textAlign: 'right' }}>Selling Price</th>
                                        <th style={{ textAlign: 'right' }}>Selling Unit Price</th>
									</tr>
								</thead>
								<tbody>
											<tr>
												<td>Paracetamol tab 850 mg</td>
												<td>1213</td>
												<td>12</td>
                                                <td className='TextRed'> 134</td>
                                                <td className='TextRed'>
                                                    Dec 2020
                                                </td>

												<td style={{ textAlign: 'right' }}>
													&#8377; 1234.00
													</td>
													<td style={{ textAlign: 'right' }}>
													&#8377; 1234.00
												</td>

											</tr>
											<tr>
												<td>Paracetamol tab 850 mg</td>
												<td>1213</td>
												<td>12</td>
                                                <td> 134</td>
                                                <td>
                                                    Dec 2020
                                                </td>

												<td style={{ textAlign: 'right' }}>
													&#8377; 1234.00
													</td>
													<td style={{ textAlign: 'right' }}>
													&#8377; 1234.00
												</td>

											</tr>

                                        </tbody>

							</table>
					</div>
				</div>
					<div className="paginationSection">
						<Pagination
							itemsCountPerPage={2}
							totalItemsCount={5}
						/>
					</div>

                </div>
		    </div>


				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PharmacyStockDetails;
