import React, { useEffect, useState } from "react";
import { callingPutAPI } from "../config";
import { callingAPI } from "../config";
import Loader from "../Common/loader";
var moment = require("moment");
function AutoIdGeneration() {
  const [load, setLoad] = useState(false);
  const [state, setState] = useState({
    txtMrNumber: "",
    txtPrNumber: "",
    txtLastIpNumber: "",
    txtInvoiceNumber: "",
    txtLastMrNumber: "",
    lastMrValidation: "",
    txtLastPrNumber: "",
    txtPatientNumber: "",
    txtlastInvoiceNumber: "",
    txtLastReciptNumberMr: "",
    txtLastReceiptNumberPr: "",
    txtReceiptNumberPrefix: "",
    txtDigitsInMr: "",
    txtDigitsInPr: "",
    txtDigitsInIp: "",
  });
  const [yearCheckBoxMr, setYearCheckBoxMr] = useState(false);
  const [dateCheckBoxMr, setDateCheckBoxMr] = useState(false);
  const [monthCheckBoxMr, setMonthCheckBoxMr] = useState(false);
  const [autoGenarationIdList, setAutoGenarationIdList] = useState([]);
  const [comboSeparateMr, setComboSeparateMr] = useState(0);
  const comboSeparateMrChange = (event) => {
    setComboSeparateMr(event.target.value);
  };
  const [yearCheckBoxPr, setYearCheckBoxPr] = useState(false);
  const [dateCheckBoxPr, setDateCheckBoxPr] = useState(false);
  const [monthCheckBoxPr, setMonthCheckBoxPr] = useState(false);
  const [getlastMrValidation, setlastMrValidation] = useState("");
  const [getlastPrValidation, setlastPrValidation] = useState("");
  const [getlastIpValidation, setlastIpValidation] = useState("");
  const [getlastInvoiceValidation, setlastInvoiceValidation] = useState("");
  const [getlastReciptValidation, setlastReciptValidation] = useState("");
  const [comboSeparatePr, setComboSeparatePr] = useState(0);
  const comboSeparatePrChange = (event) => {
    setComboSeparatePr(event.target.value);
  };

  const [yearCheckBoxIp, setYearCheckBoxIp] = useState(false);
  const [dateCheckBoxIp, setDateCheckBoxIp] = useState(false);
  const [monthCheckBoxIp, setMonthCheckBoxIp] = useState(false);
  const [comboSeparateIp, setComboSeparateIp] = useState(0);
  const comboSeparateIpChange = (event) => {
    setComboSeparateIp(event.target.value);
  };
  const onyearChangeMr = (event) => {
    setYearCheckBoxMr(event.target.checked);
  };
  const ondateChangeMr = (event) => {
    setDateCheckBoxMr(event.target.checked);
  };
  const onmonthChangeMr = (event) => {
    setMonthCheckBoxMr(event.target.checked);
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const onyearChangePr = (event) => {
    setYearCheckBoxPr(event.target.checked);
  };
  const ondateChangePr = (event) => {
    setDateCheckBoxPr(event.target.checked);
  };
  const onmonthChangePr = (event) => {
    setMonthCheckBoxPr(event.target.checked);
  };

  const onyearChangeIp = (event) => {
    setYearCheckBoxIp(event.target.checked);
  };
  const ondateChangeIp = (event) => {
    setDateCheckBoxIp(event.target.checked);
  };
  const onmonthChangeIp = (event) => {
    setMonthCheckBoxIp(event.target.checked);
  };
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const zeroPadPr = (num, places) => String(num).padStart(places, "0");
  const zeroPadIp = (num, places) => String(num).padStart(places, "0");
  const [mr, setMr] = useState(null);
  const [pr, setPr] = useState(null);
  const [ip, setIp] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const handleChange = (event) => {
    var flag = true;
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === "") {
      if (event.target.id === "txtLastMrNumber") {
        document.getElementById("txtLastMrNumber").value = event.target.value;
        setMr(document.getElementById("txtLastMrNumber").value);
      } else if (event.target.id === "txtLastPrNumber") {
        document.getElementById("txtLastPrNumber").value = event.target.value;
        setPr(document.getElementById("txtLastPrNumber").value);
      } else if (event.target.id === "txtLastIpNumber") {
        document.getElementById("txtLastIpNumber").value = event.target.value;
        setIp(document.getElementById("txtLastIpNumber").value);
      } else if (event.target.id === "txtlastInvoiceNumber") {
        document.getElementById("txtlastInvoiceNumber").value =
          event.target.value;
        setInvoice(document.getElementById("txtlastInvoiceNumber").value);
      } else if (event.target.id === "txtLastReciptNumberMr") {
        document.getElementById("txtLastReciptNumberMr").value =
          event.target.value;
        setReceipt(document.getElementById("txtLastReciptNumberMr").value);
      }
    } else if (event.target.id === "txtLastReciptNumberPr") {
      document.getElementById("txtLastReciptNumberPr").value =
        event.target.value;
      setReceipt(document.getElementById("txtLastReciptNumberPr").value);
    } else {
      if (event.target.id === "txtLastMrNumber") {
        document.getElementById("txtLastMrNumber").value =
          state.txtLastMrNumber;
        document.getElementById("txtLastMrNumber").focus();
        alert("Only Numbers Accepted");
        flag = false;
      } else {
        if (event.target.id === "txtLastMrNumber") {
          document.getElementById("txtLastMrNumber").value =
            state.txtLastMrNumber;
          document.getElementById("txtLastMrNumber").focus();
          alert("Enter the Start from Value of MR No");
          flag = false;
        } else {
          if (event.target.id === "txtLastPrNumber") {
            document.getElementById("txtLastPrNumber").value =
              state.txtLastPrNumber;
            document.getElementById("txtLastPrNumber").focus();
            alert("Only Numbers Accepted");
            flag = false;
          } else if (event.target.id === "txtLastIpNumber") {
            document.getElementById("txtLastIpNumber").value =
              state.txtLastIpNumber;
            document.getElementById("txtLastIrNumber").focus();
            alert("Only Numbers Accepted");
            flag = false;
          } else if (event.target.id === "txtlastInvoiceNumber") {
            document.getElementById("txtlastInvoiceNumber").value =
              state.txtlastInvoiceNumber;
            document.getElementById("txtlastInvoiceNumber").focus();
            alert("Only Numbers Accepted");
            flag = false;
          } else if (event.target.id === "txtLastReciptNumberMr") {
            document.getElementById("txtLastReciptNumberMr").value =
              state.txtLastReciptNumberMr;
            document.getElementById("txtLastReciptNumberMr").focus();
            alert("Only Numbers Accepted");
            flag = false;
          }
        }
      }
    }
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const saveAutoIdGeneration = () => {
    var flag = true;
    window.scroll(0, 0);
    if (document.getElementById("txtLastMrNumber").value === "") {
      document.getElementById("txtLastMrNumber").focus();
      alert("Enter the Start from Value of MR No");
      window.scroll(0, 0);
      flag = false;
      return;
    } else if (
      document.getElementById("txtPrNumber").value !== "" &&
      document.getElementById("txtLastPrNumber").value === ""
    ) {
      document.getElementById("txtLastPrNumber").focus();
      alert("Enter the Start from Value of PR No");
      window.scroll(0, 0);
      flag = false;
      return;
    } else if (
      document.getElementById("txtPatientNumber").value !== "" &&
      document.getElementById("txtLastIpNumber").value === ""
    ) {
      document.getElementById("txtLastIpNumber").focus();
      alert("Enter the Start from Value of IP No");
      window.scroll(0, 0);
      flag = false;
      return;
    }
    document.getElementById("saveButtonId").style.display = "none";
    document.getElementById("saveButtonId1").style.display = "block";
    let errMsg = "";

    if (flag === true) {
      let valuesToSend = {};
      valuesToSend.lastMrNo =
        state.txtLastMrNumber === ""
          ? getlastMrValidation
          : state.txtLastMrNumber;
      valuesToSend.lastPrNo =
        state.txtLastPrNumber === ""
          ? getlastPrValidation
          : state.txtLastPrNumber;
      valuesToSend.lastIpNo =
        state.txtLastIpNumber === ""
          ? getlastIpValidation
          : state.txtLastIpNumber;
      valuesToSend.mrNoPrefix = state.txtMrNumber;
      valuesToSend.prNoPrefix = state.txtPrNumber;
      valuesToSend.ipNoPrefix = state.txtPatientNumber;
      valuesToSend.invoiceNoPrefix = state.txtInvoiceNumber;
      valuesToSend.lastInvoiceNo =
        state.txtlastInvoiceNumber === ""
          ? getlastInvoiceValidation
          : state.txtlastInvoiceNumber;
      valuesToSend.lastReceiptNo =
        state.txtLastReciptNumberMr === ""
          ? getlastReciptValidation
          : state.txtLastReciptNumberMr;
      // valuesToSend.lastReceiptNo = state.txtLastReciptNumberPr;
      valuesToSend.receiptNoPrefix = state.txtReceiptNumberPrefix;
      valuesToSend.hasIpNodd = dateCheckBoxIp;
      valuesToSend.mrNoSeperator = comboSeparateMr;
      valuesToSend.prNoSeperator = comboSeparatePr;
      valuesToSend.ipNoSeperator = comboSeparateIp;
      valuesToSend.noOfDigitsInMrNo = state.txtDigitsInMr;
      valuesToSend.noOfDigitsInPrNo = state.txtDigitsInPr;
      valuesToSend.noOfDigitsIprNo = state.txtDigitsInIp;
      valuesToSend.hospitalId = localStorage.getItem("hospitalId");
      valuesToSend.hasMrNodd = dateCheckBoxMr;
      valuesToSend.hasPrNodd = dateCheckBoxPr;
      valuesToSend.hasMrNomm = monthCheckBoxMr;
      valuesToSend.hasPrNomm = monthCheckBoxPr;
      valuesToSend.hasMrNoyyyy = yearCheckBoxMr;
      valuesToSend.hasPrNoyyyy = yearCheckBoxPr;
      valuesToSend.hasIpNomm = monthCheckBoxIp;
      valuesToSend.hasIpNoyyyy = yearCheckBoxIp;
      valuesToSend.subFunctionName = "updateAutoIdGeneration";
      callingAPI("autoGenarationId/autoGeneartionUpdate", valuesToSend)
        .then((res) => {
          // alert(res.data.successMessage)
          alert("Data Saved Successfully");
          setLoad(true);
          window.location.href = "/settings/autoIdGeneration";
        })
        .catch();
    }
  };
  const autoGenarationIdListArray = () => {
    const postData = {
      hospitalId: localStorage.getItem("hospitalId"),
      maxlimit: "",
      Offset: "",
    };
    callingAPI("autoGenarationId", postData)
      .then((data) => {
        if (data.data.success === "1") {
          setAutoGenarationIdList(data.data.autoGenarationIdList[0]);
          setYearCheckBoxMr(data.data.autoGenarationIdList[0].mrYear);
          setDateCheckBoxMr(data.data.autoGenarationIdList[0].mrDate);
          setMonthCheckBoxMr(data.data.autoGenarationIdList[0].mrMonth);
          setYearCheckBoxPr(data.data.autoGenarationIdList[0].prYear);
          setDateCheckBoxPr(data.data.autoGenarationIdList[0].prDate);
          setMonthCheckBoxPr(data.data.autoGenarationIdList[0].prMonth);
          setDateCheckBoxIp(data.data.autoGenarationIdList[0].ipDate);
          setMonthCheckBoxIp(data.data.autoGenarationIdList[0].ipMonth);
          setYearCheckBoxIp(data.data.autoGenarationIdList[0].ipYear);
          setComboSeparateMr(data.data.autoGenarationIdList[0].seperator);
          setComboSeparatePr(data.data.autoGenarationIdList[0].prSeperator);
          setComboSeparateIp(data.data.autoGenarationIdList[0].ipSeparator);
          setlastMrValidation(data.data.autoGenarationIdList[0].mrNo);
          setlastPrValidation(data.data.autoGenarationIdList[0].prNo);
          setlastIpValidation(data.data.autoGenarationIdList[0].ipNo);
          setlastInvoiceValidation(data.data.autoGenarationIdList[0].lastno);
          setlastReciptValidation(data.data.autoGenarationIdList[0].lastrecno);
          setState({
            txtLastMrNumber: data.data.autoGenarationIdList[0].mrNo,
            txtLastPrNumber: data.data.autoGenarationIdList[0].prNo,
            txtLastIpNumber: data.data.autoGenarationIdList[0].ipNo,
            txtMrNumber: data.data.autoGenarationIdList[0].mrNoPrefix,
            txtPrNumber: data.data.autoGenarationIdList[0].prNoPrefix,
            txtPatientNumber: data.data.autoGenarationIdList[0].ipNoprefix,
            txtInvoiceNumber: data.data.autoGenarationIdList[0].invoiceno,
            txtlastInvoiceNumber: data.data.autoGenarationIdList[0].lastno,
            txtLastReciptNumberMr: data.data.autoGenarationIdList[0].lastrecno,
            // txtLastReciptNumberPr: data.data.autoGenarationIdList[0].lastrecno,
            txtReceiptNumberPrefix: data.data.autoGenarationIdList[0].reciptno,
            txtDigitsInMr: data.data.autoGenarationIdList[0].noOfDigits,
            txtDigitsInPr: data.data.autoGenarationIdList[0].prNoOfDigits,
            txtDigitsInIp: data.data.autoGenarationIdList[0].ipNoofDigits,
          });
          setLoad(true);
        } else {
          setLoad(true);
        }
      })
      .catch();
  };
  useEffect(() => {
    window.scroll(0, 0);
    autoGenarationIdListArray();
  }, []);
  return (
    <div>
      <div className="practiceManagementForm plautoId">
        {load === false ? <Loader /> : ""}
        <h3 className="practiceHead" style={{ paddingLeft: "10px" }}>
          MR No Generation
        </h3>
        <div className="practiceManagementFormBlcok">
          <form action="#" method="post">
            <input
              type="hidden"
              name="hidPrevMRNo"
              id="hidPrevMRNo"
              value="73"
            />
            <input
              type="hidden"
              name="hidLastInvNo"
              id="hidLastInvNo"
              value="38"
            />
            <input
              type="hidden"
              name="hidLastRecptNo"
              id="hidLastRecptNo"
              value="28"
            />
            <ul className="row">
              <li className="col-md-6 col-lg-6">
                <label>Patient MR Number (Prefix)</label>
                <input
                  type="text"
                  value={state.txtMrNumber}
                  onChange={onChange}
                  id="txtMrNumber"
                  autoFocus="autofocus"
                  name="txtMrNumber"
                  tabIndex="1"
                  placeholder="Patient ID (Prefix)"
                  maxLength="100"
                  style={{ width: "41%", float: "left" }}
                />
                <div className="prefixDateWrap">
                  <div className="prefixDateDiv prefixDateYr">
                    <div className="checkBoxSquareDiv">
                      <input
                        type="checkbox"
                        title="Year"
                        checked={yearCheckBoxMr}
                        onChange={onyearChangeMr}
                        id="checkYearMr"
                        name="checkYearMr"
                      />
                      <label htmlFor="checkYearMr" />
                    </div>
                    <span>YYYY</span>
                  </div>
                  <div className="prefixDateDiv">
                    <div className="checkBoxSquareDiv">
                      <input
                        type="checkbox"
                        title="Month"
                        id="checkMonthMr"
                        checked={monthCheckBoxMr}
                        name="checkMonthMr"
                        onChange={onmonthChangeMr}
                      />
                      <label htmlFor="checkMonthMr" />
                    </div>
                    <span>MM</span>
                  </div>
                  <div className="prefixDateDiv">
                    <div className="checkBoxSquareDiv">
                      <input
                        type="checkbox"
                        title="Date"
                        checked={dateCheckBoxMr}
                        id="checkDateMr"
                        onChange={ondateChangeMr}
                        name="checkDateMr"
                      />
                      <label htmlFor="checkDateMr" />
                    </div>
                    <span>DD</span>
                  </div>
                </div>
              </li>
              <li className="col-md-2 col-lg-2">
                <label>Separator</label>
                <select
                  name="comboSeparateMr"
                  id="comboSeparateMr"
                  title="separator"
                  value={comboSeparateMr}
                  onChange={comboSeparateMrChange}
                >
                  <option value="">Select</option>
                  <option value="/">/</option>
                  <option value="-">-</option>
                  <option value="_">_</option>
                  <option value="|">|</option>
                </select>
              </li>
              <li className="col-md-2 col-lg-2">
                <label>Digits in MR No.</label>
                <input
                  type="text"
                  value={state.txtDigitsInMr}
                  onChange={onChange}
                  id="txtDigitsInMr"
                  name="txtDigitsInMr"
                />
              </li>
              <li className="col-md-2 col-lg-2">
                <label>Last MR Number</label>
                <input
                  type="text"
                  value={state.txtLastMrNumber}
                  onChange={handleChange}
                  id="txtLastMrNumber"
                  autoFocus="autofocus"
                  name="txtLastMrNumber"
                  tabIndex="1"
                  placeholder="Starts From"
                  maxLength="100"
                />
              </li>
              <li className="col-md-12 col-lg-12">
                <div className="autoIdGeneration">
                  <span>MR No Format :</span>
                  <label id="generatedMr">
                    {state.txtMrNumber +
                      (state.txtMrNumber.length > 0 ? comboSeparateMr : "") +
                      (yearCheckBoxMr
                        ? new Date().getUTCFullYear() + comboSeparateMr
                        : "") +
                      (monthCheckBoxMr
                        ? moment().format("MM") + comboSeparateMr
                        : "") +
                      (dateCheckBoxMr
                        ? moment().format("DD") + comboSeparateMr
                        : "") +
                      zeroPad(state.txtLastMrNumber, state.txtDigitsInMr)}
                  </label>
                </div>
              </li>
              <li className="borderLineLi">
                <div className="borderLineDiv" />
              </li>

              {/* ************PR Number starts here******************* */}

              <h3 className="practiceHead" style={{ paddingLeft: "10px" }}>
                PR No Generation
              </h3>
              <div className="practiceManagementFormBlcok">
                <form action="#" method="post">
                  <input
                    type="hidden"
                    name="hidPrevMRNo"
                    id="hidPrevMRNo"
                    value="73"
                  />
                  <input
                    type="hidden"
                    name="hidLastInvNo"
                    id="hidLastInvNo"
                    value="38"
                  />
                  <input
                    type="hidden"
                    name="hidLastRecptNo"
                    id="hidLastRecptNo"
                    value="28"
                  />
                  <ul className="row">
                    <li
                      className="col-md-6 col-lg-6"
                      style={{ paddingLeft: "0px" }}
                    >
                      <label>Patient PR Number (Prefix)</label>
                      <input
                        type="text"
                        value={state.txtPrNumber}
                        onChange={onChange}
                        id="txtPrNumber"
                        autoFocus="autofocus"
                        name="txtPrNumber"
                        tabIndex="1"
                        placeholder="Patient ID (Prefix)"
                        maxLength="100"
                        style={{ width: "41%", float: "left" }}
                      />
                      <div className="prefixDateWrap">
                        <div className="prefixDateDiv prefixDateYr">
                          <div className="checkBoxSquareDiv">
                            <input
                              type="checkbox"
                              title="Year"
                              checked={yearCheckBoxPr}
                              onChange={onyearChangePr}
                              id="checkYearPr"
                              name="checkYearPr"
                            />
                            <label htmlFor="checkYearPr" />
                          </div>
                          <span>YYYY</span>
                        </div>
                        <div className="prefixDateDiv">
                          <div className="checkBoxSquareDiv">
                            <input
                              type="checkbox"
                              title="Month"
                              id="checkMonthPr"
                              checked={dateCheckBoxPr}
                              name="checkMonthPr"
                              onChange={ondateChangePr}
                            />
                            <label htmlFor="checkMonthPr" />
                          </div>
                          <span>MM</span>
                        </div>
                        <div className="prefixDateDiv">
                          <div className="checkBoxSquareDiv">
                            <input
                              type="checkbox"
                              title="Date"
                              checked={monthCheckBoxPr}
                              id="checkDatePr"
                              onChange={onmonthChangePr}
                              name="checkDatePr"
                            />
                            <label htmlFor="checkDatePr" />
                          </div>
                          <span>DD</span>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-2 col-lg-2">
                      <label>Separator</label>
                      <select
                        name="comboSeparatePr"
                        id="comboSeparatePr"
                        title="separator"
                        value={comboSeparatePr}
                        onChange={comboSeparatePrChange}
                      >
                        <option value="">Select</option>
                        <option value="/">/</option>
                        <option value="-">-</option>
                        <option value="_">_</option>
                        <option value="|">|</option>
                      </select>
                    </li>
                    <li className="col-md-2 col-lg-2">
                      <label>Digits in PR No.</label>
                      <input
                        type="text"
                        value={state.txtDigitsInPr}
                        onChange={onChange}
                        id="txtDigitsInPr"
                        name="txtDigitsInPr"
                      />
                    </li>
                    <li className="col-md-2 col-lg-2">
                      <label>Last PR Number</label>
                      <input
                        type="text"
                        value={state.txtLastPrNumber}
                        onChange={handleChange}
                        id="txtLastPrNumber"
                        autoFocus="autofocus"
                        name="txtLastPrNumber"
                        tabIndex="1"
                        placeholder="Starts From"
                        maxLength="100"
                      />
                    </li>
                    <li className="col-md-12 col-lg-12">
                      <div className="autoIdGeneration">
                        <span>PR No Format :</span>
                        <label id="generatedPr">
                          {state.txtPrNumber +
                            (state.txtPrNumber.length > 0
                              ? comboSeparatePr
                              : "") +
                            (yearCheckBoxPr
                              ? new Date().getUTCFullYear() + comboSeparatePr
                              : "") +
                            (dateCheckBoxPr
                              ? moment().format("MM") + comboSeparatePr
                              : "") +
                            (monthCheckBoxPr
                              ? moment().format("DD") + comboSeparatePr
                              : "") +
                            zeroPadPr(
                              state.txtLastPrNumber,
                              state.txtDigitsInPr
                            )}
                        </label>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>

              {/* ************PR Number Ends here******************* */}

              <li className="borderLineLi">
                <div className="borderLineDiv" />
              </li>
              <li className="col-md-6 col-lg-6">
                <label>Invoice Number (Prefix)</label>
                <input
                  type="text"
                  value={state.txtInvoiceNumber}
                  onChange={onChange}
                  id="txtInvoiceNumber"
                  autoFocus="autofocus"
                  name="txtInvoiceNumber"
                  tabIndex="1"
                  placeholder="Invoice Number (Prefix)"
                  maxLength="100"
                />
              </li>
              <li className="col-md-6 col-lg-6">
                <label>Last Invoice Number</label>
                <input
                  type="text"
                  value={state.txtlastInvoiceNumber}
                  onChange={handleChange}
                  id="txtlastInvoiceNumber"
                  autoFocus="autofocus"
                  name="txtlastInvoiceNumber"
                  tabIndex="1"
                  placeholder="Last Invoice Number"
                  maxLength="100"
                />
              </li>
              <li className="borderLineLi">
                <div className="borderLineDiv" />
              </li>
              <li className="col-md-6 col-lg-6">
                <label>Receipt Number (Prefix)</label>
                <input
                  type="text"
                  value={state.txtReceiptNumberPrefix}
                  onChange={onChange}
                  id="txtReceiptNumberPrefix"
                  autoFocus="autofocus"
                  name="txtReceiptNumberPrefix"
                  tabIndex="1"
                  placeholder="Recipt Number (Prefix)"
                  maxLength="100"
                />
              </li>
              <li className="col-md-6 col-lg-6">
                <label>Last Receipt Number</label>
                <input
                  type="text"
                  value={state.txtLastReciptNumberMr}
                  onChange={handleChange}
                  id="txtLastReciptNumberMr"
                  autoFocus="autofocus"
                  name="txtLastReciptNumberMr"
                  tabIndex="1"
                  placeholder="Last Recipt Number"
                  maxLength="100"
                />
              </li>
              <li className="borderLineLi">
                <div className="borderLineDiv" />
              </li>
              <h3 className="practiceHead">IP No Generation</h3>
              <li className="col-md-6 col-lg-6">
                <label>In Patient Number (Prefix)</label>
                <input
                  type="text"
                  value={state.txtPatientNumber}
                  onChange={onChange}
                  id="txtPatientNumber"
                  autoFocus="autofocus"
                  name="txtPatientNumber"
                  tabIndex="1"
                  placeholder="Patient ID (Prefix)"
                  maxLength="100"
                  style={{ width: "41%", float: "left" }}
                />
                <div className="prefixDateWrap">
                  <div className="prefixDateDiv prefixDateYr">
                    <div className="checkBoxSquareDiv">
                      <input
                        type="checkbox"
                        title="Year"
                        checked={yearCheckBoxIp}
                        onChange={onyearChangeIp}
                        id="checkYearIp"
                        name="checkYearIp"
                      />
                      <label htmlFor="checkYearIp" />
                    </div>
                    <span>YYYY</span>
                  </div>
                  <div className="prefixDateDiv">
                    <div className="checkBoxSquareDiv">
                      <input
                        type="checkbox"
                        title="Month"
                        id="checkMonthIp"
                        checked={monthCheckBoxIp}
                        name="checkMonthIp"
                        onChange={onmonthChangeIp}
                      />
                      <label htmlFor="checkMonthIp" />
                    </div>
                    <span>MM</span>
                  </div>
                  <div className="prefixDateDiv">
                    <div className="checkBoxSquareDiv">
                      <input
                        type="checkbox"
                        title="Date"
                        checked={dateCheckBoxIp}
                        id="checkDateIp"
                        onChange={ondateChangeIp}
                        name="checkDateIp"
                      />
                      <label htmlFor="checkDateIp" />
                    </div>
                    <span>DD</span>
                  </div>
                </div>
              </li>
              <li className="col-md-2 col-lg-2">
                <label>Separator</label>
                <select
                  name="comboSeparateIp"
                  title="separator"
                  id="comboSeparateIp"
                  value={comboSeparateIp}
                  onChange={comboSeparateIpChange}
                >
                  <option value="">Select</option>
                  <option value="/">/</option>
                  <option value="-">-</option>
                  <option value="_">_</option>
                  <option value="|">|</option>
                </select>
              </li>
              <li className="col-md-2 col-lg-2">
                <label>Digits in IP No.</label>
                <input
                  type="text"
                  value={state.txtDigitsInIp}
                  onChange={onChange}
                  id="txtDigitsInIp"
                  name="txtDigitsInIp"
                />
              </li>
              <li className="col-md-2 col-lg-2">
                <label>Last IP Number</label>
                <input
                  type="text"
                  value={state.txtLastIpNumber}
                  onChange={handleChange}
                  id="txtLastIpNumber"
                  autoFocus="autofocus"
                  name="txtLastIpNumber"
                  tabIndex="1"
                  placeholder="Starts From"
                  maxLength="100"
                />
              </li>
              <li className="col-md-12 col-lg-12">
                <div className="autoIdGeneration">
                  <span>IP No Format :</span>
                  <label id="generatedMr">
                    {state.txtPatientNumber +
                      (state.txtPatientNumber.length >= 1
                        ? comboSeparateIp
                        : "") +
                      (yearCheckBoxIp
                        ? new Date().getUTCFullYear() + comboSeparateIp
                        : "") +
                      (monthCheckBoxIp
                        ? moment().format("MM") + comboSeparateIp
                        : "") +
                      (dateCheckBoxIp
                        ? moment().format("DD") + comboSeparateIp
                        : "") +
                      zeroPadIp(state.txtLastIpNumber, state.txtDigitsInIp)}
                  </label>
                </div>
              </li>
              <li className="borderLineLi">
                <div className="borderLineDiv" />
              </li>
              <li className="col-md-12 col-lg-12 pull-right">
                <div
                  className="col-md-2 dashboardSearchList"
                  style={{ float: "right" }}
                >
                  <span
                    className="dashButtonBorder"
                    id="saveButtonId"
                    title="save"
                    onClick={saveAutoIdGeneration}
                  >
                    Save
                  </span>
                  <span
                    className="dashButtonBorder"
                    style={{ display: "none" }}
                    id="saveButtonId1"
                    title="save"
                  >
                    Save
                  </span>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AutoIdGeneration;
