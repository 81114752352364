import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../images/icons/icon1.png';
import icon2 from '../images/icons/icon2.png';
import icon3 from '../images/icons/icon3.png';
import icon4 from '../images/icons/icon4.png';
import user from '../images/icons/avatar.png';
import Loader from '../Common/loader';
import ImageLoader from 'react-load-image';
import doctor from '../images/media/doctor.jpg';
import Logo from '../images/media/logo.png';
import { callingAPI } from '../config';
import pulseMain from '../images/icons/blankImg.jpg';

// import ProductDefault from "../images/lokalbannerNew1.png"
import girl from '../images/media/blankImg.jpg'
function HomeLeftMenu(props) {
	const [doctorImage, setDoctorImage] = useState('');
	const [doctorName, setDoctorName] = useState('');
	const [LastName, setDoctorLastName] = useState('');

	const [doctorSpecialityDetails, setDoctorSpecialityDetails] = useState([]);
	const [doctorMemberDetails, setDoctorMemberDetails] = useState([]);
	const [load, setLoad] = useState(false);
	var getS3folderHospitalLogo =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH + "/";

	const getDoctorDetails1 = () => {

		const postData = {
			doctorId: window.sessionStorage.getItem("doctorId")
		};
		callingAPI('doctorProfile/getDoctorDetails', postData
		).then((res) => {
			setDoctorMemberDetails(res.data.result.memberDetails)
			setDoctorSpecialityDetails(res.data.result.specialityDetails)
		})
			.catch()
	}
	const practicemanagementMain = () => {
		window.sessionStorage.setItem("clinicsHeader", "headerIconActive");
		window.sessionStorage.setItem("calenderHeader", "active");
		window.sessionStorage.removeItem("homeHeader");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");

	};

	const changepasswordMain = () => {
		window.sessionStorage.removeItem("clinicsHeader", "headerIconActive");
		window.sessionStorage.removeItem("calenderHeader", "active");
		window.sessionStorage.removeItem("homeHeader");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		window.location.href = "/Dashboard/changepassword";
	};
	const profileMain = () => {
		window.sessionStorage.removeItem("homeHeader", "headerIconActive");
		window.sessionStorage.setItem("calenderHeader", "active");
		window.sessionStorage.removeItem("clinicsHeader");
		window.sessionStorage.removeItem("communicationsHeader");
		window.sessionStorage.removeItem("reportsHeader");
		window.sessionStorage.removeItem("appointmentsHeader");
		window.sessionStorage.removeItem("patientsHeader");
		window.sessionStorage.removeItem("doctorHeader");
		window.sessionStorage.removeItem("staffHeader");
		window.sessionStorage.removeItem("addpatientsHeader");
		window.sessionStorage.removeItem("treatmentHeader");
		window.sessionStorage.removeItem("dashboardHeader");
		window.sessionStorage.removeItem("reportHeader");
		window.location.href = "/Dashboard/profile";
	};
	const Preloader = () => {
		return <img src={girl} className="homeUserDiv1"></img>
	}
	useEffect(
		() => {
			getDoctorDetails1()
			if (props.doctorImg !== undefined) {

				setDoctorImage(
					process.env.REACT_APP_S3_BUCKET_URL +
					process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
					process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH +
					'/' +
					window.sessionStorage.getItem('setDoctorImage')
				);
			}

			if (props.doctorName && props.doctorName.doctorFirstName && props.doctorName.doctorLastName !== undefined) {
				setDoctorName(props.doctorName.doctorFirstName);
				setDoctorLastName(props.doctorName.doctorLastName);
				setLoad(true)
			}
		},
		[props.doctorImg]
	);

	return (
		<div>
			<div className="homeLeftmenu">
				{/* {load === false ? <Loader /> : ''} */}
				<div className="homeUserDiv">
					<div className="homeUserDiv1">
						{/* <img src={props.doctorImg !== undefined ? doctorImage : user} alt="" /> */}

						<ImageLoader src={

							window.sessionStorage.getItem('setDoctorImage') == null ? doctor : (getS3folderHospitalLogo + window.sessionStorage.getItem('setDoctorImage'))} >


							<img>

							</img>
							<Preloader />
						</ImageLoader>

						{/* <div> */}
						{/* <img src={doctorImage} alt="" /> */}
						{/* {window.sessionStorage.getItem('setDoctorImage') == null ?<img src={doctor}/>:
										<img src={getS3folderHospitalLogo + window.sessionStorage.getItem('setDoctorImage')} alt="" />} */}
						{/* <img src={getS3folderHospitalLogo===''?pulseMain:getS3folderHospitalLogo + window.sessionStorage.getItem('setDoctorImage')} alt="" /> */}
						{/* </div> */}
					</div>
					<div className="homeUseName">
						{doctorMemberDetails.length > 0 &&
							<span id="userNamedoctor">
								{doctorMemberDetails[0].userTitle + "" + doctorMemberDetails[0].doctorFirstName + " " + doctorMemberDetails[0].doctorLastName}
							</span>}
						<span style={{ fontSize: '14px' }}>
							{window.sessionStorage.getItem('specializationName')}
						</span>
						{/* {doctorSpecialityDetails.length > 0 ? doctorSpecialityDetails.map((item) => (
							<span style={{ fontSize: '14px' }}>
								{item.speciality}
							</span>
						)) : <span></span>}
						{' '} */}

					</div>
				</div>
				<div className="QuickHeadTitle">Quick Links</div>
				<ul className="dashMenu">
					<li>
						<Link to="/Dashboard/profile" onClick={() => profileMain()}>
							<img src={icon3} alt="" />Profile
						</Link>
					</li>
					<li>
						< Link to="/clinics/calendar" onClick={() => practicemanagementMain()}>
							<img src={icon1} alt="" />Practice Management
						</Link>
					</li>
					<li>
						<Link to="/Dashboard/changepassword" onClick={() => changepasswordMain()}>
							<img src={icon2} alt="" />Change Password
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}
export default HomeLeftMenu;
