import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import pulseLoader from '../images/icons/pulseLoader.png';
import { callingAPI } from "../config";
function PrescriptiomMessage() {
  let { siteId, token } = useParams();
  const [pmSiteId, setSiteId] = useState(
    typeof siteId === "undefined" ? "" : (siteId.toString().indexOf('GROUP') != -1 ? siteId.toString().split("-")[1] : siteId)
  );
  const [tokenData, setTokenData] = useState(
    typeof token === "undefined" ? "" : token
  );
  useEffect(() => {


    if (token) {
      decreptURL()
    }
  }, [])
  const decreptURL = () => {
    const apiJson = {
      minifiedId: tokenData
    };
    callingAPI("commonUserFunctions/generateSiteUrlByMinifiedUrl", apiJson).then((data) => {

      if (data.data.success === 1) {
        window.location.href = data.data.siteUrl
      }
    })
  }
  return (
    <div class="fullpage-loader">
      <div class="fullpage-loader__logo">
        <div class="loaderWrap" id="loader" style={{ display: 'block' }}>
          <div class="loaderPurple" />
          <img src={pulseLoader} />
        </div>
      </div>
    </div>
  )
}

export default PrescriptiomMessage