import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import { callingAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import moment from 'moment';
import TeethSection from './teethSection';
import ProceurerightCompletedplan from '../Patients/proceurerightCompletedplan';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import TopArrow from '../images/icons/topArrow.png';
import DownArrow from '../images/icons/downArrow.png';
import Datepicker from 'react-datepicker';
import useStateWithCallback from 'use-state-with-callback';
import Loader from '../Common/loader';
import * as XLSX from 'xlsx';
import $ from 'jquery';
function AddCompletedPlans() {
	$(document).ready(function () {
		$('#addCompletedPlan ').addClass("testResultAdd");

	})
	const changeAmount = () => {
		costAndQuantityCalculation();
	};
	const saveCSVData = () => {
		if (csvfileData.length > 0) {
			saveCSV();
		}
	};
	const [procedureList, setProcedureList] = useState([]);
	const [plannedProcedureList, setPlannedProcedureList] = useState([]);
	const [procedureListBackUp, setprocedureListBackUp] = useState([]);
	const [plannedProcedureListBackUp, setplannedProcedureListBackUp] = useState([]);
	const [procedureSearch, setProcedureSearch] = useState('');
	const [procedureArray, setProcedureArray] = useState([]);
	const [categoryId1, setCategoryId1] = useState('0');
	const [itemName, setItemName] = useState('');
	const [itemQty, setItemQty] = useState('');
	const [completedPlans, setCompletedPlans] = useStateWithCallback([], changeAmount);
	const [totalCost, setTotalCost] = useState('0');
	const [removedIds, setRemovedIds] = useState([]);
	const [totalTax, setTotalTax] = useState(0.00);
	const [grandTotal, setGrandTotal] = useState(0.00);
	const [medicineBranchId, setmedicineBranchId] = useState(window.sessionStorage.getItem('medicineBranchId'));
	const [startDate, setStartDate] = useState('');
	const [hidMemberProcedureDentalDetailsId, setHidMemberProcedureDentalDetailsId] = useState('');
	const [isData, setIsData] = useState(false);
	const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('patientDoctorId'));
	const [doctorName, setDoctor] = useState(window.sessionStorage.getItem('patientDoctorName'));
	const [doctorList, setDoctorList] = useState([]);
	const [csvfileData, setFile] = useStateWithCallback('', saveCSVData);
	const location = useLocation();
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	const [procedureCategoryValues, setProcedureCategoryValues] = useState([]);
	const [procedureCategoryId, setProcedureCategoryId] = useState("");
	const [procedureCategoryIdSearch, setProcedureCategoryIdSearch] = useState("");

	const [procedureData, setProcedureData] = useState({
		procedureName: '',
		procedureCode: '',
		procedureAmount: '',
		chkShowTeeth: 0,
		chkMultipleCost: 0,
		chkConcentForm: 0,
	});
	const setTeethInfo = (data, index) => {
		if (data === 'Multiply Cost') {
			if (completedPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth === 1) {
				completedPlans[index].quantity = 1
				completedPlans[index].hidGrandIndividual = completedPlans[index].treatmentCost
			}
			else {
				completedPlans[index].quantity = completedPlans[index].memberProcedureDentalDetailsTeethNumber.toString().split('|').length
				completedPlans[index].hidGrandIndividual = completedPlans[index].quantity * completedPlans[index].treatmentCost
			}
			completedPlans[index].fullMouth = 0;
			completedPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth = completedPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth === 1 ? 0 : 1;
		} else if (data === 'Full Mouth' || data === 'None Selected') {
			if (data === 'Full Mouth') {
				if (completedPlans[index].fullMouth === 1) {
					var element = document.getElementsByClassName('teethImg' + index);
					for (var i = 0; i < element.length; i++) {
						if (completedPlans[index].fullMouth === 0) {
							element[i].classList.add('teethActive');
						} else {
							element[i].classList.remove('teethActive');
						}
					}
				}
				completedPlans[index].memberProcedureDentalDetailsTeethNumber =
					completedPlans[index].fullMouth === 1 ? 'None Selected' : 'Full Mouth';
				completedPlans[index].fullMouth = completedPlans[index].fullMouth === 1 ? 0 : 1;
				completedPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth = 0;
				completedPlans[index].quantity = 1;
				completedPlans[index].hidGrandIndividual = completedPlans[index].quantity * completedPlans[index].treatmentCost
			} else {
				completedPlans[index].memberProcedureDentalDetailsTeethNumber = data === 'null' ? '' : data;
			}
		} else {
			if (completedPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth === 1) {
				completedPlans[index].quantity = data.toString().split('|').length;
				completedPlans[index].hidGrandIndividual =
					data.toString().split('|').length * completedPlans[index].treatmentCost;
			}
			completedPlans[index].memberProcedureDentalDetailsTeethNumber = data === 'null' ? '' : data;
		}
		setCompletedPlans([...completedPlans]);
		getTaxDetailsWithoutAsync(completedPlans[index].hidProcedureId, (completedPlans[index].quantity * completedPlans[index].treatmentCost), index, completedPlans[index].quantity, completedPlans[index].treatmentCost, completedPlans[index].taxDetails)
	};
	const procedureCategoryChange = (event) => {
		setProcedureCategoryId(event.target.value);
	};
	// useEffect(async () => {
	// 	await procedureListing();
	// 	selectBranchList();
	// 	if (location.state && Object.keys(location.state).length !== 0) {
	// 		const { dentalOrderId, isCompleted } = location.state;
	// 		const postdata = {
	// 			hospitalId: localStorage.getItem('hospitalId'),
	// 			memberId: window.sessionStorage.getItem('memberId'),
	// 			doctorId: window.sessionStorage.getItem('patientDoctorId'),
	// 			memberProcedureDentalDetailsId: dentalOrderId,
	// 			isCompleted: isCompleted,
	// 		};
	// 		callingAPI('treatmentPlanDetails/completedTreatmentListing', postdata).then(async (data) => {
	// 			if (data.data.success === '1') {
	// 				var array = data.data.result[0].itemArray[0].item;
	// 				var date1 = new Date(array[0].dateOn);
	// 				setStartDate(date1);
	// 				var totalCostValue = '0.00';
	// 				var totalGrand = '0.00';
	// 				var taxTotalget = 0.00
	// 				var netTotal = '0.00'
	// 				var dentalDetailsId = '';
	// 				var treatmentArray = [];
	// 				for (var i = 0; array.length > i; i++) {
	// 					var taxDetails = await getTaxDetails(array[i].hidProcedureId, array[i].quantity * array[i].treatmentCost);
	// 					var inventoryDetails = await getInventory(array[i].hidProcedureId);
	// 					if (document.getElementById('planedprocedureId' + array[i].hidMemberProcedureDentalDetailsId) !== null) {
	// 						document.getElementById('planedprocedureId' + array[i].hidMemberProcedureDentalDetailsId).style.display = 'none';
	// 					}
	// 					let fm = 0;
	// 					let mc = array[i].chkMultipleCost;
	// 					if (array[i].hidTeethNumber === 'Full Mouth') {
	// 						fm = 1;
	// 						mc = 0;
	// 					}
	// 					var a = {
	// 						treatmentName: array[i].procedureName,
	// 						treatmentCode: array[i].procedureCode,
	// 						hidProcedureId: array[i].hidProcedureId,
	// 						hidMemberProcedureDentalDetailsId: array[i].hidMemberProcedureDentalDetailsId,
	// 						hidTaxIndividual: array[i].hidTaxIndividual,
	// 						treatmentCost: parseFloat(array[i].treatmentCost).toFixed(2),
	// 						hidDiscountIndividual: '0',
	// 						hidGrandIndividual: (array[i].quantity * array[i].treatmentCost) + array[i].hidTaxIndividual,
	// 						notes: array[i].notes,
	// 						quantity: array[i].quantity,
	// 						memberProcedureDentalDetailsTeethNumber: array[i].hidTeethNumber,
	// 						discountType: 'INR',
	// 						discountAmount: '0',
	// 						memberProcedureDentalDetailsMultipleCostWithTeeth: mc,
	// 						fullMouth: fm,
	// 						isConsentForm: array[i].isConsentForm,
	// 						showTeeth: array[i].showTeeth,
	// 						dateOnShow: new Date(date1),
	// 						dateOn: moment(new Date(date1)).format('yyyy-MM-DD h:mm A'),
	// 						// doctorObj: ((array[i].completedBy === "" || array[i].completedBy === null) ? doctorId : array[i].completedBy),
	// 						doctorObj: isCompleted === false ? array[i].doctorId : array[i].completedBy,
	// 						doctorName: isCompleted === false ? array[i].doctorName : array[i].completedByDoctorName,
	// 						// doctorName: ((array[i].completedByDoctorName === "" || array[i].completedByDoctorName === null) ? doctorName : array[i].completedByDoctorName),
	// 						isPaymentCompleted: array[i].isPaymentCompleted,
	// 						inventoryDetails: inventoryDetails,
	// 						taxDetails: taxDetails.taxDetails,
	// 					};
	// 					treatmentArray.push(a);
	// 					taxTotalget = array[0].hidTaxIndividual
	// 					totalCostValue = +totalCost + +array[0].hidCostTotal;
	// 					totalGrand = +grandTotal + +array[0].treatmentCost;
	// 					netTotal = totalGrand + taxTotalget
	// 					dentalDetailsId = array[0].hidMemberProcedureDentalOrderId;
	// 					setCompletedPlans(treatmentArray);
	// 				}
	// 				setTotalTax(taxTotalget)
	// 				setTotalCost(totalCostValue);
	// 				setGrandTotal(netTotal);
	// 				setHidMemberProcedureDentalDetailsId(dentalDetailsId);
	// 			}
	// 		});
	// 	}
	// }, []);
	useEffect(() => {
		const fetchData = async () => {
			await procedureListing();
			selectBranchList();

			if (location.state && Object.keys(location.state).length !== 0) {
				const { dentalOrderId, isCompleted } = location.state;
				const postdata = {
					hospitalId: localStorage.getItem('hospitalId'),
					memberId: window.sessionStorage.getItem('memberId'),
					doctorId: window.sessionStorage.getItem('patientDoctorId'),
					memberProcedureDentalDetailsId: dentalOrderId,
					isCompleted: isCompleted,
				};

				const data = await callingAPI('treatmentPlanDetails/completedTreatmentListing', postdata);
				if (data.data.success === '1') {
					var array = data.data.result[0].itemArray[0].item;
					var date1 = new Date(array[0].dateOn);
					setStartDate(date1);
					var totalCostValue = '0.00';
					var totalGrand = '0.00';
					var taxTotalget = 0.00;
					var netTotal = '0.00';
					var dentalDetailsId = '';
					var treatmentArray = [];
					for (var i = 0; array.length > i; i++) {
						var taxDetails = await getTaxDetails(array[i].hidProcedureId, array[i].quantity * array[i].treatmentCost);
						var inventoryDetails = await getInventory(array[i].hidProcedureId);
						if (document.getElementById('planedprocedureId' + array[i].hidMemberProcedureDentalDetailsId) !== null) {
							document.getElementById('planedprocedureId' + array[i].hidMemberProcedureDentalDetailsId).style.display = 'none';
						}
						let fm = 0;
						let mc = array[i].chkMultipleCost;
						if (array[i].hidTeethNumber === 'Full Mouth') {
							fm = 1;
							mc = 0;
						}
						var a = {
							treatmentName: array[i].procedureName,
							treatmentCode: array[i].procedureCode,
							hidProcedureId: array[i].hidProcedureId,
							hidMemberProcedureDentalDetailsId: array[i].hidMemberProcedureDentalDetailsId,
							hidTaxIndividual: array[i].hidTaxIndividual,
							treatmentCost: parseFloat(array[i].treatmentCost).toFixed(2),
							hidDiscountIndividual: '0',
							hidGrandIndividual: (array[i].quantity * array[i].treatmentCost) + array[i].hidTaxIndividual,
							notes: array[i].notes,
							quantity: array[i].quantity,
							memberProcedureDentalDetailsTeethNumber: array[i].hidTeethNumber,
							discountType: 'INR',
							discountAmount: '0',
							memberProcedureDentalDetailsMultipleCostWithTeeth: mc,
							fullMouth: fm,
							isConsentForm: array[i].isConsentForm,
							showTeeth: array[i].showTeeth,
							dateOnShow: new Date(date1),
							dateOn: moment(new Date(date1)).format('yyyy-MM-DD h:mm A'),
							doctorObj: isCompleted === false ? array[i].doctorId : array[i].completedBy,
							doctorName: isCompleted === false ? array[i].doctorName : array[i].completedByDoctorName,
							isPaymentCompleted: array[i].isPaymentCompleted,
							inventoryDetails: inventoryDetails,
							taxDetails: taxDetails.taxDetails,
						};
						treatmentArray.push(a);
						taxTotalget = array[0].hidTaxIndividual;
						totalCostValue = +totalCost + +array[0].hidCostTotal;
						totalGrand = +grandTotal + +array[0].treatmentCost;
						netTotal = totalGrand + taxTotalget;
						dentalDetailsId = array[0].hidMemberProcedureDentalOrderId;
						setCompletedPlans(treatmentArray);
					}
					setTotalTax(taxTotalget);
					setTotalCost(totalCostValue);
					setGrandTotal(netTotal);
					setHidMemberProcedureDentalDetailsId(dentalDetailsId);
				}
			}
		};

		fetchData();
	}, []);

	const selectBranchList = () => {
		const PostData = {};
		callingAPI('commonUserFunctions/getTreatmentCategoryList', PostData)
			.then((data) => {
				if (data.data.success === '1') {

					setProcedureCategoryValues(data.data.result.categoryListArray);
				}
				else {
					setProcedureCategoryValues([]);
				}

			})
	}
	const procedureListing = (procedureSearch) => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			searchValue: procedureSearch === 'undefined' ? '' : procedureSearch,
			tabValue: 'Procedures',
			mode: 'completedPlan',
			medicineBranchId: window.sessionStorage.getItem("siteBranchId") === "4" ? (window.sessionStorage.getItem('medicineBranchId')) : "",
		};
		callingAPI('treatmentPlanDetails/procedureListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setProcedureList(data.data.result.resultData);
				setprocedureListBackUp(data.data.result.resultData);
				var plannedQueryData = data.data.result.plannedQueryData.filter((obj) => obj.isCompleted === 0);
				setPlannedProcedureList(plannedQueryData);
				setplannedProcedureListBackUp(plannedQueryData);
				setIsData(true);
			} else {
				setIsData(true);
				var plannedQueryData = data.data.result.plannedQueryData.filter((obj) => obj.isCompleted === 0);
				setPlannedProcedureList(plannedQueryData);
				setplannedProcedureListBackUp(plannedQueryData);
				setProcedureList(data.data.result.resultData);
				setprocedureListBackUp(data.data.result.resultData);
			}
		});
	};
	// const searchProcedure = (event) => {
	// 	const { name, value } = event.target;
	// 	setProcedureSearch(event.target.value);
	// 	var proArray = [...procedureListBackUp]
	// 	var proArrayPlanned = [...plannedProcedureListBackUp]
	// 	var filteredData = []
	// 	var filteredDataPlanned = []
	// 	if (event.target.value !== "") {
	// 		filteredData = proArray.filter((item) => {

	// 			const procedureName = Object.values(item?.procedureName).join('').toLowerCase();
	// 			const procedureCode = Object.values(item?.procedureCode).join('').toLowerCase();
	// 			const branchName = Object.values(item?.pmTreatmentsBranchName ? item?.pmTreatmentsBranchName : "").join('').toLowerCase();
	// 			const searchValue = value.toLowerCase();
	// 			return procedureName.includes(searchValue) || procedureCode.includes(searchValue) || branchName.includes(searchValue);
	// 			// return Object.values(item.procedureName).join('').toLowerCase().includes(event.target.value.toLowerCase()) || Object.values(item.procedureCode).join('').toLowerCase().includes(event.target.value.toLowerCase())
	// 		})
	// 		filteredDataPlanned = proArrayPlanned.filter((item) => {
	// 			return Object.values(item.procedureName).join('').toLowerCase().includes(event.target.value.toLowerCase()) || Object.values(item.procedureCode).join('').toLowerCase().includes(event.target.value.toLowerCase())
	// 		})
	// 		setProcedureList([...filteredData])
	// 		setPlannedProcedureList([...filteredDataPlanned])
	// 	} else {
	// 		setProcedureList([...procedureListBackUp])
	// 		setPlannedProcedureList([...plannedProcedureListBackUp])
	// 	}
	// };
	const procedureCategoryIdSearchChange = (event) => {
		const { value } = event.target;
		setProcedureCategoryIdSearch(value);

		if (value.trim() !== "") {
			// Filter by the selected branch ID
			const filteredData = procedureListBackUp.filter((item) =>
				item.pmTreatmentsBranchId === parseInt(value)
			);
			setProcedureList(filteredData); // Set the filtered list
			setPlannedProcedureList([...filteredData])
		} else {
			setProcedureList([...procedureListBackUp]); // Reset to the full list if no branch is selected
			setPlannedProcedureList([...procedureListBackUp])
		}

		setProcedureSearch(""); // Clear the search text when a new branch is selected
	};
	const searchProcedure = (event) => {
		const { name, value } = event.target;
		setProcedureSearch(value);

		let proArray = [...procedureListBackUp];
		let proArrayPlanned = [...plannedProcedureListBackUp];
		let filteredData = [];
		let filteredDataPlanned = [];

		// Determine the current list to search within (filtered by branch or the full backup list)
		if (procedureCategoryIdSearch) {
			proArray = proArray.filter(
				(item) => item.pmTreatmentsBranchId === parseInt(procedureCategoryIdSearch)
			);
			proArrayPlanned = proArrayPlanned.filter(
				(item) => item.pmTreatmentsBranchId === parseInt(procedureCategoryIdSearch)
			);
		}

		if (value.trim() !== "") {
			filteredData = proArray.filter((item) => {
				const procedureName = Object.values(item?.procedureName).join('').toLowerCase();
				const procedureCode = Object.values(item?.procedureCode).join('').toLowerCase();
				const branchName = Object.values(item?.pmTreatmentsBranchName || "").join('').toLowerCase();
				const searchValue = value.toLowerCase();

				return procedureName.includes(searchValue) || procedureCode.includes(searchValue) || branchName.includes(searchValue);
			});

			filteredDataPlanned = proArrayPlanned.filter((item) => {
				const procedureName = Object.values(item?.procedureName).join('').toLowerCase();
				const procedureCode = Object.values(item?.procedureCode).join('').toLowerCase();
				const branchName = Object.values(item?.pmTreatmentsBranchName || "").join('').toLowerCase();
				const searchValue = value.toLowerCase();

				return procedureName.includes(searchValue) || procedureCode.includes(searchValue) || branchName.includes(searchValue);
			});

			setProcedureList(filteredData);
			setPlannedProcedureList(filteredDataPlanned);
		} else {
			// Reset to the branch-filtered list or the full list if no search term is provided
			setProcedureList(proArray);
			setPlannedProcedureList(proArrayPlanned);
		}
	};
	const onChange = (event) => {
		const { name, value } = event.target;
		setProcedureData((prevState) => ({ ...prevState, [name]: value }));
	};
	const numberValidation = (e) => {
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (e.target.value === '' || re.test(e.target.value)) {
			setProcedureData((prevState) => ({ ...prevState, procedureAmount: e.target.value }));
		}
	};
	const onChangeCheckBox = (event) => {
		const { name, checked } = event.target;
		setProcedureData((prevState) => ({ ...prevState, [name]: checked }));
	};
	const onChangeCategory = (event, i) => {
		const { name, value } = event.target;
		const values = [...procedureArray];
		values[i] = { ...values[i], [name]: value };
		setProcedureArray(values);
	};
	const onChangeAddNotes = (event, i) => {
		const { name, value } = event.target;
		const values = [...completedPlans];
		values[i] = { ...values[i], [name]: value };
		setCompletedPlans(values);
	};
	const addRow = (e, i) => {
		let flags = true;
		if (categoryId1 === '0') {
			alert('Select Category');
			document.getElementById('categoryId1').focus();
			flags = false;
		} else if (!itemName) {
			alert('Enter Item Name ');
			document.getElementById('itemName').focus();
			flags = false;
		} else if (!itemQty) {
			alert('Enter Item Quantity ');
			document.getElementById('itemQty').focus();
			flags = false;
		}
		var b = []
		let copyAppendValues = [...procedureArray];
		b = copyAppendValues
		var filteredArr = b.filter(f =>
			f.categoryId === document.getElementById('categoryId1').value &&
			f.itemName === document.getElementById('itemName').value
		)
		if (filteredArr.length > 0) {
			alert("Please remove duplicate inventory data")
			setIsData(true);
			flags = false;
		}
		if (flags === true) {
			var a = {
				categoryId: categoryId1,
				itemName: itemName,
				itemQty: itemQty,
			};
			setProcedureArray([...procedureArray, a]);
			setCategoryId1('0');
			setItemName('');
			setItemQty('');
		}
	};
	const removeRow = (e, i) => {
		let copyAppendValues = [...procedureArray];
		copyAppendValues.splice(i, 1);
		setProcedureArray(copyAppendValues);
	};
	const addProcedure = () => {
		document.getElementById('addProNew').style.display = 'block';
		document.getElementById('addPro').style.display = 'none';
		document.getElementById('closePro').style.display = 'block';
		setProcedureData({
			procedureName: '',
			procedureCode: '',
			procedureAmount: '',
			chkShowTeeth: 0,
			chkMultipleCost: 0,
			chkConcentForm: 0,
		});
		setCategoryId1('0');
		setItemName('');
		setItemQty('');
		setProcedureArray([]);
	};
	const closeProcedure = () => {
		document.getElementById('closePro').style.display = 'none';
		document.getElementById('addPro').style.display = 'block';
		document.getElementById('addProNew').style.display = 'none';
	};
	const procedureSave = () => {
		setIsData(false);
		let flag = true;
		let copyAppendValues = [...procedureArray];
		if (!procedureData.procedureName) {
			alert("Procedure Name required")
			document.getElementById('procedureName').focus();
			flag = false;
			setIsData(true);
		}
		else if (!procedureData.procedureCode) {
			alert("Procedure Code required")
			document.getElementById('procedureCode').focus();
			flag = false;
			setIsData(true);
		}
		else if (!procedureData.procedureAmount) {
			alert("Procedure Amount required")
			document.getElementById('procedureAmount').focus();
			flag = false;
			setIsData(true);
		}
		else if (categoryId1 !== '0' && itemName !== '' && itemQty !== '') {
			var b = []
			b = copyAppendValues
			var filteredArr = b.filter(f =>
				f.categoryId === document.getElementById('categoryId1').value &&
				f.itemName === document.getElementById('itemName').value
			)
			if (filteredArr.length > 0) {
				alert("Please remove duplicate inventory data")
				setIsData(true);
				flag = false;
			}
			if (flag === true) {
				var a = {
					categoryId: categoryId1,
					itemName: itemName,
					itemQty: itemQty,
				};
				copyAppendValues.push(a);
				flag = true;
			}
		}
		for (var i = 0; i < procedureList.length; i++) {
			var name = procedureList[i].procedureName
			var code = procedureList[i].procedureCode
			if (procedureData.procedureName === name && procedureData.procedureCode === code) {
				alert("Procedure Already Exist")
				setIsData(true);
				flag = false
				break
			}
		}

		// var b = []
		// var isDuplicate = false;
		// copyAppendValues.map((obj) => {
		// 	b = copyAppendValues
		// 	var filteredArr = b.filter(f =>
		// 		f.categoryId == obj.categoryId &&
		// 		f.itemName == obj.itemName
		// 	)
		// 	if (filteredArr.length > 1) {
		// 		flag = false;
		// 		isDuplicate = true;
		// 	}
		// })
		// if (isDuplicate === true) {
		// 	alert("Please remove duplicate inventory data")
		// 	setIsData(true);
		// }
		if (flag === true) {
			const apiJson = {
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				pmTreatmentsName: procedureData.procedureName,
				procedureCode: procedureData.procedureCode,
				hospitalId: localStorage.getItem('hospitalId'),
				chkShowTeeth: medicineBranchId === '4' ? (procedureData.chkShowTeeth === true ? 1 : 0) : 0,
				chkMultipleCost: medicineBranchId === '4' ? (procedureData.chkMultipleCost1 === true ? 1 : 0) : 0,
				chkConcentForm: medicineBranchId === '4' ? (procedureData.chkConcentForm === true ? 1 : 0) : 0,
				proCost: procedureData.procedureAmount,
				item: copyAppendValues,
				treatmentBranchId: window.sessionStorage.getItem("siteBranchId") === "4" ? procedureCategoryId : ""
			};
			callingAPI('commonUserFunctions/saveProcedureAuto', apiJson).then((data) => {
				setIsData(true);
				if (data.data.success === '1') {
					alert('Procedure added successfully');
					procedureListing();
					document.getElementById('addProNew').style.display = 'none';
					document.getElementById('closePro').style.display = 'none';
					document.getElementById('addPro').style.display = 'block';
				}
			});
		}
	};
	const itemQtyValidation = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			setItemQty(e.target.value);
		} else {
			alert("Only Number accepted")
		}
	};
	const addNewProcedure = async (procedureObj, i, id) => {
		setIsData(false);
		if (id !== '') {
			document.getElementById('planedprocedureId' + id).style.display = 'none';
		}
		setStartDate(new Date());
		var taxDetails = await getTaxDetails(procedureObj.procedureId, ((procedureObj.plannedQuantity ? procedureObj.plannedQuantity : 1) * (procedureObj.plannedAmount ? procedureObj.plannedAmount : procedureObj.price)));
		var inventoryDetails = await getInventory(procedureObj.procedureId);
		var taxTotal = taxDetails.taxAmountTotal;
		var taxTot = taxTotal + totalTax
		setTotalTax(taxTot);
		var totalCostValue = +totalCost + +procedureObj.price;
		var totalGrand = +(+grandTotal + +((procedureObj.plannedQuantity ? procedureObj.plannedQuantity : 1) * (procedureObj.plannedAmount ? procedureObj.plannedAmount : procedureObj.price))) + +(taxTotal !== 0.00 ? taxTotal : 0.00);
		setTotalCost(totalCostValue);
		setGrandTotal(totalGrand);
		var date1 = moment(new Date()).format('yyyy-MM-DD h:mm A');
		var arrayObj = {
			hidProcedureId: procedureObj.procedureId,
			hidMemberProcedureDentalDetailsId:
				typeof procedureObj.memberProcedureDentalDetailsId === 'undefined' ? '' : procedureObj.memberProcedureDentalDetailsId,
			hidTaxIndividual: (taxTotal !== 0.00 ? taxTotal : 0.00),
			treatmentCost: procedureObj.plannedAmount ? procedureObj.plannedAmount : procedureObj.price,
			hidDiscountIndividual: '0',
			hidGrandIndividual: ((procedureObj.plannedQuantity ? procedureObj.plannedQuantity : 1) * (procedureObj.plannedAmount ? procedureObj.plannedAmount : procedureObj.price)) + (taxTotal !== 0.00 ? taxTotal : 0.00),
			notes: procedureObj.defaultNotes,
			quantity: procedureObj.plannedQuantity ? procedureObj.plannedQuantity : '1',
			memberProcedureDentalDetailsTeethNumber: 'None Selected',
			discountType: 'INR',
			discountAmount: '0',
			memberProcedureDentalDetailsMultipleCostWithTeeth: procedureObj.multiplyCostWithTeeth,
			treatmentName: procedureObj.procedureName,
			treatmentCode: procedureObj.procedureCode,
			isConsentForm: procedureObj.isConsentForm,
			showTeeth: procedureObj.showTeeth,
			fullMouth: 0,
			dateOnShow: new Date(date1),
			dateOn: moment(new Date(date1)).format('yyyy-MM-DD h:mm A'),
			doctorObj: doctorId,
			doctorName: doctorName,
			isPaymentCompleted: '0',
			inventoryDetails: inventoryDetails,
			taxDetails: taxDetails.taxDetails
		};
		setCompletedPlans([...completedPlans, arrayObj]);
		setIsData(true);
	};
	const getInventory = async (id) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				pmHospitalTreatmentDetailsId: id,
			};
			callingAPI('commonUserFunctions/getInventoryDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					resolve(data.data.result);
				} else {
					resolve([]);
				}
			});
		});
	};
	const getTaxDetails = async (id, amount) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				"procedureId": id,
				"amount": amount
			};
			callingAPI('commonUserFunctions/getTaxDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					var a = {
						taxDetails: data.data.result.taxDetails,
						taxAmountTotal: data.data.result.taxAmountTotal
					}
					resolve(a);
				} else {
					resolve([]);
				}
			});
		});
	};



	const removeCompletedPlan = (i, obj, id) => {
		document.getElementById('lblMessage').style.display = 'inline-block';
		let removed = [...completedPlans];
		removed.splice(i, 1);
		setCompletedPlans(removed);
		if (id) {
			if (document.getElementById(id) !== null) {
				document.getElementById(id).style.display = 'block';
			}
		}
		var taxTot = +totalTax + -obj.hidTaxIndividual;
		var cost = +totalCost + -obj.treatmentCost;
		var grand = +grandTotal + -obj.hidGrandIndividual;
		var totGrand = grand - obj.hidTaxIndividual
		setTotalTax(taxTot);
		setTotalCost(cost);
		setGrandTotal(totGrand);
		removeMessage();
		removedIds.push(obj.hidMemberProcedureDentalDetailsId)
	};
	const removeMessage = () => {
		setTimeout(() => {
			if (document.getElementById('lblMessage') !== null) {
				document.getElementById('lblMessage').style.display = 'none';
			}
		}, 2000);
	};
	const onChangeQuantity = async (event, j) => {
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		const { name, value } = event.target;
		const values = [...completedPlans];
		let currentQty = 0;
		let currentCost = 0;
		if (event.target.value === '' || re.test(event.target.value)) {
			if (name === 'quantity') {
				currentQty = value;
				currentCost = values[j].treatmentCost;
			} else {
				currentQty = values[j].quantity;
				currentCost = value;
			}
			values[j] = {
				...values[j],
				[name]: value,
				hidGrandIndividual: (currentCost * currentQty),
				quantity: currentQty,
				treatmentCost: currentCost,
			};
		} else {
			if (name === 'quantity') {
				currentQty = 1;
				currentCost = values[j].treatmentCost;
			} else {
				alert('Only Numbers Accepted')
				currentQty = values[j].quantity;
				currentCost = values[j].treatmentCost;
			}
			values[j] = {
				...values[j],
				[name]: value,
				hidGrandIndividual: (currentCost * currentQty),
				quantity: currentQty,
				treatmentCost: currentCost,
			};
		}
		setCompletedPlans(values);
		getTaxDetailsWithoutAsync(values[j].hidProcedureId, (currentQty * currentCost), j, currentQty, currentCost, values[j].taxDetails)
	};
	const getTaxDetailsWithoutAsync = (id, amount, index, currentQty, currentCost, taxDetails) => {
		const values = [...completedPlans];
		let taxamount = 0
		let taxName = ""
		let taxAmountData = 0
		let taxAmountTot = 0
		var taxDetail = []
		for (var j = 0; j < taxDetails.length; j++) {
			taxamount = (parseFloat(amount) / 100) * (parseFloat(taxDetails[j].taxPercentage))
			taxName = taxDetails[j].taxName
			taxAmountData = parseFloat(taxamount)
			taxAmountTot = parseFloat(taxAmountTot) + parseFloat(taxamount)
			let taxData = {
				taxName: taxName,
				taxAmountData: taxAmountData,
				taxPercentage: taxDetails[j].taxPercentage
			}
			taxDetail.push(taxData)
		}
		taxDetails.push(taxDetail)
		values[index] = {
			...values[index],
			taxDetails: taxDetail,
			hidGrandIndividual: amount + taxAmountTot,
			hidTaxIndividual: taxAmountTot,
			quantity: currentQty,
			treatmentCost: currentCost,

		};
		setCompletedPlans([...values]);


	};
	const costAndQuantityCalculation = () => {
		var totalCost1 = 0;
		var totalTax1 = 0.00
		var grand = 0.00
		for (var i = 0; i < completedPlans.length; i++) {
			var quantity = completedPlans[i].quantity;
			var cost = completedPlans[i].treatmentCost;
			var tax = completedPlans[i].hidTaxIndividual
			setTotalTax(tax)
			totalCost1 = totalCost1 + (quantity * cost);
			totalTax1 = totalTax1 + tax
			grand = totalCost1 + totalTax1;
			if (
				completedPlans[i].showTeeth === 1 &&
				completedPlans[i].memberProcedureDentalDetailsTeethNumber !== '' &&
				completedPlans[i].memberProcedureDentalDetailsTeethNumber !== 'None Selected' &&
				completedPlans[i].memberProcedureDentalDetailsTeethNumber !== 'Full Mouth'
			) {
				showTeethListing(i, '1');
				var teethData = completedPlans[i].memberProcedureDentalDetailsTeethNumber.toString().split('|');
				for (let index = 0; index < teethData.length; index++) {
					var element = document.getElementsByClassName('teethImg' + teethData[index] + i);
					if (element.length > 0) {
						element[0].classList.add('teethActive');
					}
				}
			} else if (completedPlans[i].memberProcedureDentalDetailsTeethNumber === 'Full Mouth') {
				showTeethListing(i, '1');
				var element = document.getElementsByClassName('teethImg' + i);
				for (let index = 0; index < element.length; index++) {
					element[index].classList.add('teethActive');
				}
			}
			if (completedPlans[i].notes !== '') {
				document.getElementById('addNote' + i).style.display = 'block';
				document.getElementById('addNoteLabel' + i).style.display = 'none';
			}
		}
		setTotalTax(totalTax1);
		setTotalCost(totalCost1);
		setGrandTotal(grand);
	};
	const addNotes = (i) => {
		document.getElementById('addNote' + i).style.display = 'block';
		document.getElementById('addNoteLabel' + i).style.display = 'none';
		document.getElementById('addNote' + i).focus()
	};
	const showTeethListing = (i, mode) => {
		if (mode !== '1') {
			document.getElementById('showTeethDiv' + i).style.display =
				document.getElementById('showTeethDiv' + i).style.display === 'block' ? 'none' : 'block';
		}
		document.getElementById('showTeeth' + i).style.display = 'none';
		document.getElementById('txtTeeth' + i).style.display = 'block';
	};
	const completedPlanSave = () => {
		setIsData(false);
		var date = moment(startDate).format('yyyy-MM-DD h:mm A');
		var milliStart = moment(startDate).valueOf();
		var milliEnd = moment(startDate).add(30, 'm').format('yyyy-MM-DD h:mm A');
		const apiJson = {
			userId: window.sessionStorage.getItem('userId'),
			hidhospitalId: localStorage.getItem('hospitalId'),
			hidMemberId: window.sessionStorage.getItem('memberId'),
			hidDoctorId: window.sessionStorage.getItem('patientDoctorId'),
			prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,

			scheduledtimeSlotStartTime: milliStart,
			scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
			appDate: date,
			hidMemberProcedureDentalOrderId: hidMemberProcedureDentalDetailsId,
			hidTaxTotal: totalTax,
			hidCostTotal: totalCost,
			hidDiscountTotal: '0',
			hidGrandTotal: grandTotal,
			hidTreatmentDetails: completedPlans,
			hidRemoveIds: removedIds.toString().replace(/[\[\]']+/g, '')
		};
		callingAPI('commonUserFunctions/completedPlanSaveNew', apiJson).then((data) => {
			if (data.data.success === '1') {
				if (hidMemberProcedureDentalDetailsId === "") {
					alert('Procedure completed successfully');
				} else {
					alert('Procedure updated Successfully');
				}
				window.location.href = '/patients/completedPlans';
			} else {
				alert(data.data.errorMessage);
				setIsData(true);
			}
		});
	};
	const doctorDropdown = (i) => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			selectCombo: '',
			inputSearch: '',
			max: 100,
			offset: 0,
		};
		document.getElementById('doctorListDiv' + i).style.display = 'block';
		callingAPI('commonUserFunctions/doctorsList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setDoctorList(data.data.result.doctorsListArray);
			}
			else {
				alert("no doctors found")
			}
		});
	};
	const setDoctorName = (doctorObj, index) => {
		completedPlans[index].doctorObj = doctorObj.doctorId;
		completedPlans[index].doctorName = doctorObj.doctorName;
		setCompletedPlans([...completedPlans]);
		document.getElementById('doctorListDiv' + index).style.display = 'none';
	};
	const setDate = (data, index) => {
		completedPlans[index].dateOn = moment(new Date(data)).format('yyyy-MM-DD h:mm A');
		completedPlans[index].dateOnShow = data;
		setCompletedPlans([...completedPlans]);
	};
	const uploadProcedureCSV = (e) => {
		document.getElementById('uploadProcedureCSVFile').click();
	};
	const uploadProcedureCSVFile = (e) => {
		setIsData(false);
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);
				if (typeof workbook.Sheets[sheetName].A1 !== "undefined" && typeof workbook.Sheets[sheetName].B1 !== "undefined" && typeof workbook.Sheets[sheetName].C1 !== "undefined") {
					if (workbook.Sheets[sheetName].A1.v !== "ProcedureName" || workbook.Sheets[sheetName].B1.v !== "ProcedureCode" || workbook.Sheets[sheetName].C1.v !== "Amount") {
						alert("Please upload a valid CSV file.")
						document.getElementById("uploadProcedureCSVFile").value = ""
						setIsData(true);
					} else if (json.length > 0) {
						setFile(json);
					} else {
						alert("Procedure Name required")
						setIsData(true);
					}
				} else {
					alert("Please upload a valid CSV file.")
					document.getElementById("uploadProcedureCSVFile").value = ""
					setIsData(true);
				}
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};
	const saveCSV = () => {
		var saveformat = [];
		for (var i = 0; i < csvfileData.length; i++) {
			if (csvfileData[i].ProcedureName !== undefined) {
				var CsvsaveForm = {
					pmTreatmentsMedicalSpeciality: medicineBranchId === '4' ? 'Dentistry' : 'Other',
					pmHospitalTreatmentDetailsProcedureCode: csvfileData[i].ProcedureCode === undefined ? "" : csvfileData[i].ProcedureCode.toString(),
					pmTreatmentsName: csvfileData[i].ProcedureName,
					pmHospitalTreatmentDetailsPrice: csvfileData[i].Amount,
					pmHospitalTreatmentDetailsMultiplyCostWithTeeth: false,
					pmHospitalTreatmentDetailsShowTeeth: false,
					pmHospitalTreatmentDetailsConsentForm: false,
					pmHospitalTreatmentDetailsDefaultNotes: '',
					taxDetails: '',
					categoryData: [],
				};
				saveformat.push(CsvsaveForm);
			}
		}
		if (csvfileData.length !== '') {
			let PostData = {
				hospitalId: localStorage.getItem('hospitalId'),
				procedureList: saveformat,
			};
			callingAPI('commonUserFunctions/procedureBulkUpload', PostData).then((response) => {
				if (response.data.success === '1') {
					alert('File successfully uploaded');
					window.location.reload()
				} else {
					alert("The file was not uploaded successfully")
					window.location.reload()
					setIsData(true);
				}
			});
		}
	};
	const showInventory = (i, divId) => {
		document.getElementById('inventoryListDiv' + i).style.display =
			document.getElementById('inventoryListDiv' + i).style.display === 'block' ? 'none' : 'block';
		droplist(divId, i)
	};
	const showTaxDetails = (i, divId) => {
		document.getElementById("taxListDiv" + i).style.display =
			document.getElementById("taxListDiv" + i).style.display === 'block' ? 'none' : 'block';
		droplist(divId, i)
	}
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none'
				}
			}
		});
	}
	function closeNav() {
		//	var element = document.getElementsByClassName("doctorListDiv");
		// for (let index = 0; index < completedPlans.length; index++) {
		// 	if (document.getElementById('doctorListDiv'+index) !== null) {
		// 		document.getElementById('doctorListDiv'+index).style.display = 'none';
		// 	}
		// }
	}

	document.onclick = function (e) {
		if (e.target.offsetParent !== null) {
			if (e.target.offsetParent.class !== 'treatmentGrandBy') {
				closeNav();
			}
		}
	};
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll add_treatment_plan aDDTreat addCmpltdln">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-md-8 practiceLeftSection pl_addTreatSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour" style={{ border: "1px solid #ddd", borderTop: "none" }}>
									<div id="divPatientContent" class="sideMenuContent" style={{ border: "none" }}>
										{isData === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead" style={{ border: 'none', padding: "13px 10px 2px" }}>
												<span class="treatmentPlanName">COMPLETED PLANS</span>
												<label id="lblMessage" style={{ color: 'red', display: 'none' }}>
													Successfully removed from your treatment plans
												</label>
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}
												{completedPlans.length > 0 ? (
													<span
														class="treatmentPlanBtn pl_blue_btn"
														style={{ background: '#5bb75b', padding: '4px 15px' }}
														onClick={completedPlanSave}
													>
														Save Completed Plans
													</span>
												) : (
													''
												)}
												<a href="/patients/completedPlans">
													<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
														Cancel
													</span>
												</a>
											</div>

											<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
												<div class="pl_treat treatmentTable planActive">
													<table style={{ border: 'none' }}>
														<thead>
															<tr className="addTreatmentTr">
																<th class="col-md-3">
																	<div class="dentalProName">TREATMENTS</div>
																</th>
																<th class="col-md-4 " style={{ display: 'flex' }}>
																	<div
																		class="dentalProCost"
																		style={{ paddingRight: '0px', paddingLeft: "47px", textAlign: "left" }}
																	>
																		QTY{' '}
																	</div>
																	<div
																		class="dentalProDis"
																		style={{ paddingLeft: '0px', marginLeft: "37px" }}
																	>
																		x&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;COST
																	</div>
																</th>
																<th class="col-md-1" />

																<th class="col-md-2">
																	<div class="dentalProTotal" style={{ paddingRight: "28px" }}>TAX (INR)</div>
																</th>
																<th class="col-md-3  ">
																	<div
																		class="dentalProNote"
																		style={{ textAlign: 'right' }}
																	>
																		TOTAL(INR)
																	</div>
																</th>
															</tr>
														</thead>
														{completedPlans.length > 0 ? (
															completedPlans.map((completeObj, a) => (
																<tbody
																	style={{ width: '100%', display: 'block', borderBottom: '1px solid #cecece' }}
																	className={
																		completeObj.isPaymentCompleted === '1' ? (
																			'treatmentSectionDisable'
																		) : (
																			''
																		)
																	}
																>
																	<span
																		className="close_row"
																		title="Remove"
																		onClick={(e) =>
																			removeCompletedPlan(
																				a,
																				completeObj,
																				'planedprocedureId' +
																				completeObj.hidMemberProcedureDentalDetailsId
																			)}
																	>
																		x
																	</span>
																	<tr class="groupBrdr">
																		<td class="col-md-3">
																			<div
																				class="dentalProName treatmntName"
																				style={{
																					padding: '0px 7px',
																					color: '#000',
																				}}
																			>
																				<span
																					class="elipsis" style={{ width: "98%", maxWidth: "190px" }}
																					title={completeObj.treatmentName}
																				>
																					<b>{completeObj.treatmentName}</b>
																				</span>
																				<span
																					class="elipsis"
																					title={completeObj.treatmentCode}
																					style={{ fontSize: '12px', width: "98%", maxWidth: "190px" }}
																				>
																					{completeObj.treatmentCode}
																				</span>
																			</div>
																		</td>
																		<td class="col-md-2">
																			<div class="treatmentPrice addCompltedPlnQty" style={{ zIndex: "999" }}>
																				<div class="treatmentPriceTxt addCompltdtreatmentPriceTxt">
																					<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																						<input
																							type="number"
																							min="1"
																							name="quantity"
																							value={completeObj.quantity}
																							id={'txtQuantity' + a}
																							class="quantity"
																							onChange={(e) =>
																								onChangeQuantity(e, a)}
																							onKeyDown={(event) => {
																								event.preventDefault();
																							}}
																						// onKeyUp={(e) =>
																						// 	costAndQuantityCalculation(
																						// 		a
																						// 	)}
																						//onKeyPressCapture={(e) => costAndQuantityCalculation(a)}
																						/>
																					</span>{' '}
																				</div>
																				<div class="treatmentPriceCross">
																					&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;
																				</div>
																				<div class="treatmentCostTxt addCompltdtreatmentCostTxt">
																					<input
																						type="text"
																						onChange={(e) =>
																							onChangeQuantity(e, a)}
																						autocomplete="off"
																						id={'treatmentCost' + a}
																						maxlength="10"
																						name="treatmentCost"
																						value={
																							completeObj.treatmentCost
																						}
																					/>
																				</div>
																			</div>

																			<div
																				className="treatmentTeethShow"
																				style={{
																					display:
																						completeObj.showTeeth === 0
																							? 'none'
																							: 'block',
																				}}
																			>
																				<span
																					id={'showTeeth' + a}
																					onClick={() => showTeethListing(a)}
																				>
																					Show Teeth
																				</span>
																				<span
																					id={'txtTeeth' + a}
																					style={{ display: 'none', width: '135px' }}
																					onClick={() => showTeethListing(a)}
																				// onClick={() =>
																				// 	setShowTeeth(!showTeeth)}
																				>
																					Teeth :&nbsp;
																					{
																						completeObj.memberProcedureDentalDetailsTeethNumber
																					}
																				</span>
																			</div>
																		</td>
																		<td class="col-md-3 pull-right">


																			{completeObj.inventoryDetails.length >
																				0 && (
																					<div className='inventryOuter' style={{ textAlign: "right", paddingRight: "10px", marginTop: "-7px" }}>
																						<span
																							onClick={() => showInventory(a, "inventoryListDiv")}
																						>
																							<a style={{ color: "#00abff", cursor: "pointer" }}>Inventory Details</a>
																						</span>
																						<ul
																							class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																							id={'inventoryListDiv' + a}
																							style={{ display: 'none' }}
																						>
																							{completeObj.inventoryDetails.map(
																								(inventoryObj, inv) => (
																									<li>
																										<span className="inventoryInfoWrap">
																											<span>{inventoryObj.medicine_name === '' ? inventoryObj.item_name : inventoryObj.medicine_name}{' '}</span>
																											<span>{inventoryObj.item_qty}</span>
																										</span>
																									</li>
																								)
																							)}
																						</ul>
																					</div>
																				)}
																			<a
																				target="new"
																				href={
																					'/patients/consentFormInvoice/' +
																					localStorage.getItem(
																						'hospitalId'
																					) +
																					'/' +
																					window.sessionStorage.getItem(
																						'memberId'
																					) +
																					'/' +
																					window.sessionStorage.getItem(
																						'patientDoctorId'
																					)
																				}
																				style={{
																					textAlign: 'right',
																					width: '100%',
																					float: 'left',
																					color: '#00abff',
																					cursor: 'pointer', paddingRight: "32px",
																					display:
																						completeObj.isConsentForm === 0
																							? 'none'
																							: 'block',
																				}}
																			>
																				Consent Form
																			</a>
																		</td>
																		<td class="col-md-1">
																			<div
																				class="dentalProDis taxAddTreat"
																				style={{
																					textAlign: 'left',
																					paddingLeft: '0px',
																					color: '#008000',
																				}}
																			>
																				{parseFloat(
																					completeObj.hidTaxIndividual
																				).toFixed(2)}
																			</div>

																			{/*  */}

																			{completeObj.taxDetails.length >
																				0 && (
																					<div className='inventryOuter addCompltedPlaninventryOuter' style={{ textAlign: "left" }}>
																						<span
																							onClick={() => showTaxDetails(a, "taxListDiv")}
																						>
																							<a style={{ color: "#00abff", cursor: "pointer", fontSize: "12px" }}>Tax Details</a>
																						</span>
																						<ul
																							class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																							id={"taxListDiv" + a}
																							style={{ display: 'none' }}
																						>
																							{completeObj.taxDetails.map(
																								(taxObj, inv) => (
																									<li>
																										<span className="inventoryInfoWrap">
																											<span>{taxObj.taxName}{' '}</span>
																											<span>{parseFloat(taxObj.taxAmountData).toFixed(2)}</span>
																										</span>
																									</li>
																								)
																							)}
																						</ul>
																					</div>
																				)}
																			{/*  */}
																		</td>

																		<td class="col-md-3">
																			<span
																				className="totalinr taxAddTreat"
																				style={{ color: '#008000' }}
																				id={'grandTotal' + a}
																				name={'grandTotal' + a}
																				value={parseFloat(
																					completeObj.hidGrandIndividual
																				).toFixed(2)}
																			>
																				{parseFloat(
																					completeObj.hidGrandIndividual
																				).toFixed(2)}
																			</span>
																		</td>
																	</tr>

																	<tr class="dentalProPlaned">
																		<input
																			type="hidden"
																			name="hidTreatmentIds00"
																			id="hidTreatmentIds00"
																			value=""
																		/>
																		<td colspan="3">
																			<div
																				class="plannedBy "
																				style={{ padding: '0 7px' }}
																			>
																				<span
																					id={'addNoteLabel' + a}
																					maxlength="250"
																					onClick={() => addNotes(a)}
																					style={{
																						fontWeight: '400',
																						fontSize: '14px', textTransform: "none"
																					}}
																				>
																					+ add notes
																				</span>

																				<input
																					style={{ display: 'none' }}
																					type="text"
																					id={'addNote' + a}
																					name="notes"
																					placeholder="Add your notes"
																					value={completeObj.notes}
																					onChange={(e) =>
																						onChangeAddNotes(e, a)}
																				/>
																			</div>
																			<div
																				className="row total_info"
																				style={{
																					background: 'transparent',
																					padding: '0 10px',
																				}}
																			>
																				<div
																					className="col-md-5"
																					style={{ padding: '0px' }}
																				>
																					{/* ========== Dropdown begins here  =============== */}
																					<span
																						class="treatmentGrandBy"
																						id="doctorListSection"
																						style={{ position: 'absolute' }}
																						onClick={() => droplist("doctorListDiv", a)}
																					>
																						<span>By</span>
																						<span
																							style={{ cursor: "pointer" }}
																							class="treatmentByName elipsis"
																							onClick={() =>
																								doctorDropdown(a)}
																						>
																							&nbsp;{' '}
																							{completeObj.doctorName}
																						</span>
																						<div
																							class="treatmentDrNameList pl_drList"
																							id={'doctorListDiv' + a}
																							style={{ display: 'none' }}
																						>
																							{doctorList.map(
																								(doctorObj, i) => (
																									<ul>
																										<li
																											onClick={(
																												e
																											) =>
																												setDoctorName(
																													doctorObj,
																													a
																												)}
																										>
																											<span>
																												{' '}
																												{doctorObj.doctorName}
																											</span>
																										</li>
																									</ul>
																								)
																							)}
																						</div>
																					</span>
																					{/* =========== Dropdown ends here ============== */}
																					<br />

																					{/* ========= Updated Datepicker begins here ================ */}
																					<span className='taskDate'
																						style={{
																							float: 'left',
																							display: 'flex',
																							cursor: "pointer"
																						}}

																					>
																						On<span style={{ color: '#1b99d5', cursor: "pointer" }} />
																						<Datepicker
																							required
																							style={{ cursor: "pointer" }}
																							id={'completedDateTime' + a}
																							className="Datepicker pa2 completedPlanCalendar  "
																							maxDate={new Date()}
																							dateFormat="dd/MM/yyyy h:mm aa"
																							placeholderText=""
																							calendarClassName="rasta-stripes"
																							selected={
																								completeObj.dateOnShow
																							}
																							showTimeSelect
																							onChange={(date) =>
																								setDate(date, a)}
																						/>
																					</span>

																					{/* ========= Updated Datepicker begins here ================ */}
																				</div>
																			</div>
																		</td>
																	</tr>
																	<TeethSection
																		setTeethInfo={setTeethInfo}
																		index={a}
																		teethObject={completeObj}
																	/>
																</tbody>
															))
														) : (
															<span>
																<span class="treatmentCommonTxt1">
																	<span>
																		{' '}
																		Add Procedure from right, Multiple Procedure(s)
																		can be added
																	</span>
																</span>
															</span>
														)}
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								{completedPlans.length > 0 ? (
									<div className="row total_info AmountInfo">
										<div className="col-md-5">&nbsp;</div>
										<div className="col-md-7">
											<div className="row">
												<div className="col-md-3" style={{ padding: '0px' }}>
													<p>Total Cost INR</p>
													<p>{parseFloat(totalCost).toFixed(2)}</p>
												</div>
												<div
													className="col-md-1"
													style={{ display: 'flex', alignItems: 'end' }}
												>
													<p> &nbsp; +</p>
												</div>
												<div className="col-md-3" style={{ padding: '0px' }}>
													<p>Total Tax INR</p>
													<p>{parseFloat(totalTax).toFixed(2)} </p>
												</div>
												<div
													className="col-md-1"
													style={{ display: 'flex', alignItems: 'end' }}
												>
													<p> &nbsp; =</p>
												</div>
												<div className="col-md-4" style={{ padding: '0px' }}>
													<p> Grand Total INR</p>
													<p>{parseFloat(grandTotal).toFixed(2)}</p>
												</div>
											</div>
										</div>
									</div>
								) : (
									''
								)}
							</div>
							<div className="col-md-4 practiceRightSection">
								<div className="patientright pl_pro">
									<div className="rightTagSection">
										<div className="rightTagList">
											<div className="rightTagTitle">
												Procedures ( in INR )
												<div class="treatmentSideBtn">
													<span
														class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
														id="addPro"
														onClick={addProcedure}
													>
														+ Add
													</span>
													<span
														style={{ display: 'none' }}
														class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
														id="closePro"
														title="Close"
														onClick={closeProcedure}
													>
														x
													</span>
													<span>
														<img
															onClick={(e) => uploadProcedureCSV(e)}
															src={TopArrow}
															title="Browse CSV file ( Columns must be in the format :- Procedure Name | Procedure Code | Amount )"
															class="treatmntPlanCSV_PTour completedPlanCSV_PTour"
														/>
														<input
															style={{ display: 'none' }}
															onChange={(e) => uploadProcedureCSVFile(e)}
															type="file"
															id="uploadProcedureCSVFile"
															name="file1"
															accept=".csv,.xlsx,xls"
															class="upload_upload"
														/>
														<a
															href="https://purplehealthfiles.s3.amazonaws.com/production/pulse/csv/procedure_template.csv"
															download
														>
															<img
																src={DownArrow}
																title="Download Template"
																class="treatmntPlanCSV_PTour completedPlanCSV_PTour"
															/>
														</a>
													</span>
													<span
														class="trtmntPlanBtn"
														style={{ display: 'none' }}
														id="removePro"
														title="Close"
													>
														x
													</span>
												</div>
											</div>
											{/* -===============  (PROCEDURE ADD DESIGN BEGINS HERE )  =============== */}
											<div class="treatmentListNew bg-whitesec mt-3"
												id="addProNew" style={{ display: 'none' }}>
												<div class="treatmentNewAdd completedPlanProFillForm_PTour treatmntPlanProFillForm_PTour">
													<input
														type="text"
														placeholder="Name*"
														id="procedureName"
														name="procedureName"
														class="completedPlanProName_PTour treatmntPlanProName_PTour"
														value={procedureData.procedureName}
														onChange={onChange}
													/>
													<input
														type="text"
														placeholder="Code*"
														id="procedureCode"
														name="procedureCode"
														value={procedureData.procedureCode}
														class="completedPlanProCode_PTour treatmntPlanProCode_PTour"
														onChange={onChange}
													/>
													{/* <input
														type="text"
														placeholder="Code*"
														id="procedureCode"
														name="procedureCode"
														value={procedureData.procedureCode}
														class="completedPlanProCode_PTour treatmntPlanProCode_PTour"
														onChange={onChange}
													/> */}
													<input
														type="text"
														placeholder="Amount*"
														id="procedureAmount"
														name="procedureAmount"
														maxlength="10"
														onChange={(e) => numberValidation(e)}
														value={procedureData.procedureAmount}
														class="completedPlanProAmount_PTour treatmntPlanProAmount_PTour"
													/>
													<span className=''>INR</span>
													{/* <div
														class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour"
														id="savePro"
														onClick={procedureSave}
													>
														Save
													</div> */}
													{medicineBranchId === '4' && (
														<div class="treatmentNewAddchck">
															<input
																type="checkbox"
																id="chkShowTeeth"
																name="chkShowTeeth"
																checked={procedureData.chkShowTeeth}
																onChange={onChangeCheckBox}
															/>
															<label>Show Teeth</label>
															<input
																type="checkbox"
																id="chkMultipleCost1"
																name="chkMultipleCost1"
																checked={procedureData.chkMultipleCost1}
																onChange={onChangeCheckBox}
															/>
															<label>Multiply Cost with Teeth</label>
															<input
																type="checkbox"
																id="chkConcentForm"
																name="chkConcentForm"
																checked={procedureData.chkConcentForm}
																onChange={onChangeCheckBox}
															/>
															<label>Consent Form</label>
														</div>
													)}
													{window.sessionStorage.getItem("siteBranchId") === "4" &&
														<div class="taxDetailWrapper mt-1">
															<div className="row">
																<label class="col-md-4 smalLbl" style={{ paddingLeft: "12px", paddingTop: "4px" }}>Treatment Branch</label>
																<div class="col-md-7 taxMainContentWrap mb-0 pb-0" style={{ paddingLeft: "2px" }}>
																	<div class="taxMainWrap">
																		<select name="procedureCategoryId"
																			id="accountHead"
																			className='inputField'
																			value={procedureCategoryId}
																			onChange={procedureCategoryChange}
																		>
																			<option value="">Select</option>
																			{procedureCategoryValues.map((item, idix) => (
																				<option value={item.id}>
																					{item.branchName}
																				</option>
																			))}
																		</select>

																	</div>
																</div>
															</div>
														</div>
													}

													<div class="taxDetailWrapper mt-1">
														<label class="smalLbl">Material / Items used</label>

														<div class="taxMainContentWrap">
															<div class="taxMainWrap">
																<input
																	type="hidden"
																	name="hndItemCount"
																	id="hndItemCount"
																	value="0"
																/>
																<select
																	class="completedPlanProCatgry_PTour treatmntPlanProCatgry_PTour"
																	id="categoryId1"
																	name="categoryId1"
																	value={categoryId1}
																	onChange={(e) => setCategoryId1(e.target.value)}
																>
																	<option value="0">Select Category*</option>
																	<option value="1">Medicine</option>
																	<option value="2">disposable</option>
																	<option value="3">Others</option>
																	<option value="4">Instruments</option>
																	<option value="5">Materials</option>
																</select>
																<input
																	type="text"
																	placeholder="Material / Item Name*"
																	id="itemName"
																	name="itemName"
																	value={itemName}
																	class="completedPlanProItemName_PTour treatmntPlanProItemName_PTour"
																	onChange={(e) => setItemName(e.target.value)}
																/>
																<input
																	type="text"
																	placeholder="Quantity*"
																	name="itemQty"
																	id="itemQty"
																	value={itemQty}
																	style={{ width: '60px' }}
																	class="completedPlanProQty_PTour treatmntPlanProQty_PTour"
																	onChange={(e) => itemQtyValidation(e)}
																/>

																<span
																	class="trtmntPlanBtn completedPlanProAddCatgry_PTour treatmntPlanProAddCatgry_PTour"
																	onClick={(e) => addRow(e)}
																>
																	+
																</span>

																<input type="hidden" id="txtCategoryId0" />
															</div>
															{procedureArray.map((obj, i) => (
																<div class="taxMainWrap">
																	<input
																		type="hidden"
																		name="hndItemCount"
																		id="hndItemCount"
																		value="0"
																	/>
																	<select
																		class="completedPlanProCatgry_PTour treatmntPlanProCatgry_PTour"
																		id="categoryId"
																		name="categoryId"
																		value={obj.categoryId}
																		onChange={(e) => onChangeCategory(e, i)}
																	>
																		<option value="0">Select Category</option>
																		<option value="1">Medicine</option>
																		<option value="2">disposable</option>
																		<option value="3">Others</option>
																		<option value="4">Instruments</option>
																		<option value="5">Materials</option>
																	</select>
																	<input
																		type="text"
																		placeholder="Material / Item Name"
																		value={obj.itemName}
																		id="itemName"
																		name="itemName"
																		class="completedPlanProItemName_PTour treatmntPlanProItemName_PTour"
																		onChange={(e) => onChangeCategory(e, i)}
																	/>
																	<input
																		type="text"
																		placeholder="Quantity"
																		value={obj.itemQty}
																		name="itemQty"
																		id="itemQty"
																		style={{ width: '60px' }}
																		class="completedPlanProQty_PTour treatmntPlanProQty_PTour"
																		onChange={(e) => onChangeCategory(e, i)}
																	/>

																	<span
																		class="trtmntPlanBtn"
																		onClick={(e) => removeRow(e, i)}
																	>
																		x
																	</span>

																	<input type="hidden" id="txtCategoryId0" />
																</div>
															))}
															<div id="divItem" />
														</div>
													</div>
													<div className="text-right">
														<div
															class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour"
															id="savePro"
															onClick={(e) => procedureSave()}
															style={{ fontWeight: 'normal' }}
														>
															Save
														</div>
														<div
															class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour bg-bluebtn"
															id="closePro"
															title="Close"
															onClick={closeProcedure}
														>
															Cancel
														</div>
													</div>
												</div>
											</div>
											{/* ===============  (PROCEDURE ADD DESIGN ENDS HERE) =============== */}
											{/* 
											<div className="tagListingDiv">
												<input
													type="text"
													className="inputField"
													placeholder="Search"
													value={procedureSearch}
													onChange={(e) => searchProcedure(e)}
												/>
											</div> */}
											<div className="row mt-2">
												{window.sessionStorage.getItem("siteBranchId") === "4" &&
													<div class="taxMainWrap col-md-6 pb-0 mb-0 mt-1">
														<select name="procedureCategoryIdSearch"
															id="accountHead"
															className='inputField'
															value={procedureCategoryIdSearch}
															onChange={procedureCategoryIdSearchChange}
														>
															<option value="">Select Treatment Branch</option>
															{procedureCategoryValues.map((item, idix) => (
																<option value={item.id}>
																	{item.branchName}
																</option>
															))}
														</select>

													</div>
												}
												<div
													className={`tagListingDiv ${window.sessionStorage.getItem("siteBranchId") === "4" ? "col-md-6" : "col-md-12"} pb-0 mb-0 mt-1`}
												>
													<input
														type="text"
														className="inputField w-100"
														placeholder="Search"
														autoComplete='off'
														name="procedureSearch"
														value={procedureSearch}
														onChange={(e) => searchProcedure(e)}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="treatmentNameList treatmentPlanList " style={{ paddingTop: '0px' }}>
										{plannedProcedureList.length > 0 || procedureList.length > 0 ? (
											<ul id="tabProceduresUL">

												{plannedProcedureList.map((plannedObj, l) => (
													<li
														id={
															'planedprocedureId' +
															plannedObj.memberProcedureDentalDetailsId
														}
														onClick={() =>
															addNewProcedure(
																plannedObj,
																l,
																plannedObj.memberProcedureDentalDetailsId
															)}
													>
														<span class="elipsis" title={plannedObj.procedureName}>
															<b>{plannedObj.procedureName}</b>
														</span>

														<span className="pl_planned_status">Planned</span>
														<span
															class="elipsis"
															title={plannedObj.procedureCode}
															style={{ fontSize: '12px' }}
														>
															{plannedObj.procedureCode == "" ? <span>&nbsp;</span> : plannedObj.procedureCode}
														</span>
														{window.sessionStorage.getItem("siteBranchId") === "4" &&
															<span
																class="elipsis"
																title={plannedObj.pmTreatmentsBranchName}
																style={{ fontSize: '10px' }}
															>
																{plannedObj.pmTreatmentsBranchName == "" ? <span>&nbsp;</span> : plannedObj.pmTreatmentsBranchName}
															</span>
														}
														<span class="treatmentNameListRight">
															<span class="treatmentNameListAmt">
																₹{parseFloat(plannedObj.plannedAmount).toFixed(2)}
															</span>
														</span>
													</li>
												))}


												{procedureList.map((procedureObj, i) => (
													<li
														id={'procedureId' + procedureObj.procedureId}
														onClick={() => addNewProcedure(procedureObj, i, '')}
													>
														<span class="elipsis" title={procedureObj.procedureName}>
															<b>{procedureObj.procedureName}</b>
														</span>

														<span
															class="elipsis"
															title={procedureObj.procedureCode}
															style={{ fontSize: '12px' }}
														>
															{procedureObj.procedureCode == "" ? <span>&nbsp;</span> : procedureObj.procedureCode}
														</span>
														{window.sessionStorage.getItem("siteBranchId") === "4" &&
															<span
																class="elipsis"
																title={procedureObj.pmTreatmentsBranchName}
																style={{ fontSize: '10px' }}
															>
																{procedureObj.pmTreatmentsBranchName == "" ? <span>&nbsp;</span> : procedureObj.pmTreatmentsBranchName}
															</span>
														}
														<span class="treatmentNameListRight treatmentAmount-right">
															<span class="treatmentNameListAmt">
																₹{parseFloat(procedureObj.price).toFixed(2)}
															</span>
														</span>
													</li>
												))}
											</ul>
										) : (
											<ul>
												<li>No Treatment Found</li>
											</ul>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AddCompletedPlans;
