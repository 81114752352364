import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
import Datepicker, { CalendarContainer } from "react-datepicker";
import NoCriteria from "../Common/noCriteria";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Common/loader";
function RevenueByDoctor() {
  const [fromDate, setFromDate] = useState();
  // const [ offset, setOffset ] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [toDate, setToDate] = useState();
  const [functionName] = useState("RevenueByDoctorDetails");
  const [grandTotalAmount, setgrandTotalAmount] = useState("");
  const [finalArray, setfinalArray] = useState([]);
  const [count, setCount] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [doctor, setDoctor] = useState([]);
  const [towards, setTowards] = useState("");
  const [load, setLoad] = useState(false);
  // pagination
  const dataPagination = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    setTowards('')
    document.getElementById("selectDoctor").value = ''
    let PostData = {
      hospitalId: localStorage.getItem("hospitalId"),
      cmbDoctor: "",
      cmbTowards: "",
      max: max,
      offset: offset,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    };
    callingAPI("reports/revenueByDoctorNew", PostData)
      .then((data) => {
        if (data.data.success === "1") {
          setLoad(true);
          setfinalArray(data.data.result.finalArray);
          setCount(data.data.result.totalCount);
          setgrandTotalAmount(data.data.result.grandTotalAmountTotal);
        }
      })
      .catch();
  };
  const changeTowards = (event) => {
    setTowards(event.target.value);
  };
  const setReceiptRecptno = (htmlData) => {
    // window.sessionStorage.setItem("htmlData", htmlData);
    var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${htmlData + '|' + window.sessionStorage.getItem("doctorId") + '|' + window.localStorage.getItem('hospitalId')}`
    window.open(a, "_blank")
  };
  const exportFunction = () => {
    if (finalArray.length === 0) {
      alert("No Data to Export");
    }
    else {
      const csvRow = [];
      var invoice = [
        [
          // "No:",
          "Doctor",
          "Towards",
          "Date",
          "Reciept%20Number",
          "Receipt%20Amount",
          "Total%20Amount",
        ],
      ];
      var invoiceArray = finalArray;
      const PostData = {
        hospitalId: localStorage.getItem("hospitalId"),
        cmbDoctor: document.querySelector("#selectDoctor").value,
        cmbTowards: towards,
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
        offset: 0,
        max: "",
      };
      callingAPI("reports/revenueByDoctorNew", PostData)
        .then((data) => {
          for (var item = 0; item < data.data.result.finalArray.length; item++) {
            for (var j = 0; j < data.data.result.finalArray[item].details.length; j++) {
              if (j === 0) {
                invoice.push([
                  data.data.result.finalArray[item].docname.replace(/ /g, '%20'),
                  data.data.result.finalArray[item].details[j].towards.replace(/,/g, '/'),
                  data.data.result.finalArray[item].details[j].receipt_date1.replace(/,/g, '').replace(/ /g, '%20'),
                  data.data.result.finalArray[item].details[j].receipt_no,
                  "Rs." + "" + data.data.result.finalArray[item].details[j].amount.toFixed(2),
                  "Rs." + "" + data.data.result.finalArray[item].amount.toFixed(2),
                ]);
              } else {
                invoice.push([
                  "%20",
                  data.data.result.finalArray[item].details[j].towards.replace(/,/g, '/'),
                  data.data.result.finalArray[item].details[j].receipt_date1.replace(/,/g, '').replace(/ /g, '%20'),
                  data.data.result.finalArray[item].details[j].receipt_no,
                  "Rs." + "" + data.data.result.finalArray[item].details[j].amount.toFixed(2),
                  "%20",
                ]);
              }
            }
          }
          for (var i = 0; i < invoice.length; ++i) {
            csvRow.push(invoice[i].join(","));
          }
          var csvString = csvRow.join("%0A");
          var a = document.createElement("a");
          a.href = "data:attachment/csv," + csvString;
          a.target = "_Blank";
          a.download = functionName + ".csv";
          document.body.appendChild(a);
          a.click();
        })
    }
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  const searchFunction = () => {
    // setLoad(false);
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("End date must be greater than or equal to start date");
        document.getElementById("todate").focus();
        return false;

      }

    }

    setActivePage(1);

    let PostData = {
      hospitalId: localStorage.getItem("hospitalId"),
      cmbDoctor: document.querySelector("#selectDoctor").value,
      cmbTowards: towards,
      max: process.env.REACT_APP_PAGINATION_COUNT,
      // max: 10,
      offset: 0,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
      // fromDate:  '',
      // toDate:  '',
    };
    callingAPI("reports/revenueByDoctorNew", PostData)
      .then((data) => {
        if (data.data.success === "0") {
          setfinalArray([]);
          //   setLoad(true);
        }
        if (data.data.success === "1") {
          //   setLoad(true);
          setfinalArray(data.data.result.finalArray);
          setCount(data.data.result.totalCount);
          setgrandTotalAmount(data.data.result.grandTotalAmountTotal);
        }
      })
      .catch();
  };
  useEffect(() => {
    doctorApiFunction();
    const PostData = {
      hospitalId: localStorage.getItem("hospitalId"),
      // hospitalId:468,
      // cmbDoctor: '',
      // cmbTowards:'',
      offset: pageOffset,
      // offset: 0,
      max: process.env.REACT_APP_PAGINATION_COUNT,
      // max:56,
      fromDate: "",
      toDate: "",
    };
    callingAPI("reports/revenueByDoctorNew", PostData)
      .then((data) => {
        if (data.data.success) {
          setfinalArray(data.data.result.finalArray);
          setCount(data.data.result.totalCount);
          setgrandTotalAmount(data.data.result.grandTotalAmountTotal);
          setLoad(true);
        }
        else { setLoad(true) }
      })
      .catch();
  }, []);
  const doctorApiFunction = () => {
    const PostData = {
      hospitalId: localStorage.getItem("hospitalId"),

    };

    callingAPI("commonUserFunctions/doctorsList", PostData)
      .then((data) => {
        if (data.data.success === "1") {
          setDoctor(data.data.result.doctorsListArray);
        }
        else {
          alert("no doctors found")
        }

      })
      .catch();
  };
  const FromDateClear = () => {
    setFromDate("")
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  const ToDateClear = () => {
    setToDate("")
  };
  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  return (
    <div className="dashboardCover">
      <div className="dashboardTitle">Revenue By Doctor</div>
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch">
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectDoctor"
                  name="selectDoctor"
                >
                  <option value="">Select Doctor</option>
                  {doctor.map((data) => (
                    <option value={data.doctorId}>{data.doctorName}</option>
                  ))}
                </select>
              </div>
              {/* <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  value={towards}
                  onChange={changeTowards}
                >
                  <option selected value="">
                    Select Towards
                  </option>
                  <option value="Procedure">Procedure</option>
                  <option value="Pharmacy">Pharmacy</option>
                  <option value="Laundry">Disposable</option>
                  <option value="Lab">Lab</option>
                </select>
              </div> */}
              <div className="col-md-2 dashboardSearchList">
                {/* <Datepicker
                  required
                  className="Datepicker pa2 inputField"
                  placeholderText="From"
                  calendarClassName="rasta-stripes"
                  selected={fromDate}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => setFromDate(date)}
                /> */}
                <div>
                  <Datepicker
                    required
                    className="Datepicker pa2 inputField"
                    placeholderText="From"
                    calendarClassName="rasta-stripes"
                    selected={fromDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setFromDate(date)}
                    id="fromdate"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                  //   onKeyDown={(e) => {
                  //     e.preventDefault();
                  //  }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                {/* <Datepicker
                  selected={toDate}
                  className="Datepicker pa2 inputField"
                  placeholderText="To"
                  calendarClassName="rasta-stripes"
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => setToDate(date)}
                /> */}
                <div>
                  <Datepicker
                    selected={toDate}
                    className="Datepicker pa2 inputField"
                    placeholderText="To"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setToDate(date)}
                    id="todate"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                  //   onKeyDown={(e) => {
                  //     e.preventDefault();
                  //  }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span className="dashButtonBorder" onClick={searchFunction}>
                  Search
                </span>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  className="dashButtonBorder dashButtonBorderGrey"
                  onClick={exportFunction}
                >
                  Export
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="searchTxt" style={{ textAlign: "right" }}>
                Grand Total{" "}
                <b>
                  : {" "}
                  {grandTotalAmount.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </b>
              </div>
            </div>
          </div>
          <div className="dashboardtable">
            {load == false ? (
              <Loader />
            ) : finalArray.length > 0 ? (
              <table style={{ tableLayout: "auto" }}>
                <thead>
                  <tr>
                    <th>Doctor</th>
                    <th>Towards</th>
                    <th>Date</th>
                    <th>Receipt No</th>
                    <th>Refund Status</th>
                    <th style={{ textAlign: "right" }}>Receipt Amount</th>
                    <th style={{ textAlign: "right" }}>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {finalArray.map((item, index) => (
                    <tr>
                      <td>   {item.docname}</td>
                      {/* {item.subArray.map((data, index) => (
												<div> */}
                      <td>
                        {item.details.map((data, index) => (
                          <div>
                            {/* {data.towards.replace(/,/g, ', ')} */}
                            {data.towards}
                          </div>
                        ))}
                      </td>
                      <td>
                        {item.details.map((data, index) => (
                          <div>{data.receipt_date1}</div>
                        ))}
                      </td>
                      {/* {item.details.map((data, index) => */}
                      <td>
                        {item.details.map((data, index) => (
                          <div>
                            {" "}
                            {data.receipt_no != "" ? (
                              <Link
                                to="#"
                                onClick={() =>
                                  setReceiptRecptno(data.inv_receipt_id)
                                }
                              >
                                {" "}
                                {data.receipt_no}
                              </Link>
                            ) : (
                              ""
                            )}
                            {/* <Link
													to={{
														pathname: '/patients/RecieptPrint/Payment',
														state: { memberProcedurePaymentReceiptId: data.inv_receipt_id  },
													}}
													className="mr-2 tableThumbAction"
												>
													 <div>{data.receipt_no}</div>
												</Link> */}
                          </div>
                        ))}
                      </td>
                      <td>
                        {item.details.map((data, index) => (
                          <div>{data.refundStatus}</div>
                        ))}
                      </td>
                      {/* <td style={{ textAlign: 'right' }}>&#8377; */}
                      <td style={{ textAlign: "right" }}>
                        {item.details.map((data, index) => (
                          <div>
                            {/* {" "}
                            &#8377;{" "}
                            {data.amount
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} */}
                            &#8377;{" "} {data.amount !== "0" ? data.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : data.amount}
                          </div>
                        ))}
                      </td>
                      {/* </div>
	))} */}
                      <td style={{ textAlign: "right" }}>
                        {/* {" "}
                        &#8377;{" "}
                        {item.amount
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} */}
                        &#8377;{" "}{item.amount !== "0" ? item.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : item.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoCriteria />
            )}
          </div>
        </div>
        {count > 10 && (
          <div className="paginationSection">
            <Pagination
              // hideNavigation
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={count}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={dataPagination}
            />
          </div>
        )}
        {/* {finalArray.length == 0 && <NoData />} */}
      </div>
    </div>
  );
}
export default RevenueByDoctor;
