import React from 'react';
import Logo from '../images/media/logo.png';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';

function VitalSignsPrint() {
	const [vitalSignsPrintId, setVitalSignsPrintId] = useState([]);
	let { printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');
	const [memberDetails, setMemberDetails] = useState({});
	const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);

	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);


			});

	}

	const printTreatment = () => {
		const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
			// Hide unchecked sections before printing
		
			if (isChecked4) {
				section4.style.display = 'none';
			}
			if (isChecked5) {
				section51.style.display = 'none';
				section52.style.display = 'none';
			}
			if (isChecked7) {
				section7.style.display = 'none';
			}
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
		section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}


	const vitalSignsPrint = () => {
		let PostData = {
			"memberHealthReportId": window.sessionStorage.getItem('mhrd'),
			"memberId": window.sessionStorage.getItem('memberId'),
			// "memberHealthReportId":517,
			// "memberId":364570

		}
		callingAPI("medicalCertificate/printVitalSigns", PostData).then((response) => {
			if (response.data.success === "1") {

				setVitalSignsPrintId(response.data.result.vitalSignData[0]);
				setMemberDetails(response.data.result.vitalSignData[0])
			}
		});

	};
	useEffect(() => {
		vitalSignsPrint()
	}, []);
	return (
		<div align="center" className='vitalSignsPrint'>
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				{/* {isData === false ? <Loader /> : ''} */}
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">

					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>

											<HeaderPrint hospitalDataId={printId.toString().split('|')[0]} setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5}/>
										</tbody>

										<tbody>


											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}

												>
													Name : {memberDetails.memberDisplayName}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Date : {memberDetails.createdDate}
												</td>
											</tr>

											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Age : {memberDetails.memberAge}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Email Id : {memberDetails.secUserEmailid}
												</td>


											</tr>
											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'left',
													}}
												>
													Sex : {memberDetails.memberGender}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '15px',
														textAlign: 'right',
													}}
												>
													Mobile No : {memberDetails.SecuserMobileNumber}
												</td>

											</tr>
											<tr>
												{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
													(memberDetails.memberMrNUmber &&
														<td
															colspan="12"
															style={{
																width: '100%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>
															MR No : {memberDetails.memberMrNUmber}
														</td>
													)
													:
													(memberDetails.memberPrNUmber &&
														<td
															colspan="12"
															style={{
																width: '100%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'left',
															}}
														>PR No : {memberDetails.memberPrNUmber}</td>
													)
												}
											</tr>
											{/* <tr>
												{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
													(<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														MR No : {memberDetails.memberMrNUmber}
													</td>) :
													(!memberDetails.memberPrNo == "" &&
														<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '15px',
																textAlign: 'right',
															}}
														>
															PR No : {memberDetails.memberPrNUmber}
														</td>
													)}

											</tr> */}


											<tr>
												<td colspan="12">
													<hr
														color="#142536"
														style={{ margin: '0', opacity: '1', height: '2px' }}
													/>
												</td>
											</tr>


										</tbody>

									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<div class="treatmentPlanList" style={{ height: "auto" }}>
							{/* {vitalSignsPrintId.map((item,i) => (    */}
							<div class="treatmentPlanList1">
								<div class="treatmentComplaintWrap" style={{ border: "none" }}>
									{vitalSignsPrintId.createdTime !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Time</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															{/* <div>	{item[i].createdOn}Testt</div> */}
															<div>	{vitalSignsPrintId.createdTime} </div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.weight !== "" &&

										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Weight (Kg)</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.weight}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.height !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Height (Cm)</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.height}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.systolic !== "" || vitalSignsPrintId.diastolic !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Blood Pressure</b><br /><b>SYS/DIA (mmHg)</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.systolic}/{vitalSignsPrintId.diastolic} &nbsp; {vitalSignsPrintId.sitOrStand}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.pulse !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Pulse (bpm)</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.pulse}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.respRate !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Resp.Rate</b><br /><b>(Breaths/Min)</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.respRate}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}{vitalSignsPrintId.temparature !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Temprature (C)</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.temparature}{vitalSignsPrintId.tempUnit}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									<div class="treatmentComplaintRowWrap vitalPrintColon">
										<div class="treatmentComplaintRow">
											<div class="treatmentComplaintLeft"><b>Blood Glucose</b></div>
											{vitalSignsPrintId.fbs !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '20%' }}>
													<span class="treatmentTAgName ">
														<div><b>FBS (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.fbs}</div>
													</span>

												</div>
											}{vitalSignsPrintId.ppbs !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '22%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>PPBS (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.ppbs}</div>
													</span>
												</div>

											}
											{vitalSignsPrintId.rbs !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '20%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>RBS (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.rbs}</div>
													</span>
												</div>
											}
										</div>
									</div>
									<div class="treatmentComplaintRowWrap vitalPrintColon">
										<div class="treatmentComplaintRow">
											<div class="treatmentComplaintLeft"><b>Hemoglobin &</b> <br /><b>Blood Count</b></div>
											{vitalSignsPrintId.hbac !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '20%' }}>
													<span class="treatmentTAgName ">
														<div><b>HbA1c (%)</b></div>
														<div>{vitalSignsPrintId.hbac}</div>
													</span>

												</div>
											}{vitalSignsPrintId.hb !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '22%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>Hb (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.hb}</div>
													</span>
												</div>
											}{vitalSignsPrintId.cbc !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '20%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>CBC</b></div>
														<div>{vitalSignsPrintId.cbc}</div>
													</span>
												</div>
											}
										</div>
									</div>
									<div class="treatmentComplaintRowWrap vitalPrintColon">
										<div class="treatmentComplaintRow">
											<div class="treatmentComplaintLeft"><b>Lipid Profile</b></div>
											{vitalSignsPrintId.cholestrol !== "" &&
												<div class="treatmentComplaintRight treatmentComplaintRightValue" style={{ width: '20%' }}>
													<span class="treatmentTAgName ">
														<div><b>Total cholestrol (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.cholestrol}</div>
													</span>

												</div>
											}{vitalSignsPrintId.triglyceride !== "" &&
												<div class="treatmentComplaintRight treatmentComplaintRightValue" style={{ width: '22%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>Trigyceride (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.triglyceride}</div>
													</span>
												</div>
											}{vitalSignsPrintId.ldl !== "" &&
												<div class="treatmentComplaintRight treatmentComplaintRightValue" style={{ width: '20%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>LDL (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.ldl}</div>
													</span>
												</div>
											}{vitalSignsPrintId.hdl !== "" &&
												<div class="treatmentComplaintRight pul_HDLPrint" style={{ width: '75%', borderLeft: 'none', marginLeft: '25%' }}>
													<span class="treatmentTAgName ">
														<div><b>HDL (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.hdl}</div>
													</span>
												</div>
											}
										</div>
									</div>
									<div class="treatmentComplaintRowWrap vitalPrintColon">
										<div class="treatmentComplaintRow">
											<div class="treatmentComplaintLeft"><b>Urine Analysis</b></div>
											{vitalSignsPrintId.urineAalbumin !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '20%' }}>
													<span class="treatmentTAgName ">
														<div><b>Urine Albumine (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.urineAalbumin}</div>
													</span>

												</div>
											}
											{vitalSignsPrintId.hb !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '22%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>Hb (Mg/dl)</b></div>
														<div>{vitalSignsPrintId.hb}</div>
													</span>
												</div>
											}
											{vitalSignsPrintId.cbc !== "" &&
												<div class="treatmentComplaintRight" style={{ width: '20%', borderLeft: 'none' }}>
													<span class="treatmentTAgName ">
														<div><b>CBC</b></div>
														<div>{vitalSignsPrintId.cbc}</div>
													</span>
												</div>
											}
										</div>
									</div>
									{vitalSignsPrintId.lpt !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>LPT</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.lpt}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.tft !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>TFT</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.tft}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.eyes !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Eyes</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.eyes}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}
									{vitalSignsPrintId.comments !== "" &&
										<div class="treatmentComplaintRowWrap">
											<div class="treatmentComplaintRow">
												<div class="treatmentComplaintLeft"><b>Comments</b></div>
												<div class="treatmentComplaintRight">
													<span>
														<span class="treatmentTAgName ">
															<div>{vitalSignsPrintId.comments}</div>
														</span>
													</span>
												</div>
											</div>
										</div>
									}


									<div class="treatmentComplaintNoted">Noted by <span> {vitalSignsPrintId.doctorName}</span></div>
								</div>
							</div>

							{/* ))}					      */}
						</div>
					</tbody>
					<FooterPrint setIsChecked7={setIsChecked7} isChecked7={isChecked7}/>
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									id="printButton"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
										fontFamily: '  Verdana',
									}}
									onClick={printTreatment}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default VitalSignsPrint;
