import React, { useEffect, useState } from "react";
import { callingAPI } from "../config";
import { Link } from "react-router-dom";
import expo1 from "../images/icons/expo1.jpg";
import expo2 from "../images/icons/expo2.jpg";
import expo3 from "../images/icons/expo3.jpg";
import expo4 from "../images/icons/expo4.jpg";
import expo5 from "../images/icons/expo5.jpg";
import MetalCeramic from "../images/icons/st1.jpg";
import MetalCeramic2 from "../images/icons/st2.png";
import arrowLeft from "../images/icons/arrowLeft.png";
import arrowLeftSmall from "../images/icons/arrowLeftSmall.png";
import T11 from "../images/dental/11.png";
import T11b from "../images/dental/11b.png";
import T12 from "../images/dental/12.png";
import T12b from "../images/dental/12b.png";
import T13 from "../images/dental/13.png";
import T13b from "../images/dental/13b.png";
import T14 from "../images/dental/14.png";
import T14b from "../images/dental/14b.png";
import T15 from "../images/dental/15.png";
import T15b from "../images/dental/15b.png";
import T16 from "../images/dental/16.png";
import T16b from "../images/dental/16b.png";
import T17 from "../images/dental/17.png";
import T17b from "../images/dental/17b.png";
import T18 from "../images/dental/18.png";
import T18b from "../images/dental/18b.png";
import T21 from "../images/dental/21.png";
import T21b from "../images/dental/21b.png";
import T22 from "../images/dental/22.png";
import T22b from "../images/dental/22b.png";
import T23 from "../images/dental/23.png";
import T23b from "../images/dental/23b.png";
import T24 from "../images/dental/24.png";
import T24b from "../images/dental/24b.png";
import T25 from "../images/dental/25.png";
import T25b from "../images/dental/25b.png";
import T26 from "../images/dental/26.png";
import T26b from "../images/dental/26b.png";
import T27 from "../images/dental/27.png";
import T27b from "../images/dental/27b.png";
import T28 from "../images/dental/28.png";
import T28b from "../images/dental/28b.png";
import T31 from "../images/dental/31.png";
import T31b from "../images/dental/31b.png";
import T32 from "../images/dental/32.png";
import T33 from "../images/dental/33.png";
import T34 from "../images/dental/34.png";
import T35 from "../images/dental/35.png";
import T36 from "../images/dental/36.png";
import T37 from "../images/dental/37.png";
import T38 from "../images/dental/38.png";
import T32b from "../images/dental/32b.png";
import T33b from "../images/dental/33b.png";
import T34b from "../images/dental/34b.png";
import T35b from "../images/dental/35b.png";
import T36b from "../images/dental/36b.png";
import T37b from "../images/dental/37b.png";
import T38b from "../images/dental/38b.png";
import T41 from "../images/dental/41.png";
import T42 from "../images/dental/42.png";
import T43 from "../images/dental/43.png";
import T44 from "../images/dental/44.png";
import T45 from "../images/dental/45.png";
import T46 from "../images/dental/46.png";
import T47 from "../images/dental/47.png";
import T48 from "../images/dental/48.png";
import Logo from "../images/media/logo.png";
import T41b from "../images/dental/41b.png";
import T42b from "../images/dental/42b.png";
import T43b from "../images/dental/43b.png";
import T44b from "../images/dental/44b.png";
import T45b from "../images/dental/45b.png";
import T46b from "../images/dental/46b.png";
import T47b from "../images/dental/47b.png";
import T48b from "../images/dental/48b.png";
import Sanitary from "../images/icons/mc1.jpg";
import Sanitary1 from "../images/icons/mc2.jpg";
import FullRidge from "../images/icons/mc3.jpg";
import Modified from "../images/icons/mc4.jpg";
import Bullet from "../images/icons/mc5.jpg";
import Ovate from "../images/icons/mc6.png";
import Datepicker from "react-datepicker";
import moment from "moment";
import Loader from "../Common/loader";
function LabOrderMetalCeramic() {
  const [state, setState] = useState({
    labTestRequestRequiredDate: "",
    labTestRequestOrderDate: "",
    labTestRedDate: "",
    labTestWhiteDate: "",
    labTestMetalDate: "",
    labTestBisqueDate: "",
  });
  const [isData, setIsData] = useState(false);
  const [flag, setflag] = useState(false);
  const [saveflag, setSaveflag] = useState(false);
  const [hospitalData, sethospitalData] = useState([]);
  const [labdetails, setLabdetails] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  const [patientAge, setPatientAge] = useState("");
  const [teethArray, setTeethArray] = useState([]);
  const [labFormDetailsIdarray, setLabFormDetailsIdarray] = useState([]);
  const [labTestRequestDetails, setLabTestRequestDetails] = useState("");
  const [a1, seta1] = useState("");
  const [b1, setb1] = useState("");
  const [c1, setc1] = useState("");
  const [d1, setd1] = useState("");
  const [e1, sete1] = useState("");
  const [f1, setf1] = useState("");
  const [g1, setg1] = useState("");
  const [h1, seth1] = useState("");
  const [i1, seti1] = useState("");
  const [a2, seta2] = useState("");
  const [b2, setb2] = useState("");
  const [c2, setc2] = useState("");
  const [d2, setd2] = useState("");
  const [e2, sete2] = useState("");
  const [f2, setf2] = useState("");
  const [g2, setg2] = useState("");
  const [h2, seth2] = useState("");
  const [i2, seti2] = useState("");
  const [txtCervical, settxtCervical] = useState("");
  const [txtMiddle, settxtMiddle] = useState("");
  const [txtIncisal, settxtIncisal] = useState("");
  const [txtImpression, settxtImpression] = useState("");
  const [txtCoping, settxtCoping] = useState("");
  const [txtAbutment, settxtAbutment] = useState("");
  const [txtScrew, settxtScrew] = useState("");
  const [txtCastable, settxtCastable] = useState("");
  const [txtTemporary, settxtTemporary] = useState("");
  const [txImplantOthers, settxImplantOthers] = useState("");
  const [txtPrep, settxtPrep] = useState("");
  const [FormNewnotes, setFormNewnotes] = useState("");
  const [marketingPerson, setmarketingPerson] = useState("");
  const [teethImage, setTeethImage] = useState("");
  const [labFormDetailsId, setlabFormDetailsId] = useState("");
  const [orderDate, setOrderDate] = useState();
  const [reqDate, setReqDate] = useState("");
  const [redDate, setRedDate] = useState("");
  const [whiteDate, setwhiteDate] = useState("");
  const [metalDate, setMetalDate] = useState("");
  const [bisqueDate, setBisqueDate] = useState("");
  const [rushState, setrushState] = useState(false);
  const [logoImage, setLogo] = useState("");
  const [doctorData, setDoctorData] = useState({
    firstName: "",
    lastName: "",
  });
  const [labName, setLabName] = useState("");
  const [labAddress, setLabAddress] = useState("");
  const [doctorMobile, setdoctorMobile] = useState("");
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [hospitalPhone, setHospitalPhone] = useState("");
  let currentDate = new Date();
  const toDate = moment(currentDate).format("DD-MM-YYYY");
  var orderedDate = moment(orderDate).format("YYYY-MM-DD");
  var requireDate = moment(reqDate).format("YYYY-MM-DD");
  var rededDate = moment(redDate).format("YYYY-MM-DD");
  var whiteteeth = moment(whiteDate).format("YYYY-MM-DD");
  var metalteeth = moment(metalDate).format("YYYY-MM-DD");
  var bisqueteeth = moment(bisqueDate).format("YYYY-MM-DD");
  var labTestRedDate = moment(state.labTestRedDate).format("DD-MM-YYYY");
  var labTestWhiteDate = moment(state.labTestWhiteDate).format("DD-MM-YYYY");
  var labTestMetalDate = moment(state.labTestMetalDate).format("DD-MM-YYYY");
  var labTestBisqueDate = moment(state.labTestBisqueDate).format("DD-MM-YYYY");
  const [dataSet, setDataSet] = useState("");
  var date = moment(orderDate).format("YYYY-MM-DD");
  var time = moment(currentDate).format("hh:mm A");
  const outputTrialDate = moment(state.labTestFrameDate).format("DD-MM-YYYY");
  var framedTrialDate = "" + date + "" + " " + time + "";
  var convertedDate = Date.parse(framedTrialDate);
  const ordereDateChange = (date) => {
    setOrderDate(date);
  };
  const reqDateChange = (date) => {
    if (date != "") {
      setReqDate(date);
    } else {
      setReqDate("");
    }
  };
  const redDateChange = (date) => {
    setRedDate(date);
  };
  const whiteDateChange = (date) => {
    setwhiteDate(date);
  };
  const metalDateChange = (date) => {
    setMetalDate(date);
  };
  const bisqueDateChange = (date) => {
    setBisqueDate(date);
  };
  const onChangeRush = (event) => {
    setrushState(event.target.checked);
  };
  var getS3folder =
    process.env.REACT_APP_S3_URI +
    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
    process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
  const printPage = () => {
    document.getElementById("cancel").style.display = "none";
    document.getElementById("print").style.display = "none";
    document.getElementById("savePrint").style.display = "none";
    document.getElementById("send").style.display = "none";
    window.print();
    document.getElementById("cancel").style.display = "inline-block";
    document.getElementById("print").style.display = "inline-block";
    document.getElementById("savePrint").style.display = "inline-block";
    document.getElementById("send").style.display = "inline-block";
    document.getElementById("send").style.display =
      flag == true ? "none" : "inline-block";
    document.getElementById("savePrint").style.display =
      flag == true ? "none" : "inline-block";
  };
  const changeColor = (t, c) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
      if (c === "W") {
        document.getElementById("teethT" + t + "W").style.display = "none";
        document.getElementById("teethT" + t + "B").style.display = "block";
      } else if (c === "B") {
        document.getElementById("teethT" + t + "W").style.display = "block";
        document.getElementById("teethT" + t + "B").style.display = "none";
      }
      const index = teethArray.indexOf(t.toString());
      if (index > -1) {
        teethArray.splice(index, 1);
        setTeethArray(teethArray);
      } else {
        teethArray.push(t);
        setTeethArray(teethArray);
      }
    } else {
      if (c === "W") {
        document.getElementById("teethT" + t + "W").style.display = "block";
        document.getElementById("teethT" + t + "B").style.display = "none";
      } else if (c === "B") {
        document.getElementById("teethT" + t + "W").style.display = "none";
        document.getElementById("teethT" + t + "B").style.display = "block";
      }
    }
  };
  const checkedBox = (event, checkedId) => {
    if (event.target.checked == true) {
      labFormDetailsIdarray.push(checkedId);
      setLabFormDetailsIdarray(labFormDetailsIdarray);
    } else {
      const index = labFormDetailsIdarray.indexOf(checkedId);
      if (index > -1) {
        labFormDetailsIdarray.splice(index, 1);
        setLabFormDetailsIdarray(labFormDetailsIdarray);
      }
    }
  };
  const saveAndSendOutside = (serviceProviderId) => {
    const postData = {
      siteId: window.sessionStorage.getItem("siteId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendMail", postData).then(
      (data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: "",
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired: "",
              ccEmailRequired: "",
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                alert("Something went wrong");
              } else if (response.data.success === "1") {
                window.location.href = "/Patients/labOrderDental";
              }
            })
            .catch();
        }
      }
    );
  };
  const saveMetalCeramic = () => {
    setIsData(false);
    if (saveflag) {
      return;
    }
    setSaveflag(true);
    var rushValue = "off";
    if (rushState === true) {
      rushValue = "on";
    }
    const saveParams = {
      rush: rushValue,
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      labTestRequestDetailsLabFormsId: "2",
      labFormsId: "3",
      labTestRequestOrderNo: dataSet,
      hospitalId: window.localStorage.getItem("hospitalId"),
      memberId: window.sessionStorage.getItem("memberId"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      userId: window.sessionStorage.getItem("userId"),
      labTestRequestRequiredDate: reqDate === "" ? "" : requireDate,
      labTestRequestOrderDate: orderedDate,
      savePrint: true,
      labFormDetailsId: labFormDetailsIdarray.toString(),
      hidTeethNumber: teethArray.toString(),
      a1: document.getElementById("a1").value,
      b1: document.getElementById("b1").value,
      c1: document.getElementById("c1").value,
      d1: document.getElementById("d1").value,
      e1: document.getElementById("e1").value,
      f1: document.getElementById("f1").value,
      g1: document.getElementById("g1").value,
      h1: document.getElementById("h1").value,
      i1: document.getElementById("i1").value,
      a2: document.getElementById("a2").value,
      b2: document.getElementById("b2").value,
      c2: document.getElementById("c2").value,
      d2: document.getElementById("d2").value,
      e2: document.getElementById("e2").value,
      f2: document.getElementById("f2").value,
      g2: document.getElementById("g2").value,
      h2: document.getElementById("h2").value,
      i2: document.getElementById("i2").value,
      txtCervical: document.getElementById("txtCervical").value,
      txtMiddle: document.getElementById("txtMiddle").value,
      txtIncisal: document.getElementById("txtIncisal").value,
      txtImpression: document.getElementById("txtImpression").value,
      txtCoping: document.getElementById("txtCoping").value,
      txtAbutment: document.getElementById("txtAbutment").value,
      txtScrew: document.getElementById("txtScrew").value,
      txtCastable: document.getElementById("txtCastable").value,
      txtTemporary: document.getElementById("txtTemporary").value,
      txImplantOthers: document.getElementById("txImplantOthers").value,
      redDate: redDate === "" ? "" : rededDate,
      whiteDate: whiteDate === "" ? "" : whiteteeth,
      metalDate: metalDate === "" ? "" : metalteeth,
      bisqueDate: bisqueDate === "" ? "" : bisqueteeth,
      txtPrep: document.getElementById("txtPrep").value,
      FormNewnotes: document.getElementById("FormNewnotes").value,
      marketingPerson: document.getElementById("marketingExecutive").value,
      appoinmentDate:
        orderDate !== ""
          ? framedTrialDate
          : moment(currentDate).format("YYYY-MM-DD hh:mm A"),
      scheduledtimeSlotStartTime:
        orderDate !== "" ? convertedDate : Date.parse(currentDate),
      scheduledtimeSlotEndTime:
        orderDate !== ""
          ? convertedDate + 15 * 60 * 1000
          : Date.parse(currentDate) + 15 * 60 * 1000,
    };
    callingAPI("adminReports/saveMetalForm", saveParams)
      .then((response) => {
        setIsData(true);
        if (response.data.success === "1") {
          saveAndSendOutside()
          alert("Lab Test save and send successfully");
          window.location.href = "/Patients/labOrderDental";
        }
        setSaveflag(false);
      })
      .catch((err) => {
        setSaveflag(false);
      });
  };
  const editingId = (event) => {
    if (window.sessionStorage.getItem("labFormId") === "") {
      setIsData(true);
    } else {
      setIsData(false);
      setflag(true);
      document.getElementById("cancel").style.display = "block";
      document.getElementById("print").style.display = "block";
      document.getElementById("savePrint").style.display = "none";
      document.getElementById("send").style.display = "none";
      const postData = {
        doctorId: window.sessionStorage.getItem("patientDoctorId"),
        memberId: window.sessionStorage.getItem("memberId"),
        hospitalId: localStorage.getItem("hospitalId"),
        serviceProviderId: window.sessionStorage.getItem("servprvid"),
        labRequestId: window.sessionStorage.getItem("labReqId"),
      };
      callingAPI("commonUserFunctions/dentalFormsThree", postData)
        .then((data) => {
          setIsData(true);
          if (data.data.success === "1") {
            setLabTestRequestDetails(
              data.data.result.labTestRequestDetails[0]
                .labTestRequestOrderNumber
            );
            setlabFormDetailsId(data.data.result.labFormDetailsId);
            var labFormData = data.data.result.labFormDetailsId
              .toString()
              .split(",");
            labFormData.map((labformId) => {
              if (labformId) {
                const checkbox = document.getElementById("teethTC" + labformId);
                checkbox.checked = "true";
                checkbox.disabled = "true";
              }
            });
            setTeethImage(data.data.result.hidTeethNumber);
            var teethData = data.data.result.hidTeethNumber.split(",");
            teethData.map((itemteethData) => {
              if (itemteethData) {
                document.getElementById(
                  "teethT" + itemteethData + "B"
                ).style.display = "block";
                document.getElementById(
                  "teethT" + itemteethData + "W"
                ).style.display = "none";
              }
            });
            seta1(data.data.result.a1);
            setb1(data.data.result.b1);
            setc1(data.data.result.c1);
            setd1(data.data.result.d1);
            sete1(data.data.result.e1);
            setf1(data.data.result.f1);
            setg1(data.data.result.g1);
            seth1(data.data.result.h1);
            seti1(data.data.result.i1);
            seta2(data.data.result.a2);
            setb2(data.data.result.b2);
            setc2(data.data.result.c2);
            setd2(data.data.result.d2);
            sete2(data.data.result.e2);
            setf2(data.data.result.f2);
            setg2(data.data.result.g2);
            seth2(data.data.result.h2);
            seti2(data.data.result.i2);
            settxtCervical(data.data.result.txtCervical);
            settxtMiddle(data.data.result.txtMiddle);
            settxtIncisal(data.data.result.txtIncisal);
            settxtImpression(data.data.result.txtImpression);
            settxtCoping(data.data.result.txtCoping);
            settxtAbutment(data.data.result.txtAbutment);
            settxtScrew(data.data.result.txtScrew);
            settxtCastable(data.data.result.txtCastable);
            settxtTemporary(data.data.result.txtTemporary);
            settxImplantOthers(data.data.result.txImplantOthers);
            settxtPrep(data.data.result.txtPrep);
            setFormNewnotes(data.data.result.FormNewnotes);
            setmarketingPerson(data.data.result.marketingPerson);
            setState({
              labTestRequestRequiredDate:
                data.data.result.labTestRequestRequiredDate,
              labTestRequestOrderDate: data.data.result.labTestRequestOrderDate,
              labTestRedDate: data.data.result.redDate,
              labTestWhiteDate: data.data.result.whiteDate,
              labTestMetalDate: data.data.result.metalDate,
              labTestBisqueDate: data.data.result.bisqueDate,
            });
            if (data.data.result.rush == 1) {
              const rushCheckbox = document.getElementById("teethTC0");
              rushCheckbox.checked = "true";
              rushCheckbox.disabled = "true";
            }
          }
        })
        .catch();
    }
  };
  const metalCeramicListing = () => {
    const listingParams = {
      hospitalId: window.localStorage.getItem("hospitalId"),
      memberId: window.sessionStorage.getItem("memberId"),
      doctorId: window.sessionStorage.getItem("patientDoctorId"),
      userId: window.sessionStorage.getItem("userId"),
      serviceProviderId: window.sessionStorage.getItem("ServiceProviderId"),
      serviceProviderLabFormsId: window.sessionStorage.getItem("setlabFormsId"),
      labFormsId: "3",
      labTestRequestOrderNo: dataSet,
      labTestRequestsRequiredDate: requireDate,
      labTestRequestsOrderDate: orderedDate,
      savePrint: true,
      labFormDetailsId: labFormDetailsIdarray.toString(),
    };
    callingAPI("adminReports/dentalFormNewThird", listingParams)
      .then((response) => {
        if (response.data.success === "1") {
          sethospitalData(response.data.result.hospital);
          setHospitalEmail(response.data.result.hospital[0].email_id);
          setLabName(response.data.result.labFormDetails[0].name);
          setLabAddress(response.data.result.labFormDetails[0].address);
          setHospitalPhone(response.data.result.hospital[0].phone);
          setDoctorData({
            firstName: response.data.result.doctorDetails[0].first_name,
            lastName: response.data.result.doctorDetails[0].last_name,
          });
          setdoctorMobile(response.data.result.doctorDetails[0].mobileNumber);
          setLabdetails(response.data.result.labFormDetails);
          setMemberDetails(response.data.result.memberDetails);
          setPatientAge(response.data.result.paitentAge[0]._year);
          if (response.data.result.hospitalLogo[0].logoImage !== "") {
            setLogo(
              getS3folder + "/" + response.data.result.hospitalLogo[0].logoImage
            );
          } else {
            setLogo(Logo);
          }
        }
      })
      .catch();
  };
  useEffect(() => {
    setDataSet(Math.floor(1000 + Math.random() * 9000));
    var fromDateSplit = toDate.split("-");
    setOrderDate(
      new Date(
        fromDateSplit[2] + "," + fromDateSplit[1] + "," + fromDateSplit[0]
      )
    );
    editingId();
    metalCeramicListing();
  }, []);
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  return (
    <div className="labOrderFormsWrap">
      <body
        style={{
          margin: "0",
          fontSize: "13px",
          fontFamily: "sans-serif",
          fontWeight: "300",
          color: "#000",
          background: "#fff",
        }}
        className="lbOrderPrintMain"
      >
        <div class="instructionFormWrap1">
          {isData === false ? <Loader /> : ""}
          <div style={{ width: "985px", border: "0", margin: "0 auto" }}>
            <div
              class="instructionFormWrap"
              style={{
                width: "980px",
                background: "#fff",
                padding: "5px 0px",
                boxSizing: "border-box",
              }}
            >
              <div
                class="formTop"
                style={{
                  clear: "both",
                  overflow: "hidden",
                  borderBottom: "1px solid #252525",
                }}
              >
                {flag == true ? (
                  <div
                    class="slNo"
                    style={{ width: "15%", float: "left", color: "#ff0000" }}
                  >
                    <span style={{ float: "left" }}>Sl No:</span>
                    <span
                      style={{
                        fontWeight: "800",
                        width: " 70%",
                        fontSize: "15px",
                        float: "left",
                        paddingLeft: "5px",
                      }}
                    >
                      <input
                        type="text"
                        name="siNo"
                        style={{ border: "0", color: "#ff0000" }}
                        readonly="readonly"
                        value={labTestRequestDetails}
                      />{" "}
                    </span>
                  </div>
                ) : (
                  <div
                    class="slNo"
                    style={{ width: "15%", float: "left", color: "#ff0000" }}
                  >
                    <span style={{ float: "left" }}>Sl No:</span>
                    <span
                      style={{
                        fontWeight: "800",
                        width: " 70%",
                        fontSize: "15px",
                        float: "left",
                        paddingLeft: "5px",
                      }}
                    >
                      <input
                        type="text"
                        name="siNo"
                        style={{ border: "0", color: "#ff0000" }}
                        readonly="readonly"
                        value={dataSet}
                      />{" "}
                    </span>
                  </div>
                )}
                {hospitalData.map((data) => (
                  <div>
                    <div
                      class="formName"
                      style={{
                        width: "70%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <div
                        class="title"
                        style={{
                          fontSize: "25px",
                          fontWeight: "600",
                          color: " #0091d0",
                        }}
                      >
                        <span
                          style={{ color: "#ff0000", verticalAlign: "top" }}
                        />
                        {labName}
                      </div>
                      <div class="address" style={{ margin: "3px 0" }}>
                        {labAddress}
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  class="mailPortion"
                  style={{
                    clear: "both",
                    overflow: "hidden",
                    fontSize: "0px",
                    textAlign: "center",
                    paddingTop: "2px",
                  }}
                >
                  <div>
                    <div
                      class="mailId"
                      style={{
                        display: "inline-block",
                        marginRight: "5px",
                        padding: "2px 7px",
                        background: "#e31e24",
                        color: "#fff",
                        transform: "skew(-15deg)",
                        verticalAlign: "top",
                      }}
                    >
                      <span
                        style={{
                          transform: "skew(15deg)",
                          display: "inline-block",
                          fontSize: "10px",
                          float: "left",
                        }}
                      >
                        Doctor Name:
                      </span>
                      <span
                        style={{
                          transform: "skew(15deg)",
                          display: "inline-block",
                          fontSize: "10px",
                          float: "left",
                          paddingLeft: "3px",
                        }}
                      >
                        Dr. {doctorData.firstName} {doctorData.lastName}
                      </span>
                    </div>
                    <div
                      class="mailId"
                      style={{
                        display: "inline-block",
                        marginRight: "5px",
                        padding: "2px 7px",
                        background: " #e31e24",
                        color: "#fff",
                        transform: "skew(-15deg)",
                        verticalAlign: "top",
                      }}
                    >
                      <span
                        style={{
                          transform: "skew(15deg)",
                          display: "inline-block",
                          fontSize: "10px",
                          float: "left",
                        }}
                      >
                        Email Id:
                      </span>
                      <span
                        style={{
                          transform: "skew(15deg)",
                          display: "inline-block",
                          fontSize: "10px",
                          float: "left",
                          paddingLeft: "3px",
                        }}
                      >
                        {hospitalEmail}
                      </span>
                    </div>

                    <div
                      class="whatsAppNo"
                      style={{
                        display: "inline-block",
                        padding: "2px 7px",
                        background: "#e31e24",
                        color: "#fff",
                        transform: "skew(-15deg)",
                        verticalAlign: "top",
                      }}
                    >
                      <span
                        style={{
                          transform: "skew(15deg)",
                          display: " inline-block",
                          fontSize: "10px",
                          float: "left",
                        }}
                      >
                        Phone
                        <span
                          style={{ fontSize: "9px", verticalAlign: "bottom" }}
                        />{" "}
                        :
                      </span>
                      <span
                        style={{
                          transform: "skew(15deg)",
                          display: "inline-block",
                          fontSize: "10px",
                          float: "left",
                          paddingLeft: "3px",
                        }}
                      >
                        {hospitalPhone}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="instructionDetails   ">
                <div
                  class="heading"
                  style={{
                    textAlign: "center",
                    paddingTop: "10px",
                    fontSize: "19px",
                    color: "#0091d0",
                  }}
                >
                  INSTRUCTIONS FOR METAL CERAMIC PROSTHESIS
                </div>
                <div
                  class="detailWrap "
                  style={{ clear: "both", overflow: "hidden" }}
                >
                  <div
                    class="doctorDetail"
                    style={{ width: "540px", float: "left" }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#000",
                        paddingBottom: "8px",
                        display: "block",
                      }}
                    >
                      LAB DETAILS
                    </span>
                    <div
                      class="doctorDes"
                      style={{
                        border: "1px solid #252525",
                        padding: "2px",
                        clear: "both",
                        overflow: "hidden",
                        height: "135px",
                      }}
                    >
                      <div
                        class="drImg"
                        style={{
                          width: "160px",
                          float: "left",
                          height: "129px",
                          border: "1px solid #252525",
                          boxSizing: "border-box",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "center",
                            display: "block",
                            width: "62px",
                            margin: "auto",
                            fontSize: "11px",
                            paddingTop: "43px",
                          }}
                        >
                          Barcode Sticker
                        </span>
                      </div>
                      {labdetails.map((data) => (
                        <div>
                          <div
                            class="drDetail"
                            style={{
                              width: "372px",
                              float: "left",
                              paddingLeft: "15px",
                              boxSizing: "border-box",
                            }}
                          >
                            <span
                              style={{ display: "block", fontSize: "8px" }}
                            />
                            <div
                              style={{ paddingTop: "3px", lineHeight: "21px" }}
                            >
                              <span
                                style={{ float: "left", paddingRight: "3px" }}
                              >
                                Name :{" "}
                              </span>
                              <div
                                style={{
                                  wordBreak: " break-word",
                                  width: "358px",
                                }}
                              >
                                {data.name}
                              </div>
                            </div>
                            <div
                              style={{ paddingTop: "3px", lineHeight: "21px" }}
                            >
                              <span
                                style={{ float: "left", paddingRight: "3px" }}
                              >
                                Place :{" "}
                              </span>
                              <div
                                style={{
                                  wordBreak: "break-word",
                                  width: "358px",
                                }}
                              >
                                {data.address}
                              </div>
                            </div>
                            <div
                              style={{ paddingTop: "3px", lineHeight: "21px" }}
                            >
                              <span
                                style={{ float: "left", paddingRight: "3px" }}
                              >
                                Phone :{" "}
                              </span>
                              <div
                                style={{
                                  wordBreak: "break-word",
                                  width: "358px",
                                }}
                              >
                                {data.phone}
                              </div>
                            </div>
                            <div
                              style={{ paddingTop: "3px", lineHeight: "21px" }}
                            >
                              <span
                                style={{ float: "left", paddingRight: "3px" }}
                              >
                                Email : {data.emailId}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    class="patientDetail"
                    style={{
                      width: "425px",
                      float: "left",
                      marginLeft: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#000",
                        paddingBottom: "8px",
                        display: "block",
                        textAlign: "right",
                      }}
                    >
                      PATIENT'S DETAILS
                    </span>
                    {memberDetails.map((data) => (
                      <div>
                        <div
                          class="doctorDes"
                          style={{
                            border: "1px solid #252525",
                            padding: "2px",
                            clear: "both",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            class="drDetail"
                            style={{
                              width: "420px",
                              float: "left",
                              paddingLeft: "15px",
                              boxSizing: "border-box",
                              height: "129px",
                            }}
                          >
                            <span
                              style={{ display: "block", fontSize: "8px" }}
                            />
                            <div
                              style={{ paddingTop: "3px", lineHeight: "21px" }}
                            >
                              <span
                                style={{ float: "left", paddingRight: "3px" }}
                              >
                                Name :{" "}
                              </span>
                              <div
                                style={{
                                  wordBreak: "break-word",
                                  width: "400px",
                                }}
                              >
                                {data.first_name} {data.last_name}
                              </div>
                            </div>
                            <div
                              style={{
                                paddingTop: "3px",
                                lineHeight: "21px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <div style={{ float: "left", width: "237px" }}>
                                <span
                                  style={{ float: "left", paddingRight: "3px" }}
                                >
                                  Gender :{" "}
                                </span>
                                <label
                                  style={{
                                    margin: "0px 20px 0 0px",
                                    float: "left",
                                  }}
                                >
                                  {data.gender}
                                </label>
                              </div>
                              <div style={{ float: "right", width: "168px" }}>
                                <span style={{ float: "left" }}>
                                  Order Date :
                                </span>
                                <div style={{ float: "left", width: "87px" }}>
                                  {flag == true ? (
                                    <Datepicker
                                      autoComplete="off"
                                      required
                                      className="Datepicker pa2 LborderdentalPrint  "
                                      calendarClassName="rasta-stripes"
                                      dateFormat="dd-MM-yyyy"
                                      id="fromdate"
                                      disabled
                                      onKeyDown={(e) => e.preventDefault()}
                                      onChangeRaw={handleDateChangeRaw}
                                      value={state.labTestRequestOrderDate}
                                    />
                                  ) : (
                                    <Datepicker
                                      autoComplete="off"
                                      required
                                      className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                                      onKeyDown={(e) => e.preventDefault()}
                                      onChangeRaw={handleDateChangeRaw}
                                      calendarClassName="rasta-stripes"
                                      dateFormat="dd-MM-yyyy"
                                      selected={orderDate}
                                      onChange={(date) => {
                                        ordereDateChange(date);
                                      }}
                                      id="fromdate"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                paddingTop: "3px",
                                lineHeight: "21px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <div style={{ float: "left", width: "222px" }}>
                                <span style={{ float: "left" }}>Age :</span>
                                <div>&nbsp;{patientAge}</div>
                              </div>
                              <div style={{ float: "right", width: "183px" }}>
                                <span style={{ float: "left" }}>
                                  Required Date :
                                </span>
                                <div style={{ float: "left", width: "87px" }}>
                                  {flag == true ? (
                                    <Datepicker
                                      autoComplete="off"
                                      required
                                      className="Datepicker pa2 LborderdentalPrint  "
                                      calendarClassName="rasta-stripes"
                                      dateFormat="dd-MM-yyyy"
                                      id="fromdate"
                                      disabled
                                      onKeyDown={(e) => e.preventDefault()}
                                      onChangeRaw={handleDateChangeRaw}
                                      //value={state.labTestRequestRequiredDate}
                                      value={
                                        state.labTestRequestRequiredDate ===
                                        "00-00-0000"
                                          ? ""
                                          : state.labTestRequestRequiredDate
                                      }
                                      style={{ color: "#000" }}
                                    />
                                  ) : (
                                    <Datepicker
                                      autoComplete="off"
                                      required
                                      className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                                      calendarClassName="rasta-stripes"
                                      dateFormat="dd-MM-yyyy"
                                      selected={reqDate}
                                      onChange={(date) => {
                                        reqDateChange(date);
                                      }}
                                      id="fromdate"
                                      onKeyDown={(e) => e.preventDefault()}
                                      onChangeRaw={handleDateChangeRaw}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                paddingTop: "3px",
                                lineHeight: "21px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <div style={{ float: "right", width: "128px" }}>
                                {flag == true ? (
                                  <input
                                    defaultChecked={rushState}
                                    //onChange={onChangeRush}
                                    type="checkbox"
                                    name="rushv"
                                    id="teethTC0"
                                    disabled={flag}
                                    style={{
                                      margin: "4px 3px 0 0px",
                                      float: "left",
                                    }}
                                  />
                                ) : (
                                  <input
                                    defaultChecked={rushState}
                                    onChange={onChangeRush}
                                    type="checkbox"
                                    name="rushv"
                                    id="teethTC0"
                                    disabled={flag}
                                    style={{
                                      margin: "4px 3px 0 0px",
                                      float: "left",
                                    }}
                                  />
                                )}
                                <div>Rush (10% extra)</div>
                              </div>
                            </div>
                            <div
                              style={{
                                paddingTop: "3px",
                                lineHeight: "21px",
                                clear: "both",
                                overflow: "hidden",
                              }}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textAlign: "right",
                                  paddingRight: "8px",
                                }}
                              >
                                NB : *Give us more time to avail extra benefit.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="formHalfCheck ">
                <div
                  class="formContent"
                  style={{ clear: "both", overflow: "hidden" }}
                >
                  <div style={{ float: "left" }}>
                    <div
                      style={{
                        width: "500px",
                        boxSizing: "border-box",
                        paddingTop: "10px",
                        float: "left",
                        marginRight: "5px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #000",
                          width: "500px",
                          padding: "5px",
                          float: "left",
                          boxSizing: "border-box",
                          position: "relative",
                        }}
                      >
                        <img
                          src={arrowLeft}
                          style={{
                            position: "absolute",
                            top: "110px",
                            left: "170px",
                            height: "117px",
                          }}
                        />
                        <span style={{ visibility: "hidden" }}>TEST</span>
                        <div
                          style={{
                            width: "40%",
                            float: "left",
                            padding: "5px",
                            border: "1px solid #000",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "5px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "156")}
                              type="checkbox"
                              name="txtMyFormNew"
                              id="teethTC156"
                              value="156"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span
                              style={{
                                color: "#0091d0",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              DMLS-CAD/CAM PFM
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "5px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "157")}
                              type="checkbox"
                              name="txtMyFormNew"
                              id="teethTC157"
                              value="157"
                              disabled={flag}
                              style={{ margin: "2px 5px 0 0px", float: "left" }}
                            />
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              DENTCARE NOVA (PFM)
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "57%",
                            float: "left",
                            marginBottom: "70px",
                            marginLeft: "10px",
                            marginTop: "-19px",
                          }}
                        >
                          <div
                            style={{
                              width: "90%",
                              border: "1px solid #000",
                              clear: "both",
                              padding: "5px",
                              overflow: "hidden",
                              textAlign: "center",
                              margin: "0 auto",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "158")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="158"
                              id="teethTC158"
                              disabled={flag}
                              style={{
                                margin: " 2px 0px 0 14px",
                                float: "left",
                              }}
                            />
                            <span
                              style={{ color: "#ff1700", fontWeight: "700" }}
                            >
                              EXPORT QUALITY
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            float: "left",
                            position: "relative",
                          }}
                        >
                          {" "}
                          <img
                            src={arrowLeftSmall}
                            style={{
                              position: "absolute",
                              right: "-4px",
                              bottom: "40px",
                              height: "37px",
                            }}
                          />
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "160")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="160"
                              disabled={flag}
                              id="teethTC160"
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span style={{ color: "#0091d0" }}>
                              Ceramic Full Coverage
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "161")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="161"
                              id="teethTC161"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Facing Ceramic</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "162")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="162"
                              id="teethTC162"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span style={{ color: " #0091d0" }}>
                              Adoro Facing
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "163")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="163"
                              id="teethTC163"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Precious Gold</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "164")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="164"
                              id="teethTC164"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span style={{ color: " #0091d0" }}>
                              Semi Precious Gold
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "165")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="165"
                              id="teethTC165"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Implant Prosthesis Cement Retained</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "166")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="166"
                              id="teethTC166"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span style={{ color: " #0091d0" }}>
                              Implant Prosthesis Screw Retained
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "6px",
                              clear: "both",
                              overflow: "hidden",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "167")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="167"
                              id="teethTC167"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Full Metal (Co. Cr)</span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "230px",
                            float: "left",
                            position: "absolute",
                            bottom: "3px",
                            right: "34px",
                          }}
                        >
                          <input
                            type="checkbox"
                            value="266"
                            onChange={(event) => checkedBox(event, "266")}
                            name="txtMyFormNew"
                            id="teethTC266"
                            disabled={flag}
                            style={{
                              width: "20px",
                              height: "33px",
                              background: "#fff",
                            }}
                          />

                          <div style={{ float: "right", width: "86px" }}>
                            <input
                              type="checkbox"
                              value="267"
                              onChange={(event) => checkedBox(event, "267")}
                              name="txtMyFormNew"
                              id="teethTC267"
                              disabled={flag}
                              style={{
                                width: "20px",
                                height: "33px",
                                background: "#fff",
                                float: "left",
                                position: "relative",
                              }}
                            />
                            <input
                              onChange={(event) => checkedBox(event, "268")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="268"
                              id="teethTC268"
                              disabled={flag}
                              style={{
                                width: "20px",
                                height: "33px",
                                background: "#fff",
                                position: "absolute",
                                right: "12px",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "158px",
                            float: "left",
                            position: "absolute",
                            bottom: "50px",
                            right: "60px",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "269")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="269"
                            disabled={flag}
                            id="teethTC269"
                            style={{
                              width: "20px",
                              height: "33px",
                              background: "#fff",
                              position: "relative",
                            }}
                          />
                          <input
                            onChange={(event) => checkedBox(event, "270")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="270"
                            disabled={flag}
                            id="teethTC270"
                            style={{
                              width: "20px",
                              height: "33px",
                              background: "#fff",
                              position: "absolute",
                              right: "34px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: "50%",
                            float: "left",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              top: "-45px",
                              right: "30px",
                            }}
                          >
                            <div
                              style={{
                                width: "20%",
                                paddingBottom: "12px",
                                float: "left",
                                textAlign: "center",
                              }}
                            >
                              <img src={expo1} style={{ width: "78%" }} />
                              <div style={{ width: "100%", float: "left" }}>
                                <input
                                  onChange={(event) => checkedBox(event, "271")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="271"
                                  disabled={flag}
                                  id="teethTC271"
                                  style={{
                                    width: "20px",
                                    height: "33px",
                                    background: "#fff",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                paddingBottom: "12px",
                                float: "left",
                                textAlign: "center",
                              }}
                            >
                              <img src={expo2} style={{ width: "78%" }} />
                              <div style={{ width: "100%", float: "left" }}>
                                <input
                                  onChange={(event) => checkedBox(event, "272")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  id="teethTC272"
                                  disabled={flag}
                                  value="272"
                                  style={{
                                    width: "20px",
                                    height: "33px",
                                    background: "#fff",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                paddingBottom: "12px",
                                float: "left",
                                textAlign: "center",
                              }}
                            >
                              <img src={expo3} style={{ width: "78%" }} />
                              <div style={{ width: "100%", float: "left" }}>
                                <input
                                  onChange={(event) => checkedBox(event, "273")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="273"
                                  disabled={flag}
                                  id="teethTC273"
                                  style={{
                                    width: "20px",
                                    height: "33px",
                                    background: "#fff",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                paddingBottom: "12px",
                                float: "left",
                                textAlign: "center",
                              }}
                            >
                              <img src={expo4} style={{ width: "78%" }} />
                              <div style={{ width: "100%", float: "left" }}>
                                <input
                                  onChange={(event) => checkedBox(event, "274")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="274"
                                  disabled={flag}
                                  id="teethTC274"
                                  style={{
                                    width: "20px",
                                    height: "33px",
                                    background: "#fff",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                paddingBottom: "12px",
                                float: "left",
                                textAlign: "center",
                              }}
                            >
                              <img src={expo5} style={{ width: "78%" }} />
                              <div style={{ width: "100%", float: "left" }}>
                                <input
                                  onChange={(event) => checkedBox(event, "275")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="275"
                                  disabled={flag}
                                  id="teethTC275"
                                  style={{
                                    width: "20px",
                                    height: "33px",
                                    background: "#fff",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid #000",
                          boxSizing: "border-box",
                          padding: "10px",
                          float: "left",
                          borderTop: "0",
                        }}
                      >
                        <div
                          style={{
                            width: "25%",
                            paddingBottom: "12px",
                            float: "left",
                            color: "#0091d0",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "168")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="168"
                            disabled={flag}
                            id="teethTC168"
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Tube</span>
                        </div>
                        <div
                          style={{
                            width: "25%",
                            paddingBottom: "12px",
                            float: "left",
                            color: "#0091d0",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "169")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="169"
                            disabled={flag}
                            id="teethTC169"
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Post & Core</span>
                        </div>
                        <div
                          style={{
                            width: "25%",
                            paddingBottom: "12px",
                            float: "left",
                            color: "#0091d0",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "170")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="170"
                            disabled={flag}
                            id="teethTC170"
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Onlay</span>
                        </div>
                        <div
                          style={{
                            width: "25%",
                            paddingBottom: "12px",
                            float: "left",
                            color: "#0091d0",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "171")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="171"
                            id="teethTC171"
                            disabled={flag}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Inlay</span>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            paddingBottom: "12px",
                            float: "left",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "172")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="172"
                            id="teethTC172"
                            disabled={flag}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Rest with ceramic coating</span>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            paddingBottom: "12px",
                            float: "left",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "173")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="173"
                            disabled={flag}
                            id="teethTC173"
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <span>Full Metal rest</span>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          boxSizing: "border-box",
                          float: "left",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            border: "1px solid #000",
                            boxSizing: "border-box",
                            padding: "3px",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              float: "left",
                              color: "#0091d0",
                              fontWeight: "700",
                            }}
                          >
                            Opposing tooth to be restored later
                          </div>
                          <div style={{ width: "50%", float: "left" }}>
                            <input
                              onChange={(event) => checkedBox(event, "174")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="174"
                              disabled={flag}
                              id="teethTC174"
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Yes</span>
                          </div>
                          <div style={{ width: "50%", float: "left" }}>
                            <input
                              onChange={(event) => checkedBox(event, "175")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="175"
                              disabled={flag}
                              id="teethTC175"
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>No</span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            border: "1px solid #000",
                            boxSizing: " border-box",
                            padding: "8px",
                            float: "left",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "50%",
                              float: "left",
                              color: "#0091d0",
                              fontWeight: "700",
                            }}
                          >
                            RCT Done
                          </div>
                          <div style={{ width: "25%", float: "left" }}>
                            <input
                              onChange={(event) => checkedBox(event, "176")}
                              type="checkbox"
                              name="txtMyFormNew"
                              id="teethTC176"
                              value="176"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span style={{ color: "#0091d0" }}>Yes</span>
                          </div>
                          <div style={{ width: "25%", float: "left" }}>
                            <input
                              onChange={(event) => checkedBox(event, "177")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="177"
                              id="teethTC177"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span style={{ color: "#0091d0" }}>No</span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "49%",
                          boxSizing: "border-box",
                          float: "left",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            border: "1px solid #000",
                            boxSizing: "border-box",
                            padding: "10px 6px 15px",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              paddingBottom: "12px",
                              float: "left",
                              color: "#0091d0",
                              fontWeight: "700",
                            }}
                          >
                            If no occlusal clearance
                          </div>
                          <div style={{ width: "50%", float: "left" }}>
                            <input
                              onChange={(event) => checkedBox(event, "178")}
                              type="checkbox"
                              name="txtMyFormNew"
                              id="teethTC178"
                              value="178"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Metal Island</span>
                          </div>
                          <div style={{ width: "50%", float: "left" }}>
                            <input
                              onChange={(event) => checkedBox(event, "179")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="179"
                              id="teethTC179"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Adjust Opposing</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              paddingTop: "5px",
                            }}
                          >
                            <input
                              onChange={(event) => checkedBox(event, "180")}
                              type="checkbox"
                              name="txtMyFormNew"
                              value="180"
                              id="teethTC180"
                              disabled={flag}
                              style={{ margin: "0px 5px 0 0px", float: "left" }}
                            />
                            <span>Reduction coping (Chargeable)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "153px",
                        float: "left",
                        margin: "10px",
                        border: "1px solid black",
                        padding: "10px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                          }}
                        >
                          shade
                        </span>
                        <div style={{ float: "left" }} />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "12px",
                          paddingTop: "12px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "181")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="181"
                          disabled={flag}
                          id="teethTC181"
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Digital photo/mail</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "5px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <span style={{ float: "left" }}>Cervical</span>
                        <div style={{ float: "left" }}>
                          {flag == true ? (
                            <input
                              type="text"
                              maxlength="2"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "10px",
                              }}
                              id="txtCervical"
                              disabled
                              value={txtCervical}
                              name="txtCervical"
                            />
                          ) : (
                            <input
                              type="text"
                              id="txtCervical"
                              name="txtCervical"
                              maxlength="2"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "10px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "5px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <span style={{ float: "left" }}>Middle</span>
                        <div style={{ float: "left" }}>
                          {flag == true ? (
                            <input
                              type="text"
                              maxlength="2"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "18px",
                              }}
                              id="txtMiddle"
                              disabled
                              value={txtMiddle}
                              name="txtMiddle"
                            />
                          ) : (
                            <input
                              type="text"
                              maxlength="2"
                              id="txtMiddle"
                              name="txtMiddle"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "18px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: "5px",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <span style={{ float: "left" }}>Incisal</span>
                        <div style={{ float: "left" }}>
                          {flag == true ? (
                            <input
                              type="text"
                              maxlength="2"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "18px",
                              }}
                              id="txtIncisal"
                              disabled
                              value={txtIncisal}
                              name="txtIncisal"
                            />
                          ) : (
                            <input
                              type="text"
                              maxlength="2"
                              id="txtIncisal"
                              name="txtIncisal"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "18px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          paddingTop: "22px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img src={MetalCeramic} style={{ width: "100%" }} />
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "36px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="a1"
                            disabled
                            value={a1}
                            name="a1"
                          />
                        ) : (
                          <input
                            type="text"
                            id="a1"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "36px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "36px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="b1"
                            disabled
                            value={b1}
                            name="b1"
                          />
                        ) : (
                          <input
                            type="text"
                            maxlength="4"
                            id="b1"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "36px",
                              left: "61px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "36px",
                              right: "5px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="c1"
                            disabled
                            value={c1}
                            name="c1"
                          />
                        ) : (
                          <input
                            type="text"
                            maxlength="4"
                            id="c1"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "36px",
                              right: "5px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "77px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="d1"
                            disabled
                            value={d1}
                            name="d1"
                          />
                        ) : (
                          <input
                            type="text"
                            id="d1"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "77px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "77px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="e1"
                            disabled
                            value={e1}
                            name="e1"
                          />
                        ) : (
                          <input
                            id="e1"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "77px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "77px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="f1"
                            disabled
                            value={f1}
                            name="f1"
                          />
                        ) : (
                          <input
                            id="f1"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "77px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "11px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="g1"
                            disabled
                            value={g1}
                            name="g1"
                          />
                        ) : (
                          <input
                            id="g1"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "11px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "11px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="h1"
                            disabled
                            value={h1}
                            name="h1"
                          />
                        ) : (
                          <input
                            id="h1"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "11px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "11px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="i1"
                            disabled
                            value={i1}
                            name="i1"
                          />
                        ) : (
                          <input
                            id="i1"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "11px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          paddingTop: "10px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img src={MetalCeramic2} style={{ width: "100%" }} />
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "24px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="a2"
                            disabled
                            value={a2}
                            name="a2"
                          />
                        ) : (
                          <input
                            id="a2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "24px",
                              left: "9px",
                              background: " rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "24px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="b2"
                            disabled
                            value={b2}
                            name="b2"
                          />
                        ) : (
                          <input
                            id="b2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "24px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "24px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="c2"
                            disabled
                            value={c2}
                            name="c2"
                          />
                        ) : (
                          <input
                            id="c2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "24px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "67px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="d2"
                            disabled
                            value={d2}
                            name="d2"
                          />
                        ) : (
                          <input
                            id="d2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "67px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "67px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="e2"
                            disabled
                            value={e2}
                            name="e2"
                          />
                        ) : (
                          <input
                            id="e2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "67px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "67px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="f2"
                            disabled
                            value={f2}
                            name="f2"
                          />
                        ) : (
                          <input
                            id="f2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              top: "67px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "10px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="g2"
                            disabled
                            value={g2}
                            name="g2"
                          />
                        ) : (
                          <input
                            id="g2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "10px",
                              left: "9px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "10px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="h2"
                            disabled
                            value={h2}
                            name="h2"
                          />
                        ) : (
                          <input
                            id="h2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "10px",
                              left: "50px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                        {flag == true ? (
                          <input
                            type="text"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "10px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                            maxlength="4"
                            id="i2"
                            disabled
                            value={i2}
                            name="i2"
                          />
                        ) : (
                          <input
                            id="i2"
                            type="text"
                            maxlength="4"
                            style={{
                              width: "32px",
                              border: "none",
                              position: "absolute",
                              bottom: "10px",
                              right: "8px",
                              background: "rgba(221, 221, 221, 0.34)",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ paddingTop: "10px", boxSizing: "border-box" }}
                    >
                      <div
                        className="NextPageContent "
                        style={{
                          boxSizing: "border-box",
                          width: "690px",
                          float: "left",
                          breakBefore: "page",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            width: "260px",
                            float: "left",
                            height: "430px",
                            padding: "30px",
                          }}
                        >
                          <div
                            style={{
                              boxSizing: "border-box",
                              width: "200px",
                              height: "385px",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: "178px",
                                left: "0px",
                                fontWeight: "600",
                              }}
                            >
                              RIGHT
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "179px",
                                left: "168px",
                                fontWeight: "600",
                              }}
                            >
                              LEFT
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "38px",
                                left: "76px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                            >
                              UPPER
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "317px",
                                left: "75px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                            >
                              LOWER
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "136px",
                                left: "-17px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "8px",
                                  fontWeight: "600",
                                }}
                              >
                                18
                              </span>

                              <img
                                onClick={(e) => changeColor("18", "W")}
                                id="teethT18W"
                                src={T18}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("18", "B")}
                                id="teethT18B"
                                src={T18b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "201px",
                                left: "-21px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "8px",
                                  fontWeight: "600",
                                }}
                              >
                                48
                              </span>

                              <img
                                onClick={(e) => changeColor("48", "W")}
                                id="teethT48W"
                                src={T48}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("48", "B")}
                                id="teethT48B"
                                src={T48b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "106px",
                                left: "-13px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "8px",
                                  fontWeight: "600",
                                }}
                              >
                                17
                              </span>

                              <img
                                onClick={(e) => changeColor("17", "W")}
                                id="teethT17W"
                                src={T17}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("17", "B")}
                                id="teethT17B"
                                src={T17b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "233px",
                                left: "-15px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "8px",
                                  fontWeight: "600",
                                }}
                              >
                                47
                              </span>

                              <img
                                onClick={(e) => changeColor("47", "W")}
                                id="teethT47W"
                                src={T47}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("47", "B")}
                                id="teethT47B"
                                src={T47b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "79px",
                                left: "-8px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "7px",
                                  fontWeight: "600",
                                }}
                              >
                                16
                              </span>

                              <img
                                onClick={(e) => changeColor("16", "W")}
                                id="teethT16W"
                                src={T16}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("16", "B")}
                                id="teethT16B"
                                src={T16b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "262px",
                                left: "-10px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "8px",
                                  fontWeight: "600",
                                }}
                              >
                                46
                              </span>

                              <img
                                onClick={(e) => changeColor("46", "W")}
                                id="teethT46W"
                                src={T46}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("46", "B")}
                                id="teethT46B"
                                src={T46b}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "55px",
                                left: "1px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "5px",
                                  fontWeight: "600",
                                }}
                              >
                                15
                              </span>

                              <img
                                onClick={(e) => changeColor("15", "W")}
                                id="teethT15W"
                                src={T15}
                                style={{
                                  width: "32px",
                                  height: "29px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("15", "B")}
                                id="teethT15B"
                                src={T15b}
                                style={{
                                  width: "32px",
                                  height: "29px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "289px",
                                left: "3px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "11px",
                                  fontWeight: "600",
                                }}
                              >
                                45
                              </span>

                              <img
                                onClick={(e) => changeColor("45", "W")}
                                id="teethT45W"
                                src={T45}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("45", "B")}
                                id="teethT45B"
                                src={T45b}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "31px",
                                left: "10px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "2px",
                                  fontWeight: "600",
                                }}
                              >
                                14
                              </span>

                              <img
                                onClick={(e) => changeColor("14", "W")}
                                id="teethT14W"
                                src={T14}
                                style={{
                                  width: "33px",
                                  height: "33px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("14", "B")}
                                id="teethT14B"
                                src={T14b}
                                style={{
                                  width: "33px",
                                  height: "33px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "308px",
                                left: "10px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  paddingTop: "15px",
                                  fontWeight: "600",
                                }}
                              >
                                44
                              </span>

                              <img
                                onClick={(e) => changeColor("44", "W")}
                                id="teethT44W"
                                src={T44}
                                style={{
                                  width: "26px",
                                  height: "26px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("44", "B")}
                                id="teethT44B"
                                src={T44b}
                                style={{
                                  width: "26px",
                                  height: "26px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "14px",
                                left: "23px",
                                marginLeft: "21px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  left: "-16px",
                                  top: "-5px",
                                }}
                              >
                                13
                              </span>

                              <img
                                onClick={(e) => changeColor("13", "W")}
                                id="teethT13W"
                                src={T13}
                                style={{
                                  width: "24px",
                                  height: "26px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("13", "B")}
                                id="teethT13B"
                                src={T13b}
                                style={{
                                  width: "24px",
                                  height: "26px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "327px",
                                left: "20px",
                                marginLeft: "19px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "22px",
                                  left: "-10px",
                                }}
                              >
                                43
                              </span>

                              <img
                                onClick={(e) => changeColor("43", "W")}
                                id="teethT43W"
                                src={T43}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("43", "B")}
                                id="teethT43B"
                                src={T43b}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "4px",
                                left: "40px",
                                marginLeft: "21px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  left: "-10px",
                                  top: "-15px",
                                }}
                              >
                                12
                              </span>

                              <img
                                onClick={(e) => changeColor("12", "W")}
                                id="teethT12W"
                                src={T12}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("12", "B")}
                                id="teethT12B"
                                src={T12b}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "338px",
                                left: "37px",
                                marginLeft: "18px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  top: "28px",
                                  left: "-3px",
                                  fontWeight: "600",
                                }}
                              >
                                42
                              </span>

                              <img
                                onClick={(e) => changeColor("42", "W")}
                                id="teethT42W"
                                src={T42}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("42", "B")}
                                id="teethT42B"
                                src={T42b}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "-1px",
                                left: "59px",
                                marginLeft: "21px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  left: "7px",
                                  top: "-15px",
                                  fontWeight: "600",
                                }}
                              >
                                11
                              </span>

                              <img
                                onClick={(e) => changeColor("11", "W")}
                                id="teethT11W"
                                src={T11}
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("11", "B")}
                                id="teethT11B"
                                src={T11b}
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "340px",
                                left: "56px",
                                marginLeft: "20px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  top: "30px",
                                  left: "3px",
                                  fontWeight: "600",
                                }}
                              >
                                41
                              </span>

                              <img
                                onClick={(e) => changeColor("41", "W")}
                                id="teethT41W"
                                src={T41}
                                style={{
                                  width: "23px",
                                  height: "27px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("41", "B")}
                                id="teethT41B"
                                src={T41b}
                                style={{
                                  width: "23px",
                                  height: "27px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "-2px",
                                left: "79px",
                                marginLeft: "21px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "-15px",
                                  left: "10px",
                                }}
                              >
                                21
                              </span>

                              <img
                                onClick={(e) => changeColor("21", "W")}
                                id="teethT21W"
                                src={T21}
                                style={{
                                  width: "25px",
                                  height: "29px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("21", "B")}
                                id="teethT21B"
                                src={T21b}
                                style={{
                                  width: "25px",
                                  height: "29px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "340px",
                                left: "75px",
                                marginLeft: "19px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  top: "30px",
                                  left: "10px",
                                  fontWeight: "600",
                                }}
                              >
                                31
                              </span>

                              <img
                                onClick={(e) => changeColor("31", "W")}
                                id="teethT31W"
                                src={T31}
                                style={{
                                  width: "24px",
                                  height: "26px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("31", "B")}
                                id="teethT31B"
                                src={T31b}
                                style={{
                                  width: "24px",
                                  height: "26px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "4px",
                                left: "99px",
                                marginLeft: "19px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "-13px",
                                  left: "20px",
                                }}
                              >
                                22
                              </span>

                              <img
                                onClick={(e) => changeColor("22", "W")}
                                id="teethT22W"
                                src={T22}
                                style={{
                                  width: "25px",
                                  height: "28px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("22", "B")}
                                id="teethT22B"
                                src={T22b}
                                style={{
                                  width: "25px",
                                  height: "28px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "335px",
                                left: "94px",
                                marginLeft: "18px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "30px",
                                  left: "15px",
                                }}
                              >
                                32
                              </span>

                              <img
                                onClick={(e) => changeColor("32", "W")}
                                id="teethT32W"
                                src={T32}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("32", "B")}
                                id="teethT32B"
                                src={T32b}
                                style={{
                                  width: "24px",
                                  height: "25px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "18px",
                                left: "115px",
                                marginLeft: "18px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "-8px",
                                  left: "29px",
                                }}
                              >
                                23
                              </span>

                              <img
                                onClick={(e) => changeColor("23", "W")}
                                id="teethT23W"
                                src={T23}
                                style={{
                                  width: "26px",
                                  height: "26px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("23", "B")}
                                id="teethT23B"
                                src={T23b}
                                style={{
                                  width: "26px",
                                  height: "26px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "329px",
                                left: "110px",
                                marginLeft: "22px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "25px",
                                  left: "20px",
                                }}
                              >
                                33
                              </span>

                              <img
                                onClick={(e) => changeColor("33", "W")}
                                id="teethT33W"
                                src={T33}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("33", "B")}
                                id="teethT33B"
                                src={T33b}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "37px",
                                left: "126px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "2px",
                                  left: "50px",
                                }}
                              >
                                24
                              </span>

                              <img
                                onClick={(e) => changeColor("24", "W")}
                                id="teethT24W"
                                src={T24}
                                style={{
                                  width: "29px",
                                  height: "29px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("24", "B")}
                                id="teethT24B"
                                src={T24b}
                                style={{
                                  width: "29px",
                                  height: "29px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "305px",
                                left: "119px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "21px",
                                  left: "47px",
                                }}
                              >
                                34
                              </span>

                              <img
                                onClick={(e) => changeColor("34", "W")}
                                id="teethT34W"
                                src={T34}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("34", "B")}
                                id="teethT34B"
                                src={T34b}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "60px",
                                left: "135px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "5px",
                                  left: "50px",
                                }}
                              >
                                25
                              </span>

                              <img
                                onClick={(e) => changeColor("25", "W")}
                                id="teethT25W"
                                src={T25}
                                style={{
                                  width: "29px",
                                  height: "29px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("25", "B")}
                                id="teethT25B"
                                src={T25b}
                                style={{
                                  width: "29px",
                                  height: "29px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "286px",
                                left: "128px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "16px",
                                  left: "50px",
                                }}
                              >
                                35
                              </span>

                              <img
                                onClick={(e) => changeColor("35", "W")}
                                id="teethT35W"
                                src={T35}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("35", "B")}
                                id="teethT35B"
                                src={T35b}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "85px",
                                left: "140px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "6px",
                                  left: "54px",
                                }}
                              >
                                26
                              </span>

                              <img
                                onClick={(e) => changeColor("26", "W")}
                                id="teethT26W"
                                src={T26}
                                style={{
                                  width: "31px",
                                  height: "31px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("26", "B")}
                                id="teethT26B"
                                src={T26b}
                                style={{
                                  width: "31px",
                                  height: "31px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "260px",
                                left: "135px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "13px",
                                  left: "55px",
                                }}
                              >
                                36
                              </span>

                              <img
                                onClick={(e) => changeColor("36", "W")}
                                id="teethT36W"
                                src={T36}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("36", "B")}
                                id="teethT36B"
                                src={T36b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "111px",
                                left: "145px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "6px",
                                  left: "54px",
                                }}
                              >
                                27
                              </span>

                              <img
                                onClick={(e) => changeColor("27", "W")}
                                id="teethT27W"
                                src={T27}
                                style={{
                                  width: "31px",
                                  height: "31px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("27", "B")}
                                id="teethT27B"
                                src={T27b}
                                style={{
                                  width: "31px",
                                  height: "31px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "232px",
                                left: "142px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "17px",
                                  left: "55px",
                                }}
                              >
                                37
                              </span>

                              <img
                                onClick={(e) => changeColor("37", "W")}
                                id="teethT37W"
                                src={T37}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("37", "B")}
                                id="teethT37B"
                                src={T37b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "140px",
                                left: "148px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "7px",
                                  left: "55px",
                                }}
                              >
                                28
                              </span>

                              <img
                                onClick={(e) => changeColor("28", "W")}
                                id="teethT28W"
                                src={T28}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("28", "B")}
                                id="teethT28B"
                                src={T28b}
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                            <span
                              style={{
                                display: "block",
                                position: "absolute",
                                top: "199px",
                                left: "146px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  fontWeight: "600",
                                  top: "20px",
                                  left: "57px",
                                }}
                              >
                                38
                              </span>

                              <img
                                onClick={(e) => changeColor("38", "W")}
                                id="teethT38W"
                                src={T38}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                }}
                              />

                              <img
                                onClick={(e) => changeColor("38", "B")}
                                id="teethT38B"
                                src={T38b}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  cursor: "pointer",
                                  marginLeft: "18px",
                                  display: "none",
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "430px",
                            boxSizing: "border-box",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "185px",
                              float: "left",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                padding: " 6px 0px",
                                fontSize: "15px",
                                color: "#0091d0",
                                fontWeight: "600",
                              }}
                            >
                              Enclosures
                            </div>
                            <div
                              style={{
                                border: "1px solid #000",
                                padding: "5px",
                                height: "325px",
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "185")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="185"
                                  id="teethTC185"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span> Bite</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "186")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="186"
                                  id="teethTC186"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Articulator</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "187")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="187"
                                  id="teethTC187"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Shade Tooth /Tab</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "188")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="188"
                                  id="teethTC188"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Pre-op models</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "189")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="189"
                                  id="teethTC189"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Model-with-temporary</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "190")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="190"
                                  id="teethTC190"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Additional Model</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "191")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="191"
                                  id="teethTC191"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Old Work (Adjust/Remake)</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "192")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="192"
                                  id="teethTC192"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Tray enclosed</span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "227px",
                              float: "left",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                padding: "6px 0px",
                                fontSize: "15px",
                                color: " #0091d0",
                                fontWeight: "600",
                              }}
                            >
                              Implant
                            </div>
                            <div
                              style={{
                                border: "1px solid #000",
                                padding: "5px",
                                borderLeft: "0",
                                height: "325px",
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "8px",
                                  clear: "both",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  onChange={(event) => checkedBox(event, "193")}
                                  type="checkbox"
                                  name="txtMyFormNew"
                                  value="193"
                                  id="teethTC193"
                                  disabled={flag}
                                  style={{
                                    margin: "0px 5px 0 0px",
                                    float: "left",
                                  }}
                                />
                                <span>Implant screw driver</span>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  No. of impression replica
                                </span>
                                <div style={{ wordBreak: "break-word" }}>
                                  {flag == true ? (
                                    <input
                                      type="text"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "20px",
                                      }}
                                      maxlength="2"
                                      id="txtImpression"
                                      disabled
                                      value={txtImpression}
                                      name="txtImpression"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id="txtImpression"
                                      name="txtImpression"
                                      maxlength="2"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  No. of impression coping
                                </span>
                                <div style={{ wordBreak: "break-word " }}>
                                  {flag == true ? (
                                    <input
                                      type="text"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                      }}
                                      maxlength="2"
                                      id="txtCoping"
                                      disabled
                                      value={txtCoping}
                                      name="txtCoping"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id="txtCoping"
                                      name="txtCoping"
                                      maxlength="2"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "20px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  No. of abutment
                                </span>
                                <div style={{ wordBreak: "break-word" }}>
                                  {flag == true ? (
                                    <input
                                      type="text"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                      }}
                                      maxlength="2"
                                      id="txtAbutment"
                                      disabled
                                      value={txtAbutment}
                                      name="txtAbutment"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id="txtAbutment"
                                      name="txtAbutment"
                                      maxlength="2"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "70px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  No. of screw
                                </span>
                                <div style={{ wordBreak: "break-word" }}>
                                  {flag == true ? (
                                    <input
                                      type="text"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                      }}
                                      maxlength="2"
                                      id="txtScrew"
                                      disabled
                                      value={txtScrew}
                                      name="txtScrew"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id="txtScrew"
                                      name="txtScrew"
                                      maxlength="2"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "92px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  No. of castable abutment
                                </span>
                                <div style={{ wordBreak: "break-word" }}>
                                  {flag == true ? (
                                    <input
                                      type="text"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                      }}
                                      maxlength="2"
                                      id="txtCastable"
                                      disabled
                                      value={txtCastable}
                                      name="txtCastable"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id="txtCastable"
                                      name="txtCastable"
                                      maxlength="2"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  No. of temporary abutment
                                </span>
                                <div style={{ wordBreak: "break-word" }}>
                                  {flag == true ? (
                                    <input
                                      type="text"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                      }}
                                      maxlength="2"
                                      id="txtTemporary"
                                      disabled
                                      value={txtTemporary}
                                      name="txtTemporary"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id="txtTemporary"
                                      name="txtTemporary"
                                      maxlength="2"
                                      style={{
                                        width: "50px",
                                        border: "1px solid #000",
                                        padding: "2px 5px",
                                        marginLeft: "8px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>

                              {/*  */}
                              <div
                                style={{
                                  width: "100%",
                                  paddingBottom: "12px",
                                  clear: "both",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ float: "left" }}>Others</span>
                                <div style={{ wordBreak: "break-word" }}>
                                  {flag == true ? (
                                    <textarea
                                      disabled
                                      id="txImplantOthers"
                                      value={txImplantOthers}
                                      style={{
                                        border: "1px solid rgb(0, 0, 0)",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                        resize: "none",
                                      }}
                                    ></textarea>
                                  ) : (
                                    <textarea
                                      id="txImplantOthers"
                                      style={{
                                        border: "1px solid rgb(0, 0, 0)",
                                        padding: "2px 5px",
                                        marginLeft: "5px",
                                        resize: "none",
                                      }}
                                    ></textarea>
                                  )}
                                </div>
                              </div>
                              {/*  */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ float: "left", width: "280px" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        border: "1px solid #000",
                        float: "left",
                        width: "290px",
                      }}
                    >
                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                          }}
                        >
                          Trial
                        </span>
                      </div>
                      <div
                        style={{
                          width: "55%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "201")}
                          id="teethTC201"
                          type="checkbox"
                          name="txtMyFormNew"
                          value="201"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Red Wax</span>
                      </div>
                      <div
                        style={{
                          width: "45%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <div style={{ float: "left" }}>Date&nbsp;:</div>{" "}
                        <div style={{ wordBreak: "break-word", float: "left" }}>
                          {flag == true ? (
                            <Datepicker
                              autoComplete="off"
                              required
                              className="Datepicker pa2 LborderdentalPrint  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              id="fromdate"
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              disabled
                              value={
                                labTestRedDate === "Invalid date"
                                  ? ""
                                  : labTestRedDate
                              }
                            />
                          ) : (
                            <Datepicker
                              autoComplete="off"
                              required
                              className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              selected={redDate}
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              onChange={(date) => {
                                redDateChange(date);
                              }}
                              id="fromdate"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "55%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "202")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="202"
                          id="teethTC202"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>White Wax</span>
                      </div>
                      <div
                        style={{
                          width: "45%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <div style={{ float: "left" }}>Date&nbsp;:</div>{" "}
                        <div style={{ wordBreak: "break-word", float: "left" }}>
                          {flag == true ? (
                            <Datepicker
                              autoComplete="off"
                              required
                              className="Datepicker pa2 LborderdentalPrint  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              id="fromdate"
                              disabled
                              value={
                                labTestWhiteDate === "Invalid date"
                                  ? ""
                                  : labTestWhiteDate
                              }
                            />
                          ) : (
                            <Datepicker
                              autoComplete="off"
                              required
                              selected={whiteDate}
                              onChange={(date) => {
                                whiteDateChange(date);
                              }}
                              className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              id="fromdate"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "55%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "203")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="203"
                          id="teethTC203"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Metal</span>
                      </div>
                      <div
                        style={{
                          width: "45%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <div style={{ float: "left" }}>Date&nbsp;:</div>{" "}
                        <div style={{ wordBreak: "break-word", float: "left" }}>
                          {flag == true ? (
                            <Datepicker
                              autoComplete="off"
                              required
                              className="Datepicker pa2 LborderdentalPrint  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              id="fromdate"
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              disabled
                              value={
                                labTestMetalDate === "Invalid date"
                                  ? ""
                                  : labTestMetalDate
                              }
                            />
                          ) : (
                            <Datepicker
                              autoComplete="off"
                              selected={metalDate}
                              onChange={(date) => {
                                metalDateChange(date);
                              }}
                              required
                              className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              id="fromdate"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "55%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "204")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="204"
                          id="teethTC204"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Bisque (body)</span>
                      </div>
                      <div
                        style={{
                          width: "45%",
                          float: "left",
                          paddingTop: "5px",
                          display: "flex",
                        }}
                      >
                        <div style={{ float: "left" }}>Date&nbsp;:</div>{" "}
                        <div style={{ wordBreak: "break-word", float: "left" }}>
                          {flag == true ? (
                            <Datepicker
                              autoComplete="off"
                              required
                              className="Datepicker pa2 LborderdentalPrint  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              id="fromdate"
                              disabled
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              value={
                                labTestBisqueDate === "Invalid date"
                                  ? ""
                                  : labTestBisqueDate
                              }
                            />
                          ) : (
                            <Datepicker
                              autoComplete="off"
                              selected={bisqueDate}
                              onChange={(date) => {
                                bisqueDateChange(date);
                              }}
                              required
                              onKeyDown={(e) => e.preventDefault()}
                              onChangeRaw={handleDateChangeRaw}
                              className="Datepicker pa2 LborderdentalPrint LborderdentalPrintBorderbtm  "
                              calendarClassName="rasta-stripes"
                              dateFormat="dd-MM-yyyy"
                              id="fromdate"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid #000",
                        float: "left",
                        width: "290px",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                          }}
                        >
                          Die Preparation
                        </span>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "209")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="209"
                          id="teethTC209"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Yes</span>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "210")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="210"
                          id="teethTC210"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>No</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <div style={{ float: "left", color: "#0091d0" }}>
                          Margin Preparation :
                        </div>
                        <div
                          style={{
                            wordBreak: "break-word",
                            float: "left",
                            color: "#0091d0",
                          }}
                        >
                          {flag == true ? (
                            <input
                              type="text"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "20px",
                              }}
                              maxlength="2"
                              id="txtPrep"
                              disabled
                              value={txtPrep}
                              name="txtPrep"
                            />
                          ) : (
                            <input
                              type="text"
                              id="txtPrep"
                              maxlength="2"
                              name="txtPrep"
                              style={{
                                width: "50px",
                                border: "1px solid #000",
                                padding: "2px 5px",
                                marginLeft: "20px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid #000",
                        float: "left",
                        width: "290px",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                          }}
                        >
                          Coping Design (please tick)
                        </span>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "212")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="212"
                          id="teethTC212"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Individual</span>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "213")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="213"
                          id="teethTC213"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <span>Splinted (Joined)</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "214")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="214"
                          id="teethTC214"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div
                          style={{
                            width: "22px",
                            float: "left",
                            paddingRight: "5px",
                          }}
                        >
                          <img src={Sanitary} style={{ width: "100%" }} />
                        </div>
                        <span>Lingual Metal Collar</span>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid #000",
                        float: "left",
                        width: "290px",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                          }}
                        >
                          Pontic Design ( Please tick one )
                        </span>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "215")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="215"
                          id="teethTC215"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div
                          style={{
                            width: "22px",
                            float: "left",
                            paddingRight: "5px",
                          }}
                        >
                          <img src={Sanitary1} style={{ width: "100%" }} />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            paddingLeft: "5px",
                          }}
                        >
                          Sanitary
                        </div>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "216")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="216"
                          id="teethTC216"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div
                          style={{
                            width: "30px",
                            float: "left",
                            paddingRight: "5px",
                          }}
                        >
                          <img src={FullRidge} style={{ width: "100%" }} />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            paddingLeft: "5px",
                          }}
                        >
                          Full Ridge
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "217")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="217"
                          id="teethTC217"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div
                          style={{
                            width: "30px",
                            float: "left",
                            paddingRight: "5px",
                          }}
                        >
                          <img src={Modified} style={{ width: "100%" }} />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            paddingLeft: "5px",
                          }}
                        >
                          Modified
                        </div>
                      </div>
                      <div
                        style={{
                          width: "60%",
                          margin: "0 auto",
                          clear: "both",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            float: "left",
                            paddingTop: "5px",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "218")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="218"
                            id="teethTC218"
                            disabled={flag}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <div
                            style={{
                              width: "30px",
                              float: "left",
                              paddingRight: "5px",
                            }}
                          >
                            <img
                              src={Bullet}
                              style={{ width: "100%", height: "40px" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              paddingLeft: "18px",
                            }}
                          >
                            Bullet
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            float: "left",
                            paddingTop: "5px",
                          }}
                        >
                          <input
                            onChange={(event) => checkedBox(event, "219")}
                            type="checkbox"
                            name="txtMyFormNew"
                            value="219"
                            id="teethTC219"
                            disabled={flag}
                            style={{ margin: "0px 5px 0 0px", float: "left" }}
                          />
                          <div
                            style={{
                              width: "40px",
                              float: "left",
                              paddingRight: "5px",
                            }}
                          >
                            <img src={Ovate} style={{ width: "100%" }} />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              paddingLeft: "22px",
                              paddingTop: "6px",
                            }}
                          >
                            Ovate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="pdfView"
                      style={{
                        padding: "10px",
                        border: "1px solid #000",
                        float: "left",
                        width: "290px",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Proximal Contacts
                        </span>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "220")}
                          type="checkbox"
                          name="txtMyFormNew"
                          id="teethTC220"
                          value="220"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Broad</div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "221")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="221"
                          id="teethTC221"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Point</div>
                      </div>

                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Translucency
                        </span>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "222")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="222"
                          id="teethTC222"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Regular</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "223")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="223"
                          id="teethTC223"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Low</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "224")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="224"
                          id="teethTC224"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>High</div>
                      </div>

                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Texture
                        </span>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "225")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="225"
                          id="teethTC225"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>No</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "226")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="226"
                          id="teethTC226"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Low</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "227")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="227"
                          id="teethTC227"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>High</div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          paddingTop: "8px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "228")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="228"
                          id="teethTC228"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>
                          Follow adjacent tooth texture
                        </div>
                      </div>

                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Glaze
                        </span>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "229")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="229"
                          id="teethTC229"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Low</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "230")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="230"
                          id="teethTC230"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Regular</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "231")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="231"
                          id="teethTC231"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>High</div>
                      </div>

                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Value
                        </span>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "232")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="232"
                          id="teethTC232"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Regular</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "233")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="233"
                          id="teethTC233"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Low</div>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "234")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="234"
                          id="teethTC234"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>High</div>
                      </div>

                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Occlusal Staining
                        </span>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "235")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="235"
                          id="teethTC235"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>No</div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "236")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="236"
                          id="teethTC236"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Light</div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "237")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="237"
                          id="teethTC237"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Medium</div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "238")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="238"
                          id="teethTC238"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Dark</div>
                      </div>

                      <div style={{ width: "100%", float: "left" }}>
                        <span
                          style={{
                            float: "left",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0091d0",
                            padding: "10px 0",
                          }}
                        >
                          Stains & Effect
                        </span>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "239")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="239"
                          id="teethTC239"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>Enamel Cracks</div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          onChange={(event) => checkedBox(event, "240")}
                          type="checkbox"
                          name="txtMyFormNew"
                          value="240"
                          id="teethTC240"
                          disabled={flag}
                          style={{ margin: "0px 5px 0 0px", float: "left" }}
                        />
                        <div style={{ float: "left" }}>White Spots</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="formBottom" style={{ paddingTop: "5px" }}>
                <div
                  style={{
                    clear: "both",
                    overflow: "hidden",
                    lineHeight: "21px",
                  }}
                >
                  <span style={{ float: "left" }}>Notes</span>
                  <div style={{ wordBreak: "break-word" }}>
                    {flag == true ? (
                      <input
                        type="text"
                        maxlength="250"
                        style={{
                          width: "100%",
                          height: "60px",
                          resize: "none",
                          border: "1px solid #000",
                          padding: "2px 5px",
                        }}
                        id="FormNewnotes"
                        disabled
                        value={FormNewnotes}
                        name="FormNewnotes"
                      />
                    ) : (
                      <textarea
                        name="FormNewnotes"
                        id="FormNewnotes"
                        maxlength="250"
                        style={{
                          width: "100%",
                          height: "60px",
                          resize: "none",
                          border: "1px solid #000",
                          padding: "2px 5px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    clear: "both",
                    overflow: "hidden",
                    marginTop: "5px",
                  }}
                >
                  <div>
                    <div style={{ float: "left", width: "650px" }}>
                      <span style={{ float: "left" }}>
                        In case of any clarification. Please contact Dr:{" "}
                      </span>
                      <div>
                        {doctorData.firstName} {doctorData.lastName}
                      </div>
                    </div>
                    <div style={{ float: "left", width: "330px" }}>
                      <span style={{ float: "left" }}>Contact No : </span>
                      <div> &nbsp;{doctorMobile}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    clear: "both",
                    overflow: "hidden",
                    paddingTop: "10px",
                  }}
                >
                  <span style={{ float: "left", paddingTop: "9px" }}>
                    Collection Agent
                  </span>
                  <div
                    style={{
                      wordBreak: "break-word",
                      boxSizing: "border-box",
                      width: "170px",
                      paddingTop: "10px",
                      marginLeft: "15px",
                      float: "left",
                    }}
                  >
                    {flag == true ? (
                      <input
                        type="text"
                        maxlength="20"
                        style={{
                          border: "1px solid #000",
                          padding: "4px 5px",
                          width: "100%",
                          clear: "both",
                          float: "none",
                          boxSizing: "border-box",
                        }}
                        id="marketingExecutive"
                        disabled
                        value={marketingPerson}
                        name="marketingExecutive"
                      />
                    ) : (
                      <input
                        type="text"
                        id="marketingExecutive"
                        maxlength="20"
                        style={{
                          border: "1px solid #000",
                          padding: "4px 5px",
                          width: "100%",
                          clear: "both",
                          float: "none",
                          boxSizing: "border-box",
                        }}
                      />
                    )}
                  </div>
                  <span style={{ float: "right", paddingTop: "5px" }}>
                    Name & Signature of Doctor/ Clinic Staff
                  </span>
                </div>
              </div>

              <div
                class="formBottomBtn"
                style={{
                  paddingTop: "15px",
                  textAlign: "right",
                  paddingBottom: "15px",
                  display: "inline-flex",
                  width: "100%",
                  flexFlow: "row",
                  justifyContent: "end",
                }}
              >
                <span
                  onClick={printPage}
                  id="print"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "10px",
                    padding: "7px 20px",
                    fontSize: "14px",
                    background: "#2d1152",
                    border: "0",
                    color: "#fff",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Print
                </span>
                <span
                  id="savePrint"
                  disabled={saveflag}
                  onClick={(e) => {
                    saveMetalCeramic(e);
                    printPage(e);
                  }}
                  class="saveClr buttonsmall"
                  tabindex="15"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "10px",
                    padding: "7px 20px",
                    fontSize: "14px",
                    background: "#dbdbdb",
                    border: "0",
                    color: "#000",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Save&Print
                </span>

                <span
                  disabled={saveflag}
                  onClick={(e) => {
                    saveMetalCeramic(e);
                    metalCeramicListing(e);
                  }}
                  id="send"
                  class="saveClr buttonsmall"
                  tabindex="15"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "10px",
                    padding: "7px 20px",
                    fontSize: "14px",
                    background: "#dbdbdb",
                    border: "0",
                    color: "#000",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Send
                </span>

                <input
                  type="submit"
                  name="_action_saveMetalForm"
                  value="Send"
                  id="btnSave"
                  class="saveClr buttonsmall"
                  tabindex="15"
                  style={{
                    padding: " 7px 20px",
                    height: "30px",
                    textAlign: "center",
                    border: "1px solid #000",
                    display: "none",
                  }}
                />
                <a
                  href="/Patients/labOrderDentalAdd"
                  id="cancel"
                  class="saveClr buttonsmall"
                  tabindex="15"
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "0px",
                    padding: "7px 20px",
                    fontSize: "14px",
                    background: "#dbdbdb",
                    border: "0",
                    color: "#000",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default LabOrderMetalCeramic;
