import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright'; 
import { callingAPI } from "../config"; 
 function RefereToaDoctor() {  
  const [doctorName, setDoctorName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [notes, setNotes] = useState("");
  const sendList = (e) => {
    var flag = true;
    var regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (!doctorName) {
			alert("Please enter Doctor Name")
			document.getElementById('doctorName').focus()
      flag = false;
    }
    else if (!regex.test(emailId)) {
			alert("Please enter valid Email Id")
			document.getElementById('emailId').focus()
      flag = false;
    }
    if(flag === true){
    const postData = {
    "siteId": window.sessionStorage.getItem("siteId"),
    "patientId": window.sessionStorage.getItem("memberId"),
    "referDrEmailId": emailId,
    "doctorId":window.sessionStorage.getItem('patientDoctorId'),
    "drNote": notes,
    "hospitalId":localStorage.getItem('hospitalId'),
    "referDrName":doctorName
    };
    callingAPI('commonUserFunctions/referToDoctorSave', postData)
      .then((data) => {
        if (data.data.success === "1") {
         alert("Saved Successfully")
         if(e==="Print"){
         // window.sessionStorage.removeItem('sendPrintreferDr')
          window.sessionStorage.setItem('htmlcontentreferDr', data.data.result.jsonMailOptions.html);
          window.sessionStorage.setItem('sendPrintreferDr', 1);
          // window.location.href='/patients/referToDoctorPrint'
          window.open('/patients/referToDoctorPrint', '_blank');
            
         
         }
         else{
          window.location.reload();
         }
        }
      })
  } }
  useEffect(() => {
		// if(window.sessionStorage.getItem('sendPrintreferDr')===1)
    // {
    //   window.open(
    //     `${process.env.PUBLIC_URL}/patients/referToDoctorPrint`,
    //     "_blank"
    // );
    // }
		
	}, []);
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										 
									<div className="treatmentProfileCover">
              <div className="treatmentPlanHead">
                  <span className="treatmentPlanName">REFER TO A DOCTOR</span>
                  <div className="treatmentPlanBtn">
                      <span className="clinicalSave" onClick={(e) => sendList()}>Send</span>
                     <span className="clinicalSave" onClick={(e) => sendList("Print")}>Send & Print</span>
                  </div>
              </div>
              <div className="treatmentPlanContent">
                <div className="profileCover">
                    <div className="practiceManagementGrid">
                        <div className="row rowMargin">
                            <div className="col-md-3 col-lg-3 practiceManagementList">
                              <label className="labelTextField txtRight">Doctor Name<sup style={{color:"red"}}>*</sup></label>
                            </div>
                            <div className="col-md-6 col-lg-6 practiceManagementList">
                              <input type="text" className="inputField" placeholder="Dr. Name" id="doctorName" onChange={(e) => setDoctorName(e.target.value)}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-3 col-lg-3 practiceManagementList">
                              <label className="labelTextField txtRight" >Email Id<sup style={{color:"red"}}>*</sup></label>
                            </div>
                            <div className="col-md-6 col-lg-6 practiceManagementList">
                              <input type="text" className="inputField" placeholder="Email Id" id="emailId"onChange={(e) => setEmailId(e.target.value)}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-3 col-lg-3 practiceManagementList">
                              <label className="labelTextField txtRight">Notes</label>
                            </div>
                            <div className="col-md-6 col-lg-6 practiceManagementList">
                              <textarea className="textareaField" placeholder="Notes" id="notes" onChange={(e) => setNotes(e.target.value)}/>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>

              </div>
          </div>

										 
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
			</div>
			
			<Footer />
			</div>
		</div>
	);
}
export default RefereToaDoctor;
 
