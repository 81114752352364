import React, { useState, useEffect } from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import { useLocation } from 'react-router-dom';
import { callingAPI } from '../config';
function LabTestPrint(props) {
	const location = useLocation();
	const [printLabTestDetails, setprintLabTestDetails] = useState([])
	const [hospitalName, sethospitalName] = useState('')
	const [Drname, setDrname] = useState('')
	const [Drvalue, setDrvalue] = useState('')
	const [hospitalAddress, sethospitalAddress] = useState('')
	const [hospitalCity, sethospitalCity] = useState('')
	const [hospitalHeaderLeftText, sethospitalHeaderLeftText] = useState('')
	const [hospitalHeaderRightText, sethospitalHeaderRightText] = useState('')
	const [memberFirstName, setmemberFirstName] = useState('')
	const [memberLastName, setmemberLastName] = useState('')
	const [memberDOB, setmemberDOB] = useState('')
	const [memberMrNo, setmemberMrNo] = useState('')
	const [memberPrNo, setmemberPrNo] = useState('')
	const [SecuserMobileNumber, setSecuserMobileNumber] = useState('')
	const [memberAge, setmemberAge] = useState('')
	const [secUserEmailid, setsecUserEmailid] = useState('')
	const [memberGender, setmemberGender] = useState('')
	const [hospitalDescription, sethospitalDescription] = useState('')
	const [hospitalEmailId, sethospitalEmailId] = useState('')
	const [hospitalLogo, sethospitalLogo] = useState('')
	const [hospitalImagename, sethospitalImagename] = useState('');
	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
	const labTestprint = () => {
		const PostData = {
			"userId": window.sessionStorage.getItem("userId"),
			"hidMemberId": window.sessionStorage.getItem("memberIdvalue").toString(),
			"hidDate": window.sessionStorage.getItem("testDatevalueprint"),
			"hidType": window.sessionStorage.getItem("personId") != "" ? "selected" : "all",
			"hidselectedMedicineIdList": window.sessionStorage.getItem("personId")
		};
		callingAPI('printLabTestDetails/printLabTestDetails', PostData).then((data) => {
			if (data.data.success === '1') {
				setprintLabTestDetails(data.data.result.testGroupData);
				sethospitalName(data.data.result.hospitalDetails[0].hospitalName)
				sethospitalAddress(data.data.result.hospitalDetails[0].hospitalAddress)
				sethospitalCity(data.data.result.hospitalDetails[0].hospitalCity)
				sethospitalHeaderLeftText(data.data.result.hospitalDetails[0].hospitalHeaderLeftText)
				sethospitalHeaderRightText(data.data.result.hospitalDetails[0].hospitalHeaderRightText)
				setmemberFirstName(data.data.result.memberDetails[0].memberFirstName)
				setmemberLastName(data.data.result.memberDetails[0].memberLastName)
				setmemberDOB(data.data.result.testDate)
				setmemberMrNo(data.data.result.memberDetails[0].memberMrNo)
				setmemberPrNo(data.data.result.memberDetails[0].memberPrNo)
				setSecuserMobileNumber(data.data.result.memberDetails[0].SecuserMobileNumber)
				setmemberAge(data.data.result.memberDetails[0].memberAge)
				setsecUserEmailid(data.data.result.memberDetails[0].secUserEmailid)
				setmemberGender(data.data.result.memberDetails[0].memberGender)
				sethospitalDescription(data.data.result.hospitalDetails[0].hospitalDescription)
				sethospitalEmailId(data.data.result.hospitalDetails[0].hospitalEmailId)
				sethospitalLogo(data.data.result.hospitalDetails[0].hospitalLogo)
				setDrname(data.data.result.doctorDetails[0].doctorDisplayName)
				setDrvalue(data.data.result.doctorDetails[0].title)
				// setCount(data.data.result.totalCount);
				// setDataFlag(true);
			}
			else {
				setprintLabTestDetails([])
				// setDataFlag(true);
				// setLabOrderDetailsListData([]);
			}
		});
	};
	useEffect(() => {
		labTestprint()
		callingAPI('commonUserFunctions/headerFooterLogoPrints', {
			hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
			doctorId: ""
		}).then((res) => {
			sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
		});
	}, [])
	const onPrintButtonClick = () => {
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	};
	return (
		<div className="labTestPrintWrap">
			<div align="center" style={{ width: "540px", height: "595px", margin: "0px auto", fontFamily: "SourceSansPro-Regular" }}>
				<table style={{ width: "100%", marginTop: "0px" }} border="0">
					<thead>
						<tr>
							<th style={{ fontWeight: "400" }}>
								<div class="printOutHeaderSec  PrescPrint">
									<table style={{ width: "100%" }}>
										<tbody>
											<tr height="80px">
												<td colspan="3" valign="middle" align="center">
													<div style={{ width: "100%", float: "none" }}>
														<p style={{ fontWeight: "400", fontSize: "14px", clear: "both", width: "100%", margin: "0px 0 3px 0px" }}>
															<span style={{ marginTop: "-5px", display: " inline-block", width: "100%" }}>
																{hospitalImagename != (null || "") ?
																	<img
																		src={getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"}
																		style={{ display: "block", width: "auto", maxHeight: "65px", margin: "auto" }} align="middle"
																	/> : <img src={Logo}
																		style={{ display: "block", width: "auto", maxHeight: "65px", margin: "auto" }} align="middle" />}
															</span>
														</p>
													</div>
													<div class="practicePrintName" style={{ width: "100%" }}>
														<p style={{ fontWeight: "400", fontSize: "14px", clear: "both", width: "100%", marginBottom: "1px", marginTop: "0" }}>
															{hospitalName}</p>
														<p style={{ fontWeight: "400", fontSize: "12px !important", clear: "both", width: "100%", margin: "0" }}>{hospitalAddress + " , " + hospitalCity}</p>
													</div>
												</td>
											</tr>
											<tr>
												<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
											</tr>
											<tr>
												<td width="50%" colspan="2" align="left" style={{ fontSize: "12px", wordBreak: "break-all" }}>
													{hospitalHeaderLeftText}
												</td>
												<td width="50%" align="right" style={{ fontSize: "12px", wordBreak: "break-all" }}>
													{hospitalHeaderRightText}
												</td>
											</tr>
											<tr>
												<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
											</tr>
											<tr>
												<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "left" }}>Name : {memberFirstName} {memberLastName}</td>
												<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "right" }}>Date : {memberDOB} </td>
											</tr>
											{/* <tr>
                                                <td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>MR No : {memberMrNo}</td>
                                                <td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Phone No : {SecuserMobileNumber}</td>
										</tr> */}
											<tr>
												<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Age : {memberAge}
												</td>
												<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Email Id : {secUserEmailid}</td>
											</tr>
											<tr>
												<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Sex : {memberGender}</td>
												<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Phone No : {SecuserMobileNumber}</td>
											</tr>
											<tr>
												{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
													(<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>MR No : {memberMrNo}</td>) :
													(<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>PR No : {memberPrNo}</td>)
												}
												{/* <td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>PR No :</td> */}
											</tr>
											<tr>
												<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
											</tr>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table style={{ width: "100%" }}>
										<tbody>
											<tr style={{ borderBottom: "2px solid black " }}>
												<td style={{ borderBottom: "2px solid black", fontWeight: "500", fontSize: "12px", lineHeight: "15px", width: "25%" }}>Test Name</td>
												<td style={{ borderBottom: "2px solid black", fontWeight: "500", fontSize: "12px", lineHeight: "15px", width: "22%" }}>Specimen</td>
												<td style={{ borderBottom: "2px solid black", fontWeight: "500", fontSize: "12px", lineHeight: "15px", width: "22%" }}>Test Type</td>
												<td style={{ borderBottom: "2px solid black", fontWeight: "500", fontSize: "12px", lineHeight: "15px", width: "20%" }}>notes</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						{printLabTestDetails.map((item) => (
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table style={{ width: "100%" }}>
											<tbody>
												<tr>
													<td style={{ fontSize: "12px", lineHeight: "15px", color: "#393939", textAlign: "left", width: "25%" }}>
														<p style={{ margin: "2px 0", fontSize: "12px !important", wordBreak: "break-word" }}>  {item.testName}    </p>
													</td>
													<td style={{ fontSize: "12px", lineHeight: "15px", color: "#393939", textAlign: "left", width: "22%" }}>
														<p style={{ margin: " 2px 0", fontSize: "12px !important", wordBreak: "break-word" }}> {item.specimen} </p>
													</td>
													<td style={{ fontSize: "12px", lineHeight: "15px", color: "#393939", textAlign: "left", width: "22%" }}>
														<p style={{ margin: "2px 0", fontSize: "12px !important", wordBreak: "break-word" }}> {item.testType} </p>
													</td>
													<td style={{ fontSize: "12px", lineHeight: "15px", color: "#393939", textAlign: "right", padding: "0 5px 0 0", width: "18%" }}>
														<p style={{ margin: "2px 0", fontSize: "12px !important", wordBreak: "break-word", textAlign: "left" }}> {item.labOrdercomments} </p>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td>
								<div class="printOutFooterSec">
									<table style={{ width: "100%" }}>
										<tbody>
											<tr>
												<td width="25%" colspan="2" align="left" style={{ wordBreak: "break-all" }}> <div align="justify" style={{ fontSize: "10px" }}> {hospitalEmailId}</div></td>
												<td width="50%" align="right" style={{ fontSize: "10px", wordBreak: " break-all" }}> {Drvalue + " " + Drname}</td>
											</tr>
											<tr>
												<td colspan="3"><div align="justify" class="practiceFootBtm">{hospitalDescription}</div></td>
											</tr>
											<tr>
												<td colspan="3" style={{ paddingTop: "10px" }}>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tfoot>
				</table>
				<div class="printBtm" style={{ textAlign: "center", clear: "both", overflow: "hidden" }}>
					<p
						id="printButton"
						onClick={onPrintButtonClick}
						style={{ width: "70px", textAlign: "center", paddingTop: "5px", lineHeight: "18px", display: "inline-block", fontWeight: "bold", fontSize: "14px", color: "white", background: "rebeccapurple none repeat scroll 0% 0%", marginTop: "2px", cursor: "pointer", borderRadius: "5px", marginBottom: "2px", height: "30px", fontFamily: "Verdana" }}>Print</p>
				</div>
			</div>
		</div>
	);
}
export default LabTestPrint;