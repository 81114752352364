import React from 'react';
// Patient medical history
function NoMedication() {
	return (
		<div className="practiceManagementForm noMedicationWrap" >
			<div className="noDataFound noMedication">
				<p>No Medication Details Found</p>
			</div>
		</div>
	);
}
export default NoMedication;
