import React, { useState, useEffect } from "react";
import Footer from "../layout/footer";
import LoginHeader from "../layout/loginHeader";
function About() {

  return (
    <div>
      <LoginHeader />
      <div className="container">
        <div className="about_wrapper">
          <h3 className="mainhead_ida">About</h3>
          <p className="about_paratext">
            IDAKSBAssist.com is a white labelled version of Purple health’s online clinic management solution specifically tailored  and provided at subsidised rates for IDA Kerala State member dentists.
            This application was developed by PurpleHealth with inputs from  IDA Kerala State in order to provide its members with a custom made clinic management system and it is being regularly updated.
          </p>
        </div>
      </div>
      <Footer />
    </div>

  );
}
export default About;
