import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import HomeleftMenu from '../Dashboard/homeleftMenu';
import { callingAPI } from '../config';
import { Link } from 'react-router-dom';
import Loader from '../Common/loader';
import EyeIcon from '../images/icons/EyeIcon.png';
import EyeIconClose from '../images/icons/EyeIconClose.png';
import BillingLeft from '../billingLogin/billingLeft';
import LabLeft from '../labLogin/labLeft';
import PharmacyLeft from '../pharmacyLogin/pharmacyLeft';
function Changepassword() {
	const [state, setState] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [doctorImage, setDoctorImage] = useState([]);
	const [isData, setIsData] = useState(true);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errmsg, setErrmsg] = useState('');
	const [doctorMemberDetails, setDoctorMemberDetails] = useState([]);
	const [doctorSpecialityDetails, setDoctorSpecialityDetails] = useState([]);
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const [isRevealPwd1, setIsRevealPwd1] = useState(false);
	const [isRevealPwd2, setIsRevealPwd2] = useState(false);
	const [rolesHeader, setRolesHeader] = useState('');
	const onChange = (event) => {
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};


	// document.getElementById('currentPassword').addEventListener('keypress', function(event){
	// document.querySelector('input[type=password]').keyup(function(event){

	// if (event.keyCode == 13) {
	//         alert("jhzg")
	//         event.preventDefault();
	// document.querySelector(this).nextElementSibling.focus()
	//     }
	// });
	// document.querySelector('input[type=password]').keyup(function(event){

	//     if (event.keyCode == 13) {
	//         alert("jhzg")
	// // //         event.preventDefault();
	// document.querySelector(this).nextElementSibling.focus()
	//     }
	// });
	const handleKeypress = (event) => {
		//it triggers by pressing the enter key
		//
		if (event.key === 'Enter') {
			//    alert("ssssssssssssssssss")
			changePasswordHandler();
		}
	};

	const changePasswordHandler = () => {
		setIsData(false)
		let errMsg = '';
		var flag = true;
		if (!currentPassword) {
			alert('Please enter Current Password');
			document.getElementById('currentPassword').focus();
			flag = false;
			setIsData(true)
		} else if (!newPassword) {
			alert('Please enter New Password');
			document.getElementById('newPassword').focus();
			flag = false;
			setIsData(true)
		} else if (!confirmPassword) {
			alert('Please enter Confirm Password');
			document.getElementById('confirmPassword').focus();
			flag = false;
			setIsData(true)
		} else if (newPassword !== confirmPassword) {
			alert('New password and confirm password does not match')
			document.getElementById('newPassword').focus();
			flag = false;
			setIsData(true)
			setNewPassword('');
			setConfirmPassword('');
			//setState({ newPassword: "", confirmPassword: "" })
		}
		setErrmsg(errMsg);
		if (flag === true) {
			const postdata = {
				userId: window.sessionStorage.getItem('userId'),
				currentPassword: currentPassword,
				newPassword: newPassword,
			};
			callingAPI('userLogin/changePassword', postdata)
				.then((response) => {
					setIsData(true)
					if (response.data.success === '1') {
						alert('Password changed successfully. ');

						setNewPassword('');
						setConfirmPassword('');
						setCurrentPassword('');
						// window.location.href = "/Dashboard/welcome";
					} else {
						alert("Current password you entered is wrong.");
						// setNewPassword('');
						// setConfirmPassword('');
						setCurrentPassword('');
						setNewPassword('');
						setConfirmPassword('');
						document.getElementById('currentPassword').focus();
					}
				})
				.catch();
		}
	};
	const getDoctorDetails1 = () => {
		const postData = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('doctorProfile/getDoctorDetails', postData)
			.then((res) => {
				setDoctorMemberDetails(res.data.result.memberDetails ? res.data.result.memberDetails[0] : []);
				setDoctorImage(res.data.result.doctorImage ? res.data.result.doctorImage[0] : 'download.jpg');
				setDoctorSpecialityDetails(res.data.result.specialityDetails);
			})
			.catch();
	};
	useEffect(() => {

		getDoctorDetails1();
		if (window.sessionStorage.getItem('role') !== 'ROLE_DOCTOR' && window.sessionStorage.getItem('role') !== 'ROLE_HOSPITALADMIN') {
			setRolesHeader('billadminChangePassword')
		} else {
			setRolesHeader('')
		}

	}, []);
	// const togglePassword = () => {
	// 	// When the handler is invoked
	// 	// inverse the boolean state of passwordShown
	// 	setPasswordShown(!passwordShown);
	//   };
	return (
		<div>
			<div className="coverWraper innerCoverWraper">
				<Header />

				<div className={"contentWraper contentWraperScroll pulseContentCoverWraper" + " " + rolesHeader}>
					<div className="container">
						<div className="row">
							{isData === false ?
								<Loader />
								: ""}


							{(() => {
								if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
									return (
										<div className="col-md-4">
											<HomeleftMenu doctorImg={doctorImage} doctorName={doctorMemberDetails} specialityDetails={window.sessionStorage.getItem('setDoctorSpeciality')} />
										</div>
									)
								} else if (window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN") {
									return (
										<BillingLeft />
									)
								}
								else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY") {
									return (
										<PharmacyLeft />
									)
								}
								else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB") {
									return (
										<LabLeft />
									)
								} else {
									return (
										<div className="col-md-4">
										</div>
									)
								}
							})()}

							<div className="col-md-8">
								<div className="landingBodyWrap">
									<div class="landingHead changePswd">Change Password</div>
									<div className="changePasswordDiv">
										<div class="landingHead oldPswd">
											Provide your old password & your new password
										</div>
										<div className="practiceManagementGrid profileForm">
											<p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
												{errmsg}
											</p>
											<div className="row rowMargin">
												<div className="col-md-12 col-lg-12 practiceManagementList">
													<label className="labelTextField labelMarginBottom">
														Current Password
													</label>
													<input
														type={isRevealPwd2 ? "text" : "password"}
														autoComplete='off'
														id="currentPassword"
														onKeyPress={handleKeypress}
														name="currentPassword"
														value={currentPassword}
														onChange={(e) => setCurrentPassword(e.target.value)}
														className="inputField"
														placeholder="Type your Current Password" tabIndex="1" autofocus="autofocus"
													/>
													<img className='showHidePassword'
														title={isRevealPwd2 ? "Show password" : " Hide password"}
														src={isRevealPwd2 ? EyeIcon : EyeIconClose}
														onClick={() => setIsRevealPwd2(prevState => !prevState)}
													/>
												</div>
												<div className="col-md-12 col-lg-12 practiceManagementList">
													<label className="labelTextField labelMarginBottom">
														New Password
													</label>
													<input
														type={isRevealPwd ? "text" : "password"}
														id="newPassword"
														autoComplete='off'
														name="newPassword"
														onKeyPress={handleKeypress}
														value={newPassword}
														onChange={(e) => setNewPassword(e.target.value)}
														className="inputField"
														placeholder="New Password" tabIndex="2"
													/>
													{/* <button onClick={togglePassword}>Show</button> */}
													<img className='showHidePassword'
														title={isRevealPwd ? "Show password" : " Hide password"}
														src={isRevealPwd ? EyeIcon : EyeIconClose}
														onClick={() => setIsRevealPwd(prevState => !prevState)}
													/>
												</div>
												<div className="col-md-12 col-lg-12 practiceManagementList">
													<label className="labelTextField labelMarginBottom">
														Confirm Password
													</label>
													<input
														type={isRevealPwd1 ? "text" : "password"}
														autoComplete='off'
														id="confirmPassword"
														name="confirmPassword"
														onKeyPress={handleKeypress}
														value={confirmPassword}
														onChange={(e) => setConfirmPassword(e.target.value)}
														className="inputField"
														placeholder="Confirm Password" tabIndex="3"
													/>
													<img className='showHidePassword'
														title={isRevealPwd1 ? "Show password" : "Hide password"}
														src={isRevealPwd1 ? EyeIcon : EyeIconClose}
														onClick={() => setIsRevealPwd1(prevState => !prevState)}
													/>
												</div>
												<div className="col-md-12 col-lg-12 practiceManagementList actionButtons">
													<a
														class="borderButton borderButtonLarge legoButton_green"
														onKeyPress={handleKeypress}
														onClick={() => changePasswordHandler()} tabIndex="4"
													>
														Change Password
													</a>
													{window.sessionStorage.getItem("role") === "ROLE_DOCTOR" ?
														<Link
															to="/Dashboard/welcome"
															className="borderButtonCancel borderButtonLarge" tabindex="5" id="doctorButton"
																	>
																	Cancel
														</Link> :
													window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" ?
														<Link
																to="/hospital Admin/hospitalHome"
																className="borderButtonCancel borderButtonLarge" tabindex="5" id="HospButton"
																	>
																	Cancel
														</Link> :
													window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ?
														<Link
																to="/pharmacyLogin/prescriptionDetails"
																className="borderButtonCancel borderButtonLarge" tabindex="5" id="presButton"
																	>
																	Cancel
														</Link> :
													window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY" ?
														<Link
																to="/pharmacyLogin/prescriptionDetails"
																className="borderButtonCancel borderButtonLarge" tabindex="5" id="presButton"
																	>
																	Cancel
														</Link> :
													window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" ?
														<Link
																to="/labLogin/labOrderDetails"
																className="borderButtonCancel borderButtonLarge" tabindex="5" id="presButton"
																	>
																	Cancel
														</Link> : ""}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default Changepassword;
