import React from 'react';
import Logo from '../images/media/logo.png';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';

function TreatmentPlansPrint() {
	let { printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');

	const [pagesizeid, setpagesizeid] = useState('');
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [treatmentPlanResult, setTreatmentPlanResult] = useState([]);
	const [doctorDetails, setdoctorDetails] = useState([]);
	const [treatmentPlanDate, setTreatmentPlanDate] = useState('');
	const [grandTotal, setSrandTotal] = useState('');
	const [headingType, setHeadingType] = useState('Treatment Plans');
	const [isData, setIsData] = useState(false);
	const [isCompleted, setIsCompleted] = useState('0')
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [isChecked1, setIsChecked1] = useState(false);
	const [isChecked2, setIsChecked2] = useState(false);
	const [isChecked3, setIsChecked3] = useState(false);
	const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	const [isChecked6, setIsChecked6] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);
	const [isChecked8, setIsChecked8] = useState(false);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);

	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

				//setMarginTop()
			});

	}

	useEffect(() => {

		styleValues(printId.toString().split('|')[2])
		let memberProcedureDentalOrderId = '';
		let memberProcedureDentalDetailsId = '';
		if (printId) {
			if (printId.toString().split('|')[1] === '') {
				memberProcedureDentalOrderId = printId.toString().split('|')[0];
			} else {
				memberProcedureDentalOrderId = printId.toString().split('|')[0];
				memberProcedureDentalDetailsId = printId.toString().split('|')[1];
				setIsCompleted('1')
				setHeadingType('Completed Plans');
			}
		}
		callingAPI('commonUserFunctions/printTreatmentAndCompletedPlan', {
			memberProcedureDentalOrderId: memberProcedureDentalOrderId,
			memberProcedureDentalDetailsId: memberProcedureDentalDetailsId,
		}).then((res) => {
			setMemberDetails(res.data.result[0].memberDetails[0]);
			setHospitalDetails(res.data.result[0].hospitalDetails[0]);
			setdoctorDetails(res.data.result[0].doctorDetails)
			setHospitalLogo(res.data.result[0].hospitalDetails[0].hospitalLogo);
			setTreatmentPlanResult(res.data.result[0].treatmentPlanResult[0].item);
			setTreatmentPlanDate(res.data.result[0].treatmentPlanResult[0].printDate);
			setSrandTotal(res.data.result[0].treatmentPlanResult[0].item[0].totalNetAmount);
			setIsData(true);

		});
	}, []);

	// const printTreatment = () => {
	// 	document.getElementById('printButton').style.display = 'none';
	// 	window.print();
	// 	document.getElementById('printButton').style.display = 'block';
	// };
	const printTreatment = () => {
		const section1 = document.getElementById('section1');
		const section21 = document.getElementById('section2.1');
		const section22 = document.getElementById('section2.2');
		const section23 = document.getElementById('section2.3');
		const section24 = document.getElementById('section2.4');
		const section25 = document.getElementById('section2.5');
		const section3 = document.getElementById('section3');
		const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
		const section8 = document.getElementById('section8');

		// Hide unchecked sections before printing
		if (isChecked1) {
			section1.style.display = 'none';
		}
		if (isChecked2) {
			section21.style.display = 'none';
			section22.style.display = 'none';
			section23.style.display = 'none';
			section24.style.display = 'none';
			section25.style.display = 'none';
		}
		if (isChecked3) {
			section3.style.display = 'none';
		}
		if (isChecked4) {
			section4.style.display = 'none';
		}
		if (isChecked5) {
			section51.style.display = 'none';
			section52.style.display = 'none';
		}
		if (isChecked7) {
			section7.style.display = 'none';
		}
		if (isChecked8) {
			section8.style.display = 'none';
		}

		// Hide print button temporarily
		document.getElementById('printButton').style.display = 'none';

		// Trigger print action
		window.print();

		// Show print button again
		document.getElementById('printButton').style.display = 'block';

		// Show all sections again after printing
		section1.style.display = '';
		section21.style.display = '';
		section22.style.display = '';
		section23.style.display = '';
		section24.style.display = '';
		section25.style.display = '';
		section3.style.display = '';
		section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';
		section8.style.display = '';
	};

	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				{isData === false ? <Loader /> : ''}
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">

					{/* <div className="no-print" style={{ position: "absolute", top: "70px", left: "-15px" }}><input checked={isChecked4} type="checkbox" onChange={(e) => setIsChecked4(e.target.checked)} /></div> */}
					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>
											<HeaderPrint hospitalDataId={printId.toString().split('|')[2]} setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5} setIsChecked6={setIsChecked6} isChecked6={isChecked6} />


											<div class="caseSheetTop position-relative" id="section1">

												<div class="printOutHeaderSec "></div>
												{/* {doctorDetails.map((item, i) => ( */}
												<div class="caseDoctor">
													<div className="no-print  form-group cus-checkbox-wrpr">
														<input checked={isChecked1} id='isChecked1' type="checkbox" onChange={(e) => setIsChecked1(e.target.checked)} title="Hide the doctor's details while printing." />
														<label for="isChecked1" title="Hide the doctor's details while printing."></label>
													</div>
													<p>

														{doctorDetails.title}
														{doctorDetails.doctorDisplayName},
														{' '}
														{doctorDetails.education}
														{/* {item.speciality} */}

													</p>
													{/* <p>Council Reg. No.{item.medicalRegNumber}</p> */}
												</div>
												{/* ))} */}
											</div>

											<>
												<div className="no-print form-group cus-checkbox-wrpr" >
													<input checked={isChecked2} id='isChecked2' type="checkbox" onChange={(e) => setIsChecked2(e.target.checked)} title="Hide the patient's details while printing." />
													<label for="isChecked2" title="Hide the patient's details while printing."></label>
												</div>

												<tr id='section2.1'>
													<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Name : {memberDetails.memberFirstName}{' '}
														{memberDetails.memberLastName}
													</td>
													<td colspan="6"
														style={{

															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Date : {treatmentPlanDate}{' '}
													</td>
												</tr>

												<tr id='section2.2'>
													<td
														colspan="4"
														style={{
															width: '40%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Age : {memberDetails.memberAge}
													</td>
													<td
														colspan="8"
														style={{
															width: '80%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Email Id : {memberDetails.secUserEmailid}{' '}
													</td>
												</tr>
												<tr id='section2.3'>
													<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Sex : {memberDetails.memberGender}
													</td>
													<td colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Phone No : {memberDetails.SecuserMobileNumber}
													</td>
												</tr>
												<tr id='section2.4'>
													{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
														(<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'left',
															}}
														>
															MR No : {memberDetails.memberMrNo}
														</td>) :
														(!memberDetails.memberPrNo == "" &&
															<td
																colspan="6"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '17px',
																	textAlign: 'left',
																}}
															>
																PR No : {memberDetails.memberPrNo}
															</td>
														)}

												</tr>

												<tr id='section2.5'>
													<td colspan="12">
														<hr
															color="#142536"
															style={{ margin: '0', opacity: '1', height: '2px' }}
														/>
													</td>
												</tr>
											</>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody id="section3">
						<div className="no-print form-group cus-checkbox-wrpr" >
							<input type="checkbox" checked={isChecked3} id='isChecked3' onChange={(e) => setIsChecked3(e.target.checked)} title="Hide the treatment plans while printing." />
							<label for="isChecked3" title="Hide the treatment plans while printing."></label>
							</div>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr style={{ borderBottom: '2px solid black' }}>
												<td style={{ fontSize: '14px', paddingBottom: '10px' }}>
													{headingType}
												</td>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'right',
													}}
												>
													{' '}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
										<tbody>
											<tr style={{ fontSize: '13px', background: '#eae8e8' }}>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Procedure
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '20%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Notes
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '8%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Qty
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Cost
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '17%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Tax
												</td>

												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '16%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Total
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											{treatmentPlanResult.map((item) => (
												<tr>
													{item.procedureCode !== "" ?
														<td
															style={{
																boxSizing: ' border-box',
																padding: '5px 2px',
																width: '25%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{item.procedureName} ({item.procedureCode})
														</td>
														:
														<td
															style={{
																boxSizing: ' border-box',
																padding: '5px 2px',
																width: '25%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{item.procedureName}
														</td>
													}
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '20%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{item.notes}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '8%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{item.quantity}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '14%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{item.amount}
														{/* {parseFloat((item.amount * item.quantity)).toFixed(2)} */}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '17%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{parseFloat(item.totalTax).toFixed(2)}
													</td>

													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '16%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{parseFloat((item.amount * item.quantity + item.totalTax)).toFixed(2)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											{isCompleted === '0' &&
												<tr class="dentalProPlaned">
													<td>
														<div
															class="taxValueTable"
															style={{
																width: '42%',
																marginTop: '-6px',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
																color: '#393939',
															}}
														/>

														<div
															style={{
																width: '58%',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
															}}
														>
															<div
																class="estimatedPrice txtright"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '15px',
																	color: ' #4a4a4a',
																}}
															>
																Grand Total (INR) :&nbsp;
																<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{parseFloat(grandTotal).toFixed(2)}
																</span>
															</div>
														</div>
													</td>
												</tr>}
											<tr>
												<td>
													<div className="no-print form-group cus-checkbox-wrpr" >
														<input checked={isChecked8} id='isChecked8' type="checkbox" onChange={(e) => setIsChecked8(e.target.checked)} title="Hide the desclaimer text while printing." />
														<label for="isChecked8" title="Hide the desclaimer text while printing."></label>
													</div>
													<div class="printOutSettingContent">
														<table width="100%" cellspacing="0">
															<tbody>
																<tr class="dentalProPlaned">
																	<td
																		style={{ paddingTop: '15px', fontSize: '10px' }}
																		id='section8'
																	>
																		The Final treatment charges may vary depending
																		on any unseen treatments included or
																		modification in treatment plan.
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>

					<FooterPrint setIsChecked7={setIsChecked7} isChecked7={isChecked7} />

				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									id="printButton"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
										fontFamily: '  Verdana',
									}}
									onClick={printTreatment}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default TreatmentPlansPrint;
