import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import { Link } from 'react-router-dom';
import Loader from '../Common/loader';
import { useNavigate } from 'react-router-dom';
function Consentform(props) {
	const navigate = useNavigate();
	const [hospitalConsentFormDescription, setproceduredescription] = useState(window.sessionStorage.getItem('hospitalConsentFormDescription') == null ? "" : window.sessionStorage.getItem('hospitalConsentFormDescription'),);
	const [descriptionLocalLanguage, setprocedurelocaldescription] = useState(window.sessionStorage.getItem('descriptionLocalLanguage') == null ? "" : window.sessionStorage.getItem('descriptionLocalLanguage'),);
	const [consentType, setconsentType] = useState(window.sessionStorage.getItem('concentFormType') == null ? "" : window.sessionStorage.getItem('concentFormType'),);
	const [DataFlag, setDataFlag] = useState(true);
	const saveCosentForm = () => {
		var consentTypeInput = consentType;
		var hospitalConsentTypeId = '';
		if (consentType == '') {
			alert('Select Type');
		}
		else if (!hospitalConsentFormDescription) {
			alert("Description Required")
			return;
		}
		else if (consentType == '') {
			window.sessionStorage.setItem("hospitalConsentFormDescription", "")
			window.sessionStorage.setItem("concentFormType", "")
			window.sessionStorage.setItem("descriptionLocalLanguage", "")
		}
		else if (consentTypeInput == 'PROCEDURE') {
			hospitalConsentTypeId = '1';
			let PostData = {
				hospitalConsentFormCreatedBy: window.sessionStorage.getItem('userId'),
				hospitalConsentFormDescription: hospitalConsentFormDescription,
				hospitalConsentFormDescriptionLocalLanguage: descriptionLocalLanguage,
				hospitalId: localStorage.getItem("hospitalId"),
				hospitalConsentTypeId: hospitalConsentTypeId,
				hospitalConsentFormIsActive: true,
				languagesId: '',
				hospitalConsentType: consentTypeInput,
			};
			setDataFlag(false);
			callingAPI('consentForm/addConsentForm', PostData).then((response) => {
				if (response.data.success === '1') {
					//window.location.href = '/settings/consentform';
					setDataFlag(true);
					alert('Consent Form Saved Successfully');
					let PostData = {
						hospitalId: localStorage.getItem("hospitalId"),
						hospitalConsentType: consentTypeInput,
					};
					callingAPI('consentForm/consentFormDetails', PostData).then((response) => {
						if (response.data.success === '1') {
							setproceduredescription(response.data.result.data[0].hospitalConsentFormDescription);
							setprocedurelocaldescription(response.data.result.data[0].descriptionLocalLanguage);
						} else {
							setproceduredescription('');
							setprocedurelocaldescription('');
						}
					});
				}
			});
		} else if (consentTypeInput == 'REFERRAL') {
			hospitalConsentTypeId = '2';
			{
				let PostData = {
					hospitalConsentFormCreatedBy: window.sessionStorage.getItem('userId'),
					hospitalConsentFormDescription: hospitalConsentFormDescription,
					hospitalConsentFormDescriptionLocalLanguage: descriptionLocalLanguage,
					hospitalId: localStorage.getItem("hospitalId"),
					hospitalConsentTypeId: hospitalConsentTypeId,
					hospitalConsentFormIsActive: true,
					languagesId: '',
					hospitalConsentType: consentTypeInput,
				};
				setDataFlag(false);
				callingAPI('consentForm/addConsentForm', PostData).then((response) => {
					if (response.data.success === '1') {
						//window.location.href = '/settings/consentform';
						setDataFlag(true);
						alert('Consent Form Saved Successfully');
						let PostData = {
							hospitalId: localStorage.getItem("hospitalId"),
							hospitalConsentType: consentTypeInput,
						};
						callingAPI('consentForm/consentFormDetails', PostData).then((response) => {
							if (response.data.success === '1') {
								setproceduredescription(response.data.result.data[0].hospitalConsentFormDescription);
								setprocedurelocaldescription(response.data.result.data[0].descriptionLocalLanguage);
							} else {
								setproceduredescription('');
								setprocedurelocaldescription('');
							}
						});
					}
				});
			}
		}
	};
	const onprocedurelocaldescriptionChange = (value) => {
		setprocedurelocaldescription(value);
	};
	//changeText
	const changeText = (val) => {
		var consentType = val;
		setconsentType(val);
		if (val === 'select type') {
			setDataFlag(true);
			setproceduredescription('');
			setprocedurelocaldescription('');
			setconsentType('');
		}
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			hospitalConsentType: consentType,
		};
		setDataFlag(false);
		callingAPI('consentForm/consentFormDetails', PostData).then((response) => {
			if (response.data.success === '1') {
				setDataFlag(true);
				setproceduredescription(response.data.result.data[0].hospitalConsentFormDescription);
				setprocedurelocaldescription(response.data.result.data[0].descriptionLocalLanguage);
				setconsentType(response.data.result.data[0].hospitalConsentType);
			}
			else (setDataFlag(true))
		});
	};
	const onproceduredescriptionChange = (value) => {
		setproceduredescription(value);
	};
	const PrintPreview = (data) => {
		navigate('/invoice/consentFormInvoiceSettings', { state: data })
		if (consentType !== '') {
			window.sessionStorage.setItem("concentFormType", consentType)
			window.sessionStorage.setItem("hospitalConsentFormDescription", hospitalConsentFormDescription)
			window.sessionStorage.setItem("descriptionLocalLanguage", descriptionLocalLanguage)
		}
	};
	return (
		<div className="dashboardCover SettingsConsent">
			{DataFlag == false ? (
				<div align="center">
					<Loader />
				</div>
			) : (
				<div className="dashboardContentCenter">
					<div className="dashboardTitle">Consent Form</div>
					<div className="dashboardContent">
						<div className="cardsettingList">
							<label>Type<sup>*</sup></label>
							<select
								className="inputField"
								id="selectFildtype"
								name="selectFildtype"
								value={consentType}
								onChange={(e) => changeText(e.target.value)}
								style={{ width: '50%' }}
							>
								<option value="select type">--Select Type--</option>
								<option value="PROCEDURE">PROCEDURE</option>
								<option value="REFERRAL">REFERRAL</option>
							</select>
						</div>
						<div className="cardsettingList">
							<label>Description (English)<sup>*</sup></label>
							<textarea
								className="textareaField"
								id="DescriptionEng"
								name="DescriptionEng"
								value={hospitalConsentFormDescription}
								onChange={(e) => onproceduredescriptionChange(e.target.value)}
							/>
						</div>
						<div className="cardsettingList">
							<label>Description (Local Language)</label>
							<textarea
								className="textareaField"
								id="DescriptionLocal"
								name="DescriptionLocal"
								value={descriptionLocalLanguage}
								placeholder=""
								onChange={(e) => onprocedurelocaldescriptionChange(e.target.value)}
							/>
						</div>
						<div className="dashButtonDiv">
							<span className="borderButton" style={{ background: '#2d1152' }}>
								{' '}
								<span
									// to={{
									// 	pathname: '/invoice/consentFormInvoiceSettings',
									// 	state: {
									// 		Description: hospitalConsentFormDescription,
									// 		LocalLanguage: descriptionLocalLanguage,
									// 		Type: consentType,
									// 	},
									// }}
									style={{ textDecoration: 'none', color: '#fff' }} onClick={() => PrintPreview({
										DescriptionValue: hospitalConsentFormDescription,
										LocalLanguageValue: descriptionLocalLanguage,
										TypeValue: consentType
									})}
								>
									Print Preview
								</span>{' '}
							</span>
							<span
								className="borderButton"
								style={{ background: '#2d1152', color: '#fff' }}
								onClick={saveCosentForm}
							>
								Save
							</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
export default Consentform;
