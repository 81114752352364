import Editicon from "../images/icons/edit-bg.png"
import Tick from "../images/icons/active.png"
import Close from "../images/icons/close.png"
import List from "../images/icons/list.png"
import LabHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import Add from '../images/icons/Add_img.png';
import LabLeft from '../labLogin/labLeft'
import NoData from '../Common/noDataFound';
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import Loader from '../Common/loader';
import { callingAPI } from '../config';
function AddLabTestDetails() {
	const [testResults, setTestResults] = useState([]);
	const [testCount, setTestCount] = useState(0);
	const [testId, setTestId] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [txtInput, setTxtInput] = useState('');
	const [getSupplierData, setSupplierData] = useState([]);
	const [filterName, setFilterInput] = useState("0");
	const [categoryType, setCategoryType] = useState(0);
	const [categoryValues, setCategoryValues] = useState([]);
	const [disposableitemsaddedarr, setDisposableItemsAddedArr] = useState([]);
	const [quantity, setQuantity] = useState('');
	const [disposableItemName, setDisposableItemName] = useState([]);
	const [popUpDoctorList, setPopUpDoactorList] = useState(false);
	const [getId, setId] = useState('');
	const [memberName, setMemberName] = useState('');
	const [memberAge, setMemberAge] = useState('');
	const [memberGender, setMemberGender] = useState('');
	const [addValue, setAddValue] = useState([]);
	const [itemName, setItemName] = useState('');
	const [buyerprice, setBuyerprice] = useState('');
	const [department, setDepartment] = useState('');
	const [testcode, setTestcode] = useState('');
	const [serviceProviderTestBuyPrice, setServiceProviderTestBuyPrice] = useState('');
	const [serviceProviderTestDepartment, setServiceProviderTestDepartment] = useState('');
	const [serviceProviderTestCode, setServiceProviderTestCode] = useState('');
	const [serviceProviderTestDescription, setServiceProviderTestDescription] = useState('');
	const [serviceProviderTestDetailsTestName, setServiceProviderTestDetailsTestName] = useState('');
	const [descr, setDescr] = useState('');
	const [getOffset, setOffset] = useState(0);
	const [showEditDiv, setShowEditDiv] = useState(false);
	const [load, setLoad] = useState(false);
	const [testname, setTestname] = useState('');
	//
	const removeAchievementItem = (e, idx) => {
		let newFormValues = [...disposableitemsaddedarr];
		newFormValues.splice(idx, 1);
		setDisposableItemsAddedArr(newFormValues);
	};
	const closePopUp = () => {
		setPopUpDoactorList(false);
	};
	const parameterList = (data) => {
		window.sessionStorage.setItem('test_id', data.id);
		window.sessionStorage.setItem('testName', data.testname);
		window.sessionStorage.setItem('activePage', activePage)
		window.sessionStorage.setItem('offset', getOffset)
		window.location.href = '/labLogin/labTestParametersLab';
	}
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		}
		setOffset(offset)
		window.sessionStorage.setItem('activePage', pageNumber)
		window.sessionStorage.setItem('offset', offset)
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			max: max,
			offset: offset,
			"cmbSelect": "",
			"txtInput": ""
		};
		callingAPI('testDetails/testDetails', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setTestResults(data.data.result.testResults);
					setTestCount(data.data.result.testCount);
				}
			})
			.catch();
	};
	const onDescChange = (event) => {
		const { name, value } = event.target;
		setMemberName(event.target.value);
	};
	const onDescChange1 = (event) => {
		const re = /^[0-9\b]+$/;
		const { name, value } = event.target;
		setMemberAge(event.target.value);
	};
	const onDescChange2 = (event) => {
		const { name, value } = event.target;
		setMemberGender(event.target.value);
	};
	const addAchievements = () => {
		var flag1 = true;
		let copy = JSON.parse(JSON.stringify(disposableitemsaddedarr));
		if (categoryType === "0" || document.getElementById("hospitalInventoryCategoryId").value === "0") {
			alert("Category required")
			flag1 = false;
			setLoad(true);
			return;
		}
		else if (categoryType != "0" && document.getElementById('itemName').value == "") {
			alert("Disposable/Item Name required")
			flag1 = false;
			setLoad(true);
			return;
		}
		else if (categoryType != "0" && quantity === "") {
			alert("Quantity required")
			flag1 = false;
			setLoad(true);
			return;
		}
		copy.push({
			itemName: document.getElementById('itemName').value,
			pmHospitalTreatmentInventoryQuantity: quantity,
			hospitalInventoryCategoryId: categoryType,
		});
		// setTestId('')
		setDisposableItemsAddedArr(copy);
		setCategoryType('0');
		setQuantity('');
		setItemName('');
		document.getElementById('itemName').value = '';
		//document.getElementById("myText").value(addValue)
	};
	const searchFunction = () => {
		if (filterName !== ("0") && txtInput === '') {
			var t = document.getElementById('cmbSelect');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('txtInput').focus();
			alert(selectedText + ' Required');
			return false;
		}
		const apiJson = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			"offset": 0,
			"max": 10,
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtInput": document.getElementById('txtInput').value,
		};
		callingAPI('testDetails/testDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				setTestResults(data.data.result.testResults);
				setTestCount(data.data.result.testCount);
			} else {
				setTestResults([]);
				setTestCount(0);
				// setErrors(data.data.errorMessage)
				//	alert('error');
			}
		});
	};
	const filterByPatient = (value) => {
		setTxtInput(value);
	};
	const changeText = (value) => {
		setFilterInput(value);
		if (value === "0") {
			setTxtInput('');
			document.getElementById('txtInput').disabled = true;
			labTestListing()
		} else {
			document.getElementById('txtInput').disabled = false;
			document.getElementById('txtInput').focus();
			// labTestListing()
		}
	};
	useEffect(() => {
		labTestListing()
		categoryDropdownValues();
		setActivePage(Number(window.sessionStorage.getItem('activePage')))
	}, []);
	const labTestListing = () => {
		const apiJson = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			"offset": window.sessionStorage.getItem('offset'),
			"max": 10,
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtInput": document.getElementById('txtInput').value,
		};
		callingAPI('testDetails/testDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				setTestResults(data.data.result.testResults);
				setTestCount(data.data.result.testCount);
				setLoad(true)
			}
			else {
				setTestResults([]);
				setTestCount(0);
				setLoad(true)
			}
		});
	};
	const statusChange = (id, status) => {
		// alert(id)
		// alert(status)
		var x = 0;
		if (status == 1) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		//alert(x);
		if (x == 1) {
			const PostData = {
				userId: window.sessionStorage.getItem('userId'),
				testId: id,
				currentStatus: status == 1 ? 1 : 0
			};
			callingAPI('activateDeActivateLabTest/activateDeActivateLabTest', PostData).then((data) => {
				if (data.data.success === "1") {
					// alert('Status Changed Successfully');
					setOffset(activePage)
					labTestListing();
					// setActivePage(1)
				}
				// if (data.data.success === '0') {
				//     alert(data.data.errorMessage);
				// }
			});
		} const closePopUp = () => {
			setPopUpDoactorList(false);
		};
	};
	const labTestSave = () => {
		setLoad(false)
		var itemDetailsArray = [];
		var flag1 = true;
		if (disposableitemsaddedarr.length === 0) {
			if (disposableitemsaddedarr.length === 0 && document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				alert("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				alert("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}
			var obj = {
				"txtCategoryItem": document.getElementById('itemName').value,
				"txtQuantity": quantity,
				"cmbCategory": categoryType,
				"txtHidMedicine": "",
			}
			itemDetailsArray.push(obj)
		}
		else if (document.getElementById('itemName').value === "" && quantity === "" && categoryType === "0") {
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				if (disposableitemsaddedarr[i].itemName === "") {
					alert("Disposable/Item Name required")
					flag1 = false;
					setLoad(true);
					return;
				}
				else if (disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity === "") {
					alert("Quantity required")
					flag1 = false;
					setLoad(true);
					return;
				}
			}
			if (document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				alert("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				alert("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				var obj1 = {
					"cmbCategory": document.getElementById('hospitalInventoryCategoryId' + i).value,
					"txtCategoryItem": document.getElementById('itemName' + i).value,
					"txtHidMedicine": "",
					"txtQuantity": document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
				}
				itemDetailsArray.push(obj1);
			}
		}
		else if (disposableitemsaddedarr.length > 0 && document.getElementById('itemName').value !== "" && document.getElementById('hospitalInventoryCategoryId').value !== "" && document.getElementById('quantity').value !== "") {
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				if (disposableitemsaddedarr[i].itemName === "") {
					alert("Disposable/Item Name required")
					flag1 = false;
					setLoad(true);
					return;
				}
				else if (disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity === "") {
					alert("Quantity required")
					flag1 = false;
					setLoad(true);
					return;
				}
			}
			if (document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				alert("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				alert("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				var obj1 = {
					"cmbCategory": document.getElementById('hospitalInventoryCategoryId' + i).value,
					"txtCategoryItem": document.getElementById('itemName' + i).value,
					"txtHidMedicine": "",
					"txtQuantity": document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
				}
				itemDetailsArray.push(obj1);
			}
			var obj = {
				"txtCategoryItem": document.getElementById('itemName').value,
				"txtQuantity": quantity,
				"cmbCategory": categoryType,
				"txtHidMedicine": "",
			}
			itemDetailsArray.push(obj)
		}
		else if (disposableitemsaddedarr.length > 0) {
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				if (disposableitemsaddedarr[i].itemName === "") {
					alert("Disposable/Item Name required")
					flag1 = false;
					setLoad(true);
					return;
				}
				else if (disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity === "") {
					alert("Quantity required")
					flag1 = false;
					setLoad(true);
					return;
				}
			}
			if (document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				alert("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				alert("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				var obj4 = {
					"cmbCategory": document.getElementById('hospitalInventoryCategoryId' + i).value,
					"txtCategoryItem": document.getElementById('itemName' + i).value,
					"txtHidMedicine": "",
					"txtQuantity": document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
				}
				itemDetailsArray.push(obj4);
			}
		}
		var hospitalInventoryMasterCategoryId = [];
		for (var i = 0; i < disposableitemsaddedarr.length; i++) {
			var a = document.getElementById('itemName' + i).value;
			hospitalInventoryMasterCategoryId.push(a);
		}
		var hospitalInventoryMasterItemName = [];
		for (var i = 0; i < disposableitemsaddedarr.length; i++) {
			var b = document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value;
			hospitalInventoryMasterItemName.push(b);
		}
		var hospitalInventoryMasterItemQuantity = [];
		for (var i = 0; i < disposableitemsaddedarr.length; i++) {
			var c = document.getElementById('hospitalInventoryCategoryId' + i).value;
			hospitalInventoryMasterItemQuantity.push(c);
		}
		var flag = true;
		//	serviceProviderTestDetailsTestName/serviceProviderTestBuyPrice/serviceProviderTestDescription
		if (serviceProviderTestDetailsTestName === '') {
			document.getElementById('serviceProviderTestDetailsTestName').focus();
			alert('Test Name Required');
			flag = false;
			document.getElementById('serviceProviderTestDetailsTestName').focus();
			setLoad(true)
		} else if (serviceProviderTestBuyPrice === '') {
			alert('Price Required');
			document.getElementById('serviceProviderTestBuyPrice').focus();
			flag = false;
			setLoad(true)
		} else if (serviceProviderTestDescription === '') {
			alert('Description Required');
			document.getElementById('serviceProviderTestDescription').focus();
			flag = false;
			setLoad(true)
		}
		if (flag === true) {
			const apiJson = {
				testId: testId,
				userId: window.sessionStorage.getItem('userId'),
				roleName: window.sessionStorage.getItem("role"),
				hospitalId: localStorage.getItem("hospitalId"),
				testName: serviceProviderTestDetailsTestName,
				txtCode: serviceProviderTestCode,
				txtDepartment: serviceProviderTestDepartment,
				txtBuyPrice: serviceProviderTestBuyPrice,
				txtDescription: serviceProviderTestDescription,
				itemDetails: itemDetailsArray,
				taxDetails: [
					{
						taxId: "1"
					}
				]
			}
			callingAPI('saveTestDetails/saveTestDetails', apiJson).then((data) => {
				if (data.data.success === '1') {
					alert(data.data.successMessage);
					setPopUpDoactorList(false);
					// setDisposableItemsAddedArr([]);
					// setMemberName('');
					setOffset("0")
					labTestListing();
					setLoad(true)
				} else {
					// setErrors(data.data.errorMessage)
					alert('error');
				}
			});
		}
	};
	const editLab = (id) => {
		setShowEditDiv(false)
		// var itemDetailsArray = [];
		// for (var i = 0; i < disposableitemsaddedarr.length; i++) {
		// 	var obj = {
		// 		"cmbCategory":document.getElementById('hospitalInventoryCategoryId' + i).value,
		// "txtCategoryItem": document.getElementById('itemName' + i).value,
		// "txtHidMedicine": "",
		// "txtQuantity" : document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
		// 	}
		// 	itemDetailsArray.push(obj);
		// }
		setMemberName('');
		setCategoryType('')
		setQuantity('')
		setPopUpDoactorList(true);
		// alert("edit")
		//document.getElementById('popup1').style.display = 'block';
		setTestId(id);
		setServiceProviderTestDetailsTestName('');
		setServiceProviderTestDepartment('');
		setServiceProviderTestBuyPrice('');
		setServiceProviderTestDescription('');
		setServiceProviderTestCode('');
		setSupplierData('');
		setDisposableItemsAddedArr([])
		const apiJson = {
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"offset": 0,
			"max": 10,
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtInput": document.getElementById('txtInput').value,
			"testId": id
		}
		callingAPI('testDetails/testDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				// alert(data.data.successMessage);
				setServiceProviderTestDetailsTestName(data.data.result.testResults[0].testname);
				setServiceProviderTestDepartment(data.data.result.testResults[0].department);
				setServiceProviderTestBuyPrice(data.data.result.testResults[0].buyerprice);
				setServiceProviderTestDescription(data.data.result.testResults[0].descr);
				setServiceProviderTestCode(data.data.result.testResults[0].testcode);
				setSupplierData(data.data.result.itemDetails);
				var quantity = [];
				for (var i = 0; i < data.data.result.itemDetails.length; i++) {
					// 			//alert("ssssssss")
					var x = {
						itemName: data.data.result.itemDetails[i].name,
						pmHospitalTreatmentInventoryQuantity:
							data.data.result.itemDetails[i].Quantity,
						hospitalInventoryCategoryId: data.data.result.itemDetails[i].inventorycategoryId,
					};
					quantity.push(x);
				}
				setDisposableItemsAddedArr(quantity);
				// 		// setPopUpDoactorList(false);
				// 		// setDisposableItemsAddedArr([]);
				// 		// setMemberName('');
				// 		// labTestListing();
			} else {
				// setErrors(data.data.errorMessage)
				//	alert('error');
			}
		});
	};
	// 		setServiceProviderTestDetailsTestName(data.data.result.test[0].serviceProviderTestDetailsTestname);
	// 		setServiceProviderTestDepartment(data.data.result.test[0].serviceProviderTestDepartment);
	// 		setServiceProviderTestBuyPrice(data.data.result.test[0].serviceProviderTestBuyerPrice);
	// 		setServiceProviderTestDescription(data.data.result.test[0].serviceProviderDescription);
	// 		setServiceProviderTestCode(data.data.result.test[0].serviceProviderTestcode);
	// 		setSupplierData(data.data.result.supplierData);
	// 		var quantity = [];
	// 		for (var i = 0; i < data.data.result.supplierData.length; i++) {
	// 			//alert("ssssssss")
	// 			var x = {
	// 				itemName: data.data.result.supplierData[i].hospitalInventoryMasterInventory,
	// 				pmHospitalTreatmentInventoryQuantity:
	// 					data.data.result.supplierData[i].hospitalInventoryMasterInventoryQuantity,
	// 				hospitalInventoryCategoryId: data.data.result.supplierData[i].hospitalInventoryCategoryId,
	// };
	// 			quantity.push(x);
	// 		}
	// 		setDisposableItemsAddedArr(quantity);
	// 	} else {
	// 		// setErrors(data.data.errorMessage)
	// 		//	alert('error');
	// 	}
	// });
	const category1PopOpen = () => {
		setShowEditDiv(true)
		setTestId('')
		setPopUpDoactorList(true);
		setMemberName('');
		setCategoryType('')
		setQuantity('')
		setServiceProviderTestBuyPrice('');
		setServiceProviderTestDepartment('');
		setServiceProviderTestCode('');
		setServiceProviderTestDescription('');
		setServiceProviderTestDetailsTestName('');
		setSupplierData([]);
		setDisposableItemsAddedArr([]);
		//  setFlag(false)
		// alert("pop")
		//  document.getElementById("popup1").style.display = "block"
	};
	const categoryTypeChange = (event) => {
		setCategoryType(event.target.value);
	};
	const categoryDropdownValues = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('treatmentProcedure/getCategoryAndTaxDropdown', postdata)
			.then((res) => {
				setCategoryValues(res.data.result.categoryData);
			})
			.catch();
	};
	const categoryTypeNewChange = (e, i) => {
		document.getElementById('itemName' + i).value = '';
		document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value = '';
		const { name, value } = e.target;
		const values = [...disposableitemsaddedarr];
		values[i] = { ...values[i], [name]: value };
		setDisposableItemsAddedArr(values);
	};
	const quantityChange = (event) => {
		setQuantity(event.target.value);
	};
	const getDisposableItemNames = (i, id1, id2) => {
		setId(i);
		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: document.getElementById(id1).value,
			selectDropdownSearchType: document.getElementById('hospitalInventoryCategoryId' + i).value,
		};
		if (document.getElementById(id1).value.length > 1) {
			callingAPI('treatmentProcedure/getDisposableItemsByCategory', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(id2).style.display = 'block';
					setDisposableItemName(data.data.result);
				} else {
					document.getElementById(id2).style.display = 'none';
					setDisposableItemName([]);
				}
			});
		}
	};
	const addTest = () => {
		alert("dddddddddd")
		document.getElementById("labTestDetails").style.display = 'block';
	}
	const getItemIDFunction = (i, id1, id2, name) => {
		document.getElementById(id1 + getId).value = name;
		document.getElementById(id2 + getId).style.display = 'none';
		setDisposableItemName([]);
	};
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec addLabTest">
			{load === false ? <Loader /> : ''}
			<LabHeader />
			<div className="contentWraper contentWraperScroll">
				<LabLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle">Lab Test Details</div>
							</div>
							<div className="col-md-2">
								<a onClick={category1PopOpen}><span className="dashButtonBorder" style={{ background: "#22a2fd", color: "#fff", border: "1px solid #22a2fd" }}>Add +</span></a>
							</div>
						</div>
						<div className="paginationSection">
							{testCount > 10 ? (
								<div className="paginationSection">
									<Pagination
										// hideNavigation
										hideDisabled
										activePage={activePage}
										itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
										totalItemsCount={testCount}
										pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
										onChange={handlePageChange}
									/>
								</div>
							) : (
								''
							)}
						</div>
						<div className="dashboardCover">
							<div className="dashboardContent">
								<div className="reportWrap">
									<div className="dashboardSearch">
										<div className="row">
											<div className="col-md-2 dashboardSearchList">
												<select className="inputField"
													id="cmbSelect"
													title="Search In"
													onChange={(e) => changeText(e.target.value)}>
													<option value="0">Search In</option>
													<option value="1">Test Name</option>
													<option value="2">Test Code</option>
												</select>
											</div>
											<div className="col-md-2 dashboardSearchList">
												<input type="text"
													className="inputField"
													id="txtInput"
													value={txtInput}
													onChange={(e) => filterByPatient(e.target.value)}
													disabled={filterName === "0" ? true : false}
													style={{ background: filterName === "0" ? "transparent" : "white" }} />
											</div>
											<div className="col-md-2 dashboardSearchList">
												<span className="dashButtonBorder" onClick={searchFunction}>Search</span>
											</div>
											<div className="col-md-6 dashboardSearchList">
												<div className="searchCount"><b>Total : {testCount ? testCount : "0"}</b></div>
											</div>
										</div>
									</div>
									{testResults.length > 0 ? (
										<div className="dashboardtable">
											<table>
												<thead>
													<tr>
														<th>Id</th>
														<th>  Test Name	</th>
														<th>Department</th>
														<th>Price</th>
														<th>Test Code</th>
														<th> Description</th>
														<th>Actions</th>
													</tr>
												</thead>
												<tbody>
													{testResults.map((data, i) => (
														<tr>
															<td>{data.id}</td>
															<td>{data.testname}</td>
															<td>{data.department}</td>
															<td>&#8377; {data.buyerprice}</td>
															<td>{data.testcode}</td>
															<td>{data.descr}</td>
															<td>
																<img
																	src={List}
																	title="Add Parameter"
																	onClick={(e) => parameterList(data)}
																/>
																<a>
																	<img
																		src={Editicon}
																		title="Edit"
																		onClick={(e) => editLab(data.id)}
																	/>
																</a>
																{data.isActive == 1 ? (
																	<img
																		src={Tick}
																		title="Click to Deactivate"
																		onClick={() => statusChange(data.id, data.isActive)}
																		style={{ width: '20px' }}
																	/>
																) : (
																	<img
																		src={Close}
																		title="Click to Activate"
																		onClick={() => statusChange(data.id, data.isActive)}
																		style={{ width: '20px' }}
																	/>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									) : (
										<NoData />
									)}
								</div>
								<div className="paginationSection">
									{testCount > 10 ? (
										<div className="paginationSection">
											<Pagination
												// hideNavigation
												hideDisabled
												activePage={activePage}
												itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
												totalItemsCount={testCount}
												pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
												onChange={handlePageChange}
											/>
										</div>
									) : (
										''
									)}
								</div>
								{/* PRINNT 1st MODAL BEGINS HERE */}
								{/* eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */}
								<div>
									{popUpDoctorList == true && (
										<div className="ploverlayy">
											<div className="plpopup plpopupList ">
												{/* <h2>
								&nbsp;
								<a class="close" href="#" onClick={closePopUp}>
									&times;
								</a>
							</h2> */}
												<div class="addDataHead" style={{ marginBottom: "10px" }}>
													<span id="spanAddSupplier">Add Test Details</span>
													<a href="#" id="btnCancel" class="close" onClick={closePopUp}>
														<span class="popUpClose">x</span>
													</a>
												</div>
												<div className="popBack" id="popup1">
													<div className="popOverlay" />
													<div className="row">
														<div className="customizationPop">
															<div className="customizationPopContentSec">
																<input type="hidden" id="txtHiddenCategoryId" />
																<div className="row">
																	<div className="col-md-12 col-12">
																		<div className="row">
																			<div className="col-md-4">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<label className="labelField">
																							Test Name<sup>*</sup>
																						</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<div className="formInputSection">
																							<input
																								type="text"
																								className="formInputField inputField"
																								id="serviceProviderTestDetailsTestName"
																								name="serviceProviderTestDetailsTestName"
																								value={serviceProviderTestDetailsTestName}
																								placeholder="Select Test Name"
																								onChange={(e) =>
																									setServiceProviderTestDetailsTestName(
																										e.target.value
																									)}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-12 col-12">
																		<div className="row">
																			<div className="col-md-4">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<label className="labelField">
																							Department<sup />
																						</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<div className="formInputSection">
																							<input
																								type="text"
																								className="formInputField inputField"
																								id="serviceProviderTestDepartment"
																								name="serviceProviderTestDepartment"
																								value={serviceProviderTestDepartment}
																								placeholder="Department"
																								onChange={(e) =>
																									setServiceProviderTestDepartment(
																										e.target.value
																									)}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-12 col-12">
																		<div className="row">
																			<div className="col-md-4">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<label className="labelField">
																							Price<sup>*</sup>
																						</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<div className="formInputSection">
																							<input
																								type="text"
																								className="formInputField inputField"
																								id="serviceProviderTestBuyPrice"
																								name="serviceProviderTestBuyPrice"
																								value={serviceProviderTestBuyPrice}
																								placeholder="Price"
																								onKeyPress={(event) => {
																									if (!/[0-9.]/.test(event.key)) {
																										event.preventDefault();
																										alert("Only numbers accepted")
																									}
																								}}
																								onChange={(e) =>
																									setServiceProviderTestBuyPrice(
																										e.target.value
																									)}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-12 col-12">
																		<div className="row">
																			<div className="col-md-4">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<label className="labelField">
																							Description<sup>*</sup>
																						</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<div className="formInputSection">
																							<input
																								type="text"
																								className="formInputField inputField"
																								id="serviceProviderTestDescription"
																								name="serviceProviderTestDescription"
																								value={serviceProviderTestDescription}
																								placeholder="Description"
																								onChange={(e) =>
																									setServiceProviderTestDescription(
																										e.target.value
																									)}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-12 col-12">
																		<div className="row">
																			<div className="col-md-4">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<label className="labelField">
																							Test Code<sup />
																						</label>
																					</div>
																				</div>
																			</div>
																			<div className="col-md-8">
																				<div className="form-group">
																					<div className="addStoreSectionList">
																						<div className="formInputSection">
																							<input
																								type="text"
																								className="formInputField inputField"
																								id="serviceProviderTestCode"
																								name="serviceProviderTestCode"
																								value={serviceProviderTestCode}
																								placeholder="Test Code"
																								onChange={(e) =>
																									setServiceProviderTestCode(
																										e.target.value
																									)}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	{/* <div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Test Code<sup />
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-8">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<div className="formInputSection">
																		<input
																			type="text"
																			className="formInputField inputField"
																			id="serviceProviderTestCode"
																			name="serviceProviderTestCode"
																			value={serviceProviderTestCode}
																			placeholder="Test Code"
																			onChange={(e) =>
																				setServiceProviderTestCode(
																					e.target.value
																				)}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div> */}
																	<div className="col-md-12">
																		<label className="labelField" style={{ marginBottom: '10px' }}>
																			Disposable/Items used
																		</label>
																		<div className="row">
																			<div className="col-md-4 col-4">
																				<div className="form-group">
																					{/* <select
																	name="kidGender"
																	class="smyLargstFieldnew formInputborderR inputField "
																	onChange={onDescChange2}
																	value={memberGender}
																>
																	<option value="0">Select Category</option>
																	<option value="1">Medicine</option>
																	<option value="2">Disposable</option>
																	<option value="3">Others</option>
																</select> */}
																					<select
																						name="hospitalInventoryCategoryId"
																						id="hospitalInventoryCategoryId"
																						value={categoryType}
																						onChange={categoryTypeChange}
																						style={{ width: '100%' }}
																						className="inputField"
																					>
																						<option value="0">Select Category</option>
																						{categoryValues.map((item, idix) => (
																							<option
																								value={item.hospitalInventoryCategoryId}
																							>
																								{item.hospitalInventoryCategoryName}
																							</option>
																						))}
																					</select>
																				</div>
																			</div>
																			<div className="col-md-4 col-4">
																				<div className="form-group">
																					{/* <input
																	type="text"
																	className="formInputField inputField"
																	name="memberName"
																	value={memberName}
																	onChange={onDescChange}
																	placeholder="Disposable/Items used"
																/> */}
																					<input
																						type="text"
																						defaultValue={memberName}
																						onChange={onDescChange}
																						name="memberName"
																						placeholder="Enter Disposable/Item Name"
																						id="itemName"
																						className="inputField"
																						onKeyUp={() =>
																							getDisposableItemNames(
																								'',
																								'itemName',
																								'itemListDiv'
																							)}
																						// onChange={itemNameChange}
																						style={{ width: '100%' }}
																					/>
																					<ul
																						className="autoSearch autoSearch-p0 appointmentNumberSearch"
																						id="itemListDiv"
																						style={{ display: 'none' }}
																					>
																						{disposableItemName.map((item, i) => (
																							<li
																								onClick={() =>
																									getItemIDFunction(
																										'',
																										'itemName',
																										'itemListDiv',
																										item.itemName
																									)}
																							>
																								{item.itemName}
																							</li>
																						))}
																					</ul>
																				</div>
																			</div>
																			<div className="col-md-3 col-3">
																				<div className="form-group">
																					<input
																						type="text"
																						id="quantity"
																						onChange={quantityChange}
																						placeholder="Quantity"
																						name="quantity"
																						value={quantity}
																						style={{ width: '100%' }}
																						className="inputField"
																					/>
																				</div>
																			</div>
																			<div className="col-md-1 col-1" style={{ padding: '2px' }}>
																				<div className="form-group">
																					<span onClick={(e) => addAchievements(e)}>
																						<img
																							src={Add}
																							style={{ cursor: 'pointer', marginLeft: '4px' }}
																						/>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="col-md-12">
																		<div class="table-responsive">
																			<table
																				class="table"
																				id="tblAchievements"
																				style={{ display: 'grid' }}
																			>
																				<tbody>
																					{disposableitemsaddedarr.map((item, idx) => (
																						<tr
																							id="gridnew_docedit_achtrow"
																							class="plLabTestAppend"
																						>
																							<td style={{ width: '30%' }}>
																								{/* <select
																				disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value={item.memberGender}
																				class="achievementDesc inputField"
																			>
																				<option value="1">Medicine</option>
																				<option value="2">Disposable</option>
																				<option value="3">Others</option>
																				<option value="LAB">Lab</option>
																			</select> */}
																								<select
																									name="hospitalInventoryCategoryId"
																									id={'hospitalInventoryCategoryId' + idx}
																									value={item.hospitalInventoryCategoryId}
																									onChange={(e) =>
																										categoryTypeNewChange(e, idx)}
																									// onChange={onDescChange}
																									style={{ width: '100%' }}
																									className="inputField"
																								>
																									<option value="0">
																										Select Category
																									</option>
																									{categoryValues.map((item, idix) => (
																										<option
																											value={
																												item.hospitalInventoryCategoryId
																											}
																										>
																											{
																												item.hospitalInventoryCategoryName
																											}
																										</option>
																									))}
																								</select>
																								{/* <input
																				disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value={item.memberGender}
																				class="achievementDesc inputField"
																			/> */}
																							</td>
																							<td>
																								{/* <input
																				// disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value={item.memberName}
																				class="achievementDesc inputField"
																			/> */}
																								<input
																									type="text"
																									name="itemName"
																									placeholder="Enter Disposable/Item Name"
																									className="inputField lbTestDetlsItemName dataAppend"
																									id={'itemName' + idx}
																									value={item.itemName}
																									// onChange={(e) => categoryTypeNewChange(e, idx)}
																									onChange={onDescChange}
																									onKeyUp={() =>
																										getDisposableItemNames(
																											idx,
																											'itemName' + idx,
																											'itemListDiv' + idx
																										)}
																									style={{ width: '100%' }}
																								/>
																								<ul
																									className="autoSearch autoSearch-p0 appointmentNumberSearch"
																									appointmentNumberSearch
																									id={'itemListDiv' + idx}
																									style={{ display: 'none' }}
																								>
																									{disposableItemName.map((item, i) => (
																										<li
																											onClick={() =>
																												getItemIDFunction(
																													i,
																													'itemName',
																													'itemListDiv',
																													item.itemName
																												)}
																										>
																											{item.itemName}
																										</li>
																									))}
																								</ul>
																							</td>
																							<td>
																								<input
																									type="text"
																									id={
																										'pmHospitalTreatmentInventoryQuantity' +
																										idx
																									}
																									// onChange={(e) => categoryTypeNewChange(e, idx)}
																									onChange={quantityChange}
																									placeholder="Quantity"
																									className="inputField lbTestDetlsQuantity"
																									name="pmHospitalTreatmentInventoryQuantity"
																									value={
																										item.pmHospitalTreatmentInventoryQuantity
																									}
																									style={{ width: '81%' }}
																								/>
																								{/* <input
																				// disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value={item.memberAge}
																				class="achievementDesc inputField plQuantity"
																			/> */}
																							</td>
																							<td>
																								<a id="remove" tabindex="4" href="#">
																									<img
																										src={Close}
																										style={{
																											width: '20px',
																											cursor: 'pointer', marginTop: "8px"
																										}}
																										onClick={(e) =>
																											removeAchievementItem(e, idx)}
																									/>
																								</a>
																							</td>
																						</tr>
																					))}{' '}
																				</tbody>
																			</table>
																		</div>
																	</div>
																	<div class="summaryBtnDiv">
																		{/* <span id="treatmentPlanBtnEdit"
														className="treatmentPlanBtn"
														style={{ display: showEditDiv ? '' : 'none' }}> */}
																		<button
																			className="practiceGreenButton"
																			id="addCategory1Save"
																			title="Save"
																			style={{ display: showEditDiv ? '' : 'none' }}
																			onClick={() => labTestSave()}
																		>
																			Save
																		</button>
																		{/* </span> */}
																		{/* <span
													id="treatmentPlanBtnEdit"
													className="treatmentPlanBtn"
													style={{ display: !showEditDiv ? '' : 'none' }}> */}
																		<button
																			className="practiceGreenButton"
																			id="addCategory1Save"
																			title="Save"
																			style={{ display: !showEditDiv ? '' : 'none' }}
																			onClick={() => labTestSave()}
																		>
																			Update
																		</button>
																		{/* </span> */}
																		<span
																			onClick={closePopUp}
																			className="customisBtn practiceGreenButton"
																			style={{ borderColor: '#b22222' }}
																			title="Cancel"
																			id="addCategory1Cancel"
																		>
																			Cancel
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
								{/* pppppppppppppppppppppppppppppppppppp */}
							</div>
						</div>
					</div>
				</div>
				)
			</div>
		</div>
	);
}
export default AddLabTestDetails
