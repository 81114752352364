import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import PatientPaymentListRefund from '../Patients/patientPaymentListRefund';
import { callingAPI } from '../config'
import Loader from '../Common/loader';
import $ from 'jquery';
function AddPaymentsRefund() {
	$(document).ready(function () {
		$('#paymentAdd').addClass("testResultAdd");

	})
	const [getinvoiceData, setinvoiceData] = useState([]);
	const [count, setCount] = useState(0);
	const [invoiceDataAppnd, setinvoiceDataAppnd] = useState([]);
	const [total, setTotal] = useState(0);
	const [refundAdvAmt, setRefundAdvAmt] = useState(0)
	const [advanceAfterRefund, setadvanceAfterRefund] = useState(0)
	const [errMsg, setErrMsg] = useState('');
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [dataFlag, setDataFlag] = useState(false);


	const [isAdvanceClick, setIsAdvanceClick] = useState(false);
	const [getInvoiceGroupListing, setInvoiceGroupListing] = useState([]);
	const [getAdvanceAmt, setAdvanceAmt] = useState('0');
	const [getAmoutDue, setAmoutDue] = useState('0');
	const [getData1, setData1] = useState('');
	const [invoiceobj, setInvoiceObj] = useState([]);
	const [invoiceLength, setinvoiceLength] = useState();
	useEffect(() => {
		listing();
	}, [])
	const listing = () => {
		const apiJson = {
			"doctorId": window.sessionStorage.getItem('doctorId'),
			"hospitalId": localStorage.getItem("hospitalId"),
			"memberId": window.sessionStorage.getItem('memberId'),
		};
		callingAPI("payment/selectPaidInvoiceList", apiJson).then((data) => {
			if (data.data.success === "1") {
				setInvoiceGroupListing(data.data.result.invoiceGroup)
				setAdvanceAmt(data.data.result.advanceAmt)
				setadvanceAfterRefund(data.data.result.advanceAmt)
				if (data.data.result.invoiceGroup.length > 0) {
					document.getElementById("invoiceTxt").style.display = 'block'
					document.getElementById("noInvoiceTxt").style.display = 'none'
				} else {
					document.getElementById("invoiceTxt").style.display = 'none'
					document.getElementById("noInvoiceTxt").style.display = 'block'
				}
				var sum = 0;
				for (var i = 0; i < data.data.result.invoiceGroup.length; i++) {
					sum = sum + data.data.result.invoiceGroup[i].totalNetAmount;
				}
				setAmoutDue(sum)
			}
		});
	}
	const addInvList = (invoiceId, id, obj) => {
		setLoaderFlag(true)
		setIsAdvanceClick(false)
		setData1(invoiceId)
		setInvoiceObj(obj)
		document.getElementById(id).style.display = 'none';
		var a = 0;
		var b = 0;
		getInvoiceGroupListing.map((obj1) => {
			if (obj1.invoiceAmount > 0) {
				if (document.getElementById("aaa" + obj1.invoiceId).style.display === 'none') {
					a = parseInt(a) + 1
				}
			}
			if (obj1.invoiceAmount === 0) {
				b = parseInt(b) + 1
				setinvoiceLength(b)
			}
		})
		if ((getInvoiceGroupListing.length - parseInt(b)) === parseInt(a)) {
			document.getElementById("invoiceTxt").style.display = 'none'
			document.getElementById("noInvoiceTxt").style.display = 'block'

		} else {
			document.getElementById("invoiceTxt").style.display = 'block'
			document.getElementById("noInvoiceTxt").style.display = 'none'
		}
		listing1(invoiceId, obj)
	}

	const listing1 = (invoiceId, obj) => {
		document.getElementById("refundAdvanceAmount").style.display = "none"
		const apiJson = {
			memberProcedureInvoiceId: invoiceId,
			hospitalId: localStorage.getItem("hospitalId"),
			memberId: window.sessionStorage.getItem('memberId'),
		};
		callingAPI('payment/selectPendingInvoice', apiJson).then((data) => {

			if (data.data.success === '1') {
				var a = [...invoiceDataAppnd]
				//if(data.data.result.invoiceData.length > 0){
				var arrayObj = {
					invoiceId: data.data.result.invoiceData[0].invoiceId,
					invoiceNumber: data.data.result.invoiceData[0].invoiceNumber,
					testName: data.data.result.invoiceData[0].testName,
					inventoryName: data.data.result.invoiceData[0].inventoryName,
					medicineName: data.data.result.invoiceData[0].medicineName,
					procedureCode: data.data.result.invoiceData[0].procedureCode.replaceAll('()', ''),
					totalBalanceAmount: parseFloat(data.data.result.invoiceData[0].totalBalanceAmount).toFixed(2),
					totalNetAmount: parseFloat(obj.invoiceAmount).toFixed(2),
					totalNetAmount1: parseFloat(obj.invoiceAmount).toFixed(2),
				};

				a.push(arrayObj);

				//setinvoiceDataAppnd(arrayObj)
				// var total = 0;
				// a.map((val, idx) => {
				// 	total = total + val.totalNetAmount;
				// 	return total
				// });
				var total = a.reduce((acc, val) => acc + parseFloat(val.totalNetAmount), 0);

			}
			setTotal(total);
			setinvoiceData(data.data.result.invoiceData);
			setCount(data.data.result.invoiceData.length);
			setinvoiceDataAppnd([...a])
			setLoaderFlag(false)
			//}
			// }else{
			// 	setTotal(total);
			// 	setinvoiceData([]);
			// 	setCount(0);
			// 	setinvoiceDataAppnd([...a])
			// 	setLoaderFlag(false)
			// }
		});
	}
	const payNowSave = () => {
		if (parseFloat(total) > 0) {
			document.getElementById("payNowSave").style.display = "none";
			var refundPaymentDetails = [];
			for (var i = 0; i < invoiceDataAppnd.length; i++) {
				var g = {
					refundAmount: document.getElementById('totalNetAmount' + i).value,
					memberProcedureInvoiceId: invoiceDataAppnd[i].invoiceId,
				};
				refundPaymentDetails.push(g);
			}
			const apiJson = {
				hospitalId: localStorage.getItem("hospitalId"),
				memberId: window.sessionStorage.getItem('memberId'),
				userId: window.sessionStorage.getItem('userId'),
				hidMode: 'Invoice',
				invoicePaymentMode: document.getElementById('paymentTypeId').value,
				refundReason: document.getElementById('addNote').value,
				totalRefundAmount: total.toString(),
				refundPaymentDetails: refundPaymentDetails,
			};
			callingAPI('payment/saveRefund', apiJson).then((data) => {
				if (data.data.success === '1') {
					alert("Amount refunded successfully");
					window.location.href = '/patients/patientPayments';
				}
			});
		}
		else {
			setErrMsg('Refund amount require')
			removeMessage()
		}
	};
	const removeInvoice = (e, i, id) => {
		let newFormValues1 = [...invoiceDataAppnd];
		newFormValues1.splice(i, 1);
		setinvoiceDataAppnd(newFormValues1);
		var totalBalance = 0;
		newFormValues1.map((val, idx) => {
			totalBalance = totalBalance + val.totalNetAmount;
		});
		setTotal(totalBalance);
		document.getElementById(id).style.display = 'block';
		document.getElementById("invoiceTxt").style.display = 'block'
		document.getElementById("noInvoiceTxt").style.display = 'none'
		if (newFormValues1.length === 0) {
			document.getElementById("saverefund").style.display = "none"
		}
		setinvoiceData(newFormValues1)
	};
	const handleTxt = (e, i, name1, id2, totalAmount) => {
		const { name, value } = e.target;
		if (parseFloat(value) > parseFloat(totalAmount)) {
			setErrMsg("Refund amount should not be greater than paid amount")
			removeMessage()
		}
		else {
			const values = [...invoiceDataAppnd];
			values[i] = { ...values[i], [name]: value };
			setinvoiceDataAppnd(values);
			if (document.getElementById(name1).value < totalAmount) {
				document.getElementById(id2).value = parseFloat(totalAmount - document.getElementById(name1).value).toFixed(2);
			} else {
				document.getElementById(name1).value = totalAmount;
				document.getElementById(id2).value = 0.00;
			}
			var total1 = 0;
			for (var i = 0; i < invoiceDataAppnd.length; i++) {
				var cost = Number(document.getElementById('totalNetAmount' + i).value);
				total1 = +total1 + + cost;
			}
			setTotal(total1);
		}
	};
	const refundAdvanceAmount = () => {
		setIsAdvanceClick(true)
		setinvoiceData([])
		setinvoiceDataAppnd([])
		getInvoiceGroupListing.map((listObj, i) => {
			if (listObj.invoiceAmount > 0) {
				document.getElementById("aaa" + listObj.invoiceId).style.display = 'block'
			}
		})


		// setTimeout(() => {
		// 	document.getElementById("selectAnInvoiceDiv").style.display = "none"
		// 	document.getElementById("refundAdvanceAmount").style.display = "block"
		// }, 1000);
	}
	const settingRefundAdvcAmount = (e) => {
		var afterRefund = 0
		var refundAmt = e.target.value
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (e.target.value === '' || re.test(e.target.value)) {
			if (parseFloat(refundAmt) === 0 || refundAmt === "") {
				// setErrMsg('Refund amount require')
				// removeMessage()
				document.getElementById("saverefund").style.display = "none"
			} else {
				document.getElementById("saverefund").style.display = "block"
			}
			refundAmt = (refundAmt === '' ? 0 : refundAmt)
			if (getAdvanceAmt >= Number(refundAmt)) {
				afterRefund = parseFloat(getAdvanceAmt) - parseFloat(refundAmt)
			} else {
				//afterRefund = parseFloat(getAdvanceAmt) - parseFloat(refundAmt)
				//refundAmt = 0
				setRefundAdvAmt(0)
				document.getElementById("saverefund").style.display = "none"
				setErrMsg("Refund amount should not be greater than advance amount")
				removeMessage()
				afterRefund = getAdvanceAmt
			}

			setadvanceAfterRefund(afterRefund)
		}
		else {
			setRefundAdvAmt('')
			setadvanceAfterRefund(getAdvanceAmt)
			alert("Only Number accepted")
		}

	}
	const removeMessage = () => {
		setTimeout(() => {
			setErrMsg("")
		}, 2000);
	};
	const saveRefund = () => {
		setLoaderFlag(true)
		const apiJson = {
			hospitalId: localStorage.getItem("hospitalId"),
			memberId: window.sessionStorage.getItem('memberId'),
			userId: window.sessionStorage.getItem('userId'),
			hidMode: '',
			invoicePaymentMode: document.getElementById('refundpaymentTypeId').value,
			refundReason: document.getElementById('addNoteRefund').value,
			totalRefundAmount: refundAdvAmt.toString(),
			refundPaymentDetails: [],
		};
		callingAPI('payment/saveRefund', apiJson).then((data) => {
			if (data.data.success === '1') {
				window.location.href = '/patients/patientPayments';
			}
			setLoaderFlag(false)
		});
	}
	return (
		<div className="coverWraper">
			{/* {dataFlag === false ? <Loader /> : ''} */}
			<Header />
			<div className="contentWraper contentWraperScroll addpaymentRefundWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					{loaderFlag === true ? <Loader /> : ''}
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">





								{/* <PatientPaymentListRefund invoiceobj={invoiceobj} getData1={getData1} getAdvanceAmt={getAdvanceAmt} getInvoiceGroupListing={getInvoiceGroupListing} /> */}



								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour ptpaymntRfundWrapper">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead" style={{ border: 'none' }}>
												<span class="treatmentPlanName">REFUND</span>
												<label
													id="lblMessage" className='invoiceErrrMsg'
													style={{ color: 'red', textAlign: "center", fontSize: "13px" }}
												>	{errMsg}
												</label>
												{getinvoiceData.length > 0 ?
													<span id="payNowSave"
														class="treatmentPlanBtn pl_blue_btn"
														style={{ background: '#5bb75b' }}
														onClick={() => {
															payNowSave();
														}}
													>
														<span>Save</span>
													</span>
													: ""}
												<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
													<a href="/patients/patientPayments">Cancel</a>
												</span>
												{getAdvanceAmt > 0 &&
													<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn" onClick={refundAdvanceAmount}>
														<span>Refund Advance Amount</span>
													</span>
												}
												<span id="saverefund"
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', display: 'none' }}
													onClick={() => {
														saveRefund();
													}}
												>
													<span>Save</span>
												</span>
											</div>
											<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
												{isAdvanceClick === false &&
													getinvoiceData.length > 0 ?
													<div class="pl_treat treatmentTable planActive">
														<table style={{ border: 'none' }}>
															<thead>
																<tr>
																	<th class="col-md-2">
																		<div class="dentalProName">INVOICE NO</div>
																	</th>

																	<th class="col-md-7">
																		<div class="dentalProName">TOWARDS</div>
																	</th>
																	<th class="col-md-3  ">
																		<div class="dentalProNote" style={{ textAlign: 'right' }}>
																			PAID AMOUNT (INR)
																		</div>
																	</th>
																	<th class="col-md-3  ">
																		<div class="dentalProNote" style={{ textAlign: 'right', paddingRight: '0px' }}>
																			REFUND AMOUNT (INR)
																		</div>
																	</th>
																	<th class="col-md-3  ">
																		<div class="dentalProNote" style={{ textAlign: 'right' }}>
																			BALANCE (INR)
																		</div>
																	</th>
																</tr>
															</thead>
															{invoiceDataAppnd.map((obj, i) => (
																<tbody style={{ border: '1px solid #cecece' }}>
																	<tr class="groupBrdr">
																		<td class="col-md-2">
																			<div class="dentalProName">
																				<a
																					href=""
																					style={{ color: '#00abff', textDecoration: 'none' }}
																				>
																					{obj.invoiceNumber}
																				</a>
																			</div>
																		</td>
																		<td class="col-md-7">
																			<div class="dentalProName">	{obj.procedureCode !== "" ? <p >{obj.procedureCode ? <span><b>Procedure -</b> {obj.procedureCode.replaceAll('()', '')}</span> : ""}<br /></p> : ""}
																				{obj.medicineName !== "" ? <p> {obj.medicineName ? <span><b>Medicine -</b> {obj.medicineName}</span> : ""}<br /></p> : ""}
																				{obj.testName !== "" ? <p> {obj.testName ? <span><b>Lab -</b> {obj.testName}</span> : ""}<br /></p> : ""}
																				{obj.inventoryName !== "" ? <p> {obj.inventoryName ? <span><b>Disposable -</b> {obj.inventoryName}</span> : ""}<br /></p> : ""}</div>
																		</td>
																		<td class="col-md-3 ">
																			<div
																				class="dentalProDis"
																				style={{
																					textAlign: 'right',
																					color: '008000',
																				}}
																			>
																				{parseFloat(obj.totalNetAmount1).toFixed(2)}
																			</div>
																		</td>
																		<td class="col-md-3">
																			<input
																				className="inputField refundAmounttableList"
																				onChange={(e) =>
																					handleTxt(
																						e,
																						i,
																						'totalNetAmount' + i,
																						'totalBalanceAmount' + i,
																						obj.totalNetAmount1
																					)}
																				name="totalNetAmount"
																				id={'totalNetAmount' + i}
																				type="text"
																				value={obj.totalNetAmount}
																				style={{ textAlign: 'right', width: "90%", float: "right", marginTop: "5px" }}
																			/>
																		</td>
																		<td class="col-md-3">
																			<input
																				className="inputField totalBalanceAmountField"
																				name="totalBalanceAmount"
																				id={'totalBalanceAmount' + i}
																				type="text"
																				readOnly
																				defaultValue={obj.totalBalanceAmount}
																				style={{ textAlign: 'right', border: 'none' }}
																			/>
																		</td>
																		<td>
																			<span title="Remove"
																				className="close_row ptPaymentListRefundClose"
																				style={{ display: 'block' }}
																				onClick={(e) => removeInvoice(e, i, 'aaa' + obj.invoiceId)}
																			>
																				x
																			</span>
																		</td>
																	</tr>
																</tbody>
															))}
														</table>
														<div
															class="paymentSec paymentSecDiv paymentPaymntSec_PTour"
															style={{ display: 'block' }}
														>
															<div class="paymentSecTop paymentSecTop_PTour">
																<span class="ContentLabel" style={{ paddingLeft: '8px' }}>
																	TOTAL REFUND : <span style={{ fontWeight: "normal" }}>INR</span><span style={{ color: '#f00' }}>{parseFloat(total).toFixed(2)}</span>
																</span>
															</div>
															<br />
															<div class="paymentSecContent paymentSecContent_PTour">
																<div class="paymentSecLst" style={{ paddingLeft: '10px' }}>
																	<label style={{ fontSize: "12px" }}>PAYMENT MODE : </label>
																	<span>
																		<select
																			style={{
																				height: '25px',
																				width: 'auto',
																				border: '1px solid #000', marginTop: "5px"
																			}}
																			id="paymentTypeId"
																		>
																			<option value="1">Cash</option>
																			<option value="2">Cheque</option>
																			<option value="3">Card</option>
																			<option value="4">UPI</option>
																		</select>
																	</span>
																</div>
															</div>
															<div class="paymentSecBtm paymentSecBtm_PTour">
																<label style={{ margin: "0px" }}>Add note</label>
																<div className="appointmentFormList">
																	<textarea
																		type="text"
																		className="formInput"
																		id="addNote"
																		name="addNote"
																		placeholder="Text here..."
																	/>
																</div>
															</div>
														</div>
													</div>
													:
													isAdvanceClick === false &&
													<span className="ptInvoiceAddMsg INVOICE" style={{ display: 'block' }} id="selectAnInvoiceDiv">
														<span class="treatmentCommonTxt1" style={{ position: "relative" }}>
															<span> Select an invoice from the right to refund</span>
														</span>
													</span>
												}
												<div
													class="paymentSec paymentSecDiv paymentPaymntSec_PTour"
													style={{ display: (isAdvanceClick === true ? 'block' : 'none'), paddingTop: '10px' }} id="refundAdvanceAmount"
												>
													<span class="ContentLabel" style={{ paddingLeft: '10px' }}>
														TOTAL AVAILABLE ADVANCE : <span style={{ fontWeight: "normal" }}>INR</span>
														<span type="text" style={{ color: '#f00' }}>{parseFloat(getAdvanceAmt).toFixed(2)}</span>
													</span>
													<br />
													<div class="paymentSecContent paymentSecContent_PTour refundAmoutINR">
														<div class="paymentSecLst">
															<label style={{ fontSize: "12px" }}>REFUND AMOUNT : INR </label>
															<span>
																<input type="text" autocomplete="off" maxlength="10" value={refundAdvAmt} style={{ height: "25px" }} onChange={(e) => setRefundAdvAmt(e.target.value)} onKeyUp={(e) => settingRefundAdvcAmount(e)} />
																<select
																	style={{
																		height: '25px',
																		width: 'auto',
																		border: '1px solid #000', marginTop: "5px"
																	}}
																	id="refundpaymentTypeId"
																>
																	<option value="1">Cash</option>
																	<option value="2">Cheque</option>
																	<option value="3">Card</option>
																	<option value="4">UPI</option>
																</select>
															</span>
														</div>
														<span class="ContentLabel txtGreenColor">
															ADVANCE AFTER REFUND: INR<span style={{ color: '#f00' }}>{parseFloat(advanceAfterRefund).toFixed(2)}</span>
														</span>
													</div>
													<div class="paymentSecBtm paymentSecBtm_PTour" style={{ position: 'relative', top: '6px' }}>
														<label style={{ margin: "0px" }}>Add note</label>
														<div className="appointmentFormList">
															<textarea
																type="text"
																className="formInput"
																id="addNoteRefund"
																name="addNote"
																placeholder="Text here..."
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>












							</div>
							<div className="col-lg-4 col-md-12 practiceRightSection">
								<div
									class="treatmentProcedureList completedPlanProce_PTour proceduresinner_PTour testResultDetails_PTour"
									id="treatmentProcedureList"
								>
									<div class="treatmentListtop invoiceSearchProcedure_PTour paymentPending_PTour">
										<div class="treatmentListHead txtCenter">
											<span id="invoiceTxt" class="inVoiceTxtLabel">
												Select Paid Invoice
											</span>
											<span id="noInvoiceTxt" class="inVoiceTxtLabel" style={{ display: 'none' }}>
												No Paid Invoice
											</span>
										</div>
									</div>
									{getInvoiceGroupListing.length > 0 &&

										<div style={{ display: 'block' }} class="treatmentListSide invoiceFullDetails_PTour paymentDetailsPending_PTour" >
											<div class="treatmentNameList">
												<ul id="treatList">
													{getInvoiceGroupListing.map((listObj, i) => (
														listObj.invoiceAmount > 0 &&
														<div class="inVoiceSideWraper paymentSideWrapper_PTour" id={"aaa" + listObj.invoiceId} onClick={() => { addInvList(listObj.invoiceId, "aaa" + listObj.invoiceId, listObj) }}>
															<div class="inVoiceSideBlck" id="invoiceDiv53068">
																<div class="inVoiceTop">
																	<div class="inVoiceSideLeft">{listObj.invoiceNumber}</div>
																	<div class="inVoiceSideRight">{listObj.generatedOn}</div>
																</div>
																<div class="inVoiceAmount">
																	<div class="inVoiceTotalAmount">
																		<div class="inVoiceSideLeft">Invoice Amount</div>
																		<div class="inVoiceSideRight">₹ {parseFloat(listObj.totalNetAmount).toFixed(2)} </div>
																	</div>
																	{listObj.refundAmount > 0 &&
																		<div>
																			<div class="inVoiceAmountList">
																				<div class="inVoiceSideLeft">Refunded Amount</div>
																				<div class="inVoiceSideRight">₹ {parseFloat(listObj.refundAmount).toFixed(2)}</div>
																			</div>
																			<div class="inVoiceAmountList">
																				<div class="inVoiceSideLeft">Balance Amount</div>
																				<div class="inVoiceSideRight">₹ {parseFloat(listObj.invoiceAmount).toFixed(2)}  </div>
																			</div>
																		</div>
																	}
																</div>
															</div>
														</div>

													))}
												</ul>
											</div>
										</div>

									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AddPaymentsRefund;
