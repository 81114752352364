import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
export default function LabOrderRight(props) {
  const [testData, setTestData] = useState([]);
  const [hospitalId, setHospitalId] = useState(
    localStorage.getItem("hospitalId")
  );
  const [memberId, setMemberId] = useState("");
  useEffect(() => {
    getLabOrderData();
  }, [props]);
  const getLabOrderData = () => {
    const PostData = {
      memberId:
        props.testIsFlagData === "TestResult" ||
        props.testIsFlagData === "TestResultCollection" ||
        props.testIsFlagData === "TestResultLBD" ||
        props.testIsFlagData === "TestResultLBD1"
          ? props.testMemberIdData
          : window.sessionStorage.getItem("memberId"),
      hospitalId: hospitalId,
      doctorVisitLabOrderTestId: "",
    };
    callingAPI("patientsListing/testResultSideList", PostData)
      .then((res) => {
        setTestData(res.data.result.testData);
      })
      .catch();
  };
  return (
    <div id="testResult" class="treatmentProcedureList">
      <div class="trestResultMainList">
        <div class="treatmentListtop">
          <div class="treatmentListHead">
            <span>Lab Order</span>
          </div>
        </div>
        <div class="treatmentListSide">
          {testData.length > 0 && (
            <div>
              {testData.map((item, index) => (
                <div class="treatmentNameList testResultDetails_PTour">
                  <div id="medicineListMost">
                    <div class="medicineHead">{item.testDate}</div>
                    {item.data.map((data) => (
                      <div class="treatmentNameList medicineNameList">
                        <ul>
                          <li onClick={() => props.labOrderTestResult(data)}>
                            <span class="elipsis">{data.testName} </span>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
