import React, { useState } from 'react';
import Favicon from 'react-favicon';
function ForgotPasswordHeader() {
	const [siteId, setsiteId] = useState(window.sessionStorage.getItem('siteId'));
	const [logoPath, setlogoPath] = useState(process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + "/" + process.env.REACT_APP_S3_LOGO_PATH + "/");
	const [showUserLink, setUserLink] = useState(false);
	const removeLoginSessions = () => {
		window.sessionStorage.removeItem('actualUserId');
		window.sessionStorage.removeItem('role');
		window.sessionStorage.removeItem('doctorId');
		window.sessionStorage.removeItem('userId');
		window.sessionStorage.removeItem('hospitalId');
		window.sessionStorage.removeItem('userName');
		window.sessionStorage.removeItem('isLoggedIn');
		window.location.href = '/';
	};
	const showUserLinks = () => {
		setUserLink(!showUserLink);
	};
	return (
		<>
			<div class="pulseHeader">
				{(siteId !== null && siteId !== "null" && siteId !== "") ?
					<Favicon url={logoPath + window.sessionStorage.getItem('siteFavIcon')} />
					:
					<Favicon url={logoPath + "fav_icon_pulse.ico"} />
				}
				<div class="container">
					<span class="pulseLogo">
						<a href="">
							{(siteId !== null && siteId !== "null" && siteId !== "") ?
								<img src={logoPath + window.sessionStorage.getItem('siteLogo')} class="  imageResponsive" />
								:
								<img src={logoPath + "purpleLogo.png"} class="  imageResponsive" />
							}
						</a>
					</span>
					{/* {window.sessionStorage.getItem('isLoggedIn') === "true" &&
						<div class="userCoverWrap">
							<span class="userClicks" onClick={() => showUserLinks()}>
								{window.sessionStorage.getItem('userName')}<span class="downCaret" />
							</span>
							<div class="userSubDetail" style={{ display:  showUserLink === false ? 'none' : 'block' }}>
								<a href="/Dashboard/profile">My Profile</a>
								<a href="/Dashboard/dashboardMain">My Dashboard</a>
								<a href="#" onClick={() => removeLoginSessions()}>Sign Out</a>
							</div>
						</div>
					} */}
				</div>
			</div>
		</>
	)
}

export default ForgotPasswordHeader
