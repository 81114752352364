import React, { Component, useState, useEffect, useRef, Suspense } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import $ from "jquery";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faUserMd,
  faSearch,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Blockcalenderpopup from "./blockcalenderPopup";
import { callingAPI } from "../config";
import User from "../images/icons/icon3.png";
import Call from "../images/icons/call.png";
import Mail from "../images/icons/mail.png";
import People from "../images/icons/people.png";
import Editicon from "../images/icons/edit-bg.png";
import Datepicker, { CalendarContainer } from "react-datepicker";
import ArrivedStatus from "../images/icons/arrivedStatus.png";
import OpenStatus from "../images/icons/openStatus.png";
import ConfirmStatus from "../images/icons/confirmStatus.png";
import CancelledStatus from "../images/icons/cancelledStatus.png";
import AdmittedStatus from "../images/icons/admittedStatus.png";
import CompletedStatus from "../images/icons/completedStatus.png";
import DischargeStatus from "../images/icons/dischargeStatus.png";
import EngagedStatus from "../images/icons/engagedStatus.png";
import CallCompletedStatus from "../images/icons/callCompletedStatus.png";
import exportCalendar from "../images/icons/exportCalendar.png";
import LoaderSm from "../Common/loaderSm";
var moment = require("moment");
let Header = React.lazy(() => import("../layout/header"))
let Footer = React.lazy(() => import("../layout/footer"))

const istoday = [
  { statusId: 0, statusName: "Today" },
  { statusId: 3, statusName: "Waiting" },
  { statusId: 8, statusName: "Engaged" },
  { statusId: 6, statusName: "Completed" },
];
const isBefore = [
  { statusId: 1, statusName: "Open" },
  { statusId: 2, statusName: "Confirm" },
  { statusId: 9, statusName: "Cancelled" },
];
const isAfter = [
  { statusId: 5, statusName: "Admitted" },
  { statusId: 6, statusName: "Completed" },
  { statusId: 9, statusName: "Cancelled" },
];
let appointmentData = [];
let calendarData = [];
let calendarDataView = [];
let tabIndex = 0;
let dateCheck = moment().format("YYYY-MM-DD");
let doctor = [];
let hospitalItem = [];
let color = "#6B8E23";
let dataFlag = true;
let selectDate = "";
let doctorName = 0;
let hospitalName = 0;
let appointmentMobileNo = "";
let appointmentMobileCode = "+91";
let appointmentMRNumber = "";
let appointmentPRNumber = "";
let appointmentPatientsFirstName = "";
let appointmentPatientsLastName = "";
let appointmentPatientEmailID = "";
let appointmentPatientGender = 0;
let appointmentPatientsImageName = "";
let appointmentTypeValue = "Walk In";
let appointmentStatus = 3;
let appointmentPatientStartTime = 0;
let appointmentPatientEndTime = 0;
let appointmentPatientReason = "";
let patientAppointmentId = "";
let memberId = "";
let doctorvisitId = "";
let calendarview = "timeGridWeek";
let fromToday = new Date();
let toSevenDay = new Date().setDate(new Date().getDate() + 7);
let allDoc = true;
let docDataSet = [];
let appointmentSelectDate = moment().format("ddd MMMM DD, YYYY");
let mrOld = "";
let calenderAppointmentBackUpData = [];


function ClinicsCalendar() {
  const calendarComponentRef = React.createRef();
  const blockCalendarRef = useRef();
  const [userId, setUserId] = useState(window.sessionStorage.getItem("userId"));
  const [siteId, setSiteId] = useState(window.sessionStorage.getItem("siteId"));
  const [hospitalId, setHospitalId] = useState(
    localStorage.getItem("hospitalId")
  );
  const [role, setRole] = useState(window.sessionStorage.getItem("role"));
  const [docHosList, setDocHosListList] = useState([]);
  const [selectedDate, setselectedDate] = useState(
    moment().format("MMM D, YYYY")
  );
  const [calenderFlag, setCalenderFlag] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [calendarDataNew, setCalendarDataNew] = useState([]);
  const [appointmentDataNew, setAppointmentDataNew] = useState([]);
  const [todayLength, setTodayLength] = useState("");
  const [isBlockedLength, setisBlockedLength] = useState("");

  const [getHospitalIdOpen, setHospitalIdOpen] = useState(
    localStorage.getItem("hospitalId")
  );

  const [waitingLength, setWaitingLength] = useState("");
  const [engagedLength, setEngagedLength] = useState("");
  const [completedLength, setCompletedLength] = useState("");
  const [openLength, setOpenLength] = useState("");
  const [confirmLength, setConfirmLength] = useState("");
  const [cancelledLength, setCancelledLength] = useState("");
  const [admittedLength, setAdmittedLength] = useState("");
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [modal, setModal] = useState(false);
  const [appointmentTime, setAppointmentTime] = useState("");
  const [patientAutoList, setPatientAutoList] = useState([]);
  const [patientMobileFlag, setPatientMobileFlag] = useState(false);
  const [patientNameFlag, setPatientNameFlag] = useState(false);
  const [patientLastNameFlag, setPatientLastNameFlag] = useState(false);
  const [patientMrFlag, setPatientMrFlag] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [mrDisabled, setMrDisabled] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const [status, setStatus] = useState([]);
  const [mrValue, setmrValue] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderSm, setLoaderSm] = useState(true);
  const [Listflag, setListflag] = useState(false);
  const [startDate, setStartDate] = useState(fromToday.setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(
    new Date(toSevenDay).setHours(23, 59, 59, 999)
  );
  const [calendarListView, setCalendarListView] = useState([]);
  const [editFlag, setEdit] = useState(false);
  const [appointmentEventId, setAppointmentEventId] = useState("");
  const [appointmentType, setAppointmentType] = useState([]);
  const [buttondisable, setButtonDisable] = useState(false);
  const [smsFlag, setSmsFlag] = useState(0);
  const [newPatientAppointmentId, setNewPatientAppointmentId] = useState("");
  const [dayWeek, setDayWeek] = useState("timeGridDay");
  const [calanderRefresh, setCalanderRefresh] = useState(new Date().getTime());
  const [dob, setFromDate] = useState("");
  const [state, setState] = useState({
    imageName: "",
    mobileNumber: "",
    phone: "",
    ageId: "",
  });
  const [getAgeMonth, setAgeMonth] = useState("Year");
  const [prNoAutoComplete, setPrNoAutoComplete] = useState('');

  const [appointmentTypeName, setAppointmentTypeName] = useState("");

  const [key, setKey] = useState(0);

  useEffect(() => {
    window.sessionStorage.setItem("qaz", "none");
    window.sessionStorage.setItem("qaz1", "none");
    getAppointmentType();
    getIsPrNoEnableFlag();
    if (
      (role == "ROLE_DOCTOR" || role == "GROUP_ADMIN") &&
      userId !== null &&
      userId !== "null" &&
      userId !== "" &&
      userId !== "undefined" &&
      userId !== undefined
    ) {
      getHospitalList();
    } else if (
      (role == "ROLE_HOSPITALADMIN" ||
        role == "ROLE_NURSE" ||
        role == "ROLE_RECEPTION") &&
      hospitalId !== null &&
      hospitalId !== "null" &&
      hospitalId !== "" &&
      hospitalId !== "undefined" &&
      hospitalId !== undefined &&
      userId !== null &&
      userId !== "null" &&
      userId !== "" &&
      userId !== "undefined" &&
      userId !== undefined
    ) {
      getDoctorList();
    }
    statusData();
    getPopulateTimeDuration();
  }, []);

  $(document).ready(function () {
    function log(txt) {
      $(".fixed_top .__react_component_tooltip ").css({
        top: txt,
      });
    }

    $(function () {
      if (
        typeof $(".fc .fc-scroller-liquid-absolute").offset() !== "undefined"
      ) {
        var eTop = $(".fc .fc-scroller-liquid-absolute").offset().top; //get the offset top of the element
        log(eTop - $(window).scrollTop()); //position of the ele w.r.t window

        $(window).scroll(function () {
          //when window is scrolled
          log(eTop - $(window).scrollTop());
        });
      }
    });
  });
  const selectAppointmentType = (e) => {
    setAppointmentTypeName(e.target.value);
  };
  const spanColour = (e) => {
    if (e.target.id === "dobGreen") {
      // setDobAlert(true)
      document.getElementById("dobDashId").style.display = "block";
      document.getElementById("ageDashId").style.display = "none";
      document.getElementById("agemonth").style.display = "none";
      document.getElementById("ageGreen").classList.remove("tabActive");
      document.getElementById("dobGreen").classList.add("tabActive");
      document.getElementById("ageId").style.display = "none";
    } else {
      document.getElementById("dobDashId").style.display = "none";
      document.getElementById("ageId").style.display = "block";
      document.getElementById("ageDashId").style.display = "block";
      document.getElementById("agemonth").style.display = "block";
      document.getElementById("dobGreen").classList.remove("tabActive");
      document.getElementById("ageGreen").classList.add("tabActive");

    }
  };
  const ageChange = (date) => {

    if (date !== null && date !== "null") {
      setFromDate(date);
      var today = new Date();
      var birthDate = date;
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      setState({
        ageId: age,
      });
    } else {
      setFromDate("");
      setState({
        ageId: "",
      });
    }
  };
  const handleChange = (event) => {
    if (event.target.id === "ageId") {
      if (getAgeMonth === "Year") {
        if (
          document.getElementById("ageId").value < 1 &&
          document.getElementById("ageId").value !== ""
        ) {
          alert("Please enter value greater than zero");
          event.target.value = "";
        } else {
          var age = document.getElementById("ageId").value * 31556926000; //in Millis
          var dateOfBirth = new Date(new Date().getTime() - age);
          if (
            (0 == dateOfBirth.getFullYear() % 4 &&
              0 != dateOfBirth.getFullYear() % 100) ||
            0 == dateOfBirth.getFullYear() % 400
          ) {
            setFromDate(dateOfBirth);
          } else {
            setFromDate(dateOfBirth);
          }
          setState({
            ageId: event.target.value,
          });
        }
      } else if (getAgeMonth === "Month") {
        if (
          document.getElementById("ageId").value < 1 &&
          document.getElementById("ageId").value !== ""
        ) {
          alert("Please enter value greater than zero");
          event.target.value = "";
        } else if (document.getElementById("ageId").value <= 12) {
          var month = document.getElementById("ageId").value * 2.765e9; //in Millis
          var dateOfBirth = new Date(new Date().getTime() - month);
          setFromDate(dateOfBirth);
          setState({
            ageId: event.target.value,
          });
        } else {
          alert("Please enter value less than twelve");
          event.target.value = "";
        }
      } else if (getAgeMonth === "Day") {
        if (
          document.getElementById("ageId").value < 1 &&
          document.getElementById("ageId").value !== ""
        ) {
          alert("Please enter value greater than zero");
          event.target.value = "";
        } else {
          var month = document.getElementById("ageId").value * 8.64e7; //in Millis
          var dateOfBirth = new Date(new Date().getTime() - month);
          setFromDate(dateOfBirth);
        }
      }
    }

    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const ageMonth = (e) => {
    setAgeMonth(e.target.value);
    setState({
      ageId: "",
    });
  };
  const blockOpen = () => {
    blockCalendarRef.current.modalPopCall();
  };
  const getAppointmentType = () => {
    const postData = {
      siteId: window.sessionStorage.getItem("siteId"),
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("calenderListing/getServiceDetails", postData)
      .then((res) => {

        let appointmentArray = [];
        let data = [];
        if (res.data.result.length > 0) {
          res.data.result.map((item) => {
            if (item.productName == "Book Appointment") {
              data.push({ name: "In Person", id: item.productId });
            } else {
              data.push({ name: item.productName, id: item.productId });
            }
          });
        }
        data.push({ name: "Walk In", id: "1" });
        appointmentArray.push(...data);
        setAppointmentType(appointmentArray);
      })
      .catch();
  };
  const getDoctorList = () => {
    if (
      hospitalId !== null &&
      hospitalId !== "null" &&
      hospitalId !== "" &&
      hospitalId !== "undefined" &&
      hospitalId !== undefined &&
      userId !== null &&
      userId !== "null" &&
      userId !== "" &&
      userId !== "undefined" &&
      userId !== undefined
    ) {
      $("#AllDoctor").addClass("activeDr");
      $(".leftMenuList").removeClass("activeDr");

      if (
        role == "ROLE_HOSPITALADMIN" ||
        role == "ROLE_NURSE" ||
        role == "ROLE_RECEPTION"
      ) {
        const postData = {
          role: role,
          userId: userId,
          hospitalId: hospitalId,
          doctorName: $("#doctorName").val(),
        };
        callingAPI("calenderListing", postData)
          .then((res) => {
            allDoc = true;
            let docArray = res.data.result.map((item) => item.doctorId);
            res.data.result.map((item, index) => {
              if (index % 7 == 0) {
                item.className = "doctorClrFrst";
                item.colorSet = "#6b8e23";
              } else if (index % 7 == 1) {
                item.className = "doctorClrSnd";
                item.colorSet = "#fd0260";
              } else if (index % 7 == 2) {
                item.className = "doctorClrThrd";
                item.colorSet = "#18e3a1";
              } else if (index % 7 == 3) {
                item.className = "doctorClrFrth";
                item.colorSet = "#4e62b3";
              } else if (index % 7 == 4) {
                item.className = "doctorClrFfth";
                item.colorSet = "#ccb21f";
              } else if (index % 7 == 5) {
                item.className = "doctorClrSxth";
                item.colorSet = "#b74e4f";
              } else {
                item.className = "doctorClrSvnth";
                item.colorSet = "#f60";
              }
              return item;
            });

            doctor = docArray;
            docDataSet = res.data.result;
            setDocHosListList(res.data.result);

            if (dataFlag == true) {
            }
            if (res.data.result.length === 1) {
              doctorSelect(res.data.result[0], res.data.result[0].colorSet, 0);
              setLoaderSm(false);
            } else {
              setLoader(true);
              setLoaderSm(true);
              if (
                hospitalId !== null &&
                hospitalId !== "null" &&
                hospitalId !== "" &&
                hospitalId !== "undefined" &&
                hospitalId !== undefined
              ) {
                appointmentDataSet();
              }
            }
          })
          .catch();
      }
    }
  };
  const getHospitalList = () => {
    if (
      userId !== null &&
      userId !== "null" &&
      userId !== "" &&
      userId !== "undefined" &&
      userId !== undefined
    ) {
      const postData = {
        role: "ROLE_DOCTOR",
        userId: userId,
      };
      callingAPI("calenderListing/getHospitalDetails", postData)
        .then((res) => {
          allDoc = false;

          doctor = res.data.result[0];
          res.data.result.map((item, index) => {
            if (index % 7 == 0) {
              item.className = "doctorClrFrst";
              item.colorSet = "#6b8e23";
            } else if (index % 7 == 1) {
              item.className = "doctorClrSnd";
              item.colorSet = "#fd0260";
            } else if (index % 7 == 2) {
              item.className = "doctorClrThrd";
              item.colorSet = "#18e3a1";
            } else if (index % 7 == 3) {
              item.className = "doctorClrFrth";
              item.colorSet = "#4e62b3";
            } else if (index % 7 == 4) {
              item.className = "doctorClrFfth";
              item.colorSet = "#ccb21f";
            } else if (index % 7 == 5) {
              item.className = "doctorClrSxth";
              item.colorSet = "#b74e4f";
            } else {
              item.className = "doctorClrSvnth";
              item.colorSet = "#f60";
            }
            return item;
          });
          docDataSet = res.data.result;
          setDocHosListList(res.data.result);
          setHospitalId(res.data.result[0].hospitalId);
          setHospitalIdOpen(res.data.result[0].hospitalId);

          if (dataFlag == true) {
            if (
              hospitalId !== null &&
              hospitalId !== "null" &&
              hospitalId !== "" &&
              hospitalId !== "undefined" &&
              hospitalId !== undefined
            ) {
              appointmentDataSet();
            }
          }
          $("#leftMenuList0").addClass("activeDr");

          if (docDataSet.length > 0) {
            if (docDataSet[0].calenderMode === "Weekly") {
              setDayWeek("timeGridWeek");
            } else if (docDataSet[0].calenderMode === "Monthly") {
              setDayWeek("dayGridMonth");
            } else if (
              docDataSet[0].calenderMode === "Daily" ||
              docDataSet[0].calenderMode === ""
            ) {
              setDayWeek("timeGridDay");
            }
            setCalanderRefresh(new Date().getTime());
            setKey(prevKey => prevKey + 1);
          }
        })
        .catch();
    }
  };
  useEffect(() => {
    // Set a timeout to force an update after initial render
    const timer = setTimeout(() => {
      setKey(prevKey => prevKey + 1);
    }, 500); // Adjust timeout as needed

    return () => clearTimeout(timer);
  }, []);
  const searchDoctor = () => {
    if ($("#doctorName").val() == "") {
      dataFlag = true;
    } else {
      dataFlag = false;
    }
    if (
      hospitalId !== null &&
      hospitalId !== "null" &&
      hospitalId !== "" &&
      hospitalId !== "undefined" &&
      hospitalId !== undefined &&
      userId !== null &&
      userId !== "null" &&
      userId !== "" &&
      userId !== "undefined" &&
      userId !== undefined
    ) {
      getDoctorList();
    }
  };
  const colorCheck = (id) => {
    let color = docDataSet
      .filter((item) => item.doctorId == id)
      .map((item) => item.colorSet);
    return color;
  };
  const blockCalendarChange = () => {
    if (
      hospitalId !== null &&
      hospitalId !== "null" &&
      hospitalId !== "" &&
      hospitalId !== "undefined" &&
      hospitalId !== undefined
    ) {
      appointmentDataSet();
    }
  };
  const calendarAfterList = (i) => {


    return new Promise((resolve, reject) => {
      let doctorId = "";
      if (role == "ROLE_DOCTOR") {
        doctorId = userId;
      } else {
        if (doctor.length > 1) {
          doctorId = doctor;
        } else {
          doctorId = doctor.doctorId;
        }
      }
      let postData = {};
      if (role == "ROLE_DOCTOR") {
        postData = {
          doctorId: doctorId,
          patientAppointmentId: "",
          hospitalId: hospitalId,
          days: "",
          max: "100",
          offset: i,
        };
      } else {
        postData = {
          doctorId: doctorId,
          patientAppointmentId: "",
          hospitalId: localStorage.getItem("hospitalId"),
          days: "",
          max: "100",
          offset: i,
        };
      }
      callingAPI("calenderListing/scheduleList", postData)
        .then((res) => {

          if (res.data.success === "1") {
            setLoader(false);
            dataFlag = true;
            let newArray = [];

            if (res.data.result.length > 0 && res.data.result !== "") {
              newArray = res.data.result.map((val, idx) => {
                val.title =
                  moment(val.startTime).format("HH:MM a") +
                  " " +
                  val.doctorName;
                if (val.isBlocked == false) {
                  val.backgroundColor =
                    allDoc == true ? colorCheck(val.doctorId) : color;
                } else {
                  val.backgroundColor = "#000000";
                }
                val.textColor = "white";
                val.color = "white";
                val.patientAppointmentId = val.patientAppointmentId;
                val.start1 = val.startTime;
                val.end1 = val.endTime;
                val.start =
                  calendarview !== "dayGridMonth"
                    ? val.startTime
                    : moment(val.startTime).format("YYYY-MM-DD");
                val.end =
                  calendarview !== "dayGridMonth"
                    ? val.endTime
                    : moment(val.endTime).format("YYYY-MM-DD");
                delete val.startTime;
                delete val.endTime;
                return val;
              });
            }
            newArray = calenderAppointmentBackUpData.concat(newArray);
            calenderAppointmentBackUpData = newArray;

            setCalendarDataNew(newArray);
            console.log("calenderListingnewArrayLength", newArray.length)
            calendarData = newArray;
            calendarDataView = newArray;
            appointmentDataFilter();
            if (i === 0) {
              setLoaderSm(false);
            }
            if (calendarview == "List") {
              listView("List");
            }
            resolve(true);
            getIsPrNoEnableFlag();
          } else {
            calendarData = [];
            calendarDataView = [];
            resolve(true);
          }
        })
        .catch();
    });
  };

  const getIsPrNoEnableFlag = () => {
    const PostData = {
      "hospitalId": localStorage.getItem("hospitalId"),

    };
    callingAPI('commonUserFunctions/getIsPrNoEnableFlag', PostData).then((response) => {
      if (response.data.success === '1') {
        window.sessionStorage.setItem("isPrNumEnabled", response.data.result[0].is_prno_enabled);
      }
      else {
        alert(response.data.errorMessage)
      }
    })
      .catch();
  }
  const appointmentDataSet = () => {
    let doctorId = "";
    if (role == "ROLE_DOCTOR") {
      doctorId = userId;
    } else {

      if (doctor.length > 1) {
        doctorId = doctor;
      } else {
        doctorId = doctor.doctorId;
      }
    }
    let postData = {};
    if (role == "ROLE_DOCTOR") {
      postData = {
        doctorId: doctorId,
        patientAppointmentId: "",
        hospitalId: hospitalId,
        days: 3,
        max: "",
        offset: "",
      };
    } else {
      postData = {
        doctorId: doctorId,
        patientAppointmentId: "",
        hospitalId: localStorage.getItem("hospitalId"),
        days: 3,
        max: "",
        offset: "",
      };
    }
    callingAPI("calenderListing/scheduleList", postData)
      .then(async (res) => {
        if (res.data.success === "1") {
          setLoader(false);
          dataFlag = true;
          let newArray = [];
          if (res.data.result.length > 0 && res.data.result !== "") {
            newArray = res.data.result.map((val, idx) => {
              val.title =
                moment(val.startTime).format("HH:MM a") + " " + val.doctorName;
              if (val.isBlocked == false) {
                val.backgroundColor =
                  allDoc == true ? colorCheck(val.doctorId) : color;
              } else {
                val.backgroundColor = "#000000";
              }
              val.textColor = "white";
              val.color = "white";
              val.patientAppointmentId = val.patientAppointmentId;
              val.start1 = val.startTime;
              val.end1 = val.endTime;
              val.start =
                calendarview !== "dayGridMonth"
                  ? val.startTime
                  : moment(val.startTime).format("YYYY-MM-DD");
              val.end =
                calendarview !== "dayGridMonth"
                  ? val.endTime
                  : moment(val.endTime).format("YYYY-MM-DD");
              delete val.startTime;
              delete val.endTime;
              return val;
            });
          }
          else {
            setLoaderSm(false)
          }
          setCalendarDataNew(newArray);
          calendarData = newArray;
          calendarDataView = newArray;
          setLoader(false);
          appointmentDataFilter();
          if (calendarview == "List") {
            listView("List");
          }
          if (
            hospitalId !== null &&
            hospitalId !== "null" &&
            hospitalId !== "" &&
            hospitalId !== "undefined" &&
            hospitalId !== undefined
          ) {

            for (
              let i = 0;
              i < localStorage.getItem("doctorAppointmentCount");

            ) {
              console.log("calenderListingnewArrayLength", newArray.length)
              await calendarAfterList(i);

              i = i + 100;
            }

          }
          getIsPrNoEnableFlag();
        } else {
          setCalendarDataNew([]);
          calendarData = [];
          calendarDataView = [];
        }
      })
      .catch();
  };
  const tabClick = (id) => {
    setTabValue(id);
    tabIndex = id;
    tabDataFilter();
  };
  const doctorSelect = (item, colorset, index) => {
    $("#AllDoctor").removeClass("activeDr");
    if (role == "ROLE_DOCTOR") {
      setHospitalId(item.hospitalId);
      setHospitalIdOpen(item.hospitalId);
    } else {
      setHospitalId(localStorage.getItem("hospitalId"));
      setHospitalIdOpen(localStorage.getItem("hospitalId"));
    }
    $(".leftMenuList").removeClass("activeDr");
    $("#leftMenuList" + index).addClass("activeDr");
    allDoc = false;
    setLoader(true);
    setLoaderSm(true);
    color = colorset;
    doctor = item;
    setListflag(false);
    $(".fc-view-harness.fc-view-harness-active").show();
    $(".fc-toolbar-chunk:first-child .fc-button-group").show();
    $(".fc-toolbar-chunk .fc-toolbar-title").show();
    $(".fc-list-button").removeClass("fc-button-active");
    if (
      hospitalId !== null &&
      hospitalId !== "null" &&
      hospitalId !== "" &&
      hospitalId !== "undefined" &&
      hospitalId !== undefined
    ) {
      calenderAppointmentBackUpData = []
      appointmentDataSet();
    }
  };
  const getPreviousDate = () => {
    let createdDate = moment(selectedDate).format();
    let expirationDate = moment(createdDate).subtract(1, "d");
    dateCheck = expirationDate.format("YYYY-MM-DD");
    setselectedDate(expirationDate.format("MMM DD YYYY"));
    dateChecking(dateCheck);
    appointmentDataFilter();
  };
  const getNextDate = () => {
    let createdDate = moment(selectedDate).format();
    let expirationDate = moment(createdDate).add(1, "d");
    dateCheck = expirationDate.format("YYYY-MM-DD");
    setselectedDate(expirationDate.format("MMM DD YYYY"));
    dateChecking(dateCheck);
    appointmentDataFilter();
  };
  const dateChecking = (date) => {
    let todaydate = moment().format("YYYY-MM-DD");
    if (moment().format("YYYY-MM-DD") == date) {
      tabIndex = 0;
      setTabValue(0);
    } else if (new Date(date).getTime() > new Date(todaydate).getTime()) {
      tabIndex = 1;
      setTabValue(1);
    } else {
      tabIndex = 5;
      setTabValue(5);
    }
  };
  const appointmentDataFilter = () => {
    calendarData = removeDuplicate(calendarData, (it) => it.doctorVisitId);
    let newData = calendarData
      .filter((data) => dateCheck === moment(data.start1).format("YYYY-MM-DD"))
      .map((item) => item);
    let setIsBlockedTodayDate = calendarData
      .filter(
        (data) =>
          dateCheck === moment(data.start1).format("YYYY-MM-DD") &&
          data.isBlocked == true
      )
      .map((isBlockedTodaycount) => isBlockedTodaycount);
    let todayLength =
      parseFloat(newData.length) - parseFloat(setIsBlockedTodayDate.length);
    setTodayLength(todayLength);
    let setWaitingArray = [];
    let setEngagedArray = [];
    let setCompletedArray = [];
    let setOpenArray = [];
    let setConfirmArray = [];
    let setCancelledArray = [];
    let setAdmittedArray = [];

    newData.map((item) => {
      if (item.statusName === "ENGAGED") {
        setEngagedArray.push(item);
      } else if (item.statusName === "COMPLETED") {
        setCompletedArray.push(item);
      } else if (item.statusName === "OPEN") {
        setOpenArray.push(item);
      } else if (item.statusName === "CONFIRM") {
        setConfirmArray.push(item);
      } else if (item.statusName === "CANCELLED") {
        setCancelledArray.push(item);
      } else if (item.statusName === "ADMITTED") {
        setAdmittedArray.push(item);
      } else if (item.statusName === "ARRIVED") {
        setWaitingArray.push(item);
      } else {
      }
    });

    setWaitingLength(setWaitingArray.length);
    setEngagedLength(setEngagedArray.length);
    setCompletedLength(setCompletedArray.length);
    setOpenLength(setOpenArray.length);
    setConfirmLength(setConfirmArray.length);
    setCancelledLength(setCancelledArray.length);
    setAdmittedLength(setAdmittedArray.length);
    appointmentData = newData;
    tabDataFilter();
  };

  const tabDataFilter = () => {

    appointmentData = removeDuplicate(
      appointmentData,
      (it) => it.doctorVisitId
    );

    if (tabIndex == 0) {
      setAppointmentDataNew(appointmentData);
    } else if (tabIndex == 3) {
      let appointmentDataFilter = appointmentData
        .filter((data) => tabIndex == data.statusId)
        .map((item) => item);
      setAppointmentDataNew(appointmentDataFilter);
    } else {
      let appointmentDataFilter = appointmentData
        .filter((data) => tabIndex == data.statusId)
        .map((item) => item);
      setAppointmentDataNew(appointmentDataFilter);
    }
  };

  const removeDuplicate = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };

  const statusData = () => {
    callingAPI("calenderListing/getStatusDetails")
      .then((res) => {
        setStatus(res.data.result);
      })
      .catch();
  };
  const getMRData = (Id) => {
    let hospitalId;
    if (role == "ROLE_DOCTOR") {
      hospitalId = Id;
    } else {
      hospitalId = localStorage.getItem("hospitalId");
    }

    const postData = {
      hospitalId: hospitalId,
      memberId: "0",
    };
    callingAPI("calenderListing/autoCompleteGetMrNo", postData)
      .then((res) => {
        setmrValue(res.data.result[0].mrNo);
        mrOld = res.data.result[0].mrNo;
      })
      .catch();
  };
  const hosNameChange = (e) => {
    getMRData(e.target.value);
    setHospitalId(e.target.value);
    if (e.target.value !== hospitalName) {
      $("#appointmentDoctorName").val(0);
      $("#appointmentMobileCode").val("+91");
      $("#appointmentMobileNo").val("");
      $("#appointmentMRNumber").val("");
      $("#appointmentPRNumber").val("");

      $("#appointmentPatientsFirstName").val("");
      $("#appointmentPatientsLastName").val("");
      $("#appointmentPatientEmailID").val("");
      $("#appointmentPatientReason").val("");
      $("#appointmentPatientGender").val(0);
      setFromDate("");

      if (disabled == true) {
        setDisabled(false);
        setMrDisabled(false);
        $("#appointmentMobileCode").prop("disabled", false);
        $("#appointmentMobileNo").prop("disabled", false);
        $("#appointmentPatientsFirstName").prop("disabled", false);
        $("#appointmentPatientsLastName").prop("disabled", false);
        $("#appointmentPRNumber").prop("disabled", false);
        $("#appointmentPatientsLastName").val("");
        setFromDate("");
        $("#appointmentPatientEmailID").val("");
        $("#appointmentPatientReason").val("");
        $("#appointmentPatientGender").val(0);
      }
    }
  };
  const getPopulateTimeDuration = () => {
    var hours, minutes, ampm;
    var timeStringArray = [];
    for (var i = 0; i <= 1425; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      var timeString = hours + ":" + minutes + " " + ampm;
      timeStringArray.push(timeString);
    }
    setTimeIntervals(timeStringArray);
  };
  const saveAppointmentEvent = () => {
    setButtonDisable(true);
    var regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    let startTime = moment(
      selectDate + " " + $("#starttime").val(),
      "DD/MM/YYYY HH:mm a"
    ).valueOf();
    let endTime = moment(
      selectDate + " " + $("#appointmentPatientEndTime").val(),
      "DD/MM/YYYY HH:mm a"
    ).valueOf();
    let appointmentDate =
      moment(selectDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
      " " +
      $("#starttime").val();
    let reScheduledAppointment =
      patientAppointmentId == ""
        ? []
        : [
          {
            reScheduleDateTime: appointmentDate,
            reScheduleStartTime: startTime,
            reScheduleEndTime: endTime,
          },
        ];
    if ($("#appointmentDoctorName").val() == 0) {
      alert("Please select a Doctor");
      setButtonDisable(false);
    } else if ($("#appointmentHosName").val() == 0) {
      alert("Please select a Hospital");
      setButtonDisable(false);
    } else if ($("#appointmentMobileNo").val() == "") {
      alert("Please enter  Mobile Number ");
      $("#appointmentMobileNo").focus();
      setButtonDisable(false);
    } else if ($("#appointmentMobileNo").val().length != 10) {
      alert("Mobile number must be 10 digits");
      $("#appointmentMobileNo").focus();
      setButtonDisable(false);
    } else if ((window.sessionStorage.getItem('isPrNumEnabled') === "false") && ($("#appointmentMRNumber").val().trim() == "")) {
      alert("Please enter MR Number ");
      $("#appointmentMRNumber").focus();
      setButtonDisable(false);
    } else if ($("#appointmentPatientsFirstName").val() == "") {
      alert("Please enter Patient First Name");
      $("#appointmentPatientsFirstName").focus();
      setButtonDisable(false);
    } else if (
      $("#appointmentPatientEmailID").val() !== "" &&
      !regex.test($("#appointmentPatientEmailID").val())
    ) {
      alert("Please enter valid Email Id");
      $("#appointmentPatientEmailID").focus();
      setButtonDisable(false);
    } else if ($("#appointmentPatientGender").val() == 0) {
      alert("Please select Gender");
      setButtonDisable(false);
    } else if ($("#appointmentTypeValue").val() == 0) {
      alert("Please select Appointment Type");
      setButtonDisable(false);
    } else if ($("#appointmentStatus").val() == 0) {
      alert("Please select Status");
      setButtonDisable(false);
    } else if ($("#starttime").val() == 0) {
      alert("Please select Start Time");
      $("#starttime").focus();
      setButtonDisable(false);
    } else if ($("#appointmentPatientEndTime").val() == 0) {
      alert("Please select End Time");
      $("#appointmentPatientEndTime").focus();
      setButtonDisable(false);
    } else if (startTime >= endTime) {
      alert("End time must be greater than start time");
      setButtonDisable(false);
    } else if ($("#appointmentPatientReason").val() == "") {
      alert("Please enter Reason ");
      $("#appointmentPatientReason").focus();
      setButtonDisable(false);
    } else if (dob == "") {
      alert("Please select Date Of Birth");
      $("#dobId").focus();
      setButtonDisable(false);
    } else {
      if (newUser == true) {
        MrNumValidation(
          reScheduledAppointment,
          startTime,
          endTime,
          appointmentDate,
          "CreateAppointment"
        );
      } else {
        setLoader(true);
        appointmentSave(
          reScheduledAppointment,
          startTime,
          endTime,
          appointmentDate,
          "CreateAppointment"
        );
      }
    }
  };
  const MrNumValidation = (
    reScheduledAppointment,
    startTime,
    endTime,
    appointmentDate,
    name,
    hospitalIdList
  ) => {
    let doctorUserId = "";
    let HosUserId = "";
    if (name == "CreateAppointment") {
      doctorUserId =
        docHosList.length === 1
          ? docHosList[0].doctorId
          : $("#appointmentDoctorName").val();
      HosUserId =
        docHosList.length === 1
          ? docHosList[0].hospitalId
          : $("#appointmentHosName").val();
    } else {
      doctorUserId = doctorName;
      HosUserId = hospitalName;
    }
    const saveData = {
      hospitalId: role == "ROLE_DOCTOR" ? HosUserId : hospitalId,
      mrNo: $("#appointmentMRNumber").val(),
      memberId: memberId,
      Newuser: "1",
    };
    callingAPI("addPatient/checkMrNo", saveData)
      .then((res) => {
        if (res.data.success === "1") {
          setLoader(true);
          appointmentSave(
            reScheduledAppointment,
            startTime,
            endTime,
            appointmentDate,
            name
          );
        } else {
          alert(res.data.errorMessage);
          setButtonDisable(false);
        }
      })
      .catch();
  };
  const appointmentSave = (
    reScheduledAppointment,
    startTime,
    endTime,
    appointmentDate,
    name
  ) => {
    let doctorUserId = "";
    let HosUserId = "";
    let isMrNoEdit = false;
    if (mrOld !== $("#appointmentMRNumber").val()) {
      isMrNoEdit = true;
    }
    if (name == "CreateAppointment") {
      doctorUserId =
        docHosList.length === 1
          ? docHosList[0].doctorId
          : $("#appointmentDoctorName").val();
      HosUserId =
        docHosList.length === 1
          ? docHosList[0].hospitalId
          : $("#appointmentHosName").val();
    } else {
      doctorUserId = doctorName;
      HosUserId = hospitalName;
    }

    const saveData = {
      hidisNewUser: newUser == true ? "newuser" : "",
      //"memberId": memberId,
      memberId: newUser == true ? "" : memberId,
      siteId: window.sessionStorage.getItem("siteId"),
      patientAppointmentDateTime: appointmentDate,
      memberFirstName:
        name == "CreateAppointment"
          ? $("#appointmentPatientsFirstName").val()
          : appointmentPatientsFirstName,
      memberLastName:
        name == "CreateAppointment"
          ? $("#appointmentPatientsLastName").val()
          : appointmentPatientsLastName,
      memberEmailId:
        name == "CreateAppointment"
          ? $("#appointmentPatientEmailID").val()
          : appointmentPatientEmailID,
      secUserAreaCode:
        name == "CreateAppointment"
          ? $("#appointmentMobileCode").val()
          : appointmentMobileCode,

      memberMobileNumber:
        name == "CreateAppointment"
          ? $("#appointmentMobileNo").val()
          : appointmentMobileNo,
      memberGender:
        name == "CreateAppointment"
          ? $("#appointmentPatientGender").val()
          : appointmentPatientGender,
      memberImageName:
        appointmentPatientsImageName === "null" ||
          appointmentPatientsImageName === null ||
          appointmentPatientsImageName === ""
          ? ""
          : appointmentPatientsImageName,
      patientAppointmentId: patientAppointmentId,
      doctorVisitId: doctorvisitId,
      userId: userId,
      patientAppointmentType:
        name == "CreateAppointment"
          ? $("#appointmentTypeValue").val()
          : appointmentTypeValue,
      isRescheduled: patientAppointmentId == "" ? false : true,
      reScheduledAppointment: reScheduledAppointment,
      doctorId: role == "ROLE_DOCTOR" ? userId : doctorUserId,
      hospitalId: role == "ROLE_DOCTOR" ? HosUserId : hospitalId,
      firstReason:
        name == "CreateAppointment"
          ? $("#appointmentPatientReason").val()
          : appointmentPatientReason,
      secondReason: "",
      patientAppointmentStartTime: startTime,
      patientAppointmentEndTime: endTime,
      doctorVisitReasonForVisitByPatient:
        name == "CreateAppointment"
          ? $("#appointmentPatientReason").val()
          : appointmentPatientReason,
      doctorVisitReasonByDoctor:
        name == "CreateAppointment"
          ? $("#appointmentPatientReason").val()
          : appointmentPatientReason,
      doctorVisitDischargeSummary: "",
      doctorVisitDetails: "",
      mrNo:
        name == "CreateAppointment"
          ? $("#appointmentMRNumber").val()
          : appointmentMRNumber,
      patientAppointmentStatusId:
        name == "CreateAppointment"
          ? $("#appointmentStatus").val()
          : appointmentStatus,
      patientAppointmentLastVisitedDate: appointmentDate,
      patientAppointmentLastVisitedReason: "",
      patientAppointmentTokenNo: "",
      isMrNoEdit: isMrNoEdit,
      fromCalender: "",
      memberIsSmsAlert: "false",
      memberIsEmailAlert: "false",
      memberDateOfBirth: dob !== "" ? moment(dob).format("yyyy-MM-DD") : "",
    };

    callingAPI("clinicalBackground/saveOrUpdateAppointments", saveData)
      .then((res) => {

        setLoader(false);
        if (res.data.success === "0") {
          alert(res.data.errorMessage);
          setButtonDisable(false);
        } else if (res.data.success === "1") {
          alert(res.data.successMessage);
          setSmsFlag(res.data.result.smsFlag);
          setNewPatientAppointmentId(res.data.result.newPatientAppointmentId);
          if (name == "CreateAppointment") {
            toggle();
          }
          setEdit(false);
          setEditDisabled(false);
          setDisabled(false);
          setMrDisabled(false);
          setAppointmentEventId();
          if (
            hospitalId !== null &&
            hospitalId !== "null" &&
            hospitalId !== "" &&
            hospitalId !== "undefined" &&
            hospitalId !== undefined
          ) {
            appointmentDataSet();
          }

          setLoader(true);
          let statusIdForSMS =
            name == "CreateAppointment"
              ? $("#appointmentStatus").val()
              : appointmentStatus;
          sendSmsEmail(
            "CreateAppointment",
            appointmentDate,
            res.data.result.newPatientAppointmentId
          );
          setTimeout(() => {
            dataClear();
            window.location.reload();
          }, 2000);
        }
      })
      .catch();
  };
  const sendSmsEmail = (name, appointmentDate, newPatientAppointmentId) => {
    let HosUserId = "";
    let doctorUserId = "";
    if (name == "CreateAppointment") {
      doctorUserId =
        docHosList.length === 1
          ? docHosList[0].doctorId
          : $("#appointmentDoctorName").val();
      HosUserId =
        docHosList.length === 1
          ? docHosList[0].hospitalId
          : $("#appointmentHosName").val();
    } else {
      doctorUserId = doctorName;
      HosUserId = hospitalName;
    }
    const postData = {
      hospitalId: role == "ROLE_DOCTOR" ? HosUserId : hospitalId,
      patientAppointmentId: patientAppointmentId,
      siteId: window.sessionStorage.getItem("siteId"),
      changedAppointmentDateTime: appointmentDate,
      hidisNewUser: newUser == true ? "newuser" : "",
      smsFlag: smsFlag,
      memberEmailId:
        name == "CreateAppointment"
          ? $("#appointmentPatientEmailID").val()
          : appointmentPatientEmailID,
      memberMobileNumber:
        name == "CreateAppointment"
          ? $("#appointmentMobileNo").val()
          : appointmentMobileNo,
      newPatientAppointmentId: newPatientAppointmentId,
      changeDate: moment(appointmentDate).format("YYYY-MM-DD"),
      changeTime: moment(appointmentDate).format("hh : mm A"),
      isRescheduled: patientAppointmentId == "" ? false : true,
      patientAppointmentType: "",
    };
    callingAPI(
      "commonUserFunctions/saveOrUpdateAppointmentEmailAndSms",
      postData
    )
      .then((data) => {

        if (data.data.success === "1") {

        }
      })
      .catch();
  };
  const dataClear = () => {
    setTimeIntervals([]);
    $("#appointmentPatientsFirstName").val("");
    $("#appointmentPatientsLastName").val("");
    setFromDate("");
    $("#appointmentPatientEmailID").val("");
    $("#appointmentMobileCode").val("+91");
    $("#appointmentMobileNo").val("");
    $("#appointmentPatientReason").val("");
    $("#appointmentMRNumber").val("");
    $("#appointmentPRNumber").val("");

    doctorName = 0;
    hospitalName = 0;
    appointmentMobileCode = "+91";
    appointmentMobileNo = "";
    appointmentMRNumber = "";
    mrOld = "";
    setmrValue("");
    appointmentPRNumber = "";
    appointmentPatientsFirstName = "";
    appointmentPatientsLastName = "";
    appointmentPatientsImageName = "";
    appointmentPatientEmailID = "";
    appointmentPatientGender = 0;
    appointmentTypeValue = "Walk In";
    appointmentStatus = 3;
    appointmentPatientStartTime = 0;
    appointmentPatientEndTime = 0;
    appointmentPatientReason = "";
    patientAppointmentId = "";
    memberId = "";
    doctorvisitId = "";
    getPopulateTimeDuration();
    setPatientAutoList([]);
    setPatientMobileFlag(false);
    setPatientNameFlag(false);
    setPatientLastNameFlag(false);
  };
  const editEventFunction = (event, id) => {
    setAppointmentEventId(event.extendedProps.scheduledTimeSlotId);
    toggle();
    setEdit(true);
    setEditDisabled(true);
    setDisabled(true);
    setMrDisabled(true);
    setModal(true);
  };
  const videoChatEventFunction = (event) => {
    const postDataVideoLink = {
      doctorId: event.extendedProps.doctorId,
      siteId: window.sessionStorage.getItem("siteId"),
    };
    callingAPI("/commonUserFunctions/encryptPulseUrl", postDataVideoLink)
      .then((response) => {

        if (response.data.success === "1") {
          window.location.href = response.data.result.encryptedUrl;
        } else {
          alert(response.data.errorMessage);
        }
      })
      .catch();
  };
  const toggle = () => {
    setModal(!modal);
    setEdit(false);
    setEditDisabled(false);
    setDisabled(false);
    setMrDisabled(false);
  };
  const popCancel = () => {
    toggle();
    dataClear();
    setEdit(false);
    setEditDisabled(false);
    setDisabled(false);
    setMrDisabled(false);
    setAppointmentEventId();
    setPrNoAutoComplete("")
  };
  const editEventFunctionListView = (value) => {
    appointmentSelectDate = moment(value.start1).format("ddd MMMM DD, YYYY");
    let data = value;
    toggle();
    setModal(true);
    let timeStringArray = [...timeIntervals];
    let timeString = "";
    let timeStringOne = "";
    let dataSetStart = timeIntervals
      .filter((item) => moment(data.start1).format("h:mm A") === item)
      .map((item) => item);
    let dataSetEnd = timeIntervals
      .filter((item) => moment(data.end1).format("h:mm A") === item)
      .map((item) => item);
    if (dataSetStart.length == 0 && dataSetEnd.length == 0) {
      timeString = moment(data.start1).format("h:mm A");
      timeStringOne = moment(data.end1).format("h:mm A");
      timeStringArray.push(timeString, timeStringOne);
      setTimeIntervals(timeStringArray);
    }
    selectDate = moment(data.start1).format("DD/MM/YYYY");
    doctorName = data.doctorId;
    hospitalName = data.hospitalId;
    appointmentMobileCode = data.areaCode;
    appointmentMobileNo = data.mobileno;
    appointmentMRNumber = data.mrNo;
    setmrValue(data.mrNo);
    appointmentPRNumber = data.prNo;
    appointmentPatientsFirstName = data.patientfirstname;
    appointmentPatientsLastName = data.patientlastname;
    appointmentPatientEmailID = data.emailId;
    appointmentPatientGender = data.gender;
    appointmentTypeValue = data.appointmentType;
    appointmentStatus = data.statusId;
    appointmentPatientStartTime = moment(data.start1).format("h:mm A");
    appointmentPatientEndTime = moment(data.end1).format("h:mm A");
    appointmentPatientReason = data.reason;
    patientAppointmentId = data.patientAppointmentId;
    memberId = data.memberId;
    doctorvisitId = data.doctorVisitId;
    setEdit(true);
    setEditDisabled(true);
    setDisabled(true);
    setMrDisabled(true);
    if (data.dob !== "" && data.dob !== null && data.dob !== "00-00-0000") {
      var dobSplit = data.dob.split("-");
      var dobSplitDate = new Date(
        dobSplit[2] + "," + dobSplit[1] + "," + dobSplit[0]
      );
      setFromDate(dobSplitDate);
    }
  };
  const handleEventClick = ({ event, el }) => {
    let data = event.extendedProps;
    if (data.isBlocked) {
      blockOpen();
    }


    let timeStringArray = [...timeIntervals];
    let timeString = "";
    let timeStringOne = "";
    let dataSetStart = timeIntervals
      .filter((item) => moment(data.start1).format("h:mm A") === item)
      .map((item) => item);
    let dataSetEnd = timeIntervals
      .filter((item) => moment(data.end1).format("h:mm A") === item)
      .map((item) => item);
    if (dataSetStart.length == 0 && dataSetEnd.length == 0) {
      timeString = moment(data.start1).format("h:mm A");
      timeStringOne = moment(data.end1).format("h:mm A");
      timeStringArray.push(timeString, timeStringOne);
      setTimeIntervals(timeStringArray);
    }

    selectDate = moment(data.start1).format("DD/MM/YYYY");
    $("#appointmentDoctorName").val(data.doctorId);
    $("#appointmentHosName").val(data.hospitalId);
    $("#appointmentMobileCode").val(data.areaCode);
    $("#appointmentMobileNo").val(data.mobileno);
    $("#appointmentMRNumber").val(data.mrNo);
    $("#appointmentPRNumber").val(data.prNo);
    $("#appointmentPatientsFirstName").val(data.patientfirstname);
    $("#appointmentPatientsLastName").val(data.patientlastname);
    $("#appointmentPatientEmailID").val(data.emailId);
    $("#appointmentPatientGender").val(data.gender);
    $("#appointmentTypeValue").val(data.appointmentType);
    $("#appointmentStatus").val(data.statusId);
    $("#starttime").val(moment(data.start1).format("h:mm A"));
    $("#appointmentPatientEndTime").val(moment(data.end1).format("h:mm A"));
    $("#appointmentPatientReason").val(data.reason);
    patientAppointmentId = data.patientAppointmentId;
    memberId = data.memberId;
    doctorvisitId = data.doctorVisitId;
    appointmentPatientsImageName = data.imageName;
    if (data.dob !== "" && data.dob !== null && data.dob !== "00-00-0000") {
      var dobSplit = data.dob.split("-");
      var dobSplitDate = new Date(
        dobSplit[2] + "," + dobSplit[1] + "," + dobSplit[0]
      );
      setFromDate(dobSplitDate);
    }
  };
  const handleDateClick = (info) => {
    setFromDate("");
    patientAppointmentId = "";
    doctorvisitId = "";
    appointmentSelectDate = moment(info.dateStr).format("ddd MMMM DD, YYYY");
    selectDate = moment(info.dateStr).format("DD/MM/YYYY");
    setAppointmentTime(moment(info.dateStr).format("hh:mm A"));
    var date =
      moment(info.dateStr).format("hh:mm") +
      "-" +
      moment(info.dateStr).add(15, "minute").format("hh:mm");
    var todayDate = moment().format("YYYY-MM-DD");
    var todayMillisecDate = new Date(startDate).setHours(0, 0, 0, 0);
    if (info.view.type !== "dayGridMonth") {
      appointmentPatientStartTime = moment(info.dateStr).format("h:mm A");
      appointmentPatientEndTime = moment(info.dateStr)
        .add(15, "minute")
        .format("h:mm A");
    } else {
      appointmentPatientStartTime = 0;
      appointmentPatientEndTime = 0;
    }
    if (new Date(info.dateStr).getTime() >= todayMillisecDate) {
      getMRData(hospitalId);
      toggle();
      $("#appointmentMobileCode").focus();
      setDisabled(false);
      setMrDisabled(false);
    } else {
      setModal(false);
    }
    $("#appointmentHosName").val(getHospitalIdOpen);
    getMRData(getHospitalIdOpen);
    setHospitalId(getHospitalIdOpen);
  };
  const EditiconShow = () => {
    setMrDisabled(false);
  };
  const mobileAutoChange = (e, name) => {
    if ($("#appointmentHosName").val() == 0) {
      alert("Please select a Hospital");
      $("#appointmentMobileNo").val("");
    }
    setNewUser(true);
    setMrDisabled(true);
    if (disabled == true) {
      setDisabled(false);
      setMrDisabled(false);

      $("#appointmentMobileCode").prop("disabled", false);
      $("#appointmentMobileNo").prop("disabled", false);
      $("#appointmentPatientsFirstName").prop("disabled", false);
      $("#appointmentPatientsLastName").prop("disabled", false);
      $("#appointmentPRNumber").prop("disabled", false);
      if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
        $("#appointmentMRNumber").prop("disabled", false);
      }

      $("#appointmentMobileCode").val("");
      $("#appointmentPatientsFirstName").val("");
      $("#appointmentPatientsLastName").val("");
      setPrNoAutoComplete("")
      setFromDate("");
      $("#appointmentPatientEmailID").val("");
      $("#appointmentPatientGender").val(0);
      if ((name == "mobile" && newUser == true) || name == "mobile") {
        $("#appointmentPatientsFirstName").val("");
        $("#appointmentPatientsLastName").val("");
      } else {
        $("#appointmentMobileNo").val("");
      }
    }
    if (name == "mobile") {
      let mrNumber = mrValue;
      $("#appointmentMRNumber").val(mrNumber);

      if ($("#appointmentMobileNo").val() == "") {
        setmrValue(mrOld);
        setNewUser(false);
      } else if ($("#appointmentMobileNo").val().length >= 2) {
        setPatientNameFlag(false);
        setPatientLastNameFlag(false);
        setPatientMobileFlag(true);
        setPatientMrFlag(false);
        autoCompleteData($("#appointmentMobileNo").val());
      } else {
        setPatientMobileFlag(false);
        setPatientAutoList([]);
      }
    } else if (name == "name") {
      if ($("#appointmentPatientsFirstName").val() == "") {
        setmrValue(mrOld);
        setNewUser(false);
      } else if ($("#appointmentPatientsFirstName").val().length >= 2) {
        setPatientMobileFlag(false);
        setPatientNameFlag(true);
        setPatientLastNameFlag(false);
        setPatientMrFlag(false);
        autoCompleteData($("#appointmentPatientsFirstName").val());
      } else {
        setPatientNameFlag(false);
        setPatientAutoList([]);
      }
    } else if (name == "lastname") {
      if ($("#appointmentPatientsLastName").val() == "") {
        setmrValue(mrOld);
        setNewUser(false);
      } else if ($("#appointmentPatientsLastName").val().length >= 2) {
        setPatientMobileFlag(false);
        setPatientNameFlag(false);
        setPatientLastNameFlag(true);
        setPatientMrFlag(false);
        autoCompleteData($("#appointmentPatientsLastName").val());
      } else {
        setPatientLastNameFlag(false);
        setPatientAutoList([]);
      }
    } else {
      if ($("#appointmentMRNumber").val() == "") {
        setmrValue(mrOld);
        setNewUser(false);
      } else if ($("#appointmentMRNumber").val().length >= 2) {
        setPatientMobileFlag(false);
        setPatientNameFlag(false);
        setPatientLastNameFlag(false);
        setPatientMrFlag(true);
        autoCompleteData($("#appointmentMRNumber").val());
      } else {
        setPatientMrFlag(false);
        setPatientAutoList([]);
      }
    }
  };
  const autoCompleteData = (keyword) => {
    const postData = {
      hospitalId: hospitalId,
      searchKeyword: keyword,
    };
    callingAPI(
      "patientAppointment/autoCompleteByPatientNameAndMobileNo",
      postData
    )
      .then((res) => {
        setPatientAutoList(res.data.result);
      })
      .catch();
  };
  const patientSelect = (data, name) => {
    setDisabled(true);
    setMrDisabled(true);
    if (name == "name") {
      $("#appointmentMobileNo").prop("disabled", true);
      $("#appointmentPatientsLastName").prop("disabled", true);
    } else if (name == "lastname") {
      $("#appointmentMobileNo").prop("disabled", true);
      $("#appointmentPatientsFirstName").prop("disabled", true);
    } else if (name == "mobile") {
      $("#appointmentPatientsFirstName").prop("disabled", true);
      $("#appointmentPatientsLastName").prop("disabled", true);
    }
    setNewUser(false);
    memberId = data.memberId;
    appointmentPatientsImageName = data.memberImageName;
    $("#appointmentMobileNo").val(data.secUserMobileNumber);
    $("#appointmentMRNumber").val(data.mrNo);
    $("#appointmentPRNumber").val(data.prNo);
    setPrNoAutoComplete(data.prNo)
    $("#appointmentPatientsFirstName").val(data.memberFirstName);
    $("#appointmentPatientsLastName").val(data.memberLastName);
    $("#appointmentMobileCode").val(data.secUserAreaCode);
    $("#appointmentPatientEmailID").val(data.secUserEmailId);
    $("#appointmentPatientGender").val(data.memberGender);
    if (data.dob !== "" && data.dob !== null && data.dob !== "00-00-0000" && data.dob !== "01-01-1900") {
      $("#dobId").val(data.dob);
      var dobSplit = data.dob.split("-");
      var dobSplitDate = new Date(
        dobSplit[2] + "," + dobSplit[1] + "," + dobSplit[0]
      );
      setFromDate(dobSplitDate);
    }
    setPatientNameFlag(false);
    setPatientMobileFlag(false);
    setPatientLastNameFlag(false);
    setPatientMrFlag(false);
    setPatientAutoList([]);
  };

  const handleEventDrop = (info) => {
    const current = new Date();
    var dateCurrent = Date.parse(moment(current).format("YYYY-MM-DD"));

    let date = info.event.start;
    let dateEnd = info.event.end;
    let data = info.event.extendedProps;
    let sTime = "";
    let eTime = "";

    if (info.view.type == "dayGridMonth") {
      sTime = moment(data.start1).format("h:mm A");
      eTime = moment(data.end1).format("h:mm A");
    } else {
      sTime = moment(date).format("h:mm A");
      eTime = moment(dateEnd).format("h:mm A");
    }
    selectDate = moment(date).format("DD/MM/YYYY");

    var selectChangeDate = Date.parse(moment(date).format("YYYY-MM-DD"));
    let startTime = moment(
      selectDate + " " + sTime,
      "DD/MM/YYYY HH:mm a"
    ).valueOf();
    let endTime = moment(
      selectDate + " " + eTime,
      "DD/MM/YYYY HH:mm a"
    ).valueOf();
    let appointmentDate =
      moment(selectDate, "DD/MM/YYYY").format("YYYY-MM-DD") + " " + sTime;
    let reScheduledAppointment = [
      {
        reScheduleDateTime: appointmentDate,
        reScheduleStartTime: startTime,
        reScheduleEndTime: endTime,
      },
    ];
    doctorName = data.doctorId;
    hospitalName = data.hospitalId;
    appointmentMobileNo = data.mobileno;
    appointmentMRNumber = data.mrNo;
    appointmentPatientsImageName = data.imageName;
    setmrValue(data.mrNo);
    appointmentPRNumber = data.prNo;
    appointmentPatientsFirstName = data.patientfirstname;
    appointmentPatientsLastName = data.patientlastname;
    appointmentPatientEmailID = data.emailId;
    appointmentPatientGender = data.gender;
    appointmentTypeValue = data.appointmentType;
    appointmentStatus = data.statusId;
    appointmentPatientStartTime = moment(data.start1).format("h:mm A");
    appointmentPatientEndTime = moment(data.end1).format("h:mm A");
    appointmentPatientReason = data.reason;
    patientAppointmentId = data.patientAppointmentId;
    memberId = data.memberId;
    doctorvisitId = data.doctorVisitId;
    if (data.dob !== "" && data.dob !== null && data.dob !== "00-00-0000") {
      var dobSplit = data.dob.split("-");
      var dobSplitDate = new Date(
        dobSplit[2] + "," + dobSplit[1] + "," + dobSplit[0]
      );
      setFromDate(dobSplitDate);
    }
    state.ageId = data.ageDay;
    if (dateCurrent > selectChangeDate) {
      alert("Appointment Date must be greater than Today Date");
      info.revert();
    } else if (data.isBlocked === true) {
      alert(
        "Unable to drag a block,if necessary unblock the current date/slot and block a new date/slot"
      );
      info.revert();
      window.location.reload();
    } else {
      setLoader(true);
      appointmentSave(
        reScheduledAppointment,
        startTime,
        endTime,
        appointmentDate,
        "DropAppointment"
      );
    }
  };
  const viewChange = (info) => {
    calendarview = info.view.type;
    if (calendarview === "timeGridDay") {
      dateCheck = moment(info.start).format("YYYY-MM-DD");
      setselectedDate(moment(info.start).format("MMM D, YYYY"));
      dateChecking(dateCheck);
    }
    let newData = [...calendarDataView];
    newData.map((val) => {
      val.start =
        calendarview !== "dayGridMonth"
          ? val.start1
          : moment(val.start1).format("YYYY-MM-DD");
      val.end =
        calendarview !== "dayGridMonth"
          ? val.end1
          : moment(val.end1).format("YYYY-MM-DD");
      val.id = val.patientAppointmentId;
      return val;
    });
    calendarData = newData;
    setListflag(false);
    $(".fc-view-harness.fc-view-harness-active").show();
    $(".fc-toolbar-chunk:first-child .fc-button-group").show();
    $(".fc-toolbar-chunk .fc-toolbar-title").show();
    $(".fc-list-button").removeClass("fc-button-active");
    appointmentDataFilter();
  };
  const startDateSet = (date) => {
    setStartDate(date);
  };
  const endDateSet = (date) => {
    setEndDate(date);
  };
  const listViewSearch = (name) => {
    let startDateValue = new Date(startDate).getTime();
    let endDateValue = new Date(endDate).getTime();
    if (!startDateValue && !endDateValue) {
      alert("Please select From Date & To Date");
    } else if (!startDateValue && endDateValue) {
      alert("Please select From Date ");
    } else if (startDateValue && !endDateValue) {
      alert("Please select To Date ");
    } else if (startDateValue > endDateValue) {
      alert("To Date must be greater than From Date");
    } else {
      listView(name);
    }
  };
  const listView = (name) => {
    calendarview = name;
    let startDateValue = new Date(startDate).getTime();
    let endDateValue = new Date(endDate).getTime();
    setLoader(true);
    let newData = [...calendarDataView];
    newData
      .sort((a, b) => a.start1 - b.start1)
      .map((val) => {
        val.start = val.start1;
        val.end = val.end1;
        val.id = val.patientAppointmentId;
        return val;
      });
    let filterArray = newData
      .filter(
        (data) =>
          startDateValue <= data.start1 &&
          endDateValue >= data.start1 &&
          data.isBlocked == false
      )
      .map((item) => item);
    setCalendarListView(filterArray);
    setLoader(false);
    $(".fc-view-harness.fc-view-harness-active").hide();
    $(".fc-toolbar-chunk:first-child .fc-button-group").hide();
    $(".fc-toolbar-chunk .fc-toolbar-title").hide();
    $(".fc-button.fc-button-primary").removeClass("fc-button-active");
    $(".fc-list-button").addClass("fc-button-active");
    setListflag(true);
  };
  const eventDelete = (event, name) => {
    var answer = window.confirm("Do you want to delete the appointment?");
    if (answer) {
      setLoader(true);
      const postData = {
        scheduledTimeSlotId:
          name !== "Edit"
            ? event.extendedProps.scheduledTimeSlotId
            : appointmentEventId,
      };
      callingAPI("removePatientAppointment/deleteAppointmentDetails", postData)
        .then((res) => {
          popCancel();
          setModal(false);
          alert("Appointment Deleted Successfully");
          window.location.reload();
          if (
            hospitalId !== null &&
            hospitalId !== "null" &&
            hospitalId !== "" &&
            hospitalId !== "undefined" &&
            hospitalId !== undefined
          ) {
            appointmentDataSet();
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };
  const payNow = (event, name) => {
    let patientId =
      name === "List" ? event.memberId : event.extendedProps.memberId;
    let patientHospitalId =
      name === "List" ? event.hospitalId : event.extendedProps.hospitalId;
    let patientDoctorId =
      name === "List" ? event.doctorId : event.extendedProps.doctorId;
    localStorage.setItem("hospitalId", patientHospitalId);
    window.sessionStorage.setItem("memberId", patientId);
    window.sessionStorage.setItem("patientDoctorId", patientDoctorId);
    window.location.href = "/patients/patientInvoiceAdd";
  };
  const consultClick = (event) => {
    window.sessionStorage.setItem("memberId", event.memberId);
    window.sessionStorage.setItem("patientDoctorId", event.doctorId);
    window.sessionStorage.setItem("patientDoctorName", event.doctorName);
    localStorage.setItem("hospitalId", event.hospitalId);
    window.sessionStorage.setItem("medicineBranchId", event.medicineBranchId);
    window.location.href = "/patients/patientconsult";
  };
  const mobNameClear = () => {
    setPatientAutoList([]);
  };
  const renderEventContent1 = ({ event, el }) => {
    if (event.extendedProps.isBlocked === true) {
      $(el).addClass("blockCalanderView");
    }
    let statusName = event.extendedProps.statusName;
    const content = (
      <div>
        {event.extendedProps.isBlocked == false ? (
          <div>
            <img
              alt=""
              style={{ marginRight: "7px" }}
              width={18}
              src={
                statusName == "ARRIVED"
                  ? ArrivedStatus
                  : statusName == "OPEN"
                    ? OpenStatus
                    : statusName == "CONFIRM"
                      ? ConfirmStatus
                      : statusName == "CANCELLED"
                        ? CancelledStatus
                        : statusName == "ADMITTED"
                          ? AdmittedStatus
                          : statusName == "COMPLETED"
                            ? CompletedStatus
                            : statusName == "DISCHARGE"
                              ? DischargeStatus
                              : statusName == "ENGAGED"
                                ? EngagedStatus
                                : CallCompletedStatus
              }
            />
            {moment(event.extendedProps.start1).format("hh:mm A")} -{" "}
            {moment(event.extendedProps.end1).format("hh:mm A")}
            &nbsp;&nbsp;&nbsp;
            <br />
            &nbsp;{event.extendedProps.doctorName}
            <span
              className="eventDelete"
              onClick={() => eventDelete(event, "")}
            >
              x
            </span>
          </div>
        ) : (
          <div>
            {moment(event.extendedProps.start1).format("hh:mm A")} -{" "}
            {moment(event.extendedProps.end1).format("hh:mm A")}
          </div>
        )}
      </div>
    );
    ReactDOM.render(content, el);
    return el;
  };
  const mouseOutHoverFunction = ({ event, el }) => {
    $(el).parent().removeClass("eventZindex");
  };
  const mouseEnterHoverFunction = ({ event, el }) => {
    $(el).parent().addClass("eventZindex");
    if (event.extendedProps.isBlocked === true) {
      $(el).addClass("blockCalanderView");
    }
    appointmentSelectDate = moment(event.extendedProps.start1).format(
      "ddd MMMM DD, YYYY"
    );
    let statusName = event.extendedProps.statusName;
    const content = (
      <div>
        {event.extendedProps.isBlocked == false ? (
          <div data-tip data-for="registerTip" style={{ height: "100%" }}>
            <div className="eventDetails">
              <img
                style={{ marginRight: "7px" }}
                width={18}
                alt=""
                src={
                  statusName == "ARRIVED"
                    ? ArrivedStatus
                    : statusName == "OPEN"
                      ? OpenStatus
                      : statusName == "CONFIRM"
                        ? ConfirmStatus
                        : statusName == "CANCELLED"
                          ? CancelledStatus
                          : statusName == "ADMITTED"
                            ? AdmittedStatus
                            : statusName == "COMPLETED"
                              ? CompletedStatus
                              : statusName == "DISCHARGE"
                                ? DischargeStatus
                                : statusName == "ENGAGED"
                                  ? EngagedStatus
                                  : CallCompletedStatus
                }
              />
              {moment(event.extendedProps.start1).format("hh:mm A")} -{" "}
              {moment(event.extendedProps.end1).format("hh:mm A")}
              &nbsp;&nbsp;&nbsp;
              <br />
              &nbsp;{event.extendedProps.doctorName}
              <span
                className="eventDelete"
                onClick={() => eventDelete(event, "")}
              >
                x
              </span>
            </div>
            <div className="fixed_top">
              <ReactTooltip
                id="registerTip"
                place="bottom"
                effect="solid"
                type="light"
              >
                <div>
                  <div className="pu_user">
                    {event.extendedProps.imageName != "" &&
                      event.extendedProps.imageName !== null &&
                      event.extendedProps.imageName !== "null" ? (
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_S3_BUCKET_URL +
                          process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
                          process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH +
                          "/" +
                          event.extendedProps.imageName
                        }
                      />
                    ) : (
                      <img alt="" src={User} />
                    )}
                  </div>
                  <div className="pu_user_info">
                    <h1>{event.extendedProps.memberName}</h1>
                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<p>MR No: {event.extendedProps.mrNo}</p>) :
                      (!event.extendedProps.prNo == "" && (
                        <p>PR No: {event.extendedProps.prNo}</p>
                      ))}

                    <p>
                      {event.extendedProps.gender}

                      {parseFloat(event.extendedProps.ageyear) !== 0 &&
                        event.extendedProps.ageyear !== "" && (
                          <span>
                            ,&nbsp;{event.extendedProps.ageyear} Year(s)
                          </span>
                        )}
                    </p>
                  </div>
                  <div className="pu_generalinfo">
                    <p>
                      <img alt="" src={Call} />
                      {event.extendedProps.mobileno}
                    </p>
                    {event.extendedProps.emailId !== "" && (
                      <p>
                        <img alt="" src={Mail} />
                        {event.extendedProps.emailId}
                      </p>
                    )}
                    <p>
                      <img alt="" src={People} />
                      {event.extendedProps.appointmentType}
                    </p>
                    <p>
                      <img
                        width={20}
                        alt=""
                        src={
                          statusName == "ARRIVED"
                            ? ArrivedStatus
                            : statusName == "OPEN"
                              ? OpenStatus
                              : statusName == "CONFIRM"
                                ? ConfirmStatus
                                : statusName == "CANCELLED"
                                  ? CancelledStatus
                                  : statusName == "ADMITTED"
                                    ? AdmittedStatus
                                    : statusName == "COMPLETED"
                                      ? CompletedStatus
                                      : statusName == "DISCHARGE"
                                        ? DischargeStatus
                                        : statusName == "ENGAGED"
                                          ? EngagedStatus
                                          : CallCompletedStatus
                        }
                      />
                      {event.extendedProps.statusName}
                    </p>
                    <p className="drNameInfo">
                      {moment(event.extendedProps.start1).format(
                        "DD-MMM-YYYY hh:mm A"
                      )}{" "}
                      - {moment(event.extendedProps.end1).format("hh:mm A")}{" "}
                      <br /> {event.extendedProps.doctorName}
                    </p>
                    {event.extendedProps.dateOfVisit != "" && (
                      <p>
                        Last Visited Date : {event.extendedProps.dateOfVisit}
                      </p>
                    )}
                    {event.extendedProps.reason !== "undefined" &&
                      event.extendedProps.reason !== "" &&
                      event.extendedProps.reason !== null ? (
                      <p>Reason : {event.extendedProps.reason}</p>
                    ) : null}
                    <div className="plmdlbtn plEdit">
                      {(event.extendedProps.appointmentType === "Video" &&
                        event.extendedProps.statusName === "CONFIRM") ||
                        (event.extendedProps.appointmentType === "Video Chat" &&
                          event.extendedProps.statusName === "CONFIRM") ||
                        (event.extendedProps.appointmentType === "Audio" &&
                          event.extendedProps.statusName === "CONFIRM") ? (
                        <button
                          style={{ backgroundColor: "#2ACB95" }}
                          onClick={() => videoChatEventFunction(event)}
                        >
                          Chat Now
                        </button>
                      ) : null}

                      <button onClick={() => editEventFunction(event, el)}>
                        Edit
                      </button>
                      {event.extendedProps.appointmentType === "Walk In" ||
                        event.extendedProps.appointmentType === "In Person" ? (
                        <span
                          className="plpaynow "
                          onClick={() => payNow(event, "Grid")}
                        >
                          Pay Now
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </ReactTooltip>
            </div>
          </div>
        ) : (
          <div data-tip data-for="registerTip" style={{ height: "100%" }}>
            <div className="eventDetails">
              {moment(event.extendedProps.start1).format("hh:mm A")} -{" "}
              {moment(event.extendedProps.end1).format("hh:mm A")}
              &nbsp;&nbsp;&nbsp;
              <br />
              &nbsp;{event.extendedProps.doctorName}
            </div>
            <ReactTooltip
              id="registerTip"
              place="bottom"
              effect="solid"
              type="light"
              className="blockedTool"
            >
              <div>This schedule has been blocked.</div>
            </ReactTooltip>
          </div>
        )}
      </div>
    );
    ReactDOM.render(content, el);
    return el;
  };
  const MyContainerFromDate = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };

  const MyContainerToDate = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };

  const FromDateClear = () => {
    setStartDate("");
  };

  const ToDateClear = () => {
    setEndDate("");
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  return (
    <div className="coverWraper">
      <Suspense fallback={<div></div>}>
        <Header />
        <div className="contentWraper contentWraperScroll">
          <div className="calenderWrap">
            <div className="row calenderRow">
              <div className="col-md-2 calenderLeftSec">
                <div className="calenderBlockSec">
                  <Blockcalenderpopup
                    blockChange={blockCalendarChange}
                    ref={blockCalendarRef}
                  />
                  <div style={{ display: "none" }} className="calenderBlockList">
                    <a
                      href="/clinics/exportCalendar"
                      style={{ color: "#4e4e4e", textDecoration: "none" }}
                    >
                      <img alt="" src={exportCalendar} className="CalendarImg" />
                      Export Calendar
                    </a>
                  </div>
                </div>
                <div className="calenderLeftListDiv">
                  {role == "ROLE_HOSPITALADMIN" ||
                    role == "ROLE_NURSE" ||
                    role == "ROLE_RECEPTION" ? (
                    <div className="calenderTitle">Doctor(s)</div>
                  ) : (
                    <div className="calenderTitle">Hospital(s)/Clinic(s)</div>
                  )}
                  <ul className="calenderList">
                    {role == "ROLE_HOSPITALADMIN" ||
                      role == "ROLE_NURSE" ||
                      role == "ROLE_RECEPTION" ? (
                      <div>
                        <div className="doctorSearchFieldSec">
                          <input
                            type="text"
                            className="inputField"
                            placeholder="Doctor Name"
                            id="doctorName"
                            onChange={() => searchDoctor()}
                          />
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="doctorSearchIcon"
                          />
                        </div>
                        <li
                          id="AllDoctor"
                          onClick={() => {
                            hospitalId !== null &&
                              hospitalId !== "null" &&
                              hospitalId !== "" &&
                              hospitalId !== "undefined" &&
                              hospitalId !== undefined &&
                              userId !== null &&
                              userId !== "null" &&
                              userId !== "" &&
                              userId !== "undefined" &&
                              userId !== undefined &&
                              getDoctorList();
                          }}
                        >
                          <FontAwesomeIcon icon={faUserMd} />
                          <span
                            className="doctorListLeftName elipsis"
                            title="All Doctor"
                          >
                            All Doctor
                          </span>
                        </li>
                      </div>
                    ) : (
                      <></>
                    )}
                    {docHosList.length > 0 ? (
                      <div>
                        {docHosList.map((item, index) => {
                          let name =
                            role == "ROLE_HOSPITALADMIN" ||
                              role == "ROLE_NURSE" ||
                              role == "ROLE_RECEPTION"
                              ? item.doctorDisplayName +
                              "(" +
                              item.speciality +
                              ")"
                              : role == "ROLE_DOCTOR"
                                ? item.hospitalName
                                : "";
                          let city =
                            role == "ROLE_DOCTOR" ? item.hospitalCity : "";
                          return (
                            <li
                              className={item.className + " " + "leftMenuList"}
                              onClick={() =>
                                doctorSelect(item, item.colorSet, index)
                              }
                              id={"leftMenuList" + index}
                            >
                              <FontAwesomeIcon
                                icon={faUserMd}
                                className="doctorClrFrst"
                                color={item.colorSet}
                              />
                              <span
                                className="doctorListLeftName elipsis"
                                title={name}
                              >
                                {name}, {city}
                              </span>
                              <span className="doctorListArrow" />
                            </li>
                          );
                        })}
                      </div>
                    ) : (
                      <li className="calenderNoData">No data</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-md-8 calenderFull">
                <div className="calenderSectionWrap">
                  {Listflag == true && (
                    <div className="row searchDataSection">
                      <div className="col-md-3">
                        <div class="appointmentFormList ">
                          <Datepicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            closeCalendar="true"
                            className="Datepicker pa2 inputField input-design "
                            placeholderText="From Date"
                            calendarClassName="rasta-stripes"
                            dateFormat="dd-MM-yyyy"
                            //defaultValue={startDate}
                            selected={startDate}
                            onChange={(date) => startDateSet(date)}
                            autoComplete="off"
                            onChangeRaw={handleDateChangeRaw}
                            calendarContainer={MyContainerFromDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div class="appointmentFormList ">
                          <Datepicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            closeCalendar="true"
                            className="Datepicker pa2 inputField input-design "
                            placeholderText="To Date"
                            calendarClassName="rasta-stripes"
                            dateFormat="dd-MM-yyyy"
                            //defaultValue={endDate}
                            selected={endDate}
                            onChange={(date) => endDateSet(date)}
                            autoComplete="off"
                            onChangeRaw={handleDateChangeRaw}
                            calendarContainer={MyContainerToDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <span
                          class="dashButtonBorder"
                          onClick={() => listViewSearch("List")}
                        >
                          Search
                        </span>
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    title="List"
                    className="fc-list-button fc-button fc-button-primary"
                    onClick={() => listView("List")}
                  >
                    List
                  </button>

                  <FullCalendar
                    key={key}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView={dayWeek}
                    // defaultView="resourceTimeGridDay"
                    selectable="true"
                    editable={true}
                    eventLimit={true}
                    slotLabelInterval="01:00:00"
                    slotMinutes={15}
                    slotDuration="00:15:00"
                    ref={calendarComponentRef}
                    // events={calendarData}
                    events={docHosList.length > 0 && calendarData}
                    eventClick={handleEventClick}
                    eventMouseEnter={mouseEnterHoverFunction}
                    eventMouseLeave={mouseOutHoverFunction}
                    eventColor="#007bff"
                    eventBackgroundColor="black"
                    dateClick={handleDateClick}
                    allDaySlot={false}
                    eventDidMount={renderEventContent1}
                    eventDrop={handleEventDrop}
                    eventResize={handleEventDrop}
                    datesSet={viewChange}
                    resize={true}
                    customButtons={{
                      new: {
                        text: "new",
                        click: () => console.log("new event"),
                      },
                    }}
                    headerToolbar={{
                      right: "timeGridDay,timeGridWeek,dayGridMonth",
                      left: "prev,next,today",
                      center: "title",
                    }}
                    views={{
                      timeGridDay: {
                        titleFormat: {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          weekday: "long",
                        },
                        dayHeaderFormat: {
                          weekday: "long",
                          month: "short",
                          day: "numeric",
                        },
                      },
                      timeGridWeek: {
                        dayHeaderFormat: {
                          day: "numeric",
                          month: "short",
                          weekday: "short",
                        },
                      },
                    }}
                  />
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    id="appointmentModal"
                    class="appointmentModalCalendar ui-dialog-content ui-widget-content"
                  >
                    <ModalHeader toggle={toggle}>
                      Schedule appointment on {appointmentSelectDate}
                    </ModalHeader>
                    <ModalBody>
                      <div className="pu_modal_content plCalendarModal">
                        <div class="col-md-12">
                          {(role == "ROLE_HOSPITALADMIN" ||
                            role == "ROLE_NURSE" ||
                            role == "ROLE_RECEPTION") && (
                              <>
                                {docHosList.length != 1 && (
                                  <div class="form-group">
                                    <div class="col-sm-12">
                                      <label class="control-label">
                                        Doctor Name{" "}
                                        <font color="red">
                                          <b>*</b>
                                        </font>
                                      </label>
                                      <div class="selectContainer">
                                        <select
                                          defaultValue={doctorName}
                                          id="appointmentDoctorName"
                                        // disabled={editDisabled}
                                        >
                                          <option value="0">Select</option>
                                          {docHosList.map((val, index) => (
                                            <option value={val.doctorId}>
                                              {val.doctorDisplayName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          {role == "ROLE_DOCTOR" && (
                            <>
                              {docHosList.length != 1 && (
                                <div class="form-group">
                                  <div class="col-sm-12">
                                    <label class="control-label">
                                      Hospital Name{" "}
                                      <font color="red">
                                        <b>*</b>
                                      </font>
                                    </label>
                                    <div class="selectContainer">
                                      <select
                                        defaultValue={hospitalName}
                                        id="appointmentHosName"
                                        disabled={editDisabled}
                                        onChange={(e) => hosNameChange(e)}
                                      >
                                        <option value="0">Select</option>
                                        {docHosList.map((val, index) => (
                                          <option value={val.hospitalId}>
                                            {val.hospitalName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          <div class="form-group">
                            <div
                              class="col-sm-12"
                              onMouseLeave={() => mobNameClear()}
                            >
                              <label class="control-label">
                                Mobile Number{" "}
                                <font color="red">
                                  <b>*</b>
                                </font>
                              </label>
                              <span class="mobileCountrySelect">
                                <label class="control-label">&nbsp;</label>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  class="form-control"
                                  id="appointmentMobileCode"
                                  defaultValue={appointmentMobileCode}
                                  maxlength="10"
                                  disabled={editDisabled}
                                  //onChange={(e) => mobileAutoChange(e, "mobile")}
                                  onKeyPress={(event) => {
                                    if (!/[0-9+-]/.test(event.key))
                                      event.preventDefault();
                                  }}
                                />
                              </span>
                              <span class="mobileNumberField">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  class="form-control"
                                  id="appointmentMobileNo"
                                  defaultValue={appointmentMobileNo}
                                  maxlength="10"
                                  onChange={(e) => mobileAutoChange(e, "mobile")}
                                  disabled={editDisabled}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key))
                                      event.preventDefault();
                                  }}
                                />
                              </span>
                              {patientAutoList.length > 0 &&
                                patientMobileFlag == true ? (
                                <div className="patientInfoList">
                                  {patientAutoList.map((item) => (
                                    <div
                                      onClick={() =>
                                        patientSelect(item, "mobile")
                                      }
                                    >
                                      {item.memberDetailsWithMrNo}
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (
                            <div class="newAppoinmentRightList">
                              <label className="formLabel">
                                PR Number
                              </label>
                              <div className="appointmentFormList" >
                                <input
                                  // style={{ display: 'none' }}
                                  type="text"
                                  className="formInput"
                                  value={prNoAutoComplete}
                                  id="prNoAutoComplete"
                                  maxlength="50"
                                  name="prNoAutoComplete"
                                  readOnly
                                  // onBlur={(e) =>
                                  // 	check(e.target.value)}
                                  placeholder="PR Number"
                                />
                              </div>
                            </div>)}
                          {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                            (
                              <div class="form-group">
                                <div
                                  class="col-sm-12 mrNumberSection"
                                  style={{ position: "relative" }}
                                >
                                  <label class="control-label">
                                    MR Number
                                    <font color="red">
                                      <b>*</b>
                                    </font>
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={appointmentMRNumber}
                                    id="appointmentMRNumber"
                                    class="form-control"
                                    maxlength="20"
                                    //disabled={mrDisabled}
                                    onChange={(e) => mobileAutoChange(e, "mrNumber")}
                                  />
                                  {newUser == true && (
                                    <span
                                      class="mrNumberEdit"
                                      id="mrNoId"
                                      style={{ display: "block" }}
                                    >
                                      <img
                                        title="Click here for edit Mr Number"
                                        id="editiconId"
                                        alt=""
                                        style={{ display: "block" }}
                                        src={Editicon}
                                        onClick={() => EditiconShow()}
                                      />
                                    </span>
                                  )}
                                  {patientAutoList.length > 0 &&
                                    patientMrFlag == true ? (
                                    <div className="patientInfoList">
                                      {patientAutoList.map((item) => (
                                        <div
                                          onClick={() =>
                                            patientSelect(item, "mrNumber")
                                          }
                                        >
                                          {item.memberDetailsWithMrNo}
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                            :
                            <input
                              type="text"
                              defaultValue={appointmentMRNumber}
                              id="appointmentMRNumber"
                              class="form-control"
                              maxlength="20"
                              hidden="true"
                            />
                          }

                          <div class="form-group">
                            <div
                              class="col-sm-12"
                              style={{ position: "relative" }}
                              onMouseLeave={() => mobNameClear()}
                            >
                              <label control-label="">
                                Patient First Name{" "}
                                <font color="red">
                                  <b>*</b>
                                </font>
                                {newUser == true && (
                                  <span
                                    class="blue-text"
                                    id="newUsertextId"
                                    style={{ color: "green" }}
                                  >
                                    <b>New User </b>
                                  </span>
                                )}
                              </label>

                              <input
                                //onBlur={(e) => setPatientAutoList([])}
                                autoComplete="off"
                                type="text"
                                class="form-control"
                                defaultValue={appointmentPatientsFirstName}
                                id="appointmentPatientsFirstName"
                                style={{ textTransform: "uppercase" }}
                                onChange={(e) => mobileAutoChange(e, "name")}
                                disabled={editDisabled}
                              />
                              {patientAutoList.length > 0 &&
                                patientNameFlag == true ? (
                                <div className="patientInfoList patientInfoListName">
                                  {patientAutoList.map((item) => (
                                    <div
                                      onClick={() => patientSelect(item, "name")}
                                    >
                                      {item.memberDetailsWithMrNo}
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="col-sm-12">
                              <label control-label="">
                                Patient Last Name{" "}
                                <font color="red">
                                  <b />
                                </font>
                              </label>

                              <input
                                autoComplete="off"
                                type="text"
                                class="form-control"
                                defaultValue={appointmentPatientsLastName}
                                id="appointmentPatientsLastName"
                                style={{ textTransform: "uppercase" }}
                                onChange={(e) => mobileAutoChange(e, "lastname")}
                                disabled={editDisabled}
                              // disabled={disabled}
                              />
                              {patientAutoList.length > 0 &&
                                patientLastNameFlag == true ? (
                                <div className="patientInfoList patientInfoListLastName">
                                  {patientAutoList.map((item) => (
                                    <div
                                      onClick={() =>
                                        patientSelect(item, "lastname")
                                      }
                                    >
                                      {item.memberDetailsWithMrNo}
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="col-sm-12">
                              <label class="control-label">
                                Email Id{" "}
                                <font color="red">
                                  <b />
                                </font>
                              </label>

                              <input
                                autoComplete="off"
                                type="text"
                                class="form-control"
                                id="appointmentPatientEmailID"
                                defaultValue={appointmentPatientEmailID}
                                maxlength="50"
                                disabled={disabled}
                              />
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="col-sm-12">
                              <label class="control-label">
                                Gender{" "}
                                <font color="red">
                                  <b>*</b>
                                </font>
                              </label>
                              <div class="selectContainer">
                                <select
                                  defaultValue={appointmentPatientGender}
                                  id="appointmentPatientGender"
                                  disabled={disabled}
                                >
                                  <option value="0">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group" style={{ paddingRight: "0px" }}>
                            <div
                              class="form-group startTime"
                              style={{ paddingLeft: "3px" }}
                            >
                              <div class="col-sm-12">
                                <label class="control-label">
                                  Appointment Type{" "}
                                  <font color="red">
                                    <b>*</b>
                                  </font>
                                </label>
                                <div class="selectContainer">
                                  <select
                                    defaultValue={appointmentTypeValue}
                                    id="appointmentTypeValue"
                                    onChange={(e) => selectAppointmentType(e)}
                                  >
                                    <option value="0">Select</option>
                                    {appointmentType.map((item) => (
                                      <option value={item.name}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group endTime"
                              style={{ paddingRight: "0px" }}
                            >
                              <div class="col-sm-12">
                                <label class="control-label">
                                  Status{" "}
                                  <font color="red">
                                    <b>*</b>
                                  </font>
                                </label>
                                <div class="selectContainer">
                                  {appointmentTypeName === "Video Chat" ? (
                                    <select
                                      defaultValue={appointmentStatus}
                                      id="appointmentStatus"
                                    >
                                      {status
                                        .filter(
                                          (item) =>
                                            item.statusName === "OPEN" ||
                                            item.statusName === "CONFIRM"
                                        )
                                        .map((data, k) => (
                                          <option value={data.statusId}>
                                            {data.statusName}
                                          </option>
                                        ))}
                                    </select>
                                  ) : (
                                    <>
                                      {Date.parse(
                                        moment(appointmentSelectDate).format(
                                          "YYYY-MM-DD"
                                        )
                                      ) >
                                        Date.parse(
                                          moment(new Date()).format("YYYY-MM-DD")
                                        ) ? (
                                        <select
                                          defaultValue={2}
                                          id="appointmentStatus"
                                        >
                                          {status
                                            .filter(
                                              (item) =>
                                                item.statusName !== "COMPLETED"
                                            )
                                            .map((data, k) => (
                                              <option value={data.statusId}>
                                                {data.statusName}
                                              </option>
                                            ))}
                                        </select>
                                      ) : (
                                        <select
                                          defaultValue={appointmentStatus}
                                          id="appointmentStatus"
                                        >
                                          {status.slice(0, 10).map((data, i) => (
                                            <option value={data.statusId}>
                                              {data.statusName}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="form-group startTime">
                              <div class="col-sm-12">
                                <label class="control-label">
                                  Start Time{" "}
                                  <font color="red">
                                    <b>*</b>
                                  </font>
                                </label>
                                <div class="selectContainer">
                                  <select
                                    id="starttime"
                                    defaultValue={appointmentPatientStartTime}
                                  >
                                    <option value="0" selected="selected">
                                      Select
                                    </option>
                                    {timeIntervals.map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group endTime">
                              <div class="col-sm-12">
                                <label class="control-label">
                                  End Time{" "}
                                  <font color="red">
                                    <b>*</b>
                                  </font>
                                </label>

                                <div class="selectContainer">
                                  <select
                                    defaultValue={appointmentPatientEndTime}
                                    id="appointmentPatientEndTime"
                                  >
                                    <option value="0" selected="selected">
                                      Select
                                    </option>
                                    {timeIntervals.map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="col-sm-12">
                              <label class="control-label">
                                Reason for visit{" "}
                                <font color="red">
                                  <b>*</b>
                                </font>
                              </label>
                              <div class="selectContainer">
                                <textarea
                                  autoComplete="off"
                                  defaultValue={appointmentPatientReason}
                                  id="appointmentPatientReason"
                                  class="form-control"
                                  maxlength="255"
                                  style={{ resize: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="col-sm-12">
                              {/* {newUser == true &&(  */}
                              <div
                                class="calendarDateOfBirth"
                                style={{ width: "100%" }}
                              >
                                <label
                                  className="formLabel"
                                  style={{ margin: "0 0 2px" }}
                                >
                                  <span
                                    class="addPatientTab tabActive "
                                    id="dobGreen"
                                    onClick={(e) => spanColour(e)}
                                    style={{
                                      fontFamily: "OpenSans-Regular",
                                      fontSize: "11px",
                                    }}
                                  >
                                    DOB
                                  </span>{" "}
                                  /{" "}
                                  <span
                                    onClick={(e) => spanColour(e)}
                                    class="addPatientTab "
                                    id="ageGreen"
                                    autoComplete="off"
                                  >
                                    Age*
                                  </span>{" "}
                                </label>

                                <div
                                  className="appointmentFormList"
                                  autoComplete="off"
                                  id="dobDashId"
                                >
                                  <Datepicker
                                    id="dobId"
                                    className="Datepicker pa2 inputField formInput"
                                    placeholderText="Date of Birth"
                                    calendarClassName="rasta-stripes"
                                    selected={dob}
                                    value={dob}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    autoComplete="off"
                                    dropdownMode="select"
                                    onChange={(date) => ageChange(date)}
                                  />
                                </div>

                                <div
                                  className="appointmentFormList"
                                  id="ageDashId"
                                >
                                  <div className="yearOfBirthInfo">
                                    <span
                                      className="practiceContentText"
                                      style={{ width: "50%" }}
                                    >
                                      <select
                                        onChange={ageMonth}
                                        value={getAgeMonth}
                                        name="agemonth"
                                        id="agemonth"
                                        className="inputField formInput"
                                        style={{
                                          height: "20px",
                                          marginTop: "0px",
                                          position: "relative",
                                          top: "-3px",
                                          display: "none",
                                        }}
                                      >
                                        <option value="Year">Year</option>
                                        <option value="Month">Month</option>
                                        <option value="Day">Day</option>
                                      </select>
                                    </span>
                                  </div>
                                  <input
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                    value={state.ageId}
                                    type="text"
                                    className="formInput form-control"
                                    id="ageId"
                                    maxlength="3"
                                    name="ageId"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    placeholder={getAgeMonth}
                                  />
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        disabled={buttondisable}
                        className="pu_save"
                        onClick={() => saveAppointmentEvent()}
                      >
                        Save
                      </Button>{" "}
                      {editFlag == true && (
                        <Button
                          className="pu_cancel"
                          onClick={() => eventDelete("", "Edit")}
                        >
                          Delete
                        </Button>
                      )}{" "}
                      <Button className="pu_cancel" onClick={() => popCancel()}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
                {Listflag == true && (
                  <div id="calendarList">
                    {calendarListView.length > 0 ? (
                      <div>
                        {calendarListView.map((item, index) => (
                          <div class="calendarList">
                            {index != 0 ? (
                              <div>
                                {moment(calendarListView[index - 1].start).format(
                                  "MMMM DD,YYYY"
                                ) != moment(item.start).format("MMMM DD,YYYY") ? (
                                  <div class="calendarListHeading  ">
                                    <div class="calendarDay">
                                      {moment(item.start).format("dddd")}
                                    </div>
                                    <div class="calendarDate">
                                      {moment(item.start).format("MMMM DD,YYYY")}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <div class="calendarListHeading   ">
                                <div class="calendarDay  ">
                                  {moment(item.start).format("dddd")}
                                </div>
                                <div class="calendarDate  ">
                                  {moment(item.start).format("MMMM DD,YYYY")}
                                </div>
                              </div>
                            )}
                            <div class="calendarListRow">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div class="calendarTime  ">
                                      {moment(item.start).format("h:mm a")} -{" "}
                                      {moment(item.end).format("h:mm a")}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      class="calendarContent  "
                                      style={{ padding: "8px 0px" }}
                                    >
                                      {item.patientfirstname +
                                        " " +
                                        item.patientlastname}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" &&
                                      (item.mrNo !== "" && (
                                        <div
                                          class="calendarContent appointmentTypeStatus "
                                          style={{ padding: "8px 0px" }}
                                        >
                                          MR Number : {item.mrNo}
                                        </div>
                                      ))
                                    }
                                  </div>

                                  <div
                                    className="col-md-2"
                                    style={{ display: "flex" }}
                                  >
                                    <div class="calendarListButton">
                                      <button
                                        style={{ color: "#393939" }}
                                        onClick={() =>
                                          editEventFunctionListView(item)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                        ></FontAwesomeIcon>
                                      </button>
                                      {item.appointmentType === "Walk In" ||
                                        item.appointmentType === "In Person" ? (
                                        <button
                                          className="plpaynow appointmntListViewayNow"
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          onClick={() => payNow(item, "List")}
                                        >
                                          Pay Now
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div
                                      class="calendarTag"
                                      style={{
                                        float: "left",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <span
                                        class="calendarTagSpan"
                                        style={{
                                          backgroundColor: item.backgroundColor,
                                        }}
                                      ></span>
                                    </div>
                                    <div class="calendarContent drNameDisplay  ">
                                      <FontAwesomeIcon icon={faUserMd} />
                                      &nbsp;{item.doctorName}
                                    </div>
                                  </div>
                                  {item.mobileno !== "" && (
                                    <div className="col-md-3">
                                      <div
                                        class="calendarContent  "
                                        style={{
                                          minWidth: "100%",
                                          padding: "8px 0px",
                                        }}
                                      >
                                        <span
                                          className="listStatus"
                                          style={{
                                            minWidth: "auto",
                                            float: "left",
                                          }}
                                        >
                                          Mobile Number : {item.mobileno}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {window.sessionStorage.getItem('isPrNumEnabled') === "true" &&
                                    (item.prNo !== "" && (
                                      <div className="col-md-3">
                                        <div
                                          class="calendarContent  "
                                          style={{
                                            minWidth: "100%",
                                            padding: "8px 0px",
                                          }}
                                        >
                                          <span
                                            className="listStatus"
                                            style={{
                                              minWidth: "auto",
                                              float: "left",
                                            }}
                                          >
                                            PR Number : {item.prNo}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  {item.emailId !== "" && (
                                    <div className="col-md-4">
                                      <div
                                        class="calendarContent appointmentTypeStatus "
                                        style={{ padding: "8px 0px" }}
                                      >
                                        <span
                                          className="listStatus"
                                          style={{
                                            minWidth: "auto",
                                            float: "left",
                                          }}
                                        >
                                          Email Id : {item.emailId}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div class="calendarContent  "></div>
                                  </div>
                                  {item.appointmentType !== "" && (
                                    <div className="col-md-3">
                                      <div
                                        class="calendarContent appointmentTypeStatus "
                                        style={{ padding: "0px 0px" }}
                                      >
                                        Type : {item.appointmentType}
                                      </div>
                                    </div>
                                  )}
                                  {item.statusName !== "" && (
                                    <div className="col-md-3">
                                      <div
                                        class="calendarContent  "
                                        style={{
                                          minWidth: "100%",
                                          padding: "0px 0px",
                                        }}
                                      >
                                        <span
                                          className="listStatus"
                                          style={{
                                            minWidth: "auto",
                                            float: "left",
                                          }}
                                        >
                                          <img
                                            width={18}
                                            alt=""
                                            src={
                                              item.statusName == "ARRIVED"
                                                ? ArrivedStatus
                                                : item.statusName == "OPEN"
                                                  ? OpenStatus
                                                  : item.statusName == "CONFIRM"
                                                    ? ConfirmStatus
                                                    : item.statusName == "CANCELLED"
                                                      ? CancelledStatus
                                                      : item.statusName == "ADMITTED"
                                                        ? AdmittedStatus
                                                        : item.statusName == "COMPLETED"
                                                          ? CompletedStatus
                                                          : item.statusName == "DISCHARGE"
                                                            ? DischargeStatus
                                                            : item.statusName == "ENGAGED"
                                                              ? EngagedStatus
                                                              : CallCompletedStatus
                                            }
                                          />
                                          {item.statusName}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="calenderNoData">No Appointments</div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-2 calenderRightSec">
                <div>
                  <br />
                  <button onClick={getPreviousDate}>
                    <span class="fc-icon fc-icon-chevron-left" />
                  </button>
                  <input
                    type="text"
                    value={selectedDate}
                    className="DateMonthView"
                  />
                  <button onClick={getNextDate}>
                    <span class="fc-icon fc-icon-chevron-right" />
                  </button>
                </div>
                <div>
                  {console.log(".....................XDSD", loaderSm)}
                  {loaderSm === true ? <LoaderSm /> : ''}
                  {moment().isSame(
                    moment(selectedDate).format("YYYY-MM-DD"),
                    "day"
                  ) ? (
                    <div>
                      {istoday.map((item) => (
                        <div
                          className={
                            "calenderTabTitle" +
                            " " +
                            (tabValue == item.statusId ? "active" : "")
                          }
                          onClick={() => tabClick(item.statusId)}
                        >
                          {item.statusName}
                          <span
                            className={
                              "circle" +
                              " " +
                              (item.statusId == "0"
                                ? "circleGreen"
                                : item.statusId == "3"
                                  ? "circleRed"
                                  : item.statusId == "8"
                                    ? "circleBlue"
                                    : "circleGrey")
                            }
                          >
                            {item.statusId == "0"
                              ? todayLength
                              : item.statusId == "3"
                                ? waitingLength
                                : item.statusId == "8"
                                  ? engagedLength
                                  : completedLength}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : moment().isBefore(
                    moment(selectedDate).format("YYYY-MM-DD"),
                    "day"
                  ) ? (
                    <div>
                      {isBefore.map((item) => (
                        <div
                          className={
                            "calenderTabTitle" +
                            " " +
                            (tabValue == item.statusId ? "active" : "")
                          }
                          onClick={() => tabClick(item.statusId)}
                        >
                          {item.statusName}
                          <span
                            className={
                              "circle" +
                              " " +
                              (item.statusId == "1"
                                ? "circleGreen"
                                : item.statusId == "2"
                                  ? "circleGrey"
                                  : "circleBlue")
                            }
                          >
                            {item.statusId == "1"
                              ? openLength
                              : item.statusId == "2"
                                ? confirmLength
                                : cancelledLength}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {isAfter.map((item) => (
                        <div
                          className={
                            "calenderTabTitle" +
                            " " +
                            (tabValue == item.statusId ? "active" : "")
                          }
                          onClick={() => tabClick(item.statusId)}
                        >
                          {item.statusName}
                          <span
                            className={
                              "circle" +
                              " " +
                              (item.statusId == "5"
                                ? "circleGrey"
                                : item.statusId == "6"
                                  ? "circleRed"
                                  : "circleBlue")
                            }
                          >
                            {item.statusId == "5"
                              ? admittedLength
                              : item.statusId == "6"
                                ? completedLength
                                : cancelledLength}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="calenderTabContent">
                    {
                      appointmentDataNew.length > 0 ? (
                        <div>
                          {appointmentDataNew.map((item) => (
                            <div>
                              {item.isBlocked === false && (
                                <div class="clndrAppmntRightBlock">
                                  <div
                                    id="todaytimeSlot0today"
                                    class="todyTimeSlot"
                                  >
                                    {moment(item.start1).format("hh:mm A")}
                                  </div>
                                  <div class="todyDetails">
                                    <div class="patntname elipsis">
                                      {item.patientfirstname}{" "}
                                      {item.patientlastname}
                                    </div>
                                    <div class="dctrname elipsis">
                                      {item.doctorName}
                                    </div>
                                  </div>
                                  {((role == "ROLE_HOSPITALADMIN" ||
                                    role == "ROLE_NURSE" ||
                                    role == "ROLE_RECEPTION") &&
                                    item.statusName == "ARRIVED") ||
                                    (role == "ROLE_DOCTOR" &&
                                      item.statusName == "ARRIVED") ? (
                                    <div class="consultpatient">
                                      <a onClick={() => consultClick(item)}>
                                        Consult
                                      </a>
                                    </div>
                                  ) : null}
                                  {item.isBlocked === false && (
                                    <div className="calendarTooltipOuter">
                                      <div className="calendarTooltip">
                                        <div className="pu_user">
                                          {/* <img src={User} /> */}
                                          {item.imageName != "" &&
                                            item.imageName !== null &&
                                            item.imageName !== "null" ? (
                                            <img
                                              alt=""
                                              src={
                                                process.env
                                                  .REACT_APP_S3_BUCKET_URL +
                                                process.env
                                                  .REACT_APP_S3_ENVIRONMEWNT_TYPE +
                                                process.env
                                                  .REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH +
                                                "/" +
                                                item.imageName
                                              }
                                            />
                                          ) : (
                                            <img alt="" src={User} />
                                          )}
                                        </div>
                                        <div className="pu_user_info">
                                          <h1>
                                            {item.patientfirstname}{" "}
                                            {item.patientlastname}
                                          </h1>
                                          {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<p>MR No: {item.mrNo}</p>) :
                                            (!item.prNo == "" && (
                                              <p>PR No: {item.prNo}</p>
                                            ))}

                                          <p>
                                            <span style={{ float: "left" }}>
                                              {item.gender}
                                            </span>
                                            {parseFloat(item.ageyear) !== 0 &&
                                              item.ageyear !== "" && (
                                                <span>
                                                  ,&nbsp;{item.ageyear} Year(s)
                                                </span>
                                              )}
                                          </p>
                                        </div>
                                        <div className="pu_generalinfo">
                                          <p>
                                            <img alt="" src={Call} />
                                            {item.mobileno}
                                          </p>
                                          {item.emailId !== "" && (
                                            <p>
                                              <img alt="" src={Mail} />
                                              {item.emailId}
                                            </p>
                                          )}
                                          {item.appointmentType !== "" && (
                                            <p>
                                              <img alt="" src={People} />
                                              {item.appointmentType}
                                            </p>
                                          )}
                                          <p>
                                            <img
                                              alt=""
                                              width={20}
                                              src={
                                                item.statusName == "ARRIVED"
                                                  ? ArrivedStatus
                                                  : item.statusName == "OPEN"
                                                    ? OpenStatus
                                                    : item.statusName == "CONFIRM"
                                                      ? ConfirmStatus
                                                      : item.statusName == "CANCELLED"
                                                        ? CancelledStatus
                                                        : item.statusName == "ADMITTED"
                                                          ? AdmittedStatus
                                                          : item.statusName == "COMPLETED"
                                                            ? CompletedStatus
                                                            : item.statusName == "DISCHARGE"
                                                              ? DischargeStatus
                                                              : item.statusName == "ENGAGED"
                                                                ? EngagedStatus
                                                                : CallCompletedStatus
                                              }
                                            />
                                            {item.statusName}
                                          </p>
                                          <div className="DrInfo">
                                            {" "}
                                            <p>
                                              {moment(item.start1).format(
                                                "hh:mm A"
                                              )}{" "}
                                              -{" "}
                                              {moment(item.end1).format(
                                                "hh:mm A"
                                              )}
                                            </p>
                                            <p>{item.doctorName}</p>
                                          </div>
                                          {item.reason !== "undefined" &&
                                            item.reason !== "" &&
                                            item.reason !== null ? (
                                            <p>Reason : {item.reason}</p>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}

export default ClinicsCalendar;
