import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import moment from 'moment';
import { callingAPI } from '../config';
import AWS from 'aws-sdk';
var s3;
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;

const styles = {
	border: '0.0625rem solid #9c9c9c',
	borderRadius: '0.25rem',
};

// function PatientPriscriptionWrite(props) {
const PatientPriscriptionWrite = forwardRef((props, ref) => {
	// const inputRef = useRef(null)
	const canvas = React.createRef();
	const [checkF, setChekF] = useState(false);
	const [values, setValues] = useState([]);
	const [sendImagedata, setImageData] = useState([])
	
	const [penColor, setPenColor] = useState('black');
	const [storeAuto, setStoreAuto] = useState([]);
	const [storeAutoId, setStoreAutoID] = useState('');
	const [getStoreID, setStoreID] = useState('')
	const [drawing, setDrawing] = useState([]);
	const [baseDrawing, setBaseDrawing] = useState([]);
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });

	const removeImgArr = (e, i) => {
		let removed = [...props.imgArr];
		if (removed[i].doctorVisitMedicineImageId) {
			alert('Do you want to delete');
			const postData = {
				userId: window.sessionStorage.getItem('userId'),
				doctorVisitMedicineImageId: removed[i].doctorVisitMedicineImageId

			};
			callingAPI('medicinePrescription/deactivateImageDetails', postData)
				.then((data) => {
					if (data.data.success === '1') {
						removed.splice(i, 1);
						props.setImgArr(removed);
					}
				})
				.catch((err) => { });
		} else {
			removed.splice(i, 1);
			props.setImgArr(removed);
		}
		// removed.splice(i, 1);
		// props.setImgArr(removed);
	};
	const setStoreClick = (name, id) => {
		setStoreAutoID(id)
		var array1 = []
		if([props.imgArr1].length > 0){
			//alert('1')
			array1.push(props.imgArr1)
		//    props.setImgArr1(array1)
		  array1.forEach((val, index) => {
			val.storeId = id ? id : "";
		})
   
		}
		// setValues([props.setImgArr1]);
// if(props.imgArr.length>0)
		props.imgArr.forEach((val, index) => {
			val.storeId = id ? id : "";
		})
		// else{
		// props.imgArr1.forEach((val, index) => {
		// 	val.storeId = id ? id : "";
		// })}
		setStoreID(id)
		props.setWriteStoreId(id)
		document.getElementById('txtStore').value = name;
		document.getElementById('hiddenStoreId').value = id;
		setStoreAuto([]);
		document.getElementById('storeList').style.display = 'none';
	};
	const passData = async () => {
		await props.settingImageDate(props.imgArr, sendImagedata, canvas);

	}
	useImperativeHandle(ref, () => ({
		canvasdetails() {

			return canvas;
		}
	}));

	async function addMore() {
		setChekF(false)
	canvas.current.clearCanvas();
		props.isDrawnFunction(false)
		canvas.current.exportImage('png').then((data) => {
				if (checkF == false) {
					return false;
				}
				else if (data) {
					var copy = [...sendImagedata]
					var obj = {
						doctorVisitMedicineImageId: "",
						imageName: moment().valueOf() + "Prescription.png",
						prescriptionImageName: data,
						storeId: "",
						comments: ""
					}
					var obj1 = {
						doctorVisitMedicineImageId: "",
						imageName: "",
						prescriptionImageName: "",
						storeId: "",
						comments: ""
					}
			
					//imgArr.push(obj)
					setDrawing(obj.imageName);
					props.isDrawnFunction(true)
					props.setImgArr([...props.imgArr, obj]);
					props.setImgArr1(obj1)

					setBaseDrawing(obj.prescriptionImageName);
				}
				else {
				}
			})
			.catch();
	};
	const setImagePath = (e) => {
		var checkFlag = false;
		if (e.length > 0) {
			checkFlag = true
			setChekF(true)
			canvas.current.exportImage('png').then((data) => {
				var obj = {
					doctorVisitMedicineImageId: "",
					imageName: moment().valueOf() + "Prescription.png",
					prescriptionImageName: data,
					storeId:storeAutoId?storeAutoId:"",
					comments: ""
				}
				props.setImgArr1(obj);
			})
		//	props.setImgArr1(getQaz);
			

		}
		else {
			checkFlag = false
			setChekF(false)
		}

		props.isDrawnFunction(checkFlag)
	}
	const clearCnvasImage = () => {
		canvas.current.clearCanvas();
		props.isDrawnFunction(false)
	}
	const getStoreNameAutoComplete = (mode, dropdownId) => {
		const postdata = {
			keyWord: document.getElementById(mode).value,
		};
		callingAPI('medicinePrescription/getStoreName', postdata).then((res) => {
			if (res.data.success === '1') {
				document.getElementById(dropdownId).style.display = 'block';
				setStoreAuto(res.data.data);
				document.addEventListener('mouseup', function (e) {
					var container = document.getElementById(dropdownId);
					if (container !== null) {
						if (!container.contains(e.target)) {
							container.style.display = 'none';
						}
					}
				});
				if (res.data.data.length == 0) {
					// alert('No Supplier Found.Please add Supplier');
					document.getElementById(dropdownId).style.display = 'none';
					setStoreAuto([]);
				}

			}
			else {
				document.getElementById(dropdownId).style.display = 'none';
				setStoreAuto([]);
			}

		})
			.catch();
	};
	useEffect(async () => {

		// 	await setImgArr(props.prescriptionImageData)
		//   props.settingImageDate(imgArr, sendImagedata,canvas.current);
	}, [])
	return (
		<div>
			{/* ==================   CANVAS SECTION BEGINS HERE =================================== */}
			<div class="prescriptionContent prescriptionContentAdd precriptionAddedDrugsgroups_PTour">
				<div class="practiceManagementForm1">
					<div class="practiceManagementFormBlcok" id="typePreId">
						<div id="divJsFiles" />
						<div id="treatmentPlanSection" class="prescriptionAddedMedcne_PTour">
							<div class="treatmentsList precscriptionTrtmntList">
								{/*  */}
								<div class="practiceManagementForm" style={{ padding: ' 0 10px' }}>
									<div class="practiceManagementFormBlcok" id="typePreId">
										<div id="treatmentPlanSection" class="prescriptionAddedMedcne_PTour">
											{/* Canvas begins here */}

											<div class="skechpadWrap" style={{ width: '100%' }}>
												<div class="skechpadTop">
													<div class="skechpadChoose">
														<label for="line-color-input">Choose Color</label>
														<div class="palette-box">
															<div
																class="palette red"
																onClick={() => setPenColor('red')}
															/>
															<div
																class="palette blue"
																onClick={() => setPenColor('blue')}
															/>
															<div class="palette black" onClick={() => setPenColor('black')} />
															<span class="u-full-width" id="clear" onClick={clearCnvasImage}>
																Clear
															</span>
														</div>
													</div>
												</div>
												{/* <Canvas /> */}
												<div class="sketchpadDiv">
													<div id="sketchpad">
														<ReactSketchCanvas
															ref={canvas}
															id="sketchpadP"
															class="sketchpadP"
															width={100}
															onChange={(e) => setImagePath(e)}
															style={{
																width: '780px',
																height: '350px',
															}}
															height={100}
															strokeWidth={5}
															strokeColor={penColor}
														/>
													</div>
												</div>
											</div>

											<span
												id="canvasBtnP"
												onclick="return saveCanvas()"
												class="practiceGreenButton prescriptionAddMoreBtn_PTour"
												style={{ cursor: 'pointer' }}
												onClick={addMore}
											>
												Add More
											</span>
											<br />

											<div className="addmoreImgs">
												{props.imgArr.map((val, index) => (

													<div className="plcanvasclose">

														<span
															className="close_row"
															onClick={(e) => removeImgArr(e, index)}
														>
															x
														</span>

														<img

															src={val.prescriptionImageName}
															style={{
																width: '100%',
																height: '25%',
																border: '1px solid grey',
															}}
														/>
													</div>
												))}
											</div>
											<div
												class="precsriptionStore"
												id="medicineCommonSection"
											>
												<ul>
													<li class="col-md-12 col-lg-12 PaddingGridFix12">
														<label>
															Select Store
														</label>
														<input
															type="text"
															id="txtStore"
															name="txtStore"
															class="prescriptionSelectStore_PTour ui-autocomplete-input"
															data-position="top"
															tabindex="13"
															placeholder="Select Store"
															autocomplete="off"
															onKeyUp={() =>
																getStoreNameAutoComplete("txtStore", "storeList")}
															onClick={() =>
																getStoreNameAutoComplete("txtStore", "storeList")}
														/>
														<ul
															className="autoSearch autoSearch-p0"
															id="storeList"
															style={{
																display:
																	'none',
															}}
														>
															{storeAuto.map(
																(
																	item,
																	i
																) => (
																	<li
																		onClick={() =>
																			setStoreClick(
																				item.StoreName,
																				item.ServiceProvId
																			)}
																	>
																		{
																			item.StoreName
																		}
																	</li>
																)
															)}
														</ul>
														<input
															type="hidden"
															id="hiddenStoreId"
														/>
													</li>
												</ul></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ==================   CANVAS SECTION ENDS HERE =================================== */}
		</div>
	);
})

export default PatientPriscriptionWrite;
